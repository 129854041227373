interface OptionsProps {
  container?: any;
  width?: string;
  height?: string;
  textAlign?: any;
  textBaseline?: any;
  font?: string;
  fillStyle?: string;
  content?: string;
  globalAlpha?: number;
  rotate?: number;
  zIndex?: number;
}
let reloadTimes = 0;

// eslint-disable-next-line
export function watermark(options: OptionsProps) {
  const {
    // 容器
    container = document.body,
    // canvas元素宽
    width = "300",
    // canvas元素高
    height = "200",
    // 文字对齐
    textAlign = "left",
    // 基准线
    textBaseline = "bottom",
    // 字体大小及样式
    font = "18px Microsoft Yahei",
    // 自定义水印的颜色
    fillStyle = "#000",
    // 水印内容
    content = "flightAI",
    // 设置图形和图像透明度的值
    globalAlpha = 0.1,
    // 文字旋转角度
    rotate = 16,
    // 元素堆叠顺序
    zIndex = 1000,
  } = options;

  const canvas = document.createElement("canvas");
  canvas.setAttribute("width", width);
  canvas.setAttribute("height", height);
  // 获取 canvas2d 上下文
  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.globalAlpha = globalAlpha;
    ctx.textAlign = textAlign;
    ctx.textBaseline = textBaseline;
    ctx.font = font;
    ctx.fillStyle = fillStyle;
    ctx.rotate((Math.PI * rotate) / 180);
    ctx.fillText(content, 50, 50);
  }

  // 返回一个包含图片展示的 data URI
  const base64Url = canvas.toDataURL();

  // 选择器
  const __wm = document.querySelector(".__wm");
  const watermarkDiv = __wm || document.createElement("div");
  const styleStr = `
      position:absolute;
      top:0px;
      left:0px;
      width:100%;
      height:100%;
      z-index:${zIndex};
      pointer-events:none;
      background-repeat:repeat;
      background-image:url('${base64Url}')`;

  watermarkDiv.setAttribute("style", styleStr);
  // 为元素添加“__wm”类名
  watermarkDiv.classList.add("__wm");
  if (container) {
    container.style.position = "relative";
    if (!__wm) {
      // 添加元素
      container.appendChild(watermarkDiv);
    }
  }

  // 检查浏览器是否支持这个API
  const MutationObserver = window.MutationObserver;
  if (MutationObserver) {
    // eslint-disable-next-line
    const args = arguments[0];
    let mo: any = new MutationObserver(() => {
      const __wm2 = document.querySelector(".__wm");
      // 只在__wm元素变动才重新调用
      if (
        (__wm2 && __wm2.getAttribute("style") !== styleStr) ||
        !__wm2 ||
        container.style.position !== "relative"
      ) {
        // 避免一直触发
        mo.disconnect();
        mo = null;
        if (reloadTimes < 5) {
          reloadTimes++;
          watermark(args);
        }
      }
    });
    mo.observe(container, {
      // 观察目标节点的属性节点
      attributes: true,
      // 观察目标节点的所有后代节点
      subtree: true,
      // 观察目标节点的子节点
      childList: true,
    });
  }
}
