import React, { useEffect, useImperativeHandle, useState } from "react";
import { Row, Table } from "antd";
import _, { cloneDeep } from "lodash";
import "./CustomTable.css";
import ValueNum from "Components/ValueNum";
import {
  getComparedPercentage,
  getCompareVal,
  openDownloadDialog,
  showNum,
  showRawNum,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { IQueryExt } from "../Interface";
import { IDownloadHeader } from "Interface";
import { SHOW_LOAD } from "Constants";

const baseColumn: any[] = [
  {
    title: getSharkText("key.rank.name"),
    dataIndex: "px",
    key: "px",
    render: (val: number) => <ValueNum type="num" value={val} />,
    width: 70,
    fixed: "left",
    downLoadFormatter: (val: any) => showNum(val),
  },
  {
    title: getSharkText("area.airport.title"),
    dataIndex: "airportName",
    key: "airportName",
    render: (val: string, record: any) => `${val}（${record.airport}）`,
    width: 180,
    fixed: "left",
    downLoadFormatter: (val: any, record: any) => `${val}（${record.airport}）`,
  },
];

const valColumns: IDownloadHeader[] = [
  {
    title: getSharkText("card.passenger_traffic.name"),
    dataIndex: "passengerTraffic",
    key: "passengerTraffic",
    defaultSortOrder: "descend",
    sorter: (a: any, b: any) => a.passengerTraffic - b.passengerTraffic,
    render: (val: number) => <ValueNum type="num" value={val} />,
    downloadFormatter: (val: any) => showRawNum(val),
  },
  {
    title: getSharkText("card.transport_capacity.name"),
    dataIndex: "transportCapacity",
    key: "transportCapacity",
    sorter: (a: any, b: any) => a.transportCapacity - b.transportCapacity,
    render: (val: number) => <ValueNum type="num" value={val} />,
    downloadFormatter: (val: any) => showRawNum(val),
  },
  {
    title: getSharkText("card.flight_sorties.name"),
    dataIndex: "flightSorties",
    key: "flightSorties",
    sorter: (a: any, b: any) => a.flightSorties - b.flightSorties,
    render: (val: number) => <ValueNum type="num" value={val} />,
    downloadFormatter: (val: any) => showRawNum(val),
  },
  ...(SHOW_LOAD
    ? [
        {
          title: getSharkText("key.loadfactor"),
          dataIndex: "lf",
          key: "lf",
          sorter: (a: any, b: any) => a.lf - b.lf,
          render: (val: number) => <ValueNum type="percentage" value={val} />,
          downloadFormatter: (val: any) => showRawNum(val, "percentage"),
        },
      ]
    : []),
  {
    title: getSharkText("key.search_index.name"),
    dataIndex: "searchIndex",
    key: "searchIndex",
    sorter: (a: any, b: any) => a.searchIndex - b.searchIndex,
    render: (val: number) => <ValueNum type="num" value={val} />,
    downloadFormatter: (val: any) => showRawNum(val),
  },
  {
    title: getSharkText("dashboard.table.avg_price"),
    dataIndex: "avgPrice",
    key: "avgPrice",
    sorter: (a: any, b: any) => a.avgPrice - b.avgPrice,
    render: (val: number) => <ValueNum type="price" value={val} />,
    downloadFormatter: (val: any) => showRawNum(val),
  },
  {
    title: getSharkText("card.refund.name"),
    dataIndex: "refund",
    key: "refund",
    sorter: (a: any, b: any) => a.refund - b.refund,
    render: (val: number) => <ValueNum type="percentage" value={val} />,
    downloadFormatter: (val: any) => showRawNum(val, "percentage"),
  },
];

// 表格头两列的宽度
const dimWidth = 250;
const baseWidth = 800;

const PERCENTAGE_COLS = ["lf", "refund"];

interface CustomTableProps {
  dataSource: any[];
  searchkeys: string[];
  searchPlaceholder: string;
  handleDownload?: () => void;
  valControl: string[];
  queryExt?: IQueryExt;
  defaultAirport?: string;
}

const CTable = (props: CustomTableProps, ref: any) => {
  const { dataSource, searchkeys, valControl, queryExt, defaultAirport } =
    props;
  const [searchText] = useState<string>("");
  const [columns, setColumns] = useState<any[]>([]);
  const [width, setWidth] = useState<number>(baseWidth + dimWidth);
  // 需要将同比环比的数据合并
  const [mergedData, setMergedData] = useState<any[]>([]);
  const [curPage, setCurPage] = useState<number>(1);

  // 来新数据以后, merge计算同比环比
  useEffect(() => {
    const curData: any[] = [];
    const yoyData: any[] = [];
    const wowData: any[] = [];
    dataSource.forEach((p) => {
      const d = cloneDeep(p);
      switch (d.type) {
        case 0:
          curData.push(d);
          break;
        case 1:
          yoyData.push(d);
          break;
        case 2:
          wowData.push(d);
          break;
      }
    });
    curData.forEach((d, i) => {
      d.px = i + 1;
      if (queryExt && queryExt.yoy !== 0) {
        const yoyD = yoyData.find((y) => y.airport === d.airport);
        valColumns.forEach((col: any) => {
          const columnDataIndex = `${col.dataIndex}_yoy`;
          d[columnDataIndex] = yoyD
            ? PERCENTAGE_COLS.includes(col.dataIndex)
              ? getComparedPercentage(d[col.dataIndex], yoyD[col.dataIndex]) /
                100
              : getCompareVal(d[col.dataIndex], yoyD[col.dataIndex])
            : 0;
        });
      }
      if (queryExt && queryExt.wow !== 0) {
        const wowD = wowData.find((w) => w.airport === d.airport);
        valColumns.forEach((col: any) => {
          const columnDataIndex = `${col.dataIndex}_wow`;
          d[columnDataIndex] = wowD
            ? PERCENTAGE_COLS.includes(col.dataIndex)
              ? getComparedPercentage(d[col.dataIndex], wowD[col.dataIndex]) /
                100
              : getCompareVal(d[col.dataIndex], wowD[col.dataIndex])
            : 0;
        });
      }
    });
    // 计算初始的表格页数
    let defaultPageIndex = 1;
    const airportIdx =
      defaultAirport &&
      curData.findIndex((d: any) => d.airport === defaultAirport);
    if (airportIdx && airportIdx >= 0) {
      // 因为页码从1开始, 所以除以10以后需要 + 1
      defaultPageIndex = Math.floor(airportIdx / 10 + 1);
    }
    setMergedData(curData);
    setCurPage(defaultPageIndex);
  }, [dataSource, queryExt]);

  // 生成同比环比的columns
  useEffect(() => {
    if (!queryExt) {
      return;
    }
    const { yoy = 0, wow = 0 } = queryExt;
    if (yoy === 0 && wow === 0) {
      setColumns(baseColumn.concat(valColumns));
      // 200是开头两列的宽度
      // setWidth(baseWidth + dimWidth);
      // } else {
      // let widthTimes = 1;
      // if (yoy !== 0) widthTimes++;
      // if (wow !== 0) widthTimes++;
    }
    setWidth(baseWidth * valControl.length + dimWidth);
    const tmpColumns: any[] = cloneDeep(valColumns);
    let count = 1;
    valColumns.forEach((c, i) => {
      if (yoy !== 0) {
        const key = `${c.dataIndex}_yoy`;
        const yoyItem = {
          title: `${c.title}${getSharkText("key.yoy.button")}`,
          dataIndex: key,
          key,
          sorter: (a: any, b: any) => a[key] - b[key],
          render: (val: number) => (
            <ValueNum
              type="percentage"
              value={c.dataIndex === "lf" ? val : val * 100}
            />
          ),
          downLoadFormatter: (val: any) =>
            showRawNum(c.dataIndex === "lf" ? val : val * 100, "percentage"),
        };
        tmpColumns.splice(i + count, 0, yoyItem);
        count++;
      }
      if (wow !== 0) {
        const key = `${c.dataIndex}_wow`;
        const wowItem = {
          title: `${c.title}${getSharkText("key.wow.indicator")}/${getSharkText(
            "key.mom.indicator"
          )}`,
          dataIndex: key,
          key,
          sorter: (a: any, b: any) => a[key] - b[key],
          render: (val: number) => (
            <ValueNum
              type="percentage"
              value={c.dataIndex === "lf" ? val : val * 100}
            />
          ),
          downLoadFormatter: (val: any) =>
            showRawNum(c.dataIndex === "lf" ? val : val * 100, "percentage"),
        };
        tmpColumns.splice(i + count, 0, wowItem);
        count++;
      }
    });
    let rstColumns = tmpColumns;
    if (!valControl.includes("数值")) {
      rstColumns = tmpColumns.filter(
        (c: any) => c.dataIndex.includes("yoy") || c.dataIndex.includes("wow")
      );
    }
    setColumns(baseColumn.concat(rstColumns));
  }, [queryExt, valControl]);

  // download
  useImperativeHandle(ref, () => ({
    download: () => {
      const sheetData1: any = [];
      const sheetDataFn = (record: any, i: number) => {
        const obj1: any = {};
        columns.forEach((c: any) => {
          obj1[c.title] = c.downLoadFormatter
            ? c.downLoadFormatter(record[c.dataIndex], record, i)
            : record[c.dataIndex];
        });
        return obj1;
      };

      mergedData.forEach((record: any, i: number) => {
        sheetData1.push(sheetDataFn(record, i));
      });
      const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet1, "机场");
      const workbookBlob = workbook2blob(wb);
      openDownloadDialog(workbookBlob, "机场.xlsx");
    },
  }));

  const getFilterData = () => {
    const cloneData = _.cloneDeep(mergedData);
    const filter = cloneData.filter((data: any) => {
      let isPass = false;
      for (const key of searchkeys) {
        if (data[key] && data[key].includes(searchText)) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = _.isEmpty(dataSource) ? dataSource : getFilterData();

  return (
    <>
      {_.isEmpty(mergedData) ? undefined : (
        <Row style={{ marginTop: 0 }}>
          <Table
            showSorterTooltip={false}
            tableLayout="fixed"
            dataSource={filterData}
            columns={columns}
            scroll={{ x: width, y: 540 }}
            style={{ color: "#000000d9" }}
            size="small"
            rowKey="key"
            pagination={{
              showSizeChanger: true,
              current: curPage,
              onChange: setCurPage,
              pageSizeOptions: ["10", "25", "50", "100"],
            }}
          />
        </Row>
      )}
    </>
  );
};

const CustomTable = React.forwardRef(CTable);
export default CustomTable;
