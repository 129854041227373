export const MODULE_CODE: any = "load_factor";

export const CHART_TABLE_CODE = {
  routeOrFlight: "non",
  chart: "non",
};

export const QUERY_URL = {
  routeOrFlight: "queryLoadFactorRouteOrFlight",
  chart: "queryLoadFactor",
};
