import { OpenableHandle, Role } from "Interface";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import RoutesSelector from "./RoutesSelector";
import { Modal } from "antd";
import useRefFunc from "Utils/useRefFunc";
import { isSame } from "Utils";

export interface AirlineRoutesEditorProps {
  airlineRoles: Role;
  onSubmit: (v: Role) => void;
}

/** 航司权限航线列表 */
const AirlineRoutesEditor = forwardRef(
  (props: AirlineRoutesEditorProps, ref: Ref<OpenableHandle>): ReactElement => {
    const { airlineRoles, onSubmit } = props;
    const [open, setOpen] = useState(false);
    const [editingAirlineRole, setEditingAirlineRole] =
      useState<Role>(airlineRoles);
    useEffect(() => {
      setEditingAirlineRole(airlineRoles);
    }, [airlineRoles]);

    useImperativeHandle(ref, () => ({
      open: () => {
        setOpen(true);
      },
    }));
    const submit = useRefFunc(() => {
      if (isSame(airlineRoles, editingAirlineRole)) {
        return;
      }
      onSubmit(editingAirlineRole);
      setOpen(false);
    });

    const airlineRoutes = useMemo(() => {
      if (editingAirlineRole.permissionRoute) {
        return JSON.parse(editingAirlineRole.permissionRoute);
      }
      return {};
    }, [editingAirlineRole.permissionRoute]);

    const changeRoutes = useRefFunc((airline: string, routes: string[]) => {
      const filterRoutes =
        routes.length > 1
          ? routes.filter((f) => !["*", "-"].includes(f))
          : routes.filter((f) => f !== "*");
      const newAirlineRoutes = {
        ...airlineRoutes,
        [airline]:
          filterRoutes.length === 0 ? undefined : filterRoutes.join(","),
      };
      const tmp: Role = {
        ...editingAirlineRole,
        permissionRoute: JSON.stringify(newAirlineRoutes),
      };
      if (!isSame(tmp, editingAirlineRole)) {
        setEditingAirlineRole(tmp);
      }
    });

    return (
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        onOk={submit}
        destroyOnClose
        width={1000}
      >
        {editingAirlineRole.permissionCode.split(",").map((airline) => {
          const routes = airlineRoutes[airline]
            ? airlineRoutes[airline].split(",")
            : [];
          return (
            <RoutesSelector
              key={airline}
              airline={airline}
              routes={routes}
              setRoutes={(v) => changeRoutes(airline, v)}
            />
          );
        })}
      </Modal>
    );
  }
);
AirlineRoutesEditor.displayName = "AirlineRoutesEditor";
export default AirlineRoutesEditor;
