import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Popover, Row } from "antd";
import TrendsPie from "./Components/TrendsPie";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import useGlobal from "Store";
import Page from "Layout/Page";
import { getServer } from "Service/server";
import ScoreTable from "./Components/ScoreTable";
import { DEFAULT_AIRPORT_COMPARE_TYPE_NAME } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

const unit = getSharkText("config_page_passenger_traffic_estimate");

interface IAirlineIndexProps {
  isDemo: boolean;
}

const AirlineIndex: React.FC<IAirlineIndexProps> = () => {
  const [globalState] = useGlobal();
  const { systemType } = globalState;
  const server = getServer(systemType);

  const priceTitle = (
    <div className="trends-card-title">
      <Popover
        content={
          <p style={{ width: 300, margin: 0 }}>
            <span style={{ display: "block" }}>
              <span style={{ marginRight: 5 }}>
                {getSharkText("config_page_price_recovery_index")}:
              </span>
              {getSharkText("date.last30")}
              {getSharkText("key.ow_avg_price_ctrip.hint")}/
              {DEFAULT_AIRPORT_COMPARE_TYPE_NAME}
              {getSharkText("key.ow_avg_price_ctrip.hint")}
            </span>
          </p>
        }
      >
        <span>
          {getSharkText("config_page_market_price_recovery_index")}&nbsp;
          <InfoCircleOutlined style={{ color: "#999" }} />
        </span>
      </Popover>
    </div>
  );

  const searchTitle = (
    <div className="trends-card-title">
      <Popover
        content={
          <p style={{ width: 300, margin: 0 }}>
            <span style={{ display: "block" }}>
              {`${getSharkText(
                "dashboard.trend_pie.Confidence.title"
              )}：${getSharkText(
                "config_page_PaAiDaAi_future_30_days_search_index"
              )}/${DEFAULT_AIRPORT_COMPARE_TYPE_NAME}搜索指数`}
            </span>
          </p>
        }
      >
        <span>
          {getSharkText("dashboard.trend_pie.Confidence.title")}&nbsp;
          <InfoCircleOutlined style={{ color: "#999" }} />
        </span>
      </Popover>
    </div>
  );

  const orderTitle = (
    <div className="trends-card-title">
      <Popover
        content={
          <p style={{ width: 300, margin: 0 }}>
            <span style={{ display: "block", marginTop: 8 }}>
              {`${getSharkText(
                "config_page_PaAiDaAi_travel_recovery_index"
              )}${unit}/${DEFAULT_AIRPORT_COMPARE_TYPE_NAME}${unit}`}
            </span>
          </p>
        }
      >
        <span>
          {getSharkText("config_page_travel_recovery_index")}&nbsp;
          <InfoCircleOutlined style={{ color: "#999" }} />
        </span>
      </Popover>
    </div>
  );

  return (
    <Page
      defaultDateMode="none"
      isShowCompareType={false}
      isShowInOut={false}
      isShowAirlineAirport={false}
    >
      <div className="content-white">
        <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 16]}>
          <Col xl={8} lg={24}>
            <Card type="inner" title={orderTitle} style={{ height: 390 }}>
              <TrendsPie
                server={server}
                queryUrl={QUERY_URL[systemType].trendsPie}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].travel}
                color="#177DFE"
                link="/core_overview"
                type={1}
              />
            </Card>
          </Col>
          <Col xl={8} lg={24}>
            <Card type="inner" title={searchTitle} style={{ height: 390 }}>
              <TrendsPie
                server={server}
                queryUrl={QUERY_URL[systemType].trendsPie}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].confident}
                color="#FC9B4F"
                link="/search_index"
                type={2}
              />
            </Card>
          </Col>
          <Col xl={8} lg={24}>
            <Card type="inner" title={priceTitle} style={{ height: 390 }}>
              <TrendsPie
                server={server}
                queryUrl={QUERY_URL[systemType].trendsPie}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].confident}
                color="#26af5f"
                link="/airline_analysis"
                type={3}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ScoreTable
              server={server}
              queryUrl={QUERY_URL[systemType].table}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].score}
            />
          </Col>
        </Row>
      </div>
    </Page>
  );
};
export default AirlineIndex;
