import { Spin, Tooltip } from "antd";
import { ISeries } from "Interface";
import merge from "lodash/merge";
import {
  CHART_TABLE_CODE,
  MODULE_CODE,
  QUERY_URL,
} from "Page/AI/FlightReview/fetchCode";
import React, { ReactElement, useEffect, useState } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { genSeriesByDimensions, showNum, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { WEEK } from "Constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import EchartsReactBase from "Components/EchartsReactBase";

export interface IPlanAnalysisProps {
  defaultSeries: ISeries[];
  ext?: Record<string, unknown>;
  chartConfig?: Record<string, unknown>;
  precision: number;
}

/**
 * 航班复盘-班期分析
 */
const PlanAnalysis = (props: IPlanAnalysisProps): ReactElement => {
  const { ext, defaultSeries, chartConfig, precision } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(2),
    url: QUERY_URL[2].schedule,
    defaultValue: null,
    head: {
      moduleCode: MODULE_CODE[2],
      chartTableCode: CHART_TABLE_CODE[2].schedule,
    },
    query: airlinesQueryCondition,
    ext: {
      ...ext,
    },
    lazey: true,
  });

  useEffect(() => {
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        ...ext,
      },
    });
  }, [ext, doFetch, airlinesQueryCondition]);

  const [option, setOption] = useState<any>();

  useEffect(() => {
    const dimensions = data && data.length ? Object.keys(data[0]) : [];
    const tmp: Record<string, any> = {
      dataset: {
        source: data,
      },
      color: ["#01C5DB", "#FC9B4F", "#177DFE"],
      // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          let dom = "";
          params.forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const seriesConfig = defaultSeries.find(
              (d) => d.name === item.seriesName
            );
            const isPercentVal = seriesConfig
              ? seriesConfig.isPercentVal
              : false;
            const itemDim = item.dimensionNames[item.encode.y[0]];
            const itemValue = item.value[itemDim];
            const value = isPercentVal
              ? showNum(itemValue * 100, "percentage", precision)
              : showNum(itemValue, "num", precision);
            const val = `${item.seriesName}: ${value}`;
            dom = dom + "<br />" + dot + val;
          });
          return dom;
        },
      },
      xAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
        axisLabel: {
          padding: [8, 0, 0, 0],
          formatter: (value: number) => WEEK[value - 1],
        },
      },
      grid: {
        top: "100",
        right: "100",
      },
      dataZoom: null,
      yAxis: [
        {
          position: "right",
          type: "value",
          splitNumber: 3,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#ddd",
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: (value: number) =>
              defaultSeries.filter((d) => d.isPercentVal).length > 0
                ? showNum(value * 100, "percentage")
                : showNum(value, "num", precision),
          },
        },
      ],
      series: genSeriesByDimensions(dimensions, defaultSeries),
    };
    const opt = merge(tmp, chartConfig);
    if (typeof opt.xAxis.dataFn === "function") {
      opt.xAxis.data = opt.xAxis.dataFn(data, defaultSeries);
    }
    setOption(opt);
  }, [chartConfig, data, defaultSeries, precision]);

  return (
    <Spin spinning={isLoading}>
      <div style={{ padding: 24 }}>
        <span style={{ fontSize: "150%", marginRight: 5 }}>
          {getSharkText("config_page_class_analysis")}{" "}
        </span>
        <Tooltip title={getSharkText("config_page_data_statistics")}>
          <InfoCircleOutlined />
        </Tooltip>
        {option ? (
          <EchartsReactBase
            className="bar-line-charts"
            option={option}
            theme="default"
            style={{ height: 484 }}
            notMerge
          />
        ) : undefined}
      </div>
    </Spin>
  );
};
export default PlanAnalysis;
