import React, {
  CSSProperties,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  SwapLeftOutlined,
  SwapOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { Button, Col, Row, Tooltip, Typography } from "antd";
import { Area, FlightClass } from "Interface";
import useGlobal from "Store";
import { getModuleNameFromPath, getModuleStatus } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import CascaderArea from "Components/CascaderArea";
import City from "./city";
import withRouterAndRef from "Utils/withRouterAndRef";
import { useLocation } from "react-router-dom";
import { DEFAULT_AREA } from "Constants";

const { Text } = Typography;

interface SearchAirlineProps {
  server?: string;
  url?: string;
  setArea: (area: Area) => void;
  moduleCode?: string;
  chartTableCode?: string;
  type?: "city" | "airport";
  submitControl?: boolean;
  style?: CSSProperties;
  useDomesticArea?: boolean;
  flightClass?: FlightClass;
}

export interface SearchAirlineRef {
  setValue: (v: Area) => void;
}

const SearchAreaOrAirport = React.forwardRef(
  (props: SearchAirlineProps, ref: React.Ref<SearchAirlineRef>) => {
    const {
      server,
      url,
      moduleCode,
      setArea,
      type = "airport",
      submitControl = true,
      style,
      useDomesticArea,
      flightClass: propFlightClass,
    } = props;
    const location = useLocation();
    const [selectedArea, setSelectedArea] = useState<Area>(DEFAULT_AREA);
    const [globalState] = useGlobal();
    const { queryCondition, helpVisibility, userInfo, systemType } =
      globalState;
    const { departure, arrive, airportName, flightClass } = queryCondition;
    const { pathname } = location;
    const moduleName = getModuleNameFromPath(pathname, systemType);
    const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
    const cascaderRef = useRef<any>();

    const handleClick = () => {
      if (selectedArea) {
        setArea(selectedArea);
      }
    };

    useEffect(() => {
      if (!submitControl && selectedArea) {
        setArea(selectedArea);
      }
    }, [submitControl, selectedArea, setArea]);

    useImperativeHandle(ref, () => ({
      setValue: (v: Area) => {
        console.log("search ref :", v);
        if (cascaderRef && cascaderRef.current) {
          cascaderRef.current.setValue(v);
        }
      },
    }));

    return (
      <Row
        className="search-area"
        align="middle"
        gutter={4}
        style={{ marginTop: 16, ...style }}
      >
        {!isDemo ? (
          <>
            <Col className="area-departure" id="areaDeparture">
              <Tooltip
                getPopupContainer={() =>
                  document.getElementById("main") || document.body
                }
                title={getSharkText("key.airport_search_note.hint")}
                placement="bottomLeft"
                visible={helpVisibility}
              >
                <Text>{type === "airport" ? airportName : <City />}</Text>
              </Tooltip>
            </Col>
            <Col style={{ padding: "0 5px" }}>
              {departure && arrive ? (
                <SwapOutlined />
              ) : departure ? (
                <SwapRightOutlined />
              ) : (
                <SwapLeftOutlined />
              )}
            </Col>
          </>
        ) : undefined}
        <Col className="area-arrive" id="areaArrive">
          <CascaderArea
            ref={cascaderRef}
            server={server}
            url={url}
            moduleCode={moduleCode}
            value={selectedArea}
            type={type}
            setSelectedArea={setSelectedArea}
            flightClass={propFlightClass ?? flightClass}
            isDemo={isDemo}
            useDomesticArea={useDomesticArea}
          />
        </Col>
        {submitControl ? (
          <Col>
            <Button
              id="areaSearchBtn"
              disabled={!!isDemo}
              type="primary"
              onClick={handleClick}
            >
              {getSharkText("key.query.button")}
            </Button>
          </Col>
        ) : undefined}
      </Row>
    );
  }
);
SearchAreaOrAirport.displayName = "SearchAreaOrAirport";
export default withRouterAndRef(SearchAreaOrAirport);
