import React from "react";
import { IDownloadHeaderPro } from "Interface";
import { InputNumber, Switch } from "antd";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import { WEEK } from "Constants";
import { FlightInfoDetailWithKey } from "../../interface";

export const getColumns = (v: {
  key: string | undefined;
  changeAuto: (record: FlightInfoDetailWithKey, auto: boolean) => void;
  roOpen: boolean;
  changeRoOpen: (open: boolean, record: FlightInfoDetailWithKey) => void;
  searchRange: [number | null | undefined, number | null | undefined];
  setSearchRange: (
    range: [number | null | undefined, number | null | undefined]
  ) => void;
  isCtriper: boolean;
  agency: number;
}): Array<IDownloadHeaderPro<FlightInfoDetailWithKey>> => {
  const {
    key,
    changeAuto,
    roOpen,
    changeRoOpen,
    searchRange,
    setSearchRange,
    isCtriper,
    agency,
  } = v;
  const rst: Array<IDownloadHeaderPro<FlightInfoDetailWithKey>> = [
    {
      title: getSharkText("charts.date_of_departure"),
      dataIndex: "takeoffdate",
      key: "takeoffdate",
      render: (val: any, r: FlightInfoDetailWithKey) => {
        const obj = {
          children: `${val} ${WEEK[moment(val).weekday()]}`,
          props: { rowSpan: r.feRowSpan ?? 1 },
        };
        return obj;
      },
      width: 185,
      className: "white-column",
    },
    // 库存开关
    {
      title: "库存信息",
      dataIndex: "actions",
      key: "control",
      width: 65,
      render: (val: any, r: FlightInfoDetailWithKey) => {
        return (
          <div
            style={{ textAlign: "center" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Switch
              size="small"
              checked={roOpen && r.key === key}
              onChange={(checked) => changeRoOpen(checked, r)}
            />
          </div>
        );
      },
    },
    {
      title: "航班",
      dataIndex: "flightno",
      key: "flightNo",
      width: 95,
    },
    {
      title: "出发机场",
      dataIndex: "dport",
      key: "takeofftime",
      width: 90,
    },
    {
      title: "到达机场",
      dataIndex: "aport",
      width: 80,
    },
    {
      title: "库存",
      children: [
        {
          title: "总库存",
          dataIndex: "totalInventory",
          key: "totalInventory",
          width: 75,
        },
        {
          title: "已售",
          dataIndex: "soldInventory",
          key: "soldInventory",
          width: 75,
        },
        {
          title: "剩余可售",
          dataIndex: "feRemainInventory",
          filterDropdown: () => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
              <InputNumber
                value={searchRange[0]}
                onChange={(val) => setSearchRange([val, undefined])}
                size="small"
                style={{ width: 70 }}
              />
              <span style={{ margin: "0 5px" }}>~</span>
              <InputNumber
                value={searchRange[1]}
                onChange={(val) => setSearchRange([undefined, val])}
                size="small"
                style={{ width: 70 }}
              />
            </div>
          ),
          valueType: "digit",
          width: 85,
        },
        ...(isCtriper
          ? ([
              {
                title: "携程销量",
                dataIndex: "ctripSales",
                width: 70,
              },
            ] as Array<IDownloadHeaderPro<FlightInfoDetailWithKey>>)
          : []),
      ],
    },
    {
      title: "进度",
      children: [
        {
          title: "当前",
          dataIndex: "saleProgress",
          key: "saleProgress",
          render: (val: any) => {
            return showNum(val * 100, "percentage");
          },
          width: 160,
        },
        ...(isCtriper
          ? ([
              {
                title: "预测",
                dataIndex: "predProgress",
                key: "lfpredict",
                render: (val: any) => showNum(val * 100, "percentage"),
                width: 70,
              },
            ] as Array<IDownloadHeaderPro<FlightInfoDetailWithKey>>)
          : []),
      ],
    },
    {
      title: "价格",
      children: [
        {
          title: "全价",
          dataIndex: "fullPrice",
          key: "fullPrice",
          width: 120,
          render: (val: any) => showNum(val),
        },
        {
          title: "已售均价",
          dataIndex: "soldAvgPrice",
          key: "soldAvgPrice",
          width: 120,
          render: (val: any) => showNum(val),
        },
        {
          title: "已售折扣",
          dataIndex: "soldAvgDiscount",
          key: "soldAvgDiscount",
          width: 120,
          render: (val: any) => showNum(val, "num", 2),
        },
        {
          title: "旗舰店",
          dataIndex: "airlineMarketPrice",
          key: "airlineMarketPrice",
          width: 120,
          render: (val: any) => showNum(val),
        },
        {
          title: "最低展示",
          dataIndex: "minDisplayPrice",
          key: "minDisplayPrice",
          width: 120,
          render: (val: any) => showNum(val),
        },
        {
          title: "最低成本",
          dataIndex: "minCostPrice",
          key: "minCostPrice",
          width: 120,
          render: (val: any) => showNum(val),
        },
        ...(isCtriper
          ? ([
              {
                title: "预测最高",
                dataIndex: "predTopPrice",
                key: "predTopPrice",
                width: 120,
                render: (val: any) => showNum(val),
              },
            ] as Array<IDownloadHeaderPro<FlightInfoDetailWithKey>>)
          : []),
      ],
    },
    {
      title: "政策",
      children: [
        {
          title: "是否胜出",
          dataIndex: "minCostAgency",
          key: "minCostAgency",
          width: 120,
          render: (v: any) => {
            return v === agency ? "是" : "-";
          },
        },
        {
          title: "政策票面价",
          dataIndex: "printPrice",
          key: "printPrice",
          width: 120,
        },
        {
          title: "竞价空间",
          dataIndex: "biddingSpace",
          key: "biddingSpace",
          width: 120,
        },
        {
          title: "投放库存数",
          dataIndex: "ticketInventory",
          key: "ticketInventory",
          width: 120,
        },
      ],
    },
    {
      title: "执行时间",
      dataIndex: "exeTime",
      width: 300,
      render: (v: any) => v.substring(0, 19),
    },
    {
      title: "自动执行开关",
      dataIndex: "automated",
      width: 80,
      render: (val: any, r: FlightInfoDetailWithKey) => {
        return (
          <div
            style={{ textAlign: "center" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Switch
              size="small"
              checked={val}
              onChange={(checked) => changeAuto(r, checked)}
            />
          </div>
        );
      },
    },
  ];
  return rst;
};
