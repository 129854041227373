import React, { useCallback, useEffect, useMemo } from "react";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import isEmpty from "lodash/isEmpty";
import { Empty, Spin } from "antd";
import Refetch from "Components/Refetch";
import CustomTable from "./CustomTable";
import { ITransferAnalysisExt } from "Page/AI/TransferPolicy/TransferPolicyInterface";
import { getServer } from "Service/server";

interface AirlineTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  transferAnalysisExt: ITransferAnalysisExt;
}

const TransferTable: React.FC<AirlineTableProps> = (
  props: AirlineTableProps
) => {
  const { queryUrl, moduleCode, chartTableCode, transferAnalysisExt } = props;
  const { query, filter } = transferAnalysisExt;
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const { useCompare } = queryCondition;
  const server = getServer(systemType);
  const ext = useMemo(() => ({ filter: { ...filter }, type: 0 }), [filter]);
  const queryWithExt = useMemo(
    () => ({ ...queryCondition, ...query }),
    [query, queryCondition]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    defaultValue: [],
    debugId: "airlineTable",
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext,
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      query: queryWithExt,
      ext: { ...ext },
    });
  }, [chartTableCode, doFetch, ext, moduleCode, queryWithExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Refetch refetch={refetch} />;
  }
  if (isEmpty(data)) {
    return <Empty />;
  }

  return (
    <div
      id="analysisTable"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <div
        style={{
          fontSize: "120%",
          fontWeight: "bold",
        }}
      >
        {getSharkText("tranfer.t_routes_details.name")}
      </div>
      <CustomTable
        useCompare={useCompare}
        dataSource={data}
        searchkeys={["dport", "aport", "dportName", "aportName"]}
        size="small"
        query={query}
        searchPlaceholder={getSharkText("key.input_to_search_dep_arr")}
        moduleCode={moduleCode}
        chartTableCode={chartTableCode}
      />
    </div>
  );
};

export default TransferTable;
