import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { DATE_FORMAT } from "Constants";
import { AggCode, IDownloadHeader } from "Interface";
import moment, { Moment } from "moment";
import { showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { FlightSegmentTable } from "@ctrip/flt-bi-flightai-airlines";

const AVG_PRICE_TITLE = getSharkText("config_page_average_deal_price");
const PER_CLASS_TITLE = getSharkText("config_page_income_per_class");
const APPORTION_NAME = getSharkText("apportion");

/**
 * 计算显示的时间周期, 如果时间周期结束时间在endDate之后, 则使用endDate;
 * @param agg 周期
 * @param value 当前区域其实时间
 * @param startDate 数据总的起始时间
 * @param endDate 数据中的结束时间
 */
const getDateRange = (
  agg: AggCode,
  value: string,
  startDate: string,
  endDate: string
): string => {
  const mValue = moment(value);
  const mStart = moment(startDate);
  const mEnd = moment(endDate);
  const rangeStart: string = (mValue.isBefore(mStart) ? mStart : mValue).format(
    DATE_FORMAT
  );
  let mEndRange: Moment = moment();
  switch (agg) {
    case AggCode.week:
      mEndRange = moment(value).endOf("week");
      break;
    case AggCode.month:
      mEndRange = moment(value).endOf("month");
      break;
    case AggCode.year:
      mEndRange = moment(value).endOf("year");
      break;
  }
  if (mEndRange.isAfter(mEnd)) {
    mEndRange = mEnd;
  }
  return `${rangeStart} ~ ${mEndRange.format(DATE_FORMAT)}`;
};

export const getColumns = (
  aggCode: AggCode,
  /**
   * 是否往返合计
   */
  rt: boolean,
  startDate: string,
  endDate: string,
  groups: string[]
): Array<IDownloadHeader<FlightSegmentTable>> => {
  const allCols: Array<
    IDownloadHeader<FlightSegmentTable> & { shouldCellShow?: () => boolean }
  > = [
    {
      title: getSharkText("charts.date_of_departure"),
      dataIndex: "takeoffDate",
      width: 120,
      sorter: (r1, r2) => r1.takeoffDate?.localeCompare(r2.takeoffDate) || 0,
      render: (val: string) => {
        if (aggCode === AggCode.day) {
          return moment(val).format(DATE_FORMAT);
        }
        if (aggCode === AggCode.total) {
          return `${startDate} ~ ${endDate}`;
        }
        return getDateRange(aggCode, val, startDate, endDate);
      },
    },
    {
      title: "班期",
      dataIndex: "schedule",
      sorter: (r1, r2) => r1.schedule?.localeCompare(r2.schedule) || 0,
    },
    {
      title: "航段",
      dataIndex: "dCode",
      width: 170,
      sorter: (r1, r2) => r1.dCode?.localeCompare(r2.dCode) || 0,
      render: (v, r) => {
        const sep = rt ? "=" : "-";
        const d = `${r.dName}${
          r.dType === 5 ? `/${r.dCode}` : r.dType === 0 ? "*" : ""
        }`;
        const a = `${r.aName}${
          r.aType === 5 ? `/${r.aCode}` : r.aType === 0 ? "*" : ""
        }`;
        return `${d}${sep}${a}`;
      },
    },
    {
      title: getSharkText("config_page_PaAiFlCoSeTa_segment_count"),
      dataIndex: "segmentCount",
      sorter: (r1, r2) => (r1.segmentCount > r2.segmentCount ? 1 : -1),
      render: (v) => showNum(v),
    },
    {
      title: getSharkText("config_page_flight_volume"),
      dataIndex: "flightCount",
      sorter: (r1, r2) => (r1.flightCount > r2.flightCount ? 1 : -1),
      render: (v) => showNum(v),
    },
    {
      title: getSharkText("config_page_total_seats"),
      dataIndex: "allSeats",
      sorter: (r1, r2) => (r1.allSeats > r2.allSeats ? 1 : -1),
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText("config_page_total_seats")}(${APPORTION_NAME})`,
      dataIndex: "allSeatsApportion",
      sorter: (r1, r2) =>
        r1.allSeatsApportion > r2.allSeatsApportion ? 1 : -1,
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: getSharkText("config_page_seats_per_flight"),
      dataIndex: "seatsPerFlight",
      sorter: (r1, r2) => (r1.seatsPerFlight > r2.seatsPerFlight ? 1 : -1),
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText(
        "config_page_seats_per_flight"
      )}(${APPORTION_NAME})`,
      dataIndex: "seatsPerFlightApportion",
      sorter: (r1, r2) =>
        r1.seatsPerFlightApportion > r2.seatsPerFlightApportion ? 1 : -1,
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: getSharkText("config_page_passenger_number"),
      dataIndex: "persons",
      sorter: (r1, r2) => (r1.persons > r2.persons ? 1 : -1),
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText(
        "config_page_passenger_number"
      )}(${APPORTION_NAME})`,
      dataIndex: "personsApportion",
      sorter: (r1, r2) => (r1.personsApportion > r2.personsApportion ? 1 : -1),
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: getSharkText("config_page_persons_per_flight"),
      dataIndex: "personsPerFlight",
      sorter: (r1, r2) => (r1.personsPerFlight > r2.personsPerFlight ? 1 : -1),
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText(
        "config_page_persons_per_flight"
      )}(${APPORTION_NAME})`,
      dataIndex: "personsPerFlightApportion",
      sorter: (r1, r2) =>
        r1.personsPerFlightApportion > r2.personsPerFlightApportion ? 1 : -1,
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: getSharkText("key.loadfactor"),
      dataIndex: "lf",
      sorter: (r1, r2) => (r1.lf > r2.lf ? 1 : -1),
      render: (v) => showNum(v * 100, "percentage"),
      downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText("key.loadfactor")}(${APPORTION_NAME})`,
      dataIndex: "lfApportion",
      sorter: (r1, r2) => (r1.lfApportion > r2.lfApportion ? 1 : -1),
      render: (v) => showNum(v * 100, "percentage"),
      downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: (
        <>
          {AVG_PRICE_TITLE}
          <Popover
            content={getSharkText("config_page_calculated_by_ctrip_big_data")}
          >
            <InfoCircleOutlined />
          </Popover>
        </>
      ),
      downloadTitle: AVG_PRICE_TITLE,
      dataIndex: "avgPrice",
      sorter: (r1, r2) => (r1.avgPrice > r2.avgPrice ? 1 : -1),
      render: (v) => showNum(v),
    },
    {
      title: getSharkText("config_page_average_flight_distance"),
      dataIndex: "avgDistance",
      sorter: (r1, r2) => (r1.avgDistance > r2.avgDistance ? 1 : -1),
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText(
        "config_page_average_flight_distance"
      )}(${APPORTION_NAME})`,
      dataIndex: "avgDistanceApportion",
      sorter: (r1, r2) =>
        r1.avgDistanceApportion > r2.avgDistanceApportion ? 1 : -1,
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: (
        <>
          {PER_CLASS_TITLE}
          <Popover
            content={getSharkText(
              "config_page_calculated_by_the_number_of_people_per_class_and_the_average_transaction_price"
            )}
          >
            <InfoCircleOutlined />
          </Popover>
        </>
      ),
      downloadTitle: PER_CLASS_TITLE,
      dataIndex: "incomePerFlight",
      sorter: (r1, r2) => (r1.incomePerFlight > r2.incomePerFlight ? 1 : -1),
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${PER_CLASS_TITLE}(${APPORTION_NAME})`,
      dataIndex: "incomePerFlightApportion",
      sorter: (r1, r2) =>
        r1.incomePerFlightApportion > r2.incomePerFlightApportion ? 1 : -1,
      render: (v) => showNum(v),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: getSharkText("config_page_seat_kilometer_income"),
      dataIndex: "incomePerKiloTransportCapacity",
      sorter: (r1, r2) =>
        r1.incomePerKiloTransportCapacity > r2.incomePerKiloTransportCapacity
          ? 1
          : -1,
      render: (v) => showNum(v, "num", 3),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText(
        "config_page_seat_kilometer_income"
      )}(${APPORTION_NAME})`,
      dataIndex: "incomePerKiloTransportCapacityApportion",
      sorter: (r1, r2) =>
        r1.incomePerKiloTransportCapacityApportion >
        r2.incomePerKiloTransportCapacityApportion
          ? 1
          : -1,
      render: (v) => showNum(v, "num", 3),
      shouldCellShow: () => groups.includes("apportion"),
    },
    {
      title: getSharkText("config_page_passenger_kilometer_income"),
      dataIndex: "incomePerKiloPassengerTraffic",
      sorter: (r1, r2) =>
        r1.incomePerKiloPassengerTraffic > r2.incomePerKiloPassengerTraffic
          ? 1
          : -1,
      render: (v) => showNum(v, "num", 3),
      shouldCellShow: () => groups.includes("default"),
    },
    {
      title: `${getSharkText(
        "config_page_passenger_kilometer_income"
      )}(${APPORTION_NAME})`,
      dataIndex: "incomePerKiloPassengerTrafficApportion",
      sorter: (r1, r2) =>
        r1.incomePerKiloPassengerTrafficApportion >
        r2.incomePerKiloPassengerTrafficApportion
          ? 1
          : -1,
      render: (v) => showNum(v, "num", 3),
      shouldCellShow: () => groups.includes("apportion"),
    },
  ];
  return allCols.filter((c) => !c.shouldCellShow || c.shouldCellShow());
};
