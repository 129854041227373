import React, { ReactNode } from "react";
import { Spin } from "antd";
import {
  genSeriesByDimensions,
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  toThousands,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode, ISeries } from "Interface";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  day: string;
  value: number;
  compareValue: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  loading: boolean;
  aggCode: AggCode;
  startDate: string;
  endDate: string;
  isDemo: boolean;
  totalSeries?: ISeries[];
  legendHelp?: Record<string, string | ReactNode>;
  ext?: Partial<TransferPolicyBarlineRequestType>;
}

const defaultSeries = [
  {
    type: "line",
    encode: { x: "day", y: "value" },
    lineStyle: {
      width: 3,
    },

    name: getSharkText("config_page_search_pv"),
    showSymbol: false,
    symbol: "circle",
  },
  {
    type: "line",
    encode: { x: "day", y: "compareValue" },
    lineStyle: {
      width: 3,
    },
    name: getSharkText("config_page_display_pv"),
    showSymbol: false,
    symbol: "circle",
  },
  {
    type: "bar",
    encode: { x: "day", y: "resultValue" },
    barMaxWidth: 20,
    name: getSharkText("config_page_display_rate"),
    yAxisIndex: 1,
  },
];

const BarCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const {
    data,
    height = 300,
    loading,
    aggCode,
    startDate,
    endDate,
    isDemo,
    totalSeries = defaultSeries,
    legendHelp,
    ext,
  } = props;

  const isZero = (val: number) => val === 0;
  const legendEx = legendHelp
    ? {
        tooltip: {
          show: true,
          formatter: (params: any) => {
            console.log(params);
            return ReactDOMServer.renderToString(
              <span>{legendHelp[params.name]}</span>
            );
          },
        },
      }
    : {};
  const option = {
    dataset: {
      source: data,
    },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
      ...legendEx,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          isDemo
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          let val = "";
          const encodeYColumnName = item.dimensionNames[item.encode.y[0]];
          if (item.componentSubType === "bar") {
            // if (item.componentSubType === 'line') {
            val = `${item.seriesName}: ${
              !isZero(item.data.resultValue) ? item.data.resultValue + "%" : "-"
            }`;
          } else {
            val = `${item.seriesName}: ${
              !isZero(item.value[encodeYColumnName])
                ? toThousands(item.value[encodeYColumnName])
                : "-"
            }`;
          }
          dom = dom + "<br />" + dot + val;
        });
        const prefix =
          ext?.axisType === 0
            ? getSharkText("charts.date_of_search")
            : getSharkText("charts.date_of_departure");
        dom = `${prefix}：` + day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    yAxis: [
      {
        type: "value",
        splitNumber: 3,
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        max: (val: any) => {
          const max =
            Math.abs(val.max) > Math.abs(val.min)
              ? Math.abs(val.max)
              : Math.abs(val.min);
          const num = _.round(max * 1.2) > 1 ? _.round(max * 1.2) : 1;
          return num;
        },
      },
      {
        position: "right",
        type: "value",
        splitLine: false,
        splitNumber: 3,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) => `${value}%`,
        },
        axisTick: {
          show: false,
        },
        max: (val: any) => val.max,
      },
    ],
    series:
      data && data.length
        ? genSeriesByDimensions(Object.keys(data[0]), totalSeries)
        : [],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default BarCharts;
