// Created by xh_zhu on 2021-03-11

import { Checkbox, Col, Row, Spin } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Refetch from "Components/Refetch";
import moment from "moment";
import { Area, BarLineData, IDownloadHeader, ISeries } from "Interface";
import { isEmpty, merge, round } from "lodash";
import {
  genSeriesByDimensions,
  isAirportMode,
  listMap2dataSet,
  openDownloadDialog,
  sheet2blob,
  useFetch,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { DATE_MINUTE_FORMAT } from "Constants";
import { getDownloadColumnHeader } from "Utils/downloadXLSX";
import EchartsReactBase from "Components/EchartsReactBase";
import DownloadBtn from "../../../../Components/DownloadBtn";

/**
 * Component description
 * 执行率模块, 包含模块名称, 可选择对比周期, 一个趋势图
 */

interface ICarryOutRateChartsProps {
  title: string;
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  area: Area;
  height?: number;
  style?: object;
  ext: any;
  totalSeries: ISeries[];
  downloadColumns?: IDownloadHeader[];
  chartsOption?: any;
  onCompareChange?: (v: number) => void;
}

const CarryOutRateCharts = (props: ICarryOutRateChartsProps): ReactElement => {
  const { title } = props;
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    area,
    style,
    ext: otherExt,
    totalSeries,
    chartsOption,
    downloadColumns,
    onCompareChange,
  } = props;
  const [globalState] = useGlobalState();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const [compareType, setCompareType] = useState<number>(-1);
  const [compareTypeIdx, setCompareTypeIdx] = useState<number[]>([]);
  const ext = useMemo(
    () => merge({}, { area, aggCode: 0 }, otherExt),
    [area, otherExt]
  );
  const queryWithExt = useMemo(
    () => ({ ...query, compareType }),
    [compareType, query]
  );
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext,
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      query: queryWithExt,
      ext: {
        ...ext,
      },
    });
  }, [doFetch, ext, queryWithExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    let rst = -1;
    if (compareTypeIdx.length) {
      rst = compareTypeIdx[0];
    }
    setCompareType(rst);
    if (onCompareChange) {
      onCompareChange(rst);
    }
  }, [compareTypeIdx, onCompareChange]);

  const sortedData = useMemo(() => {
    const sortDate = data.sort((a: any, b: any) =>
      moment(a.day).isBefore(moment(b.day)) ? -1 : 1
    );
    const dateStringData = sortDate.map((item: BarLineData) => {
      item.day = moment(item.day).format("YYYY-MM-DD");
      return item;
    });
    return dateStringData;
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }
  if (isLoading) {
    return <Spin />;
  }

  // 只能单选或不选
  const handleCompareChange = (v: any[]) => {
    let tmp = compareTypeIdx;
    // 单选
    if (v.length === 2) {
      tmp = v.filter((n: number) => n !== compareType);
      setCompareTypeIdx(tmp);
    } else {
      setCompareTypeIdx(v);
    }
  };

  const dataSource = listMap2dataSet(sortedData);
  const dimensions = dataSource.length ? dataSource[0] : [];
  const option = merge(
    {},
    {
      dataset: {
        dimensions,
        source: dataSource,
      },
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const dayIndex = params[0].dimensionNames.indexOf("day");
          const day = moment(params[0].data[dayIndex]).format(
            DATE_MINUTE_FORMAT
          );
          let dom = "";
          if (isEmpty(dom)) {
            return null;
          }
          dom = day + dom;
          return dom;
        },
      },
      xAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
      },
      yAxis: [
        {
          type: "value",
          splitLine: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          type: "value",
          min: (value: any) => {
            return round(value.min * 0.95);
          },
          splitLine: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: "{value}%",
          },
        },
      ],
      series: genSeriesByDimensions(dimensions, totalSeries),
    },
    chartsOption
  );

  const handleDownload = () => {
    if (!downloadColumns) {
      return;
    }
    const sheetData: any = [];
    data.forEach((item: any, idx: number) => {
      const obj: any = {};
      downloadColumns.forEach((col: IDownloadHeader) => {
        const val = item[col.dataIndex];
        const t = getDownloadColumnHeader(col);
        obj[t] = col.downloadFormatter
          ? col.downloadFormatter(val, item, idx)
          : col.render
          ? col.render(val, item, idx)
          : val;
      });
      sheetData.push(obj);
    });
    const sheet = XLSX.utils.json_to_sheet(sheetData);
    const blob = sheet2blob(sheet);
    openDownloadDialog(blob, `${title}.xlsx`);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <h3 style={{ color: "#000000d9", fontWeight: "bold" }}>{title}</h3>
        </Col>
        <Col>
          <Checkbox.Group
            value={compareTypeIdx}
            style={{ width: "100%" }}
            onChange={handleCompareChange}
          >
            <span>{getSharkText("key.comparison_period.flightplan")}</span>
            <Checkbox id="dateCompare" value={1}>
              {getSharkText("key.last_week.checkbox")}
            </Checkbox>
            <Checkbox id="increase" value={2}>
              {getSharkText("key.last_month.checkbox")}
            </Checkbox>
          </Checkbox.Group>
        </Col>
        {downloadColumns && downloadColumns.length ? (
          <Col>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        ) : undefined}
      </Row>
      <Row style={{ flexFlow: "column" }}>
        <EchartsReactBase
          style={{ margin: "0 -20px -20px", ...style }}
          option={option}
          notMerge
          theme="default"
        />
      </Row>
    </div>
  );
};
export default CarryOutRateCharts;
