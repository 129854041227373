import React from "react";
import { IDownloadHeader, IDownloadHeaderPro } from "Interface";
import moment from "moment";
import { DATE_MINUTE_FORMAT } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

type statusKey = 0 | 1 | 2;

export const StatusName: Record<statusKey, string> = {
  0: getSharkText("config_page_wait_approve"),
  1: getSharkText("config_page_pass"),
  2: getSharkText("config_page_refuse"),
};

export const GenderName: Record<number, string> = {
  0: getSharkText("custom_profile.page.gender.male"),
  1: getSharkText("custom_profile.page.gender.female"),
};

export const OperationName: Record<number, string> = {
  0: getSharkText("key.add.button"),
  1: getSharkText("config_page_modify"),
  2: getSharkText("config_page_delete"),
};

export interface INewDataType {
  /**
   * 	报名ID
   */
  regId?: number;
  /**
   * 	报名年份
   */
  regYear: number;
  /**
   * 	单位名称
   */
  unitName: string;
  /**
   * 	姓名
   */
  name: string;
  /**
   * 	职务
   */
  job: string;
  /**
   * 	性别 0=男 1=女
   */
  sex: number;
  /**
   * 	手机国家代码
   */
  mobilePrefix: string;
  /**
   * 	手机
   */
  mobile: string;
  /**
   * 	邮箱
   */
  mail: string;
  /**
   * 	省
   */
  province: string;
  /**
   * 	城市
   */
  city: string;
  /**
   * 	关注内容
   */
  content: string;
  /**
   * 	报名时间
   */
  registerDate: string;
  /**
   * 	报名状态0=待审核 1=通过 2=拒绝
   */
  status: statusKey;
  /**
   * 	维护人
   */
  maintainer: string;
  /**
   * 	所在大区
   */
  region: string;
  /**
   * 	单位类型 0=不区分 1=机场 2=航司 3=其他
   */
  unitType: number;
  /**
   * 	备注
   */
  remark: string;
  /**
   * 	历史邮件时间
   */
  mailTime: string;
  /**
   * 	最后一次邮件内容
   */
  mailContent: string;
}

export interface IDataType extends INewDataType {
  /**
   * 	报名ID
   */
  regId: number;
}

export const columns: Array<IDownloadHeaderPro<IDataType>> = [
  {
    title: getSharkText("config_page_name"),
    dataIndex: "name",
    width: 120,
    fixed: true,
  },
  {
    title: getSharkText("config_page_unit"),
    dataIndex: "unitName",
    width: 120,
    fixed: true,
  },
  {
    title: getSharkText("config_page_job"),
    dataIndex: "job",
  },
  {
    title: getSharkText("custom_profile.page.gender"),
    dataIndex: "sex",
    valueType: "select",
    width: 50,
    valueEnum: {
      "0": {
        text: getSharkText("custom_profile.page.gender.male"),
        status: "Success",
      },
      "1": {
        text: getSharkText("custom_profile.page.gender.female"),
        status: "Error",
      },
    },
    downloadFormatter: (val: number) => GenderName[val],
  },
  {
    title: getSharkText("config_page_mobile"),
    dataIndex: "mobile",
    width: 130,
  },
  {
    title: getSharkText("config_page_email"),
    dataIndex: "mail",
  },
  {
    title: getSharkText("config_page_current_province"),
    dataIndex: "province",
  },
  {
    title: getSharkText("config_page_current_city"),
    dataIndex: "city",
  },
  {
    title: getSharkText("config_page_register_time"),
    dataIndex: "registerDate",
    editable: false,
    width: 135,
    render: (val: any) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_status"),
    key: "status",
    dataIndex: "status",
    valueType: "select",
    width: 80,
    valueEnum: {
      "0": {
        text: getSharkText("config_page_wait_approve"),
        status: "Default",
      },
      "1": { text: getSharkText("config_page_pass"), status: "Success" },
      "2": { text: getSharkText("config_page_refuse"), status: "Error" },
    },
    downloadFormatter: (val: number) => StatusName[val as statusKey],
  },
  {
    title: getSharkText("config_page_maintainer"),
    dataIndex: "maintainer",
    width: 70,
  },
  {
    title: getSharkText("config_page_region"),
    dataIndex: "region",
    width: 70,
  },
  {
    title: getSharkText("config_page_unit_type"),
    dataIndex: "unitType",
    width: 70,
  },
  {
    title: getSharkText("config_page_remark"),
    dataIndex: "remark",
    width: 120,
  },
  {
    title: getSharkText("config_page_history_email_send_time"),
    dataIndex: "mailTime",
    editable: false,
  },
  {
    title: getSharkText("config_page_recent_email_send_content"),
    dataIndex: "mailContent",
    editable: false,
  },
  {
    title: getSharkText("config_page_content_to_hear_on_forum"),
    dataIndex: "content",
  },
  {
    title: getSharkText("config_page_operation"),
    dataIndex: "actions",
    width: 150,
    fixed: "right",
    valueType: "option",
    render: (text, record: IDataType, _, action) => [
      <a
        key="editable"
        onClick={() => {
          action?.startEditable?.(record.regId);
        }}
      >
        {getSharkText("config_page_edit")}
      </a>,
    ],
  },
];

export interface ILogType {
  key: string | number;
  /**
   * 	日志ID
   */
  logId: number;
  /**
   * 	操作人
   */
  logUser: string;
  /**
   * 	日志时间
   */
  logDate: string;
  /**
   * 	操作类型 0=新增 1=修改 2=删除
   */
  logType: number;
  /**
   * 	报名年份
   */
  regYear: number;
  /**
   * 	姓名
   */
  name: string;
  /**
   * 	手机国家代码
   */
  mobilePrefix: string;
  /**
   * 	手机
   */
  mobile: string;
  /**
   * 	报名状态0=待审核 1=通过 2=拒绝
   */
  status: number;
  /**
   * 	维护人
   */
  maintainer: string;
  /**
   * 	所在大区
   */
  region: string;
}

export const logColumns: Array<IDownloadHeader<ILogType>> = [
  {
    title: getSharkText("config_page_modifier"),
    dataIndex: "logUser",
  },
  {
    title: getSharkText("config_page_modified_time"),
    dataIndex: "logDate",
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_operation"),
    dataIndex: "logType",
    render: (val: number) => OperationName[val],
  },
  {
    title: getSharkText("config_page_name"),
    dataIndex: "name",
  },
  {
    title: getSharkText("config_page_mobile"),
    dataIndex: "mobile",
  },
  {
    title: getSharkText("config_page_status"),
    dataIndex: "status",
    render: (val: statusKey): string => {
      return StatusName[val];
    },
  },
  {
    title: getSharkText("config_page_maintainer"),
    dataIndex: "maintainer",
  },
  {
    title: getSharkText("config_page_region"),
    dataIndex: "region",
  },
];
