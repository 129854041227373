import React, { ReactElement, useEffect, useState } from "react";
import { Cascader, Input, Select } from "antd";
import useGlobal from "Store";
import { Area } from "Interface";
import { getModuleNameFromPath, getModuleStatus } from "Utils";
import _ from "lodash";
import { DefaultOptionType, SingleValueType } from "rc-cascader/lib/Cascader";
import { useLocation } from "react-router-dom";
import { useFetch } from "Utils/useFetch";
import { canSelectAirports } from "Utils/global";

const { Option } = Select;

/**
 * Component description
 *
 */

interface IProps {
  airports: Area[];
  onChange: (airport: Area) => void;
}

const AirportSingleSelector = (props: IProps): ReactElement => {
  const { airports, onChange } = props;
  const location = useLocation();
  const [area, setArea] = useState<Area[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, userInfo, systemType, moduleConfig } = globalState;
  const { airportName } = queryCondition;
  const isCtripOrCanSelectAirports = canSelectAirports(userInfo, moduleConfig);
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const [{ data }, doFetch] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
    lazey: true,
  });

  useEffect(() => {
    if (isCtripOrCanSelectAirports) {
      doFetch({
        ext: {
          type: 1,
        },
      });
    }
  }, [doFetch, isCtripOrCanSelectAirports]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setArea(data);
    }
  }, [data]);

  const handleSelectAirport = (value: string, option: any) => {
    onChange({
      areaName: option.props.label,
      areaCode: option.props.value,
      areaType: 5,
    });
  };

  const onCascaderChange = (
    value: SingleValueType,
    selectedOptions?: DefaultOptionType[]
  ) => {
    if (selectedOptions && selectedOptions.length) {
      const { areaCode, areaName } =
        selectedOptions[selectedOptions.length - 1];
      onChange({
        areaName,
        areaCode,
        areaType: 5,
      });
    }
  };

  const showSearch = {
    filter: (value: string, path: any[]) =>
      path.some(
        (item) =>
          item.areaType === 5 &&
          (item.areaCode.includes(value.toUpperCase()) ||
            item.areaName.includes(value))
      ),
    limit: 10,
    render: (value: string, path: any[]) => (
      <span>
        {path[path.length - 1].areaName}({path[path.length - 1].areaCode})
      </span>
    ),
  };

  const displayRender = (labels: string[]) => (
    <span>{labels[labels.length - 1]}</span>
  );

  return (
    <>
      {isDemo ? (
        <Input style={{ minWidth: 200 }} disabled value="Demo" />
      ) : !isCtripOrCanSelectAirports ? (
        <Select
          id="toolbarAirportSelect"
          showSearch
          style={{ minWidth: 200 }}
          value={airportName}
          onChange={handleSelectAirport}
        >
          {airports.map((item) => (
            <Option
              key={item.areaCode}
              value={item.areaCode}
              label={item.areaName}
            >
              {item.areaName}
            </Option>
          ))}
        </Select>
      ) : (
        <Cascader
          className="toolbar-cascader"
          options={area.length > 0 && area[0].children ? area[0].children : []}
          placeholder={airportName}
          expandTrigger="hover"
          fieldNames={{
            label: "areaName",
            value: "areaCode",
            children: "children",
          }}
          onChange={onCascaderChange}
          showSearch={showSearch}
          displayRender={displayRender}
          notFoundContent="No Data"
        />
      )}
    </>
  );
};
export default AirportSingleSelector;
