import { Button, Col, InputNumber, message, Row, Table } from "antd";
import { IDownloadHeader } from "Interface";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import AdjustCabin, { CabinAdjustHandle } from "./Adjust";
import { ubt, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { TRACE_ID } from "Page/AI/FlightManage/FlightManageCommon";
import useRefFunc from "Utils/useRefFunc";
import { CloseOutlined, UndoOutlined } from "@ant-design/icons";

import "./index.scss";
import {
  FlightInfoDetailWithKey,
  IntervalInventoryInfo,
  UpdateInventoryInfoRequest,
} from "../../interface";
import useGlobalState from "Store";
import { MODULE_CODE } from "../..";
import moment from "moment";

/**
 * Component description
 * 黑屏面板
 */
const infoColumns: Array<IDownloadHeader<FlightInfoDetailWithKey>> = [
  {
    title: getSharkText("key.flightno.name"),
    dataIndex: "flightno",
    width: 70,
    render: (val: string) => (
      <span style={{ fontWeight: 500, color: "var(--main)" }}>{val}</span>
    ),
  },
  {
    title: getSharkText("charts.date_of_departure"),
    dataIndex: "takeoffdate",
    width: 100,
  },
  {
    title: getSharkText("config_page_days_before_departure"),
    dataIndex: "takeoffdate",
    key: "daysBeforeDeparture",
    render: (val) => {
      const days = moment(val).diff(moment().startOf("day"), "days");
      return days;
    },
    width: 120,
  },
  {
    title: "通用票台",
    dataIndex: "supplierId",
    width: 60,
  },
];
interface IProps {
  record: FlightInfoDetailWithKey;
  closeRoPanel: () => void;
  refresh: () => void;
}

const BlackScreen = (props: IProps): ReactElement => {
  const { record, closeRoPanel, refresh } = props;
  const [globalState] = useGlobalState();
  const intervals = useMemo(() => {
    return record.intervalInventoryInfoList || [];
  }, [record.intervalInventoryInfoList]);
  const [tableRow, setTableRow] = useState<FlightInfoDetailWithKey[]>([record]);
  const [editingTotal, setEditingTotal] = useState<number | null>(null);
  const cabinEditorRef = useRef<CabinAdjustHandle>(null);

  useEffect(() => {
    setTableRow([record]);
    setEditingTotal(null);
  }, [record]);

  const [{}, doSave] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "none",
    },
    lazey: true,
    onSuccess: (proxyRes) => {
      if (proxyRes.ResponseStatus.Ack === "Success") {
        const res = JSON.parse(proxyRes.responseBody);
        if (res.ResponseStatus.Ack === "Success" && res.resultCode === 0) {
          message.success("操作成功");
          refresh();
          return;
        }
      }
      message.error("操作失败");
    },
    onError: () => {
      message.error("操作失败");
    },
  });

  const changeTotal = useRefFunc((val: number) => {
    setEditingTotal(val);
    setTableRow([record]);
  });

  const summaryColumns = useMemo(() => {
    const tmp: Array<IDownloadHeader<FlightInfoDetailWithKey>> = [
      {
        title: "总库存数量",
        dataIndex: "totalInventory",
        render: (v, r: FlightInfoDetailWithKey) => {
          const oriVal = r.totalInventory;
          const val = editingTotal ?? oriVal;
          const color = editingTotal === oriVal ? "" : "red";
          return (
            <InputNumber
              className="adjust-cabin-number"
              size="small"
              style={{
                width: 60,
                color,
                transition: "all 0.0s linear",
              }}
              min={`${0}`}
              value={val}
              onChange={(newTotal) => changeTotal(Number(newTotal))}
              formatter={() => {
                if (val !== oriVal) {
                  return `${val}(${oriVal})`;
                }
                return `${val}`;
              }}
              parser={(v?: string) => {
                if (v) {
                  try {
                    return parseInt(v, 10);
                  } catch (e) {
                    return v;
                  }
                }
                return "0";
              }}
            />
          );
        },
      },
      {
        title: "剩余可售数量",
        dataIndex: "soldInventory",
        render: (value, r: FlightInfoDetailWithKey) => {
          const oriAllow = (r.totalInventory ?? 0) - (r.soldInventory ?? 0);
          if (editingTotal && editingTotal !== r.totalInventory) {
            const newAllow = editingTotal - (r.soldInventory ?? 0);
            if (newAllow !== oriAllow) {
              return (
                <span style={{ color: "red" }}>
                  {newAllow}({oriAllow})
                </span>
              );
            }
          }
          return oriAllow;
        },
      },
    ];
    return tmp;
  }, [changeTotal, editingTotal]);

  // 重置调仓面板
  const handleReset = () => {
    ubt(TRACE_ID.getName("layoutReset"));
    setEditingTotal(null);
    if (cabinEditorRef.current) {
      return cabinEditorRef.current.reset();
    }
  };

  const AdjustCabinMemo = useMemo(() => {
    return (
      <AdjustCabin
        ref={cabinEditorRef}
        record={record}
        intervals={intervals}
      ></AdjustCabin>
    );
  }, [intervals, record]);

  const validateInventory = useRefFunc(
    (total: number, inventoryList: IntervalInventoryInfo[]) => {
      const sum = inventoryList.reduce((acc, cur) => {
        return acc + (cur.totalInventory ?? 0);
      }, 0);
      return sum === total;
    }
  );

  const handleSave = useRefFunc(() => {
    if (cabinEditorRef.current) {
      const adjusting = cabinEditorRef.current.getAdjusting();
      const request: UpdateInventoryInfoRequest = {
        user: globalState.userInfo.user.uid,
        groupId: globalState.userInfo.unitList
          ? globalState.userInfo.unitList[0].id.toString()
          : "",
        groupName: globalState.userInfo.unitList
          ? globalState.userInfo.unitList[0].unitName
          : "",
        inventoryId: record.inventoryId,
        totalInventory: editingTotal ?? record.totalInventory,
        soldInventory: record.soldInventory,
        takeoffdate: record.takeoffdate,
        flightno: record.flightno,
        dport: record.dport,
        aport: record.aport,
        intervalInventoryInfoList: (record.intervalInventoryInfoList || []).map(
          (i) => {
            const adjust = adjusting.find((a) => a.code === i.code);
            return {
              ...i,
              totalInventory: adjust?.newTotalInventory ?? i.totalInventory,
              remainingInventory: adjust
                ? (adjust.newTotalInventory || 0) - (i.soldInventory || 0)
                : i.remainingInventory,
            };
          }
        ),
      };
      if (
        !validateInventory(
          editingTotal ?? record.totalInventory ?? 0,
          request.intervalInventoryInfoList || []
        )
      ) {
        message.error("库存之和与总库存数量不符，请检查！");
        return;
      }
      console.log("request: ", request);
      doSave({
        ext: {
          serviceCode: "30554",
          operation: "updateInventoryInfo",
          requestBody: JSON.stringify(request),
        },
      });
    }
  });

  return (
    <div className="ro-panel">
      <div
        style={{
          overflow: "auto",
        }}
      >
        <div
          className="ro-header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 0",
          }}
        >
          <div style={{ fontSize: 16, fontWeight: "bold" }}>
            {getSharkText("cabin_management")}
          </div>
          <div>
            <CloseOutlined onClick={closeRoPanel} />
          </div>
        </div>
        <div>
          <Table
            columns={infoColumns}
            dataSource={tableRow}
            pagination={false}
            size="small"
            bordered
          />
          <div
            style={{
              fontWeight: 600,
              padding: "15px 0 5px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span>{getSharkText("cabin_layout")}</span>
            <Button size="small" onClick={handleReset}>
              <UndoOutlined
                style={{
                  transform: "rotate(180deg)",
                  position: "relative",
                  top: -2,
                }}
              />
              {getSharkText("config_page_restore")}
            </Button>
          </div>
          <Table
            columns={summaryColumns}
            dataSource={tableRow}
            rowKey="key"
            size="small"
            pagination={false}
          />
          <div style={{ marginTop: 10 }}>
            <span
              style={{
                padding: "3px 10px",
                color: "var(--main)",
                display: "inline-block",
                borderBottom: "2px solid var(--main)",
              }}
            >
              {record.dport}-{record.aport}
            </span>
          </div>
          {AdjustCabinMemo}
          <Row style={{ margin: "10px 0" }} justify="space-between">
            <Col></Col>
            <Col>
              <Button type="primary" onClick={handleSave}>
                生效
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default BlackScreen;
