import React, { useEffect, useImperativeHandle, useState } from "react";
import { DatePicker, message } from "antd";
import { Moment } from "moment";
import { getDateRange, isHistoryToFuture } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { IDateMode } from "Interface";

interface IDateRangePickerProps {
  name?: string;
  defaultDateMode: IDateMode;
  onChange?: (range: Moment[]) => void;
  // 限制时间范围选择不能同时包含历史日期和未来日期, 默认为false
  noHistoryToFuture?: boolean;
}

const { RangePicker } = DatePicker;

const Picker = (props: IDateRangePickerProps, ref: any) => {
  const { defaultDateMode, onChange, name, noHistoryToFuture = false } = props;
  const range = getDateRange(defaultDateMode);
  const [dateRange, setDateRange] = useState<any>(range);
  const [isShowPicker, setIsShowPicker] = useState(false);
  useEffect(() => {
    if (onChange) {
      onChange(range || []);
    }
  }, []);
  useImperativeHandle(ref, () => ({
    hello: () => {
      alert("Child component function called");
    },
    setValue: (val: Moment[]) => {
      setDateRange(val);
    },
  }));

  const handleSelectRange = (dates: any) => {
    if (noHistoryToFuture && isHistoryToFuture(dates)) {
      message.error(getSharkText("key.choose_past_or_future_time.hint"));
    } else {
      setDateRange(dates);
      // setIsShowPicker(false) // 因为升级antd后, 每次操作都会触发onchange,所以这里的关闭事件取消
      if (onChange) {
        onChange(dates);
      }
    }
  };

  const handleOpenRange = (open: boolean) => {
    setIsShowPicker(open);
  };

  return (
    <>
      {name ? (
        <span className="search-area-filter-title">{name}：</span>
      ) : undefined}
      <RangePicker
        id="toolbarRangePicker"
        allowClear={false}
        value={dateRange}
        format="YYYY-MM-DD"
        onChange={handleSelectRange}
        open={isShowPicker}
        onOpenChange={handleOpenRange}
      />
    </>
  );
};
const DateRangePicker = React.forwardRef(Picker);
export default DateRangePicker;
