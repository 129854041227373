import { SortOrder } from "antd/lib/table/interface";
import {
  DATE_RANGE_GROUPS,
  EDateRangeGroupsNames,
  FlightROInfoType,
  IKeyLabelOption,
  IModuleConfig,
  IValueLabelOption,
  LegEntity,
  LegEntityExtendsSub,
  Module,
  Role,
  RoleType,
  SystemType,
  User,
  UserInfo,
  UserType,
} from "Interface";
import {
  Row as AiRow,
  Cell as AiCell,
  FDDatasetCol,
} from "@ctrip/flt-bi-flightai-base";
import moment, { Moment } from "moment";
import { IRouteItem } from "Page/AI/FlightManage/FlightManageInterface";
import useGlobalState, { IGlobalState } from "Store";
import {
  getModuleNameFromPath,
  getModule,
  isGroupUser,
  recursiveList,
  isAirportMode,
} from "Utils";
import {
  Header,
  StandardFilter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { isEmpty } from "lodash";
import {
  COMPARE_FILTER_KEY_FLAG,
  DEFAULT_AIRLINE,
  DEFAULT_AIRLINE_NAME,
  EMPTY_ARRAY,
} from "Constants";
import { getSharkText } from "./i18nGlobal";

export const redirectTo = (menuUrl: string): boolean => {
  if (menuUrl !== window.location.pathname) {
    console.log("redirect to location.replace url: ", menuUrl);
    window.location.replace(menuUrl);
    return true;
  }
  return false;
};
/**
 * 如果用户设置了首页, 则跳转到首页, 如果跳转了, 返回true, 如果没有跳转, 返回false
 *
 * @param modules
 */
export const redirectHome = (modules: Module[]): boolean => {
  // 首页跳转
  const homeModules: Module[] = modules.filter((m: Module) => m.homePage === 1);
  if (
    homeModules.length &&
    homeModules[0].menuUrl !== "#" &&
    window.location.pathname === "/dashboard"
  ) {
    console.log("jump location");
    // 如果有机场版的首页, 优先跳转机场版首页
    const airportHome = homeModules.find(
      (m: Module) => !m.moduleType || m.moduleType === 1
    );
    if (airportHome) {
      return redirectTo(airportHome.menuUrl);
    } else {
      // 没有机场版首页, 跳转到第一个首页
      return redirectTo(homeModules[0].menuUrl);
    }
    // 如果用户没有设置首页, 跳转到用户拥有权限的第一个页面
  } else {
    console.log("jump first module");
    const homePageModule = modules.find((m) => m.menuUrl === "/dashboard");
    const firstModule = modules.find((m) => m.menuUrl !== "#");
    if (!homePageModule || (homePageModule.moduleStatus === 3 && firstModule)) {
      return redirectTo(firstModule?.menuUrl || "");
    }
  }
  return false;
};

/**
 * 验证当前页面是不是demo模式
 *
 * @param pathName
 * @param systemType
 * @param moduleList
 */
export const validateDemo = (
  pathName: string,
  systemType: SystemType,
  moduleList: Module[]
): boolean => {
  const moduleName = getModuleNameFromPath(pathName, systemType);
  const module = getModule(moduleName, moduleList);
  const isDemo = (module && module.moduleStatus === 0) || false;
  return isDemo;
};

export const getDateRangeGroupName = (
  url: string
): keyof typeof EDateRangeGroupsNames | undefined => {
  let name: keyof typeof EDateRangeGroupsNames;
  for (name in EDateRangeGroupsNames) {
    if (isNaN(Number(name))) {
      if (DATE_RANGE_GROUPS[name].includes(url)) {
        return name;
      }
    }
  }
};

/**
 * 部分页面需要统一时间跨度选择
 * @param url 页面url
 * @returns 其他页面已经生效的时间
 */
export const GetGroupDateRangeValue = (
  url: string
): [Moment, Moment] | undefined => {
  const groupName = getDateRangeGroupName(url);
  const [globalState] = useGlobalState();
  const { dateRangeInGroup } = globalState;
  if (groupName && dateRangeInGroup[groupName].length) {
    return dateRangeInGroup[groupName].map((m: string) => moment(m)) as [
      Moment,
      Moment
    ];
  }
};

/**
 * 将枚举值转为可遍历的名称数组, 例如{DAY=1,WEEK=2}转为["DAY", "WEEK"]
 * @param obj 枚举
 * @param type 类型
 * @returns 默认返回string类型数组, 如果需要数值类型, 第二个参数填写Number, 以后可能扩展其他类型
 */
export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O,
  type?: NumberConstructor
): K[] => {
  let rst: K[] = [];
  if (type) {
    rst = Object.keys(obj)
      .map(type)
      .filter((k) => !Number.isNaN(k)) as K[];
  } else {
    rst = Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
  }
  return rst;
};

/**
 * 根据一个值在对象中找到具有这个值的key, 只返回第一个属性, 没有找到返回undefined
 * @param obj 对象
 * @param value 值
 * @param keyType 是否需要对key的type做转换
 * @returns 默认返回string类型的key, 如果需要使用Number类型的key, 需要写KeyType
 */
export const findKeyByValue = <
  O extends Record<string, unknown>,
  K extends keyof O = keyof O
>(
  obj: O,
  value: unknown,
  keyType?: NumberConstructor
): K | undefined => {
  let keys = Object.keys(obj) as K[];
  if (keyType) {
    keys = keys.map(keyType) as K[];
  }
  const key = keys.find((k) => obj[k] === value);
  return key;
};

export const redirectLogin = (): void => {
  console.log("redirect login");
  window.location.replace("/login");
};

export const transRoute = (item: string | IRouteItem): string => {
  if (item === "*") {
    return item;
  }
  if (typeof item === "string") {
    return item ? `${item.substring(0, 3)}-${item.substring(3, 6)}` : "";
  } else if (typeof item === "object" && item) {
    return item.line
      ? `${item.line.substring(0, 3)}-${item.line.substring(3, 6)}`
      : "";
  } else {
    return "";
  }
};

export const isCtrip = (userInfo: UserInfo): boolean => {
  return userInfo.user.userType === UserType.internal;
};

/**
 * 用来存放与业务有关的通用方法, 也业务无关的/改善js功能的方法写在Utils中
 */
export const G = {
  /**
   * 将枚举值转为可遍历的值数组, 例如{DAY=1,WEEK=2}转为[1,2]
   * @param obj 枚举
   * @param type 类型
   * @returns 默认返回string类型数组, 如果需要数值类型, 第二个参数填写Number, 以后可能扩展其他类型
   */
  enumValues: (obj: Record<any, any>): number[] => {
    let rst: number[] = [];
    rst = Object.keys(obj)
      .map(Number)
      .filter((k) => !Number.isNaN(k));
    return rst;
  },
  /**
   * 通用表格排序方法, 可以处理字段为null或者undefined等情况
   */
  columnSort: (
    a: Record<string, any>,
    b: Record<string, any>,
    c: SortOrder,
    property: string
  ): number => {
    if (
      a[property] === undefined ||
      a[property] === null ||
      b[property] === undefined ||
      b[property] === null
    ) {
      return 0;
    } else if (typeof a[property] === "string") {
      return (a[property] as string).localeCompare(b[property]);
    } else if (typeof a[property] === "number") {
      return a[property] - b[property];
    } else {
      return 0;
    }
  },
  /**
   * options转换器, 使["a","b"]转换为[{label:"a", value:"a"}, {value:"b"...}]
   * @param source string或者配置
   * @returns 转换完的数组
   */
  formatBaseOptions: (
    source: Array<string | Record<string, string>>
  ): IValueLabelOption[] => {
    return source.map((s) => {
      if (typeof s === "string") {
        return { label: s, value: s };
      } else {
        return { label: s.label || s.value, value: s.value };
      }
    });
  },
  formatKeyOptions: (
    source: Array<string | number | Record<string, string>>
  ): IKeyLabelOption[] => {
    return source.map((s) => {
      if (typeof s === "object") {
        return { label: s.label || s.value, value: s.value, ...s };
      } else {
        return { label: s, value: s };
      }
    });
  },
};

enum CellType {
  IntV = "int",
  StringV = "str",
  DoubleV = "double",
  DateV = "ts",
  BooleanV = "bool",
}

const readCellValueMS = (
  cell: AiCell,
  type: CellType
): string | number | boolean | null => {
  if (cell.blank) {
    return null;
  }
  switch (type) {
    case CellType.IntV:
      return cell.intVal;
    case CellType.StringV:
      return cell.str;
    case CellType.DoubleV:
      return cell.doubleVal;
    case CellType.DateV:
      return cell.ts;
    case CellType.BooleanV:
      return cell.boolVal;
    default:
      console.warn(`data type: ${type} not defined `);
      return null;
  }
};

/** 仅调价监控在用 */
export const DataRow2ListMapMS = (
  rows: AiRow[],
  headers: Header[]
): Array<Record<string, unknown>> => {
  const rst: Array<Record<string, unknown>> = [];
  rows.forEach((row) => {
    const cells = row.cells;
    if (cells === null) return;
    const newRow: Record<string, unknown> = {};
    headers.forEach((header, i) => {
      const cell = cells[i];
      if (!cell || !header.name || !header.type) {
        return;
      }
      newRow[header.name] = readCellValueMS(
        cell,
        header.type as unknown as CellType
      );
    });
    rst.push(newRow);
  });
  return rst;
};

export const getEnv = (): "fat" | "fws" | "pro" => {
  return window.appConfig?.env === "##env##"
    ? "fat"
    : window.appConfig?.env ?? "fat";
};

export const isSuperAdmin = (user: User): boolean => {
  return user.userType === 1 && user.memberType === 1;
};

export const getDefaultAirline = (
  roles: Role | Role[] | undefined
): { airlines: string; airlinesName: string } => {
  const airlinesRole = Array.isArray(roles)
    ? roles.find((r) => r.roleType === RoleType.airline)
    : roles;
  return {
    airlines:
      airlinesRole && airlinesRole.permissionCode !== "*"
        ? airlinesRole.permissionCode?.split(",")[0]
        : DEFAULT_AIRLINE,
    airlinesName:
      airlinesRole && airlinesRole.permissionCode !== "*"
        ? airlinesRole.permissionName?.split(",")[0]
        : DEFAULT_AIRLINE_NAME,
  };
};

/** 是否可以选择机场 */
export const canSelectAirports = (
  userInfo: UserInfo,
  module: IModuleConfig
): boolean => {
  const roleList = userInfo.roleList;
  const airportsRole = roleList
    ? roleList.find((item: Role) => item.roleType === 1)
    : undefined;
  return (
    userInfo.user.userType === 1 ||
    module.allowAccessAllAirports ||
    airportsRole?.permissionCode === "*"
  );
};

/** 为StandardFilter生成filter Key, 会修改传入的filter */
export const genFilterKey = (
  filter: StandardFilter,
  parentKey: string[]
): void => {
  if (filter.and) {
    const cKey = parentKey.concat(["and."]);
    filter.and.key = parentKey
      .concat(["and", COMPARE_FILTER_KEY_FLAG])
      .join("");
    filter.and.filters.forEach((f) => {
      genFilterKey(f, cKey);
    });
  } else if (filter.or) {
    const cKey = parentKey.concat(["or."]);
    filter.or.key = parentKey.concat(["or", COMPARE_FILTER_KEY_FLAG]).join("");
    filter.or.filters.forEach((f) => {
      genFilterKey(f, cKey);
    });
  } else if (filter.not?.filter) {
    const cKey = parentKey.concat(["not."]);
    filter.not.key = parentKey
      .concat(["not", COMPARE_FILTER_KEY_FLAG])
      .join("");
    genFilterKey(filter.not.filter, cKey);
  } else {
    const keys = Object.keys(filter);
    if (keys.length === 1) {
      const value = Object.values(filter)[0];
      const columnName = value.field.split(".")[1];
      const filterKey = parentKey
        .concat([keys[0], COMPARE_FILTER_KEY_FLAG, columnName])
        .join("");
      value.key = filterKey;
    }
  }
};

/** 判断一个数据列是不是时间类型 */
export const isDateCol = (col: FDDatasetCol): boolean => {
  return col.jdbctype === "DATE" || JSON.parse(col.extra || "{}").isDate;
};

export const getMenuTree = (
  moduleList: Module[],
  user: User,
  isAirport: boolean,
  roleList?: Role[]
): Module[] => {
  if (!isEmpty(moduleList)) {
    const tmpList = moduleList.filter((module) => {
      // #region 特殊处理, 国航仅四个指定UID可以查看调价建议页面
      if (module.moduleCode === "dynamic_price_airlines") {
        // 吕鸿飞/李瑜/黄恺/孟宪津
        const CA_SPECIFY_UID = [
          "13901291682",
          "13922193319",
          "18620120902",
          "13901007053",
        ];
        const isCAUser = roleList ? isGroupUser(roleList, "CA") : false;
        if (isCAUser && !CA_SPECIFY_UID.includes(user.uid)) {
          return false;
        }
      }
      return true;
      // #endregion
    });
    const parent = recursiveList(tmpList);
    return parent.filter((module) => {
      switch (module.moduleType) {
        case 1:
          return isAirport;
        case 3:
          return !isAirport;
        default:
          return true;
      }
    });
  }
  return [];
};

export const genMenuTreeByGlobal = (globalState: IGlobalState): Module[] => {
  const { userInfo, systemType } = globalState;
  const { moduleList, user, roleList } = userInfo;
  const isAirport = isAirportMode(systemType);
  return getMenuTree(moduleList, user, isAirport, roleList);
};
/** 获取目录显示名 */
export const getModuleName = (item: Module): string => {
  return getSharkText(`menu_${item.moduleCode}`);
};

/**
 * 原始格式为子仓下包含多个航节, 需要展开并且按航节排序
 * @param data
 * @returns
 */
export const genSummaryData = (data: any): LegEntityExtendsSub[] => {
  if (data?.length) {
    const flight = data[0] as FlightROInfoType;
    const legs = flight.legs as LegEntity[];
    // 展开子仓和航节, 并按航节分组
    const expandGroupSubLeg = legs.reduce((total, leg) => {
      leg.sublegs.forEach((subleg) => {
        const newItem: LegEntityExtendsSub = {
          ...leg,
          ...subleg,
          key: `${leg.cabin}_${subleg.leg}`,
          editingPcfValue: subleg.pcfValue || 0,
          editingInd: subleg.ind.includes("L"),
          editingStopBooking: subleg.stopBooking ?? false,
        };
        const legArr = total.find((t) => t[0].leg === subleg.leg);
        if (legArr) {
          legArr.push(newItem);
        } else {
          total.push([newItem]);
        }
      });
      return total;
    }, [] as LegEntityExtendsSub[][]);
    // 将航节拼接
    const rst = expandGroupSubLeg.reduce((total, item) => {
      total = total.concat(item);
      return total;
    }, [] as LegEntityExtendsSub[]);
    return rst;
  }
  return EMPTY_ARRAY;
};

export const getDowOfDateRange = (dateRange: [Moment, Moment]): number[] => {
  const [start, end] = dateRange;
  const rst: number[] = [];
  for (let i = 0; i < 7; i++) {
    const date = start.clone().add(i, "days");
    if (date.isBetween(start, end, "day", "[]")) {
      rst.push(date.weekday());
    }
  }
  return rst;
};
