export interface IRouteGroup {
  // 组合ID, 新建时传空
  id: number | null;
  // 组合名称
  routeGroupName: string;
  // 航线列表, 逗号分隔
  route: string;
}

export const MODULE_CODE: any = {
  1: "",
  2: "flight_manage_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {},
  2: {
    query: "non",
    set: "non",
  },
};

export const QUERY_URL: any = {
  1: {},
  2: {
    query: "queryRouteGroup",
    set: "setRouteGroup",
  },
};
