import {
  Airline,
  Area,
  FlightReviewFlightFilter,
} from "@ctrip/flt-bi-flightai-airlines";
import { Button, Checkbox, Col, message, Row } from "antd";
import CheckButtons from "Components/CheckButtons/CheckButtons";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import SelectMultiFlight from "Components/SelectMultiFlight";
import SelectRT from "Components/SelectRT/SelectRT";
import Airlines from "Components/Toolbar/Airlines";
import { DATE_FORMAT, DEFAULT_FLIGHT_AREA, EMPTY_ARRAY } from "Constants";
import {
  AirportOrAirline,
  FlightArea,
  FlightClass,
  IDateMode,
  RangeObject,
  SystemType,
} from "Interface";
import { cloneDeep } from "lodash";
import { Moment } from "moment";
import React, { Key, ReactElement, useEffect, useMemo, useState } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { getDateRange, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { G, isCtrip } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { MODULE_CODE, QUERY_URL } from "../../fetchCode";

export interface ISearchAreaProps {
  onChange: (v: FlightReviewFlightFilter) => void;
}

const scheduleOptions = G.formatKeyOptions(
  Array.from({ length: 7 }, (v, i) => i + 1)
);

const defaultDateMode: IDateMode = "lastWeek";

/**
 * 航段数据-筛选器
 */
const SearchArea = (props: ISearchAreaProps): ReactElement => {
  const { onChange } = props;
  const [globalState, actions] = useGlobalState();
  const { userInfo, airlinesQueryCondition } = globalState;
  const { setAirlinesQueryConditionAttr } = actions;
  const [airlines, setAirlines] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange(defaultDateMode)
  );
  const [schedule, setSchedule] = useState<Key[]>([1, 2, 3, 4, 5, 6, 7]);
  const [flightArea, setFlightArea] = useState<FlightArea>(
    cloneDeep(DEFAULT_FLIGHT_AREA)
  );
  const [flightNO, setFlightNO] = useState<string[]>([]);
  const [aggFlightNO, setAggFlightNO] = useState<boolean>(false);
  const [splitSchedule, setSplitSchedule] = useState<boolean>(false);

  const [{ data }, doFetch] = useFetch({
    url: QUERY_URL.airlines,
    server: getServer(SystemType.airlines),
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
  });

  const collectAirlines = useMemo(() => {
    if (!data) {
      return EMPTY_ARRAY;
    } else {
      return data.map((d: Airline) => {
        return { code: d.airlinesCode, name: d.airlinesName };
      });
    }
  }, [data]);

  useEffect(() => {
    doFetch({ query: airlinesQueryCondition });
  }, [airlinesQueryCondition, doFetch]);

  const handleChangeDates = useRefFunc((v: Moment[]) => {
    if (v.length !== 2) return;
    const startDate = v[0].format(DATE_FORMAT);
    const endDate = v[1].format(DATE_FORMAT);
    setAirlinesQueryConditionAttr({
      startDate,
      endDate,
    });
  });

  const handleSearch = useRefFunc(() => {
    const tmpDate = dateRange?.filter((d) => d != null) as Moment[];
    if (!tmpDate || tmpDate.length < 2) {
      message.error(getSharkText("config_page_date_error"));
      return;
    }
    handleChangeDates(tmpDate);
    const filter: FlightReviewFlightFilter = {
      aggFlightNO: aggFlightNO ? 1 : 0,
      splitSchedule: splitSchedule ? 1 : 0,
      departArea: flightArea.departArea as Area,
      arriveArea: flightArea.arriveArea as Area,
      schedule: schedule as number[],
      flightNO,
      airlines,
    };
    onChange(filter);
  });

  useEffect(() => {
    handleSearch();
  }, []);

  const handleAirlinesChange = useRefFunc((v: AirportOrAirline) => {
    setAirlines(v.code.split(",").filter((f) => !!f));
  });

  return (
    <Row
      align="middle"
      gutter={[10, 20]}
      style={{ marginTop: 0, padding: "15px 0px" }}
    >
      {isCtrip(userInfo) ? (
        <Col>
          <span>{getSharkText("config_page_belong_airlines")}：</span>
          <Airlines />
        </Col>
      ) : undefined}
      <Col>
        <div>
          <span>{getSharkText("config_page_collect_airlines")}：</span>
          <Airlines
            allowEmpty={true}
            airlines={collectAirlines}
            ctripAirlines={collectAirlines}
            onChange={handleAirlinesChange}
          />
        </div>
      </Col>
      <Col>
        <DateRangePickerV2
          defaultDateMode={defaultDateMode}
          onChange={setDateRange}
          disableFuture
        />
      </Col>
      <Col>
        <CheckButtons
          options={scheduleOptions}
          value={schedule}
          onChange={setSchedule}
        />
      </Col>
      <Col>
        <SelectRT
          onChange={setFlightArea}
          useDomesticArea
          useExchange
          flightClass={FlightClass.Domestic}
        />
      </Col>
      <Col>
        <SelectMultiFlight
          value={flightNO}
          onChange={setFlightNO}
          queryUrl={QUERY_URL.flightNO}
          server={getServer(SystemType.airlines)}
          moduleCode={MODULE_CODE}
          isDemo={false}
          airlinesCode={airlines.join(",")}
          query={airlinesQueryCondition}
        />
      </Col>
      <Col>
        <Checkbox
          value={aggFlightNO}
          onChange={(e) => setAggFlightNO(e.target.checked)}
        >
          {getSharkText("config_page_flight_number_summary")}
        </Checkbox>
      </Col>
      <Col>
        <Checkbox
          value={splitSchedule}
          onChange={(e) => setSplitSchedule(e.target.checked)}
        >
          {getSharkText("config_page_split_schedule")}
        </Checkbox>
      </Col>
      <Col>
        <Button onClick={handleSearch} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default SearchArea;
