import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Divider,
  Empty,
  message,
  Modal,
  Select,
  SelectProps,
  Spin,
} from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobalState from "Store";
import { getServer } from "Service/server";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import { IRouteItem } from "Page/AI/FlightManage/FlightManageInterface";
import usePageContext from "Page/AI/FlightManage/FlightManageContext";
import RouteGroup from "./RouteGroup";
import { cloneDeep, groupBy, uniq } from "lodash";
import { AirlinesQueryCondition, IValueLabelOption } from "Interface";
import useRefFunc from "Utils/useRefFunc";
import { SwapOutlined } from "@ant-design/icons";
import { EMPTY_ARRAY } from "Constants";
import "./index.scss";
import { IRouteGroup } from "./RouteGroupCommon";

const defaultRouteList: string[] = [];
const transRoute = (item: string | IRouteItem) => {
  if (item === "*") {
    return item;
  }
  if (typeof item === "string") {
    return item ? `${item.substr(0, 3)}-${item.substr(3, 3)}` : "";
  } else if (typeof item === "object" && item) {
    return item.line
      ? `${item.line.substr(0, 3)}-${item.line.substr(3, 3)}`
      : "";
  } else {
    return "";
  }
};
export interface SelectMultiRouteProps extends SelectProps<any> {
  title?: string | false | ReactNode;
  routes: string | string[];
  setRoutes: (list: string[]) => void;
  onDataSourceChange?: (list: IRouteItem[]) => void;
  isDemo: boolean;
  width?: number | "auto";
  flights: string;
  /**
   * 查询后是否默认选中第一项, 默认为true
   */
  autoSelectFirst?: boolean;
  /**
   * 查询航班和航线的接口type, 1为机场航线, 4为城市航线
   */
  type?: number;
  /**
   * 是否启用自定义航线组合, 默认false
   */
  useGroup?: boolean;
  /**
   * 是否启用flightManage的page上下文, 如果启用, 则flightManage中任何一个组件均可直接调用和设置航线选择器的属性
   */
  useContext?: boolean;
  /**
   * 请求的URL
   */
  queryUrl?: string;
  /**
   * moduleCode
   */
  moduleCode?: string;
  /**
   * 额外查询参数
   */
  ext?: Record<string, any>;
  /**
   * 调试ID
   */
  debugId?: string;
  /**
   * 查询参数, 可以替换airlineQueryCondition, 部分不使用全局查询参数的场景使用
   */
  customQuery?: AirlinesQueryCondition;
  /**
   * 最大可选数量
   */
  limitSelected?: number;
  /**
   * 是否启用对调按钮, 默认不启用
   */
  useExchange?: boolean;
  /** 权限类型, 航班管理需要按用户卡航线权限 */
  permissionType?: "group" | "user";
}

const SelectMultiRoute = (props: SelectMultiRouteProps): ReactElement => {
  const {
    title,
    routes: propRoutes,
    setRoutes: setVal,
    onDataSourceChange,
    isDemo,
    flights,
    width = 334,
    autoSelectFirst = false,
    type = 1,
    useGroup = false,
    useContext = true,
    queryUrl,
    moduleCode,
    ext,
    debugId,
    customQuery,
    limitSelected,
    useExchange = false,
    permissionType = "group",
    ...otherProps
  } = props;
  const routes = useMemo(() => {
    return typeof propRoutes === "string"
      ? propRoutes
        ? [propRoutes]
        : defaultRouteList
      : propRoutes;
  }, [propRoutes]);
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [flightList, setFlightList] = useState<IRouteItem[]>([]);
  const [filterList, setFilterList] = useState<IRouteItem[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const query = customQuery || airlinesQueryCondition;
  let setRouteList: any = null;
  const setRoutes = useRefFunc(setVal);
  try {
    const [, actions] = usePageContext();
    if (useContext) {
      setRouteList = actions.setRouteList;
    }
  } catch (e) {}
  const queryParam = {
    server: getServer(2),
    url: queryUrl || QUERY_URL.routeFlight,
    head: {
      moduleCode: moduleCode || MODULE_CODE,
      chartTableCode: null,
    },
    query,
    ext: {
      code: null,
      type,
      permissionType,
      ...ext,
    },
    debugId,
  };
  const [{ isLoading, error }, doFetch] = useFetch<IRouteItem[]>({
    ...queryParam,
    debugId: `${debugId}_all`,
    lazey: true,
    onSuccess: useRefFunc((res: any) => {
      if (autoSelectFirst && routes.length === 0 && res.data?.length) {
        const tmp = res.data;
        setRoutes(typeof tmp[0] === "string" ? [tmp[0]] : [tmp[0].line]);
      }
      if (onDataSourceChange) {
        onDataSourceChange(res.data || EMPTY_ARRAY);
      }
      const source = res.data;
      if (source && source.length) {
        const tmp = cloneDeep(source);
        setFlightList(tmp);
        setFilterList(tmp);
      } else {
        setFlightList(EMPTY_ARRAY);
        setFilterList(EMPTY_ARRAY);
      }
      if (setRouteList) {
        setRouteList(source || defaultRouteList);
      }
    }),
  });

  const refetch = useCallback(() => {
    if (isDemo || !query.startDate || !query.endDate) {
      return;
    }
    doFetch({
      query,
      ext: {
        // 不用航班控制航线, 单选模式下会出现选择一次后无法切换的问题
        code: null,
        permissionType,
        type,
        ...ext,
      },
    });
  }, [isDemo, query, doFetch, permissionType, type, ext]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleExchange = useRefFunc(() => {
    console.log("exchange");
    const flightListValue = flightList.map((f) => f.line.replace("-", ""));
    const returns = uniq(
      routes.map((r) => r.substring(3, 6) + r.substring(0, 3))
    );
    // 判断当前选中的航线是否都有反向航线, 如果有则设置反向航线为选中值, 否则弹窗提醒
    const availableReturns = returns.filter((r) => flightListValue.includes(r));
    const routesHasReturnGroups = groupBy(routes, (r) => {
      const returnRoute = r.substring(3, 6) + r.substring(0, 3);
      return flightListValue.includes(returnRoute) ? "1" : "0";
    });
    const nextSingle = availableReturns;
    const nextAll = uniq(routes.concat(availableReturns));
    const nextAvailable = uniq(
      (routesHasReturnGroups["1"] || []).concat(availableReturns)
    );
    if (otherProps.mode === undefined) {
      if (nextSingle.length) {
        setRoutes(nextSingle);
      } else {
        message.warn(getSharkText("config_page_no_return_flight"));
      }
    } else if (availableReturns.length === routes.length) {
      setRoutes(nextAll);
    } else {
      Modal.confirm({
        title: getSharkText("config_page_flight_selection"),
        content: (
          <div>
            <div>
              {getSharkText(
                "config_page_some_selected_flights_have_no_return_flights"
              )}
              :{(routesHasReturnGroups["0"] || []).join(",")}
            </div>
          </div>
        ),
        okText: getSharkText("config_page_keep_no_return_flight"),
        cancelText: getSharkText("config_page_remove_no_return_flight"),
        onOk: () => {
          setRoutes(nextAll);
        },
        onCancel: () => {
          setRoutes(nextAvailable);
        },
      });
    }
  });

  const [activeUserGroups, setActiveUserGroups] = useState<IRouteGroup[]>([]);

  const options = useMemo(() => {
    const propFlights = flights.split(",");
    let byFlightList: string[] = EMPTY_ARRAY;
    if (filterList.length && flights && propFlights.length) {
      byFlightList =
        filterList
          .filter((f) => propFlights.some((p) => f?.flightNo?.includes(p)))
          .map((p) => p.line) || [];
    }
    const existsItem = cloneDeep(byFlightList);
    const userGroups: Array<{ label: string; options: IValueLabelOption[] }> =
      [];
    if (activeUserGroups.length) {
      activeUserGroups.forEach((group) => {
        const groupRoutes = group.route
          .split(",")
          .filter((r) => !existsItem.includes(r));
        userGroups.push({
          label: group.routeGroupName,
          options: groupRoutes.map((r) => ({ label: transRoute(r), value: r })),
        });
        existsItem.push(...groupRoutes);
      });
    }
    const groupByRouteList = groupBy(filterList, (f) => f.group);
    // console.log(groupByRouteList)
    const air = Object.keys(groupByRouteList)?.filter((s) => s !== "other")[0];
    const airName =
      air === "null"
        ? airlinesQueryCondition.airlines
        : air === "undefined"
        ? "其他"
        : air;
    const otherFlight =
      groupByRouteList[air]
        ?.filter((f) => !existsItem.includes(f.line))
        .map((f) => f.line) || [];
    existsItem.push(...otherFlight);
    const otherGroupFlightList =
      groupByRouteList.other
        ?.filter((f) => !existsItem.includes(f.line))
        .map((f) => f.line) || [];
    const total = 20;
    const byFlightOptions = byFlightList.slice(0, total).map((b) => ({
      label: transRoute(b),
      value: b,
    }));
    const byFlightArr = byFlightOptions.length
      ? [
          {
            label: flights || "Flight",
            options: byFlightOptions,
          },
        ]
      : [];
    const otherFlightOptions = otherFlight
      .slice(0, Math.max(0, total - byFlightList.length))
      .map((o) => ({
        label: transRoute(o),
        value: o,
      }));
    const otherFlightArr = otherFlight.length
      ? [
          {
            label: airName,
            options: otherFlightOptions,
          },
        ]
      : [];
    const otherGroupOptions = otherGroupFlightList
      .slice(0, Math.max(0, total - byFlightList.length - otherFlight.length))
      .map((o) => ({
        label: transRoute(o),
        value: o,
      }));
    const otherGroupArr = otherGroupFlightList.length
      ? [
          {
            label: "其他",
            options: otherGroupOptions,
          },
        ]
      : [];
    const tmpRst: Array<
      IValueLabelOption | { label: string; options: IValueLabelOption[] }
    > = [...byFlightArr, ...userGroups, ...otherFlightArr, ...otherGroupArr];
    /** 前20个航线中可能不包含已选中的航线, 导致已选中的航线不能展示label, 在此处补足 */
    const allOptions = byFlightOptions
      .concat(userGroups.map((g) => g.options).flat())
      .concat(otherFlightOptions)
      .concat(otherGroupOptions);
    const allOptionsValue = allOptions.map((o) => o.value);
    const notIncludeSelectedOptions = routes.filter(
      (r) => !allOptionsValue.includes(r)
    );
    const otherSelectedRouteOptions = notIncludeSelectedOptions.map((o) => ({
      label: transRoute(o),
      value: o,
    }));
    tmpRst.push(...otherSelectedRouteOptions);
    if (tmpRst.length === 1) {
      const item = tmpRst[0] as any;
      return item.options;
    }
    return tmpRst;
  }, [
    flights,
    filterList,
    activeUserGroups,
    airlinesQueryCondition.airlines,
    routes,
  ]);

  const handleChange = useRefFunc((value: string[] | string) => {
    if (Array.isArray(value)) {
      if (limitSelected && value.length > limitSelected) {
        message.error(
          getSharkText("config_page_most_select") +
            `${limitSelected}` +
            getSharkText("key.options.text") +
            "。"
        );
      } else {
        setRoutes(value.map((item) => item.replace("-", "")));
      }
    } else if (value) {
      setRoutes([value.replace("-", "")]);
    } else {
      setRoutes(EMPTY_ARRAY);
    }
  });

  const handleSearch = useRefFunc((value: string) => {
    const filterArr = flightList.filter((item) =>
      item?.line
        .replace("-", "")
        .includes(value.toLocaleUpperCase().replace("-", ""))
    );
    setFilterList(filterArr);
  });

  const onVisibleChange = useRefFunc((v: boolean) => {
    setOpen(v);
  });

  const closeDropdown = useRefFunc(() => {
    setOpen(false);
  });

  if (error) {
    return <Empty />;
  }

  return (
    <>
      <Spin spinning={isLoading}>
        <div style={{ display: "flex", alignItems: "stretch" }}>
          {title && (
            <span
              className="search-area-filter-title"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {title}
            </span>
          )}
          <Select
            open={open}
            className="search-airlines"
            disabled={isDemo}
            notFoundContent="NO Data"
            placeholder={getSharkText("config_page_select_route")}
            value={routes}
            showSearch
            onChange={handleChange}
            onSearch={handleSearch}
            style={{ width, minWidth: 110 }}
            onDropdownVisibleChange={onVisibleChange}
            filterOption={false}
            options={options}
            dropdownRender={(menu) => {
              return (
                <div>
                  {menu}
                  {useGroup ? (
                    <>
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-around",
                          flexWrap: "wrap",
                          padding: 8,
                        }}
                      >
                        <RouteGroup
                          isDemo={isDemo}
                          onSelectGroup={(group, checked) => {
                            if (checked) {
                              setActiveUserGroups([...activeUserGroups, group]);
                            } else {
                              setActiveUserGroups(
                                activeUserGroups.filter(
                                  (g) => g.id !== group.id
                                )
                              );
                            }
                          }}
                          onModalVisibleChange={(v) => v && closeDropdown()}
                        />
                      </div>
                    </>
                  ) : undefined}
                </div>
              );
            }}
            {...otherProps}
          ></Select>
          {useExchange ? (
            <span className="exchange-icon" style={{ display: "flex" }}>
              <SwapOutlined
                onClick={handleExchange}
                style={{ color: "var(--main)", padding: 5 }}
              ></SwapOutlined>
            </span>
          ) : undefined}
        </div>
      </Spin>
    </>
  );
};
SelectMultiRoute.displayName = "SelectMultiRoute";
export default SelectMultiRoute;
