import { MarketTrendRequestType } from "@ctrip/flt-bi-flightai-airlines";
import { Col, Row, Space, Typography } from "antd";
import useRefFunc from "Utils/useRefFunc";
import { ArrowDownOutlined } from "@ant-design/icons";

import React, { ReactElement, useRef, useState } from "react";
import AirlineCharts, { ChartHandle } from "./Components/Airlinecharts";
import { ChartType, RevertChartTypeOptions } from "./common";

export interface ITablePortalProps {
  filter: MarketTrendRequestType;
}

const TablePortal = (props: ITablePortalProps): ReactElement => {
  const { filter } = props;
  const [chartType, setChartType] = useState<ChartType>(ChartType.Line);
  const chartTypeContent = RevertChartTypeOptions[chartType];
  const chartRef = useRef<ChartHandle>(null);

  const handleChangeChartType = useRefFunc(() => {
    if (chartType === ChartType.Line) {
      setChartType(ChartType.Table);
    } else {
      setChartType(ChartType.Line);
    }
  });
  const handleDownload = useRefFunc(() => {
    console.log("download parent");
    if (chartRef && chartRef.current) {
      chartRef.current.download("市场趋势面板");
    }
  });

  return (
    <div>
      <Row justify="space-between" style={{ margin: 30 }}>
        <Col>
          <Space>
            <Typography>{""}</Typography>
          </Space>
        </Col>
        <Col>
          <Space>
            <span onClick={handleChangeChartType}>
              {chartTypeContent.component}
            </span>
            <span>
              <ArrowDownOutlined onClick={handleDownload} />
            </span>
          </Space>
        </Col>
      </Row>
      <div
        style={{
          marginRight: "auto",
          marginLeft: "auto",
          width: "auto",
          position: "relative",
        }}
      >
        <AirlineCharts filter={filter} chartType={chartType} ref={chartRef} />
      </div>
    </div>
  );
};

export default TablePortal;
