import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { EDictType } from "../WingonCommon";
import { useFetch } from "Utils";
import { WingonFilter } from "@ctrip/flt-bi-flightai-partners";

export interface ISearchProps {
  title: string;
  filter?: WingonFilter;
  onChange: (v: string[]) => void;
  type: EDictType;
  width?: number;
}

/**
 *  Component description
 */
const Search = (props: ISearchProps): ReactElement => {
  const { title, filter, onChange, type, width } = props;

  const [globalState] = useGlobalState();
  const { partnerCondition } = globalState;
  const [loaded, setLoaded] = useState<boolean>(false);

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(4),
    url: QUERY_URL.dict,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE,
    },
    query: partnerCondition,
    ext: {},
    lazey: true,
  });

  useEffect(() => {
    if (!data && !loaded && partnerCondition.partner) {
      doFetch({
        query: partnerCondition,
        ext: { filter, type },
      });
      setLoaded(true);
    }
  }, [data, doFetch, filter, loaded, partnerCondition, type]);

  const handleChange = useCallback(
    (v: any) => {
      onChange(v as string[]);
    },
    [onChange]
  );

  if (!data || data.length === 0) {
    return <span></span>;
  }

  if (isLoading) {
    return <Spin></Spin>;
  }

  return (
    <>
      <span style={{ marginRight: 10 }}>{title}</span>
      <Select
        options={data}
        onChange={handleChange}
        style={{ minWidth: width ?? 100 }}
        mode="multiple"
      ></Select>
    </>
  );
};
export default Search;
