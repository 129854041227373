import { Col, Radio, Row } from "antd";
import PreLastChain from "./PreLastChain";
import {
  COMPARE_TYPE_PLC_NAME,
  DATE_FORMAT,
  DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
  DEFAULT_AIRPORT_COMPARE_TYPE_NAME,
} from "Constants";
import { Moment } from "moment";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import useGlobalState from "Store";
import { IRefundExt } from "../Interface";
import DateRangePicker from "./DateRangePicker";
import { findKeyByValue } from "Utils/global";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 *
 */
const defaultValControl = [DEFAULT_AIRPORT_COMPARE_TYPE_NAME];

interface IRefundSearchProps {
  onChangeVal: (v: IRefundExt) => void;
}

const RefundSearch = (props: IRefundSearchProps): ReactElement => {
  const { onChangeVal } = props;
  const [dateType, setDateType] = useState<0 | 1>(0);
  const [range, setRange] = useState<Moment[]>([]);
  const [valControl, setValControl] = useState<string[]>(defaultValControl);
  const pickerRef = useRef<any>();

  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const dateMode = "lastMonth";

  useEffect(() => {
    const dateStrArr = range.map((m: Moment) => m.format(DATE_FORMAT));
    if (!!onChangeVal && dateStrArr[0] !== undefined) {
      // const type = enumKeys(COMPARE_TYPE_PER_NAME, true).find((d) => COMPARE_TYPE_PER_NAME[d] === valControl[0]);
      const type =
        findKeyByValue(COMPARE_TYPE_PLC_NAME, valControl[0], Number) ??
        DEFAULT_AIRLINE_COMPARE_TYPE_VALUE;
      const tmpQuery = queryCondition;
      const query = {
        ...tmpQuery,
        startDate: dateStrArr[0],
        endDate: dateStrArr[1],
        useCompare: 1,
        compareType: type,
      };
      const val: IRefundExt = {
        query,
        ext: {
          type: dateType,
        },
      };
      onChangeVal(val);
    }
  }, [range, valControl, onChangeVal, queryCondition, dateType]);

  const rangePickerChange = (val: Moment[]) => {
    setRange(val);
  };

  return (
    <>
      <Row
        gutter={[{ xs: 8, sm: 16, md: 24 }, 20]}
        align="middle"
        className="search-row"
      >
        <Col>
          <Radio.Group
            value={dateType}
            onChange={(v) => setDateType(v.target.value)}
          >
            <Radio value={0}>{getSharkText("charts.date_of_departure")}</Radio>
            <Radio value={1}>{getSharkText("config_page_refund_date")}</Radio>
          </Radio.Group>
        </Col>
        <Col>
          <DateRangePicker
            ref={pickerRef}
            defaultDateMode={dateMode}
            onChange={rangePickerChange}
            noHistoryToFuture
          ></DateRangePicker>
        </Col>
        <Col>
          <PreLastChain
            defaultValue={defaultValControl}
            onChange={setValControl}
          ></PreLastChain>
        </Col>
      </Row>
    </>
  );
};
export default RefundSearch;
