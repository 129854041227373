import { SwapRightOutlined } from "@ant-design/icons";
import CascaderArea from "Components/CascaderArea";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { Area, FlightClass, RangeObject } from "Interface";
import { defaultArea } from "Page/AI/UserProfile/common";
import useRefFunc from "Utils/useRefFunc";
import { Button, Checkbox, Col, Row } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { ODParam } from "./interface";
import Airlines from "Components/Toolbar/Airlines";
import useGlobalState from "Store";
import { getDateRange } from "Utils";

export const TRANSIT_CLASS_OPTIONS = [
  {
    label: "Direct",
    value: 0,
  },
  {
    label: "1 Stop",
    value: 1,
  },
  {
    label: "2 Stops",
    value: 2,
  },
];

export interface SearchAreaProps {
  onQuery: (v: ODParam) => void;
}

/** 客源流向-搜索区域 */
const SearchArea = (props: SearchAreaProps): ReactElement => {
  const { onQuery } = props;
  const [globalState] = useGlobalState();
  const { airlines } = globalState.airlinesQueryCondition;
  const [stops, setStops] = useState<number[]>([0]);
  const [originArea, setOriginArea] = useState<Area>(defaultArea);
  const [departArea, setDepartArea] = useState<Area>(defaultArea);
  const [arriveArea, setArriveArea] = useState<Area>(defaultArea);
  const [destinationArea, setDestinationArea] = useState<Area>(defaultArea);
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange("lastYear")
  );
  const [initialed, setInitialed] = useState(false);

  const handleSearch = useRefFunc(() => {
    onQuery({
      airline: airlines,
      dateRange,
      stops,
      originArea,
      departArea,
      arriveArea,
      destinationArea,
    });
  });

  useEffect(() => {
    if (!initialed && airlines) {
      handleSearch();
      setInitialed(true);
    }
  }, [airlines, handleSearch, initialed]);

  return (
    <Row gutter={[20, 10]}>
      <Col>
        <Airlines />
      </Col>
      <Col>
        <DateRangePickerV2
          value={dateRange}
          onChange={setDateRange}
          otherProps={{ picker: "month", format: "YYYY-MM" }}
        />
      </Col>
      <Col>
        <Checkbox.Group
          options={TRANSIT_CLASS_OPTIONS}
          value={stops}
          onChange={(v) => setStops(v.map((v1) => Number(v1)))}
        ></Checkbox.Group>
      </Col>
      <Col>
        <Row gutter={[10, 10]}>
          <Col>
            <CascaderArea
              flightClass={FlightClass.All}
              setSelectedArea={setOriginArea}
              changeOnSelect={false}
              placeholder="Airport"
              lang="en-US"
            />
          </Col>
          <Col>...</Col>
          <Col>
            <CascaderArea
              flightClass={FlightClass.All}
              setSelectedArea={setDepartArea}
              changeOnSelect={false}
              disabled={!(stops.includes(1) || stops.includes(2))}
              placeholder="Airport"
              lang="en-US"
            />
          </Col>
          <Col>
            <SwapRightOutlined />
          </Col>
          <Col>
            <CascaderArea
              flightClass={FlightClass.All}
              setSelectedArea={setArriveArea}
              changeOnSelect={false}
              disabled={!stops.includes(2)}
              placeholder="Airport"
              lang="en-US"
            />
          </Col>
          <Col>...</Col>
          <Col>
            <CascaderArea
              flightClass={FlightClass.All}
              setSelectedArea={setDestinationArea}
              changeOnSelect={false}
              placeholder="Airport"
              lang="en-US"
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Button type="primary" onClick={handleSearch}>
          Query
        </Button>
      </Col>
    </Row>
  );
};
export default SearchArea;
