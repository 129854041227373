import ubtUtils from "Utils/ubtUtils";
import React, { ComponentProps, ReactNode } from "react";

interface State {
  hasError: boolean;
  error?: Error | undefined;
}
interface Props extends ComponentProps<any> {
  pageId: string;
}
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, info: { componentStack: string }): void {
    console.error("catch an error, code stack: ", error);
    console.log("catch an error, componentStack: ", info?.componentStack);
    ubtUtils.error(error.message, error.name, error.stack);
    ubtUtils.error(error.message, error.name, info?.componentStack);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <div>
            页面遇到异常内容, 请切换或刷新页面, 如果连续遇到此错误请联系管理员。
          </div>
          {"error : " + this.state.error?.message}
        </div>
      );
    }

    return this.props.children;
  }
}
