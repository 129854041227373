import { IS_PRO } from "Constants";
import { AreasConfig } from "./interface";

export const DATASET_ID = IS_PRO ? 505 : 204;

export const CITYID = "cityid";
export const CITYNAME = "cityname";
export const CITYENGNAME = "cityengname";
export const CURRENCY = "currency";
export const CITYCODE = "citycode";
export const CURRENTFLAG = "currentflag";
export const PROVINCEID = "provinceid";
export const PROVINCENAME = "provincename";
export const REGIONNAME = "regionname";
export const REGIONNAME_ZMG = "regionname_zmg";
export const COUNTRYID = "countryid";
export const COUNTRYCODE = "countrycode";
export const COUNTRYNAME = "countryname";
export const COUNTRYNAME_EN = "countryname_en";
export const AREA_EN = "area_en";
export const AREA_CN = "area_cn";
export const TC_NAME = "tc_name";
export const CONTINENTID = "continentid";
export const CONTINENTNAME = "continentname";
export const AFFILIATIONNAME = "affiliationname";
export const LATITUDE = "latitude";
export const LONGITUDE = "longitude";
export const HTL_CITYID = "htl_cityid";
export const HTL_CITYNAME = "htl_cityname";
export const HTL_PROVINCEID = "htl_provinceid";
export const HTL_PROVINCENAME = "htl_provincename";
export const HTL_COUNTRYID = "htl_countryid";
export const HTL_COUNTRYNAME = "htl_countryname";
export const ICAOCOUNTRYCODE = "icaocountrycode";
export const DATACHANGE_INSERTDATE = "datachange_insertdate";
export const AREA_MARKET = "area_market";
export const PROVINCENAME_EN = "provincename_en";
export const CONTINENTNAME_EN = "continentname_en";
export const AFFILIATIONNAME_EN = "affiliationname_en";
export const REGIONNAME_AI = "regionname_ai";

export const LEVELS = [
  "continent",
  "country",
  "region",
  "province",
  "city",
] as const;

export const AREAS_CONFIG: AreasConfig = {
  continent: {
    code: CONTINENTID,
    name: CONTINENTNAME,
    enName: CONTINENTNAME_EN,
    extCols: [],
    typeNum: 1,
  },
  country: {
    code: COUNTRYCODE,
    name: COUNTRYNAME,
    enName: COUNTRYNAME_EN,
    extCols: [],
    typeNum: 2,
  },
  region: {
    code: REGIONNAME_AI,
    name: REGIONNAME_AI,
    enName: REGIONNAME_AI,
    extCols: [],
    typeNum: 2,
  },
  province: {
    code: PROVINCEID,
    name: PROVINCENAME,
    enName: PROVINCENAME_EN,
    extCols: [],
    typeNum: 3,
  },
  city: {
    code: CITYID,
    name: CITYNAME,
    enName: CITYENGNAME,
    extCols: [],
    typeNum: 4,
  },
};
