import React, { ReactElement } from "react";
import { Card } from "antd";
import UserTable from "./Components/UserTable";
import { getSharkText } from "Utils/i18nGlobal";

const UserManage = (): ReactElement => {
  const title = (
    <div className="mode-card-title">{getSharkText("menu_user_manage")}</div>
  );

  return (
    <div className="content">
      <Card title={title}>
        <UserTable queryUrl="findUserList" moduleCode="user_manage" />
      </Card>
    </div>
  );
};

export default UserManage;
