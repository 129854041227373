import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import {
  AIRLINE_MARK,
  ARRIVAL_AREA,
  ARR_AREA_NAME,
  DATASET_ID,
  DEPART_AREA,
  DEP_AREA_NAME,
  DESTINATION,
  DEST_AREA_NAME,
  PAX_MASKING,
  TAKEOFF_MONTH,
  TRANSIT_CLASS,
} from "./beyondCols";
import { U, useFetch } from "Utils";
import { Spin, message } from "antd";
import {
  StandardFilter,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import EChartsReact from "echarts-for-react";
import { BeyondParam, BeyondResData, ChartData } from "./interface";
import { cloneDeep, groupBy } from "lodash";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { ECHARTS_NODATA_OPTION } from "Constants";

const getYData = (m: BeyondResData): string => {
  return `${m.depart_area}-${m.arrival_area}${
    m.destination ? `...${m.destination}` : ""
  }`;
};

export interface BeyondProps {
  param: BeyondParam;
  cols: FDDatasetCol[];
}

/**  Component description */
const BeyondChart = (props: BeyondProps): ReactElement => {
  const { param, cols } = props;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [resData, setResData] = useState<ChartData[]>([]);
  const [yData, setYData] = useState<string[]>([]);

  const oriFilters = useMemo(() => {
    if (!param.dateRange || param.dateRange.length !== 2) {
      return [];
    }
    const tmpFilters: StandardFilter[] = [
      {
        wildcard: {
          field: `dimension.${AIRLINE_MARK}`,
          value: `*${param.airline}*`,
        },
      },
      {
        range: {
          field: `dimension.${TAKEOFF_MONTH}`,
          strRange: {
            lower: param.dateRange[0]?.format("YYYY-MM") || "",
            upper: param.dateRange[1]?.format("YYYY-MM") || "",
          },
        },
      },
    ];
    if (param.departArea?.areaCode) {
      tmpFilters.push({
        in: {
          field: `dimension.${DEP_AREA_NAME[param.departArea.areaType]}`,
          values: [param.departArea?.areaCode || ""],
        },
      });
    }
    if (param.arriveArea?.areaCode) {
      tmpFilters.push({
        in: {
          field: `dimension.${ARR_AREA_NAME[param.arriveArea.areaType]}`,
          values: [param.arriveArea?.areaCode || ""],
        },
      });
    }
    if (param.destinationArea?.areaCode) {
      tmpFilters.push({
        in: {
          field: `dimension.${DEST_AREA_NAME[param.destinationArea.areaType]}`,
          values: [param.destinationArea?.areaCode || ""],
        },
      });
    }
    return tmpFilters;
  }, [param]);

  const builder = useMemo(() => {
    if (!cols.length || !oriFilters.length) {
      return null;
    }
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns: cols,
      dimensions: [DEPART_AREA, ARRIVAL_AREA, DESTINATION],
      measures: [PAX_MASKING],
      chartFilters: [],
      containerFilters: [],
      oriFilters,
      sorters: [
        {
          chartUsedColId: PAX_MASKING,
          columnName: PAX_MASKING,
          sorter: "DESC",
          statistical: "SUM",
        },
      ],
      limit: 10,
    });
  }, [cols, oriFilters]);

  // #region 表格查询
  const [{ error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "chartData",
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(
        res.rows,
        res.headers
      ) as unknown as BeyondResData[];
      console.log("map: ", map);
      const chartData = map.map((m) => ({
        x: getYData(m),
        y: m.SUM_pax_masking,
        category: m.transit_class,
      }));
      setResData(chartData);
    },
    onFinish: () => {
      setIsLoadingData(false);
    },
  });

  // #region 表格查询
  const [{ error: errorPre }, doFetchPre] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "chart-pre",
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(
        res.rows,
        res.headers
      ) as unknown as BeyondResData[];
      console.log("map: ", map);
      const filters = map.map((m) => {
        const f: StandardFilter = {
          and: {
            filters: [
              {
                in: {
                  field: `dimension.${DEPART_AREA}`,
                  values: [m.depart_area],
                },
              },
              {
                in: {
                  field: `dimension.${ARRIVAL_AREA}`,
                  values: [m.arrival_area],
                },
              },
              {
                in: {
                  field: `dimension.${DESTINATION}`,
                  values: [m.destination || ""],
                },
              },
            ],
          },
        };
        return f;
      });
      if (!builder || !filters.length) {
        return;
      }
      const tmpYData = map.map((m) => getYData(m)).reverse();
      setYData(tmpYData);
      const builderCP = cloneDeep(builder);
      builderCP.appendFilters([{ or: { filters } }]);
      builderCP.setDimensions(
        builderCP.getDimensions().concat([
          {
            columnName: TRANSIT_CLASS,
            dimensionConfig: { calculateConfig: null, type: "column" },
          },
        ])
      );
      builderCP.setLimit(1000);
      const { encrypted } = builder.getRequestBody();
      doFetch({
        ext: {
          datasetId: DATASET_ID,
          colIds: [],
          req: encrypted,
        },
      });
    },
    onFinish: () => {
      setIsLoadingData(false);
    },
  });

  const { requestBody, encrypted } = useMemo(() => {
    return builder
      ? builder.getRequestBody()
      : { requestBody: null, encrypted: "" };
  }, [builder]);

  const lastParam = useRef<string>();

  const refetch = useCallback(() => {
    setIsLoadingData(true);
    setResData([]);
    setYData([]);
    doFetchPre({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetchPre, encrypted]);

  useEffect(() => {
    if (encrypted && encrypted !== lastParam.current) {
      refetch();
      lastParam.current = encrypted;
    }
  }, [encrypted, refetch]);

  const option = useMemo(() => {
    // if (isLoadingData) {
    //   return {};
    // }
    if (!resData.length) {
      return ECHARTS_NODATA_OPTION;
    }
    const groups = groupBy(resData, "category");
    return {
      title: {
        text: null,
      },
      color: ["#07D4BE", "#1BC6FF", "#165DFF"],
      tooltip: { trigger: "axis" },
      grid: { top: 50, bottom: 40, left: 120, right: 40 },
      legend: { left: 10, top: 10, itemGap: 40 },
      xAxis: {
        type: "value",
        axisLabel: {
          formatter: (v: number) => U.abbreviateNumber(v),
        },
      },
      yAxis: {
        type: "category",
        data: yData,
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          align: "left",
          margin: 105,
          fontFamily: "TripGeomTF-Medium",
          formatter: (v: string) => {
            const arr = v.split("...");
            const textDA = `${arr[0]}${arr[1] ? " ··· " : ""}`;
            const textDest = arr[1] ? arr[1] : "";
            return `{a|${textDA}}${textDest ? `{b|${textDest}}` : ""}`;
          },
          rich: {
            a: {
              color: "#86909C",
            },
            b: {
              color: "#1D2129",
            },
          },
        },
      },
      series: ["0", "1", "2"].map((key: string) => {
        return {
          name: `${key} beyond point`,
          type: "bar",
          stack: "total",
          data: groups[key]?.map((g) => [g.y, g.x]).reverse() ?? [],
        };
      }),
    };
  }, [isLoadingData, resData, yData]);

  return (
    <Spin spinning={isLoadingData}>
      <EChartsReact option={option} notMerge style={{ height: 430 }} />
    </Spin>
  );
};
export default BeyondChart;
