import { IDownloadHeader } from "Interface";
import ValueNum from "Components/ValueNum";
import React from "react";
import { MarketTrendDataDiffTime } from "./Airlinecharts";

export const getColumns = (): Array<
  IDownloadHeader<MarketTrendDataDiffTime>
> => {
  return [
    {
      title: "提前起飞期",
      dataIndex: "earlyTime",
      sorter: (r1: any, r2: any) =>
        r1.earlyTime?.localeCompare(r2.earlyTime) || 0,
    },
    {
      title: "平均价格",
      dataIndex: "avgPrice",
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "搜索指数",
      dataIndex: "searchIndex",
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "航班数量",
      dataIndex: "flightCount",
    },
  ];
};
export const getColumnsCompare = (): Array<
  IDownloadHeader<MarketTrendDataDiffTime>
> => {
  return [
    {
      title: "提前起飞期",
      dataIndex: "earlyTime",
      sorter: (r1: any, r2: any) =>
        r1.earlyTime?.localeCompare(r2.earlyTime) || 0,
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "平均价格",
      dataIndex: "avgPrice",
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "搜索指数",
      dataIndex: "searchIndex",
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "航班数量",
      dataIndex: "flightCount",
    },
    {
      title: "对比平均价格",
      dataIndex: "compareAvgPrice",
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "对比搜索指数",
      dataIndex: "compareSearchIndex",
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: "对比航班数量",
      dataIndex: "compareFlightCount",
    },
  ];
};
