import { getSharkText } from "Utils/i18nGlobal";
import { EPanelType } from "../../WingonCommon";
import { ISeries } from "Interface";
const search = (panelType: EPanelType): ISeries[] => {
  return [
    {
      type: "line",
      encode: { x: "day", y: "value" },
      lineStyle: {
        width: 3,
      },
      name: `${
        panelType === 0
          ? getSharkText("config_page_query_total")
          : getSharkText("config_page_reservation_amount")
      }`,
      showSymbol: false,
      symbol: "circle",
    },
    {
      type: "line",
      encode: { x: "day", y: "compareValue" },
      lineStyle: {
        width: 3,
      },

      name: `${
        panelType === 0
          ? getSharkText("config_page_success_quantity_or_check_ratio")
          : "查定比"
      }`,
      showSymbol: false,
      symbol: "circle",
    },
    {
      type: "bar",
      encode: { x: "day", y: "resultValue" },
      barMaxWidth: 20,
      isPercentVal: true,
      name: getSharkText("config_page_success_rate"),
      yAxisIndex: 1,
    },
  ];
};

const order = (panelType: EPanelType): ISeries[] => {
  return [
    {
      type: "line",
      encode: { x: "day", y: "value" },
      lineStyle: {
        width: 3,
      },
      name: `${
        panelType === 0
          ? getSharkText("config_page_query_total")
          : getSharkText("config_page_reservation_amount")
      }`,
      showSymbol: false,
      symbol: "circle",
    },
    {
      type: "bar",
      encode: { x: "day", y: "totalValue" },
      lineStyle: {
        width: 3,
      },
      yAxisIndex: 1,
      name: `${
        panelType === 0
          ? getSharkText("config_page_success_count")
          : getSharkText("config_page_query_ratio")
      }`,
      showSymbol: false,
      symbol: "circle",
      precision: 2,
    },
  ];
};

export const genSeries = (panelType: EPanelType): ISeries[] => {
  if (panelType === 0) {
    return search(panelType);
  } else {
    return order(panelType);
  }
};
