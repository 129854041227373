import useGlobalState from "Store";
import EChartsReact, { EChartsReactProps } from "echarts-for-react";
import React, { forwardRef, ReactElement, useMemo, useRef } from "react";

export interface EchartsReactBaseProps extends EChartsReactProps {
  option: any;
}

/**  Component description */
const EchartsReactBase = forwardRef(
  (props: EchartsReactBaseProps, ref: any): ReactElement => {
    const { option, onEvents, ...rest } = props;
    const localRef = useRef<any>(null);
    const [globalState] = useGlobalState();
    const theme = globalState.theme;
    const mergedOption = useMemo(() => {
      return {
        ...option,
        backgroundColor: "rgba(0,0,0,0)",
      };
    }, [option]);

    const handleEvents = useMemo(() => {
      return {
        ...onEvents,
        click: (params: any) => {
          onEvents?.click?.(params);
          const r = ref || localRef;
          console.log(
            "EchartsReact.click options: ",
            r?.current?.getEchartsInstance()?.getOption()
          );
        },
      };
    }, [onEvents, ref]);

    return (
      <EChartsReact
        ref={ref || localRef}
        {...rest}
        option={mergedOption}
        theme={theme === "light" ? "default" : "dark"}
        onEvents={handleEvents}
      />
    );
  }
);
EchartsReactBase.displayName = "EchartsReactBase";
export default EchartsReactBase;
