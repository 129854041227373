import React, { useEffect, useMemo, useState } from "react";
import { Button, Col, Row } from "antd";
import useGlobal from "Store";
import { IDateMode, IDynamicPriceFilter, RangeObject, Role } from "Interface";
import { DATE_FORMAT, LIMIT_END_DATE, LIMIT_START_DATE } from "Constants";
import {
  getDateRange,
  getModuleNameFromPath,
  getModuleStatus,
  getUserAirlines,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import "./index.css";
import Airlines from "Components/Toolbar/Airlines";
import { SelectMultFlightProps } from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import { useQueryString, useQueryStringArr } from "Utils/query/useQueryString";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { useLocation } from "react-router-dom";
import { SelectMultiRouteProps } from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import { getDefaultAirline } from "Utils/global";
import SelectRouteAndFlight from "Page/AI/FlightManage/Components/SearchArea/SelectRouteAndFlight";

interface SearchAreaProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  setQueryExt: (ext: IDynamicPriceFilter) => void;
}

const defaultDate: IDateMode = "nextFourteenDays";

const SearchArea: React.FC<SearchAreaProps> = (props: SearchAreaProps) => {
  const { setQueryExt } = props;
  const location = useLocation();
  const [qs] = useQueryString("startDate", "");
  const [qe] = useQueryString("endDate", "");
  const [qa] = useQueryString("airline", "");
  const [routes, setRoutes] = useQueryStringArr("routes", []);
  const defaultRange =
    qs && qe ? ([moment(qs), moment(qe)] as RangeObject) : null;
  const [range, setRange] = useState<RangeObject>(
    defaultRange || getDateRange(defaultDate)
  );
  const [flightNO, setFlightNO] = useState<string[]>([]);
  const [, setLoading] = useState(false);
  const [globalState, actions] = useGlobal();
  const { userInfo, queryCondition, systemType, airlinesQueryCondition } =
    globalState;
  const { setAirlinesQueryCondition } = actions;
  const { user } = userInfo;
  const isCtrip = user.userType === 1;
  const { flightClass } = queryCondition;
  const { pathname } = location;
  const userAirlines = useMemo(
    () => getUserAirlines(userInfo.roleList),
    [userInfo.roleList]
  );
  const roleList = userInfo.roleList;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;

  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const [inited, setInited] = useState<boolean>(false);

  const handleSearch = () => {
    // if (compareAirport) {
    setQueryExt({
      routeList: routes,
      flightList: flightNO,
      startDate: (range && range[0]?.format(DATE_FORMAT)) || "",
      endDate: (range && range[1]?.format(DATE_FORMAT)) || "",
    });
    // } else {
    //     message.warning('请选择对标机场')
    // }
  };
  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false));
  }, [flightClass]);

  useEffect(() => {
    if (!inited && range?.length) {
      handleSearch();
      setInited(true);
    }
  }, [range]);
  useEffect(() => {
    if (airlinesQueryCondition.airlines) {
      setAirlinesQueryCondition({
        ...airlinesQueryCondition,
        startDate: (range && range[0]?.format(DATE_FORMAT)) || LIMIT_START_DATE,
        endDate: (range && range[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
      });
    } else {
      const tmp = getDefaultAirline(airlinesRole);
      setAirlinesQueryCondition({
        ...airlinesQueryCondition,
        startDate: (range && range[0]?.format(DATE_FORMAT)) || LIMIT_START_DATE,
        endDate: (range && range[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
        ...tmp,
      });
    }
  }, []);

  const routeProps = useMemo(() => {
    const tmpProps: SelectMultiRouteProps = {
      queryUrl: "queryDynamicPriceRouteOrFlight",
      moduleCode: "dynamic_price_airlines",
      routes,
      setRoutes,
      flights: flightNO.join(","),
      isDemo,
      mode: "multiple",
      useGroup: true,
      useExchange: true,
      autoSelectFirst: true,
      style: { minWidth: 200, width: "auto" },
      debugId: "top-routes",
    };
    return tmpProps;
  }, [flightNO, isDemo, routes, setRoutes]);

  const flightProps = useMemo(() => {
    const tmpProps: SelectMultFlightProps = {
      queryUrl: "queryDynamicPriceRouteOrFlight",
      moduleCode: "dynamic_price_airlines",
      flightNO,
      setFlightNO,
      routes: routes.join(","),
      isDemo,
      width: "auto",
      mode: "multiple",
    };
    return tmpProps;
  }, [flightNO, isDemo, routes]);

  const [routeComponent, flightComponent] = SelectRouteAndFlight({
    routeProps,
    flightProps,
    changeRouteByFlight: true,
    changeFlightByRoute: true,
  });

  return (
    <Row align="middle" gutter={[10, 20]} style={{ marginTop: 0 }}>
      {isCtrip && (
        <Col>
          <Airlines airlines={userAirlines} />
        </Col>
      )}
      <Col>{routeComponent}</Col>
      <Col>{flightComponent}</Col>
      <Col>
        <DateRangePickerV2
          defaultDateMode={defaultRange || defaultDate}
          onChange={setRange}
        />
      </Col>
      <Col>
        <Button
          id="areaSearchBtn"
          type="primary"
          onClick={handleSearch}
          // style={{ marginLeft: 351 }}
        >
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};

export default SearchArea;
