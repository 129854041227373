import React from "react";
import { Link } from "react-router-dom";
import webchat from "assets/images/wechat.png";
import "./index.scss";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div className="footer-con">
        <div className="footer-l">
          <div className="logo logo-reverse"></div>
          <div className="mail">邮箱：flightai@trip.com</div>
          <div className="copyright">
            Copyright @2022 上海箐程信息技术有限公司
          </div>
        </div>
        <div className="footer-r">
          <div className="link">
            <Link to="/">首页</Link>
            <Link to="/MarketInsight">市场洞察平台</Link>
            <Link to="/Consulting">行业咨询</Link>
            <Link to="/RevenueManagement">收益管理</Link>
            <Link to="/AboutUs">关于我们</Link>
          </div>
          <div className="wechat">
            <img src={webchat} alt="微信公众号" />
            <p>微信公众号</p>
          </div>
          <div className="wechat">
            <div className="ios"></div>
            <p>
              <a href="https://download2.ctrip.com/flightai-storage_bucket/flightai_apk.apk">
                安卓下载
              </a>
            </p>
          </div>
          <div className="wechat">
            {/* <img src={iospng} alt="IOS下载" /> */}
            <div className="ios"></div>
            <p>
              <a href="https://download2.ctrip.com/flightai-storage_bucket/flightai_ios.ipa">
                IOS下载
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
