import { UID } from "Constants";
import moment from "moment";
import { getCookie } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import {
  CardTypeConfig,
  DashboardConfig,
  DefaultGranularityType,
  DimensionSchemaEx,
  FormatConfig,
  GranularityUnitType,
  MeasureSchemaEx,
  PageConfig,
  StatisticalType,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import md5 from "crypto-js/md5";
import { Agg, Sort } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/schema";
import { GranularityUnitList } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/common";
import { isDateCol } from "Utils/global";
import { Obj } from "Interface";
import { StandardResponse } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import ubtUtils from "Utils/ubtUtils";

export const ChartTypeList = ["table", "line", "bar", "pie", "card"] as const;
const ChartTypeMap: Record<(typeof ChartTypeList)[number], any> = {
  table: {
    label: "table",
    value: "table",
  },
  line: {
    label: "line",
    value: "line",
  },
  bar: {
    label: "bar",
    value: "bar",
  },
  pie: {
    label: "pie",
    value: "pie",
  },
  card: {
    label: "card",
    value: "card",
  },
};
export const ChartTypeOptions = Object.values(ChartTypeMap);

export const DimTypeList = ["row", "column"] as const;

export const SorterType = ["ASC", "DESC"] as const;

export const DataTypeList = [
  "VARCHAR",
  "INTEGER",
  "BIGINT",
  "DOUBLE",
  "DECIMAL",
  "NUMERIC",
  "TIMESTAMP",
  "DATE",
] as const;

export const MeasureTypeList = ["decimal", "percent", "currency"] as const;
export const MeasureTypeMap: Record<(typeof MeasureTypeList)[number], any> = {
  decimal: {
    label: getSharkText("key.value.text"),
    value: "decimal",
    config: {
      precision: 1,
    },
  },
  percent: {
    label: getSharkText("config_page_percent"),
    value: "percent",
    config: {
      precision: 3,
    },
  },
  currency: {
    label: getSharkText("config_page_currency"),
    value: "currency",
    config: {
      precision: 2,
    },
  },
};
export const MeasureTypeOptions = Object.values(MeasureTypeMap);

export const UnitTypeList = [
  "none",
  "k",
  "m",
  "hundred",
  "thousand",
  "tenThousands",
  "hundredMillion",
] as const;
export const UnitTypeMap: Record<(typeof UnitTypeList)[number], any> = {
  none: {
    label: getSharkText("module.none.name"),
    value: "none",
  },
  k: {
    label: "K",
    value: "k",
  },
  m: {
    label: "M",
    value: "M",
  },
  hundred: {
    label: getSharkText("config_page_hundred"),
    value: "hundred",
  },
  thousand: {
    label: getSharkText("config_page_thousand"),
    value: "thousand",
  },
  tenThousands: {
    label: getSharkText("config_page_ten_thousands"),
    value: "tenThousands",
  },
  hundredMillion: {
    label: getSharkText("config_page_hundred_million"),
    value: "hundredMillion",
  },
};
export const UnitTypeOptions = Object.values(UnitTypeMap);

export const CalculateList = ["include", "exclude"] as const;
export const CalculateMap: Record<
  (typeof CalculateList)[number],
  { label: string; value: (typeof CalculateList)[number] }
> = {
  include: {
    label: getSharkText("config_page_include"),
    value: "include",
  },
  exclude: {
    label: getSharkText("config_page_exclude"),
    value: "exclude",
  },
};

export const CalculateOptions = Object.values(CalculateMap);

export const StatisticalList = [
  "SUM",
  "AVG",
  "MAX",
  "MIN",
  "COUNT",
  "P50",
  "P90",
  "P95",
  "P99",
] as const;
export const StatisticalMap: Record<
  (typeof StatisticalList)[number],
  { label: string; value: (typeof StatisticalList)[number] }
> = {
  SUM: {
    label: getSharkText("config_page_sum"),
    value: "SUM",
  },
  AVG: {
    label: getSharkText("config_page_average"),
    value: "AVG",
  },
  MAX: {
    label: getSharkText("config_page_maximum"),
    value: "MAX",
  },
  MIN: {
    label: getSharkText("config_page_minimum"),
    value: "MIN",
  },
  COUNT: {
    label: getSharkText("config_page_count"),
    value: "COUNT",
  },
  P50: {
    label: "P50",
    value: "P50",
  },
  P90: {
    label: "P90",
    value: "P90",
  },
  P95: {
    label: "P95",
    value: "P95",
  },
  P99: {
    label: "P99",
    value: "P99",
  },
};
export const StatisticalOptions = Object.values(StatisticalMap);

export const DEFAULT_PAGE: DashboardConfig = {
  id: moment().toISOString() + sessionStorage.getItem("uid"),
  creator: getCookie(UID) || "unknown",
  name: getSharkText("config_page_unnamed_dashboard"),
  cards: [
    {
      id: "filterContainer1",
      type: "filters",
      layout: { i: "filterContainer1", w: 48, h: 3, x: 0, y: 0 },
      filters: [
        {
          id: "filter1",
          name: getSharkText("config_page_example_filter"),
          type: "date",
          allowEmpty: false,
          defaultValue: [
            {
              id: "filter1_value_0",
              // 与当前日期差值
              type: "diffToday",
              // 上一个月
              doubleValue: -30,
            },
            {
              id: "filter1_value_1",
              // 与当前日期差值
              type: "diffToday",
              // 上一个月
              doubleValue: -1,
            },
          ],
          argOfReports: [],
          optionConfig: { type: "custom" },
        },
      ],
    },
    {
      id: "panelId1",
      layout: { i: "panelId1", w: 24, h: 15, x: 0, y: 3 },
      type: "panel",
      chartConfig: {
        id: "panelId1",
        name: getSharkText("config_page_example_dashboard"),
        dataset: -1,
        // table/line/bar/column/pie
        chartType: "bar",
        dimensions: [],
        measures: [],
        filters: [],
        sorters: [],
        viewConfig: {},
      },
    },
  ],
};

export const DEFAULT_PAGE_INFO: PageConfig = {
  id: null,
  menuId: null,
  name: "",
  description: "",
  version: 0,
  config: JSON.stringify(DEFAULT_PAGE),
  userCreate: -1,
  userChange: -1,
};

export const CardTypeList = ["panel", "text", "filters"] as const;

export const CardTypeMap: Record<
  (typeof CardTypeList)[number],
  CardTypeConfig
> = {
  panel: {
    label: getSharkText("config_page_chart"),
    value: "panel",
    createItem: () => {
      const id = `${new Date().toISOString()}_${getCookie(UID)}_panel`;
      return {
        id,
        type: "panel",
        layout: { i: id, w: 24, h: 15, x: 0, y: Infinity },
        chartConfig: {
          id,
          name: getSharkText("config_page_undefined_chart"),
          dataset: -1,
          filters: [],
          dimensions: [],
          measures: [],
          sorters: [],
          viewConfig: {},
          chartType: "table",
        },
      };
    },
  },
  filters: {
    label: getSharkText("config_page_filters"),
    value: "filters",
    createItem: () => {
      const id = `${new Date().toISOString()}_${getCookie(UID)}_panel`;
      return {
        id,
        type: "filters",
        layout: { i: id, w: 48, h: 3, x: 0, y: Infinity },
        filters: [],
      };
    },
  },
  text: {
    label: getSharkText("config_page_text"),
    value: "text",
    createItem: () => {
      const id = `${new Date().toISOString()}_${getCookie(UID)}_text`;
      return {
        id,
        type: "text",
        layout: { i: id, w: 24, h: 9, x: 0, y: Infinity },
      };
    },
  },
};

export const PERCENT_FORMAT_CONFIG: FormatConfig = {
  type: "percent",
};
/** 从extra中获取一个属性的值 */
export const getExVal = (
  col: FDDatasetCol,
  /** 属性名 */
  name: string
): string | number | boolean | null => {
  if (col.extra) {
    try {
      const exObj = JSON.parse(col.extra);
      if (typeof exObj === "object" && Object.keys(exObj).includes(name)) {
        return exObj[name];
      }
    } catch (e) {
      return null;
    }
  }
  return null;
};

/** 当前字段是否是复合字段 */
export const isCombineColumn = (col: FDDatasetCol): boolean => {
  const operator = getExVal(col, "operator");
  return !!operator;
};
/** 是否是计算字段 */
export const isExpCol = (col: FDDatasetCol): boolean => {
  const isExp = getExVal(col, "isExp");
  const computeType = getExVal(col, "computeType");
  return !!isExp && (!computeType || computeType === "m_exp");
};

/** 是否是udf字段 */
export const isPExpCol = (col: FDDatasetCol): boolean => {
  const isExp = getExVal(col, "isExp");
  const computeType = getExVal(col, "computeType");
  return !!isExp && computeType === "p_exp";
};

export const hasSpecialCharText = (v: string): boolean => {
  const regex = /[\n\t\r]/gi;
  return regex.test(v);
};

/** 获取字段的alias */
export const getColumnAlias = (
  col: FDDatasetCol,
  statistics?: StatisticalType
): string => {
  if (col.kind === 1) {
    return col.name || md5(JSON.stringify(col)).toString();
  } else if (col.kind === 0 || col.kind === 2) {
    let key: string = statistics || "UNKNOWN_STATISTICS";
    if (isCombineColumn(col)) {
      key = "SUM";
    } else if (isExpCol(col)) {
      key = "m_exp";
    } else if (isPExpCol(col)) {
      key = "p_exp";
    }
    return key + "_" + col.name;
  } else {
    return md5(JSON.stringify(col)).toString();
  }
};

export const DEFAULT_GRANULARITY_UNIT_FORMAT: Record<
  GranularityUnitType,
  string
> = {
  s: "DD HH:mm:ss",
  m: "DD HH:mm",
  h: "YYYY-MM-DD HH",
  d: "YYYY-MM-DD",
  f: "-",
  W: `YYYY-w${getSharkText("key.week.button")}`,
  M: "YYYY-MM",
  Q: `YYYY-Q${getSharkText("key.quarter.button")}`,
  Y: "YYYY",
};

export const getUnitFromGranularity = (
  v: string
): GranularityUnitType | null => {
  const unit = v.slice(-1) as GranularityUnitType;
  if (GranularityUnitList.includes(unit)) {
    return unit;
  }
  return null;
};

export const DEFAULT_ALLOWED_GRANULARITY: DefaultGranularityType[] = [
  "1d",
  "1W",
  "1M",
  "1Y",
];

export const SortList = ["LONG", "INT", "DOUBLE", null] as const;

export const FDDatasetCol2DimensionSchemaEx = (
  column: FDDatasetCol
): DimensionSchemaEx => {
  return {
    ...column,
    displayName: column.displayName || "",
    name: column.name || "",
    id: column.id || undefined,
    isDate: isDateCol(column),
  };
};

export const FDDatasetCol2MeasureSchemaEx = (
  column: FDDatasetCol
): MeasureSchemaEx => {
  return {
    ...column,
    displayName: column.displayName || "",
    name: column.name || "",
    id: column.id || undefined,
    sort: (column.sort as Sort) || "DOUBLE",
    aggs: (column.aggs?.split(",") || []) as Agg[],
    isMExp: isExpCol(column),
    isPExp: isPExpCol(column),
  };
};

/** 从mytrixQueryResponse中获取返回的数据表, 或者错误码 */
export const getDataFromRes = (res: any): Obj[] | number | undefined => {
  if (res?.ResponseStatus?.Ack === "Success") {
    const resBody: StandardResponse = JSON.parse(res.data);
    if (resBody.status === 0) {
      return DataRow2ListMap(resBody.rows, resBody.headers);
    } else {
      ubtUtils.error("getDataFromRes", res);
      return resBody.status;
    }
  }
};
