import React, { useEffect, useMemo } from "react";
import { IFlightRecord } from "../../FlightManageInterface";
import { AVDataEntity, FLPDataEntity, GetAVDataRequestType } from "./GetAVData";
import { CHART_TABLE_CODE, MODULE_CODE } from "../../fetchCode";
import { isOneCityMultiPort, useFetch } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import moment from "moment";
import { DATE_FORMAT, EMPTY_ARRAY } from "Constants";
import { CLIENT_APP_ID, CLIENT_IP } from "../../FlightManageCommon";
import { uniq } from "lodash";
import { UserInfo } from "Interface";

export interface IavFlpServiceProps {
  records: IFlightRecord[];
  avFlpShow: boolean;
  route: string | undefined;
  airline: string;
  userInfo: UserInfo;
}

/**
 *  Component description
 */
const useAvFlp = (
  props: IavFlpServiceProps
): {
  avData: AVDataEntity[];
  flpData: FLPDataEntity[];
  loadingStart: string;
  loadingEnd: string;
} => {
  const { route, airline, records, avFlpShow, userInfo } = props;
  const [avData, setAvData] = React.useState<AVDataEntity[]>([]);
  const [flpData, setFlpData] = React.useState<FLPDataEntity[]>([]);
  const [loadingStart, setLoadingStart] = React.useState<string>("");
  const [loadingEnd, setLoadingEnd] = React.useState<string>("");
  const [loadedEnd, setLoadedEnd] = React.useState<string>("");
  const allDates = useMemo(() => {
    return uniq(records.map((r) => r.takeoffdate)).sort();
  }, [records]);

  const [, fetchAVData, clearFetch] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE.table,
    },
    lazey: true,
    debugId: "GetAVData",
  });

  useEffect(() => {
    setAvData(EMPTY_ARRAY);
    setFlpData(EMPTY_ARRAY);
    setLoadingStart("");
    setLoadingEnd("");
    setLoadedEnd("");
    clearFetch();
  }, [clearFetch, records]);

  const reqAVdata = useRefFunc((startDate, endDate) => {
    const routePort = route;
    if (!routePort) {
      return;
    }
    const dport = routePort.substring(0, 3);
    const aport = routePort.substring(3, 6);
    const checkDCity = isOneCityMultiPort(dport);
    const dcity = checkDCity ? checkDCity[0] : dport;
    const checkACity = isOneCityMultiPort(aport);
    const acity = checkACity ? checkACity[0] : aport;
    const routeCity = dcity + acity;

    setLoadingStart(startDate);
    setLoadingEnd(endDate);

    const reqParam: GetAVDataRequestType = {
      Head: { ClientAppID: CLIENT_APP_ID, ClientIP: CLIENT_IP },
      seg: routeCity,
      startDate,
      endDate,
      airline,
      userGroup: userInfo.unitList ? userInfo.unitList[0].id.toString() : "-1",
    };
    fetchAVData({
      ext: {
        serviceCode: "22299",
        operation: "GetAVData",
        requestBody: JSON.stringify(reqParam),
      },
    })
      .then((res) => {
        if (res?.ResponseStatus.Ack === "Success") {
          const soaResponse = JSON.parse(res.responseBody);
          if (soaResponse.ResponseStatus.Ack === "Success") {
            const resAvData = (soaResponse.avData as AVDataEntity[]) || [];
            const resFlpData = (soaResponse.flpData as FLPDataEntity[]) || [];
            setAvData((s) => [...s, ...resAvData]);
            setFlpData((s) => [...s, ...resFlpData]);
          }
        }
      })
      .finally(() => {
        const idx = allDates.indexOf(endDate);
        if (idx >= 0 && idx !== allDates.length - 1) {
          const nextStartDate = allDates[idx + 1];
          const day2End = moment(nextStartDate)
            .add(2, "day")
            .format(DATE_FORMAT);
          const day1End = moment(nextStartDate)
            .add(1, "day")
            .format(DATE_FORMAT);
          const nextEndDate = allDates.includes(day2End)
            ? day2End
            : allDates.includes(day1End)
            ? day1End
            : startDate;
          reqAVdata(nextStartDate, nextEndDate);
        } else {
          setLoadingStart("");
          setLoadingEnd("");
          setLoadedEnd(endDate);
        }
      });
  });

  /** 获取AV数据 */
  useEffect(() => {
    if (!avFlpShow) {
      return;
    }
    if (!allDates.length) {
      return;
    }
    if (loadingStart || loadedEnd) {
      return;
    }
    const startDate = allDates[0];
    const day2End = moment(startDate).add(2, "day").format(DATE_FORMAT);
    const day1End = moment(startDate).add(1, "day").format(DATE_FORMAT);
    const endDate = allDates.includes(day2End)
      ? day2End
      : allDates.includes(day1End)
      ? day1End
      : startDate;
    reqAVdata(startDate, endDate);
  }, [allDates, avFlpShow, loadedEnd, loadingStart, reqAVdata]);

  const avFLpData = useMemo(() => {
    return { avData, flpData, loadingStart, loadingEnd };
  }, [avData, flpData, loadingEnd, loadingStart]);

  return avFLpData;
};
export default useAvFlp;
