import React, { useState, useEffect, CSSProperties, useMemo } from "react";
import { Select, Spin } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AirlineItem } from "Interface";
import _ from "lodash";
import { getServer } from "Service/server";
import useGlobalState from "Store";

interface IProps {
  title?: string;
  queryUrl: string;
  moduleCode: string;
  flightNO: string[];
  setFlightNO: (list: string[]) => void;
  isDemo: boolean;
  type?: number;
  style?: CSSProperties;
  route: string[];
}
const queryExt = {
  partner: "travix",
  partnerName: "travix",
  compareType: 0,
  marketType: 0,
};

const { Option } = Select;

const SelectMultiFlight: React.FC<IProps> = (props: IProps) => {
  const {
    queryUrl,
    title = getSharkText("config_page_flight") + "：",
    moduleCode,
    flightNO,
    setFlightNO,
    isDemo,
    type = 2,
    style,
    route,
  } = props;
  const [flightList, setFlightList] = useState<AirlineItem[]>([]);
  const [filterList, setFilterList] = useState<AirlineItem[]>([]);
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const queryWithExt = useMemo(
    () => ({ ...queryCondition, ...queryExt }),
    [queryCondition]
  );
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(4),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode: null,
    },
    query: queryWithExt,
    ext: {
      code: route.length ? route : null,
      type,
    },
  });

  useEffect(() => {
    doFetch({
      query: queryWithExt,
      ext: {
        code: route.length ? route : null,
        type,
      },
    });
  }, [doFetch, queryWithExt, route, type]);

  useEffect(() => {
    if (data) {
      const arr = _.uniqWith(
        data,
        (arrVal: AirlineItem, othVal: AirlineItem) =>
          arrVal.flightNO === othVal.flightNO
      );
      const filterArr = arr.slice(0, 20);
      setFlightList(arr);
      setFilterList(filterArr);
    }
  }, [data]);

  if (error) {
    return null;
  }

  const handleChange = (value: string[]) => {
    setFlightNO(value);
  };

  const handleSearch = (value: string) => {
    const filterArr = flightList.filter((item) =>
      item.flightNO?.includes(value.toLocaleUpperCase())
    );
    if (filterArr) {
      setFilterList(filterArr.slice(0, 20));
    }
  };

  return (
    <Spin spinning={isLoading}>
      {title ? (
        <span className="search-area-filter-title">{title}</span>
      ) : undefined}
      <Select
        className="search-airlines"
        disabled={isDemo}
        mode="multiple"
        notFoundContent="NO Data"
        placeholder={getSharkText("key.mutiple_flightno.query")}
        value={flightNO}
        onChange={handleChange}
        onSearch={handleSearch}
        style={{ width: 200, ...style }}
      >
        {filterList.map((item: AirlineItem, idx: number) =>
          item.flightNO ? (
            <Option key={idx} value={item.flightNO}>
              {item.flightNO}
            </Option>
          ) : undefined
        )}
      </Select>
    </Spin>
  );
};

export default SelectMultiFlight;
