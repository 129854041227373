// Created by xh_zhu on 2021-01-29

import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  durationFormatter,
  genLoadFloat,
  genSeriesByDimensions,
  isSame,
  listMap2dataSet,
  showNum,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import { DATE_FORMAT, DATE_MINUTE_FORMAT } from "Constants";
import { isEmpty, isNumber, round } from "lodash";
import { ISeries } from "Interface";
import EChartsBase from "Components/EChartsBase";
import { IFlightRecord } from "../../FlightManageInterface";

// 数据请求接口, type从1开始, 对应航班,携程,预测
interface IType {
  name: string;
  isPercent: boolean;
}
interface ILoadTrendProps {
  record: IFlightRecord;
  compareDate?: string;
  compareFlightNo?: string;
  filledData: any[];
  isLoading: boolean;
  onChartInstanceChange: (v: any) => void;
  view: 0 | 1;
}

/**
 * 1. 请求数据时设置dataFilled为[],
 * 2. 请求数据后对携程和预测数据进行起飞小时重算,并且合并数据, 放入dataMerged
 * 3. 根据dataMerged计算最大最小值, 并且上报到parent组件
 * 4. 监听最大最小值, 并且填充空白维度数据,放入dataFilled
 * 5. 监听dataFilled并且更新option
 */
const LoadTrend = (props: ILoadTrendProps): ReactElement => {
  const {
    record,
    compareDate,
    compareFlightNo,
    filledData,
    isLoading,
    onChartInstanceChange,
    view,
  } = props;
  const [option, setOption] = useState<any>(undefined);
  const ref = useRef<any>();
  /**
   * 引用takeOffDate的变更
   */
  const tkRef = useRef("");
  tkRef.current = moment(record.takeoffdate).format(DATE_FORMAT);
  const compareDiffDays = compareDate
    ? moment(compareDate).diff(moment(tkRef.current), "days")
    : 0;

  const types: IType[] = useMemo(
    () => [
      { name: "", isPercent: true },
      {
        name: `${record.flightNo}${getSharkText("key.loadfactor")}`,
        isPercent: true,
      },
      {
        name: getSharkText("config_page_flight_target_seat_rate"),
        isPercent: true,
      },
      {
        name: getSharkText("config_page_route_seat_rate"),
        isPercent: true,
      },
      {
        name: `${compareFlightNo}${getSharkText("key.loadfactor")}`,
        isPercent: true,
      },
    ],
    [compareFlightNo, record.flightNo]
  );

  const defaultSeries: ISeries[] = useMemo(
    () => [
      {
        name: `${types[1].name}`,
        type: "line",
        connectNulls: true,
        symbol: "diamond",
        symbolSize: 6,
        showAllSymbol: true,
        itemStyle: {
          color: "#01C5DB",
        },
        lineStyle: {
          color: "#01C5DB",
        },
        encode: {
          x: "d",
          y: "lf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[1].name}`,
        type: "line",
        connectNulls: true,
        symbol: "diamond",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#01C5DB",
        },
        itemStyle: {
          color: "#01C5DB",
        },
        encode: {
          x: "d",
          y: "lfCompare",
        },
      },
      {
        name: `${types[4].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "comLf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[4].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "comLfCompare",
        },
      },
      {
        name: `${types[2].name}`,
        type: "bar",
        connectNulls: true,
        symbol: "triangle",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          color: "#177DFE",
        },
        itemStyle: {
          color: "#177DFE",
        },
        encode: {
          x: "d",
          y: "predictLf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[2].name}`,
        type: "bar",
        connectNulls: true,
        symbol: "triangle",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "predictLfCompare",
        },
      },
      {
        name: `${types[3].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          color: "#E96B5B",
        },
        itemStyle: {
          color: "#E96B5B",
        },
        encode: {
          x: "d",
          y: "lineLf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[3].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#E96B5B",
        },
        itemStyle: {
          color: "#E96B5B",
        },
        encode: {
          x: "d",
          y: "lineLfCompare",
        },
      },
    ],
    [types]
  );

  useEffect(() => {
    if (ref) {
      try {
        onChartInstanceChange(ref.current.getEchartsInstance());
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      if (isLoading) {
        ref.current.showLoading();
      } else {
        ref.current.hideLoading();
      }
    }
  }, [isLoading]);

  useEffect(() => {
    if (option && ref && ref.current) {
      ref.current.setOption(option, { notMerge: true });
    }
  }, [option]);
  /**
   * 根据不同的view切换不同的x轴配置
   */
  const xAxis = useMemo(() => {
    return {
      type: "category",
      name: getSharkText("config_page_days_from_takeoff"),
      nameLocation: "start",
      nameTextStyle: {
        padding: [25, 35, 0, 0],
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        overflow: "truncate",
        interval:
          view === 0
            ? (index: number, value: string) => {
                const hours = moment(value).hour();
                // 仅显示整天的数据
                const tmp = hours % 24;
                if (tmp !== 0) {
                  return false;
                }
                return true;
              }
            : undefined,
        formatter: (val: number) => {
          if (view === 0) {
            const diff = moment(record.takeoffdate).diff(
              moment(val),
              "seconds"
            );
            // const hours = round(val);
            const rst = durationFormatter(diff, "h");
            return rst;
          } else {
            return moment(val).format(DATE_FORMAT);
          }
        },
      },
    };
  }, [record.takeoffdate, view]);

  /**
   * 生成echarts的配置
   */
  useEffect(() => {
    if (filledData.length) {
      const dataSource2 =
        Array.isArray(filledData) && listMap2dataSet(filledData);
      const dimensions = Array.isArray(dataSource2) ? dataSource2[0] : [];
      const xStart = moment(filledData[0].d).valueOf();
      const xEnd = moment(filledData[filledData.length - 1].d).valueOf();
      // 起始轴, 百分比展示
      let sliderStart = 0;
      if (view === 0 && xStart !== undefined && xEnd !== undefined) {
        let days15 = xStart + 15 * 24 * 3600 * 1000;
        if (days15 > xEnd) {
          days15 = xEnd;
        }
        // sliderStart =
        //   100 - ((defaultSliderStart - xEnd) / (xStart - xEnd)) * 100;
        sliderStart = 100 - ((xEnd - days15) / (xEnd - xStart)) * 100;
      }
      console.log("sliderStart", sliderStart);
      const defaultShowLegend = ["lf", "comLf", "lfCompare", "comLfCompare"];

      const legendSelected = compareDate
        ? genSeriesByDimensions(dimensions, defaultSeries).reduce(
            (total: any, item: ISeries) => {
              total[item.name] = defaultShowLegend.includes(item.encode.y);
              return total;
            },
            {}
          )
        : undefined;

      const opt = {
        color: [
          "#01C5DB",
          "#FC9B4F",
          "#177DFE",
          "#E96B5B",
          "#6950a1",
          "#769149",
        ],
        title: {
          text: getSharkText("key.loadfactor"),
          // textVerticalAlign: 'middle'
          top: "center",
          left: "2%",
        },
        dataset: {
          dimensions,
          source: dataSource2,
        },
        legend: {
          itemGap: 20,
          itemWidth: 16,
          itemHeight: 8,
          top: 0,
          selected: legendSelected,
        },
        dataZoom: [
          {
            type: "inside",
            start: sliderStart,
            filterMode: "weakFilter",
          },
          {
            type: "slider",
            bottom: 0,
            start: sliderStart,
            filterMode: "weakFilter",
            labelFormatter: (val: string, valStr: string) => {
              if (view === 0) {
                const seconds = moment(record.takeoffdate).diff(
                  moment(valStr),
                  "seconds"
                );
                return durationFormatter(seconds, "d");
              } else {
                return moment(valStr).format(DATE_FORMAT);
              }
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: (params: any) => {
            if (view === 0) {
              // console.log('load', params);
              const dayIndex = params[0].dimensionNames.indexOf("d");
              const curDayMo = moment(params[0].data[dayIndex]);
              const currentDay = curDayMo.format(DATE_MINUTE_FORMAT);
              const compareDay = compareDiffDays
                ? curDayMo
                    .add(compareDiffDays, "days")
                    .format(DATE_MINUTE_FORMAT)
                : null;
              // 250时当前起飞航班文字最长宽度, 11px是线条颜色点的宽度
              const day = `<span style="width: 250px; margin-left:11px; display:inline-block">${currentDay}</span>${
                compareDay
                  ? `<span style="width: 250px; display:inline-block">${compareDay}</span>`
                  : ""
              }`;
              let dom = "";
              types.forEach((type: IType, tyIdx: number) => {
                if (!type.name) {
                  return;
                }
                const baseSeriesName = `${type.name}`;
                const compareBaseSeriesName = `${getSharkText(
                  "key.compare.name"
                )}${baseSeriesName}`;
                const series = params.filter(
                  (item: any) =>
                    item.seriesName === baseSeriesName ||
                    item.seriesName === compareBaseSeriesName
                );
                if (
                  series.length > 0 &&
                  series.some((s: any) => s.value[s.encode.y[0]] !== null)
                ) {
                  const dot = `<span class='dot' style='background:${series[0].color}'></span>`;
                  const currentSeries = series.find(
                    (item: any) => item.seriesName === baseSeriesName
                  );
                  // 起飞时间
                  const tkTimeBase = tyIdx === 1 ? "day" : "dayCom";
                  const currentVal =
                    currentSeries &&
                    currentSeries.value[currentSeries.encode.y[0]];
                  const currentTk = currentSeries
                    ? moment(
                        currentSeries.value[
                          currentSeries.dimensionNames.indexOf(tkTimeBase)
                        ]
                      )
                    : undefined;
                  const currentMinute =
                    currentTk && currentTk.isValid()
                      ? currentTk.format("HH:mm")
                      : "--";
                  const currentTkStr = [1, 4].includes(tyIdx)
                    ? ` | ${currentMinute}`
                    : "";
                  const currentDom = `${baseSeriesName} | ${
                    isNumber(currentVal)
                      ? type.isPercent
                        ? tyIdx === 4
                          ? genLoadFloat(currentVal * 100, "percentage", 100)
                          : showNum(currentVal * 100, "percentage")
                        : showNum(currentVal)
                      : "--"
                  }${currentTkStr}`;
                  let compareDom;
                  if (compareDate) {
                    const compareSeries = series.find(
                      (item: any) => item.seriesName === compareBaseSeriesName
                    );
                    const compareVal =
                      compareSeries &&
                      compareSeries.value[compareSeries.encode.y[0]];
                    const compareTkTime = compareSeries
                      ? moment(
                          compareSeries.value[
                            compareSeries.dimensionNames.indexOf(
                              `${tkTimeBase}Compare`
                            )
                          ]
                        )
                      : null;
                    const compareTKMinute =
                      compareTkTime && compareTkTime.isValid()
                        ? compareTkTime.format("HH:mm")
                        : "--";
                    const compareTkStr = [1, 4].includes(tyIdx)
                      ? ` | ${compareTKMinute}`
                      : "";
                    compareDom = `${compareBaseSeriesName} | ${
                      isNumber(compareVal)
                        ? type.isPercent
                          ? tyIdx === 4
                            ? genLoadFloat(compareVal * 100, "percentage", 100)
                            : showNum(compareVal * 100, "percentage")
                          : showNum(compareVal)
                        : "--"
                    }${compareTkStr}`;
                  }
                  const val = `<span style="width: 250px; display:inline-block">${currentDom}</span>${
                    compareDom ? `<span>${compareDom}</span>` : ""
                  }`;
                  dom = dom + "<br />" + dot + val;
                }
              });
              if (isEmpty(dom)) {
                return null;
              }
              dom = day + dom;
              return dom;
            } else if (view === 1) {
              const day = moment(params[0].data[params[0].encode.x[0]]).format(
                DATE_FORMAT
              );
              let dom = "";
              params.forEach((item: any) => {
                const dot = `<span class='dot' style='background:${item.color}'></span>`;
                const valIdx = item.encode.y[0];
                const itemValue = item.value[valIdx];
                const value = showNum(itemValue * 100, "percentage");
                const val = `${item.seriesName}: ${value}`;
                dom = dom + "<br />" + dot + val;
              });
              dom = day + dom;
              return dom;
            }
          },
        },
        xAxis,
        yAxis: [
          {
            type: "value",
            splitNumber: 4,
            min: (v: any) => v.min * 0.9,
            axisLabel: {
              formatter: (value: number) => `${round(value * 100, 2)} %`,
            },
          },
          {
            show: true,
            splitNumber: 4,
            splitLine: {
              show: false,
            },
          },
        ],
        series: genSeriesByDimensions(dimensions, defaultSeries),
      };
      setOption((o: any) => (isSame(o, opt) ? o : opt));
    } else {
      const emptyConfig = {
        graphic: {
          id: "noData",
          type: "text",
          $action: "merge",
          z: 100,
          left: "center",
          top: "center",
          style: {
            fill: "#666",
            text: [
              getSharkText("config_page_data_empty"),
              getSharkText("config_page_please_reselect_filter_conditions"),
            ].join("\n"),
            font: "26px Microsoft YaHei",
          },
        },
      };
      setOption((o: any) => (isSame(o, emptyConfig) ? o : emptyConfig));
    }
  }, [
    compareDate,
    compareDiffDays,
    defaultSeries,
    filledData,
    record.takeoffdate,
    types,
    view,
    xAxis,
  ]);

  return <EChartsBase ref={ref}></EChartsBase>;
};
export default LoadTrend;
