// Created by xh_zhu on 2021-03-11

import { Card, Col, Row } from "antd";
import LinesCharts from "./LinesCharts";
import SaleCharts from "./SaleCharts";
import TopCharts from "./TopCharts";
import React, { ReactElement, ReactNode, useMemo, useState } from "react";
import useGlobal from "Store";
import Search from "./Search";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { ICard, IQueryExt } from "../Interface";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AirlinesQueryCondition } from "Interface";
import { trendColumns } from "./LinesCharts/downloadColumns";

/**
 * Component description
 * 市场趋势分为上下两块, 内容基本共用, 唯独销量特别
 */
const panelTypeName = [
  getSharkText("menu_industry_trend_airlines"),
  getSharkText("config_page_ctrip_perspective"),
];
interface IAirportIndexPanelProps {
  panelType: 0 | 1;
  alert?: ReactNode;
  airportsExt: Partial<AirlinesQueryCondition>;
  example?: string;
}

const Panel = (props: IAirportIndexPanelProps): ReactElement => {
  const { panelType, airportsExt } = props;
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [tmpExt, setQueryExt] = useState<IQueryExt>();
  const queryExt: IQueryExt | undefined = useMemo(() => {
    if (!tmpExt) return undefined;
    return {
      ...tmpExt,
      query: {
        ...tmpExt?.query,
        ...(airportsExt as any),
      },
    };
  }, [airportsExt, tmpExt]);
  const [card, setCard] = useState<ICard>();
  const isAirport = isAirportMode(systemType);
  const targetName = isAirport
    ? queryCondition.airportName
    : airlinesQueryCondition.airlinesName;

  return (
    <div className="content-white">
      <h1 style={{ color: "#1890FF" }} className={"row-title"}>
        {queryExt?.ext.together === 1
          ? getSharkText("config_page_together_flight_route")
          : ""}
        {panelTypeName[panelType]}
      </h1>

      <Search
        onChangeVal={setQueryExt}
        panelType={panelType}
        onChangeCard={setCard}
      />

      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          {!queryExt || !queryExt.query || !card ? undefined : (
            <LinesCharts
              queryUrl={QUERY_URL[systemType].lineCharts}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
              ext={queryExt.ext}
              query={queryExt.query}
              card={card}
              style={{ height: "532px" }}
              compareType={queryExt.query.compareType}
              panelType={panelType}
              downloadColumns={trendColumns}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          {!queryExt || !queryExt.query || !card ? undefined : (
            <Card className="barline-charts" style={{ height: "532px" }}>
              <Row>
                <Col lg={12} md={12} xs={24}>
                  <TopCharts
                    queryUrl={QUERY_URL[systemType].top10}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].top10}
                    ext={queryExt.ext}
                    query={queryExt.query}
                    card={card}
                    cardCode={
                      !isAirport
                        ? panelType === 0
                          ? card.cardCode
                          : "search_index"
                        : ""
                    }
                    title={
                      !isAirport
                        ? panelType === 0
                          ? `${getSharkText("config_page_market_top10")} ${
                              card.cardLabel
                            }${
                              queryExt?.ext.together === 1
                                ? getSharkText(
                                    "config_page_together_flight_route"
                                  )
                                : getSharkText("key.route.name")
                            }`
                          : getSharkText(
                              "config_page_market_top10_search_flight_route"
                            )
                        : ""
                    }
                    type={0}
                    style={{ height: "532px" }}
                    panelType={panelType}
                    compareType={queryExt.query.compareType}
                  />
                </Col>
                <Col lg={12} md={12} xs={24}>
                  <TopCharts
                    queryUrl={QUERY_URL[systemType].top10}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].top10}
                    ext={queryExt.ext}
                    query={queryExt.query}
                    card={card}
                    title={
                      !isAirport
                        ? panelType === 0
                          ? `${targetName}Top10${card.cardLabel}${
                              queryExt?.ext.together === 1
                                ? getSharkText(
                                    "config_page_together_flight_route"
                                  )
                                : getSharkText("key.route.name")
                            }`
                          : `${targetName}Top10 ${getSharkText(
                              "config_page_booking_route"
                            )}`
                        : ""
                    }
                    type={1}
                    cardCode={
                      !isAirport
                        ? panelType === 0
                          ? card.cardCode
                          : "ticket"
                        : ""
                    }
                    style={{ height: "532px" }}
                    panelType={panelType}
                    compareType={queryExt.query.compareType}
                  />
                </Col>
              </Row>
            </Card>
          )}
        </Col>
      </Row>
      {panelType !== 1 ? undefined : (
        <Row style={{ marginTop: "16px", flexFlow: "column" }}>
          <Col>
            {!queryExt || !queryExt.query || !card ? undefined : (
              <SaleCharts
                title={"当日销量"}
                queryUrl={QUERY_URL[systemType].sale}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].sale}
                ext={queryExt.ext}
                query={queryExt.query}
                card={card}
                style={{ height: "532px" }}
                panelType={panelType}
                compareType={queryExt.query.compareType}
              />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};
export default Panel;
