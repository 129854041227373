export const MODULE_CODE = "monitoring_alerting_airlines";

export const CHART_TABLE_CODE = {
  popup: "non",
  setPopup: "non",
  setRule: "non",
  queryRule: "non",
};

export const QUERY_URL = {
  popup: "queryMonitoringAlertingPopup",
  popupSearch: "queryMonitoringAlertingPopupSearch",
  setPopup: "setMonitoringAlertingPopupStatus",
  setRule: "setMonitoringAlertingRule",
  delRule: "deleteMonitoringAlertingRuleInfo",
  queryRule: "queryMonitoringAlertingRuleInfo",
  getQR: "getWechatQrcode",
};
