import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { AggCode, AirportCompareQueryExt } from "Interface";
import useGlobal from "Store";
import { fillCharts, getBetweenDateArr, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import Charts from "./charts";
import { PERCENT_VALUES } from "Constants";
import { getServer } from "Service/server";

interface ChartsProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  cardCode: string;
  airportCompareQueryExt: AirportCompareQueryExt;
  height?: number;
  style?: React.CSSProperties;
}

interface Data {
  day: string;
  value: number;
  compareValue: number;
}
const noTotal = ["load_factor", "ow_price"];

const CompareBarCharts: React.FC<ChartsProps> = (props: ChartsProps) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    airportCompareQueryExt,
    cardCode,
  } = props;
  const [aggCode, setAggCode] = useState<AggCode>(0);
  // 设置累计&每日
  const [type, setType] = useState<0 | 1>(0);
  // 每日
  const [dayData, setDayData] = useState<Data[]>([]);
  // 累计
  const [totalData, setTotalData] = useState<Data[]>([]);

  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const { startDate, endDate, airport } = queryCondition;
  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryCondition,
    ext: {
      ...airportCompareQueryExt,
      cardCode,
      aggCode,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query: queryCondition,
      ext: { ...airportCompareQueryExt, cardCode, aggCode },
    });
  };

  useEffect(() => {
    refetch();
  }, [queryCondition, airportCompareQueryExt, cardCode, aggCode]);

  useEffect(() => {
    if (noTotal.includes(cardCode) && type === 0) {
      setType(1);
    }
  }, [cardCode, type]);

  useEffect(() => {
    if (_.isEmpty(data)) {
      setDayData([]);
      setTotalData([]);
    } else {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value ? _.round(item.value) : 0;
        item.compareValue = item.compareValue ? _.round(item.compareValue) : 0;
        return item;
      });
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(startDate), moment(endDate), aggCode)
      );
      let value = 0;
      let compareValue = 0;
      const grandTotleData = fillData.reduce((accumulator, current) => {
        value = value + current.value;
        compareValue = compareValue + current.compareValue;
        const tmp = {
          day: current.day,
          value,
          compareValue,
        };
        accumulator.push(tmp);
        return accumulator;
      }, []);
      setDayData(fillData);
      setTotalData(grandTotleData);
    }
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleChangeAggCode = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  const handleChangeType = (e: RadioChangeEvent) => {
    setType(e.target.value);
  };

  return (
    <Card>
      <Row justify="space-between">
        <Col>
          <Radio.Group value={aggCode} onChange={handleChangeAggCode}>
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Radio.Group value={type} onChange={handleChangeType}>
            {!noTotal.includes(cardCode) && (
              <Radio.Button value={0}>
                {getSharkText("key.cumulative_value.name")}
              </Radio.Button>
            )}
            <Radio.Button value={1}>
              {getSharkText("key.daily.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <Charts
          aggCode={aggCode}
          isPercentVal={isPercentVal}
          loading={isLoading}
          data={type ? dayData : totalData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          airport={airport}
          compareAirport={
            airportCompareQueryExt.compareAirport.id
              ? airportCompareQueryExt.compareAirport.maskName
              : airportCompareQueryExt.compareAirport.airport
          }
        />
      )}
    </Card>
  );
};

export default CompareBarCharts;
