export const MODULE_CODE: any = {
  1: "user_profile_airport",
  2: "user_profile_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    barLineCharts: "capacity_compare",
    table: "target_table",
    download: "search_order_table",
  },
  2: {
    barLineCharts: "search_order_table",
    table: "AL0303",
    download: "search_order_table",
  },
};

export const QUERY_URL = {
  tagList: "queryUserProfileAirlineTagList",
  1: {
    tagList: "queryUserProfileAirportTagList",
    barLineCharts: "queryUserProfileAirportChart",
    top10: "querySearchIndexTop10City",
    table: "querySearchIndexTable",
    download: "queryUserProfileAirportDownload",
  },
  2: {
    tagList: "queryUserProfileAirlineTagList",
    barLineCharts: "queryUserProfileAirlineChart",
    top10: "queryAirlinesSearchIndexTop10City",
    table: "queryAirlinesSearchIndexTable",
    download: "queryUserProfileAirlineDownload",
  },
};
