// Created by xh_zhu on 2021-02-24

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Col,
  DatePicker,
  message,
  Radio,
  RadioChangeEvent,
  Row,
} from "antd";
import { DATE_FORMAT, TIME_FORMAT } from "Constants";
import LoadTrend from "./LoadTrend";
import Feedback from "./PriceCalendar/Feedback";
import SuggestTrend from "./SuggestTrend";
import moment from "moment";
import "./PriceTrend.css";
import Trend from "Page/AI/Dashboard/Components/Trend";
import {
  IOnNextReturnCode,
  IRecordTrend,
  onNextReturnMsg,
  TView,
} from "../DynamicPriceInterface";
import * as echarts from "echarts/lib/echarts";
import { getDateWeek } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import FullScreen from "Components/FullScreen";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import useGlobalState from "Store";
import { max, min } from "lodash";

interface IPriceTrendContentProps<T> {
  record: T;
  setModalVisible: (v: boolean) => void;
  onNext: (r: T) => IOnNextReturnCode;
  onPrev: (r: T) => IOnNextReturnCode;
  setRecord: (r: T) => void;
  currentIdx: number;
  maxIdx: number;
  isDemo: boolean;
  pageType: number;
}

const PriceTrendContent = <T extends IRecordTrend>(
  props: IPriceTrendContentProps<T>
): ReactElement => {
  const {
    record,
    onNext,
    onPrev,
    setRecord,
    currentIdx,
    maxIdx,
    isDemo,
    pageType,
  } = props;
  const [compareDate, setCompareDate] = useState<string | undefined>();
  const [compareFlightNo, setCompareFlightNo] = useState<string | undefined>();
  const [loadXStart, setLoadXStart] = useState<number | undefined>(undefined);
  const [loadXEnd, setLoadXEnd] = useState<number | undefined>(undefined);
  const [historyXStart, setHistoryXStart] = useState<number | undefined>(
    undefined
  );
  const [historyXEnd, setHistoryXEnd] = useState<number | undefined>(undefined);
  const [xStart, setXStart] = useState<number | undefined>(undefined);
  const [xEnd, setXEnd] = useState<number | undefined>(undefined);
  const [historyChartInstance, setHistoryChartInstance] =
    useState<any>(undefined);
  const [loadChartInstance, setLoadChartInstance] = useState<any>(undefined);
  const { id } = record;
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  // 视角, 0 = 预售监控, 1= 销售过程
  const [view, setView] = useState<TView>(0);
  record.feTkDate = record.takeoffdate || record.takeofftime;

  useEffect(() => {
    setCompareDate(undefined);
  }, [id]);

  /**
   * 当竞飞/对比/航班任意发生改变时, 清空xStart和xEnd的值
   */
  useEffect(() => {
    setXStart(undefined);
    setXEnd(undefined);
  }, [compareDate, compareFlightNo, record.id]);

  useEffect(() => {
    if (historyChartInstance && loadChartInstance) {
      echarts.connect([historyChartInstance, loadChartInstance]);
    }
  }, [historyChartInstance, loadChartInstance]);

  useEffect(() => {
    const tmpStart = min([historyXStart, loadXStart]);
    const tmpEnd = max([historyXEnd, loadXEnd]);
    setXStart(tmpStart);
    setXEnd(tmpEnd);
  }, [loadXStart, loadXEnd, historyXStart, historyXEnd]);

  const renderDetailHeader = (r: T) => {
    // 计算距今天数使用自然日相减
    const takeoffTimeMoment = moment(r.feTkDate);
    const takeoffDateMoment = moment(takeoffTimeMoment.format(DATE_FORMAT));
    const time =
      pageType === 1 ? r.takeofftime : takeoffTimeMoment.format(TIME_FORMAT);
    const diffDaysFromToday = takeoffDateMoment.diff(
      moment().format(DATE_FORMAT),
      "days"
    );
    return (
      <>
        <Col className="dpr-price-flightno">{r.flightNo}</Col>
        <Col className="dpr-price-route">
          {r.route.substr(0, 3)}-{r.route.substr(3, 3)}
        </Col>
        <Col className="dpr-price-takeoff-date">
          {takeoffTimeMoment.format(DATE_FORMAT)}
        </Col>
        <Col className="dpr-price-weekday">
          {getDateWeek(r.feTkDate as string)}
        </Col>
        <Col className="dpr-price-takeoff-time">
          {time}
          {getSharkText("config_page_takeoff")}
        </Col>
        <Col className="dpr-price-before-depart">
          {getSharkText("config_page_days_from_today")}
          {diffDaysFromToday}
          {getSharkText("key.day.button")}
        </Col>
      </>
    );
  };

  const renderHeaderSuggest = () => {
    const diff = record.suggestPrice - record.rawPrice;
    const color = diff > 0 ? "#D03D33" : "green";
    return (
      <>
        <span className="dpr-control-price-suggest-title">
          {getSharkText("config_page_suggested_price")}
        </span>
        <span style={{ color, marginLeft: 5 }}>{record.suggestPrice}</span>
        <Trend
          style={{ display: "inline-block" }}
          flag={diff > 0 ? "up" : "down"}
          showFlag={diff !== 0}
          downColor="#4eb31c"
        />
        <span style={{ color, margin: "0 10px 0 5px" }}>{Math.abs(diff)}</span>
        <span className="dpr-control-price-suggest-title">原价</span>
        <span style={{ marginLeft: 5 }}>{record.rawPrice}</span>
      </>
    );
  };

  const selectRecord = (r: T, direct: "next" | "prev") => {
    const code = direct === "next" ? onNext(r) : onPrev(r);
    const msg = onNextReturnMsg[code];
    if (code !== 0) {
      message.info({ content: msg, duration: 2 });
    }
  };

  const onChangeDate = (date: any, dateString: any) => {
    setCompareDate(dateString);
  };
  console.log(xStart, xEnd);

  const handleChangeView = useCallback((e: RadioChangeEvent) => {
    setCompareDate(undefined);
    setView(e.target.value);
  }, []);

  const titleExtends = useMemo(
    () =>
      pageType === 0 ? undefined : (
        <>
          <Col span={4}>
            <Radio.Group value={view} onChange={handleChangeView}>
              <Radio.Button value={1}>
                {getSharkText("config_page_presale_view")}
              </Radio.Button>
              <Radio.Button value={0}>
                {getSharkText("config_page_sales_process_view")}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </>
      ),
    [pageType, view]
  );

  return (
    <div className="fullScreen-module">
      <FullScreen
        isFullScreen={isFullScreen}
        onChange={setIsFullScreen}
        titleExtends={titleExtends}
      >
        <div className="dynamic-price-record-detail">
          <Row className="dpr-header">{renderDetailHeader(record)}</Row>
          <Row className="dpr-control">
            <Col className="dpr-control-price">{renderHeaderSuggest()}</Col>
            <Col className="dpr-control-feedback">
              <Feedback
                feedback={record.feedback}
                cause={record.cause}
                setRecord={setRecord}
                record={record}
                passText={getSharkText("config_page_PaAiDyCoPr_accept")}
                refuseText={getSharkText("config_page_refuse")}
                isDemo={isDemo}
              ></Feedback>
            </Col>
            <Col className="dpr-control-compareDate">
              <SelectMultiFlight
                title="对比竞飞："
                queryUrl={QUERY_URL.routeFlight}
                moduleCode={MODULE_CODE}
                flightNO={compareFlightNo || ""}
                setFlightNO={(v) =>
                  setCompareFlightNo(v.length ? v[0] : undefined)
                }
                routes={record.route}
                isDemo={isDemo}
                type={3}
                placeholder={getSharkText("key.mutiple_flightno.query")}
                style={{ width: 150 }}
                mode={undefined}
                allowClear
              />
            </Col>
            {view === 0 ? (
              <>
                {" "}
                <Col className="dpr-control-compareDate">
                  <span
                    className="search-area-filter-title"
                    style={{ width: 100 }}
                  >
                    {getSharkText("config_page_compare_takeoff_date")}：
                  </span>
                  <DatePicker
                    value={(compareDate && moment(compareDate)) || undefined}
                    onChange={onChangeDate}
                  />
                </Col>
                {pageType === 0 ? (
                  <>
                    <Col className="dpr-control-next">
                      <Button
                        type="primary"
                        disabled={currentIdx <= 0}
                        style={{ marginRight: 10 }}
                        onClick={() => selectRecord(record, "prev")}
                      >
                        {getSharkText("config_page_previous_item")}
                      </Button>
                      <Button
                        type="primary"
                        disabled={currentIdx >= maxIdx - 1}
                        onClick={() => selectRecord(record, "next")}
                      >
                        {getSharkText("config_page_next")}
                      </Button>
                    </Col>
                  </>
                ) : undefined}
              </>
            ) : undefined}
          </Row>
          <div className="dynamic-price-record-trend">
            <div style={{ height: "50%" }}>
              <SuggestTrend
                record={record}
                compareDate={compareDate}
                compareFlightNo={compareFlightNo}
                xStart={xStart}
                xEnd={xEnd}
                setXStart={setHistoryXStart}
                setXEnd={setHistoryXEnd}
                onChartInstanceChange={setHistoryChartInstance}
                view={view}
              ></SuggestTrend>
            </div>
            <div style={{ height: "50%" }}>
              <LoadTrend
                record={record}
                compareDate={compareDate}
                compareFlightNo={compareFlightNo}
                xStart={xStart}
                xEnd={xEnd}
                setXStart={setLoadXStart}
                setXEnd={setLoadXEnd}
                onChartInstanceChange={setLoadChartInstance}
                view={view}
              ></LoadTrend>
            </div>
          </div>
        </div>
      </FullScreen>
    </div>
  );
};
export default PriceTrendContent;
