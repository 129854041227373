import React, { useEffect, useState } from "react";
import { DatePicker, message } from "antd";
import moment, { Moment } from "moment";
import { getDateRange, isAirportMode, isHistoryToFuture } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import { DATE_MODES } from "Constants";
import { EDateRangeGroupsNames, IDateMode } from "Interface";
import { getDateRangeGroupName, GetGroupDateRangeValue } from "Utils/global";
import { RangePickerProps } from "antd/es/date-picker/generatePicker";
import { useLocation } from "react-router-dom";

interface DateRangePickerProps {
  defaultDateMode: IDateMode;
  shouldDisableDate?: boolean;
  otherProps?: RangePickerProps<Moment>;
}

const { RangePicker } = DatePicker;
const DateRangePicker: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  const { defaultDateMode, shouldDisableDate = true, otherProps } = props;
  const location = useLocation();
  const { pathname } = location;
  const [, setRangeMode] = useState(defaultDateMode);
  const [dateRange, setDateRange] = useState<any>(
    GetGroupDateRangeValue(pathname) || getDateRange(defaultDateMode)
  );
  const [isShowPicker, setIsShowPicker] = useState(false);
  const [globalState, actions] = useGlobal();
  const {
    queryCondition,
    airlinesQueryCondition,
    systemType,
    dateRangeInGroup,
  } = globalState;
  const {
    setQueryCondition,
    setQueryConditionAttr,
    setAirlinesQueryCondition,
    setDateRangeInGroup,
  } = actions;

  const isFlightPlan = pathname === "/flight_plan";
  const isSearchIndex = pathname === "/search_index";
  const isTouristSource = pathname === "/tourist_source";
  const isCompareAirport = pathname === "/airport_compare";
  const isPriceMonitor = pathname === "/price_monitor";
  const isAirport = isAirportMode(systemType);
  const isAirportHome = pathname === "/" && isAirport;

  useEffect(() => {
    const groupName: keyof typeof EDateRangeGroupsNames | undefined =
      getDateRangeGroupName(pathname);
    if (groupName) {
      if (dateRangeInGroup[groupName].length > 0) {
        setQueryConditionAttr({
          startDate: dateRangeInGroup[groupName][0],
          endDate: dateRangeInGroup[groupName][1],
        });
      } else {
        const range = getDateRange(defaultDateMode);
        setQueryConditionAttr({
          startDate: range ? range[0].format("YYYY-MM-DD") : "",
          endDate: range ? range[1].format("YYYY-MM-DD") : "",
        });
        // setDateRangeInGroup(range, pathname);
      }
    } else {
      const range = getDateRange(defaultDateMode);
      setQueryConditionAttr({
        startDate: range ? range[0].format("YYYY-MM-DD") : "",
        endDate: range ? range[1].format("YYYY-MM-DD") : "",
      });
    }
  }, []);

  const disabledDate = (current: any) => {
    if (!shouldDisableDate) {
      return false;
    }
    if (isSearchIndex || isCompareAirport || isFlightPlan) {
      return (
        current > moment(new Date()).add(89, "days") ||
        current < moment("20190101", "YYYYMMDD")
      );
    } else if (isCompareAirport) {
      return (
        current > moment(new Date()).add(29, "days") ||
        current < moment("20190101", "YYYYMMDD")
      );
    } else if (isTouristSource) {
      return (
        current > moment(new Date()).add(14, "days") ||
        current < moment(new Date()).add(-15, "day")
      );
    } else if (isPriceMonitor) {
      return current < moment("20190101", "YYYYMMDD");
    } else {
      return (
        current > moment(new Date()).add(-1, "days") ||
        current < moment("20190101", "YYYYMMDD")
      );
    }
  };

  const handleSelectRange = (dates: any, datesString: string[]) => {
    // setRangeMode(DATE_MODES[2].value)
    if ((isCompareAirport || isAirportHome) && isHistoryToFuture(dates)) {
      message.error(getSharkText("key.choose_past_or_future_time.hint"));
    } else {
      setDateRange(dates);
      // setIsShowPicker(false) // 因为升级antd后, 每次操作都会触发onchange,所以这里的关闭事件取消
      if (isAirport) {
        setQueryCondition({
          ...queryCondition,
          startDate: datesString[0],
          endDate: datesString[1],
        });
      } else {
        setAirlinesQueryCondition({
          ...airlinesQueryCondition,
          startDate: datesString[0],
          endDate: datesString[1],
        });
      }
      const groupName: keyof typeof EDateRangeGroupsNames | undefined =
        getDateRangeGroupName(pathname);
      if (groupName) {
        setDateRangeInGroup(
          datesString.map((d: string) => moment(d)),
          pathname
        );
      }
    }
  };

  const handleOpenRange = (open: boolean) => {
    if (open) {
      setRangeMode(DATE_MODES[2].value);
    }
    setIsShowPicker(open);
  };

  return (
    <>
      <RangePicker
        id="toolbarRangePicker"
        allowClear={false}
        value={dateRange}
        disabledDate={disabledDate}
        ranges={
          isFlightPlan
            ? {
                [getSharkText("config_page_next_3_months")]: [
                  moment(new Date()),
                  moment(new Date()).add(3, "months"),
                ],
              }
            : isSearchIndex
            ? {
                [getSharkText("date.last30")]: [
                  moment(new Date()).add(-30, "days"),
                  moment(new Date()).add(-1, "days"),
                ],
                [getSharkText("date.next30")]: [
                  moment(new Date()),
                  moment(new Date()).add(29, "days"),
                ],
              }
            : isTouristSource
            ? undefined
            : {
                [getSharkText("date.last30")]: [
                  moment(new Date()).add(-30, "days"),
                  moment(new Date()).add(-1, "days"),
                ],
              }
        }
        format="YYYY-MM-DD"
        onChange={handleSelectRange}
        open={isShowPicker}
        onOpenChange={handleOpenRange}
        {...otherProps}
      />
    </>
  );
};

export default DateRangePicker;
