// Created by xh_zhu on 2021-03-11

import { FlightArea, Area, IDownloadHeader } from "Interface";
import { isEmpty } from "lodash";
import React, { ReactElement, useState } from "react";
import { showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import CarryOutRateCharts from "./CarryOutRateCharts";

/**
 * Component description
 * Top10航司预计航班执行率
 */

interface ITop10CarryOutRateProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  area: Area;
  height?: number;
  style?: object;
  airlinesOnsaleAirlineExt: FlightArea;
  ext: any;
}

const Top10CarryOutRate = (props: ITop10CarryOutRateProps): ReactElement => {
  const [compareType, setCompareType] = useState<number>(-1);
  const totalSeries = [
    {
      type: "bar",
      name: getSharkText("key.flights_for_sale.chart"),
      encode: {
        x: "airline",
        y: "count",
      },
    },
    {
      type: "line",
      name: getSharkText("key.est_ex_rate.chart"),
      yAxisIndex: 1,
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "airline",
        y: "value",
      },
    },
    {
      type: "line",
      name: getSharkText("key.compared_est_ex_rate.chart"),
      yAxisIndex: 1,
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "airline",
        y: "compareValue",
      },
    },
  ];
  const downloadColumns: IDownloadHeader[] = [
    {
      title: getSharkText("key.airline.flightplan"),
      dataIndex: "airline",
    },
    {
      title: getSharkText("key.flights_for_sale.chart"),
      dataIndex: "count",
      downloadFormatter: (val: number) => showRawNum(val),
    },
    {
      title: getSharkText("key.est_ex_rate.chart"),
      dataIndex: "value",
      downloadFormatter: (val: number) => showRawNum(val, "percentage"),
    },
  ];
  if (compareType !== -1) {
    downloadColumns.push({
      title: getSharkText("key.compared_est_ex_rate.chart"),
      dataIndex: "compareValue",
      downloadFormatter: (val: number) => showRawNum(val, "percentage"),
    });
  }

  const chartsOption = {
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = "";
        // const day = moment(params[0].data[dayIndex]).format(DATE_FORMAT);
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const valueColumnName = item.dimensionNames[item.encode.y];
          const value = item.value[item.encode.y];
          if (value !== null) {
            const val = `${item.seriesName}: ${
              valueColumnName === "count"
                ? showNum(value)
                : showNum(value, "percentage")
            }`;
            dom = dom + `${dom ? "<br />" : ""}` + dot + val;
          }
        });
        if (isEmpty(dom)) {
          return null;
        }
        dom = day + dom;
        return dom;
      },
    },
  };
  return (
    <CarryOutRateCharts
      title={getSharkText("key.top10airlines_ex_rate")}
      {...props}
      queryUrl="queryOnsaleFlightsAirlineExecution "
      totalSeries={totalSeries}
      downloadColumns={downloadColumns}
      chartsOption={chartsOption}
      onCompareChange={setCompareType}
    />
  );
};
export default Top10CarryOutRate;
