import { Spin } from "antd";
import React, { ReactElement } from "react";

export interface FullSpinProps {
  minHeight?: number | string;
}

/**  Component description */
const FullSpin = (props: FullSpinProps): ReactElement => {
  const { minHeight = 200 } = props;
  return (
    <Spin
      style={{
        width: "100%",
        minHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  );
};
export default FullSpin;
