import React, { PropsWithChildren } from "react";
import "./index.scss";

interface ButtonProps {
  fill?: boolean;
  full?: boolean;
  style?: object;
  className?: string;
  loading?: boolean;
  onClick?: () => void;
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = (props) => {
  const {
    fill = false,
    full = false,
    children,
    style,
    onClick,
    className,
    loading = false,
  } = props;

  return (
    <div
      className={`button ${fill ? "fill" : ""} ${full ? "full" : ""} ${
        loading ? "loading" : ""
      } ${className}`}
      style={style}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Button;
