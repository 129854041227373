import { CheckOutlined } from "@ant-design/icons";
import { Col, Row, Tabs } from "antd";
import { Area } from "Interface";
import { cloneDeep } from "lodash";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFetch } from "Utils/useFetch";
import "./AirportSelector.css";

const { TabPane } = Tabs;
/**
 * Component description
 * 机场集团选择器控件
 */

const AlphaGroup = ["ABCDEFG", "HIJ", "KLMN", "PQRSTUVW", "XYZ"];

interface IProps {
  selected: Area[];
  onChange: (v: Area[]) => void;
  multiple?: boolean;
}

const AirportGroupSelector = (props: IProps): ReactElement => {
  const { selected, onChange, multiple = false } = props;
  const [groupSelected, setGroupSelected] = useState<Area[]>([]);

  // 移除外层删除的group
  useEffect(() => {
    setGroupSelected(selected);
  }, [selected]);

  const [{ data }] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 3,
    },
  });

  const groupClick = (group: Area) => {
    const isSelected = groupSelected.find(
      (g: Area) => g.areaName === group.areaName
    );
    let newSelected;
    if (isSelected) {
      newSelected = groupSelected.filter(
        (g: Area) => g.areaName !== group.areaName
      );
    } else {
      if (multiple) {
        newSelected = cloneDeep(groupSelected);
        newSelected.push(group);
      } else {
        newSelected = [group];
      }
    }
    setGroupSelected(newSelected);
    onChange(newSelected);
  };

  const isCurGroupSelected = useCallback(
    (g: Area): boolean => {
      const tmp = groupSelected.find((a: Area) => a.areaName === g.areaName);
      return !!tmp;
    },
    [groupSelected]
  );

  const dataSorted = data.sort((d1: Area, d2: Area) => {
    if (d1.pinyin) {
      return d1.pinyin.localeCompare(d2.pinyin || "");
    } else {
      return 0;
    }
  });

  return (
    <div>
      <Tabs defaultActiveKey="0">
        {AlphaGroup.map((Alpha: string, tabIdx: number) => {
          return (
            <TabPane tab={Alpha} key={tabIdx}>
              {dataSorted
                .filter((d: Area) =>
                  Alpha.includes(
                    d.pinyin && d.pinyin[0] ? d.pinyin[0].toUpperCase() : ""
                  )
                )
                .map((group: Area, index: number) =>
                  group.areaName ? (
                    <div
                      className={
                        isCurGroupSelected(group)
                          ? "group-selected"
                          : "not-selected"
                      }
                      key={index}
                      style={{ marginBottom: 20 }}
                      onClick={() => groupClick(group)}
                    >
                      <span style={{ fontWeight: "bold", fontSize: "120%" }}>
                        {group.areaName}
                      </span>
                      {isCurGroupSelected(group) ? (
                        <span style={{ float: "right", marginRight: "20%" }}>
                          <CheckOutlined />
                        </span>
                      ) : undefined}
                      <Row>
                        {group.children?.map((airport: Area, idx: number) => (
                          <Col span={6} key={idx}>
                            {airport.areaName}
                          </Col>
                        ))}
                      </Row>
                    </div>
                  ) : undefined
                )}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
export default AirportGroupSelector;
