import React, { ReactElement, useEffect, useState } from "react";
import { message, Select } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import SvgIcon from "Components/SvgIcon";
import { IRecordTrend } from "../../DynamicPriceInterface";

import "./Feedback.css";
import { getServer } from "Service/server";

const { Option } = Select;

interface FeedbackProps<T> {
  /** 0=未选择 1=接受 2=拒绝 */
  feedback: number;
  cause?: string;
  record: T;
  setRecord: (record: T) => void;
  // 接受的文字
  passText?: string;
  // 拒绝的文字
  refuseText?: string;
  isDemo: boolean;
}

const causeList = [
  getSharkText("config_page_coordination_factors"),
  getSharkText("config_page_plan_cancellation_change"),
  getSharkText("config_page_slow_guest_seat_progress"),
  getSharkText("config_page_fast_guest_seat_progress"),
  getSharkText("config_page_appropriate_guest_seat_progress"),
  getSharkText("config_page_follow_price_competition"),
  getSharkText("config_page_holiday_factors"),
  getSharkText("config_page_time_advantage"),
  getSharkText("config_page_airline_policy_changes"),
  getSharkText("system_type_Others"),
];

const Feedback = <T extends IRecordTrend>(
  props: FeedbackProps<T>
): ReactElement => {
  const {
    feedback,
    cause,
    record,
    setRecord,
    passText = getSharkText("config_page_yes"),
    refuseText = getSharkText("config_page_no"),
    isDemo,
  } = props;
  const [newRecord, setNewRecord] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);

  const [{ res }, doFetch] = useFetch({
    server: getServer(2),
    url: "setDynamicPriceFeedback",
    head: {
      moduleCode: "dynamic_price_airlines",
      chartTableCode: null,
    },
    ext: {
      row: {},
    },
    lazey: true,
  });

  const refetch = (tmpRecord: any) => {
    setNewRecord(tmpRecord);
    doFetch({
      ext: { row: tmpRecord },
    });
  };

  const hideSelectList = () => {
    setOpen(false);
  };

  useEffect(() => {
    // 当点击任意下拉框以外元素时, 隐藏下拉框
    document.addEventListener("click", hideSelectList);
  }, []);

  useEffect(() => {
    if (!res) {
      return;
    }
    const { flag, errorMsg } = res;
    if (flag) {
      message.success(getSharkText("config_page_operation_success"));
      setRecord(newRecord);
    } else {
      message.error(
        getSharkText("config_page_operation_failed") + `。${errorMsg}`
      );
    }
  }, [res]);

  const handleChangeCause = (val: any) => {
    const tmpRecord = _.cloneDeep(record);
    tmpRecord.feedback = 2;
    tmpRecord.cause = val;
    refetch(tmpRecord);
  };

  const approve = () => {
    const tmpRecord = _.cloneDeep(record);
    tmpRecord.feedback = feedback === 1 ? 0 : 1;
    tmpRecord.cause = "";
    refetch(tmpRecord);
  };

  const refuse = (e: any) => {
    if (feedback === 2) {
      const tmpRecord = _.cloneDeep(record);
      tmpRecord.feedback = 0;
      tmpRecord.cause = "";
      refetch(tmpRecord);
      return;
    }
    setOpen(true);
    // 阻止触发全局的隐藏事件
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  const selectClick = (e: any) => {
    setOpen(!open);
    // 阻止出发全局的隐藏事件
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <>
      <div
        className={`feedback-control-container ${
          isDemo ? "feedback-disabled" : ""
        }`}
        title={isDemo ? getSharkText("config_page_demo_mode_disabled") : ""}
      >
        <span className="feedback-control-item" onClick={approve}>
          <SvgIcon
            name={
              feedback === 1 ? "open_icon_successx" : "icon-purpose-uncheck"
            }
          ></SvgIcon>
          <span>{passText}</span>
        </span>
        <span className="feedback-control-item" onClick={refuse}>
          <SvgIcon name={feedback === 2 ? "refuse1" : "refuse"}></SvgIcon>
          <span>{refuseText}</span>
        </span>
        <span onClick={selectClick}>
          <Select
            value={cause}
            onChange={handleChangeCause}
            style={{ marginLeft: 10, minWidth: 150 }}
            open={open}
            disabled={isDemo}
          >
            {causeList.map((c: string, i: number) => (
              <Option key={i} value={c}>
                {c}
              </Option>
            ))}
          </Select>
        </span>
        {/* <Checkbox checked={feedback === 1} onChange={approve}>{getSharkText("config_page_yes")}</Checkbox>
                <Checkbox checked={feedback === 2} onChange={refuse}>{getSharkText("config_page_no")}</Checkbox> */}
      </div>
    </>
  );
};

export default Feedback;
