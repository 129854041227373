import * as React from "react";

/**
 * 返回始终一致的函数引用
 *
 * Same as `React.useCallback` but always return a memoized function
 * but redirect to real function.
 */
const useRefFunc = <T extends (...args: any[]) => any>(callback: T): T => {
  const funcRef = React.useRef<T>();
  funcRef.current = callback;

  const cacheFn = React.useCallback((...args: any[]) => {
    return funcRef.current && funcRef.current(...args);
  }, []);

  return cacheFn as any;
};
export default useRefFunc;
