import { IDownloadHeader } from "Interface";
import {
  getComparePercentageVal,
  showNum,
  showRawNum,
  tableNumSorter,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { ICard } from "Page/AI/IndustryTrend/Interface";
import { SortOrder } from "antd/lib/table/interface";

export const baseColumns: IDownloadHeader[] = [
  {
    title: getSharkText("config_page_takeoff_time"),
    dataIndex: "takeoffdate",
  },
  {
    title: getSharkText("key.departure.name"),
    dataIndex: "dport",
    sorter: (a: any, b: any) => a.dportName.localeCompare(b.dportName),
    render: (val: number, record: any) =>
      `${record.dportName ?? ""}(${record.dport ?? ""})`,
  },
  {
    title: getSharkText("key.arrival.name"),
    dataIndex: "aport",
    sorter: (a: any, b: any) => a.aportName.localeCompare(b.aportName),
    render: (val: number, record: any) =>
      `${record.aportName ?? ""}(${record.aport ?? ""})`,
  },
];

export const searchColumns = (): IDownloadHeader[] => {
  const commonColumns = [
    {
      title: getSharkText("config_page_market_search_index"),
      dataIndex: "marketValue",
      sorter: (a: any, b: any) => tableNumSorter(a.marketValue, b.marketValue),
      render: (val: number) => showNum(val, "num"),
      downloadFormatter: (val: number) => showRawNum(val, "num"),
    },
    {
      title: getSharkText(
        "config_page_PaAiHoTa_market_search_index_year_on_year"
      ),
      dataIndex: "marketCompareValue",
      sorter: (a: any, b: any, c?: SortOrder) =>
        tableNumSorter(
          getComparePercentageVal(a.marketValue, a.marketCompareValue),
          getComparePercentageVal(b.marketValue, b.marketCompareValue),
          c
        ),
      render: (val: number, record: any) =>
        showNum(getComparePercentageVal(record.marketValue, val), "percentage"),
      downloadFormatter: (val: number, record: any) =>
        showRawNum(
          getComparePercentageVal(record.marketValue, val),
          "percentage"
        ),
    },
  ];
  return baseColumns.concat(commonColumns);
};

export const orderColumns = (
  card: ICard,
  isAirport: boolean
): IDownloadHeader[] => {
  return [
    {
      title: getSharkText("config_page_market_booking_index_annual_comparison"),
      dataIndex: "marketOrderYoy",
      sorter: (a: any, b: any) =>
        tableNumSorter(a.marketOrderYoy, b.marketOrderYoy),
      render: (val: number) => showNum(val, "percentage"),
      downloadFormatter: (val: number) => showRawNum(val, "percentage"),
    },
    {
      title: `${
        isAirport
          ? getSharkText("area.airport.title")
          : getSharkText("system_type_Airline")
      }${getSharkText("config_page_booking_index_annual_comparison")}`,
      dataIndex: "orderYoy",
      sorter: (a: any, b: any) => tableNumSorter(a.orderYoy, b.orderYoy),
      render: (val: number) => showNum(val, "percentage"),
      downloadFormatter: (val: number) => showRawNum(val, "percentage"),
    },
  ];
};

export const priceColumns = (
  card: ICard,
  isAirport: boolean
): IDownloadHeader[] => {
  return [
    {
      title: getSharkText("config_page_market_average_price"),
      dataIndex: "marketPrice",
      sorter: (a: any, b: any) => tableNumSorter(a.marketPrice, b.marketPrice),
      render: (val: number) => showNum(val, "num"),
      downloadFormatter: (val: number) => showRawNum(val, "num"),
    },
    {
      title: `${
        isAirport
          ? getSharkText("config_page_airport_average_price")
          : getSharkText("config_page_airline_average_price")
      }`,
      dataIndex: "price",
      sorter: (a: any, b: any) => tableNumSorter(a.price, b.price),
      render: (val: number) => showNum(val, "num"),
      downloadFormatter: (val: number) => showRawNum(val, "num"),
    },
    {
      title: getSharkText("config_page_market_average_price_year_on_year"),
      dataIndex: "marketComparePrice",
      sorter: (a: any, b: any) =>
        tableNumSorter(
          getComparePercentageVal(a.marketPrice, a.marketComparePrice),
          getComparePercentageVal(b.marketPrice, b.marketComparePrice)
        ),
      render: (val: number, record: any) =>
        showNum(getComparePercentageVal(record.marketPrice, val), "percentage"),
      downloadFormatter: (val: number, record: any) =>
        showRawNum(
          getComparePercentageVal(record.marketPrice, val),
          "percentage"
        ),
    },
    {
      title: `${
        isAirport
          ? getSharkText("area.airport.title")
          : getSharkText("system_type_Airline")
      }${getSharkText("config_page_average_price_year_on_year")}`,
      dataIndex: "comparePrice",
      sorter: (a: any, b: any) =>
        tableNumSorter(
          getComparePercentageVal(a.price, a.comparePrice),
          getComparePercentageVal(b.price, b.comparePrice)
        ),
      render: (val: number, record: any) =>
        showNum(getComparePercentageVal(record.price, val), "percentage"),
      downloadFormatter: (val: number, record: any) =>
        showRawNum(getComparePercentageVal(record.price, val), "percentage"),
    },
  ];
};

export const columns = (card: ICard, isAirport: boolean): IDownloadHeader[] => {
  // switch (card.cardCode) {
  //   case "search_index":
  //     return searchColumns(card);
  //   case "order_index":
  //     return orderColumns(card, isAirport);
  //   case "ow_price":
  //     return priceColumns(card, isAirport);
  // }
  // return searchColumns(card);
  return searchColumns()
    .concat(orderColumns(card, isAirport))
    .concat(priceColumns(card, isAirport));
};
