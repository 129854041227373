export const MODULE_CODE: any = {
  1: "airport_compare_airport",
  2: "",
};

export const CHART_TABLE_CODE: any = {
  1: {
    compareList: "AP0600",
    cards: "AP0601",
    top10City: "AP0602",
    barCharts: "AP0603",
    table: "AP0604",
  },
  2: {},
};

export const QUERY_URL: any = {
  1: {
    compareList: "queryAirportCompareAirportList",
    cards: "queryAirportCompareCard",
    top10City: "queryAirportCompareTop10City",
    barCharts: "queryAirportCompareBarline",
    table: "queryAirportCompareTable",
    share: "queryAirportCompareAirlinesShare",
  },
  2: {},
};
