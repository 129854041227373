import { ISeries, SystemType } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

export const genSeries = (
  panelType: 0 | 1,
  systemType: SystemType
): ISeries[] => {
  const systemTypeName =
    systemType === SystemType.airport
      ? getSharkText("area.airport.title")
      : getSharkText("system_type_Airline");
  return [
    {
      type: "line",
      encode: { x: "day", y: "value" },
      lineStyle: {
        width: 3,
      },
      name: `${
        panelType === 0
          ? getSharkText("config_page_search_pv")
          : getSharkText("config_page_overall_transfer_policy")
      }`,
      showSymbol: false,
      symbol: "circle",
    },
    {
      type: "line",
      encode: { x: "day", y: "compareValue" },
      lineStyle: {
        width: 3,
      },
      name: `${
        panelType === 0
          ? getSharkText("config_page_display_pv")
          : `${systemTypeName}${getSharkText(
              "config_page_system_type_name_transfer_policy_count"
            )}`
      }`,
      showSymbol: false,
      symbol: "circle",
    },
    {
      type: "bar",
      encode: { x: "day", y: "resultValue" },
      barMaxWidth: 20,
      name: getSharkText("config_page_display_rate"),
      yAxisIndex: 1,
    },
  ];
};
