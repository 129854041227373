import { DATE_FORMAT } from "Constants";
import { RangeCompare, RangeObject } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";
import { LunarCalendar } from "Utils/lunar";
import { Button, Col, Popover, Row, Select } from "antd";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import React, { ReactElement, useMemo } from "react";

// #region 同环比选项
const wowList = ["pre7d", "pre30d", "pre60d", "pre90d", "pre180d"] as const;
export type WowType = (typeof wowList)[number];
const wowMap: Record<
  WowType,
  {
    label: string;
    tip?: string;
    getValue: (v: RangeObject) => RangeObject;
  }
> = {
  pre7d: {
    label: getSharkText("config_page_this_period_pre_7_days"),
    getValue: (v) => {
      if (!v) return v;
      return v.map((item) =>
        item != null ? cloneDeep(item).add(-7, "day") : null
      ) as RangeObject;
    },
  },
  pre30d: {
    label: getSharkText("config_page_this_period_pre_30_days"),
    getValue: (v) => {
      if (!v) return v;
      return v.map((item) =>
        item != null ? cloneDeep(item).add(-30, "day") : null
      ) as RangeObject;
    },
  },
  pre60d: {
    label: getSharkText("config_page_pre60_days"),
    getValue: (v) => {
      if (!v) return v;
      return v.map((item) =>
        item != null ? cloneDeep(item).add(-60, "day") : null
      ) as RangeObject;
    },
  },
  pre90d: {
    label: getSharkText("config_page_pre90_days"),
    getValue: (v) => {
      if (!v) return v;
      return v.map((item) =>
        item != null ? cloneDeep(item).add(-90, "day") : null
      ) as RangeObject;
    },
  },
  pre180d: {
    label: "本期前180天",
    getValue: (v) => {
      if (!v) return v;
      return v.map((item) =>
        item != null ? cloneDeep(item).add(-180, "day") : null
      ) as RangeObject;
    },
  },
};
const wowOptions = Object.keys(wowMap).map((key) => {
  return {
    value: key,
    ...wowMap[key as WowType],
  };
});
const yoyTypeList = ["weekAlign", "solar", "lunar"] as const;
export type YoyType = (typeof yoyTypeList)[number];
const yoyTypeMap: Record<YoyType, string> = {
  weekAlign: getSharkText("config_page_week_align"),
  solar: getSharkText("config_page_solar_align"),
  lunar: getSharkText("config_page_lunar_align"),
};
const yoyTypeOptions = Object.keys(yoyTypeMap).map((key) => {
  return {
    value: key,
    label: yoyTypeMap[key as YoyType],
  };
});
// #endregion

interface GetCompareDate {
  currentValue: RangeObject;
  wowType?: WowType | null;
  yoyYear?: number | null;
  yoyType?: YoyType | null;
  days?: number;
}

export const diffDays = (rangeCompare: RangeCompare): number => {
  if (rangeCompare == null) {
    return 0;
  }
  if (!rangeCompare.current || !rangeCompare.compare) {
    return 0;
  }
  if (rangeCompare.current[0] && rangeCompare.compare[0]) {
    return moment(rangeCompare.compare[0].format(DATE_FORMAT)).diff(
      rangeCompare.current[0],
      "days"
    );
  }
  if (rangeCompare.current[1] && rangeCompare.compare[1]) {
    return moment(rangeCompare.compare[1].format(DATE_FORMAT)).diff(
      rangeCompare.current[1],
      "days"
    );
  }
  return 0;
};

export const getLunarDay = (
  v: string | Moment
): { y: number; m: number; d: number; isLean: boolean } => {
  const ori = typeof v === "string" ? moment(v) : cloneDeep(v);
  const rst = new LunarCalendar(
    ori.year(),
    ori.month() + 1,
    ori.date()
  ).getLunar();
  return rst;
};

/**
 * 获取以农历对齐的年同比日期
 * @param v 原始日期, 阳历
 * @param year 对比年份
 * @returns 阳历日期
 */
export const getLunarCompare = (
  v: string | Moment,
  yearDiff: number
): Moment => {
  const lunarDate = getLunarDay(v);
  const year = lunarDate.y;
  const rst = new LunarCalendar(
    year + yearDiff,
    lunarDate.m,
    lunarDate.d,
    "lunar"
  ).getSolar();
  return moment([rst.y, rst.m - 1, rst.d]);
};

export const getCompareDate = ({
  currentValue,
  wowType,
  yoyType,
  yoyYear,
  days,
}: GetCompareDate): RangeObject => {
  const currentSelected = cloneDeep(currentValue) as RangeObject;
  if (currentSelected == null) {
    return null;
  }
  const start = currentSelected[0];
  const end = currentSelected[1];
  if (start === null && end === null) {
    return null;
  }
  const year = start?.year() ?? (end?.year() as number);
  if (wowType) {
    const tmp = wowMap[wowType].getValue(currentSelected);
    return tmp;
  }
  if (yoyYear && yoyType) {
    const yearDiff = year - yoyYear;
    switch (yoyType) {
      case "weekAlign":
        return [
          start?.add(yearDiff * -364, "days") ?? null,
          end?.add(yearDiff * -364, "days") ?? null,
        ];
      case "solar":
        return [
          start?.add(yearDiff * -1, "year") ?? null,
          end?.add(yearDiff * -1, "year") ?? null,
        ];
      case "lunar": {
        const s = start ? getLunarCompare(start, yearDiff * -1) : null;
        if (s && end) {
          const diff = cloneDeep(start)?.diff(end, "days") || 0;
          const e = cloneDeep(s).add(diff * -1, "days");
          return [s, e];
        } else {
          return [null, end ? getLunarCompare(end, yearDiff * -1) : null];
        }
      }
    }
  }
  const diffDays = days ?? 0;
  return [
    start?.add(diffDays, "days") ?? null,
    end?.add(diffDays, "days") ?? null,
  ];
};

export interface CompareComProps {
  wowType: WowType | null;
  yoyYear: number | null;
  yoyType: YoyType | null;
  handleChangeWowType: (v: WowType) => void;
  handleChangeYoyType: (v: YoyType) => void;
  handleChangeYoyYear: (v: number) => void;
}

/** 对比快捷选项 */
const CompareCom = (props: CompareComProps): ReactElement => {
  const {
    wowType,
    yoyYear,
    yoyType,
    handleChangeWowType,
    handleChangeYoyYear,
    handleChangeYoyType,
  } = props;

  const yoyList = useMemo(() => {
    return Array.from({ length: moment().year() - 2019 + 1 }, (v, i) => {
      return 2019 + i;
    });
  }, []);

  const yoyListOptions = useMemo(() => {
    return yoyList.map((y) => {
      return {
        value: y,
        label: y,
      };
    });
  }, [yoyList]);

  const yoyContent = useMemo(
    () => (
      <Row gutter={[10, 10]}>
        <Col>
          <Select
            options={yoyListOptions}
            value={yoyYear}
            onChange={handleChangeYoyYear}
            getPopupContainer={(node) => node.parentNode}
            placeholder={getSharkText(
              "config_page_CoDaIn_select_same_ratio_year"
            )}
            style={{ minWidth: 60 }}
          ></Select>
        </Col>
        <Col>
          <Select
            style={{ minWidth: 120 }}
            options={yoyTypeOptions}
            value={yoyType}
            onChange={handleChangeYoyType}
            getPopupContainer={(node) => node.parentNode}
            placeholder={getSharkText(
              "config_page_CoDaIn_select_alignment_method"
            )}
          ></Select>
        </Col>
      </Row>
    ),
    [handleChangeYoyType, handleChangeYoyYear, yoyListOptions, yoyType, yoyYear]
  );

  const wowButtonText = useMemo(() => {
    if (wowType) {
      return wowMap[wowType].label;
    }
    return getSharkText("config_page_chain_ratio_period");
  }, [wowType]);

  const yoyButtonText = useMemo(() => {
    if (yoyType && yoyYear) {
      return `${yoyYear} : ${yoyTypeMap[yoyType]}`;
    }
    return getSharkText("config_page_same_period");
  }, [yoyType, yoyYear]);

  return (
    <div>
      <Popover
        trigger="click"
        content={
          <Select
            options={wowOptions}
            value={wowType}
            onChange={handleChangeWowType}
            getPopupContainer={(node) => node.parentNode}
            placeholder={getSharkText("config_page_select_chain_ratio_period")}
            style={{ minWidth: 120 }}
            // defaultValue={wowOptions[0].value as WowType}
          ></Select>
        }
        getPopupContainer={(node) => node}
      >
        <Button
          style={{ marginRight: 20 }}
          type={wowType ? "primary" : "default"}
        >
          {wowButtonText}
        </Button>
      </Popover>
      <Popover
        content={yoyContent}
        getPopupContainer={(node) => node}
        trigger="click"
      >
        <Button type={yoyYear && yoyType ? "primary" : "default"}>
          {yoyButtonText}
        </Button>
      </Popover>
    </div>
  );
};
export default CompareCom;
