import React, {
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
  ReactNode,
  useRef,
} from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Layout, message, Result, Spin, Button, ConfigProvider } from "antd";
import "./App.css";
import Dashboard from "Page/AI/Dashboard";
import CoreOverview from "Page/AI/CoreOverview";
import AirlineAnalysis from "Page/AI/AirlineAnalysis";
import FlightPlan from "Page/AI/FlightPlan";
import SearchTrends from "Page/AI/SearchTrends";
import MarketTrends from "Page/AI/MarketTrends";
import TouristSource from "Page/AI/TouristSource";
import PriceMonitor from "Page/AI/PriceMonitor";
import AirportCompare from "Page/AI/AirportCompare";
import PassengerAnalysis from "Page/AI/PassengerAnalysis";
import User from "Page/AI/User";
import Login from "Page/AI/Login";
import FileSharing from "Page/AI/FileSharing";
import FlightSegment from "Page/AI/FlightSegment";
import ApproveUser from "Page/AI/ApproveUser";
import RightManage from "Page/AI/RightManage";
import UserManage from "Page/AI/UserManage";
import UserAgreement from "Page/AI/UserAgreement";
import PrivacyPolicy from "Page/AI/PrivacyPolicy";
import { SERVER, CHARTS_THEME, USER_NAME, PAGE_ID } from "Constants";
import { logout, setCookie, watermark } from "Utils";
import useGlobal, { GlobalStateProvider } from "Store";
import AIHeader from "Components/UserInfo";
import * as Echarts from "echarts";
import moment from "moment";
import { SystemType } from "Interface";
import UserProfile from "Page/AI/UserProfile";
import DynamicPrice from "Page/AI/DynamicPrice";
import IndustryTrend from "Page/AI/IndustryTrend";
import { isSuperAdmin, redirectHome, redirectLogin } from "Utils/global";
import HolidayAnalysis from "Page/AI/HolidayAnalysis";
import TravixOverview from "Page/AI/Travix/TravixOverview";
import TravixSalesData from "Page/AI/Travix/TravixSalesData";
import MonitoringAlerting from "Page/AI/MonitoringAlerting";
import SalesAnalysis from "Page/AI/SalesAnalysis";
import { defaultLang, ELangs, getAntdLang } from "./i18n/config";
import { useTranslation } from "react-i18next";
import Trans from "Trans";
import { Locale } from "antd/lib/locale-provider";
import SalonManage from "Page/AI/SalonManage";
import TransferPolicy from "Page/AI/TransferPolicy";
import TransferAnalysis from "Page/AI/TransferAnalysis";
import FlightReview from "Page/AI/FlightReview";
import LoadFactor from "Page/AI/LoadFactor";

/**
 * 官网页面
 */
import Home from "Page/Official/Home";
import Market from "Page/Official/Market";
import Advisory from "Page/Official/Advisory";
import Income from "Page/Official/Income";
import About from "Page/Official/About";
import Header from "Components/Official/Header";
import Footer from "Components/Official/Footer";
import "style/official/global.scss";
import Salon from "Page/Official/Salon";
import SalonH5 from "Page/Official/SalonH5";
import SalonRegister from "Page/Official/Salon/register";
import ReportList from "Page/Official/Reports/ReportList";
import Report from "Page/Official/Reports/Report";
import ReportManage from "Page/AI/ReportManage/ReportManage";
import WingonOverview from "Page/AI/WingonOverview";
import useI18Next from "Utils/useI18Next";
import IPollManage from "Page/AI/IPollManage";
import { debugMode } from "@ctrip/flt-bidw-common/debugMode";
import MonitoringSuggestion from "Page/AI/MonitoringSuggestion";
import FreeDashboardColumnsManage from "Page/AI/FreeDashboard/Manage";
import ErrorBoundary from "Components/ErrorBoundary";
import ubtUtils from "Utils/ubtUtils";
import ProfitMonitor from "Page/AI/ProfitMonitor";
import { DEFAULT_SUB_FAT } from "./Utils/fetchBase";
import SalesPlan from "Page/AI/SalesPlan";
import AllocationOperation from "Page/AI/AllocationOperation";
import CancellationPrediction from "Page/AI/CancellationPrediction";
import ODAnalysis from "Page/AI/ODAnalysis";
import IMBatch from "Page/AI/IMBatch";
// import AirlineHotel from "Page/AI/AirlineHotel";
// import AdjustmentByRules from "Page/AI/AdjustmentByRules";
// import ExceptionMonitoring from "Page/AI/ExceptionMonitoring";

const { Content, Sider, Footer: FooterLayout } = Layout;
const AdjustmentByRules = React.lazy(() => import("Page/AI/AdjustmentByRules"));
const ExceptionMonitoring = React.lazy(
  () => import("Page/AI/ExceptionMonitoring")
);
const FlightManage = React.lazy(() => import("Page/AI/FlightManage"));
const FreeDashboard = React.lazy(() => import("Page/AI/FreeDashboard"));
const SimulatorPortal = React.lazy(
  () => import("Page/AI/Simulator/SimulatorPortal")
);
const TransitSuggestion = React.lazy(() => import("Page/AI/TransitSuggestion"));
const FlightHotel = React.lazy(() => import("Page/AI/FlightHotel"));

message.config({
  top: 80,
  duration: 2,
  maxCount: 2,
});

Echarts.registerTheme("default", CHARTS_THEME);

interface ILayoutProps {
  mainStyle?: React.CSSProperties;
}
interface IDefaultLayoutProps {
  children?: ReactNode;
  layoutProps?: ILayoutProps;
}

interface IProps extends PropsWithChildren<any> {
  type?: "ONLINE" | "H5";
}

const OfficialLayout = ({ type = "ONLINE", children }: IProps) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      className="main official"
      style={{ paddingTop: type === "H5" ? "unset" : 64 }}
    >
      {type === "H5" ? undefined : <Header />}
      {children}
      {type === "H5" ? undefined : <Footer />}
    </div>
  );
};

const DefaultLayout: React.FC<IDefaultLayoutProps> = (
  props: IDefaultLayoutProps
) => {
  const { children, layoutProps = {} } = props;
  const { mainStyle = {} } = layoutProps;
  const [globalState, actions] = useGlobal();
  const { userInfo, systemType } = globalState;
  const { user } = userInfo;
  const { uid, token } = actions.getLogin();
  const { setUserInfo, setSystemType } = actions;
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const divRef = useCallback((node: any) => {
    if (node !== null) {
      watermark({
        container: node,
        content: "FlightAI",
      });
    }
  }, []);
  const t = useI18Next();
  const { i18n } = useTranslation(["ns1"]);

  const queryUserInfo = async (_uid: any, _token: any, signal: any) => {
    try {
      const result: any = await fetch(
        `${SERVER}queryUserInfo?subEnv=${DEFAULT_SUB_FAT}`,
        {
          signal,
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            uid: _uid,
            token: _token,
          }),
        }
      );
      const res = await result.json();
      if (res && res.ResponseStatus.Ack === "Success") {
        let tmpSystemType: SystemType = SystemType.airlines;
        if (
          res.user.groupId === 0 ||
          (res.roleList && res.roleList.length === 0)
        ) {
          tmpSystemType = res.user.demoType === 2 ? 2 : 1;
          setSystemType(tmpSystemType);
        } else {
          tmpSystemType =
            res.roleList[0] && res.roleList[0].roleType === 2 ? 2 : 1;
          setSystemType(tmpSystemType);
        }
        setUserInfo({
          user: res.user,
          moduleList: res.moduleList,
          roleList: res.roleList,
          unitList: res.unitList,
        });
        setCookie(USER_NAME, res.user.name);
        // 假如触发了页面跳转, 就保持刷新状态
        const rst = redirectHome(res.moduleList);
        setIsLoading(rst);
        // 超级管理员默认开启mode模式
        if (isSuperAdmin(res.user)) {
          debugMode(true);
        }
      } else {
        if (res.ResponseStatus.Errors[0].ErrorCode === "2001") {
          logout();
          redirectLogin();
        } else {
          setError(res);
          setIsLoading(false);
        }
      }
    } catch (e1: any) {
      console.log(e1);
      if (e1 && e1.name === "AbortError") {
        return;
      }
      setIsLoading(false);
      setError(e1);
    }
  };

  useEffect(() => {
    const abortCtrl = new AbortController();
    const signal = abortCtrl.signal;
    if (uid && token) {
      queryUserInfo(uid, token, signal);
    }
    return () => {
      abortCtrl.abort();
    };
  }, []);

  if (isLoading) {
    return (
      <div className="loading">
        <Spin size="large" />
      </div>
    );
  }
  if (error) {
    return (
      <Result
        status="500"
        title="Error"
        subTitle="Sorry, the server is wrong."
      />
    );
  }

  const changeSystemType = (type: SystemType) => {
    setIsLoading(true);
    setSystemType(type);
    setTimeout(() => setIsLoading(false), 100);
  };

  return (
    <div ref={divRef}>
      <div
        style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
      >
        <div style={{ flex: "0 0 54px" }}>
          <AIHeader
            user={user}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            systemType={systemType}
            changeSystemType={changeSystemType}
          />
        </div>
        <Content id="main" style={{ ...mainStyle, flex: "1 1 auto" }}>
          <ErrorBoundary pageId="10650040938">
            {isLoading ? "Loading..." : children}
          </ErrorBoundary>
        </Content>
        <FooterLayout
          style={{
            textAlign: "center",
            padding: "10px 20px",
            flex: "0 0 60px",
            width: "100%",
            bottom: 0,
            zIndex: 10,
          }}
          className="foot"
        >
          <a href="mailto:flightai@trip.com">{t("footer.contactUs")}</a>
          &nbsp;|&nbsp;
          <Link to="/user_agreement" target="_blank">
            {t("footer.termOfUse")}
          </Link>
          &nbsp;|&nbsp;
          <Link to="/privacy_policy" target="_blank">
            {t("footer.privacy")}
          </Link>
          &nbsp;&nbsp; Copyright©{moment().format("YYYY")},FlightAI. All rights
          reserved.&nbsp;&nbsp;
          {i18n.language === ELangs.cn ? (
            <>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://beian.miit.gov.cn/"
                className="black"
              >
                ICP证：沪B2-20050130
              </a>
              &nbsp;|&nbsp;
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://beian.miit.gov.cn/"
                className="black"
              >
                沪ICP备08023580号
              </a>
              <br />
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010502002731"
                className="black"
              >
                <i className="icon-gns"></i>沪公网备31010502002731号
              </a>
            </>
          ) : undefined}
        </FooterLayout>
      </div>
    </div>
  );
};

const UserLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const bgNum = useRef(Math.floor(Math.random() * 3) + 1);
  // const className = `login-bg bg${bgNum.current}`;
  const className = `login-bg bg3`;
  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div style={{ textAlign: "right", margin: "10px 10px 0 0 " }}>
        <Trans />
      </div>
      <Layout
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "transparent",
        }}
      >
        {children}
      </Layout>
    </div>
  );
};

const RequireAuth = ({ children, layoutProps }: any) => {
  const [, actions] = useGlobal();
  const { uid, token } = actions.getLogin();
  if (uid && token) {
    return <DefaultLayout layoutProps={layoutProps}>{children}</DefaultLayout>;
  } else {
    return <Navigate to="/login" />;
  }
};

const AllRoutes: React.FC = () => {
  const t = useI18Next();
  // #region PV埋点
  const location = useLocation();
  const pageId = PAGE_ID[location.pathname] ?? "0";
  useEffect(() => {
    ubtUtils.pv(pageId, location.pathname);
    if (!pageId) {
      ubtUtils.warn("noPageId", { pathname: location.pathname });
    }
  }, [location.pathname, pageId]);
  // #endregion
  return (
    <Routes>
      <Route
        path="/dashboard"
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route
        path="/adjustment_by_rules"
        element={
          <RequireAuth>
            <AdjustmentByRules />
          </RequireAuth>
        }
      />
      <Route
        path="/airline_analysis"
        element={
          <RequireAuth>
            <AirlineAnalysis />
          </RequireAuth>
        }
      />
      <Route
        path="/airport_compare"
        element={
          <RequireAuth>
            <AirportCompare />
          </RequireAuth>
        }
      />
      <Route
        path="allocation_operation"
        element={
          <RequireAuth>
            <AllocationOperation />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/approve_user"
        element={
          <RequireAuth>
            <ApproveUser />
          </RequireAuth>
        }
      />
      <Route
        path="/cancellation_prediction"
        element={
          <RequireAuth>
            <CancellationPrediction />
          </RequireAuth>
        }
      />
      <Route
        path="/core_overview"
        element={
          <RequireAuth>
            <CoreOverview />
          </RequireAuth>
        }
      />
      <Route
        path="/dynamic_price"
        element={
          <RequireAuth>
            <DynamicPrice />
          </RequireAuth>
        }
      />
      <Route
        path="/exception_monitoring"
        element={
          <RequireAuth>
            <ExceptionMonitoring />
          </RequireAuth>
        }
      />
      <Route
        path="/file_sharing"
        element={
          <RequireAuth>
            <FileSharing />
          </RequireAuth>
        }
      />
      <Route
        path="/flight_hotel"
        element={
          <RequireAuth>
            <FlightHotel />
          </RequireAuth>
        }
      />
      <Route
        path="/flight_manage"
        element={
          <RequireAuth>
            <FlightManage />
          </RequireAuth>
        }
      />
      <Route
        path="/flight_plan"
        element={
          <RequireAuth>
            <FlightPlan />
          </RequireAuth>
        }
      />
      <Route
        path="/flight_plan_intl"
        element={
          <RequireAuth>
            <div style={{ margin: "0 200px" }}>
              <FlightPlan intl={true} />
            </div>
          </RequireAuth>
        }
      />
      <Route
        path="/flight_review"
        element={
          <RequireAuth>
            <FlightReview />
          </RequireAuth>
        }
      />
      <Route
        path="/flight_segment"
        element={
          <RequireAuth>
            <FlightSegment />
          </RequireAuth>
        }
      />
      <Route
        path="/free_dashboard"
        element={
          <RequireAuth
            layoutProps={{
              mainStyle: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
              },
            }}
          >
            <FreeDashboard />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/free_dashboard_columns_manage"
        element={
          <RequireAuth>
            <FreeDashboardColumnsManage />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/holiday_analysis"
        element={
          <RequireAuth>
            <HolidayAnalysis />
          </RequireAuth>
        }
      />
      <Route
        path="/im_batch"
        element={
          <RequireAuth>
            <IMBatch />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/industry_trend"
        element={
          <RequireAuth>
            <IndustryTrend />
          </RequireAuth>
        }
      />
      <Route
        path="/ipoll_manage"
        element={
          <RequireAuth>
            <IPollManage />
          </RequireAuth>
        }
      />
      <Route
        path="/load_factor"
        element={
          <RequireAuth>
            <LoadFactor />
          </RequireAuth>
        }
      />
      <Route
        path="/market_index"
        element={
          <RequireAuth>
            <MarketTrends />
          </RequireAuth>
        }
      />
      <Route
        path="/monitoring_alerting"
        element={
          <RequireAuth>
            <MonitoringAlerting />
          </RequireAuth>
        }
      />
      <Route
        path="/monitoring_suggestion"
        element={
          <RequireAuth>
            <MonitoringSuggestion />
          </RequireAuth>
        }
      />
      <Route
        path="/od_analysis"
        element={
          <RequireAuth>
            <ODAnalysis />
          </RequireAuth>
        }
      />
      <Route
        path="/report_manage"
        element={
          <RequireAuth>
            <ReportManage />
          </RequireAuth>
        }
      />
      <Route
        path="/right_manage"
        element={
          <RequireAuth>
            <RightManage />
          </RequireAuth>
        }
      />
      <Route
        path="/sales_analysis"
        element={
          <RequireAuth>
            <SalesAnalysis />
          </RequireAuth>
        }
      />
      <Route
        path="/sales_plan"
        element={
          <RequireAuth>
            <SalesPlan />
          </RequireAuth>
        }
      />
      <Route
        path="/salon_manage"
        element={
          <RequireAuth>
            <SalonManage />
          </RequireAuth>
        }
      />
      <Route
        path="/search_index"
        element={
          <RequireAuth>
            <SearchTrends />
          </RequireAuth>
        }
      />
      <Route
        path="/search_index_intl"
        element={
          <RequireAuth>
            <div style={{ margin: "0 200px" }}>
              <SearchTrends intl={true} />
            </div>
          </RequireAuth>
        }
      />
      <Route
        path="/transfer_analysis"
        element={
          <RequireAuth>
            <TransferAnalysis />
          </RequireAuth>
        }
      />
      <Route
        path="/transfer_policy"
        element={
          <RequireAuth
            layoutProps={{
              mainStyle: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
              },
            }}
          >
            <TransferPolicy />
          </RequireAuth>
        }
      />
      <Route
        path="/transit_suggestion"
        element={
          <RequireAuth>
            <TransitSuggestion />
          </RequireAuth>
        }
      ></Route>
      <Route
        path="/tourist_source"
        element={
          <RequireAuth>
            <TouristSource />
          </RequireAuth>
        }
      />
      <Route
        path="/price_monitor"
        element={
          <RequireAuth>
            <PriceMonitor />
          </RequireAuth>
        }
      />
      <Route
        path="/passenger_analysis"
        element={
          <RequireAuth>
            <PassengerAnalysis />
          </RequireAuth>
        }
      />
      <Route path="/privacy_policy" element={<PrivacyPolicy />} />
      <Route
        path="/profit_monitor"
        element={
          <RequireAuth>
            <ProfitMonitor />
          </RequireAuth>
        }
      />
      <Route
        path="/travix_overview"
        element={
          <RequireAuth>
            <TravixOverview />
          </RequireAuth>
        }
      />
      <Route
        path="/travix_salesdata"
        element={
          <RequireAuth>
            <TravixSalesData />
          </RequireAuth>
        }
      />
      <Route
        path="/user"
        element={
          <RequireAuth>
            <User />
          </RequireAuth>
        }
      />
      <Route path="/user_agreement" element={<UserAgreement />} />
      <Route
        path="/user_manage"
        element={
          <RequireAuth>
            <UserManage />
          </RequireAuth>
        }
      />
      <Route
        path="/user_profile"
        element={
          <RequireAuth>
            <UserProfile />
          </RequireAuth>
        }
      />
      <Route
        path="/simulator"
        element={
          <RequireAuth
            layoutProps={{
              mainStyle: {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                maxHeight: "100vh",
                overflow: "hidden",
              },
            }}
          >
            <SimulatorPortal />
          </RequireAuth>
        }
      />
      <Route
        path="/wingon_overview"
        element={
          <RequireAuth>
            <WingonOverview />
          </RequireAuth>
        }
      />

      <Route
        path="/login"
        element={
          <UserLayout>
            <Login />
          </UserLayout>
        }
      />
      <Route
        path="/reset"
        element={
          <UserLayout>
            <Login isReset={true} />
          </UserLayout>
        }
      />
      <Route
        path="/"
        element={
          window.TARGET_PLATFORM_CONFIG ? (
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          ) : (
            <OfficialLayout>
              <Home />
            </OfficialLayout>
          )
        }
      ></Route>
      <Route
        path="/MarketInsight"
        element={
          <OfficialLayout>
            <Market />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/Consulting"
        element={
          <OfficialLayout>
            <Advisory />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/RevenueManagement"
        element={
          <OfficialLayout>
            <Income />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/AboutUs"
        element={
          <OfficialLayout>
            <About />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/Salon"
        element={
          <OfficialLayout>
            <Salon />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/SalonRegister"
        element={
          <OfficialLayout>
            <SalonRegister />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/SalonH5"
        element={
          <OfficialLayout type="H5">
            <SalonH5 />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/ReportList"
        element={
          <OfficialLayout>
            <ReportList />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/Report"
        element={
          <OfficialLayout>
            <Report />
          </OfficialLayout>
        }
      ></Route>
      <Route
        path="/*"
        element={
          <Result
            status="404"
            title="404"
            subTitle={t("common.pageNotExists")}
            extra={
              <Button type="primary">
                <Link to="/">{t("common.backToHome")}</Link>
              </Button>
            }
          />
        }
      ></Route>
      <Route element={<Navigate to="/" />} />
    </Routes>
  );
};

const App: React.FC = () => {
  const [lang, setLang] = useState<Locale>(getAntdLang(defaultLang));
  const { i18n } = useTranslation(["ns1"]);
  const { language } = i18n;
  useEffect(() => {
    const antdLang = getAntdLang(language);
    if (antdLang.locale !== language.toLocaleLowerCase()) {
      console.log("lang-antd", language);
      setLang(antdLang);
    }
    sessionStorage.setItem("lang", language);
  }, [language]);
  return (
    <ConfigProvider locale={lang}>
      <GlobalStateProvider>
        <Router>
          <AllRoutes />
        </Router>
      </GlobalStateProvider>
    </ConfigProvider>
  );
};

export default App;
