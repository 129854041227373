import React, { useState, useEffect } from "react";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { Table, Modal } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import Refetch from "Components/Refetch";
import moment from "moment";

interface FileTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  keyword: string;
  typeId: number;
  refreshTable: boolean;
  setRefreshTable: (refreshTable: boolean) => void;
}
const { confirm } = Modal;

const FileTable: React.FC<FileTableProps> = (props: FileTableProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    typeId,
    keyword,
    refreshTable,
    setRefreshTable,
  } = props;
  const [tableData, setTableData] = useState<[]>();
  const [globalState] = useGlobal();
  const { userInfo } = globalState;
  const { user } = userInfo;
  const isSuperAdmin = user.userType === 1 && user.memberType === 1;
  const [{ isLoading, error, res }, doFetch] = useFetch({
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    ext: {
      typeId,
    },
    lazey: true,
  });

  const [{ res: downloadResponse }, downloadFetch] = useFetch({
    url: "downloadFile",
    head: {
      moduleCode,
      chartTableCode,
    },
    ext: {
      id: 0,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({ ext: { typeId } });
  };
  const [, deleteFetch] = useFetch({
    url: "deleteFile",
    head: {
      moduleCode,
      chartTableCode,
    },
    ext: {
      id: 0,
    },
    lazey: true,
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    refetch();
  }, [typeId]);

  useEffect(() => {
    if (refreshTable) {
      refetch();
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    if (res && res.fileList) {
      let list = res.fileList;
      if (keyword) {
        const text = keyword.toLowerCase();
        list = res.fileList.filter((item: any) => item.fileName.includes(text));
      }

      setTableData(list);
    }
  }, [res, keyword]);

  useEffect(() => {
    if (downloadResponse && downloadResponse.url) {
      const eleLink = document.createElement("a");
      eleLink.style.display = "none";
      eleLink.href = downloadResponse.url;
      document.body.appendChild(eleLink);
      eleLink.click();
      document.body.removeChild(eleLink);
    }
  }, [downloadResponse]);

  const handDownload = (record: any) => {
    downloadFetch({ ext: { id: record.id } });
    record.hot = record.hot + 1;
  };
  const handDelete = (record: any) => {
    confirm({
      title: getSharkText("config_page_prompt"),
      content: `${getSharkText("config_page_confirm_delete_file")}[${
        record.fileName
      }]吗?`,
      okText: getSharkText("config_page_confirm"),
      okType: "danger",
      cancelText: getSharkText("key.cancel.button"),
      onOk: () => {
        deleteFetch({ ext: { id: record.id } });
      },
      onCancel: () => {
        console.log("Cancel");
      },
    });
  };

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const columns = [
    {
      title: getSharkText("config_page_report_article_name"),
      dataIndex: "fileName",
      key: "fileName",
      sorter: (a: any, b: any) => a.fileName.localeCompare(b.fileName),
    },
    {
      title: getSharkText("config_page_operation"),
      dataIndex: "id",
      key: "id",
      render: (value: string, record: any) => (
        <div>
          <DownloadOutlined
            style={{ color: "#1890ff" }}
            onClick={() => handDownload(record)}
          />{" "}
          &nbsp;
          {isSuperAdmin ? (
            <DeleteOutlined
              style={{ color: "#1890ff" }}
              onClick={() => handDelete(record)}
            />
          ) : undefined}
        </div>
      ),
    },
    {
      title: getSharkText("config_page_source"),
      dataIndex: "source",
      key: "source",
      sorter: (a: any, b: any) => a.source.localeCompare(b.source),
    },
    {
      title: getSharkText("config_page_PaAiFiCoFiIn_download_heat"),
      dataIndex: "hot",
      key: "hot",
      sorter: (a: any, b: any) => a.hot - b.hot,
    },
    {
      title: getSharkText("config_page_PaAiFiCoFiIn_upload_date"),
      dataIndex: "uploadTime",
      key: "uploadTime",
      sorter: (a: any, b: any) => a.uploadTime.localeCompare(b.uploadTime),
      render: (value: string) => (
        <div>{moment(value).format("YYYY.MM.DD")}</div>
      ),
    },
  ];

  return (
    <>
      <Table
        showSorterTooltip={false}
        rowKey="id"
        tableLayout="fixed"
        dataSource={tableData}
        // @ts-ignore
        columns={columns}
        loading={isLoading}
      />
    </>
  );
};

export default FileTable;
