import { FlightSegmentFilter } from "@ctrip/flt-bi-flightai-airlines";
import React, { ReactElement, useState } from "react";
import SearchArea from "./SearchArea";
import TablePortal from "./TablePortal";

/**
 * 航段数据tab页
 */
const SegmentPanel = (): ReactElement => {
  const [filter, setFilter] = useState<FlightSegmentFilter>(
    {} as FlightSegmentFilter
  );
  return (
    <div>
      <SearchArea onChange={setFilter} />
      <TablePortal filter={filter} />
    </div>
  );
};
export default SegmentPanel;
