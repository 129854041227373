import {
  EditOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  InputNumber,
  message,
  Popover,
  Row,
  Spin,
  Table,
} from "antd";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  AdjustSubCabinEntity,
  IDownloadHeader,
  LayoutRestrictionType,
  SegEntity,
  SubCabinEntityExtend,
} from "Interface";
import { EQUAL_ITEM } from "Page/AI/FlightManage/FlightManageCommon";
import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import "./AdjustCabin.scss";
import { arrayUpsert, isSame, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { allCabinsBelow2, changedCabinsBelow6 } from "./GetROLss";
import useRefFunc from "Utils/useRefFunc";
import { MODULE_CODE } from "../../fetchCode";
import { cloneDeep } from "lodash";
import { useDebounce } from "Utils/useDebounce";
import DynamicFarePrice, { DynamicFarePriceRef } from "./DynamicFarePrice";
import { hasDynamicPricingPermission } from "./DynamicFarePrice/common";
import useGlobalState from "Store";
import { getCabinTips } from "./services";

const DEFAULT_IND = "ELAPK";
const generateChangedInd = (
  oriInd: string,
  adjustCabin: AdjustSubCabinEntity
): string => {
  let tmpInd = oriInd;
  if (tmpInd.includes("L") && adjustCabin.ind_L === "-") {
    tmpInd = tmpInd.replace("L", "");
  }
  if (!tmpInd.includes("L") && adjustCabin.ind_L === "+") {
    tmpInd = tmpInd + "L";
  }
  if (tmpInd.includes("P") && adjustCabin.ind_P === "-") {
    tmpInd = tmpInd.replace("P", "");
  }
  if (!tmpInd.includes("P") && adjustCabin.ind_P === "+") {
    tmpInd = tmpInd + "P";
  }
  // #region 排序IND
  // 1. 取出不在默认IND中的内容, 追加在最后
  const others = tmpInd.split("").filter((t) => !DEFAULT_IND.includes(t));
  // 2. 排序包含在默认IND中的内容
  const inDefaultInd = DEFAULT_IND.split("").filter((d) => tmpInd.includes(d));
  tmpInd = inDefaultInd.concat(others).join("");
  // #endregion
  return tmpInd;
};
const LSV_HASH_NEST_ERROR = getSharkText("lsv_hast_nest_error");
interface IProps {
  flightNo: string;
  takeoffdate: string;
  cacheKey: string;
  curSeg: SegEntity;
  /** 是否有调价, 没有调价不显示舱位变化 */
  hasDiffSuggestPrice: boolean;
  /** 是否使用建议调仓 */
  useSuggestAdjust: boolean;
  layoutRestriction?: LayoutRestrictionType | null;
}

export interface CabinAdjustHandle {
  getAdjusting: () => AdjustSubCabinEntity[];
  reset: () => void;
}

const isGrayLine = (lss: string, ind: string): boolean => {
  if ((lss !== "-" && Number(lss) <= 0) || ind.includes("P")) {
    return true;
  }
  return false;
};
/** 舱位P锁应锁而未锁判断 */
const shouldClosePermanent = (r: SubCabinEntityExtend): boolean => {
  return (
    r.lss !== "-" && Number(r.lss) <= 0 && !r.permanentOri && r.lsv !== "#"
  );
};

/** 调舱工具 */
const AdjustCabin = forwardRef(
  (props: IProps, ref: Ref<CabinAdjustHandle>): ReactElement => {
    const {
      flightNo,
      takeoffdate,
      cacheKey,
      curSeg,
      hasDiffSuggestPrice,
      useSuggestAdjust,
      layoutRestriction,
    } = props;
    /** 原始舱位数据, 建议调仓数据 */
    const { subcabins, adjustsubcabins: suggestAdjust } = curSeg;
    // 当前所有的改动
    const [adjusting, setAdjusting] = useState<AdjustSubCabinEntity[]>([]);
    const [globalState] = useGlobalState();
    const moduleConfig = globalState.moduleConfig;
    /** 服务端根据改动内容计算改动后的结果 */
    const [adjustedCabins, setAdjustedCabins] = useState<allCabinsBelow2[]>([]);
    // const [editing, setEditing] = useState(false);
    const [{ isLoading }, doAdjustReq, clearReq] = useFetch({
      url: "soaProxy",
      head: {
        moduleCode: MODULE_CODE,
        chartTableCode: "none",
      },
      defaultValue: [],
      ext: {},
      lazey: true,
      debugId: "GetROLss",
      onSuccess: (res) => {
        if (res?.responseBody) {
          try {
            const response = JSON.parse(res.responseBody);
            if (response.ResponseStatus.Ack === "Success") {
              setAdjustedCabins(response.allCabins || []);
            } else {
              message.error(response.ResponseStatus?.Errors[0].Message);
            }
            return;
          } catch (e) {
            console.log("get Ro lss error");
            console.error(e);
          }
        }
        setAdjustedCabins([]);
      },
    });

    /** 需要对可用舱位段(可售不为0且P舱未锁)做浮出效果, 这个变量用来记录段落起点舱位和终点舱位,
     * 舱段根据原始数据计算, 不考虑编辑中的数据 */
    const [cabinBlockInfo, setCabinBlockInfo] = useState<string[][]>([]);
    const dynamicFarePriceRef = useRef<DynamicFarePriceRef>(null);
    /** 原始舱位信息变更时, 重新计算adjusting舱位 */
    useEffect(() => {
      console.log("AdjustCain useEffect reset adjusting");
      let isInCabinBlock = false;
      const tmpCabinBlockInfo: string[][] = [];
      subcabins.forEach((sub, idx) => {
        if (!isGrayLine(sub.lss, sub.ind)) {
          if (!isInCabinBlock) {
            isInCabinBlock = true;
            tmpCabinBlockInfo.push([sub.cls]);
          }
        } else {
          if (isInCabinBlock) {
            isInCabinBlock = false;
            tmpCabinBlockInfo[tmpCabinBlockInfo.length - 1].push(
              subcabins[idx - 1].cls
            );
          }
        }
      });
      setCabinBlockInfo(tmpCabinBlockInfo);
      /** 多段航班某段调仓完成后, 检测当前段调整中的数据是否还有效 */
      setAdjusting((oldAdjusting) => {
        const newAdjusting: AdjustSubCabinEntity[] = [];
        oldAdjusting.forEach((oldAdjustItem) => {
          const newAdjustItem = cloneDeep(oldAdjustItem);
          const ori = subcabins.find((s) => s.cls === oldAdjustItem.cls);
          if (!ori) return;
          if (ori.lsv === oldAdjustItem.lsv) {
            newAdjustItem.lsv = "";
          }
          if (
            (ori.ind.includes("L") && oldAdjustItem.ind_L === "+") ||
            (!ori.ind.includes("L") && oldAdjustItem.ind_L === "-")
          ) {
            newAdjustItem.ind_L = "";
          }
          if (
            (ori.ind.includes("P") && oldAdjustItem.ind_P === "+") ||
            (!ori.ind.includes("P") && oldAdjustItem.ind_P === "-")
          ) {
            newAdjustItem.ind_P = "";
          }
          if (newAdjustItem.ind_L || newAdjustItem.ind_P || newAdjustItem.lsv) {
            newAdjusting.push(newAdjustItem);
          }
        });
        if (!isSame(newAdjusting, oldAdjusting)) {
          return newAdjusting;
        }
        return oldAdjusting;
      });
    }, [subcabins]);

    /** 切换数据源或者变更应用建议时, 重置变更舱位 */
    useEffect(() => {
      console.log("AdjustCain useEffect reset suggestAdjust", suggestAdjust);
      if (useSuggestAdjust) {
        setAdjusting(suggestAdjust ?? []);
      } else {
        setAdjusting([]);
      }
    }, [suggestAdjust, useSuggestAdjust]);

    const onCabinChange = useRefFunc(() => {
      // if (editing) {
      //   return;
      // }
      if (adjusting.length && cacheKey) {
        const changedCabins = adjusting.map((adjust) => {
          const ori = subcabins.find((s) => s.cls === adjust.cls);
          if (!ori) {
            throw new Error(`can not find original sub cabin:  ${adjust.cls}`);
          }
          const changedCabin: changedCabinsBelow6 = {
            cls: adjust.cls,
            lsv: adjust.lsv,
            ind: generateChangedInd(ori.ind, adjust),
          };
          return changedCabin;
        });
        doAdjustReq({
          ext: {
            serviceCode: "22299",
            operation: "GetROLss",
            requestBody: JSON.stringify({
              Head: { ClientAppID: "100026391", ClientIP: "10.22.117.126" },
              flightno: flightNo,
              takeoffdate,
              seg: curSeg.line,
              roCacheKey: cacheKey,
              changedCabins,
            }),
          },
        });
      } else {
        clearReq();
        setAdjustedCabins([]);
      }
    });

    const sendCabinChange = useDebounce(onCabinChange, 2000);

    /** 舱位变更时, 发送请求获取调舱结果 */
    useEffect(() => {
      sendCabinChange();
    }, [
      adjusting,
      cacheKey,
      clearReq,
      doAdjustReq,
      // editing,
      flightNo,
      curSeg.line,
      takeoffdate,
      sendCabinChange,
      subcabins,
    ]);

    useImperativeHandle(ref, () => ({
      getAdjusting: () => adjusting,
      reset: () => {
        setAdjusting(useSuggestAdjust ? suggestAdjust ?? [] : []);
        setAdjustedCabins([]);
      },
    }));

    const [displayCabins, setDisplayCabins] =
      useState<SubCabinEntityExtend[]>();
    /** 变舱信息 */
    const changedObj = useMemo(() => {
      let prefix = "";
      let suffix = "";
      // 如果没有变价, 则展示原值
      if (!hasDiffSuggestPrice) {
        return null;
      }
      // 如果没有变舱, 则展示原值
      if (curSeg.subclassori === curSeg.subclasssuggest) {
        return null;
      }
      const raw = curSeg.subclassori || "";
      const suggest = curSeg.subclasssuggest || "";
      // 判断第三位是否改变
      if (raw.substring(2) !== suggest.substring(2)) {
        suffix = `${raw.substring(2)}${suggest.substring(2)}`;
      }
      // 判断前两位是否改变
      if (raw.substring(0, 2) !== suggest.substring(0, 2)) {
        prefix = `${raw.substring(0, 2)}${suggest.substring(0, 2)}`;
      }
      return {
        // 主舱及子舱改变
        prefix,
        // P舱浮动舱改变
        suffix,
        rawCabinPrefix: raw.substring(0, 2),
        suggestCabinPrefix: suggest.substring(0, 2),
      };
    }, [curSeg.subclassori, curSeg.subclasssuggest, hasDiffSuggestPrice]);

    /** 根据原始subcabins,前端调整中的adjusting合前端调整后端实时计算的adjusted
     * 合并计算最终显示的各舱位信息displayCabins
     */
    useEffect(() => {
      if (isLoading) {
        return;
      }
      const tmpDisplayCabins: SubCabinEntityExtend[] = subcabins.map((ori) => {
        const adjustingCabin = adjusting.find(
          (adjust) => adjust.cls === ori.cls
        );
        const adjustedCabin = adjustedCabins.find((a) => a.cls === ori.cls);
        const adjustedInd = adjustedCabin?.ind || ori.ind;
        const item: SubCabinEntityExtend = {
          ...ori,
          adjustingInd: adjustedInd,
          nestOri: ori.ind.includes("L"),
          nestAdjusting: adjustingCabin?.ind_L
            ? adjustingCabin.ind_L === "+"
            : adjustedInd.includes("L"),
          permanentOri: ori.ind.includes("P"),
          permanentAdjusting: adjustingCabin?.ind_P
            ? adjustingCabin.ind_P === "+"
            : adjustedInd.includes("P"),
          adjustingLsv: adjustingCabin?.lsv || ori.lsv,
          adjustedLss: adjustedCabin?.lss || ori.lss,
        };
        return item;
      });
      setDisplayCabins(tmpDisplayCabins);
    }, [adjustedCabins, adjusting, isLoading, subcabins]);

    const handleRecordChange = useRefFunc(
      (adjustEntity: AdjustSubCabinEntity) => {
        if (adjustEntity.ind_L || adjustEntity.ind_P || adjustEntity.lsv) {
          setAdjusting((s) => {
            const newArray = arrayUpsert(
              s,
              adjustEntity,
              (a) => a.cls === adjustEntity.cls
            );
            return newArray;
          });
        } else {
          setAdjusting((s) => s.filter((s1) => s1.cls !== adjustEntity.cls));
        }
      }
    );

    const validLsvNest = useRefFunc(
      (v: {
        newLsv: string | undefined;
        newNest: boolean | undefined;
        oriRecord: SubCabinEntityExtend;
        adjustingCabin: AdjustSubCabinEntity | undefined;
      }) => {
        const { newLsv, newNest, oriRecord, adjustingCabin } = v;
        let isNest = false;
        if (newNest != null) {
          isNest = newNest;
        } else if (adjustingCabin && adjustingCabin.ind_L) {
          isNest = adjustingCabin.ind_L === "+";
        } else {
          isNest = oriRecord.nestOri;
        }
        let lsvIsHash = false;
        if (newLsv) {
          lsvIsHash = newLsv === "#";
        } else if (adjustingCabin) {
          lsvIsHash = adjustingCabin.lsv === "#";
        } else {
          lsvIsHash = oriRecord.lsv === "#";
        }
        if (isNest && lsvIsHash) {
          return false;
        }
        return true;
      }
    );

    const handleNumChange = useRefFunc(
      (adjustingLsv: string | number | null, r: SubCabinEntityExtend) => {
        const val = adjustingLsv ? adjustingLsv.toString() : "";
        let adjustingCabin = cloneDeep(adjusting).find((a) => a.cls === r.cls);
        if (
          layoutRestriction === "UNNEST_CLOSED_CABIN" &&
          !validLsvNest({
            newLsv: val,
            newNest: undefined,
            oriRecord: r,
            adjustingCabin,
          })
        ) {
          message.error(LSV_HASH_NEST_ERROR);
          return;
        }
        console.log("val, r", val, r);
        if (adjustingLsv?.toString() !== r.lsv) {
          if (adjustingCabin) {
            adjustingCabin.lsv = val;
          } else {
            adjustingCabin = {
              cls: r.cls,
              ind_P: "",
              ind_L: "",
              lsv: val,
            };
          }
          handleRecordChange(adjustingCabin);
        } else if (adjustingCabin) {
          adjustingCabin.lsv = "";
          handleRecordChange(adjustingCabin);
        }
      }
    );

    const handleNestChange = useRefFunc(
      (v: CheckboxChangeEvent, r: SubCabinEntityExtend) => {
        let adjustingCabin = cloneDeep(adjusting).find((a) => a.cls === r.cls);
        const nestAdjusting = v.target.checked;
        if (
          layoutRestriction === "UNNEST_CLOSED_CABIN" &&
          !validLsvNest({
            newLsv: undefined,
            newNest: nestAdjusting,
            oriRecord: r,
            adjustingCabin,
          })
        ) {
          message.error(LSV_HASH_NEST_ERROR);
          return;
        }
        if (nestAdjusting !== r.nestOri) {
          const indL = r.nestOri ? "-" : "+";
          if (adjustingCabin) {
            adjustingCabin.ind_L = indL;
          } else {
            adjustingCabin = {
              cls: r.cls,
              ind_P: "",
              ind_L: indL,
              lsv: "",
            };
          }
          handleRecordChange(adjustingCabin);
        } else if (adjustingCabin) {
          adjustingCabin.ind_L = "";
          handleRecordChange(adjustingCabin);
        }
      }
    );

    const handlePermanentChange = useRefFunc(
      (v: CheckboxChangeEvent, r: SubCabinEntityExtend) => {
        let adjustingCabin = cloneDeep(adjusting).find((a) => a.cls === r.cls);
        const permanentAdjusting = v.target.checked;
        if (permanentAdjusting !== r.permanentOri) {
          const indP = r.permanentOri ? "-" : "+";
          if (adjustingCabin) {
            adjustingCabin.ind_P = indP;
          } else {
            adjustingCabin = {
              cls: r.cls,
              ind_P: indP,
              ind_L: "",
              lsv: "",
            };
          }
          handleRecordChange(adjustingCabin);
        } else if (adjustingCabin) {
          adjustingCabin.ind_P = "";
          handleRecordChange(adjustingCabin);
        }
      }
    );

    const onFarePriceClick = useRefFunc(() => {
      dynamicFarePriceRef.current?.open(true);
    });

    const priceTitle = useMemo(() => {
      if (
        hasDynamicPricingPermission(
          moduleConfig,
          globalState.airlinesQueryCondition.airlines
        )
      ) {
        return (
          <span
            className="price-edit"
            data-test-id="price-edit"
            onClick={onFarePriceClick}
          >
            {getSharkText("key.fare_price")}
            <EditOutlined />
            <Popover content="Fare">
              <InfoCircleOutlined />
            </Popover>
          </span>
        );
      }
      return (
        <span>
          {getSharkText("key.fare_price")}
          <Popover content="Fare">
            <InfoCircleOutlined />
          </Popover>
        </span>
      );
    }, [
      globalState.airlinesQueryCondition.airlines,
      moduleConfig,
      onFarePriceClick,
    ]);

    const columns: Array<IDownloadHeader<SubCabinEntityExtend>> = useMemo(
      () => [
        {
          title: (
            <>
              <span>{getSharkText("config_page_cabin")}</span>
              <Popover content="CLS">
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          // dataIndex: "cabin",
          dataIndex: "cls",
          width: changedObj?.prefix ? 115 : 60,
          render: (val: string, r: SubCabinEntityExtend) => {
            // 生效小舱信息
            const tips = getCabinTips(r);
            let smallCabinEx: ReactNode = "";
            if (tips) {
              smallCabinEx = (
                <Popover content={tips.popupContent}>
                  <InfoCircleFilled
                    style={{ marginLeft: 3, color: tips.color }}
                  />
                </Popover>
              );
            } else {
              smallCabinEx = (
                <span style={{ display: "inline-block", width: 17 }}></span>
              );
            }
            if (!changedObj) {
              return (
                <>
                  <span style={{ display: "inline-block", width: 15 }}>
                    {val}
                  </span>
                  {smallCabinEx}
                </>
              );
            }
            let valElement: ReactNode = val;
            if (changedObj.suffix.indexOf(val) >= 0) {
              valElement = (
                <span style={{ color: "red", fontWeight: "bold" }}>{val}</span>
              );
            }
            if (changedObj.prefix.indexOf(val) >= 0) {
              valElement = (
                <>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    {val}
                  </span>
                  {changedObj.rawCabinPrefix.indexOf(val) >= 0 ? (
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        marginLeft: 5,
                      }}
                    >
                      {`${changedObj.rawCabinPrefix.replace(
                        "_",
                        ""
                      )} -> ${changedObj.suggestCabinPrefix.replace("_", "")}`}
                    </span>
                  ) : undefined}
                </>
              );
            }
            return (
              <>
                {smallCabinEx}
                {valElement}
              </>
            );
          },
        },
        {
          title: priceTitle,
          dataIndex: "farePrice",
          width: 70,
        },
        {
          title: (
            <>
              <span>{getSharkText("key.sold")}</span>
              <Popover content="BKD">
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          // dataIndex: "soldSeats",
          dataIndex: "bkd",
          width: 55,
        },
        {
          title: (
            <>
              <span>{getSharkText("config_page_original_limit_sale")}</span>
              <Popover content="LSV">
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          // dataIndex: "suggestLimitSold",
          dataIndex: "adjustingLsv",
          width: 167,
          render: (value, r: SubCabinEntityExtend) => {
            const val = r.adjustingLsv ?? r.lsv;
            const isChanged = `${val}` !== r.lsv;
            const color = `${val}` === r.lsv ? "" : "red";
            return (
              <Row gutter={5}>
                <Col>
                  <InputNumber
                    className="adjust-cabin-number"
                    size="small"
                    style={{
                      width: 60,
                      color,
                      transition: "all 0.0s linear",
                    }}
                    min={`${0}`}
                    value={val}
                    onChange={(v) => handleNumChange(v, r)}
                    onFocus={(e) => {
                      e.target.select();
                    }}
                    // onBlur={() => {
                    //   setEditing(false);
                    // }}
                  />
                </Col>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className="adjust-cabin-number"
                    type="primary"
                    size="small"
                    style={{
                      width: 21,
                      height: 18,
                      lineHeight: "18px",
                      padding: 0,
                      fontWeight: "bold",
                    }}
                    onClick={() => handleNumChange("#", r)}
                  >
                    #
                  </Button>
                </Col>
                <Col style={{ display: "flex", alignItems: "center" }}>
                  <Button
                    className="adjust-cabin-number"
                    type="primary"
                    size="small"
                    style={{
                      width: 21,
                      height: 18,
                      lineHeight: "18px",
                      padding: 0,
                      fontWeight: "bold",
                    }}
                    onClick={() => handleNumChange("-", r)}
                  >
                    -
                  </Button>
                </Col>
                <Col>
                  <span
                    style={{
                      color: "#00000088",
                      display: "inline-block",
                      width: 32,
                    }}
                  >
                    {isChanged ? r.lsv : ""}
                  </span>
                </Col>
              </Row>
            );
          },
        },
        {
          title: (
            <>
              <span>
                {getSharkText("config_page_suggested_sales_original_sales")}
              </span>
              <Popover content="LSS">
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          // dataIndex: "suggestSold",
          dataIndex: "adjustedLss",
          width: 85,
          render: (value: number, r: SubCabinEntityExtend) => {
            const val = r.adjustedLss ?? r.lss;
            if (
              `${val}` === r.lss ||
              (EQUAL_ITEM.includes(`${val}`) && EQUAL_ITEM.includes(`${r.lss}`))
            ) {
              return val;
            } else {
              return (
                <div style={{ display: "flex" }}>
                  <div style={{ color: "red", width: "50%" }}>{val}</div>
                  <div
                    style={{
                      color: "#00000088",
                      width: "50%",
                    }}
                  >
                    {r.lss}
                  </div>
                </div>
              );
            }
          },
        },
        {
          // P锁
          title: (
            <>
              <span>
                {getSharkText(
                  "config_page_permanent_application_directly_select"
                )}
              </span>
              <Popover content="P">
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          dataIndex: "permanentAdjusting",
          width: 50,
          render: (val: boolean, r: SubCabinEntityExtend) => (
            <span>
              <Checkbox
                checked={val}
                className={val === r.permanentOri ? "" : "changed"}
                onChange={(v) => {
                  handlePermanentChange(v, r);
                }}
              />
              <span
                style={{
                  visibility: shouldClosePermanent(r) ? "visible" : "hidden",
                }}
              >
                <Popover content="已售罄，未关舱">
                  <InfoCircleOutlined style={{ color: "red" }} />
                </Popover>
              </span>
            </span>
          ),
        },
        {
          // 嵌套
          title: (
            <>
              <span>{getSharkText("config_page_nested_combination")}</span>
              <Popover content="L">
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          dataIndex: "nestAdjusting",
          width: 50,
          render: (val: boolean, r: SubCabinEntityExtend) => (
            <Checkbox
              className={val === r.nestOri ? "" : "changed"}
              checked={val}
              onChange={(v) => {
                handleNestChange(v, r);
              }}
            />
          ),
        },
        {
          title: (
            <>
              <span>{getSharkText("config_page_status_indicator")}</span>
              <Popover content="IND">
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          dataIndex: "adjustingInd",
          width: 90,
          render: (val: string, r: SubCabinEntityExtend) => {
            let raw = "";
            if (r.ind && r.ind !== r.adjustingInd) {
              raw = r.ind;
            }
            return (
              <span>
                <span style={{ color: raw ? "red" : "" }}>{val}</span>
                {raw ? (
                  <span style={{ marginLeft: 5, color: "#00000088" }}>
                    {raw}
                  </span>
                ) : undefined}
              </span>
            );
          },
        },
      ],
      [
        changedObj,
        handleNestChange,
        handleNumChange,
        handlePermanentChange,
        priceTitle,
      ]
    );

    const onRowClassName = useRefFunc((r: SubCabinEntityExtend) => {
      const tmpClass = [];
      if (isGrayLine(r.lss, r.ind)) {
        tmpClass.push("gray-bg");
      }
      if (cabinBlockInfo.some((b) => b[0] === r.cls)) {
        tmpClass.push("block-start");
      }
      if (cabinBlockInfo.some((b) => b[1] === r.cls)) {
        tmpClass.push("block-end");
      }
      return tmpClass.join(" ");
    });

    return (
      <div className="adjust-cabin-container">
        <Spin spinning={isLoading}>
          <Table
            className="micro-table"
            columns={columns}
            dataSource={displayCabins}
            pagination={false}
            size="small"
            sticky
            rowClassName={onRowClassName}
          ></Table>
        </Spin>
        <DynamicFarePrice
          ref={dynamicFarePriceRef}
          takeoffdate={takeoffdate}
          flightNo={flightNo}
          route={curSeg.line}
        />
      </div>
    );
  }
);
AdjustCabin.displayName = "AdjustCabin";
export default AdjustCabin;
