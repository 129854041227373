import React, { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, message, Row } from "antd";
import hmacMD5 from "crypto-js/hmac-md5";
import "./index.scss";
import { BINDING, IS_PRO, SERVER, TOKEN, UID } from "Constants";
import { setCookie } from "Utils/cookie";
import useGlobal from "Store";
import VerifyCode from "./verifyCode";
import Register from "./RegisterCard";
import Forget from "./Forget";
import Reset from "./Reset";
import StatusCard from "./StatusCard";
import { useForm } from "antd/es/form/Form";
import useI18Next from "Utils/useI18Next";
import { useNavigate } from "react-router-dom";
import { DEFAULT_SUB_FAT } from "../../../Utils/fetchBase";

interface IProps {
  isReset?: boolean;
}

const Login = (props: IProps) => {
  const [globalState, actions] = useGlobal();
  const { setLoginPageState, setRefreshCodeState, setLogin } = actions;
  const { verifyInfo, loginPageState, refreshCode } = globalState;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = useForm();

  const { isReset } = props;
  const t = useI18Next();

  const login = async (params: any) => {
    console.log("login");
    try {
      const result: any = await fetch(
        `${SERVER}login?subEnv=${DEFAULT_SUB_FAT}`,
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            account: params.username,
            pwd: hmacMD5(params.password, params.username).toString(),
            validCode: {
              token: verifyInfo.token,
              version: verifyInfo.version,
              requestId: verifyInfo.requestId,
            },
            loginType: 0,
            remember: params.remember,
          }),
        }
      );
      const res = await result.json();
      if (res.flag) {
        const { uid = "", token = "", binding = "" } = res;
        setCookie(UID, uid);
        setCookie(TOKEN, token);
        setCookie(BINDING, binding);
        setLogin({ uid, token, name: "" });
        console.log("login to dashboard");
        // window.location.replace("/dashboard");
        navigate("/dashboard");
      } else {
        const code = res.ResponseStatus.Errors[0].ErrorCode;
        message.error(t(`error.${code}`));
        setLoading(false);
        setRefreshCodeState(!refreshCode);
      }
    } catch (error) {
      message.error(t("login.error"));
    }
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    const rst = await form.validateFields();
    if (rst) {
      if (verifyInfo.token === "" && IS_PRO) {
        message.error(t("login.needSecurity"));
      } else {
        setLoading(true);
        login(form.getFieldsValue());
      }
    }
  };

  const handleToggleRegister = () => {
    setLoginPageState({
      mode: "register",
    });
  };

  const handleToggleReset = () => {
    setLoginPageState({
      mode: "reset-submit",
    });
  };

  const loginCard = () => (
    <Card className="login-card">
      <div className="login-card-title-wrap">
        <div className="logo logo-reverse" style={{ marginBottom: 12 }}></div>
        {/* <div className="login-card-title">{t("login.title")}</div> */}
        <p className="login-title-sub">{t("common.siteTitle")}</p>
        <p className="login-title-sub">{t("common.siteDesc")}</p>
      </div>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{ remember: true }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: t("login.inputAccount"),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="login-input-icon" />}
            placeholder={t("login.inputAccount")}
            className="login-card-input"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: t("login.inputPwd") }]}
        >
          <Input
            prefix={<LockOutlined className="login-input-icon" />}
            type="password"
            placeholder={t("login.inputPwd")}
            className="login-card-input"
          />
        </Form.Item>
        <VerifyCode />
        <Row>
          <Col span={12}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox className="login-input-icon">
                {t("login.autoNext")}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <a
              className="login-form-forgot"
              onClick={handleToggleReset}
              style={{ lineHeight: "32px" }}
            >
              {t("login.forgetPwd")}？
            </a>
          </Col>
        </Row>
        <Form.Item>
          <>
            <Button
              id="btnLogin"
              type="primary"
              loading={loading}
              htmlType="submit"
              size="large"
              className="login-form-button"
            >
              {t("login.signIn")}
            </Button>
            <div style={{ marginTop: 12 }}>
              <span>{t("login.noAccount")}？ </span>
              <a href="#" onClick={handleToggleRegister}>
                {t("login.signUp")}
              </a>
            </div>
          </>
        </Form.Item>
      </Form>
    </Card>
  );

  const renderCard = () => {
    if (isReset && loginPageState.mode !== "reset-success") {
      return <Reset />;
    }
    switch (loginPageState.mode) {
      case "login":
        return loginCard();
      case "register":
        return <Register mode={loginPageState.mode} />;
      case "reset-submit":
        return <Forget mode={loginPageState.mode} />;
      case "register-success":
        return (
          <StatusCard
            title={t("login.signUpEmailSuccess")}
            content={t("login.signUpSuccessMsg")}
          />
        );
      case "reset-submit-success":
        return (
          <StatusCard
            title={t("common.submitSuccess")}
            content={t("login.resetSubmitSuccessMsg")}
          />
        );
      case "reset-success":
        return (
          <StatusCard
            title={t("login.resetSuccess")}
            content={t("login.resetSuccessMsg")}
          />
        );
    }
  };

  return (
    <>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {/* <Col md={{ span: 8, offset: 4 }} xs={24}>
            <div className="logo-wrap-lg">
              <div className="logo logo-reverse" />
              <div className="logo-text-lg" />
            </div>
            <p className="login-title-sub">{t("common.siteTitle")}</p>
            <p className="login-title-sub">{t("common.siteDesc")}</p>
            {!isSupportedBrowser ? (
              <p className="login-title-sub login-browser-warning">
                {t("common.browserWarn")}
              </p>
            ) : undefined}
          </Col> */}
          <div>{renderCard()}</div>
        </div>
        <input type="hidden" id="page_id" value="wait" />
      </div>
    </>
  );
};
export default Login;
