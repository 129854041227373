import { ICardConfigList } from "Components/Card/CardsInterface";
import { ECompareTypeV2 } from "Interface";
import {
  CardsConfig,
  precisionConfig,
} from "Page/AI/CoreOverview/Components/CoreOverviewBarLineCharts/CardConfig";
import { getCompareVal, showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

export const AirlinesCardsConfig: ICardConfigList = {
  ow_price: {
    trend: {
      name: {
        formatter: (v, card, compareType) => {
          return compareType === ECompareTypeV2.MARKET_COMPARE
            ? getSharkText("key.compare_to_market")
            : v;
        },
      },
      value: {
        formatter: (v, card, compareType) => {
          let value = v;
          if (compareType === ECompareTypeV2.MARKET_COMPARE) {
            const { cardValue, compareValue } = card;
            value = getCompareVal(cardValue, compareValue);
          }
          return { value, label: showNum(value * 100, "percentage") };
        },
      },
    },
  },
  passengers: CardsConfig.passengers,
  kilo_passenger_traffic: CardsConfig.kilo_passenger_traffic,
  kilo_transport_capacity: CardsConfig.kilo_transport_capacity,
  capacity_production_cost: CardsConfig.capacity_production_cost,
  income_production_cost: CardsConfig.income_production_cost,
  passenger_kilo_rm: precisionConfig,
};
