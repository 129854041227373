import { DPMonitorFilter } from "@ctrip/flt-bi-flightai-airlines";
import { Button, Checkbox, Col, Radio, Row, Select } from "antd";
import { ReactComponent as VS } from "assets/svg/vs.svg";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import Airlines from "Components/Toolbar/Airlines";
import { AggCode, RangeObject } from "Interface";
import moment from "moment";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import SelectMultRoute from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import useRefFunc from "Utils/useRefFunc";
import { EQueryType, MonitoringAggOptions, QueryTypeOptions } from "../common";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import { Moment } from "moment/moment";
import { DATE_FORMAT } from "../../../../Constants";
import { cloneDeep } from "lodash";
import { getSharkText } from "Utils/i18nGlobal";

export interface ITopSearchProps {
  onChange: (v: DPMonitorFilter) => void;
}

/**
 * 页面顶部通用过滤器
 */
const TopSearch = (props: ITopSearchProps): ReactElement => {
  const { onChange } = props;
  const [globalState, actions] = useGlobalState();
  const { userInfo, systemType, airlinesQueryCondition } = globalState;
  const [monitoringRange, setMonitoringRange] = useState<RangeObject>([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);
  const [monitoringCompareRange, setMonitoringCompareRange] =
    useState<RangeObject>([null, null]);
  const [takeoffRange, setTakeoffRange] = useState<RangeObject>([null, null]);
  const [takeoffCompareRange, setTakeoffCompareRange] = useState<RangeObject>([
    null,
    null,
  ]);
  const [granularity, setGranularity] = useState<AggCode>(AggCode.hour);
  const [queryType, setQueryType] = useState<EQueryType>(1);
  const [routes, setRoutes] = useState<string[]>([]);
  const [flights, setFlights] = useState<string[]>([]);
  const [isCompareRoute, setIsCompareRoute] = useState<boolean>(false);
  const [isCompareFlight, setIsCompareFLight] = useState<boolean>(false);
  const [aggOption, setAggOption] = useState(MonitoringAggOptions);

  // 设置一个全局时间以便查询航班航线列表
  useEffect(() => {
    actions.setAirlinesQueryConditionAttr({
      startDate: "2020-01-01",
      endDate: moment().format(DATE_FORMAT),
    });
  }, []);

  useEffect(() => handleSubmit, []);

  useEffect(() => {
    if (
      monitoringRange &&
      monitoringRange[0] &&
      monitoringRange[1] &&
      monitoringRange[1]?.diff(monitoringRange[0], "d") >= 4
    ) {
      const op = cloneDeep(aggOption);
      op.forEach((o) => {
        if (o.value !== AggCode.day) {
          o.disabled = true;
        }
      });
      setAggOption(op);
      setGranularity(AggCode.day);
    } else {
      setAggOption(MonitoringAggOptions);
    }
  }, [monitoringRange]);

  const handleSubmit = useRefFunc(() => {
    onChange({
      monitoringRange:
        monitoringRange
          ?.filter((m) => Boolean(m))
          .map((m) => m?.format() || "") || null,
      monitoringCompareRange:
        monitoringCompareRange
          ?.filter((m) => Boolean(m))
          .map((m) => m?.format() || "") || null,
      takeoffRange:
        takeoffRange
          ?.filter((m) => Boolean(m))
          .map((m) => (m && m.format()) || "") || null,
      takeoffCompareRange:
        takeoffCompareRange
          ?.filter((m) => Boolean(m))
          .map((m) => m?.format() || "") || null,
      granularity,
      queryType,
      airlines: airlinesQueryCondition.airlines,
      routes,
      flights,
      isCompareRoute,
      isCompareFlight,
      mode: [],
    });
  });

  const otherProps: RangePickerProps<Moment> = {
    showTime: {
      format: "HH",
      hideDisabledOptions: true,
      defaultValue: [
        moment("00:00:00", "HH:mm:ss"),
        moment("23:59:59", "HH:mm:ss"),
      ],
    },
    format: "YY-MM-DD HH:mm",
  };

  const setMonitoringCompareRangeAuto = (dates: RangeObject) => {
    if (
      dates &&
      dates[0] &&
      monitoringRange &&
      monitoringRange[0] &&
      monitoringRange[1]
    ) {
      const copy = cloneDeep(dates[0]);
      dates[1] = copy.add(
        monitoringRange[1]?.diff(monitoringRange[0], "s"),
        "s"
      );
    }
    if (!dates || !dates[0]) {
      setMonitoringCompareRange([null, null]);
    } else {
      setMonitoringCompareRange(dates);
    }
  };

  const setTakeoffCompareRangeAuto = (dates: RangeObject) => {
    if (
      dates &&
      dates[0] &&
      takeoffRange &&
      takeoffRange[0] &&
      takeoffRange[1]
    ) {
      const copy = cloneDeep(dates[0]);
      dates[1] = copy.add(takeoffRange[1]?.diff(takeoffRange[0], "s"), "s");
    }
    if (!dates || !dates[0]) {
      setTakeoffCompareRange([null, null]);
    } else {
      setTakeoffCompareRange(dates);
    }
  };

  return (
    <Row gutter={[10, 10]}>
      <Col>
        <span>{getSharkText("config_page_PaAiMoCoTo_monitoring_date")}:</span>
      </Col>
      <Col>
        <DateRangePickerV2
          value={monitoringRange}
          onChange={(v) => {
            setMonitoringRange(v);
            setTakeoffRange([null, null]);
          }}
          defaultDateMode="none"
          otherProps={otherProps}
        />
      </Col>
      <Col>
        <VS />
        <DateRangePickerV2
          value={monitoringCompareRange}
          onChange={(v) => {
            setMonitoringCompareRangeAuto(v);
            setTakeoffCompareRange([null, null]);
          }}
          defaultDateMode="none"
          otherProps={{
            ...otherProps,
            // disabled: [false, true],
            allowClear: true,
          }}
        />
      </Col>
      <Col>
        <span>{getSharkText("charts.date_of_departure")}:</span>
      </Col>
      <Col>
        <DateRangePickerV2
          value={takeoffRange}
          onChange={setTakeoffRange}
          defaultDateMode="none"
          otherProps={{
            ...otherProps,
            allowClear: true,
            disabledDate: (cur) => {
              if (monitoringRange && monitoringRange[0] && monitoringRange[1]) {
                const tooLate = cur.diff(monitoringRange[1], "days") > 60;
                const tooEarly = monitoringRange[0].diff(cur, "s") > 0;
                return tooEarly || tooLate;
              }
              return true;
            },
          }}
        />
      </Col>
      <Col>
        <VS />
        <DateRangePickerV2
          value={takeoffCompareRange}
          onChange={setTakeoffCompareRangeAuto}
          defaultDateMode="none"
          otherProps={{
            ...otherProps,
            // disabled: [false, true],
            allowClear: true,
            disabledDate: (cur) => {
              if (
                monitoringCompareRange &&
                monitoringCompareRange[0] &&
                monitoringCompareRange[1]
              ) {
                const tooLate =
                  cur.diff(monitoringCompareRange[1], "days") > 14;
                const tooEarly = monitoringCompareRange[0].diff(cur, "s") > 0;
                return tooEarly || tooLate;
              }
              return true;
            },
          }}
        />
      </Col>
      <Col>
        <Radio.Group
          options={aggOption}
          optionType="button"
          value={granularity}
          onChange={(e) => setGranularity(e.target.value)}
        />
      </Col>
      <Col>
        <Select
          options={QueryTypeOptions}
          value={queryType}
          onChange={setQueryType}
        ></Select>
      </Col>
      <Col>
        <Airlines />
      </Col>
      {queryType !== EQueryType.Airline ? (
        <Col>
          <SelectMultRoute
            routes={routes}
            setRoutes={setRoutes}
            isDemo={false}
            flights=""
            useExchange
            mode="multiple"
          />
        </Col>
      ) : undefined}
      {queryType !== EQueryType.Airline ? (
        <Col>
          <Checkbox
            checked={isCompareRoute}
            onChange={(e) => setIsCompareRoute(e.target.checked)}
            disabled={routes.length === 0 || routes.length > 2}
          >
            {getSharkText("config_page_compare_route")}
          </Checkbox>
        </Col>
      ) : undefined}
      {queryType === EQueryType.Flight ? (
        <Col>
          <SelectMultiFlight
            flightNO={flights}
            setFlightNO={setFlights}
            routes=""
            isDemo={false}
            mode="multiple"
          />
        </Col>
      ) : undefined}
      {queryType === EQueryType.Flight ? (
        <Col>
          <Checkbox
            checked={isCompareFlight}
            onChange={(e) => setIsCompareFLight(e.target.checked)}
            disabled={flights.length === 0 || flights.length > 3}
          >
            {getSharkText("config_page_compare_flight")}
          </Checkbox>
        </Col>
      ) : undefined}
      <Col>
        <Button type="primary" onClick={handleSubmit}>
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default TopSearch;
