import React, { useState, useEffect, useCallback } from "react";
import { SwapRightOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import useGlobal from "Store";
import CascaderArea from "Components/CascaderArea";
import { Area, FlightArea } from "Interface";
import { DEFAULT_AREA } from "Constants";
import { getModuleStatus, getModuleNameFromPath } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { Link } from "react-scroll";
import { useLocation } from "react-router-dom";

interface SearchAreaProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  setFlightAreaQueryExt: (ext: FlightArea) => void;
}

const SearchArea: React.FC<SearchAreaProps> = (props: SearchAreaProps) => {
  const { setFlightAreaQueryExt } = props;
  const location = useLocation();
  const [departArea, setDepartArea] = useState<Area>(DEFAULT_AREA);
  const [arriveArea, setArriveArea] = useState<Area>(DEFAULT_AREA);
  const [loading, setLoading] = useState(false);
  const [globalState] = useGlobal();
  const { userInfo, queryCondition, systemType } = globalState;
  const { airportName, flightClass } = queryCondition;
  const { pathname } = location;
  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;

  const onChangeScroll = useCallback((v: any) => {
    // setScrollV(v);
    console.log(v);
  }, []);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false));
  }, [flightClass]);

  useEffect(() => {
    setFlightAreaQueryExt({
      departArea,
      arriveArea,
    });
  }, [departArea, arriveArea, setFlightAreaQueryExt]);

  return (
    <Row align="middle" gutter={10} style={{ marginTop: 0 }}>
      <Col>
        <CascaderArea
          type="airport"
          setSelectedArea={setDepartArea}
          flightClass={flightClass}
          isDemo={isDemo}
        />
      </Col>
      <Col>
        <SwapRightOutlined />
      </Col>
      <Col>{airportName}</Col>
      <Col>
        <SwapRightOutlined />
      </Col>
      <Col>
        {loading ? undefined : (
          <CascaderArea
            type="airport"
            setSelectedArea={setArriveArea}
            flightClass={flightClass}
            isDemo={isDemo}
          />
        )}
      </Col>
      <Col>
        <Link
          className="ant-radio-button-wrapper"
          onClick={() => onChangeScroll("1")}
          activeClass="active"
          to="transfer-trend"
          spy={true}
          smooth={true}
          duration={300}
        >
          {getSharkText("key.trendchart.psgrsource")}
        </Link>
        <Link
          className="ant-radio-button-wrapper"
          onClick={() => onChangeScroll("2")}
          activeClass="active"
          to="transfer-table"
          spy={true}
          smooth={true}
          duration={300}
          offset={-110}
        >
          {getSharkText("config_page_details")}
        </Link>
      </Col>
    </Row>
  );
};

export default SearchArea;
