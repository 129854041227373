// Created by xh_zhu on 2021-01-07
// 顶部的过滤栏

import { Affix, Alert, Col, Row } from "antd";
import FlightClassCom from "Components/Toolbar/FlightClassCom";
import Airport from "./Airport";
import useGlobal from "Store";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import DateRangePicker from "Components/Toolbar/DateRangePicker";
import { Area } from "Interface";
import PreLastChain from "Components/PreLastChain";
import { DEFAULT_AIRPORT_COMPARE_TYPE_NAME } from "Constants";

interface ITopSearchAreaProps {
  alertMessage: ReactNode;
  onRiseControlChange?: (val: string[]) => void;
  onAirportChange: (val: Area) => void;
}

const TopSearchArea = (props: ITopSearchAreaProps): ReactElement => {
  const { onRiseControlChange, onAirportChange, alertMessage } = props;
  const [isShowAlert, setIsShowAlert] = useState<boolean>(true);
  const [globalState] = useGlobal();
  const { queryCondition } = globalState;
  const { startDate, endDate } = queryCondition;
  const defaultRiseControl = [DEFAULT_AIRPORT_COMPARE_TYPE_NAME];
  const [riseControl, setRiseControl] = useState<string[]>(defaultRiseControl);

  useEffect(() => {
    if (onRiseControlChange) {
      onRiseControlChange(riseControl);
    }
  }, [riseControl, onRiseControlChange]);

  return (
    <div>
      {alertMessage && isShowAlert ? (
        <Alert
          className="flightClassAlert"
          message={alertMessage}
          type="info"
          banner
          closable
          afterClose={() => setIsShowAlert(false)}
        />
      ) : undefined}
      <Affix offsetTop={0} className="toolbar-wrap">
        <div className="toolbar">
          <Row gutter={[{ md: 24 }, 20]} align="middle">
            <Col>
              <FlightClassCom />
            </Col>
            <Col>
              <Airport onChange={onAirportChange} />
            </Col>
            <Col>
              <DateRangePicker defaultDateMode="lastMonth" />
            </Col>
            <Col>
              <PreLastChain
                needValueOption={false}
                defaultValue={defaultRiseControl}
                onChange={setRiseControl}
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          </Row>
        </div>
      </Affix>
    </div>
  );
};
export default TopSearchArea;
