// Created by xh_zhu on 2021-02-24

import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Col, DatePicker, Radio, RadioChangeEvent, Row } from "antd";
import { DATE_FORMAT } from "Constants";
import moment from "moment";
import Trend from "Page/AI/Dashboard/Components/Trend";
import { getDateWeek } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import FullScreen from "Components/FullScreen";
import {
  CHART_TABLE_CODE,
  MODULE_CODE,
  QUERY_URL,
} from "Page/AI/FlightManage/fetchCode";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import useGlobalState from "Store";
import Feedback from "../PriceCalendar/Feedback";
import { IFlightRecord, TView } from "../../FlightManageInterface";
import { CaretLeftFilled, CaretRightFilled } from "@ant-design/icons";
import useRefFunc from "Utils/useRefFunc";
import TrendContent from "./TrendContent";
import CardContainer from "./Card/CardContainer";
import DetailTable from "../DetailTable";

interface IPriceTrendContentProps {
  record: IFlightRecord;
  setModalVisible: (v: boolean) => void;
  setRecord: (r: IFlightRecord) => void;
  onNext: () => void;
  onPrev: () => void;
  currentIdx: number;
  totalSize: number;
  isDemo: boolean;
}

const PriceTrendContent = (props: IPriceTrendContentProps): ReactElement => {
  const { record, setRecord, onNext, onPrev, currentIdx, totalSize, isDemo } =
    props;
  const [compareDate, setCompareDate] = useState<string | undefined>();
  const [compareFlightNo, setCompareFlightNo] = useState<string | undefined>();
  const { id } = record;
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  // 视角, 0 = 预售监控, 1= 销售过程
  const [view, setView] = useState<TView>(0);

  useEffect(() => {
    setCompareDate(undefined);
  }, [id]);

  const renderDetailHeader = (r: IFlightRecord) => {
    // 计算距今天数使用自然日相减
    const takeoffDateMoment = moment(r.takeoffdate);
    const time = r.takeofftime;
    const diffDaysFromToday = takeoffDateMoment.diff(
      moment().format(DATE_FORMAT),
      "days"
    );
    return (
      <>
        <Col className="dpr-price-flightno">{r.flightNo}</Col>
        <Col className="dpr-price-route">
          {r.route.substr(0, 3)}-{r.route.substr(3, 3)}
        </Col>
        <Col className="dpr-price-takeoff-date">
          <span>
            <CaretLeftFilled
              style={{ color: currentIdx > 0 ? "#1890ff" : "#aaa" }}
              onClick={onPrev}
            />
          </span>
          {takeoffDateMoment.format(DATE_FORMAT)}
          <span>
            <CaretRightFilled
              style={{
                color: currentIdx === totalSize - 1 ? "#aaa" : "#1890ff",
              }}
              onClick={onNext}
            />
          </span>
        </Col>
        <Col className="dpr-price-weekday">{getDateWeek(r.takeoffdate)}</Col>
        <Col className="dpr-price-takeoff-time">
          {time}
          {getSharkText("config_page_takeoff")}
        </Col>
        <Col className="dpr-price-before-depart">
          {getSharkText("config_page_days_from_today")}
          {diffDaysFromToday}
          {getSharkText("key.day.button")}
        </Col>
      </>
    );
  };

  const renderHeaderSuggest = () => {
    const diff = record.suggestPrice - record.rawPrice;
    const color = diff > 0 ? "#D03D33" : "green";
    return (
      <>
        <span className="dpr-control-price-suggest-title">
          {getSharkText("config_page_suggested_price")}
        </span>
        <span style={{ color, marginLeft: 5 }}>{record.suggestPrice}</span>
        <Trend
          style={{ display: "inline-block" }}
          flag={diff > 0 ? "up" : "down"}
          showFlag={diff !== 0}
          downColor="#4eb31c"
        />
        <span style={{ color, margin: "0 10px 0 5px" }}>{Math.abs(diff)}</span>
        <span className="dpr-control-price-suggest-title">原价</span>
        <span style={{ marginLeft: 5 }}>{record.rawPrice}</span>
      </>
    );
  };

  const onChangeDate = (date: any, dateString: any) => {
    setCompareDate(dateString);
  };

  const handleChangeView = useRefFunc((e: RadioChangeEvent) => {
    setCompareDate(undefined);
    setView(e.target.value);
  });

  const titleExtends = useMemo(
    () => (
      <Col span={4}>
        <Radio.Group value={view} onChange={handleChangeView}>
          <Radio.Button value={1}>
            {getSharkText("config_page_presale_view")}
          </Radio.Button>
          <Radio.Button value={0}>
            {getSharkText("config_page_sales_process_view")}
          </Radio.Button>
        </Radio.Group>
      </Col>
    ),
    [handleChangeView, view]
  );

  return (
    <div className="fullScreen-module">
      <FullScreen
        isFullScreen={isFullScreen}
        onChange={setIsFullScreen}
        titleExtends={titleExtends}
      >
        <div className="flightmanage-price-record-detail">
          <div>
            <Row className="dpr-header">{renderDetailHeader(record)}</Row>
            <Row className="dpr-control">
              <Col className="dpr-control-price">{renderHeaderSuggest()}</Col>
              <Col className="dpr-control-feedback">
                <Feedback
                  feedback={record.feedback}
                  cause={record.cause}
                  setRecord={setRecord}
                  record={record}
                  passText={getSharkText("config_page_PaAiDyCoPr_accept")}
                  refuseText={getSharkText("config_page_refuse")}
                  isDemo={isDemo}
                ></Feedback>
              </Col>
              <Col className="dpr-control-compareDate">
                <SelectMultiFlight
                  title="对比竞飞："
                  queryUrl={QUERY_URL.routeFlight}
                  moduleCode={MODULE_CODE}
                  flightNO={compareFlightNo || ""}
                  setFlightNO={(v) =>
                    setCompareFlightNo(v.length ? v[0] : undefined)
                  }
                  routes={record.route}
                  isDemo={isDemo}
                  type={3}
                  placeholder={getSharkText("key.mutiple_flightno.query")}
                  style={{ width: 150 }}
                  mode={undefined}
                  allowClear
                />
              </Col>
              {view === 0 ? (
                <Col className="dpr-control-compareDate">
                  <span
                    className="search-area-filter-title"
                    style={{ width: 100, marginRight: 5 }}
                  >
                    {getSharkText("config_page_compare_takeoff_date")}:
                  </span>
                  <DatePicker
                    value={(compareDate && moment(compareDate)) || undefined}
                    onChange={onChangeDate}
                  />
                </Col>
              ) : undefined}
            </Row>
          </div>
          <div style={{ overflow: "scroll" }}>
            {airlinesQueryCondition.airlines === "DR" ? (
              <Row justify="space-around" style={{ marginBottom: 10 }}>
                <Col span={6}>
                  <CardContainer record={record} type="overview" />
                </Col>
                <Col span={6}>
                  <CardContainer
                    record={record}
                    type="detail"
                    compareType="week"
                  />
                </Col>
                <Col span={6}>
                  <CardContainer
                    record={record}
                    type="detail"
                    compareType="year"
                  />
                </Col>
              </Row>
            ) : undefined}
            <div
              className="dynamic-price-record-trend"
              style={{ overflow: "visible" }}
            >
              <TrendContent
                record={record}
                compareDate={compareDate}
                compareFlightNo={compareFlightNo}
                view={view}
              />
            </div>
            <div>
              <DetailTable
                record={record}
                compareFlightNo={compareFlightNo || ""}
                moduleCode={MODULE_CODE}
                chartTableCode={CHART_TABLE_CODE.table}
              />
            </div>
          </div>
        </div>
      </FullScreen>
    </div>
  );
};
export default PriceTrendContent;
