import {
  ContainerOutlined,
  ExclamationCircleOutlined,
  FunnelPlotFilled,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Col, Empty, message, Popover, Radio, Row, Spin } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import BaseCard from "./Components/BaseCard";
import {
  DEFAULT_FILTER,
  IFilter,
  INotificationDataType,
  TRACE_ID,
} from "./NotificationCenterCommon";
import { MODULE_CODE, QUERY_URL } from "./fetchCode";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { ubt, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import styles from "./Panel.module.scss";
import { cloneDeep, isEqual, uniqWith } from "lodash";
import moment from "moment";
import { DATE_FORMAT, EMPTY_ARRAY, EMPTY_OBJECT } from "Constants";
import confirm from "antd/lib/modal/confirm";
import Filter from "./Filter";

export interface IPanelProps {
  openSettings: () => void;
  setWarnCnt: (v: number | "...") => void;
  setInfoCnt: (v: number | "...") => void;
  userConfig: any;
}

const pageSize = 5;
// TODO 存在一个bug, 某些页面初始化时, 会进行很多请求, 触发请求数量上限阈值. 而通知中心碰到阈值后会请求失败, 并且不能重新请求, 导致数据显示异常
// TODO 频繁的重复加载, 会导致请求数量很容易达到阈值, 需要优化
/**
 * 通知面板
 */
const Panel = (props: IPanelProps): ReactElement => {
  const { openSettings, setWarnCnt, setInfoCnt, userConfig } = props;
  // 信息类别, 1 = 监控预警, 2 = 信息建议
  const [type, setType] = useState<1 | 2>(1);
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const { startDate, endDate } = airlinesQueryCondition;
  // const [loading, setLoading] = useState<boolean>(false);
  const [totalWarnData, setTotalWarnData] = useState<INotificationDataType[]>(
    []
  );
  const [totalInfoData, setTotalInfoData] = useState<INotificationDataType[]>(
    []
  );
  const [showData, setShowData] = useState<INotificationDataType[]>([]);
  // 是否当前查看的归档
  const [status, setStatus] = useState<number>(0);
  // 筛选项
  const [filterVal, setFilterVal] = useState<IFilter>(DEFAULT_FILTER);
  const isFilterMode = filterVal.isFilterMode;
  const url: string = isFilterMode ? QUERY_URL.popupSearch : QUERY_URL.popup;

  const filterQuery = useMemo(() => {
    return filterVal.dateRange?.length
      ? {
          ...airlinesQueryCondition,
          startDate: filterVal.dateRange[0].format(DATE_FORMAT),
          endDate: filterVal.dateRange[1].format(DATE_FORMAT),
        }
      : null;
  }, [airlinesQueryCondition, filterVal.dateRange]);

  const filterExt = useMemo(() => {
    return isFilterMode
      ? {
          flightNO: filterVal.flightNO?.join(","),
          type: filterVal.filterType,
          route: filterVal.route?.join(","),
        }
      : EMPTY_OBJECT;
  }, [isFilterMode, filterVal]);

  const [
    { data: warnData, isLoading: warnLoading, res: warnRes },
    doFetchWarn,
  ] = useFetch<INotificationDataType[]>({
    server: getServer(systemType),
    url,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: filterExt,
    lazey: true,
    debugId: "fetch-warn-data",
  });

  const [
    { data: infoData, isLoading: infoLoading, res: infoRes },
    doFetchInfo,
  ] = useFetch<INotificationDataType[]>({
    server: getServer(systemType),
    url,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: filterExt,
    lazey: true,
    debugId: "fetch-info-data",
  });

  const refreshTotal = useCallback(() => {
    console.log("refresh total");
    const fn = type === 1 ? doFetchWarn : doFetchInfo;
    const query = filterVal.dateRange?.length
      ? {
          ...airlinesQueryCondition,
          startDate: filterVal.dateRange[0].format(DATE_FORMAT),
          endDate: filterVal.dateRange[1].format(DATE_FORMAT),
        }
      : airlinesQueryCondition;
    fn({
      url: QUERY_URL.popup,
      query,
      ext: {
        status,
        type,
        date: moment().format(DATE_FORMAT),
        page: {
          page: 1,
          pageSize: 0,
        },
        ...filterExt,
      },
    });
  }, [
    airlinesQueryCondition,
    doFetchInfo,
    doFetchWarn,
    filterExt,
    filterVal.dateRange,
    status,
    type,
  ]);

  /**
   * 清除所有操作
   */
  const [, removeAllFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL.setPopup,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {
      type,
    },
    lazey: true,
    onSuccess: useCallback(
      (res: any) => {
        if (res.flag) {
          message.success(getSharkText("config_page_processed"));
          refreshTotal();
        } else {
          message.error(getSharkText("config_page_operation_abnormal"));
        }
      },
      [refreshTotal]
    ),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_operation_failed"));
    }, []),
  });

  /**
   * 对单张卡片做已处理操作
   */
  const [, dealFetch] = useFetch<any[]>({
    server: getServer(systemType),
    url: QUERY_URL.setPopup,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {
      type,
      id: "-1",
    },
    lazey: true,
    /**
     * setData是卡片处理完成以后, 从父节点中移除卡片的操作, 必须在所有请求完成后再调用。
     * 过早调用可能导致请求未完成, 卡片已经被从父节点中卸载, 导致请求无法正常完成
     */
    onSuccess: useCallback(
      (res: any) => {
        if (res.flag) {
          message.success(getSharkText("config_page_processed"));
          refreshTotal();
        } else {
          message.error(getSharkText("config_page_operation_abnormal"));
        }
      },
      [refreshTotal]
    ),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_operation_failed"));
    }, []),
  });

  /**
   * 数据处理后, 从当前列表中删除这一条数据
   */
  const setData = useCallback(
    (d: INotificationDataType) => {
      console.log("set data:", d);
      const totalList = type === 1 ? totalWarnData : totalInfoData;
      const setFn = type === 1 ? setTotalWarnData : setTotalInfoData;
      // 将已处理的项排除在列表外
      console.log("before set list: ", totalList);
      const exceptList = totalList.filter((s) => s.id !== d.id);
      console.log("after set list: ", exceptList);
      setFn(exceptList);
    },
    [totalInfoData, totalWarnData, type]
  );

  const setPopup = useCallback(
    (data: INotificationDataType) => {
      console.log("set popup");
      if (data.status === 1) {
        return;
      }
      console.log("fetch submit");
      dealFetch({
        ext: {
          type,
          id: data.id,
        },
      });
      const dataCP = cloneDeep(data);
      dataCP.status = 1;
      setData(dataCP);
    },
    [type, dealFetch, setData]
  );

  /**
   * 类型展示埋点
   */
  useEffect(() => {
    if (type === 1) {
      ubt(TRACE_ID.getName("warn"));
    } else if (type === 2) {
      ubt(TRACE_ID.getName("info"));
    }
  }, [type]);

  useEffect(() => {
    if (warnLoading) {
      setWarnCnt("...");
    } else if (warnRes && warnRes.page) {
      setWarnCnt(warnRes.page.total);
    }
  }, [warnRes, warnLoading, setWarnCnt]);

  useEffect(() => {
    if (infoLoading) {
      setInfoCnt("...");
    } else if (infoRes && infoRes.page) {
      setInfoCnt(infoRes.page.total);
    }
  }, [infoRes, infoLoading, setInfoCnt]);

  /**
   * 数据合并逻辑
   * 因为用户可以操作已有数据影响后台分页结果, 因此, 会有一部分重复加载来防止漏加载, 获取到数据后需要对数据进行排序和去重
   */
  useEffect(() => {
    setTotalWarnData(uniqWith(totalWarnData.concat(warnData), isEqual));
  }, [warnData]);

  /**
   * 数据合并逻辑
   * 因为用户可以操作已有数据影响后台分页结果, 因此, 会有一部分重复加载来防止漏加载, 获取到数据后需要对数据进行排序和去重
   */
  useEffect(() => {
    setTotalInfoData(uniqWith(totalInfoData.concat(infoData), isEqual));
  }, [infoData]);

  useEffect(() => {
    console.log("show data set");
    setShowData(type === 1 ? totalWarnData : totalInfoData);
  }, [type, totalWarnData, totalInfoData]);

  /**
   * 初始化加载数据
   */
  useEffect(() => {
    console.log("init load data");
    if (startDate && endDate && !isFilterMode) {
      doFetchWarn({
        url: QUERY_URL.popup,
        query: airlinesQueryCondition,
        ext: {
          status,
          type: 1,
          date: moment().format(DATE_FORMAT),
          page: {
            page: 1,
            pageSize,
          },
        },
      });
      doFetchInfo({
        url: QUERY_URL.popup,
        query: airlinesQueryCondition,
        ext: {
          status,
          type: 2,
          date: moment().format(DATE_FORMAT),
          page: {
            page: 1,
            pageSize,
          },
        },
      });
    }
  }, [
    startDate,
    endDate,
    airlinesQueryCondition,
    status,
    doFetchInfo,
    doFetchWarn,
    systemType,
    isFilterMode,
  ]);

  const resolveAll = useCallback(() => {
    removeAllFetch({
      query: filterQuery || airlinesQueryCondition,
      ext: {
        type,
        ...filterExt,
      },
    });
  }, [removeAllFetch, filterQuery, airlinesQueryCondition, type, filterExt]);

  useEffect(() => {
    setTotalInfoData(EMPTY_ARRAY);
    setTotalWarnData(EMPTY_ARRAY);
  }, [status, filterVal]);

  /**
   * 加载更多功能逻辑:
   * 1. 按照分页数据, 每次加载下一批
   * 2. 因为用户可以对已有的通知消息操作已处理, 删除当前通知消息会直接修改后台的数据记录, 后台的分页结果会变动
   * 3 因此, 当前的数据条目数量不是分页的整数倍, 那么条目数量向下取整, 获取最后一个分页的数据用以合并, 合并逻辑在结果集返回后处理
   */
  const fetchMore = useCallback(() => {
    const f = type === 1 ? doFetchWarn : doFetchInfo;
    console.log(
      "before fetch more, show data length :",
      showData.length,
      showData
    );
    const curPage = Math.floor(showData.length / pageSize);
    const query = isFilterMode ? filterQuery : airlinesQueryCondition;
    f({
      url,
      query,
      ext: {
        status,
        type,
        date: moment().format(DATE_FORMAT),
        page: {
          page: curPage + 1,
          pageSize,
        },
        ...filterExt,
      },
    });
  }, [
    airlinesQueryCondition,
    doFetchInfo,
    doFetchWarn,
    filterExt,
    filterQuery,
    isFilterMode,
    showData,
    status,
    type,
    url,
  ]);

  const getEmptyNotify = useCallback(() => {
    if (!userConfig?.subscribe?.route) {
      return getSharkText("config_page_please_click_top_right");
    } else if (status === 1) {
      return getSharkText("config_page_no_archived_messages");
    } else {
      return getSharkText("config_page_no_notification_messages");
    }
  }, [userConfig, status]);

  const showConfirm = useCallback(() => {
    confirm({
      title: getSharkText("config_page_confirm_clear_all_notifications"),
      icon: <ExclamationCircleOutlined />,
      okText: getSharkText("config_page_confirm"),
      cancelText: getSharkText("key.cancel.button"),
      onOk: resolveAll,
    });
  }, [resolveAll]);

  /**
   * 筛选查询
   */
  useEffect(() => {
    console.log("filter search");
    if (isFilterMode) {
      const fn = type === 1 ? doFetchWarn : doFetchInfo;
      fn({
        url: QUERY_URL.popupSearch,
        query: filterQuery,
        ext: {
          status,
          type,
          date: moment().format(DATE_FORMAT),
          ...filterExt,
          page: {
            page: 1,
            pageSize: 5,
          },
        },
      });
    }
  }, [
    doFetchInfo,
    doFetchWarn,
    filterExt,
    filterQuery,
    isFilterMode,
    status,
    systemType,
    type,
  ]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Row justify="space-around" style={{ marginBottom: 10 }}>
        <Col>
          <Radio.Group
            value={type}
            onChange={(e) => setType(e.target.value)}
            buttonStyle="solid"
          >
            <Radio.Button value={1}>
              <span className={styles.leftCnt}>{warnRes?.page?.total}</span>
              {getSharkText("menu_monitoring_alerting_airlines")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("config_page_information_suggestion")}
              <span className={styles.rightCnt}>{infoRes?.page?.total}</span>
            </Radio.Button>
          </Radio.Group>
        </Col>
        <div className={styles.settingsIcon} onClick={openSettings}>
          <SettingOutlined style={{ fontSize: 20 }} />
        </div>
      </Row>
      <Row style={{ margin: "10px 0px" }}>
        <Col span={12} style={{ textAlign: "center" }}>
          <ContainerOutlined
            style={{
              color: status === 1 ? "#1890ff" : "#ddd",
              fontSize: 20,
              marginRight: 10,
            }}
            onClick={() => setStatus(status ? 0 : 1)}
          />
          <Popover
            trigger="click"
            content={<Filter mainType={type} setFilterVal={setFilterVal} />}
            title={getSharkText("config_page_filter")}
            getPopupContainer={(t) => t.parentElement || document.body}
            overlayStyle={{ width: 600 }}
            placement="bottom"
          >
            <span style={{ fontSize: 20, color: "#1890ff" }}>
              <FunnelPlotFilled style={{ color: "#1890ff" }} />
              <span style={{ fontSize: 16 }}>
                {getSharkText("config_page_filter")}
              </span>
            </span>
          </Popover>
        </Col>
        <Col span={12} style={{ textAlign: "center" }}>
          {status !== 1 ? (
            <Button size="small" type="primary" onClick={showConfirm}>
              {getSharkText("config_page_PaAiNoPa_clear_all")}
            </Button>
          ) : undefined}
        </Col>
      </Row>
      <div className={styles.list} style={{ overflow: "scroll" }}>
        <Spin spinning={warnLoading || infoLoading}>
          {showData.filter((t) => !!t).length ? (
            showData
              .filter((t) => !!t)
              .map((t) => (
                <BaseCard
                  data={t}
                  key={t.id}
                  cardType={type}
                  setPopup={() => setPopup(t)}
                ></BaseCard>
              ))
          ) : (
            <Empty description={getEmptyNotify()} />
          )}
          <Row justify="center">
            <Col>
              {showData.length <
              (type === 1 ? warnRes : infoRes)?.page?.total ? (
                <Button type="primary" onClick={fetchMore}>
                  {getSharkText("config_page_load_more")}
                </Button>
              ) : undefined}
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
};
export default Panel;
