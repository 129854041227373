import Button from "Components/Official/Button";
import React, { ReactElement } from "react";
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tabs } from "antd";

const { TabPane } = Tabs;

export interface ISalonProps {
  example?: string;
}

/**
 * 沙龙宣传页
 */
const Salon = (): ReactElement => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
  };
  return (
    <div className={`${styles.official}`}>
      <div className={`top-banner ${styles["salon-banner"]}`}>
        <div className={styles["base-con"]}>
          <div className={styles["banner-con"]}>
            <div className={styles.bannerDesc}>
              2021携程航空数据应用与研究论坛
            </div>
            <div className={styles["sub-desc"]}>
              FlightAI AVIATION CONFERENCE
            </div>
            <div className={styles.bannerTitle}>
              智慧民航引领实践，推动民航高质量发展
            </div>
            <div className={styles.bannerHref}>某月某日 中国 上海</div>
            <div className={styles.register}>
              <NavLink
                className={({ isActive }) => (isActive ? "salon" : "")}
                to="/salonRegister"
              >
                <Button fill>立即报名</Button>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.intro}>
        <div className={styles.body}>
          <div className={styles.title}>
            <div className={styles["title-text"]}>论坛介绍</div>
            <div className={styles["title-desc"]}>Introduction</div>
          </div>
          <div className={styles.content}>
            <div className={styles.left}></div>
            <div className={styles.right}>
              <div className={styles.introTitle}>关于携程FlightAI论坛</div>
              <div className={styles.introText}>
                <p>
                  近年来，随着民航业的持续快速发尽，我国航空枢纽建设已在国际市场上崭鑫头角，但仍面临着很名问题和挑战--—机场容景趋近饱和、空域资源严重不足．基础设施建设相对滞后、机场运行效率有待提升、运营能力不足、国际航线依赖补贴、IT技术…
                </p>
                <p>
                  如何卖破发展瓶颈，打造未来新型枢纽机场？航联传播旗下民航资源网
                  (CARNoC.com)
                  主办的“2018民航趋势论坛将对此进行光分而深人的探讨。
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.overview}>
        <div className={styles.body}>
          <div className={styles.title}>
            <div className={styles["title-text"]}>主题概述</div>
            <div className={styles["title-desc"]}>Theme overview</div>
          </div>
          <div className={styles.content}>
            <div className={styles.left}>
              <div className={styles.introTitle}>主题概述</div>
              <div className={styles.introText}>
                <p>
                  近年来，随着民航业的持续快速发尽，我国航空枢纽建设已在国际市场上崭鑫头角，但仍面临着很名问题和挑战--—机场容景趋近饱和、空域资源严重不足．基础设施建设相对滞后、机场运行效率有待提升、运营能力不足、国际航线依赖补贴、IT技术…
                </p>
                <p>
                  如何卖破发展瓶颈，打造未来新型枢纽机场？航联传播旗下民航资源网
                  (CARNoC.com)
                  主办的“2018民航趋势论坛将对此进行光分而深人的探讨。
                </p>
              </div>
            </div>
            <div className={styles.right}></div>
          </div>
        </div>
      </div>
      <div className={styles.speakers}>
        <div className={styles.body}>
          <div className={styles.title}>
            <div className={styles["title-text"]}>演讲嘉宾</div>
            <div className={styles["title-desc"]}>Speakers</div>
          </div>
          <div className={styles.content}>
            <Slider {...settings}>
              <div>
                <div>1</div>
              </div>
              <div>
                <div>2</div>
              </div>
              <div>
                <div>3</div>
              </div>
              <div>
                <div>4</div>
              </div>
              <div>
                <div>5</div>
              </div>
              <div>
                <div>6</div>
              </div>
              <div>
                <div>7</div>
              </div>
              <div>
                <div>8</div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <div className={styles.agenda}>
        <div className={styles.body}>
          <div className={styles.title}>
            <div className={styles["title-text"]}>论坛议程</div>
            <div className={styles["title-desc"]}>Forum agenda</div>
          </div>
          <div className={styles.content}>
            <Tabs
              defaultActiveKey="1"
              size="large"
              tabBarStyle={{ fontSize: 32 }}
            >
              <TabPane tab="上午" key="1">
                <div className={styles.stage}>
                  <div className={styles.time}>09:00</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>开幕辞</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>09:30</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>航空市场趋势报告</div>
                    <div className={styles.resume}>XXX分析师 哈利波特</div>
                    <div className={styles.resume}>
                      xxxx集团亚太地区航空公司xxxxx总部高级执行总裁(2008-2020)
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>10:00</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>航空市场趋势报告</div>
                    <div className={styles.resume}>XXX分析师 哈利波特</div>
                    <div className={styles.resume}>
                      xxxx集团亚太地区航空公司xxxxx总部高级执行总裁(2008-2020)
                    </div>
                    <div className={styles.resume}>xxx创始人</div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>10:30</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>xxx趋势探讨</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>11:00</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>xxx趋势探讨</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>11:30</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>xxx趋势探讨</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="下午" key="2">
                <div className={styles.stage}>
                  <div className={styles.time}>13:00</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>开幕辞</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>13:30</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>航空市场趋势报告</div>
                    <div className={styles.resume}>XXX分析师 哈利波特</div>
                    <div className={styles.resume}>
                      xxxx集团亚太地区航空公司xxxxx总部高级执行总裁(2008-2020)
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>14:00</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>航空市场趋势报告</div>
                    <div className={styles.resume}>XXX分析师 哈利波特</div>
                    <div className={styles.resume}>
                      xxxx集团亚太地区航空公司xxxxx总部高级执行总裁(2008-2020)
                    </div>
                    <div className={styles.resume}>xxx创始人</div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>14:30</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>xxx趋势探讨</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>15:00</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>xxx趋势探讨</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
                <div className={styles.stage}>
                  <div className={styles.time}>15:30</div>
                  <div className={styles.image}></div>
                  <div className={styles.topic}>
                    <div className={styles.title}>xxx趋势探讨</div>
                    <div className={styles.resume}>
                      飞常准创始人兼首席执行官 Jason
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </div>
      </div>
      <div className={styles.PPT}>
        <div className={styles.body}>
          <div className={styles.title}>
            <div className={styles["title-text"]}>往期沙龙PPT下载</div>
            <div className={styles["title-desc"]}>Past salon PPT download</div>
          </div>
          <div className={styles.content}>
            <div className={styles.downloadItem}></div>
            <div className={styles.downloadItem}></div>
            <div className={styles.downloadItem}></div>
          </div>
        </div>
      </div>
      <div className={styles.gallery}>
        <div className={styles.body}>
          <div className={styles.title}>
            <div className={styles["title-text"]}>往期沙龙图片</div>
            <div className={styles["title-desc"]}>Past salon pictures</div>
          </div>
          <div className={styles.content}>
            <div>1</div>
            <div>1</div>
            <div>1</div>
            <div>1</div>
            <div>1</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Salon;
