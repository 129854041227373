import qs from "query-string";
export type IQueryValue = string | string[];

const setQueryStringWithoutPageReload = (qsValue: string) => {
  const newUrl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    qsValue;
  console.log("history pushState");
  window.history.pushState({ path: newUrl }, "", newUrl);
};

export const getQueryStringValue = (
  key: string,
  queryString = window.location.search
): string | Array<string | null> | null => {
  const values = qs.parse(queryString);
  return values[key];
};

export const setQueryStringValue = (
  key: string,
  value: IQueryValue,
  queryString = window.location.search
): void => {
  const values = qs.parse(queryString);
  const newQsValue = qs.stringify({
    ...values,
    [key]: value,
  });
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};
