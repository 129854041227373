import React from "react";
import { Spin } from "antd";
import { UserProfileChart } from "@ctrip/flt-bi-flightai-airlines";
import { ISeries } from "Interface";
import EchartsReactBase from "Components/EchartsReactBase";
import { getSharkText } from "Utils/i18nGlobal";

interface SimplePictorialBarChartsProps {
  data: UserProfileChart[];
  loading: boolean;
  seriesConfig: ISeries[];
}

export const genChartData = (
  data: UserProfileChart[],
  seriesConfig: ISeries[]
) => {
  // 获取总量
  let totalValue = 0;
  data.forEach((d: UserProfileChart) => {
    totalValue += d.value || 0;
  });
  const rst = data.reduce((total: any, d) => {
    total.push({
      name:
        d.tagName === "unkown" ? getSharkText("key.unknown.name") : d.tagName,
      value:
        totalValue === 0
          ? 0
          : parseFloat((((d.value || 0) / totalValue) * 100).toFixed(2)),
      ...seriesConfig.find((s) => s.tagCode === d.tagCode),
    });
    return total;
  }, []);
  return rst;
};

const SimplePictorialBarCharts: React.FC<SimplePictorialBarChartsProps> = (
  props: SimplePictorialBarChartsProps
) => {
  const { data, loading, seriesConfig } = props;
  const chartData = genChartData(data, seriesConfig);
  const isZero = (val: number) => val === 0;
  const option = {
    // dataset: {
    //     source: data
    // },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let totalValue = 0;
        data.forEach((d: UserProfileChart) => {
          totalValue += d.value || 0;
        });
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const per = isZero(totalValue) ? "-" : item.data.value;
          const perDom = `${getSharkText("key.proportion_souce.text")}: ${
            per !== "-" ? per : "-"
          }%`;
          dom = dot + perDom;
        });
        return dom;
      },
    },
    xAxis: {
      splitLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    yAxis: {
      data: chartData.map((d: any) => d.name),
      inverse: true,
      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    series: [
      {
        type: "pictorialBar",
        label: {
          normal: {
            show: true,
            position: "right",
            offset: [10, 0],
            formatter: (d: any) => Math.round(d.data.value) + "%",
          },
        },
        symbolRepeat: true,
        symbolClip: true,
        symbolSize: ["50%", "90%"],
        barCategoryGap: "40%",
        data: chartData,
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height: "300px" }}
      />
    </Spin>
  );
};

export default SimplePictorialBarCharts;
