import React from "react";
import useGlobal from "Store";
import { isAirportMode } from "Utils";
import AirportIndex from "./AirportIndex";
import AirlineIndex1 from "./AirlineIndex1";
import { validateDemo } from "Utils/global";
import { useLocation } from "react-router-dom";

const Dashboard = () => {
  const location = useLocation();
  const [globalState] = useGlobal();
  const { userInfo, systemType } = globalState;
  const isAirport = isAirportMode(systemType);
  const isDemo = validateDemo(
    location.pathname,
    systemType,
    userInfo.moduleList
  );

  return isAirport ? (
    <AirportIndex isDemo={isDemo}></AirportIndex>
  ) : (
    // <AirlineIndex isDemo={isDemo}></AirlineIndex>
    <AirlineIndex1 isDemo={isDemo}></AirlineIndex1>
  );
};

export default Dashboard;
