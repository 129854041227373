import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { BellOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Descriptions,
  Dropdown,
  Menu,
  MenuProps,
  message,
} from "antd";
import { Module, ModuleType, SystemType, User } from "Interface";
import {
  getCookie,
  getModule,
  getModuleNameFromPath,
  logout,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { SERVER, TOKEN, UID } from "Constants";
import useGlobal from "Store";
import RightModal from "Components/RightModal";
import SelectSystemType from "Components/SelectSystemType";
import "./index.scss";
import { getServer } from "Service/server";
import { getMenuTree, redirectLogin } from "Utils/global";
import Trans from "Trans";
import { Link, useLocation } from "react-router-dom";
import useI18Next from "Utils/useI18Next";
import { Questionnaire } from "@ctrip/flt-bi-flightai-base";
import moment from "moment";
import Nav from "Components/Nav";
import useRefFunc from "Utils/useRefFunc";
import BreadCom from "./BreadCom";
import PaymentNotice from "Components/PaymentNotice";

interface UserInfoProps {
  user: User;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  systemType: SystemType;
  changeSystemType: (type: SystemType) => void;
}

const AIHeader: React.FC<UserInfoProps> = (props: UserInfoProps) => {
  const { user, collapsed, setCollapsed, systemType, changeSystemType } = props;
  const location = useLocation();
  const [globalState, actions] = useGlobal();
  const { helpVisibility, userInfo } = globalState;
  const { roleList, moduleList } = userInfo;
  const isAirport = systemType === SystemType.airport;
  const { setHelpVisibility } = actions;
  const [modalVisible, setModalVisible] = useState(false);
  const isCtriper = user.userType === 1;
  const { pathname } = location;
  const isDynamicPrice = pathname === "/dynamic_price";
  const { uid, token } = actions.getLogin();
  const [asHome, setAsHome] = useState<boolean | null>(null);
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const t = useI18Next();
  const [paymentEntry, setPaymentEntry] = useState<ReactNode>(null);
  const [noticeCnt, setNoticeCnt] = useState(0);

  const [{ res: questionRes }] = useFetch({
    url: "queryQuestionnaireViaUser",
    head: {
      moduleCode: "non",
      chartTableCode: "non",
    },
    query: null,
    ext: {
      userId: user.id,
    },
  });

  const questionList = useMemo(() => {
    let rst: Questionnaire[] = [];
    if (questionRes?.questionnaireList?.length) {
      rst = questionRes.questionnaireList.filter((q: Questionnaire) => {
        return (
          moment(q.startTime).isSameOrBefore(moment()) &&
          moment(q.endTime).isSameOrAfter(moment())
        );
      });
    }
    return rst;
  }, [questionRes?.questionnaireList]);

  const abortCtrl = new AbortController();
  const signal = abortCtrl.signal;

  const handleClick = () => {
    setModalVisible(true);
  };

  const PopoverContact = (
    <div className="popover-content">
      <Descriptions title={t("userInfo.contactUs")} column={1}>
        <Descriptions.Item label={t("userInfo.email")}>
          flightai@trip.com
        </Descriptions.Item>
        <Descriptions.Item label={t("userInfo.address.title")}>
          {t("userInfo.address.value")}
        </Descriptions.Item>
        <Descriptions.Item label={t("userInfo.weChat")}>
          <div className="qrcode" style={{ position: "relative", top: 15 }} />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );

  const handleLoginOut = async () => {
    try {
      const result: any = await fetch(`${SERVER}logout`, {
        signal,
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: getCookie(UID),
          token: getCookie(TOKEN),
        }),
      });
      const res = await result.json();
      if (res.flag) {
        message.destroy();
        logout();
        redirectLogin();
      } else {
        message.destroy();
        message.error("server error");
      }
    } catch (error) {
      message.destroy();
      message.error("server error");
    }
  };

  useEffect(
    () => () => {
      abortCtrl.abort();
    },
    []
  );

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/user">{t("userInfo.changePassword")}</Link>
      </Menu.Item>
      <Menu.Item onClick={handleClick}>
        {t("userInfo.businessCooperation")}
      </Menu.Item>
      <Menu.Item onClick={handleLoginOut}>{t("userInfo.signOut")}</Menu.Item>
    </Menu>
  );

  const polls = useMemo(() => {
    return (
      <Menu>
        {questionList.map((q: Questionnaire) => {
          return (
            <Menu.Item key={q.pollId}>
              <a href={q.url || "#"} target="_blank" rel="noreferrer">
                {q.questionnaireName}
              </a>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [questionList]);

  const noticeItems = useMemo(() => {
    const rst: MenuProps["items"] = [];
    let tmpCnt = 0;
    if (questionList.length) {
      tmpCnt += questionList.length;
      const questionEleList: MenuProps["items"] = questionList.map(
        (q: Questionnaire) => {
          return {
            label: (
              <a href={q.url || "#"} target="_blank" rel="noreferrer">
                {q.questionnaireName}
              </a>
            ),
            key: q.questionnaireId || -1,
          };
        }
      );
      rst.push({
        label: getSharkText("key.questionnaire.button"),
        key: "questionnaire",
        type: "group",
        children: questionEleList,
      });
    }
    if (paymentEntry) {
      tmpCnt++;
      rst.push({
        label: paymentEntry,
        key: "paymentNotice",
      });
    }
    setNoticeCnt(tmpCnt);
    return rst;
  }, [paymentEntry, questionList]);

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const setDynamicPriceHome = async (type: number) => {
    try {
      const result: any = await fetch(`${getServer(2)}setDynamicPriceHome`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          head: {
            uid,
            token,
            requestTime: new Date(),
            appId: "",
            clientType: "2",
            clientVersion: "",
          },
          type,
        }),
      });
      const { flag } = await result.json();
      switch (type) {
        case 0:
          setAsHome(flag);
          break;
        case 1:
          setAsHome(!!flag);
          break;
        case 2:
          setAsHome(!flag);
          break;
      }
    } catch (error) {
      message.destroy();
      message.error("server error");
    }
  };

  if (asHome === null && isDynamicPrice) {
    setDynamicPriceHome(0);
  }

  const setDynamicPriceAsHome = (e: boolean) => {
    setDynamicPriceHome(e ? 1 : 2);
    return false;
  };

  const showSystemTypeSelector = (): boolean => {
    if (module && module.moduleType === ModuleType.partner) {
      return false;
    }
    if (isCtriper) {
      return true;
    }
    if (!roleList) {
      return false;
    }
    return roleList.filter((r) => [1, 2].includes(r.roleType)).length === 2;
  };

  const menus = useMemo(() => {
    return getMenuTree(moduleList, user, isAirport, roleList);
  }, [isAirport, moduleList, roleList, user]);

  const moduleToMenuItem = useRefFunc((modules: Module[]) => {
    return modules.map((module) => {
      const newItem: {
        key: string;
        label: ReactNode;
        path: string;
        breadcrumbName: string;
        [key: string]: any;
      } = {
        ...module,
        key: module.moduleCode,
        label: module.moduleName,
        path: module.menuUrl,
        breadcrumbName: module.moduleName,
        children: undefined,
        popupClassName: "nav-menu-popup",
        // label: module.moduleName,
      };
      if (module.children.length) {
        newItem.children = moduleToMenuItem(module.children);
      }
      return newItem;
    });
  });

  const items = useMemo(() => {
    return moduleToMenuItem(menus);
  }, [menus, moduleToMenuItem]);

  const itemRender = (route: any, params: any, routes: any, paths: any) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={"/" + paths.pop()}>{route.breadcrumbName}</Link>
    );
  };

  return (
    <div className="page-header">
      <div className="user-info">
        <div className="user-info-left">
          <div className="logo"></div>
        </div>
        <div style={{ flex: 1, overflow: "hidden" }}>
          <Nav />
        </div>
        <div style={{ flex: "0 0 auto", display: "flex", height: 32 }}>
          {showSystemTypeSelector() ? (
            <SelectSystemType
              systemType={systemType}
              changeSystemType={changeSystemType}
            />
          ) : undefined}
          {/* {isDynamicPrice ? (
            <>
              <span
                style={{
                  fontSize: 14,
                  color: "#888",
                  marginRight: 10,
                  marginLeft: 20,
                }}
              >
                {getSharkText("key.set_frontpage.button")}
              </span>
              <Switch
                checked={!!asHome}
                onChange={setDynamicPriceAsHome}
              ></Switch>
            </>
          ) : undefined} */}
          <div className="header-icon">
            <Badge count={noticeCnt}>
              <Avatar
                icon={
                  <Dropdown menu={{ items: noticeItems }}>
                    <BellOutlined
                      style={{
                        color: noticeCnt > 0 ? "var(--main)" : "unset",
                        fontSize: "130%",
                      }}
                    />
                  </Dropdown>
                }
              ></Avatar>
            </Badge>
          </div>
          <div className="header-icon">
            {location.pathname === "/search_index_intl" ||
            location.pathname === "/flight_plan_intl" ||
            location.pathname === "/od_analysis" ? null : (
              <Avatar icon={<Trans />} />
            )}
          </div>
          {/* <div className="header-icon">
            <Theme />
          </div> */}
          {/* <div className="header-icon">
            <Avatar
              icon={
                <span className="anticon">
                  <SvgIcon name="NotificationFilled" />
                </span>
              }
            />
          </div> */}
          <div className="header-icon">
            <Dropdown overlay={menu}>
              <Avatar icon={<UserOutlined />} />
            </Dropdown>
          </div>
          {/* <Popover placement="bottomRight" content={t("userInfo.showHelp")}>
            <em key="help" onClick={() => setHelpVisibility(!helpVisibility)}>
              <QuestionCircleFilled style={{ fontSize: "20px" }} />
            </em>
          </Popover>
          <Popover content={PopoverContact} placement="bottomRight">
            <em key="contact">
              <MailOutlined style={{ fontSize: "20px" }} />
            </em>
          </Popover> */}
        </div>
        <RightModal visible={modalVisible} setVisible={setModalVisible} />
      </div>
      <div
        style={{
          paddingLeft: 16,
          borderBottom: "1px solid var(--filter-bg-border)",
        }}
      >
        <BreadCom />
      </div>
      <PaymentNotice onMount={setPaymentEntry} />
    </div>
  );
};

export default AIHeader;
