import Airlines from "Components/Toolbar/Airlines";
import { Button, Col, DatePicker, Row, message } from "antd";
import React, { ReactElement, useMemo } from "react";
import { SelectMultFlightProps } from "../FlightManage/Components/SearchArea/SelectMultFlight";
import SelectRouteAndFlight from "../FlightManage/Components/SearchArea/SelectRouteAndFlight";
import { SelectMultiRouteProps } from "../FlightManage/Components/SearchArea/SelectMultRoute";
import useRefFunc from "Utils/useRefFunc";
import { getIsDemo } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobalState from "Store";
import { useLocation } from "react-router-dom";
import { QueryParam } from "./interface";
import moment from "moment";
import { Moment } from "@react-awesome-query-builder/core";
import { DATE_FORMAT } from "Constants";

export interface SearchAreaProps {
  onChange: (param: QueryParam) => void;
}

/**
 *  Component description
 */
const SearchArea = (props: SearchAreaProps): ReactElement => {
  const { onChange } = props;
  const [globalState] = useGlobalState();
  const { pathname } = useLocation();
  const isDemo = getIsDemo(pathname, globalState);

  const [routes, setRoutes] = React.useState<string[]>([]);
  const [flightNO, setFlightNO] = React.useState<string[]>([]);
  const [takeoffDate, setTakeoffDate] = React.useState<Moment>(moment());

  const routeProps = useMemo(() => {
    const tmpProps: SelectMultiRouteProps = {
      routes,
      setRoutes,
      isDemo,
      // mode: undefined,
      width: 150,
      flights: flightNO.join(","),
      useExchange: true,
      debugId: "im_batch_route",
      permissionType: "user",
      allowClear: true,
      useGroup: true,
      autoSelectFirst: true,
    };
    return tmpProps;
  }, [flightNO, isDemo, routes]);
  const flightProps = useMemo(() => {
    const tmpProps: SelectMultFlightProps = {
      flightNO,
      setFlightNO,
      routes: routes.join(","),
      isDemo,
      width: 120,
      autoSelectFirst: true,
      placeholder: getSharkText("config_page_select_flight"),
      debugId: "im_batch_flight",
    };
    return tmpProps;
  }, [flightNO, isDemo, routes]);
  const [routeCom, flightCom] = SelectRouteAndFlight({
    routeProps,
    flightProps,
    changeFlightByRoute: true,
    changeRouteByFlight: true,
  });
  const onQuery = useRefFunc(() => {
    if (!flightNO.length) {
      message.error("航班为必选项");
      return;
    }
    onChange({
      airline: globalState.airlinesQueryCondition.airlines,
      flightNo: flightNO[0],
      route: routes[0] || "",
      takeoffdate: takeoffDate.format(DATE_FORMAT),
    });
  });
  return (
    <Row gutter={[20, 20]} justify="space-between">
      <Col>
        <Airlines />
      </Col>
      <Col>
        <Row gutter={[20, 20]}>
          <Col>{flightCom}</Col>
          <Col>{routeCom}</Col>
          <Col>
            <DatePicker
              value={takeoffDate}
              onChange={(e) => setTakeoffDate(e as Moment)}
            />
          </Col>
          <Col>
            <Button onClick={onQuery} type="primary">
              查询
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default SearchArea;
