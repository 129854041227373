import { EditOutlined } from "@ant-design/icons";
import { UnitList } from "Interface";
import { Button, Col, Modal, Row, Spin, message } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import SelectAirports from "./SelectAirports";
import { isSame, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

export interface UnitCodeEditorProps {
  curUnit: UnitList;
  canEdit: boolean;
  refetch: () => void;
}

const getPropsUnitCodes = (v: UnitList): string[] => {
  return v.unit.unitCode.split(",").filter((v1) => !!v1);
};

/**
 * 权限组编码编辑器
 */
const UnitCodeEditor = (props: UnitCodeEditorProps): ReactElement => {
  const { curUnit, canEdit, refetch } = props;
  const [unitCodes, setUnitCodes] = useState<string[]>(
    getPropsUnitCodes(curUnit)
  );

  useEffect(() => {
    setUnitCodes((state) => {
      const propCodes = getPropsUnitCodes(curUnit);
      return isSame(propCodes, state) ? state : propCodes;
    });
  }, [curUnit]);

  const [open, setOpen] = useState(false);

  const [{ isLoading }, doFetch] = useFetch({
    url: "saveUnit",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_add_modify_success"));
      setOpen(false);
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_add_modify_failed"));
    },
  });

  const handleOk = () => {
    doFetch({
      ext: {
        unit: {
          ...curUnit.unit,
          unitCode: unitCodes.join(","),
        },
      },
    });
  };

  return (
    <Row align="middle">
      <Col>
        机场代码:&nbsp;
        {curUnit.unit.unitCode === "ctrip" ? "*" : unitCodes.join(",")}
      </Col>
      <Col>
        {canEdit ? (
          <Button type="link" onClick={() => setOpen(true)}>
            <EditOutlined />
          </Button>
        ) : undefined}
      </Col>
      <Modal onOk={handleOk} open={open} onCancel={() => setOpen(false)}>
        <Spin spinning={isLoading}>
          <SelectAirports
            value={unitCodes}
            onChange={(v) => setUnitCodes(v.map((v1) => v1.value))}
          />
        </Spin>
      </Modal>
    </Row>
  );
};
UnitCodeEditor.displayName = "UnitCodeEditor";
export default UnitCodeEditor;
