import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { IS_PRO } from "Constants";
import { MODULE_CODE } from "../../Constants";

export const DATASET_ID = IS_PRO ? 330 : 92;

export const CONFIG_KEY = "config_key";
export const D = "d";
export const ROUTE = "route";
export const DPORT = "dport";
export const APORT = "aport";
export const FLIGHTNO = "flightno";
export const SEATS = "seats";
export const AIRLINE = "airline";
export const TAKEOFFDATE = "takeoffdate";
export const TAKEOFFTIME = "takeofftime";
export const AHEAD_DAY = "ahead_day";
export const PROB = "prob";
export const CQ_CROWD_DEGREE_FINAL_PRED_50 = "cq_crowd_degree_final_pred_50";
export const CANCEL_RATE = "cancel_rate";
export const CURRENT_AVGPRICE = "current_avgprice";
export const COMPETITOR_AVGPRICE = "competitor_avgprice";
export const PRICE_DIFF = "price_diff";
export const SUBSIDY_NUM = "subsidy_num";
export const SUBSIDY_AMOUNT = "subsidy_amount";
export const SUBSIDY_PRICE = "subsidy_price";
export const IS_SINGLEFLIGHT = "is_singleflight";
export const SUBSIDY_NUM_FIXED = "subsidy_num_fixed";
export const SUBSIDY_AMOUNT_FIXED = "subsidy_amount_fixed";
export const UDF_PRICE = "udf_price";
export const UDF_COMPETE_PRICE = "udf_compete_price";
export const UDF_CONFIG = "udf_config";
export const UDF_SUBSIDY_NON_LOCAL = "udf_subsidy_non_local";
export const UDF_SUBSIDY_LOCAL = "udf_subsidy_local";
export const DCITYCODE = "dcitycode";
export const DPROVINCENAME = "dprovincename";
export const DREGIONNAME = "dregionname";
export const DCOUNTRYCODE = "dcountrycode";
export const DCONTINENTNAME = "dcontinentname";
export const ACITYCODE = "acitycode";
export const APROVINCENAME = "aprovincename";
export const AREGIONNAME = "aregionname";
export const ACOUNTRYCODE = "acountrycode";
export const ACONTINENTNAME = "acontinentname";
export const DEP_AIRPORTNAME = "dep_airportname";
export const DCITYNAME = "dcityname";
export const ARR_AIRPORTNAME = "arr_airportname";
export const ACITYNAME = "acityname";

export const customCols: FDDatasetCol[] = [
  {
    id: 0.1,
    displayName: UDF_PRICE,
    name: UDF_PRICE,
    dbcolumn: `realtimePriceUDPF(dimension.takeoffdate, p_concat(dimension.dport, dimension.aport), dimension.flightno)`,
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true, "computeType": "p_exp"}',
  },
  {
    id: 0.2,
    displayName: UDF_COMPETE_PRICE,
    name: UDF_COMPETE_PRICE,
    dbcolumn: `realtimePriceUDPF(dimension.takeoffdate, p_concat(dimension.dport, dimension.aport), dimension.flightno, competeFlightUDPF(1))`,
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true, "computeType": "p_exp"}',
  },
  {
    id: 0.3,
    displayName: UDF_CONFIG,
    name: UDF_CONFIG,
    dbcolumn: `odtConfigUDPF('${MODULE_CODE}')`,
    jdbctype: "VARCHAR",
    kind: 0,
    sort: "VARCHAR",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true, "computeType": "p_exp"}',
  },
  {
    id: 0.4,
    displayName: UDF_SUBSIDY_NON_LOCAL,
    name: UDF_SUBSIDY_NON_LOCAL,
    dbcolumn: `odtConfigUDPF('${MODULE_CODE}${UDF_SUBSIDY_NON_LOCAL}')`,
    jdbctype: "VARCHAR",
    kind: 0,
    sort: "VARCHAR",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true, "computeType": "p_exp"}',
  },
  {
    id: 0.5,
    displayName: UDF_SUBSIDY_LOCAL,
    name: UDF_SUBSIDY_LOCAL,
    dbcolumn: `odtConfigUDPF('${MODULE_CODE}${UDF_SUBSIDY_LOCAL}')`,
    jdbctype: "VARCHAR",
    kind: 0,
    sort: "VARCHAR",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true, "computeType": "p_exp"}',
  },
];
