export const MODULE_CODE: any = {
  1: "dashboard_airport",
  2: "dashboard_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    travel: "AP0001",
    confident: "AP0002",
    score: "AP0003",
    cards: "AP0004",
    table: "AP0005",
  },
  2: {
    travel: "AL0001",
    confident: "AL0002",
    score: "AL0003",
  },
};

export const QUERY_URL: any = {
  1: {
    trendsPie: "queryAirportDashboardIndex",
    radarCharts: "queryAirportDashboardScore",
    cards: "queryHomeCard",
    table: "queryHomeTable",
  },
  2: {
    trendsPie: "queryAirlinesDashboardIndex",
    radarCharts: "queryAirlinesDashboardScore",
    table: "queryAirlinesDashboardTable",
  },
};
