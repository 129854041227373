import { IDownloadHeader } from "Interface";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { G } from "Utils/global";
import { Index } from "../../TouristConstant";
// #region 客座率Columns
const getLoadColumns = (
  isAirport: boolean,
  airlines: string
): IDownloadHeader[] => {
  const airlineLoad = [
    {
      title: `${airlines}${getSharkText(
        "config_page_airlines_history_actual_average_load_factor"
      )}`,
      dataIndex: "lf",
      key: "lf",
      sorter: (a: any, b: any) => (a.lf ? a.lf : -1) - (b.lf ? b.lf : -1),
      render: (val: number) => showNum(val, "percentage"),
    },
  ];
  const lineLoad = [
    {
      title: `${
        isAirport
          ? ""
          : getSharkText(
              "config_page_airline_history_actual_average_load_factor"
            )
      }`,
      dataIndex: "airlineValue",
      key: "airlineValue",
      sorter: (a: any, b: any) =>
        (a.airlineValue ? a.airlineValue : -1) -
        (b.airlineValue ? b.airlineValue : -1),
      render: (val: number) => showNum(val, "percentage"),
    },
    {
      title: `${isAirport ? "" : "航线"}未来预测平均客座率`,
      dataIndex: "airlinePredValue",
      key: "airlinePredValue",
      sorter: (a: any, b: any) =>
        (a.airlinePredValue ? a.airlinePredValue : -1) -
        (b.airlinePredValue ? b.airlinePredValue : -1),
      render: (val: number) => showNum(val, "percentage"),
    },
  ];
  return isAirport ? lineLoad : airlineLoad.concat(lineLoad);
};
// #endregion
// #region 上座数Columns
const getSeatsColumns = (
  isAirport: boolean,
  airlines: string
): IDownloadHeader[] => {
  const airlineSeats = [
    {
      title: `${airlines}${getSharkText(
        "config_page_airline_history_actual_total_passenger_volume"
      )}`,
      dataIndex: "soldSeats",
      key: "soldSeats",
      sorter: (a: any, b: any) =>
        (a.soldSeats ? a.soldSeats : -1) - (b.soldSeats ? b.soldSeats : -1),
      render: (val: number) => showNum(val, "num"),
    },
  ];
  const lineSeats = [
    {
      title: `${isAirport ? "" : "航线"}${getSharkText(
        "config_page_airline_history_actual_total_passenger_volume"
      )}`,
      dataIndex: "airlineSoldSeats",
      key: "airlineSoldSeats",
      sorter: (a: any, b: any) =>
        (a.airlineSoldSeats ? a.airlineSoldSeats : -1) -
        (b.airlineSoldSeats ? b.airlineSoldSeats : -1),
      render: (val: number) => showNum(val, "num"),
    },
    {
      title: `${isAirport ? "" : "航线"}${getSharkText(
        "config_page_future_predicted_total_passenger_volume"
      )}`,
      dataIndex: "airlinePredSoldSeats",
      key: "airlinePredSoldSeats",
      sorter: (a: any, b: any) =>
        (a.airlinePredSoldSeats ? a.airlinePredSoldSeats : -1) -
        (b.airlinePredSoldSeats ? b.airlinePredSoldSeats : -1),
      render: (val: number) => (val === 0 ? "-" : showNum(val, "num")),
    },
  ];
  return isAirport ? lineSeats : airlineSeats.concat(lineSeats);
};
// #endregion
// #region 维度Columns
const baseColumns: IDownloadHeader[] = [
  {
    title: getSharkText("key.departure.name"),
    dataIndex: "dportName",
    key: "dportName",
    sorter: (a: any, b: any) => a.dport.localeCompare(b.dport),
    render: (value: string, record: any) =>
      `${record.dportName}(${record.dport})`,
  },
  {
    title: getSharkText("key.arrival.name"),
    dataIndex: "aportName",
    key: "aportName",
    sorter: (a: any, b: any) => a.aport.localeCompare(b.aport),
    render: (value: string, record: any) =>
      `${record.aportName}(${record.aport})`,
  },
];

const getFlightColumns = (flightNo: string): IDownloadHeader[] => [
  {
    title: getSharkText("key.flightno.name"),
    dataIndex: "lf",
    key: "lf",
    render: () => flightNo,
  },
];
// #endregion
export const getColumns = (
  /**
   * 机场or航司
   */
  isAirport: boolean,
  /**
   * 选中的指标 ex. 客座率/客运量
   */
  indexes: Index[],
  /**
   * 选中的航司
   */
  airlines: string,
  /**
   * 筛选的航班号
   */
  flightNO?: string
): IDownloadHeader[] => {
  const constColumns: Record<Index, IDownloadHeader[]> = {
    [Index.LF]: getLoadColumns(isAirport, airlines),
    [Index.Seat]: getSeatsColumns(isAirport, airlines),
  };
  const dimColumns = !flightNO
    ? baseColumns
    : baseColumns.concat(getFlightColumns(flightNO));
  const valueColumns = G.enumValues(constColumns).reduce(
    (total, current: Index) => {
      if (indexes.includes(current)) {
        total = total.concat(constColumns[current]);
      }
      return total;
    },
    [] as IDownloadHeader[]
  );
  return dimColumns.concat(valueColumns);
};
