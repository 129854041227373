import { MonitoringAlertingConfig } from "@ctrip/flt-bi-flightai-airlines";
import { Spin } from "antd";
import React, { ReactElement, useCallback, useEffect } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import SettingList from "./SettingList";
import Settings from "./Settings";

export interface ISettingContainerProps {
  setUserConfig: (v: any) => void;
  settingsRef: any;
}

/**
 * 通知中心-弹窗配置面板
 */
const SettingContainer = (props: ISettingContainerProps): ReactElement => {
  const { setUserConfig, settingsRef } = props;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;

  const [{ isLoading, res }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL.queryRule,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
  });

  const refetchRule = useCallback(() => {
    if (airlinesQueryCondition.startDate && airlinesQueryCondition.endDate) {
      doFetch({ ext: {} });
    }
  }, [
    airlinesQueryCondition.endDate,
    airlinesQueryCondition.startDate,
    doFetch,
  ]);

  useEffect(() => {
    refetchRule();
  }, [refetchRule]);

  const handleSetEditingRules = useRefFunc((v: MonitoringAlertingConfig[]) => {
    if (settingsRef?.current && settingsRef.current.setEditingConfig) {
      settingsRef.current.setEditingConfig(v);
    }
  });

  return (
    <Spin spinning={isLoading}>
      <div style={{ display: "flex" }}>
        <div>
          <Settings
            ref={settingsRef}
            setUserConfig={setUserConfig}
            res={res}
            refetchRule={refetchRule}
          />
        </div>
        <div style={{ width: 430, padding: 5 }}>
          <SettingList
            configList={res?.configList || []}
            refetchRule={refetchRule}
            setCurrentConfig={handleSetEditingRules}
          />
        </div>
      </div>
    </Spin>
  );
};
export default SettingContainer;
