/**
 *
 * define the response class
 *
 */
export interface AdjustFlightType {
  layout: string | "";
  /**
   * 起飞日期
   */
  takeoffdate: string | "";
  /**
   * 航班号
   */
  flightno: string | "";
  seg: AdjustSegType | null;
}
export interface GetSeatInfoResponseType {
  ResponseStatus: ResponseStatusType | null;
  flights: SeatInfoResponseEntity[] | [];
}
/**
 *
 * define the response class
 *
 */
export interface AdjustSegType {
  /**
   * 建议舱位
   */
  subclasssuggest: string | "";
  maincabins: AdjustMainCabinEntity[] | [];
  subcabins: AdjustSubCabinEntity[] | [];
  priceori: string | "";
  /**
   * 航线：样例：SHABJS
   */
  line: string | "";
  pricesuggest: string | "";
  lsssuggest?: string | null;
  /**
   * 原舱位
   */
  subclassori: string | "";
  borrowMethod?: BorrowMethod | null;
}
/**
 *
 * define the response class
 *
 */
export interface GetDynamicFarePriceResponseType {
  ResponseStatus: ResponseStatusType | null;
  flights: FlightDynamicFarePriceType[] | [];
}
export interface PNRInfoType {
  flightInfo?: FlightSimpleInfoType | null;
  seatNum?: number | null;
  pnrCode?: string | null;
  fromCode?: string | null;
}
/**
 * 舱位动态运价类型
 */
export interface CabinDynamicFarePriceType {
  awaitPrice?: number | null;
  effectivePrice?: number | null;
  standardPrice?: number | null;
  effectTime?: string | null;
  cabinType?: string | null;
  statusUpdateTime?: string | null;
  status?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface SplitPNRResponseType {
  ResponseStatus: ResponseStatusType | null;
  splitCodes: PNRInfoType[] | [];
}
export interface SubCabinEntity {
  blk: string | "";
  /**
   * 当前生效小舱对应的运价
   */
  effectiveFarePrice?: number | null;
  /**
   * 当前生效动态运价
   */
  dynamicFarePrice?: number | null;
  /**
   * 运价更新时间
   */
  priceUpdateTime: string | "";
  lt: string | "";
  grs: string | "";
  /**
   * 航司运价
   */
  farePrice?: number | null;
  /**
   * 子仓，样例：R
   */
  cls: string | "";
  /**
   * 还可销售座位数
   */
  lss: string | "";
  /**
   * 限制销售座位数
   */
  lsv: string | "";
  wl: string | "";
  /**
   * 当前生效动态运价更新时间
   */
  dynamicUpdateTime?: string | null;
  /**
   * 已订妥座位数
   */
  bkd: string | "";
  smt: string | "";
  /**
   * IND标识
   */
  ind: string | "";
  /**
   * 当前生效小舱
   */
  effectiveSmallCabin: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface SetSmallCabinRuleResponseType {
  takeoffdate: string | "";
  flightno: string | "";
  triggerPriceQuery: boolean | false;
  seg: string | "";
  cacheKey: string | "";
  ResponseStatus: ResponseStatusType | null;
  /**
   * 获取的航班信息列表
   */
  smallCabins: SmallCabinInfo[] | [];
  optList: SmallCabinOptType[] | [];
  executedCMD: string | "";
}
export interface GetSeatInfoRequestType {
  Head: RequestHeadType | null;
  flights: SeatInfoRequestEntity[] | [];
  userName: string | "";
  userGroup?: string | null;
}
export interface SQLModifyInfoType {
  dataId: number | 0;
  tableName: string | "";
  status?: boolean | null;
}
/**
 *
 * define the request class
 *
 */
export interface GetAutoAdjustStatesResponseType {
  ResponseStatus: ResponseStatusType | null;
  flights: AutoAdjustFlightStateType[] | [];
}
/**
 *
 * define the response class
 *
 */
export interface SmallCabinOptType {
  /**
   * 操作:"+"/"-"
   */
  opt: string | "";
  /**
   * 子舱类型
   */
  subCabinType: string | "";
  /**
   * 小舱类型
   */
  smallCabinType: string | "";
  /**
   * 执行结果
   */
  status: string | "";
}
/**
 * This is serivce, validation or framework-level error.
 */
export interface ErrorDataType {
  /**
   * ErrorDataType
   */
  ErrorClassification?: ErrorClassificationCodeType | null;
  Message?: string | null;
  /**
   * ErrorDataType
   */
  ErrorFields?: ErrorFieldType | null;
  /**
   * ErrorDataType
   */
  StackTrace?: string | null;
  /**
   * ErrorDataType
   */
  SeverityCode?: SeverityCodeType | null;
  /**
   * A unique code that identifies the particular error condition that occurred.
   */
  ErrorCode?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface ForwardSeatInfoResponseType {
  ResponseStatus: ResponseStatusType | null;
  executeStatus: boolean | false;
}
/**
 *
 * define the response class
 *
 */
export interface FlightFarePriceSetType {
  flightno?: string | null;
  enddate?: string | null;
  targetPrice?: number | null;
  seg?: string | null;
  startdate?: string | null;
  dow?: string | null;
  cabinType?: string | null;
  optMode?: DynamicFarePriceOptMode | null;
}
export interface SkyOneAuthenticateRequestType {
  /**
   * 目前所处认证阶段，分为0，1，2三步
   */
  currentStage: number | 0;
  Head: RequestHeadType | null;
  /**
   * 通用字段，图形验证码或SMS验证码。stage为1时需要传图形验证码，stage为2时需要传sms验证码
   */
  code: string | "";
  /**
   * SkyOne密码，stage为1时需要传
   */
  passWd: string | "";
  /**
   * SkyOne用户名，stage为1时需要传
   */
  userName: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface SmallCabinInfo {
  /**
   * 子舱类型
   */
  subCabinType: string | "";
  /**
   * 最后同步时间
   */
  lastSyncTime: string | "";
  /**
   * 是否打开
   */
  isOpen: boolean | false;
  priceUpdateTime: string | "";
  /**
   * 涉及规则信息（关闭时候为空）
   */
  ruleInfo: RuleBasicInfoType[] | [];
  /**
   * 最后修改时间
   */
  lastModifyTime: string | "";
  farePrice?: number | null;
  /**
   * 建议操作 开/关/空
   */
  suggestOpt: string | "";
  groupNumber?: number | null;
  /**
   * 小舱类型
   */
  smallCabinType: string | "";
  /**
   * 是否当前生效小舱
   */
  isEffective: boolean | false;
}
export interface CabinBkdInfo {
  bkd?: number | null;
  cls: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface GetFlightInfoResponseType {
  ResponseStatus: ResponseStatusType | null;
  /**
   * 返回的航班信息列表
   */
  flights: FlightInfoResponseEntity[] | [];
}
/**
 * 竞飞航班概率
 *
 */
export interface CompetitorInfo {
  prob: string | "";
  flightNo: string | "";
  price: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface IMBatchCmdGenerateResponseType {
  ResponseStatus: ResponseStatusType | null;
  imCmd: FlightImCmdType[] | [];
}
/**
 *
 * define the response class
 *
 */
export interface IMBatchResponseType {
  ResponseStatus: ResponseStatusType | null;
  /**
   * 航班信息，跟RO返回数据结构一致
   */
  flights: BatchFlightImResult[] | [];
}
export interface ExtensionType {
  /**
   * ExtensionType
   */
  ContentType?: string | null;
  /**
   * ExtensionType
   */
  Version?: string | null;
  /**
   * ExtensionType
   */
  Value?: string | null;
  /**
   * ExtensionType
   */
  Id?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface SplitPNRRequestType {
  Head: RequestHeadType | null;
  seatNum?: number | null;
  pnrList?: string[] | null;
  airline?: string | null;
  userGroup?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface ApplyPNRRequestType {
  timeLimit?: string | null;
  cabinLevel?: string | null;
  Head: RequestHeadType | null;
  monthCode?: string | null;
  seatNum?: number | null;
  mobileNo?: string | null;
  flights?: FlightSimpleInfoType[] | null;
  airline?: string | null;
  userGroup?: string | null;
  officeNo?: string | null;
}
export interface SeatInfoResponseEntity {
  /**
   * 起飞日期
   */
  takeoffdate: string | "";
  currenttime?: string | null;
  /**
   * 已售座位
   */
  saledseat?: number | null;
  lf_share?: number | null;
  groupCabinBkd?: number | null;
  lf_share_new?: number | null;
  saleIncrement?: number | null;
  subCabin?: string | null;
  groupBkd?: number | null;
  /**
   * 航班号
   */
  flightno: string | "";
  /**
   * 舱位总数
   */
  cap?: number | null;
  saledseat_share?: number | null;
  /**
   * 航段
   */
  seg: string | "";
  individualBkdIncrement?: number | null;
  /**
   * 客座率
   */
  lf?: number | null;
  aheadDays?: number | null;
  groupBkdIncrement?: number | null;
}
export interface SkyOneLogOutResponseType {
  ResponseStatus: ResponseStatusType | null;
  /**
   * SkyOne账户是否登出成功
   */
  isSuccess: boolean | false;
}
export interface ExecuteEIInfoType {
  pnrCode?: string | null;
  status?: boolean | null;
}
/**
 *
 * define the request class
 *
 */
export interface ForwardSeatInfoRequestType {
  Head: RequestHeadType | null;
  dataSource: string | "";
  rawMessage: string | "";
}
export interface SeatInfoRequestEntity {
  /**
   * 起飞日期，样例：2021-06-20
   */
  takeoffdate: string | "";
  /**
   * 航班号，样例：9H0000
   */
  flightno: string | "";
  /**
   * 航段
   */
  seg: string | "";
}
/**
 *
 * define the request class
 *
 */
export interface GetROLssResponseType {
  ResponseStatus: ResponseStatusType | null;
  allCabins: allCabinsBelow2[] | [];
}
export interface CabinStatus {
  cls: string | "";
  status: string | "";
}
export interface FlightSimpleInfoType {
  /**
   * 起飞日期，样例：2021-06-20
   */
  takeoffdate: string | "";
  /**
   * 航班号，样例：9H0000
   */
  flightno: string | "";
  /**
   * 航段，样例：XIYPVG
   */
  seg: string | "";
}
export interface changedCabinsBelow6 {
  cls: string | "";
  lsv: string | "";
  ind: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface IMCmdGenerateResponseType {
  ResponseStatus: ResponseStatusType | null;
  imCmd: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface IMBatchCmdGenerateRequestType {
  Head: RequestHeadType | null;
  flights: BatchAdjustFlightType[] | [];
  userName: string | "";
}
export interface SubLegEntity {
  blk: string | "";
  pt: string | "";
  /**
   * 最大开放数
   */
  max: string | "";
  gro: string | "";
  lt: string | "";
  grs: string | "";
  /**
   * 还可销售数
   */
  opn: string | "";
  gt: string | "";
  lss: string | "";
  tb: string | "";
  /**
   * 航节出发机场
   */
  leg: string | "";
  layout?: string | null;
  ct: string | "";
  stopBooking?: boolean | null;
  /**
   * 布局数
   */
  cap: string | "";
  at: string | "";
  av: string | "";
  pcfValue?: number | null;
  smt: string | "";
  /**
   * IND标识
   */
  ind: string | "";
}
/**
 *
 * define the request class
 *
 */
export interface SetAutoAdjustStatesRequestType {
  Head: RequestHeadType | null;
  websiteUserID?: string | null;
  flights: AlterAutoAdjustFlightStateType[] | [];
  userGroup?: string | null;
  websiteUserName?: string | null;
}
/**
 *
 * define the response class
 *
 */
export interface SetDynamicFarePriceRequestType {
  Head: RequestHeadType | null;
  websiteUserID?: string | null;
  flights: FlightFarePriceSetType[] | [];
  airline?: string | null;
  userGroup?: string | null;
  websiteUserName?: string | null;
}
/**
 *
 * define the response class
 *
 */
export interface IMResponseType {
  isIMSuccess?: boolean | null;
  triggerPriceQuery: boolean | false;
  ResponseStatus: ResponseStatusType | null;
  /**
   * 航班信息，跟RO返回数据结构一致
   */
  flights: FlightROInfoType[] | [];
}
/**
 * 航班批量执行指令类型
 */
export interface FlightImCmdType {
  flightno: string | "";
  line: string | "";
  cmd: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface IMCmdGenerateRequestType {
  Head: RequestHeadType | null;
  flights: AdjustFlightType[] | [];
  userName: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface IMRequestType {
  executeCMD: boolean | false;
  Head: RequestHeadType | null;
  confirmedCMD: string | "";
  websiteUserID?: string | null;
  flights: AdjustFlightType[] | [];
  userName: string | "";
  userGroup?: string | null;
  roCacheKey: string | "";
  websiteUserName?: string | null;
}
/**
 *
 * define the response class
 *
 */
export interface SetDynamicFarePriceResponseType {
  ResponseStatus: ResponseStatusType | null;
  flights: FlightDynamicFarePriceType[] | [];
}
/**
 *
 * define the request class
 *
 */
export interface SetAutoAdjustStatesResponseType {
  ResponseStatus: ResponseStatusType | null;
  flights: AutoAdjustFlightStateType[] | [];
}
export interface MultiSeatInfoRequestEntity {
  /**
   * 航班号，样例：9H0000
   */
  flightno: string | "";
  /**
   * 结束日期，样例：2021-06-30
   */
  enddate: string | "";
  /**
   * 开始日期，样例：2021-06-20
   */
  startdate: string | "";
}
/**
 *
 * define the request class
 *
 */
export interface ModifySQLDataRequestType {
  Head: RequestHeadType | null;
  flights: SQLModifyInfoType[] | [];
}
/**
 * 航班动态运价类型
 */
export interface FlightDynamicFarePriceType {
  optStatus?: string | null;
  takeoffdate: string | "";
  flightno: string | "";
  line: string | "";
  aheaddays?: number | null;
  priceList: CabinDynamicFarePriceType[] | [];
}
export interface allCabinsBelow2 {
  cls: string | "";
  lss: string | "";
  ind: string | "";
}
export interface GetSkyOneLogStatusResponseType {
  ResponseStatus: ResponseStatusType | null;
  /**
   * 是否保持在线状态
   */
  isLoggedIn: boolean | false;
}
/**
 *
 * define the request class
 *
 */
export interface GetRODataRequestType {
  Head: RequestHeadType | null;
  /**
   * 批次号
   */
  jobBatchNo?: number | null;
  /**
   * 是否请求model
   */
  modelTrigger: boolean | false;
  allowModelCache?: boolean | null;
  /**
   * 需要获取信息的航班信息列表
   */
  flights: FlightSimpleInfoType[] | [];
  userName: string | "";
  userGroup?: string | null;
  isJob?: boolean | null;
}
/**
 *
 * define the response class
 *
 */
export interface SetSmallCabinRuleRequestType {
  Head: RequestHeadType | null;
  /**
   * 起飞日期
   */
  takeoffdate: string | "";
  /**
   * 航班号
   */
  flightno: string | "";
  /**
   * 航段
   */
  seg: string | "";
  cacheKey: string | "";
  websiteUserID?: string | null;
  /**
   * 操作列表
   */
  optList: SmallCabinOptType[] | [];
  userName: string | "";
  userGroup?: string | null;
  websiteUserName?: string | null;
}
/**
 *
 * define the response class
 *
 */
export interface FlightInfoRequestEntity {
  /**
   * 起飞日期，样例，2021-06-20
   */
  takeoffdate: string | "";
  /**
   * 航班号
   */
  flightno: string | "";
  /**
   * 航段，样例：SHABJS
   */
  seg: string | "";
  /**
   * 是否请求eterm
   */
  eterm: boolean | false;
  /**
   * JOB批次号
   */
  jobBatchNo?: number | null;
  /**
   * 是否请求model
   */
  modelTrigger: boolean | false;
  allowModelCache?: boolean | null;
  /**
   * 是否是JOB
   */
  isJob?: boolean | null;
}
/**
 *
 * define the request class
 *
 */
export interface GetROLssRequestType {
  Head: RequestHeadType | null;
  takeoffdate: string | "";
  flightno: string | "";
  seg: string | "";
  changedCabins: changedCabinsBelow6[] | [];
  /**
   * RO接口返回的cacheKey
   */
  roCacheKey: string | "";
}
/**
 *
 * define the request class
 *
 */
export interface ApplyPNRResponseType {
  ResponseStatus: ResponseStatusType | null;
  applyCodes: PNRInfoType[] | [];
}
/**
 *
 * define the response class
 *
 */
export interface GetAdjustmentResponseType {
  ResponseStatus: ResponseStatusType | null;
  /**
   * 执行的命令
   */
  cmd: string | "";
}
export interface AutoAdjustFlightStateType {
  /**
   * 起飞日期，样例：2021-06-20
   */
  takeoffdate: string | "";
  /**
   * 航班号，样例：9H0000
   */
  flightno: string | "";
  /**
   * 航段，样例：XIYPVG
   */
  seg: string | "";
  isSetOk?: boolean | null;
  state?: UserDefinedPermissionState | null;
  isWithinRange?: boolean | null;
}
export interface GetMultiSeatInfoResponse {
  ResponseStatus: ResponseStatusType | null;
  flights: SeatInfoResponseEntity[] | [];
}
/**
 *
 * define the response class
 *
 */
export interface RuleBasicInfoType {
  /**
   * 规则类型
   */
  ruleType: string | "";
  /**
   * 规则编号
   */
  ruleNumber: string | "";
  /**
   * 规则名称
   */
  ruleName: string | "";
}
export interface RequestHeadType {
  /**
   * 客户端appid
   */
  ClientAppID: string | "";
  /**
   * 客户端ip
   */
  ClientIP: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface GetSmallCabinDataResponseType {
  /**
   * 起飞日期
   */
  takeoffdate: string | "";
  /**
   * 航班号
   */
  flightno: string | "";
  /**
   * 航段
   */
  seg: string | "";
  /**
   * 小舱信息缓存键
   */
  cacheKey: string | "";
  ResponseStatus: ResponseStatusType | null;
  /**
   * 建议命令
   */
  suggestCMD: string | "";
  /**
   * 当前最低外放舱位
   */
  lowestEffiSubCabin?: string | null;
  /**
   * 获取的航班信息列表
   */
  smallCabins: SmallCabinInfo[] | [];
}
/**
 *
 * define the response class
 *
 */
export interface IMBatchRequestType {
  Head: RequestHeadType | null;
  confirmedCMD: FlightImCmdType[] | [];
  websiteUserID?: string | null;
  flights: BatchAdjustFlightType[] | [];
  userName: string | "";
  userGroup?: string | null;
  roCacheKey?: string | null;
  websiteUserName?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface ExecuteEIRequestType {
  eiCode?: string | null;
  Head: RequestHeadType | null;
  executeContent?: string | null;
  pnrList?: string[] | null;
  airline?: string | null;
  userGroup?: string | null;
}
/**
 * A variable that contains specific information about the context of this error.
 * For example, in request validation failure case,
 * the problematic field name might be returned as an error field.
 * Use error fields to flag fields that users need to correct.
 * Also use error fields to distinguish between errors when multiple
 * errors are returned.
 */
export interface ErrorFieldType {
  /**
   * ErrorFieldType
   */
  Message?: string | null;
  /**
   * ErrorFieldType
   */
  ErrorCode?: string | null;
  /**
   * ErrorFieldType
   */
  FieldName?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface GetAVDataRequestType {
  Head: RequestHeadType | null;
  avFlightType?: AVFlightType | null;
  flightNo: string | "";
  seg: string | "";
  endDate: string | "";
  avDataType?: AVDataType | null;
  flpDataType?: FLPDataType | null;
  airline: string | "";
  dow: string | "";
  userGroup?: string | null;
  startDate: string | "";
}
export interface LegEntity {
  /**
   * 子仓分布
   */
  subcabin: string | "";
  /**
   * 仓等，样例:Y
   */
  cabin: string | "";
  sublegs: SubLegEntity[] | [];
}
export interface AlterAutoAdjustFlightStateType {
  /**
   * 起飞日期，样例：2021-06-20
   */
  takeoffdate: string | "";
  /**
   * 航班号，样例：9H0000
   */
  flightno: string | "";
  /**
   * 航段，样例：XIYPVG
   */
  seg: string | "";
  targetState?: UserDefinedPermissionState | null;
  currentState?: UserDefinedPermissionState | null;
}
/**
 *
 * define the request class
 *
 */
export interface GetAVDataResponseType {
  ResponseStatus: ResponseStatusType | null;
  flpData?: FLPDataEntity[] | null;
  avData?: AVDataEntity[] | null;
}
export interface GetSkyOneLogStatusRequestType {
  Head: RequestHeadType | null;
  /**
   * SkyOne用户名
   */
  userName: string | "";
}
export interface AdjustSubCabinEntity {
  /**
   * 改变嵌套状态，如果是“+”，则标识原来是非嵌套，现在要变为嵌套；如果是“-”，标识原来是嵌套，现在要变成非嵌套
   */
  ind_L: string | "";
  /**
   * 子仓
   */
  cls: string | "";
  /**
   * 改变永久申请状态，如果是“+”，则标识原来是非永久申请，现在要变为永久申请；如果是“-”，标识原来是永久申请，现在要变成非永久申请
   */
  ind_P: string | "";
  /**
   * 限制销售座位数
   */
  lsv: string | "";
}
export interface SkyOneAuthenticateResponseType {
  /**
   * 图形验证码，仅在stage为0时返回。如果使用第三方验证码识别工具，识别结果也在此处返回。
   */
  captchaCode: GeneralFieldType[] | [];
  ResponseStatus: ResponseStatusType | null;
  /**
   * 认证状态码，0代表失败，1代表成功
   */
  status: number | 0;
}
export interface SkyOneLogOutRequestType {
  Head: RequestHeadType | null;
  /**
   * SkyOne用户名
   */
  userName: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface GetRODataResponseType {
  ResponseStatus: ResponseStatusType | null;
  /**
   * 获取的航班信息列表
   */
  flights: FlightROInfoType[] | [];
}
/**
 *
 * define the response class
 *
 */
export interface GetFlightInfoRequestType {
  flightDataSource?: DataSourceType | null;
  Head: RequestHeadType | null;
  /**
   * 是否请求eterm
   */
  eterm: boolean | false;
  /**
   * 是否请求model
   */
  modelTrigger: boolean | false;
  /**
   * 需要请求的航班信息列表
   */
  flights: FlightInfoRequestEntity[] | [];
  userName: string | "";
  userGroup?: string | null;
}
/**
 *
 * define the response class
 *
 */
export interface LoadFactorInfo {
  saleIncrement?: number | null;
  groupBkd?: number | null;
  /**
   * 最大客座数
   */
  cap: number | 0;
  /**
   * 已销售的客座数，包含锁定未出票的
   */
  saledseat: number | 0;
  groupDetail?: string | null;
  individualBkdIncrement?: number | null;
  /**
   * 客座率，没有百分比，样例：60，表示60%
   */
  lf: number | 0;
  groupBkdIncrement?: number | null;
  groupCabinBkd?: number | null;
}
/**
 *
 * define the response class
 *
 */
export interface BatchAdjustFlightType {
  layout?: string | null;
  /**
   * 航班号
   */
  flightno: string | "";
  enddate: string | "";
  seg: AdjustSegType | null;
  line: string | "";
  startdate: string | "";
  /**
   * day of week
   */
  dow?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface ExecuteEIResponseType {
  executeResList: ExecuteEIInfoType[] | [];
  ResponseStatus: ResponseStatusType | null;
}
/**
 * Response status type definition of a response payload. Per ctrip SOA policy, all
 * concrete response types must include this response status type as a root element with element name 'ResponseStatus'.
 * This is requreied for unified response status/error handling at framework level.
 * The recommended naming convention we use for the concrete type names is the name
 * of the service (the verb or call name) followed by "ResponseType": VerbNameResponseType
 */
export interface ResponseStatusType {
  Extension?: ExtensionType[] | null;
  msg?: string | null;
  Errors?: ErrorDataType[] | null;
  reason?: string | null;
  /**
   * 响应编码（20000：成功）
   */
  ResponseCode?: number | null;
  /**
   * 描述信息
   */
  responseDesc?: string | null;
  code?: string | null;
  Version?: string | null;
  Build?: string | null;
  Ack?: AckCodeType | null;
  Timestamp?: string | null;
  userID?: string | null;
}
/**
 *
 * define the response class
 *
 */
export interface GetAdjustmentRequestType {
  price_ori: string | "";
  Head: RequestHeadType | null;
  /**
   * 起飞日期，样例，2021-06-20
   */
  takeoffdate: string | "";
  /**
   * 航班号
   */
  flightno: string | "";
  /**
   * 航段，样例：SHABJS
   */
  seg: string | "";
  /**
   * 建议舱位，样例：N
   */
  subclass_suggest: string | "";
  userName: string | "";
  /**
   * 原始舱位，样例：M
   */
  subclass_ori: string | "";
  price_suggest: string | "";
}
/**
 *
 * define the request class
 *
 */
export interface GetAutoAdjustStatesRequestType {
  Head: RequestHeadType | null;
  flights: FlightSimpleInfoType[] | [];
  userGroup?: string | null;
}
export interface SegEntity {
  /**
   * 模型建议舱位
   */
  subclasssuggest: string | "";
  /**
   * 需要调整的子仓信息
   */
  adjustsubcabins: AdjustSubCabinEntity[] | [];
  /**
   * 子仓信息
   */
  subcabins: SubCabinEntity[] | [];
  priceori: string | "";
  /**
   * 航段，样例：SHABJS
   */
  line: string | "";
  pricesuggest: string | "";
  lsssuggest?: string | null;
  /**
   * 原舱位
   */
  subclassori: string | "";
  advisetime?: string | null;
}
/**
 *
 * define the request class
 *
 */
export interface ModifySQLDataResponseType {
  ResponseStatus: ResponseStatusType | null;
  flights: SQLModifyInfoType[] | [];
}
/**
 *
 * define the request class
 *
 */
export interface GetSmallCabinDataRequestType {
  Head: RequestHeadType | null;
  takeoffdate: string | "";
  flightno: string | "";
  seg: string | "";
  userName: string | "";
  userGroup?: string | null;
  /**
   * RO接口返回的cacheKey
   */
  roCacheKey: string | "";
}
export interface FLPDataEntity {
  cabinBkdInfos: CabinBkdInfo[] | [];
  flightNo: string | "";
  takeoffDate: string | "";
  seg: string | "";
}
export interface GetMultiSeatInfoRequestType {
  flight: MultiSeatInfoRequestEntity | null;
  Head: RequestHeadType | null;
  userName: string | "";
  dataSource?: DataSourceType | null;
  userGroup?: string | null;
}
/**
 *
 * define the response class
 *
 */
export interface FlightInfoResponseEntity {
  competitorsInfo: CompetitorInfo[] | [];
  /**
   * 模型返回参数
   */
  aheadday: string | "";
  /**
   * 模型返回参数
   */
  referprices: string | "";
  /**
   * 模型返回参数
   */
  minpriceclose: string | "";
  /**
   * 模型返回参数
   */
  datachange_createtime: string | "";
  errorCode: string | "";
  /**
   * 模型返回参数
   */
  linesstring: string | "";
  /**
   * 模型返回参数
   */
  takeofftime: string | "";
  /**
   * 模型返回参数
   */
  mincityprice: string | "";
  /**
   * 航班号
   */
  flightno: string | "";
  isLongSeg?: boolean | null;
  reallocatedCap?: string | null;
  /**
   * 模型返回参数，id
   */
  id: string | "";
  /**
   * 模型返回参数
   */
  probsuggest: string | "";
  /**
   * 模型返回参数
   */
  subclasssuggest: string | "";
  currentLf: string | "";
  /**
   * 起飞日期
   */
  takeoffdate: string | "";
  /**
   * 模型返回参数
   */
  priceori: string | "";
  errorMessage: string | "";
  lsssuggest?: string | null;
  /**
   * 模型返回参数
   */
  subclassori: string | "";
  /**
   * 客座率信息，从eterm中获取，如果传入参数eterm为false，则该对象没有数据
   */
  lfinfo: LoadFactorInfo | null;
  /**
   * 模型返回参数
   */
  competeminprice: string | "";
  /**
   * 模型返回参数
   */
  pricesuggest: string | "";
  /**
   * 模型返回参数
   */
  probori: string | "";
  advisetime?: string | null;
}
export interface AVDataEntity {
  flightNo: string | "";
  takeoffDate: string | "";
  seg: string | "";
  cabinStatuses: CabinStatus[] | [];
}
/**
 * 航班批量执行指令类型
 */
export interface BatchFlightImResult {
  isIMSuccess?: boolean | null;
  flightno: string | "";
  enddate: string | "";
  line: string | "";
  cmd: string | "";
  startdate: string | "";
  dow?: string | null;
  errormsg: string | "";
  errorcode: string | "";
}
/**
 *
 * define the response class
 *
 */
export interface GetDynamicFarePriceRequestType {
  Head: RequestHeadType | null;
  flights: FlightSimpleInfoType[] | [];
  airline?: string | null;
  userGroup?: string | null;
}
export interface AdjustMainCabinEntity {
  /**
   * 改变禁售状态，如果是"+"，则表示原来不禁售，现在要变成禁售。
   */
  stopBooking?: string | null;
  /**
   * 改变嵌套状态，如果是“+”，则标识原来是非嵌套，现在要变为嵌套；如果是“-”，标识原来是嵌套，现在要变成非嵌套
   */
  ind_l: string | "";
  /**
   * 超售数
   */
  pcfValue?: number | null;
  /**
   * 主仓
   */
  cls: string | "";
}
/**
 * 通用的键值对类型
 */
export interface GeneralFieldType {
  fieldName: string | "";
  fieldValue: string | "";
}
export interface FlightROInfoType {
  modelErrorCode?: string | null;
  /**
   * 航线：样例：SHABJS
   */
  line: string | "";
  allowModelCache?: boolean | null;
  /**
   * 航段信息
   */
  segs: SegEntity[] | [];
  takeofftime: string | "";
  isJob?: boolean | null;
  isInitialization?: boolean | null;
  layoutRestriction?: LayoutRestrictionType | null;
  /**
   * 航班号
   */
  flightno: string | "";
  cacheKey: string | "";
  isLongSeg?: boolean | null;
  reallocatedCap?: string | null;
  /**
   * 航节信息
   */
  legs: LegEntity[] | [];
  effectCabinInfoAttached: boolean | false;
  /**
   * 机型
   */
  flighttype: string | "";
  borrowMethod?: BorrowMethod | null;
  /**
   * 起飞日期
   */
  takeoffdate: string | "";
  /**
   * 距离起飞的天数
   */
  daterange: number | 0;
  jobBatchNo?: number | null;
  isRestock?: boolean | null;
  isPriceCorrect?: boolean | null;
  modelErrorMsg?: string | null;
  /**
   * 舱位分布
   */
  layout: string | "";
  currentTime?: string | null;
  etermcmd: string | "";
  adviseId: string | "";
}

export enum DataSourceType {
  ETERM,
  CE,
  SKYONE,
}
export enum UserDefinedPermissionState {
  ALL,
  PRICE_UNCHANGE,
  PRICE_CHANGE,
  NONE,
}
export enum AVFlightType {
  ALL,
  DIRECT,
}
export enum FLPDataType {
  FLPJ,
  FLP,
}
/**
 * AckCodeType - Type declaration to be used by other schema. This code identifies
 * the acknowledgement code types that ctrip could use to communicate the status of
 * processing a (request) message to a client. This code would be used as part
 * of a response message that contains a framework, validation or service-level
 * acknowledgement element.
 */
export enum AckCodeType {
  Warning = "Warning",
  PartialFailure = "PartialFailure",
  Success = "Success",
  Failure = "Failure",
}
/**
 * SeverityCodeType - Type declaration to be used by other schema. This code
 * identifies the severity of an API error. A code indicates whether there is an API-
 * level error or warning that needs to be communicated to the client.
 */
export enum SeverityCodeType {
  Warning,
  Error,
}
export enum AVDataType {
  AV,
  AVJ,
  AVO,
}
export enum ErrorClassificationCodeType {
  ServiceError,
  FrameworkError,
  SLAError,
  ValidationError,
}
export enum DynamicFarePriceOptMode {
  DRAWBACK = "DRAWBACK",
  PUBLISH = "PUBLISH",
}
/**
 * 航司布局特殊约束
 */
export enum LayoutRestrictionType {
  UNNEST_CLOSED_CABIN = "UNNEST_CLOSED_CABIN",
}
export enum BorrowMethod {
  LOWEST = "LOWEST",
  NEIGHBOUR = "NEIGHBOUR",
}
