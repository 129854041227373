import { SystemType } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";
import React from "react";

const airlinePanelType0Help = {
  [getSharkText("config_page_search_pv")]: getSharkText(
    "config_page_user_search_times"
  ),
  [getSharkText("config_page_display_pv")]: getSharkText(
    "config_page_airline_transfer_policy_display_times"
  ),
  [getSharkText("config_page_display_rate")]:
    getSharkText("config_page_display_pv") + "/搜索PV",
};

const airlinePanelType1Help = {
  [getSharkText("config_page_overall_transfer_policy")]: (
    <span>
      {getSharkText(
        "config_page_user_search_results_transfer_product_flight_combination_number_accumulation"
      )}
      .
      <br />
      {getSharkText("config_page_airline_transfer_policy_number")}
    </span>
  ),
  [getSharkText("config_page_airline_transfer_policy_number")]: (
    <span>
      {getSharkText(
        "config_page_user_search_results_xx_airline_flight_combination_number_accumulation"
      )}
      .
      <br />
      {getSharkText("config_page_for_example")}
      {getSharkText("config_page_user_search_once")}上海-佳木斯，
      {getSharkText("config_page_appear")}10个
      {getSharkText("config_page_transfer_combination_product")}，
      <br />
      {getSharkText("config_page_both_segments_are_eastern_airlines")}
      为5，那么
      {getSharkText("config_page_eastern_airlines_transfer_policy_number")}
      为5
    </span>
  ),
  展示率: `${getSharkText(
    "config_page_airline_transfer_policy_number"
  )}/${getSharkText("config_page_overall_transfer_policy")}`,
};
export const legendHelp = {
  [SystemType.airport]: {
    0: {
      ...airlinePanelType0Help,
      展示PV: getSharkText(
        "config_page_this_airport_transfer_policy_display_times"
      ),
    },
    1: {
      整体中转政策数: (
        <span>
          {getSharkText("config_page_user_search_results")}
          中转产品的航班组合数目累加。
          <br />
          {getSharkText("config_page_for_example")}
          {getSharkText("config_page_user_search_once")}上海-佳木斯，
          {getSharkText("config_page_appear")}10个中转组合产品，
          {getSharkText("config_page_this_time_transfer_policy_number")}
          为10
        </span>
      ),
      airport_transfer_policy_count: (
        <span>
          {getSharkText(
            "config_page_user_search_results_airport_flight_combination_count_accumulation"
          )}
          .
          <br />
          {getSharkText(
            "config_page_for_example_user_search_once_guangzhou_jiamusi"
          )}
          ,
          <br />
          {getSharkText("config_page_appear_10_transfer_combination_products")},
        </span>
      ),
      展示率: getSharkText(
        "config_page_airport_transfer_policy_count_whole_transfer_policy_count"
      ),
    },
  },
  [SystemType.airlines]: {
    0: airlinePanelType0Help,
    1: airlinePanelType1Help,
  },
};
