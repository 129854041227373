import { Col, Row, Select } from "antd";
import FlightClassCom from "Components/Toolbar/FlightClassCom";
import SelectMultiRoute from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import React, {
  CSSProperties,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import useGlobalState from "Store";
import { EOwnerType, EPanelType, OwnerType } from "../MonitoringAlertingCommon";
import style from "./SearchArea.module.scss";
import Airlines from "Components/Toolbar/Airlines";
import { getDateRange, getUserAirlines } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import {
  DATE_FORMAT,
  DEFAULT_AIRLINE,
  LIMIT_END_DATE,
  LIMIT_START_DATE,
} from "Constants";
import { AirlinesQueryCondition, RangeObject, Role } from "Interface";
import { useQueryString, useQueryStringArr } from "Utils/query/useQueryString";
import DateRangePickerV2 from "Components/DateRangePickerV2";

import moment, { Moment } from "moment";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import { G } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";

const { Option } = Select;

/**
 * Component description
 * 预警搜索
 */

interface IProps {
  panelType: EPanelType;
  onChange: (v: Record<string, unknown>) => void;
  style?: CSSProperties;
  isDemo?: boolean;
}

const SearchArea = (props: IProps): ReactElement => {
  const { panelType, onChange, style: css, isDemo = false } = props;
  // const [routes, setRoutes] = useState<string[]>([]);
  const [routes, setRoutes] = useQueryStringArr("routes", []);
  const [flightNo, setFlightNo] = useQueryStringArr("flightNo", []);
  const [qStartDate, setStartDate] = useQueryString("startDate", "");
  const [qEndDate, setEndDate] = useQueryString("endDate", "");
  const [qAirline] = useQueryString("airline", "");
  const [ownerType, setOwnerType] = useState<EOwnerType>(EOwnerType.System);
  const [globalState, actions] = useGlobalState();
  const { userInfo, airlinesQueryCondition } = globalState;
  const { setAirlinesQueryCondition, setAirlinesQueryConditionAttr } = actions;
  const { user, roleList } = userInfo;
  const isCtrip = user.userType === 1;
  const userAirlines = useMemo(
    () => getUserAirlines(userInfo.roleList),
    [userInfo.roleList]
  );
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;

  const dates = useMemo(() => {
    if (qStartDate && qEndDate) {
      return [moment(qStartDate), moment(qEndDate)] as RangeObject;
    }
    return null;
  }, [qStartDate, qEndDate]);

  useEffect(() => {
    onChange({
      lineList: routes,
      flightNo,
      ownerType,
    });
  }, [routes, flightNo, onChange, ownerType]);
  const defaultSelectedAirline = useMemo(() => {
    if (qAirline) {
      return qAirline;
    }
    if (airlinesRole && airlinesRole.permissionCode !== "*") {
      return airlinesRole.permissionCode?.split(",")[0];
    }
    return DEFAULT_AIRLINE;
  }, []);
  useEffect(() => {
    const range = getDateRange("nextFourteenDays") as [Moment, Moment];
    const globalValue: Partial<AirlinesQueryCondition> = {
      startDate: qStartDate || range[0].format(DATE_FORMAT),
      endDate: qEndDate || range[1].format(DATE_FORMAT),
    };
    if (!airlinesQueryCondition.airlines) {
      globalValue.airlines = defaultSelectedAirline;
      globalValue.airlinesName = "";
    }
    setAirlinesQueryCondition({
      ...airlinesQueryCondition,
      ...globalValue,
    });
  }, []);
  const setDates = useRefFunc((v: RangeObject) => {
    if (v && v.length === 2) {
      setAirlinesQueryConditionAttr({
        startDate: (v && v[0]?.format(DATE_FORMAT)) || LIMIT_START_DATE,
        endDate: (v && v[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
      });
    }
  });
  return (
    <div className={style.container} style={css}>
      <Row gutter={[20, 20]}>
        <Col>
          <FlightClassCom />
        </Col>
        {isCtrip && (
          <Col>
            <span className="search-area-filter-title">
              {getSharkText("system_type_Airline")}：
            </span>
            <Airlines airlines={userAirlines} mode={undefined} />
          </Col>
        )}
        <Col>
          <span className="search-area-filter-title">
            {getSharkText("charts.date_of_departure")}：
          </span>
          {/* <DateRangePicker
            defaultDateMode="nextFourteenDays"
            shouldDisableDate={false}
          /> */}
          <DateRangePickerV2
            defaultDateMode={dates || "nextFourteenDays"}
            onChange={setDates}
          />
        </Col>
        <Col>
          <SelectMultiRoute
            mode="multiple"
            routes={routes}
            setRoutes={setRoutes}
            isDemo={false}
            flights=""
            autoSelectFirst={panelType === EPanelType.Custom}
            useGroup
            type={
              panelType === EPanelType.Search ||
              panelType === EPanelType.Capacity
                ? 4
                : 1
            }
            useExchange
          />
        </Col>
        {panelType === EPanelType.Speed || EPanelType.Custom ? (
          <Col>
            <SelectMultiFlight
              flightNO={flightNo}
              setFlightNO={setFlightNo}
              routes={routes.join(",")}
              isDemo={isDemo}
              width={200}
              mode={undefined}
            />
          </Col>
        ) : undefined}
        {[EPanelType.Speed, EPanelType.Load, EPanelType.Price].includes(
          panelType
        ) ? (
          <Col>
            <span className="search-area-filter-title" style={{ width: 100 }}>
              {getSharkText("config_page_monitor_result_range")}：
            </span>
            <Select value={ownerType} onChange={(e) => setOwnerType(e)}>
              {G.enumValues(EOwnerType).map((t: EOwnerType) => (
                <Option value={t} key={t}>
                  {OwnerType[t]}
                </Option>
              ))}
            </Select>
          </Col>
        ) : undefined}
      </Row>
    </div>
  );
};
export default SearchArea;
