import { Cascader } from "antd";
import { DEFAULT_AREA } from "Constants";
import { Area } from "Interface";
import React, { ReactElement } from "react";
import { DefaultOptionType, SingleValueType } from "rc-cascader/lib/Cascader";
import { getSharkText } from "Utils/i18nGlobal";

export interface ICitySelectorProps {
  area: Area[];
  onChange: (v: Area) => void;
  placeholder?: string;
  type: "airport" | "city";
}

/**
 * 只能选择城市, 从接口获取数据后过滤到机场数据
 */
const CitySelector = (props: ICitySelectorProps): ReactElement => {
  const {
    area,
    onChange,
    placeholder = getSharkText("selector.city_select.hint"),
    type,
  } = props;

  const onCascaderChange = (
    value: SingleValueType,
    selectedOptions?: DefaultOptionType[]
  ) => {
    if (selectedOptions && selectedOptions.length) {
      // TODO fix type define
      onChange(selectedOptions[selectedOptions.length - 1] as any);
    } else {
      onChange(DEFAULT_AREA);
    }
  };

  const showSearch = {
    filter: (value: string, path: any[]) =>
      path.some(
        (item) =>
          item.areaType === (type === "airport" ? 5 : 4) &&
          (item.areaCode.includes(value.toUpperCase()) ||
            item.areaName.includes(value))
      ),
    limit: 10,
    render: (value: string, path: any[]) => (
      <span>
        {path[path.length - 1].areaName}({path[path.length - 1].areaCode})
      </span>
    ),
  };

  const displayRender = (labels: string[]) => (
    <span>{labels[labels.length - 1]}</span>
  );

  return (
    <Cascader
      className="toolbar-cascader"
      options={area.length > 0 && area[0].children ? area[0].children : []}
      placeholder={placeholder}
      expandTrigger="hover"
      fieldNames={{
        label: "areaName",
        value: "areaCode",
        children: "children",
      }}
      onChange={onCascaderChange}
      showSearch={showSearch}
      displayRender={displayRender}
      notFoundContent="No Data"
    />
  );
};
export default CitySelector;
