// Created by xh_zhu on 2020-12-16

import { Radio, Tooltip } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { DEFAULT_FLIGHT_CLASS, FLIGHT_CLASS } from "Constants";
import { FlightClass as FlightClassEnum } from "Interface";
import React, { useState } from "react";
import { getSharkText } from "Utils/i18nGlobal";

interface IFlightClassProps {
  helpVisibility?: boolean;
  defaultValue?: number;
  setFlightClass?: (val: FlightClassEnum) => void;
}

const FlightClass: React.FC<IFlightClassProps> = (props: IFlightClassProps) => {
  const {
    helpVisibility = false,
    setFlightClass,
    defaultValue = DEFAULT_FLIGHT_CLASS,
  } = props;
  const [fc, setFc] = useState<FlightClassEnum>(defaultValue);

  const handleChangeFlightClass = (e: RadioChangeEvent) => {
    setFc(e.target.value);
    if (setFlightClass) {
      setFlightClass(e.target.value);
    }
  };

  return (
    <Tooltip
      placement="bottom"
      visible={helpVisibility}
      title={getSharkText("key.change_data_range")}
      getPopupContainer={() => document.getElementById("main") || document.body}
    >
      <Radio.Group
        id="toolbarFlightClass"
        value={fc}
        onChange={handleChangeFlightClass}
        optionType="button"
        buttonStyle="solid"
      >
        {FLIGHT_CLASS.map((item, idx) => (
          <Radio.Button key={idx} value={idx}>
            {item}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Tooltip>
  );
};
export default FlightClass;
