/**
 *  Created by xh_zhu on 2020-12-10
 */
import { useEffect, useRef } from "react";

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
export default usePrevious;
