import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";
import { UserList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface IProps {
  userItem: UserList;
  refetch: () => void;
}

const UserDelete: React.FC<IProps> = (props: IProps) => {
  const { userItem, refetch } = props;

  const [, doFetch] = useFetch({
    url: "deleteUserById",
    head: {
      moduleCode: "user_manage",
      chartTableCode: null,
    },
    ext: {
      id: userItem.user.id,
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_delete_success"));
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_delete_fail"));
    },
  });

  const confirm = () => {
    doFetch({
      head: {
        moduleCode: "user_manage",
        chartTableCode: null,
      },
      ext: {
        id: userItem.user.id,
      },
    });
  };

  return (
    <Popconfirm
      title={getSharkText("config_page_confirm_delete")}
      okText={getSharkText("config_page_confirm")}
      cancelText={getSharkText("key.cancel.button")}
      onConfirm={confirm}
    >
      <a>
        <DeleteOutlined /> {getSharkText("config_page_delete")}
      </a>
    </Popconfirm>
  );
};

export default UserDelete;
