export const MODULE_CODE: any = {
  1: "",
  2: "dynamic_price_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {},
  2: {
    routeFlight: "AL0801",
    table: "AL0801",
    feedback: "AL0801",
    setHome: "AL0801",
  },
};

export const QUERY_URL: any = {
  1: {},
  2: {
    routeFlight: "queryDynamicPriceRouteOrFlight",
    table: "queryDynamicPriceTable",
    tableCount: "queryDynamicPriceTableCount",
    feedback: "setDynamicPriceFeedback",
    setHome: "setDynamicPriceHome",
  },
  popupPrice: "queryDynamicPricePopupPrice",
  popupLf: "queryDynamicPricePopupLf",
  save: "saveTargetLF",
  query: "queryTargetLF",
};
