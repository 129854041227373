import { Button, message, Modal, Spin, TableProps } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { EMPTY_ARRAY, SERVER } from "Constants";
import { Article } from "@ctrip/flt-bi-flightai-base";
import Page from "Layout/Page";
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFetch } from "Utils/useFetch";
import { genColumns, IAction } from "./columns";
import { QUERY_URL } from "./fetchCode";
import ReportEditor from "./ReportEditor";
import TypeEditor from "./TypeEditor";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * 研究报告后台管理页面
 */
const ReportManage = (): ReactElement => {
  const [visible, setVisible] = useState<boolean>(false);
  const [typeVisible, setTypeVisible] = useState<boolean>(false);
  const curObjRef = useRef<Article | undefined>();

  const [{ data: listArticle, isLoading, res }, listFetch] = useFetch({
    server: SERVER,
    url: QUERY_URL.listArticle,
    head: {
      moduleCode: "common",
      chartTableCode: "null",
    },
    ext: {
      keyword: "",
      typeId: null,
      page: {
        page: 1,
        pageSize: 10,
      },
      orderSort: 0,
    },
  });

  const [{ data: listType }, typeFetch] = useFetch({
    server: SERVER,
    url: QUERY_URL.listArticleType,
    head: {
      moduleCode: "common",
      chartTableCode: "null",
    },
    ext: {
      page: {
        page: 1,
        pageSize: 1000,
      },
      orderSort: 0,
    },
  });

  const refreshTypes = useCallback(() => {
    typeFetch({
      ext: {
        page: {
          page: 1,
          pageSize: 1000,
        },
        orderSort: 0,
      },
    });
  }, [typeFetch]);

  const refreshArticles = useCallback(() => {
    setVisible(false);
    listFetch({
      ext: {
        keyword: "",
        typeId: null,
        page: {
          page: 1,
          pageSize: 1000,
        },
        orderSort: 0,
      },
    });
  }, [listFetch]);

  const [, postArticleReq] = useFetch({
    server: SERVER,
    url: QUERY_URL.postArticle,
    head: {
      moduleCode: "common",
      chartTableCode: "null",
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_operation_success"));
      refreshArticles();
    },
    onError: () => {
      message.error(getSharkText("config_page_operation_failed"));
    },
  });

  const columns = useMemo(() => {
    const actions: Array<IAction<Article>> = [
      {
        name: getSharkText("config_page_edit"),
        method: (v: Article) => {
          curObjRef.current = v;
          setVisible(true);
          console.log("edit", v);
        },
      },
      {
        name: getSharkText("config_page_delete"),
        method: (v: Article) => {
          postArticleReq({
            ext: {
              article: v,
              type: 3,
            },
          });
        },
      },
    ];
    return genColumns(actions, listType);
  }, [listType, postArticleReq]);

  const headExtends = useMemo(() => {
    return (
      <>
        <Button
          style={{ marginRight: 20 }}
          onClick={() => setVisible(true)}
          type="primary"
        >
          {getSharkText("key.add.button")}
        </Button>
        <Button onClick={() => setTypeVisible(true)} type="primary">
          {getSharkText("config_page_type_edit")}
        </Button>
      </>
    );
  }, []);

  const close = useCallback(() => {
    curObjRef.current = undefined;
    setVisible(false);
  }, []);
  const closeType = useCallback(() => {
    setTypeVisible(false);
  }, []);

  const tableProps: TableProps<Article> = useMemo(() => {
    return {
      size: "small",
      pagination: { pageSize: 10, total: res?.page.total || 0 },
      onChange: (pagination) => {
        listFetch({
          ext: {
            keyword: "",
            typeId: null,
            page: {
              page: pagination.current,
              pageSize: pagination.pageSize,
            },
            orderSort: 0,
          },
        });
      },
    };
  }, [listFetch, res?.page.total]);

  return (
    <Page needToolBar={false} defaultDateMode="none">
      <Spin spinning={isLoading}>
        <div className="content-white" style={{ marginTop: 0 }}>
          <CustomTableV2
            dataSource={listArticle}
            columns={columns}
            searchKeys={["title", "summary"]}
            headExtends={headExtends}
            tableProps={tableProps}
            moduleCode={"report_manage"}
            chartTableCode={""}
          ></CustomTableV2>
        </div>
      </Spin>
      <Modal
        open={visible}
        onCancel={close}
        onOk={close}
        destroyOnClose
        footer={null}
        width={1040}
      >
        <ReportEditor
          article={curObjRef.current}
          articleTypes={listType}
          refreshArticles={refreshArticles}
        />
      </Modal>
      <Modal open={typeVisible} onCancel={closeType} onOk={closeType}>
        <TypeEditor
          articleTypes={listType || EMPTY_ARRAY}
          refreshTypes={refreshTypes}
        />
      </Modal>
    </Page>
  );
};
export default ReportManage;
