import React, { ReactElement, useEffect, useState } from "react";
import { AreaConfig, LEVEL } from "./interface";
import { Cascader } from "antd";
import useDimensionEnumValues from "../../useDimensionEnumValues";
import { AREAS_CONFIG, DATASET_ID } from "./cols";
import ubtUtils from "Utils/ubtUtils";
import useRefFunc from "Utils/useRefFunc";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { Area } from "Interface";
import { useDebounce } from "Utils/useDebounce";
import { EMPTY_ARRAY } from "Constants";
import { LoadingOutlined } from "@ant-design/icons";

interface Option {
  /** 给cascader组件用的value, 会添加层级前缀, 防止不同层级的key有重复导致组件异常 */
  value?: string | number | null;
  /** 选项code, 比如机场三字码/cityid等用于业务计算 */
  code?: string | number | null;
  label: React.ReactNode;
  name?: string;
  children?: Option[];
  level?: LEVEL;
  isLeaf?: boolean;
}

export interface IMytrixCitySelectorProps {
  levels?: LEVEL[];
  filters?: StandardFilter[];
  onChange?: (item: Area | null) => void;
}

/**
 * 基于Mytrix查询的城市选择器
 */
const MytrixCitySelectorCore = (
  props: IMytrixCitySelectorProps
): ReactElement => {
  const { levels = ["region", "province", "city"], filters = [] } = props;
  const [options, setOptions] = useState<Option[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchOptions, setSearchOptions] = useState<Option[]>([]);
  const [searching, setSearching] = useState<boolean>(false);
  const areasConfig: AreaConfig[] = levels.map((level) => AREAS_CONFIG[level]);
  const s = useDimensionEnumValues({
    datasetId: DATASET_ID,
  });
  useEffect(() => {
    s.getDimensionEnumValues(
      [areasConfig[0].code, areasConfig[0].name],
      filters,
      100
    ).then((res) => {
      ubtUtils.info("getDimensionEnumValues", res);
      if (res) {
        const tmpOptions: Option[] = res
          .filter((r) => !!r[areasConfig[0].code])
          .map((item) => ({
            value: item[areasConfig[0].code] as string,
            code: item[areasConfig[0].code] as string,
            label: item[areasConfig[0].name] as string,
            name: item[areasConfig[0].name] as string,
            level: levels[0],
            isLeaf: false,
          }));
        setOptions(tmpOptions);
      }
    });
  }, []);

  const reqChildOptions = useRefFunc((level: LEVEL, code: string) => {
    const levelIdx = levels.indexOf(level);
    const nextLevelConfig = areasConfig[levelIdx + 1];
    const cols = s.getCols();
    const parentCol = cols.find(
      (col) => col.name === areasConfig[levelIdx].code
    );
    const tmpFilters: StandardFilter[] = [
      {
        in: {
          field: `dimension.${areasConfig[levelIdx].code}`,
          values: parentCol?.jdbctype !== "VARCHAR" ? [Number(code)] : [code],
        },
      },
    ];
    return s.getDimensionEnumValues(
      [nextLevelConfig.code, nextLevelConfig.name],
      [...filters, ...tmpFilters],
      100
    );
  });

  const loadData = (selectedOptions: Option[]) => {
    if (searchOptions.length) {
      return;
    }
    const targetOption = selectedOptions[selectedOptions.length - 1];
    ubtUtils.info("loadData", targetOption);
    reqChildOptions(
      targetOption.level as LEVEL,
      targetOption.value as string
    ).then((res) => {
      ubtUtils.info("reqChildOptions", res);
      if (res && res.length) {
        const parentLevelIdx = levels.indexOf(targetOption.level as LEVEL);
        const childLevelIdx = parentLevelIdx + 1;
        targetOption.children = res
          .filter((r) => !!r[areasConfig[childLevelIdx].code])
          .map((item) => ({
            value: item[areasConfig[childLevelIdx].code] as string,
            code: item[areasConfig[childLevelIdx].code] as string,
            label: item[areasConfig[childLevelIdx].name] as string,
            name: item[areasConfig[childLevelIdx].name] as string,
            level: levels[childLevelIdx],
            isLeaf: childLevelIdx === levels.length - 1,
          }));
        setOptions([...options]);
      }
    });
  };

  const searchDebounce = useDebounce(() => {
    const value = searchValue;
    ubtUtils.info("searchDebounce", { searchValue });
    const cols = s.getCols();
    if (cols.length === 0 || searchValue === "") {
      setSearchOptions(EMPTY_ARRAY);
      setSearching(false);
      return;
    }
    const reqList = areasConfig.map((config) => {
      const codeCol = cols.find((col) => col.name === config.code);
      const nameCol = cols.find((col) => col.name === config.name);
      const tmpFilters: StandardFilter[] = [
        {
          or: {
            filters: [
              ...(codeCol?.jdbctype !== "VARCHAR"
                ? [
                    // 目前不能支持费字符串的过滤
                    // {
                    //   in: {
                    //     field: `dimension.${config.code}`,
                    //     values: [value],
                    //   },
                    // },
                  ]
                : [
                    {
                      wildcard: {
                        field: `dimension.${config.code}`,
                        value: `*${value}*`,
                      },
                    },
                  ]),
              ...(nameCol?.jdbctype !== "VARCHAR"
                ? [
                    // {
                    //   in: {
                    //     field: `dimension.${config.name}`,
                    //     values: [value],
                    //   },
                    // },
                  ]
                : [
                    {
                      wildcard: {
                        field: `dimension.${config.name}`,
                        value: `*${value}*`,
                      },
                    },
                  ]),
            ],
          },
        },
      ];
      return s.getDimensionEnumValues(
        [config.code, config.name],
        [...filters, ...tmpFilters],
        20
      );
    });
    Promise.all(reqList)
      .then((resList) => {
        ubtUtils.info("handleSearch", { resList });
        const tmpOptions: Option[][] = resList.map((res, idx) => {
          const config = areasConfig[idx];
          return (
            res
              ?.filter((r) => !!r[config.code])
              .map((item) => ({
                value: (levels[idx] + item[config.code]) as string,
                code: item[config.code] as string,
                label: `${item[config.name] as string}(${levels[idx]})`,
                name: item[config.name] as string,
                level: levels[idx],
                isLeaf: true,
              })) || []
          );
        });
        setSearchOptions(tmpOptions.flat());
      })
      .finally(() => setSearching(false));
  }, 500);

  useEffect(() => {
    if (searchValue) {
      setSearching(true);
      searchDebounce();
    } else {
      setSearchOptions(EMPTY_ARRAY);
    }
  }, [searchDebounce, searchValue]);

  const handleSearch = useRefFunc((value: string) => {
    setSearchValue(value);
  });

  const onChange = useRefFunc((values, items: Option[]) => {
    ubtUtils.info("onChange", { values, items });
    if (items.length === 0 && props.onChange) {
      props.onChange(null);
      return;
    }
    const lastItem = items.pop();
    const levelIdx = levels.indexOf(lastItem?.level as LEVEL);
    const selectedItem: Area | null = !lastItem
      ? null
      : {
          areaCode: lastItem?.code?.toString() || "",
          areaName: lastItem.name as string,
          areaType: lastItem.level ? areasConfig[levelIdx].typeNum : 0,
          pinyin: "",
          areaNameEn: "",
        };
    if (props.onChange) {
      props.onChange(selectedItem);
    }
  });

  const displayRender = (labels: string[]) => (
    <span>{labels[labels.length - 1]}</span>
  );

  return (
    <Cascader
      options={searchOptions.length ? searchOptions : options}
      loadData={loadData}
      showSearch
      onSearch={handleSearch}
      onChange={onChange}
      expandTrigger="hover"
      changeOnSelect
      displayRender={displayRender}
      onClear={() => {
        ubtUtils.info("onClear");
        setSearchValue("");
      }}
      dropdownRender={
        searching
          ? () => <LoadingOutlined style={{ padding: "0 20px" }} />
          : undefined
      }
      style={{ minWidth: 120 }}
    ></Cascader>
  );
};
export default MytrixCitySelectorCore;
