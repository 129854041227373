import { EMPTY_ARRAY } from "Constants";
import { Area } from "Interface";
import { UserProfileTag } from "@ctrip/flt-bi-flightai-airlines";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";

const SET_ROUTE_LIST = "SET_ROUTE_LIST";
const SET_AREA_SOURCE = "SET_AREA_SOURCE";

export interface IUserProfileState {
  tagList: UserProfileTag[];
  areaSource: Area[];
}

export interface IStateAction {
  setTagList: (v: UserProfileTag[]) => void;
  setAreaSource: (v: Area[]) => void;
}

const initialState: IUserProfileState = {
  tagList: [],
  areaSource: [],
};

const UserProfileContext = createContext<any>(null);

const globalStateReducer = (
  state: IUserProfileState,
  action: any
): IUserProfileState => {
  switch (action.type) {
    case SET_ROUTE_LIST:
      return {
        ...state,
        tagList: action.payload,
      };
    case SET_AREA_SOURCE:
      return {
        ...state,
        areaSource: action.payload,
      };
    default:
      return state;
  }
};

const usePageContext = (): [IUserProfileState, IStateAction] => {
  const [state, dispatch] = useContext(UserProfileContext);

  const setTagList = useCallback(
    (v: UserProfileTag[]) => {
      dispatch({
        type: SET_ROUTE_LIST,
        payload: v || EMPTY_ARRAY,
      });
    },
    [dispatch]
  );
  const setAreaSource = useCallback(
    (v: Area[]) => {
      dispatch({
        type: SET_AREA_SOURCE,
        payload: v || EMPTY_ARRAY,
      });
    },
    [dispatch]
  );

  const setState: IStateAction = useMemo(
    () => ({
      setTagList,
      setAreaSource,
    }),
    [setTagList, setAreaSource]
  );

  return [state, setState];
};

export default usePageContext;

export const UserProfileProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  return (
    <UserProfileContext.Provider value={[state, dispatch]}>
      {children}
    </UserProfileContext.Provider>
  );
};
