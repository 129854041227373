import { Button, Checkbox, Col, Input, Row, Tooltip } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ALERT_NAMES,
  ALERT_TYPE,
  DEFAULT_FILTER,
  IFilter,
} from "./NotificationCenterCommon";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import moment, { Moment } from "moment";
import { EMPTY_ARRAY } from "Constants";
import { useBatchEffect } from "Utils/useBatchEffect";
import { RangeObject } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

export interface IFilterProps {
  mainType: 1 | 2;
  startDate?: string;
  endDate?: string;
  setFilterVal: (v: IFilter) => void;
}

/**
 * 通知中心过滤筛选
 */
const Filter = (props: IFilterProps): ReactElement => {
  const { setFilterVal, mainType: type, startDate, endDate } = props;
  // 筛选变量
  const [filterType, setFilterType] = useState<number[]>(EMPTY_ARRAY);
  const [dateRange, setDateRange] = useState<RangeObject>(null);
  const [route, setRoute] = useState<string>("");
  const [flightNO, setFlightNO] = useState<string[]>(EMPTY_ARRAY);

  const otherProps = useMemo(
    () => ({
      getPopupContainer: (t: any) => t.parentElement || document.body,
    }),
    []
  );

  /**
   * 重置过滤器
   */
  const resetFilter = useCallback(() => {
    setFilterType(ALERT_TYPE[type]);
    setDateRange(null);
    setFlightNO(EMPTY_ARRAY);
    setRoute("");
    setFilterVal(DEFAULT_FILTER);
  }, [setFilterVal, type]);

  /**
   * 切换type时, 重置过滤器
   */
  useEffect(() => {
    resetFilter();
  }, [resetFilter, type]);

  /**
   * 修改了任意值后, 如果日期为空, 则自动填充日期选择器的值
   */
  useBatchEffect(() => {
    console.log(
      "batch effect: ",
      dateRange,
      startDate,
      endDate,
      filterType,
      route,
      flightNO.length,
      type
    );
    if (
      (filterType !== ALERT_TYPE[type] || route || flightNO.length) &&
      (!dateRange || dateRange.filter((d) => !d).length === 0)
    ) {
      const sd = startDate ? moment(startDate) : moment();
      const ed = endDate ? moment(endDate) : moment().add(15, "days");
      setDateRange([sd, ed]);
    }
  }, [dateRange, startDate, endDate, filterType, route, flightNO.length, type]);

  const handleSearch = useCallback(() => {
    const tmpRange =
      dateRange && (dateRange.filter((d) => d != null) as Moment[]);
    if (
      (filterType || route.length || flightNO.length) &&
      tmpRange &&
      tmpRange.length
    ) {
      console.log("filter.....");
      setFilterVal({
        filterType,
        flightNO,
        route: route.split(","),
        dateRange: tmpRange,
        isFilterMode: true,
      });
    } else {
      setFilterVal(DEFAULT_FILTER);
    }
  }, [dateRange, filterType, flightNO, route, setFilterVal]);

  const options = useMemo(() => {
    return ALERT_TYPE[type].map((v) => ({
      label: ALERT_NAMES[v],
      value: v,
    }));
  }, [type]);

  return (
    <Row>
      <Col span={20}>
        {type && (
          <div style={{ marginBottom: 10 }}>
            <span style={{ width: 80, display: "inline-block" }}>
              {getSharkText("config_page_category")}：
            </span>
            <Checkbox.Group
              value={filterType}
              options={options}
              onChange={(e) => setFilterType(e as number[])}
            ></Checkbox.Group>
          </div>
        )}
        <div>
          <span style={{ width: 80, display: "inline-block" }}>
            {getSharkText("charts.date_of_departure")}：
          </span>
          <DateRangePickerV2
            defaultDateMode="none"
            onChange={setDateRange}
            value={dateRange}
            otherProps={otherProps}
          />
        </div>
        <Row style={{ marginTop: 10 }}>
          <Col span={11}>
            <Tooltip
              title={getSharkText(
                "config_page_filter_search_warning_and_capacity_warning"
              )}
              getPopupContainer={(t) => t.parentElement || document.body}
            >
              <span>{getSharkText("key.route.name")}：</span>
              <Input
                style={{ width: 150 }}
                value={route}
                onChange={(e) => setRoute(e.target.value.toLocaleUpperCase())}
                placeholder="SHAPEK,XIYPVG..."
              />
            </Tooltip>
          </Col>
          <Col span={12}>
            <SelectMultiFlight
              flightNO={flightNO}
              setFlightNO={setFlightNO}
              isDemo={false}
              routes=""
              width={150}
              autoSelectFirst={false}
              getPopupContainer={(t) => t.parentElement || document.body}
              mode="multiple"
            />
          </Col>
        </Row>
      </Col>
      <Col
        span={4}
        style={{ textAlign: "center", justifyContent: "space-around" }}
      >
        <Button
          style={{ width: 60, height: 60, whiteSpace: "break-spaces" }}
          onClick={resetFilter}
        >
          {getSharkText("config_page_clear_filter")}
        </Button>
        <Button type="primary" onClick={handleSearch} style={{ marginTop: 20 }}>
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default Filter;
