import Page from "Layout/Page";
import React, { ReactElement, useMemo, useState } from "react";
import TopSearch from "./Components/TopSearch";
import Panel from "./Components/Panel";
import RGL, { WidthProvider } from "react-grid-layout";
import useRefFunc from "Utils/useRefFunc";
import "./index.css";
import { Button } from "antd";
import {
  yoyColumns,
  yoyColumns2,
  yoyColumns3,
  yoyColumns4,
  yoySeries,
  yoySeries2,
  yoySeries3,
  yoySeries3extra,
  yoySeries4,
} from "./PanelConfig";
import { DPMonitorFilter } from "@ctrip/flt-bi-flightai-airlines";
import { AggCode } from "Interface";
import { EQueryType } from "./common";
import PanelFilterCommon from "./Components/PanelFilterCommon";
import { getSharkText } from "Utils/i18nGlobal";

const ReactGridLayout = WidthProvider(RGL);

const STORAGE_KEY = "priceAlertLayouts";
const storageLayouts = localStorage.getItem(STORAGE_KEY);
const defaultLayouts = storageLayouts
  ? JSON.parse(storageLayouts)
  : [
      { i: "0", x: 0, y: 0, w: 12, h: 6 },
      { i: "1", x: 0, y: 6, w: 12, h: 6 },
      { i: "2", x: 0, y: 12, w: 12, h: 6 },
      { i: "3", x: 0, y: 18, w: 12, h: 6 },
    ];
const defaultFilter: DPMonitorFilter = {
  monitoringRange: null,
  monitoringCompareRange: null,
  takeoffRange: null,
  takeoffCompareRange: null,
  granularity: AggCode.halfHour,
  queryType: EQueryType.Airline,
  airlines: "",
  routes: [],
  flights: [],
  isCompareRoute: false,
  isCompareFlight: false,
  mode: [],
};

const moduleCode = "monitor_suggestion";

/**
 * 调价建议监控面板
 */
const MonitoringSuggestion = (): ReactElement => {
  const [layouts, setLayouts] = useState<any>(defaultLayouts);
  const [queryExt, setQueryExt] = useState<DPMonitorFilter>(defaultFilter);
  const [draggable, setDraggable] = useState<boolean>(false);

  const handleLayoutChange = useRefFunc((v: any) => {
    setLayouts(v);
    // localStorage.setItem(STORAGE_KEY, JSON.stringify(v));
  });
  const contents = useMemo(() => {
    return [
      <div key="0" style={{ overflow: "scroll" }}>
        <Panel
          title={getSharkText("config_page_yoy_missing_rate_monitoring")}
          type={"1"}
          filter={queryExt}
          baseSeries={yoySeries}
          tableColumns={yoyColumns}
          panelFilterComponent={[
            (ref, onChange) => (
              <PanelFilterCommon key={"0"} ref={ref} onChange={onChange} />
            ),
          ]}
          moduleCode={moduleCode}
          chartTableCode={"yoy_missing_rate"}
        />
      </div>,
      <div key="1" style={{ overflow: "scroll" }}>
        <Panel
          title={getSharkText("config_page_suggested_ratio_monitoring")}
          type={"2"}
          filter={queryExt}
          baseSeries={yoySeries2}
          tableColumns={yoyColumns2}
          panelFilterComponent={[
            (ref, onChange) => (
              <PanelFilterCommon key={"0"} ref={ref} onChange={onChange} />
            ),
          ]}
          moduleCode={moduleCode}
          chartTableCode={"suggested_ratio"}
        />
      </div>,
      <div key="2" style={{ overflow: "scroll" }}>
        <Panel
          title={getSharkText(
            "config_page_price_change_suggestion_process_monitoring"
          )}
          type={"3"}
          filter={queryExt}
          baseSeries={yoySeries3}
          tableColumns={yoyColumns3}
          extraSeries={yoySeries3extra}
          moduleCode={moduleCode}
          chartTableCode={"price_change_suggestion_process"}
        />
      </div>,
      <div key="3" style={{ overflow: "scroll" }}>
        <Panel
          title={getSharkText(
            "config_page_PaAiMoIn_small_cabin_setting_monitoring"
          )}
          type={"4"}
          filter={queryExt}
          baseSeries={yoySeries4}
          tableColumns={yoyColumns4}
          moduleCode={moduleCode}
          chartTableCode={"small_cabin_setting"}
        />
      </div>,
    ];
    // RGL属性依赖于dom结构, 属性更新时必须要更新DOM节点才能生效
  }, [draggable, queryExt]);
  return (
    <Page needToolBar={false} defaultDateMode="none">
      <div className="content-white">
        <TopSearch onChange={setQueryExt} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: 20,
          paddingRight: 20,
        }}
      >
        <Button onClick={() => setDraggable((s) => !s)}>{`${
          draggable
            ? getSharkText("config_page_exit")
            : getSharkText("config_page_free")
        }${getSharkText("config_page_drag")}`}</Button>
      </div>
      <div style={{ margin: 20, padding: 5 }}>
        <ReactGridLayout
          layout={layouts}
          rowHeight={50}
          isResizable={draggable}
          isDraggable={draggable}
          cols={12}
          onLayoutChange={handleLayoutChange}
        >
          {contents}
        </ReactGridLayout>
      </div>
    </Page>
  );
};
export default MonitoringSuggestion;
