export const MODULE_CODE: any = {
  1: "airline_analysis_airport",
  2: "airline_analysis_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    map: "target_airport_lf",
    count: "target_count",
    card: "target_card",
    barLineCharts: "target_card_compare",
    table: "target_table",
  },
  2: {
    map: "AL0201",
    count: "AL0202",
    card: "AL0203",
    barLineCharts: "AL0204",
    tableLastYear: "AL0206",
    tableMarket: "AL0207",
  },
};

export const QUERY_URL: any = {
  1: {
    map: "queryAirlineAnalysisMap",
    count: "queryAirlineAnalysisCount",
    card: "queryAirlineAnalysisCard",
    barLineCharts: "queryAirlineAnalysisBarLine",
    top10: "queryAirlineAnalysisTop10City",
    table: "queryAirlineAnalysisTable",
  },
  2: {
    map: "queryAirlinesAirlineAnalysisMap",
    count: "queryAirlinesAirlineAnalysisCount",
    card: "queryAirlinesAirlineAnalysisCard",
    top10: "queryAirlinesAirlineAnalysisTop10City",
    barLineCharts: "queryAirlinesAirlineAnalysisBarLine",
    table: "queryAirlinesAirlineAnalysisTable",
    barLineDownload: "queryAirlinesAirlineAnalysisBarLineDownload",
  },
};
