import React, { useEffect } from "react";
import { Badge, Descriptions, Spin, Typography } from "antd";
import { isAirportMode } from "Utils";
import useGlobal from "Store";
import { AirlinesQueryExt, Area } from "Interface";
import "./index.css";
import { getServer } from "Service/server";
import { useFetch } from "Utils/useFetch";

const { Text } = Typography;

interface CountProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  area: Area;
  airlinesQueryExt: AirlinesQueryExt;
}

const Count: React.FC<CountProps> = (props: CountProps) => {
  const { queryUrl, moduleCode, chartTableCode, area, airlinesQueryExt } =
    props;
  const [globalState] = useGlobal();
  const { queryCondition, airlinesQueryCondition, systemType } = globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const ext = isAirport ? { area } : { filter: { ...airlinesQueryExt } };
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({ query, ext: { ...ext } });
  };

  useEffect(() => {
    refetch();
  }, [area, query, airlinesQueryExt]);

  if (error) {
    return null;
  }

  if (!data) {
    return null;
  }
  return (
    <Spin spinning={isLoading}>
      <Descriptions column={2} style={{ marginBottom: -16 }}>
        {data.map((item: any, index: number) => (
          <Descriptions.Item key={index} className="count-item">
            <Badge color="#108ee9" text={item.countName} />
            &nbsp;&nbsp;<Text strong>{item.countValue}</Text>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Spin>
  );
};

export default Count;
