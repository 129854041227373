import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AIRLINE_MARK,
  ARRIVAL_AREA,
  ARR_AREA_NAME,
  AVG_PRICE,
  DATASET_ID,
  DEPART_AREA,
  DEP_AREA_NAME,
  ORIGIN,
  ORI_AREA_NAME,
  PAX_MASKING,
  TAKEOFF_MONTH,
  TRANSFER_POINT,
  TRANSIT_CLASS,
} from "./behindCols";
import { showNum, useFetch } from "Utils";
import { Button, Table, message } from "antd";
import {
  CompareMean,
  FilterKeyReplacement,
  StandardFilter,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { groupBy } from "lodash";
import { IDownloadHeader } from "Interface";
import { BehindParam, BehindTableResData } from "./interface";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import useRefFunc from "Utils/useRefFunc";
import { downloadExcel } from "Utils/downloadXLSX";

export interface BeyondTableProps {
  param: BehindParam;
  cols: FDDatasetCol[];
}

/**  Component description */
const BehindTable = (props: BeyondTableProps): ReactElement => {
  const { param, cols } = props;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [listData, setListData] = useState<BehindTableResData[]>([]);

  const [resData, setResData] = useState<BehindTableResData[]>([]);

  // #region 表格查询
  const [{ error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(
        res.rows,
        res.headers
      ) as unknown as BehindTableResData[];
      console.log("map: ", map);
      setListData(map);
      const groups = groupBy(map, (item) => item.transit_class);
      console.log("groups: ", groups);
      const rst: BehindTableResData[] = [];
      Object.keys(groups).reduce((t, c) => {
        const items = groups[c];
        const parentRow: BehindTableResData = {
          origin: `${c} beyond point(${items.length})`,
          arrival_area: "",
          depart_area: "",
          transfer_point: "",
          transit_class: items[0].transit_class,
          SUM_pax_masking: items[0].com_abs_SUM_pax_masking ?? null,
          m_exp_1ee3e: items[0].com_abs_m_exp_1ee3e ?? null,
          key: `${items[0].depart_area}-${items[0].arrival_area}-${items[0].origin}-${items[0].transfer_point}-${items[0].transit_class}`,
          details: items,
        };
        t.push(parentRow);
        return t;
      }, rst);
      console.log("rst: ", rst);
      setResData(rst);
    },
    onFinish: () => {
      setIsLoadingData(false);
    },
  });

  const oriFilters = useMemo(() => {
    if (!param.dateRange || param.dateRange.length !== 2) {
      return [];
    }
    const tmpFilters: StandardFilter[] = [
      {
        wildcard: {
          field: `dimension.${AIRLINE_MARK}`,
          value: `*${param.airline}*`,
        },
      },
      {
        range: {
          field: `dimension.${TAKEOFF_MONTH}`,
          strRange: {
            lower: param.dateRange[0]?.format("YYYY-MM") || "",
            upper: param.dateRange[1]?.format("YYYY-MM") || "",
          },
        },
      },
    ];
    if (param.departArea?.areaCode) {
      tmpFilters.push({
        in: {
          field: `dimension.${DEP_AREA_NAME[param.departArea.areaType]}`,
          values: [param.departArea?.areaCode || ""],
        },
      });
    }
    if (param.arriveArea?.areaCode) {
      tmpFilters.push({
        in: {
          field: `dimension.${ARR_AREA_NAME[param.arriveArea.areaType]}`,
          values: [param.arriveArea?.areaCode || ""],
        },
      });
    }
    if (param.originArea?.areaCode) {
      tmpFilters.push({
        in: {
          field: `dimension.${ORI_AREA_NAME[param.originArea.areaType]}`,
          values: [param.originArea?.areaCode || ""],
        },
      });
    }
    return tmpFilters;
  }, [param]);

  const builder = useMemo(() => {
    if (!cols.length || !oriFilters.length) {
      return null;
    }
    const filterReplace: FilterKeyReplacement[] = [
      // {
      //   key: ORIGIN,
      //   op: "DISABLE",
      // },
      // {
      //   key: DEPART_AREA,
      //   op: "DISABLE",
      // },
      // {
      //   key: ARRIVAL_AREA,
      //   op: "DISABLE",
      // },
    ];
    const compareFilters = filterReplace.map((f) => JSON.stringify(f));
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns: cols,
      dimensions: [
        DEPART_AREA,
        ARRIVAL_AREA,
        ORIGIN,
        TRANSIT_CLASS,
        TRANSFER_POINT,
      ],
      measures: [
        PAX_MASKING,
        {
          id: AVG_PRICE,
          columnName: AVG_PRICE,
          measureConfig: {
            formatConfig: { precision: 2 },
            statisticalConfig: { method: "AVG" },
          },
        },
      ],
      chartFilters: [],
      containerFilters: [],
      oriFilters,
      comparators: [
        {
          name: "filterKey",
          comparisonName: "com",
          means: [CompareMean.abs],
          args: [
            [ORIGIN, DEPART_AREA, ARRIVAL_AREA, TRANSFER_POINT].join(),
            ...compareFilters,
          ],
        },
      ],
      sorters: [PAX_MASKING],
    });
  }, [cols, oriFilters]);

  const { requestBody, encrypted } = useMemo(() => {
    return builder
      ? builder.getRequestBody()
      : { requestBody: null, encrypted: "" };
  }, [builder]);

  const lastParam = useRef<string>();

  const refetch = useCallback(() => {
    setIsLoadingData(true);
    setListData([]);
    setResData([]);
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetch, encrypted]);

  useEffect(() => {
    if (encrypted && encrypted !== lastParam.current) {
      refetch();
      lastParam.current = encrypted;
    }
  }, [encrypted, refetch]);

  const columns = useMemo(() => {
    const tmp: Array<IDownloadHeader<BehindTableResData>> = [
      {
        dataIndex: ORIGIN,
        title: "Origin",
      },
      {
        dataIndex: TRANSFER_POINT,
        title: "Transfer points",
      },
      {
        dataIndex: DEPART_AREA,
        title: "Departure area",
      },
      {
        dataIndex: ARRIVAL_AREA,
        title: "Arrival area",
      },
      {
        dataIndex: "SUM_pax_masking",
        title: "Passengers volume(est)",
        render: (v) => showNum(v),
      },
      // {
      //   dataIndex: "m_exp_1ee3e",
      //   title: "Avg.price",
      //   render: (v) => showNum(v),
      // },
    ];
    return tmp;
  }, []);

  const handleDownload = useRefFunc(() => {
    downloadExcel(columns, listData, "Detailed Data");
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3>Detailed Data</h3>
        </div>
        <div>
          <Button onClick={handleDownload} type="primary">
            Download
          </Button>
        </div>
      </div>
      <Table
        loading={isLoadingData}
        dataSource={resData}
        columns={columns}
        tableLayout="fixed"
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={columns}
              tableLayout="fixed"
              dataSource={record.details}
              size="small"
              pagination={{ pageSize: 10 }}
              style={{ paddingLeft: 56 }}
            ></Table>
          ),
          rowExpandable: () => true,
        }}
        size="small"
      />
    </div>
  );
};
export default BehindTable;
