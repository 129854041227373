import { Area, FlightSegmentFilter } from "@ctrip/flt-bi-flightai-airlines";
import { Button, Checkbox, Col, message, Row } from "antd";
import CheckButtons from "Components/CheckButtons/CheckButtons";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import SelectRT from "Components/SelectRT/SelectRT";
import Airlines from "Components/Toolbar/Airlines";
import { DATE_FORMAT, DEFAULT_FLIGHT_AREA } from "Constants";
import { FlightArea, FlightClass, IDateMode, RangeObject } from "Interface";
import { cloneDeep } from "lodash";
import { Moment } from "moment";
import React, { Key, ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import { getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { G } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";

export interface ISearchAreaProps {
  onChange: (v: FlightSegmentFilter) => void;
}

const scheduleOptions = G.formatKeyOptions(
  Array.from({ length: 7 }, (v, i) => i + 1)
);

const defaultDateMode: IDateMode = "lastDay";

/**
 * 航段数据-筛选器
 */
const SearchArea = (props: ISearchAreaProps): ReactElement => {
  const { onChange } = props;
  const [, actions] = useGlobalState();
  const { setAirlinesQueryConditionAttr } = actions;
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange(defaultDateMode)
  );
  const [schedule, setSchedule] = useState<Key[]>([1, 2, 3, 4, 5, 6, 7]);
  const [flightArea, setFlightArea] = useState<FlightArea>(
    cloneDeep(DEFAULT_FLIGHT_AREA)
  );
  const [rt, setRt] = useState<boolean>(false);
  const [splitSchedule, setSplitSchedule] = useState<boolean>(false);
  const [splitSegment, setSplitSegment] = useState<boolean>(false);

  const handleChangeDates = useRefFunc((v: Moment[]) => {
    if (v.length !== 2) return;
    const startDate = v[0].format(DATE_FORMAT);
    const endDate = v[1].format(DATE_FORMAT);
    setAirlinesQueryConditionAttr({
      startDate,
      endDate,
    });
  });

  const handleSearch = useRefFunc(() => {
    const tmpDates = dateRange?.filter((d) => d != null) as Moment[];
    if (!tmpDates || tmpDates.length < 2) {
      message.error("日期选择有误");
      return;
    }
    handleChangeDates(tmpDates);
    const filter: FlightSegmentFilter = {
      rt: rt ? 1 : 0,
      splitSchedule: splitSchedule ? 1 : 0,
      splitSegment: splitSegment ? 1 : 0,
      departArea: flightArea.departArea as Area,
      arriveArea: flightArea.arriveArea as Area,
      schedule: schedule as number[],
    };
    onChange(filter);
  });

  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <Row
      align="middle"
      gutter={[10, 20]}
      style={{ marginTop: 0, padding: "15px 0px" }}
    >
      <Col>
        <div style={{ display: "none" }}>
          <Airlines />
        </div>
      </Col>
      <Col>
        <DateRangePickerV2
          defaultDateMode={defaultDateMode}
          onChange={setDateRange}
          disableFuture
        />
      </Col>
      <Col>
        <CheckButtons
          options={scheduleOptions}
          value={schedule}
          onChange={setSchedule}
        />
      </Col>
      <Col>
        <SelectRT
          onChange={setFlightArea}
          useDomesticArea
          useExchange
          flightClass={FlightClass.Domestic}
        />
      </Col>
      <Col>
        <Checkbox value={rt} onChange={(e) => setRt(e.target.checked)}>
          {getSharkText("config_page_round_trip_total")}
        </Checkbox>
      </Col>
      <Col>
        <Checkbox
          value={splitSchedule}
          onChange={(e) => setSplitSchedule(e.target.checked)}
        >
          {getSharkText("config_page_split_schedule")}
        </Checkbox>
      </Col>
      <Col>
        <Checkbox
          value={splitSegment}
          onChange={(e) => setSplitSegment(e.target.checked)}
        >
          {getSharkText("config_page_split_segment")}
        </Checkbox>
      </Col>
      <Col>
        <Button onClick={handleSearch} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default SearchArea;
