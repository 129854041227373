import { IDownloadHeader } from "Interface";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

// #region 文本
const ORDER_BEFORE = getSharkText("custom_profile.page.orderday_before");
const CAP = getSharkText("config_page_layout");
const DAILY_SOLD = getSharkText("config_page_daily_sold");
const DAILY_DETAILS = getSharkText("config_page_daily_details");
const SOLD_SUMMARY = getSharkText("config_page_sold_summary");
const SUMMARY_DETAILS = getSharkText("config_page_summary_details");
// #endregion

const columns: IDownloadHeader[] = [
  {
    title: ORDER_BEFORE,
    dataIndex: "aheadday",
    render: (v: string, r: any): string => {
      const hour = `/ ${r.show_hour}`;
      return `D${v}${hour}`;
    },
  },
  {
    title: CAP,
    dataIndex: "SUM_cap",
  },
  {
    title: DAILY_SOLD,
    dataIndex: "daySold",
  },
  {
    title: DAILY_DETAILS,
    dataIndex: "changeDetail",
  },
  {
    title: SOLD_SUMMARY,
    dataIndex: "SUM_saledseat_before",
    render: (v: string, r: any): string => {
      return `${r.SUM_saledseat_before}/${showNum(
        r.SUM_lf_before * 100,
        "percentage"
      )}`;
    },
  },
  {
    title: SUMMARY_DETAILS,
    dataIndex: "subcabinbkddetail",
  },
];

export default columns;
