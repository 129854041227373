import React from "react";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Card, Divider, Popover, Statistic, Typography } from "antd";
import { CompareCardItemData } from "Interface";
import { PERCENT_VALUES } from "Constants";
import ValueNum from "Components/ValueNum";
import { calculateCardsContrastVal } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";

interface CompareCardItemIProps {
  data: CompareCardItemData[];
  curCardCode: string;
  setCurCardCode: (code: string) => void;
}

const { Text } = Typography;

const CompareCardItem: React.FC<CompareCardItemIProps> = (
  props: CompareCardItemIProps
) => {
  const { data, curCardCode, setCurCardCode } = props;

  const isPercentVal = _.indexOf(PERCENT_VALUES, data[0].cardCode) > -1;
  const airportData = data.find((item) => item.cardType === 0);
  const compareData = data.find((item) => item.cardType === 1);
  const airportYoY = airportData
    ? calculateCardsContrastVal(
        airportData.cardValue,
        airportData.compareValue,
        1,
        1,
        airportData.cardCode
      )
    : 0;
  const compareYoY = compareData
    ? calculateCardsContrastVal(
        compareData.cardValue,
        compareData.compareValue,
        1,
        1,
        compareData.cardCode
      )
    : 0;

  const getBarNum = (num: number, compareNum: number) => {
    if (num > compareNum) {
      return {
        num: 100,
        compareNum: (compareNum / num) * 100,
      };
    } else if (num < compareNum) {
      return {
        num: (num / compareNum) * 100,
        compareNum: 100,
      };
    } else {
      return {
        num: 100,
        compareNum: 100,
      };
    }
  };

  const barNumObj =
    airportData && compareData
      ? getBarNum(airportData.cardValue, compareData.cardValue)
      : { num: 100, compareNum: 100 };

  const handleSelectCard = () => {
    setCurCardCode(data[0].cardCode);
  };

  return (
    <Card
      id={data[0].cardCode}
      size="small"
      bordered={false}
      hoverable={true}
      className={`compare-card ${
        curCardCode === data[0].cardCode ? "cur" : ""
      }`}
      onClick={handleSelectCard}
    >
      <div className="compare-card-title">
        <div className="compare-title">{data[0].cardName}</div>
        <Popover content={<span>{data[0].cardHint}</span>}>
          <InfoCircleOutlined />
        </Popover>
      </div>
      <div className="compare-card-con">
        <div className="compare-l">
          <div className="compare-num">
            {airportData && airportData.cardValue ? (
              <Statistic
                valueStyle={{ fontSize: 20 }}
                groupSeparator=","
                precision={0}
                suffix={isPercentVal ? "%" : ""}
                value={airportData.cardValue}
              />
            ) : (
              <div className="ant-statistic-content" style={{ fontSize: 20 }}>
                <span className="ant-statistic-content-value">
                  <span className="ant-statistic-content-value-int">-</span>
                </span>
              </div>
            )}
            <i
              className="compare-bar"
              style={{ height: barNumObj.num + "%" }}
            ></i>
          </div>
        </div>
        <div className="compare-r">
          <div className="compare-num">
            {compareData && compareData.cardValue ? (
              <Statistic
                valueStyle={{ fontSize: 20 }}
                precision={0}
                groupSeparator=","
                suffix={isPercentVal ? "%" : ""}
                value={compareData.cardValue}
              />
            ) : (
              <div className="ant-statistic-content" style={{ fontSize: 20 }}>
                <span className="ant-statistic-content-value">
                  <span className="ant-statistic-content-value-int">-</span>
                </span>
              </div>
            )}
            <i
              className="compare-bar"
              style={{ height: barNumObj.compareNum + "%" }}
            ></i>
          </div>
        </div>
      </div>
      <Divider style={{ margin: "5px 0" }} />
      <div className="compare-card-footer">
        <div>
          <Text type="secondary">{getSharkText("key.yoy.button")}</Text>
          {airportYoY === 0 ||
          !_.isFinite(airportYoY) ? undefined : airportYoY > 0 ? (
            <CaretUpOutlined style={{ color: "#f5222d" }} />
          ) : (
            <CaretDownOutlined style={{ color: "#52c41a" }} />
          )}
          <ValueNum type={"percentage"} value={airportYoY} />
        </div>
        <div>
          <Text type="secondary">{getSharkText("key.yoy.button")}</Text>
          {compareYoY === 0 ||
          !_.isFinite(compareYoY) ? undefined : compareYoY > 0 ? (
            <CaretUpOutlined style={{ color: "#f5222d" }} />
          ) : (
            <CaretDownOutlined style={{ color: "#52c41a" }} />
          )}
          <ValueNum type={"percentage"} value={compareYoY} />
        </div>
      </div>
    </Card>
  );
};

export default CompareCardItem;
