import { Button, Checkbox, Col, message, Row } from "antd";
import SelectedAirlines from "Components/SelectedAirlines";
import { AirlinesQueryExt } from "Interface";
import React, { ReactElement, useMemo, useState } from "react";
import { G } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { Index, IndexList } from "../TouristConstant";
import { TouristQueryExt } from "../TouristInterface";
import { getSharkText } from "Utils/i18nGlobal";

export interface IAirlinesSelectAreaProps {
  value: TouristQueryExt;
  onChange: (v: TouristQueryExt) => void;
}

/**
 * 客座预测航司版选择器
 */
const AirlinesSelectArea = (props: IAirlinesSelectAreaProps): ReactElement => {
  const { value, onChange } = props;
  const [airlinesQueryExt, setAirlinesQueryExt] =
    useState<AirlinesQueryExt>(value);
  const [indexes, setIndexes] = useState<Index[]>(value.indexes);

  const options = useMemo(() => {
    return G.enumValues(Index).map((v: Index) => {
      return {
        label: IndexList[v],
        value: v,
      };
    });
  }, []);

  const handleIndexesChange = useRefFunc((v: Index[]) => {
    if (v.length === 0) {
      message.error(getSharkText("config_page_at_least_one_item"));
    } else {
      setIndexes(v);
    }
  });

  const handleSubmit = useRefFunc(() => {
    console.log("submit");
    onChange({
      ...airlinesQueryExt,
      indexes,
    });
  });
  return (
    <div>
      <Row>
        <Col>
          <SelectedAirlines
            airlinesQueryExt={airlinesQueryExt}
            setAirlinesQueryExt={setAirlinesQueryExt}
            useDomesticArea
            submitControl={false}
            useExchange
          />
        </Col>
        <Col>
          <div style={{ marginTop: 16, paddingTop: 5, marginLeft: 16 }}>
            <Checkbox.Group
              value={indexes}
              onChange={(v) => handleIndexesChange(v as Index[])}
              options={options}
            ></Checkbox.Group>
          </div>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handleSubmit}
            style={{ marginTop: 16 }}
          >
            {getSharkText("key.query.button")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default AirlinesSelectArea;
