import { WorkBook, WorkSheet, WritingOptions } from "xlsx";
// @ts-ignore
import * as XLSX from "xlsx/xlsx.js";
export { XLSX };

export const sheet2blob = (sheet: WorkSheet, sheetName?: string): Blob => {
  // 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
  sheetName = sheetName || "sheet1";
  const workbook: WorkBook = {
    SheetNames: [sheetName],
    Sheets: {},
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  const wOpts: WritingOptions = {
    // 要生成的文件类型
    bookType: "xlsx",
    // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    bookSST: false,
    type: "binary",
  };
  // 字符串转ArrayBuffer
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const wbOut = XLSX.write(workbook, wOpts);
  const blob = new Blob([s2ab(wbOut)], { type: "application/octet-stream" });
  return blob;
};

// 将workbook装化成blob对象
export const workbook2blob = (workbook: WorkBook): Blob => {
  // 生成excel的配置项
  const wOpts: WritingOptions = {
    // 要生成的文件类型
    bookType: "xlsx",
    // // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    bookSST: false,
    type: "binary",
  };
  const wbOut = XLSX.write(workbook, wOpts);
  // 将字符串转ArrayBuffer
  const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };
  const blob = new Blob([s2ab(wbOut)], {
    type: "application/octet-stream",
  });
  return blob;
};

export const genSaveName = (name: string): string => "FlightAI-" + name;

/**
 * 通用的打开下载对话框方法，没有测试过具体兼容性
 *
 * @param url 下载地址，也可以是一个blob对象，必选
 * @param saveName 保存文件名，可选
 */
export const openDownloadDialog = (
  url: Blob | string,
  saveName?: string
): void => {
  if (typeof url === "object" && (url as any) instanceof Blob) {
    // 创建blob地址
    url = URL.createObjectURL(url);
  }
  const aLink = document.createElement("a");
  aLink.href = url as string;
  // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
  aLink.download = saveName ? genSaveName(saveName) : "";
  let event;
  if (window.MouseEvent) {
    event = new MouseEvent("click");
  } else {
    event = document.createEvent("MouseEvents");
    event.initMouseEvent(
      "click",
      true,
      false,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
  }
  aLink.dispatchEvent(event);
};
