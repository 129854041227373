import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Empty, Radio, Row, Spin } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import Charts from "./charts";
import {
  AggCode,
  AirlinesQueryCondition,
  IDownloadHeader,
  ISeries,
  QueryCondition,
} from "Interface";
import useGlobal from "Store";
import {
  getComparedPercentage,
  getCompareVal,
  isAirportMode,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { cloneDeep, merge, round } from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";

import { ICard } from "../../Interface";
import DownloadBtn from "Components/DownloadBtn";
import useRefFunc from "Utils/useRefFunc";
import { downloadExcel } from "Utils/downloadXLSX";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: object;
  query: AirlinesQueryCondition | QueryCondition;
  ext?: any;
  card: ICard;
  panelType?: 0 | 1;
  compareType: number;
  dateType?: string;
  downloadColumns: IDownloadHeader[];
}

const LinesCharts: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    style,
    ext: otherExt,
    query,
    card,
    compareType,
    dateType,
    downloadColumns,
  } = props;
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [barData, setBarData] = useState([]);
  const isAirport = isAirportMode(systemType);
  const chartRef = useRef<any>();
  const ext = isAirport
    ? merge({}, { aggCode }, otherExt)
    : merge({}, { aggCode }, otherExt);
  const targetName = isAirport
    ? queryCondition.area.areaName
    : airlinesQueryCondition.airlinesName;
  const isPercentVal = card.isPercentVal;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        ...ext,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [aggCode, query, otherExt, airlinesQueryCondition, card]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.yoy =
          item.value && item.compareValue
            ? round(
                isPercentVal
                  ? getComparedPercentage(item.value, item.compareValue)
                  : getCompareVal(item.value, item.compareValue),
                2
              )
            : 0;
        item.marketYoy =
          item.marketValue && item.marketCompareValue
            ? round(
                isPercentVal
                  ? getComparedPercentage(
                      item.marketValue,
                      item.marketCompareValue
                    )
                  : getCompareVal(item.marketValue, item.marketCompareValue),
                2
              )
            : 0;

        return item;
      });
      // console.log(compareData)
      setBarData(compareData);
    } else {
      setBarData([]);
    }
  }, [data]);

  const handleDownload = useRefFunc(() => {
    if (!chartRef.current?.getSeries) {
      return;
    }
    const series = chartRef.current.getSeries();
    // 过滤出当前图表显示的列, 并且将名称应用到下载的列上
    const columns = downloadColumns.reduce((t, c) => {
      if (c.dataIndex === "day") {
        t.push(c);
        return t;
      }
      const targetSeries = series.find(
        (s: ISeries) => s.encode.y === c.dataIndex
      );
      if (targetSeries) {
        const tmp = cloneDeep(c);
        tmp.title = tmp.title || targetSeries.name;
        t.push(tmp);
      }
      return t;
    }, [] as IDownloadHeader[]);
    downloadExcel(columns, barData, "行业趋势");
  });

  if (error) {
    return <Refetch refetch={refetch} />;
  }
  // if (isLoading) {
  //   return <Spin />;
  // }

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  return (
    <Card className="barline-charts" style={style}>
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={handleChange}
            id="chartsAggCode"
          >
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      {!isLoading && barData && barData.length === 0 ? (
        <Empty style={{ marginTop: 30 }} />
      ) : (
        <Spin spinning={isLoading}>
          <Charts
            seriesName={targetName}
            cardName={card.cardLabel}
            data={barData}
            height={height}
            aggCode={aggCode}
            startDate={query.startDate}
            endDate={query.endDate}
            isPercentVal={isPercentVal}
            compareType={compareType}
            dateType={dateType}
            ref={chartRef}
          />
        </Spin>
      )}
    </Card>
  );
};

export default LinesCharts;
