import React, { CSSProperties, useEffect, useState } from "react";
import { Select, Spin } from "antd";
import { AirlineItem, AirlinesQueryCondition, QueryCondition } from "Interface";
import _ from "lodash";
import { useFetch } from "Utils/useFetch";
import { getSharkText } from "Utils/i18nGlobal";

interface SelectMultiFlightProps {
  queryUrl?: string;
  moduleCode?: string;
  server?: string;
  value: string[];
  onChange: (list: string[]) => void;
  airlinesCode: string;
  isDemo: boolean;
  type?: number;
  style?: CSSProperties;
  query?: AirlinesQueryCondition | QueryCondition;
}

const { Option } = Select;

const SelectMultiFlight: React.FC<SelectMultiFlightProps> = (
  props: SelectMultiFlightProps
) => {
  const {
    queryUrl = "findAirlines",
    moduleCode = "common",
    server,
    value,
    onChange,
    airlinesCode,
    isDemo,
    type = 2,
    style,
    query,
  } = props;
  const [flightList, setFlightList] = useState<AirlineItem[]>([]);
  const [filterList, setFilterList] = useState<AirlineItem[]>([]);
  const [{ data, isLoading, error }, doFetch] = useFetch({
    url: queryUrl,
    server,
    head: {
      moduleCode,
      chartTableCode: null,
    },
    query,
    ext: {
      code: airlinesCode.split(","),
      type,
    },
  });

  useEffect(() => {
    doFetch({
      query,
      ext: {
        code: airlinesCode.split(","),
        type,
      },
    });
  }, [airlinesCode, query]);

  useEffect(() => {
    if (data) {
      const tmpData =
        typeof data[0] === "string"
          ? data.map((d: string) => ({ flightNO: d }))
          : data;
      const arr = _.uniqWith(
        tmpData,
        (arrVal: AirlineItem, othVal: AirlineItem) =>
          arrVal.flightNO === othVal.flightNO
      );
      const filterArr = arr.slice(0, 20);
      setFlightList(arr);
      setFilterList(filterArr);
    }
  }, [data]);

  if (error) {
    return null;
  }

  const handleChange = (v: string[]) => {
    onChange(v);
  };

  const handleSearch = (v: string) => {
    const filterArr = flightList.filter((item) =>
      item.flightNO?.includes(v.toLocaleUpperCase())
    );
    if (filterArr) {
      setFilterList(filterArr.slice(0, 20));
    }
  };
  if (isLoading) {
    return <Spin />;
  }

  return (
    <Select
      className="search-airlines"
      disabled={isDemo}
      mode="multiple"
      notFoundContent="NO Data"
      placeholder={getSharkText("key.mutiple_flightno.query")}
      value={value}
      onChange={handleChange}
      onSearch={handleSearch}
      style={{ width: 400, ...style }}
    >
      {filterList.map((item: AirlineItem, idx: number) =>
        item.flightNO ? (
          <Option key={idx} value={item.flightNO}>
            {item.flightNO}
          </Option>
        ) : undefined
      )}
    </Select>
  );
};

export default SelectMultiFlight;
