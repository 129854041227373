import { FileInfo } from "@ctrip/flt-bi-flightai-base";
import { Modal } from "antd";
import { ACCEPT_IMAGE_TYPES } from "Constants";
import React, { ReactElement, useCallback } from "react";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

export interface IImgViewerProps {
  file: FileInfo;
  nameSuffix?: string;
}

/**
 *  Component description
 */
const ImageViewer = (props: IImgViewerProps): ReactElement => {
  const { file, nameSuffix } = props;
  const ext = file.fileName?.split(".").pop();
  const isImg =
    ext && ACCEPT_IMAGE_TYPES.includes(`.${ext}`.toLocaleLowerCase());
  const showImg = useCallback(() => {
    if (!file.base64) return;
    const src = `data:image/${ext};base64,${file.base64}`;
    Modal.info({
      maskClosable: true,
      width: 900,
      icon: null,
      content: <img src={src} style={{ maxWidth: 860 }} />,
    });
  }, [ext, file.base64]);
  const [, fetchImg] = useFetch({
    url: "getFile",
    head: {
      moduleCode: "non",
      chartTableCode: "non",
    },
    ext: {
      fileInfo: file,
    },
    lazey: true,
    onSuccess: (res) => {
      const resFile = res.fileInfo;
      if (resFile.base64) {
        file.base64 = resFile.base64;
        showImg();
      }
    },
  });
  const handleClick = useCallback(() => {
    if (!file.base64) {
      fetchImg({ ext: { fileInfo: file } });
    } else {
      showImg();
    }
  }, [fetchImg, file, showImg]);
  if (!isImg) {
    return <span>{getSharkText("config_page_not_image")}</span>;
  }
  return (
    <a onClick={handleClick} style={{ marginRight: 5 }}>
      {getSharkText("config_page_image")}
      {nameSuffix}
    </a>
  );
};
export default ImageViewer;
