import React, { useState } from "react";
import { Checkbox, Col, Input, Row, Table } from "antd";
import _ from "lodash";
import DownloadBtn from "Components/DownloadBtn";
import { IDownloadHeader } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

interface CustomTableProps {
  columns: IDownloadHeader[];
  subColumns?: any;
  dataSource: any[];
  mode: number;
  useCompare: number;
  setMode: (value: number) => void;
  isAirlinesMarketComparison?: boolean;
  searchkeys: string[];
  searchPlaceholder: string;
  handleDownload: () => void;
  moduleCode: string;
  chartTableCode: string;
  disableDownload?: boolean;
}

const CustomTable: React.FC<CustomTableProps> = (props: CustomTableProps) => {
  const {
    columns,
    subColumns,
    dataSource,
    setMode,
    mode,
    useCompare,
    isAirlinesMarketComparison,
    searchkeys,
    searchPlaceholder,
    handleDownload,
    moduleCode,
    chartTableCode,
    disableDownload = false,
  } = props;
  const [searchText, setSearchText] = useState<string>("");

  const getFilterData = () => {
    const cloneData = _.cloneDeep(dataSource);
    const filter = cloneData.filter((data: any) => {
      let isPass = false;
      for (const key of searchkeys) {
        if (data[key] && data[key].includes(searchText)) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = _.isEmpty(dataSource) ? dataSource : getFilterData();

  const handleOnChange = (checkedValues: any[]) => {
    if (checkedValues.length === 0) {
      setMode(0);
    } else if (checkedValues.length === 1) {
      if (checkedValues[0] === "1") {
        setMode(1);
      } else {
        setMode(2);
      }
    } else {
      setMode(3);
    }
  };

  const expandedRowRender = (record: any) => {
    let expandedSource: any[] = [];
    switch (mode) {
      case 1:
        expandedSource = [record.compare];
        break;
      case 2:
        expandedSource = !isAirlinesMarketComparison
          ? [record.result]
          : [record.compare2];
        break;
      case 3:
        expandedSource = !isAirlinesMarketComparison
          ? [record.compare, record.result]
          : [record.compare, record.compare2];
        break;
    }

    return (
      <Table
        dataSource={expandedSource}
        columns={subColumns}
        pagination={false}
        showHeader={false}
      />
    );
  };

  const getCheckedValue = (): string[] => {
    switch (mode) {
      case 1:
        return ["1"];
      case 2:
        return ["2"];
      case 3:
        return ["1", "2"];
      default:
        return [];
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      {useCompare === 1 ? (
        <Row align="middle" gutter={32}>
          <Col>
            {!isAirlinesMarketComparison ? (
              <Checkbox.Group
                value={getCheckedValue()}
                style={{ width: "100%" }}
                onChange={handleOnChange}
              >
                <Checkbox id="dateCompare" value="1">
                  {getSharkText("key.comparsion_date.checkbox")}
                </Checkbox>
                <Checkbox id="increase" value="2">
                  {getSharkText("key.growth_rate.checkbox")}
                </Checkbox>
              </Checkbox.Group>
            ) : (
              <Checkbox.Group
                value={getCheckedValue()}
                style={{ width: "100%" }}
                onChange={handleOnChange}
              >
                <Checkbox id="airlinesOverall" value="1">
                  {getSharkText("key.air_route_airport.checkbox")}
                </Checkbox>
                <Checkbox id="airlinesOverallCity" value="2">
                  {getSharkText("key.air_route_city.checkbox")}
                </Checkbox>
              </Checkbox.Group>
            )}
          </Col>
          <Col>
            <Input.Search
              value={searchText}
              placeholder={searchPlaceholder}
              onChange={handleSearch}
            />
          </Col>
          <Col style={{ flex: 1, textAlign: "right" }}>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
              disabled={disableDownload}
            />
          </Col>
        </Row>
      ) : undefined}
      <Row style={{ marginTop: 16 }}>
        <Table
          defaultExpandAllRows={true}
          showSorterTooltip={false}
          expandIconColumnIndex={-1}
          expandable={{ indentSize: 0 }}
          tableLayout="fixed"
          dataSource={filterData}
          columns={columns}
          indentSize={0}
          expandedRowRender={mode ? expandedRowRender : undefined}
          expandedRowKeys={filterData.map((data: any) => data.key)}
          scroll={{ y: 540 }}
        />
      </Row>
    </>
  );
};

export default CustomTable;
