import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { Empty, Spin } from "antd";
import _, { isEmpty, max, round } from "lodash";
import { ColorComputer, deepFind, isSame } from "Utils";
import BMap from "Components/BMap";
import "echarts/extension/bmap/bmap";
import { UserProfileChart } from "@ctrip/flt-bi-flightai-airlines";
import * as echarts from "echarts/lib/echarts";
import { EMPTY_ARRAY } from "Constants";
import * as level from "../level.json";
import { defaultArea, ILevel, IQuery } from "../common";
import { Area, FlightArea } from "Interface";
import usePageContext from "../UserProfileContext";
import MapCityRanking from "./MapCityRanking";
import EchartsReactBase from "Components/EchartsReactBase";

const getLevelItem = (key: string, arr: ILevel[]): ILevel | undefined => {
  const item = arr.find((a) => a.name.indexOf(key) >= 0);
  if (item) {
    return item;
  } else {
    for (let i = 0; i < arr.length; i++) {
      const a = arr[i];
      if (a.children) {
        const deepR = getLevelItem(key, a.children);
        if (deepR) {
          return deepR;
        }
      }
    }
  }
};

const selectedArea = (cur: string, area: Area, filter: FlightArea) => {
  if (
    (area.areaName && cur.indexOf(area.areaName) >= 0) ||
    (filter.departArea.areaName &&
      cur.indexOf(filter.departArea.areaName) >= 0) ||
    (filter.arriveArea.areaName && cur.indexOf(filter.arriveArea.areaName) >= 0)
  ) {
    return true;
  }
  return false;
};

interface ChartMapProps {
  data: UserProfileChart[];
  cityData: UserProfileChart[];
  isLoading: boolean;
  setArea: (v: Area) => void;
  area: Area;
  queryExt: IQuery;
}

const MapChart: React.FC<ChartMapProps> = (props: ChartMapProps) => {
  const { data, cityData, isLoading, setArea, area, queryExt } = props;
  const [option, setOption] = useState<object>({});
  const { current } = queryExt;
  const [isBMapReady, setIsBMapReady] = useState<boolean>(false);
  const [isChartReady, setIsChartReady] = useState<boolean>(false);
  // 可以通过设置面板改变颜色的值, 仅测试环境使用, 需要解除下方输入框的注释
  const [colorCustom] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        case "min":
          return { ...state, min: action.payload };
        case "max":
          return { ...state, max: action.payload };
      }
    },
    { min: "#f5ece5", max: "#f96d06" }
  );
  const [context] = usePageContext();
  const { areaSource } = context;
  let totalValue = 0;
  data.forEach((d) => {
    totalValue += d.value || 0;
  });
  const maxOther = data
    ? max(
        data.map((d) =>
          d.name === current.filter.departArea.areaName
            ? 0
            : (d.value as number)
        )
      )
    : 1;
  const chartsRef = useRef<any>(null);
  const [series, setSeries] = useState<any[]>(EMPTY_ARRAY);

  // const [provinces, setProvinces] = useState<Record<string, any[][][]>>({});

  useEffect(() => {
    // 必须执行这一步才能引入百度地图组件
    try {
      BMap.init().then(() => {
        // BMAP引入后会自动进行初始化, 这个初始化和echarts的初始化是异步的, 如果echarts先初始化完成,
        // 加载BMap时, 会报BMap.Overlay is not a constructor的错误, 所以等待一秒, 后续寻找BMap检测init完成的方法后替换这个延时
        setTimeout(() => {
          console.log("BMap init end");
          setIsBMapReady(true);
        }, 1000);
      });
    } catch (e) {
      console.log(e);
    }
    const resizeFn = () => {
      console.log("sdfdsf", this);
    };
    window.addEventListener("resize", resizeFn);
    return () => window.removeEventListener("resize", resizeFn);
  }, []);

  const getColor = useMemo(() => {
    try {
      const colorInstance = new ColorComputer(colorCustom.min, colorCustom.max);
      return colorInstance.getColor;
    } catch (e) {
      console.log(e);
    }
  }, [colorCustom.max, colorCustom.min]);

  const renderItem = useCallback(
    (params: any, api: any) => {
      const name = params.seriesName;
      const { data: levelData } = level as any;
      const levelItem = getLevelItem(name, levelData as ILevel[]);
      if (!echarts || !levelItem || !getColor) {
        return;
      }

      const coordsList = levelItem.coords || [];
      const per = maxOther && api.value() ? api.value() / maxOther : 0;
      let color = getColor(per);
      if (selectedArea(name, area, queryExt.current.filter)) {
        color = "#5f6d79";
      }

      const generateShape = (coords: any[][]) => {
        const points: any[] = [];
        coords.forEach((c) => {
          points.push(api.coord(c));
        });
        return {
          type: "polygon",
          shape: {
            points: echarts.graphic.clipPointsByRect(points, {
              x: params.coordSys.x,
              y: params.coordSys.y,
              width: params.coordSys.width,
              height: params.coordSys.height,
            }),
          },
          style: api.style({
            fill: color,
            stroke: echarts.color.lift(color, 1),
          }),
        };
      };

      return {
        type: "group",
        children: coordsList.filter((t) => !!t).map(generateShape),
      };
    },
    [maxOther, getColor]
  );

  const convertData = (source: UserProfileChart[]): any[] => {
    let sum = 0;
    const rst: any[] = [];
    if (!source) {
      return rst;
    }
    source.forEach((d: UserProfileChart) => {
      const v = Math.round(d.value || 0);
      sum += v;
      const geoCoord = [d.lng, d.lat, v];

      if (geoCoord) {
        rst.push(geoCoord);
      }
    });
    return [rst, sum];
  };

  // #region 生成series 当area的areaType为4时, 返回的数据为热力图模式数据, 否则为区域图模式数据
  useEffect(() => {
    if (area.areaType !== 4) {
      const tmpSeries = data.map((d) => {
        return {
          type: "custom",
          name: d.name,
          coordinateSystem: "bmap",
          renderItem,
          itemStyle: {
            opacity: 0.5,
          },
          animation: false,
          // silent: true,
          data: [d.value],
          z: 2,
        };
      });
      setSeries(tmpSeries);
    } else {
      const [d, sum] = convertData(data);
      setSeries([
        {
          id: "series1",
          // series名称
          name: "heatmap",
          // series图表类型
          type: "heatmap",
          coordinateSystem: "bmap",
          data: d,
          minOpacity: 0.05,
          pointSize: 3,
          blurSize: 6,
        },
      ]);
    }
  }, [data, renderItem]);
  // #endregion

  const loadMapData = useCallback(
    (source: UserProfileChart[]) => {
      // 地图在全屏和取消全屏时, 由于页面的滚动会导致中心点偏移, 需要设置一个变量在切换全屏时记忆滚动距离, 取消全屏时再设置回来
      let scrollTopInBMap = 0;
      const [d, sum] = convertData(source);
      const hotStandard = sum / 10000 > 20 ? sum / 10000 : 20;
      const visualMaxMin = sum
        ? {
            max: hotStandard,
          }
        : null;
      const optionTemp = {
        // backgroundColor: "#fff",
        // 加载 bmap 组件
        bmap: {
          // 百度地图中心经纬度
          center: [106.33066322374, 33.704018034923],
          // 百度地图缩放
          zoom: 4,
          // 是否开启拖拽缩放，可以只设置 'scale' 或者 'move'
          // roam: "move",
          roam: true,
          // // 百度地图的自定义样式，见 http://developer.baidu.com/map/jsdevelop-11.htm
          // mapStyle: { styleJson: [] },
        },
        legend: {
          orient: "vertical",
          y: "bottom",
          x: "right",
          data: [],
        },
        visualMap:
          area.areaType === 4
            ? {
                type: "continuous",
                show: true,
                top: 20,
                realtime: false,
                inRange: {
                  color: ["blue", "yellow", "red", "red"],
                },
                ...visualMaxMin,
              }
            : undefined,
        toolbox: {
          show: true,
          z: 10,
          feature: {
            dataView: { show: false },
            dataZoom: { show: false },
            restore: { show: false },
            saveAsImage: { show: false },
            myFull: {
              show: true,
              title: "Full Screen View",
              icon: `path://M64.46799 531.124935c34.111289 0 61.929376 25.535468 64.297327 58.814775l0.170664 4.586571-0.042666 217.062144L374.733526 
            573.428054c21.972876-22.399533 56.852149-25.023479 82.344952-8.255828l4.373242 3.157267 3.967917 3.370597c25.044812 23.03952 26.602112 
            61.225391 4.693236 87.251515l-4.159914 4.479907-241.850961 233.744464h218.171455c32.468657 0 59.284098 23.167517 63.785337 54.121539l0.51199 
            4.693235 0.14933 4.586571c0 33.940626-26.346118 60.969396-59.838753 63.252016l-4.607904 0.14933H64.46799c-34.132622 
            0-61.950709-25.535468-64.297327-58.814775L0 
            960.577321v-366.072373c0-35.476594 28.7994-63.380013 64.46799-63.380013zM959.510677 0c34.132622 0 61.950709 25.535468 64.297327 58.814775l0.170663 
            4.586571v366.072373c0 35.476594-28.7994 63.380013-64.46799 63.380013A63.913335 63.913335 0 0 1 895.981334 
            439.542843l-0.682653-4.7999-0.255994-5.290557V211.024937l-248.954814 241.274973c-23.03952 21.183559-58.025458 23.03952-82.259619 
            5.546552l-4.181247-3.263932-3.690589-3.498594a61.929376 61.929376 0 0 1-3.626592-85.395555l3.626592-3.775921L798.575363 126.760026l-216.870149 
            0.021333c-32.468657 0-59.284098-23.146184-63.785337-54.100207l-0.51199-4.693235-0.14933-4.586571c0-33.940626 26.346118-60.969396 
            59.838753-63.252016L581.705214         0h377.805463z`,
              iconStyle: {
                color: "blue",
                background: "#fff",
              },
              onclick: () => {
                const fullscreenElement =
                  // 标准
                  document.fullscreenElement ||
                  // chrome内核
                  document.webkitFullscreenElement ||
                  // firefox
                  document.mozFullScreenElement ||
                  // IE;
                  document.msFullscreenElement;
                const element = chartsRef.current.ele;
                if (!fullscreenElement) {
                  const body = document.querySelector("html");
                  if (body) {
                    scrollTopInBMap = body.scrollTop;
                    console.log("scroll: ", scrollTopInBMap);
                    body.scrollTop = 0;
                  }
                  if (element.requestFullScreen) {
                    // HTML W3C proposed
                    element.requestFullScreen();
                  } else if (element.msRequestFullscreen) {
                    // IE11
                    element.msRequestFullScreen();
                  } else if (element.webkitRequestFullScreen) {
                    // Webkit (works in Safari5.1 and Chrome 15)
                    element.webkitRequestFullScreen();
                  } else if (element.mozRequestFullScreen) {
                    // Firefox (works in nightly)
                    element.mozRequestFullScreen();
                  }
                  // isFullScreen = true;
                } else {
                  const body = document.querySelector("html");
                  if (body) {
                    console.log("scroll to: ", scrollTopInBMap);
                    body.scrollTop = scrollTopInBMap;
                  }
                  if (document.exitFullscreen) {
                    document.exitFullscreen();
                  } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                  } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                  } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                  }
                  // isFullScreen = false
                }
              },
            },
          },
        },
        title: {
          show: false,
          text: "",
          left: 0,
        },
        tooltip: {
          trigger: "item",
          formatter: (item: any) => {
            const name = item.seriesName;
            const val = `${round((item.value / totalValue) * 100, 2)}%`;
            return `${name} : ${val}`;
          },
        },
        series,
        animation: false,
      };
      setOption(optionTemp);
    },
    [series]
  );

  // hook data
  useEffect(() => {
    if (data && data.length && isBMapReady && isChartReady) {
      loadMapData(data);
    }
  }, [data, isBMapReady, isChartReady, loadMapData]);

  useEffect(() => {
    const reRender = () => {
      console.log("resize");
      if (chartsRef && chartsRef.current) {
        const instance = chartsRef.current.getEchartsInstance();
        const optionTmp = instance.getOption();
        instance.clear();
        instance.setOption(optionTmp);
      }
    };
    const onResize = _.debounce(reRender, 500);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [chartsRef]);

  const onEvents = useMemo(() => {
    return {
      click: (params: any, v: any) => {
        console.log("click: ", params, v);
        // 获取areaSource
        const name = params.seriesName;
        const tempArea = deepFind(
          areaSource,
          (a: Area) =>
            a.areaName === name ||
            a.areaName.indexOf(name) >= 0 ||
            name.indexOf(a.areaName) >= 0
        );
        const clickArea: Area = isSame(area, defaultArea)
          ? tempArea
          : {
              areaType: 4,
              areaCode: "",
              areaName: name,
            };
        if (!clickArea) {
          return;
        }
        setArea(clickArea);
      },
    };
  }, [area, areaSource, setArea]);

  const chartReady = () => {
    console.log("ready.....");
    setIsChartReady(true);
  };
  if (isEmpty(data)) {
    return <Empty style={{ marginTop: 32 }} />;
  }
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "1 1 auto" }}>
        <Spin spinning={isLoading}>
          {isBMapReady ? (
            <EchartsReactBase
              className="airlines-map"
              ref={chartsRef}
              option={option}
              notMerge
              style={{ height: 300, width: "100%" }}
              onChartReady={chartReady}
              onEvents={onEvents}
            />
          ) : undefined}
        </Spin>
      </div>
      <div style={{ width: 200 }}>
        <MapCityRanking data={cityData} />
      </div>
    </div>
  );
};

export default MapChart;
