export const MODULE_CODE: any = "salon_manage";

export const CHART_TABLE_CODE: any = {
  listArticle: "TX0302",
  postArticle: "TX0302",
  listArticleType: "TX0302",
  postArticleType: "TX0302",
};

export const QUERY_URL = {
  listArticle: "listArticle",
  postArticle: "postArticle",
  listArticleType: "listArticleType",
  postArticleType: "postArticleType",
};
