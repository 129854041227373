import React from "react";
import { Spin } from "antd";
import { round } from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";
import { getSharkText } from "Utils/i18nGlobal";

interface BarData {
  name: string;
  value: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  loading: boolean;
  seriesMap: object;
}
const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? "0" + hex : hex;
};

const rgbToHex = (r: number, g: number, b: number) =>
  "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
const genChartColor = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const step = 10;
  let { r, g, b } = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
  const rst = [];
  for (let index = 0; index < 15; index++) {
    r = r + step > 255 ? 255 : r + step;
    g = g + step > 255 ? 255 : g + step;
    b = b + step > 255 ? 255 : b + step;
    rst.push(rgbToHex(r, g, b));
  }
  return rst;
};

const genChartData = (data: BarData[], seriesMap: any) => {
  const rst = data.reduce((total: BarData[], d: BarData) => {
    // 排除总量数据
    if (/all$/.test(d.name)) {
      return total;
    }
    if (!seriesMap) {
      total.push(d);
      return total;
    }
    if (seriesMap[d.name]) {
      total.push({
        name: seriesMap[d.name],
        value: d.value,
      });
    }
    return total;
  }, []);
  return rst;
};

const SimpleTreeMapCharts: React.FC<BarChartsProps> = (
  props: BarChartsProps
) => {
  const { data, height = 300, loading, seriesMap } = props;
  const chartData = genChartData(data, seriesMap);
  // const seriesValueName = systemType === 2 && compareType === 1 ? airlines : '当期'
  // const seriesResultValueName = getCardTrendText(cardCode, systemType, compareType)
  // const seriesCompareValueName = systemType === 2 && compareType === 1 ? '市场' : COMPARE_TABLE_TYPE[compareType]
  const isZero = (val: number) => val === 0;
  const option = {
    color: genChartColor("#0369EA"),
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    grid: {
      show: true,
      top: 250,
      bottom: 50,
      left: 70,
      right: 70,
      backgroundColor: "#fff",
      borderWidth: 0,
    },
    tooltip: {
      trigger: "item",
      formatter: (item: any) => {
        // const day = getTooltipDateFromAggCode(aggCode, params, data.length, startDate, endDate, isDemo)
        const totalObj = data.find((d) => /all$/.test(d.name));
        const totalValue = totalObj ? totalObj.value : 0;
        let dom = "";
        const dot = `<span class='dot' style='background:${item.color};'></span>`;
        const per = isZero(totalValue) ? "-" : item.data.value / totalValue;
        const perDom = `${item.data.name}${getSharkText(
          "key.proportion_souce.text"
        )}: ${per !== "-" ? round(per * 100, 1) : "-"}%`;
        // dom = dot + val + '<br />' + perDom;
        dom = dot + perDom;
        return dom;
      },
    },
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
    series: [
      {
        type: "treemap",
        data: chartData,
        nodeClick: false,
        breadcrumb: {
          show: false,
        },
        label: {
          show: true,
          formatter: (d: any) => {
            const totalObj = data.find((d1) => /all$/.test(d1.name));
            const totalValue = totalObj ? totalObj.value : 0;
            return `${d.name} ${round((d.value / totalValue) * 100, 1)}%`;
          },
        },
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default SimpleTreeMapCharts;
