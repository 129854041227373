import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DateRangePickerV2 from "../DateRangePickerV2";
import moment, { Moment } from "moment";
import { getDateRange, isSame, showLunarDay } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import { RangePickerProps } from "antd/es/date-picker/generatePicker";
import { Radio } from "antd";
import { IDateMode, RangeCompare, RangeObject } from "Interface";
import { DefaultGranularityType } from "Page/AI/FreeDashboard/interface";
import CompareCom, { getCompareDate, WowType, YoyType } from "./CompareCom";
import { useDebounce } from "Utils/useDebounce";
import { GranularityMap } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/common";

// #region 日期范围快捷选项
export const range1List = [
  "today",
  "currentWeek",
  "currentMonth",
  "currentQuarter",
  "currentYear",
  "last7days",
  "last30days",
  "last90days",
] as const;
type range1 = (typeof range1List)[number];
export const range2List = [
  "yesterday",
  "lastWeek",
  "lastMonth",
  "lastQuarter",
  "lastYear",
  "last14days",
  "last60days",
  "last180days",
] as const;
type range2 = (typeof range2List)[number];
export type Range = range1 | range2;
// const allRangeList: Range[] = range1List.concat(range2List as unknown as any[]);
const allRangeList: Range[] = [
  "today",
  "yesterday",
  "currentWeek",
  "lastWeek",
  "currentMonth",
  "lastMonth",
  "currentQuarter",
  "lastQuarter",
  "currentYear",
  "lastYear",
  "last7days",
  "last14days",
  "last30days",
  "last60days",
  "last90days",
  "last180days",
];
export const rangeMap: Record<
  Range,
  { label: string; tip?: string; range: [Moment, Moment] }
> = {
  today: {
    label: getSharkText("config_page_today"),
    range: [moment().startOf("day"), moment()],
  },
  yesterday: {
    label: getSharkText("config_page_yesterday"),
    range: [moment().add(-1, "days").startOf("day"), moment().add(-1, "day")],
  },
  currentWeek: {
    label: getSharkText("key.current_week.checkbox"),
    tip: getSharkText("config_page_from_monday_zero"),
    range: [moment().startOf("week"), moment().add(-1, "days")],
  },
  lastWeek: {
    label: getSharkText("key.last_week.checkbox"),
    tip: getSharkText("config_page_from_last_monday_to_last_sunday"),
    range: [
      moment().add(-1, "week").startOf("week"),
      moment().add(-1, "week").endOf("week"),
    ],
  },
  currentMonth: {
    label: getSharkText("key.current_month.checkbox"),
    range: [moment().startOf("month"), moment().add(-1, "days")],
  },
  lastMonth: {
    label: getSharkText("key.last_month.checkbox"),
    range: [
      moment().add(-1, "month").startOf("month"),
      moment().add(-1, "month").endOf("month"),
    ],
  },
  currentQuarter: {
    label: getSharkText("config_page_current_quarter"),
    range: [moment().startOf("quarter"), moment().add(-1, "days")],
  },
  lastQuarter: {
    label: getSharkText("config_page_last_quarter"),
    range: [
      moment().add(-1, "quarter").startOf("quarter"),
      moment().add(-1, "quarter").endOf("quarter"),
    ],
  },
  currentYear: {
    label: getSharkText("config_page_current_year"),
    range: [moment().startOf("year"), moment().add(-1, "days")],
  },
  lastYear: {
    label: getSharkText("config_page_last_year"),
    range: [
      moment().add(-1, "year").startOf("year"),
      moment().add(-1, "year").endOf("year"),
    ],
  },
  last7days: {
    label: getSharkText("config_page_last_7_days"),
    range: [moment().add(-7, "days"), moment().add(-1, "days")],
  },
  last14days: {
    label: getSharkText("config_page_last_14_days"),
    range: [moment().add(-14, "days"), moment().add(-1, "days")],
  },
  last30days: {
    label: getSharkText("date.last30"),
    range: [moment().add(-30, "days"), moment().add(-1, "days")],
  },
  last60days: {
    label: getSharkText("date.last60"),
    range: [moment().add(-60, "days"), moment().add(-1, "days")],
  },
  last90days: {
    label: getSharkText("config_page_last_90_days"),
    range: [moment().add(-90, "days"), moment().add(-1, "days")],
  },
  last180days: {
    label: getSharkText("date.last180"),
    range: [moment().add(-180, "days"), moment().add(-1, "days")],
  },
};

const showLunarInCell = (d: Moment) => {
  return (
    <div>
      <div>{d.date()}</div>
      <div style={{ fontSize: "50%" }}>{showLunarDay(d.toDate())}</div>
    </div>
  );
};
// #endregion

/** 日期组件返回格式, 返回的date为时间对象 */
export type DateComObjectType = RangeCompare & {
  granularity?: string;
};

export interface IDatesProps {
  /**
   * 默认使用的时间范围
   */
  defaultDateMode?: IDateMode;
  /**
   * 默认值
   */
  defaultValue?: RangeCompare;
  /**
   * 是否启用对比, 默认不启用
   */
  useCompare?: boolean;
  /**
   * 快捷选项列表
   */
  ranges?: Range[];
  /**
   * 是否显示农历, 目前有些问题, 会导致严重的卡顿
   */
  showLunar?: boolean;
  /** 受控值 */
  value?: RangeCompare;
  /**
   * 值变更时触发
   */
  onChange: (v: DateComObjectType) => void;
  /** 聚合粒度 */
  allowedGranularity?: string[] | false;
  /** 时间选择器props */
  datePickerProps?: RangePickerProps<moment.Moment>;
}

/**
 * 日期选择组件, 支持对比, 快捷选项
 */
const Dates = (props: IDatesProps): ReactElement => {
  const {
    useCompare = false,
    defaultValue,
    ranges = allRangeList,
    defaultDateMode = "none",
    showLunar = false,
    value,
    onChange,
    allowedGranularity = false,
    datePickerProps,
  } = props;
  const [currentValue, setCurrentValue] = useState<RangeObject>(() => {
    if (defaultValue?.current) {
      return defaultValue.current;
    } else if (typeof defaultDateMode === "string") {
      return getDateRange(defaultDateMode);
    }
    return null;
  });
  const [compareValue, setCompareValue] = useState<RangeObject>(
    defaultValue?.compare || null
  );
  const [isComparing, setIsComparing] = useState<boolean>(false);
  const [comparePopupOpen, setComparePopupOpen] = useState<boolean>(false);
  // 由于现版本antd有bug, 操作extraFooter中的控件时, 弹出面板会关闭, 所以只能手动控制., 当extraFooterUsing为true时, 阻止弹窗关闭
  const extraFooterUsing = useRef<boolean>(false);
  const extraFooterUsingTimer = useRef<number>();
  const [wowType, setWowType] = useState<WowType | null>(null);
  const [yoyYear, setYoyYear] = useState<number | null>(null);
  const [yoyType, setYoyType] = useState<YoyType | null>(null);
  const [granularity, setGranularity] = useState<string | undefined>(
    allowedGranularity && allowedGranularity.length
      ? allowedGranularity[0]
      : undefined
  );

  useEffect(() => {
    if (value !== undefined) {
      if (value.current) {
        setCurrentValue((state) => {
          if (isSame(value.current, state)) {
            return state;
          } else {
            return value.current;
          }
        });
      }
      if (value.compare) {
        setIsComparing(true);
        setCompareValue((state) => {
          if (isSame(value.compare, state)) {
            return state;
          } else {
            return value.compare;
          }
        });
      } else {
        setIsComparing(false);
        setCompareValue(null);
      }
    }
  }, [value]);

  const { current: usedRanges } = useRef(
    ranges.reduce((t, r) => {
      const v = rangeMap[r];
      t[v.label] = v.range;
      return t;
    }, {} as Record<string, [Moment, Moment]>)
  );
  const otherProps = useMemo((): RangePickerProps<moment.Moment> => {
    const tmpRange = ranges?.length
      ? {
          ranges: usedRanges,
        }
      : {};
    const tmpRender = showLunar
      ? {
          dateRender: (d: Moment) => showLunarInCell(d),
        }
      : {};
    return {
      ...datePickerProps,
      ...tmpRange,
      ...tmpRender,
    };
  }, [datePickerProps, ranges?.length, showLunar, usedRanges]);

  const submitChange = useRefFunc((key: keyof DateComObjectType, v) => {
    if (onChange) {
      onChange({
        current: currentValue,
        compare: compareValue,
        granularity,
        [key]: v,
      });
    }
  });

  const handleCurrentChange = useRefFunc((v: RangeObject) => {
    setCurrentValue(v);
    submitChange("current", v);
  });

  const changeCompareBySpecial = useRefFunc((v: RangeObject) => {
    setCompareValue(v);
    submitChange("compare", v);
  });

  const onChangeGranularity = useRefFunc((v: string) => {
    setGranularity(v);
    submitChange("granularity", v);
  });

  const handleCompareChange = useRefFunc((v: RangeObject) => {
    console.log("compare change: ", v);
    setYoyYear(null);
    setYoyType(null);
    setWowType(null);
    changeCompareBySpecial(v);
    if (!v) {
      setIsComparing(false);
    }
  });

  // #region compare footer

  const setCompareComCloseable = useDebounce(() => {
    console.log("extraFooterUsing false, closeable");
    extraFooterUsing.current = false;
  }, 1000);

  const stopProp = useRefFunc((e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    extraFooterUsing.current = true;
    console.log("extraFooterUsing true, can't close");
    setCompareComCloseable();
  });

  useEffect(() => {
    if (!currentValue || currentValue.filter((v) => !!v).length === 0) {
      return;
    }
    const tmp = currentValue as [Moment, Moment];
    if (wowType || (yoyYear && yoyType)) {
      changeCompareBySpecial(
        getCompareDate({ currentValue: tmp, wowType, yoyYear, yoyType })
      );
    } else {
      changeCompareBySpecial(null);
    }
  }, [currentValue, changeCompareBySpecial, wowType, yoyType, yoyYear]);

  const handleChangeYoyYear = useRefFunc((v: number) => {
    setYoyYear(v);
    setWowType(null);
  });

  const handleChangeYoyType = useRefFunc((v: YoyType) => {
    setYoyType(v);
    setWowType(null);
  });

  const handleChangeWowType = useRefFunc((v: WowType) => {
    setWowType(v);
    setYoyYear(null);
    setYoyType(null);
  });

  const footer = useMemo(
    () => (
      <div onClick={stopProp}>
        <CompareCom
          wowType={wowType}
          yoyType={yoyType}
          yoyYear={yoyYear}
          handleChangeWowType={handleChangeWowType}
          handleChangeYoyType={handleChangeYoyType}
          handleChangeYoyYear={handleChangeYoyYear}
        />
      </div>
    ),
    [
      handleChangeWowType,
      handleChangeYoyType,
      handleChangeYoyYear,
      stopProp,
      wowType,
      yoyType,
      yoyYear,
    ]
  );
  // #endregion

  const compareFooter = useCallback(() => {
    return footer;
  }, [footer]);

  /**
   * 当用户刚刚操作了extraFooter中的控件后, 阻止弹窗关闭
   */
  const handleCompareOpenChange = useRefFunc((open: boolean) => {
    console.log(
      "open change :",
      open,
      " extraUsing :",
      extraFooterUsing.current
    );
    if (open || !extraFooterUsing.current) {
      setComparePopupOpen(open);
    }
  });

  const compareContent = useMemo(() => {
    if (!useCompare) return undefined;
    if (isComparing) {
      return (
        <>
          <span
            style={{ color: "#1890ff", fontWeight: "bold", margin: "0 5px" }}
          >
            VS
          </span>
          <DateRangePickerV2
            defaultDateMode="none"
            value={compareValue}
            onChange={handleCompareChange}
            footer={compareFooter}
            otherProps={{
              ...datePickerProps,
              ...(showLunar
                ? { dateRender: (d: Moment) => showLunarInCell(d) }
                : {}),
              open: comparePopupOpen,
              onOpenChange: handleCompareOpenChange,
              allowClear: true,
            }}
          />
        </>
      );
    } else {
      return (
        <span onClick={() => setIsComparing(true)} style={{ marginLeft: 2 }}>
          +{getSharkText("config_page_CoDaIn_compare_date")}
        </span>
      );
    }
  }, [
    compareFooter,
    comparePopupOpen,
    compareValue,
    datePickerProps,
    handleCompareChange,
    handleCompareOpenChange,
    isComparing,
    showLunar,
    useCompare,
  ]);

  const granularityContent = useMemo(() => {
    if (!allowedGranularity || !allowedGranularity.length) {
      return undefined;
    }
    const options = allowedGranularity.map((key) => ({
      label: GranularityMap[key as DefaultGranularityType] || key,
      value: key,
    }));
    return (
      <Radio.Group
        options={options}
        optionType="button"
        buttonStyle="solid"
        style={{ marginLeft: 5 }}
        value={granularity}
        onChange={(e) => onChangeGranularity(e.target.value)}
      ></Radio.Group>
    );
  }, [allowedGranularity, granularity, onChangeGranularity]);

  return (
    <>
      <DateRangePickerV2
        defaultDateMode={defaultDateMode}
        value={currentValue}
        onChange={handleCurrentChange}
        otherProps={otherProps}
      />
      {compareContent}
      {granularityContent}
    </>
  );
};
export default Dates;
