import { Spin } from "antd";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { durationFormatter, useFetch } from "Utils";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import {
  ACC_REVENUE_NAME,
  AHEADHOUR_CAL_NAME,
  AIRLINE_NAME,
  ASK_REVENUE_NAME,
  AVG_PRICE_NAME,
  CAP_NAME,
  DATASET_ID,
  FLIGHTNO_NAME,
  FLYING_HOURS_NAME,
  GROUP_PCT_NAME,
  HOURS_REVENUE_NAME,
  LOAD_FACTOR_NAME,
  ROUTE_NAME,
  SALEDSEAT_NAME,
  SCHEDULE_CNT_NAME,
  TAKEOFF_CNT_NAME,
  TAKEOFFDATE_NAME,
} from "../DatasetCols";
import {
  ChartRenderConfig,
  Dimension,
  DimensionSchemaEx,
  FilterValue,
  Measure,
  MeasureSchemaEx,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import {
  FDDatasetCol2DimensionSchemaEx,
  FDDatasetCol2MeasureSchemaEx,
} from "Page/AI/FreeDashboard/common";
import {
  CompareMean,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { MetricSchema } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/schema";
import useRefFunc from "Utils/useRefFunc";
import { QueryHandler, QueryParam } from "Page/AI/ProfitMonitor/interface";
import { ChartContainer } from "@ctrip/flt-bidw-mytrix-ui";
import Refetch from "Components/Refetch";
import { ComparatorArg } from "Interface";

export interface OverviewLineProps {
  columns: FDDatasetCol[];
  params: QueryParam;
}

/** 收益监控-线条图 */
const OverviewLine = forwardRef(
  (props: OverviewLineProps, ref: Ref<QueryHandler>): ReactElement => {
    const { columns, params } = props;

    const [response, setResponse] = useState<StandardResponse | null>();

    const dimensions: Dimension[] = useMemo(() => {
      return [
        {
          columnName: AHEADHOUR_CAL_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
      ];
    }, []);

    const measures = useMemo(() => {
      const tmp: Measure[] = [
        {
          id: ACC_REVENUE_NAME,
          columnName: ACC_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: SALEDSEAT_NAME,
          columnName: SALEDSEAT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: CAP_NAME,
          columnName: CAP_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: SCHEDULE_CNT_NAME,
          columnName: SCHEDULE_CNT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: FLYING_HOURS_NAME,
          columnName: FLYING_HOURS_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: HOURS_REVENUE_NAME,
          columnName: HOURS_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
              precision: 0,
            },
          },
        },
        {
          id: ASK_REVENUE_NAME,
          columnName: ASK_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "decimal",
              precision: 6,
            },
          },
        },
        {
          id: LOAD_FACTOR_NAME,
          columnName: LOAD_FACTOR_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "percent",
              precision: 2,
            },
          },
        },
        {
          id: AVG_PRICE_NAME,
          columnName: AVG_PRICE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
              precision: 0,
            },
          },
        },
        {
          id: GROUP_PCT_NAME,
          columnName: GROUP_PCT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "percent",
              precision: 2,
            },
          },
        },
        {
          id: TAKEOFF_CNT_NAME,
          columnName: TAKEOFF_CNT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
      ];
      return tmp;
    }, []);

    const filters = useMemo(() => {
      const rst: FilterValue[] = [];
      const airlineFilter: FilterValue = {
        filterId: "airline",
        filterType: "select",
        argOfReports: [
          {
            panelId: "1",
            columnName: AIRLINE_NAME,
            values: [params.airline],
          },
        ],
      };
      const tkFilter: FilterValue = {
        filterId: "takeoffdate",
        filterType: "date",
        argOfReports: [
          {
            panelId: "1",
            columnName: TAKEOFFDATE_NAME,
            values: {
              current: [params.takeoffDateStart, params.takeoffDateEnd],
            },
          },
        ],
      };
      const routeFilter: FilterValue = {
        filterId: "route",
        filterType: "select",
        argOfReports: [
          {
            panelId: "1",
            columnName: ROUTE_NAME,
            values: params.routes,
          },
        ],
      };
      const flightsFilter: FilterValue = {
        filterId: "flights",
        filterType: "select",
        argOfReports: [
          {
            panelId: "1",
            columnName: FLIGHTNO_NAME,
            values: params.flights,
          },
        ],
      };
      rst.push(airlineFilter);
      rst.push(tkFilter);
      if (params.routes.length) {
        rst.push(routeFilter);
      }
      if (params.flights.length) {
        rst.push(flightsFilter);
      }
      return rst;
    }, [
      params.airline,
      params.flights,
      params.routes,
      params.takeoffDateEnd,
      params.takeoffDateStart,
    ]);

    const builder = useMemo(() => {
      const comparatorWowFilter: ComparatorArg = {
        key: "takeoffdate",
        op: "REPLACE",
        filter: {
          range: {
            field: `dimension.takeoffdate`,
            strRange: {
              lower: params.wowStart,
              upper: params.wowEnd,
            },
          },
        },
      };
      const comparatorYoyFilter: ComparatorArg = {
        key: "takeoffdate",
        op: "REPLACE",
        filter: {
          range: {
            field: `dimension.takeoffdate`,
            strRange: {
              lower: params.yoyStart,
              upper: params.yoyEnd,
            },
          },
        },
      };
      return new RequestBuilder({
        datasetId: DATASET_ID,
        columns,
        dimensions,
        measures,
        chartFilters: [],
        containerFilters: filters,
        preserveComparatorGroups: true,
        sorters: [
          {
            chartUsedColId: AHEADHOUR_CAL_NAME,
            columnName: AHEADHOUR_CAL_NAME,
            sorter: "DESC",
            statistical: "SUM",
          },
        ],
        comparators: [
          {
            name: "filterKey",
            comparisonName: "环比",
            means: [CompareMean.grate],
            args: ["", JSON.stringify(comparatorWowFilter)],
          },
          {
            name: "filterKey",
            comparisonName: "同比",
            means: [CompareMean.grate],
            args: ["", JSON.stringify(comparatorYoyFilter)],
          },
        ],
        granularity: "1m",
      });
    }, [
      columns,
      dimensions,
      measures,
      filters,
      params.wowStart,
      params.wowEnd,
      params.yoyStart,
      params.yoyEnd,
    ]);

    const { requestBody, encrypted } = useMemo(() => {
      return builder.getRequestBody();
    }, [builder]);

    const [{ isLoading }, doFetchLine] = useFetch({
      url: "mytrixQuery",
      head: {},
      lazey: true,
      debugId: "overview-line-chart",
      onSuccess: (r) => {
        const res = JSON.parse(r.data);
        if (res.status === 40000) {
          throw new Error("40000");
        }
        setResponse(res);
      },
    });

    const reqLine = useRefFunc(() => {
      return doFetchLine({
        ext: {
          datasetId: DATASET_ID,
          colIds: [],
          req: encrypted,
        },
      });
    });

    useEffect(() => {
      if (encrypted) {
        setResponse(null);
        reqLine();
      }
    }, [reqLine, encrypted]);

    useImperativeHandle(ref, () => ({
      query: () => {
        reqLine();
      },
    }));

    const schema = useMemo(() => {
      const rst: MetricSchema<DimensionSchemaEx, MeasureSchemaEx> = {
        name: "test",
        version: "1",
        dimensions: columns
          .filter((c) => c.kind === 1)
          .map((c) => FDDatasetCol2DimensionSchemaEx(c)),
        measures: columns
          .filter((c) => c.kind === 0)
          .map((c) => FDDatasetCol2MeasureSchemaEx(c)),
      };
      return rst;
    }, [columns]);

    const renderConfig: ChartRenderConfig = {
      id: "1",
      name: "sdf",
      dimensions,
      measures,
      chartType: "line",
      viewConfig: {
        measureFilter: { type: "multiple", defaultValue: [ACC_REVENUE_NAME] },
        line: {
          echartsOption: {
            xAxis: {
              axisLabel: {
                formatter: (v: number) => {
                  const tmp = durationFormatter(v * 3600, "h");
                  return tmp;
                },
              },
            },
            dataZoom: [
              {
                type: "inside",
              },
              {},
            ],
          },
        },
        downloadName: "test-file",
      },
    };

    if (isLoading) {
      return <Spin />;
    }

    if (!response) {
      return <Refetch refetch={reqLine} />;
    }

    return (
      <div style={{ height: 540, backgroundColor: "#fff", paddingTop: 10 }}>
        {requestBody && (
          <ChartContainer
            request={requestBody}
            response={response}
            schema={schema}
            renderConfig={renderConfig}
          />
        )}
      </div>
    );
  }
);
OverviewLine.displayName = "OverviewLine";
export default OverviewLine;
