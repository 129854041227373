import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AVG_PRICE,
  DATASET_ID,
  DESTINATION_AIRPORT,
  FLIGHT_DURATION,
  ORIGIN_AIRPORT,
  PAX_MASKING,
  TRANSIT_AIRPORT1,
  TRANSIT_AIRPORT2,
  TRANSIT_CLASS,
} from "./ODCols";
import { durationFormatter, showNum, useFetch } from "Utils";
import { Button, Table, message } from "antd";
import {
  CompareMean,
  FilterKeyReplacement,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { groupBy } from "lodash";
import { IDownloadHeader } from "Interface";
import { ODParam, ODTableResData } from "./interface";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { getFilters } from ".";
import useRefFunc from "Utils/useRefFunc";
import { downloadExcel } from "Utils/downloadXLSX";

export interface ODTableProps {
  param: ODParam;
  cols: FDDatasetCol[];
}

/**  Component description */
const ODTable = (props: ODTableProps): ReactElement => {
  const { param, cols } = props;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [listData, setListData] = useState<ODTableResData[]>([]);
  const [TreeData, setTreeData] = useState<ODTableResData[]>([]);

  // #region 表格查询
  const [{ error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "ODTable",
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      console.log("ODTable res: ", res);
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(
        res.rows,
        res.headers
      ) as unknown as ODTableResData[];
      setListData(map);
      console.log("map: ", map);
      const groups = groupBy(map, (item) => item.transit_class);
      console.log("groups: ", groups);
      const rst: ODTableResData[] = [];
      Object.keys(groups).reduce((t, c) => {
        const items = groups[c];
        const categoryName =
          c === "0" ? "Direct" : c === "1" ? "1 stop" : "2 stops";
        const parentRow: ODTableResData = {
          origin_airport: `${categoryName} (${items.length})`,
          transit_airport1: "",
          transit_airport2: "",
          destination_airport: "",
          transit_class: items[0].transit_class,
          SUM_pax_masking: items[0].com_abs_SUM_pax_masking ?? null,
          m_exp_9ac50: items[0].com_abs_m_exp_9ac50 ?? null,
          m_exp_a1f1e: items[0].com_abs_m_exp_a1f1e ?? null,
          key: `${items[0].origin_airport}-${items[0].transit_airport1}-${items[0].transit_airport2}-${items[0].destination_airport}-${items[0].transit_class}`,
          details: items,
        };
        t.push(parentRow);
        return t;
      }, rst);
      console.log("rst: ", rst);
      setTreeData(rst);
    },
    onFinish: () => {
      setIsLoadingData(false);
    },
  });

  const oriFilters = useMemo(() => {
    if (!param.dateRange || param.dateRange.length !== 2) {
      return [];
    }
    const tmpFilters = getFilters(param);
    return tmpFilters;
  }, [param]);

  const builder = useMemo(() => {
    if (!cols.length || !oriFilters.length) {
      return null;
    }
    const filterReplace: FilterKeyReplacement[] = [
      {
        key: ORIGIN_AIRPORT,
        op: "DISABLE",
      },
      {
        key: TRANSIT_AIRPORT1,
        op: "DISABLE",
      },
      {
        key: TRANSIT_AIRPORT2,
        op: "DISABLE",
      },
      {
        key: DESTINATION_AIRPORT,
        op: "DISABLE",
      },
    ];
    const compareFilters = filterReplace.map((f) => JSON.stringify(f));
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns: cols,
      dimensions: [
        ORIGIN_AIRPORT,
        TRANSIT_AIRPORT1,
        TRANSIT_AIRPORT2,
        DESTINATION_AIRPORT,
        TRANSIT_CLASS,
      ],
      measures: [
        {
          id: FLIGHT_DURATION,
          columnName: FLIGHT_DURATION,
          measureConfig: {
            formatConfig: null,
            statisticalConfig: { method: "AVG" },
          },
        },
        PAX_MASKING,
        {
          id: AVG_PRICE,
          columnName: AVG_PRICE,
          measureConfig: {
            formatConfig: { precision: 2 },
            statisticalConfig: { method: "AVG" },
          },
        },
      ],
      chartFilters: [],
      containerFilters: [],
      oriFilters,
      comparators: [
        {
          name: "filterKey",
          comparisonName: "com",
          means: [CompareMean.abs],
          args: [
            [
              ORIGIN_AIRPORT,
              TRANSIT_AIRPORT1,
              TRANSIT_AIRPORT2,
              DESTINATION_AIRPORT,
            ].join(),
            ...compareFilters,
          ],
        },
      ],
      sorters: [PAX_MASKING],
    });
  }, [cols, oriFilters]);

  const { encrypted } = useMemo(() => {
    return builder
      ? builder.getRequestBody()
      : { requestBody: null, encrypted: "" };
  }, [builder]);

  const lastParam = useRef<string>();

  const refetch = useCallback(() => {
    setIsLoadingData(true);
    setListData([]);
    setTreeData([]);
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetch, encrypted]);

  useEffect(() => {
    if (encrypted && encrypted !== lastParam.current) {
      refetch();
      lastParam.current = encrypted;
    }
  }, [encrypted, refetch]);

  const columns = useMemo(() => {
    const tmp: Array<IDownloadHeader<ODTableResData>> = [
      {
        dataIndex: ORIGIN_AIRPORT,
        title: "Origin",
      },
      {
        dataIndex: TRANSIT_AIRPORT1,
        title: "Transfer points",
        render: (v, r) => {
          const arr = [r.transit_airport1, r.transit_airport2].filter(
            (f) => !!f
          );
          return arr.length ? arr.join(",") : "- -";
        },
      },
      {
        dataIndex: DESTINATION_AIRPORT,
        title: "Destination",
      },
      {
        dataIndex: "m_exp_9ac50",
        title: "Duration",
        render: (v) => durationFormatter(v * 60, "m"),
      },
      {
        dataIndex: "SUM_pax_masking",
        title: "Passengers volume(est)",
        render: (v) => showNum(v),
      },
      // {
      //   dataIndex: "m_exp_a1f1e",
      //   title: "Avg.price",
      //   render: (v) => showNum(v),
      // },
    ];
    return tmp;
  }, []);

  const handleDownload = useRefFunc(() => {
    downloadExcel(columns, listData, "Detailed Data");
  });

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h3>Detailed Data</h3>
        </div>
        <div>
          <Button onClick={handleDownload} type="primary">
            Download
          </Button>
        </div>
      </div>
      <Table
        style={{ marginTop: 10, border: "1px solid var(--filter-bg-border)" }}
        loading={isLoadingData}
        dataSource={TreeData}
        columns={columns}
        tableLayout="fixed"
        expandable={{
          expandedRowRender: (record) => (
            <Table
              columns={columns}
              tableLayout="fixed"
              dataSource={record.details}
              size="small"
              pagination={{ pageSize: 10 }}
              style={{ paddingLeft: 56 }}
              showHeader={false}
            ></Table>
          ),
          rowExpandable: () => true,
        }}
        size="small"
      />
    </div>
  );
};
export default ODTable;
