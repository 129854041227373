import React, { useEffect } from "react";
import { Col, Empty, Row, Spin } from "antd";
import { AirportCompareQueryExt } from "Interface";
import { useFetch } from "Utils";
import useGlobal from "Store";
import Refetch from "Components/Refetch";
import _ from "lodash";
import "./index.css";
import CompareCardItem from "./CompareCardItem";
import { getServer } from "Service/server";

interface CompareCardProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  curCardCode: string;
  setCurCardCode: (cardCode: string) => void;
  setHasCardData: (hasCardData: boolean) => void;
  airportCompareQueryExt: AirportCompareQueryExt;
}

const CompareCard: React.FC<CompareCardProps> = (props: CompareCardProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    curCardCode,
    setCurCardCode,
    setHasCardData,
    airportCompareQueryExt,
  } = props;

  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryCondition,
    ext: {
      ...airportCompareQueryExt,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query: queryCondition,
      ext: { ...airportCompareQueryExt },
    });
  };

  useEffect(() => {
    setHasCardData(false);
    refetch();
  }, [queryCondition, airportCompareQueryExt]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (!curCardCode) {
        setCurCardCode(data[0].cardCode);
      }
      setHasCardData(true);
    }
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const groupData = !_.isEmpty(data) ? _.groupBy(data, "cardCode") : {};

  return (
    <Spin spinning={isLoading}>
      <div className="compare-cards-carousel">
        <div className="compare-carousel-con">
          {!_.isEmpty(groupData) ? (
            <Row
              gutter={[{ xs: 6, sm: 12, md: 18 }, 20]}
              className="compare-cards-row"
            >
              {Object.keys(groupData).map((key, idx) => (
                <Col key={idx} className="compare-cards-column">
                  <CompareCardItem
                    data={groupData[key]}
                    curCardCode={curCardCode}
                    setCurCardCode={setCurCardCode}
                  />
                </Col>
              ))}
            </Row>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </Spin>
  );
};

export default CompareCard;
