export const MODULE_CODE: any = {
  1: "passenger_analysis_airport",
  2: "",
};

export const CHART_TABLE_CODE: any = {
  1: {
    nearCityList: "AP0800",
    distribution: "AP0801",
    top10City: "AP0802",
    table: "AP0803",
  },
  2: {},
};

export const QUERY_URL: any = {
  1: {
    nearCityList: "findSurroundingCityOrAirport",
    nearAirportList: "findSurroundingCityOrAirport",
    distributionTop: "queryPassengerAnalysisDistributionTop",
    heatMap: "queryPassengerAnalysisDistributionHot",
    barline: "queryPassengerAnalysisDistributionBarline",
    lossRanking: "queryPassengerAnalysisLossTopAirport",
    lossHeatMap: "queryPassengerAnalysisLossHot",
    lossBarline: "queryPassengerAnalysisLossBarline",
    lossTopAirline: "queryPassengerAnalysisLossTopAirline",
  },
  2: {},
};
