import { BMAP_AK_FAT, BMAP_AK_PRD } from "Constants";
import * as levelKJ from "Page/AI/UserProfile/levelKj.json";

const B = {
  init: (): Promise<unknown> => {
    return new Promise((resolve) => {
      // 如果已加载直接返回
      if (typeof window.BMap !== "undefined") {
        resolve(window.BMap);
        return true;
      }
      // 百度地图异步加载回调处理
      window.bMapInit = () => {
        resolve(window.BMap);
      };

      // 插入script脚本
      const scriptNode = document.createElement("script");
      const BMAP_AK =
        location.host === "flightai.ctrip.com" ||
        location.host === "flightai.aero"
          ? BMAP_AK_PRD
          : BMAP_AK_FAT;
      scriptNode.src =
        "//api.map.baidu.com/api?v=2.0&ak=" + BMAP_AK + "&callback=bMapInit";
      document.body.appendChild(scriptNode);
      // 创建一个计时器监听脚本是否已经加载
      const timeOut: any = -1;
      const fn = () => {
        console.log("等待地图加载");
        if (typeof window.BMap !== "undefined") {
          clearTimeout(timeOut);
          resolve(window.BMap);
          console.log("百度地图脚本初始化成功...");
        } else {
          setTimeout(fn, 10);
        }
      };
      fn();
    });
  },
  getBoundary: (query: string): Promise<unknown> => {
    return new Promise((resolve) => {
      B.init().then((map: any) => {
        console.log("map: ", map);
        const boundary = new map.Boundary();
        boundary.get(query, (rs: any) => {
          resolve(rs);
        });
      });
    });
  },
};
export default B;

const getProvince = async (query: string) => {
  return B.getBoundary(query);
};

const getRegion = (obj: any[]) => {
  const reqList: Array<Promise<unknown>> = [];
  const names: string[] = obj.map((o: any) => o.name);
  names.forEach((n) => {
    reqList.push(getProvince(n));
  });
  Promise.all(reqList).then((values: any[]) => {
    console.log(`values:`, values);
    values.forEach((rs: any, i: number) => {
      console.log(`province: ${names[i]}`, rs);
      // obj[i].coords = filterRs(rs);
      obj[i].oriCoords = rs.boundaries;
    });
  });
};

export const mapClick = (): void => {
  // const { data: levelD } = level as any;
  const { data: levelD } = levelKJ as any;
  // const ll = levelD;
  const ll = levelD.filter((l: any) => l.name === "黑龙江");
  getRegion(ll);
  ll.forEach((l: any) => {
    if (l.children.length) {
      getRegion(l.children);
    }
    l.children.forEach((lc: any) => {
      if (lc.children?.length) {
        getRegion(lc.children);
      }
    });
  });
  console.log("aaaa: ", ll);
  // @ts-ignore
  window.testMap = ll;
};
