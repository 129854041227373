import React, { useEffect, useState } from "react";
import {
  MailOutlined,
  NumberOutlined,
  PhoneOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Cascader,
  Col,
  Form,
  Input,
  message,
  Popover,
  Row,
  Select,
  Upload,
} from "antd";
import { DefaultOptionType } from "antd/lib/cascader";
import { SingleValueType } from "rc-cascader/lib/Cascader";
import { AirlineItem, Area } from "Interface";
import useGlobal from "Store";
import { ACCEPT_IMAGE_TYPES, SERVER } from "Constants";
import VerifyCode from "./verifyCode";
import { useTranslation } from "react-i18next";
import { ELangs } from "i18n/config";
import { useForm } from "antd/es/form/Form";
import { cloneDeep } from "lodash";
import { FileInfo } from "@ctrip/flt-bi-flightai-base";
import useI18Next from "Utils/useI18Next";

const { Option } = Select;

interface IFormComponentProps {
  mode?: string;
}

interface SelectedAirline {
  airlinesCode: string;
  airlinesName: string;
}

const Register = (props: IFormComponentProps) => {
  const [globalState, actions] = useGlobal();
  const { verifyInfo, refreshCode } = globalState;
  const { setLoginPageState, setRefreshCodeState } = actions;

  const [demoType, setDemoType] = useState<number>(0);
  const [airlines, setAirlines] = useState<AirlineItem[]>([]);
  const [selectedAirline, setSelectedAirline] =
    useState<SelectedAirline | null>(null);
  const [area, setArea] = useState<Area[]>([]);
  const [selectedArea, setSelectedArea] = useState<Area>({
    areaCode: "",
    areaType: 5,
    areaName: "",
  });
  const [emailCodeTiming, setEmailCodeTiming] = useState(false);
  const [fileList, setFileList] = useState<FileInfo[]>([]);
  const [second, setSecond] = useState(60);
  const [form] = useForm();
  const { i18n } = useTranslation(["ns1"]);
  const t = useI18Next();
  const language = i18n.language;

  useEffect(() => {
    let interval: any;
    if (emailCodeTiming) {
      interval = setInterval(() => {
        setSecond((preSecond) => {
          if (preSecond <= 1) {
            setEmailCodeTiming(false);
            clearInterval(interval);
            // 重置秒数
            return 60;
          } else {
            return preSecond - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [emailCodeTiming]);

  const sendEmailCode = async (mail: string) => {
    setEmailCodeTiming(true);
    const result: any = await fetch(`${SERVER}emailValidate`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        head: {
          uid: "",
          token: "",
          requestTime: new Date(),
          appId: "",
          clientType: "1",
          clientVersion: "",
          moduleCode: "",
          chartTableCode: "",
        },
        mail,
        validCode: {
          token: verifyInfo.token,
          version: verifyInfo.version,
          requestId: verifyInfo.requestId,
        },
      }),
    });
    const res = await result.json();
    setRefreshCodeState(!refreshCode);
    if (res.flag) {
      message.success({ content: t("common.sendSuccess") });
    } else if (res.errorMsg === "mail error:[wait 5 min]") {
      message.warning({ content: t("user.register.alreadySendIn5Minutes") });
    } else {
      message.error({ content: t("common.sendFailed") });
    }
  };

  const searchArea = async () => {
    const result: any = await fetch(`${SERVER}searchArea`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        head: {
          uid: "",
          token: "",
          requestTime: new Date(),
          appId: "",
          clientType: "1",
          clientVersion: "",
          moduleCode: "common",
          chartTableCode: null,
        },
        type: 1,
      }),
    });
    const res = await result.json();
    if (res.data) {
      setArea(res.data[0] && res.data[0].children ? res.data[0].children : []);
    } else {
      message.error({ content: t("common.getAirportsFailed") });
    }
  };

  const searchAirlines = async () => {
    const result: any = await fetch(`${SERVER}findAirlines`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        head: {
          uid: "",
          token: "",
          requestTime: new Date(),
          appId: "",
          clientType: "1",
          clientVersion: "",
          moduleCode: "common",
          chartTableCode: null,
        },
        type: 1,
      }),
    });
    const res = await result.json();
    if (res.data) {
      setAirlines(res.data);
    } else {
      message.error({ content: t("common.getAirlinesFailed") });
    }
  };

  const register = async (params: any) => {
    const result: any = await fetch(`${SERVER}register`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          name: params.name,
          account: params.account,
          mail: params.mail,
          mobilePrefix: "+86",
          mobile: params.mobile || "",
          mainUnitCode:
            demoType === 1
              ? selectedArea.areaCode
              : demoType === 2
              ? selectedAirline?.airlinesCode
              : "",
          mainUnitName:
            demoType === 1
              ? selectedArea.areaName
              : demoType === 2
              ? selectedAirline?.airlinesName
              : params.unitOther,
          remark: "",
          photos: params.photos || [],
          demoType,
        },
        validCode: {
          token: verifyInfo.token,
          version: verifyInfo.version,
          requestId: verifyInfo.requestId,
        },
        emailCode: params.mailCode,
      }),
    });
    const res = await result.json();
    if (res.flag) {
      setLoginPageState({
        mode: "register-success",
      });
    } else {
      const code = res.ResponseStatus.Errors[0].ErrorCode;
      message.error({ content: t(`error.${code}`) });
      setRefreshCodeState(!refreshCode);
    }
  };

  const displayRender = (labels: string[]) => (
    <span>{labels[labels.length - 1]}</span>
  );

  const handleDemoTypeChange = (value: any) => {
    setDemoType(value);
  };

  const handleRegisterSubmit = async (e: any) => {
    // e.preventDefault();
    const tmp = await form.validateFields();
    const rst = cloneDeep(tmp);
    console.log(rst.photoFiles);
    if (rst.photoFiles?.fileList) {
      if (rst.photoFiles.fileList.length === fileList.length) {
        rst.photos = fileList;
        delete rst.photoFiles;
      } else {
        message.error({ content: t("user.register.imageError") });
        return;
      }
    }
    if (rst) {
      if (verifyInfo.token === "") {
        message.error({ content: t("login.needSecurity") });
      } else {
        const value = form.getFieldsValue();
        value.photos = fileList;
        console.log("values :", value);
        register(value);
      }
    }
  };

  const onCascaderChange = (
    value: SingleValueType,
    selectedOptions?: DefaultOptionType[]
  ) => {
    if (selectedOptions) {
      const { areaType, areaCode, areaName } =
        selectedOptions[selectedOptions.length - 1];
      setSelectedArea({ areaType, areaCode, areaName });
    }
  };

  const showSearch = {
    filter: (value: string, path: any[]) =>
      !!path.find(
        (item) =>
          item.areaType === 5 &&
          (item.areaCode.includes(value.toUpperCase()) ||
            item.areaName.includes(value))
      ),
    limit: 10,
    render: (value: string, path: any[]) => (
      <span>
        {path[path.length - 1].areaName}({path[path.length - 1].areaCode})
      </span>
    ),
  };

  const handleToggleLogin = () => {
    setLoginPageState({
      mode: "login",
    });
  };

  const handleSendEmailCode = async () => {
    const rst = await form.validateFields(["mail"]);
    if (rst) {
      if (verifyInfo.token === "") {
        message.error({ content: t("login.needSecurity") });
      } else {
        sendEmailCode(form.getFieldValue("mail"));
      }
    }
  };

  const handleChangeAline = (val: string, option: any) => {
    setSelectedAirline({
      airlinesCode: option.props.value,
      airlinesName: option.props.title,
    });
  };

  useEffect(() => {
    if (demoType === 1 && area.length === 0) {
      searchArea();
    } else if (demoType === 2 && airlines.length === 0) {
      searchAirlines();
    }
  }, [demoType]);

  const uploadProp = {
    action: "test",
    maxCount: 3,
    accept: ACCEPT_IMAGE_TYPES.join(","),
    beforeUpload: (file: any) => {
      const reader = new FileReader();

      if (file) {
        if (file.size > 1024 * 1024 * 5) {
          // 最大支持的文件大小，单位：字节
          message.destroy();
          message.error({ content: t("user.register.fileTooLarge") });
          // this.props.onChange({target:{value:oldFileUrl}});
          return false;
        }
        const fileName: string = file.name || "";
        const nameExt = fileName.substring(fileName.lastIndexOf("."));
        if (!ACCEPT_IMAGE_TYPES.includes(nameExt.toLocaleLowerCase())) {
          // 不支持的文件格式
          message.destroy();
          message.error({ content: t("user.register.notSupportedType") });
          // this.props.onChange({target:{value:oldFileUrl}});
          return false;
        }
      }

      reader.onloadstart = () => {};

      reader.onload = () => {
        const result = reader.result + "";
        file.base64 = result.split(",")[1];
        file.fileName = file.name;
      };

      reader.onerror = () => {};

      reader.readAsDataURL(file);
      const list: any[] = [...fileList];
      if (list.indexOf(file) < 0) {
        list.push(file);
      }
      setFileList(list);
      return false;
    },
    onRemove: (file: any) => {
      const index = fileList.findIndex((f: any) => f.name === file.name);
      if (index >= 0) {
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      }
    },
  };

  const photosHelp = (
    <Popover
      title={t("user.register.photos.popover.title")}
      content={
        <div style={{ width: 350 }}>
          {t("user.register.photos.popover.content")}
        </div>
      }
    >
      {t("user.register.photos.label")}
    </Popover>
  );

  return (
    <Card className="login-card">
      <div className="login-card-title-wrap">
        <div className="login-card-title">{t("user.register.title")}</div>
      </div>
      <Form
        form={form}
        onFinish={handleRegisterSubmit}
        onChange={(e) => console.log("form change:", e)}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: t("user.register.enterName") }]}
        >
          <Input
            prefix={<UserOutlined className="login-input-icon" />}
            placeholder={t("user.register.name")}
            className="login-card-input"
          />
        </Form.Item>
        <div className="register-unit-type">
          <Form.Item
            label="DemoType"
            name="demoType"
            rules={[
              {
                required: true,
                message: t("user.register.selectCompanyType"),
              },
            ]}
          >
            <Select
              size="large"
              onChange={handleDemoTypeChange}
              placeholder={t("user.register.selectCompanyType")}
              popupClassName="register-unit-type-select"
              style={{ fontSize: 14 }}
            >
              <Option value={1}>{t("common.airport")}</Option>
              <Option value={2}>{t("common.airline")}</Option>
              <Option value={3}>{t("common.other")}</Option>
            </Select>
          </Form.Item>
        </div>
        {demoType === 1 ? (
          <Form.Item
            name="unitAirport"
            rules={[{ required: true, message: t("common.selectAirport") }]}
          >
            <Cascader
              options={area}
              placeholder={t("common.selectAirportMsg")}
              expandTrigger="hover"
              allowClear={false}
              fieldNames={{
                label: "areaName",
                value: "areaCode",
                children: "children",
              }}
              onChange={onCascaderChange}
              suffixIcon={<span></span>}
              showSearch={showSearch}
              displayRender={displayRender}
              notFoundContent={t("common.noData")}
              size="large"
              className="register-cascader-airport"
              dropdownClassName="register-cascader-airport-dropdown"
            />
          </Form.Item>
        ) : demoType === 2 ? (
          <Form.Item
            name="unitAline"
            rules={[{ required: true, message: t("common.selectAirline") }]}
          >
            <Select
              size="large"
              showSearch
              onChange={handleChangeAline}
              placeholder={t("common.selectAirlineMsg")}
              popupClassName="register-unit-type-select"
              style={{ fontSize: 14 }}
              // TODO 修复代码
              // @ts-ignore 历史代码, 稍后修复
              filterOption={(val: string, option: React.ReactElement) =>
                option.props.value.toLowerCase().indexOf(val.toLowerCase()) >=
                  0 ||
                option.props.title.toLowerCase().indexOf(val.toLowerCase()) >= 0
              }
            >
              {airlines.map((item) => (
                <Option
                  key={item.airlinesCode}
                  value={item.airlinesCode}
                  title={item.airlinesName}
                >
                  {item.airlinesName}({item.airlinesCode})
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : demoType === 3 ? (
          <Form.Item
            name="unitOther"
            rules={[
              { required: true, message: t("user.register.enterCompany") },
            ]}
          >
            <Input
              className="login-card-input"
              placeholder={t("user.register.enterCompany")}
            />
          </Form.Item>
        ) : undefined}
        <Form.Item name="depart">
          <Input
            placeholder={t("user.register.enterDepart")}
            className="login-card-input"
          />
        </Form.Item>
        <Form.Item name="photoFiles" label={photosHelp}>
          <Upload {...uploadProp}>
            <Button icon={<UploadOutlined />}>
              {t("user.register.photos.button")}
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="mobile"
          rules={
            language === ELangs.en
              ? []
              : [
                  { required: true, message: t("login.inputAccount") },
                  {
                    pattern: new RegExp(/^1[0-9]{10}$/),
                    message: t("login.inputAccount"),
                  },
                ]
          }
        >
          <Input
            prefix={<PhoneOutlined className="login-input-icon" />}
            placeholder={t("user.register.enterMobileMsg")}
            className="login-card-input"
          />
        </Form.Item>
        <Form.Item
          name="mail"
          extra={t("user.register.mailHelp")}
          rules={[
            { required: true, message: t("user.register.enterEmail") },
            {
              pattern: new RegExp(
                /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
              ),
              message: t("user.register.enterEmailWarn"),
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="login-input-icon" />}
            placeholder={t("user.register.enterEmailPlaceholder")}
            className="login-card-input"
          />
        </Form.Item>
        <Form.Item
          name="mailCode"
          rules={[
            {
              required: true,
              message: t("user.register.enterEmailSecurityCode"),
            },
            {
              pattern: new RegExp(/^[0-9]{6}$/),
              message: t("user.register.enterEmailSecurityCodeMsg"),
            },
          ]}
        >
          <Row gutter={16}>
            <Col>
              <Input
                prefix={<NumberOutlined className="login-input-icon" />}
                placeholder={t("user.register.enterEmailSecurityCode")}
                className="login-card-input"
              />
            </Col>
            <Col>
              <Button
                style={{ width: 100 }}
                disabled={emailCodeTiming}
                type="link"
                onClick={handleSendEmailCode}
              >
                {t("user.register.sendValidateCode")}{" "}
                {emailCodeTiming ? second : undefined}
              </Button>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item>
          <VerifyCode id="register-verification-code" />
        </Form.Item>
        <Form.Item>
          <>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="login-form-button"
            >
              {t("user.register.sendRegisterInfo")}
            </Button>
            {t("user.register.hadAccount")}
            <a style={{ marginLeft: 10 }} href="#" onClick={handleToggleLogin}>
              {t("user.register.signInNow")}
            </a>
          </>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Register;
