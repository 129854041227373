import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  InfoCircleOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Empty,
  List,
  Popover,
  Radio,
  Row,
  Spin,
  Typography,
} from "antd";
import useGlobal from "Store";
import { AirlinesQueryExt, FlightArea } from "Interface";
import _ from "lodash";
import { calculateCardsContrastVal, useFetch } from "Utils";
import { PERCENT_VALUES } from "Constants";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import { getServer } from "Service/server";
import "./index.css";
import RankSortBtn from "../RankSortBtn";

const { Title, Text } = Typography;

interface IProps {
  title: string;
  tab: any;
  style?: object;
  queryUrl: string;
  moduleCode: string;
  chartTableCode?: string;
  sortable?: boolean;
  cardCode?: string;
  airlinesQueryExt?: AirlinesQueryExt;
  selectItem?: (item: FlightArea | null) => void;
  airlinesSearchTrendsExt?: FlightArea;
  airlinesOnsaleAirlineExt?: FlightArea;
  setChartsType?: (v: number) => void;
  ext?: any;
}

interface Tab {
  title: string;
  titleTip?: string;
  type: 0 | 1;
  compare: boolean;
}

const AirlinesRanking: React.FC<IProps> = (props: IProps) => {
  const {
    title,
    tab,
    style,
    queryUrl,
    moduleCode,
    sortable,
    cardCode,
    chartTableCode,
    airlinesQueryExt,
    airlinesSearchTrendsExt,
    airlinesOnsaleAirlineExt,
    selectItem,
    setChartsType,
    ext: otherExt,
  } = props;
  const [currentTab, setCurrentTab] = useState<Tab | null>(null);
  const [sort, setSort] = useState(1);
  const [curCityIndex, setCurCityIndex] = useState<number | null>(null);
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, systemType } = globalState;
  const { compareType } = airlinesQueryCondition;
  const getExt = useCallback(() => {
    let obj;
    if (airlinesQueryExt) {
      // 航线分析
      obj = {
        cardCode,
        orderSort: sort,
        orderByType: currentTab?.compare ? 1 : 0,
        compare: currentTab ? currentTab.compare : false,
        filter: airlinesQueryExt,
      };
    } else if (airlinesSearchTrendsExt) {
      // 搜索指数
      obj = {
        ...airlinesSearchTrendsExt,
        type: currentTab ? currentTab.type || 0 : 0,
        orderSort: sort,
      };
    } else if (airlinesOnsaleAirlineExt) {
      // 航班计划
      obj = {
        ...airlinesOnsaleAirlineExt,
        type: currentTab ? currentTab.type : 0,
        orderSort: sort,
      };
    } else {
      // 核心概览
      obj = {
        cardCode,
        orderSort: sort,
        compare: currentTab ? currentTab.compare : false,
      };
    }
    return obj;
  }, [
    airlinesOnsaleAirlineExt,
    airlinesQueryExt,
    airlinesSearchTrendsExt,
    cardCode,
    currentTab,
    sort,
  ]);
  const ext = useMemo(() => getExt(), [getExt]);
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: airlinesQueryCondition,
    ext,
    lazey: true,
  });

  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;

  const refetch = useCallback(() => {
    if (currentTab) {
      doFetch({
        query: airlinesQueryCondition,
        ext: {
          ...ext,
          ...otherExt,
        },
      });
    }
  }, [airlinesQueryCondition, currentTab, doFetch, ext, otherExt]);

  useEffect(() => {
    setCurrentTab(tab[0]);
    if (setChartsType) {
      setChartsType(tab[0].type);
    }
  }, [tab]);

  useEffect(() => {
    setCurCityIndex(null);
    if (selectItem) {
      selectItem(null);
    }
    refetch();
  }, [refetch, selectItem]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleSort = () => {
    setSort(sort === 0 ? 1 : 0);
  };

  const handleModeChange = (e: any) => {
    setCurrentTab(e.target.value);
    if (setChartsType) {
      setChartsType(e.target.value.type);
    }
  };

  const defaultItem = (item: any, idx: number) => {
    const itemTitle = `${item.dportName} - ${item.aportName}`;
    return (
      <List.Item className="ranking-item">
        <div className="ranking-badge">{idx + 1}</div>
        <div className="ranking-item-content" title={itemTitle}>
          <Text className="ranking-item-content-span">{itemTitle}</Text>
        </div>
        <div className="ranking-item-value">
          <Text>
            {currentTab ? (
              currentTab.compare === false ? (
                <ValueNum
                  type={isPercentVal ? "percentage" : "num"}
                  value={item.value}
                  precision={
                    cardCode &&
                    [
                      "capacity_production_cost",
                      "income_production_cost",
                    ].includes(cardCode)
                      ? 3
                      : 0
                  }
                />
              ) : (
                <ValueNum
                  type={"percentage"}
                  value={calculateCardsContrastVal(
                    item.value,
                    item.compareValue,
                    compareType,
                    systemType,
                    cardCode
                  )}
                />
              )
            ) : undefined}
          </Text>
        </div>
      </List.Item>
    );
  };

  const handleChooseItem = (value: any, idx: number) => {
    if (selectItem) {
      if (curCityIndex === idx) {
        setCurCityIndex(null);
        selectItem({
          departArea: {
            areaType: 0,
            areaCode: "",
            areaName: "",
          },
          arriveArea: {
            areaType: 0,
            areaCode: "",
            areaName: "",
          },
        });
      } else {
        setCurCityIndex(idx);
        selectItem({
          departArea: {
            areaType: 4,
            areaCode: value.dcityCode,
            areaName: value.dcityName,
          },
          arriveArea: {
            areaType: 4,
            areaCode: value.acityCode,
            areaName: value.acityName,
          },
        });
      }
    }
  };

  const searchItem = (item: any, index: any) => {
    const computeValue = _.round(item.value);

    return (
      <List.Item
        className={`ranking-item optional ${
          index === curCityIndex ? "cur" : ""
        }`}
        onClick={() => handleChooseItem(item, index)}
      >
        <div className="ranking-badge">{index + 1}</div>
        <div className="ranking-item-content">
          <Text>
            {item.dcityName} - {item.acityName}
          </Text>
        </div>
        <div className="ranking-item-value">
          <Text>
            <ValueNum type="num" value={computeValue} />
          </Text>
        </div>
      </List.Item>
    );
  };

  const onSaleItem = (item: any, index: number) => (
    <List.Item className="ranking-item">
      <div className="ranking-badge">{index + 1}</div>
      <div className="ranking-item-content">
        <Text>
          {item.dportName} - {item.aportName}
        </Text>
      </div>
      <div className="ranking-item-value">
        <Text>
          <ValueNum type="num" value={item.value} />
        </Text>
      </div>
    </List.Item>
  );

  let listItem: any;
  switch (moduleCode) {
    case "search_index_airlines":
      listItem = searchItem;
      break;
    case "flight_plan_airlines":
      listItem = onSaleItem;
      break;
    default:
      listItem = defaultItem;
  }

  return (
    <Spin spinning={isLoading}>
      <Card className="ranking-card" style={style}>
        {title && <Title level={4}>{title}</Title>}
        <Row justify="space-between" data-ut-id="ranking-tab">
          <Radio.Group
            onChange={handleModeChange}
            value={currentTab}
            id="rankTab"
          >
            {tab.map((item: any, index: any) => (
              <Radio.Button value={item} key={index}>
                {item.title}
                {item.titleTips ? (
                  <Popover content={item.titleTips}>
                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                  </Popover>
                ) : undefined}
              </Radio.Button>
            ))}
          </Radio.Group>
          {sortable ? (
            <RankSortBtn sort={Boolean(sort)} handleSortChange={handleSort} />
          ) : undefined}
        </Row>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={data}
            style={{ margin: "5px 0 -10px" }}
            renderItem={(item: any, index) => listItem(item, index)}
          />
        )}
      </Card>
    </Spin>
  );
};

export default AirlinesRanking;
