import React, { ReactElement, useMemo, useState } from "react";
import DetailItemIndex from "./DetailItemIndex";
import { FormatConfig } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { DATE_FORMAT, WEEK } from "Constants";
import moment from "moment";
import { Col, Popover, Row } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import useRefFunc from "Utils/useRefFunc";
import "./DetailCardItem.scss";
import { getSharkText } from "Utils/i18nGlobal";

const COMPARE_LIST = ["yoy", "wow"] as const;
export type CompareType = (typeof COMPARE_LIST)[number];
const COMPARE_NAME_MAP: Record<CompareType, string> = {
  yoy: "同比",
  wow: "环比",
};

export interface DetailItemValue {
  value: number;
  yoy: number;
  wow: number;
  trend: Array<{ datachange_lasttime: string; value: number }>;
}

export interface DetailCardDataItem {
  takeoffdate: string;
  flightno: string;
  route: string;
  income: DetailItemValue;
  loadFactor: DetailItemValue;
  avgPrice: DetailItemValue;
}
export interface DetailCardItemProps {
  updateTime?: string;
  data: DetailCardDataItem;
  onTitleClick: () => void;
}

/**  Component description */
const DetailCardItem = (props: DetailCardItemProps): ReactElement => {
  const { updateTime, data, onTitleClick } = props;
  const indexes = useMemo(() => {
    const tmp: Array<{ title: string; data: any; formatConfig: FormatConfig }> =
      [
        {
          title: "收入",
          data: data.income,
          formatConfig: {
            type: "decimal",
            thousandths: 3,
            precision: 0,
          },
        },
        {
          title: "客座率",
          data: data.loadFactor,
          formatConfig: {
            type: "percent",
            precision: 2,
          },
        },
        {
          title: "均价",
          data: data.avgPrice,
          formatConfig: {
            type: "decimal",
            thousandths: 3,
            precision: 2,
          },
        },
      ];
    return tmp;
  }, [data.avgPrice, data.income, data.loadFactor]);

  const title = useMemo(() => {
    return (
      <Row gutter={[10, 10]} style={{ color: "#1890ff" }}>
        <Col>{moment(data.takeoffdate).format(DATE_FORMAT)}</Col>
        <Col>{WEEK[moment(data.takeoffdate).weekday()]}</Col>
        <Col>{data.route}</Col>
        <Col>{data.flightno}</Col>
      </Row>
    );
  }, [data.flightno, data.route, data.takeoffdate]);

  const [compareType, setCompareType] = useState<"yoy" | "wow">("wow");
  const switchCom = useMemo(() => {
    return (
      <div>
        <span style={{ fontSize: "90%", color: "#aaa" }}>
          {getSharkText("card.switch_card.button")}
        </span>
        {COMPARE_LIST.map((d) =>
          d === compareType ? (
            <div key={d}>
              <strong>{COMPARE_NAME_MAP[d]}</strong>
            </div>
          ) : (
            <div style={{ color: "#aaa" }} key={d}>
              {COMPARE_NAME_MAP[d]}
            </div>
          )
        )}
      </div>
    );
  }, [compareType]);
  const switchCompareType = useRefFunc(() => {
    const idx = COMPARE_LIST.indexOf(compareType);
    const newIdx = (idx + 1) % COMPARE_LIST.length;
    setCompareType(COMPARE_LIST[newIdx]);
  });

  return (
    <div className="profit-monitor_detail_card" style={{ padding: 10 }}>
      <div className="card-header">
        <span onClick={onTitleClick} style={{ cursor: "pointer" }}>
          {title}
        </span>
        <Popover content={switchCom}>
          <SwapOutlined className="swap-icon" onClick={switchCompareType} />
        </Popover>
      </div>
      <div className="update-time">{updateTime || "-"}</div>
      {indexes.map((idx) => (
        <DetailItemIndex
          formatConfig={idx.formatConfig}
          key={idx.title}
          data={idx.data}
          title={idx.title}
          compareType={compareType}
        />
      ))}
    </div>
  );
};
export default DetailCardItem;
