import React, { ReactNode, useEffect, useMemo, useState } from "react";
import moment from "moment";
import "moment/locale/zh-cn";
import { Affix, Alert, Col, Row } from "antd";
import useGlobal from "Store";
import {
  getAirportsOrAirlinesArr,
  getDateRange,
  getIsDemo,
  isAirportMode,
  useSessionStorage,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import FlightClassCom from "./FlightClassCom";
import Airlines from "./Airlines";
import InOut from "./inOut";
import DateRangePicker from "./DateRangePicker";
import {
  ECompareTypeV2,
  IDateMode,
  Module,
  Role,
  T_YEAR_COMPARE_TYPE,
} from "Interface";
import HeaderAlert from "Components/HeaderAlert";
import "./index.css";

import {
  CompareContent,
  DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
  DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
  EYearCompareType,
} from "Constants";
import { getDateRangeGroupName, getDefaultAirline } from "Utils/global";
import AirportSelectorWithGroup from "Components/AirportGroup/AirportSelectorWithGroup";
import { useLocation } from "react-router-dom";
import Airport from "./Airport";
import CompareTypeGlobal from "Components/CompareType/CompareTypeGlobal";

moment.locale("zh-cn");
interface ToolbarProps {
  defaultDateMode: IDateMode;
  shouldDisableDate?: boolean;
  useAirportGroup?: boolean;
  airportGroupMultiple?: boolean;
  module: Module;
  setLoading: (loading: boolean) => void;
  airlinesCompareType?: ECompareTypeV2;
  compareContent?: Partial<Record<ECompareTypeV2, CompareContent>>;
  isShowCompareType?: boolean;
  isShowInOut?: boolean;
  isShowAirlineAirport?: boolean;
  topMessage?: ReactNode | string;
  extendTools?: ReactNode;
  airportCompareTypes?: Partial<Record<ECompareTypeV2, ECompareTypeV2>>;
  airlinesCompareTypes?: Partial<Record<ECompareTypeV2, ECompareTypeV2>>;
  flightClassName?: string[];
  intl?: boolean;
}

const Toolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {
  const {
    defaultDateMode,
    shouldDisableDate,
    useAirportGroup = false,
    airportGroupMultiple = false,
    setLoading,
    module,
    airlinesCompareType = DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
    compareContent,
    isShowCompareType = true,
    isShowInOut = true,
    isShowAirlineAirport = true,
    topMessage,
    extendTools,
    airportCompareTypes = EYearCompareType,
    airlinesCompareTypes,
    flightClassName,
    intl,
  } = props;

  const location = useLocation();
  const [globalState, actions] = useGlobal();
  const {
    queryCondition,
    userInfo,
    systemType,
    airlinesQueryCondition,
    moduleConfig,
    dateRangeInGroup,
  } = globalState;
  const { airlines } = airlinesQueryCondition;
  const { roleList } = userInfo;
  const {
    setQueryCondition,
    setAirlinesQueryCondition,
    setQueryConditionAttr,
    setAirlinesQueryConditionAttr,
  } = actions;
  const { pathname } = location;
  const isAirport = isAirportMode(systemType);
  const isShowDateRangePicker = defaultDateMode !== "none";
  const flightClass =
    systemType === 1
      ? queryCondition.flightClass
      : airlinesQueryCondition.flightClass;

  const [isShowAlert, setIsShowAlert] = useSessionStorage(
    "flightai" + pathname,
    true
  );
  const { loading: moduleConfigLoading } = moduleConfig;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;
  /**
   * 国航用户特别处理, 航线分析页面, 国航拥有三大航权限
   */
  const userAirlines = useMemo(() => {
    if (
      airlinesRole &&
      airlinesRole.permissionCode === "CA" &&
      pathname === "/airline_analysis"
    ) {
      return [
        {
          code: "CA",
          name: getSharkText("config_page_china_airlines"),
        },
        {
          code: "CZ",
          name: getSharkText("config_page_southern_airlines"),
        },
        {
          code: "MU",
          name: getSharkText("config_page_eastern_airlines"),
        },
        {
          code: "FM",
          name: getSharkText("config_page_shanghai_airlines"),
        },
      ];
    }
    return getAirportsOrAirlinesArr(airlinesRole, false);
  }, [airlinesRole, pathname]);
  const [yoyIdx, setYoyIdx] = useState<T_YEAR_COMPARE_TYPE>(
    DEFAULT_AIRPORT_COMPARE_TYPE_VALUE
  );
  const isDemo = getIsDemo(location.pathname, globalState);

  const getFlightClass = (isAirportQuery: boolean) => {
    switch (pathname) {
      case "/flight_plan_intl":
        return 2;
      case "/price_monitor":
        return isAirportQuery
          ? queryCondition.flightClass
          : airlinesQueryCondition.flightClass === 0
          ? 1
          : airlinesQueryCondition.flightClass;
      case "/tourist_source":
        return 1;
      default:
        return isAirportQuery
          ? queryCondition.flightClass
          : airlinesQueryCondition.flightClass;
    }
  };

  const getFlightClassAlert = () => {
    switch (pathname) {
      case "/price_monitor":
        return flightClass !== 0
          ? getSharkText("key.overall_not_supported.hint")
          : "";
      case "/tourist_source":
        return flightClass === 1
          ? getSharkText("key.domestic_supported.hint")
          : "";
      default:
        return undefined;
    }
  };

  const alertMessage = topMessage || getFlightClassAlert();

  useEffect(() => {
    const groupName = getDateRangeGroupName(pathname);
    const range = isShowDateRangePicker
      ? getDateRange(defaultDateMode)
      : [moment(new Date()), moment(new Date())];
    const groupRange =
      groupName && dateRangeInGroup[groupName].length
        ? dateRangeInGroup[groupName]
        : null;
    const startDate = groupRange
      ? groupRange[0]
      : range
      ? range[0].format("YYYY-MM-DD")
      : "";
    const endDate = groupRange
      ? groupRange[1]
      : range
      ? range[1].format("YYYY-MM-DD")
      : "";
    if (isAirport) {
      setQueryCondition({
        ...queryCondition,
        startDate,
        endDate,
        flightClass: getFlightClass(true),
      });
    } else {
      if (airlines) {
        setAirlinesQueryCondition({
          ...airlinesQueryCondition,
          startDate,
          endDate,
          compareType: airlinesCompareType,
          flightClass: getFlightClass(false),
        });
      } else {
        const tmp = getDefaultAirline(airlinesRole);
        setAirlinesQueryCondition({
          ...airlinesQueryCondition,
          startDate,
          endDate,
          ...tmp,
          compareType: airlinesCompareType,
          flightClass: getFlightClass(false),
        });
      }
    }
    setLoading(false);
  }, [defaultDateMode, systemType]);

  useEffect(() => {
    const yoy = yoyIdx;
    if (isAirport) {
      setQueryConditionAttr({
        compareType: yoy,
      });
    } else {
      setAirlinesQueryConditionAttr({
        compareType:
          typeof airlinesCompareType !== "undefined"
            ? airlinesCompareType
            : yoy,
      });
    }
  }, [yoyIdx]);

  return (
    <div>
      <div className="content-white">
        <HeaderAlert module={module} />
      </div>
      {alertMessage && isShowAlert ? (
        <Alert
          className="flightClassAlert"
          message={alertMessage}
          type="warning"
          banner
          closable
          afterClose={() => setIsShowAlert(false)}
        />
      ) : undefined}
      <Affix offsetTop={0} className="toolbar-wrap">
        <div className="toolbar">
          <Row gutter={[{ md: 24 }, 20]} align="middle">
            {intl ? (
              <FlightClassCom flightClassName={flightClassName} intl={intl} />
            ) : (
              <Col>
                <FlightClassCom flightClassName={flightClassName} intl={intl} />
              </Col>
            )}
            {!moduleConfigLoading ? (
              <Col>
                {isShowAirlineAirport ? (
                  isAirport ? (
                    useAirportGroup ? (
                      <AirportSelectorWithGroup
                        isDemo={isDemo}
                        multipleGroup={airportGroupMultiple}
                      />
                    ) : (
                      <Airport />
                    )
                  ) : (
                    <Airlines airlines={userAirlines} />
                  )
                ) : undefined}
              </Col>
            ) : undefined}
            {isShowInOut && isAirport ? (
              <Col>
                <InOut />
              </Col>
            ) : undefined}
            {isShowDateRangePicker ? (
              <Col>
                <DateRangePicker
                  defaultDateMode={defaultDateMode}
                  shouldDisableDate={shouldDisableDate}
                />
              </Col>
            ) : undefined}
            {isShowCompareType ? (
              <Col>
                <CompareTypeGlobal
                  title={getSharkText("key.benchmarking")}
                  AirportCompareOptions={airportCompareTypes}
                  AirlineCompareOptions={airlinesCompareTypes}
                  compareContent={compareContent}
                />
              </Col>
            ) : undefined}
            {extendTools}
          </Row>
        </div>
      </Affix>
    </div>
  );
};

export default Toolbar;
