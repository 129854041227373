import React, { useMemo, useState } from "react";
import { Alert, Button } from "antd";
import useGlobal from "Store";
import { Module } from "Interface";
import moment from "moment";
import RightModal from "Components/RightModal";
import "./index.css";
import { getModule, getModuleNameFromPath } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { DemoModal } from "./Modal";
import HTMLReactParser from "html-react-parser";
import { useLocation } from "react-router-dom";

interface IProps {
  module?: Module;
}

const HeaderAlert: React.FC<IProps> = (props: IProps) => {
  let { module } = props;
  const location = useLocation();
  const { pathname } = location;

  const [modalVisible, setModalVisible] = useState(false);
  const [globalState, actions] = useGlobal();
  const { userInfo, systemType, demoModalShowed } = globalState;
  const { unitList } = userInfo;
  const userUnit = useMemo(() => {
    if (unitList && unitList.length) {
      // 目前一个用户只属于一个用户组
      return unitList[0];
    }
  }, [unitList]);
  if (!module) {
    const moduleName = getModuleNameFromPath(pathname, systemType);
    module = getModule(moduleName, userInfo.moduleList);
  }
  const isFormal = module ? module.moduleStatus === 1 : false;
  const isDemo = module ? module.moduleStatus === 0 : true;
  const { setHelpVisibility } = actions;

  const handleClick = () => {
    setModalVisible(true);
    setHelpVisibility(false);
  };

  const getText = (moduleStatus: number) => {
    const formalText = getSharkText("headeralert.data_estimated.info");
    // 如果到期15天内, 则提示需用试用期, 否则提示同正式用户;
    const validPeriod = moment(module && module.endTime).diff(moment(), "d");
    switch (moduleStatus) {
      case 0:
        return getSharkText("headeralert.demo_version.info");
      case 1:
        return formalText;
      // 到期15天内
      case 2:
        if (validPeriod <= 15) {
          if (userUnit?.autoRenew === 1) {
            return formalText;
          }
          return (
            <>
              {`${getSharkText(
                "headeralert.data_estimated.info"
              )}${getSharkText(
                "headeralert.invalid_notification_after.info"
              )}${moment(module && module.endTime).format("LL")}${getSharkText(
                "headeralert.invalid_notification_after.info"
              )}`}
              ，
              <a onClick={handleClick}>{getSharkText("headeralert.renewal")}</a>
            </>
          );
        } else {
          return formalText;
        }
    }
  };

  const text = <span>{getText(module ? module.moduleStatus : 0)}</span>;

  const description = isDemo ? (
    <span>
      {HTMLReactParser(getSharkText("headeralert.acquire_real_data.button"))}
      <Button style={{ marginLeft: 5 }} type="primary" onClick={handleClick}>
        {getSharkText("headeralert.apply.button")}
      </Button>
    </span>
  ) : (
    ""
  );

  return (
    <div>
      <Alert
        message={text}
        description={description}
        banner
        type={isFormal ? "info" : "warning"}
        showIcon
      />
      <RightModal visible={modalVisible} setVisible={setModalVisible} />
      {!demoModalShowed && isDemo ? <DemoModal /> : undefined}
    </div>
  );
};

export default HeaderAlert;
