import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { FDDataset, FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { useServices } from "../useServices";
import useGlobalState from "Store";
import DataColumns from "./DataColumns";
import { EMPTY_ARRAY } from "Constants";
import useRefFunc from "Utils/useRefFunc";
import { arrayUpsert } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { cloneDeep, differenceBy, merge, uniqBy } from "lodash";
import { isExpCol } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/common";
import { IValueLabelOption } from "Interface";

const DEFAULT_DATASET: FDDataset = {
  id: null,
  name: null,
  datasource: null,
  sqlTpl: null,
  description: null,
  userCreate: null,
  jobid: null,
  jobidruleid: null,
  realtimeMetric: null,
  priority: "P3",
  permissionUserField: null,
  permissionGroupField: null,
  permissionUserWhitelist: null,
  permissionGroupWhitelist: null,
  cache: 0,
};

export interface DatasetEditorHandler {
  open: (v?: FDDataset, cols?: FDDatasetCol[]) => void;
}

export interface Props {
  refreshDatasets: () => void;
}

const DatasetEditor = forwardRef(
  (props: Props, ref: Ref<DatasetEditorHandler>) => {
    const { refreshDatasets } = props;
    const [open, setOpen] = useState(false);
    const [form] = useForm();
    const [globalState] = useGlobalState();
    const { userInfo } = globalState;
    const { user } = userInfo;
    const [previewDataset, setPreviewDataset] = useState<FDDataset | null>(
      null
    );
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [previewCols, setPreviewCols] = useState<FDDatasetCol[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [permissionCols, setPermissionCols] =
      useState<IValueLabelOption[]>(EMPTY_ARRAY);
    const services = useServices();
    const editingRef = useRef<{
      dataset: FDDataset;
      cols: FDDatasetCol[];
    } | null>(null);

    const dataSourceOptions = useMemo(() => {
      if (!globalState.moduleConfig.datasource) {
        return [];
      } else {
        const list = JSON.parse(
          globalState.moduleConfig.datasource
        ) as string[];
        return list.map((l) => ({ label: l, value: l }));
      }
    }, [globalState.moduleConfig.datasource]);

    useImperativeHandle(ref, () => ({
      open: (v?: FDDataset, cols?: FDDatasetCol[]) => {
        setOpen(true);
        setPermissionCols(EMPTY_ARRAY);
        if (v && cols) {
          setPreviewDataset(v);
          editingRef.current = {
            dataset: v,
            cols,
          };
          form.setFieldsValue(cloneDeep(v));
          setPermissionCols(
            cols
              .filter((c) => !!c.dbcolumn)
              .map((c) => {
                const type = c.kind === 0 ? "measure" : "dimension";
                return {
                  label: c.displayName || c.name || c.dbcolumn || "",
                  value: `${type}.${c.dbcolumn}`,
                };
              })
          );
        } else {
          editingRef.current = null;
          form.setFieldsValue(cloneDeep(DEFAULT_DATASET));
        }
      },
    }));

    const submitDataset = (values: FDDataset) => {
      // 在这里处理提交数据集的逻辑
      console.log("Submit dataset:", values);
      const newDataset: FDDataset = {
        ...DEFAULT_DATASET,
        ...values,
        userCreate: user.id,
      };
      setIsLoading(true);
      services
        .genCols(newDataset)
        .then(
          (res: any) => {
            if (res.data?.length) {
              const resCols = res.data as FDDatasetCol[];
              // 编辑模式需要校验后来的列是否比先前的列更多, 如果有列缺失, 则不能保存, 编辑模式列属性有限使用已有的.
              if (editingRef.current) {
                const existsCols = editingRef.current.cols.filter(
                  (f) => !isExpCol(f)
                );
                const notExistsCols = differenceBy(
                  existsCols,
                  resCols,
                  (item: FDDatasetCol) => item.dbcolumn
                );
                if (notExistsCols.length) {
                  message.error(
                    `New dataset must include all exists dataset columns : ${notExistsCols
                      .map((n) => n.dbcolumn)
                      .join()}`
                  );
                  setPreviewCols(EMPTY_ARRAY);
                  setPreviewDataset(null);
                } else {
                  const newCols = uniqBy(
                    existsCols.concat(resCols),
                    (col) => col.dbcolumn
                  );
                  setPreviewCols(newCols);
                  setPreviewDataset(newDataset);
                  setPreviewOpen(true);
                  refreshDatasets();
                }
              } else {
                setPreviewCols(res.data);
                setPreviewDataset(newDataset);
                setPreviewOpen(true);
              }
            } else {
              setPreviewCols(EMPTY_ARRAY);
              setPreviewDataset(null);
              message.error(getSharkText("config_page_preview_dataset_failed"));
            }
          },
          () => {
            setPreviewCols(EMPTY_ARRAY);
            setPreviewDataset(null);
            message.error(getSharkText("config_page_preview_dataset_failed"));
          }
        )
        .finally(() => setIsLoading(false));
    };

    const handleSubmit = () => {
      form
        .validateFields()
        .then((values) => {
          if (!values.datasource && !values.sqlTpl) {
            message.error(
              getSharkText(
                "config_page_datasource_and_customsql_cannot_be_empty"
              )
            );
            return;
          }
          const datasetConfig = merge(editingRef.current?.dataset, values);
          submitDataset(datasetConfig as FDDataset);
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    };

    const handleSubmitAll = useRefFunc(async () => {
      if (!previewDataset || !previewCols.length) {
        console.log(
          getSharkText(
            "config_page_dataset_or_data_column_is_empty_cannot_save"
          )
        );
        return;
      }
      setIsSaving(true);
      services.upsertDataset(previewDataset).then(
        (res: any) => {
          if (res.id >= 0) {
            const tmpCols: FDDatasetCol[] = previewCols.map((c) => ({
              ...c,
              datasetId: res.id,
            }));
            services
              .upsertDatasetCols(tmpCols)
              .then(
                (res: any) => {
                  if (res.data?.length) {
                    message.success(getSharkText("config_page_save_success"));
                    setPreviewOpen(false);
                    setOpen(false);
                    refreshDatasets();
                  }
                },
                () => {
                  message.error(
                    getSharkText("config_page_save_column_list_failed")
                  );
                }
              )
              .finally(() => setIsSaving(false));
          }
        },
        () => {
          message.error(getSharkText("config_page_save_dataset_failed"));
          setIsSaving(false);
        }
      );
    });

    const handleOnlySaveDataset = useRefFunc(() => {
      form.validateFields().then((values) => {
        if (!values.datasource && !values.sqlTpl) {
          message.error(
            getSharkText("config_page_datasource_and_customsql_cannot_be_empty")
          );
          return;
        }
        const datasetConfig = merge(editingRef.current?.dataset, values);
        setIsSaving(true);
        services
          .upsertDataset(datasetConfig)
          .then((res) => {
            message.success(getSharkText("config_page_save_success"));
            refreshDatasets();
          })
          .catch(() => {
            message.error(getSharkText("config_page_save_dataset_failed"));
          })
          .finally(() => {
            setIsSaving(false);
          });
      });
    });

    // #region 预览datesetCols
    const previewColsEditor = useMemo(() => {
      const keys = previewCols.map((c) => c.dbcolumn) as string[];
      return (
        <Modal
          open={previewOpen}
          onCancel={() => setPreviewOpen(false)}
          onOk={handleSubmitAll}
          width={1200}
          destroyOnClose
        >
          <Spin spinning={isSaving}>
            <DataColumns
              value={previewCols}
              onRowChange={(v) =>
                setPreviewCols(
                  arrayUpsert(
                    previewCols,
                    v,
                    (item) => item.dbcolumn === v.dbcolumn
                  )
                )
              }
              onRowDelete={(v) =>
                setPreviewCols((s) =>
                  s.filter((s1) => s1.dbcolumn !== v.dbcolumn)
                )
              }
              editKeys={keys}
            />
          </Spin>
        </Modal>
      );
    }, [handleSubmitAll, isSaving, previewCols, previewOpen]);
    // #endregion

    return (
      <>
        <Modal
          title={getSharkText("config_page_edit_dataset")}
          open={open}
          width={1280}
          style={{ top: 20 }}
          footer={[
            <Button key="back" onClick={() => setOpen(false)}>
              {getSharkText("key.close.button")}
            </Button>,
            <Button key="submit" type="primary" onClick={handleSubmit}>
              {getSharkText("config_page_preview")}
            </Button>,
            <Tooltip title="保存Dataset信息并保存Dataset列信息" key="saveOnly">
              <Button type="primary" danger onClick={handleOnlySaveDataset}>
                仅保存Dataset信息
              </Button>
            </Tooltip>,
          ]}
          onCancel={() => setOpen(false)}
        >
          <Spin spinning={isLoading}>
            <Form
              form={form}
              layout="horizontal"
              initialValues={DEFAULT_DATASET}
            >
              <Row gutter={[20, 0]}>
                <Col span={12}>
                  <Form.Item
                    label={getSharkText("config_page_dataset_name")}
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: getSharkText(
                          "config_page_please_enter_dataset_name"
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={getSharkText("config_page_database_type")}
                    name="datasource"
                    rules={[{ required: true }]}
                  >
                    <Select
                      options={dataSourceOptions}
                      showSearch
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    ></Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={getSharkText("config_page_custom_sql")}
                    name="sqlTpl"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea
                      rows={10}
                      placeholder="ex: select * from table where xxx"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={getSharkText("config_page_description")}
                    name="description"
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={getSharkText("config_page_jobid")}
                    name="jobidruleid"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label={getSharkText("config_page_realtime_metric")}
                    name="realtimeMetric"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="重要级别" name="priority">
                    <Select
                      options={[
                        { label: "P0", value: "P0" },
                        { label: "P1", value: "P1" },
                        { label: "P2", value: "P2" },
                        { label: "P3", value: "P3" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label="查询缓存" name="cache">
                    <Select
                      options={[
                        { label: "关闭", value: 0 },
                        { label: "开启", value: 1 },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="组权限字段"
                    name="permissionGroupField"
                    hidden={!editingRef.current?.cols.length}
                    normalize={(value) => {
                      if (typeof value === "undefined") {
                        return null;
                      }
                      return value;
                    }}
                  >
                    <Select
                      options={permissionCols}
                      style={{ minWidth: 160 }}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="组权限白名单"
                    name="permissionGroupWhitelist"
                    tooltip="使用组id, 逗号分隔"
                    hidden={!editingRef.current?.cols.length}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="用户权限字段"
                    name="permissionUserField"
                    hidden={!editingRef.current?.cols.length}
                    normalize={(value) => {
                      if (typeof value === "undefined") {
                        return null;
                      }
                      return value;
                    }}
                  >
                    <Select
                      options={permissionCols}
                      style={{ minWidth: 160 }}
                      allowClear
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="用户权限白名单"
                    name="permissionUserWhitelist"
                    tooltip="使用用户UID, 逗号分隔. 例如: 15600001234,15600001235..."
                    hidden={!editingRef.current?.cols.length}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </Modal>
        {previewColsEditor}
      </>
    );
  }
);

export default DatasetEditor;
