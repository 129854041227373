import Dates from "Components/Dates";
import Airlines from "Components/Toolbar/Airlines";
import { RangeCompare, IValueLabelOption } from "Interface";
import useGlobalState from "Store";
import useRefFunc from "Utils/useRefFunc";
import { Button, Col, message, Modal, Row, Upload, Radio, Select } from "antd";
import moment from "moment";
import React, {
  ReactElement,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { QueryParam } from "..";
import { XLSX, getUserAirlines } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { arrayDataToRecord } from "../common";
import { UploadOutlined } from "@ant-design/icons";
import { RecordTypeWithCompare } from "../interface";
import { max, min, uniq } from "lodash";
import { FLIGHTNO, ROUTE, TAKEOFFDATE, DATASET_ID } from "./cols";
import { WorkBook } from "xlsx";
import CompareFlightsRoutes from "Components/Dates/CompareFlightsRoutes";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import useDimensionEnumValues from "Page/AI/FreeDashboard/useDimensionEnumValues";
import { DATE_FORMAT } from "Constants";
export interface SearchAreaProps {
  onChange: (v: QueryParam | null) => void;
  onChangeUpload: (v: WorkBook | null) => void;
}

/**  Component description */
const SearchArea = (props: SearchAreaProps): ReactElement => {
  const { onChange, onChangeUpload } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition, userInfo } = globalState;
  const { startDate, endDate } = airlinesQueryCondition;
  const { user, roleList } = userInfo;
  const userAirlines = useMemo(() => getUserAirlines(roleList), [roleList]);
  const isCtrip = user.userType === 1;
  const useAirlineChange = isCtrip || userAirlines.length > 1;
  const [mode, setMode] = useState<string>("compare");
  const defaultRange = useMemo(() => {
    return startDate
      ? {
          current: [moment(startDate), moment(endDate)],
          compare: null,
        }
      : null;
  }, []);
  const [rangeCompare, setRangeCompare] = useState<RangeCompare>({
    current: [moment(), moment().add(14, "days")],
    compare: null,
  });
  const [routes, setRoutes] = useState<string[]>([]);
  const [flights, setFlights] = useState<string[]>([]);

  const [currentRoutes, setCurrentRoutes] = useState<string | null>(null);
  const [currentFlights, setCurrentFlights] = useState<string | null>(null);
  const [compareRoutes, setCompareRoutes] = useState<string | null>(null);
  const [compareFlights, setCompareFlights] = useState<string | null>(null);
  const dimensionService = useDimensionEnumValues({ datasetId: DATASET_ID });

  const [uploadModeFlights, setUploadModeFlights] = useState<string[]>([]);
  const [uploadModeRoutes, setUploadModeRoutes] = useState<string[]>([]);
  const [uploadRoutesOptions, setUploadRouteOptions] = useState<
    IValueLabelOption[]
  >([]);
  const [uploadFlightsOptions, setUploadFlightsOptions] = useState<
    IValueLabelOption[]
  >([]);

  const onSearch = useRefFunc(() => {
    // 清空查询参数, 销毁表格
    onChange(null);
    onChangeUpload(null);
    if (mode === "upload") {
      setTimeout(() => {
        onChange({
          rangeCompare,
          routes: uploadModeRoutes,
          flights: uploadModeFlights,
        });
      }, 0);
    } else {
      setRoutes([]);
      setFlights([]);
      if (!currentRoutes || !compareRoutes) {
        message.error("请选择航线");
        return;
      }
      // if (!compareFlights) {
      //   message.error("请选择对比航班");
      //   return;
      // }
      routes.push(...(currentRoutes ? [currentRoutes] : [""]));
      routes.push(...(compareRoutes ? [compareRoutes] : [""]));
      flights.push(...(currentFlights ? [currentFlights] : [""]));
      flights.push(...(compareFlights ? [compareFlights] : [""]));
      setTimeout(() => {
        onChange({
          rangeCompare,
          routes,
          flights,
        });
      }, 0);
    }
    console.log("rangeCompare is ", rangeCompare);
    console.log("routes is ", routes);
    console.log("flights is", flights);
  });

  // 上传模式航线过滤
  const uploadRoutesFilter = useMemo(() => {
    const filters: StandardFilter[] = [];
    if (rangeCompare.current) {
      filters.push({
        range: {
          field: `dimension.${TAKEOFFDATE}`,
          strRange: {
            lower: rangeCompare.current[0]?.format(DATE_FORMAT) || "",
            upper: rangeCompare.current[1]?.format(DATE_FORMAT) || "",
          },
        },
      });
    }
    return filters;
  }, [rangeCompare]);

  // 上传模式航班过滤
  const uploadFlightFilter = useMemo(() => {
    const filters: StandardFilter[] = [];
    if (uploadModeRoutes) {
      filters.push({
        in: {
          field: `dimension.${ROUTE}`,
          values: uploadModeRoutes,
        },
      });
      filters.push(...uploadRoutesFilter);
    }
    return filters;
  }, [uploadModeRoutes, uploadRoutesFilter]);

  // 获取当前航线和航班枚举值
  useEffect(() => {
    dimensionService
      .getDimensionEnumValues("route", uploadRoutesFilter)
      .then((res) => {
        if (res) {
          const options = res.map((r) => ({
            label: r.route,
            value: r.route,
          })) as IValueLabelOption[];
          setUploadRouteOptions(options);
          setUploadModeRoutes([options[0].value]);
          console.log("currentRoutes is ", options);
        }
      });
  }, [dimensionService, setRoutes, currentRoutes, uploadRoutesFilter]);

  useEffect(() => {
    dimensionService
      .getDimensionEnumValues("flightno", uploadFlightFilter)
      .then((res) => {
        if (res) {
          const options = res.map((r) => ({
            label: r.flightno,
            value: r.flightno,
          })) as IValueLabelOption[];
          setUploadFlightsOptions(options);
        }
      });
  }, [dimensionService, uploadFlightFilter]);

  const isConfirmOpenRef = useRef(false);

  const onUpload = useRefFunc((f): any => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      // reader.readAsDataURL(f.file.originFileObj);
      reader.readAsArrayBuffer(f.file.originFileObj);
      reader.onload = () => {
        const fils: WorkBook = XLSX.read(reader.result);
        const json = XLSX.utils.sheet_to_json(fils.Sheets[fils.SheetNames[0]], {
          header: 1,
        });
        console.log("json: ", json);
        const rst: Array<Partial<RecordTypeWithCompare>> = json
          .slice(1)
          .map((j: any[]) => {
            try {
              return arrayDataToRecord(j);
            } catch (e) {
              return null;
            }
          })
          .filter((f: any) => !!f);
        console.log("rst: ", rst);
        if (rst.length === 0) {
          message.error("上传文件为空");
          return;
        }
        if (isConfirmOpenRef.current) {
          return;
        }
        isConfirmOpenRef.current = true;
        Modal.confirm({
          title:
            "继续上传将会重新查询数据, 当前页面如有未保存的操作将会丢弃。确定吗?",
          onOk: () => {
            isConfirmOpenRef.current = false;
            const uploadStartDate = min(rst.map((r) => r[TAKEOFFDATE]));
            const uploadEndDate = max(rst.map((r) => r[TAKEOFFDATE]));
            const uploadRoutes = uniq(rst.map((r) => r[ROUTE]));
            const uploadFlights = uniq(rst.map((f) => f[FLIGHTNO]));
            const uploadRangeCompare: RangeCompare = {
              current: [moment(uploadStartDate), moment(uploadEndDate)],
              compare: null,
            };
            setRangeCompare(uploadRangeCompare);
            setRoutes(uploadRoutes);
            setFlights(uploadFlights);
            // 清空查询参数, 间接销毁表格元素和残留数据, 否则新旧数据交叉会出异常
            onChange(null);
            setTimeout(() => {
              onChange({
                rangeCompare: uploadRangeCompare,
                routes: uploadRoutes,
                flights: uploadFlights,
              });
            }, 0);
            setTimeout(() => {
              onChangeUpload(fils);
            }, 0);
          },
          onCancel: () => {
            isConfirmOpenRef.current = false;
          },
        });
      };
    });
  });
  const handleMOdeClick = (newMode: string) => {
    setMode(newMode);
    setCurrentRoutes(null);
    setCurrentFlights(null);
    setRoutes([]);
    setFlights([]);
  };

  return (
    <Row gutter={[10, 10]}>
      {useAirlineChange && (
        <Col>
          <Airlines airlines={userAirlines} mode={undefined} />
        </Col>
      )}
      <Radio.Group
        id="toolbarFlightClass"
        optionType="button"
        buttonStyle="solid"
        defaultValue="a"
      >
        <Radio.Button value="a" onClick={() => handleMOdeClick("compare")}>
          对比模式
        </Radio.Button>
        <Radio.Button value="b" onClick={() => handleMOdeClick("upload")}>
          上传模式
        </Radio.Button>
      </Radio.Group>
      {mode === "upload" && (
        <>
          <Col>
            {defaultRange ? (
              <Dates
                // defaultDateMode="none"
                // defaultValue={rangeCompare}
                value={rangeCompare}
                onChange={setRangeCompare}
                useCompare={false}
              />
            ) : undefined}
          </Col>
          <Col>
            <Select
              options={uploadRoutesOptions}
              value={uploadModeRoutes}
              onChange={setUploadModeRoutes}
              style={{ minWidth: 300 }}
              showSearch
              allowClear
              placeholder="请选择航线"
              mode="multiple"
            />
          </Col>
          <Col>
            <Select
              options={uploadFlightsOptions}
              value={uploadModeFlights}
              onChange={setUploadModeFlights}
              style={{ minWidth: 300 }}
              showSearch
              allowClear
              placeholder="请选择航班"
              mode="multiple"
            />
          </Col>
        </>
      )}
      {mode === "compare" && (
        <Col>
          <CompareFlightsRoutes
            value={rangeCompare}
            onChange={setRangeCompare}
            routes={currentRoutes}
            setRoutes={setCurrentRoutes}
            flights={currentFlights}
            setFlights={setCurrentFlights}
            compareRoutes={compareRoutes}
            setCompareRoutes={setCompareRoutes}
            compareFlights={compareFlights}
            setCompareFlights={setCompareFlights}
          />
        </Col>
      )}
      <Col>
        <Button type="primary" onClick={onSearch}>
          {getSharkText("key.query.button")}
        </Button>
      </Col>
      {mode === "upload" && (
        <Col>
          <Upload
            // action="//jsonplaceholder.typicode.com/posts/"
            // listType="picture"
            onChange={onUpload}
            itemRender={() => <div></div>}
          >
            <Button
              icon={<UploadOutlined />}
              type="primary"
              style={{ marginLeft: 10 }}
            >
              上传
            </Button>
          </Upload>
        </Col>
      )}
    </Row>
  );
};
export default SearchArea;
