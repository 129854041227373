import {
  MonitoringAlertingConfig,
  MonitoringAlertingRule,
} from "@ctrip/flt-bi-flightai-airlines";

export const defaultLast4WeekRuleList: MonitoringAlertingRule[] = [
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 1,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 2,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 3,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 4,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
];

export const defaultCompareChangeRuleList: MonitoringAlertingRule[] = [
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 1,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
];

export const defaultCompareLoadRuleList: MonitoringAlertingRule[] = [
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 1,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 2,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 3,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 4,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
];

export const defaultPriceRuleList: MonitoringAlertingRule[] = [
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 1,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 2,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 3,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
];

export const defaultLoadAbnormalRuleList: MonitoringAlertingRule[] = [
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 1,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 2,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
  {
    configId: null,
    ruleId: null,
    userId: null,
    ruleType: 3,
    threshold1: null,
    threshold2: null,
    threshold3: null,
    threshold4: null,
    threshold5: null,
    threshold6: null,
  },
];

export const defaultLast4WeekConfig = {
  configId: null,
  userId: null,
  configType: 1,
  ruleList: defaultLast4WeekRuleList,
  flightno: null,
  route: null,
  compareFlightno: null,
};

export const defaultCompareLoadConfig = {
  configId: null,
  userId: null,
  configType: 2,
  ruleList: defaultCompareLoadRuleList,
  flightno: null,
  route: null,
  compareFlightno: null,
};

export const defaultPriceConfig = {
  configId: null,
  userId: null,
  configType: 3,
  ruleList: defaultPriceRuleList,
  flightno: null,
  route: null,
  compareFlightno: null,
};

export const defaultCompareChangeConfig = {
  configId: null,
  userId: null,
  configType: 4,
  ruleList: defaultCompareChangeRuleList,
  flightno: null,
  route: null,
  compareFlightno: null,
};

export const defaultLoadAbnormalConfig = {
  configId: null,
  userId: null,
  configType: 5,
  ruleList: defaultLoadAbnormalRuleList,
  flightno: null,
  route: null,
  compareFlightno: null,
};

export const defaultRuleList: MonitoringAlertingConfig[] = [
  defaultLast4WeekConfig,
  defaultCompareLoadConfig,
  defaultPriceConfig,
  defaultCompareChangeConfig,
  defaultLoadAbnormalConfig,
];
