import { LineChartOutlined, TableOutlined } from "@ant-design/icons";
import { AggCodeName } from "Constants";
import { AggCode } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";
import { G } from "Utils/global";
import React, { ReactElement } from "react";

export enum EQueryType {
  Airline = 1,
  Route,
  Flight,
}
export const QueryType: Record<EQueryType, string> = {
  1: getSharkText("system_type_Airline"),
  2: getSharkText("key.route.name"),
  3: getSharkText("config_page_flight"),
};

export const QueryTypeOptions = G.enumValues(EQueryType).map((item) => {
  const key = item as EQueryType;
  return {
    value: key,
    label: QueryType[key],
  };
});

export const MonitoringAgg = [
  AggCode.halfHour,
  AggCode.hour,
  AggCode.day,
] as const;

export const MonitoringAggOptions = Object.values(MonitoringAgg).map((item) => {
  const key = item as AggCode;
  return {
    value: key,
    label: AggCodeName[key],
    disabled: false,
  };
});

export enum ChartType {
  Line,
  Table,
}

export interface ChartTypeContent {
  name: string;
  component: ReactElement;
}

export const RevertChartTypeOptions: Record<ChartType, ChartTypeContent> = {
  [ChartType.Table]: {
    name: getSharkText("key.trendchart.psgrsource"),
    component: <LineChartOutlined />,
  },
  [ChartType.Line]: {
    name: getSharkText("config_page_table"),
    component: <TableOutlined />,
  },
};
