import { Spin } from "antd";
import NormalTable from "Components/Table/NormalTable";
import { ICard } from "Page/AI/IndustryTrend/Interface";
import React, { ReactElement, useEffect } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { isAirportMode, useFetch } from "Utils";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { columns } from "../tableColumns";

/**
 * Component description
 *
 */

interface IProps {
  ext?: any;
  card: ICard;
}

const HolidayTable = (props: IProps): ReactElement => {
  const { ext, card } = props;
  const [globalState] = useGlobalState();
  const { systemType, queryCondition, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].table,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].table,
    },
    query: isAirport ? queryCondition : airlinesQueryCondition,
    ext: {
      ...ext,
    },
    // lazey: true,
  });

  const refetch = () => {
    doFetch({
      ext: {
        ...ext,
      },
      query: isAirport ? queryCondition : airlinesQueryCondition,
    });
  };

  useEffect(() => {
    refetch();
  }, [ext, queryCondition, airlinesQueryCondition]);

  return (
    <>
      <Spin spinning={isLoading}>
        <NormalTable
          columns={columns(card, isAirport)}
          data={data}
          searchKeys={["dport", "dportName", "aport", "aportName"]}
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={CHART_TABLE_CODE[systemType].table}
        ></NormalTable>
      </Spin>
    </>
  );
};
export default HolidayTable;
