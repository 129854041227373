import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { getEnv } from "Utils/global";
import { FECalculateAttr } from "./Overview/OverviewCard/calculate";

const isPro = getEnv() === "pro";
export const DATASET_ID = isPro ? 169 : 29;
export const DATASET_ID_NEWEST = isPro ? 204 : 36;
/** 起飞日期 */
export const TAKEOFFDATE_NAME = "takeoffdate";
/** 航司 */
export const AIRLINE_NAME = "airline";
/** 航班号 */
export const FLIGHTNO_NAME = "flightno";
/** 航段 */
export const ROUTE_NAME = "route";
/** 起飞时刻 */
export const TAKEOFFTIME_NAME = "takeofftime";
/** 提前期 */
export const AHEADDAY_NAME = "aheadday";
/** 所在小时 */
export const AHEADHOUR_NAME = "aheadhour";
/** 提前小时数 */
export const AHEADHOUR_CAL_NAME = "aheadhour_cal";
/** 布局数 */
export const CAP_NAME = "cap";
/** 完整航线 */
export const COMPLETE_SEG_NAME = "complete_seg";
/** 团队人数 */
export const GROUP_BKD_NAME = "group_bkd";
/** 总订座人数 */
export const SALEDSEAT_NAME = "saledseat";
/** 累积总收入 */
export const ACC_REVENUE_NAME = "acc_revenue";
/** 累积团队收入 */
export const ACC_GROUP_REVENUE_NAME = "acc_group_revenue";
/** 计划飞行时间 */
export const PLAN_MINUTES_NAME = "plan_minutes";
/** 是否经停 */
export const IS_STOP_FLIGHT_NAME = "is_stop_flight";
/** 长短段 */
export const STOPS_NAME = "stops";
/** 航距 */
export const TPM_NAME = "tpm";
/** 座公里 */
export const ASK_NAME = "ask";
/** 客公里 */
export const RPK_NAME = "rpk";
/** 采集航司 */
export const COLLECT_AIRLINE_NAME = "collect_airline";
/** 采集时间 */
export const COLLECTTIME_NAME = "collecttime";
/** 采集时间 */
export const DATACHANGE_LASTTIME_NAME = "datachange_lasttime";
/** job开始时间 */
export const JOB_START_HOUR_NAME = "job_start_hour";
/** 计算时间 */
export const COMPUTE_TIME_NAME = "compute_time";
/** 真实收益最新日期 */
export const D_NAME = "d";
/** 批次 */
export const RN_NAME = "rn";
/** 时间戳datachange */
export const DATACHANGE_LASTTIME_LONG_NAME = "datachange_lasttime_long";

/** 班次量 */
export const SCHEDULE_CNT_NAME = "schedule_cnt";
/** 起降架次 */
export const TAKEOFF_CNT_NAME = "takeoff_cnt";
/** 飞行小时 */
export const FLYING_HOURS_NAME = "flying_hours";
/** 小时收入 */
export const HOURS_REVENUE_NAME = "hours_revenue";
/** 座公里收入 */
export const ASK_REVENUE_NAME = "ask_revenue";
/** 客座率 */
export const LOAD_FACTOR_NAME = "load_factor";
/** 平均票价 */
export const AVG_PRICE_NAME = "avg_price";
/** 客公里收入 */
export const RPK_REVENUE_NAME = "rpk_revenue";
/** 团队旅客占比 */
export const GROUP_PCT_NAME = "group_pct";
/** UDF */
export const UDF_NAME = "udf";
/** 最小更新时间 */
export const UPDATE_MIN = "update_min";
/** 最大更新时间 */
export const UPDATE_MAX = "update_max";

export const CUSTOM_COLS: Array<FDDatasetCol & FECalculateAttr> = [
  {
    id: 0.1,
    displayName: "班次量",
    name: SCHEDULE_CNT_NAME,
    dbcolumn: `count(1)`,
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.2,
    displayName: "起降架次",
    name: TAKEOFF_CNT_NAME,
    dbcolumn: `count(1)-sum(measure.${STOPS_NAME})`,
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.3,
    displayName: "飞行小时",
    name: FLYING_HOURS_NAME,
    dbcolumn: `sum(measure.${PLAN_MINUTES_NAME})/60`,
    _argColumnNames: [PLAN_MINUTES_NAME],
    _calculate: (args: number[]): number => {
      return args[0] / 60;
    },
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.4,
    displayName: "小时收入",
    name: HOURS_REVENUE_NAME,
    dbcolumn: `sum(measure.${ACC_REVENUE_NAME})*60/sum(measure.${PLAN_MINUTES_NAME})`,
    _argColumnNames: [ACC_REVENUE_NAME, PLAN_MINUTES_NAME],
    _calculate: (args: number[]): number => {
      return (args[0] * 60) / args[1];
    },
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.5,
    displayName: "座公里收入",
    name: ASK_REVENUE_NAME,
    dbcolumn: `sum(measure.${ACC_REVENUE_NAME})/sum(measure.${ASK_NAME}))`,
    _argColumnNames: [ACC_REVENUE_NAME, ASK_NAME],
    _calculate: (args: number[]): number => {
      return args[0] / args[1];
    },
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.6,
    displayName: "客座率",
    name: LOAD_FACTOR_NAME,
    dbcolumn: `sum(measure.${SALEDSEAT_NAME})/sum(measure.${CAP_NAME})`,
    _argColumnNames: [SALEDSEAT_NAME, CAP_NAME],
    _calculate: (args: number[]): number => {
      return args[0] / args[1];
    },
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.7,
    displayName: "平均票价",
    name: AVG_PRICE_NAME,
    dbcolumn: `sum(measure.${ACC_REVENUE_NAME})/sum(measure.${SALEDSEAT_NAME})`,
    _argColumnNames: [ACC_REVENUE_NAME, SALEDSEAT_NAME],
    _calculate: (args: number[]): number => {
      return args[0] / args[1];
    },
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.8,
    displayName: "客公里收入",
    name: RPK_REVENUE_NAME,
    dbcolumn: `sum(measure.${ACC_REVENUE_NAME})/sum(measure.${RPK_NAME})`,
    _argColumnNames: [ACC_REVENUE_NAME, RPK_NAME],
    _calculate: (args: number[]): number => {
      return args[0] / args[1];
    },
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.9,
    displayName: "团队旅客占比",
    name: GROUP_PCT_NAME,
    dbcolumn: `sum(measure.${GROUP_BKD_NAME})/sum(measure.${SALEDSEAT_NAME})`,
    _argColumnNames: [GROUP_BKD_NAME, SALEDSEAT_NAME],
    _calculate: (args: number[]): number => {
      return args[0] / args[1];
    },
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.11,
    displayName: UDF_NAME,
    name: UDF_NAME,
    dbcolumn: `ticketCumulativeBatchUdf('takeoffdate','route', 'flightno', '${AHEADHOUR_NAME}', 'saledseat', sum(measure.saledseat))`,
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true, "computeType": "p_exp"}',
  },
  {
    id: 0.12,
    displayName: UPDATE_MIN,
    name: UPDATE_MIN,
    dbcolumn: `min(toDateTime(dimension.${DATACHANGE_LASTTIME_NAME}))`,
    jdbctype: "VARCHAR",
    kind: 1,
    sort: "VARCHAR",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.13,
    displayName: UPDATE_MAX,
    name: UPDATE_MAX,
    dbcolumn: `max(toDateTime(dimension.${DATACHANGE_LASTTIME_NAME}))`,
    jdbctype: "VARCHAR",
    kind: 1,
    sort: "VARCHAR",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true}',
  },
];
