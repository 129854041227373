import React, { ReactElement, useMemo } from "react";
import { CardItemDataEx } from "./CardItem";
import { showNum } from "Utils";
import { Divider, Progress } from "antd";
import { isNumber } from "lodash";

export interface CardItemValueProps {
  currentCardIdx: number;
  data: CardItemDataEx[];
  comparePrefix: string;
}

/**  Component description */
const CardItemValue = (props: CardItemValueProps): ReactElement => {
  const { currentCardIdx, data, comparePrefix } = props;
  const item: CardItemDataEx = useMemo(() => {
    return Array.isArray(data) ? data[currentCardIdx] : data;
  }, [currentCardIdx, data]);
  const { cardName, cardValue, compareValue, valueType, precision = 0 } = item;

  const value = showNum(
    valueType === "percentage" ? cardValue * 100 : cardValue,
    valueType,
    precision
  );
  const compare = showNum(
    valueType === "percentage" ? compareValue * 100 : compareValue,
    valueType,
    precision
  );
  /**
   * 卡片中央大数字
   */
  const mainValue = `${value} / ${compare}`;

  const progress = compareValue ? (cardValue / compareValue) * 100 : 0;
  return (
    <div>
      <div
        style={{ height: 22, marginTop: 5 }}
      >{`累计${cardName}/${comparePrefix}${cardName}`}</div>
      <div style={{ fontSize: 16 }}>{mainValue || "-"}</div>
      <Divider style={{ margin: "6px 0" }} />
      <Progress
        percent={progress}
        status="normal"
        size="small"
        format={(v) => (isNumber(v) ? showNum(v, "percentage", 0) : "-")}
      />
    </div>
  );
};
export default CardItemValue;
