import React from "react";
import { Button, message } from "antd";
import { Module } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface IProps {
  isEdit: boolean;
  setIsEdit: (isEdit: boolean) => void;
  changeList: Module[];
  resetModule: () => void;
  unitId: number;
  refetch: () => void;
  saveAutoRenew: () => void;
}

const ModuleEditButton: React.FC<IProps> = (props: IProps) => {
  const {
    isEdit,
    setIsEdit,
    changeList,
    resetModule,
    unitId,
    refetch,
    saveAutoRenew,
  } = props;
  const [, doFetch] = useFetch({
    url: "saveModuleAuthority",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      moduleList: changeList,
      groupId: unitId,
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_modify_success"));
      setIsEdit(false);
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_fail"));
    },
  });

  const handleSave = () => {
    doFetch({
      ext: {
        moduleList: changeList,
        groupId: unitId,
      },
    });
    saveAutoRenew();
  };

  return (
    <div style={{ margin: "16px 0" }}>
      {isEdit ? (
        <>
          <Button
            type="primary"
            onClick={handleSave}
            style={{ marginRight: 10 }}
          >
            {getSharkText("config_page_save")}
          </Button>
          <Button onClick={resetModule}>
            {getSharkText("key.cancel.button")}
          </Button>
        </>
      ) : (
        <Button type="primary" onClick={() => setIsEdit(true)}>
          {getSharkText("config_page_edit_module_permission")}
        </Button>
      )}
    </div>
  );
};

export default ModuleEditButton;
