import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, message } from "antd";
import { UnitList, CompareAirport, OpenableHandle, Role } from "Interface";
import UnitEdit from "../UnitEditModal";
import RoleListEdit from "../RoleListEdit";
import ModuleAuthorityList from "../ModuleAuthorityList";
import CompareAirportListEdit from "../CompareAirportListEdit";
import useGlobal from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import "./index.css";
import { getServer } from "Service/server";
import { EditOutlined } from "@ant-design/icons";
import AirlineRoutesEditor from "../AirlineRoutesEditor";
import useRefFunc from "Utils/useRefFunc";
import UnitCodeEditor from "../UnitCodeEditor";

interface IProps {
  curUnit: UnitList;
  unitList: UnitList[];
  refetch: () => void;
}

const ModuleManage: React.FC<IProps> = (props: IProps) => {
  const { curUnit, refetch, unitList } = props;
  const [isShowEditNameModal, setIsShowEditNameModal] = useState(false);
  const [isShowEditRoleModal, setIsShowEditRoleModal] = useState(false);
  const [unitCodes, setUnitCodes] = useState<string[]>(
    curUnit.unit.unitCode.split(",")
  );
  const [isShowEditCompareAirport, setIsShowEditCompareAirport] =
    useState(false);
  const [isShowUnitCodeEditor, setIsShowUnitCodeEditor] = useState(false);
  const [globalState] = useGlobal();
  const { queryCondition } = globalState;
  const [{ data }, doFetch] = useFetch({
    server: getServer(1),
    url: "queryAirportCompareAirportList",
    defaultValue: [],
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    query: queryCondition,
    ext: {
      groupId: curUnit.unit.id,
    },
    lazey: true,
  });

  const refetchCompareAirportList = () =>
    doFetch({
      ext: {
        groupId: curUnit.unit.id,
      },
    });

  const canEdit = curUnit.unit.id !== 0 && !curUnit.unit.managerUnit;
  const airportRole = curUnit.roleList.find((item) => item.roleType === 1);
  const airlineRole = curUnit.roleList.find((item) => item.roleType === 2);
  const partnerRole = curUnit.roleList.find((item) => item.roleType === 3);

  useEffect(() => {
    if (airportRole && curUnit) {
      refetchCompareAirportList();
    }
  }, [curUnit]);

  const handleEditUnit = () => {
    setIsShowEditNameModal(true);
  };

  const handleEditRoleList = () => {
    setIsShowEditRoleModal(true);
  };

  const handleEditCompareAirport = () => {
    setIsShowEditCompareAirport(true);
  };

  const getModuleListMode = (): Array<"airport" | "airline" | "partner"> => {
    const rst: Array<"airport" | "airline" | "partner"> = [];
    if (airportRole) {
      rst.push("airport");
    }
    if (airlineRole) {
      rst.push("airline");
    }
    if (partnerRole) {
      rst.push("partner");
    }
    return rst;
  };

  const selectedAirportsCode = data.reduce(
    (arr: any[], current: CompareAirport) => {
      arr.push(current.airport);
      return arr;
    },
    []
  );

  const airlineRouteEditorRef = useRef<OpenableHandle>(null);
  const handleEditRoute = useRefFunc(() => {
    if (airlineRouteEditorRef.current) {
      airlineRouteEditorRef.current.open();
    }
  });

  const [{}, doSubmitAirlineRoute] = useFetch({
    url: "bindingUnitRole",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      roleList: [],
      type: 1,
    },
    lazey: true,
    onSuccess: () => {
      message.success("修改成功");
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_fail"));
    },
  });

  const handleChangeAirlineRoutes = useRefFunc((v: Role) => {
    doSubmitAirlineRoute({
      ext: {
        roleList: [v],
        type: 1,
      },
    });
  });

  return (
    <>
      <Row align="middle" gutter={16} justify="space-between">
        <Col>
          {getSharkText("config_page_permission_group_name")}:{" "}
          {curUnit.unit.unitName}
          {canEdit ? (
            <Button type="link" onClick={handleEditUnit}>
              <EditOutlined />
            </Button>
          ) : undefined}
        </Col>
        <Col>
          <Row>
            <Col>
              {airportRole ? (
                <p className="module-title">
                  {getSharkText("config_page_airport_permission")}:{" "}
                  {airportRole.permissionCode}
                </p>
              ) : undefined}
              {airlineRole ? (
                <p className="module-title">
                  {getSharkText("config_page_airline_permission")}:{" "}
                  {airlineRole.permissionCode}
                </p>
              ) : undefined}
              {partnerRole ? (
                <p className="module-title">
                  {getSharkText("config_page_partner_permission")}:{" "}
                  {partnerRole.permissionCode}
                </p>
              ) : undefined}
              {!airportRole && !airlineRole && !partnerRole ? (
                <p className="module-title">
                  {getSharkText("config_page_no_data_permission")}
                </p>
              ) : undefined}
            </Col>
            <Col>
              {canEdit ? (
                <Button type="link" onClick={handleEditRoleList}>
                  <EditOutlined />
                </Button>
              ) : undefined}
            </Col>
          </Row>
        </Col>
        {airportRole ? (
          <>
            <Col>
              <UnitCodeEditor
                curUnit={curUnit}
                canEdit={canEdit}
                refetch={refetch}
              />
            </Col>
            <Col>
              <Row align="middle">
                <Col>
                  {getSharkText("config_page_benchmark_airport")}:&nbsp;
                  {curUnit.unit.unitCode === "ctrip"
                    ? "*"
                    : selectedAirportsCode.join(",")}
                </Col>
                <Col>
                  {canEdit ? (
                    <Button type="link" onClick={handleEditCompareAirport}>
                      <EditOutlined />
                    </Button>
                  ) : undefined}
                </Col>
              </Row>
            </Col>
          </>
        ) : airlineRole ? (
          <Col>
            <Row align="middle">
              <Col>
                {getSharkText("key.route.name")}:&nbsp;
                {curUnit.unit.unitCode === "ctrip"
                  ? "*"
                  : airlineRole.permissionRoute}
              </Col>
              <Col>
                {canEdit ? (
                  <Button type="link" onClick={handleEditRoute}>
                    <EditOutlined />
                  </Button>
                ) : undefined}
              </Col>
            </Row>
          </Col>
        ) : undefined}
      </Row>
      <ModuleAuthorityList
        curUnit={curUnit}
        mode={getModuleListMode()}
        refetch={refetch}
      />
      <UnitEdit
        visible={isShowEditNameModal}
        setVisible={setIsShowEditNameModal}
        curUnit={curUnit}
        refetch={refetch}
        unitList={unitList}
      />
      <RoleListEdit
        visible={isShowEditRoleModal}
        setVisible={setIsShowEditRoleModal}
        curUnit={curUnit}
        refetch={refetch}
      />
      {airportRole ? (
        <CompareAirportListEdit
          visible={isShowEditCompareAirport}
          setVisible={setIsShowEditCompareAirport}
          curUnit={curUnit}
          refetch={refetchCompareAirportList}
          compareAirports={data}
          permissionCode={airportRole.permissionCode}
        />
      ) : airlineRole ? (
        <AirlineRoutesEditor
          ref={airlineRouteEditorRef}
          airlineRoles={airlineRole}
          onSubmit={handleChangeAirlineRoutes}
        />
      ) : undefined}
    </>
  );
};

export default ModuleManage;
