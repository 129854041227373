import React, { useEffect, useState } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message } from "antd";
import hmacMD5 from "crypto-js/hmac-md5";
import { SERVER } from "Constants";
import useGlobal from "Store";
import VerifyCode from "./verifyCode";
import { useForm } from "antd/es/form/Form";
import useI18Next from "Utils/useI18Next";
import { getSharkText } from "Utils/i18nGlobal";

const getQueryString = (name: any) => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
};

const Reset = () => {
  const [globalState, actions] = useGlobal();
  const { verifyInfo, refreshCode } = globalState;
  const { setLoginPageState, setRefreshCodeState } = actions;
  const [form] = useForm();
  const [user, setUser] = useState({
    uid: "",
    id: "",
    mobile: "",
  });
  const t = useI18Next();

  const forget = async () => {
    const result: any = await fetch(`${SERVER}resetPassword`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: getQueryString("code"),
        token: getQueryString("token"),
      }),
    });
    const res = await result.json();
    if (res.ResponseStatus.Ack === "Success") {
      setUser(res.user);
    } else {
      message.error("server error");
    }
  };

  const savePassword = async (params: any) => {
    const result: any = await fetch(`${SERVER}saveMyInfo`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        head: {
          uid: user.uid,
          token: getQueryString("token"),
          requestTime: new Date(),
          appId: "",
          clientType: "2",
          clientVersion: "",
        },
        user: {
          id: user.id,
          passwd: hmacMD5(params.passwordDoublecheck, user.uid).toString(),
        },
      }),
    });
    const res = await result.json();
    if (res.flag) {
      setLoginPageState({
        mode: "reset-success",
      });
    } else {
      const code = res.ResponseStatus.Errors[0].ErrorCode;
      message.error(t(`error.${code}`));
      setRefreshCodeState(!refreshCode);
    }
  };

  useEffect(() => {
    forget();
  }, []);

  const handleResetSubmit = async (e: any) => {
    // e.preventDefault();
    const rst = await form.validateFields();
    if (rst) {
      if (verifyInfo.token === "") {
        message.error(
          getSharkText("config_page_please_pass_security_verification")
        );
      } else {
        savePassword(form.getFieldsValue());
      }
    }
  };

  return (
    <Card className="login-card">
      <div className="login-card-title-wrap">
        <div className="login-card-title">
          {getSharkText("config_page_change_password")}
        </div>
        <div className="login-card-subtitle">
          {getSharkText("config_page_current_change_password_account")}：
          {user.mobile}
        </div>
      </div>
      <Form onFinish={handleResetSubmit} form={form}>
        <Form.Item
          name="passwordNew"
          rules={[
            {
              required: true,
              message: getSharkText("config_page_please_enter_new_password"),
            },
            {
              min: 8,
              message: getSharkText("config_page_new_password_length"),
            },
            {
              pattern: new RegExp(
                "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}"
              ),
              message: getSharkText("config_page_password_requirement"),
            },
            {
              validator: (rule, val, callback) => {
                // const cfmPwd = props.form.getFieldValue("passwordDoublecheck");
                const cfmPwd = form.getFieldValue("passwordDoublecheck");
                if (cfmPwd && cfmPwd !== val) {
                  const msg = getSharkText("config_page_password_mismatch");
                  callback(msg);
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="login-input-icon" />}
            placeholder={getSharkText("config_page_enter_new_password")}
            className="login-card-input"
            type="password"
          />
        </Form.Item>
        <Form.Item
          name="passwordDoublecheck"
          rules={[
            {
              required: true,
              message: getSharkText("config_page_enter_new_password_again"),
            },
            {
              min: 8,
              message: getSharkText("config_page_new_password_length"),
            },
            {
              pattern: new RegExp(
                "(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}"
              ),
              message: getSharkText("config_page_password_requirement"),
            },
            {
              validator: (rule, val, callback) => {
                // const Pwd = props.form.getFieldValue("passwordNew");
                const Pwd = form.getFieldValue("passwordNew");
                if (Pwd && Pwd !== val) {
                  const msg = getSharkText(
                    "config_page_PaAiLoRe_passwords_not_match"
                  );
                  callback(msg);
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="login-input-icon" />}
            placeholder={getSharkText(
              "config_page_PaAiLoRe_confirm_new_password"
            )}
            className="login-card-input"
            type="password"
          />
        </Form.Item>
        <Form.Item>
          <VerifyCode />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="login-form-button"
          >
            {getSharkText("config_page_confirm")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Reset;
