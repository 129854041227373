import i18n from "i18next";
import ns1 from "./en/ns1.json";
import zh1 from "./zh/zh1.json";
import { initReactI18next } from "react-i18next";
import zhCN from "antd/es/locale/zh_CN";
import enUS from "antd/es/locale/en_US";
import { Locale } from "antd/lib/locale-provider";

/**
 * 添加新的语言.
 * 1. 建立语言配置文件, 如./en/ns1.json
 * 2. 在本文件中引用文件
 * 3. 在resources中添加语言标识, 并赋值{ns1: xxx, name: xxx}
 * 4. 添加antd国际语言支持包, 这个包按需一个个引入
 */

export enum ELangs {
  cn = "zh-CN",
  en = "en-US",
}

export const resources: Record<ELangs, any> = {
  "en-US": {
    ns1,
    name: "English",
  },
  "zh-CN": {
    ns1: zh1,
    // eslint-disable-next-line only-ascii/only-ascii
    name: "中文",
  },
};

export const getAntdLang = (i18nLang: string): Locale => {
  switch (i18nLang) {
    case "zh-CN":
      return zhCN;
    case "en-US":
      return enUS;
  }
  return zhCN;
};

const langKeys = Object.keys(resources);

const DEFAULT_LANGUAGE = "zh-CN";
const urlParams = new URLSearchParams(window.location.search);
export const defaultEn =
  window.location.pathname === "/search_index_intl" ||
  window.location.pathname === "/flight_plan_intl" ||
  window.location.pathname === "/od_analysis";
console.log("defaultEn", defaultEn);
const urlLang = urlParams.has("lang") ? urlParams.get("lang") : null;
export const defaultLang = defaultEn
  ? "en-US"
  : urlLang !== null && langKeys.includes(urlLang)
  ? urlLang
  : DEFAULT_LANGUAGE;

i18n.use(initReactI18next).init({
  fallbackLng: defaultLang,
  resources,
});
