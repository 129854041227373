import { Spin } from "antd";
import NormalTable from "Components/Table/NormalTable";
import React, { ReactElement, useCallback, useEffect } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { getColumns } from "./columns";
import { EPanelType } from "../WingonCommon";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { WingonFilter } from "@ctrip/flt-bi-flightai-partners";

/**
 * Component description
 *
 */

interface IProps {
  ext: WingonFilter;
  panelType: EPanelType;
}

const AirlineTable = (props: IProps): ReactElement => {
  const { ext, panelType } = props;
  const [globalState] = useGlobalState();
  const { partnerCondition } = globalState;
  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(4),
    url: QUERY_URL.table,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE,
    },
    query: partnerCondition,
    ext: {},
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (ext?.searchDateStart && ext?.searchDateEnd) {
      doFetch({
        ext: { filter: { ...ext }, type: panelType },
        query: partnerCondition,
      });
    }
  }, [ext, doFetch, panelType, partnerCondition]);

  useEffect(() => {
    refetch();
  }, [refetch]);
  return (
    <Spin spinning={isLoading}>
      <NormalTable
        downloadFileName={getSharkText("menu_wingon_overview")}
        columns={getColumns(panelType, ext)}
        data={data}
        searchKeys={["dcityCode", "dcityName", "acityCode", "acityName"]}
        moduleCode={MODULE_CODE}
        chartTableCode={CHART_TABLE_CODE}
      />
    </Spin>
  );
};
export default AirlineTable;
