import React, { ReactElement, useEffect, useState } from "react";
import { Empty, Select, Spin } from "antd";
import { UserList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface CurUser {
  key: number;
  label: string;
}

interface Iprops {
  curUsers: CurUser[];
  setCurUsers: (curUsers: CurUser[]) => void;
  setUserIds: (userIds: number[]) => void;
}

const { Option } = Select;

const SelectUnauthorizedUser = (props: Iprops): ReactElement => {
  const { setUserIds, curUsers, setCurUsers } = props;
  const [userList, setUserList] = useState<UserList[]>([]);
  const [search, setSearch] = useState("");
  const [{ isLoading, error, res }] = useFetch({
    url: "findUserList",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      filter: {
        groupId: 0,
      },
    },
  });

  useEffect(() => {
    if (res && res.userList) {
      setUserList(res.userList);
    }
  }, [res]);

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Empty />;
  }

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const filterOption = (value: string, option: any) => {
    if (value.length >= 3) {
      return option.props.children[0].includes(value);
    }
    return false;
  };

  const handleChange = (values: CurUser[]) => {
    setSearch("");
    setCurUsers(values);
    const userIds: number[] = [];
    values.map((item) => {
      userIds.push(item.key);
    });
    setUserIds(userIds);
  };

  return (
    <Select
      showSearch
      style={{ width: 500 }}
      mode="multiple"
      onSearch={handleSearch}
      filterOption={filterOption}
      notFoundContent={getSharkText(
        "config_page_PaAiRiCoSeIn_enter_complete_mobile_number"
      )}
      // @ts-ignore
      onChange={handleChange}
      // @ts-ignore
      value={curUsers}
      labelInValue
    >
      {search
        ? userList.map((item) => (
            <Option key={item.user.id} value={item.user.id}>
              {item.user.mobile}
              {item.user.name}
            </Option>
          ))
        : undefined}
    </Select>
  );
};

export default SelectUnauthorizedUser;
