// Created by xh_zhu on 2021-01-07
// 顶部的过滤栏

import { Affix, Alert, Col, Row } from "antd";
import FlightClassCom from "Components/Toolbar/FlightClassCom";
import React, { ReactElement, ReactNode, useEffect, useState } from "react";
import { ECompareTypeV2, IDateMode, RangeObject } from "Interface";
import { DATE_FORMAT } from "Constants";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { getDateRange } from "Utils";
import AirlinesGlobal from "Components/AirlinesGlobal";
import useGlobalState from "Store";
import CompareTypeGlobal from "Components/CompareType/CompareTypeGlobal";

interface ITopSearchAreaProps {
  alertMessage: ReactNode;
}

const TopSearchArea = (props: ITopSearchAreaProps): ReactElement => {
  const { alertMessage } = props;
  const [isShowAlert, setIsShowAlert] = useState<boolean>(true);
  const [, actions] = useGlobalState();
  const { setAirlinesQueryConditionAttr } = actions;
  const defaultDateMode: IDateMode = "lastMonth";
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange(defaultDateMode)
  );

  /**
   * 默认设置为市场对标
   */
  useEffect(() => {
    setAirlinesQueryConditionAttr({
      compareType: ECompareTypeV2.MARKET_COMPARE,
    });
  }, [setAirlinesQueryConditionAttr]);

  useEffect(() => {
    console.log("set date to global");
    setAirlinesQueryConditionAttr({
      startDate: (dateRange && dateRange[0]?.format(DATE_FORMAT)) || "",
      endDate: (dateRange && dateRange[1]?.format(DATE_FORMAT)) || "",
    });
  }, [dateRange, setAirlinesQueryConditionAttr]);

  return (
    <div>
      {alertMessage && isShowAlert ? (
        <Alert
          className="flightClassAlert"
          message={alertMessage}
          type="info"
          banner
          closable
          afterClose={() => setIsShowAlert(false)}
        />
      ) : undefined}
      <Affix offsetTop={0} className="toolbar-wrap">
        <div className="toolbar">
          <Row gutter={[{ md: 24 }, 20]} align="middle">
            <Col>
              <FlightClassCom />
            </Col>
            <Col>
              <AirlinesGlobal mode={undefined} />
            </Col>
            <Col>
              <DateRangePickerV2
                defaultDateMode="lastMonth"
                onChange={setDateRange}
                pathname={location.pathname}
              />
            </Col>
            <Col>
              <CompareTypeGlobal />
            </Col>
          </Row>
        </div>
      </Affix>
    </div>
  );
};
export default TopSearchArea;
