import React from "react";
import { Spin } from "antd";
import _, { round } from "lodash";
import { ISeries } from "Interface";
import { UserProfileChart } from "@ctrip/flt-bi-flightai-airlines";
import EchartsReactBase from "Components/EchartsReactBase";
import { getSharkText } from "Utils/i18nGlobal";

interface BarChartsProps {
  data: UserProfileChart[];
  height?: number;
  loading: boolean;
  seriesConfig: ISeries[];
  chartConfig: Record<string, unknown>;
}

export const genChartData = (
  data: UserProfileChart[],
  seriesConfig: ISeries[]
): any[] => {
  // 获取总量
  let totalValue = 0;
  data.forEach((d: UserProfileChart) => {
    totalValue += d.value || 0;
  });
  // 返回除了总量的每个值的占比
  const rst = data.reduce((total: any[], d: UserProfileChart) => {
    const v = d.value || 0;
    total.push({
      name:
        d.tagName === "unkown" ? getSharkText("key.unknown.name") : d.tagName,
      value: v / totalValue,
      ...seriesConfig.find((s) => s.tagCode === d.tagCode),
    });
    return total;
  }, []);
  return rst;
};

const SimplePieCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const { data, height = 300, loading, seriesConfig, chartConfig } = props;
  const chartData = genChartData(data, seriesConfig);
  const option = {
    dataset: {
      source: chartData,
    },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: { bottom: 10, top: "auto" },
    // legend: {
    //   itemGap: 20,
    //   itemWidth: 16,
    //   itemHeight: 8,
    //   top: 0,
    // },
    grid: {
      show: true,
      top: 50,
      bottom: 50,
      left: 100,
      right: 100,
      backgroundColor: "#fff",
      borderWidth: 0,
    },
    tooltip: {
      trigger: "item",
      formatter: (item: any) => {
        let dom = "";
        const dot = `<span class='dot' style='background:${item.color};'></span>`;
        const per = item.data.value;
        const perDom = `${item.data.name}: ${round(per * 100, 2)}%`;
        // dom = dot + val + '<br />' + perDom;
        dom = dot + perDom;
        // dom = '起飞日期：' + day + dom
        return dom;
      },
    },
    xAxis: {
      type: "value",
      show: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      splitNumber: 3,
      inverse: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: "pie",
        top: 6,
        encode: { itemName: "name", value: "value" },
        radius: ["55%", "70%"],
        center: ["50%", "40%"],
        label: {
          show: true,
          formatter: (d: any) =>
            d.data.name + ":" + round(d.data.value * 100, 2) + "%",
        },
      },
    ],
  };

  const mergedOption = chartConfig ? _.merge({}, option, chartConfig) : option;
  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={mergedOption}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default SimplePieCharts;
