import React from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";

const env = process.env.NODE_ENV;
const homePage = "/dashboard";
// 开发调试阶段快速到达目标页面
if (env === "development") {
  // homePage = "/simulator";
  console.log("home page is :", homePage);
}

const Header: React.FC = () => {
  return (
    <div className="header">
      <div className="header-con">
        {/* <Logo className="official-logo" /> */}
        <div className="logo logo-reverse official-logo"></div>
        <ul>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "cur" : "")}
              to="/"
              end
            >
              首页
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "cur" : "")}
              to="/MarketInsight"
            >
              市场洞察平台
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "cur" : "")}
              to="/ReportList"
            >
              研究报告
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "cur" : "")}
              to="/Consulting"
            >
              行业咨询
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "cur" : "")}
              to="/RevenueManagement"
            >
              收益管理
            </NavLink>
          </li>
          {/* <li>
            <NavLink className={({isActive}) => isActive ? "cur" : ""} to="/Salon">
              研究论坛
            </NavLink>
          </li> */}
          <li>
            <NavLink
              className={({ isActive }) => (isActive ? "cur" : "")}
              to="/AboutUs"
            >
              关于我们
            </NavLink>
          </li>
        </ul>
      </div>
      <NavLink to={homePage} style={{ color: "#fff", paddingRight: 10 }}>
        &gt;&gt;进入FlightAI系统
      </NavLink>
    </div>
  );
};

export default Header;
