import React, { CSSProperties, ReactElement, ReactNode } from "react";
import { IDownloadHeaderPro } from "Interface";
import { IDayHover, IFlightRecord } from "../../FlightManageInterface";
import { SortOrder } from "antd/lib/table/interface";
import { orderTable } from "../../FlightManageCommon";
import { Popover, Spin, Switch } from "antd";
import {
  InfoCircleOutlined,
  StarFilled,
  ThunderboltFilled,
  WarningFilled,
  EditOutlined,
} from "@ant-design/icons";
import { isNumeric, showNum, U } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import Feedback from "./Feedback";
import { FixedType } from "rc-table/lib/interface";
import Trend from "Components/Trend";
import { isNumber, round } from "lodash";
import AutoAdjust from "./AutoAdjust";
import { RecordWithAuto } from ".";
import { DATE_FORMAT, WEEK } from "Constants";
import PassengerClass from "../PassengerClass";

export const getColumns = (v: {
  airlineMode: number;
  airlines: string;
  id: string | undefined;
  isDemo: boolean;
  loadedRealTimeIds: string[];
  realLoadingIds: string[];
  setRecord: (record: IFlightRecord) => void;
  showAlert: (record: IFlightRecord) => void;
  hasAutoAdjust: boolean;
  roOpen: boolean;
  changeRoOpen: (open: boolean, record: IFlightRecord) => void;
  allFlights: string[];
  allRoutes: string[];
  allDepartTimes: string[];
  onAdjustmentParameterClick: (record: IFlightRecord) => void;
  hoveredRowIndex: number | null;
}): IDownloadHeaderPro[] => {
  const {
    airlineMode,
    airlines,
    id,
    isDemo,
    loadedRealTimeIds,
    realLoadingIds,
    setRecord,
    showAlert,
    hasAutoAdjust,
    roOpen,
    changeRoOpen,
    allFlights,
    allRoutes,
    allDepartTimes,
    onAdjustmentParameterClick,
    hoveredRowIndex,
  } = v;
  return [
    {
      title: getSharkText("charts.date_of_departure"),
      dataIndex: "takeoffdate",
      key: "takeoffdate",
      onCell: (record: IFlightRecord) => ({
        rowSpan: record.feRowSpan ?? 1,
      }),
      render: (val: any, r: IFlightRecord) => {
        return `${val} ${WEEK[moment(val).weekday()]}`;
      },
      width: 140,
      className: "white-column",
    },
    {
      title: "航班",
      dataIndex: "flightNo",
      key: "flightNo",
      width: 105,
      filters: allFlights.map((f) => ({ text: f, value: f })),
      // onFilter: (value, record) => record.flightNo === value,
      sorter: true,
      sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
        orderTable(a, b, "flightNo", "string", s),
      render: (val: any, r: IFlightRecord) => {
        const style: CSSProperties = {};
        if (r.flightType === 0) {
          style.color = "#1890FF";
        }
        return (
          <span style={style}>
            {r.isStopFlight ? (
              <Popover content={<strong>{r.routeAll}</strong>}>
                <span
                  style={{
                    color: "#f00",
                    fontSize: "110%",
                    marginRight: 2,
                    display: "inline-block",
                    width: 10,
                  }}
                >
                  #
                </span>
                {val}
              </Popover>
            ) : (
              <>
                <span
                  style={{
                    marginRight: 2,
                    display: "inline-block",
                    width: 10,
                  }}
                ></span>
                {val}
              </>
            )}
            {r.important ? (
              <Popover content={getSharkText("config_page_key_flight")}>
                <StarFilled style={{ color: "#f7d047" }} />
              </Popover>
            ) : undefined}
            {r.alert ? (
              <a href="javascript:void()" onClick={() => showAlert(r)}>
                <Popover
                  content={getSharkText(
                    "config_page_PaAiFlCoPrIn_click_to_view_warning_details"
                  )}
                >
                  <ThunderboltFilled style={{ color: "red" }} />
                </Popover>
              </a>
            ) : undefined}
          </span>
        );
      },
    },
    ...(airlineMode !== 0
      ? [
          {
            title: getSharkText("cabin_management"),
            dataIndex: "control",
            key: "control",
            width: 65,
            render: (val: any, r: IFlightRecord) => {
              if (r.flightNo.substr(0, 2) === airlines) {
                return (
                  <div
                    style={{ textAlign: "center" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                  >
                    <Switch
                      size="small"
                      checked={roOpen && r.id === id}
                      onChange={(checked) => changeRoOpen(checked, r)}
                    />
                  </div>
                );
              } else {
                return "";
              }
            },
          },
        ]
      : []),
    {
      title: getSharkText("config_page_takeoff_time"),
      dataIndex: "takeofftime",
      key: "takeofftime",
      filters: allDepartTimes.map((t) => ({ text: t, value: t })),
      sorter: true,
      sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
        orderTable(a, b, "takeofftime", "string", s),
      render: (val: any) => val.substr(0, 16),
      width: 110,
    },
    {
      title: getSharkText("key.route.name"),
      dataIndex: "route",
      filters: allRoutes.map((r) => ({ text: r, value: r })),
      // onFilter: (value, record) => record.route === value,
      sorter: true,
      sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
        orderTable(a, b, "route", "string", s),
      width: 90,
      render: (val: any, r: IFlightRecord) => {
        return <Popover content={<PassengerClass record={r} />}>{val}</Popover>;
      },
    },
    {
      title: `${getSharkText("config_page_seat_number")}/${getSharkText(
        "config_page_layout"
      )}`,
      children: [
        {
          title: (
            <>
              {getSharkText("config_page_booked_estimated")}
              <Popover
                content={getSharkText(
                  "config_page_current_reservation_number_and_yesterday_last_collection_reservation_number_difference"
                )}
              >
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          dataIndex: "soldSeats",
          key: "soldSeats",
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "soldSeats", "number", s),
          render: (val, r: IFlightRecord) => {
            if (r.soldSeats === null) {
              return "--";
            }
            const color = r.soldToday > 0 ? "#00B42A" : "#F53F3F";
            const flag = r.soldToday > 0 ? "+" : "";
            return (
              <span>
                <span>{showNum(r.soldSeats)}</span>
                {r.soldToday != null ? (
                  <span style={{ color, marginLeft: 5 }}>
                    ({flag + showNum(r.soldToday)})
                  </span>
                ) : undefined}
              </span>
            );
          },
          width: 120,
        },
        {
          title: getSharkText("config_page_layout"),
          dataIndex: "allSeats",
          key: "allSeats",
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "allSeats", "number", s),
          width: 75,
        },
      ],
    },
    {
      /** 客座率 */
      title: getSharkText("key.loadfactor"),
      children: [
        {
          title: getSharkText("estimated"),
          className: "lf",
          dataIndex: "lf",
          key: "lf",
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "lf", "number", s),
          render: (val: any, r: IFlightRecord) => {
            const valShow = isNumber(val) ? showNum(val, "percentage") : "--";
            const target = r.lfpredict;
            const totalBarWidth = 100;
            const color = target
              ? val < target
                ? "#F53F3F"
                : "#00B42A"
              : "#A9AEB8";
            const textColor = target ? color : "none";
            return (
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "inline-block",
                    width: 40,
                    color: textColor,
                  }}
                >
                  {valShow}
                </div>
                <div style={{ position: "relative" }}>
                  <span
                    className="bg"
                    style={{
                      width: isNumber(val) ? totalBarWidth : 0,
                      backgroundColor: "#e5e6eb",
                      height: 8,
                      display: "block",
                      marginTop: 7,
                    }}
                  ></span>
                  <span
                    className="bar"
                    style={{
                      width: (val * totalBarWidth) / 100,
                      display: "block",
                      height: 8,
                      backgroundColor: color,
                      position: "absolute",
                      top: 7,
                    }}
                  ></span>
                  {target != null ? (
                    <div
                      className="lf-predict-flag"
                      style={{
                        position: "absolute",
                        left: (target * totalBarWidth) / 100,
                        width: 1,
                        height: 12,
                        top: 5,
                        background: "#000",
                      }}
                    ></div>
                  ) : undefined}
                </div>
              </div>
            );
          },
          width: 160,
        },
        /** 航班目标客座率 */
        {
          title: getSharkText("today_goal"),
          className: "lf-goal",
          dataIndex: "lfpredict",
          key: "lfpredict",
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "lfpredict", "number", s),
          render: (val: any) => showNum(val, "percentage"),
          width: 80,
        },
        /** 预测最终客座率 */
        {
          title: getSharkText("estimate_final"),
          dataIndex: "lfpredictFinal",
          key: "lfpredictFinal",
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "lfpredictFinal", "number", s),
          render: (val: any) => showNum(val, "percentage"),
          width: 80,
        },
        /** 航线平均客座率(预估) */
        {
          title: getSharkText("config_page_average_seat_occupancy_estimation"),
          dataIndex: "lflines",
          key: "lflines",
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "lflines", "number", s),
          render: (val: any) => showNum(val, "percentage"),
          width: 80,
        },
      ],
    },
    {
      /** 历史四周客座均值 */
      title: (
        <>
          {getSharkText("config_page_history_four_weeks_average_seat")}
          <Popover
            content={getSharkText(
              "config_page_the_flight_past_28_days_average_seat"
            )}
          >
            <InfoCircleOutlined style={{ marginLeft: 3 }} />
          </Popover>
        </>
      ),
      children: [
        {
          title: getSharkText("same_lead_time"),
          dataIndex: "history4weekLf",
          key: "history4weekLf",
          width: 120,
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "history4weekLf", "number", s),
          render: (val: any, r: IFlightRecord) => {
            const avg = showNum(val * 100, "percentage") || "--";
            const dayDiff = moment(r.takeoffdate).diff(
              moment(moment().format(DATE_FORMAT)),
              "d"
            );
            const popoverContent = (
              <div style={{ minWidth: 200 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "40%" }}></div>
                  <div
                    style={{ width: "30%", textAlign: "center" }}
                  >{`D${dayDiff}`}</div>
                  <div style={{ width: "30%", textAlign: "center" }}>最终</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "40%", fontWeight: 500 }}>
                    {getSharkText("coefficient_of_variation")}
                  </div>
                  <div style={{ width: "30%", textAlign: "right" }}>
                    {r.history4weekLfCv != null
                      ? showNum(r.history4weekLfCv * 100, "percentage")
                      : "--"}
                  </div>
                  <div style={{ width: "30%", textAlign: "right" }}>
                    {r.history4weekLfCvFinal != null
                      ? showNum(r.history4weekLfCvFinal * 100, "percentage")
                      : "--"}
                  </div>
                </div>
              </div>
            );
            return <Popover content={popoverContent}>{avg}</Popover>;
          },
        },
        {
          title: getSharkText("final_value"),
          dataIndex: "history4weekLfFinal",
          key: "history4weekLfFinal",
          width: 120,
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "history4weekLfFinal", "number", s),
          render: (val: any, r: IFlightRecord) => {
            const final =
              r.history4weekLfFinal !== null && isNumeric(r.history4weekLfFinal)
                ? showNum(r.history4weekLfFinal * 100, "percentage")
                : "--";
            const dayDiff = moment(r.takeoffdate).diff(
              moment(moment().format(DATE_FORMAT)),
              "d"
            );
            const popoverContent = (
              <div style={{ minWidth: 200 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "40%" }}></div>
                  <div
                    style={{ width: "30%", textAlign: "center" }}
                  >{`D${dayDiff}`}</div>
                  <div style={{ width: "30%", textAlign: "center" }}>最终</div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "40%", fontWeight: 500 }}>
                    {getSharkText("coefficient_of_variation")}
                  </div>
                  <div style={{ width: "30%", textAlign: "right" }}>
                    {r.history4weekLfCv != null
                      ? showNum(r.history4weekLfCv * 100, "percentage")
                      : "--"}
                  </div>
                  <div style={{ width: "30%", textAlign: "right" }}>
                    {r.history4weekLfCvFinal != null
                      ? showNum(r.history4weekLfCvFinal * 100, "percentage")
                      : "--"}
                  </div>
                </div>
              </div>
            );
            return <Popover content={popoverContent}>{final}</Popover>;
          },
        },
      ],
    },
    {
      /** 历史同班期客座均值 */
      title: (
        <>
          {getSharkText("config_page_history_same_class_period_average")}
          <Popover
            content={getSharkText(
              "config_page_the_flight_in_the_past_same_class_period_four_departure_dates_average"
            )}
          >
            <InfoCircleOutlined style={{ marginLeft: 3 }} />
          </Popover>
        </>
      ),
      children: [
        {
          title: getSharkText("same_lead_time"),
          dataIndex: "history4dayLf",
          key: "history4dayLf",
          width: 120,
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "history4dayLf", "number", s),
          render: (val: any, r: IFlightRecord) => {
            const avg = showNum(val * 100, "percentage") || "--";
            const dayDiff = moment(r.takeoffdate).diff(
              moment(moment().format(DATE_FORMAT)),
              "d"
            );
            const popJson: IDayHover[] = r.history4dayLfHover
              ? JSON.parse(r.history4dayLfHover)
              : null;
            if (popJson && popJson.length) {
              popJson.sort((a, b) =>
                b.takeoffdate_his.localeCompare(a.takeoffdate_his)
              );
              const popoverContent = (
                <div style={{ minWidth: 200 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "40%" }}>
                      {popJson[0]
                        ? U.getWeekDay(moment(popJson[0].takeoffdate).toDate())
                        : "--"}
                    </div>
                    <div
                      style={{ width: "30%", textAlign: "center" }}
                    >{`D${dayDiff}`}</div>
                    <div style={{ width: "30%", textAlign: "center" }}>
                      最终
                    </div>
                  </div>
                  {popJson.map((p) => (
                    <div
                      key={p.takeoffdate_his}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "40%", fontWeight: 500 }}>
                        {p.takeoffdate_his}
                      </div>
                      <div style={{ width: "30%", textAlign: "right" }}>
                        {showNum(p.lf * 100, "percentage")}
                      </div>
                      <div style={{ width: "30%", textAlign: "right" }}>
                        {showNum(p.lf_final * 100, "percentage")}
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "40%", fontWeight: 500 }}>
                      {getSharkText("coefficient_of_variation")}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      {r.lfCv != null
                        ? showNum(r.lfCv * 100, "percentage")
                        : "--"}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      {r.lfCvFinal != null
                        ? showNum(r.lfCvFinal * 100, "percentage")
                        : "--"}
                    </div>
                  </div>
                </div>
              );
              return <Popover content={popoverContent}>{avg}</Popover>;
            } else {
              return avg;
            }
          },
        },
        {
          title: getSharkText("final_value"),
          dataIndex: "history4dayLfFinal",
          key: "history4dayLfFinal",
          width: 120,
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "history4dayLfFinal", "number", s),
          render: (val: any, r: IFlightRecord) => {
            const final =
              r.history4dayLfFinal && isNumeric(r.history4dayLfFinal)
                ? showNum(r.history4dayLfFinal * 100, "percentage")
                : "--";
            const dayDiff = moment(r.takeoffdate).diff(
              moment(moment().format(DATE_FORMAT)),
              "d"
            );
            const popJson: IDayHover[] = r.history4dayLfHover
              ? JSON.parse(r.history4dayLfHover)
              : null;
            if (popJson && popJson.length) {
              popJson.sort((a, b) =>
                b.takeoffdate_his.localeCompare(a.takeoffdate_his)
              );
              const popoverContent = (
                <div style={{ minWidth: 200 }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "40%" }}>
                      {popJson[0]
                        ? U.getWeekDay(moment(popJson[0].takeoffdate).toDate())
                        : "--"}
                    </div>
                    <div
                      style={{ width: "30%", textAlign: "center" }}
                    >{`D${dayDiff}`}</div>
                    <div style={{ width: "30%", textAlign: "center" }}>
                      最终
                    </div>
                  </div>
                  {popJson.map((p) => (
                    <div
                      key={p.takeoffdate_his}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "40%", fontWeight: 500 }}>
                        {p.takeoffdate_his}
                      </div>
                      <div style={{ width: "30%", textAlign: "right" }}>
                        {showNum(p.lf * 100, "percentage")}
                      </div>
                      <div style={{ width: "30%", textAlign: "right" }}>
                        {showNum(p.lf_final * 100, "percentage")}
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "40%", fontWeight: 500 }}>
                      {getSharkText("coefficient_of_variation")}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      {r.lfCv != null
                        ? showNum(r.lfCv * 100, "percentage")
                        : "--"}
                    </div>
                    <div style={{ width: "30%", textAlign: "right" }}>
                      {r.lfCvFinal != null
                        ? showNum(r.lfCvFinal * 100, "percentage")
                        : "--"}
                    </div>
                  </div>
                </div>
              );
              return <Popover content={popoverContent}>{final}</Popover>;
            } else {
              return final;
            }
          },
        },
      ],
    },
    {
      title: getSharkText("config_page_price"),
      children: [
        {
          title: (
            <>
              {getSharkText("realtime")}
              <Popover
                content={getSharkText("config_page_real_time_lowest_price")}
              >
                <InfoCircleOutlined />
              </Popover>
            </>
          ),
          // 实时价格与后端约定使用minPrice字段
          dataIndex: "minPrice",
          key: "minPrice",
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "minPrice", "number", s),
          width: 80,
          render: (val: any, r: IFlightRecord) => {
            const value = showNum(val);
            const popoverContent = !r.competitorsInfo?.length
              ? null
              : r.competitorsInfo.map((c) => {
                  const rate =
                    c.prob !== null && isNumeric(c.prob)
                      ? parseFloat(c.prob)
                      : null;
                  return (
                    <div key={c.flightNo}>
                      <span>{c.flightNo}</span>
                      <span style={{ margin: "0 5px" }}>|</span>
                      <span>
                        {rate !== null
                          ? showNum(rate * 100, "percentage")
                          : "-"}
                      </span>
                    </div>
                  );
                });
            return popoverContent ? (
              <Popover content={popoverContent}>{value}</Popover>
            ) : (
              value
            );
          },
        },
        {
          /** 成交均价 */
          title: (
            <>
              {getSharkText("config_page_average_deal_price")}
              <Popover
                content={getSharkText("config_page_average_deal_price_tooltip")}
              >
                <InfoCircleOutlined style={{ marginLeft: 3 }} />
              </Popover>
            </>
          ),
          dataIndex: "lineMinPrice",
          key: "lineMinPrice",
          width: 110,
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "lineMinPrice", "number", s),
          render: (val: any) => showNum(val),
        },
        /** 历史成交价差 */
        {
          title: (
            <Popover
              content={`${getSharkText(
                "config_page_PaAiFlCoPrIn_historical_price_difference"
              )}: ${getSharkText("config_page_historical_price_difference")}`}
            >
              {/* <div style={{ width: 50 }} className="text-ellipsis"> */}
              {getSharkText(
                "config_page_PaAiFlCoPrIn_historical_price_difference"
              )}
              {/* </div> */}
            </Popover>
          ),
          dataIndex: "historyDiff",
          key: "historyDiff",
          width: 120,
          sorter: true,
          sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
            orderTable(a, b, "historyDiff", "number", s),
          render: (val: any) => showNum(val, "num"),
        },
      ],
    },
    {
      title: "团队数量",
      dataIndex: "groupBkd",
      width: 80,
    },
    {
      title: "团队明细",
      dataIndex: "groupDetail",
      width: 100,
    },
    {
      title: "团队水分",
      dataIndex: "teamMargin",
      width: 100,
      render: (val: any) => showNum(round(val * 100, 1), "percentage"),
    },
    {
      title: "调价幅度",
      dataIndex: "adjustPriceGap",
      width: 100,
    },
    {
      title: "调价底限",
      dataIndex: "adjustPriceBase",
      width: 100,
    },
    {
      title: "建议价",
      dataIndex: "suggestPrice",
      key: "suggestPrice",
      defaultSortOrder: "descend",
      width: 130,
      sorter: true,
      sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
        orderTable(a, b, "suggestPrice", "number", s),
      render: (val: any, record: any, index: number) => {
        if (
          moment().isAfter(
            moment(`${record.takeoffdate} ${record.takeofftime}`)
          )
        ) {
          return "--";
        }
        if (record.flightNo.substring(0, 2) !== airlines) {
          return "--";
        }
        if (realLoadingIds.find((n) => n === record.id)) {
          return <Spin />;
        }
        if (!loadedRealTimeIds.includes(record.id)) {
          return "待加载";
        } else if (
          record.errorCode === null &&
          record.errorMsg === null &&
          record.suggestPrice === null
        ) {
          return "已加载";
        }
        const { errorCode, errorMsg } = record;
        let isSuccess = false;
        // 如果返回了价格, 则始终视为成功
        if (isNumber(val)) {
          isSuccess = true;
          // 如果返回了错误码, 则特定错误码不视为失败
        } else if (errorCode) {
          const code1: string = errorCode.split("|")[0];
          const code2: string = errorCode.split("|")[1];
          if (code1 === "200" && code2 === "20000") {
            isSuccess = true;
          } else if (
            ["200", "404", "406", "901"].includes(code1) &&
            [
              "20000",
              "31500",
              "31600",
              "50001",
              "90001",
              "32004",
              "33011",
              "33015",
            ].includes(code2)
          ) {
            return "--";
          }
        }
        // 计算值展示内容
        let valEle: ReactNode =
          Number(val) || isSuccess
            ? showNum(val)
            : getSharkText("config_page_load_failed");
        if (Number(val)) {
          const diff = val - record.rawPrice;
          const color = diff > 0 ? "#D03D33" : "green";
          if (diff) {
            valEle = (
              <span>
                <span style={{ color, marginRight: 5 }}>{val}</span>
                <Trend
                  style={{ display: "inline-block" }}
                  flag={diff > 0 ? "up" : "down"}
                  showFlag={diff !== 0}
                  downColor="#4eb31c"
                />
                <span style={{ color, marginLeft: 5 }}>{Math.abs(diff)}</span>
              </span>
            );
          }
        }

        let msg: ReactElement =
          errorMsg || getSharkText("config_page_unknown_reason");
        if (
          errorCode &&
          errorMsg &&
          errorCode.split("|").length >= 2 &&
          errorMsg.split("|").length >= 2
        ) {
          const codeArr = errorCode.split("|");
          const msgArr = errorMsg.split("|");
          msg = (
            <>
              <div>
                {codeArr[0]}： {msgArr[0]}
              </div>
              <div>
                {codeArr[1]}： {msgArr[1]}
              </div>
            </>
          );
        }
        return (
          <span>
            {valEle}
            {hoveredRowIndex === index ? (
              <EditOutlined
                style={{ width: 15 }}
                onClick={() => onAdjustmentParameterClick(record)}
              />
            ) : null}
            {!isSuccess ? (
              <Popover content={msg}>
                <WarningFilled style={{ color: "#e8ce1f" }} />
              </Popover>
            ) : undefined}
          </span>
        );
      },
    },
    {
      title: getSharkText("config_page_PaAiFlCoPrIn_suggested_push_time"),
      dataIndex: "sendTime",
      key: "sendTime",
      sorter: true,
      sortFn: (a: IFlightRecord, b: IFlightRecord, s?: SortOrder) =>
        orderTable(a, b, "sendTime", "string", s),
      width: 170,
    },
    ...(airlineMode === 0
      ? [
          {
            title: getSharkText("config_page_accept"),
            dataIndex: "feedback",
            fixed: "right" as FixedType,
            key: "feedback",
            width: 270,
            render: (val: any, record: IFlightRecord) => {
              return record.flightType === 0 ? (
                <Feedback
                  feedback={val}
                  cause={record.cause}
                  setRecord={setRecord}
                  record={record}
                  isDemo={isDemo}
                ></Feedback>
              ) : undefined;
            },
          },
        ]
      : []),
    ...(hasAutoAdjust
      ? [
          {
            title: "自动调舱开关",
            dataIndex: "autoAdjust",
            // fixed: "right" as FixedType,
            key: "autoAdjust",
            width: 270,
            render: (val: any, record: RecordWithAuto) => {
              if (
                record.flightNo.substring(0, 2) === airlines &&
                record.autoAdjust
              ) {
                return <AutoAdjust autoAdjust={record.autoAdjust} />;
              }
              return null;
            },
          },
        ]
      : []),
  ];
};
