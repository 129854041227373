import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { CalculateConfig } from "../interface";
import {
  Dimension,
  Filter,
  FilterValue,
  Measure,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { uniq, uniqWith } from "lodash";
import { isSame } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import {
  FDDatasetCol2MeasureSchemaEx,
  getColumnAlias,
  isCombineColumn,
  isExpCol,
  isPExpCol,
} from "../common";
import { getMeasureAlias } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/common";
import { FieldSchema } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/schema";
import {
  Column,
  CompareMean,
  Ordering,
  StandardComparator,
  StandardFilter,
  StandardMatrixRequest,
  StandardOrderBy,
  TimeSeries,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { ComparatorArg } from "Interface";
import { isDateCol } from "Utils/global";
import RequestBuilder from "./RequestBuilder";
import { IS_PRO } from "Constants";
import ubtUtils from "Utils/ubtUtils";

interface ColumnSchema {
  column: Column;
  schema: FieldSchema & FDDatasetCol;
}

const genColumns = (
  columns: FDDatasetCol[],
  dimensions: Dimension[],
  measures: Measure[],
  groupCols: FDDatasetCol[]
): ColumnSchema[] => {
  const args = { columns, dimensions, measures, groupCols };
  const tmpDimensions: ColumnSchema[] = dimensions
    // .filter((d) => d.dimensionConfig.type === "row")
    .reduce((t, d) => {
      const column = columns.find(
        (c) => c.name === d.columnName || c.id === d.columnId
      );
      if (column && column.name) {
        const item: ColumnSchema = {
          column: {
            field: `dimension.${column.name}`,
            alias: column.name,
          },
          schema: {
            ...column,
            name: column.name,
          },
        };
        t.push(item);
      } else {
        ubtUtils.warn("mytrixQueryColumnNotFound", {
          target: d,
          args,
        });
      }
      return t;
    }, [] as ColumnSchema[]);
  const tmpMeasures: ColumnSchema[] = measures.reduce((t, m) => {
    const column = columns.find(
      (c) => c.name === m.columnName || c.id === m.columnId
    );
    if (column && column.name) {
      let key: string = m.measureConfig.statisticalConfig.method;
      let value = `measure.${column.name}`;
      if (isCombineColumn(column)) {
        key = "SUM";
      } else if (isExpCol(column) && column.dbcolumn) {
        key = "m_exp";
        value = column.dbcolumn;
      } else if (isPExpCol(column) && column.dbcolumn) {
        key = "p_exp";
        value = column.dbcolumn;
      }
      const item: ColumnSchema = {
        column: {
          [key.toLocaleLowerCase()]: value,
          alias: getColumnAlias(
            column,
            m.measureConfig.statisticalConfig.method
          ),
        },
        schema: {
          ...column,
          name: column.name,
        },
      };
      t.push(item);
    } else {
      ubtUtils.warn("mytrixQueryColumnNotFound", {
        target: m,
        args,
      });
    }
    return t;
  }, [] as ColumnSchema[]);
  const tmpGroups: ColumnSchema[] = groupCols.reduce((t, g) => {
    if (g.name) {
      const item: ColumnSchema = {
        column: {
          field: `${g.kind === 0 ? "measure" : "dimension"}.${g.name}`,
          alias: g.name,
        },
        schema: {
          ...g,
          name: g.name,
        },
      };
      t.push(item);
    } else {
      ubtUtils.warn("mytrixQueryGroupNameFound", {
        target: g,
        args,
      });
    }
    return t;
  }, [] as ColumnSchema[]);
  const rst = tmpDimensions.concat(tmpMeasures).concat(tmpGroups);
  const uniqRst = uniqWith(rst, (a, b) => isSame(a, b));
  return uniqRst;
};

const conditions = [
  { label: "=", value: "=" },
  { label: "≠", value: "≠" },
  { label: ">", value: ">" },
  { label: ">=", value: ">=" },
  { label: "<", value: "<" },
  { label: "<=", value: "<=" },
  { label: "c<a<b", value: "c<a<b" },
  { label: "c<=a<b", value: "c<=a<b" },
  { label: "c<a<=b", value: "c<a<=b" },
  { label: "c<=a<=b", value: "c<=a<=b" },
  { label: getSharkText("config_page_include"), value: "包含" },
  { label: getSharkText("config_page_exclude"), value: "不包含" },
  { label: getSharkText("config_page_is_empty"), value: "为空" },
  { label: getSharkText("config_page_not_empty"), value: "不为空" },
];
const genFilter = (
  name: string,
  field: string,
  values?: number | string | number[] | string[]
): StandardFilter | null => {
  if (!name || !field || values === undefined) {
    return null;
  }
  return { [name]: { field: `dimension.${field}`, values, key: field } };
};

const getFilterReq = (f: CalculateConfig, name: string): StandardFilter[] => {
  let filter = null;
  let rst: StandardFilter[] = [];
  switch (f.calculate) {
    case "exclude":
      filter = genFilter(
        "not in",
        name,
        f.argsType === "number" ? f.numberArgs : f.stringArgs
      );
      return filter ? [filter] : [];
    case "between":
      if (f.stringArgs?.length === 2) {
        return [
          {
            range: {
              field: `dimension.${name}`,
              key: name,
              strRange: {
                lower: f.stringArgs[0],
                upper: f.stringArgs[1],
              },
            },
          },
        ];
      }
      return [];
    case "include":
      filter = genFilter(
        "in",
        name,
        f.argsType === "number" ? f.numberArgs : f.stringArgs
      );
      return filter ? [filter] : [];
    case "=":
      if (f.numberArgs?.length === 1) {
        return [
          {
            in: {
              field: `dimension.${name}`,
              key: name,
              values: f.numberArgs as unknown as string[],
            },
          },
        ];
      } else {
        return [];
      }
    case ">":
      if (f.numberArgs?.length === 1) {
        return [
          {
            gt: {
              field: `dimension.${name}`,
              key: name,
              float: f.numberArgs[0],
            },
          },
        ];
      } else {
        return [];
      }
    case ">=":
      if (f.numberArgs?.length === 1) {
        return [
          {
            ge: {
              field: `dimension.${name}`,
              key: name,
              float: f.numberArgs[0],
            },
          },
        ];
      } else {
        return [];
      }
    case "<":
      if (f.numberArgs?.length === 1) {
        return [
          {
            lt: {
              field: `dimension.${name}`,
              key: name,
              float: f.numberArgs[0],
            },
          },
        ];
      } else {
        return [];
      }
    case "<=":
      if (f.numberArgs?.length === 1) {
        return [
          {
            le: {
              field: `dimension.${name}`,
              key: name,
              float: f.numberArgs[0],
            },
          },
        ];
      } else {
        return [];
      }
    case "≠":
      filter = genFilter("<>", name, f.numberArgs ? f.numberArgs[0] : 0);
      return filter ? [filter] : [];
    case "c<a<b":
      if (f.numberArgs?.length !== 2) {
        return [];
      } else {
        filter = genFilter(">", name, f.numberArgs[0]);
        if (filter) rst.push(filter);
        filter = genFilter("<", name, f.numberArgs[1]);
        if (filter) rst.push(filter);
        return rst;
      }
    case "c<=a<b":
      if (f.numberArgs?.length !== 2) {
        return [];
      } else {
        filter = genFilter(">=", name, f.numberArgs[0]);
        if (filter) rst.push(filter);
        filter = genFilter("<", name, f.numberArgs[1]);
        if (filter) rst.push(filter);
        return rst;
      }
    case "c<a<=b":
      if (f.numberArgs?.length !== 2) {
        return [];
      } else {
        filter = genFilter(">", name, f.numberArgs[0]);
        if (filter) rst.push(filter);
        filter = genFilter("<=", name, f.numberArgs[1]);
        if (filter) rst.push(filter);
        return rst;
      }
    case "c<=a<=b":
      if (f.numberArgs?.length !== 2) {
        return [];
      } else {
        filter = genFilter(">=", name, f.numberArgs[0]);
        if (filter) rst.push(filter);
        filter = genFilter("<=", name, f.numberArgs[1]);
        if (filter) rst.push(filter);
        return rst;
      }
    case getSharkText("config_page_include"):
      filter = genFilter(
        "like",
        name,
        f.argsType === "number" ? f.numberArgs : f.stringArgs
      );
      return filter ? [filter] : [];
    case getSharkText("config_page_exclude"):
      filter = genFilter(
        "not like",
        name,
        f.argsType === "number" ? f.numberArgs : f.stringArgs
      );
      return filter ? [filter] : [];
    case getSharkText("config_page_is_empty"):
      filter = genFilter("is null", name, "");
      return filter ? [filter] : [];
    case getSharkText("config_page_not_empty"):
      filter = genFilter("is not null", name, "");
      return filter ? [filter] : [];
    default:
      return [];
  }
};

const genFilters = (
  columns: FDDatasetCol[],
  filters: Filter[],
  filterValue: FilterValue[]
) => {
  // 如果filterValue和filters的值冲突, 则用filterValue替换filters的值
  const filterValueKeys = filterValue
    .map((f) =>
      f.argOfReports.length
        ? f.argOfReports[0].columnId || f.argOfReports[0].columnName
        : undefined
    )
    .filter((c) => c !== undefined);
  const configFilter = filters.reduce((t, f) => {
    if (filterValueKeys.includes(f.columnId || f.columnName)) {
      return t;
    }
    const column = columns.find(
      (c) => c.name === f.columnName || c.id === f.columnId
    );
    if (!column) {
      return t;
    }
    f.filterConfig.forEach((v) => {
      t = t.concat(getFilterReq(v, column.name || ""));
    });
    return t;
  }, [] as any[]);
  const pageFilter = filterValue
    .filter((f) => f.argOfReports.length > 0)
    .reduce((t, f) => {
      const column = columns.find(
        (c) =>
          c.name === f.argOfReports[0].columnName ||
          c.id === f.argOfReports[0].columnId
      );
      if (column) {
        switch (f.filterType) {
          case "date":
            if (!f.argOfReports[0].values.current) {
              console.log("date is empty");
            } else if (
              f.argOfReports[0].values.current[0] &&
              f.argOfReports[0].values.current[1]
            ) {
              t.push({
                range: {
                  field: `dimension.${column.name}`,
                  key: column.name || "",
                  strRange: {
                    lower: f.argOfReports[0].values.current[0],
                    upper: f.argOfReports[0].values.current[1],
                  },
                },
              });
            } else if (
              f.argOfReports[0].values.current[0] &&
              !f.argOfReports[0].values.current[1]
            ) {
              t.push({
                ge: {
                  field: `dimension.${column.name}`,
                  key: column.name || "",
                  str: f.argOfReports[0].values.current[0],
                },
              });
            } else if (
              !f.argOfReports[0].values.current[0] &&
              f.argOfReports[0].values.current[1]
            ) {
              t.push({
                le: {
                  field: `dimension.${column.name}`,
                  key: column.name || "",
                  str: f.argOfReports[0].values.current[1],
                },
              });
            }
            break;
          case "select":
            t.push({
              in: {
                field: `dimension.${column.name}`,
                key: column.name || "",
                values: f.argOfReports[0].values,
              },
            });
            break;
          case "numberRange":
            {
              const value = f.argOfReports[0].values?.value;
              const field = `${column.kind === 0 ? "measure" : "dimension"}.${
                column.name
              }`;
              if (!value || value.filter((v: any) => v !== null).length === 0) {
                break;
              } else if (value[0] === null) {
                t.push({
                  le: {
                    field,
                    key: column.name || "",
                    float: value[1],
                  },
                });
              } else if (value[1] === null) {
                t.push({
                  ge: {
                    field,
                    key: column.name || "",
                    float: value[0],
                  },
                });
              } else {
                t.push({
                  range: {
                    field,
                    key: column.name || "",
                    floatRange: {
                      lower: f.argOfReports[0].values?.value[0] ?? null,
                      upper: f.argOfReports[0].values?.value[1] ?? null,
                    },
                  },
                });
              }
            }
            break;
          case "numberRangeInput":
            {
              const value = f.argOfReports[0].values?.value;
              const field = `${column.kind === 0 ? "measure" : "dimension"}.${
                column.name
              }`;
              if (!value || value.filter((v: any) => v !== null).length === 0) {
                break;
              } else {
                t.push({
                  or: {
                    filters: value.map((v: any) => {
                      return {
                        range: {
                          field,
                          key: column.name || "",
                          floatRange: {
                            lower: v[0] ?? null,
                            upper: v[1] ?? null,
                          },
                        },
                      };
                    }),
                  },
                });
              }
            }
            break;
        }
      } else if (f.filterType === "aresCascader") {
        f.argOfReports[0].values?.forEach((val: any) => {
          if (val.colId == null) {
            return;
          }
          const col = columns.find((c) => c.id === val.colId);
          if (col) {
            const selectedCodes = Array.isArray(val.code)
              ? val.code
              : [val.code];
            t.push({
              in: {
                field: `${col.kind === 0 ? "measure" : "dimension"}.${
                  col.name
                }`,
                key: col.name || "",
                values: selectedCodes.map((v: string) =>
                  ["BIGINT", "DECIMAL", "DOUBLE", "INTEGER", "FLOAT"].includes(
                    col.jdbctype || "VARCHAR"
                  )
                    ? Number(v)
                    : v
                ),
              },
            });
          }
        });
      } else {
        // throw new Error(
        //   `not find this column in filter value: ${
        //     f.argOfReports[0].columnId || f.argOfReports[0].columnName
        //   }`
        // );
      }
      return t;
    }, [] as StandardFilter[]);
  return configFilter.concat(pageFilter) || [];
};

const getTimeSeries = (
  schema: FDDatasetCol[],
  columns: Array<FieldSchema & FDDatasetCol>,
  filterValue: FilterValue[],
  comparators?: StandardComparator[],
  timeZone?: string
): [TimeSeries | undefined, StandardComparator[]] => {
  // 寻找行的第一个时间维度作为坐标X轴
  const tsCol = columns.find((c) => {
    if (isDateCol(c)) {
      return true;
    }
    return false;
  });
  let tmpRst: TimeSeries | undefined;
  let tmpComparators: StandardComparator[] = [];
  if (tsCol) {
    tmpRst = {
      field: `dimension.${tsCol.name}`,
      timeZone: timeZone || "+00:00",
    };
  } else {
    // throw new Error("can't find a date column for xAixs");
    tmpRst = undefined;
  }
  const compares = filterValue.filter((f) => {
    return (
      f.filterType === "date" && f.argOfReports.find((a) => a.values.compare)
    );
  });
  const comparatorArgs = compares.map((compare) => {
    const column = schema.find(
      (dc) =>
        dc.name === compare.argOfReports[0].columnName ||
        dc.id === compare.argOfReports[0].columnId
    );
    if (!column) {
      throw new Error(
        `get column by name ${compare.argOfReports[0].columnName} error`
      );
    }
    let filterItem: StandardFilter = {
      range: {
        field: `dimension.${column.name}`,
        key: column.name || "",
        strRange: {
          lower: compare.argOfReports[0].values.compare[0],
          upper: compare.argOfReports[0].values.compare[1],
        },
      },
    };

    if (
      compare.argOfReports[0].values.compare[0] &&
      !compare.argOfReports[0].values.compare[1]
    ) {
      filterItem = {
        ge: {
          field: `dimension.${column.name}`,
          key: column.name || "",
          str: compare.argOfReports[0].values.compare[0],
        },
      };
    } else if (
      !compare.argOfReports[0].values.compare[0] &&
      compare.argOfReports[0].values.compare[1]
    ) {
      filterItem = {
        le: {
          field: `dimension.${column.name}`,
          key: column.name || "",
          str: compare.argOfReports[0].values.compare[1],
        },
      };
    }

    const item: ComparatorArg = {
      key: column.name || "",
      op: "REPLACE",
      filter: filterItem,
    };
    return item;
  });
  const compareFilters = comparatorArgs.map((c) => JSON.stringify(c));
  if (compareFilters.length) {
    // 仅当坐标轴和时间过滤字段相同时, 对比可用
    const comparator: StandardComparator = {
      name: "filterKey",
      comparisonName: getSharkText("key.compare.name"),
      // means: [CompareMean.abs, CompareMean.grate],
      means: [CompareMean.abs, CompareMean.diff, CompareMean.grate],
      args: ["", ...compareFilters],
    };
    // tmpRst = {
    //   ...tmpRst,
    // };
    tmpComparators = [comparator];
  }
  return [tmpRst, comparators || tmpComparators];
};

export const getInterval = (
  columns: FDDatasetCol[],
  dimensions: Dimension[],
  pageFilters: FilterValue[]
): string => {
  let rst = "1d";
  // 寻找行的第一个时间维度作为坐标X轴
  const tsCol = columns.find((c) => {
    const column = dimensions
      .filter((f) => f.dimensionConfig.type === "row")
      .find((d) => d.columnName === c.name || d.columnId === c.id);
    if (column && isDateCol(c)) {
      return true;
    }
  });
  if (tsCol) {
    pageFilters.find((f) => {
      if (f.argOfReports?.length) {
        const arg = f.argOfReports[0];
        if (arg.columnName === tsCol.name || arg.columnId === tsCol.id) {
          rst = arg.values?.granularity || "1d";
          return true;
        }
      }
      return false;
    });
  }
  return rst;
};

export const getSorters = (
  sorters: Sorter[],
  columnWithSchemas: ColumnSchema[]
): StandardOrderBy[] => {
  const usedSorters = sorters.filter((r) => {
    return columnWithSchemas.find((re) => {
      return (
        r.columnName === re.schema.name ||
        (r.columnId && r.columnId === re.schema.id)
      );
    });
  });
  const rst = usedSorters.reduce((t, r) => {
    const columnSchema = columnWithSchemas.find(
      (re) =>
        r.columnName === re.schema.name ||
        (r.columnId && r.columnId === re.schema.id)
    );
    if (columnSchema) {
      const schema = columnSchema.schema as FDDatasetCol;
      if (schema) {
        const kindType = schema.kind === 0 ? "measure" : "dimension";
        const columnName = `${kindType}.${schema.name}`;
        const measureSchema = FDDatasetCol2MeasureSchemaEx(schema);
        const columnNameWithCalc =
          schema.kind === 0
            ? getMeasureAlias(measureSchema, r.statistical)
            : columnName;
        t.push({
          column: columnNameWithCalc,
          ordering: r.sorter === "ASC" ? Ordering.ASC : Ordering.DESC,
        });
      }
    }
    return t;
  }, [] as StandardOrderBy[]);
  return rst;
};

const mergeFilters = (
  filters: StandardFilter[],
  filterValue: FilterValue[]
) => {
  const compares = filterValue.filter((f) => {
    return (
      f.filterType === "date" && f.argOfReports.find((a) => a.values.compare)
    );
  });
  if (compares.length < 2) {
    return filters;
  }
  const colNames = compares.map((f) => f.argOfReports[0].columnName);
  const groupFilters: StandardFilter[] = [];
  const rstFilter = filters.reduce((total, filter) => {
    const field =
      filter.exists?.field ||
      filter.in?.field ||
      filter.range?.field ||
      filter.ge?.field ||
      filter.gt?.field ||
      filter.le?.field ||
      filter.lt?.field;
    if (colNames.find((c) => field?.includes(c))) {
      groupFilters.push(filter);
    } else {
      total.push(filter);
    }
    return total;
  }, [] as StandardFilter[]);
  rstFilter.push({
    and: { filters: groupFilters },
  });
  return rstFilter;
};

export const genRequest = (builder: RequestBuilder): StandardMatrixRequest => {
  const datasetId = builder.getDatasetId();
  const columns = builder.getColumns();
  const dimensions = builder.getDimensions();
  const measures = builder.getMeasures();
  const filters = builder.getChartFilters();
  const sorters = builder.getSorters();
  const filterValue = builder.getContainerFilters();
  const oriFilters = builder.getOriFilters();
  const comparators = builder.getComparators();
  const limit = builder.getLimit();
  const granularity = builder.getGranularity();
  const preserveComparatorGroups = builder.getPreserveComparatorGroups();
  const postFilter = builder.postFilter();
  const needComment = builder.needComment() ?? !IS_PRO;
  const timeZone = builder.timeZone;

  const pageFilters = filterValue.filter((f) => f.filterType !== "group");
  // const { dimensions, measures, filters, sorters } = chartConfig;
  // #region 获取分组配置
  /** 图表自身的group配置 */
  const dimensionsWithColumns = dimensions.map((d) => {
    const column = columns.find(
      (c) => c.name === d.columnName || c.id === d.columnId
    );
    return {
      ...d,
      column,
    };
  });
  const pageGroupFilters = filterValue.filter((f) => f.filterType === "group");
  const pageGroupFilterColumns = pageGroupFilters.reduce((t, c) => {
    if (!c.argOfReports.length || !c.argOfReports[0].values) {
      return t;
    }
    const ids = c.argOfReports[0].values as number[];
    const tmpCols = ids
      .map((id) => columns.find((c) => c.id === id))
      .filter((c) => !!c) as FDDatasetCol[];
    return t.concat(tmpCols);
  }, [] as FDDatasetCol[]);
  const groupCols = uniq(
    dimensionsWithColumns
      .map(
        (d) =>
          `${d.column?.kind === 0 ? "measure" : "dimension"}.${d.column?.name}`
      )
      .concat(
        pageGroupFilterColumns.map(
          (column) =>
            `${column.kind === 0 ? "measure" : "dimension"}.${column.name}`
        )
      )
  );
  // #endregion
  const columnWithSchemaList = genColumns(
    columns,
    dimensions,
    measures,
    pageGroupFilterColumns
  );
  const reqColumns = columnWithSchemaList.map((c) => c.column);

  const [timeSeries, comparatorRst] = getTimeSeries(
    columns,
    columnWithSchemaList.map((c) => c.schema),
    pageFilters,
    comparators,
    timeZone
  );
  const listFilters = genFilters(columns, filters, pageFilters).concat(
    oriFilters
  );
  const mergedFilters = mergeFilters(listFilters, filterValue);
  const interval = granularity || getInterval(columns, dimensions, pageFilters);

  const rst: StandardMatrixRequest = {
    version: 0,
    namespace: "global_dataset",
    name: `id_${datasetId}`,
    // name: `id_23`,
    interval,
    columns: reqColumns,
    filters: mergedFilters,
    timeSeries,
    comparators: comparatorRst,
    orderBy: getSorters(sorters, columnWithSchemaList),
    groupBy: {
      maxGroups: limit,
      columns: groupCols,
    },
    options: { show_sql: needComment ? "true" : "false" },
    ...(preserveComparatorGroups !== undefined
      ? { preserveComparatorGroups }
      : null),
    postFilter,
  };

  return rst;
  // return test;
};
