import React, { ReactNode } from "react";
import { SortOrder } from "antd/lib/table/interface";
import Trend from "Components/Trend";
import { IDownloadHeader, SystemType } from "Interface";
import { durationFormatter, showNum, showRawNum, tableNumSorter } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { EAlertType } from "../Common";
import { TransferAnalysisRoute } from "@ctrip/flt-bi-flightai-airlines";

type RouteColumns = Array<IDownloadHeader<TransferAnalysisRoute>>;

const routeWidth = 280;

export const airlinesRouteColumns: RouteColumns = [
  {
    title: getSharkText("transfer.route_OD"),
    dataIndex: "dport",
    width: routeWidth,
    render: (v: number, r: TransferAnalysisRoute) =>
      `${r.dportName}(${r.dport})-${r.aportName}(${r.aport})`,
  },
  {
    title: getSharkText("transfer.t_psgr_7days"),
    dataIndex: "last7dayPersons",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.last7dayPersons, b.last7dayPersons, s),
  },
  {
    title: getSharkText("key.wow.indicator"),
    dataIndex: "last7dayPersonsYoy",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ): number => {
      const aPer =
        a.last7dayPersons && a.last7dayPersonsYoy
          ? a.last7dayPersons / a.last7dayPersonsYoy
          : null;
      const bPer =
        b.last7dayPersons && b.last7dayPersonsYoy
          ? b.last7dayPersons / b.last7dayPersonsYoy
          : null;
      return tableNumSorter(aPer, bPer, s);
    },
    render: (v: number, r: TransferAnalysisRoute): ReactNode => {
      const rv =
        r.last7dayPersons && r.last7dayPersonsYoy
          ? r.last7dayPersons / r.last7dayPersonsYoy - 1
          : null;
      if (rv !== null) {
        const val = showNum(rv * 100, "percentage");
        return rv === 0 ? (
          val
        ) : (
          <Trend flag={rv > 0 ? "up" : "down"}>{val}</Trend>
        );
      } else {
        return "--";
      }
    },
    downloadFormatter: (v: number, r: TransferAnalysisRoute): string => {
      const rv =
        r.last7dayPersons && r.last7dayPersonsYoy
          ? r.last7dayPersons / r.last7dayPersonsYoy - 1
          : null;
      if (rv !== null) {
        return showNum(rv * 100, "percentage");
      } else {
        return "--";
      }
    },
  },
  {
    title: getSharkText("transfer.transfer_share.name"),
    dataIndex: "allOdRate",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.allOdRate, b.allOdRate, s),
    render: (v: number) => showNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("transfer.avg_transfer_duration.name"),
    dataIndex: "transferTime",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTime, b.transferTime, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.market_transfer_duration.name"),
    dataIndex: "transferTimeMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTimeMarket, b.transferTimeMarket, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.avg_transfer_price.name"),
    dataIndex: "avgPrice",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPrice, b.avgPrice, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.market_transfer_price.name"),
    dataIndex: "avgPriceMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPriceMarket, b.avgPriceMarket, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.price_difference.name"),
    dataIndex: "priceDiff",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.priceDiff, b.priceDiff, s),
    render: (v) => showNum(v),
  },
];

export const airlinesAirportColumns: RouteColumns = [
  {
    title: getSharkText("transfer.t_airport.name"),
    dataIndex: "tportName",
  },
  ...airlinesRouteColumns.slice(1),
];

export const airportColumns: RouteColumns = [
  {
    title: getSharkText("transfer.route_OD"),
    dataIndex: "dport",
    width: routeWidth,
    render: (v: number, r: TransferAnalysisRoute) =>
      `${r.dportName}(${r.dport})-${r.aportName}(${r.aport})`,
  },
  {
    title: getSharkText("transfer.transfer_point.name"),
    dataIndex: "tport",
    render: (v: number, r: TransferAnalysisRoute) => `${r.tportName}`,
  },
  {
    title: getSharkText("transfer.t_psgr_7days"),
    dataIndex: "last7dayPersons",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.last7dayPersons, b.last7dayPersons, s),
  },
  {
    title: getSharkText("key.wow.indicator"),
    dataIndex: "last7dayPersonsYoy",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ): number => {
      const aPer =
        a.last7dayPersons && a.last7dayPersonsYoy
          ? a.last7dayPersons / a.last7dayPersonsYoy
          : null;
      const bPer =
        b.last7dayPersons && b.last7dayPersonsYoy
          ? b.last7dayPersons / b.last7dayPersonsYoy
          : null;
      return tableNumSorter(aPer, bPer, s);
    },
    render: (v: number, r: TransferAnalysisRoute): ReactNode => {
      const rv =
        r.last7dayPersons && r.last7dayPersonsYoy
          ? r.last7dayPersons / r.last7dayPersonsYoy - 1
          : null;
      if (rv !== null) {
        const val = showNum(rv * 100, "percentage");
        return rv === 0 ? (
          val
        ) : (
          <Trend flag={rv > 0 ? "up" : "down"}>{val}</Trend>
        );
      } else {
        return "--";
      }
    },
    downloadFormatter: (v: number, r: TransferAnalysisRoute): any => {
      const rv =
        r.last7dayPersons && r.last7dayPersonsYoy
          ? r.last7dayPersons / r.last7dayPersonsYoy - 1
          : null;
      if (rv !== null) {
        return showRawNum(rv * 100, "percentage");
      } else {
        return "--";
      }
    },
  },
  {
    title: getSharkText(
      "config_page_PaAiTrCoTrCu_occupy_od_total_person_times_ratio"
    ),
    dataIndex: "allOdRate",
    render: (v: number): string => showNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("transfer.avg_transfer_duration.name"),
    dataIndex: "transferTime",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTime, b.transferTime, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.t_duration_oth_points.name"),
    dataIndex: "transferTimeMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTimeMarket, b.transferTimeMarket, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.avg_transfer_price.name"),
    dataIndex: "avgPrice",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPrice, b.avgPrice, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.market_transfer_price.name"),
    dataIndex: "avgPriceMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPriceMarket, b.avgPriceMarket, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.price_difference.name"),
    dataIndex: "priceDiff",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.priceDiff, b.priceDiff, s),
    render: (v) => showNum(v),
  },
];

export const getColumns = (
  systemType: SystemType,
  alertType?: EAlertType
): RouteColumns | undefined => {
  if (systemType === SystemType.airport) {
    return airportColumns;
  } else if (alertType !== undefined) {
    switch (alertType) {
      case EAlertType.TransferRoute:
        return airlinesRouteColumns;
      case EAlertType.TransferAirport:
        return airlinesAirportColumns;
    }
  }
};
