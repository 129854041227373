import React, { useEffect, useState } from "react";
import { Checkbox, Col, Input, Row, Table } from "antd";
import cloneDeep from "lodash/cloneDeep";
import round from "lodash/round";
import isEmpty from "lodash/isEmpty";
import DownloadBtn from "Components/DownloadBtn";
import {
  durationFormatter,
  groupSubData,
  isSame,
  openDownloadDialog,
  showRawNum,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";
import "./CustomTable.css";
import { ITransferAnalysisQueryExt } from "Page/AI/TransferPolicy/TransferPolicyInterface";

const dimColumns: any[] = [
  {
    title: getSharkText("transfer.route_OD"),
    dataIndex: "dport",
    key: "dport",
    width: 280,
    fixed: "left",
    render: (value: string, record: any) =>
      record.isChild
        ? ""
        : `${record.dportName}(${record.dport})-${record.aportName}(${record.aport})`,
    subRender: () => "--",
    downLoadFormatter: (val: any, record: any) =>
      `${record.dportName}(${record.dport})-${record.aportName}(${record.aport})`,
  },
  {
    title: getSharkText("transfer.transfer_point.name"),
    dataIndex: "tportName",
    key: "tportName",
    width: 140,
    fixed: "left",
    render: (val: string, record: any) =>
      [
        getSharkText("config_page_other_transfer_points"),
        getSharkText("tranfer.direct.checkbox"),
      ].includes(val)
        ? val
        : `${record.tportName}(${record.tport})`,
    downLoadFormatter: (val: any, record: any) =>
      [
        getSharkText("config_page_other_transfer_points"),
        getSharkText("tranfer.direct.checkbox"),
      ].includes(val)
        ? val
        : `${record.tportName}(${record.tport})`,
  },
];

const valColumns: any[] = [
  {
    title: getSharkText("transfer.psgr_traffic.name"),
    dataIndex: "transitPersons",
    key: "transitPersons",
    sorter: (a: any, b: any) => a.transitPersons - b.transitPersons,
    downLoadFormatter: (val: number) => showRawNum(val),
  },
  {
    title: getSharkText("transfer.avg_transfer_duration.name"),
    dataIndex: "transferTime",
    key: "transferTime",
    sorter: (a: any, b: any) => a.transferTime - b.transferTime,
    render: (val: number) => `${durationFormatter(val * 60)}`,
    downLoadFormatter: (val: number) => showRawNum(val, "minute"),
  },
  {
    title: getSharkText("transfer.flight_duration.name"),
    dataIndex: "flightTime",
    key: "flightTime",
    sorter: (a: any, b: any) => a.flightTime - b.flightTime,
    render: (val: number) => `${durationFormatter(val * 60)}`,
    downLoadFormatter: (val: number) => showRawNum(val, "minute"),
  },
  {
    title: getSharkText("transfer.avg_transfer_price.name"),
    dataIndex: "avgPrice",
    key: "avgPrice",
    sorter: (a: any, b: any) => a.avgPrice - b.avgPrice,
    render: (val: number) => <ValueNum type="num" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val),
  },
  {
    title: getSharkText("transfer.proportion_same_airline.name"),
    dataIndex: "airlineRate",
    key: "airlineRate",
    sorter: (a: any, b: any) => a.airlineRate - b.airlineRate,
    render: (val: number) => <ValueNum type="percentage" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText(
      "config_page_PaAiTrCoTrCu_occupy_od_total_person_times_ratio"
    ),
    dataIndex: "allTransferRate",
    key: "allTransferRate",
    sorter: (a: any, b: any) => a.allTransferRate - b.allTransferRate,
    render: (val: number) => <ValueNum type="percentage" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText("transfer_proportion_ttl_od.name"),
    dataIndex: "allOdRate",
    key: "allOdRate",
    sorter: (a: any, b: any) => a.allOdRate - b.allOdRate,
    render: (val: number) => <ValueNum type="percentage" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
];

// 动态计算表格的高度, 如果表格高度太高, 控制栏与表格底部数据就不能同时显示
const getBoxHeight = () => {
  const ele = document.getElementById("table-container");
  const height = ele?.clientHeight;
  console.log(height);
  // 100是分页器高度
  return height ? height - 100 : 400;
};

// 表格头两列的宽度
const dimWidth = 400;
const baseWidth = 800;
interface CustomTableProps {
  dataSource: any[];
  useCompare: number;
  searchkeys: string[];
  searchPlaceholder: string;
  moduleCode: string;
  chartTableCode: string;
  size?: "large" | "middle" | "small";
  query: ITransferAnalysisQueryExt;
}

const CustomTable: React.FC<CustomTableProps> = (props: CustomTableProps) => {
  const {
    dataSource,
    useCompare,
    searchkeys,
    searchPlaceholder,
    size = "large",
    query,
    moduleCode,
    chartTableCode,
  } = props;
  const [width, setWidth] = useState<number>(baseWidth + dimWidth);
  const { yoy, wow } = query;
  const [searchText, setSearchText] = useState<string>("");
  const [chiControls, setChiControls] = useState<string[]>([]);
  const [colControls, setColControls] = useState<string[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [groupData, setGroupData] = useState<any[]>([]);
  const [tableHeight, setTableHeight] = useState<number>(400);

  useEffect(() => {
    setTableHeight(getBoxHeight());
    const resizeListener = () => {
      // change width from the state object
      setTableHeight(getBoxHeight());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    const tmp = colControls.filter((item: string) => {
      if (yoy === 0 && item === "yoy") {
        return false;
      }
      if (wow === 0 && item === "wow") {
        return false;
      }
      return true;
    });
    if (!isSame(tmp, colControls)) {
      setColControls(tmp);
    }
  }, [yoy, wow, colControls]);
  // 生成同比环比的columns
  useEffect(() => {
    if (!colControls.length) {
      setColumns(dimColumns.concat(valColumns));
    }
    setWidth(baseWidth * (colControls.length + 1) + dimWidth);
    const tmpColumns: any[] = cloneDeep(valColumns);
    let count = 1;
    valColumns.forEach((c, i) => {
      if (colControls.indexOf("yoy") >= 0) {
        const key = `${c.dataIndex}Yoy`;
        const yoyItem = {
          title: `${c.title}${getSharkText("key.yoy.button")}`,
          dataIndex: key,
          key,
          sorter: (a: any, b: any) => a[key] - b[key],
          render: (val: number) => <ValueNum type="percentage" value={val} />,
          downLoadFormatter: (val: any) => showRawNum(round(val), "percentage"),
        };
        tmpColumns.splice(i + count, 0, yoyItem);
        count++;
      }
      if (colControls.indexOf("wow") >= 0) {
        const key = `${c.dataIndex}Compare`;
        const wowItem = {
          title: `${c.title}${getSharkText("config_page_wow")}`,
          dataIndex: key,
          key,
          sorter: (a: any, b: any) => a[key] - b[key],
          render: (val: number) => <ValueNum type="percentage" value={val} />,
          downLoadFormatter: (val: any) => showRawNum(round(val), "percentage"),
        };
        tmpColumns.splice(i + count, 0, wowItem);
        count++;
      }
    });
    const rstColumns = tmpColumns;
    // if (!riseControl.includes('数值')) {
    //   rstColumns = tmpColumns.filter((c: any) => c.dataIndex.includes('yoy') || c.dataIndex.includes('wow'));
    // }
    setColumns(dimColumns.concat(rstColumns));
  }, [colControls]);

  useEffect(() => {
    const childrenKey = ["OTHER", "-"];
    dataSource.forEach(
      (item: any, i: number) => (item.key = item.key || i + 1)
    );
    // 排除不需要显示的子项
    const showData = dataSource.filter(
      (item: any) =>
        childrenKey.indexOf(item.tport) < 0 ||
        chiControls.indexOf(item.tport) >= 0
    );
    const groupData2 = groupSubData({
      data: showData,
      groupByFn: (d) => (childrenKey.includes(d.tport) ? 1 : 0),
      dimColumns: ["aport", "dport"],
    });
    setGroupData(groupData2);
  }, [chiControls, dataSource]);

  const getFilterData = () => {
    const cloneData = cloneDeep(groupData);
    const filter = cloneData.filter((data: any) => {
      let isPass = false;
      for (const key of searchkeys) {
        if (
          data[key] &&
          data[key].toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        ) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = isEmpty(dataSource) ? dataSource : getFilterData();

  const handleOnChange = (checkedValues: any[]) => {
    setChiControls(checkedValues);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const onChangeColControls = (checkedValues: any[]) => {
    setColControls(checkedValues);
  };

  const handleDownload = () => {
    const sheetData1: any = [];
    const sheetData2: any = [];
    const sheetData3: any = [];
    const sheetDataFn = (record: any, i: number) => {
      const obj1: any = {};
      columns.forEach((c: any) => {
        obj1[c.title] = c.downLoadFormatter
          ? c.downLoadFormatter(record[c.dataIndex], record, i)
          : record[c.dataIndex];
      });
      return obj1;
    };

    dataSource.forEach((record: any, i: number) => {
      switch (record.tport) {
        case "OTHER":
          sheetData3.push(sheetDataFn(record, i));
          break;
        case "-":
          sheetData2.push(sheetDataFn(record, i));
          break;
        default:
          sheetData1.push(sheetDataFn(record, i));
          break;
      }
    });
    const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
    const sheet2 = XLSX.utils.json_to_sheet(sheetData2);
    const sheet3 = XLSX.utils.json_to_sheet(sheetData3);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      sheet1,
      getSharkText("transfer.transfer.name")
    );
    XLSX.utils.book_append_sheet(
      wb,
      sheet2,
      getSharkText("tranfer.direct.checkbox")
    );
    XLSX.utils.book_append_sheet(
      wb,
      sheet3,
      getSharkText("config_page_other_transfer_points")
    );
    const workbookBlob = workbook2blob(wb);
    openDownloadDialog(
      workbookBlob,
      getSharkText("config_page_transfer_analysis_table") + ".xlsx"
    );
  };

  return (
    <>
      {useCompare === 1 ? (
        <Row align="middle" gutter={32}>
          <Col>
            <Input.Search
              value={searchText}
              placeholder={searchPlaceholder}
              onChange={handleSearch}
            />
          </Col>
          <Col>
            <Checkbox.Group
              value={colControls}
              style={{ width: "100%" }}
              onChange={onChangeColControls}
            >
              {yoy !== 0 ? (
                <Checkbox id="dateCompare" value="yoy">
                  {getSharkText("key.yoy.button")}
                </Checkbox>
              ) : undefined}
              {wow !== 0 ? (
                <Checkbox id="dateCompare" value="wow">
                  {getSharkText("config_page_week_month_on_week_month")}
                </Checkbox>
              ) : undefined}
            </Checkbox.Group>
          </Col>
          <Col>
            <Checkbox.Group
              value={chiControls}
              style={{ width: "100%" }}
              onChange={handleOnChange}
            >
              <Checkbox id="dateCompare" value="OTHER">
                {getSharkText("config_page_other_transfer_points")}
              </Checkbox>
              <Checkbox id="dateCompare" value="-">
                {getSharkText("tranfer.direct.checkbox")}
              </Checkbox>
            </Checkbox.Group>
          </Col>
          <Col style={{ flex: 1, textAlign: "right" }}>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        </Row>
      ) : undefined}
      <Row
        id="table-container"
        style={{ marginTop: 16, flexGrow: 1, overflow: "hidden" }}
      >
        <Table
          showSorterTooltip={false}
          defaultExpandAllRows={true}
          rowClassName={(record: any) => (record.isChild ? "is-child-row" : "")}
          expandIconColumnIndex={-1}
          tableLayout="fixed"
          indentSize={0}
          dataSource={filterData}
          columns={columns}
          scroll={{ x: width, y: tableHeight }}
          pagination={{ defaultPageSize: 20 }}
          // defaultExpandedRowKeys={filterData.map((data: any) => data.key)}
          expandedRowKeys={filterData.map((data: any) => data.key)}
          size={size}
        />
      </Row>
    </>
  );
};

export default CustomTable;
