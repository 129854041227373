import { FlightsSeasonPlanFilter } from "@ctrip/flt-bi-flightai-airlines";
import { Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { AirlinesQueryCondition } from "Interface";
import React, { ReactElement, useEffect, useMemo } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE } from "../../fetchCode";
import { getColumns } from "./tableColumns";
import { WEEK, WEEK_EN } from "Constants";

export interface ITablePortalProps {
  filter: FlightsSeasonPlanFilter | undefined;
  query: Partial<AirlinesQueryCondition> | undefined;
}

/**
 * 航班时刻站点资源-表格
 */
const TablePortal = (props: ITablePortalProps): ReactElement => {
  const { filter, query } = props;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;

  const [{ data, isLoading }, doFetch] = useFetch({
    url: "queryAirlinesOnsaleFlightsSeasonPlan",
    server: getServer(systemType),
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].table,
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
  });

  useEffect(() => {
    if (airlinesQueryCondition.startDate) {
      doFetch({
        query: {
          ...airlinesQueryCondition,
          ...query,
          // airlines: "ALL",
          // airlinesName: "所有航司",
        },
        ext: {
          filter,
        },
      });
    }
  }, [airlinesQueryCondition, doFetch, filter, query]);

  let columns = useMemo(() => {
    return getColumns();
  }, []);

  const map = new Map<string, string>();
  for (let i = 0; i < WEEK.length; i++) {
    map.set(i + 1 + "", WEEK_EN[i].toLocaleLowerCase());
  }

  const subtractArray = (map: Map<string, string>, arr2: any[]) => {
    for (let i = 0; i < arr2.length; i++) {
      map.delete(arr2[i]);
    }
  };

  if (filter?.schedule) {
    subtractArray(map, filter.schedule);
    const val = Array.from(map.values());
    columns = columns.filter((obj) => !val.includes(obj.dataIndex));
  }

  return (
    <Spin spinning={isLoading}>
      <CustomTableV2
        columns={columns}
        dataSource={data}
        tableProps={{ size: "small" }}
        downloadName={getSharkText(
          "config_page_flight_season_schedule_site_resource_data"
        )}
        showLimit={1000}
        moduleCode={MODULE_CODE[systemType]}
        chartTableCode={CHART_TABLE_CODE[systemType].table}
      />
    </Spin>
  );
};
export default TablePortal;
