import {
  AggCode,
  Area,
  ChartDataFormatter,
  ECompareTypeV2,
  FlightArea,
  IDateMode,
  IMultiAirport,
  T_YMW_COMPARE_TYPE,
} from "Interface";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { cloneDeep } from "lodash";
import { ReactNode } from "react";

export * from "./Style";
export * from "./PageId";

let SERVER = "";
let IS_PRO = false;
const USE_MOCK = false;

export const UBT_APPID = 700032;

const HOST = window.location.host;
export const LOCAL_URL = "//localhost:8080/api/";
export const FAT_URL =
  "//gateway.m.fws.qa.nt.ctripcorp.com/restapi/soa2/19595/";
export const PRO_URL = "//m.ctrip.com/restapi/soa2/19595/";
// const MOCK_URL = "//10.32.177.2:5351/restapi/soa2/19595/";
const MOCK_URL = "//localhost:5388/restapi/soa2/19595/";
const env = process.env.NODE_ENV;
console.log("HOST: ", HOST);
// 全局配置的serverDomain优先
if (window.SERVER_BASE_URL) {
  SERVER = window.SERVER_BASE_URL;
} else {
  switch (HOST) {
    // FAT
    case "flightai.fat3868.qa.nt.ctripcorp.com":
      SERVER = FAT_URL;
      IS_PRO = false;
      break;
    case "flightai.ctrip.com":
    case "flightai.aero":
    // PRO
    case "www.flightai.aero":
      SERVER = PRO_URL;
      IS_PRO = true;
      break;
    default:
      if (USE_MOCK && env === "development") {
        SERVER = MOCK_URL;
        // SERVER = FAT_URL;
        // SERVER = LOCAL_URL;
      } else {
        SERVER = FAT_URL;
      }
      IS_PRO = false;
    // SERVER = '//10.5.13.202:8080/api/'
    // SERVER = '//m.ctrip.com/restapi/soa2/19595/';
  }
}
export { SERVER, IS_PRO };
export const UID = "flightai_uid";
export const TOKEN = "flightai_token";
export const BINDING = "flightai_binding";
export const USER_NAME = "flightai_user_name";

export const IS_DEV = process.env.NODE_ENV === "development";
export const FLIGHT_CLASS = [
  getSharkText("flightClass.all.name"),
  getSharkText("flightClass.domestic.name"),
  getSharkText("flightClass.foreign.name"),
];
export const MODULE = {
  3: getSharkText("module.none.name"),
  0: getSharkText("module.demo.name"),
  1: getSharkText("module.official.name"),
  2: getSharkText("module.trial.name"),
};
export const PERCENT_VALUES = [
  "load_factor",
  "wide_body",
  "execution_rate",
  "refund",
];
export const MARKET_COMPARISO_CARDS = [
  "passenger_traffic",
  "transport_capacity",
  "flight_sorties",
];
export const DEFAULE_DATE_MODE = "lastMonth";

export const COMPARE_FILTER_KEY_FLAG = "__$$filterKey$$__";

export interface IDateModeObj {
  value: IDateMode;
  text: string;
}

export const DATE_MODES: IDateModeObj[] = [
  {
    value: "lastMonth",
    text: getSharkText("date.last30"),
  },
  {
    value: "nextThirtyDays",
    text: getSharkText("date.next30"),
  },
  {
    value: "custom",
    text: getSharkText("key.custom.text"),
  },
];
export const WEEK = [
  getSharkText("date_mon"),
  getSharkText("date_tue"),
  getSharkText("date_wed"),
  getSharkText("date_thu"),
  getSharkText("date_fri"),
  getSharkText("date_sat"),
  getSharkText("date_sun"),
];
export const WEEK_EN = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const ROLETYPES = [
  getSharkText("system_type_Airport"),
  getSharkText("system_type_Airline"),
  getSharkText("system_type_Others"),
];
export const SYSTEM_TYPES = [
  {
    name: getSharkText("system_type_Airport"),
    value: 1,
    className: "airport-mode-selected",
  },
  {
    name: getSharkText("system_type_Airline"),
    value: 2,
    className: "airlines-mode-selected",
  },
];

export const ERROR_CODE: any = {
  1001: getSharkText("error_1001"),
  1002: getSharkText("error_1002"),
  1003: getSharkText("error_1003"),
  1004: getSharkText("error_1004"),
  1005: getSharkText("error_1005"),
  1006: getSharkText("error_1006"),
  1007: getSharkText("error_1007"),
  1008: getSharkText("error_1008"),
  2001: getSharkText("error_2001"),
  2002: getSharkText("error_2002"),
  2003: getSharkText("error_2003"),
  2004: getSharkText("error_2004"),
  2005: getSharkText("error_2005"),
  2006: getSharkText("error_2006"),
  2010: getSharkText("error_2010"),
  3001: getSharkText("error_3001"),
  3002: getSharkText("error_3002"),
  3003: getSharkText("error_3003"),
  3004: getSharkText("error_3004"),
  10099: getSharkText("error_10099"),
};
export const AREA_DOMESTIC = "area_domestic";
export const USER_AIRPORTS = "user_airports";
export const ALINES_LIST = "alines_list";
export const DEFAULT_AREA: Area = {
  areaType: 0,
  areaCode: "",
  areaName: "",
  areaNameEn: "",
  pinyin: null,
};
export const DEFAULT_FLIGHT_AREA: FlightArea = {
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
};
export const DATE_FORMAT_MONTH = "YYYY-MM";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_HOUR_FORMAT = `${DATE_FORMAT} HH`;
export const DATE_MINUTE_FORMAT = `${DATE_HOUR_FORMAT}:mm`;
export const DATE_SECOND_FORMAT = `${DATE_MINUTE_FORMAT}:ss`;
export const TIME_FORMAT = "HH:mm";

export type Partial<T> = {
  [P in keyof T]?: T[P];
};

// 默认选中境内
export const DEFAULT_FLIGHT_CLASS = 1;

// BMap的AK
export const BMAP_AK_FAT = "UC4TKdqYWQxCh7paWNcap6vNyCR6Y3u4";
export const BMAP_AK_PRD = "2TnWNfXZ3VPkCGkqH0D9fAkTFoWLED00";

// 用户画像男女图标
export const pathSymbols = {
  woman: `path://M576,96a96,96,0,1,1-192,0,96,96,0,0,1,192,0zM719.008,512L768,476.512,634.72,270.4A32,32,0,0,0,608,256h-256a32,32,
  0,0,0-26.72,14.4L192,476.512,240.992,512l110.656-143.584,38.464,89.728L256,704h122.656l21.344,320h64V704h32v320h64l21.344-320H704l-134.112-245.856,
  38.464-89.728L719.008,512z`,
  man: `path://M511.991604,170.48901c50.208075,0.238924,90.927472-37.886447,90.927472-85.261571C602.919076,38.159502,562.199678,0,511.991604,0S421.064132,
  38.159502,421.064132,85.227439c0,47.102069,40.719397,85.261571,90.927472,85.261571z,m91.166395,19.182147H420.85934C352.015229,189.671157,307.2,
  246.364299,307.2,300.361019v259.607489c0,50.276339,75.568102,50.276339,75.568102,0v-239.947496h14.642599v652.704973c0,69.492617,102.395802,
  67.205778,104.819169,0V596.489677h19.523467v376.4411c4.129964,71.30161,104.819169,64.441091,104.819169-0.238924V319.952749h12.1851v239.981627c0,
  50.276339,78.025601,50.276339,78.025601,0V300.361019c0-53.723664-45.088285-110.65573-113.625208-110.65573z`,
};

// 因为数据敏感原因, 需要暂时取消客座率数据的展示
export const SHOW_LOAD = false;

// travix 国家名
export const TRAVIX_COUNTRY = [
  "DE",
  "ES",
  "FR",
  "GB",
  "IT",
  "SE",
  "DK",
  "NO",
  "FI",
  "NL",
  "BE",
  "AT",
  "PL",
  "PT",
  "GR",
  "IE",
  "CH",
];

export const TRAVIX_MARKET = [
  "AT",
  "BE",
  "CH",
  "DE",
  "DK",
  "ES",
  "FR",
  "GB",
  "GR",
  "IE",
  "IT",
  "NL",
  "PL",
  "PT",
];

export const ALL_MARKET = [
  "CN",
  "GB",
  "US",
  "DE",
  "IT",
  "ES",
  "FR",
  "RU",
  "BR",
  "AU",
  "NL",
  "JP",
  "KR",
  "IN",
  "PL",
  "CA",
  "IE",
  "TR",
  "MX",
  "CH",
  "GR",
  "TW",
  "HK",
  "PT",
  "AE",
  "SE",
  "SG",
  "TH",
  "IL",
  "SA",
  "UA",
  "BE",
  "AT",
  "CZ",
  "MY",
  "CO",
  "XX",
  "NZ",
  "HU",
  "PH",
  "RO",
  "FI",
  "ID",
  "DK",
  "LT",
  "NO",
  "AR",
  "VN",
  "SI",
  "EG",
  "HR",
  "KW",
  "MA",
  "PK",
  "CY",
  "CL",
  "ZA",
  "LV",
  "SK",
  "QA",
  "EE",
  "BG",
  "MT",
  "PE",
  "LU",
  "RS",
  "OM",
  "CR",
  "AL",
  "KZ",
  "JO",
  "EC",
  "BH",
  "LB",
  "BY",
  "TN",
  "GE",
  "UY",
  "BD",
  "DZ",
  "NG",
  "MO",
  "LK",
  "GT",
  "IQ",
  "AZ",
  "ME",
  "KH",
  "BA",
  "MD",
  "PA",
  "KE",
  "VE",
  "IS",
  "AM",
  "SV",
  "DO",
  "TZ",
  "NP",
  "MK",
  "PR",
  "RE",
  "GH",
  "BO",
  "PY",
  "IR",
  "HN",
  "ZW",
  "MM",
  "MV",
  "MU",
  "GI",
  "AD",
  "UZ",
  "LY",
  "AO",
  "GP",
  "BN",
  "TT",
  "LA",
  "GG",
  "AS",
  "AF",
  "MC",
  "JM",
  "SN",
  "NI",
  "KG",
  "UG",
  "MN",
  "ZM",
  "CI",
  "CU",
  "MQ",
  "CM",
  "ET",
  "MZ",
  "SD",
  "KY",
  "GU",
  "VI",
  "AW",
  "PS",
  "CD",
  "NA",
  "BW",
  "FJ",
  "BS",
  "AG",
  "VG",
  "NC",
  "KO",
  "BB",
  "PF",
  "SO",
  "RW",
  "SY",
  "GM",
  "AI",
  "MG",
  "TC",
  "MW",
  "SC",
  "TJ",
  "UNKWN",
  "CV",
  "PG",
  "AQ",
  "MR",
  "YE",
  "ML",
  "HT",
  "BM",
  "GA",
  "BF",
  "BJ",
  "MP",
  "LI",
  "BZ",
  "SL",
  "TG",
  "KM",
  "SR",
  "GD",
  "GN",
  "LC",
  "SZ",
  "YT",
  "GF",
  "GY",
  "WS",
  "CG",
  "LR",
  "DJ",
  "VA",
  "TM",
  "BL",
  "CW",
  "TD",
  "VU",
  "NE",
  "BT",
  "CF",
  "FO",
  "BI",
  "SS",
  "PM",
  "VC",
  "GL",
  "TL",
  "DM",
  "GQ",
  "KN",
  "FK",
  "LS",
  "CK",
  "TO",
  "BQ",
  "ER",
  "GW",
  "SB",
  "WF",
  "PW",
  "XK",
  "SX",
  "SH",
  "KP",
  "ST",
  "TV",
  "FM",
  "NR",
  "MH",
  "GS",
  "CC",
  "KI",
  "MS",
  "CX",
  "NU",
  "AN",
  "WN",
  "EN",
];

export const MARKET_OPTIONS = ALL_MARKET.map((market) => ({
  label: market,
  value: market,
}));

// 用于声明新数组时使用, 防止useEffect循环触发
export const EMPTY_ARRAY: any[] = [];
// 用于声明新对象, 防止useEffect循环触发
export const EMPTY_OBJECT: Record<any, any> = {};

export const MULTI_AIRPORT: IMultiAirport = {
  BJS: ["PEK", "PKX"],
  CTU: ["CTU", "TFU"],
  SHA: ["SHA", "PVG"],
};

/**
 * 对比的百分比的名称, 去年同比, 前年同比等
 */
export const COMPARE_TYPE_PER_NAME: Record<ECompareTypeV2, string> = {
  [ECompareTypeV2.YEAR]: getSharkText("compare_YEAR_per_name"),
  [ECompareTypeV2.WEEK]: getSharkText("compare_WEEK_per_name"),
  [ECompareTypeV2.MONTH]: getSharkText("compare_MONTH_per_name"),
  [ECompareTypeV2.YEAR_BEFORE_LAST]: getSharkText(
    "compare_YEAR_BEFORE_LAST_per_name"
  ),
  [ECompareTypeV2.LAST_DAY]: getSharkText("compare_LAST_DAY_per_name"),
  [ECompareTypeV2.YEAR_LAST_LAST]: getSharkText(
    "compare_YEAR_LAST_LAST_per_name"
  ),
  [ECompareTypeV2.YEAR_4_BEFORE]: getSharkText(
    "compare_YEAR_4_BEFORE_per_name"
  ),
  [ECompareTypeV2.YEAR_5_BEFORE]: getSharkText(
    "compare_YEAR_5_BEFORE_per_name"
  ),
  [ECompareTypeV2.MARKET_COMPARE]: getSharkText(
    "compare_MARKET_COMPARE_per_name"
  ),
};

/**
 * 对比的值的名称, 去年同期, 上月, 上周等
 */
export const COMPARE_TYPE_VALUE_NAME: Record<ECompareTypeV2, string> = {
  [ECompareTypeV2.YEAR]: getSharkText("compare_year_value_name"),
  [ECompareTypeV2.WEEK]: getSharkText("compare_week_value_name"),
  [ECompareTypeV2.MONTH]: getSharkText("compare_month_value_name"),
  [ECompareTypeV2.YEAR_BEFORE_LAST]: getSharkText(
    "compare_year_before_last_value_name"
  ),
  [ECompareTypeV2.LAST_DAY]: getSharkText("compare_last_day_value_name"),
  [ECompareTypeV2.YEAR_LAST_LAST]: getSharkText(
    "compare_year_last_last_value_name"
  ),
  [ECompareTypeV2.YEAR_4_BEFORE]: getSharkText(
    "compare_year_4_before_value_name"
  ),
  [ECompareTypeV2.YEAR_5_BEFORE]: getSharkText(
    "compare_year_5_before_value_name"
  ),
  [ECompareTypeV2.MARKET_COMPARE]: getSharkText(
    "compare_market_compare_value_name"
  ),
};
/**
 * 有preLastChain的场合
 */
export const COMPARE_TYPE_PLC_NAME = (() => {
  const tmp = cloneDeep(COMPARE_TYPE_PER_NAME);
  tmp[ECompareTypeV2.WEEK] = getSharkText("compare_week_over_week_per_name");
  tmp[ECompareTypeV2.MONTH] = getSharkText("compare_month_over_month_per_name");
  return tmp;
})();

/**
 * 市场份额的场合
 */
export const COMPARE_TYPE_MARKET_NAME = (() => {
  const tmp = cloneDeep(COMPARE_TYPE_PER_NAME);
  tmp[ECompareTypeV2.MARKET_COMPARE] = getSharkText(
    "compare_market_share_name"
  );
  return tmp;
})();

/**
 * 年同比可选项
 */
export const EYearCompareType = [
  ECompareTypeV2.YEAR,
  ECompareTypeV2.YEAR_BEFORE_LAST,
  ECompareTypeV2.YEAR_LAST_LAST,
  ECompareTypeV2.YEAR_4_BEFORE,
  ECompareTypeV2.YEAR_5_BEFORE,
] as const;

/**
 * 航司同比可选项, 在年同比基础上增加市场对标
 */
export const EAirlineCompareType = [
  ...EYearCompareType,
  ECompareTypeV2.MARKET_COMPARE,
] as const;

/**
 * 年周月同比
 */
export const EYmwCompareType: Readonly<
  Record<T_YMW_COMPARE_TYPE, T_YMW_COMPARE_TYPE>
> = {
  [ECompareTypeV2.YEAR]: ECompareTypeV2.YEAR,
  [ECompareTypeV2.WEEK]: ECompareTypeV2.WEEK,
  [ECompareTypeV2.MONTH]: ECompareTypeV2.MONTH,
  [ECompareTypeV2.YEAR_BEFORE_LAST]: ECompareTypeV2.YEAR_BEFORE_LAST,
  [ECompareTypeV2.YEAR_LAST_LAST]: ECompareTypeV2.YEAR_LAST_LAST,
  [ECompareTypeV2.YEAR_4_BEFORE]: ECompareTypeV2.YEAR_4_BEFORE,
  [ECompareTypeV2.YEAR_5_BEFORE]: ECompareTypeV2.YEAR_5_BEFORE,
};

export const DEFAULT_AIRPORT_COMPARE_TYPE_VALUE = ECompareTypeV2.YEAR;
export const DEFAULT_AIRPORT_COMPARE_TYPE_NAME: string =
  COMPARE_TYPE_PER_NAME[DEFAULT_AIRPORT_COMPARE_TYPE_VALUE];

export const DEFAULT_AIRLINE_COMPARE_TYPE_VALUE: ECompareTypeV2 =
  ECompareTypeV2.YEAR;
export const DEFAULT_AIRLINE_COMPARE_TYPE_NAME: string =
  COMPARE_TYPE_PER_NAME[DEFAULT_AIRLINE_COMPARE_TYPE_VALUE];

export const COMPARE_TYPE_HELP: Record<ECompareTypeV2, string> = {
  [ECompareTypeV2.YEAR]: getSharkText("compare_help_year"),
  [ECompareTypeV2.WEEK]: getSharkText("compare_help_week"),
  [ECompareTypeV2.MONTH]: getSharkText("compare_help_month"),
  [ECompareTypeV2.YEAR_BEFORE_LAST]: getSharkText(
    "compare_help_year_before_last"
  ),
  [ECompareTypeV2.LAST_DAY]: getSharkText("compare_help_last_day"),
  [ECompareTypeV2.YEAR_LAST_LAST]: getSharkText("compare_help_year_last_last"),
  [ECompareTypeV2.YEAR_4_BEFORE]: getSharkText("compare_help_year_4_before"),
  [ECompareTypeV2.YEAR_5_BEFORE]: getSharkText("compare_help_year_5_before"),
  [ECompareTypeV2.MARKET_COMPARE]: getSharkText("compare_help_market_compare"),
};

export interface CompareContent {
  valueName: string;
  compareName: string;
  introduce: string;
}
export const CompareTypes: Record<ECompareTypeV2, CompareContent> = {
  [ECompareTypeV2.YEAR]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.YEAR],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.YEAR],
  },
  [ECompareTypeV2.WEEK]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.WEEK],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.WEEK],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.WEEK],
  },
  [ECompareTypeV2.MONTH]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.MONTH],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.MONTH],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.MONTH],
  },
  [ECompareTypeV2.YEAR_BEFORE_LAST]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.YEAR_BEFORE_LAST],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_BEFORE_LAST],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.YEAR_BEFORE_LAST],
  },
  [ECompareTypeV2.LAST_DAY]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.LAST_DAY],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.LAST_DAY],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.LAST_DAY],
  },
  [ECompareTypeV2.YEAR_LAST_LAST]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.YEAR_LAST_LAST],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_LAST_LAST],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.YEAR_LAST_LAST],
  },
  [ECompareTypeV2.YEAR_4_BEFORE]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.YEAR_4_BEFORE],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_4_BEFORE],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.YEAR_4_BEFORE],
  },
  [ECompareTypeV2.YEAR_5_BEFORE]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.YEAR_5_BEFORE],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_5_BEFORE],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.YEAR_5_BEFORE],
  },
  [ECompareTypeV2.MARKET_COMPARE]: {
    valueName: COMPARE_TYPE_VALUE_NAME[ECompareTypeV2.MARKET_COMPARE],
    compareName: COMPARE_TYPE_PER_NAME[ECompareTypeV2.MARKET_COMPARE],
    introduce: COMPARE_TYPE_HELP[ECompareTypeV2.MARKET_COMPARE],
  },
};

// 注意全部为小写
export const ACCEPT_IMAGE_TYPES = [
  ".png",
  ".jpg",
  ".jpeg",
  ".jfif",
  ".gif",
  ".bmp",
];

export const LIMIT_START_DATE = "2000-01-01";
export const LIMIT_END_DATE = "2099-12-31";

export const AggCodeName: Record<AggCode, string> = {
  [AggCode.day]: getSharkText("key.day.button"),

  [AggCode.week]: getSharkText("key.week.button"),
  [AggCode.month]: getSharkText("key.month.button"),
  [AggCode.year]: getSharkText("key.year.button"),
  [AggCode.total]: getSharkText("config_page_all"),
  [AggCode.hour]: getSharkText("config_page_1h"),
  [AggCode.halfHour]: getSharkText("config_page_30min"),
};

/** 周选项, 1为周一, 7为周日 */
export const WEEK_OPTIONS = Array.from({ length: 7 }, (a, v) => ({
  label: v + 1,
  value: v,
}));

export const ChartFormatterFuncs: Record<
  ChartDataFormatter,
  (v: number) => ReactNode
> = {
  num: (v) => v,
  percent: (v) => showNum(v * 100, "percentage"),
  decimal: (v) => showNum(v, "num", 3),
};

/** 默认航司 */
export const DEFAULT_AIRLINE = "DR";
export const DEFAULT_AIRLINE_NAME = "瑞丽航空";

export const ctripDefaultAirport = [
  {
    name: getSharkText("config_page_shanghai_hongqiao"),
    code: "SHA",
  },
];

export const ctripDefaultAirlines = [
  {
    name: DEFAULT_AIRLINE_NAME,
    code: DEFAULT_AIRLINE,
  },
];
export const THEMES = ["light", "dark"] as const;

export const ECHARTS_NODATA_OPTION = {
  graphic: {
    id: "noData",
    type: "text",
    $action: "merge",
    z: 100,
    left: "center",
    top: "center",
    style: {
      fill: "#666",
      text: [
        getSharkText("config_page_data_empty"),
        getSharkText("config_page_please_reselect_filter_conditions"),
      ].join("\n"),
      font: "26px Microsoft YaHei",
    },
  },
};
