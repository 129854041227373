import {
  MonitoringAlertingConfig,
  MonitoringAlertingRule,
} from "@ctrip/flt-bi-flightai-airlines";
import { Button, Col, Row } from "antd";
import { cloneDeep } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { defaultCompareChangeRuleList } from "../SettingCommon";
import InputItem from "./InputItem";
import styles from "./Tabs.module.scss";
import { getSharkText } from "Utils/i18nGlobal";

export interface ICompareChangeProps {
  value: MonitoringAlertingConfig;
  submitRule: (v: MonitoringAlertingConfig) => void;
  editable: boolean;
}

/**
 * 竞飞价格变动
 */
const CompareChange = (props: ICompareChangeProps): ReactElement => {
  const { value, submitRule, editable } = props;
  const [threList, setThreList] = useState<MonitoringAlertingRule[]>(
    defaultCompareChangeRuleList
  );
  const far = threList.find((t) => t.ruleType === 1);
  const farOriginal = useMemo(
    () => value.ruleList?.find((t) => t.ruleType === 1),
    [value]
  );

  useEffect(() => {
    if (value) {
      setThreList(cloneDeep(value.ruleList) || []);
    }
  }, [value]);

  const doSubmit = useCallback(() => {
    submitRule({
      ...value,
      configType: 4,
      ruleList: threList,
    });
  }, [submitRule, threList, value]);

  const resetRuleList = useCallback(() => {
    setThreList(defaultCompareChangeRuleList);
  }, []);

  const changeCondition = (
    v: number | null,
    ruleType: 1 | 2 | 3 | 4,
    s: 1 | 2 | 3 | 4
  ) => {
    const thre = threList.find((t) => t.ruleType === ruleType);
    const attr = `threshold${s}` as keyof MonitoringAlertingRule;
    if (thre) {
      thre[attr] = v || 0;
    } else {
      const defaultRule = defaultCompareChangeRuleList.find(
        (d) => d.ruleType === ruleType
      );
      if (!defaultRule) {
        console.error("not set default last 4 week rule: ruleType: ", ruleType);
      } else {
        threList.push({
          ...defaultRule,
          ruleType,
          [attr]: v,
        });
      }
    }
    setThreList([...threList]);
  };
  return (
    <div className={styles.tab}>
      <p>
        <span>
          {getSharkText("config_page_flight_price_change_greater_than")}
        </span>
        <InputItem
          editable={editable}
          value={far?.threshold1}
          originalValue={farOriginal?.threshold1}
          onChange={(v) => changeCondition(v, 1, 1)}
        />
        <span>{getSharkText("config_page_yuan")}</span>
      </p>
      <Row gutter={[10, 10]} justify="center">
        <Col>
          <Button type="primary" onClick={doSubmit} disabled={!editable}>
            {getSharkText("config_page_save")}
          </Button>
        </Col>
        <Col>
          <Button onClick={resetRuleList}>
            {getSharkText("key.reset.button")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default CompareChange;
