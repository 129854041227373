import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Alert, Card, Col, Popover, Row, Tabs } from "antd";
import AirlinesRanking from "Components/AirlinesRanking";
import { FlightClass, FlightPlanQueryExt, ISeries } from "Interface";
import Page from "Layout/Page";
import UpdateTime from "Components/UpdateTime";
import AirlinesPlanTable from "./Components/AirlinesPlanTable";
import FlightsSeasonPlanTable from "./Components/FlightSeasonPlanTable";
import useGlobal from "Store";
import { getUserAirlines, isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { DEFAULT_AREA } from "Constants";
import AirportIndex from "./AirportIndex";
import BarLineChartsV2 from "Components/BarLineChartsV2";
import RankingV2 from "Components/RankingV2/RankingV2";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  executeTransFn,
  flightPlanFlightClass,
} from "./FlightPlanAirlineCommon";
import AirlineSearch from "./Components/AirlineSearch";

const { TabPane } = Tabs;

const rankTabAirlines = [
  {
    title: getSharkText("key.temp_cancelled.name"),
    type: 0,
  },
  {
    title: getSharkText("key.temp_added.name"),
    type: 1,
  },
];

interface FlightPlanProps {
  intl?: boolean;
}

const FlightPlan = (props: FlightPlanProps): ReactElement<FlightPlanProps> => {
  const { intl } = props;
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition, userInfo } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const [loading, setLoading] = useState(false);

  const defaultAirlinesQueryExt: FlightPlanQueryExt = useMemo(() => {
    const userAirlines = getUserAirlines(userInfo.roleList);
    return {
      departArea: DEFAULT_AREA,
      arriveArea: DEFAULT_AREA,
      filterAirlines: userAirlines.map((u) => u.code).join(","),
    };
  }, [userInfo.roleList]);
  const [airlinesOnSaleAirlineExt, setAirlinesOnSaleAirlineExt] =
    useState<FlightPlanQueryExt>(defaultAirlinesQueryExt);

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setAirlinesOnSaleAirlineExt(defaultAirlinesQueryExt);
    setTimeout(() => setLoading(false));
  }, [
    queryCondition.flightClass,
    airlinesQueryCondition.flightClass,
    defaultAirlinesQueryExt,
  ]);

  const defaultSeries: ISeries[] = useMemo(
    () => [
      {
        type: "line",
        encode: { x: "day", y: "planValue" },
        name: getSharkText("key.scheduled.name"),
        showSymbol: false,
        symbol: "circle",
      },
      {
        type: "line",
        encode: { x: "day", y: "cancelValue" },
        name: getSharkText("key.cancelled.name"),
        showSymbol: false,
        symbol: "circle",
      },
      {
        type: "line",
        encode: { x: "day", y: "newValue" },
        name: getSharkText("key.added.name"),
        showSymbol: false,
        symbol: "circle",
      },
    ],
    []
  );

  const alertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].top10}
      text={
        isAirport
          ? `${getSharkText(
              "key.stopover_codeshare_excluded.hint"
            )}${getSharkText("key.note1.flightplan")}${getSharkText(
              "key.note2.flightplan"
            )}`
          : `${getSharkText(
              "key.stopover_codeshare_excluded.hint"
            )}${getSharkText("key.note1.flightplan")}${getSharkText(
              "key.note2.flightplan"
            )}`
      }
    />
  );

  const executeTitle = (
    <span>
      {getSharkText("key.airline_execution_rate")}
      <Popover
        content={
          <div>
            {getSharkText("key.ex_rate_note1")}
            <br />
            {getSharkText("key.ex_rate_note2")}
          </div>
        }
      >
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
      </Popover>
    </span>
  );

  return (
    <>
      {isAirport ? (
        <AirportIndex intl={intl} />
      ) : (
        <Page
          defaultDateMode="ninetyDays"
          airlinesCompareType={1}
          isShowCompareType={false}
          flightClassName={flightPlanFlightClass}
          intl={intl}
        >
          {loading ? undefined : (
            <>
              <div className="content-white">
                <Alert message={alertMessage} banner type="info" />
                <AirlineSearch
                  value={airlinesOnSaleAirlineExt}
                  onChange={setAirlinesOnSaleAirlineExt}
                />
                <Row
                  gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
                  style={{ marginTop: 10 }}
                >
                  <Col xxl={intl ? 24 : 16} xl={intl ? 24 : 16} md={24} xs={24}>
                    <BarLineChartsV2
                      queryUrl={QUERY_URL[systemType].lineCharts}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
                      ext={airlinesOnSaleAirlineExt}
                      defaultSeries={defaultSeries}
                      style={{ height: "532px" }}
                    />
                  </Col>
                  <Col xxl={intl ? 24 : 8} xl={intl ? 24 : 8} md={24} xs={24}>
                    <Tabs type="card">
                      <TabPane
                        key={0}
                        tab={getSharkText("key.top_10_routes.name")}
                      >
                        <AirlinesRanking
                          title=""
                          queryUrl={QUERY_URL[systemType].top10}
                          moduleCode={MODULE_CODE[systemType]}
                          chartTableCode="AL0402"
                          tab={rankTabAirlines}
                          airlinesOnsaleAirlineExt={airlinesOnSaleAirlineExt}
                        />
                      </TabPane>
                      <TabPane key={1} tab={executeTitle}>
                        <RankingV2
                          queryUrl={QUERY_URL[systemType].execute}
                          moduleCode={MODULE_CODE[systemType]}
                          chartTableCode={CHART_TABLE_CODE[systemType].execute}
                          formatter="percent"
                          dataTransFn={executeTransFn}
                          usePagination
                          ext={{
                            ...airlinesOnSaleAirlineExt,
                            orderSort: 1,
                            type: 0,
                          }}
                          defaultData={[
                            { id: "-1", name: "--", value: null, rnk: 1 },
                          ]}
                        />
                      </TabPane>
                    </Tabs>
                  </Col>
                </Row>
                <Row style={{ marginTop: "16px" }}>
                  <Col>
                    <Card>
                      <AirlinesPlanTable
                        queryUrl={QUERY_URL[systemType].table}
                        moduleCode={MODULE_CODE[systemType]}
                        chartTableCode={CHART_TABLE_CODE[systemType].table}
                        airlinesOnsaleAirlineExt={airlinesOnSaleAirlineExt}
                      />
                    </Card>
                  </Col>
                </Row>
                {/* <Row>
                  <Col span={24}>
                    <FlightWave />
                  </Col>
                </Row> */}
                {airlinesQueryCondition.flightClass === FlightClass.Domestic ? (
                  <Row style={{ marginTop: "16px" }}>
                    <Col>
                      <Card>
                        <FlightsSeasonPlanTable />
                      </Card>
                    </Col>
                  </Row>
                ) : undefined}
              </div>
            </>
          )}
        </Page>
      )}
    </>
  );
};

export default FlightPlan;
