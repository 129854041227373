import React, { useMemo } from "react";
import { Dropdown, Tabs, Tag } from "antd";
import useGlobal from "Store";
import { Module } from "Interface";
import { isAirportMode } from "Utils";
import "./index.scss";
import { DelayLink } from "./DelayLink";
import useRefFunc from "Utils/useRefFunc";
import { getMenuTree, getModuleName } from "Utils/global";
import { DownOutlined } from "@ant-design/icons";

const Nav: React.FC = () => {
  const [globalState] = useGlobal();
  const { systemType, userInfo } = globalState;
  const { moduleList, user, roleList } = userInfo;
  const isAirport = isAirportMode(systemType);
  const menus = useMemo(() => {
    return getMenuTree(moduleList, user, isAirport, roleList);
  }, [isAirport, moduleList, roleList, user]);

  const renderLinkItem = useRefFunc((item: Module) => {
    return (
      <DelayLink to={item.menuUrl} key={item.menuUrl}>
        <span className="nav-page-name">{getModuleName(item)}</span>
        {item.newModule === 1 ? (
          <Tag color="#f5222d" style={{ marginLeft: 15, lineHeight: "19px" }}>
            new
          </Tag>
        ) : undefined}
      </DelayLink>
    );
  });

  const dropdownRender = useRefFunc((downMenus: Module[]) => {
    const list = downMenus.map((menu) => {
      // 包含三级菜单
      if (menu.children.length) {
        return (
          <div key={menu.moduleCode} className="dropdown-item">
            <div className="nav-second-title">{getModuleName(menu)}</div>
            <div>
              {menu.children.map((child) => {
                return (
                  <div key={child.moduleCode} className="nav-third-item">
                    {renderLinkItem(child)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        return (
          <div key={menu.moduleCode} className="dropdown-item">
            {renderLinkItem(menu)}
          </div>
        );
      }
    });
    return <div className="nav-menu-dropdown">{list}</div>;
  });

  const renderMenuItem = useRefFunc((item: Module) => {
    if (item.children.length) {
      return (
        <Dropdown
          key={item.moduleCode}
          dropdownRender={() => dropdownRender(item.children)}
          placement="bottomCenter"
        >
          <span className="nav-first-menu">
            <span style={{ marginRight: 3 }}>{getModuleName(item)}</span>
            <DownOutlined />
          </span>
        </Dropdown>
      );
    } else {
      const link = renderLinkItem(item);
      return <span className="nav-first-menu">{link}</span>;
    }
  });

  return (
    <div className="nav-menu">
      <Tabs
        popupClassName="nav-menu-popup"
        items={menus.map((menu, i) => ({
          key: `id_${i}`,
          label: renderMenuItem(menu),
        }))}
      />
    </div>
  );
};

export default Nav;
