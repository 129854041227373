import { useEffect, useMemo, useState } from "react";
import { useServices } from "./useServices";
import useRefFunc from "Utils/useRefFunc";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";

export const useDataset = (
  datasetId: number
): { isLoading: boolean; datasetCols: FDDatasetCol[] } => {
  const [isLoading, setIsLoading] = useState(false);
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const services = useServices();

  const init = useRefFunc(() => {
    setIsLoading(true);
    services
      .getDatasetColsOnQuery(datasetId)
      .then(
        (r) => {
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e))
      .finally(() => {
        setIsLoading(false);
      });
  });

  useEffect(() => {
    init();
  }, [init]);

  const rst = useMemo(() => {
    return {
      isLoading,
      datasetCols,
    };
  }, [datasetCols, isLoading]);

  return rst;
};
