import React, { ReactElement, useState } from "react";
import SearchArea from "./SearchArea";
import { ODParam } from "./interface";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import {
  AIRLINE_MARK,
  DESTINATION_AIRPORT,
  ORIGIN_AIRPORT,
  TAKEOFF_MONTH,
  TRANSIT_AIRPORT1,
  TRANSIT_AIRPORT2,
  TRANSIT_CLASS,
  DATASET_ID,
} from "./ODCols";
import { useDataset } from "Page/AI/FreeDashboard/useDataset";
import ODTrend from "./ODTrend";
import ODBar from "./ODBar";
import ODTable from "./ODTable";
import { DATASET_ID as TREND_DATASET_ID } from "./ODTrendCols";
import FullSpin from "Components/FullSpin";

export const getFilters = (param: ODParam): StandardFilter[] => {
  const tmpFilters: StandardFilter[] = [
    {
      range: {
        field: `dimension.${TAKEOFF_MONTH}`,
        key: TAKEOFF_MONTH,
        strRange: {
          lower: param.dateRange[0]?.format("YYYY-MM") || "",
          upper: param.dateRange[1]?.format("YYYY-MM") || "",
        },
      },
    },
    {
      wildcard: {
        field: `dimension.${AIRLINE_MARK}`,
        key: AIRLINE_MARK,
        value: `*${param.airline}*`,
      },
    },
  ];
  if (param.stops.length) {
    tmpFilters.push({
      in: {
        field: `dimension.${TRANSIT_CLASS}`,
        key: TRANSIT_CLASS,
        // @ts-ignore
        values: param.stops,
      },
    });
  }
  if (param.originArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${ORIGIN_AIRPORT}`,
        values: [param.originArea.areaCode || ""],
      },
    });
  }
  if (param.departArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${TRANSIT_AIRPORT1}`,
        values: [param.departArea.areaCode || ""],
      },
    });
  }
  if (param.arriveArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${TRANSIT_AIRPORT2}`,
        values: [param.arriveArea.areaCode || ""],
      },
    });
  }
  if (param.destinationArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${DESTINATION_AIRPORT}`,
        values: [param.destinationArea.areaCode || ""],
      },
    });
  }
  return tmpFilters;
};

export interface ODProps {
  example?: string;
}

/**  Component description */
const OD = (props: ODProps): ReactElement => {
  const { example } = props;
  const [param, setParam] = useState<ODParam | null>();
  const { isLoading, datasetCols } = useDataset(DATASET_ID);
  const { isLoading: isLoadingTrend, datasetCols: trendDatasetCols } =
    useDataset(TREND_DATASET_ID);

  if (isLoading || isLoadingTrend) {
    return <FullSpin />;
  }

  return (
    <div>
      <SearchArea onQuery={setParam} />
      {param ? (
        <div>
          <div>
            <h3 style={{ marginTop: 10 }}>Trend</h3>
            <div style={{ border: "1px solid var(--filter-bg-border)" }}>
              <ODTrend param={param} cols={trendDatasetCols} />
            </div>
          </div>
          <div style={{ marginTop: 25 }}>
            <ODBar param={param} cols={datasetCols} />
          </div>
          <div style={{ marginTop: 25 }}>
            <ODTable param={param} cols={datasetCols} />
          </div>
        </div>
      ) : (
        "No data"
      )}
    </div>
  );
};
export default OD;
