import { Button, Checkbox, Col, Row } from "antd";
import SelectedAirlines from "Components/SelectedAirlines";
import Airlines from "Components/Toolbar/Airlines";
import { DEFAULT_FLIGHT_AREA } from "Constants";
import { AirlinesQueryExt, FlightPlanQueryExt } from "Interface";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import useGlobalState from "Store";
import { getUserAirlines, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";

export interface IAirlineSearchProps {
  value: FlightPlanQueryExt;
  onChange: (v: FlightPlanQueryExt) => void;
}

/**
 * 航班计划-航司-选择区域
 */
const AirlineSearch = (props: IAirlineSearchProps): ReactElement => {
  const { value, onChange } = props;
  const [globalState] = useGlobalState();
  const { userInfo } = globalState;
  const [isAirlinesFilter, setIsAirlinesFilter] = useState<boolean>(true);
  const [airlineQueryExt, setAirlineQueryExt] =
    useState<AirlinesQueryExt>(DEFAULT_FLIGHT_AREA);

  const [filterAirlines, setFilterAirlines] = useState<string>(() => {
    const userAirlines = getUserAirlines(userInfo.roleList);
    return userAirlines.map((u) => u.code).join(",");
  });
  const [{ data }] = useFetch({
    url: "findAirlines",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
  });
  const airlines = useMemo(() => {
    return data.map((d: any) => ({
      code: d.airlinesCode,
      name: d.airlinesName,
    }));
  }, [data]);

  useEffect(() => {
    setAirlineQueryExt({
      departArea: value.departArea,
      arriveArea: value.arriveArea,
    });
  }, [value.arriveArea, value.departArea]);

  const handleSubmit = useRefFunc(() => {
    onChange({
      departArea: airlineQueryExt.departArea,
      arriveArea: airlineQueryExt.arriveArea,
      filterAirlines: isAirlinesFilter ? filterAirlines : "",
    });
  });
  return (
    <Row gutter={[20, 20]}>
      <Col>
        <SelectedAirlines
          changeTypeEnable={false}
          airlinesQueryExt={airlineQueryExt}
          setAirlinesQueryExt={setAirlineQueryExt}
          submitControl={false}
          useExchange
          useDomesticArea
        />
      </Col>
      <Col style={{ paddingTop: 16 }}>
        <Checkbox
          checked={isAirlinesFilter}
          onChange={(e) => setIsAirlinesFilter(e.target.checked)}
          style={{ marginRight: 5 }}
        />
        <span>{getSharkText("key.airline_filter")}</span>
        <Airlines
          value={filterAirlines}
          airlines={airlines}
          disabled={!isAirlinesFilter}
          onChange={(v) => setFilterAirlines(v.code)}
          allowEmpty
        />
      </Col>
      <Col style={{ paddingTop: 16 }}>
        <Button onClick={handleSubmit} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default AirlineSearch;
