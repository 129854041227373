import { IS_PRO } from "Constants";

export const DATASET_ID = IS_PRO ? 393 : 148;
export const DEPART_AREA = "depart_area";
export const ARRIVAL_AREA = "arrival_area";
export const ORIGIN = "origin";
export const TRANSFER_POINT = "transfer_point";
export const TRANSIT_CLASS = "transit_class";
export const TAKEOFF_MONTH = "takeoffmonth";
export const PAX_MASKING = "pax_masking";
export const REVENUE_SUM = "revenue_sum";
export const PERSONS_SUM = "persons_sum";
export const AIRLINE_MARK = "airline_mark";
export const AVG_PRICE = "1ee3e";
export const DEP_CITYCODE = "dep_citycode";
export const DEP_PROVINCEID = "dep_provinceid";
export const DEP_COUNTRYCODE = "dep_countrycode";
export const ARR_CITYCODE = "arr_citycode";
export const ARR_PROVINCEID = "arr_provinceid";
export const ARR_COUNTRYCODE = "arr_countrycode";
export const ORI_CITYCODE = "ori_citycode";
export const ORI_PROVINCEID = "ori_provinceid";
export const ORI_COUNTRYCODE = "ori_countrycode";
export const TRANS_CITYCODE = "trans_citycode";
export const TRANS_PROVINCEID = "trans_provinceid";
export const TRANS_COUNTRYCODE = "trans_countrycode";

export const DEP_AREA_NAME: { [key: number]: string } = {
  2: DEP_COUNTRYCODE,
  3: DEP_PROVINCEID,
  4: DEP_CITYCODE,
  5: DEPART_AREA,
};

export const ARR_AREA_NAME: { [key: number]: string } = {
  2: ARR_COUNTRYCODE,
  3: ARR_PROVINCEID,
  4: ARR_CITYCODE,
  5: ARRIVAL_AREA,
};

export const ORI_AREA_NAME: { [key: number]: string } = {
  2: ORI_COUNTRYCODE,
  3: ORI_PROVINCEID,
  4: ORI_CITYCODE,
  5: ORIGIN,
};

export const TRANS_AREA_NAME: { [key: number]: string } = {
  2: TRANS_COUNTRYCODE,
  3: TRANS_PROVINCEID,
  4: TRANS_CITYCODE,
  5: TRANSFER_POINT,
};
