import { Tabs } from "antd";
import TablePortal from "Page/AI/MonitoringAlerting/Components/TablePortal";
import {
  EPanelName,
  EPanelType,
} from "Page/AI/MonitoringAlerting/MonitoringAlertingCommon";
import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import { IFlightRecord } from "../FlightManageInterface";
import { getSharkText } from "Utils/i18nGlobal";

const { TabPane } = Tabs;

/**
 * Component description
 * 预警弹窗
 */

const tabs: EPanelType[] = [
  EPanelType.Capacity,
  EPanelType.Search,
  EPanelType.Load,
  EPanelType.Price,
];

interface IProps {
  record: IFlightRecord;
}

const AlertModal = (props: IProps): ReactElement => {
  const { record } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [queryExtCity, setQueryExtCity] = useState<Record<string, unknown>>({});
  const [queryExtPort, setQueryExtPort] = useState<Record<string, unknown>>({});
  const [typeCount, setTypeCount] = useState<Record<EPanelType, number>>(
    {} as any
  );
  useEffect(() => {
    setQueryExtCity({
      lineList: [record.routeCity],
      query: {
        ...airlinesQueryCondition,
        startDate: record.takeoffdate,
        endDate: record.takeoffdate,
      },
    });
    setQueryExtPort({
      lineList: [record.route],
      query: {
        ...airlinesQueryCondition,
        startDate: record.takeoffdate,
        endDate: record.takeoffdate,
      },
    });
  }, [record]);
  const setTabCount = (tab: EPanelType, count: number) => {
    setTypeCount({
      ...typeCount,
      [tab]: count,
    });
  };

  const getContent = (t: EPanelType) => {
    if (![EPanelType.Speed, EPanelType.Load].includes(t)) {
      return (
        <TablePortal
          panelType={t}
          selectedStatus={[0]}
          queryExt={t > 1 ? queryExtPort : queryExtCity}
          setTabCount={setTabCount}
          tableProps={{ subTitle: `${EPanelName[t]}预警` }}
        />
      );
    } else {
      return (
        <Tabs
          defaultActiveKey="2"
          tabBarStyle={{ margin: "-15px 20px 0", paddingLeft: 150 }}
        >
          <TabPane
            forceRender
            tab={
              <span>
                {getSharkText("menu_load_factor")}
                <span
                  style={{
                    marginLeft: 5,
                    padding: "0 5px",
                    backgroundColor: "#e8e8e8",
                    display: "inline-block",
                    color:
                      typeCount[EPanelType.Load] &&
                      typeCount[EPanelType.Load] !== 0
                        ? "#ff3131"
                        : "gray",
                    borderRadius: 7,
                  }}
                >
                  {typeCount[EPanelType.Load] || 0}
                </span>
              </span>
            }
            key={EPanelType.Load.toString()}
          >
            <TablePortal
              panelType={EPanelType.Load}
              selectedStatus={[0]}
              queryExt={EPanelType.Load > 1 ? queryExtPort : queryExtCity}
              setTabCount={setTabCount}
              tableProps={{
                subTitle: getSharkText("menu_load_factor"),
              }}
            />
          </TabPane>
          <TabPane
            forceRender
            tab={
              <span>
                {EPanelName[EPanelType.Speed]}
                <span
                  style={{
                    marginLeft: 5,
                    padding: "0 5px",
                    backgroundColor: "#e8e8e8",
                    display: "inline-block",
                    color:
                      typeCount[EPanelType.Speed] &&
                      typeCount[EPanelType.Speed] !== 0
                        ? "#ff3131"
                        : "gray",
                    borderRadius: 7,
                  }}
                >
                  {typeCount[EPanelType.Speed] || 0}
                </span>
              </span>
            }
            key={EPanelType.Speed.toString()}
          >
            <TablePortal
              panelType={EPanelType.Speed}
              selectedStatus={[0]}
              queryExt={EPanelType.Speed > 1 ? queryExtPort : queryExtCity}
              setTabCount={setTabCount}
              tableProps={{ subTitle: `${EPanelName[EPanelType.Speed]}` }}
            />
          </TabPane>
        </Tabs>
      );
    }
  };

  const getCount = (t: EPanelType) => {
    let rst = typeCount[t];
    if (t === EPanelType.Load) {
      rst = typeCount[t] + typeCount[EPanelType.Speed];
    }
    return rst || 0;
  };

  return (
    <div style={{ paddingRight: 15 }}>
      <Tabs
        defaultActiveKey={EPanelType.Capacity.toString()}
        tabBarGutter={20}
        tabBarStyle={{ marginLeft: 20 }}
      >
        {tabs.map((t) => (
          <TabPane
            forceRender
            tab={
              <span>
                {EPanelName[t]}
                {getSharkText("config_page_warning")}
                <span
                  style={{
                    marginLeft: 5,
                    padding: "0 5px",
                    backgroundColor: "#e8e8e8",
                    display: "inline-block",
                    color:
                      getCount(t) && getCount(t) !== 0 ? "#ff3131" : "gray",
                    borderRadius: 7,
                  }}
                >
                  {getCount(t)}
                </span>
              </span>
            }
            key={t.toString()}
          >
            {getContent(t)}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};
export default AlertModal;
