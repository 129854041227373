import { SearchOutlined } from "@ant-design/icons";
import { Col, Input, Pagination, Radio, Row } from "antd";
import Side from "Components/Official/Side";
import { SERVER } from "Constants";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDebounce } from "Utils/useDebounce";
import ReportItem from "./ReportItem";
import "./index.scss";
import { Article, ArticleType } from "@ctrip/flt-bi-flightai-base";
import { useFetch } from "Utils";

/**
 * 研究报告列表页
 */
const ReportList = (): ReactElement => {
  const [searchVal, setSearchVal] = useState<string>("");
  const [pn, setPn] = useState<number>(1);
  const [ps, setPs] = useState<number>(10);
  const [typeId, setTypeId] = useState<number>(-1);
  const [current, setCurrent] = useState<number>(1);

  const [{ data: types }] = useFetch<ArticleType[]>({
    server: SERVER,
    url: "listArticleType",
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      orderSort: 1,
      page: {
        page: 1,
        pageSize: 1000,
      },
    },
  });

  const listTypes: ArticleType[] = useMemo(() => {
    return [
      {
        typeId: -1 as number | null,
        typeName: "全部" as string | null,
      },
    ].concat(types);
  }, [types]);

  const [{ data, res }, listFetch] = useFetch<Article[]>({
    server: SERVER,
    url: "listArticle",
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      orderSort: 1,
      keyword: "",
      typeId: null,
      page: {
        page: 1,
        pageSize: 1000,
      },
    },
    onError: (err) => {
      console.log(err);
    },
  });
  // 过滤文章
  const fn = useDebounce(() => {
    listFetch({
      ext: {
        orderSort: 1,
        keyword: searchVal,
        typeId: typeId === -1 ? null : typeId,
        page: {
          page: pn,
          pageSize: ps,
        },
      },
    });
  }, 300);
  /**
   * 当类型和查询文字变更时, 切换到第一页
   */
  useEffect(() => {
    setPn(1);
    setCurrent(1);
  }, [typeId, searchVal]);
  /**
   *  effect description
   */
  useEffect(() => {
    fn();
  }, [fn, pn, ps, searchVal, typeId]);

  const changePage = useCallback((page: number, pageSize: number) => {
    setPn(page);
    setPs(pageSize);
    setCurrent(page);
  }, []);

  return (
    <>
      <div className="top-banner advisory-banner">
        <div className="base-con">
          <div className="banner-con">
            <h1>研究报告</h1>
            <h4>
              基于丰富数据，为航司、机场等合作伙伴提供市场洞察、数据分析与智能解决方案。
            </h4>
          </div>
        </div>
      </div>
      <div className="base-mode report-mod">
        <div className="base-con">
          <Row justify="space-between">
            <Col>
              <Radio.Group
                className="types"
                defaultValue={-1}
                buttonStyle="solid"
                onChange={(e) => setTypeId(e.target.value)}
              >
                {listTypes
                  ? listTypes
                      .filter((t) => !!t)
                      .map((t) => (
                        <Radio.Button
                          className="type-button"
                          value={t.typeId}
                          key={t.typeId}
                        >
                          {t.typeName}
                        </Radio.Button>
                      ))
                  : undefined}
              </Radio.Group>
            </Col>
            <Col>
              <Input
                value={searchVal}
                onChange={(e) => setSearchVal(e.target.value)}
                placeholder="搜索报告"
                size="large"
                suffix={<SearchOutlined />}
              ></Input>
            </Col>
          </Row>
        </div>
        <div className="base-list report-list">
          <div className="base-con">
            {data &&
              data.map((d, i) => (
                <ReportItem key={d.id} article={d} index={i} types={types} />
              ))}
            <Pagination
              onChange={changePage}
              style={{ boxSizing: "border-box" }}
              current={current}
              total={res?.page.total || 10}
            />
          </div>
        </div>
      </div>
      <Side />
    </>
  );
};
export default ReportList;
