import { DATE_FORMAT } from "Constants";
import {
  DynamicFarePriceOptMode,
  FlightDynamicFarePriceType,
  FlightFarePriceSetType,
  IModuleConfig,
} from "Interface";
import { getSharkText } from "Utils/i18nGlobal";
import ubtUtils from "Utils/ubtUtils";
import { isNumber } from "lodash";
import { Moment } from "moment";
import { Key } from "react";

export const STATUS_MAP: Record<string, string> = {
  PROCESSING: getSharkText("processing"),
  PROCESSED: getSharkText("config_page_success"),
  UNPROCESSED: getSharkText("unprocessed"),
  FAILED: getSharkText("config_page_fail"),
};

const PERMISSION_CONFIG_KEY = "flightmanage.airline.dynamic_fare_price";

/** 如果config有配置, 则代表有权限, 暂没有细分权限的控制 */
export const hasDynamicPricingPermission = (
  moduleConfig: IModuleConfig,
  airline: string
): boolean => {
  if (moduleConfig && moduleConfig[PERMISSION_CONFIG_KEY]) {
    try {
      const list = JSON.parse(moduleConfig[PERMISSION_CONFIG_KEY]);
      if (list.includes("*")) {
        return true;
      } else if (list.includes(airline)) {
        return true;
      }
    } catch (e: any) {
      ubtUtils.error("hasDynamicPricingPermission", e.message, e.stack, 2);
    }
  }
  return false;
};

export const submitValidate = ({
  data,
  cabinType,
  price,
  optMode,
  dow,
  dateRange,
}: {
  data: FlightDynamicFarePriceType;
  cabinType: string;
  price: string;
  optMode: DynamicFarePriceOptMode;
  dow: Key[];
  dateRange: [Moment, Moment];
}): { errorMsg?: string; param?: FlightFarePriceSetType } => {
  // 检查价格
  const idx = data.priceList.findIndex((f) => f.cabinType === cabinType);
  const currentCabinPrice = data.priceList[idx]?.standardPrice;
  const nextCabinPrice = data.priceList[idx + 1]?.standardPrice;
  const targetPrice = price === "" ? null : Number(price);
  if (!cabinType && optMode === DynamicFarePriceOptMode.PUBLISH) {
    return {
      errorMsg: getSharkText("fare_publishing_mode_must_select_cabin"),
    };
  }
  if (optMode === DynamicFarePriceOptMode.PUBLISH && !isNumber(targetPrice)) {
    return { errorMsg: getSharkText("please_enter_price") };
  }
  if (isNumber(targetPrice)) {
    if (currentCabinPrice != null && targetPrice > currentCabinPrice) {
      return {
        errorMsg: getSharkText("price_cannot_exceed_cabin_fare"),
      };
    }
    if (nextCabinPrice != null && targetPrice < nextCabinPrice) {
      return {
        errorMsg: getSharkText("price_cannot_be_lower_than_next_cabin"),
      };
    }
  }
  if (dow.length === 0) {
    return { errorMsg: getSharkText("please_select_weekday") };
  }
  const param: FlightFarePriceSetType = {
    flightno: data.flightno,
    seg: data.line,
    startdate: dateRange[0].format(DATE_FORMAT),
    enddate: dateRange[1].format(DATE_FORMAT),
    dow: dow.map((d) => Number(d) + 1).join(""),
    optMode,
    cabinType: cabinType || null,
    targetPrice,
  };
  return { param };
};
