export const MODULE_CODE: any = {
  1: "",
  2: "monitoring_alerting_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {},
  2: {
    routeFlight: "non",
    table: "non",
    feedback: "non",
    setHome: "non",
    loadLineChart: "non",
    none: "non",
  },
};

export const QUERY_URL: any = {
  1: {},
  2: {
    capacity: "queryMonitoringAlertingCapacity",
    search: "queryMonitoringAlertingSearch",
    set: "setMonitoringAlertingStatus",
    change: "queryMonitoringAlertingFlightChange",
    load: "queryMonitoringAlertingLf",
    loadLineChart: "queryMonitoringAlertingLfBarline",
    price: "queryMonitoringAlertingPrice",
    speed: "queryMonitoringAlertingSpeed",
    combiTable: "queryMonitoringAlertingCombiRuleTable",
    saveRule: "saveMonitoringAlertingCombiRule",
    queryRule: "queryMonitoringAlertingCombiRule",
    tag: "queryMonitoringAlertingCombiRuleTag",
  },
};
