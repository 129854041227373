import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Row, Statistic, Tooltip, Typography } from "antd";
import Trend from "./Trend";
import { CardItemData, ECompareTypeV2, SystemType } from "Interface";
import _, { round } from "lodash";
import { calculateCardsContrastVal, showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";

interface CardItemProps {
  data: CardItemData;
  selectedCode: string;
  setCurCard: (card: CardItemData) => void;
  compareType?: ECompareTypeV2;
  systemType?: SystemType;
}

const { Text } = Typography;

const CardItem: React.FC<CardItemProps> = (props: CardItemProps) => {
  const { data, setCurCard, selectedCode, systemType, compareType } = props;
  const { cardCode, cardName, cardValue, cardHint, compareValue } = data;

  const isPercentVal = cardCode === "final_lf";
  const isAirlinesMarketComparison = systemType === 2 && compareType === 1;
  const calculateVal =
    compareValue === null
      ? null
      : cardCode === "final_lf"
      ? (cardValue - compareValue) * 100
      : calculateCardsContrastVal(
          cardValue,
          compareValue,
          compareType,
          systemType,
          cardCode
        );
  const isMarketComparison =
    cardCode === "passenger_traffic" ||
    cardCode === "transport_capacity" ||
    cardCode === "flight_sorties";
  const isShowEmpty = cardValue === 0;
  const isShowCardFlag = (): boolean => {
    if (!_.isFinite(calculateVal) || calculateVal === 0 || isShowEmpty) {
      return false;
    } else {
      return isAirlinesMarketComparison ? !isMarketComparison : true;
    }
  };

  const handleSelectCard = () => {
    setCurCard(data);
  };

  return (
    <Card
      id={cardCode}
      size="small"
      bordered={false}
      className={`card ${selectedCode === cardCode ? "cur" : ""}`}
      hoverable={true}
      onClick={handleSelectCard}
    >
      <div className="card-title">
        <div className="title">{cardName}</div>
        <Tooltip title={<span>{cardHint}</span>}>
          {cardHint && <InfoCircleOutlined />}
        </Tooltip>
      </div>
      {isShowEmpty ? (
        <div className="ant-statistic-content">
          <span className="ant-statistic-content-value">
            <span className="ant-statistic-content-value-int">-</span>
          </span>
        </div>
      ) : (
        <Statistic
          groupSeparator=","
          precision={cardCode === "kilo_income" ? 3 : isPercentVal ? 1 : 0}
          value={
            cardCode === "kilo_income"
              ? round(cardValue, 4)
              : showNum(
                  isPercentVal ? cardValue * 100 : cardValue,
                  isPercentVal ? "percentage" : "num"
                )
          }
        />
      )}
      {cardCode !== "order_index" ? (
        <>
          <Divider style={{ margin: "6px 0" }} />
          <Row justify="space-between">
            <Col>
              <Trend
                flag={compareValue < cardValue ? "up" : "down"}
                showFlag={isShowCardFlag()}
              >
                <Text className="card-text">
                  {getSharkText("config_page_wow")}&nbsp;
                  {isShowEmpty ? (
                    "-"
                  ) : (
                    <ValueNum
                      isShowZero={false}
                      type="percentage"
                      value={calculateVal}
                    />
                  )}
                </Text>
              </Trend>
            </Col>
            {cardCode === "avg_price" && data.extValue !== null ? (
              <Col>
                <Text className="card-text">
                  {getSharkText("config_page_discount_rate")}&nbsp;
                  {round(data.extValue || 0, 2)}
                </Text>
              </Col>
            ) : undefined}
          </Row>
        </>
      ) : undefined}
    </Card>
  );
};

export default CardItem;
