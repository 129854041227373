import { Col, message, Row } from "antd";
import classnames from "classnames";
import React, { PropsWithChildren, ReactElement } from "react";
import SvgIcon from "Components/SvgIcon";
import "./FullScreen.css";
import { getSharkText } from "Utils/i18nGlobal";

/**
 *  Created by leijiang on 2019/04/17
 */
interface IFullScreenProps {
  isFullScreen: boolean;
  showMessage?: boolean;
  onOpen?: (v?: any) => void;
  onChange: (v: boolean) => void;
  onClose?: (v?: any) => void;
  titleExtends?: ReactElement;
}
// interface IHolderStyle {
//   width: number
//   height: number
//   display: 'none' | 'block'
// }

interface IFullScreenState {
  isFullScreen: boolean;
  // holderStyle: IHolderStyle
}

export default class FullScreen extends React.Component<
  PropsWithChildren<IFullScreenProps>,
  IFullScreenState
> {
  ref: any;
  constructor(props: IFullScreenProps) {
    super(props);
    this.ref = React.createRef();
    const { isFullScreen } = this.props;
    this.state = {
      // true 全屏状态 false 正常状态
      isFullScreen: isFullScreen || false,
    };
  }

  componentDidUpdate(): void {
    const { isFullScreen } = this.props;
    const { isFullScreen: stateFullScreen } = this.state;
    if (stateFullScreen !== isFullScreen) {
      if (isFullScreen) {
        this.open();
        return;
      }
      this.close();
    }
  }

  addEventListener = (eventName: string, callBack: any, option: any): void => {
    this.ref.current.addEventListener(eventName, callBack, option);
  };

  removeEventListener = (
    eventName: string,
    callBack: any,
    option: any
  ): void => {
    this.ref.current.removeEventListener(eventName, callBack, option);
  };

  fullScreen = (): void => {
    const { isFullScreen } = this.state;
    if (!isFullScreen) {
      this.open();
    } else {
      this.close();
    }
  };

  close = (): void => {
    const { onChange, onClose: onPropsClose } = this.props;
    if (onChange) {
      onChange(false);
    }
    this.setState({ isFullScreen: false }, () => {
      window.removeEventListener("keydown", this.keyShort, false);
      window.dispatchEvent(new Event("resize"));
      if (onPropsClose) {
        onPropsClose();
      }
    });
  };

  open = (onOpen?: any, showMessage?: boolean): void => {
    // full-screen 撑满屏幕，holder 占据children的位置
    const {
      onChange,
      showMessage: propsShowMessage,
      onOpen: onPropsOpen,
    } = this.props;
    if (onChange) {
      onChange(true);
    }
    // const { height, width } = this.ref.current.getBoundingClientRect();
    this.setState({ isFullScreen: true }, () => {
      window.dispatchEvent(new Event("resize"));
      window.addEventListener("keydown", this.keyShort, false);
      if (typeof showMessage === "boolean") {
        if (showMessage) {
          message.info({
            duration: 3,
            // closeable: false,
            content: getSharkText("config_page_press_esc_exit_fullscreen"),
            // hasMask: true,
          });
        }
      } else if (typeof propsShowMessage !== "boolean" || propsShowMessage) {
        message.info({
          duration: 3,
          // closeable: false,
          content: getSharkText("config_page_press_esc_exit_fullscreen"),
          // hasMask: true,
        });
      }

      if (onPropsOpen) {
        onPropsOpen();
      }
      if (onOpen) {
        onOpen();
      }
    });
  };

  keyShort = (e: any): void => {
    if (e.keyCode === 27) {
      this.close();
    }
  };

  render(): ReactElement {
    const { children, titleExtends } = this.props;
    const { isFullScreen } = this.state;
    return (
      <div className={classnames("full-screen-container", { isFullScreen })}>
        <div className="full-screen-modal">
          <Row
            style={{
              flexDirection: "row-reverse",
              justifyContent: "space-between",
            }}
          >
            <Col span={1} className="full-screen-icon">
              <span onClick={this.fullScreen} title="点击或按esc退出全屏">
                <SvgIcon
                  name={isFullScreen ? "exit-fullscreen" : "fullscreen"}
                />
              </span>
            </Col>
            {titleExtends}
          </Row>
          <div
            className={classnames("full-screen")}
            ref={this.ref}
            style={{ top: "30px" }}
          >
            {children}
          </div>
        </div>
        <div className="full-screen-placeholder" />
      </div>
    );
  }
}
