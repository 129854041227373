import React, { useEffect, useMemo, useState } from "react";
import { Empty, Tooltip } from "antd";
import _ from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import EchartsReactBase from "Components/EchartsReactBase";
import { getSharkText } from "Utils/i18nGlobal";

interface RadarChartsProps {
  height?: number;
  data: any;
}

interface RadarObj {
  indicator: RadarIndicator[];
  radarValue: number[];
}

interface RadarIndicator {
  name: string;
  max: number;
}

const nameMap: Record<string, string> = {
  lf_lvl: getSharkText("menu_load_factor"),
  progress_lvl: getSharkText("config_page_guest_speed"),
  market_lvl: getSharkText("config_page_market_trend"),
  compete_lvl: getSharkText("config_page_competitive_advantage"),
  ischange: getSharkText("config_page_last_batch_change_price"),
};

const changeConst = (v: number) => {
  switch (v) {
    case 0:
      return getSharkText("config_page_original_price");
    case 1:
      return getSharkText("config_page_raise_price");
    case 2:
      return getSharkText("config_page_reduce_price");
    default:
      return "--";
  }
};
const RadarCharts = (props: RadarChartsProps) => {
  const { height = 150, data } = props;
  const [radarObj, setRadarObj] = useState<RadarObj | null>(null);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const indicatorArr: RadarIndicator[] = [];
      const valueArr: number[] = [];
      Object.keys(data).forEach((item: string) => {
        if (["ischange", "islowest"].includes(item)) {
          return;
        }
        const tmp = {
          name: nameMap[item],
          min: -2,
          max: 2,
        };
        indicatorArr.push(tmp);
        valueArr.push(_.round(data[item], 1));
      });
      setRadarObj({
        indicator: indicatorArr,
        radarValue: valueArr,
      });
    } else {
      setRadarObj(null);
    }
  }, [data]);

  const info = useMemo(() => {
    return (
      <div style={{ width: 400 }}>
        <div>
          {getSharkText("config_page_display_scores_between_minus2_and_2")}
          <span style={{ color: "#ff9966" }}>
            {getSharkText("config_page_orange_line")}
          </span>
          {getSharkText("config_page_zero_scale_line")}
        </div>
        <div>{getSharkText("config_page_different_dimensions_include")}</div>
        <div>
          {getSharkText("config_page_competitive_advantages_and_disadvantages")}
        </div>
        <div>{getSharkText("config_page_market_trends")}</div>
        <div>
          {getSharkText("menu_load_factor")}：
          {getSharkText("config_page_load_factor_and_lead_time")}
        </div>
        <div>
          {getSharkText("config_page_guest_speed")}：
          {getSharkText("config_page_load_moment_progress")}、
          {getSharkText("config_page_load_increment_acceleration")}、
          {getSharkText("config_page_target_load_increment")}
        </div>
      </div>
    );
  }, []);
  if (!radarObj) {
    return <Empty />;
  }

  const onChartClick = () => {
    console.log("chart click ");
  };

  const onEvents = {
    click: onChartClick,
  };

  const option = {
    tooltip: {
      formatter: (v: any) => {
        console.log(v);
        const html: string[] = radarObj.indicator.map((r, i) => {
          return `<div>
            <span>${r.name}：</span>
            <span>${v.value[i] ?? "--"}</span>
          </div>`;
        });
        html.push(
          `<div style="color: #1890ff">${getSharkText(
            "config_page_is_already_the_lowest_freight"
          )}：${
            data.islowest
              ? "<span style='color: #1890ff'>" +
                getSharkText("config_page_yes") +
                "</span>"
              : "<span style='color: #ff9966'>" +
                getSharkText("config_page_no") +
                "</span>"
          }</div><div style="color: #1890ff">${getSharkText(
            "config_page_last_batch_recommendation"
          )}：${changeConst(data.ischange)}</div>`
        );
        return html.join("");
      },
    },
    radar: {
      name: {
        textStyle: {
          fontSize: 13,
        },
      },
      center: ["50%", "45%"],
      nameGap: 5,
      splitNumber: 4,
      radius: 40,
      indicator: radarObj.indicator,
    },
    series: [
      {
        name: getSharkText("menu_dynamic_price_airlines"),
        type: "radar",
        z: 2,
        data: [
          {
            value: radarObj.radarValue,
            name: getSharkText("menu_dynamic_price_airlines"),
            symbol: "none",
            label: {
              show: true,
              position: "outside",
              color: "#fff",
              backgroundColor: "#177DFE",
              padding: [2, 4],
              borderRadius: 3,
            },
            areaStyle: {
              color: "#fae28b",
            },
          },
        ],
        emphasis: {
          areaStyle: {
            opacity: 1,
          },
          lineStyle: {
            width: 0,
          },
        },
      },
      {
        name: "",
        type: "radar",
        z: 1,
        data: [
          {
            value: [0, 0, 0, 0],
            name: "",
            symbol: "none",
            lineStyle: {
              width: 1,
              color: "#ff9966",
            },
            tooltip: {
              show: false,
            },
          },
        ],
      },
    ],
  };

  return (
    <div>
      <EchartsReactBase
        className="radar-charts"
        option={option}
        notMerge={true}
        lazyUpdate={true}
        style={{ height }}
        theme="default"
        onEvents={onEvents}
      />
      <div style={{ position: "absolute", right: 5, top: 5 }}>
        <Tooltip
          title={info}
          placement="bottomLeft"
          overlayStyle={{ maxWidth: "500px" }}
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    </div>
  );
};

export default RadarCharts;
