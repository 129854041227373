// #region imports
import React, {
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  IDownloadHeaderPro,
  IFlightManageQueryExt,
  SystemType,
} from "Interface";
import { isSame, ubt, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _, { cloneDeep, isEmpty, uniq } from "lodash";
import {
  Checkbox,
  Empty,
  Modal,
  Popover,
  Radio,
  Result,
  Spin,
  TableProps,
  message,
} from "antd";
import { DATE_FORMAT, EMPTY_ARRAY, WEEK_OPTIONS } from "Constants";
import Refetch from "Components/Refetch";
import CustomTable, { CustomTableHandler } from "./CustomTable";
import moment from "moment";

import "./index.scss";
import { getServer } from "Service/server";
import { IFlightRecord } from "Page/AI/FlightManage/FlightManageInterface";
import PriceTrend from "../PriceTrend/PriceTrend";
import {
  CLIENT_APP_ID,
  CLIENT_IP,
  COMPETITIVE_ALL_HELP,
  COMPETITIVE_EMPHASIS_HELP,
  TRACE_ID,
} from "Page/AI/FlightManage/FlightManageCommon";
import { InfoCircleOutlined } from "@ant-design/icons";
import AlertModal from "../AlertModal";
import { LS } from "Constants/localStorage";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import usePageContext from "../../FlightManageContext";
import RefreshCMDPrice from "./RefreshCMDPrice";
import { useQueryString } from "../../../../../Utils/query/useQueryString";
import useRefFunc from "Utils/useRefFunc";
import { getColumns } from "./columns";
import {
  AutoAdjustFlightStateType,
  GetAutoAdjustStatesRequestType,
  GetAutoAdjustStatesResponseType,
} from "./GetAutoAdjustStates";
import CheckButtons from "Components/CheckButtons/CheckButtons";
import { ListToolBarTabs } from "@ant-design/pro-table/es/components/ListToolBar";
import SvgIcon from "Components/SvgIcon";
import useAvFlp from "./useAvFlp";
import useRealtime from "./useRealtime";
import ubtUtils from "Utils/ubtUtils";
import CabinAdjustmentParameter, {
  CabinAdjustmentParameterRef,
} from "./CabinAdjustmentParameter";
import { LFRecord } from "./interface";
import {
  CHART_TABLE_CODE,
  MODULE_CODE,
  QUERY_URL,
} from "Page/AI/DynamicPrice/fetchCode";
export type RecordWithAuto = IFlightRecord & {
  autoAdjust?: AutoAdjustFlightStateType;
};

// #endregion

interface ITabItem {
  name: string;
  label: ReactNode;
  key: string;
  disabled?: boolean;
  value: string | "all";
}

const LOCAL_STORAGE_KEY_COMPETE_TYPE = "flightManage.competeType";
/** 航班量大于此值默认开启极速模式 */
const FAST_RENDER_THRESHOLD = 200;
/** 航班量大于此值强制开始极速模式 */
export const FORCE_FAST_RENDER_THRESHOLD = 500;

const defaultTabs = [
  {
    name: getSharkText("config_page_all"),
    // label: getSharkText("config_page_all"),
    label: (
      <span style={{ paddingRight: 10, borderRight: "1px solid #eee" }}>
        <SvgIcon name="zhiding" style={{ position: "relative", top: 4 }} />
      </span>
    ),
    key: "all",
    value: "all",
  },
];

const airlinesLocalStorage = (route: string): string[] | undefined => {
  const tmp = localStorage.getItem(LS.flightManageCustomAirlines);
  if (tmp) {
    return JSON.parse(tmp)[route];
  } else {
    return undefined;
  }
};

interface FastRenderProps {
  useFastRender: boolean;
  setUseFastRender: (v: boolean) => void;
  userFastRenderSelect: boolean | undefined;
  setUserFastRenderSelected: (v: boolean) => void;
  setDisableFastRenderChange: (v: boolean) => void;
}

interface AirlineTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  queryExt: IFlightManageQueryExt | null;
  isDemo: boolean;
  curRecord: IFlightRecord | undefined;
  setCurRecord: (record: IFlightRecord | undefined) => void;
  // 为0表示不合作航司
  airlineMode: number;
  roOpen: boolean;
  setRoOpen: (v: boolean) => void;
  fastRenderProps: FastRenderProps;
}
export interface PCalendarHandler {
  setRecord: (record: IFlightRecord) => void;
}
/**
 * 模块逻辑说明
 * 1. 每次查询条件变动后, 重新查询表格航班及竞飞航班数据
 * 2. 数据返回后, 进入数据筛选展示逻辑, 计算每条数据的状态, 自营, 最新调价等, 放入 hasStatusData
 * 3. 从hasStatusData中筛选需要展示加载的数据, 放入showData
 * 4. 每次筛选条件更换后, 设置showIndex, 重新查询数据
 */
const PCalendar = (props: AirlineTableProps, ref: Ref<PCalendarHandler>) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    queryExt,
    isDemo,
    curRecord,
    setCurRecord,
    airlineMode,
    roOpen,
    setRoOpen,
    fastRenderProps,
  } = props;
  const [context, actions] = usePageContext();
  const cabinAdjustmentParameterRef = useRef<CabinAdjustmentParameterRef>(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number | null>(null);
  const [dataSource, setDataSource] = useState<LFRecord>();
  const { fetchCMDPriceList } = context;
  const { setFetchCMDPriceList } = actions;
  const competeTypeStored = useMemo(() => {
    const localType = localStorage.getItem(LOCAL_STORAGE_KEY_COMPETE_TYPE);
    if (localType != null) {
      return Number(localType);
    }
  }, []);
  /** 0=所有, 1=重点, 2=自定义, 3=无 */
  const [competitive, setCompetitive] = useState<number>(
    competeTypeStored ?? 1
  );
  const [competitiveFlightNo, setCompetitiveFlightNo] = useState<string[]>([]);
  const [globalState] = useGlobal();
  const { systemType, airlinesQueryCondition, userInfo } = globalState;
  const { airlines } = airlinesQueryCondition;
  const [flightType, setFlightType] = useState<number[]>([]);
  const priceTrendModalRef = useRef<any>();
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [alertRecord, setAlertRecord] = useState<IFlightRecord>();
  const route = queryExt?.filter?.route;
  const [adviseId] = useQueryString("adviseId", "");
  const [avFlpShow, setAvFlpShow] = useState<boolean>(false);
  const [paramsRecord, setParamsRecord] = useState<IFlightRecord>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [queryStatus, setQueryStatus] = useState<boolean>(false);
  /** 自动调舱配置 */
  const [autoAdjustFlights, setAutoAdjustFlights] = useState<
    AutoAdjustFlightStateType[]
  >([]);
  const [tableFilters, setTableFilters] = useState<
    Record<string, FilterValue | null>
  >({});
  const [tableSorter, setTableSorter] = useState<SorterResult<IFlightRecord>>(
    {}
  );

  const [dataLazyCp, setDataLazyCp] = useState<IFlightRecord[]>(EMPTY_ARRAY);

  /** 因为ProTable的headerTitle中直接使用tabs会有闪跳问题, 为了解决此问题, 需要计算table的width来固定tabs的大小, 防止闪跳 */
  const tableContainerRef = useRef<HTMLDivElement>(null);

  /** 需要保持表格下方有一定空余空间显示第二屏内容, 需要手动计算表格高度 */
  const [tableContentHeight, setTableContentHeight] = useState<number>(300);

  useEffect(() => {
    const resizeTableHeight = () => {
      const windowHeight = window.innerHeight;
      const rect = tableContainerRef.current?.getBoundingClientRect();
      if (rect) {
        // setTableContentHeight(height);
        setTableContentHeight(windowHeight > 668 ? 668 : windowHeight);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      resizeTableHeight();
    });

    const addListen = () => {
      setTimeout(() => {
        if (tableContainerRef.current) {
          const containerEle = tableContainerRef.current;
          resizeObserver.observe(containerEle);
          return () => {
            resizeObserver.unobserve(containerEle);
          };
        } else {
          addListen();
        }
      }, 10);
    };
    addListen();
  });

  // #region 数据请求
  const genExt = useCallback(
    (query?: IFlightManageQueryExt) => {
      if (!query && !queryExt) return undefined;
      const q = query || (queryExt as IFlightManageQueryExt);
      return {
        filter: { ...q.filter, competitive, competitiveFlightNo },
        type: 0,
      };
    },
    [competitive, competitiveFlightNo, queryExt]
  );

  const [, fetchAutoAdjust] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: "flight_manage_airlines",
      chartTableCode: "none",
    },
    debugId: "GetAutoAdjustStates",
    lazey: true,
    onSuccess: (res) => {
      if (res.ResponseStatus.Ack === "Success") {
        const soaResponse: GetAutoAdjustStatesResponseType = JSON.parse(
          res.responseBody
        );
        if (soaResponse.ResponseStatus?.Ack === "Success") {
          setAutoAdjustFlights(soaResponse.flights || []);
        }
      }
    },
  });

  const refetchAutoAdjust = useRefFunc((records: IFlightRecord[]) => {
    const req: GetAutoAdjustStatesRequestType = {
      head: {
        clientAppID: CLIENT_APP_ID,
        clientIP: CLIENT_IP,
      },
      flights: records.map((record) => ({
        flightno: record.flightNo,
        takeoffdate: record.takeoffdate,
        seg: record.route,
      })),
      userGroup: userInfo.unitList ? userInfo.unitList[0].id.toString() : "-1",
    };
    const autoAdjustExt = {
      serviceCode: "22299",
      operation: "GetAutoAdjustStates",
      requestBody: JSON.stringify(req),
    };
    fetchAutoAdjust({ ext: autoAdjustExt });
  });

  const [{ data: dataLazy, isLoading: isLoadingLazy, error }, doFetchLazy] =
    useFetch<IFlightRecord[]>({
      server: getServer(systemType),
      url: queryUrl,
      defaultValue: [],
      head: {
        moduleCode,
        chartTableCode,
      },
      query: airlinesQueryCondition,
      lazey: true,
      debugId: "fetch-data-lazy",
      onSuccess: (res) => {
        console.log("flightmanage.doFetchLazy.success");
        const dataCP = (res.data || []).map((record, i, arr) => {
          /** 是否是当前起飞日的第一条数据 */
          const isTakeoffFirstRow =
            i === 0 || record.takeoffdate !== arr[i - 1].takeoffdate;
          return {
            ...record,
            isTakeoffFirstRow,
          };
        });
        setDataLazyCp(dataCP);
        // 如果查询了新的航班列表, 那么清空当前正在刷新价格的row
        setFetchCMDPriceList([]);
        refetchAutoAdjust(res.data);
        const {
          useFastRender,
          userFastRenderSelect,
          setUseFastRender,
          setDisableFastRenderChange,
        } = fastRenderProps;
        if (res.data.length > FORCE_FAST_RENDER_THRESHOLD) {
          setDisableFastRenderChange(true);
          setUseFastRender(true);
        } else {
          setDisableFastRenderChange(false);
          if (
            res.data.length > FAST_RENDER_THRESHOLD &&
            userFastRenderSelect === undefined
          ) {
            setUseFastRender(true);
          } else if (
            userFastRenderSelect !== undefined &&
            useFastRender !== userFastRenderSelect
          ) {
            setUseFastRender(userFastRenderSelect);
          }
        }
      },
    });

  const refetch = useRefFunc((query?: IFlightManageQueryExt) => {
    console.log("flightmanage.refetchLazy");
    // 必须创建一个新对象, 否则再点击刷新时, doFetchLazy不会触发
    const ext = genExt(query);
    console.log("ext is ", ext);
    doFetchLazy({
      ext: {
        ...ext,
        refresh,
      },
    });
    setRefresh(false);
  });

  const { avData, flpData, loadingStart, loadingEnd } = useAvFlp({
    records: dataLazy,
    avFlpShow,
    airline: airlines,
    userInfo,
    route: queryExt?.filter?.route,
  });

  const { realLoadedData, realLoadingIds, realLoadedIds } = useRealtime({
    records: dataLazy,
    adviseId,
  });

  const firstLoadedTimer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    clearTimeout(firstLoadedTimer.current);
    firstLoadedTimer.current = setTimeout(() => {
      refetch();
    }, 300);
    return () => {
      clearTimeout(firstLoadedTimer.current);
    };
  }, [airlinesQueryCondition, queryExt, genExt, refetch]);

  /**
   * 切换自营选项时, 发送埋点
   */
  useEffect(() => {
    if (flightType.length) {
      ubt(TRACE_ID.getName("selfSupport"), flightType);
    }
  }, [flightType]);

  const chooseFirst = useRefFunc((source: IFlightRecord[]) => {
    setCurRecord(undefined);
    const first = source.find((r) => r.flightType === 0);
    if (first) {
      setCurRecord(first);
    }
  });

  useEffect(() => {
    if (route) {
      setCompetitiveFlightNo(airlinesLocalStorage(route) || []);
    } else {
      setCompetitiveFlightNo([]);
    }
  }, [route]);

  /** 班期过滤 */
  const [schedule, setSchedule] = useState<number[]>(
    Array.from({ length: 7 }, (v, i) => i)
  );

  const filteredData = useMemo(() => {
    if (!dataLazyCp.length) return [];
    return dataLazyCp.reduce((total, item) => {
      let flag = true;
      if (flightType.length && !flightType.includes(item.flightType)) {
        flag = false;
      }
      const itemSchedule = moment(item.takeoffdate).weekday();
      if (!schedule.includes(itemSchedule)) {
        flag = false;
      }
      const filterKeys = Object.keys(tableFilters);
      if (filterKeys.length) {
        const tmpFlag = filterKeys.every((k) => {
          const key = k as keyof IFlightRecord;
          const filterSelected = tableFilters[key];
          if (filterSelected === null) {
            return true;
          }
          return filterSelected.includes(item[key] as string);
        });
        if (!tmpFlag) {
          flag = false;
        }
      }
      if (flag) {
        total.push(item);
      }
      return total;
    }, [] as IFlightRecord[]);
  }, [dataLazyCp, flightType, tableFilters, schedule]);

  const mergeRealtimeData = useMemo(() => {
    // 合并实时数据
    if (realLoadedData.length) {
      // 取出所有的选择概率数据, 并移除航班原有的选择概率
      const rateList: any[] = [];
      const realCP = cloneDeep(realLoadedData);
      realCP.forEach((r) => {
        const sr = filteredData.find((s) => s.id === r.id);
        if (r.competitorsInfo?.length) {
          r.competitorsInfo.forEach((c) => {
            rateList.push({
              takeoffDate: sr ? sr.takeoffdate : r.takeoffdate,
              flightNo: c.flightNo,
              prob: c.prob,
            });
          });
          r.competitorsInfo = [];
        }
      });
      const filterDataCP = cloneDeep(filteredData);
      filterDataCP.forEach((s, i) => {
        const real = realCP.find((r: IFlightRecord) => r.id === s.id);
        if (real) {
          filterDataCP[i] = { ...filterDataCP[i], ...real };
        }
        const rate = rateList.find(
          (r) => r.takeoffDate === s.takeoffdate && r.flightNo === s.flightNo
        );
        if (rate) {
          filterDataCP[i].competitorsInfo = [
            { flightNo: rate.flightNo, prob: rate.prob },
          ];
        }
      });
      if (!isSame(filteredData, filterDataCP)) {
        return filterDataCP;
      }
      return filteredData;
    }
    return filteredData;
  }, [filteredData, realLoadedData]);

  const mergeAvData = useMemo(() => {
    if (!mergeRealtimeData.length) {
      return EMPTY_ARRAY as IFlightRecord[];
    }
    if (isEmpty(avData) && isEmpty(flpData) && !loadingStart && !loadingEnd) {
      return mergeRealtimeData;
    }
    const rst: IFlightRecord[] = mergeRealtimeData.map((record) => {
      /** 判断是否正在加载 */
      if (loadingStart && loadingEnd) {
        if (
          moment(loadingStart).isSameOrBefore(moment(record.takeoffdate)) &&
          moment(loadingEnd).isSameOrAfter(moment(record.takeoffdate))
        ) {
          return {
            ...record,
            avLoading: true,
            avData: undefined,
            flpData: undefined,
          };
        }
      }
      return {
        ...record,
        avLoading: false,
        avData: avData.find(
          (av) =>
            av.flightNo === record.flightNo &&
            av.seg === record.route &&
            av.takeoffDate === record.takeoffdate
        ),
        flpData: flpData.find(
          (flp) =>
            flp.flightNo === record.flightNo &&
            flp.seg === record.route &&
            flp.takeoffDate === record.takeoffdate
        ),
      };
    });
    return rst;
  }, [avData, flpData, loadingEnd, loadingStart, mergeRealtimeData]);

  const hasAutoAdjust = useMemo(
    () => autoAdjustFlights.some((a) => a.isWithinRange),
    [autoAdjustFlights]
  );
  const mergeAutoAdjustData = useMemo(() => {
    if (!hasAutoAdjust) {
      return mergeAvData;
    }
    const withAutoAdjust: RecordWithAuto[] = mergeAvData.map((row) => {
      const auto = autoAdjustFlights.find(
        (a) =>
          a.flightno === row.flightNo &&
          a.takeoffdate === row.takeoffdate &&
          a.seg === row.route
      );
      if (auto) {
        return { ...row, autoAdjust: auto };
      } else {
        return row;
      }
    });
    return withAutoAdjust;
  }, [autoAdjustFlights, hasAutoAdjust, mergeAvData]);

  const mergedData = useMemo(() => mergeAutoAdjustData, [mergeAutoAdjustData]);

  // #region 生成日期Tabs
  /** 日期tabs */
  const tabs = useMemo(() => {
    const sortedSource = _.sortBy(filteredData, (d) => new Date(d.takeoffdate));
    const takeoffTimes = _.sortedUniqBy(sortedSource, (d) =>
      new Date(d.takeoffdate.substring(0, 10)).getTime()
    );
    const takeoffDays = takeoffTimes.map((d: any) =>
      moment(d.takeoffdate).format(DATE_FORMAT)
    );
    const today = moment().format(DATE_FORMAT);
    const rst: ITabItem[] = _.cloneDeep(defaultTabs);
    takeoffDays.forEach((v) => {
      const vd = moment(v).format(DATE_FORMAT);
      rst.push({
        name: today === vd ? getSharkText("config_page_today") : `${vd}`,
        label: today === vd ? getSharkText("config_page_today") : `${vd}`,
        key: vd,
        value: vd,
      });
    });
    return rst;
  }, [filteredData]);
  // #endregion

  //
  /**
   * 2. 数据筛选展示逻辑, 首先为数据添加状态, 自营,  从头开始找第一条需要实时查询的数据, 找到后获取同日竞飞航班的最后一条的索引,
   * 如果索引大于20, 则停止查找并记录showIndex, 展示已经找到的数据
   * 如果索引小于20, 则继续查找直到索引大于20位置, 假如查找了三条自营数据都没有大于20, 则返回
   */

  useEffect(() => {
    if (!dataLazy) {
      return;
    }
    setCurRecord(undefined);
    setRoOpen(false);
    // chooseFirst(data);
  }, [dataLazy, setCurRecord, chooseFirst, setRoOpen]);

  /**
   * 因为获取调仓数据和获取表格实时数据是同步的, 导致调仓数据初始化时, 会判定实时建议价为空.
   * 因此, 在实时数据变动时, 需要检测如果选中项没有建议价, 就比对选中项是否与filterData是否一致
   * 如果不一致就更新选中项数据
   */
  useEffect(() => {
    if (curRecord?.suggestPrice === null) {
      const matchItem = mergeRealtimeData.find((f) => f.id === curRecord.id);
      if (matchItem && matchItem.suggestPrice) {
        setCurRecord(matchItem);
      }
    }
  }, [curRecord?.id, curRecord?.suggestPrice, mergeRealtimeData, setCurRecord]);

  useImperativeHandle(ref, () => ({
    setRecord: (record: IFlightRecord) => {
      setRecord(record);
    },
  }));

  const showAlert = useRefFunc((r: IFlightRecord) => {
    setAlertRecord(r);
    setAlertVisible(true);
    ubt(TRACE_ID.getName("alert"), r);
  });

  const changeRoOpen = useRefFunc((open: boolean, r: IFlightRecord) => {
    setRoOpen(open);
    if (open && r.id !== curRecord?.id) {
      setCurRecord(r);
    }
  });

  const [, doFetch] = useFetch<LFRecord>({
    server: getServer(SystemType.airlines),
    url: QUERY_URL.query,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE[SystemType.airlines],
      chartTableCode: CHART_TABLE_CODE[SystemType.airlines].table,
    },
    lazey: true,
    ext: {
      data: {
        takeofftime: curRecord?.takeofftime,
        flightNo: curRecord?.flightNo,
        route: curRecord?.route,
      },
    },
    onSuccess: (res) => {
      if (res.flag) {
        setDataSource(res.data);
        console.log("data: ", res.data);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (queryStatus) {
          message.success("查询成功");
          setQueryStatus(false);
        }
      } else {
        message.error("请求失败");
      }
    },
  });

  const onAdjustmentParameterClick = useRefFunc((record: IFlightRecord) => {
    cabinAdjustmentParameterRef.current?.open(true);
    setParamsRecord(record);
    setQueryStatus(false);
    doFetch({
      ext: {
        data: {
          takeofftime: record?.takeoffdate,
          flightNo: record?.flightNo,
          route: record?.route,
        },
      },
    });
  });

  const isMergeCell = !fastRenderProps.useFastRender && !avFlpShow;

  const [sortAndRowSpanData, setSortAndRowSpanData] =
    useState<IFlightRecord[]>(EMPTY_ARRAY);

  const setRecord = useRefFunc((record: IFlightRecord) => {
    console.log("sorted data set");
    const oldRecord = sortAndRowSpanData.find(
      (r: IFlightRecord) => r.id === record.id
    );
    if (!oldRecord) {
      return;
    }
    // oldRecord.feedback = record.feedback;
    // oldRecord.cause = record.cause;
    const newRecord = { ...cloneDeep(oldRecord), ...record };
    const index = sortAndRowSpanData.findIndex(
      (d: IFlightRecord) => d.id === newRecord.id
    );
    if (index > -1) {
      const newShowData = cloneDeep(sortAndRowSpanData);
      newShowData[index] = newRecord;
      setSortAndRowSpanData(newShowData);
    }
  });

  // #region 列定义
  const columns: IDownloadHeaderPro[] = useMemo(() => {
    const allFlights = uniq(dataLazyCp.map((d) => d.flightNo));
    const allRoutes = uniq(dataLazyCp.map((d) => d.route));
    const allDepartTimes = uniq(dataLazyCp.map((d) => d.takeofftime)).sort();
    return getColumns({
      airlineMode,
      airlines,
      id: curRecord?.id,
      isDemo,
      loadedRealTimeIds: realLoadedIds,
      realLoadingIds,
      setRecord,
      showAlert,
      hasAutoAdjust,
      roOpen,
      changeRoOpen,
      allFlights,
      allRoutes,
      allDepartTimes,
      onAdjustmentParameterClick,
      hoveredRowIndex,
    });
  }, [
    dataLazyCp,
    airlineMode,
    airlines,
    curRecord?.id,
    isDemo,
    realLoadedIds,
    realLoadingIds,
    setRecord,
    showAlert,
    hasAutoAdjust,
    roOpen,
    changeRoOpen,
    onAdjustmentParameterClick,
    hoveredRowIndex,
  ]);
  // #endregion

  useEffect(() => {
    console.log("sorted data updated");
    let rst: IFlightRecord[] = [];
    const tmpShowData = mergedData;
    if (tableSorter && Object.keys(tableSorter).length) {
      const sortColumn = tableSorter.column as IDownloadHeaderPro;
      if (sortColumn && sortColumn?.sortFn) {
        if (sortColumn?.sortFn) {
          const sortFn = sortColumn.sortFn;
          tmpShowData.sort((a, b) => {
            const asc = sortFn(a, b, tableSorter.order);
            return asc;
          });
        } else {
          ubtUtils.error(
            `FlightManage: sortColumn.sortFn is not a function: ${sortColumn.dataIndex}`
          );
        }
      }
    }
    if (!isMergeCell) {
      rst = tmpShowData;
    } else {
      // 计算日期合并
      let tmpRowSpan = 1;
      let curParentIdx = 0;
      // const showDataCP = midShowDataCp;
      const showDataCP: IFlightRecord[] = cloneDeep(tmpShowData);
      for (let idx = 1; idx < showDataCP.length; idx++) {
        if (showDataCP[idx].takeoffdate === showDataCP[idx - 1].takeoffdate) {
          tmpRowSpan++;
          showDataCP[idx].feRowSpan = 0;
          if (idx === showDataCP.length - 1) {
            showDataCP[curParentIdx].feRowSpan = tmpRowSpan;
          }
        } else {
          showDataCP[curParentIdx].feRowSpan = tmpRowSpan;
          tmpRowSpan = 1;
          curParentIdx = idx;
          // 最后一天如果只有自营数据, 需要在循环退出前赋值
          if (idx === showDataCP.length - 1) {
            showDataCP[curParentIdx].feRowSpan = tmpRowSpan;
          }
        }
      }
      rst = showDataCP;
    }
    setSortAndRowSpanData(rst);
  }, [isMergeCell, mergedData, tableSorter]);

  const openDetailModal = (record: IFlightRecord) => {
    setCurRecord(record);
    if (priceTrendModalRef && priceTrendModalRef.current) {
      priceTrendModalRef.current.show();
    }
    ubt(TRACE_ID.getName("selectedRow"), record);
  };

  const handleRefresh = () => {
    refetch();
  };

  const changeTable: TableProps<IFlightRecord>["onChange"] = (
    p,
    f,
    s: SorterResult<any> | any
  ) => {
    setTableFilters(f);
    setTableSorter(s);
  };

  const setOldRowClass = useRefFunc((record: IFlightRecord, index: number) => {
    let boldBorder = "";
    if (index !== 0 && (record.feRowSpan || record.isTakeoffFirstRow)) {
      boldBorder = "boldBorder";
    }
    const c =
      record.id === curRecord?.id
        ? "row-checked"
        : record.flightType === 0
        ? "row-owned"
        : "row-compare";
    const rowIndex = `record-${record.id}`;
    return `${boldBorder} ${c} ${rowIndex}`;
  });

  const scrollToDateFirstRow = useRefFunc((date?: string) => {
    let targetRowClass = `.record-${sortAndRowSpanData[0].id}`;
    if (date) {
      const firstDateRow = sortAndRowSpanData.find(
        (r) => moment(r.takeoffdate).format(DATE_FORMAT) === date
      );
      if (firstDateRow) {
        targetRowClass = `.record-${firstDateRow.id}`;
      }
    }
    const element: HTMLTableRowElement | null =
      document.querySelector(targetRowClass);
    const container = document.querySelector(
      "#flight-manage-main-table .ant-table-body"
    );
    if (element !== null && container) {
      container.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  });

  const curRecordSameFlightNoList = curRecord
    ? sortAndRowSpanData.filter((s) => s.flightNo === curRecord.flightNo)
    : EMPTY_ARRAY;

  const headerTitle = useMemo(() => {
    return (
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="flight-manage_compete_radio">
            {/* <span>{getSharkText("config_page_compete_flight")}: </span> */}
            <Radio.Group
              value={competitive}
              size="small"
              onChange={(e) => {
                setCompetitive(e.target.value);
                localStorage.setItem(
                  LOCAL_STORAGE_KEY_COMPETE_TYPE,
                  e.target.value
                );
              }}
            >
              <Radio.Button value={1}>
                <span style={{ marginRight: 5 }}>
                  {getSharkText("config_page_key_flight")}
                </span>
                <Popover content={COMPETITIVE_EMPHASIS_HELP}>
                  <InfoCircleOutlined />
                </Popover>
              </Radio.Button>
              <Radio.Button value={0}>
                <span style={{ marginRight: 5 }}>
                  {getSharkText("config_page_all_flights")}
                </span>
                <Popover content={COMPETITIVE_ALL_HELP}>
                  <InfoCircleOutlined />
                </Popover>
              </Radio.Button>
            </Radio.Group>
          </div>
          <div
            style={{
              flex: "0 0 auto",
              paddingRight: 20,
              display: "flex",
            }}
          >
            <div style={{ alignSelf: "center", marginRight: 10 }}>
              <span style={{ marginRight: 10 }}>
                {getSharkText("config_page_schedule")}:{" "}
              </span>
              <CheckButtons
                value={schedule}
                onChange={(v) => setSchedule(v as number[])}
                options={WEEK_OPTIONS}
              />
            </div>
            <div>
              <Checkbox.Group
                value={flightType}
                onChange={(e) => setFlightType(e as number[])}
              >
                <Checkbox value={0}>
                  {getSharkText("config_page_self_operated")}
                </Checkbox>
              </Checkbox.Group>
              <Checkbox
                checked={avFlpShow}
                onChange={(e) => setAvFlpShow(e.target.checked)}
              >
                {getSharkText("config_page_show_seat_available")}
              </Checkbox>
            </div>
          </div>
        </div>
      </div>
    );
  }, [avFlpShow, competitive, flightType, schedule]);

  const [activeKey, setActiveKey] = useState<string>("unknown");
  const tableRef = useRef<CustomTableHandler>(null);

  const onChangeTab = useRefFunc((key: string) => {
    if (fastRenderProps.useFastRender) {
      tableRef.current?.scrollToDate(key);
    } else {
      scrollToDateFirstRow(key === "all" ? undefined : key);
    }
    setActiveKey(key === "all" ? "unknown" : key);
  });

  const toolbarTabsConfig = useMemo(() => {
    const config: ListToolBarTabs = {
      activeKey,
      onChange: onChangeTab,
      items: tabs,
    };
    return config;
  }, [activeKey, onChangeTab, tabs]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }
  if (dataLazy === null) {
    return <Result title="请重新查询" />;
  }
  if (!isLoadingLazy && _.isEmpty(dataLazy)) {
    return <Empty />;
  }

  return (
    <div id="analysisTable">
      <Spin spinning={isLoadingLazy}>
        <div ref={tableContainerRef}>
          <CustomTable
            ref={tableRef}
            curRecord={curRecord}
            setCurRecord={setCurRecord}
            showAvFlp={avFlpShow}
            dataSource={sortAndRowSpanData}
            columns={columns}
            onRecordSelectedChange={openDetailModal}
            otherProps={{
              id: "flight-manage-main-table",
              headerTitle,
              pagination: false,
              rowClassName: setOldRowClass,
              scroll: { y: tableContentHeight },
              onChange: changeTable,
              // bordered,
            }}
            toolbarTabsConfig={toolbarTabsConfig}
            onReload={handleRefresh}
            roOpen={roOpen}
            isFastRender={fastRenderProps.useFastRender}
            setHoveredRowIndex={setHoveredRowIndex}
          />
        </div>
      </Spin>
      {/* {curRecord ? ( */}
      <PriceTrend
        ref={priceTrendModalRef}
        record={curRecord}
        allRecords={curRecordSameFlightNoList}
        setRecord={setRecord}
        setSelectedRecord={setCurRecord}
        isDemo={isDemo}
      ></PriceTrend>
      {/* ) : undefined} */}
      {alertRecord && (
        <Modal
          width="auto"
          open={alertVisible}
          onCancel={() => {
            setAlertVisible(false);
          }}
        >
          <AlertModal record={alertRecord} />
        </Modal>
      )}
      {fetchCMDPriceList.map((f) => (
        <RefreshCMDPrice
          key={f.cacheKey}
          row={f.row}
          cacheKey={f.cacheKey}
          updateRow={setRecord}
        />
      ))}
      <CabinAdjustmentParameter
        takeoffdate={paramsRecord?.takeoffdate as string}
        flightNo={paramsRecord?.flightNo as string}
        route={paramsRecord?.route as string}
        doFetch={doFetch}
        dataSource={dataSource}
        setRefresh={setRefresh}
        setQueryStatus={setQueryStatus}
        ref={cabinAdjustmentParameterRef}
      />
    </div>
  );
};

const PriceCalendar = React.forwardRef(PCalendar);
PriceCalendar.displayName = "PriceCalendar";
export default PriceCalendar;
