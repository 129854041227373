import { EMPTY_ARRAY } from "Constants";
import { IKeyLabelOption } from "Interface";
import { cloneDeep } from "lodash";
import React, { Key, ReactElement, ReactNode } from "react";
import useMergedState from "Utils/useMergedState";
import useRefFunc from "Utils/useRefFunc";
import "./CheckButtons.scss";

export interface ICheckButtonsProps {
  value?: Key[];
  onChange?: (v: Key[]) => void;
  options: IKeyLabelOption[];
  renderItem?: (v: IKeyLabelOption) => ReactNode;
}

/**
 * 按钮式的checkBox
 */
const CheckButtons = (props: ICheckButtonsProps): ReactElement => {
  const { value, onChange, options, renderItem } = props;
  const [innerValue, setInnerValue] = useMergedState(EMPTY_ARRAY, {
    value,
    onChange,
  });

  const handleClick = useRefFunc((option: IKeyLabelOption) => {
    let newValues;
    if (innerValue?.includes(option.value)) {
      newValues = innerValue.filter((a) => a !== option.value);
    } else {
      newValues = cloneDeep(innerValue || []);
      newValues.push(option.value);
    }
    setInnerValue(newValues);
  });

  return (
    <span className="check-buttons">
      {options.map((option) =>
        renderItem ? (
          renderItem(option)
        ) : (
          <span
            className={innerValue?.includes(option.value) ? "checked" : ""}
            key={option.value}
            onClick={() => handleClick(option)}
          >
            {option.label}
          </span>
        )
      )}
    </span>
  );
};
export default CheckButtons;
