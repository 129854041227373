import { IDownloadHeader, IValueLabelOption, SystemType } from "Interface";
import { cloneDeep, round } from "lodash";
import { getServer } from "Service/server";
import { U, XLSX } from "Utils";
import { getDownloadColumnValue } from "Utils/downloadXLSX";

const dataTrans = (rowToColumn: any[], resNames: string[]) => {
  const cp = cloneDeep(rowToColumn);
  cp.forEach((row) => {
    let valueSum = 0;
    let marketValueSum = 0;
    // 计算总量
    resNames.forEach((name) => {
      valueSum += row[`${name}value`] || 0;
      marketValueSum += row[`${name}marketValue`] || 0;
    });
    row.valueSum = valueSum;
    row.marketValueSum = marketValueSum;
    // 计算占比
    resNames.forEach((name) => {
      row[`${name}valuePer`] =
        valueSum !== 0 ? row[`${name}value`] / valueSum : null;
      row[`${name}marketValuePer`] =
        marketValueSum !== 0
          ? row[`${name}marketValue`] / marketValueSum
          : null;
      row[`${name}price`] = round(row[`${name}price`]);
      row[`${name}marketPrice`] = round(row[`${name}marketPrice`]);
    });
  });
  return cp;
};

export const downloadFetch = async (
  url: string,
  body: any,
  systemType: SystemType,
  sheetConfig: any
): Promise<{ sheet: any; config: any }> => {
  const result = await fetch(
    `${getServer(systemType)}${url}?debugId=${sheetConfig.tagCode}`,
    {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  );
  const { data } = await result.json();
  if (!data) {
    const emptySheet = XLSX.utils.aoa_to_sheet([]);
    return { sheet: emptySheet, config: sheetConfig };
  }
  // 获取服务端返回的所有tagName枚举值, 用来计算总量和总量占比
  const config = sheetConfig.config;
  const resNames = config.tagNames.map((t: IValueLabelOption) => t.value);
  const rowToColumn = U.rowToColumn(data, "name", "tagCode", [
    "value",
    "price",
    "marketValue",
    "marketPrice",
    "compareValue",
    "comparePrice",
  ]);
  const row = dataTrans(rowToColumn, resNames);
  const sheetData1: any = [];
  const sheetDataFn = (record: any, i: number) => {
    const obj1: any = {};
    config.columns.forEach((c: IDownloadHeader) => {
      const title = c.dataIndex;
      obj1[title] = getDownloadColumnValue(c, record, i);
    });
    return obj1;
  };
  console.log(
    "name: ",
    sheetConfig.tagName,
    " rowData: ",
    row,
    " columns:",
    config.columns
  );
  row.forEach((record: any, i: number) => {
    sheetData1.push(sheetDataFn(record, i));
  });
  const sheet1 = XLSX.utils.aoa_to_sheet(config.data);
  sheet1["!merges"] = config.mergeCells;
  // 首行居中
  for (const i in sheet1) {
    if (!Object.prototype.hasOwnProperty.call(sheet1, i)) {
      continue;
    }
    const num = U.getStringFirstNumber(i);
    if (num === 1) {
      sheet1[i].s = {
        alignment: {
          vertical: "center",
          horizontal: "center",
          // any truthy value here
          wrapText: "1",
        },
      };
    }
  }

  XLSX.utils.sheet_add_json(sheet1, sheetData1, {
    origin: "A3",
    skipHeader: true,
  });
  return { sheet: sheet1, config: sheetConfig };
};
