import ValueNum from "Components/ValueNum";
import React from "react";
import { IDownloadHeader, SystemType } from "Interface";
import {
  getProportionPercentageVal,
  getProportionVal,
  showRawNum,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { Popover } from "antd";
import { legendHelp } from "./AirlineCommon";
import { InfoCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";

export const columns = (
  panelType: 0 | 1,
  systemType: SystemType,
  ext: Partial<TransferPolicyBarlineRequestType>
): IDownloadHeader[] => {
  const systemTypeName =
    systemType === SystemType.airlines
      ? getSharkText("system_type_Airline")
      : getSharkText("area.airport.title");
  return [
    {
      title: `${
        ext?.axisType === 0
          ? getSharkText("config_page_search_time")
          : getSharkText("config_page_takeoff_time")
      }`,
      dataIndex: "takeoffdate",
      render: (val: string) => moment(val).format(DATE_FORMAT),
    },
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dport",
      sorter: (a: any, b: any) => a.dportName.localeCompare(b.dportName),
      render: (val: number, record: any) =>
        `${record.dportName}(${record.dport})`,
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "aport",
      sorter: (a: any, b: any) => a.aportName.localCompare(b.aportName),
      render: (val: number, record: any) =>
        `${record.aportName}(${record.aport})`,
    },
    {
      title: getSharkText("transfer.transfer_point.name"),
      dataIndex: "tport",
      sorter: (a: any, b: any) => a.tportName.localeCompare(b.tportName),
      render: (val: number, record: any) =>
        `${record.tportName}(${record.tport})`,
    },
    {
      title:
        panelType === 0 ? (
          <>
            {getSharkText("config_page_search_pv")}
            {/* @ts-ignore */}
            <Popover content={legendHelp[systemType][panelType]["搜索PV"]}>
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Popover>
          </>
        ) : (
          <Popover
            content={legendHelp[systemType][panelType]["整体中转政策数"]}
          >
            <span>{getSharkText("config_page_overall_transfer_policy")}</span>
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        ),
      downloadTitle: panelType === 0 ? "搜索PV" : "整体中转政策数",
      dataIndex: "value",
      sorter: (a: any, b: any) => a.value - b.value,
      render: (val: number) => <ValueNum value={val} type="num" />,
      downloadFormatter: (val: number) => showRawNum(val, "num"),
    },
    {
      title:
        panelType === 0 ? (
          <>
            {getSharkText("config_page_display_pv")}
            <Popover content={legendHelp[systemType][panelType]["展示PV"]}>
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Popover>
          </>
        ) : (
          <>
            {`${systemTypeName}${getSharkText(
              "config_page_transfer_policy_count"
            )}`}
            <Popover
              content={
                (legendHelp[systemType][panelType] as any)[
                  `${systemTypeName}${getSharkText(
                    "config_page_transfer_policy_count"
                  )}`
                ]
              }
            >
              <InfoCircleOutlined style={{ marginLeft: 5 }} />
            </Popover>
          </>
        ),
      downloadTitle:
        panelType === 0
          ? getSharkText("config_page_display_pv")
          : `${systemTypeName}{getSharkText("config_page_transfer_policy_count")}`,
      dataIndex: "compareValue",
      sorter: (a: any, b: any) => a.compareValue - b.compareValue,
      render: (val: number) => <ValueNum value={val} type="num" />,
      downloadFormatter: (val: number) => showRawNum(val, "num"),
    },
    {
      title: (
        <>
          {getSharkText("config_page_display_rate")}
          {/* @ts-ignore */}
          <Popover content={legendHelp[systemType][panelType]["展示率"]}>
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </>
      ),
      downloadTitle: getSharkText("config_page_display_rate"),
      dataIndex: "fe_proportion",
      sorter: (a: any, b: any) =>
        getProportionVal(a.compareValue, a.value) -
        getProportionVal(b.compareValue, b.value),
      render: (val: number, r: any) => (
        <ValueNum
          value={getProportionPercentageVal(r.compareValue, r.value)}
          type="percentage"
        />
      ),
      downloadFormatter: (val: number, r: any) =>
        showRawNum(
          getProportionPercentageVal(r.compareValue, r.value),
          "percentage"
        ),
    },
  ];
};
