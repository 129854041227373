import React from "react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "abortcontroller-polyfill/dist/polyfill-patch-fetch";
import moment from "moment-timezone";
import "./public-path";
import "style/official/resetAntdGlobal.scss";
import "./index.scss";
import "style/theme.base.css";
import "./style/ant.css";
import "./style/theme.antd.scss";
import { createRoot } from "react-dom/client";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import { ConfigProvider } from "antd";

console.log("start index.tsx");

// 设置使用北京时间
const localUtcOffset = moment().utcOffset();
const adjustOffset = 480 - localUtcOffset;
if (adjustOffset !== 0) {
  moment.tz.setDefault("Asia/Shanghai");
}
const container = document.getElementById("root");
// createRoot(container!) if you use TypeScript
const root = createRoot(container as HTMLElement);
root.render(
  <React.StrictMode>
    <StyleProvider
      hashPriority="high"
      transformers={[legacyLogicalPropertiesTransformer]}
    >
      {/* <ConfigProvider prefixCls="dark"> */}
      <App />
      {/* </ConfigProvider> */}
    </StyleProvider>
  </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
