import { Questionnaire } from "@ctrip/flt-bi-flightai-base";
import { Button, message, Modal, Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { DATE_FORMAT } from "Constants";
import { UnitList, UserList } from "Interface";
import Page from "Layout/Page";
import moment from "moment";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import useGlobalState from "Store";
import { getIsDemo, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import { getColumns } from "./columns";
import Editor from "./Editor";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { PollData, UnitUserTree } from "./IPoll";

const defaultNewPoll: PollData = {
  feSortId: -1,
  pollId: null,
  questionnaireId: null,
  questionnaireName: null,
  feUnit: [],
  feUsers: [],
  userIdList: [],
  startTime: moment().format(DATE_FORMAT),
  endTime: moment().format(DATE_FORMAT),
  url: null,
};

export interface ISalonManageProps {
  example?: string;
}
/**
 * 问卷后台管理页面
 */
const IPollManage = (): ReactElement => {
  const [editorOpen, setEditorOpen] = useState<boolean>(false);
  const [regYear] = useState<number>(moment().year());
  const [globalState] = useGlobalState();
  const location = useLocation();
  const isDemo = getIsDemo(location.pathname, globalState);
  const [editingRow, setEditingRow] = useState<PollData>(defaultNewPoll);
  const editorRef = useRef<any>();
  const [data, setData] = useState<Questionnaire[]>([]);

  const [{ isLoading }, doFetch] = useFetch<Questionnaire[]>({
    url: QUERY_URL.table,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE,
    },
    query: null,
    lazey: true,
    onSuccess: (res) => {
      setData(res.questionnaireList);
    },
  });

  const refreshList = useRefFunc(() => {
    doFetch({ ext: {} });
  });

  const [, doEdit] = useFetch({
    url: QUERY_URL.edit,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE,
    },
    query: null,
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_operation_success"));
      setEditorOpen(false);
      refreshList();
    },
    onError: () => {
      message.error(getSharkText("config_page_operation_failed"));
    },
  });

  const [, doDel] = useFetch({
    url: QUERY_URL.delete,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE,
    },
    query: null,
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_delete_success"));
      refreshList();
    },
    onError: () => {
      message.error(getSharkText("config_page_operation_failed"));
    },
  });

  // #region 获取用户和组数据
  const [{ res: unitRes }] = useFetch({
    url: "findUnitList",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      unitName: "",
    },
  });

  const unitList: UnitList[] = useMemo(() => {
    return unitRes?.unitList || [];
  }, [unitRes]);

  const [{ res: userRes }] = useFetch({
    url: "findUserList",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: { filter: {} },
  });

  const userList: UserList[] = useMemo(() => {
    return userRes?.userList || [];
  }, [userRes]);

  const unitUserTree: UnitUserTree[] = useMemo(() => {
    if (!unitList.length || !userList.length) {
      return [];
    }
    const rootTree: UnitUserTree[] = unitList.map((u) => {
      return {
        id: u.unit.id,
        key: `${u.unit.id}`,
        code: u.unit.unitCode,
        title: u.unit.unitName,
        children: [],
      };
    });
    const unitUser = rootTree.map((r) => {
      const tmpUsers = userList.filter((u) => u.user.groupId === r.id);
      tmpUsers.forEach((t) => {
        r.children.push({
          id: t.user.id,
          key: `${r.key}-${t.user.id}`,
          code: "",
          title: t.user.name,
          children: [],
        });
      });
      return r;
    });
    unitUser.sort((a, b) => (a.id < b.id ? -1 : 1));
    return unitUser;
  }, [unitList, userList]);
  // #endregion

  const dataSource: PollData[] = useMemo(() => {
    return data.map((d, i) => {
      const feUsers = userList
        .filter((u) => d.userIdList?.includes(u.user.id))
        .map((u) => u.user);
      const feUnit = unitList
        .filter((u) => feUsers.find((f) => f.groupId === u.unit.id))
        .map((f) => f.unit);
      return {
        ...d,
        feSortId: i + 1,
        feUnit,
        feUsers,
      } as PollData;
    });
  }, [data, unitList, userList]);

  useEffect(() => {
    doFetch({
      ext: {
        regYear,
      },
    });
  }, [doFetch, regYear]);

  const addRow = useRefFunc(() => {
    setEditingRow(defaultNewPoll);
    setEditorOpen(true);
  });

  const editRow = useRefFunc((row: PollData) => {
    setEditingRow(row);
    setEditorOpen(true);
  });

  const delRow = useRefFunc((row: PollData) => {
    Modal.confirm({
      title: getSharkText("config_page_delete_questionnaire"),
      content: getSharkText("config_page_confirm_delete"),
      onOk: () => {
        doDel({
          ext: {
            questionnaireId: row.questionnaireId,
          },
        });
      },
    });
  });

  const columns = useMemo(() => {
    const allColumns = getColumns(editRow, delRow);
    if (isDemo) {
      return allColumns.filter((d) => d.dataIndex !== "actions");
    } else {
      return allColumns;
    }
  }, [delRow, editRow, isDemo]);

  const handleSubmit = useRefFunc(() => {
    if (!editorRef?.current?.save) {
      console.log("mount editor ");
      return;
    }
    const val = editorRef.current.save();
    if (!val) {
      return;
    }
    doEdit({
      ext: {
        questionnaire: val,
      },
    });
  });

  const headExtends = useMemo(() => {
    return (
      <Button type="primary" onClick={addRow}>
        {getSharkText("key.add.button")}
      </Button>
    );
  }, [addRow]);

  return (
    <Page needToolBar={false} defaultDateMode="none">
      <Spin spinning={isLoading}>
        <div className="content-white" style={{ marginTop: 0 }}>
          <CustomTableV2<any>
            columns={columns}
            dataSource={dataSource}
            subTitle={getSharkText(
              "config_page_survey_questionnaire_management_2"
            )}
            downloadName={false}
            headExtends={headExtends}
            moduleCode={MODULE_CODE}
            chartTableCode={CHART_TABLE_CODE}
          />
        </div>
      </Spin>
      <Modal
        width={800}
        open={editorOpen}
        onOk={handleSubmit}
        onCancel={() => setEditorOpen(false)}
        destroyOnClose
      >
        <Editor
          value={editingRow}
          ref={editorRef}
          unitUserTree={unitUserTree}
        />
      </Modal>
    </Page>
  );
};
export default IPollManage;
