import { DPMonitorFilter, Row } from "@ctrip/flt-bi-flightai-airlines";
import { Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import Refetch from "Components/Refetch";
import { ChartFormatterFuncs, EMPTY_ARRAY } from "Constants";
import {
  ChartDataFormatter,
  IDownloadHeader,
  ISeries,
  SystemType,
} from "Interface";
import { groupBy, max, uniq } from "lodash";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { rowToColumn, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { downloadExcel } from "Utils/downloadXLSX";
import { DataRow2ListMapMS } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { ChartType } from "../common";
import moment from "moment";
import EchartsReactBase from "Components/EchartsReactBase";

export interface IChartProps {
  filter: DPMonitorFilter;
  type: string;
  baseSeries: ISeries[];
  extraSeries?: any;
  tableColumns?: IDownloadHeader[];
  chartType?: ChartType;
  moduleCode: string;
  chartTableCode: string;
}

export interface ChartHandle {
  download: (name: string) => void;
}

export const color = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
  "#2ec7c9",
  "#b6a2de",
  "#5ab1ef",
  "#ffb980",
  "#d87a80",
  "#8d98b3",
  "#e5cf0d",
  "#97b552",
  "#95706d",
  "#dc69aa",
  "#07a2a4",
  "#9a7fd1",
  "#588dd5",
  "#f5994e",
  "#c05050",
  "#59678c",
  "#c9ab00",
  "#7eb00a",
  "#6f5553",
  "#c14089",
  "#c12e34",
  "#e6b600",
  "#0098d9",
  "#2b821d",
  "#005eaa",
  "#339ca8",
  "#cda819",
  "#32a487",
];

/**
 *  Component description
 */
const Chart = forwardRef(
  (props: IChartProps, ref: Ref<ChartHandle>): ReactElement => {
    const {
      filter,
      type,
      baseSeries,
      tableColumns = EMPTY_ARRAY,
      chartType = ChartType.Line,
      extraSeries,
      moduleCode,
      chartTableCode,
    } = props;
    const [globalState] = useGlobalState();
    const [dataSource, setDataSource] = useState<
      Array<Record<string, unknown>>
    >([]);
    const [{ isLoading, error }, doFetch] = useFetch<Row[]>({
      server: getServer(SystemType.airlines),
      url: "queryDPMonitorData",
      head: {
        moduleCode: "dynamic_price_airlines",
        chartTableCode: "non",
      },
      query: globalState.airlinesQueryCondition,
      ext: { filter, panelKey: type },
      onSuccess: (res) => {
        const rows = res.rows;
        const headers = res.headers;
        const source = DataRow2ListMapMS(rows, headers);
        setDataSource(source);
      },
      onError: () => {
        setDataSource(EMPTY_ARRAY);
      },
      lazey: true,
    });

    const refetch = useRefFunc(() => {
      setDataSource(EMPTY_ARRAY);
      doFetch({
        ext: { filter, panelKey: type },
      });
    });

    useEffect(() => {
      if (!filter) {
        return;
      }
      if (!filter.monitoringRange) {
        return;
      }
      refetch();
    }, [filter, refetch]);

    useImperativeHandle(ref, () => ({
      download: (name) => {
        downloadExcel(tableColumns, dataSource, name);
      },
    }));

    const groupedData = useMemo(() => {
      // 对比日期or对比航线航班
      if (
        (filter.monitoringCompareRange &&
          filter.monitoringCompareRange[0] &&
          filter.monitoringCompareRange[1]) ||
        filter.isCompareRoute ||
        filter.isCompareFlight
      ) {
        return rowToColumn(dataSource, "xAxis", "group");
      }
      return dataSource;
    }, [
      dataSource,
      filter.isCompareFlight,
      filter.isCompareRoute,
      filter.monitoringCompareRange,
    ]);

    const series = useMemo(() => {
      const groups = uniq(dataSource.map((d) => d.group)).filter(
        (g) => g !== null
      ) as string[];
      if (
        !(
          (filter.monitoringCompareRange &&
            filter.monitoringCompareRange[0] &&
            filter.monitoringCompareRange[1]) ||
          filter.isCompareRoute ||
          filter.isCompareFlight
        ) ||
        !groups.length
      ) {
        return baseSeries;
      }
      if (groups.length) {
        return groups.reduce((total, g) => {
          const newSeriesArr = baseSeries.map((b) => {
            return {
              ...b,
              name: `${g ? g + "-" : ""}${b.name}`,
              stack: b.stack ? g + b.stack : null,
              encode: {
                ...b.encode,
                y: `${g}${b.encode.y}`,
              },
            };
          });
          total = total.concat(newSeriesArr);
          return total;
        }, [] as ISeries[]);
      }
      return baseSeries;
    }, [
      baseSeries,
      dataSource,
      filter.isCompareFlight,
      filter.isCompareRoute,
      filter.monitoringCompareRange,
    ]);

    const yAxis = useMemo(() => {
      const yAxisMaxIndex = max(series.map((s) => s.yAxisIndex) || 0) || 0;
      const tmpYAxis: any[] = Array.from(
        { length: yAxisMaxIndex + 1 },
        () => ({})
      );
      const groupedSeries = groupBy(series, (s) => s.yAxisIndex || 0);
      Object.values(groupedSeries).forEach((ss) => {
        const formatters = uniq(
          ss.map((s) => s.formatterType || ChartDataFormatter.Num)
        );
        if (formatters.length) {
          if (formatters.length >= 2) {
            console.warn(
              `yAxisIndex: ${ss[0].yAxisIndex} formatter has more than 1 types`
            );
          }
          const currentAxis = tmpYAxis[ss[0].yAxisIndex || 0];
          if (!currentAxis.axisLabel) {
            currentAxis.axisLabel = {};
          }
          currentAxis.axisLabel.formatter = ChartFormatterFuncs[formatters[0]];
        }
      });
      return tmpYAxis;
    }, [series]);

    const findSeriesConfig = useRefFunc((yAttribute: string) => {
      return series.find((s) => {
        return s.encode.y === yAttribute;
      });
    });

    const option = useMemo(() => {
      return {
        color,
        dataset: {
          source: groupedData,
        },
        legend: {
          type: "scroll",
        },
        tooltip: {
          trigger: "axis",
          enterable: true,
          appendToBody: true,
          formatter: (items: any[]) => {
            if (!items.length) {
              return null;
            }
            let x = "";
            const rows = items.map((item) => {
              const dot = `<span class='dot' style='background:${item.color}'></span>`;
              const name = item.seriesName;
              const dims = item.dimensionNames;
              const yIdx = item.encode.y[0];
              const yDim = dims[yIdx];
              const seriesConfig = findSeriesConfig(yDim);
              const yVal = item.value[yDim];
              let yShowVal = yVal;
              if (seriesConfig && seriesConfig.formatterType) {
                const formatterFn =
                  ChartFormatterFuncs[seriesConfig.formatterType];
                yShowVal = formatterFn(yVal);
              }
              const xIdx = item.encode.x[0];
              const xDim = dims[xIdx];
              const xVal = item.value[xDim];
              if (!x) {
                x = xVal;
              }
              return `${dot} ${name}: ${yShowVal}`;
            });
            return `${x}<br />` + rows.join("<br />");
          },
        },
        xAxis: [
          {
            type: "category",
            axisLabel: {
              formatter: (value: any) =>
                moment(value).format("YYYY-MM-DD HH:mm"),
            },
          },
        ],
        yAxis,
        series,
      };
    }, [findSeriesConfig, groupedData, series, yAxis]);

    const extraOption = useMemo(() => {
      if (!extraSeries) {
        return {};
      }
      if (extraSeries.type === "funnel") {
        const baseData = extraSeries.data.map((k: any) => ({
          name: k.name,
          value: dataSource
            .filter(
              (r) => r.group != null && !(r.group as string).includes("compare")
            )
            .map((r: { [x: string]: any }) => r[k.value])
            .reduce((a: any, c: any) => a + c, 0),
        }));
        const compareData = extraSeries.data.map((k: any) => ({
          name: k.name,
          value: dataSource
            .filter(
              (r) => r.group != null && (r.group as string).includes("compare")
            )
            .map((r: { [x: string]: any }) => r[k.value])
            .reduce((a: any, c: any) => a + c, 0),
        }));
        const isCompare =
          dataSource.filter(
            (r) => r.group != null && (r.group as string).includes("compare")
          ).length > 0;
        const genFunnelSeries = (name: any, data: any[], left = "0%") => {
          return {
            name,
            type: "funnel",
            width: isCompare ? "50%" : "100%",
            left,
            sort: "none",
            label: {
              position: "inside",
              formatter: (params: any) => {
                return (
                  params.name +
                  ": " +
                  params.value +
                  " " +
                  (params.dataIndex !== 0
                    ? (
                        (data[params.dataIndex].value /
                          data[params.dataIndex - 1].value) *
                        100
                      ).toFixed(2)
                    : "100") +
                  "%"
                );
              },
            },
            data,
          };
        };
        return {
          title: {
            text:
              getSharkText("config_page_PaAiMoCoCh_funnel_chart") +
              (isCompare ? " - " + getSharkText("key.compare.name") : ""),
            top: "bottom",
            left: "center",
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            type: "scroll",
          },
          series: [
            genFunnelSeries(extraSeries.name, baseData),
            isCompare
              ? genFunnelSeries(
                  "compare" + extraSeries.name,
                  compareData,
                  "50%"
                )
              : {},
          ],
        };
      } else {
        return {};
      }
    }, [extraSeries, dataSource]);

    if (error) {
      <Refetch refetch={refetch} />;
    }
    if (isLoading) {
      return <Spin spinning={isLoading} />;
    }
    return (
      <div style={{ display: "flex" }}>
        {chartType === ChartType.Line ? (
          <>
            <EchartsReactBase
              option={option}
              style={extraSeries ? { width: "70%" } : { width: "100%" }}
            />
            {extraSeries ? (
              <EchartsReactBase option={extraOption} style={{ width: "30%" }} />
            ) : null}
          </>
        ) : (
          <CustomTableV2
            tableProps={{ size: "small" }}
            columns={tableColumns}
            dataSource={dataSource.map((d) => ({
              ...d,
              key: `${d.xAxis}${d.group}`,
            }))}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        )}
      </div>
    );
  }
);
export default Chart;
