import React, { useEffect, useState } from "react";
import { Alert, Col, Result, Row } from "antd";
import useGlobal from "Store";
import Page from "Layout/Page";
import SearchAirport from "./Components/SearchAirport";
import CompareCard from "./Components/CompareCard";
import CompareTable from "./Components/CompareTable";
import CompareShare from "./Components/CompareShare";
import CompareRanking from "./Components/CompareRanking";
import CompareBarCharts from "./Components/CompareBarCharts";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { AirportCompareQueryExt } from "Interface";
import { DEFAULT_AREA } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

const AirportCompare: React.FC = () => {
  const [airportCompareQueryExt, setAirportCompareQueryExt] =
    useState<AirportCompareQueryExt | null>(null);
  const [curCardCode, setCurCardCode] = useState<string>("");
  const [hasCardData, setHasCardData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalState] = useGlobal();
  const { systemType, queryCondition } = globalState;
  const { departure, arrive, flightClass } = queryCondition;

  useEffect(() => {
    if (!departure && !arrive) {
      setAirportCompareQueryExt(null);
    }
  }, [departure, arrive]);

  useEffect(() => {
    // 修改flightClass清空page页面参数
    if (airportCompareQueryExt) {
      setLoading(true);
      setAirportCompareQueryExt({
        compareAirport: airportCompareQueryExt.compareAirport,
        area: DEFAULT_AREA,
      });
      setTimeout(() => setLoading(false));
    }
  }, [flightClass]);

  return (
    <Page defaultDateMode="lastMonth">
      <div className="content-white">
        <Alert
          message={getSharkText("config_page_compare_core_indicators")}
          type="info"
          banner
        />
        <SearchAirport
          queryUrl={QUERY_URL[systemType].compareList}
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={CHART_TABLE_CODE[systemType].compareList}
          setAirportCompareQueryExt={setAirportCompareQueryExt}
        />
      </div>
      {loading ? undefined : airportCompareQueryExt ? (
        <>
          <div className="content-white">
            <CompareCard
              queryUrl={QUERY_URL[systemType].cards}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].cards}
              curCardCode={curCardCode}
              setCurCardCode={setCurCardCode}
              setHasCardData={setHasCardData}
              airportCompareQueryExt={airportCompareQueryExt}
            />
            {hasCardData ? (
              <Row
                gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
                style={{ marginTop: 16 }}
              >
                <Col lg={7} md={12} xs={24}>
                  <CompareRanking
                    queryUrl={QUERY_URL[systemType].top10City}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].top10City}
                    cardCode={curCardCode}
                    airportCompareQueryExt={airportCompareQueryExt}
                  />
                </Col>
                <Col lg={17} md={12} xs={24}>
                  <CompareBarCharts
                    queryUrl={QUERY_URL[systemType].barCharts}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].barCharts}
                    cardCode={curCardCode}
                    airportCompareQueryExt={airportCompareQueryExt}
                  />
                </Col>
              </Row>
            ) : undefined}
          </div>
          <div className="content-white">
            <CompareTable
              queryUrl={QUERY_URL[systemType].table}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].table}
              airportCompareQueryExt={airportCompareQueryExt}
            />
          </div>
          <div className="content-white">
            <CompareShare
              queryUrl={QUERY_URL[systemType].share}
              moduleCode={MODULE_CODE[systemType]}
              airportCompareQueryExt={airportCompareQueryExt}
            />
          </div>
        </>
      ) : (
        <Result title={getSharkText("key.choose_airport_compare")} />
      )}
    </Page>
  );
};

export default AirportCompare;
