import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  InfoCircleOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Card,
  Radio,
  List,
  Button,
  Row,
  Spin,
  Empty,
  Popover,
} from "antd";
import useGlobal from "Store";
import { FlightArea } from "Interface";
import _ from "lodash";
import { durationFormatter, getComparedPercentage, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { SERVER } from "Constants";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import "./index.css";
import {
  ITransferAnalysisExt,
  PERCENT_VALUES,
} from "Page/AI/TransferPolicy/TransferPolicyInterface";
import RankSortBtn from "../../../../../Components/RankSortBtn";

const { Title, Text } = Typography;

const routeTabs = [
  {
    label: getSharkText("key.route.name"),
    value: 0,
    getRouteName: (item: any) => `${item.dportName} - ${item.aportName}`,
  },
  {
    label: getSharkText("key.departure.name"),
    value: 1,
    getRouteName: (item: any) => item.dportName,
  },
  {
    label: getSharkText("key.arrival.name"),
    value: 2,
    getRouteName: (item: any) => item.aportName,
  },
];

interface IProps {
  title?: string;
  tab: any;
  style?: React.CSSProperties;
  queryUrl: string;
  moduleCode: string;
  area: ITransferAnalysisExt;
  sortable?: boolean;
  selectItem?: (item: FlightArea | null) => void;
  setChartsType?: (type: number) => void;
  cardCode?: string;
  setHasCityData?: (has: boolean) => void;
  server?: string;
  chartTableCode: string;
}

interface City {
  cityCode: string;
  cityName: string;
  value: number;
}

interface Tab {
  title: string;
  titleTips?: string;
  comparable?: boolean;
  type?: number;
  compare?: number;
}

const TransferRanking: React.FC<IProps> = (props: IProps) => {
  const {
    server = SERVER,
    title = "",
    tab,
    style,
    queryUrl,
    moduleCode,
    area,
    sortable = false,
    selectItem,
    cardCode,
    setChartsType,
    setHasCityData,
    chartTableCode,
  } = props;
  const { query, filter } = area;
  const [currentTab, setCurrentTab] = useState<Tab | null>(null);
  const [sort, setSort] = useState(1);
  const [routeType, setRouteType] = useState<any>(routeTabs[0]);
  const [globalState] = useGlobal();
  const { queryCondition } = globalState;
  const [curCityIndex, setCurCityIndex] = useState<number | null>(null);
  const isDurationNum = cardCode === "transit_minutes";
  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;

  const queryWithExt = useMemo(
    () => ({
      ...queryCondition,
      area: query.area,
      // 当选择同比增幅时, 如果顶部选择去年, 则yoy为1, 前年yoy为3
      yoy: currentTab && currentTab.compare === 1 ? query.yoy : 0,
      wow: currentTab && currentTab.compare === 2 ? query.wow : 0,
    }),
    [currentTab, query.area, query.wow, query.yoy, queryCondition]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext: {
      filter: { ...filter },
      orderSort: 0,
      cardCode,
      orderByType: currentTab ? currentTab.compare : 0,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (currentTab) {
      doFetch({
        head: {
          moduleCode,
          chartTableCode,
        },
        query: queryWithExt,
        ext: {
          filter: { ...filter },
          orderSort: sort,
          cardCode,
          orderByType: currentTab.compare,
          type: routeType.value,
        },
      });
    }
  }, [
    cardCode,
    chartTableCode,
    currentTab,
    doFetch,
    filter,
    moduleCode,
    queryWithExt,
    routeType.value,
    sort,
  ]);

  useEffect(() => {
    setCurrentTab(tab[0]);
  }, [tab]);

  useEffect(() => {
    setCurCityIndex(null);
    if (selectItem) {
      selectItem(null);
    }
    if (setHasCityData) {
      setHasCityData(false);
    }
    refetch();
  }, [refetch, selectItem, setHasCityData]);

  useEffect(() => {
    if (data && setHasCityData) {
      setHasCityData(true);
    }
  }, [data, setHasCityData]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleModeChange = (e: any) => {
    setCurrentTab(e.target.value);
    if (setChartsType && selectItem) {
      selectItem({
        departArea: {
          areaType: 0,
          areaCode: "",
          areaName: "",
        },
        arriveArea: {
          areaType: 0,
          areaCode: "",
          areaName: "",
        },
      });
      setChartsType(e.target.value.type);
    }
  };

  const handleSort = () => {
    setSort(sort === 0 ? 1 : 0);
  };

  const handleChooseItem = (value: any, idx?: number) => {
    if (selectItem) {
      selectItem({
        departArea: {
          areaType: 4,
          areaCode: value.dcityCode,
          areaName: value.dcityName,
        },
        arriveArea: {
          areaType: 4,
          areaCode: value.acityCode,
          areaName: value.acityName,
        },
      });
      if (idx || idx === 0) {
        setCurCityIndex(idx);
      }
    }
  };

  const renderValue = (item: any) => {
    let computeValue = _.round(item.value);
    if (!currentTab) {
      return "";
    }
    if (currentTab.compare === 1) {
      computeValue = _.round(
        getComparedPercentage(item.value || 0, item.valueYoy || 0)
      );
    } else if (currentTab.compare === 2) {
      computeValue = _.round(
        getComparedPercentage(item.value || 0, item.valueCompare || 0)
      );
    }
    // if (currentTab.compare === 0) {
    if (isDurationNum) {
      return durationFormatter(computeValue * 60);
    } else if (isPercentVal) {
      return <ValueNum type="percentage" value={computeValue} />;
    } else {
      return <ValueNum type="num" value={computeValue} />;
    }
  };

  const searchItem = (item: any, index: any) => {
    // const title = `${item.dportName} - ${item.aportName}`;
    const routeName = routeType.getRouteName(item);
    return (
      <List.Item
        className={`ranking-item optional ${
          index === curCityIndex ? "cur" : ""
        }`}
        onClick={() => handleChooseItem(item, index)}
      >
        <div className="ranking-badge">{index + 1}</div>
        <div className="ranking-item-content" title={routeName}>
          <Text className="ranking-item-content-span">{routeName}</Text>
        </div>
        <div className="ranking-item-value">
          <Text>{renderValue(item)}</Text>
        </div>
      </List.Item>
    );
  };

  const listItem: any = searchItem;

  const changeRouteType = (e: any) => {
    setRouteType(e.target.value);
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="ranking-card" style={style}>
        {title && <Title level={4}>{title}</Title>}
        <Row justify="space-between">
          <Radio.Group
            onChange={handleModeChange}
            value={currentTab}
            id="rankTab"
          >
            {tab.map((item: any, index: any) => (
              <Radio.Button value={item} key={index}>
                {item.title}
                {item.titleTips ? (
                  <Popover content={item.titleTips}>
                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                  </Popover>
                ) : undefined}
              </Radio.Button>
            ))}
          </Radio.Group>
          <Radio.Group
            value={routeType}
            onChange={changeRouteType}
            style={{ marginLeft: 10 }}
          >
            {routeTabs.map((item: any, index: number) => (
              <Radio value={item} key={index}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
          {sortable && (
            <RankSortBtn sort={Boolean(sort)} handleSortChange={handleSort} />
          )}
        </Row>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={data}
            style={{ margin: "5px 0 -10px" }}
            renderItem={(item: City, index) => listItem(item, index)}
          />
        )}
      </Card>
    </Spin>
  );
};

export default TransferRanking;
