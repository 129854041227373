// Created by xh_zhu on 2021-01-29

import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  durationFormatter,
  genLoadFloat,
  genSeriesByDimensions,
  getTS,
  isSame,
  listMap2dataSet,
  rowToColumn,
  showNum,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import moment from "moment";
import { DATE_FORMAT, DATE_MINUTE_FORMAT, EMPTY_ARRAY } from "Constants";
import { IRecordTrend, TView } from "../DynamicPriceInterface";
import { cloneDeep, isEmpty, isNumber, round } from "lodash";
import { ISeries } from "Interface";
import EChartsBase from "Components/EChartsBase";
import { calcD, fillData, IHistoryLoad, lfTypes } from "./TrendCommon";
import { getServer } from "Service/server";
import { QUERY_URL } from "../fetchCode";
import { useBatchEffect } from "Utils/useBatchEffect";
import {
  DynamicPriceHistoryLoad,
  DynamicPricePopupFilter,
} from "@ctrip/flt-bi-flightai-airlines";

// 数据请求接口, type从1开始, 对应航班,携程,预测
interface IType {
  name: string;
  isPercent: boolean;
}
interface ILoadTrendProps {
  record: IRecordTrend;
  compareDate?: string;
  compareFlightNo?: string;
  xStart?: number;
  xEnd?: number;
  setXStart: (v: number | undefined) => void;
  setXEnd: (v: number | undefined) => void;
  onChartInstanceChange: (v: any) => void;
  view: 0 | 1;
}

/**
 * 1. 请求数据时设置dataFilled为[],
 * 2. 请求数据后对携程和预测数据进行起飞小时重算,并且合并数据, 放入dataMerged
 * 3. 根据dataMerged计算最大最小值, 并且上报到parent组件
 * 4. 监听最大最小值, 并且填充空白维度数据,放入dataFilled
 * 5. 监听dataFilled并且更新option
 */
const LoadTrend = (props: ILoadTrendProps): ReactElement => {
  const {
    record,
    compareDate,
    compareFlightNo,
    xStart,
    xEnd,
    setXStart,
    setXEnd,
    onChartInstanceChange,
    view,
  } = props;
  const [globalState] = useGlobal();
  const { systemType, airlinesQueryCondition } = globalState;
  const [dataMerged, setDataMerged] = useState<IHistoryLoad[]>(EMPTY_ARRAY);
  const [dataFilled, setDataFilled] = useState<IHistoryLoad[]>(EMPTY_ARRAY);
  const [option, setOption] = useState<any>(undefined);
  const ref = useRef<any>();
  /**
   * 引用takeOffDate的变更
   */
  const tkRef = useRef("");
  tkRef.current = moment(record.feTkDate).format(DATE_FORMAT);
  const compareDiffDays = compareDate
    ? moment(compareDate).diff(moment(tkRef.current), "days")
    : 0;

  const types: IType[] = useMemo(
    () => [
      { name: "", isPercent: true },
      {
        name: `${record.flightNo}${getSharkText("key.loadfactor")}`,
        isPercent: true,
      },
      {
        name: getSharkText("config_page_flight_target_seat_rate"),
        isPercent: true,
      },
      {
        name: getSharkText("config_page_route_seat_rate"),
        isPercent: true,
      },
      {
        name: `${compareFlightNo}${getSharkText("key.loadfactor")}`,
        isPercent: true,
      },
    ],
    [compareFlightNo, record.flightNo]
  );

  const defaultSeries: ISeries[] = useMemo(
    () => [
      {
        name: `${types[1].name}`,
        type: "line",
        connectNulls: true,
        symbol: "diamond",
        symbolSize: 6,
        showAllSymbol: true,
        itemStyle: {
          color: "#01C5DB",
        },
        lineStyle: {
          color: "#01C5DB",
        },
        encode: {
          x: "d",
          y: "lf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[1].name}`,
        type: "line",
        connectNulls: true,
        symbol: "diamond",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#01C5DB",
        },
        itemStyle: {
          color: "#01C5DB",
        },
        encode: {
          x: "d",
          y: "lfCompare",
        },
      },
      {
        name: `${types[4].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "comLf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[4].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "comLfCompare",
        },
      },
      {
        name: `${types[2].name}`,
        type: "bar",
        connectNulls: true,
        symbol: "triangle",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          color: "#177DFE",
        },
        itemStyle: {
          color: "#177DFE",
        },
        encode: {
          x: "d",
          y: "predictLf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[2].name}`,
        type: "bar",
        connectNulls: true,
        symbol: "triangle",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "predictLfCompare",
        },
      },
      {
        name: `${types[3].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          color: "#E96B5B",
        },
        itemStyle: {
          color: "#E96B5B",
        },
        encode: {
          x: "d",
          y: "lineLf",
        },
      },
      {
        name: `${getSharkText("key.compare.name")}${types[3].name}`,
        type: "line",
        connectNulls: true,
        symbol: "roundRect",
        symbolSize: 6,
        showAllSymbol: true,
        lineStyle: {
          type: "dashed",
          color: "#E96B5B",
        },
        itemStyle: {
          color: "#E96B5B",
        },
        encode: {
          x: "d",
          y: "lineLfCompare",
        },
      },
    ],
    [types]
  );

  const filterList: DynamicPricePopupFilter[] = useMemo(() => {
    if (!tkRef.current) return EMPTY_ARRAY;
    const base: DynamicPricePopupFilter = {
      flightNo: record.flightNo,
      takeoffDate: moment(tkRef.current).format(DATE_FORMAT),
      route: record.route,
      type: 1,
    };
    const rst: DynamicPricePopupFilter[] = [base];
    if (compareDate) {
      const tmp = cloneDeep(base);
      tmp.takeoffDate = compareDate;
      rst.push(tmp);
    }
    if (compareFlightNo) {
      const tmp = cloneDeep(base);
      tmp.flightNo = compareFlightNo;
      tmp.type = 2;
      rst.push(tmp);
    }
    if (compareDate && compareFlightNo) {
      const tmp = cloneDeep(base);
      tmp.takeoffDate = compareDate;
      tmp.flightNo = compareFlightNo;
      tmp.type = 2;
      rst.push(tmp);
    }
    return rst;
  }, [record, compareDate, compareFlightNo]);

  useEffect(() => {
    if (ref) {
      try {
        onChartInstanceChange(ref.current.getEchartsInstance());
      } catch (e) {
        console.log(e);
      }
    }
  }, [ref, onChartInstanceChange]);

  const [{ data, isLoading }, doFetch] = useFetch<DynamicPriceHistoryLoad[]>({
    server: getServer(systemType),
    url: QUERY_URL.popupLf,
    defaultValue: [],
    head: {
      moduleCode: "dynamic_price_airlines",
      chartTableCode: "AL0801",
    },
    query: airlinesQueryCondition,
    ext: {
      filterList,
      view,
    },
  });

  const refetch = useCallback(() => {
    setDataMerged(EMPTY_ARRAY);
    setDataFilled(EMPTY_ARRAY);
    setXStart(undefined);
    setXEnd(undefined);
    if (filterList.length) {
      doFetch({
        query: airlinesQueryCondition,
        ext: { filterList, view },
      });
    }
  }, [airlinesQueryCondition, doFetch, filterList, setXEnd, setXStart, view]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const transData = (source: any[], viewType: TView) => {
    if (viewType === 0) {
      return rowToColumn(
        source,
        ["d", "takeoff"],
        "type",
        undefined,
        (rt: any, col) => {
          if (col === "lf") {
            return lfTypes[Number(rt) - 1];
          }
          if (rt === 2 && col === "day") {
            return "dayCom";
          }
          if (rt === 1) {
            return col;
          }
          return null;
        }
      );
    } else {
      return rowToColumn(source, ["d"], "type", undefined, (rt: any, col) => {
        return col === "lf" ? lfTypes[Number(rt) - 1] : null;
      });
    }
  };

  // 转换数据
  useEffect(() => {
    console.log("load data merging");
    if (isLoading) {
      return;
    }
    let dataSource: any = EMPTY_ARRAY;
    if (data && data.length) {
      console.log("data is :", data);
      data.forEach(
        (d: any) =>
          (d.d = calcD(d.diffTime, view === 0 ? tkRef.current : d.day, view))
      );

      // 第一步, 生成takeoff字段. 同时将精确到分钟的收集时间转换为小时
      data.forEach((r: any) => {
        r.takeoff = moment(r.day).format("yyyy-MM-DD");
        // 根据takeoff的值重算采集时间, 去除分钟的影响
        const collectMinutes = moment(r.collectDay);
        if (collectMinutes.minute() > 30) {
          collectMinutes.add(1, "hours");
        }
        collectMinutes.set("minute", 0);
        r.collectDay = getTS(collectMinutes.valueOf());
        // if (r.takeoff !== curDateStr) {
        //   r.diffTime += diffHours;
        // }
      });
      // 第二步, 过滤掉竞飞航线目标客座和竞飞航班目标客座
      const noCompare34 = data.filter((d) => {
        if (d.type === 3) {
          const nameArr = d.name?.split(" ") || [];
          const flightNo = nameArr.length >= 2 ? nameArr[1] : null;
          if (flightNo !== record.flightNo) {
            return false;
          }
        }
        if (d.type === 4) {
          const nameArr = d.name?.split(" ") || [];
          const flightNo = nameArr.length >= 2 ? nameArr[1] : null;
          if (flightNo !== record.flightNo) {
            return false;
          }
        }
        return true;
      });
      console.time();
      // 第三步, 依照type行转列, 转换后每个采集时间节点, 每个起飞日只有一行
      const rst = transData(noCompare34, view);
      dataSource = rst;
      console.timeEnd();
      console.log("first step:", rst);
      // 第四步 将对比起飞日行转列
      if (compareDate) {
        console.time();
        const curDateStr = tkRef.current.substr(0, 10);
        const diffHours = moment(curDateStr).diff(moment(compareDate), "hours");
        console.log("diffHours: ", diffHours);
        console.timeEnd();
        console.log("load second step:", rst);
        // 第三步, 转换对比的值
        console.time();
        const rst3 = rowToColumn(
          rst,
          ["d", "diffTime"],
          "takeoff",
          undefined,
          (rt: string, col: string) => {
            if (rt === curDateStr) {
              return col;
            } else {
              return col + "Compare";
            }
          }
        );
        console.timeEnd();
        console.log("third step:", rst3);
        dataSource = rst3;
      }
    }
    setDataMerged(dataSource);
    console.log("load data merged");
  }, [data, compareDate, setXStart, setXEnd, isLoading, view, record.flightNo]);

  // 计算最大最小X轴的值
  useEffect(() => {
    let max: number | undefined;
    let min: number | undefined;
    const setStartEnd = (item: IHistoryLoad) => {
      const dMoment = moment(item.d);
      if (!dMoment.isValid()) {
        return;
      }
      if (max === undefined || moment(max).isAfter(dMoment)) {
        max = moment(item.d).valueOf();
      }
      if (min === undefined || moment(min).isBefore(dMoment)) {
        min = moment(item.d).valueOf();
      }
    };
    if (dataMerged !== undefined) {
      dataMerged.forEach(setStartEnd);
      if (max !== undefined && min !== undefined) {
        setXStart(max);
        setXEnd(min);
      }
    }
    console.log("load max min end", max, min);
  }, [dataMerged, setXStart, setXEnd]);

  // 补齐坐标轴
  useBatchEffect(() => {
    const dataMergedCopy = cloneDeep(dataMerged);
    if (xStart !== undefined && xEnd !== undefined && dataMergedCopy.length) {
      console.log("load: fill data");
      console.time("load: fill");
      const rst = fillData(dataMergedCopy, xStart, xEnd, view);
      rst.sort((r1, r2) => (r1.d < r2.d ? -1 : 1));
      console.timeEnd("load: fill");
      setDataFilled(rst || EMPTY_ARRAY);
    }
  }, [dataMerged, xStart, xEnd]);

  useEffect(() => {
    if (ref && ref.current) {
      if (isLoading) {
        ref.current.showLoading();
      } else {
        ref.current.hideLoading();
      }
    }
  }, [isLoading, ref]);

  useEffect(() => {
    console.log(option);
    if (option && ref && ref.current) {
      ref.current.setOption(option, { notMerge: true });
    }
  }, [ref, option]);

  const xAxis = useRef({});
  /**
   * 根据不同的view切换不同的x轴配置
   */
  useEffect(() => {
    xAxis.current = {
      type: "category",
      name: getSharkText("config_page_days_from_takeoff"),
      nameLocation: "start",
      nameTextStyle: {
        padding: [25, 35, 0, 0],
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        overflow: "truncate",
        interval:
          view === 0
            ? (index: number, value: string) => {
                const hours = moment(value).hour();
                // 仅显示整天的数据
                const tmp = hours % 24;
                if (tmp !== 0) {
                  return false;
                }
                return true;
              }
            : undefined,
        formatter: (val: number) => {
          if (view === 0) {
            const diff = moment(tkRef.current).diff(moment(val), "seconds");
            // const hours = round(val);
            return durationFormatter(diff, "h");
          } else {
            return moment(val).format(DATE_FORMAT);
          }
        },
      },
    } as any;
  }, [view]);

  /**
   * 生成echarts的配置
   */
  useEffect(() => {
    if (dataFilled.length) {
      const dataSource2 =
        Array.isArray(dataFilled) && listMap2dataSet(dataFilled);
      const dimensions = Array.isArray(dataSource2) ? dataSource2[0] : [];
      // 起始轴, 百分比展示
      let sliderStart = 0;
      if (view === 0 && xStart !== undefined && xEnd !== undefined) {
        let days15 = xStart + 15 * 24 * 3600 * 1000;
        if (days15 > xEnd) {
          days15 = xEnd;
        }
        // sliderStart =
        //   100 - ((defaultSliderStart - xEnd) / (xStart - xEnd)) * 100;
        sliderStart = 100 - ((xEnd - days15) / (xEnd - xStart)) * 100;
      }
      console.log("sliderStart", sliderStart);
      const defaultShowLegend = ["lf", "comLf", "lfCompare", "comLfCompare"];

      const legendSelected = compareDate
        ? genSeriesByDimensions(dimensions, defaultSeries).reduce(
            (total: any, item: ISeries) => {
              total[item.name] = defaultShowLegend.includes(item.encode.y);
              return total;
            },
            {}
          )
        : undefined;

      const opt = {
        color: [
          "#01C5DB",
          "#FC9B4F",
          "#177DFE",
          "#E96B5B",
          "#6950a1",
          "#769149",
        ],
        title: {
          text: getSharkText("key.loadfactor"),
          // textVerticalAlign: 'middle'
          top: "center",
          left: "2%",
        },
        dataset: {
          dimensions,
          source: dataSource2,
        },
        legend: {
          itemGap: 20,
          itemWidth: 16,
          itemHeight: 8,
          top: 0,
          selected: legendSelected,
        },
        dataZoom: [
          {
            type: "inside",
            start: sliderStart,
            filterMode: "weakFilter",
          },
          {
            type: "slider",
            bottom: 0,
            start: sliderStart,
            filterMode: "weakFilter",
            labelFormatter: (val: string, valStr: string) => {
              if (view === 0) {
                const seconds = moment(tkRef.current).diff(
                  moment(valStr),
                  "seconds"
                );
                return durationFormatter(seconds, "d");
              } else {
                return moment(valStr).format(DATE_FORMAT);
              }
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: (params: any) => {
            if (view === 0) {
              // console.log('load', params);
              const dayIndex = params[0].dimensionNames.indexOf("d");
              const curDayMo = moment(params[0].data[dayIndex]);
              const currentDay = curDayMo.format(DATE_MINUTE_FORMAT);
              const compareDay = compareDiffDays
                ? curDayMo
                    .add(compareDiffDays, "days")
                    .format(DATE_MINUTE_FORMAT)
                : null;
              // 250时当前起飞航班文字最长宽度, 11px是线条颜色点的宽度
              const day = `<span style="width: 250px; margin-left:11px; display:inline-block">${currentDay}</span>${
                compareDay
                  ? `<span style="width: 250px; display:inline-block">${compareDay}</span>`
                  : ""
              }`;
              let dom = "";
              types.forEach((type: IType, tyIdx: number) => {
                if (!type.name) {
                  return;
                }
                const baseSeriesName = `${type.name}`;
                const compareBaseSeriesName = `${getSharkText(
                  "key.compare.name"
                )}${baseSeriesName}`;
                const series = params.filter(
                  (item: any) =>
                    item.seriesName === baseSeriesName ||
                    item.seriesName === compareBaseSeriesName
                );
                if (
                  series.length > 0 &&
                  series.some((s: any) => s.value[s.encode.y[0]] !== null)
                ) {
                  const dot = `<span class='dot' style='background:${series[0].color}'></span>`;
                  const currentSeries = series.find(
                    (item: any) => item.seriesName === baseSeriesName
                  );
                  // 起飞时间
                  const tkTimeBase = tyIdx === 1 ? "day" : "dayCom";
                  const currentVal =
                    currentSeries &&
                    currentSeries.value[currentSeries.encode.y[0]];
                  const currentTk = currentSeries
                    ? moment(
                        currentSeries.value[
                          currentSeries.dimensionNames.indexOf(tkTimeBase)
                        ]
                      )
                    : undefined;
                  const currentMinute =
                    currentTk && currentTk.isValid()
                      ? currentTk.format("HH:mm")
                      : "--";
                  const currentTkStr = [1, 4].includes(tyIdx)
                    ? ` | ${currentMinute}`
                    : "";
                  const currentDom = `${baseSeriesName} | ${
                    isNumber(currentVal)
                      ? type.isPercent
                        ? tyIdx === 4
                          ? genLoadFloat(currentVal * 100, "percentage", 100)
                          : showNum(currentVal * 100, "percentage")
                        : showNum(currentVal)
                      : "--"
                  }${currentTkStr}`;
                  let compareDom;
                  if (compareDate) {
                    const compareSeries = series.find(
                      (item: any) => item.seriesName === compareBaseSeriesName
                    );
                    const compareVal =
                      compareSeries &&
                      compareSeries.value[compareSeries.encode.y[0]];
                    const compareTkTime = compareSeries
                      ? moment(
                          compareSeries.value[
                            compareSeries.dimensionNames.indexOf(
                              `${tkTimeBase}Compare`
                            )
                          ]
                        )
                      : null;
                    const compareTKMinute =
                      compareTkTime && compareTkTime.isValid()
                        ? compareTkTime.format("HH:mm")
                        : "--";
                    const compareTkStr = [1, 4].includes(tyIdx)
                      ? ` | ${compareTKMinute}`
                      : "";
                    compareDom = `${compareBaseSeriesName} | ${
                      isNumber(compareVal)
                        ? type.isPercent
                          ? tyIdx === 4
                            ? genLoadFloat(compareVal * 100, "percentage", 100)
                            : showNum(compareVal * 100, "percentage")
                          : showNum(compareVal)
                        : "--"
                    }${compareTkStr}`;
                  }
                  const val = `<span style="width: 250px; display:inline-block">${currentDom}</span>${
                    compareDom ? `<span>${compareDom}</span>` : ""
                  }`;
                  dom = dom + "<br />" + dot + val;
                }
              });
              if (isEmpty(dom)) {
                return null;
              }
              dom = day + dom;
              return dom;
            } else if (view === 1) {
              const day = moment(params[0].data[params[0].encode.x[0]]).format(
                DATE_FORMAT
              );
              let dom = "";
              params.forEach((item: any) => {
                const dot = `<span class='dot' style='background:${item.color}'></span>`;
                const valIdx = item.encode.y[0];
                const itemValue = item.value[valIdx];
                const value = showNum(itemValue * 100, "percentage");
                const val = `${item.seriesName}: ${value}`;
                dom = dom + "<br />" + dot + val;
              });
              dom = day + dom;
              return dom;
            }
          },
        },
        xAxis: xAxis.current,
        yAxis: [
          {
            type: "value",
            splitNumber: 4,
            min: (v: any) => v.min * 0.9,
            axisLabel: {
              formatter: (value: number) => `${round(value * 100, 2)} %`,
            },
          },
          {
            show: true,
            splitNumber: 4,
            splitLine: {
              show: false,
            },
          },
        ],
        series: genSeriesByDimensions(dimensions, defaultSeries),
      };
      if (!isSame(opt, option)) {
        setOption(opt);
        console.log("load updated option", option);
        // ref && ref.current && ref.current.setOption(opt);
        // console.log(opt, ref);
      } else {
        console.log("option same");
      }
    } else if (!dataMerged || dataMerged.length === 0) {
      setOption({
        graphic: {
          id: "noData",
          type: "text",
          $action: "merge",
          z: 100,
          left: "center",
          top: "center",
          style: {
            fill: "#666",
            text: [
              getSharkText("config_page_data_empty"),
              getSharkText("config_page_please_reselect_filter_conditions"),
            ].join("\n"),
            font: "26px Microsoft YaHei",
          },
        },
      });
    }
  }, [dataFilled, dataMerged]);

  return <EChartsBase ref={ref}></EChartsBase>;
};
export default LoadTrend;
