import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { Card, Col, Empty, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import BarCharts from "./charts";
import { AggCode, ISeries } from "Interface";
import useGlobal from "Store";
import {
  calculateCardsContrastVal,
  fillCharts,
  getBetweenDateArr,
  getModule,
  getModuleNameFromPath,
  isAirportMode,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";
import { useLocation } from "react-router";
import { useFetch } from "Utils/useFetch";
import DownloadBtn from "../DownloadBtn";

interface BarLineChartsProps {
  /**
   * SOA接口中的moduleCode
   */
  moduleCode: string;
  /**
   * SOA接口中的chartTableCode
   */
  chartTableCode: string;
  /**
   * SOA接口的地址
   */
  queryUrl: string;
  /**
   * 可选. 图表的高度, 默认452
   */
  height?: number;
  /**
   * 可选. 图表Container的样式
   */
  style?: CSSProperties;
  /**
   * 可选, 附加的查询参数, 根据业务需求传递
   */
  ext?: Record<string, unknown>;
  /**
   * 所有可选的series合集, 获取到数据并进行转换后, 会根据数据已有的列, 和defaultSeries配置, 判断需要实际使用的列
   */
  defaultSeries: ISeries[];
  /**
   * 可选, 是否启用日周月粒度选项, 默认启用
   */
  useGranularity?: boolean;
  /**
   * 是否启用下载, 必须配置onDownload函数才可以生效
   */
  useDownload?: boolean;
  /**
   * 下载函数, 仅在启用下载时有效
   */
  onDownload?: (oriData: any[]) => void;
  /**
   * 可选, 查询时使用的SOA服务地址,
   * 例如//gateway.m.fws.qa.nt.ctripcorp.com/restapi/soa2/19595/  机场21323, 航司21324,
   * 默认从全局获取systemType并切换服务地址
   */
  server?: string;
  /**
   * 可选. 将图表查询结果数据共享给其它组件使用
   */
  setShareData?: (v: any) => void;
  /**
   * 可选, 对查询数据进行格式转化或者计算, 配置defaultSeries显示
   * 传入经过日期排序后的数据结果
   * 需要返回被修改了字段的数据结果
   */
  dataTrans?: (data: any) => any;
  /**
   * 是否使用dataSet数据集, 默认启用
   */
  useDataset?: boolean;
  /**
   * 可选. ECharts配置项, 会透传给ECharts, 因为过于灵活可能导致难以维护, 非必要不建议使用
   */
  chartConfig?: Record<string, unknown>;
  /**
   * need100Times, 百分数是否需要乘以100, 由于旧版flightAi的问题, 百分数*100是由后端处理的, 但应该由前端在显示时处理才更加合理
   * 默认为false以兼容旧逻辑, 新模块设为true
   */
  need100Times?: boolean;
  /**
   * 可以由父组件管理aggCode
   */
  aggCode?: number;
  /**
   * 数据精度
   */
  precision?: number;
  /**
   * 浮窗日期的起始时间, 如果不设置则使用全局变量中的起始时间
   */
  startDate?: string;
  /**
   * 浮窗日期的结束时间, 如果不设置则使用全局变量中的起始时间
   */
  endDate?: string;
  /**
   * 图表的事件
   */
  chartEvents?: Record<string, (...args: any[]) => any>;
}

/**
 * 新版的线图组件, 组件内不与任何业务组件关联
 */
const BarLineChartsV2: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    style,
    ext,
    defaultSeries,
    useGranularity = true,
    useDownload = false,
    onDownload,
    server,
    setShareData,
    useDataset = true,
    dataTrans,
    chartConfig,
    need100Times = false,
    aggCode: ParentAggCode,
    precision = 0,
    startDate: tooltipStartDate,
    endDate: tooltipEndDate,
    chartEvents,
  } = props;
  const location = useLocation();
  const [aggCode, setAggCode] = useState<AggCode>(ParentAggCode ?? 0);
  const [barData, setBarData] = useState<any[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition, userInfo } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const startDate =
    tooltipStartDate ||
    (isAirport ? queryCondition.startDate : airlinesQueryCondition.startDate);
  const endDate =
    tooltipEndDate ||
    (isAirport ? queryCondition.endDate : airlinesQueryCondition.endDate);
  const compareType = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const isDemo = !!(module && module.moduleStatus === 0);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: server || getServer(systemType),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      ...(useGranularity && { aggCode }),
      ...ext,
    },
    lazey: true,
  });
  const refetch = useCallback(() => {
    doFetch({
      query,
      ext: {
        ...(useGranularity && { aggCode }),
        ...ext,
      },
    });
  }, [doFetch, query, useGranularity, aggCode, ext]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const defaultDataTrans = useCallback(
    (source: any[]) => {
      const compareData = source.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value ? _.round(item.value, precision) : 0;
        item.compareValue = item.compareValue
          ? _.round(item.compareValue, precision)
          : 0;
        const resultValue = _.round(
          calculateCardsContrastVal(
            item.value,
            item.compareValue,
            compareType,
            systemType
          ),
          1
        );
        item.resultValue = _.isFinite(resultValue) ? resultValue : 0;
        return item;
      });
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(startDate), moment(endDate), aggCode)
      );
      return fillData;
    },
    [startDate, endDate, aggCode, precision, compareType, systemType]
  );
  const trans = dataTrans || defaultDataTrans;

  useEffect(() => {
    if (setShareData) {
      setShareData(data);
    }
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const fillData = trans(sortDate);
      setBarData(fillData);
    } else {
      setBarData([]);
    }
  }, [data, setShareData, trans]);
  const handleDownload = useCallback(() => {
    if (onDownload) {
      onDownload(data);
    }
  }, [data, onDownload]);

  /**
   * 当父组件ParentAggCode变更时, 改变本地AggCode的值
   */
  useEffect(() => {
    if (ParentAggCode !== undefined) {
      setAggCode(ParentAggCode);
    }
  }, [ParentAggCode]);

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <Card style={style} className="barline-charts">
      <Row justify="space-between">
        {/* 只有当选择启用粒度选项, 并且父组件的粒度设置为undefined时才生效 */}
        {useGranularity && ParentAggCode === undefined ? (
          <Col>
            <Radio.Group
              value={aggCode}
              onChange={handleChange}
              id="chartsAggCode"
            >
              <Radio.Button value={0}>
                {getSharkText("key.day.button")}
              </Radio.Button>
              <Radio.Button value={1}>
                {getSharkText("key.week.button")}
              </Radio.Button>
              <Radio.Button value={2}>
                {getSharkText("key.month.button")}
              </Radio.Button>
            </Radio.Group>
          </Col>
        ) : undefined}
        {useDownload ? (
          <Col>
            <DownloadBtn
              handleDownload={handleDownload}
              showIcon={false}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        ) : undefined}
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <BarCharts
          aggCode={aggCode}
          loading={isLoading}
          data={barData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          isDemo={isDemo}
          defaultSeries={defaultSeries}
          useDataset={useDataset}
          chartConfig={chartConfig}
          need100Times={need100Times}
          precision={precision}
          chartEvents={chartEvents}
        />
      )}
    </Card>
  );
};

export default BarLineChartsV2;
