import { Checkbox, Col, Row } from "antd";
import AirlinesGlobal from "Components/AirlinesGlobal";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import {
  DATE_FORMAT,
  EMPTY_ARRAY,
  LIMIT_END_DATE,
  LIMIT_START_DATE,
} from "Constants";
import { IDateMode, RangeObject, Role } from "Interface";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import SelectMultiRoute from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import { getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getDefaultAirline } from "Utils/global";

// import { MODULE_CODE, QUERY_URL } from "../fetchCode";

export interface ISearchAreaProps {
  setQueryExt: (v: any) => void;
  isDemo?: boolean;
}

const defaultDateMode: IDateMode = "lastDay";

/**
 *  Component description
 */
const SearchArea = (props: ISearchAreaProps): ReactElement => {
  const { setQueryExt, isDemo = false } = props;
  // const [qf] = useQueryStringArr("flightNo", []);
  const [routes, setRoutes] = useState<string[]>(EMPTY_ARRAY);
  const [flightNO, setFlightNO] = useState<string[]>(EMPTY_ARRAY);
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange(defaultDateMode)
  );
  const [otherAirlines, setOtherAirlines] = useState<number>(1);
  const [globalState, actions] = useGlobalState();
  const { systemType, userInfo, airlinesQueryCondition } = globalState;
  const { setAirlinesQueryConditionAttr } = actions;
  const roleList = userInfo.roleList;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;

  useEffect(() => {
    if (!airlinesQueryCondition.airlines) {
      const tmp = getDefaultAirline(airlinesRole);
      setAirlinesQueryConditionAttr(tmp);
    }
  }, []);

  /**
   * 监听参数变化并缓存参数
   */
  useEffect(() => {
    setQueryExt({
      startDate:
        (dateRange && dateRange[0]?.format(DATE_FORMAT)) || LIMIT_START_DATE,
      endDate:
        (dateRange && dateRange[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
      filter: {
        flightList: flightNO,
        routeList: routes,
        otherAirlines,
        download: 0,
      },
    });
  }, [flightNO, routes, setQueryExt, otherAirlines, dateRange]);

  return (
    <Row align="middle" gutter={[10, 20]} style={{ marginTop: 0 }}>
      <Col>
        <span className="search-area-filter-title">
          {getSharkText("system_type_Airline")}：
        </span>
        {/* <Airlines airlines={userAirlines} mode={undefined} /> */}
        <AirlinesGlobal mode={undefined} />
      </Col>
      <Col>
        <span>{getSharkText("charts.date_of_departure")}：</span>
        <DateRangePickerV2
          defaultDateMode={defaultDateMode}
          onChange={setDateRange}
        />
      </Col>
      <Col>
        <SelectMultiRoute
          mode="multiple"
          queryUrl={QUERY_URL.routeFlight}
          moduleCode={MODULE_CODE}
          routes={routes}
          setRoutes={setRoutes}
          isDemo={isDemo}
          width="auto"
          flights=""
          // ext={flightQueryExt}
          autoSelectFirst
          useExchange
          debugId="price_analysis_table_route"
        />
      </Col>
      <Col>
        <SelectMultiFlight
          queryUrl={QUERY_URL.routeFlight}
          moduleCode={MODULE_CODE}
          flightNO={flightNO}
          setFlightNO={setFlightNO}
          routes={routes.join(",")}
          isDemo={isDemo}
          width={200}
          type={5}
          debugId="price_analysis_table_flight"
          mode="multiple"
        />
      </Col>
      <Col>
        <Checkbox
          checked={!!otherAirlines}
          onChange={(e) => setOtherAirlines(e.target.checked ? 1 : 0)}
        >
          {getSharkText("config_page_other_airlines_price")}
        </Checkbox>
      </Col>
    </Row>
  );
};
export default SearchArea;
