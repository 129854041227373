import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { IDownloadHeader, SystemType } from "Interface";
import { durationFormatter, showNum, tableNumSorter } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { ERouteType } from "../Common";
import { TransferAnalysisRoute } from "@ctrip/flt-bi-flightai-airlines";

export type RouteColumns = Array<IDownloadHeader<TransferAnalysisRoute>>;

const routeWidth = 280;

const DisadvantageTypeName: Record<number, string> = {
  0: getSharkText("config_page_price_and_transfer_duration_advantage"),
  1: getSharkText("config_page_only_price_disadvantage"),
  2: getSharkText("config_page_only_transfer_duration_disadvantage"),
  3: getSharkText("config_page_price_and_transfer_duration_disadvantage"),
};

const searchIndexTitle = (
  <Popover content={getSharkText("config_page_based_on_ctrip_platform")}>
    {getSharkText("key.search_index.name")}{" "}
    <InfoCircleOutlined style={{ display: "inline-block", marginLeft: 5 }} />
  </Popover>
);

export const airlinesAdvantageColumns: RouteColumns = [
  {
    title: getSharkText("transfer.route_OD"),
    dataIndex: "dport",
    width: routeWidth,
    render: (v: number, r: TransferAnalysisRoute) =>
      `${r.dportName}(${r.dport})-${r.aportName}(${r.aport})`,
  },
  {
    title: getSharkText("transfer.no_transfer_psgr.name"),
    dataIndex: "transitPersons",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transitPersons, b.transitPersons, s),
  },
  {
    title: getSharkText("transfer.transfer_share.name"),
    dataIndex: "allOdRate",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.allOdRate, b.allOdRate, s),
    render: (v: number) => showNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("transfer.avg_transfer_duration.name"),
    dataIndex: "transferTime",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTime, b.transferTime, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.market_transfer_duration.name"),
    dataIndex: "transferTimeMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTimeMarket, b.transferTimeMarket, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.avg_transfer_price.name"),
    dataIndex: "avgPrice",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPrice, b.avgPrice, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.market_transfer_price.name"),
    dataIndex: "avgPriceMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPriceMarket, b.avgPriceMarket, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.price_difference.name"),
    dataIndex: "priceDiff",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.priceDiff, b.priceDiff, s),
    render: (v) => showNum(v),
  },
];

export const airlinesDisadvantageColumns: RouteColumns = [
  ...airlinesAdvantageColumns,
  {
    title: getSharkText("tranfer.disad_type.name"),
    dataIndex: "diffType",
    render: (val: number): string => {
      if (DisadvantageTypeName[val]) {
        return DisadvantageTypeName[val];
      }
      return getSharkText("key.unknown.name");
    },
  },
];

export const airlinesPotentialColumns: RouteColumns = [
  ...airlinesAdvantageColumns.slice(0, 1),
  {
    title: getSharkText("transfer.t_airport.name"),
    dataIndex: "tportName",
  },
  ...airlinesAdvantageColumns.slice(1, 2),
  {
    title: getSharkText("config_page_market_transfer_passengers"),
    dataIndex: "transitPersonsMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transitPersonsMarket, b.transitPersonsMarket, s),
  },
  ...airlinesAdvantageColumns.slice(2, 3),
  {
    title: searchIndexTitle,
    dataIndex: "searchIndex",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.searchIndex, b.searchIndex, s),
    render: (v: number) => showNum(v),
  },
  ...airlinesAdvantageColumns.slice(3),
];

export const airportAdvantageColumns: RouteColumns = [
  {
    title: getSharkText("transfer.route_OD"),
    dataIndex: "dport",
    width: routeWidth,
    render: (v: number, r: TransferAnalysisRoute) =>
      `${r.dportName}(${r.dport})-${r.aportName}(${r.aport})`,
  },
  {
    title: getSharkText("transfer.transfer_point.name"),
    dataIndex: "tport",
    render: (v: number, r: TransferAnalysisRoute) => `${r.tportName}`,
  },
  {
    title: getSharkText("transfer.no_transfer_psgr.name"),
    dataIndex: "transitPersons",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transitPersons, b.transitPersons, s),
  },
  {
    title: getSharkText("transfer.proportion_ttl_transfers.name"),
    dataIndex: "allOdRate",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.allOdRate, b.allOdRate, s),
    render: (v: number): string => showNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("transfer.avg_transfer_duration.name"),
    dataIndex: "transferTime",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTime, b.transferTime, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.t_duration_oth_points.name"),
    dataIndex: "transferTimeMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transferTimeMarket, b.transferTimeMarket, s),
    render: (v: number) => durationFormatter(v * 60, "m"),
  },
  {
    title: getSharkText("transfer.avg_transfer_price.name"),
    dataIndex: "avgPrice",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPrice, b.avgPrice, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.t_price_oth_points.name"),
    dataIndex: "avgPriceMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.avgPriceMarket, b.avgPriceMarket, s),
    render: (v) => showNum(v),
  },
  {
    title: getSharkText("transfer.price_difference.name"),
    dataIndex: "priceDiff",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.priceDiff, b.priceDiff, s),
    render: (v) => showNum(v),
  },
];

export const airportDisadvantageColumns: RouteColumns = [
  ...airportAdvantageColumns,
  {
    title: getSharkText("tranfer.disad_type.name"),
    dataIndex: "diffType",
    render: (val: number): string => {
      if (DisadvantageTypeName[val]) {
        return DisadvantageTypeName[val];
      }
      return getSharkText("key.unknown.name");
    },
  },
];

export const airportPotentialColumns: RouteColumns = [
  ...airportAdvantageColumns.slice(0, 3),
  {
    title: getSharkText("config_page_total_transit_passengers"),
    dataIndex: "transitPersonsMarket",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.transitPersonsMarket, b.transitPersonsMarket, s),
  },
  ...airportAdvantageColumns.slice(3, 4),
  {
    title: searchIndexTitle,
    dataIndex: "searchIndex",
    sorter: (
      a: TransferAnalysisRoute,
      b: TransferAnalysisRoute,
      s?: SortOrder
    ) => tableNumSorter(a.searchIndex, b.searchIndex, s),
    render: (v: number) => showNum(v),
  },
  ...airportAdvantageColumns.slice(4),
];

export const columns: Record<SystemType, Record<ERouteType, RouteColumns>> = {
  [SystemType.airlines]: {
    [ERouteType.Advantage]: airlinesAdvantageColumns,
    [ERouteType.Disadvantage]: airlinesDisadvantageColumns,
    [ERouteType.Potential]: airlinesPotentialColumns,
  },
  [SystemType.airport]: {
    [ERouteType.Advantage]: airportAdvantageColumns,
    [ERouteType.Disadvantage]: airportDisadvantageColumns,
    [ERouteType.Potential]: airportPotentialColumns,
  },
};
