import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import { CompareAirport, Role } from "Interface";
import _ from "lodash";
import { getServer } from "Service/server";

interface SelectAirportProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  setCompareAirport: (compareAirport: CompareAirport) => void;
}

const { Option } = Select;

const SelectAirport: React.FC<SelectAirportProps> = (
  props: SelectAirportProps
) => {
  const { queryUrl, moduleCode, chartTableCode, setCompareAirport } = props;
  const [options, setOptions] = useState<CompareAirport[]>([]);

  const [globalState] = useGlobal();
  const { queryCondition, userInfo, systemType } = globalState;
  const { roleList } = userInfo;

  const [{ data, isLoading }] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryCondition,
  });

  useEffect(() => {
    const airportOptions: CompareAirport[] = [];
    const airportRole =
      roleList && roleList.find((item: Role) => item.roleType === 1);
    if (airportRole && airportRole.permissionCode !== "*") {
      const permissionCodeArr = airportRole.permissionCode.split(",");
      const permissionNameArr = airportRole.permissionName.split(",");
      permissionCodeArr.forEach((code: string, idx: number) => {
        airportOptions.push({
          id: "",
          airport: code,
          airportName: permissionNameArr[idx],
          maskName: "",
        });
      });
    }
    if (!_.isEmpty(data)) {
      data.forEach((item: CompareAirport) => {
        airportOptions.push({
          id: item.id,
          airport: item.airport,
          airportName: item.airportName,
          maskName: item.maskName,
        });
      });
    }
    setOptions(airportOptions);
  }, [data, queryCondition]);

  const handleChange = (val: number) => {
    setCompareAirport(options[val]);
  };

  return (
    <Select
      style={{ width: "184px" }}
      placeholder={getSharkText("key.choose_airport_compare")}
      loading={isLoading}
      onChange={handleChange}
    >
      {options.map((option, idx: number) => (
        <Option key={idx} value={idx}>
          {option.id ? option.maskName : option.airportName}
        </Option>
      ))}
    </Select>
  );
};

export default SelectAirport;
