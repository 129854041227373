export enum ETravixCompareType {
  WoW = 1,
  Yo19,
  YoY,
}
export enum ETravixDimensionType {
  Travix = 1,
  Trip,
  Total,
}

export enum ETravixValueType {
  Seg = 1,
  Gmv = 2,
  PaxShare = 3,
}
export const TravixCompareType: Record<number, string> = {
  1: "WoW",
  2: "Yo19",
  3: "YoY",
};
