export const MODULE_CODE: any = {
  1: "transfer_policy_airport",
  2: "transfer_policy_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    barline: "AP0903",
    table: "AP0905",
  },
  2: {
    barline: "AL0901",
    table: "AL0902",
  },
};

export const QUERY_URL: any = {
  1: {
    barline: "queryAirportTransferPolicyBarline",
    table: "queryAirportTransferPolicyTable",
  },
  2: {
    barline: "queryTransferPolicyBarline",
    table: "queryTransferPolicyTable",
  },
};
