import { IDownloadHeader } from "Interface";
import { showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

export const totalColumns: IDownloadHeader[] = [
  {
    title: getSharkText("key.date.flightplan"),
    dataIndex: "day",
  },
  {
    // 标题使用图表的标题,
    title: "",
    dataIndex: "lf",
    render: (val: number) => showNum(val, "percentage"),
    downloadFormatter: (val) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText("config_page_actual_airline_seats"),
    dataIndex: "airlineValue",
    downloadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText("config_page_airline_pred_passenger"),
    dataIndex: "airlinePredValue",
    downloadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    // 标题使用图表的标题
    title: "",
    dataIndex: "soldSeats",
    downloadFormatter: (val: number) => showRawNum(val, "num"),
  },
  {
    title: getSharkText("config_page_airline_actual_passenger_volume"),
    dataIndex: "airlineSoldSeats",
    downloadFormatter: (val: number) => showRawNum(val, "num"),
  },
  {
    title: getSharkText("config_page_airline_pred_passenger_volume"),
    dataIndex: "airlinePredSoldSeats",
    downloadFormatter: (val: number) => showRawNum(val, "num"),
  },
];
