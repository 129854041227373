import { ColumnType, SortOrder } from "antd/lib/table/interface";
import { ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { FileInfo } from "@ctrip/flt-bi-flightai-base";
import { ICardConfig } from "Components/Card/CardsInterface";
import { Moment } from "moment";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { THEMES } from "Constants";
import { LegEntity, SubCabinEntity, SubLegEntity } from "./ro_100031888";
import { getSharkText } from "Utils/i18nGlobal";
export * from "./ro_100031888";
console.log("interface start");
/** 1=airport,2=airline */
export enum SystemType {
  airport = 1,
  airlines,
}

export enum UserStatus {
  unactivated,
  actived,
  reject,
}

export enum UserType {
  unknown = 0,
  internal = 1,
  external,
}

export enum RoleTypeUser {
  member,
  groupAdmin,
}

export interface Login {
  uid: string | null;
  token: string | null;
  name: string | null;
}

export interface User {
  id: number;
  uid: string;
  name: string;
  depart?: string;
  photos?: FileInfo;
  mobilePrefix: string;
  mobile: string;
  wechat: string;
  mail: string;
  groupId: number;
  mainUnitCode: string;
  mainUnitName: string;
  demoType: DemoType;
  /** 组内角色, 普通组员=0/管理员=1 */
  memberType: RoleTypeUser;
  userStatus: UserStatus;
  /** 是否携程: 携程=1/外部=2 */
  userType: UserType;
  sortId: number;
  registerDate: string;
  lastLoginDate: string;
}

export enum ModuleStatus {
  demo,
  formal,
  trial,
  null,
}

export enum ModuleType {
  airport = 1,
  system,
  airline,
  partner,
}

export interface Module {
  id?: number;
  moduleCode: string;
  moduleName: string;
  menuUrl: string;
  menuIcon: string;
  parentId: number;
  moduleStatus: ModuleStatus;
  moduleType: ModuleType;
  newModule: 0 | 1;
  homePage: 0 | 1;
  remark: string;
  beginTime: string;
  endTime: string;
  children: Module[];
}

export enum DemoType {
  // 默认值为0
  default,
  // 机场版
  airport,
  // 航司版，不等于航司版都为机场版
  airline,
  // 其他
  other,
}

export enum RoleType {
  airport = 1,
  airline,
  other,
}

export interface Role {
  id: number | null;
  roleType: RoleType;
  permissionCode: string;
  permissionName: string;
  /** 权限航线，json string, key:value形式, 每个航司一个key, value是航线列表, 航线列表逗号分隔. 默认空字符串或*为所有. -为禁用 */
  permissionRoute?: string;
}

export interface RoleItem extends Role {
  groupId: number;
}

export interface UserInfo {
  user: User;
  moduleList: Module[];
  roleList?: Role[];
  unitList?: Unit[];
}

export enum FlightClass {
  /**
   * 整体
   */
  All,
  /**
   * 境内
   */
  Domestic,
  /**
   * 跨境
   */
  Foreign,
  /** 纯境外 */
  Overseas,
}

enum Turnover {
  False,
  True,
}

export interface QueryCondition {
  flightClass: FlightClass;
  airport: string;
  airportName: string;
  originalAirport?: Area;
  departure: Turnover;
  arrive: Turnover;
  startDate: string;
  endDate: string;
  useCompare: Turnover;
  compareType: ECompareTypeV2;
  area: Area;
  yoy?: number;
  wow?: number;
}

export enum ECompareTypeV2 {
  // 同比去年
  YEAR,
  // 周同比
  WEEK,
  // 月环比
  MONTH,
  // 前年同比
  YEAR_BEFORE_LAST,
  // 昨天
  LAST_DAY,
  // 大前年同比
  YEAR_LAST_LAST,
  // 对比4年前(例如2023对比2019),
  YEAR_4_BEFORE,
  // 对比5年前
  YEAR_5_BEFORE,
  // 市场对标
  MARKET_COMPARE = 100,
}

/**
 * 默认的航司同比可选项
 */
export type T_AIRLINES_COMPARE_TYPE = Extract<
  ECompareTypeV2,
  | ECompareTypeV2.YEAR
  | ECompareTypeV2.YEAR_BEFORE_LAST
  | ECompareTypeV2.YEAR_LAST_LAST
  | ECompareTypeV2.YEAR_4_BEFORE
  | ECompareTypeV2.YEAR_5_BEFORE
  | ECompareTypeV2.MARKET_COMPARE
>;

/**
 * 默认的机场同比可选项, 既仅年同比选项
 */
export type T_YEAR_COMPARE_TYPE = Extract<
  ECompareTypeV2,
  | ECompareTypeV2.YEAR
  | ECompareTypeV2.YEAR_BEFORE_LAST
  | ECompareTypeV2.YEAR_LAST_LAST
  | ECompareTypeV2.YEAR_4_BEFORE
  | ECompareTypeV2.YEAR_5_BEFORE
>;

/**
 * 年周月同比
 */
export type T_YMW_COMPARE_TYPE = Extract<
  ECompareTypeV2,
  | ECompareTypeV2.YEAR
  | ECompareTypeV2.YEAR_BEFORE_LAST
  | ECompareTypeV2.YEAR_LAST_LAST
  | ECompareTypeV2.YEAR_4_BEFORE
  | ECompareTypeV2.YEAR_5_BEFORE
  | ECompareTypeV2.WEEK
  | ECompareTypeV2.MONTH
>;
export interface AirlinesQueryCondition {
  flightClass: FlightClass;
  airlines: string;
  airlinesName: string;
  startDate: string;
  endDate: string;
  takeoffTime: string;
  compareType: ECompareTypeV2;
  weekAlign: number;
  fsa: number;
  lcc: number;
  departure?: number;
  arrive?: number;
  areaList?: Area[];
  ext: any;
}

export interface IModuleConfig {
  loading: boolean;
  updateTime: string;
  allowAccessAllAirports: boolean;
  [key: string]: any;
}

export enum AggCode {
  day,
  week,
  month,
  year,
  total,
  hour = 10,
  halfHour = 11,
}

export interface CardItemData {
  cardCode: string;
  cardName: string;
  cardValue: number;
  avgValue: number;
  compareValue: number;
  cardHint: string;
  yoyValue: number;
  extValue?: number | null;
  /**
   * 关联card, 用于同一张卡片上做数据切换
   */
  feSwitchList?: CardItemData[];
  /**
   * 卡片配置
   */
  feCardConfig?: ICardConfig;
}

export interface Area extends Record<string, unknown> {
  areaType: number;
  areaCode: string;
  areaName: string;
  areaNameEn?: string | null;
  pinyin?: string | null;
  children?: Area[];
}

export interface Ext {
  [propName: string]: any;
}
export interface BarLineData {
  day: string;
  value: number;
  compareValue: number;
  totalValue: number;
}

export interface VerifyInfo {
  token: string;
  version: string;
  requestId: string;
}

export interface LoginPageState {
  mode: string;
}

export interface ApproveUserData {
  id: number;
  applyTime: string;
  uid: string;
  name: string;
  mobilePrefix: string;
  mobile: string;
  mail: string;
  mainUnitCode: string;
  mainUnitName: string;
  remark: string;
  groupId: number;
  approveStatus: UserStatus;
  approveRemark: string;
  depart: string;
  budget: number;
  persons: number;
  updateTime: string;
  demoType: DemoType;
  roleList: Role[];
}

export interface UserList {
  roleList: Role[];
  user: User;
}

export interface Unit {
  id: number;
  unitCode: string;
  unitName: string;
  managerUnit: boolean;
  remark: string;
  sortId: number;
  autoRenew: 0 | 1 | null;
  renewDay: number | null;
}

export interface UnitList {
  unit: Unit;
  roleList: Role[];
}

export interface Modulelog {
  id: number;
  user: string;
  changeDate: string;
  moduleCode: string;
  moduleName: string;
  menuUrl: string;
  moduleStatus: ModuleStatus;
  beginTime: string;
  endTime: string;
  remark: string;
}

export interface AirlineItem {
  airlinesCode: string;
  airlinesName: string;
  flightNO?: string;
  dport?: string;
  dportName: string;
  aport?: string;
  aportName: string;
  dcity?: string;
  dcityName: string;
  acity?: string;
  acityName: string;
}

export type AirlineItemPartial = Partial<AirlineItem>;

export interface AirlinesQueryExt extends FlightArea {
  filterType?: 0 | 1 | 2;
  flightNO?: string[];
}

export interface AirlineQueryExtWithTogether extends AirlinesQueryExt {
  together?: number;
  solo?: 1 | 0;
}

export interface FlightArea extends Record<string, unknown> {
  departArea: Area;
  arriveArea: Area;
}

export interface FlightPlanQueryExt extends FlightArea {
  filterAirlines: string;
}

export interface TransferFlightArea extends FlightArea {
  transferArea: Area;
  sameAirline: 0 | 1;
  airline1: 0 | 1;
  airline2: 0 | 1;
}

export interface CompareAirport {
  id?: string;
  airport?: string;
  airportName?: string;
  maskName?: string;
}

export interface AirportCompareQueryExt {
  compareAirport: CompareAirport;
  area: Area;
}

export enum CardType {
  airport,
  compareAirport,
}

export interface CompareCardItemData extends CardItemData {
  cardType: CardType;
}

export interface PassengerAnalysisQueryExt {
  local?: 0 | 1;
  surrounding?: 0 | 1;
  cityList?: Area[];
  byProvince?: 0 | 1;
  byDistance?: 0 | 1;
  kilometers?: number;
}

export interface IDynamicPriceFilter {
  routeList: string[];
  flightList: string[];
  startDate: string;
  endDate: string;
}

export interface IFlightManageFilter {
  route: string;
  flightNo: string[];
  time: number;
  competitive: number;
  competitiveFlightNo?: number;
}

export interface IFlightManageQueryExt {
  filter: IFlightManageFilter;
}

export interface IDashboardAirportFilter {
  flightClass: 0 | 1 | 2;
}

export enum EValueControl {
  "数值",
  "年同比",
  "月环比",
  "周环比",
}

export interface IValueControlItem {
  value: EValueControl;
  render?: ReactNode;
  tooltip?: string;
}

type sortFn = (a: any, b: any, c?: SortOrder) => number;
// 用于下载数据的列配置
export interface IDownloadHeader<T extends Record<string, any> = any>
  extends ColumnType<T> {
  /**
   * 下载用的列名, 在下载文件中需要作为属性名使用, 必须是string.
   */
  downloadTitle?: string;
  /**
   * 可以选择数据结构中的字段作为dataIndex, 额外包含"", "feNone", "actions"用于某些特殊的业务处理
   * 匹配list<map>中map的key
   */
  dataIndex: Extract<keyof T, string> | "" | "feNone" | "actions";
  /**
   * 单元格转化val为当前单元格数据, record为当前行数据
   */
  downloadFormatter?: (val: number, record: T, i: number) => any;
  render?: (val: any, record: T, i: number) => string | number | ReactNode;
  sorter?: sortFn | true;
  /**
   * 部分表格需要自定义排序方法, 比如航班管理需要先按日期后按字段排序
   */
  sortFn?: sortFn;
  /**
   * 快捷设置项, 为true且没有设置render和downloadFormatter, 会自动配置两个render
   */
  isPercentVal?: boolean;
  /**
   * 快捷设置项, 设置值且没有设置render和downloadFormatter, 会自动配置render的数据精度
   */
  precision?: number;
  // [key: string]: any;
}

export type IDownloadHeaderPro<T extends Record<string, any> = any> =
  ProColumns & {
    // 显示的列名
    title: string | ReactNode;
    // 匹配list<map>中map的key
    dataIndex?: Extract<keyof T, string> | "" | "feNone" | "actions";
    downloadTitle?: string;
    /** 单元格转化 val为当前单元格数据, record为当前行数据 */
    downloadFormatter?: (val: number, record: any, i: number) => any;
    /**
     * 部分表格需要自定义排序方法, 比如航班管理需要先按日期后按字段排序
     */
    sortFn?: sortFn;
    // render?: (val: any, record: any, i: number) => string | number | ReactNode;
    children?: Array<IDownloadHeaderPro<T>>;
  };

// 在IDownloadHeader的基础上, 支持数组配置key值
export interface IDownloadHeaderArrIdx<T extends Record<string, any> = any>
  extends ColumnType<T> {
  // 匹配list<map>中map的key
  dataIndex: Extract<keyof T, string> | readonly string[];
}

export enum ChartDataFormatter {
  Num = "num",
  Percent = "percent",
  Decimal = "decimal",
}

export interface ISeries {
  type: string;
  name: string;
  encode: {
    x: string;
    y: string;
  };
  isPercentVal?: boolean;
  itemStyle?: {
    color?: string;
  };
  yAxisIndex?: number;
  precision?: number;
  /**
   * 同一个Y轴的格式化类型必须一致.
   */
  formatterType?: ChartDataFormatter;
  [key: string]: any;
}

export interface AirportOrAirline {
  name: string;
  code: string;
}

export interface City {
  cityCode: string;
  cityName: string;
  value: number;
}
export interface Tab {
  chartTableCode: string;
  title: string;
  titleTips?: string;
  comparable?: boolean;
  type?: number;
  compare?: boolean | number;
  isPercentVal?: boolean;
}

export interface IValueLabelOption {
  value: string;
  label: string;
}
export interface INumberLabelOption {
  value: number;
  label: string;
}

export interface IKeyLabelOption {
  value: React.Key;
  label: ReactNode;
}
/**
 * none用来在通用query中禁用日期选项, custom在快捷选项中设置自定义日期段, 比如说最近一周/最近30天/自定义
 * custom 和 none不会生成固定的时间区段,
 */
export type IDateMode =
  | "none"
  | "currentMonth"
  | "lastWeek"
  | "lastMonth"
  | "lastThreeMonth"
  | "lastYear"
  | "currentDay"
  | "nextWeek"
  | "nextThirtyDays"
  | "nextFourteenDays"
  | "fourteenDays"
  | "ninetyDays"
  | "lastDay"
  | "custom";

export enum EDateRangeGroupsNames {
  businessAnalysis,
}
export type IDateRangeGroup = {
  [key in keyof typeof EDateRangeGroupsNames]: string[];
};

export const DATE_RANGE_GROUPS: IDateRangeGroup = {
  businessAnalysis: [
    "/core_overview",
    "/airline_analysis",
    "/airport_compare",
    "/passenger_analysis",
  ],
};

export interface IAirport {
  name: string;
  code: string;
}

export interface IHoliday {
  /** 日期(节日当天或者开始日) */
  day: string;
  // 节假日名称
  holidayName: string;
  // 年份
  year: number;
  // 可选日期段0=节中(默认) 1=节前 2=节后 3=节前+节后４＝所有
  holidayType?: number;
}

export interface IEbkSharkGetParam {
  key: string;
}

export interface IEbkSharkHelper {
  init: () => void;
  get: (o: IEbkSharkGetParam) => string;
}

declare global {
  interface Window {
    TARGET_PLATFORM_CONFIG?: {
      code: string;
      usedRoles?: RoleType[];
    };
    BMap: any;
    __bfi: any;
    ActiveXObject?: any;
    bMapInit: any;
    opera?: any;
    InstallTrigger?: any;
    StyleMedia?: any;
    chrome?: any;
    moment?: any;
    _?: any;
    i18n_100028128?: Record<string, string>;
    subFat?: string;
    appConfig: {
      env: "##env##" | "fws" | "fat" | "pro";
    };
    SERVER_BASE_URL: string;
  }
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    documentMode?: any;
  }
}

export interface ITrace {
  category: string;
  [key: string]: any;
  getName: (k: keyof ITrace) => string;
}

// 一机两场的处理
export interface IMultiAirport {
  [key: string]: string[];
}

export type Direction = "T" | "R" | "RT";

export type DateObject = Moment | null;

export type RangeObject = [DateObject, DateObject] | null;

export interface ResponseStatusType {
  Timestamp?: string | null;
  Ack?: AckCodeType | null;
  Errors?: ErrorDataType[] | null;
  Build?: string | null;
  Version?: string | null;
  Extension?: ExtensionType[] | null;
  /**
   * 描述信息
   */
  responseDesc?: string | null;
  userID?: string | null;
  msg?: string | null;
  /**
   * 响应编码（20000：成功）
   */
  responseCode?: number | null;
}
export interface ErrorDataType {
  Message?: string | null;
  /**
   * A unique code that identifies the particular error
   *  condition that occurred.
   */
  ErrorCode?: string | null;
  /**
   * ErrorDataType
   */
  StackTrace?: string | null;
  /**
   * ErrorDataType
   */
  SeverityCode?: SeverityCodeType | null;
  /**
   * ErrorDataType
   */
  ErrorFields?: ErrorFieldType | null;
  /**
   * ErrorDataType
   */
  ErrorClassification?: ErrorClassificationCodeType | null;
}
export interface ErrorFieldType {
  /**
   * ErrorFieldType
   */
  fieldName?: string | null;
  /**
   * ErrorFieldType
   */
  errorCode?: string | null;
  /**
   * ErrorFieldType
   */
  message?: string | null;
}
type SeverityCodeType = "Error" | "Warning";
type ErrorClassificationCodeType =
  | "ServiceError"
  | "ValidationError"
  | "FrameworkError"
  | "SLAError";
export interface ExtensionType {
  /**
   * ExtensionType
   */
  id?: string | null;
  /**
   * ExtensionType
   */
  version?: string | null;
  /**
   * ExtensionType
   */
  contentType?: string | null;
  /**
   * ExtensionType
   */
  value?: string | null;
}
type AckCodeType = "Success" | "Failure" | "Warning" | "PartialFailure";

export type ResUI<T> = Omit<T, "responseStatus"> & {
  ResponseStatus: ResponseStatusType;
};

export interface OpenableHandle {
  open: () => void;
}

export interface ComparatorArg {
  key: string;
  op: "REPLACE" | "DISABLE";
  filter: StandardFilter;
}

export interface RangeCompare {
  current: RangeObject;
  compare: RangeObject;
}

/** 将舱位和布局合并以便显示在表格中 */
export type LegEntityExtendsSub = LegEntity &
  SubLegEntity & {
    key: string;
    editingPcfValue: number;
    editingInd: boolean;
    editingStopBooking: boolean;
  };
/** 子仓entity扩展支持前端操作ind */
export type SubCabinEntityExtend = SubCabinEntity & {
  /** 前端从ind字段中拆解出来的, 控制ind的L */
  nestOri: boolean;
  nestAdjusting: boolean;
  /** 前端从ind字段中拆解出来的, 控制ind的P */
  permanentOri: boolean;
  permanentAdjusting: boolean;
  /** 编辑中的ind */
  adjustingInd: string;
  /** 编辑中的lsv */
  adjustingLsv: string | undefined;
  /** 调舱后新的Lss */
  adjustedLss: string | undefined;
};

export type Theme = (typeof THEMES)[number];

export type Obj = Record<string, unknown>;
