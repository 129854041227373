import UBT, { UBT_TYPE } from "@ctrip/ubt-web";
import { getCookie, getCookieUID } from "./cookie";
import { UBT_APPID, USER_NAME } from "Constants";
const ubtUtils = {
  lastPageId: "",
  lastPathname: "",
  pv: (pageId: string, pathname: string): void => {
    if (pageId !== ubtUtils.lastPageId || pathname !== ubtUtils.lastPathname) {
      ubtUtils.lastPageId = pageId;
      ubtUtils.lastPathname = pathname;
      const pvData = {
        pageId,
        appId: UBT_APPID,
        business: {
          loginName: getCookieUID() || "unLogin",
          loginId: getCookieUID() || "unLogin",
          userName: getCookie(USER_NAME) || "unLogin",
          pathname: pathname || "unKnown",
        },
      };
      if (process.env.NODE_ENV === "development") {
        console.log("pv: ", pvData);
      }
      UBT.register(pvData);
    }
  },
  info: (
    /** 必填项, trace统计和告警时依赖此字段落表, 仅英文和数字 */
    title: string,
    content: Record<string, any> = {},
    key = "o_flt_flightai_info"
  ): void => {
    const data = {
      title,
      ...content,
    };
    if (process.env.NODE_ENV === "development") {
      console.log(title, data, key);
    }
    UBT.send({ type: UBT_TYPE.TRACE, key, data });
  },
  warn: (
    /** 必填项, trace统计和告警时依赖此字段落表, 仅英文和数字 */
    title: string,
    content: Record<string, any>,
    key = "o_flt_flightai_warn"
  ): void => {
    const data = {
      title,
      ...content,
    };
    if (process.env.NODE_ENV === "development") {
      console.warn(title, data, key);
    }
    UBT.send({ type: UBT_TYPE.TRACE, key, data });
  },
  error: (
    error: Error | string,
    name?: string,
    stack?: string,
    priority?: 0 | 1 | 2 | 3 | 4
  ): void => {
    const data =
      typeof error === "string"
        ? {
            stack,
            name,
            message: error,
            category: priority || 0,
          }
        : {
            stack: error.stack,
            name: error.name,
            message: error.message,
            category: priority || 0,
          };
    console.log(
      `custom ubt error! name:${name}, error: ${error}, stack: ${stack}`
    );
    UBT.send({
      type: UBT_TYPE.ERROR,
      data,
    });
  },
};
export default ubtUtils;
