import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import React, { ReactNode } from "react";

import "./index.css";

export interface TrendProps {
  colorful?: boolean;
  flag: "up" | "down";
  style?: React.CSSProperties;
  reverseColor?: boolean;
  className?: string;
  showFlag?: boolean;
  upColor?: string;
  downColor?: string;
  children?: ReactNode;
}

const Trend: React.FC<TrendProps> = ({
  showFlag = true,
  flag,
  children,
  upColor,
  downColor,
  ...rest
}: TrendProps) => (
  <div {...rest} title={typeof children === "string" ? children : ""}>
    <span>{children}</span>
    {showFlag ? (
      <span className="icon-caret">
        {flag === "up" ? (
          <CaretUpOutlined twoToneColor={upColor} />
        ) : (
          <CaretDownOutlined twoToneColor={downColor} />
        )}
      </span>
    ) : undefined}
  </div>
);

export default Trend;
