import React, { useState, useEffect } from "react";
import { Modal, Descriptions, Input, message } from "antd";
import { UserList } from "Interface";
import { getUserRoleTypeText, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  data: UserList;
  refetch: () => void;
}

const UserModal: React.FC<IProps> = (props: IProps) => {
  const { visible, setVisible, data, refetch } = props;
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [{ isLoading }, doFetch] = useFetch({
    url: "saveUser",
    head: {
      moduleCode: "user_manage",
      chartTableCode: null,
    },
    ext: {
      user: {
        ...data.user,
        name,
        mail,
      },
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_modify_success"));
      setVisible(false);
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_fail"));
    },
  });

  useEffect(() => {
    if (visible) {
      setName(data.user.name);
      setMail(data.user.mail);
    }
  }, [data.user.mail, data.user.name, visible]);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    if (name === data.user.name && mail === data.user.mail) {
      message.warning(getSharkText("config_page_PaAiUsCoUsIn_no_modification"));
    } else {
      doFetch({
        ext: {
          user: {
            ...data.user,
            name,
            mail,
          },
        },
      });
    }
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleChangeMail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
  };

  return (
    <Modal
      width={900}
      title={getSharkText("config_page_edit_user")}
      onOk={handleOk}
      okText={getSharkText("config_page_submit")}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      open={visible}
    >
      <div>
        <Descriptions bordered>
          <Descriptions.Item label={getSharkText("config_page_mobile")}>
            {data.user.mobile}
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_work_unit")}
            span={2}
          >
            {data.user.mainUnitName}
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_registration_time")}
          >
            {moment(data.user.registerDate).format("YYYY-MM-DD HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_last_login_time")}
            span={2}
          >
            {moment(data.user.lastLoginDate).format("YYYY-MM-DD HH:mm:ss")}
          </Descriptions.Item>

          <Descriptions.Item label={getSharkText("config_page_name")}>
            <Input value={name} onChange={handleChangeName} />
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_email")} span={2}>
            <Input value={mail} onChange={handleChangeMail} />
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_user_type")}>
            {data.user.userType === 1
              ? getSharkText("config_page_internal_user")
              : getSharkText("config_page_external_user")}
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_user_type")}
            span={2}
          >
            {data.user.memberType === 1
              ? getSharkText("config_page_admin")
              : getSharkText("config_page_common_user")}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_user_role")}>
            {getUserRoleTypeText(data)}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  );
};

export default UserModal;
