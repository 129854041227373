import { Col, Input, Row, Table, TableProps } from "antd";
import DownloadBtn from "Components/DownloadBtn";
import { IDownloadHeader } from "Interface";
import React, { ReactElement, useEffect, useState } from "react";
import { getSharkText } from "Utils/i18nGlobal";
import { downloadExcel } from "Utils/downloadXLSX";

/**
 * Component description
 *
 */

interface IProps {
  columns: IDownloadHeader[];
  data: any[];
  searchKeys: string[];
  showDownload?: boolean;
  tableProps?: TableProps<Record<string, unknown>>;
  downloadFileName?: string;
  searchPlaceholder?: string;
  en?: boolean;
  moduleCode: string;
  chartTableCode: string;
}

const NormalTable = (props: IProps): ReactElement => {
  const {
    columns,
    data,
    searchKeys,
    showDownload = true,
    tableProps,
    downloadFileName = "FlightAi",
    en = false,
    searchPlaceholder,
    moduleCode,
    chartTableCode,
  } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [filterData, setFilterData] = useState<any[]>(data);
  const placeHolder =
    searchPlaceholder || en ? "Search..." : getSharkText("key.input_to_search");

  useEffect(() => {
    if (searchText) {
      const tmp = data.filter((d: any) => {
        const rst = searchKeys.some((key) => {
          return (
            d[key] &&
            d[key].toLowerCase().indexOf(searchText.toLowerCase()) >= 0
          );
        });
        return rst;
      });
      setFilterData(tmp);
    } else {
      setFilterData(data);
    }
  }, [data, searchText, searchKeys]);

  const handleSearch = (v: any) => {
    const queryText = v.target.value;
    setSearchText(queryText);
  };
  const handleDownload = () => {
    downloadExcel(columns, data, downloadFileName);
  };
  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col>
          <Input.Search
            value={searchText}
            placeholder={placeHolder}
            onChange={handleSearch}
          />
        </Col>
        {showDownload && (
          <Col style={{ flex: 1, textAlign: "right" }}>
            <DownloadBtn
              handleDownload={handleDownload}
              en={en}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        )}
      </Row>
      <Table
        columns={columns}
        dataSource={filterData}
        showSorterTooltip={false}
        size="small"
        {...tableProps}
      ></Table>
    </div>
  );
};
export default NormalTable;
