import React from "react";
import { Checkbox } from "antd";
import useGlobal from "Store";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { getSharkText } from "Utils/i18nGlobal";

const InOut: React.FC = () => {
  const [globalState, actions] = useGlobal();
  const { queryCondition } = globalState;
  const { departure, arrive } = queryCondition;
  const { setQueryCondition } = actions;

  const handleChangeDeparture = (e: CheckboxChangeEvent) => {
    setQueryCondition({
      ...queryCondition,
      departure: e.target.checked ? 1 : 0,
    });
  };

  const handleChangeArrive = (e: CheckboxChangeEvent) => {
    setQueryCondition({
      ...queryCondition,
      arrive: e.target.checked ? 1 : 0,
    });
  };

  return (
    <>
      <Checkbox
        id="toolbarDeparture"
        onChange={handleChangeDeparture}
        checked={!!departure}
      >
        {getSharkText("key.outbound.checkbox")}
      </Checkbox>
      <Checkbox
        id="toolbarArrive"
        onChange={handleChangeArrive}
        checked={!!arrive}
      >
        {getSharkText("key.inbound.checkbox")}
      </Checkbox>
    </>
  );
};

export default InOut;
