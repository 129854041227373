export const MODULE_CODE: any = {
  1: "",
  4: "salon_manage",
};

export const CHART_TABLE_CODE: any = {
  1: {},
  4: {
    table: "TX0302",
    action: "TX0302",
    log: "TX0302",
    mail: "TX0302",
  },
};

export const QUERY_URL: any = {
  1: {},
  4: {
    table: "querySalonRegList",
    action: "salonRegAction",
    log: "querySalonRegLog",
    mail: "salonRegMail",
  },
};
