import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Col, Empty, Row, Select, Spin } from "antd";
import DetailCardItem, { DetailCardDataItem } from "./DetailCardItem";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import {
  Dimension,
  FilterValue,
  Measure,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import {
  ACC_REVENUE_NAME,
  AIRLINE_NAME,
  AVG_PRICE_NAME,
  DATACHANGE_LASTTIME_NAME,
  DATASET_ID,
  DATASET_ID_NEWEST,
  FLIGHTNO_NAME,
  LOAD_FACTOR_NAME,
  ROUTE_NAME,
  TAKEOFFDATE_NAME,
  UDF_NAME,
} from "../../DatasetCols";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import {
  CompareMean,
  StandardFilter,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { useFetch } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import { QueryHandler, QueryParam } from "Page/AI/ProfitMonitor/interface";
import CheckButtons from "Components/CheckButtons/CheckButtons";
import { cloneDeep } from "lodash";
import moment from "moment";
import { SelectedFlight } from "Page/AI/FlightManage/FlightManageInterface";
import { WEEK_OPTIONS } from "Constants";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";

export interface SummaryDetail extends Record<string, any> {
  takeoffdate: string;
  route: string;
  flightno: string;
  SUM_acc_revenue: number;
  m_exp_load_factor: number;
  m_exp_avg_price: number;
  wow_grate_SUM_acc_revenue: number;
  yoy_grate_SUM_acc_revenue: number;
  wow_grate_m_exp_load_factor: number;
  yoy_grate_m_exp_load_factor: number;
  wow_grate_m_exp_avg_price: number;
  yoy_grate_m_exp_avg_price: number;
}

export interface LineDetail {
  takeoffdate: string;
  route: string;
  flightno: string;
  datachange_lasttime: string;
  SUM_acc_revenue: number;
  m_exp_load_factor: number;
  m_exp_avg_price: number;
}

export interface DetailCardsProps {
  columns: FDDatasetCol[];
  params: QueryParam;
  onCardClick: (v: SelectedFlight) => void;
}

/** 明细卡片 */
const DetailCards = forwardRef(
  (props: DetailCardsProps, ref: Ref<QueryHandler>): ReactElement => {
    const { columns, params, onCardClick } = props;
    const [summaryData, setSummaryData] = useState<SummaryDetail[]>([]);
    const [lineResponse, setLineResponse] = useState<LineDetail[]>([]);

    const summaryDimensions = useMemo(() => {
      const tmp: Dimension[] = [
        {
          columnName: TAKEOFFDATE_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
        {
          columnName: ROUTE_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
        {
          columnName: FLIGHTNO_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
        {
          columnName: DATACHANGE_LASTTIME_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
      ];
      return tmp;
    }, []);

    const lineMeasures = useMemo(() => {
      const tmp: Measure[] = [
        {
          id: ACC_REVENUE_NAME,
          columnName: ACC_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
          },
        },
        {
          id: LOAD_FACTOR_NAME,
          columnName: LOAD_FACTOR_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
          },
        },
        {
          id: AVG_PRICE_NAME,
          columnName: AVG_PRICE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
          },
        },
      ];
      return tmp;
    }, []);

    const summaryMeasures = useMemo(() => {
      return lineMeasures.concat([
        {
          id: UDF_NAME,
          columnName: UDF_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
          },
        },
      ]);
    }, [lineMeasures]);

    const filters = useMemo(() => {
      const rst: FilterValue[] = [];
      const airlineFilter: FilterValue = {
        filterId: "airline",
        filterType: "select",
        argOfReports: [
          {
            panelId: "1",
            columnName: AIRLINE_NAME,
            values: [params.airline],
          },
        ],
      };
      const tkFilter: FilterValue = {
        filterId: "takeoffdate",
        filterType: "date",
        argOfReports: [
          {
            panelId: "1",
            columnName: TAKEOFFDATE_NAME,
            values: {
              current: [params.takeoffDateStart, params.takeoffDateEnd],
            },
          },
        ],
      };
      const routeFilter: FilterValue = {
        filterId: "route",
        filterType: "select",
        argOfReports: [
          {
            panelId: "1",
            columnName: ROUTE_NAME,
            values: params.routes,
          },
        ],
      };
      const flightsFilter: FilterValue = {
        filterId: "flights",
        filterType: "select",
        argOfReports: [
          {
            panelId: "1",
            columnName: FLIGHTNO_NAME,
            values: params.flights,
          },
        ],
      };
      rst.push(airlineFilter);
      rst.push(tkFilter);
      if (params.routes.length) {
        rst.push(routeFilter);
      }
      if (params.flights.length) {
        rst.push(flightsFilter);
      }
      return rst;
    }, [
      params.airline,
      params.flights,
      params.routes,
      params.takeoffDateEnd,
      params.takeoffDateStart,
    ]);

    const summaryBuilder = useMemo(() => {
      const comparatorWowFilter: StandardFilter = {
        range: {
          field: `dimension.takeoffdate`,
          strRange: {
            lower: params.wowStart,
            upper: params.wowEnd,
          },
        },
      };
      const comparatorYoyFilter: StandardFilter = {
        range: {
          field: `dimension.takeoffdate`,
          strRange: {
            lower: params.yoyStart,
            upper: params.yoyEnd,
          },
        },
      };
      return new RequestBuilder({
        datasetId: DATASET_ID_NEWEST,
        columns,
        dimensions: summaryDimensions,
        measures: summaryMeasures,
        chartFilters: [],
        containerFilters: filters,
        sorters: [
          {
            chartUsedColId: TAKEOFFDATE_NAME,
            columnName: TAKEOFFDATE_NAME,
            sorter: "ASC",
            statistical: "SUM",
          },
        ],
        granularity: "1d",
        limit: 500,
        comparators: [
          {
            name: "filterKey",
            comparisonName: "wow",
            means: [CompareMean.grate],
            args: ["", JSON.stringify(comparatorWowFilter)],
          },
          {
            name: "filterKey",
            comparisonName: "yoy",
            means: [CompareMean.grate],
            args: ["", JSON.stringify(comparatorYoyFilter)],
          },
        ],
      });
    }, [
      columns,
      summaryDimensions,
      summaryMeasures,
      filters,
      params.wowStart,
      params.wowEnd,
      params.yoyStart,
      params.yoyEnd,
    ]);

    const { encrypted } = useMemo(() => {
      return summaryBuilder.getRequestBody();
    }, [summaryBuilder]);

    const lineDimensions = useMemo(() => {
      return summaryDimensions.concat([
        {
          columnName: DATACHANGE_LASTTIME_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
      ]);
    }, [summaryDimensions]);

    const lineBuilder = useMemo(() => {
      return new RequestBuilder({
        datasetId: DATASET_ID,
        columns,
        dimensions: lineDimensions,
        measures: lineMeasures,
        chartFilters: [],
        containerFilters: filters,
        sorters: [
          {
            chartUsedColId: DATACHANGE_LASTTIME_NAME,
            columnName: DATACHANGE_LASTTIME_NAME,
            sorter: "ASC",
            statistical: "COUNT",
          },
        ],
        granularity: "1d",
      });
    }, [columns, lineDimensions, lineMeasures, filters]);

    const { encrypted: lineEncrypted } = useMemo(() => {
      return lineBuilder.getRequestBody();
    }, [lineBuilder]);

    const [{ isLoading }, doFetchSummary] = useFetch({
      url: "mytrixQuery",
      head: {},
      lazey: true,
      useCache: true,
      debugId: "detail-summary",
      onSuccess: (r) => {
        const res = JSON.parse(r.data) as StandardResponse;
        if (res.status === 40000) {
          throw new Error("40000");
        }
        const rows = res.rows;
        const headers = res.headers;
        const source = DataRow2ListMap(
          rows,
          headers
        ) as unknown as SummaryDetail[];
        setSummaryData(source);
      },
    });

    const [{ isLoading: isLoadingLine }, doFetchLine] = useFetch({
      url: "mytrixQuery",
      head: {},
      lazey: true,
      debugId: "detail-line",
      useCache: true,
      onSuccess: (r) => {
        const res = JSON.parse(r.data) as StandardResponse;
        if (res.status === 40000) {
          throw new Error("40000");
        }
        const responseMapData = DataRow2ListMap(
          res.rows,
          res.headers
        ) as unknown as LineDetail[];
        setLineResponse(responseMapData);
      },
    });

    const reqSummary = useRefFunc(() => {
      if (params.flights.length || params.routes.length) {
        return doFetchSummary({
          ext: {
            datasetId: DATASET_ID_NEWEST,
            colIds: [],
            req: encrypted,
          },
        });
      }
    });

    useEffect(() => {
      setSummaryData([]);
      reqSummary();
    }, [reqSummary, encrypted]);

    const reqLine = useRefFunc(() => {
      if (params.flights.length || params.routes.length) {
        return doFetchLine({
          ext: {
            datasetId: DATASET_ID,
            colIds: [],
            req: lineEncrypted,
          },
        });
      }
    });

    useEffect(() => {
      setLineResponse([]);
      reqLine();
    }, [reqLine, lineEncrypted]);

    useImperativeHandle(ref, () => ({
      query: () => {
        reqSummary();
        reqLine();
      },
    }));

    const orderOptions = useMemo(() => {
      return [
        {
          label: "按起飞日期",
          value: "takeoffdate",
        },
        {
          label: "按班期排序",
          value: "schedule",
        },
      ];
    }, []);
    const [schedule, setSchedule] = useState<number[]>(
      Array.from({ length: 7 }, (a, v) => v)
    );
    const [order, setOrder] = useState<"takeoffdate" | "schedule">(
      "takeoffdate"
    );
    // #endregion

    const filteredSummary = useMemo(() => {
      let rst = cloneDeep(summaryData);
      if (order === "schedule" || schedule.length !== 7) {
        const tmp: Array<SummaryDetail & { schedule: number }> = rst.map(
          (r) => ({
            ...r,
            schedule: moment(r.takeoffdate).weekday(),
          })
        );
        const filtered =
          schedule.length === 7
            ? tmp
            : tmp.filter((d) => schedule.includes(d.schedule));
        if (order === "schedule") {
          filtered.sort((a, b) => (a.schedule < b.schedule ? -1 : 1));
        }
        rst = filtered;
      }
      return rst.slice(0, 20);
    }, [order, schedule, summaryData]);

    const handleCardClick = useRefFunc((cardItem: SummaryDetail) => {
      const item: SelectedFlight = {
        airline: cardItem.flightno.substring(0, 2),
        flightNo: cardItem.flightno,
        route: cardItem.route,
        takeoffDate: cardItem.takeoffdate,
      };
      onCardClick(item);
    });

    if (isLoading || isLoadingLine) {
      return <Spin />;
    }

    if (!params.flights.length && !params.routes.length) {
      return <Empty description="请选择航班或者航线" />;
    }

    if (!filteredSummary.length) {
      return <Empty description="结果为空, 请更换查询条件" />;
    }

    return (
      <div>
        <div
          className="filter"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ marginBottom: 10 }}>
            <span>班期: </span>
            <CheckButtons
              options={WEEK_OPTIONS}
              value={schedule}
              onChange={(v) => setSchedule(v as number[])}
            />
            <Select
              size="small"
              options={orderOptions}
              value={order}
              onChange={setOrder}
              style={{ minWidth: 150, marginLeft: 20 }}
              placeholder="搜索起飞日期"
            />
          </div>
        </div>
        <Row gutter={[10, 10]}>
          {filteredSummary &&
            filteredSummary.map((item) => {
              const key = `${item.takeoffdate}_${item.flightno}_${item.route}`;
              const lineData = lineResponse.filter(
                (l) =>
                  l.takeoffdate === item.takeoffdate &&
                  l.flightno === item.flightno &&
                  l.route === item.route
              );
              const d: DetailCardDataItem = {
                takeoffdate: item.takeoffdate,
                flightno: item.flightno,
                route: item.route,
                income: {
                  value: item.SUM_acc_revenue,
                  yoy: item.yoy_grate_SUM_acc_revenue,
                  wow: item.wow_grate_SUM_acc_revenue,
                  trend: lineData.map((l) => ({
                    datachange_lasttime: l.datachange_lasttime,
                    value: l.SUM_acc_revenue,
                  })),
                },
                loadFactor: {
                  value: item.m_exp_load_factor,
                  yoy: item.yoy_grate_m_exp_load_factor,
                  wow: item.wow_grate_m_exp_load_factor,
                  trend: lineData.map((l) => ({
                    datachange_lasttime: l.datachange_lasttime,
                    value: l.m_exp_load_factor,
                  })),
                },
                avgPrice: {
                  value: item.m_exp_avg_price,
                  yoy: item.yoy_grate_m_exp_avg_price,
                  wow: item.wow_grate_m_exp_avg_price,
                  trend: lineData.map((l) => ({
                    datachange_lasttime: l.datachange_lasttime,
                    value: l.m_exp_avg_price,
                  })),
                },
              };
              return (
                <Col xxl={6} xl={8} key={key}>
                  <DetailCardItem
                    data={d}
                    updateTime={item.datachange_lasttime}
                    onTitleClick={() => handleCardClick(item)}
                  />
                </Col>
              );
            })}
        </Row>
      </div>
    );
  }
);
DetailCards.displayName = "DetailCards";
export default DetailCards;
