import React, { forwardRef } from "react";
interface Props {
  forwardedRef: any;
}

const withRouterAndRef = (
  Component: any
): React.ForwardRefExoticComponent<
  Pick<any, string | number | symbol> & React.RefAttributes<unknown>
> => {
  const WithRouter = ({ forwardedRef, ...props }: Props) => (
    <Component ref={forwardedRef} {...props} />
  );

  return forwardRef((props: any, ref: any) => (
    <WithRouter {...props} forwardedRef={ref} />
  ));
};

export default withRouterAndRef;
