import { FlightsSeasonPlanFilter } from "@ctrip/flt-bi-flightai-airlines";
import React, { ReactElement, useState } from "react";
import SearchArea from "./SearchArea";
import TablePortal from "./TablePortal";
import { AirlinesQueryCondition } from "Interface";
import useRefFunc from "Utils/useRefFunc";

/**
 * 航班时刻站点资源tab页
 */
const FlightsSeasonPlanTable = (): ReactElement => {
  const [filter, setFilter] = useState<FlightsSeasonPlanFilter>();
  const [query, setQuery] = useState<Partial<AirlinesQueryCondition>>();
  const handleQueryChange = useRefFunc((v: Partial<AirlinesQueryCondition>) => {
    setQuery((q) => ({ ...q, ...v }));
  });
  return (
    <div>
      <SearchArea onChange={setFilter} onChangeQuery={handleQueryChange} />
      <TablePortal filter={filter} query={query} />
    </div>
  );
};

export default FlightsSeasonPlanTable;
