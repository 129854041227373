import React from "react";
import { ReactComponent as IconSummary1 } from "assets/svg/icon-summary1.svg";
import { ReactComponent as IconSummary2 } from "assets/svg/icon-summary2.svg";
import { ReactComponent as IconSummary3 } from "assets/svg/icon-summary3.svg";
import { ReactComponent as HomeIcon1 } from "assets/svg/home-icon-1.svg";
import { ReactComponent as HomeIcon2 } from "assets/svg/home-icon-2.svg";
import { ReactComponent as HomeIcon3 } from "assets/svg/home-icon-3.svg";
import { ReactComponent as HomeIcon4 } from "assets/svg/home-icon-4.svg";
import { ReactComponent as HomeIcon5 } from "assets/svg/home-icon-5.svg";
import { ReactComponent as HomeIcon6 } from "assets/svg/home-icon-6.svg";
import { ReactComponent as HomeIcon7 } from "assets/svg/home-icon-7.svg";
import { ReactComponent as HomeIcon8 } from "assets/svg/home-icon-8.svg";
import { ReactComponent as HomeIcon9 } from "assets/svg/home-icon-9.svg";
import { ReactComponent as HomeIcon10 } from "assets/svg/home-icon-10.svg";
import { ReactComponent as HomeIcon11 } from "assets/svg/home-icon-11.svg";
import { ReactComponent as HomeIcon12 } from "assets/svg/home-icon-12.svg";
import { ReactComponent as HomeIcon13 } from "assets/svg/home-icon-13.svg";
import { ReactComponent as HomeIcon14 } from "assets/svg/home-icon-14.svg";
import Button from "Components/Official/Button";
import Partner from "Components/Official/Partner";
import ContactUs from "Components/Official/ContactUs";
import Side from "Components/Official/Side";
import "./index.scss";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="home-banner">
        <div className="home-banner-con">
          <h1>
            携程旗下品牌
            <br />
            专注航空业大数据
          </h1>
          <h4>市场洞察、行业咨询、智能解决方案提供者</h4>
        </div>
      </div>
      <div className="wrap">
        <ul className="home-summary">
          <li>
            <IconSummary2 className="icon-summary" />
            <h4>优质的数据来源</h4>
            <p>
              数据维度广（含OTA独家数据）、历史数据更丰富、数据处理逻辑成熟、数据实用性强。
            </p>
          </li>
          <li>
            <IconSummary3 className="icon-summary" />
            <h4>专业的技术团队</h4>
            <p>
              依托成熟稳定的数据平台技术，基于强大的数据分析和挖掘建模能力，充分发挥数据价值。
            </p>
          </li>
          <li>
            <IconSummary1 className="icon-summary" />
            <h4>丰富的行业经验</h4>
            <p>
              丰富的行业经验和行业政策法规认知，丰富的成功项目经验，更加可靠、更值得信赖。
            </p>
          </li>
        </ul>

        <div className="home-mod">
          <h2 className="mod-title">市场洞察平台</h2>
          <h5 className="hint">
            基于携程机票大数据，打造数据可视化自助分析平台
          </h5>
          <div className="mod-bd clearfix">
            <div className="mod-l">
              <div className="home-list">
                <HomeIcon1 className="home-icon" />
                <h5>海量数据</h5>
                <p className="paragraph">
                  具备高时效性、高参考性的海量数据，T+1即可自助获取丰富的数据维度和指标
                </p>
              </div>
              <div className="home-list">
                <HomeIcon2 className="home-icon" />
                <h5>多维分析</h5>
                <p className="paragraph">
                  满足日常监测和专题分析需求，洞察行业数据，发掘潜在机会
                </p>
              </div>
              <div className="home-list">
                <HomeIcon3 className="home-icon" />
                <h5>直观呈现</h5>
                <p className="paragraph">
                  平台丰富的可视化效果，使数据结论更加直观清晰
                </p>
              </div>
              <div className="home-list">
                <HomeIcon4 className="home-icon" />
                <h5>赋能行业</h5>
                <p className="paragraph">
                  与航司、机场内外部环境相结合，优化运营策略，挖掘商业机会
                </p>
              </div>
              <Button onClick={() => navigate("/MarketInsight")}>
                了解更多
              </Button>
            </div>
            <div className="mod-r mod-bg1"></div>
          </div>
        </div>

        <div className="home-mod">
          <h2 className="mod-title">行业咨询</h2>
          <h5 className="hint">
            利用携程大数据，在协助制订航线航班开发、培育、引流、预测等方面提供强力支持
          </h5>
          <div className="mod-bd clearfix">
            <div className="mod-l">
              <div className="home-list">
                <HomeIcon5 className="home-icon" />
                <h5>定期分析报告</h5>
                <p className="paragraph">
                  帮助全方位了解行业整体趋势、自身发展现状，从而做出最优决策。
                </p>
              </div>
              <div className="home-list">
                <HomeIcon6 className="home-icon" />
                <h5>航线网络规划</h5>
                <p className="paragraph">
                  通过多维分析分析对比，提供航线网络的现状分析与未来展望。
                </p>
              </div>
              <div className="home-list">
                <HomeIcon7 className="home-icon" />
                <h5>新开航线分析</h5>
                <p className="paragraph">
                  针对票价、运力投放、旅客来源等角度对开航可行性提出建议。
                </p>
              </div>
              <div className="home-list">
                <HomeIcon8 className="home-icon" />
                <h5>客源构成分析</h5>
                <p className="paragraph">
                  挖掘旅客各项特征，帮助了解客源构成，评估潜在增长点。
                </p>
              </div>
              <Button onClick={() => navigate("/Consulting")}>了解更多</Button>
            </div>
            <div className="mod-r mod-bg2" />
          </div>
        </div>

        <div className="home-mod">
          <h2 className="mod-title">收益管理</h2>
          <h5 className="hint">
            通过模型输出航班管理建议， 更科学更高效，实现收益最大化。
          </h5>
          <div className="mod-bd clearfix">
            <div className="mod-l">
              <div className="clearfix">
                <div className="home-icon-list">
                  <HomeIcon9 className="home-icon" />
                  <h4>用户需求预测</h4>
                </div>
                <div className="home-icon-list">
                  <HomeIcon10 className="home-icon" />
                  <h4>对手策略预测</h4>
                </div>
                <div className="home-icon-list">
                  <HomeIcon11 className="home-icon" />
                  <h4>用户行为模拟</h4>
                </div>
                <div className="home-icon-list">
                  <HomeIcon12 className="home-icon" />
                  <h4>价格趋势预测</h4>
                </div>
                <div className="home-icon-list">
                  <HomeIcon13 className="home-icon" />
                  <h4>动态价格建议</h4>
                </div>
                <div className="home-icon-list">
                  <HomeIcon14 className="home-icon" />
                  <h4>航班选择模型</h4>
                </div>
              </div>
              <Button
                style={{ marignLeft: "28px" }}
                onClick={() => navigate("/RevenueManagement")}
              >
                了解更多
              </Button>
            </div>
            <div className="mod-r mod-bg3" />
          </div>
        </div>
      </div>
      <ContactUs />
      <Partner />
      <Side />
    </>
  );
};

export default Home;
