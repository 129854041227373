import React, { ReactElement } from "react";
import { ResData } from "./CardContainer";
import { Card, Divider, Empty, Popover } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

const CARD_TITLE = getSharkText("config_page_accumulated_income");

export type OverviewRes = Pick<ResData, "SUM_revenue" | "collectTime">;
export interface CardItemSummaryProps {
  data: OverviewRes | undefined;
}

/** 航班管理弹窗-卡片-累计 */
const CardItemSummary = (props: CardItemSummaryProps): ReactElement => {
  const { data } = props;

  if (!data) {
    return <Empty />;
  }

  return (
    <Card
      id={"flightmanage-popup-card-accumulated"}
      size="small"
      bordered={false}
      className={`card cur`}
      hoverable={true}
      style={{ height: 165 }}
    >
      <div className="card-title">
        <div className="title">{CARD_TITLE}</div>
        <div>
          <Popover
            content={
              <span>累计收入：根据真实收入数据结合订座采集增量估算</span>
            }
          >
            <InfoCircleOutlined />
          </Popover>
        </div>
      </div>
      <div style={{ color: "#999" }}>更新时间: {data.collectTime}</div>
      <div style={{ fontSize: 24, height: 62 }}>
        {showNum(data.SUM_revenue, "num")}
      </div>
      <Divider style={{ margin: "6px 0" }} />
    </Card>
  );
};
export default CardItemSummary;
