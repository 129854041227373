import { Alert, Checkbox, Col, Row, Tabs } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import BarLineChartsV2 from "Components/BarLineChartsV2";
import UpdateTime from "Components/UpdateTime";
import { DATE_MINUTE_FORMAT } from "Constants";
import { ISeries, SystemType } from "Interface";
import { cloneDeep, round } from "lodash";
import moment from "moment";
import React, { ReactElement, useMemo, useState } from "react";

import useGlobalState from "Store";
import { durationFormatter, isNumeric, showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { enumKeys } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import {
  EPanelDays,
  EPanelName,
  EPanelType,
  EStatus,
  ILoadDataType,
} from "../MonitoringAlertingCommon";
import SearchArea from "./SearchArea";
import TablePortal from "./TablePortal";

const { TabPane } = Tabs;

const curTrans = (data: any) => {
  return data.map((d: any) => {
    return {
      ...d,
      // day: moment(d.day).format(DATE_FORMAT),
      avgDiff: d.value - d.avgValue,
    };
  });
};

const compareTrans = (data: any) => {
  return data.map((d: any) => {
    return {
      ...d,
      // day: moment(d.day).format(DATE_FORMAT),
      compareDiff: d.value - d.compareValue,
    };
  });
};

const getXAxisData = (data: any) => {
  let max: number | null = null;
  const min = 0;
  if (data && data.length) {
    const dp = cloneDeep(data);
    const tmp = dp.filter((d: any) => isNumeric(d.diffTime));
    if (tmp.length) {
      tmp.sort((a: any, b: any) => (a.diffTime < b.diffTime ? -1 : 1));
      max = tmp[tmp.length - 1].diffTime;
    }
  }

  // Echarts的坑, 如果axis的data值为数值类型, 则会被识别为索引, 无法与数据中的坐标值进行匹配. 所以写作字符串
  if (data) {
    data.forEach((d: any) => {
      if (isNumeric(d.diffTime)) {
        d.diffTime = `${d.diffTime}s`;
      }
    });
  }
  if (max !== null && min !== null) {
    return Array.from({ length: max - min }, (v, k) => {
      // Echarts的坑, 如果axis的data值为数值类型, 则会被识别为索引, 无法与数据中的坐标值进行匹配. 所以写作字符串
      return { value: max !== null ? `${max - k}s` : k };
    });
  }
  return [];
};

const chartConfig = {
  dataZoom: null,
  yAxis: [
    {},
    {
      splitLine: {
        show: true,
      },
    },
  ],
  xAxis: {
    type: "category",
    name: getSharkText("config_page_days_from_takeoff"),
    nameLocation: "start",
    dataFn: getXAxisData,
    nameTextStyle: {
      padding: [25, 25, 0, 0],
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      overflow: "truncate",
      interval: (index: number, value: string) => {
        const hours = round(parseFloat(value));
        // 仅显示整天的数据
        const tmp = hours % 24;
        if (tmp !== 0) {
          return false;
        }
        return true;
      },
      formatter: (val: string) => {
        const hours = round(parseInt(val, 10));
        return durationFormatter(hours * 3600, "h");
        // return moment(val).format(DATE_MINUTE_FORMAT);
      },
    },
  },
  tooltip: {
    trigger: "axis",
    formatter: (params: any) => {
      const day = params.length
        ? moment(params[0].data.day).format(DATE_MINUTE_FORMAT)
        : "";
      let dom = "";
      params.forEach((item: any) => {
        const dot = `<span class='dot' style='background:${item.color}'></span>`;
        const isPercentVal = true;
        const itemDim = item.dimensionNames[item.encode.y[0]];
        const itemValue = item.value[itemDim];
        const value = isPercentVal
          ? showNum(itemValue, "percentage")
          : showNum(itemValue, "num");
        const val = `${item.seriesName}: ${value}`;
        dom = dom + "<br />" + dot + val;
      });
      dom = day + dom;
      return dom;
    },
  },
};
/**
 * Component description
 * 客座率预警模块
 */
const LoadContent = (): ReactElement => {
  const [tableExt, setTableExt] = useState<Record<string, any>>();
  const [chartExt, setChartExt] = useState<Record<string, any> | undefined>();
  const statusKeys = enumKeys(EStatus);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([0]);
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const { airlines } = airlinesQueryCondition;

  const panelType = EPanelType.Load;
  const systemType = SystemType.airlines;

  const onStatusChange = (v: CheckboxValueType[]) => {
    setSelectedStatus(v as number[]);
  };

  const onRowClick = useRefFunc((r: ILoadDataType | null) => {
    if (r) {
      setChartExt({
        id: r.id,
        flightNo: r.flightno,
        takeoffDate: r.takeofftime,
        route: `${r.dport}${r.aport}`,
        ownerType: tableExt?.ownerType || 1,
      });
    } else {
      setChartExt(undefined);
    }
  });

  const curSeries: ISeries[] = useMemo(() => {
    return [
      {
        type: "line",
        name: `${airlines}${getSharkText("menu_load_factor")}`,
        isPercentVal: true,
        yAxisIndex: 1,
        encode: {
          x: "diffTime",
          y: "value",
        },
      },
      {
        type: "line",
        name: `前四周${airlines}${getSharkText("menu_load_factor")}`,
        isPercentVal: true,
        yAxisIndex: 1,
        encode: {
          x: "diffTime",
          y: "avgValue",
        },
      },
      {
        type: "bar",
        name: getSharkText("config_page_difference"),
        isPercentVal: true,
        yAxisIndex: 1,
        encode: {
          x: "diffTime",
          y: "avgDiff",
        },
      },
    ];
  }, [airlines]);
  const compareSeries: ISeries[] = useMemo(() => {
    return [
      {
        type: "line",
        name: `${airlines}${getSharkText("menu_load_factor")}`,
        isPercentVal: true,
        yAxisIndex: 1,
        encode: {
          x: "diffTime",
          y: "value",
        },
      },
      {
        type: "line",
        name: getSharkText("config_page_competing_flight_passenger_progress"),
        isPercentVal: true,
        yAxisIndex: 1,
        encode: {
          x: "diffTime",
          y: "compareValue",
        },
      },
      {
        type: "bar",
        name: getSharkText("config_page_difference"),
        isPercentVal: true,
        yAxisIndex: 1,
        encode: {
          x: "diffTime",
          y: "compareDiff",
        },
      },
    ] as ISeries[];
  }, [airlines]);
  return (
    <div className="content-white">
      {/* <Alert
        message={HelpInfo[enumKeys(EPanelType)[EPanelType.Load]]}
        banner
        type="info"
      /> */}
      <SearchArea
        panelType={panelType}
        onChange={setTableExt}
        style={{ padding: 0 }}
      />
      {chartExt && chartExt.id ? (
        <Tabs tabBarStyle={{ marginBottom: 0 }}>
          <TabPane key={0} tab="航司上客进度">
            <BarLineChartsV2
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].loadLineChart}
              queryUrl={QUERY_URL[systemType].loadLineChart}
              defaultSeries={curSeries}
              ext={chartExt}
              dataTrans={curTrans}
              chartConfig={chartConfig}
              useGranularity={false}
              height={300}
            />
          </TabPane>
          <TabPane key={1} tab="竞飞上客进度">
            <BarLineChartsV2
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].loadLineChart}
              queryUrl={QUERY_URL[systemType].loadLineChart}
              defaultSeries={compareSeries}
              ext={chartExt}
              dataTrans={compareTrans}
              chartConfig={chartConfig}
              useGranularity={false}
              height={300}
            />
          </TabPane>
        </Tabs>
      ) : undefined}
      <Row
        style={{ margin: "10px 0 10px", alignItems: "center" }}
        gutter={[20, 20]}
      >
        <Col>
          <UpdateTime
            moduleCode={MODULE_CODE[2]}
            chartTableCode="none"
            text={
              <Alert
                banner
                message={`提前${
                  EPanelDays[enumKeys(EPanelType)[panelType]]
                }天监控航线${EPanelName[panelType]}指数，及时掌握市场变化。`}
                type="info"
              />
            }
          />
        </Col>
        <Col>
          <Checkbox.Group value={selectedStatus} onChange={onStatusChange}>
            {statusKeys.map((key) => (
              <Checkbox key={key} value={EStatus[key]}>
                {key}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Col>
      </Row>
      <TablePortal<ILoadDataType>
        panelType={panelType}
        queryExt={tableExt}
        selectedStatus={selectedStatus}
        tableProps={{
          onRowClick,
        }}
      ></TablePortal>
    </div>
  );
};
export default LoadContent;
