import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Alert, Card, Col, Row } from "antd";
import Ranking from "Components/Ranking";
import SearchTable, { TableHandler } from "./Components/SearchTable";
import { Area, FlightArea, QueryCondition } from "Interface";
import SearchTrendBarLineCharts from "./Components/SearchTrendsBarLineCharts";
import UpdateTime from "Components/UpdateTime";
import Page from "Layout/Page";
import useGlobal from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { getDateRange, isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { DATE_FORMAT, DEFAULT_AREA } from "Constants";
import moment from "moment";
import { useBatchEffect } from "Utils/useBatchEffect";
import { QueryExt } from "./SearchInterface";
import { AxisType } from "./Common";
import useRefFunc from "Utils/useRefFunc";
import AirportSearch from "./Components/AirportSearch";
import { getDefaultAirport } from "Utils/user";

const defaultAirlinesQueryExt: QueryExt = {
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
  range: {
    searchAlign: 1,
    axisReverse: 1,
    searchDateStart: null,
    searchDateEnd: moment().add(-1, "d").format(DATE_FORMAT),
    beforeStart: null,
    beforeEnd: null,
    queryType: 1,
  },
  market: ["CN"],
};

const rankTab = [
  {
    chartTableCode: "top10_city_compare",
    title: getSharkText("key.search_index.name"),
    titleTips: getSharkText("key.searchindex_explain.hint"),
    type: 0,
  },
  {
    chartTableCode: "top10_city_non_airport",
    title: getSharkText("key.not_navigable.name"),
    titleTips: getSharkText("key.no_available_seats.hint"),
    type: 1,
  },
];

interface SearchTrendsAirportProps {
  intl?: boolean;
}

const SearchTrendsAirport = (
  props: SearchTrendsAirportProps
): ReactElement<SearchTrendsAirportProps> => {
  const { intl } = props;
  const [globalState, actions] = useGlobal();
  const { setQueryConditionAttr } = actions;
  const { queryCondition, systemType, userInfo } = globalState;
  const { airport, airportName, departure, arrive } = queryCondition;
  const [loading, setLoading] = useState(false);
  // 机场版选中城市
  const [area, setArea] = useState<Area>(DEFAULT_AREA);
  /// 航司版选中城市
  const [queryExt, setQueryExt] = useState<QueryExt>(defaultAirlinesQueryExt);
  /** 页面过滤器是否加载完成 */
  const [pageFilterLoaded, setPageFilterLoaded] = useState<boolean>(false);
  // 选中排行榜城市
  const [selectCity, setSelectCity] = useState<FlightArea | null>(null);
  const [hasCityData, setHasCityData] = useState(false);
  const [chartsType, setChartsType] = useState(0);
  const [axis, setAxis] = useState<AxisType>(AxisType.takeoff);
  const isAirport = isAirportMode(systemType);
  const airportArea = { areaCode: airport, areaName: airportName, areaType: 5 };
  const departArea = useMemo(() => {
    return selectCity
      ? selectCity.departArea
      : isAirport
      ? airportArea
      : queryExt.departArea;
  }, [selectCity, isAirport, queryExt]);
  const arriveArea = useMemo(() => {
    return selectCity
      ? selectCity.arriveArea
      : isAirport
      ? area
      : queryExt.arriveArea;
  }, [selectCity, isAirport, area, queryExt]);

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setArea(DEFAULT_AREA);
    // setQueryExt(defaultAirlinesQueryExt);
    setSelectCity(null);
    setTimeout(() => setLoading(false));
  }, [queryCondition.flightClass]);

  useEffect(() => {
    if (!departure && !arrive) {
      setArea(DEFAULT_AREA);
    }
  }, [departure, arrive]);

  useEffect(() => {
    setSelectCity(null);
  }, [area]);

  const alertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].top10}
      text={
        isAirport
          ? getSharkText("key.search_demonstration_airport.hint")
          : getSharkText("key.search_demonstration_airline.hint")
      }
    />
  );

  const defaultTkDateRange = getDateRange("ninetyDays");

  const handleSubmit = useRefFunc((v: QueryExt, a: Area) => {
    setQueryExt(v);
    setArea(a);
    if (!pageFilterLoaded) {
      setPageFilterLoaded(true);
    }
  });
  // #region 设置搜索日期起飞日期默认值等

  const tableRef = useRef<TableHandler>(null);

  useBatchEffect(() => {
    const tmpInit: Partial<QueryCondition> = {
      startDate: defaultTkDateRange
        ? defaultTkDateRange[0].format(DATE_FORMAT)
        : "",
      endDate: defaultTkDateRange
        ? defaultTkDateRange[1].format(DATE_FORMAT)
        : "",
    };
    if (!queryCondition.airport) {
      const userDefaultAirport = getDefaultAirport(userInfo);
      tmpInit.airport = userDefaultAirport?.code || "";
      tmpInit.airportName = userDefaultAirport?.name || "";
    }
    setQueryConditionAttr(tmpInit);
  }, []);
  // #endregion

  const onBarlineSuccess = useRefFunc(() => {
    if (tableRef.current) {
      tableRef.current.query();
    }
  });

  return (
    <Page
      needToolBar={false}
      defaultDateMode="none"
      needGlobalConditionCheck={false}
    >
      <div className="content-white">
        <AirportSearch onChange={handleSubmit} axis={axis} intl={intl} />
      </div>
      {loading || !pageFilterLoaded ? undefined : (
        <>
          <div className="content-white">
            <Alert message={alertMessage} banner type="info" />
            <Row
              gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
              style={{ marginTop: 10 }}
            >
              <Col xxl={intl ? 24 : 16} xl={intl ? 24 : 16} md={24} xs={24}>
                <SearchTrendBarLineCharts
                  queryUrl={QUERY_URL[systemType].barLineCharts}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode={CHART_TABLE_CODE[systemType].barLineCharts}
                  departArea={departArea}
                  arriveArea={arriveArea}
                  type={chartsType}
                  axis={axis}
                  onAxisChange={setAxis}
                  ext={queryExt}
                  onSuccess={onBarlineSuccess}
                />
              </Col>
              <Col xxl={intl ? 24 : 8} xl={intl ? 24 : 8} md={24} xs={24}>
                <Ranking
                  title={getSharkText("key.top_10_routes_searched.text")}
                  queryUrl={QUERY_URL[systemType].top10}
                  moduleCode={MODULE_CODE[systemType]}
                  tab={rankTab}
                  area={area}
                  sortable
                  selectItem={setSelectCity}
                  setChartsType={setChartsType}
                  ext={queryExt}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col>
                <Card>
                  <SearchTable
                    ref={tableRef}
                    queryUrl={QUERY_URL[systemType].table}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].table}
                    area={area}
                    airlinesSearchTrendsExt={queryExt}
                    ext={queryExt}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Page>
  );
};

export default SearchTrendsAirport;
