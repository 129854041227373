import { Col, DatePicker, Radio, Row } from "antd";
import { DATE_FORMAT } from "Constants";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import { getServer } from "Service/server";
import { ETravixValueType } from "../TravixCommon";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../TravixFetchCode";
import PieCharts from "./PieCharts";

const { RangePicker } = DatePicker;
/**
 * Component description
 *
 */

interface IProps {
  marketType?: number;
  country?: string;
  panelType: "overview" | "salesData";
}

const ChannelType = (props: IProps): ReactElement => {
  const { marketType = 0, panelType, country } = props;

  const defaultRange: [Moment, Moment] = [
    moment("2021-01-01"),
    moment().add(-1, "days"),
  ];

  const [queryExtTravix, setQueryExtTravix] = useState<Record<string, unknown>>(
    {}
  );
  const [queryExtTrip, setQueryExtTrip] = useState<Record<string, unknown>>({});
  const [range, setRange] = useState<Array<Moment | null>>(defaultRange);
  const [valueType, setValueType] = useState<number>(0);

  useEffect(() => {
    const tmp = {
      query: {
        startDate: range[0] ? range[0].format(DATE_FORMAT) : "",
        endDate: range[1] ? range[1].format(DATE_FORMAT) : "",
        compareType: 0,
        partner: "travix",
        marketType,
      },
      dimensionType: 0,
      valueType,
      country,
    };
    setQueryExtTravix(tmp);
    const tripTmp = cloneDeep(tmp);
    tripTmp.dimensionType = 1;
    setQueryExtTrip(tripTmp);
  }, [range, valueType, marketType, country]);

  const changeDateRange = (rangeDate: Array<Moment | null> | null) => {
    if (!rangeDate) {
      return;
    }
    setRange(rangeDate);
  };

  return (
    <div>
      <Row>
        <Col>
          <h2 style={{ color: "#1890FF" }}>ChannelType Distribution</h2>
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{ marginTop: 10 }}>
        <Col>
          <RangePicker defaultValue={defaultRange} onChange={changeDateRange} />
        </Col>
        <Col>
          <Radio.Group
            value={valueType}
            onChange={(e) => setValueType(e.target.value)}
          >
            <Radio value={0}>Seg</Radio>
            <Radio value={1}>GMV</Radio>
          </Radio.Group>
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={24}>
          <PieCharts
            server={getServer(4)}
            queryUrl={QUERY_URL.pie}
            moduleCode={MODULE_CODE}
            chartTableCode={CHART_TABLE_CODE[panelType].Channel}
            ext={queryExtTravix}
            chartTitle={`Travix ${ETravixValueType[valueType + 1]}`}
          />
        </Col>
        <Col xl={12} lg={24}>
          <PieCharts
            server={getServer(4)}
            queryUrl={QUERY_URL.pie}
            moduleCode={MODULE_CODE}
            chartTableCode={CHART_TABLE_CODE[panelType].Channel}
            ext={queryExtTrip}
            chartTitle={`Trip ${ETravixValueType[valueType + 1]}`}
          />
        </Col>
      </Row>
    </div>
  );
};
export default ChannelType;
