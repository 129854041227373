import React, { useState, useEffect, ReactElement } from "react";
import { Table, Modal, Button, Empty } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { Modulelog, ModuleStatus } from "Interface";
import { MODULE } from "Constants";
import moment from "moment";
import _ from "lodash";

interface Iprops {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  unitId: number;
  moduleId: number;
}

const ModuleHistory = (props: Iprops): ReactElement => {
  const { visible, setVisible, unitId, moduleId } = props;
  const [logList, setLogList] = useState<Modulelog[]>([]);
  const [{ isLoading, error, res }, doFetch] = useFetch({
    url: "findModuleAuthorityLog",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      groupId: unitId,
      moduleId,
    },
    lazey: true,
  });

  useEffect(() => {
    if (visible) {
      doFetch({
        ext: {
          groupId: unitId,
          moduleId,
        },
      });
    }
  }, [visible]);

  useEffect(() => {
    if (res && res.logList) {
      setLogList(res.logList);
    }
  }, [res]);

  if (error) {
    return <Empty />;
  }

  const columns = [
    {
      title: getSharkText("config_page_modifier"),
      dataIndex: "user",
      key: "user",
    },
    {
      title: getSharkText("config_page_status"),
      dataIndex: "moduleStatus",
      key: "moduleStatus",
      render: (value: ModuleStatus) => MODULE[value],
    },
    {
      title: "有效期",
      key: "time",
      width: 300,
      render: (value: any, record: Modulelog) =>
        `${moment(record.beginTime).format("YYYY-MM-DD")} - ${moment(
          record.endTime
        ).format("YYYY-MM-DD")}`,
    },
    {
      title: "备注",
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: "修改时间",
      dataIndex: "changeDate",
      key: "changeDate",
      render: (value: string) => `${moment(value).format("YYYY-MM-DD")}`,
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        moment(a.changeDate).isBefore(moment(b.changeDate)) ? -1 : 1,
    },
  ];

  return (
    <Modal
      width={900}
      title={`${_.isEmpty(logList) ? "" : logList[0].moduleName}${getSharkText(
        "config_page_history"
      )}`}
      onCancel={() => setVisible(false)}
      open={visible}
      footer={[
        <Button key={1} onClick={() => setVisible(false)}>
          {getSharkText("key.close.button")}
        </Button>,
      ]}
    >
      <Table
        showSorterTooltip={false}
        loading={isLoading}
        size="small"
        tableLayout="fixed"
        dataSource={logList}
        // @ts-ignore
        columns={columns}
        rowKey="id"
      />
    </Modal>
  );
};

export default ModuleHistory;
