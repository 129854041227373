import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
// import { Dimension, Filter, FilterValue, Measure, Sorter } from "../interface";
import { genRequest } from "./genRequest";
import AesUtil, { AESPWD_PREFIX } from "Utils/AESUtil";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import {
  Dimension,
  Filter,
  FilterValue,
  Measure,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { getEnv } from "Utils/global";
import {
  StandardComparator,
  StandardComparison,
  StandardFilter,
  StandardMatrixRequest,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";

const env = getEnv();

interface Props {
  datasetId: number;
  columns: FDDatasetCol[];
  dimensions: Array<Dimension | string>;
  measures: Array<Measure | string>;
  chartFilters: Filter[];
  containerFilters: FilterValue[];
  sorters: Array<Sorter | string>;
  oriFilters?: StandardFilter[];
  limit?: number;
  granularity?: string;
  comparators?: StandardComparator[];
  preserveComparatorGroups?: boolean;
  postFilter?: StandardFilter[];
  isGenComment?: boolean;
  timeZone?: string;
}

export default class RequestBuilder {
  private datasetId;
  private columns;
  private dimensions: Dimension[];
  private measures: Measure[];
  /** 图表组件的过滤条件 */
  private chartFilters;
  /** 图表容器的过滤条件, 多层嵌套时合并为一个数组 */
  private containerFilters;
  private sorters: Sorter[];
  private oriFilters;
  private limit;
  private granularity;
  /** 时区字符串, 使用ISO8601标准时区表示法 */
  private _timeZone = "+08:00";

  public get timeZone(): string {
    console.log("get timeZone");
    return this._timeZone;
  }

  public set timeZone(value: string) {
    this._timeZone = value;
  }

  private comparators;
  private preserveComparatorGroups;
  private _postFilter;
  private isGenComment;

  public postFilter = (
    value?: StandardFilter[]
  ): StandardFilter[] | undefined => {
    if (value === undefined) return this._postFilter;
    this._postFilter = value;
  };

  public getDatasetId = () => {
    return this.datasetId;
  };

  public getColumns = () => {
    return this.columns;
  };

  public setDimensions = (v: Dimension[]): void => {
    this.dimensions = v;
  };

  public getDimensions = () => {
    return this.dimensions;
  };

  public getMeasures = () => {
    return this.measures;
  };

  public getChartFilters = () => {
    return this.chartFilters;
  };

  public getContainerFilters = () => {
    return this.containerFilters;
  };

  public getSorters = () => {
    return this.sorters;
  };

  public getOriFilters = () => {
    return this.oriFilters;
  };

  public getLimit = () => {
    return this.limit;
  };

  public setLimit = (v: number) => {
    this.limit = v;
  };

  public getGranularity = () => {
    return this.granularity;
  };

  public getComparators = () => {
    return this.comparators;
  };

  public getPreserveComparatorGroups = () => {
    return this.preserveComparatorGroups;
  };

  public needComment = () => {
    return this.isGenComment;
  };

  private buildDimensions = (
    propDims: Array<Dimension | string>
  ): Dimension[] => {
    return propDims.map((dim) => {
      if (typeof dim === "string") {
        const tmp: Dimension = {
          columnName: dim,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        };
        return tmp;
      }
      return dim;
    });
  };

  private static buildMeasures = (
    propMeasures: Array<Measure | string>
  ): Measure[] => {
    return propMeasures.map((measure) => {
      if (typeof measure === "string") {
        const tmp: Measure = {
          id: measure,
          columnName: measure,
          measureConfig: {
            statisticalConfig: { method: "SUM" },
            formatConfig: null,
            comparison: null,
          },
        };
        return tmp;
      }
      return measure;
    });
  };

  public static getMeasureMeta = (
    name: Measure | string,
    comparison?: StandardComparison
  ): string => {
    const mea = this.buildMeasures([name])[0];
    const method = mea.measureConfig.statisticalConfig.method;
    const comName = comparison?.comparisonName;
    const comMean = comparison?.mean;
    let comNameStr = "";
    let comMeanStr = "";
    if (comName) {
      comNameStr = `${comName}_`;
      comMeanStr = `${comMean}_`;
    }
    return `${comNameStr}${comMeanStr}${method}_${mea.columnName}`;
  };

  private buildSorters = (
    propSorters: Array<Sorter | string>,
    measures: Measure[]
  ): Sorter[] => {
    return propSorters.map((sorter) => {
      if (typeof sorter === "string") {
        const mea = measures.find((m) => m.columnName === sorter);
        const tmp: Sorter = {
          chartUsedColId: sorter,
          columnName: sorter,
          sorter: mea ? "DESC" : "ASC",
          statistical: mea
            ? mea.measureConfig.statisticalConfig.method
            : "COUNT",
        };
        return tmp;
      }
      return sorter;
    });
  };

  constructor(props: Props) {
    this.datasetId = props.datasetId;
    this.columns = props.columns;
    this.dimensions = this.buildDimensions(props.dimensions);
    this.measures = RequestBuilder.buildMeasures(props.measures);
    this.chartFilters = props.chartFilters;
    this.containerFilters = props.containerFilters;
    this.sorters = this.buildSorters(props.sorters, this.measures);
    this.oriFilters = props.oriFilters || [];
    this.limit = props.limit || 20000;
    this.granularity = props.granularity;
    this.comparators = props.comparators;
    this.preserveComparatorGroups = props.preserveComparatorGroups;
    this._postFilter = props.postFilter;
    this.isGenComment = props.isGenComment;
    this.timeZone = props.timeZone || "+08:00";
  }

  public appendFilters = (filters: StandardFilter[]): RequestBuilder => {
    this.oriFilters = this.oriFilters.concat(filters);
    return this;
  };

  public getRequestBody = (): {
    requestBody: StandardMatrixRequest | null;
    encrypted: string | null;
  } => {
    if (!this.dimensions.length && !this.measures.length) {
      return { requestBody: null, encrypted: null };
    }
    const param = genRequest(this);
    const aes = new AesUtil();
    const pwd = AESPWD_PREFIX + moment().format(DATE_FORMAT);
    const encrypted = aes.encrypt(JSON.stringify(param), pwd);
    console.log(`original request: ${encrypted.slice(-16)}`, param);
    return {
      requestBody: param,
      encrypted: env === "pro" ? encrypted : JSON.stringify(param),
    };
  };
}
