export const MODULE_CODE: any = {
  1: "flight_plan_airport",
  2: "flight_plan_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    lineCharts: "plan_cancel_new",
    top10: "top10_city_cancel_new",
    table: "cancel_new_table",
  },
  2: {
    lineCharts: "AL0401",
    top10: "AL0402",
    table: "AL0403",
    execute: "AL0405",
  },
};

export const QUERY_URL: any = {
  1: {
    lineCharts: "queryOnsaleFlightsBarLine",
    top10: "queryOnsaleFlightsTop10City",
    table: "queryOnsaleFlightsTable",
    top10Table: "queryOnsaleFlightsAirportExecutionTable",
  },
  2: {
    lineCharts: "queryAirlinesOnsaleFlightsBarLine",
    top10: "queryAirlinesOnsaleFlightsTop10City",
    table: "queryAirlinesOnsaleFlightsTable",
    execute: "queryAirlinesOnsaleFlightsExecute",
  },
};
