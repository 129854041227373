// Created by xh_zhu on 2021-03-11

import { Card, Checkbox, Col, Radio, Row, Spin } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Refetch from "Components/Refetch";
import moment, { Moment } from "moment";
import {
  AirlinesQueryCondition,
  BarLineData,
  IDateMode,
  IDownloadHeader,
  QueryCondition,
} from "Interface";
import _, { isEmpty, merge, round } from "lodash";
import {
  genSeriesByDimensions,
  isAirportMode,
  listMap2dataSet,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { DATE_FORMAT } from "Constants";

import { ICard } from "../../Interface";
import DateRangePicker from "../DateRangePicker";
import EchartsReactBase from "Components/EchartsReactBase";

/**
 * Component description
 * 执行率模块, 包含模块名称, 可选择对比周期, 一个趋势图
 */

interface ISaleChartsProps {
  title: string;
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: object;
  query: AirlinesQueryCondition | QueryCondition;
  ext?: any;
  card: ICard;
  panelType: number;
  compareType: number;
  downloadColumns?: IDownloadHeader[];
  chartsOption?: any;
  onCompareChange?: (v: number) => void;
}

const SaleCharts = (props: ISaleChartsProps): ReactElement => {
  const { title } = props;
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    style,
    query,
    ext: otherExt,
    chartsOption,
    onCompareChange,
  } = props;
  const [globalState] = useGlobalState();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [, setBarData] = useState([]);
  const isAirport = isAirportMode(systemType);
  const [compareType, setCompareType] = useState<number>(4);
  const [compareTypeIdx, setCompareTypeIdx] = useState<number[]>([4]);
  const [aggCode, setAggCode] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>(
    moment(new Date()).format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState<string>(
    moment(new Date()).format(DATE_FORMAT)
  );
  const ext = useMemo(
    () => merge({}, { aggCode }, otherExt),
    [aggCode, otherExt]
  );
  const [dateMode] = useState<IDateMode>("currentDay");
  const [range, setRange] = useState<Moment[]>([]);
  const pickerRef = useRef<any>();
  const targetName = isAirport
    ? queryCondition.airportName
    : airlinesQueryCondition.airlinesName;
  const isZero = (val: number) => val === 0;
  const queryWithExt = useMemo(
    () => ({ ...query, compareType, startDate, endDate }),
    [compareType, endDate, query, startDate]
  );
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    ext,
    lazey: true,
  });

  useEffect(() => {
    const dateStrArr = range.map((m: Moment) => m.format(DATE_FORMAT));
    setStartDate(dateStrArr[0]);
    setEndDate(dateStrArr[1]);
  }, [range]);

  const checkDate = useCallback(
    (day1: string, day2: string) => {
      const start = moment(day1);
      const end = moment(day2);
      const day = end.diff(start, "day");
      if (day >= 1 && day <= 3 && aggCode === 0) {
        setAggCode(1);
        return false;
      } else if (day > 3 && aggCode !== 3) {
        setAggCode(3);
        return false;
      }
      return true;
    },
    [aggCode]
  );

  const refetch = useCallback(() => {
    doFetch({
      query: {
        ...query,
        compareType,
        startDate,
        endDate,
      },
      ext: {
        ...ext,
        aggCode,
      },
    });
  }, [aggCode, compareType, doFetch, endDate, ext, query, startDate]);

  useEffect(() => {
    if (startDate && endDate && checkDate(startDate, endDate)) {
      refetch();
    }
  }, [checkDate, endDate, refetch, startDate]);

  useEffect(() => {
    let rst = 4;
    if (compareTypeIdx.length) {
      rst = compareTypeIdx[0];
    }
    setCompareType(rst);
    if (onCompareChange) {
      onCompareChange(rst);
    }
  }, [compareTypeIdx, onCompareChange]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: BarLineData) => {
        if (aggCode > 2) {
          item.day = moment(item.day).format("YYYY-MM-DD");
        } else {
          item.day = moment(item.day).format("YYYY-MM-DD HH:mm");
        }
        return item;
      });
      setBarData(compareData);
    } else {
      setBarData([]);
    }
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  // 只能单选或不选
  const handleCompareChange = (v: any[]) => {
    let tmp = compareTypeIdx;
    // 单选
    if (v.length === 2) {
      tmp = v.filter((n: number) => n !== compareType);
      setCompareTypeIdx(tmp);
    } else {
      setCompareTypeIdx(v);
    }
  };
  const totalSeries = [
    {
      type: "line",
      name: getSharkText("config_page_today_target_name_sales").replace(
        "{targetName}",
        targetName
      ),
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "day",
        y: "quantity",
      },
    },
    {
      type: "line",
      name: getSharkText("config_page_benchmark_target_name_sales").replace(
        "{targetName}",
        targetName
      ),
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "day",
        y: "compareQuantity",
      },
    },
    {
      type: "line",
      name: `今日${targetName}票价`,
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "day",
        y: "price",
      },
    },
    {
      type: "line",
      name: `对标${targetName}票价`,
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "day",
        y: "comparePrice",
      },
    },
  ];
  const dataSource = listMap2dataSet(data);
  const dimensions = dataSource.length ? dataSource[0] : [];
  const selectItem: any = {};
  selectItem[`今日${targetName}票价`] = false;
  selectItem[`对标${targetName}票价`] = false;
  const option = merge(
    {},
    {
      color: ["#FC9B4F", "#01C5DB", "#177DFE", "#82b1ff"],
      dataset: {
        dimensions,
        source: dataSource,
      },
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
        selected: selectItem,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const dayIndex = params[0].dimensionNames.indexOf("day");
          let day = "";
          if (aggCode > 2) {
            day = moment(params[0].data[dayIndex]).format("YYYY-MM-DD");
          } else {
            day = moment(params[0].data[dayIndex]).format("YYYY-MM-DD HH:mm");
          }
          let dom = "";
          params.forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const dimIndex =
              item.encode && item.encode.y ? item.encode.y[0] : 1;
            const val = `${item.seriesName}: ${
              !isZero(item.data[dimIndex]) ? round(item.data[dimIndex]) : "-"
            }`;

            dom = dom + "<br />" + dot + val;
          });
          dom = getSharkText("config_page_booking_date") + "：" + day + dom;
          if (isEmpty(dom)) {
            return null;
          }

          return dom;
        },
      },
      dataZoom: [
        {
          type: "inside",
        },
        {
          type: "slider",
          bottom: 0,
        },
      ],
      xAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
      },
      yAxis: [
        {
          type: "value",
          splitLine: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        {
          type: "value",
          min: (value: any) => {
            return round(value.min * 0.95);
          },
          splitLine: false,
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: "{value}%",
          },
        },
      ],
      series: genSeriesByDimensions(dimensions, totalSeries),
    },
    chartsOption
  );
  const handleChange = (e: any) => {
    setAggCode(e.target.value);
  };
  const rangePickerChange = (val: Moment[]) => {
    setRange(val);
  };

  return (
    <Card className="barline-charts" style={style}>
      <Row justify="space-between" align="middle">
        <Col>
          <h3 style={{ color: "#000000d9", fontWeight: "bold" }}>{title}</h3>
        </Col>
        <Col>
          <DateRangePicker
            ref={pickerRef}
            defaultDateMode={dateMode}
            onChange={rangePickerChange}
            noHistoryToFuture={false}
          ></DateRangePicker>
        </Col>
        <Col>
          <Checkbox.Group
            value={compareTypeIdx}
            style={{ width: "100%" }}
            onChange={handleCompareChange}
          >
            <span>{getSharkText("config_page_compare_period")}：</span>
            <Checkbox id="dateCompare" value={4}>
              {getSharkText("config_page_yesterday")}
            </Checkbox>
            <Checkbox id="increase" value={1}>
              {getSharkText("config_page_last_week_same_period")}
            </Checkbox>
          </Checkbox.Group>
        </Col>

        <Col>
          <Radio.Group value={aggCode} onChange={handleChange}>
            <Radio.Button value={0}>5 min</Radio.Button>
            <Radio.Button value={1}>30 min</Radio.Button>
            <Radio.Button value={2}>1 h</Radio.Button>
            <Radio.Button value={3}>1 d</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <Row style={{ flexFlow: "column" }}>
        <Col>
          <Spin spinning={isLoading}>
            <EchartsReactBase
              style={{ margin: "20px -20px -20px ", height: "400px" }}
              option={option}
              notMerge
              theme="default"
            />
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};
export default SaleCharts;
