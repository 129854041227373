import { IDownloadHeader } from "Interface";
import { showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

// 行业趋势
export const trendColumns: IDownloadHeader[] = [
  {
    title: "日期",
    dataIndex: "day",
  },
  {
    title: "",
    dataIndex: "value",
  },
  {
    title: "",
    dataIndex: "compareValue",
  },
  {
    title: "",
    dataIndex: "marketValue",
  },
  {
    title: "",
    dataIndex: "yoy",
    downloadFormatter: (v: number) => showRawNum(v * 100, "percentage"),
  },
  {
    title: "",
    dataIndex: "marketYoy",
    downloadFormatter: (v: number) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("key.date.flightplan"),
    dataIndex: "marketCompareValue",
  },
];

export const refundColumns: IDownloadHeader[] = [
  {
    title: getSharkText("key.date.flightplan"),
    dataIndex: "day",
  },
  {
    title: "",
    dataIndex: "value",
    downloadFormatter: (v: number) => showRawNum(v, "percentage"),
  },
  {
    title: "",
    dataIndex: "compareValue",
    downloadFormatter: (v: number) => showRawNum(v, "percentage"),
  },
  {
    title: "",
    dataIndex: "marketValue",
    downloadFormatter: (v: number) => showRawNum(v, "percentage"),
  },
  {
    title: "",
    dataIndex: "yoy",
    downloadFormatter: (v: number) => showRawNum(v, "percentage"),
  },
  {
    title: "",
    dataIndex: "marketYoy",
    downloadFormatter: (v: number) => showRawNum(v, "percentage"),
  },
  {
    title: getSharkText("key.date.flightplan"),
    dataIndex: "marketCompareValue",
  },
];
