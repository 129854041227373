import React, { useEffect, useState } from "react";
import Button from "Components/Official/Button";
import { ReactComponent as IconUser } from "assets/svg/icon-user.svg";
import { ReactComponent as IconCompany } from "assets/svg/icon-company.svg";
import { ReactComponent as IconMail } from "assets/svg/icon-mail.svg";
import { ReactComponent as IconTel } from "assets/svg/icon-tel.svg";
import "./index.scss";
import VerifyCode from "../VerifyCode";
import { message } from "antd";

const HOST = window.location.host;

let SERVER = "";
switch (HOST) {
  // FAT
  case "flightai-site.fat4151.qa.nt.ctripcorp.com/":
    SERVER = "//gateway.m.fws.qa.nt.ctripcorp.com/restapi/soa2/19595/linkUs";
    break;
  // PRO
  case "www.flightai.aero":
    SERVER = "//m.ctrip.com/restapi/soa2/19595/linkUs";
    break;
  default:
    SERVER = "//gateway.m.fws.qa.nt.ctripcorp.com/restapi/soa2/19595/linkUs";
  // SERVER = '//10.5.13.202:8080/api/'
  // SERVER = '//m.ctrip.com/restapi/soa2/19595/';
}

interface Props {
  verifyId: string;
}

const Form: React.FC<Props> = (props: Props) => {
  const { verifyId } = props;
  const [loading, setLoading] = useState(false);
  const [second, setSecond] = useState(10);
  const [submitTiming, setSubmitTiming] = useState(false);
  const [name, setName] = useState("");
  const [industry, setIndustry] = useState("");
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [mail, setMail] = useState("");
  const [tel, setTel] = useState("");
  const [marketinsight, setMarketinsight] = useState<0 | 1>(0);
  const [industrynews, setIndustrynews] = useState<0 | 1>(0);
  const [rm, setRm] = useState<0 | 1>(0);
  const [msg, setMessage] = useState("");
  const [verifyCode, setVerifyCode] = useState<any>(null);
  const [isShowError, setIsShowError] = useState(false);

  useEffect(() => {
    let interval: any;
    if (submitTiming) {
      interval = setInterval(() => {
        setSecond((preSecond) => {
          if (preSecond <= 1) {
            setSubmitTiming(false);
            clearInterval(interval);
            // 重置秒数
            return 60;
          } else {
            return preSecond - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [submitTiming]);

  const abortCtrl = new AbortController();
  const signal = abortCtrl.signal;
  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await fetch(SERVER, {
        signal,
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          head: {
            requestTime: new Date(),
          },
          linkUs: {
            name,
            industry,
            company,
            title,
            tel,
            mail,
            marketinsight,
            industrynews,
            rm,
            message: msg,
          },
          validCode: verifyCode,
        }),
      });
      const res = await result.json();
      if (res.flag) {
        setLoading(false);
        message.success("提交成功", 5);
        setSubmitTiming(true);
      } else {
        setLoading(false);
        message.error("提交失败", 5);
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        return;
      }
      message.error("提交失败", 5);
    }
  };

  useEffect(() => {
    return () => {
      abortCtrl.abort();
    };
  }, []);

  const isErrorItem = (val: string) => !val;
  const isErrorMail = (val: string) => {
    const reg =
      /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/;
    return !reg.test(val);
  };
  const isErrorMessage = (val: string) =>
    !val || val.length > 400 ? "error-item" : "";

  const handleClick = () => {
    if (!loading && !submitTiming) {
      // 检测是否有验证码
      if (JSON.stringify(verifyCode) === "{}") {
        message.error("请拖动滑块输入验证码");
        return;
      }
      if (
        name &&
        industry &&
        company &&
        title &&
        !isErrorMail(mail) &&
        tel &&
        msg
      ) {
        setIsShowError(false);
        fetchData();
      } else {
        setIsShowError(true);
      }
    }
  };

  return (
    <div className={`form ${isShowError ? "showErrorForm" : ""}`}>
      <div className={`input-item ${isErrorItem(name) ? "error-item" : ""}`}>
        <IconUser className="form-icon" />
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          type="text"
          placeholder={isErrorItem(name) && isShowError ? "请输入姓名" : "姓名"}
        />
      </div>
      <div
        className={`input-item ${isErrorItem(industry) ? "error-item" : ""}`}
      >
        <IconCompany className="form-icon" />
        <input
          value={industry}
          type="text"
          onChange={(e) => setIndustry(e.target.value)}
          placeholder={
            isErrorItem(industry) && isShowError ? "请输入行业" : "行业"
          }
        />
      </div>
      <div className={`input-item ${isErrorItem(company) ? "error-item" : ""}`}>
        <IconCompany className="form-icon" />
        <input
          value={company}
          type="text"
          onChange={(e) => setCompany(e.target.value)}
          placeholder={
            isErrorItem(company) && isShowError ? "请输入公司" : "公司"
          }
        />
      </div>
      <div className={`input-item ${isErrorItem(title) ? "error-item" : ""}`}>
        <IconCompany className="form-icon" />
        <input
          value={title}
          type="text"
          onChange={(e) => setTitle(e.target.value)}
          placeholder={
            isErrorItem(title) && isShowError ? "请输入职务" : "职务"
          }
        />
      </div>
      <div className={`input-item ${isErrorItem(tel) ? "error-item" : ""}`}>
        <IconTel className="form-icon" />
        <input
          value={tel}
          type="tel"
          onChange={(e) => setTel(e.target.value)}
          placeholder={isErrorItem(tel) && isShowError ? "请输入电话" : "电话"}
        />
      </div>
      <div className={`input-item ${isErrorMail(mail) ? "error-item" : ""}`}>
        <IconMail className="form-icon" />
        <input
          value={mail}
          type="email"
          onChange={(e) => setMail(e.target.value)}
          placeholder={
            isErrorMail(mail) && isShowError ? "请输入正确的邮箱" : "邮箱"
          }
        />
      </div>
      <div className="interested-item">
        <span className="interested-hint">您感兴趣：</span>
        <label>
          <input
            onChange={(e) => setMarketinsight(e.target.checked ? 1 : 0)}
            checked={!!marketinsight}
            type="checkbox"
          />
          <span>市场洞察平台</span>
        </label>
        <label>
          <input
            onChange={(e) => setIndustrynews(e.target.checked ? 1 : 0)}
            checked={!!industrynews}
            type="checkbox"
          />
          <span>行业咨询</span>
        </label>
        <label>
          <input
            onChange={(e) => setRm(e.target.checked ? 1 : 0)}
            checked={!!rm}
            type="checkbox"
          />
          <span>收益管理</span>
        </label>
      </div>
      <div
        className={`textarea-item ${isErrorMessage(msg) ? "error-item" : ""}`}
      >
        <textarea
          value={msg}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="请在此输入留言内容，我们会尽快与您联系。"
        ></textarea>
        <span className={`textarea-num ${msg.length > 400 ? "num-error" : ""}`}>
          {msg.length}/400
        </span>
      </div>
      <div style={{ marginTop: 10 }}>
        <VerifyCode setVerifyInfo={setVerifyCode} id={verifyId} />
      </div>
      <div className="form-submit">
        <Button
          onClick={handleClick}
          fill
          full
          loading={loading || submitTiming}
        >
          {submitTiming ? `已提交 ${second}` : "提交"}
        </Button>
      </div>
    </div>
  );
};

export default Form;
