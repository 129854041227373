import React, { useState, useEffect, useMemo } from "react";
import { Select, Cascader, Input } from "antd";
import useGlobal from "Store";
import { Area, Role } from "Interface";
import { getModuleNameFromPath, getModuleStatus, useFetch } from "Utils";
import _ from "lodash";
import { getAirportsOrAlinesArr } from "./AirportCommon";
import { DefaultOptionType } from "antd/lib/cascader";
import { useLocation } from "react-router-dom";
import { canSelectAirports } from "Utils/global";

interface AirportProps {
  onChange: (val: Area) => void;
}

const { Option } = Select;

const Airport: React.FC<AirportProps> = (props: AirportProps) => {
  const { onChange } = props;
  const location = useLocation();
  const [area, setArea] = useState<Area[]>([]);
  const [globalState, actions] = useGlobal();
  const { queryCondition, userInfo, systemType, moduleConfig } = globalState;
  const { airport, airportName } = queryCondition;
  const { roleList } = userInfo;
  const airportsRole = useMemo(() => {
    return roleList
      ? roleList.find((item: Role) => item.roleType === 1)
      : undefined;
  }, [roleList]);
  const isCtripOrCanSelectAirports = canSelectAirports(userInfo, moduleConfig);
  const { setQueryConditionAttr } = actions;
  const airports = useMemo(
    () => getAirportsOrAlinesArr(airportsRole, true),
    [airportsRole]
  );
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const [{ data }, doFetch] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
    lazey: true,
  });

  useEffect(() => {
    if (!airport) {
      setQueryConditionAttr({
        airport: airports[0].code,
        airportName: airports[0].name,
      });
      onChange({
        areaType: 5,
        areaCode: airports[0].code,
        areaName: airports[0].name,
      });
    } else {
      onChange({
        areaType: 5,
        areaCode: airport,
        areaName: airportName,
      });
    }
  }, [airports, airport, airportName, setQueryConditionAttr, onChange]);

  useEffect(() => {
    if (isCtripOrCanSelectAirports) {
      doFetch({
        ext: {
          type: 1,
        },
      });
    }
  }, [doFetch, isCtripOrCanSelectAirports]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setArea(data);
    }
  }, [data]);

  const handleSelectAirport = (value: string, option: any) => {
    setQueryConditionAttr({
      ...queryCondition,
      airport: option.props.value,
      airportName: option.props.label,
    });
    onChange({
      areaType: 5,
      areaCode: option.props.value,
      areaName: option.props.label,
    });
  };

  const onCascaderChange = (
    value: string[],
    selectedOptions?: DefaultOptionType[]
  ) => {
    if (selectedOptions && selectedOptions.length) {
      const { areaCode, areaName } =
        selectedOptions[selectedOptions.length - 1];
      setQueryConditionAttr({
        ...queryCondition,
        airportName: areaName,
        airport: areaCode,
      });
      onChange({
        areaType: 5,
        areaCode,
        areaName,
      });
    }
  };

  const showSearch = {
    filter: (value: string, path: any[]) =>
      path.some(
        (item) =>
          item.areaType === 5 &&
          (item.areaCode.includes(value.toUpperCase()) ||
            item.areaName.includes(value))
      ),
    limit: 10,
    render: (value: string, path: any[]) => (
      <span>
        {path[path.length - 1].areaName}({path[path.length - 1].areaCode})
      </span>
    ),
  };

  const displayRender = (labels: string[]) => (
    <span>{labels[labels.length - 1]}</span>
  );

  return (
    <>
      {isDemo ? (
        <Input style={{ minWidth: 200 }} disabled value="Demo" />
      ) : !isCtripOrCanSelectAirports ? (
        <Select
          id="toolbarAirportSelect"
          showSearch
          style={{ minWidth: 200 }}
          value={airportName}
          onChange={handleSelectAirport}
        >
          {airports.map((item) => (
            <Option key={item.code} value={item.code} label={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Cascader
          className="toolbar-cascader"
          options={area.length > 0 && area[0].children ? area[0].children : []}
          placeholder={airportName}
          expandTrigger="hover"
          fieldNames={{
            label: "areaName",
            value: "areaCode",
            children: "children",
          }}
          onChange={(v, v2) => onCascaderChange(v as string[], v2)}
          showSearch={showSearch}
          displayRender={displayRender}
          notFoundContent="No Data"
        />
      )}
    </>
  );
};

export default Airport;
