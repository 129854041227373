import { Button, Col, Row } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./Tabs.module.scss";
import { defaultLoadAbnormalRuleList } from "../SettingCommon";
import { cloneDeep } from "lodash";
import {
  MonitoringAlertingConfig,
  MonitoringAlertingRule,
} from "@ctrip/flt-bi-flightai-airlines";
import InputItem from "./InputItem";

export interface IComparePriceProps {
  value: MonitoringAlertingConfig;
  submitRule: (v: MonitoringAlertingConfig) => void;
  editable: boolean;
}

/**
 * 上客进度异常
 */
const LoadAbnormal = (props: IComparePriceProps): ReactElement => {
  const { value, submitRule, editable } = props;
  const [threList, setThreList] = useState<MonitoringAlertingRule[]>(
    defaultLoadAbnormalRuleList
  );
  const far = threList.find((t) => t.ruleType === 1);
  const farOriginal = useMemo(
    () => value.ruleList?.find((t) => t.ruleType === 1),
    [value]
  );
  const mid = threList.find((t) => t.ruleType === 2);
  const midOriginal = useMemo(
    () => value.ruleList?.find((t) => t.ruleType === 2),
    [value]
  );
  const near = threList.find((t) => t.ruleType === 3);
  const nearOriginal = useMemo(
    () => value.ruleList?.find((t) => t.ruleType === 3),
    [value]
  );

  useEffect(() => {
    if (value) {
      setThreList(cloneDeep(value.ruleList) || []);
    }
  }, [value]);

  const doSubmit = useCallback(() => {
    submitRule({
      ...value,
      configType: 5,
      ruleList: threList,
    });
  }, [submitRule, threList, value]);

  const resetRuleList = useCallback(() => {
    setThreList(defaultLoadAbnormalRuleList);
  }, []);

  const changeCondition = (
    v: number | null,
    ruleType: 1 | 2 | 3 | 4,
    s: 1 | 2
  ) => {
    const thre = threList.find((t) => t.ruleType === ruleType);
    const attr = `threshold${s}` as keyof MonitoringAlertingRule;
    if (thre) {
      thre[attr] = v || 0;
    } else {
      const defaultRule = defaultLoadAbnormalRuleList.find(
        (d) => d.ruleType === ruleType
      );
      if (!defaultRule) {
        console.error("not set default last 4 week rule: ruleType: ", ruleType);
      } else {
        threList.push({
          ...defaultRule,
          ruleType,
          [attr]: v,
        });
      }
    }
    setThreList([...threList]);
  };
  return (
    <div className={styles.tab}>
      <p>
        <span>远期 [14天,11天]：速度（24小时订座变化量）大于</span>
        <InputItem
          editable={editable}
          value={far?.threshold1}
          originalValue={farOriginal?.threshold1}
          onChange={(v) => changeCondition(v, 1, 1)}
        />
        <span>或小于</span>
        <InputItem
          editable={editable}
          value={far?.threshold2}
          originalValue={farOriginal?.threshold2}
          onChange={(v) => changeCondition(v, 1, 2)}
        />
      </p>
      <p>
        <span>中期 [10天,4天]：速度（4小时订座变化量）大于</span>
        <InputItem
          editable={editable}
          value={mid?.threshold1}
          originalValue={midOriginal?.threshold1}
          onChange={(v) => changeCondition(v, 2, 1)}
        />
        <span>或小于</span>
        <InputItem
          editable={editable}
          value={mid?.threshold2}
          originalValue={midOriginal?.threshold2}
          onChange={(v) => changeCondition(v, 2, 2)}
        />
      </p>
      <p>
        <span>近期 [3天,0天]：速度（1小时订座变化量）大于</span>
        <InputItem
          editable={editable}
          value={near?.threshold1}
          originalValue={nearOriginal?.threshold1}
          onChange={(v) => changeCondition(v, 3, 1)}
        />
        <span>或小于</span>
        <InputItem
          editable={editable}
          value={near?.threshold2}
          originalValue={nearOriginal?.threshold2}
          onChange={(v) => changeCondition(v, 3, 2)}
        />
      </p>
      <Row gutter={[10, 10]} justify="center">
        <Col>
          <Button type="primary" onClick={doSubmit} disabled={!editable}>
            保存
          </Button>
        </Col>
        <Col>
          <Button onClick={resetRuleList}>重置</Button>
        </Col>
      </Row>
    </div>
  );
};
export default LoadAbnormal;
