import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { AirlinesSearchIndexTag } from "@ctrip/flt-bi-flightai-airlines";
import {
  Button,
  Checkbox,
  Col,
  InputNumber,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Tag,
} from "antd";
import CompareTypeCore from "Components/CompareType/CompareTypeCore";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import SelectRT from "Components/SelectRT/SelectRT";
import Airlines from "Components/Toolbar/Airlines";
import FlightClassCom from "Components/Toolbar/FlightClassCom";
import {
  DATE_FORMAT,
  DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
  DEFAULT_FLIGHT_AREA,
  EYearCompareType,
  MARKET_OPTIONS,
} from "Constants";
import {
  AirlinesQueryCondition,
  ECompareTypeV2,
  FlightArea,
  RangeObject,
} from "Interface";
import { cloneDeep, isNil } from "lodash";
import moment, { Moment } from "moment";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import useGlobalState from "Store";
import { getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import { defaultAirlinesQueryExt } from "../../UserProfile/common";
import {
  AxisType,
  defaultTag,
  QueryType,
  QueryTypeOptions,
  searchAlignHelp,
  searchTrendsAirlinesFlightClass,
} from "../Common";
import { QueryExt } from "../SearchInterface";
import GroupSelector from "./GroupSelector";

const SEARCH_TYPE = [0, 1] as const;
export type SearchType = (typeof SEARCH_TYPE)[number];
const SearchMap: Record<SearchType, string> = {
  0: getSharkText("config_page_search_area_uv"),
  1: getSharkText("config_page_search_area_pv"),
};
const SearchOptions = SEARCH_TYPE.map((key) => ({
  label: SearchMap[key],
  value: key,
}));

export interface IAirlineSearchProps {
  axis: AxisType;
  onChange: (q: AirlinesQueryCondition, v: QueryExt) => void;
  intl?: boolean;
}

/**
 *  Component description
 */
const AirlineSearch = (props: IAirlineSearchProps): ReactElement => {
  const { axis, onChange, intl } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const { flightClass } = airlinesQueryCondition;
  const defaultTkDateRange = getDateRange("ninetyDays");
  const [compare, setCompare] = useState<ECompareTypeV2>(
    DEFAULT_AIRPORT_COMPARE_TYPE_VALUE
  );
  const [queryType, setQueryType] = useState<keyof typeof QueryType>("1");
  const [searchRange, setSearchRange] = useState<RangeObject>([
    null as unknown as Moment,
    moment().add(-1, "d"),
  ]);
  const [takeoffRange, setTakeoffRange] = useState<RangeObject>([
    moment(),
    moment().add(90, "d"),
  ]);
  const [alignBefore, setAlignBefore] = useState<boolean>(true);
  const [beforeStart, setBeforeStart] = useState<number | null>(null);
  const [beforeEnd, setBeforeEnd] = useState<number | null>(null);
  const [flightArea, setFlightArea] = useState<FlightArea>(DEFAULT_FLIGHT_AREA);
  const [visible, setVisible] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] =
    useState<AirlinesSearchIndexTag>(defaultTag);
  const [isUseGroup, setIsUseGroup] = useState<boolean>(false);
  const [market, setMarket] = useState<string[]>(["CN"]);
  const [searchType, setSearchType] = useState<SearchType>(SEARCH_TYPE[0]);

  const handleChange = useRefFunc((v: Partial<AirlinesSearchIndexTag>) => {
    if (v.route && v.route.length === 0) {
      message.error(getSharkText("key.choose_one_navigable.hint"));
      return;
    }
    setSelectedTags((t) => {
      return {
        ...t,
        ...v,
      };
    });
  });

  const handleSubmit = useRefFunc(() => {
    // 根据选项调整被禁用选项的值
    let searchStart = searchRange && searchRange[0];
    let searchEnd = searchRange && searchRange[1];
    let tkStart = takeoffRange && takeoffRange[0];
    let tkEnd = takeoffRange && takeoffRange[1];
    if (queryType === "2") {
      if (!tkStart || !tkEnd) {
        message.error(getSharkText("key.departuredate_empty.hint"));
        return;
      }
      if (isNil(beforeEnd) || isNil(beforeStart)) {
        message.error(getSharkText("key.search_aheaddays_empty.hint"));
        return;
      }
      searchStart = cloneDeep(tkStart).add(-1 * beforeEnd, "day");
      searchEnd = cloneDeep(tkEnd).add(-1 * beforeStart, "day");
      setSearchRange([searchStart, searchEnd]);
    }
    if (queryType === "3") {
      if (!searchStart || !searchEnd) {
        message.error(getSharkText("key.searchdate_empty.hint"));
        return;
      }
      if (isNil(beforeEnd) || isNil(beforeStart)) {
        message.error(getSharkText("key.search_aheaddays_empty.hint"));
        return;
      }
      tkStart = cloneDeep(searchStart).add(beforeStart, "day");
      tkEnd = cloneDeep(searchEnd).add(beforeEnd, "day");
      setTakeoffRange([tkStart, tkEnd]);
    }
    const query: AirlinesQueryCondition = {
      ...airlinesQueryCondition,
      startDate: tkStart?.format(DATE_FORMAT) || "",
      endDate: tkEnd?.format(DATE_FORMAT) || "",
      compareType: compare,
    };
    const ext: QueryExt = {
      ...defaultAirlinesQueryExt,
      ...flightArea,
      range: {
        searchAlign: alignBefore ? 1 : 0,
        searchDateStart: searchStart?.format(DATE_FORMAT) || "",
        searchDateEnd: searchEnd?.format(DATE_FORMAT) || "",
        queryType: parseInt(queryType, 10),
        beforeStart,
        beforeEnd,
        axisReverse: 0,
      },
      market,
      searchType,
      ...(isUseGroup ? { tag: selectedTags } : null),
    };
    onChange(query, ext);
  });

  useEffect(() => handleSubmit, []);

  const handleOk = useRefFunc(() => {
    setVisible(false);
  });

  const footer = useMemo(() => {
    return (
      <>
        <Button onClick={() => setVisible(false)}>
          {getSharkText("key.cancel.button")}
        </Button>
        <Button type="dashed" onClick={() => setSelectedTags(defaultTag)}>
          {getSharkText("key.reset_range.button")}
        </Button>
        <Button type="primary" onClick={handleOk}>
          {getSharkText("key.confirm.button")}
        </Button>
      </>
    );
  }, [handleOk]);

  return (
    <Row gutter={[{ md: 24 }, 20]} align="middle">
      {intl ? (
        <FlightClassCom
          flightClassName={searchTrendsAirlinesFlightClass}
          intl={intl}
        />
      ) : (
        <Col>
          <FlightClassCom
            flightClassName={searchTrendsAirlinesFlightClass}
            intl={intl}
          />
        </Col>
      )}
      {flightClass === 3 ? (
        <Col>
          <Select
            value={searchType}
            onChange={setSearchType}
            style={{ width: 170 }}
            options={SearchOptions}
          />
        </Col>
      ) : undefined}
      <Col>
        <Airlines />
      </Col>
      <Col>
        <CompareTypeCore
          source={EYearCompareType}
          value={compare}
          onChange={setCompare}
          defaultValue={DEFAULT_AIRPORT_COMPARE_TYPE_VALUE}
        />
      </Col>
      <Col>
        <span>{getSharkText("key.query_mode.text")}</span>
        <Select
          options={QueryTypeOptions}
          value={queryType}
          onChange={setQueryType}
        ></Select>
      </Col>
      <Col>
        <DateRangePickerV2
          title={getSharkText("key.search_date_colon.text")}
          defaultDateMode={[null, moment().add(-1, "d")]}
          otherProps={{
            allowEmpty: [true, false],
            allowClear: axis !== AxisType.search,
            disabled: queryType === "2",
            disabledDate: (v) => v.isAfter(moment().add(-1, "d")),
          }}
          value={searchRange}
          onChange={setSearchRange}
        />
      </Col>
      <Col>
        <DateRangePickerV2
          title={getSharkText("key.takeoffdate_colon.name")}
          defaultDateMode={defaultTkDateRange || "none"}
          otherProps={{
            allowClear: axis !== AxisType.takeoff,
            disabled: queryType === "3",
          }}
          value={takeoffRange}
          onChange={setTakeoffRange}
        />
      </Col>
      <Col>
        <Checkbox
          defaultChecked
          onChange={(e) => setAlignBefore(e.target.checked)}
        >
          <span style={{ marginRight: 5 }}>
            {getSharkText("key.search_aheaddays_alignment.text")}
          </span>
          <Popover content={searchAlignHelp}>
            <InfoCircleOutlined />
          </Popover>
        </Checkbox>
      </Col>
      <Col>
        <Row gutter={[10, 10]} align="middle">
          <Col>{getSharkText("key.search_aheaddays.name")}</Col>
          <Col>
            <InputNumber
              style={{ width: 100 }}
              value={beforeStart}
              onChange={setBeforeStart}
              min={0}
              addonAfter={getSharkText("key.days.text")}
              disabled={queryType === "1"}
            />
          </Col>
          <Col>~</Col>
          <Col>
            <InputNumber
              style={{ width: 100 }}
              value={beforeEnd}
              onChange={setBeforeEnd}
              min={beforeStart ?? 0}
              addonAfter={getSharkText("key.days.text")}
              disabled={queryType === "1"}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Checkbox onChange={(e) => setIsUseGroup(e.target.checked)}>
          {getSharkText("key.routes_selection_colon.text")}
        </Checkbox>
        <span>
          <Tag className="site-tag-plus" onClick={() => setVisible(true)}>
            <PlusOutlined /> {getSharkText("key.add.button")}
          </Tag>
        </span>
        <Modal
          title={getSharkText("key.routes_selection_colon.text")}
          open={visible}
          onCancel={() => setVisible(false)}
          onOk={handleOk}
          footer={footer}
          width={1024}
        >
          <GroupSelector
            value={selectedTags}
            onChange={handleChange}
          ></GroupSelector>
        </Modal>
      </Col>
      <Col>
        <SelectRT
          value={flightArea}
          onChange={setFlightArea}
          type="city"
          useExchange
        />
      </Col>
      {!intl && (
        <Col>
          <span style={{ marginRight: 5 }}>
            {getSharkText("charts.market.seriesvaluename")}
          </span>
          <Select
            options={MARKET_OPTIONS}
            value={market}
            onChange={setMarket}
            mode="multiple"
            style={{ minWidth: 60 }}
          />
        </Col>
      )}
      <Col>
        <Button onClick={handleSubmit} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default AirlineSearch;
