import { Checkbox, Col, DatePicker, Row, Tooltip } from "antd";
import AirlinesGlobal from "Components/AirlinesGlobal";
import { DATE_FORMAT, EMPTY_ARRAY } from "Constants";
import { Role } from "Interface";
import moment, { Moment } from "moment";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import SelectMultiRoute from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useGlobalState from "Store";
import { getRouteForMultiAirportCity } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getDefaultAirline } from "Utils/global";

// import { MODULE_CODE, QUERY_URL } from "../fetchCode";

export interface ISearchAreaProps {
  onChange: (v: any) => void;
  isDemo?: boolean;
}

export interface SearchAreaHandle {
  setFilters: (date: string, route: string, flightNO: string) => void;
}

/**
 *  Component description
 */
const SearchArea: ForwardRefRenderFunction<
  SearchAreaHandle,
  ISearchAreaProps
> = (props, ref): ReactElement => {
  const { onChange, isDemo = false } = props;
  // const [qf] = useQueryStringArr("flightNo", []);
  const [routes, setRoutes] = useState<string[]>(EMPTY_ARRAY);
  const [flightNO, setFlightNO] = useState<string[]>(EMPTY_ARRAY);
  const [hasOneCityMultiPort, setHasOneCityMultiPort] =
    useState<boolean>(false);
  const [departDate, setDepartDate] = useState<Moment>(
    moment().add(-1, "days")
  );
  const [globalState, actions] = useGlobalState();
  const { systemType, userInfo, airlinesQueryCondition } = globalState;
  const { setAirlinesQueryConditionAttr } = actions;
  const roleList = userInfo.roleList;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;

  /**
   * 用于接收外部参数
   */
  useImperativeHandle(ref, () => ({
    setFilters: (date: string, r: string, f: string) => {
      console.log("set filters: ", date, r, f);
      setRoutes([r]);
      setFlightNO([f]);
      setDepartDate(moment(date));
      // document.querySelector("body")?.scrollTo(0, 0);
      window.scrollTo({ top: 100, left: 0, behavior: "auto" });
    },
  }));

  useEffect(() => {
    if (!airlinesQueryCondition.airlines) {
      const tmp = getDefaultAirline(airlinesRole);
      setAirlinesQueryConditionAttr(tmp);
    }
  }, []);

  /**
   * 监听参数变化并缓存参数
   */
  useEffect(() => {
    // 处理一市两场
    let rs = routes;
    if (hasOneCityMultiPort) {
      rs = getRouteForMultiAirportCity(routes);
    }
    onChange({
      filter: {
        flightList: flightNO,
        routeList: rs,
        otherAirlines: 0,
        download: 0,
      },
    });
  }, [flightNO, routes, onChange, hasOneCityMultiPort]);

  useEffect(() => {
    setAirlinesQueryConditionAttr({
      startDate: departDate.format(DATE_FORMAT),
      endDate: departDate.format(DATE_FORMAT),
    });
  }, [departDate, setAirlinesQueryConditionAttr]);

  return (
    <Row align="middle" gutter={[10, 20]} style={{ marginTop: 0 }}>
      <Col>
        <span className="search-area-filter-title">
          {getSharkText("system_type_Airline")}：
        </span>
        {/* <Airlines airlines={userAirlines} mode={undefined} /> */}
        <AirlinesGlobal mode={undefined} />
      </Col>
      <Col>
        <span>{getSharkText("charts.date_of_departure")}：</span>
        <DatePicker
          value={departDate}
          onChange={(e) => e && setDepartDate(e)}
        />
      </Col>
      <Col>
        <SelectMultiRoute
          mode="multiple"
          queryUrl={QUERY_URL.routeFlight}
          moduleCode={MODULE_CODE}
          routes={routes}
          setRoutes={setRoutes}
          isDemo={isDemo}
          width={200}
          flights=""
          // ext={flightQueryExt}
          limitSelected={4}
          placeholder={getSharkText("config_page_select_route_no_more_than_4")}
          useExchange
          debugId="price_analysis_top_route"
        />
      </Col>
      <Col>
        <Tooltip
          title={getSharkText(
            "config_page_selected_include_one_city_two_venues"
          )}
        >
          <Checkbox
            checked={hasOneCityMultiPort}
            onChange={(e) => setHasOneCityMultiPort(e.target.checked)}
          >
            {getSharkText("config_page_include_one_city_two_venues")}
          </Checkbox>
        </Tooltip>
      </Col>
      <Col>
        <SelectMultiFlight
          queryUrl={QUERY_URL.routeFlight}
          moduleCode={MODULE_CODE}
          flightNO={flightNO}
          setFlightNO={setFlightNO}
          routes={routes.join(",")}
          isDemo={isDemo}
          width={200}
          type={5}
          debugId="price_analysis_top_flight"
          mode="multiple"
        />
      </Col>
    </Row>
  );
};
export default forwardRef(SearchArea);
