export const MODULE_CODE: any = {
  1: "file_sharing",
  2: "file_sharing",
};

export const CHART_TABLE_CODE: any = {
  1: {
    table: "CM0501",
  },
  2: {
    table: "CM0501",
  },
};

export const QUERY_URL: any = {
  1: {
    listFile: "listFile",
    listFileType: "listFileType",
    downloadFile: "downloadFile",
    uploadFile: "uploadFile",
    deleteFile: "deleteFile",
    addFileType: "addFileType",
    deleteFileType: "deleteFileType",
  },
  2: {
    listFile: "listFile",
    listFileType: "listFileType",
    downloadFile: "downloadFile",
    uploadFile: "uploadFile",
    deleteFile: "deleteFile",
    addFileType: "addFileType",
    deleteFileType: "deleteFileType",
  },
};
export interface FileType {
  typeId: number;
  typeName: string;
}
export const DEFAULT_FILE_TYPE: FileType = {
  typeId: 0,
  typeName: decodeURIComponent("%E5%85%A8%E9%83%A8"),
};
