import React, { ReactElement } from "react";
import _, { isFinite, round } from "lodash";
import { toThousands } from "Utils";
import "./index.css";

interface ValueNumProps {
  value: any;
  type?: "num" | "percentage" | "price";
  color?: boolean;
  roundNum?: number;
  isShowZero?: boolean;
  precision?: number;
}

const ValueNum = (props: ValueNumProps): ReactElement => {
  const {
    value,
    type = "percentage",
    color,
    isShowZero = true,
    precision = 0,
  } = props;
  let val: string | number = value;
  if (isFinite(value)) {
    if (!isShowZero && value === 0) {
      val = "-";
    } else {
      const prefix = type === "price" ? "¥" : "";
      const num =
        type === "percentage"
          ? round(value, 1) + "%"
          : toThousands(round(value, precision));
      val = prefix + num;
    }
  } else {
    val = "-";
  }

  return (
    <>
      <span
        className={
          color && _.isFinite(value) ? (value > 0 ? "up" : "down") : ""
        }
      >
        {val}
      </span>
    </>
  );
};

export default ValueNum;
