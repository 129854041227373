import { message } from "antd";
import { SERVER } from "Constants";
import { ArticleType } from "@ctrip/flt-bi-flightai-base";
import React, { ReactElement, useCallback, useMemo } from "react";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { QUERY_URL } from "./fetchCode";
import TagsInput from "Components/TagsInput";

export interface IProps {
  articleTypes: ArticleType[];
  refreshTypes: () => void;
}

/**
 * 研报分类编辑
 */
const TypeEditor = (props: IProps): ReactElement => {
  const { articleTypes, refreshTypes } = props;
  const showTags = useMemo(() => {
    return articleTypes.map((a) => a.typeName).filter((s) => !!s) as string[];
  }, [articleTypes]);
  const [, fetchPost] = useFetch({
    server: SERVER,
    url: QUERY_URL.postArticleType,
    head: {
      moduleCode: "common",
      chartTableCode: "null",
    },
    ext: {
      articleType: {},
      type: 1,
    },
    onSuccess: () => {
      message.success(getSharkText("config_page_operation_success"));
      refreshTypes();
    },
    onError: () => {
      message.error(getSharkText("config_page_operation_failed"));
    },
    lazey: true,
  });
  const handleDelete = useCallback(
    (name: string) => {
      const tag = articleTypes.find((a) => a.typeName === name);
      if (tag) {
        fetchPost({
          ext: {
            articleType: tag,
            type: 3,
          },
        });
      }
    },
    [articleTypes, fetchPost]
  );
  const handleAddition = useCallback(
    (newName: string, oldName?: string) => {
      let typeId = null;
      /** 1=新增, 2=修改, 3=删除 */
      let type = 1;
      if (oldName) {
        const oldTag = articleTypes.find((a) => a.typeName === oldName);
        if (oldTag) {
          typeId = oldTag.typeId;
          type = 2;
        }
      }
      fetchPost({
        ext: {
          articleType: {
            typeId,
            typeName: newName,
          },
          type,
        },
      });
    },
    [articleTypes, fetchPost]
  );

  return (
    <div>
      <TagsInput
        tags={showTags}
        onUpsert={handleAddition}
        onRemove={handleDelete}
      />
    </div>
  );
};
export default TypeEditor;
