import React from "react";
import { Spin } from "antd";
import {
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  toThousands,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode, ISeries } from "Interface";
import _ from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  day: string;
  value: number;
  compareValue: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  loading: boolean;
  isPercentVal: boolean;
  aggCode: AggCode;
  startDate: string;
  endDate: string;
  cardCode?: string;
  isDemo: boolean;
  series: ISeries[];
  seriesValueName: string;
}

const BarCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const {
    data,
    height = 300,
    loading,
    isPercentVal,
    aggCode,
    startDate,
    endDate,
    isDemo,
    series,
    seriesValueName,
  } = props;
  const isZero = (val: number) => val === 0;
  const option = {
    dataset: {
      source: data,
    },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          isDemo
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          let val = "";
          if (item.componentSubType === "bar") {
            // if (item.componentSubType === 'line') {
            val = `${item.seriesName}: ${
              !isZero(item.data.resultValue) ? item.data.resultValue + "%" : "-"
            }`;
          } else if (item.seriesName === seriesValueName) {
            val = `${item.seriesName}: ${
              !isZero(item.data.value)
                ? isPercentVal
                  ? item.data.value + "%"
                  : toThousands(item.data.value)
                : "-"
            }`;
          } else {
            val = `${item.seriesName}: ${
              !isZero(item.data.compareValue)
                ? isPercentVal
                  ? item.data.compareValue + "%"
                  : toThousands(item.data.compareValue)
                : "-"
            }`;
          }
          dom = dom + "<br />" + dot + val;
        });
        dom = getSharkText("key.takeoffdate_colon.name") + day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    yAxis: [
      {
        position: "right",
        type: "value",
        splitNumber: 3,
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) => `${value} %`,
        },
        axisTick: {
          show: false,
        },
        max: (val: any) => {
          const max =
            Math.abs(val.max) > Math.abs(val.min)
              ? Math.abs(val.max)
              : Math.abs(val.min);
          const num = _.round(max * 1.2) > 1 ? _.round(max * 1.2) : 1;
          return num;
        },
        min: (val: any) => {
          const min =
            Math.abs(val.max) > Math.abs(val.min)
              ? -Math.abs(val.max)
              : -Math.abs(val.min);
          const num = -_.round(min * 1.2) > 1 ? _.round(min * 1.2) : -1;
          return num;
        },
      },
      {
        position: "left",
        type: "value",
        splitLine: false,
        splitNumber: 3,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) => `${isPercentVal ? value + "%" : value}`,
        },
        axisTick: {
          show: false,
        },
        max: (val: any) => val.max,
        min: (val: any) => -val.max,
      },
    ],
    series,
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default BarCharts;
