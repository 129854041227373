import { SwapLeftOutlined, SwapRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";

interface RankSortBtnProps {
  sort: boolean;
  handleSortChange: () => void;
}

const RankSortBtn = (props: RankSortBtnProps) => {
  const { sort, handleSortChange } = props;
  return (
    <Button
      id="rankSortBtn"
      type={"text"}
      style={{ background: "#f2f3f5" }}
      icon={
        sort ? (
          <SwapRightOutlined rotate={90} style={{ color: "#165dff" }} />
        ) : (
          <SwapLeftOutlined rotate={90} style={{ color: "#165dff" }} />
        )
      }
      onClick={handleSortChange}
    />
  );
};

export default RankSortBtn;
