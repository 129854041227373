// Created by xh_zhu on 2021-03-31

import { Col, Row } from "antd";
import SearchAreaOrAirport from "Components/SearchAreaOrAirport";
import { Area } from "Interface";
import React, { ReactElement } from "react";
import { Link } from "react-scroll";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 * 出发到达选择和滚动条控件
 */

interface IRouteAndScrollProps {
  setArea: (v: Area) => void;
}

const RouteAndScroll = (props: IRouteAndScrollProps): ReactElement => {
  const { setArea } = props;

  const onChangeScroll = (v: any) => {
    console.log(v);
  };

  return (
    <Row gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}>
      <Col>
        <SearchAreaOrAirport
          submitControl={false}
          setArea={setArea}
        ></SearchAreaOrAirport>
      </Col>
      <Col style={{ marginLeft: "20%" }}>
        <Row style={{ marginTop: 16 }}>
          <Col>
            <Link
              className="ant-radio-button-wrapper"
              onClick={() => onChangeScroll("1")}
              activeClass="active"
              to="passenger-analysis-distribution"
              spy={true}
              smooth={true}
              duration={300}
              offset={-110}
            >
              {getSharkText("key.distribution_psgr.name")}
            </Link>
            <Link
              className="ant-radio-button-wrapper"
              onClick={() => onChangeScroll("2")}
              activeClass="active"
              to="passenger-analysis-loss"
              spy={true}
              smooth={true}
              duration={300}
            >
              {getSharkText("key.psgr_loss.name")}
            </Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default RouteAndScroll;
