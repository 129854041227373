export const MODULE_CODE = "flight_manage_airlines";

export const CHART_TABLE_CODE = {
  routeFlight: "non",
  table: "non",
  feedback: "non",
  setHome: "non",
};

export const QUERY_URL = {
  config: "queryFlightManageConfig",
  routeFlight: "queryFlightManageRouteOrFlight",
  table: "queryFlightManageTable",
  feedback: "setFlightManageFeedback",
  realTime: "queryFlightManageTableRealtime",
  calendar: "queryFlightManageTrafficCalendar",
  CMDPrice: "queryFlightManageCMDPrice",
  querySubCabin: "queryFlightManageSubCabin",
  setSubCabin: "setFlightManageSubCabin",
};
