export const onElementResize = (
  element: HTMLElement,
  callback: (...args: any) => void
): void => {
  const resizeObserver = new ResizeObserver(() => callback());
  resizeObserver.observe(element);
};

export const offElementResize = (element: HTMLElement): void => {
  const resizeObserver = new ResizeObserver(() => () => undefined);
  console.log("off element resize");
  resizeObserver.unobserve(element);
};
