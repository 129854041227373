export enum EPanelType {
  /**
   * 查询分析
   */
  Search,
  /**
   * 预定分析
   */
  Order,
}

/**
 * 坐标轴展示 0=搜索, 1=起飞
 */
export enum EAxisType {
  Search,
  Takeoff,
}

/**
 * 列表查询接口的枚举 0=引擎, 1=接口
 */
export enum EDictType {
  Engine,
  Interface,
}
