import { FlightSegmentFilter } from "@ctrip/flt-bi-flightai-airlines";
import { Radio, Select, Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { AggCode } from "Interface";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../../fetchCode";
import { getColumns } from "./tableColumns";
import useRefFunc from "Utils/useRefFunc";
import { downloadExcel } from "Utils/downloadXLSX";

const OPTIONS = [
  { label: "无分摊", value: "default" },
  { label: "投影分摊", value: "apportion" },
];
export interface ITablePortalProps {
  filter: FlightSegmentFilter;
}

const allowAgg = [
  { label: getSharkText("config_page_all"), value: 4 },
  { label: getSharkText("key.day.button"), value: 0 },
  { label: getSharkText("key.week.button"), value: 1 },
  { label: getSharkText("key.month.button"), value: 2 },
  { label: getSharkText("key.year.button"), value: 3 },
];

/**
 * 航段数据-表格
 */
const TablePortal = (props: ITablePortalProps): ReactElement => {
  const { filter } = props;
  const [aggCode, setAggCode] = useState<AggCode>(AggCode.total);
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const { startDate, endDate } = airlinesQueryCondition;
  const [groups, setGroups] = useState<string[]>(["default"]);
  const [{ data, isLoading }, doFetch] = useFetch({
    url: QUERY_URL.segment,
    server: getServer(systemType),
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE.table,
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
  });

  useEffect(() => {
    if (
      airlinesQueryCondition.startDate &&
      filter.departArea &&
      filter.arriveArea
    ) {
      doFetch({
        query: {
          ...airlinesQueryCondition,
          // airlines: "ALL",
          // airlinesName: "所有航司",
        },
        ext: {
          filter,
          aggCode,
        },
      });
    }
  }, [aggCode, airlinesQueryCondition, doFetch, filter]);

  const agg = useMemo(() => {
    return (
      <div>
        <Radio.Group
          options={allowAgg}
          optionType="button"
          buttonStyle="solid"
          value={aggCode}
          onChange={(e) => setAggCode(e.target.value as AggCode)}
        ></Radio.Group>
        <Select
          mode="multiple"
          style={{ minWidth: 120, marginLeft: 10 }}
          placeholder="请选择分组"
          options={OPTIONS}
          value={groups}
          onChange={(v: any) => setGroups(v)}
        />
      </div>
    );
  }, [aggCode, groups]);

  const columns = useMemo(() => {
    return getColumns(aggCode, !!filter.rt, startDate, endDate, groups);
  }, [aggCode, endDate, filter.rt, groups, startDate]);

  const handleDownload = useRefFunc(() => {
    const names = OPTIONS.filter((o) => groups.includes(o.value)).map(
      (o) => o.label
    );
    downloadExcel(columns, data, "航段数据-" + names.join(","));
  });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <CustomTableV2
      columns={columns}
      dataSource={data}
      headExtends={agg}
      downloadName="航段数据"
      onDownload={handleDownload}
      tableProps={{
        tableLayout: "auto",
        size: "small",
      }}
      showLimit={1000}
      moduleCode={MODULE_CODE}
      chartTableCode={CHART_TABLE_CODE.table}
    />
  );
};
export default TablePortal;
