import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, message, Popover, Row, Switch } from "antd";
import useGlobal from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getServer } from "Service/server";

interface SubscribeProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  subscribed: number;
  refetch: () => void;
}

const Subscribe: React.FC<SubscribeProps> = (props: SubscribeProps) => {
  const { queryUrl, moduleCode, chartTableCode, subscribed, refetch } = props;
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, userInfo } = globalState;
  const query = airlinesQueryCondition;
  const mail = userInfo.user.mail;
  const [{ isLoading }, doFetch] = useFetch({
    server: getServer(2),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    lazey: true,
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_fail"));
    },
  });

  const handleChange = (checked: boolean) => {
    console.log(userInfo);
    doFetch({
      query,
      ext: {
        subscribeType: checked ? 1 : 2,
        mailList: checked ? [mail] : null,
      },
    });
  };

  return (
    <Row gutter={16} align="middle" justify="end" style={{ paddingRight: 15 }}>
      <Col>{getSharkText("config_page_message_subscription")}:</Col>
      <Col>
        <Switch
          checkedChildren={getSharkText("config_page_subscribe")}
          unCheckedChildren={getSharkText("key.close.button")}
          checked={subscribed === 1}
          onChange={handleChange}
          loading={isLoading}
        />
        <Popover content={getSharkText("config_page_push_abnormal_price_info")}>
          <InfoCircleOutlined style={{ marginLeft: 10 }} />
        </Popover>
      </Col>
    </Row>
  );
};

export default Subscribe;
