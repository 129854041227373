import { Button, Col, DatePicker, Row } from "antd";
import { DATE_FORMAT } from "Constants";
import moment, { Moment } from "moment";
import React, { ReactElement, useCallback, useState, useEffect } from "react";
import useGlobalState from "Store";
import { MODULE_CODE, QUERY_URL } from "./fetchCode";
import SelectMultiFlight from "./SelectMultiFlight";
import SelectMultiRoutes from "./SelectMultiRoutes";
import { getSharkText } from "Utils/i18nGlobal";

export interface ISearchAreaProps {
  setExt: (ext: any) => void;
  setInitFilter: (v: boolean) => void;
}

const queryExt = {
  partner: "travix",
  partnerName: "travix",
  compareType: 0,
  marketType: 0,
};
/**
 * 上客进度筛选栏
 */
const SearchArea = (props: ISearchAreaProps): ReactElement => {
  const { setExt, setInitFilter } = props;
  const [globalState, actions] = useGlobalState();
  const { queryCondition } = globalState;
  const { setQueryConditionAttr } = actions;
  const [flightNO, setFlightNO] = useState<string[]>([]);
  const [route, setRoute] = useState<string[]>([]);
  const [date, setDate] = useState<Moment>(moment());
  const [init, setInit] = useState<boolean>(false);

  useEffect(() => {
    setQueryConditionAttr({
      startDate: date.format(DATE_FORMAT),
      endDate: date.format(DATE_FORMAT),
    });
  }, []);

  useEffect(() => {
    if (!init && (route.length || flightNO.length)) {
      setExt({
        flightNOList: flightNO,
        routeList: route,
        query: {
          ...queryCondition,
          ...queryExt,
        },
      });
      setInit(true);
      setInitFilter(true);
    }
  }, [init, route, flightNO, queryCondition, setExt, setInitFilter]);

  const clickQuery = useCallback(() => {
    setExt({
      flightNOList: flightNO,
      routeList: route,
      query: {
        ...queryCondition,
        ...queryExt,
      },
    });
    if (date.format(DATE_FORMAT) !== queryCondition.startDate) {
      setQueryConditionAttr({
        startDate: date.format(DATE_FORMAT),
        endDate: date.format(DATE_FORMAT),
      });
    }
  }, [setExt, flightNO, route, date, queryCondition]);

  return (
    <Row justify="space-between">
      <Col>
        <DatePicker defaultValue={date} onChange={(e) => e && setDate(e)} />
      </Col>
      <Col>
        <SelectMultiRoutes
          queryUrl={QUERY_URL.routeOrFlight}
          moduleCode={MODULE_CODE}
          routes={route}
          setRoutes={setRoute}
          flights=""
          isDemo={false}
          autoSelectFirst
        />
      </Col>
      <Col>
        <SelectMultiFlight
          queryUrl={QUERY_URL.routeOrFlight}
          moduleCode={MODULE_CODE}
          route={route}
          flightNO={flightNO}
          setFlightNO={setFlightNO}
          isDemo={false}
        />
      </Col>
      <Col>
        <Button onClick={clickQuery} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default SearchArea;
