import React, { ReactElement } from "react";
import * as echarts from "echarts/lib/echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/line";
import "echarts/lib/chart/pie";
import "echarts/lib/chart/funnel";
import "echarts/lib/chart/map";
import "echarts/lib/component/dataset";
import "echarts/lib/component/dataZoom";
import "echarts/lib/component/graphic";
import "echarts/lib/component/grid";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/markLine";
import "echarts/lib/component/markPoint";
import "echarts/lib/component/title";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/tooltip";
import throttle from "lodash/throttle";
import { ECharts } from "echarts";
import { offElementResize, onElementResize } from "Utils/elementResize";
// import 'zrender/lib/svg/svg';
// import echarts, { ECharts } from 'echarts';

/**
 *  Created by leijiang on 2019/04/01
 */
export const color = [
  "#7bc18b",
  "#d95316",
  "#82b1ff",
  "#3f51b5",
  "#f8bbd0",
  "#f00",
  "#009688",
  "#fb8c00",
  "#cddc39",
  "#9cd0d0",
  "#00e5ff",
  "#795548",
  "#9c27b0",
  "#e040fb",
  "#eec144",
  "#c12e34",
  "#2b821d",
  "#005eaa",
  "#cda819",
];
export const getColor = (i: number, chartsConfigColor: string[]): string => {
  if (chartsConfigColor) {
    return chartsConfigColor[i % chartsConfigColor.length];
  }
  return color[i % color.length];
};

interface IEchartsState {
  loading: boolean;
}

export default class EChartsBase extends React.Component<any, IEchartsState> {
  container: any;

  ref: any;

  chart: ECharts | undefined;

  lastHeight: number;

  lastWidth: number;

  constructor(props: any) {
    super(props);
    this.container = React.createRef();
    this.ref = React.createRef();
    this.chart = undefined;
    this.lastHeight = -1;
    this.lastWidth = -1;
    this.state = {
      loading: false,
    };
  }

  componentDidMount(): void {
    const { height, width } = this.getParentSize();
    this.initChart(height, width);
    onElementResize(this.container.current, this.resize);
  }

  componentWillUnmount(): void {
    offElementResize(this.container.current);
  }

  getParentSize = (): any => {
    try {
      const { height, width } = this.container.current.getBoundingClientRect();
      return { height, width };
    } catch (e) {
      return { height: 0, width: 0 };
    }
  };

  resize = throttle(() => {
    console.log("resize echarts base");
    const rect = this.getParentSize();
    if (rect.height !== this.lastHeight || rect.width !== this.lastWidth) {
      this.lastHeight = rect.height;
      this.lastWidth = rect.width;
      if (this.chart) {
        this.chart.resize(rect);
      }
    }
  }, 500);

  initChart = (height: number, width: number): void => {
    // 基于准备好的dom，初始化eCharts实例
    // TODO very important!!! 如果你更换了渲染引擎，请务必注意导出文件功能是否正常
    this.chart = echarts.init(this.ref.current, undefined, { width, height });
    this.setOption({ color });
  };

  setOption = (
    option: any,
    notMerge = false,
    lazyUpdate = false,
    silent = false
  ): void => {
    if (this.chart) {
      this.chart.setOption(option, { notMerge, lazyUpdate, silent });
    }
  };

  getOption = (): any => this.chart && this.chart.getOption();

  showLoading = (): void => {
    if (this.chart) {
      this.chart.showLoading();
    }
    this.setState({ loading: true });
  };

  hideLoading = (): void => {
    if (this.chart) {
      this.chart.hideLoading();
    }
    this.setState({ loading: false });
  };

  getEchartsInstance = (): any => this.chart;

  render(): ReactElement {
    return (
      <div
        className="chart-docker"
        style={{ width: "100%", height: "100%", flexGrow: 1, minHeight: 200 }}
        ref={this.container}
        onClick={() => {
          console.log(this.chart && this.chart.getOption());
          console.log(this.chart);
        }}
      >
        <div ref={this.ref} style={{ height: "100%" }} />
      </div>
    );
  }
}
