import { Button, Divider, Input, message, Modal, Tabs, Tooltip } from "antd";
import { SystemType } from "Interface";
import { cloneDeep } from "lodash";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { arrayUpsert, getCharacterLength, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useI18Next from "Utils/useI18Next";
import SelectMultiRoute from ".";
import { IRouteGroup, MODULE_CODE, QUERY_URL } from "./RouteGroupCommon";
import useRefFunc from "Utils/useRefFunc";

const MAX_GROUP_COUNT = 2;
const MAX_NAME_LENGTH = 10;

/**
 * Component description
 * 自定义航线组
 */
const defaultGroup: IRouteGroup[] = [
  {
    id: -1,
    route: "",
    routeGroupName: getSharkText("config_page_custom_combination_one"),
  },
  {
    id: -2,
    route: "",
    routeGroupName: getSharkText("config_page_custom_combination_two"),
  },
];

interface IRouteGroupProps {
  isDemo: boolean;
  /**
   * 选中group后执行的函数
   */
  onSelectGroup: (group: IRouteGroup, checked: boolean) => void;
  /**
   * 在编辑框弹出时触发, 因为Modal的zIndex小于Select的dropdown, 所以编辑框弹出时需要手动收回下拉框, 否则编辑框会被下拉框盖住
   */
  onModalVisibleChange: (v: boolean) => void;
}

const RouteGroup = (props: IRouteGroupProps): ReactElement => {
  const { isDemo, onSelectGroup, onModalVisibleChange } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [userGroup, setUserGroup] = useState<IRouteGroup[]>(defaultGroup);
  const [editingGroup, setEditingGroup] = useState<IRouteGroup[]>(defaultGroup);
  const [activeKey, setActiveKey] = useState<number>(0);
  const [selectedGroup, setSelectedGroup] = useState<number[]>([]);
  const [, actions] = useGlobalState();
  const { uid, token } = actions.getLogin();
  const systemType = SystemType.airlines;
  const t = useI18Next();
  const close = () => {
    setVisible(false);
  };
  const [{ data }, doFetch] = useFetch<IRouteGroup[]>({
    server: getServer(systemType),
    url: QUERY_URL[systemType].query,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: null,
    },
    query: null,
  });
  const refetch = useRefFunc(() => {
    doFetch({ ext: {} });
  });
  useEffect(() => {
    if (data && data.length) {
      const tmp = cloneDeep(data);
      if (tmp.length === 1) {
        tmp.push(defaultGroup[1]);
      }
      setUserGroup(tmp);
      setEditingGroup(tmp);
    }
  }, [data]);

  useEffect(() => {
    if (onModalVisibleChange) {
      onModalVisibleChange(visible);
    }
  }, [visible, onModalVisibleChange]);

  const chooseGroup = (v: number) => {
    if (userGroup[v]) {
      let checked = true;
      if (selectedGroup.includes(v)) {
        setSelectedGroup(selectedGroup.filter((item) => item !== v));
        checked = false;
      } else {
        setSelectedGroup([...selectedGroup, v]);
        checked = true;
      }
      onSelectGroup(userGroup[v], checked);
    }
  };

  const save = async () => {
    const routeGroup = editingGroup.find((g) => g.id === activeKey);
    if (!routeGroup) return;
    if (getCharacterLength(routeGroup.routeGroupName) > MAX_NAME_LENGTH) {
      message.error(
        `"${routeGroup.routeGroupName}" : 组合名称不能超过${MAX_NAME_LENGTH}个字符`
      );
      return;
    }
    /** 新建组时, 提交后需要刷新组数据, 避免重复创建组 */
    let needRefresh = false;
    if (routeGroup.id && routeGroup.id < 0) {
      routeGroup.id = null;
      needRefresh = true;
    }
    const result: any = await fetch(
      `${getServer(systemType)}${QUERY_URL[systemType].set}`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          head: {
            uid,
            token,
            requestTime: new Date(),
            appId: "",
            clientType: "2",
            clientVersion: "",
          },
          routeGroup,
        }),
      }
    );
    const res = await result.json();
    if (res.flag) {
      message.success(t("common.submitSuccess"));
      setUserGroup(editingGroup);
      if (needRefresh) {
        refetch();
      }
    } else {
      try {
        const code = res.ResponseStatus.Errors[0].ErrorCode;
        message.error(t(`error.${code}`));
      } catch (e) {
        console.log(e);
      }
    }
  };

  const setGroupItem = useRefFunc((item: IRouteGroup) => {
    const tmp = arrayUpsert(editingGroup, item, (a) => a.id === item.id);
    setEditingGroup(tmp);
  });

  const groupEditor = useRefFunc((group: IRouteGroup) => {
    const len = getCharacterLength(group.routeGroupName);
    const style = len > MAX_NAME_LENGTH ? { color: "red" } : {};
    const suffix = (
      <span style={style}>
        {len}/{MAX_NAME_LENGTH}
      </span>
    );
    return (
      <div>
        <div>
          <h2>{getSharkText("config_page_route_combination")}</h2>
        </div>
        <div>
          <SelectMultiRoute
            mode="multiple"
            routes={group.route?.split(",") || []}
            setRoutes={(v) => setGroupItem({ ...group, route: v.join(",") })}
            isDemo={isDemo}
            flights=""
            title={false}
            style={{ width: "100%" }}
            permissionType="user"
          />
        </div>
        <div>
          <h2>{getSharkText("config_page_combination_name")}</h2>
        </div>
        <div>
          <Input
            placeholder={getSharkText("config_page_no_more_than_20_characters")}
            value={group.routeGroupName}
            suffix={suffix}
            onChange={(v) =>
              setGroupItem({
                ...group,
                routeGroupName: v.target.value,
              })
            }
            onBlur={(v) => {
              setGroupItem({
                ...group,
                routeGroupName:
                  v.target.value ||
                  getSharkText("config_page_custom_combination_one"),
              });
            }}
          />
        </div>
      </div>
    );
  });

  const tabItems = useMemo(() => {
    return editingGroup.slice(0, MAX_GROUP_COUNT).map((group) => {
      return {
        label: group.routeGroupName,
        key: group.id?.toString() || "",
        children: groupEditor(group),
      };
    });
  }, [editingGroup, groupEditor]);

  useEffect(() => {
    setActiveKey((active) => {
      const keys = tabItems.map((t) => Number(t.key)) as number[];
      return keys.includes(active) ? active : keys[0];
    });
  }, [tabItems]);

  return (
    <>
      <Button
        style={{ margin: "0 5px 5px" }}
        onClick={() => chooseGroup(0)}
        type={selectedGroup.includes(0) ? "primary" : "default"}
        disabled={!userGroup[0].route}
      >
        {userGroup[0].routeGroupName}
      </Button>
      <Button
        style={{ margin: "0 5px 5px" }}
        type={selectedGroup.includes(1) ? "primary" : "default"}
        onClick={() => chooseGroup(1)}
        disabled={!userGroup[1].route}
      >
        {userGroup[1].routeGroupName}
      </Button>
      <Divider style={{ margin: "4px 0" }} />
      <Tooltip
        title={getSharkText(
          "config_page_PaAiFlCoSeSeRo_set_custom_route_group"
        )}
      >
        <Button
          style={{ margin: "0 5px 5px" }}
          onClick={() => setVisible(true)}
          type="dashed"
        >
          {getSharkText("config_page_custom_route")}
        </Button>
      </Tooltip>
      <Modal
        title={getSharkText("config_page_custom_route_combination_setting")}
        open={visible}
        onCancel={close}
        footer={null}
      >
        <Tabs
          onChange={(v) => setActiveKey(parseInt(v, 10))}
          items={tabItems}
        ></Tabs>
        <div
          style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}
        >
          <Button onClick={close} style={{ marginRight: 10 }}>
            {getSharkText("key.cancel.button")}
          </Button>
          <Button onClick={save} type="primary">
            {t("common.save")}
          </Button>
        </div>
      </Modal>
    </>
  );
};
export default RouteGroup;
