import React from "react";
import { CheckCircleFilled } from "@ant-design/icons";
import { Button, Card } from "antd";
import useGlobal from "Store";
import useI18Next from "Utils/useI18Next";

interface IProps {
  title: string;
  content: string;
}

const StatusCard: React.FC<IProps> = (props: any) => {
  const { title, content } = props;

  const [, actions] = useGlobal();
  const { setLoginPageState } = actions;
  const t = useI18Next();

  const handleBackLogin = () => {
    window.location.replace("/");
    setLoginPageState({
      mode: "login",
    });
  };

  return (
    <Card className="login-card">
      <div className="status-card-title">
        <CheckCircleFilled />
        {title}
      </div>
      <div className="status-card-content">{content}</div>
      <Button
        type="primary"
        htmlType="submit"
        size="large"
        className="login-form-button"
        onClick={handleBackLogin}
      >
        {t("common.backToHome")}
      </Button>
    </Card>
  );
};

export default StatusCard;
