import { Input, message, Modal, Spin, Tooltip } from "antd";
import { Role, User } from "Interface";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useGlobalState from "Store";
import { isSame, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import RoutesSelector from "./AirlineRoutesEditor/RoutesSelector";
import { isEmpty, merge } from "lodash";

export interface IUserRoutesEditorProps {
  user: User;
  unitId: number;
  roleList: Role[];
  refetchUser: () => void;
  /**
   * 用户编辑航线和eterm权限 0 = eterm, 1 = 航线
   */
  type: 0 | 1;
}

const string2MapRoutes = (str: string): Record<string, string[]> => {
  const map = JSON.parse(str);
  const rst = Object.keys(map).reduce((t, c) => {
    // 兼容旧的航线格式
    if (Array.isArray(map[c])) {
      t[c] = map[c];
      return t;
    } else {
      t[c] = map[c].split(",");
      return t;
    }
  }, {} as Record<string, string[]>);
  return rst;
};

/**
 *  Component description
 */
const UserRoutesEditor = (props: IUserRoutesEditorProps): ReactElement => {
  const { user, refetchUser, unitId, type, roleList } = props;
  const [visible, setVisible] = useState<boolean>(false);
  const [globalState] = useGlobalState();
  const { userInfo } = globalState;
  const [ctriperValue, setCtripValue] = useState<string>("");
  const airlineRole = roleList.find((r) => r.roleType === 2);
  const [editing, setEditing] = useState<
    Record<string, string[]> | undefined
  >();

  // 先获取一次管理员自己的航线
  const [{ data: managerData }] = useFetch({
    url: "queryEtermOrRouteConfig",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      userId: userInfo.user.id,
      groupId: unitId,
      type,
    },
  });
  const [{ data, isLoading }, doFetch] = useFetch({
    url: "queryEtermOrRouteConfig",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      userId: user.id,
      groupId: unitId,
      type,
    },
    lazey: true,
    onSuccess: (res) => {
      const base = {} as Record<string, string[]>;
      // 默认从航司code生成一份record，防止删掉了没有
      if (airlineRole?.permissionCode && airlineRole.permissionCode !== "*") {
        const airlines = airlineRole.permissionCode.split(",");
        const rst = airlines.reduce((t, c) => {
          t[c] = [];
          return t;
        }, {} as Record<string, string[]>);
        merge(base, rst);
      }
      // 如果用户配置了航线, 则使用用户配置的航线
      const resData = res.data;
      if (
        resData &&
        resData.route &&
        !isEmpty(string2MapRoutes(resData.route)) &&
        Object.keys(string2MapRoutes(resData.route)).filter((f) => !!f).length
      ) {
        const rst = string2MapRoutes(resData.route);
        merge(base, rst);
        if (airlineRole?.permissionCode === "*") {
          const ctripValue = Object.keys(rst).reduce((t, c) => {
            t += `${c}:${rst[c].join(",")};`;
            return t;
          }, "");
          setCtripValue(ctripValue);
        }
      }
      setEditing(base);
    },
    onError: (e) => {
      message.error(`获取用户航线失败, 错误码: ${e.ErrorCode}`);
    },
  });
  const [{ isLoading: upLoading }, doSubmit] = useFetch({
    url: "setEtermOrRouteConfig",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    lazey: true,
    onSuccess: useCallback(() => {
      message.success(getSharkText("config_page_edit_success"));
      setVisible(false);
      refetchUser();
    }, [refetchUser]),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_edit_fail"));
    }, []),
  });

  const showModal = useCallback(() => {
    doFetch({
      ext: {
        userId: user.id,
        groupId: unitId,
        type,
      },
    });
    setVisible(true);
  }, [doFetch, type, unitId, user.id]);

  useEffect(() => {}, [
    airlineRole?.permissionCode,
    airlineRole?.permissionRoute,
    data,
  ]);

  const handleSave = useRefFunc(() => {
    let newAirlineRoutes: Record<string, string> | undefined;
    if (editing) {
      newAirlineRoutes = Object.keys(editing).reduce((t, c) => {
        const isAllRoutes = c === "*" && isSame(editing[c], ["*"]);
        if (
          c &&
          !isAllRoutes &&
          editing[c].length &&
          !isSame(editing[c], ["*"])
        ) {
          t[c] = editing[c].join(",");
        }
        return t;
      }, {} as Record<string, string>);
      if (isEmpty(newAirlineRoutes)) {
        newAirlineRoutes = undefined;
      }
    }
    doSubmit({
      ext: {
        config: {
          ...data,
          userId: user.id,
          route: newAirlineRoutes ? JSON.stringify(newAirlineRoutes) : "",
        },
        type,
      },
    });
  });

  const roleRoutes: Record<string, string[]> | null = useMemo(() => {
    if (!airlineRole?.permissionCode) {
      return null;
    }
    if (airlineRole.permissionCode === "*") {
      return { "*": ["*"] };
    }
    const allAirlines = airlineRole.permissionCode.split(",").reduce((t, c) => {
      t[c] = ["*"];
      return t;
    }, {} as Record<string, string[]>);
    const airlineRoutes = airlineRole.permissionRoute
      ? string2MapRoutes(airlineRole.permissionRoute)
      : {};
    const rst = { ...allAirlines, ...airlineRoutes };
    return rst;
  }, [airlineRole?.permissionRoute, airlineRole?.permissionCode]);

  const changeRoutes = useRefFunc((airline: string, routeList: string[]) => {
    if (!editing) {
      return;
    }
    const filterRoutes =
      routeList.length > 1
        ? routeList.filter((f) => !["*", "-"].includes(f) && !!f)
        : routeList;
    const newAirlineRoutes = {
      ...editing,
      [airline]: filterRoutes,
    };
    if (!isSame(newAirlineRoutes, editing)) {
      setEditing(newAirlineRoutes);
    }
  });

  /** 返回当前航司当前用户可选航线列表, 返回undefined表示可选任意航线, 返回[]表示不可选, 返回string[]限制具体可选范围 */
  const getAllowRoutes = useRefFunc((airline: string): string[] | undefined => {
    if (!roleRoutes) {
      return [];
    }
    if (roleRoutes["*"]) {
      if (isSame(roleRoutes["*"], ["*"])) {
        return undefined;
      } else if (isSame(roleRoutes["*"], ["-"])) {
        return [];
      }
      return roleRoutes["*"];
    }
    if (roleRoutes[airline]) {
      if (isSame(roleRoutes[airline], ["-"])) {
        return [];
      } else if (isSame(roleRoutes[airline], ["*"])) {
        return undefined;
      }
      return roleRoutes[airline];
    }
    return undefined;
  });

  /**
   * 携程用户手输航线, input格式为 航司1:航线1,航线2,航线3;航司2:航线1,航线2
   * 解析的目标格式为 {航司1: [航线1, 航线2, 航线3], 航司2: [航线1, 航线2]}
   */
  const changeCtriperRoutes = useRefFunc((input: string) => {
    setCtripValue(input);
    if (!input) {
      setEditing(undefined);
    }
    const airlines = input.split(";").filter((f) => !!f);
    const rst = airlines.reduce((t, c) => {
      const [airline, routes] = c.split(":");
      t[airline] = routes?.split(",") || ["*"];
      return t;
    }, {} as Record<string, string[]>);
    setEditing(rst);
  });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Spin spinning={isLoading}>
      <a onClick={showModal}>{getSharkText("config_page_edit_route")}</a>
      <Modal
        open={visible}
        title={getSharkText("config_page_edit_route")}
        onCancel={() => setVisible(false)}
        onOk={handleSave}
        width={800}
        destroyOnClose
      >
        <Spin spinning={upLoading}>
          {/* 携程用户手输 */}
          {airlineRole?.permissionCode === "*" ? (
            <>
              <span>{getSharkText("config_page_edit_route")}：</span>
              <Tooltip title={getSharkText("config_page_input_route")}>
                <Input
                  value={ctriperValue}
                  onChange={(v) => changeCtriperRoutes(v.target.value)}
                  style={{ width: 400 }}
                />
              </Tooltip>
            </>
          ) : (
            <>
              {editing
                ? Object.keys(editing).map((airline) => {
                    const routeList = editing[airline] ? editing[airline] : [];
                    let roleRouteList = getAllowRoutes(airline);
                    if (isSame(roleRouteList, ["*"])) {
                      roleRouteList = undefined;
                    }
                    return (
                      <RoutesSelector
                        key={airline}
                        airline={airline}
                        routeOptions={roleRouteList}
                        routes={routeList}
                        setRoutes={(v) => changeRoutes(airline, v)}
                      />
                    );
                  })
                : undefined}
            </>
          )}
        </Spin>
      </Modal>
    </Spin>
  );
};
export default UserRoutesEditor;
