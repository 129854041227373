import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  InputNumber,
  message,
  Popover,
  Row,
  Select,
} from "antd";
import CompareTypeCore from "Components/CompareType/CompareTypeCore";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import SearchAreaOrAirport from "Components/SearchAreaOrAirport";
import Airport from "Components/Toolbar/Airport";
import FlightClassCom from "Components/Toolbar/FlightClassCom";
import {
  DATE_FORMAT,
  DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
  DEFAULT_AREA,
  EYearCompareType,
} from "Constants";
import { Area, ECompareTypeV2, QueryCondition, RangeObject } from "Interface";
import { cloneDeep, isNil } from "lodash";
import moment, { Moment } from "moment";
import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import { getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import { defaultAirlinesQueryExt } from "../../UserProfile/common";
import {
  AxisType,
  QueryType,
  QueryTypeOptions,
  searchAlignHelp,
} from "../Common";
import { QueryExt } from "../SearchInterface";

export interface IAirportSearchProps {
  axis: AxisType;
  onChange: (v: QueryExt, area: Area) => void;
  intl?: boolean;
}

/**
 *  Component description
 */
const AirportSearch = (props: IAirportSearchProps): ReactElement => {
  const { axis, onChange, intl } = props;
  const [globalState, actions] = useGlobalState();
  const { queryCondition } = globalState;
  const { setQueryConditionAttr } = actions;
  console.debug("AirportSearch.globalState", queryCondition);
  const defaultTkDateRange = getDateRange("ninetyDays");
  const [depart, setDepart] = useState<boolean>(true);
  const [arrive, setArrive] = useState<boolean>(false);
  const [compare, setCompare] = useState<ECompareTypeV2>(
    DEFAULT_AIRPORT_COMPARE_TYPE_VALUE
  );
  const [queryType, setQueryType] = useState<keyof typeof QueryType>("1");
  const [searchRange, setSearchRange] = useState<RangeObject>([
    null as unknown as Moment,
    moment().add(-1, "d"),
  ]);
  const [takeoffRange, setTakeoffRange] = useState<RangeObject>([
    moment(),
    moment().add(90, "d"),
  ]);
  const [alignBefore, setAlignBefore] = useState<boolean>(true);
  const [beforeStart, setBeforeStart] = useState<number | null>(null);
  const [beforeEnd, setBeforeEnd] = useState<number | null>(null);
  const [area, setArea] = useState<Area>(DEFAULT_AREA);
  const [market, setMarket] = useState<string[]>(["CN"]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const handleSubmit = useRefFunc(() => {
    if (!depart && !arrive) {
      message.error(getSharkText("key.choose_inoutbound.hint"));
      return;
    }
    // 根据选项调整被禁用选项的值
    let searchStart = searchRange && searchRange[0];
    let searchEnd = searchRange && searchRange[1];
    let tkStart = takeoffRange && takeoffRange[0];
    let tkEnd = takeoffRange && takeoffRange[1];
    if (queryType === "2") {
      if (!tkStart || !tkEnd) {
        message.error(getSharkText("key.departuredate_empty.hint"));
        return;
      }
      if (isNil(beforeEnd) || isNil(beforeStart)) {
        message.error(getSharkText("key.search_aheaddays_empty.hint"));
        return;
      }
      searchStart = cloneDeep(tkStart).add(-1 * beforeEnd, "day");
      searchEnd = cloneDeep(tkEnd).add(-1 * beforeStart, "day");
      setSearchRange([searchStart, searchEnd]);
    }
    if (queryType === "3") {
      if (!searchStart || !searchEnd) {
        message.error(getSharkText("key.searchdate_empty.hint"));
        return;
      }
      if (isNil(beforeEnd) || isNil(beforeStart)) {
        message.error(getSharkText("key.search_aheaddays_empty.hint"));
        return;
      }
      tkStart = cloneDeep(searchStart).add(beforeStart, "day");
      tkEnd = cloneDeep(searchEnd).add(beforeEnd, "day");
      setTakeoffRange([tkStart, tkEnd]);
    }
    const query: Partial<QueryCondition> = {
      // ...queryCondition,
      startDate: tkStart?.format(DATE_FORMAT) || "",
      endDate: tkEnd?.format(DATE_FORMAT) || "",
      compareType: compare,
      departure: depart ? 1 : 0,
      arrive: arrive ? 1 : 0,
    };
    const ext: QueryExt = {
      ...defaultAirlinesQueryExt,
      arriveArea: area,
      range: {
        searchAlign: alignBefore ? 1 : 0,
        searchDateStart: searchStart?.format(DATE_FORMAT) || "",
        searchDateEnd: searchEnd?.format(DATE_FORMAT) || "",
        queryType: parseInt(queryType, 10),
        beforeStart: queryType === "1" ? null : beforeStart,
        beforeEnd: queryType === "1" ? null : beforeEnd,
        axisReverse: 0,
      },
      market,
    };
    setQueryConditionAttr(query);
    onChange(ext, area);
  });

  useEffect(() => {
    if (!isLoaded) {
      if (depart || arrive) {
        setIsLoaded(true);
        handleSubmit();
      }
    }
  }, [depart, arrive, isLoaded, handleSubmit]);

  return (
    <Row gutter={[{ md: 24 }, 20]} align="middle">
      {intl ? (
        <FlightClassCom intl={intl} />
      ) : (
        <Col>
          <FlightClassCom intl={intl} />
        </Col>
      )}
      <Col>
        <Airport />
      </Col>
      <Col>
        <Checkbox
          checked={depart}
          onChange={(e) => setDepart(e.target.checked)}
        >
          {getSharkText("key.outbound.checkbox")}
        </Checkbox>
        <Checkbox
          checked={arrive}
          onChange={(e) => setArrive(e.target.checked)}
        >
          {getSharkText("key.inbound.checkbox")}
        </Checkbox>
      </Col>
      <Col>
        <CompareTypeCore
          source={EYearCompareType}
          value={compare}
          onChange={setCompare}
          defaultValue={DEFAULT_AIRPORT_COMPARE_TYPE_VALUE}
        />
      </Col>
      <Col>
        <span>{getSharkText("key.query_mode_colon.text")}</span>
        <Select
          options={QueryTypeOptions}
          value={queryType}
          onChange={setQueryType}
        ></Select>
      </Col>
      <Col>
        <DateRangePickerV2
          title={getSharkText("key.search_date_colon.text")}
          defaultDateMode={[null, moment().add(-1, "d")]}
          value={searchRange}
          otherProps={{
            allowEmpty: [true, false],
            allowClear: axis !== AxisType.search,
            disabled: queryType === "2",
            disabledDate: (v) => v.isAfter(moment().add(-1, "d")),
          }}
          onChange={setSearchRange}
        />
      </Col>
      <Col>
        <DateRangePickerV2
          title={getSharkText("key.takeoffdate_colon.name")}
          defaultDateMode={defaultTkDateRange || "none"}
          value={takeoffRange}
          otherProps={{
            allowClear: axis !== AxisType.takeoff,
            disabled: queryType === "3",
          }}
          onChange={setTakeoffRange}
        />
      </Col>
      <Col>
        <Checkbox
          defaultChecked
          onChange={(e) => setAlignBefore(e.target.checked)}
        >
          <span style={{ marginRight: 5 }}>
            {getSharkText("key.search_aheaddays_alignment.text")}
          </span>
          <Popover content={searchAlignHelp}>
            <InfoCircleOutlined />
          </Popover>
        </Checkbox>
      </Col>
      <Col>
        <Row gutter={[10, 10]} align="middle">
          <Col>{getSharkText("key.search_aheaddays.name")}</Col>
          <Col>
            <InputNumber
              style={{ width: 100 }}
              value={beforeStart}
              onChange={setBeforeStart}
              min={0}
              addonAfter={getSharkText("key.days.text")}
              disabled={queryType === "1"}
            />
          </Col>
          <Col>~</Col>
          <Col>
            <InputNumber
              style={{ width: 100 }}
              value={beforeEnd}
              onChange={setBeforeEnd}
              min={beforeStart || 0}
              addonAfter={getSharkText("key.days.text")}
              disabled={queryType === "1"}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <SearchAreaOrAirport
          type="city"
          setArea={setArea}
          submitControl={false}
          style={{ marginTop: 0 }}
        />
      </Col>
      {/* <Col>
        <span style={{ marginRight: 5 }}>
          {getSharkText("charts.market.seriesvaluename")}
        </span>
        <Select
          options={MARKET_OPTIONS}
          value={market}
          onChange={setMarket}
          mode="multiple"
          style={{ minWidth: 60 }}
        />
      </Col> */}
      <Col>
        <Button onClick={handleSubmit} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default AirportSearch;
