import React, { ReactElement, useMemo, useEffect, useState } from "react";
import SelectRouteAndFlight from "../../SearchArea/SelectRouteAndFlight";
import { SelectMultiRouteProps } from "../../SearchArea/SelectMultRoute";
import useGlobalState from "Store";
import { useLocation } from "react-router-dom";
import { getIsDemo } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import { Moment } from "@react-awesome-query-builder/core";
import { SelectMultFlightProps } from "../../SearchArea/SelectMultFlight";
import { Button, Col, DatePicker, Row, message } from "antd";
import { RORecord } from "../interface";
import useRefFunc from "Utils/useRefFunc";
import { DATE_FORMAT } from "Constants";
import { RangePickerProps } from "antd/es/date-picker";

export interface SearchAreaProps {
  takeoffdate: string;
  onChange: (v: RORecord) => void;
  // doFetch: (v: any) => void;
  setAreaTakeoffDate: (v: string) => void;
  routes: string[];
  setRoutes: (v: string[]) => void;
  flightNO: string[];
  setFlightNO: (v: string[]) => void;
  maxDate: Moment;
}

/**
 * 搜索区域
 */
const SearchArea = (props: SearchAreaProps): ReactElement => {
  const {
    takeoffdate,
    onChange,
    // doFetch,
    setAreaTakeoffDate,
    routes,
    setRoutes,
    flightNO,
    setFlightNO,
    maxDate,
  } = props;
  const [globalState] = useGlobalState();
  const { pathname } = useLocation();
  const isDemo = getIsDemo(pathname, globalState);

  const [takeoffDate, setTakeoffDate] = useState<Moment>(moment(takeoffdate));

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return (
      current.isAfter(maxDate) || current.isBefore(moment().add(-1, "day"))
    );
  };

  useEffect(() => {
    setTakeoffDate(moment(takeoffdate));
  }, [takeoffdate]);

  const routeProps = useMemo(() => {
    const tmpProps: SelectMultiRouteProps = {
      routes,
      setRoutes,
      isDemo,
      // mode: undefined,
      width: 150,
      flights: flightNO.join(","),
      useExchange: true,
      debugId: "im_batch_route",
      permissionType: "user",
      autoSelectFirst: false,
      allowClear: true,
      useGroup: true,
    };
    return tmpProps;
  }, [flightNO, isDemo, routes, setRoutes]);
  const flightProps = useMemo(() => {
    const tmpProps: SelectMultFlightProps = {
      flightNO,
      setFlightNO,
      routes: routes.join(","),
      isDemo,
      width: 120,
      autoSelectFirst: false,
      placeholder: getSharkText("config_page_select_flight"),
      debugId: "im_batch_flight",
    };
    return tmpProps;
  }, [flightNO, isDemo, routes, setFlightNO]);
  const [routeCom, flightCom] = SelectRouteAndFlight({
    routeProps,
    flightProps,
    changeFlightByRoute: true,
    changeRouteByFlight: true,
  });

  const onQuery = useRefFunc(() => {
    if (!flightNO.length) {
      message.error("航班为必选项");
      return;
    }
    onChange({
      flightNo: flightNO[0],
      route: routes[0] || "",
      takeofftime: takeoffDate.format(DATE_FORMAT),
    });
  });

  return (
    <Row gutter={[20, 20]} justify="space-between">
      <Col style={{ marginLeft: "180px" }}>
        <Row gutter={[20, 20]}>
          <Col>{flightCom}</Col>
          <Col>{routeCom}</Col>
          <Col>
            <DatePicker
              value={takeoffDate}
              onChange={(e) => {
                setAreaTakeoffDate(e?.format(DATE_FORMAT) || "");
                setTakeoffDate(e as Moment);
              }}
              disabledDate={disabledDate}
            />
          </Col>
          <Col>
            <Button onClick={onQuery} type="primary">
              查询
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default SearchArea;
