import React from "react";
import { Card } from "antd";

const PrivacyPolicy: React.FC = () => (
  <div className="content" data-testid="privacy-policy">
    <Card bordered={false} title="隐私政策" style={{ color: "#333" }}>
      <p>
        <strong>
          欢迎您访问FlightAI！FlightAI提醒您：FlightAI网站及产品服务由
        </strong>
        <strong>
          上海箐程信息技术有限公司运营。如果用户在本网站、FlightAI关联公司网站或其他FlightAI提供的移动应用或软件上（以下简称“FlightAI”）访问或使用FlightAI的产品或服务（以上统称为“服务”），
        </strong>
        <strong>
          便视为用户接受了以下隐私政策，请您仔细阅读以下内容，尤其是以下加粗字体。如果您不同意以下任何内容，请立刻停止访问/使用本网站或其他任何移动应用或软件。本隐私政策中提及的“FlightAI”以及“我们”均指FlightAI，“用户”以及“您”均指自愿接受本隐私政策的用户。
        </strong>
      </p>
      <p>
        <strong>本版本发布日期：2020年6月15日</strong>
      </p>
      <p>
        <strong>本版本生效日期：2020年6月15日</strong>
      </p>
      <p>
        <strong>本隐私政策将帮助您了解以下内容：</strong>
      </p>
      <p>
        <strong>1. 隐私政策的确认和接纳</strong>
      </p>
      <p>
        <strong>2. 信息收集</strong>
      </p>
      <p>
        <strong>3. 信息使用</strong>
      </p>
      <p>
        <strong>4. 信息共享、转让和披露</strong>
      </p>
      <p>
        <strong>5. 信息保存及跨境传输</strong>
      </p>
      <p>
        <strong>6. Cookie的使用</strong>
      </p>
      <p>
        <strong>7. 个人敏感信息提示</strong>
      </p>
      <p>
        <strong>8. 信息安全与保护</strong>
      </p>
      <p>
        <strong>9. 信息安全事件处置</strong>
      </p>
      <p>
        <strong>10. 未成年人信息保护</strong>
      </p>
      <p>
        <strong>11. 用户个人信息管理</strong>
      </p>
      <p>
        <strong>12. 从中国大陆地区以外访问我们的网站</strong>
      </p>
      <p>
        <strong>13. 隐私政策的适用范围</strong>
      </p>
      <p>
        <strong>14. 隐私政策的修改</strong>
      </p>
      <p>
        <strong>15. 如何联系我们</strong>
      </p>
      <p>
        <strong>16. 法律适用和纠纷解决</strong>
      </p>
      <p>
        <strong>1、隐私政策的确认和接纳</strong>
      </p>
      <p>
        尊重用户个人隐私是<strong>FlightAI</strong>
        的一项基本政策。FlightAI是面向航空业的数据咨询与产品服务提供商。请
        <strong>
          您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并与上海箐程信息技术有限公司达成协议并接受所有的服务条款。
        </strong>
      </p>
      <p>
        <strong>
          上海箐程信息技术有限公司同时作为FlightAI网的信息控制者，
        </strong>
        我们十分注重保护用户的个人信息及隐私安全，我们理解您通过网络向我们提供信息是建立在对我们信任的基础上，我们重视这种信任，也深知隐私对您的重要性，并尽最大努力全力保护您的隐私。
      </p>
      <p>
        <strong>2、信息收集</strong>
      </p>
      <p>
        <strong>
          （1）为了更好地为您提供服务，FlightAI将遵循“合理、相关、必要”原则，且以您同意为前提收集信息，不会收集法律法规禁止收集的信息。考虑到FlightAI会员服务的重要性，用户同意，FlightAI将会收集您在FlightAI站点或移动应用上输入的或者以其他方式提供给我们的所有信息：
        </strong>
      </p>
      <ul>
        <li>
          <blockquote>
            <p>
              在您注册为FlightAI会员时，至少需提供<strong>手机号和邮箱</strong>
              用以创建FlightAI账号，获取初始密码。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>在您后续更新个人信息时，需符合及时、详尽、准确的要求。</p>
          </blockquote>
        </li>
      </ul>
      <p>
        <strong>
          （3）我们将在您使用FlightAI服务过程中，自动收集您的个人信息：
        </strong>
      </p>
      <ul>
        <li>
          <blockquote>
            <p>
              日志信息，指您使用我们的服务时，系统可能通过cookies、web
              beacon或其他方式自动采集的信息，包括：
            </p>
          </blockquote>
          <ul>
            <li>
              <blockquote>
                <p>
                  设备信息或软件信息，例如您的IP地址和移动设备所用的版本和设备识别码（IDFA/IMEI/OAID）、设备应用安装列表、以及通过网页浏览器、移动设备用于接入我们服务的配置信息。
                </p>
              </blockquote>
            </li>
            <li>
              <blockquote>
                <p>
                  在使用我们服务时搜索或浏览的信息，例如您使用的网页或APP搜索词语、访问页面，以及您在使用我们服务时浏览或提供的其他信息。
                </p>
              </blockquote>
            </li>
            <li>
              <blockquote>
                <p>
                  您通过我们的服务提交的内容所包含的信息，例如提交的具体需求及意见反馈等。
                </p>
              </blockquote>
            </li>
          </ul>
        </li>
        <li>
          <blockquote>
            <p>
              <span>
                位置信息，指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您的位置信息，例如通过GPS或WLAN等方式收集您的地理位置信息，或您提供账户信息中包含的您所在地区信息，
              </span>
              <span>
                您或其他人上传的显示您当前或曾经所处地理位置的共享信息等。您可以通过关闭定位功能，停止对您的地理位置信息的收集。
              </span>
            </p>
          </blockquote>
        </li>
      </ul>
      <p>
        <strong>3、信息使用</strong>
      </p>
      <p>
        <strong>
          （1）一般来说，FlightAI出于以下目的收集、使用您的个人信息：
        </strong>
      </p>
      <ul>
        <li>
          <blockquote>
            <p>
              账号管理：您可以通过FlightAI网创建账号，我们将使用您所提供的信息来验证您的身份，根据您的单位信息授予您相关的数据权限，并为您提供一系列的数据查询及分析功能。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              与您联系：回复及处理您所提出的疑问或要求。我们也可能会向您发送问卷调查或邀请您对我们的服务提供反馈等。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              市场调研：我们有时会邀请您参与市场调查，以衡量您对我们的产品、服务和网站的兴趣。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              提升服务的安全性和可靠性：我们可能会检测和预防欺诈行为、不法活动，将您的个人数据用于风险评估和安全目的。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              数据分析：我们可能将您的行为数据用于分析，从而形成用户画像，以便让我们能够了解您所在的位置、偏好和人口统计信息，或与其他来源（包括第三方）的数据相匹配，从而开发我们的产品、服务或营销计划，改进我们的服务。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              日常运营：包括但不限于权限管理、客户验证、技术支持、网络维护、故障排除、内部行政事务及内部政策和程序、生成内部报告等。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              电话监测：您接听和拨打我们的客服电话可能会被录音，我们可能会使用通话录音来监控我们的客服服务质量，检查您所提供的信息的准确性以防止欺诈，或为了我们内部人员培训的目的。录音在被保留一段时间后会自动删除，除非由于合规要求或合法利益需要为场景的保留。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              履行义务：处理相关政策下发生的保险索赔和付款，处理支付给合作伙伴的佣金或对服务合作伙伴造成的损失提起索赔或收回付款等。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              法律目的：我们可能需要使用您的信息来处理和解决法律纠纷，或遵循对我们具有约束力的任何法律法规或监管机构颁发的文件规定，以配合国家部门或监管机构调查和遵从法律法规的目的。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              此外，我们可能会出于其他目的收集、使用和披露您的个人信息，并通过修订本声明的形式告知您。
            </p>
          </blockquote>
        </li>
      </ul>
      <p>
        <strong>
          （2）您在享受FlightAI提供的各项服务的同时，授权并同意接受FlightAI向您的电子邮件、手机、通信地址等发送商业信息，包括不限于最新的FlightAI产品信息、促销信息等。若您选择不接受FlightAI提供的各类信息服务，您可以按照FlightAI提供的相应设置拒绝该类信息服务。
        </strong>
      </p>
      <p>
        <strong>
          （3）您充分知晓，以下情形中我们使用个人信息无需征得您的授权同意：
        </strong>
      </p>
      <ul>
        <li>
          <blockquote>
            <p>与国家安全、国防安全有关的；</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>与公共安全、公共卫生、重大公共利益有关的</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>与犯罪侦查、起诉、审判和判决执行等有关的；</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>根据您的要求签订合同所必需的；</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>为合法的新闻报道所必需的；</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>法律法规规定的其他情形。</p>
          </blockquote>
        </li>
      </ul>
      <p>
        <strong>4、信息共享、转让和公开披露</strong>
      </p>
      <p>
        <strong>
          （1）我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴包括以下类型（包含中国境内和中国境外实体）：
        </strong>
      </p>
      <ul>
        <li>
          <blockquote>
            <p>
              业务合作伙伴：我们可能与合作伙伴一起为您提供产品或者服务，包括快递业务、通讯服务、客户服务、市场推广、广告投放、技术服务、实名认证服务、咨询服务等等。
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              关联公司：我们可能会与我们的关联公司共享您的个人信息，使我们能够向您提供数据咨询与产品服务，他们会采取不低于本隐私政策同等严格的保护措施。
            </p>
          </blockquote>
        </li>
      </ul>
      <p>
        对于我们与之共享个人信息的公司、组织我们会与其签署严格的保密协定，要求他们按照本隐私政策以及我们其他相关的保密和安全措施来处理个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途，如要改变个人信息的处理目的，将再次征求您的授权同意。
      </p>
      <p>
        <strong>（2）信息转让</strong>
      </p>
      <p>
        <strong>
          我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
        </strong>
      </p>
      <ul>
        <li>
          <blockquote>
            <p>事先获得您的明确同意或授权；</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>根据适用的法律法规、法律程序的要求、强制性的行政或司法要求；</p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。
            </p>
          </blockquote>
        </li>
      </ul>
      <p>
        <strong>（3）公开披露</strong>
      </p>
      <p>
        <strong>我们仅会在以下情形，公开披露您的个人信息：</strong>
      </p>
      <ul>
        <li>
          <blockquote>
            <p>
              根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；
            </p>
          </blockquote>
        </li>
        <li>
          <blockquote>
            <p>
              根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。
            </p>
          </blockquote>
        </li>
      </ul>
      <p>
        <strong>5、信息保存及跨境传输</strong>
      </p>
      <p>
        （1）您的个人信息我们将保存至您账号注销之日后的三个月，除非需要延长保留期或受到法律或者有权机关的允许或协助有权机关进行查询。
      </p>
      <p>
        （2）如我们停止运营FlightAI网产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
      </p>
      <p>
        （3）我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，部分场景需要将您的个人信息传输至境外
      </p>
      <ul>
        <li>
          <p>获得您的明确授权；</p>
        </li>
        <li>
          <p>当营销场景涉及到海外服务提供商时；</p>
        </li>
        <li>
          <p>
            当您通过携程平台进行跨境交易（如预订境外酒店，预订境外机票涉及到使用境外GDS全球分销系统，预订汽车票、火车票、船票、打车、旅游服务时涉及境外供应商提供服务时）等个人主动行为；
          </p>
        </li>
        <li>
          <p>法律法规的明确要求。</p>
        </li>
      </ul>
      <p>
        <strong>6、Cookie的使用</strong>
      </p>
      <p>
        Cookie是包含字符串的小文件，在您登入和使用网站或其他网络内容时发送、存放在您的计算机、移动设备或其他装置内（通过经过加密）。
      </p>
      <p>
        <span>
          为了让您获得更轻松便捷的访问体验，您访问FlightAI网站或使用FlightAI提供的服务时，我们可能会在您的设备终端或系统上存储名为Cookie的小型数据文件用来识别您的身份，这样可以帮您省去重复输入注册信息的步骤，
        </span>
        <span>
          帮您优化对广告的选择与互动及其帮助判断您的账户安全状态。您知悉并同意，随着技术的发展和我们产品和服务的进一步完善，我们也可能会使用Cookie类似用途的其他技术。
        </span>
        <strong>
          您有权接受或拒绝Cookie，如果浏览器自动接收Cookie，您可以根据自己的需要修改浏览器的设置以拒绝Cookie。
        </strong>
        <strong>
          请注意，如果您选择拒绝Cookie，那么您可能无法更好地体验FlightAI提供的服务。
        </strong>
      </p>
      <p>
        <strong>7、个人敏感信息提示</strong>
      </p>
      <p>
        个人敏感信息是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息，本隐私政策中涉及的个人敏感信息包括：个人身份信息（
        <strong>包括但不限于姓名、单位证明</strong>
        ）、网络身份标识信息（包括但不限于<strong>账户名、邮箱地址</strong>
        及与前述有关的<strong>密码、口令、口令保护答案</strong>
        ），以及其他信息（包括但不限于<strong>个人电话号码等</strong>）。
      </p>
      <p>
        这些个人敏感信息将受到严格保护
        <strong>
          ，您需要在使用我们为您提供的产品或服务前谨慎考虑。您同意这些个人敏感信息将按照《FlightAI隐私政策》阐明的目的和方式来进行处理。
        </strong>
      </p>
      <p>
        <strong>8、信息安全与保护</strong>
      </p>
      <p>
        （1）FlightAI非常重视信息安全，成立了专门的负责团队。
        我们努力为您提供信息保护，采取了合适的管理、技术以及物理安全措施，参照国内外信息安全标准及最佳实践建立了与业务发展相适应的信息安全保障体系，已获得ISO27001信息安全管理体系标准认证，及PCI-DSS支付卡行业数据安全标准认证。
      </p>
      <p>
        （2）我们从数据的生命周期角度出发，在数据收集、存储、显示、处理、使用、销毁等各个环节建立了安全防护措施，根据信息敏感程度的级别采取不同的控制措施，包括但不限于访问控制、SSL加密传输、AES256bit或以上强度的加密算法进行加密存储、敏感信息脱敏显示等。
      </p>
      <p>
        （3）我们对可能接触到您信息的员工也采取了严格管理，可监控他们的操作情况，对于数据访问、内外部传输使用、脱敏、解密等重要操作建立了审批机制，并与上述员工签署保密协议等。与此同时，我们还定期对员工进行信息安全培训，要求员工在日常工作中形成良好操作习惯，提升数据保护意识。
      </p>
      <p>
        （4）尽管有前述的安全措施，但同时也请您理解在网络上不存在“完善的安全措施”。我们会按现有的技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄露、损毁或丢失。
      </p>
      <p>
        （5）您的账户均有安全保护功能，请妥善保管您的账号及密码信息，切勿将密码告知他人，如果您发现自己的个人信息泄露，特别是您的账号和密码发生泄露，请您立即与我们的客服联系，以便我们采取相应的措施。
      </p>
      <p>
        <strong>9、信息安全事件处置</strong>
      </p>
      <p>
        <span>
          在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。
        </span>
        <span>
          事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
        </span>
      </p>
      <p>
        <strong>10、未成年人信息保护</strong>
      </p>
      <p>
        (1) FlightAI非常重视对未成年人个人信息的保护。
        <strong>
          若您是18周岁以下的未成年人，在使用FlightAI的服务前，应事先取得法定监护人的同意。
        </strong>
        FlightAI根据《中华人民共和国未成年人保护法》等国家相关法律法规的要求对未成年人的个人信息及隐私进行保护。
      </p>
      <p>
        (2)
        FlightAI不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们仅在法律法规允许、监护人同意或保护未成年人所必要的情况下使用、共享、转让或披露此类信息。
      </p>
      <p>
        (3)
        如果有事实证明未成年人并未取得监护人同意的情况下注册和使用了我们的服务，监护人可以联系FlightAI客服，我们会在确认后尽快删除相关未成年人的个人信息。
      </p>
      <p>
        (4)
        对于不满14周岁的儿童个人信息，我们还会遵循正当必要、知情同意、目的明确、安全保障、依法利用的原则，按照《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露。
        <strong>
          当您作为监护人为被监护的儿童选择使用FlightAI相关旅行服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。如果您不提供前述信息，您将无法享受我们提供的相关服务。
        </strong>
        <strong>
          此外，您在使用评价功能时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。如您对儿童个人信息相关事宜有任何意见、建议或投诉，请联系我们。
        </strong>
      </p>
      <p>
        <strong>11、用户个人信息管理</strong>
      </p>
      <p>
        您可发送邮件至flightai@trip.com注销FlightAI网账户。注销FlightAI网账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或共享与该账户相关的个人信息，但之前的信息我们仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。
      </p>
      <p>
        <strong>12、从中国大陆地区以外访问我们的网站</strong>
      </p>
      <p>
        如果您从中国大陆以外地区访问我们网站，请您注意，您的信息可能被传送至、存储于中国大陆，并且在中国大陆进行处理。中国大陆的数据保护法和其他法律可能与您所在国家/地区的法律不同，但请相信我们将采取措施保护您的信息。
        <strong>
          选择使用我们的服务的同时，您了解并同意您的信息可能被传送至我们的网站，并如本隐私政策所诉的与我们共享信息的第三方。
        </strong>
      </p>
      <p>
        <strong>13、隐私政策的适用范围</strong>
      </p>
      <p>
        除某些特定服务外，我们所有的服务均适用《FlightAI隐私政策》。特定服务将适用特定的隐私政策或协议。该特定服务的隐私政策或协议构成《FlightAI隐私政策》的一部分。如任何特定服务的隐私政策或协议与《FlightAI隐私政策》有不一致之处，则适用特定服务的隐私政策或协议。
      </p>
      <p>
        <strong>
          请您注意，《FlightAI隐私政策》不适用于以下情况：通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；通过在我们服务中进行广告服务的其他公司和机构所收集的信息。
        </strong>
      </p>
      <p>
        <strong>14、隐私政策的修改</strong>
      </p>
      <p>
        <span>
          FlightAI会在必要时修改隐私政策，请您理解，我们可能会适时修订本隐私政策，我们将标注本隐私政策最近更新的日期，更新将于发布时生效。未经您明确同意，FlightAI不会削减您按照本隐私权政策所应享有的权利。
        </span>
        <span>
          对于重大变更，FlightAI还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私权政策的具体变更内容）。请您经常回访本隐私政策，以阅读最新版本。
        </span>
      </p>
      <p>
        <strong>15、如何联系我们</strong>
      </p>
      <p>
        （1）如您有任何与个人信息保护或隐私保护相关的问题、意见或建议，您可以发送邮件至我们的邮箱flightai@trip.com与我们联系。
        <strong>
          如您不同意本隐私政策中的任何条款，您可选择停止访问或者卸载此应用，否则将视为您知悉并同意本隐私政策，且愿意受其约束。
        </strong>
      </p>
      <p>
        （2）一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信办、消费者保护协会等监管部门进行投诉，投诉方式如下：
      </p>
      <p>国家网信办举报中心：www.12377.cn</p>
      <p>消费者投诉举报电话：12315</p>
      <p>
        <strong>16、法律</strong>
      </p>
      <p>
        隐私政策要与该国的国家法律解析一致，包括法律条款中有争议抵触的内容。
        <strong>
          用户和上海箐程信息技术有限公司一致同意适用中华人民共和国法律并接受中国法院的管辖。若有任何条款与法律相抵触，那我们将修改这些条款以确保其合法、合规，而其它条款的法律效力则不受影响，
        </strong>
        <strong>
          因解释本隐私政策以及用户通过FlightAI网预订任何产品而导致的争议，以及任何跟用户个人信息或隐私保护相关的纠纷，同意接受上海市长宁区人民法院的管辖。
        </strong>
      </p>
    </Card>
  </div>
);

export default PrivacyPolicy;
