import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { genChartData } from "Utils";
import _ from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface Data {
  name: string;
  value: number;
  cnt: number;
}

interface ChartsProps {
  isPercentVal: boolean;
  loading: boolean;
  data: Data[];
  height: number;
  seriesMap: Record<string, string>;
}

const BarCharts: React.FC<ChartsProps> = (props: ChartsProps) => {
  const { isPercentVal, loading, data, height, seriesMap } = props;

  const [areaData, setAreaData] = useState<Data[]>(data);

  useEffect(() => {
    if (_.isEmpty(data)) {
      setAreaData([]);
    } else {
      const transData = genChartData(data, seriesMap);
      setAreaData(transData);
    }
  }, [data, seriesMap]);

  const option = {
    dataset: {
      source: areaData,
    },
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const perDom = `占比: ${item.data.value}%`;
          dom = dot + perDom;
        });
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
      },
    },
    yAxis: {
      position: "left",
      type: "value",
      splitLine: false,
      splitNumber: 5,
      axisLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: number) =>
          `${isPercentVal ? value.toFixed(2) + "%" : value}`,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: "bar",
        encode: { x: "name", y: "value" },
        label: {
          show: true,
          position: "top",
          formatter: (d: any) => Math.round(d.data.value) + "%",
        },
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default BarCharts;
