import React, { useCallback } from "react";
import { message, Spin, Popconfirm } from "antd";
import { User } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface IProps {
  user: User;
  unitId: number;
  refetchUser: () => void;
}

const RemoveUser: React.FC<IProps> = (props: IProps) => {
  const { user, refetchUser, unitId } = props;
  const [{ isLoading }, doFetch] = useFetch({
    url: "removeUserFromUnit",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      userId: user.id,
      groupId: unitId,
    },
    lazey: true,
    onSuccess: useCallback(() => {
      message.success(getSharkText("config_page_remove_success"));
      refetchUser();
    }, [refetchUser]),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_remove_fail"));
    }, []),
  });

  const handleClick = () => {
    doFetch({
      ext: {
        userId: user.id,
        groupId: unitId,
      },
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Popconfirm
        title={getSharkText("config_page_confirm_remove_user")}
        onConfirm={handleClick}
      >
        <a>{getSharkText("config_page_remove")}</a>
      </Popconfirm>
    </Spin>
  );
};

export default RemoveUser;
