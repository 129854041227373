import React, { useCallback, useEffect, useState } from "react";
import { Descriptions, Input, message, Modal } from "antd";
import { UnitList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface UnidAddProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  refetch: () => void;
  curUnit?: UnitList;
  unitList: UnitList[];
  setAddUnitName?: (name: string) => void;
}

const UnitEditModal: React.FC<UnidAddProps> = (props: UnidAddProps) => {
  const { visible, setVisible, curUnit, refetch, unitList, setAddUnitName } =
    props;
  const [unitName, setUnitName] = useState("");
  const [{ isLoading }, doFetch] = useFetch({
    url: "saveUnit",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      unit: {
        ...curUnit,
        remark: "",
        unitName,
      },
    },
    lazey: true,
    onSuccess: useCallback(() => {
      message.success(getSharkText("config_page_add_modify_success"));
      setVisible(false);
      refetch();
      if (setAddUnitName) {
        setAddUnitName(unitName);
      }
    }, [refetch, setAddUnitName, setVisible, unitName]),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_add_modify_failed"));
    }, []),
  });

  useEffect(() => {
    if (curUnit) {
      setUnitName(curUnit.unit.unitName);
    } else {
      setUnitName("");
    }
  }, [curUnit, visible]);

  const handleOk = () => {
    const existUnit = unitList.find((item) => item.unit.unitName === unitName);

    const isExistName = curUnit
      ? !!(existUnit && existUnit.unit.id !== curUnit.unit.id)
      : !!existUnit;

    if (unitName && !isExistName) {
      doFetch({
        ext: {
          unit: {
            unitName,
            unitCode: curUnit ? curUnit.unit.unitCode : "",
            id: curUnit ? curUnit.unit.id : null,
            sortId: curUnit ? curUnit.unit.sortId : 3,
            remark: curUnit ? curUnit.unit.remark : "",
            managerUnit: curUnit ? curUnit.unit.managerUnit : false,
            autoRenew: curUnit ? curUnit.unit.autoRenew ?? 0 : 0,
            renewDay: curUnit ? curUnit.unit.renewDay ?? 90 : 90,
          },
        },
      });
    } else if (isExistName) {
      message.error(
        getSharkText("config_page_permission_group_name_cannot_repeat")
      );
    } else {
      message.error(
        getSharkText("config_page_permission_group_name_cannot_be_empty")
      );
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={getSharkText("config_page_add_modify_permission_group_name")}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={getSharkText("config_page_confirm")}
      cancelText={getSharkText("key.cancel.button")}
      confirmLoading={isLoading}
    >
      <Descriptions>
        <Descriptions.Item
          label={getSharkText("config_page_permission_group_name")}
          span={3}
        >
          <Input
            placeholder={getSharkText(
              "config_page_enter_permission_group_name"
            )}
            value={unitName}
            onChange={(e) => setUnitName(e.target.value)}
            style={{ width: 330 }}
          />
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default UnitEditModal;
