/** 用户权限相关的方法 */
import { AirportOrAirline, Role, UserInfo } from "Interface";
import { getSharkText } from "./i18nGlobal";

const ctripDefaultAirport = {
  name: getSharkText("config_page_shanghai_hongqiao"),
  code: "SHA",
};

const isAirportCode = (code: string): boolean => {
  return code.length === 3;
};

/** 获取用户有权限的机场或航司选项, 如果为*则返回*表示允许全部 */
export const getPermissionItems = (role: Role): AirportOrAirline[] | "*" => {
  if (role.permissionCode === "*") {
    return "*";
  }
  const codeArr = role.permissionCode.split(",");
  const nameArr = role.permissionName.split(",");
  const lang = sessionStorage.getItem("lang") || "zh-CN";
  const arr: AirportOrAirline[] = [];
  codeArr.forEach((item, idx) => {
    arr.push({
      code: item,
      name: lang === "en-US" ? item : nameArr[idx],
    });
  });
  return arr;
};

/** 获取用户的默认机场, 优先使用用户的code, 其次使用 */
export const getDefaultAirport = (
  userInfo: UserInfo
): AirportOrAirline | null => {
  const { roleList, unitList = [] } = userInfo;
  const lang = sessionStorage.getItem("lang") || "zh-CN";
  const airportsRole = roleList?.find((item: Role) => item.roleType === 1);
  if (!airportsRole) {
    return null;
  }
  const airports = getPermissionItems(airportsRole);
  let unitAirportCode = "";
  if (unitList.length) {
    const firstCode = unitList[0].unitCode.split(",")[0];
    if (isAirportCode(firstCode)) {
      unitAirportCode = firstCode;
    }
  }
  if (airports === "*") {
    if (unitAirportCode) {
      return { code: unitAirportCode, name: unitAirportCode };
    } else {
      return ctripDefaultAirport;
    }
  } else if (airports.length === 0) {
    return null;
  } else {
    const unitItem = unitAirportCode
      ? airports.find((item) => item.code === unitAirportCode)
      : null;
    if (unitItem) {
      return {
        code: unitItem.code,
        name: lang === "en-US" ? unitItem.code : unitItem.name,
      };
    } else {
      return airports[0];
    }
  }
};
