import { UserProfileChart } from "@ctrip/flt-bi-flightai-airlines";
import { max } from "lodash";
import React, { ReactElement, useMemo } from "react";
import { showNum } from "Utils";
import "./MapCityRanking.scss";
import { Typography } from "antd";

export interface IMapCityRankingProps {
  data: UserProfileChart[];
}

const maxWidth = 88;

/**
 *  Component description
 */
const MapCityRanking = (props: IMapCityRankingProps): ReactElement => {
  const { data } = props;
  const maxCount = useMemo(() => {
    return max(data.map((d) => d.value)) || 0;
  }, [data]);
  return (
    <>
      {data.map((d, i) => {
        const width = d.value ? (d.value / maxCount) * maxWidth : 20;
        return (
          <div key={d.name} className="user-profile-city-ranking">
            <span className="badge" data-rnk={i + 1}>
              {i + 1}
            </span>
            <Typography.Text className="name" ellipsis={{ tooltip: d.name }}>
              {d.name || "--"}
            </Typography.Text>
            <span className="value" style={{ width }}>
              {!d.totalValue || !d.value
                ? "--"
                : showNum((d.value / d.totalValue) * 100, "percentage")}
            </span>
          </div>
        );
      })}
    </>
  );
};
export default MapCityRanking;
