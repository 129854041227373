import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import React, { ReactElement, useMemo, useRef, useState } from "react";
import DetailCards from "./DetailCard/DetailCards";
import useGlobalState from "Store";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import SearchArea from "../SearchArea";
import {
  QueryHandler,
  QueryParam,
  RouteFlightSearchParam,
  TopSearchParam,
} from "../../interface";
import useRefFunc from "Utils/useRefFunc";
import RouteFlightArea from "../RouteFlightArea";
import { getCompareDate } from "Components/Dates/CompareCom";
import FlightManageDrawer, {
  FlightManageDrawerHandler,
} from "Page/AI/FlightManage/FlightManageDrawer";
import { SelectedFlight } from "Page/AI/FlightManage/FlightManageInterface";

export interface DetailIndexProps {
  columns: FDDatasetCol[];
}

/** 航班明细 */
const DetailIndex = (props: DetailIndexProps): ReactElement => {
  const { columns } = props;
  const [globalState] = useGlobalState();
  const defaultQuery = useRef<TopSearchParam & RouteFlightSearchParam>({
    airline: globalState.airlinesQueryCondition.airlines,
    tkDate: [moment().add(-30), moment().add(-1)],
    wowType: "pre7d",
    yoyType: "weekAlign",
    yoyYear: 2019,
    flights: [],
    routes: [],
  }).current;
  const [topSearch, setTopSearch] = useState<TopSearchParam>(defaultQuery);
  const [rfSearch, setRfSearch] =
    useState<RouteFlightSearchParam>(defaultQuery);
  const cardRef = useRef<QueryHandler>(null);
  const [selectedFlight, setSelectedFlight] = useState<SelectedFlight | null>(
    null
  );
  const drawerRef = useRef<FlightManageDrawerHandler>(null);

  const searchParams = useMemo(() => {
    const wow = getCompareDate({
      currentValue: topSearch.tkDate,
      wowType: topSearch.wowType,
    });
    const yoy = getCompareDate({
      currentValue: topSearch.tkDate,
      yoyType: topSearch.yoyType,
      yoyYear: topSearch.yoyYear,
    });
    const params: QueryParam = {
      airline: topSearch.airline,
      takeoffDateStart: topSearch.tkDate[0].format(DATE_FORMAT),
      takeoffDateEnd: topSearch.tkDate[1].format(DATE_FORMAT),
      wowStart: wow ? wow[0]?.format(DATE_FORMAT) || "" : "",
      wowEnd: wow ? wow[1]?.format(DATE_FORMAT) || "" : "",
      yoyStart: yoy ? yoy[0]?.format(DATE_FORMAT) || "" : "",
      yoyEnd: yoy ? yoy[1]?.format(DATE_FORMAT) || "" : "",
      routes: rfSearch.routes,
      flights: rfSearch.flights,
    };
    return params;
  }, [topSearch, rfSearch]);
  const handleRefresh = useRefFunc(() => {
    if (cardRef.current) {
      cardRef.current.query();
    }
  });
  const handleCardClick = useRefFunc((v: SelectedFlight) => {
    setSelectedFlight(v);
    if (drawerRef.current) {
      drawerRef.current.open();
    }
  });
  return (
    <div>
      <div
        style={{
          padding: "0 20px 5px",
          marginBottom: 10,
          backgroundColor: "#fff",
        }}
      >
        <div style={{ marginBottom: 15 }}>
          <SearchArea
            query={handleRefresh}
            value={topSearch}
            onChange={setTopSearch}
          />
        </div>
        <RouteFlightArea
          value={rfSearch}
          onChange={setRfSearch}
          hasAll={false}
        />
      </div>
      <DetailCards
        columns={columns}
        ref={cardRef}
        params={searchParams}
        onCardClick={handleCardClick}
      />
      <FlightManageDrawer selectedFlight={selectedFlight} ref={drawerRef} />
    </div>
  );
};
export default DetailIndex;
