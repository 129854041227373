import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ODParam, ODTrendRes } from "./interface";
import { showNum, useFetch } from "Utils";
import { message } from "antd";
import {
  FilterKeyReplacement,
  StandardFilter,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import {
  AIRLINE_COL,
  AIRLINE_MARK,
  DATASET_ID,
  DESTINATION_AIRPORT,
  MKT_PAX_MASKING,
  ORIGIN_AIRPORT,
  PAX_MASKING,
  TAKEOFFMONTH,
  TRANSIT_AIRPORT1,
  TRANSIT_AIRPORT2,
  TRANSIT_CLASS,
} from "./ODTrendCols";
import EChartsReact from "echarts-for-react";
import FullSpin from "Components/FullSpin";
import * as echarts from "echarts";
import { ECHARTS_NODATA_OPTION } from "Constants";

export const getFilters = (param: ODParam): StandardFilter[] => {
  const tmpFilters: StandardFilter[] = [
    {
      range: {
        field: `dimension.${TAKEOFFMONTH}`,
        key: TAKEOFFMONTH,
        strRange: {
          lower: param.dateRange[0]?.format("YYYY-MM") || "",
          upper: param.dateRange[1]?.format("YYYY-MM") || "",
        },
      },
    },
  ];
  if (param.stops.length) {
    tmpFilters.push({
      in: {
        field: `dimension.${TRANSIT_CLASS}`,
        key: TRANSIT_CLASS,
        // @ts-ignore
        values: param.stops,
      },
    });
  }
  if (param.originArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${ORIGIN_AIRPORT}`,
        values: [param.originArea.areaCode || ""],
      },
    });
  }
  if (param.departArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${TRANSIT_AIRPORT1}`,
        values: [param.departArea.areaCode || ""],
      },
    });
  }
  if (param.arriveArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${TRANSIT_AIRPORT2}`,
        values: [param.arriveArea.areaCode || ""],
      },
    });
  }
  if (param.destinationArea.areaCode) {
    tmpFilters.push({
      in: {
        field: `dimension.${DESTINATION_AIRPORT}`,
        values: [param.destinationArea.areaCode || ""],
      },
    });
  }
  if (param.airline) {
    tmpFilters.push({
      in: {
        field: `dimension.${AIRLINE_COL}`,
        key: AIRLINE_COL,
        values: param.airline.split(",").concat(""),
      },
    });
  }
  return tmpFilters;
};

export interface ODTrendProps {
  param: ODParam;
  cols: FDDatasetCol[];
}

/**  Component description */
const ODTrend = (props: ODTrendProps): ReactElement => {
  const { param, cols } = props;
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [resData, setResData] = useState<ODTrendRes[]>([]);

  // #region 表格查询
  const [{ error, isLoading }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "ODTrend",
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(res.rows, res.headers);
      console.log("map: ", map);
      const airlineAttr = RequestBuilder.getMeasureMeta(PAX_MASKING);
      const marketAttr = RequestBuilder.getMeasureMeta(MKT_PAX_MASKING);
      const tmp: ODTrendRes[] = map.map((m: any) => ({
        ...m,
        marketShare:
          airlineAttr &&
          marketAttr &&
          m[airlineAttr] != null &&
          m[marketAttr] != null
            ? m[airlineAttr] / m[marketAttr]
            : null,
      }));
      setResData(tmp);
    },
    onFinish: () => {
      setIsLoadingData(false);
    },
  });

  const oriFilters = useMemo(() => {
    return getFilters(param);
  }, [param]);

  const builder = useMemo(() => {
    if (!cols.length || !oriFilters.length) {
      return null;
    }
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns: cols,
      dimensions: [TAKEOFFMONTH],
      measures: [PAX_MASKING, MKT_PAX_MASKING],
      chartFilters: [],
      containerFilters: [],
      oriFilters,
      sorters: [TAKEOFFMONTH],
    });
  }, [cols, oriFilters]);

  const { requestBody, encrypted } = useMemo(() => {
    return builder
      ? builder.getRequestBody()
      : { requestBody: null, encrypted: "" };
  }, [builder]);

  const lastParam = useRef<string>();

  const refetch = useCallback(() => {
    setIsLoadingData(true);
    setResData([]);
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetch, encrypted]);

  useEffect(() => {
    if (encrypted && encrypted !== lastParam.current) {
      refetch();
      lastParam.current = encrypted;
    }
  }, [encrypted, refetch]);

  const option = useMemo(() => {
    // if (isLoading) {
    //   return {};
    // }
    if (!resData.length) {
      return ECHARTS_NODATA_OPTION;
    }
    return {
      title: {
        text: null,
      },
      color: ["#165DFF", "#1BC6FF"],
      dataset: {
        dimension: Object.keys(resData[0]),
        source: resData,
      },
      legend: { left: 10, top: 10, itemGap: 40 },
      grid: { top: 50, bottom: 40, left: 80, right: 80 },
      tooltip: {
        trigger: "axis",
        formatter: (items: any[]) => {
          if (!items.length) {
            return null;
          }
          let x = "";
          const rows = items.map((item) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const name = item.seriesName;
            const id = item.seriesId;
            const dims = item.dimensionNames;
            const yIdx = item.encode.y[0];
            const yDim = dims[yIdx];
            const yVal = item.value[yDim];
            let yShowVal = yVal;
            if (id === "id2") {
              yShowVal = showNum(yVal * 100, "percentage");
            } else {
              yShowVal = showNum(yVal, "num");
            }
            const xIdx = item.encode.x[0];
            const xDim = dims[xIdx];
            const xVal = item.value[xDim];
            x = xVal;
            return `${dot} ${name}: <span style="font-weight: bolder">${yShowVal}</span>`;
          });
          return `${x}<br />` + rows.join("<br />");
        },
      },
      xAxis: {
        type: "category",
      },
      yAxis: [
        {
          type: "value",
          splitLine: { show: false },
        },
        {
          splitLine: { show: false },
          axisLabel: {
            formatter: (v: number) => showNum(v * 100, "percentage"),
          },
        },
      ],
      series: [
        {
          name: `Market Passenger volume`,
          id: `id1`,
          type: "bar",
          barWidth: "30%",
          encode: {
            x: [TAKEOFFMONTH],
            // y: "com_abs_SUM_pax_masking",
            y: RequestBuilder.getMeasureMeta(MKT_PAX_MASKING),
          },
        },
        {
          name: `${param.airline} Market share`,
          id: "id2",
          type: "line",
          yAxisIndex: 1,
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "rgba(27,198,255, 0.47)",
              },
              {
                offset: 1,
                color: "rgba(255, 255, 255, 0)",
              },
            ]),
          },
          smooth: true,
          encode: {
            x: [TAKEOFFMONTH],
            y: "marketShare",
          },
        },
      ],
    };
  }, [param.airline, resData]);

  if (isLoading) {
    return <FullSpin minHeight={300} />;
  }

  return <EChartsReact option={option} notMerge />;
};
export default ODTrend;
