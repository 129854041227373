import React, { useState, useEffect } from "react";
import { Modal, Descriptions, Select, Switch, Input, message } from "antd";
import { ApproveUserData } from "Interface";
import moment from "moment";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface IProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  data: ApproveUserData;
  refetch: () => void;
}

const { Option } = Select;

const ApproveModal: React.FC<IProps> = (props: IProps) => {
  const { visible, setVisible, data, refetch } = props;

  const [approveStatus, setApproveStatus] = useState(0);
  const [isSendMail, setIsSendMail] = useState(false);
  const [approveRemark, setApproveRemark] = useState("");
  const [{ isLoading }, doFetch] = useFetch({
    url: "approveUser",
    head: {
      moduleCode: "approve_user",
      chartTableCode: null,
    },
    ext: {
      userApprove: {
        ...data,
        approveStatus,
        approveRemark,
      },
      mail: isSendMail,
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_modify_success"));
      setVisible(false);
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_failed"));
    },
  });

  useEffect(() => {
    if (visible) {
      setApproveStatus(data.approveStatus);
      setApproveRemark(data.approveRemark);
      setIsSendMail(false);
    }
  }, [visible]);

  const writeMailText = (sendMail: boolean, status: number, remark: string) => {
    if (sendMail && status === 1 && !remark) {
      setApproveRemark(
        getSharkText("config_page_flightai_permission_request_accepted")
      );
    }
  };

  const handleOk = () => {
    if (approveStatus) {
      doFetch({
        head: {
          moduleCode: "approve_user",
          chartTableCode: null,
        },
        ext: {
          userApprove: {
            ...data,
            approveStatus,
            approveRemark,
          },
          mail: isSendMail,
        },
      });
    } else {
      message.warning(
        getSharkText("config_page_please_select_application_status")
      );
    }
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const handleChangeApproveStatus = (value: number) => {
    setApproveStatus(value);
    writeMailText(isSendMail, value, approveRemark);
  };

  const handleSendMail = (checked: boolean) => {
    setIsSendMail(checked);
    writeMailText(checked, approveStatus, approveRemark);
  };

  const handleChangeApproveRemark = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setApproveRemark(e.target.value);
  };

  return (
    <Modal
      width={900}
      title={getSharkText("config_page_application_form")}
      onOk={handleOk}
      okText={getSharkText("config_page_submit")}
      onCancel={handleCancel}
      confirmLoading={isLoading}
      open={visible}
    >
      <div>
        <Descriptions bordered className="modal-descriptions">
          <Descriptions.Item label={getSharkText("config_page_apply_time")}>
            {moment(data.applyTime).format("YYYY-MM-DD HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_work_unit")}>
            {data.mainUnitName}({data.mainUnitCode})
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_email")}>
            {data.mail}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_name")}>
            {data.name}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_mobile")}>
            {data.mobile}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_department")}>
            {data.depart}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_update_time")}>
            {moment(data.updateTime).format("YYYY-MM-DD HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_budget")}>
            {data.budget}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_persons")}>
            {data.persons}
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_data_cooperation_content")}
            span={3}
          >
            {data.remark}
          </Descriptions.Item>
          <Descriptions.Item label={getSharkText("config_page_status")}>
            <Select
              style={{ width: 100 }}
              value={approveStatus}
              onChange={handleChangeApproveStatus}
              dropdownMatchSelectWidth={false}
            >
              <Option value={0} disabled>
                {getSharkText("config_page_untreated")}
              </Option>
              <Option value={1}>
                <span style={{ color: "green" }}>
                  {getSharkText("config_page_opened")}
                </span>
              </Option>
              <Option value={2}>
                <span>{getSharkText("config_page_reject_application")}</span>
              </Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_send_email_notification")}
            span={2}
          >
            <Switch checked={isSendMail} onChange={handleSendMail} />
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_approval_remark")}
            span={3}
          >
            <Input.TextArea
              value={approveRemark}
              rows={4}
              placeholder={getSharkText("config_page_enter_application_remark")}
              onChange={handleChangeApproveRemark}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  );
};

export default ApproveModal;
