import { Select, Spin } from "antd";
import { IHoliday } from "Interface";
import { cloneDeep, uniqBy } from "lodash";
import moment from "moment";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { useFetch } from "Utils/useFetch";
import Refetch from "./Refetch";
import { getSharkText } from "Utils/i18nGlobal";

const { Option } = Select;

/**
 * Component description
 *
 */

interface IProps {
  onChange: (val: IHoliday | undefined) => void;
}

const Holiday = (props: IProps): ReactElement => {
  const { onChange } = props;
  const [selected, setSelected] = useState<IHoliday>();

  const [{ data, isLoading, error }, doFetch] = useFetch({
    url: "findHolidayList",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
  });

  // 如果未设置节假日, 则选中与当前日子最接近的节日
  useEffect(() => {
    if (data && data.length && !selected) {
      const dataCP: IHoliday[] = cloneDeep(data);
      dataCP.sort((d1, d2) =>
        Math.abs(moment(d1.day).diff(moment())) <
        Math.abs(moment(d2.day).diff(moment()))
          ? -1
          : 1
      );
      setSelected(dataCP[0]);
    }
  }, [data, selected]);

  useEffect(() => {
    onChange(selected);
  }, [selected, onChange]);

  const changeHoliday = (e: string, option: any) => {
    setSelected(option.data);
  };

  const uniqFes = uniqBy(data, (d: IHoliday) => d.holidayName);

  const content = useMemo(() => {
    if (isLoading) {
      return <Spin />;
    }
    if (error) {
      return <Refetch refetch={() => doFetch({ ext: {} })} />;
    }
    return (
      <Select
        style={{ width: 120 }}
        value={selected?.holidayName}
        onChange={changeHoliday}
      >
        {uniqFes.map((d: IHoliday) => (
          <Option key={d.holidayName} value={d.holidayName} data={d}>
            {d.holidayName}
          </Option>
        ))}
      </Select>
    );
  }, [doFetch, error, isLoading, selected?.holidayName, uniqFes]);

  return (
    <div>
      <span style={{ marginRight: 10 }}>
        {getSharkText("key.holidays.text")}
      </span>
      <span>{content}</span>
    </div>
  );
};
export default Holiday;
