const EbkSharkHelper = {
  get: ({ key }: { key: string }) => {
    if (window.i18n_100028128 && window.i18n_100028128[key]) {
      return window.i18n_100028128[key];
    } else {
      if (!window.i18n_100028128) {
        console.warn("shark js config is not exist");
      } else if (!window.i18n_100028128[key]) {
        console.info(`shark key: ${key} is not exist`);
      }
    }
    return key;
  },
};

export const getSharkText = (key: string): string => {
  return EbkSharkHelper.get({ key });
};
