import { EMPTY_ARRAY } from "Constants";
import { DependencyList, EffectCallback, useEffect } from "react";
import { useDebounce } from "./useDebounce";

/**
 * 批量设置更新, wait设置为0时, 会等到下一个CPU院子时间
 */
export const useBatchEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
  wait = 0
): void => {
  const fn = useDebounce(effect, wait);
  const dependencies = deps ?? EMPTY_ARRAY;
  useEffect(fn, [...dependencies, fn]);
};
