export const CHARTS_THEME = {
  color: [
    "#177DFE",
    "#01C5DB",
    "#FC9B4F",
    "#E96B5B",
    "#76CAEC",
    "#9765BA",
    "#2F9999",
    "#FF9EC6",
  ],
  tooltip: {
    trigger: "item",
    backgroundColor: "rgba(255,255,255, 0.9)",
    padding: [5, 10],
    textStyle: {
      color: "rgba(0, 0, 0, 0.65)",
      fontSize: 13,
    },
    extraCssText: "box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);",
    axisPointer: {
      type: "shadow",
      shadowStyle: {
        color: "rgba(150, 150, 150, .1)",
      },
    },
  },
  textStyle: {
    color: "#666",
  },
  xAxis: {
    axisTick: {
      alignWithLabel: true,
    },
    axisLine: {
      onZero: true,
      lineStyle: {
        color: "#666",
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
      },
    },
  },
  yAxis: [
    {
      poition: "left",
      type: "value",
      axisLine: {
        show: false,
        lineStyle: {
          color: "#666",
        },
      },
      axisTick: {
        show: false,
      },
    },
  ],
};
