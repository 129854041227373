import { ChartDataFormatter, IDownloadHeader, ISeries } from "Interface";
import { showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

export const commonColumns: IDownloadHeader[] = [
  {
    title: getSharkText("config_page_PaAiMoPa_monitoring_time"),
    dataIndex: "xAxis",
  },
  {
    title: getSharkText("key.compare.name"),
    dataIndex: "group",
  },
];

export const yoySeries: ISeries[] = [
  {
    name: getSharkText("config_page_display_price_missing_rate"),
    type: "line",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate_r1",
    },
  },
  {
    name: getSharkText("config_page_final_passenger_load_factor_missing_rate"),
    type: "line",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate_r2",
    },
  },
  {
    name: getSharkText("config_page_passenger_load_factor_missing_rate"),
    type: "line",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate_c1",
    },
  },
  {
    name: getSharkText(
      "config_page_today_target_seat_occupancy_rate_missing_rate"
    ),
    type: "line",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate_c2",
    },
  },
  {
    name: getSharkText("config_page_seat_occupancy_rate_missing_rate"),
    type: "line",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate_m1",
    },
  },
  {
    name: getSharkText("config_page_final_average_seat_loss_rate"),
    type: "line",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate_m2",
    },
  },
  {
    name: getSharkText("config_page_average_display_price_loss_rate"),
    type: "line",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate_m3",
    },
  },
];

export const yoyColumns: IDownloadHeader[] = [
  ...commonColumns,
  {
    title: getSharkText("config_page_display_price_missing_rate"),
    dataIndex: "rate_r1",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_final_passenger_load_factor_missing_rate"),
    dataIndex: "rate_r2",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_passenger_load_factor_missing_rate"),
    dataIndex: "rate_c1",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText(
      "config_page_today_target_seat_occupancy_rate_missing_rate"
    ),
    dataIndex: "rate_c2",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_seat_occupancy_rate_missing_rate"),
    dataIndex: "rate_m1",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_final_average_seat_loss_rate"),
    dataIndex: "rate_m2",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_average_display_price_loss_rate"),
    dataIndex: "rate_m3",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
];

export const yoySeries2: ISeries[] = [
  {
    name: "涨价建议占比",
    type: "bar",
    stack: "total",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "up",
    },
  },
  {
    name: "降价建议占比",
    type: "bar",
    stack: "total",
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "down",
    },
  },
  {
    name: getSharkText("config_page_original_price_suggestion_rate"),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "equal_ratio",
    },
  },
  {
    name: getSharkText("config_page_price_advice_rate"),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "diff_ratio",
    },
  },
];

export const yoyColumns2: IDownloadHeader[] = [
  ...commonColumns,
  {
    title: getSharkText("config_page_price_increase_suggestion_ratio"),
    dataIndex: "up",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_price_decrease_suggestion_ratio"),
    dataIndex: "down",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_original_price_suggestion_rate"),
    dataIndex: "equal_ratio",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_price_advice_rate"),
    dataIndex: "diff_ratio",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
];

export const yoySeries3: ISeries[] = [
  {
    name: getSharkText("config_page_suggested_production_rate"),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate0",
    },
  },
  {
    name: getSharkText("config_page_price_advice_rate"),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate1",
    },
  },
  {
    name: getSharkText("config_page_price_instruction_generation_rate"),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate2",
    },
  },
  {
    name: getSharkText("config_page_change_price_order_execution_success_rate"),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate3",
    },
  },
  {
    name: getSharkText(
      "config_page_change_price_order_execution_success_and_expected_consistency_rate"
    ),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate4",
    },
  },
  {
    name: getSharkText("config_page_request_total"),
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "num0",
    },
  },
  {
    name: getSharkText("config_page_suggestion_total"),
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "num1",
    },
  },
  {
    name: getSharkText("config_page_suggested_output_success"),
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "num2",
    },
  },
  {
    name: getSharkText("config_page_price_change_instruction"),
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "num3",
    },
  },
  {
    name: getSharkText("config_page_price_change_suggestion_execution_success"),
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "num4",
    },
  },
  {
    name: getSharkText(
      "config_page_price_instruction_execution_success_consistent"
    ),
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "num5",
    },
  },
];

export const yoySeries3extra = {
  name: "Funnel",
  type: "funnel",
  data: [
    { name: getSharkText("config_page_request_total"), value: "num0" },
    {
      name: getSharkText("config_page_suggestion_total"),
      value: "num1",
    },
    {
      name: getSharkText("config_page_suggested_output_success"),
      value: "num2",
    },
    {
      name: getSharkText("config_page_price_change_instruction"),
      value: "num3",
    },
    {
      name: getSharkText(
        "config_page_price_change_suggestion_execution_success"
      ),
      value: "num4",
    },
    {
      name: getSharkText(
        "config_page_price_instruction_execution_success_consistent"
      ),
      value: "num5",
    },
  ],
};

export const yoyColumns3: IDownloadHeader[] = [
  ...commonColumns,
  {
    title: getSharkText("config_page_suggested_production_rate"),
    dataIndex: "rate0",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_price_advice_rate"),
    dataIndex: "rate1",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_price_instruction_generation_rate"),
    dataIndex: "rate2",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText(
      "config_page_change_price_order_execution_success_rate"
    ),
    dataIndex: "rate3",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText(
      "config_page_change_price_order_execution_success_and_expected_consistency_rate"
    ),
    dataIndex: "rate4",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: getSharkText("config_page_request_total"),
    dataIndex: "num0",
  },
  {
    title: getSharkText("config_page_suggestion_total"),
    dataIndex: "num1",
  },
  {
    title: getSharkText("config_page_suggested_output_success"),
    dataIndex: "num2",
  },
  {
    title: getSharkText("config_page_price_change_instruction"),
    dataIndex: "num3",
  },
  {
    title: getSharkText(
      "config_page_price_change_suggestion_execution_success"
    ),
    dataIndex: "num4",
  },
  {
    title: getSharkText(
      "config_page_price_instruction_execution_success_consistent"
    ),
    dataIndex: "num5",
  },
];

export const yoySeries4: ISeries[] = [
  {
    name: getSharkText("config_page_small_cabin_used_rules"),
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "rule_count",
    },
  },
  {
    name: getSharkText(
      "config_page_change_instruction_small_cabin_success_rate"
    ),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "change_rate",
    },
  },
  {
    name: getSharkText("config_page_small_cabin_setting_success_rate"),
    type: "line",
    yAxisIndex: 1,
    formatterType: ChartDataFormatter.Percent,
    encode: {
      x: "xAxis",
      y: "rate",
    },
  },
];

export const yoyColumns4: IDownloadHeader[] = [
  ...commonColumns,
  {
    title: getSharkText("config_page_small_cabin_used_rules"),
    dataIndex: "rule_count",
  },
  {
    title: getSharkText(
      "config_page_change_instruction_small_cabin_success_rate"
    ),
    dataIndex: "change_rate",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
  {
    title: "小舱设置成功率",
    dataIndex: "rate",
    render: (v) => showNum(v * 100, "percentage"),
    downloadFormatter: (v) => showRawNum(v * 100, "percentage"),
  },
];
