import { Tag } from "antd";
import { Area } from "Interface";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import useGlobalState from "Store";
import { defaultArea } from "../common";
import MytrixCitySelector from "Page/AI/FreeDashboard/Components/MytrixCitySelector";

export interface IAreaSelectorProps {
  value?: Area[];
  onChange?: (v: Area[]) => void;
  areaSource: Area[];
}

/**
 * 客源地筛选器
 */
const AreaSelector = (props: IAreaSelectorProps): ReactElement => {
  const { value: selectedTags, onChange: setSelectedTags, areaSource } = props;
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const { flightClass } = queryCondition;
  const [area, setDepartArea] = useState<Area>(defaultArea);

  const setFn = useRef(setSelectedTags);

  // #region 增加选中项
  // 选中任意项后, 加入列表, 并重新生成选择器, 不能加selectedTags为依赖
  useEffect(() => {
    if (!setFn.current) {
      return;
    }
    if (area !== defaultArea) {
      const old = selectedTags || [];
      if (!old.find((o) => o.areaCode === area.areaCode)) {
        setFn.current([...old, area]);
      }
      setTimeout(() => {
        setDepartArea(defaultArea);
      }, 100);
    }
  }, [area]);
  // #endregion

  const onClose = useCallback(
    (idx: Area | null) => {
      if (idx && selectedTags && setFn.current) {
        const ss = selectedTags.filter((s) => {
          return s !== idx;
        });
        if (ss.length !== selectedTags.length) {
          setFn.current(ss);
        }
      }
    },
    [selectedTags]
  );
  return (
    <div>
      {selectedTags &&
        selectedTags.map((t) => (
          <Tag
            key={`${t.areaType}_${t.areaCode}`}
            closable
            onClose={() => onClose(t)}
          >
            {t.areaName}
          </Tag>
        ))}
      <span style={{ display: "inline-block" }}>
        {/* {area === defaultArea && areaSource && (
          <CascaderArea
            setSelectedArea={setDepartArea}
            flightClass={flightClass === 1 ? 1 : 0}
            type="airport"
            isDemo={false}
            dataSource={areaSource}
          />
        )} */}
        <MytrixCitySelector
          flightClass={flightClass === 1 ? 1 : 0}
          onChange={(v) => v && setDepartArea(v)}
        />
      </span>
    </div>
  );
};
export default AreaSelector;
