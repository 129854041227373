import React, { useCallback, useEffect, useState } from "react";

interface IProps {
  setVerifyInfo: (v: any) => void;
  /**
   * 同个页面内显示多个验证码, ID必须不同
   */
  id?: string;
}

const VerifyCode = (props: IProps) => {
  const { setVerifyInfo, id = "verification-code" } = props;
  const [slideObj, setSlideObj] = useState();
  const init = useCallback(() => {
    if (slideObj) {
      //   @ts-ignore // 如果涉及在同一个div多次渲染验证码，可以加个判断来清除
      slideObj.clear();
    }
    setSlideObj(
      //   @ts-ignore
      new slidingVerification({
        // 验证码DIV的id
        id,
        // appId
        appId: "100026391",
        // businessSite
        businessSite: "flightai_login_online",
        // 可选填像素字符串或百分数字符串，如'280px'或'80%'，默认值'280px'
        width: "100%",
        // 滑块长度，可选，填像素字符串，如'40px'，默认值'40px'
        height: "40px",
        // 语种，默认‘zh-CN’（简体中文）
        language: "zh-CN",
        // language: "en-US",
        // select_language: "en-US",
        // 选字语种，默认‘zh-CN’（简体中文）
        select_language: "zh-CN",
        chooseOpt: {
          // 选字验证码定位，可选，默认absolute（绝对定位），可选fixed（相对于浏览器定位） ~2.5.0中新增
          position: "fixed",
          // 选字图片宽度，可选，选字验证码 大图的宽度
          width: "280px",
          // 选字图片高度，可选，选字验证码 大图的高度 如果选字width，height不填，生成的图片将根据后端默认配置进行屏幕大小自适应；如果选字width，height填写了，需要先找 ldq刘丹青 进行配置响应大小的图片
          height: "200px",
          // "over"： 浮窗(默认)；"pop"：弹出
          type: "pop",
        },
        resultHandler: (e: any) => {
          setVerifyInfo({
            token: e.token,
            version: e.version,
            requestId: e.rid,
          });
        },
      })
    );
  }, [setVerifyInfo, slideObj]);

  useEffect(() => {
    setTimeout(() => {
      init();
    }, 200);
  }, []);

  const refresh = () => {
    if (slideObj) {
      //   @ts-ignore
      slideObj.refresh();
    }
  };

  return (
    <div className="form-group has-feedback validcode-div">
      <div id={id}></div>
    </div>
  );
};

export default VerifyCode;
