import CryptoJS from "crypto-js";

export const AESPWD_PREFIX = "gC7gE1fC";
export const CRYPTOGRAPHY_VERSION = "flightai-1";
export default class AesUtil {
  private keySize: number;
  private iterationCount: number;
  private defaultSalt = "1234";
  private defaultIv = "00000000000000000000000000000000";
  private defaultPassPhrase = "flight.ctrip.com";

  constructor(keySize = 128, iterationCount = 1000) {
    this.keySize = keySize / 32;
    this.iterationCount = iterationCount;
  }

  private generateKey(salt: string, passPhrase: string) {
    const key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), {
      keySize: this.keySize,
      iterations: this.iterationCount,
    });
    return key;
  }

  public encrypt(
    plainText: string,
    passPhrase = this.defaultPassPhrase,
    salt = this.defaultSalt,
    iv = this.defaultIv
  ): string {
    const key = this.generateKey(salt, passPhrase);
    const encrypted = CryptoJS.AES.encrypt(plainText, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  }

  public decrypt(
    cipherText: string,
    passPhrase = this.defaultPassPhrase,
    salt = this.defaultSalt,
    iv = this.defaultIv
  ): string {
    const key = this.generateKey(salt, passPhrase);
    const cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(cipherText),
    });
    const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
      iv: CryptoJS.enc.Hex.parse(iv),
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }
}

//@ts-ignore
window.AESUtil = AesUtil;
