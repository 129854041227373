import React, { useState } from "react";
import { ReactComponent as Number1 } from "assets/svg/01.svg";
import { ReactComponent as Number2 } from "assets/svg/02.svg";
import { ReactComponent as Number3 } from "assets/svg/03.svg";
import { ReactComponent as Number4 } from "assets/svg/04.svg";
import { ReactComponent as Number5 } from "assets/svg/05.svg";
import xinjiang from "assets/images/xinjianglogo.png";
import xianggang from "assets/images/xiangganglogo.png";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Side from "Components/Official/Side";
import "./index.scss";

const Advisory: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <>
      <div className="top-banner advisory-banner">
        <div className="base-con">
          <div className="banner-con">
            <h1>行业咨询</h1>
            <h4>基于数据分析提供优化建议，赋能业务增长</h4>
          </div>
        </div>
      </div>
      <div className="base-mode advisory-mod">
        <div className="base-con">
          <h2 className="base-title">分析与咨询</h2>
          <h5 className="base-title-hint">
            以月报、专题咨询分析等形式，提供专业数据咨询服务
            <br />
            为日常数据监测及解读、航线规划、市场营销、客源引流等决策工作提供数据支持
          </h5>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info advisory-list-bg1">
              <Number1 className="list-number" />
              <div className="list-title">客源构成分析</div>
              <p>
                基于旅客画像数据，分析旅客的出行目的、价格敏感度、客源地分布、航空偏好等各项特征，帮助了解客源构成，评估潜在增长点
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-even-list">
          <div className="base-con">
            <div className="list-info advisory-list-bg2">
              <Number2 className="list-number" />
              <div className="list-title">航线网络规划</div>
              <p>
                从航线分布、时刻分布、航线新增与停飞等维度进行多维分析。通过横向纵向的对比分析，提供航线网络的现状分析与未来展望。
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info advisory-list-bg3">
              <Number3 className="list-number" />
              <div className="list-title">日常运营分析</div>
              <p>
                年度/季度/月度的数据趋势分析和基本情况解读。全方位了解行业整体趋势、自身发展现状。
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-even-list">
          <div className="base-con">
            <div className="list-info advisory-list-bg4">
              <Number4 className="list-number" />
              <div className="list-title">新开航线分析</div>
              <p>
                从市场需求、地理位置、出行目的等方面进行多维分析。针对票价、运力投放、旅客来源等角度对开航可行性提出建议。
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info advisory-list-bg5">
              <Number5 className="list-number" />
              <div className="list-title">其他专项分析</div>
              <p>
                市场竞争力分析、中长期规划分析等其他定制化咨询服务。提供专项数据分析、解决实际问题
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="base-mode advisory-mod">
        <div className="base-con">
          <h2 className="base-title">海量数据储备</h2>
          <h5 className="base-title-hint">
            拥有OTA独家数据，掌握行业情况，洞察用户特征，挖掘用户需求
            <br />
            结合优质的数据资源、强大的技术优势、丰富的行业经验，基于数据分析提供解决方案，赋能业务增长
          </h5>
          <ul className="advisory-store">
            <li>
              <h4>用户需求数据</h4>
              <p>
                拥有各项用户出行数据，覆盖搜索/购买/出行等全面了解需求情况，预知未来市场趋势。
              </p>
            </li>
            <li>
              <h4>市场供给数据</h4>
              <p>
                丰度的市场数据，包括航班计划、运力安排、航班架次。了解整体市场情况。
              </p>
            </li>
            <li>
              <h4>用户画像数据</h4>
              <p>
                长期积累的基础数据，可用于生成旅客画像，了解用户需求，挖掘旅客特征。
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="advisory-example">
        <div className="base-con">
          <h2>部分案例</h2>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
            forceRenderTabPanel
          >
            <TabList>
              <Tab onMouseOver={() => setTabIndex(0)}>香港国际机场</Tab>
              <Tab onMouseOver={() => setTabIndex(1)}>新疆机场集团</Tab>
            </TabList>
            <TabPanel>
              <div className="example-info">
                <div className="example-log">
                  <img src={xianggang} alt="香港" />
                </div>
                <h4>香港国际机场</h4>
                <h5>《大珠三角机场用户行为习惯研究》</h5>
              </div>
              <div className="example-detail">
                <p>
                  <strong>项目背景</strong>
                  <span>
                    发挥香港国际机场对粤港澳大湾区的重要作用，巩固香港国际航空枢纽地位，提升香港机场客运量。
                  </span>
                </p>
                <p>
                  <strong>研究成果</strong>
                  <span>
                    从客源地、市场份额、用户机场选择偏好、空铁联运等角度帮助香港机场了解目标旅客的特点、行为习惯等，帮助香港机场制定恰当的业务方针，对如何提升旅客忠诚度，提升香港机场的整体竞争力提出可行的建议。
                  </span>
                </p>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="example-info bg2">
                <div className="example-log">
                  <img src={xinjiang} alt="xinjiang" />
                </div>
                <h4>新疆机场集团</h4>
                <h5>《新疆第二机场规划研究》</h5>
              </div>
              <div className="example-detail">
                <p>
                  <strong>项目背景</strong>
                  <span>
                    乌鲁木齐机场容量趋于饱和，整体改扩建工作要持续 到 2023
                    年完成。乌鲁木齐机场改扩建期间，需要科学规划是否需要新建或打造新疆第二机场，并合理规划发展路径。在乌鲁木齐机场改扩建完成之后，进一步规划疆内机场如何协同发展。
                  </span>
                </p>
                <p>
                  <strong>研究成果</strong>
                  <span>
                    通过分析明确建设新疆第二枢纽机场的可行性和必要性，制定第二枢纽建设时序规划。通过分析如何解决好次枢纽，机场容量持续提升，各机场间环飞串飞，直飞内地，备降等问题为新疆第二机场发展提供远期的规划与建议。
                  </span>
                </p>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
      <Side />
    </>
  );
};

export default Advisory;
