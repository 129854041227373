import React, { useState } from "react";
import Button from "Components/Official/Button";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ReactComponent as Number1 } from "assets/svg/01.svg";
import { ReactComponent as Number2 } from "assets/svg/02.svg";
import { ReactComponent as Number3 } from "assets/svg/03.svg";
import Side from "Components/Official/Side";
import "./index.scss";
import { Link } from "react-router-dom";

const Market: React.FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  return (
    <>
      <div className="top-banner market-banner">
        <div className="base-con">
          <div className="banner-con">
            <h1>市场洞察平台</h1>
            <h4>面向机场、航司等行业合作伙伴的专业数据平台。</h4>
            <Link to="/dashboard">
              <Button fill>立即体验</Button>
            </Link>
          </div>
        </div>
      </div>

      <div className="wrap">
        <div className="base-mode market-intro">
          <h2 className="base-title">专业的数据平台</h2>
          <h5 className="base-title-hint">
            整合基础数据，连接数据孤岛，充分挖掘数据价值。
            <br />
            提炼各类高频需求，并将常用分析思路融入其中。
            随时登录，即可满足日常监测和专题分析需求。
          </h5>
          <div className="intro-con">
            <div className="intro-list">
              <h4>优质的数据资源</h4>
              <ul className="row">
                <li>
                  <span>搜索数据</span>
                </li>
                <li>
                  <span>运力数据</span>
                </li>
                <li>
                  <span>交易数据</span>
                </li>
                <li>
                  <span>航班信息</span>
                </li>
                <li>
                  <span>用户数据</span>
                </li>
                <li>...</li>
              </ul>
            </div>
            <div className="intro-list">
              <h4>强大的数据能力</h4>
              <ul>
                <li>
                  <span>打通数据孤岛</span>
                </li>
                <li>
                  <span>挖掘数据价值</span>
                </li>
                <li>
                  <span>可视化呈现</span>
                </li>
                <li>
                  <span>即席查询</span>
                </li>
              </ul>
            </div>
            <div className="intro-list">
              <h4>定制化数据平台</h4>
              <ul>
                <li>
                  <span>核心指标</span>
                </li>
                <li>
                  <span>搜索趋势</span>
                </li>
                <li>
                  <span>行业数据</span>
                </li>
                <li>
                  <span>专题分析</span>
                </li>
              </ul>
            </div>
            <div className="intro-list">
              <h4>广泛的应用场景</h4>
              <ul>
                <li>
                  <span>专题分析</span>
                </li>
                <li>
                  <span>日常分析</span>
                </li>
                <li>
                  <span>决策支持</span>
                </li>
              </ul>
            </div>
            <div className="intro-list">
              <h4>完善的系统支持</h4>
              <ul>
                <li>
                  <span>每日及时更新</span>
                </li>
                <li>
                  <span>持续优化迭代</span>
                </li>
                <li>
                  <span>严控数据安全</span>
                </li>
                <li>
                  <span>防止数据泄露</span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="base-mode advantage-mod">
          <h2 className="base-title">优势特色</h2>
          <div className="advantage-con">
            <Number1 className="advantage-num" />
            <h3>场景化的专题模块</h3>
            <p className="hint">结合实际业务场景，充分挖掘数据价值</p>
            <div className="advantage-tab-wrap">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index: number) => setTabIndex(index)}
                forceRenderTabPanel
              >
                <TabList>
                  <Tab onMouseOver={() => setTabIndex(0)}>
                    <p>
                      <strong>核心概览</strong>
                    </p>
                    <p className="hint">
                      直观呈现核心指标走势、航线网络布局。宏观掌握整体概貌。
                    </p>
                  </Tab>
                  <Tab onMouseOver={() => setTabIndex(1)}>
                    <p>
                      <strong>航线分析</strong>
                    </p>
                    <p className="hint">
                      核心指标观察，衡量数据表现，辅助航线网络规划，多维分析数据波动原因。
                    </p>
                  </Tab>
                  <Tab onMouseOver={() => setTabIndex(2)}>
                    <p>
                      <strong>搜索指数</strong>
                    </p>
                    <p className="hint">
                      反映用户真实出行意愿，辅助预判市场需求情况；识别有用户需求但无运力供给的航线，挖掘潜在机会。
                    </p>
                  </Tab>
                  <Tab onMouseOver={() => setTabIndex(3)}>
                    <p>
                      <strong>航班计划</strong>
                    </p>
                    <p className="hint">
                      未来起飞日的航班计划和临时变化，及时知晓航班动态，便于做出策略调整。
                    </p>
                  </Tab>
                </TabList>
                <TabPanel>
                  <i className="advantage-con-bg1" />
                </TabPanel>
                <TabPanel>
                  <i className="advantage-con-bg2" />
                </TabPanel>
                <TabPanel>
                  <i className="advantage-con-bg3" />
                </TabPanel>
                <TabPanel>
                  <i className="advantage-con-bg4" />
                </TabPanel>
              </Tabs>
            </div>
          </div>

          <div className="advantage-con">
            <Number2 className="advantage-num" />
            <h3>丰富的指标和维度</h3>
            <p className="hint">
              涵盖运力安排、用户搜索/购买/出行等指标，时间跨度覆盖历史及未来，辅助进行市场供需分析、经营情况复盘、未来趋势前瞻
            </p>
            <div className="advantage-bannner2"></div>
          </div>

          <div className="advantage-con">
            <Number3 className="advantage-num" />
            <h3>直观的可视化呈现方案</h3>
            <p className="hint">
              平台包含航线图、趋势图、排行榜、表格等多种可视化视图，数据展示清晰直观，一目了然
            </p>
            <div className="advantage-bannner3"></div>
          </div>
        </div>
      </div>

      <div className="invite-mod">
        <div className="base-con">
          <h3>FlightAI 邀您开启民航数据洞察之旅</h3>
          <Link to="/dashboard">
            <Button className="invite-button">立即体验</Button>
          </Link>
        </div>
      </div>
      <Side />
    </>
  );
};

export default Market;
