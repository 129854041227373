import { Tabs } from "antd";
import Page from "Layout/Page";
import NotificationCenter from "Page/AI/NotificationCenter";
import React, { ReactElement } from "react";
import { useQueryString } from "Utils/query/useQueryString";
import FlightManageDrawer from "../FlightManage/FlightManageDrawer";
import LoadContent from "./Components/LoadContent";
import PanelContent from "./Components/PanelContent";
import { EPanelType } from "./MonitoringAlertingCommon";
import { getSharkText } from "Utils/i18nGlobal";

const { TabPane } = Tabs;
/**
 * Component description
 * 收益管理-监控预警页面
 */

const MonitoringAlerting = (): ReactElement => {
  const [tab] = useQueryString("tab", "0");
  const [loadTab] = useQueryString("loadTab", "0");
  return (
    <Page defaultDateMode="none" needToolBar={false}>
      <div className="content-white" style={{ marginTop: 0, padding: 0 }}>
        <Tabs
          defaultActiveKey={tab}
          tabBarGutter={20}
          tabBarStyle={{ margin: "0 20px" }}
        >
          <TabPane
            tab={getSharkText("config_page_capacity")}
            key={EPanelType.Capacity}
          >
            <PanelContent panelType={EPanelType.Capacity} />
          </TabPane>
          <TabPane
            tab={getSharkText("config_page_search_warning")}
            key={EPanelType.Search}
          >
            <PanelContent panelType={EPanelType.Search} />
          </TabPane>
          {/* <TabPane tab="航班取消预警" key={EPanelType.Change}>
            <PanelContent panelType={EPanelType.Change} />
          </TabPane> */}
          <TabPane
            tab={getSharkText("config_page_load_warning")}
            key={EPanelType.Load}
          >
            <Tabs
              defaultActiveKey={loadTab}
              tabBarGutter={20}
              tabBarStyle={{ paddingLeft: 150, margin: "-5px 20px 0px" }}
            >
              <TabPane tab={getSharkText("menu_load_factor")} key="0">
                <LoadContent />
              </TabPane>
              <TabPane tab={getSharkText("config_page_guest_speed")} key="1">
                <PanelContent panelType={EPanelType.Speed} />
              </TabPane>
            </Tabs>
          </TabPane>
          <TabPane
            tab={getSharkText("config_page_price_warning")}
            key={EPanelType.Price}
          >
            <PanelContent panelType={EPanelType.Price} />
          </TabPane>
          {/* <TabPane tab="自主组合" key={EPanelType.Custom}>
            <CustomPanel panelType={EPanelType.Custom} />
          </TabPane> */}
        </Tabs>
      </div>
      <NotificationCenter />
      <FlightManageDrawer />
    </Page>
  );
};
export default MonitoringAlerting;
