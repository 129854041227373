import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import OverviewCards from "./OverviewCard/OverviewCards";
import RouteFlightArea from "../RouteFlightArea";
import {
  QueryHandler,
  QueryParam,
  RouteFlightSearchParam,
  TopSearchParam,
} from "../../interface";
import moment from "moment";
import useGlobalState from "Store";
import { DATE_FORMAT, DATE_SECOND_FORMAT } from "Constants";
import SearchArea from "../SearchArea";
import useRefFunc from "Utils/useRefFunc";
import { getCompareDate } from "Components/Dates/CompareCom";
import { Alert, Col, Row } from "antd";
import OverviewLine from "./OverviewLine";
import OverviewTable from "./OverviewTable";
import {
  FilterValue,
  Measure,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import {
  AIRLINE_NAME,
  DATACHANGE_LASTTIME_LONG_NAME,
  DATASET_ID_NEWEST,
  FLIGHTNO_NAME,
  ROUTE_NAME,
  TAKEOFFDATE_NAME,
} from "../DatasetCols";
import { useFetch } from "Utils";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";

interface UpdateRes {
  airline: string;
  MIN_datachange_lasttime_long: number;
  MAX_datachange_lasttime_long: number;
}
export interface OverviewIndexProps {
  columns: FDDatasetCol[];
}

/** 汇总情况 */
const OverviewIndex = (props: OverviewIndexProps): ReactElement => {
  const { columns } = props;
  const [globalState] = useGlobalState();
  const defaultQuery = useRef<TopSearchParam & RouteFlightSearchParam>({
    airline: globalState.airlinesQueryCondition.airlines,
    tkDate: [moment().add(-30), moment().add(-1)],
    wowType: "pre7d",
    yoyType: "weekAlign",
    yoyYear: 2019,
    flights: [],
    routes: [],
  }).current;
  const [topSearch, setTopSearch] = useState<TopSearchParam>(defaultQuery);
  const [rfSearch, setRfSearch] =
    useState<RouteFlightSearchParam>(defaultQuery);

  const cardRef = useRef<QueryHandler>(null);
  const lineRef = useRef<QueryHandler>(null);
  const tableRef = useRef<QueryHandler>(null);

  const searchParams = useMemo(() => {
    const wow = getCompareDate({
      currentValue: topSearch.tkDate,
      wowType: topSearch.wowType,
    });
    const yoy = getCompareDate({
      currentValue: topSearch.tkDate,
      yoyType: topSearch.yoyType,
      yoyYear: topSearch.yoyYear,
    });
    const params: QueryParam = {
      airline: topSearch.airline,
      takeoffDateStart: topSearch.tkDate[0].format(DATE_FORMAT),
      takeoffDateEnd: topSearch.tkDate[1].format(DATE_FORMAT),
      wowStart: wow ? wow[0]?.format(DATE_FORMAT) || "" : "",
      wowEnd: wow ? wow[1]?.format(DATE_FORMAT) || "" : "",
      yoyStart: yoy ? yoy[0]?.format(DATE_FORMAT) || "" : "",
      yoyEnd: yoy ? yoy[1]?.format(DATE_FORMAT) || "" : "",
      routes: rfSearch.routes,
      flights: rfSearch.flights,
    };
    return params;
  }, [topSearch, rfSearch]);

  const commonFilters = useMemo(() => {
    const rst: FilterValue[] = [];
    const airlineFilter: FilterValue = {
      filterId: "airline",
      filterType: "select",
      argOfReports: [
        {
          panelId: "1",
          columnName: AIRLINE_NAME,
          values: [searchParams.airline],
        },
      ],
    };
    const tkFilter: FilterValue = {
      filterId: "takeoffdate",
      filterType: "date",
      argOfReports: [
        {
          panelId: "1",
          columnName: TAKEOFFDATE_NAME,
          values: {
            current: [
              searchParams.takeoffDateStart,
              searchParams.takeoffDateEnd,
            ],
          },
        },
      ],
    };
    const routeFilter: FilterValue = {
      filterId: "route",
      filterType: "select",
      argOfReports: [
        {
          panelId: "1",
          columnName: ROUTE_NAME,
          values: searchParams.routes,
        },
      ],
    };
    const flightsFilter: FilterValue = {
      filterId: "flights",
      filterType: "select",
      argOfReports: [
        {
          panelId: "1",
          columnName: FLIGHTNO_NAME,
          values: searchParams.flights,
        },
      ],
    };
    rst.push(airlineFilter);
    rst.push(tkFilter);
    if (searchParams.routes.length) {
      rst.push(routeFilter);
    }
    if (searchParams.flights.length) {
      rst.push(flightsFilter);
    }
    return rst;
  }, [
    searchParams.airline,
    searchParams.flights,
    searchParams.routes,
    searchParams.takeoffDateEnd,
    searchParams.takeoffDateStart,
  ]);

  const updateTimeMeasures = useRef<Measure[]>([
    {
      id: DATACHANGE_LASTTIME_LONG_NAME + "max",
      columnName: DATACHANGE_LASTTIME_LONG_NAME,
      measureConfig: {
        statisticalConfig: {
          method: "MAX",
        },
      },
    },
    {
      id: DATACHANGE_LASTTIME_LONG_NAME + "min",
      columnName: DATACHANGE_LASTTIME_LONG_NAME,
      measureConfig: {
        statisticalConfig: {
          method: "MIN",
        },
      },
    },
    // {
    //   id: UPDATE_MAX,
    //   columnName: UPDATE_MAX,
    //   measureConfig: {
    //     statisticalConfig: {
    //       method: "SUM",
    //     },
    //   },
    // },
  ]).current;

  const builder = useMemo(() => {
    return new RequestBuilder({
      datasetId: DATASET_ID_NEWEST,
      columns,
      chartFilters: [],
      containerFilters: commonFilters,
      dimensions: [
        {
          columnName: AIRLINE_NAME,
          dimensionConfig: { type: "row", calculateConfig: null },
        },
      ],
      measures: updateTimeMeasures,
      // measures: [],
      limit: 1,
      sorters: [],
    });
  }, [columns, commonFilters, updateTimeMeasures]);

  const { requestBody, encrypted } = useMemo(() => {
    return builder.getRequestBody();
  }, [builder]);

  const [updateMap, setUpdateMap] = useState<{ max: string; min: string }>();
  const [{}, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    lazey: true,
    debugId: "overview-update-time",
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const map = DataRow2ListMap(
        res.rows,
        res.headers
      ) as unknown as UpdateRes[];
      if (map.length) {
        const item = map[0];
        const max = item.MAX_datachange_lasttime_long
          ? moment(item.MAX_datachange_lasttime_long * 1000).format(
              DATE_SECOND_FORMAT
            )
          : "";
        const min = item.MIN_datachange_lasttime_long
          ? moment(item.MIN_datachange_lasttime_long * 1000).format(
              DATE_SECOND_FORMAT
            )
          : "";
        setUpdateMap({ max, min });
      } else {
        setUpdateMap(undefined);
      }
    },
  });

  const refreshUpdateTime = useCallback(() => {
    setUpdateMap(undefined);
    doFetch({
      ext: {
        datasetId: DATASET_ID_NEWEST,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetch, encrypted]);

  useEffect(() => {
    refreshUpdateTime();
  }, [refreshUpdateTime]);

  const handleRefresh = useRefFunc(() => {
    if (cardRef.current) {
      cardRef.current.query();
    }
    if (lineRef.current) {
      lineRef.current.query();
    }
    if (tableRef.current) {
      tableRef.current.query();
    }
    refreshUpdateTime();
  });

  const description = `数据更新时间范围：${updateMap?.min ?? "-"} ~ ${
    updateMap?.max ?? "-"
  }`;

  return (
    <div>
      <div
        style={{
          padding: "0 20px 5px",
          marginBottom: 10,
          backgroundColor: "#fff",
        }}
      >
        <div style={{ marginBottom: 15 }}>
          <SearchArea
            query={handleRefresh}
            value={topSearch}
            onChange={setTopSearch}
          />
        </div>
        <RouteFlightArea value={rfSearch} onChange={setRfSearch} />
      </div>
      <Alert message={description} type="info" banner />
      <OverviewCards
        commonFilters={commonFilters}
        columns={columns}
        ref={cardRef}
        params={searchParams}
      />
      <Row style={{ marginTop: 10 }} gutter={[10, 10]}>
        <Col span={12}>
          <OverviewLine columns={columns} params={searchParams} ref={lineRef} />
        </Col>
        <Col span={12}>
          <OverviewTable
            columns={columns}
            params={searchParams}
            ref={tableRef}
          />
        </Col>
      </Row>
    </div>
  );
};
export default OverviewIndex;
