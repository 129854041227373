import React from "react";
import { Spin } from "antd";
import { COMPARE_TYPE_VALUE_NAME } from "Constants";
import {
  getCardTrendText,
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  showNum,
  toThousands,
  U,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode, ECompareTypeV2, SystemType } from "Interface";
import _ from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  day: string;
  value: number;
  compareValue: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  systemType: SystemType;
  compareType: ECompareTypeV2;
  loading: boolean;
  isPercentVal: boolean;
  aggCode: AggCode;
  startDate: string;
  endDate: string;
  airlines: string;
  cardCode?: string;
  isDemo: boolean;
  ext?: any;
}

const BarCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const {
    data,
    compareType,
    height = 300,
    loading,
    isPercentVal,
    aggCode,
    startDate,
    endDate,
    systemType,
    airlines,
    cardCode,
    isDemo,
    ext,
  } = props;

  const seriesValueName =
    systemType === 2 && compareType === 1
      ? airlines
      : getSharkText("charts.current_period.seriesvaluename");
  const seriesResultValueName = getCardTrendText(
    cardCode,
    systemType,
    compareType
  );
  const seriesCompareValueName =
    systemType === 2 && compareType === 1
      ? getSharkText("charts.market.seriesvaluename")
      : COMPARE_TYPE_VALUE_NAME[compareType];
  const isZero = (val: number) => val === 0;
  const option = {
    dataset: {
      source: data,
    },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          isDemo
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          let val = "";
          if (item.componentSubType === "bar") {
            // if (item.componentSubType === 'line') {
            val = `${item.seriesName}: ${
              !isZero(item.data.resultValue) ? item.data.resultValue + "%" : "-"
            }`;
          } else if (item.seriesName === seriesValueName) {
            val = `${item.seriesName}: ${
              !isZero(item.data.value)
                ? isPercentVal
                  ? item.data.value + "%"
                  : [
                      "kilo_passenger_traffic",
                      "kilo_transport_capacity",
                    ].includes(cardCode || "") &&
                    Math.abs(item.data.value) > 10 * 1000 * 1000
                  ? U.abbreviateNumber(item.data.value)
                  : [
                      "capacity_production_cost",
                      "income_production_cost",
                    ].includes(cardCode || "")
                  ? showNum(item.data.value, "num", 3)
                  : toThousands(item.data.value)
                : "-"
            }`;
          } else {
            val = `${item.seriesName}: ${
              !isZero(item.data.compareValue)
                ? isPercentVal
                  ? item.data.compareValue + "%"
                  : [
                      "kilo_passenger_traffic",
                      "kilo_transport_capacity",
                    ].includes(cardCode || "") &&
                    Math.abs(item.data.value) > 10 * 1000 * 1000
                  ? U.abbreviateNumber(item.data.value)
                  : toThousands(item.data.compareValue)
                : "-"
            }`;
          }
          dom = dom + "<br />" + dot + val;
        });
        const prefix =
          ext?.axisType === 1
            ? getSharkText("charts.date_of_search")
            : getSharkText("charts.date_of_departure");
        dom = `${prefix}：` + day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    yAxis: [
      {
        position: "right",
        type: "value",
        splitNumber: 3,
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) => `${value} %`,
        },
        axisTick: {
          show: false,
        },
        max: (val: any) => {
          const max =
            Math.abs(val.max) > Math.abs(val.min)
              ? Math.abs(val.max)
              : Math.abs(val.min);
          const num = _.round(max * 1.2) > 1 ? _.round(max * 1.2) : 1;
          return num;
        },
        min: (val: any) => {
          const min =
            Math.abs(val.max) > Math.abs(val.min)
              ? -Math.abs(val.max)
              : -Math.abs(val.min);
          const num = -_.round(min * 1.2) > 1 ? _.round(min * 1.2) : -1;
          return num;
        },
      },
      {
        position: "left",
        type: "value",
        splitLine: false,
        splitNumber: 3,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) => {
            return `${
              isPercentVal
                ? value + "%"
                : [
                    "kilo_passenger_traffic",
                    "kilo_transport_capacity",
                  ].includes(cardCode || "") &&
                  Math.abs(value) > 10 * 1000 * 1000
                ? U.abbreviateNumber(value)
                : value
            }`;
          },
        },
        axisTick: {
          show: false,
        },
        max: (val: any) => val.max,
        min: (val: any) => -val.max,
      },
    ],
    series: [
      {
        type: "line",
        encode: { x: "day", y: "value" },
        lineStyle: {
          width: 3,
        },
        name: seriesValueName,
        yAxisIndex: 1,
        showSymbol: false,
        symbol: "circle",
        // barMaxWidth: 20,
        // barGap: 0
      },
      {
        type: "line",
        encode: { x: "day", y: "compareValue" },
        lineStyle: {
          width: 3,
        },
        name: seriesCompareValueName,
        yAxisIndex: 1,
        showSymbol: false,
        symbol: "circle",
        // barMaxWidth: 20,
      },
      {
        type: "bar",
        encode: { x: "day", y: "resultValue" },
        barMaxWidth: 20,
        name: seriesResultValueName,
        // lineStyle: {
        //     width: 3
        // },
        // showSymbol: false,
        // symbol: 'circle'
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        style={{ height }}
      />
    </Spin>
  );
};

export default BarCharts;
