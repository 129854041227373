import { InputNumber } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import useRefFunc from "Utils/useRefFunc";
import { useDebounce } from "Utils/useDebounce";

export interface IProps {
  onChange: (v: [number | null, number | null]) => void;
}

/**
 * 自由报表-数据范围过滤器的展示组件
 */
const NumberRange = (props: IProps): ReactElement => {
  const { onChange } = props;
  const [start, setStart] = useState<number | null>(null);
  const [end, setEnd] = useState<number | null>(null);

  const onChangeFn = useRefFunc(onChange);

  const deb = useDebounce(() => onChangeFn([start, end]), 500);

  useEffect(() => {
    deb();
  }, [deb, start, end]);

  return (
    <>
      <InputNumber value={start} onChange={setStart} step={0.1} />
      ~
      <InputNumber value={end} onChange={setEnd} step={0.1} />
    </>
  );
};
export default NumberRange;
