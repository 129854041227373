import { EditableProTable } from "@ant-design/pro-table";
import { Button, Col, Input, message, Modal, Row, Select, Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { ERROR_CODE, TOKEN, UID } from "Constants";
import Page from "Layout/Page";
import moment from "moment";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { getCookie, getIsDemo, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { downloadExcel } from "Utils/downloadXLSX";
import {
  columns,
  IDataType,
  ILogType,
  INewDataType,
  logColumns,
} from "./columns";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";

const { Option } = Select;

export interface ISalonManageProps {
  example?: string;
}

const waitTime = (time = 100) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });
};
/**
 * 沙龙后台管理页面
 */
const SalonManage = (): ReactElement => {
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<IDataType[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [searchStatus, setSearchStatus] = useState<number>(-1);
  const [selectedRows, setSelectedRows] = useState<IDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [logVisible, setLogVisible] = useState<boolean>(false);
  const [mailVisible, setMailVisible] = useState<boolean>(false);
  // TODO 改写为动态时间
  const [regYear] = useState<number>(moment().year());
  const [mailTo, setMailTo] = useState<number>(0);
  const [subject, setSubject] = useState<string>(
    getSharkText("config_page_flightai_salon_reminder_email")
  );
  const [mailTemplate, setMailTemplate] = useState<number>(0);
  const [mailContent, setMailContent] = useState<string>("");
  const [logDataWithKey, setLogDataWithKey] = useState<
    Array<ILogType & { key: string | number }>
  >([]);
  const [globalState] = useGlobalState();
  const location = useLocation();
  const isDemo = getIsDemo(location.pathname, globalState);
  const systemType = 4;

  const [{ data, isLoading }, doFetch] = useFetch<IDataType[]>({
    server: getServer(systemType),
    url: QUERY_URL[systemType].table,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].table,
    },
    query: null,
    ext: {
      regYear,
    },
    lazey: true,
  });
  const refreshList = useCallback(() => {
    doFetch({ ext: { regYear } });
  }, [doFetch, regYear]);
  const [{ data: logData }, doFetchLog] = useFetch<ILogType[]>({
    server: getServer(systemType),
    url: QUERY_URL[systemType].log,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].log,
    },
    query: null,
    ext: {
      regYear,
    },
    lazey: true,
  });

  const [{ res: sendMailRes }, sendMailFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].mail,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].mail,
    },
    query: null,
    ext: {
      content: mailContent,
    },
    lazey: true,
  });
  useEffect(() => {
    if (!sendMailRes) {
      return;
    }
    if (sendMailRes.flag) {
      message.success(getSharkText("config_page_PaAiSaIn_send_success"));
    } else {
      message.error(getSharkText("config_page_PaAiSaIn_send_fail"));
    }
  }, [sendMailRes]);

  useEffect(() => {
    if (logData) {
      logData.forEach((l) => {
        l.key = l.logId;
      });
      setLogDataWithKey(logData);
    }
  }, [logData]);

  useEffect(() => {
    if (logVisible) {
      doFetchLog({
        ext: {
          regYear,
        },
      });
    }
  }, [logVisible, doFetchLog, regYear]);

  useEffect(() => {
    doFetch({
      ext: {
        regYear,
      },
    });
  }, [doFetch, regYear]);

  // 当dataSource改变时, 检查selectRows每一行是否都还在dataSource中
  useEffect(() => {
    if (dataSource.length && selectedRows.length) {
      const tmp = selectedRows.filter(
        (s) => !!dataSource.find((d) => d.regId === s.regId)
      );
      if (tmp.length !== selectedRows.length) {
        setSelectedRows(tmp);
      }
    }
  }, [dataSource, selectedRows]);

  useEffect(() => {
    if (!data) {
      setDataSource([]);
      return;
    }
    const filterKeys: Array<keyof IDataType> = [
      "mobile",
      "mail",
      "name",
      "region",
    ];
    if (searchText || searchStatus !== -1) {
      setDataSource(
        data.filter((d) => {
          let match = true;
          if (searchText) {
            const isFind = filterKeys.some(
              (key) => d[key].toString().indexOf(searchText) >= 0
            );
            if (!isFind) {
              match = false;
            }
          }
          if (searchStatus !== -1 && d.status !== searchStatus) {
            match = false;
          }
          return match;
        })
      );
    } else {
      setDataSource(data);
    }
  }, [data, searchStatus, searchText]);

  const exportList = useCallback(() => {
    downloadExcel(columns, dataSource, "沙龙报名名单");
  }, [dataSource]);

  const action = async (
    row: INewDataType,
    type: 0 | 1 | 2,
    cb: any,
    useLoading?: boolean
  ) => {
    try {
      if (useLoading) {
        setLoading(true);
      }
      const result: any = await fetch(
        `${getServer(systemType)}${QUERY_URL[systemType].action}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            head: {
              uid: getCookie(UID),
              token: getCookie(TOKEN),
              requestTime: new Date(),
              appId: "",
              clientType: "2",
              clientVersion: "",
              moduleCode: MODULE_CODE[systemType],
              chartTableCode: CHART_TABLE_CODE[systemType].table,
            },
            data: row,
            type,
          }),
        }
      );
      const res = await result.json();
      if (res && res.ResponseStatus.Ack === "Success") {
        if (cb) {
          cb();
        } else {
          message.success(getSharkText("config_page_operation_success"));
        }
      } else {
        const code = res.ResponseStatus.Errors[0].ErrorCode;
        if (ERROR_CODE[code]) {
          message.error(ERROR_CODE[code]);
        } else {
          message.error(getSharkText("config_page_system_exception"));
        }
      }
    } catch (e1: any) {
      console.log(e1);
      if (e1 && e1.name === "AbortError") {
        return;
      }
    } finally {
      if (useLoading) {
        setLoading(false);
      }
    }
  };

  const deleteCB = useCallback(
    async (rows: IDataType[], i: number) => {
      if (i < rows.length) {
        console.log("delete row: ", rows[i]);
        await action(rows[i], 2, () => deleteCB(rows, i + 1), false);
      } else {
        console.log("delete end ");
        setLoading(false);
        doFetch({ ext: { regYear } });
      }
    },
    [doFetch, regYear]
  );

  const deleteSelected = useCallback(() => {
    setLoading(true);
    deleteCB(selectedRows, 0);
  }, [deleteCB, selectedRows]);

  const modifyLog = useCallback(() => {
    setLogVisible(true);
  }, []);

  const showMail = useCallback(() => {
    setMailVisible(true);
  }, []);

  const sendMail = useCallback(() => {
    if (!mailContent || !subject) {
      message.error(
        getSharkText("config_page_email_title_and_content_cannot_be_empty")
      );
      return;
    }
    let list: IDataType[] = [];
    switch (mailTo) {
      case 0:
        list = selectedRows;
        break;
      case 1:
        list = dataSource.filter((d) => d.status === 1);
        break;
      case 2:
        list = dataSource.filter((d) => d.status === 2);
        break;
    }
    if (!list.length) {
      message.error(getSharkText("config_page_recipient_list_is_empty"));
      return;
    }
    sendMailFetch({
      ext: {
        data: list,
        subject,
        content: mailContent,
      },
    });
  }, [dataSource, mailTo, subject, mailContent, sendMailFetch, selectedRows]);

  const toolbarRender = useCallback(() => {
    let rst = [
      <Input
        key="searchText"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder={getSharkText(
          "config_page_enter_name_phone_email_region_query"
        )}
        style={{ width: 300 }}
      />,
      <span key="searchStatus">
        <span>{getSharkText("config_page_status")}：</span>
        <Select
          value={searchStatus}
          onChange={(e) => setSearchStatus(e)}
          style={{ width: 120 }}
        >
          <Option value={-1}>{getSharkText("config_page_all")}</Option>
          <Option value={0}>{getSharkText("config_page_wait_approve")}</Option>
          <Option value={1}>{getSharkText("config_page_pass")}</Option>
          <Option value={2}>{getSharkText("config_page_refuse")}</Option>
        </Select>
      </span>,
    ];
    if (!isDemo) {
      rst = rst.concat([
        <Button type="primary" key="export" onClick={exportList}>
          {getSharkText("config_page_export_list")}
        </Button>,
        <Button type="primary" key="delete" onClick={deleteSelected}>
          {getSharkText("config_page_delete_list")}
        </Button>,
        <Button type="primary" key="export" onClick={modifyLog}>
          {getSharkText("config_page_modify_record")}
        </Button>,
        <Button type="primary" key="export" onClick={showMail}>
          {getSharkText("config_page_group_notification")}
        </Button>,
      ]);
    }
    return rst;
  }, [
    deleteSelected,
    exportList,
    isDemo,
    modifyLog,
    searchStatus,
    searchText,
    showMail,
  ]);

  const getColumns = useCallback(() => {
    if (isDemo) {
      return columns.filter((d) => d.dataIndex !== "actions");
    } else {
      return columns;
    }
  }, [isDemo]);

  return (
    <Page needToolBar={false} defaultDateMode="none">
      <Spin spinning={loading || isLoading}>
        <div className="content-white" style={{ marginTop: 0 }}>
          <EditableProTable
            rowKey="regId"
            headerTitle={getSharkText(
              "config_page_shark_data_management_backend"
            )}
            columns={getColumns()}
            recordCreatorProps={{
              position: "bottom",
              record: () =>
                ({
                  regId: -1,
                  registerDate: new Date().toString(),
                } as IDataType),
            }}
            request={async () => ({
              data: dataSource || [],
              success: true,
            })}
            value={dataSource}
            onChange={(e) => setDataSource(e as IDataType[])}
            editable={{
              type: "multiple",
              editableKeys,
              onSave: async (
                rowKey,
                data1: INewDataType,
                row: INewDataType
              ) => {
                let type: 0 | 1 = 0;
                if (data1.regId === -1) {
                  delete data1.regId;
                } else {
                  type = 1;
                }
                data1.regYear = regYear;
                data1.mobilePrefix = "+86";
                console.log("on save");
                console.log(rowKey, data1, row);
                await waitTime(1000);
                action(data1, type, () => refreshList());
              },
              onChange: setEditableRowKeys,
            }}
            rowSelection={{
              selectedRowKeys: selectedRows.map((s) => s.regId),
              onChange: (keys, rows) => setSelectedRows(rows),
            }}
            toolBarRender={() => toolbarRender()}
            scroll={{ x: "max-content" }}
            pagination={{ defaultPageSize: 10 }}
          ></EditableProTable>
        </div>
      </Spin>
      <Modal
        open={logVisible}
        footer={null}
        width={1200}
        onCancel={() => setLogVisible(false)}
      >
        <CustomTableV2
          columns={logColumns}
          dataSource={logDataWithKey}
          searchKeys={["mobile", "name"]}
          subTitle="修改日志"
          searchPlaceholder="请输入手机/姓名查询"
          downloadName={false}
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={CHART_TABLE_CODE[systemType].table}
        />
      </Modal>
      <Modal
        open={mailVisible}
        onCancel={() => setMailVisible(false)}
        onOk={() => sendMail()}
        okText={getSharkText("config_page_send")}
        cancelText={getSharkText("key.cancel.button")}
        title={getSharkText("config_page_group_notification")}
      >
        <Row gutter={[20, 20]}>
          <Col span={6}>{getSharkText("config_page_recipient")}：</Col>
          <Col span={18}>
            <Select value={mailTo} onChange={(e) => setMailTo(e)}>
              <Option value={0}>
                {getSharkText("config_page_selected_list")}
              </Option>
              <Option value={1}>{getSharkText("config_page_passed")}</Option>
              <Option value={2}>{getSharkText("config_page_refused")}</Option>
            </Select>
          </Col>
          <Col span={6}>{getSharkText("config_page_title")}：</Col>
          <Col span={18}>
            <Input
              value={subject}
              onChange={(v) => setSubject(v.target.value)}
            />
          </Col>
          <Col span={6}>
            {getSharkText("config_page_notification_template")}：
          </Col>
          <Col span={18}>
            <Select value={mailTemplate} onChange={(e) => setMailTemplate(e)}>
              <Option value={0}>{getSharkText("config_page_template1")}</Option>
              <Option value={1}>{getSharkText("config_page_template2")}</Option>
              <Option value={2}>{getSharkText("config_page_template3")}</Option>
            </Select>
          </Col>
          <Col span={6}>{getSharkText("config_page_mail_content")}：</Col>
          <Col span={18}>
            <Input.TextArea
              value={mailContent}
              onChange={(e) => setMailContent(e.target.value)}
            ></Input.TextArea>
          </Col>
        </Row>
      </Modal>
    </Page>
  );
};
export default SalonManage;
