import { Button, Col, message, Row } from "antd";
import AirlinesGlobal from "Components/AirlinesGlobal";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { DATE_FORMAT, LIMIT_END_DATE, LIMIT_START_DATE } from "Constants";
import { IDateMode, RangeObject, Role } from "Interface";
import { cloneDeep } from "lodash";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useGlobalState from "Store";
import { getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { IFlight } from "../SalesAnalysisInterface";
import CompareFlight from "./CompareFlight";
import { getDefaultAirline } from "Utils/global";

// import { MODULE_CODE, QUERY_URL } from "../fetchCode";

export interface ISearchAreaProps {
  setQueryExt: (v: any) => void;
  isDemo?: boolean;
}

const EMPTY_FLIGHT: IFlight = {
  flightNO: "",
  route: "",
};
// 最多可对比几个航班
const MAX_COMPARE_FLIGHT = 3;

const DEFAULT_DATE_MODE: IDateMode = "nextWeek";

/**
 *  Component description
 */
const SearchTrend = (props: ISearchAreaProps): ReactElement => {
  const { setQueryExt, isDemo = false } = props;
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange(DEFAULT_DATE_MODE)
  );
  const [flights, setFlights] = useState<IFlight[]>(
    Array.from({ length: 2 }, () => cloneDeep(EMPTY_FLIGHT))
  );
  const [globalState, actions] = useGlobalState();
  const { systemType, userInfo, airlinesQueryCondition } = globalState;
  const { setAirlinesQueryConditionAttr } = actions;
  const roleList = userInfo.roleList;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;

  useEffect(() => {
    if (!airlinesQueryCondition.airlines) {
      const tmp = getDefaultAirline(airlinesRole);
      setAirlinesQueryConditionAttr(tmp);
    }
  }, []);

  /**
   * 添加一行对比
   */
  const handlePlus = useCallback(() => {
    if (flights.length === MAX_COMPARE_FLIGHT + 1) {
      message.error(getSharkText("config_page_maximum_compare_3_flights"));
      return;
    }
    flights.push(cloneDeep(EMPTY_FLIGHT));
    const newVal = cloneDeep(flights);
    setFlights(newVal);
  }, [flights]);

  /**
   * 删除一行对比
   */
  const handleMinus = useCallback(
    (i: number) => {
      flights.splice(i, 1);
      setFlights(cloneDeep(flights));
    },
    [flights]
  );
  const flightRender = useCallback(() => {
    const rst = flights.map((f, i) => (
      <CompareFlight
        f={f}
        i={i}
        key={i}
        handlePlus={handlePlus}
        handleMinus={() => handleMinus(i)}
        isDemo={isDemo}
        isLast={i === flights.length - 1}
        systemType={systemType}
      />
    ));
    return rst;
  }, [flights, handleMinus, handlePlus, isDemo, systemType]);

  const handleSearch = useCallback(() => {
    setQueryExt({
      filter: flights.filter((f) => f.flightNO && f.route),
      query: {
        ...airlinesQueryCondition,
        startDate:
          (dateRange && dateRange[0]?.format(DATE_FORMAT)) || LIMIT_START_DATE,
        endDate:
          (dateRange && dateRange[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
      },
    });
  }, [airlinesQueryCondition, dateRange, flights, setQueryExt]);

  return (
    <Row align="middle" gutter={[10, 20]} style={{ marginTop: 0 }}>
      <Col>
        <span className="search-area-filter-title">
          {getSharkText("system_type_Airline")}：
        </span>
        {/* <Airlines airlines={userAirlines} mode={undefined} /> */}
        <AirlinesGlobal mode={undefined} />
        <span>{getSharkText("charts.date_of_departure")}：</span>
        <DateRangePickerV2
          onChange={setDateRange}
          defaultDateMode={DEFAULT_DATE_MODE}
        />
      </Col>
      <Col>{flightRender()}</Col>
      <Col>
        <Button onClick={handleSearch} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default SearchTrend;
