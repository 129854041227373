import { Col, Row, Spin } from "antd";
import React, { ReactElement } from "react";
import { IFlightReviewFilter } from "../../ReviewCommon";
import Compare from "./Compare";
import OFF from "./OFF";
import Process from "./Process";

export interface IMiddleProps {
  queryExt: IFlightReviewFilter;
}

/**
 * 航班复盘中间部分
 */
const Middle = (props: IMiddleProps): ReactElement => {
  const { queryExt } = props;
  return (
    <div>
      {queryExt.filter.flightNO ? (
        <Row>
          <Col span={14}>
            <Process queryExt={queryExt} />
          </Col>
          <Col span={10}>
            <OFF queryExt={queryExt} />
          </Col>
          <Col span={24}>
            <Compare queryExt={queryExt} />
          </Col>
        </Row>
      ) : (
        <Spin />
      )}
    </div>
  );
};
export default Middle;
