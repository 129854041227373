export const MODULE_CODE: any = {
  1: "",
  2: "flight_review_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {},
  2: {
    routeFlight: "non",
    card: "non",
    feedback: "non",
    setHome: "non",
  },
};

export const QUERY_URL: any = {
  1: {},
  2: {
    routeFlight: "queryFlightReviewRouteOrFlight",
    card: "queryFlightReviewCard",
    barline: "queryFlightReviewBarline",
    schedule: "queryFlightReviewSchedule",
    sales: "queryFlightReviewSaleSeat",
    discount: "queryFlightReviewSaleSeatDiscount",
    search: "queryFlightReviewSearch",
    price: "queryFlightReviewPrice",
    load: "queryFlightReviewLoadFactor",
    footChart: "queryFlightReviewDynamicPriceChart",
    footTable: "queryFlightReviewDynamicPriceTable",
    checkDate: "queryFlightReviewCheckDate",
  },
};
