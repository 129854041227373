import React, { useCallback, useEffect, useMemo } from "react";
import { Col, Empty, message, Row, Spin, Tooltip } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import CardItem from "./CardItem";
import { AirlinesQueryCondition, Area, CardItemData } from "Interface";
import Refetch from "Components/Refetch";
import _ from "lodash";
import { getServer } from "Service/server";
import { IFlightReviewFilter } from "Page/AI/FlightReview/ReviewCommon";

interface CardsProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  curCardCode: string;
  setCurCard: (card: CardItemData) => void;
  area?: Area;
  setHasCardData?: (has: boolean) => void;
  queryExt?: IFlightReviewFilter;
}

const Cards: React.FC<CardsProps> = (props: CardsProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    setCurCard,
    curCardCode,
    setHasCardData,
    queryExt,
  } = props;
  const [globalState] = useGlobal();
  const compareType = 1;
  const { helpVisibility, airlinesQueryCondition, systemType } = globalState;
  const query: AirlinesQueryCondition = useMemo(() => {
    return { ...airlinesQueryCondition, compareType };
  }, [airlinesQueryCondition]);
  const ext = queryExt;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      ...ext,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (ext?.filter.flightNO) {
      doFetch({ query, ext: { ...ext } });
    }
  }, [doFetch, ext, query]);

  useEffect(() => {
    if (setHasCardData) {
      setHasCardData(false);
    }
    if (queryExt && queryExt.filter) {
      refetch();
    }
  }, [refetch, setHasCardData, queryExt]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (!curCardCode) {
        setCurCard(data[0]);
      }
      if (setHasCardData) {
        setHasCardData(true);
      }
    } else if (Array.isArray(data) && data.length === 0) {
      message.error(getSharkText("config_page_flight_cancelled_or_no_data"));
    }
  }, [curCardCode, data, setCurCard, setHasCardData]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <Tooltip
      title={getSharkText("card.switch_card.explaination")}
      getPopupContainer={() => document.getElementById("main") || document.body}
      visible={helpVisibility}
      placement="bottomLeft"
    >
      <Spin spinning={isLoading}>
        <div className="cards-carousel">
          <div className="cards-carousel-content">
            {!_.isEmpty(data) ? (
              <Row
                gutter={[{ xs: 6, sm: 12, md: 18 }, 20]}
                className="cards-row"
              >
                {data.map((item: any, index: number) => (
                  <Col xl={4} key={index} className="cards-column">
                    <CardItem
                      data={item}
                      selectedCode={curCardCode}
                      setCurCard={setCurCard}
                      systemType={systemType}
                      compareType={compareType}
                    />
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </Spin>
    </Tooltip>
  );
};

export default Cards;
