// Created by xh_zhu on 2021-01-29
// #region 引用和基础
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  durationFormatter,
  genSeriesByDimensions,
  isSame,
  listMap2dataSet,
  rowToColumn,
  showNum,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getServer } from "Service/server";
import useGlobal from "Store";
import moment from "moment";
import {
  DATE_FORMAT,
  DATE_HOUR_FORMAT,
  DATE_MINUTE_FORMAT,
  EMPTY_ARRAY,
} from "Constants";
import { IRecordTrend, statusNameMap, TView } from "../DynamicPriceInterface";
import { cloneDeep, isEmpty, isNumber } from "lodash";
import { ISeries } from "Interface";
import EChartsBase from "Components/EChartsBase";
import { calcD, fillData, IHistoryPrice, IHistorySuggest } from "./TrendCommon";
import ReactDOMServer from "react-dom/server";
import { legendHelp } from "Page/AI/FlightManage/FlightManageCommon";
import { QUERY_URL } from "../fetchCode";
import { useBatchEffect } from "Utils/useBatchEffect";
import {
  DynamicPriceHistoryPrice,
  DynamicPricePopupFilter,
} from "@ctrip/flt-bi-flightai-airlines";

const genSuggestMarkLine = (dataSuggest: IHistorySuggest[]) => {
  if (!dataSuggest.length) {
    return undefined;
  }
  return {
    data: dataSuggest.map((d: IHistorySuggest) => {
      const x = moment(d.day).format(DATE_HOUR_FORMAT);
      const color = d.suggestPrice - d.rawPrice > 0 ? "#D03D33" : "green";
      return [
        {
          name: getSharkText("config_page_start_point"),
          xAxis: x,
          yAxis: d.rawPrice,
          lineStyle: { type: "solid", color },
          label: { show: false },
        },
        {
          name: getSharkText("config_page_end_point"),
          xAxis: x,
          yAxis: d.suggestPrice,
        },
      ];
    }),
  };
};

interface ISuggestTrendProps {
  record: IRecordTrend;
  compareDate?: string;
  compareFlightNo?: string;
  xStart?: number;
  xEnd?: number;
  setXStart: (v: number | undefined) => void;
  setXEnd: (v: number | undefined) => void;
  onChartInstanceChange: (v: any) => void;
  view: 0 | 1;
}
// #endregion
/**
 * 模块数据处理步骤:
 * 1. 先获取数据, dataPrice, dataCom
 * 2. 将数据进行拼接, 把当期数据和对比数据合并到一个数组之中, 以diffTime(距起飞小时数为拼接字段), 拼接后放入dataMerged
 * 3. 根据拼接数据计算最大最小值
 * 4. 根据最大最小值对dataMerged的数据进行空白填充
 *
 * @param props
 */
const SuggestTrend = (props: ISuggestTrendProps): ReactElement => {
  // #region 变量声明
  const {
    record,
    compareDate,
    compareFlightNo,
    xStart,
    xEnd,
    setXStart,
    setXEnd,
    onChartInstanceChange,
    view,
  } = props;
  const [globalState] = useGlobal();
  const { systemType, airlinesQueryCondition } = globalState;
  const [dataMerged, setDataMerged] = useState<IHistoryPrice[]>([]);
  const [dataFilled, setDataFilled] = useState<IHistoryPrice[]>([]);
  // let [ref, setRef] = useState<any>();
  const ref = useRef<any>();
  const [option, setOption] = useState<any>(undefined);
  const takeoffDate = useMemo(() => {
    return moment(record.feTkDate).format(DATE_FORMAT);
  }, [record.feTkDate]);
  const compareDiffDays = compareDate
    ? moment(compareDate).diff(moment(takeoffDate), "day")
    : 0;
  // #endregion
  // #region 请求数据
  const filterList: DynamicPricePopupFilter[] = useMemo(() => {
    const base: DynamicPricePopupFilter = {
      flightNo: record.flightNo,
      takeoffDate: moment(record.feTkDate).format(DATE_FORMAT),
      route: record.route,
      type: 1,
    };
    const rst: DynamicPricePopupFilter[] = [base];
    if (compareDate) {
      const tmp = cloneDeep(base);
      tmp.takeoffDate = compareDate;
      rst.push(tmp);
    }
    if (compareFlightNo) {
      const tmp = cloneDeep(base);
      tmp.flightNo = compareFlightNo;
      tmp.type = 2;
      rst.push(tmp);
    }
    if (compareDate && compareFlightNo) {
      const tmp = cloneDeep(base);
      tmp.takeoffDate = compareDate;
      tmp.flightNo = compareFlightNo;
      tmp.type = 2;
      rst.push(tmp);
    }
    return rst;
  }, [record, compareDate, compareFlightNo]);

  useEffect(() => {
    if (ref) {
      try {
        onChartInstanceChange(ref.current.getEchartsInstance());
      } catch (e) {
        console.log(e);
      }
    }
  }, [ref, onChartInstanceChange]);

  const [{ data, isLoading }, doFetch] = useFetch<DynamicPriceHistoryPrice[]>({
    server: getServer(systemType),
    url: QUERY_URL.popupPrice,
    defaultValue: [],
    head: {
      moduleCode: "dynamic_price_airlines",
      chartTableCode: "AL0801",
    },
    query: airlinesQueryCondition,
    ext: {
      filterList,
      view,
    },
  });

  // test
  const testSuggest: IHistorySuggest[] = useMemo(
    () => [
      {
        day: "/Date(1611126000000)/",
        diffTime: 16.67013888888889,
        suggestPrice: 955,
        rawPrice: 1087,
        feedback: 0,
      },
    ],
    []
  );
  // test end

  const refetch = useCallback(() => {
    setDataFilled(EMPTY_ARRAY);
    setXStart(undefined);
    setXEnd(undefined);
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        filterList,
        view,
      },
    });
  }, [airlinesQueryCondition, doFetch, filterList, setXEnd, setXStart, view]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (option && ref && ref.current) {
      ref.current.setOption(option, { notMerge: true });
    }
  }, [ref, option]);

  useEffect(() => {
    if (ref && ref.current) {
      if (isLoading) {
        ref.current.showLoading();
      } else {
        ref.current.hideLoading();
      }
    }
  }, [ref, isLoading]);
  // #endregion

  // #region 转换数据
  const defaultSeries: ISeries[] = useMemo(
    () => [
      {
        name: `${record.flightNo}${getSharkText("config_page_price")}`,
        __aiType: "cur",
        type: "line",
        symbol: "none",
        connectNulls: true,
        lineStyle: {
          color: "#01C5DB",
        },
        itemStyle: {
          color: "#01C5DB",
        },
        encode: {
          x: "d",
          y: "price",
        },
        markLine: genSuggestMarkLine(testSuggest),
      },
      {
        name: `${getSharkText("key.compare.name")}${
          record.flightNo
        }${getSharkText("config_page_price")}`,
        type: "line",
        symbol: "none",
        connectNulls: true,
        lineStyle: {
          type: "dashed",
          color: "#01C5DB",
        },
        itemStyle: {
          color: "#01C5DB",
        },
        encode: {
          x: "d",
          y: "priceCompare",
        },
      },
      {
        name: `${compareFlightNo}${getSharkText("config_page_price")}`,
        type: "line",
        symbol: "none",
        connectNulls: true,
        lineStyle: {
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "priceCom",
        },
      },
      {
        name: `对比${compareFlightNo}价格`,
        type: "line",
        symbol: "none",
        connectNulls: true,
        lineStyle: {
          type: "dashed",
          color: "#FC9B4F",
        },
        itemStyle: {
          color: "#FC9B4F",
        },
        encode: {
          x: "d",
          y: "priceComCompare",
        },
      },
      ...(view === 0
        ? [
            {
              name: getSharkText("config_page_deal_price"),
              type: "bar",
              connectNulls: true,
              symbol: "roundRect",
              symbolSize: 6,
              showAllSymbol: true,
              encode: {
                x: "d",
                y: "priceDeal",
              },
              lineStyle: {
                color: "#177DFE",
              },
              itemStyle: {
                color: "#177DFE",
              },
            },
            {
              name: getSharkText(
                "config_page_PaAiDyCoSu_comparison_deal_price"
              ),
              type: "bar",
              connectNulls: true,
              symbol: "roundRect",
              symbolSize: 6,
              showAllSymbol: true,
              encode: {
                x: "d",
                y: "priceDealCompare",
              },
              lineStyle: {
                type: "dashed",
                color: "#FC9B4F",
              },
              itemStyle: {
                color: "#FC9B4F",
              },
            },
            {
              name: getSharkText("config_page_nearby_flight_average_price"),
              type: "line",
              connectNulls: true,
              symbol: "roundRect",
              symbolSize: 6,
              showAllSymbol: true,
              encode: {
                x: "d",
                y: "racePriceAvgSame",
              },
              lineStyle: {
                color: "#E96B5B",
              },
              itemStyle: {
                color: "#E96B5B",
              },
            },
            {
              name: "对比临近航线均价",
              type: "line",
              connectNulls: true,
              symbol: "roundRect",
              symbolSize: 6,
              showAllSymbol: true,
              encode: {
                x: "d",
                y: "racePriceAvgSameCompare",
              },
              lineStyle: {
                type: "dashed",
                color: "#E96B5B",
              },
              itemStyle: {
                color: "#E96B5B",
              },
            },
          ]
        : []),
      ...(view === 1
        ? [
            {
              id: "a2",
              name: "临近起飞价格",
              type: "line",
              connectNulls: true,
              symbol: "triangle",
              symbolSize: 6,
              showAllSymbol: true,
              lineStyle: {
                color: "#6950a1",
              },
              itemStyle: {
                color: "#6950a1",
              },
              encode: {
                x: "d",
                y: "nearPrice",
              },
            },
            {
              id: "b2",
              name: getSharkText(
                "config_page_PaAiDyCoSu_compare_near_departure_price"
              ),
              type: "line",
              connectNulls: true,
              symbol: "triangle",
              symbolSize: 6,
              showAllSymbol: true,
              lineStyle: {
                type: "dashed",
                color: "#6950a1",
              },
              itemStyle: {
                color: "#6950a1",
              },
              encode: {
                x: "d",
                y: "compareNearPrice",
              },
            },
          ]
        : []),
    ],
    [compareFlightNo, record.flightNo, testSuggest, view]
  );
  const transData = (source: any[], viewType: TView) => {
    if (viewType === 0) {
      return rowToColumn(
        source,
        ["d", "takeoff"],
        "type",
        undefined,
        (rt: any, col: string) => {
          if (rt === 1) {
            return col;
          }
          if (rt === 2) {
            switch (col) {
              case "price":
                return "priceCom";
              case "day":
                return "dayCom";
            }
          }
          if (rt === 3 && col === "price") {
            return "priceDeal";
          }
          return null;
        }
      );
    } else {
      return rowToColumn(
        source,
        ["d"],
        "type",
        undefined,
        (rt: any, col: string) => {
          if (rt === 1) {
            return col;
          }
          if (rt === 2 && col === "price") {
            return "priceCom";
          }
          return null;
        }
      );
    }
  };

  // 数据拼接, 只有当所有数据都请求完成后, 才进行拼接
  useEffect(() => {
    console.log("suggest merging");
    if (isLoading) {
      return;
    }
    if (!data || !data.length) {
      setDataMerged(EMPTY_ARRAY);
      return;
    }
    // 第一步, 转换对比数据
    data.forEach((d: any) => {
      d.d = calcD(d.diffTime, view === 0 ? takeoffDate : d.day, view);
    });
    let dataSource: any[] = [];
    // 第二步, 计算起飞时间
    console.time();
    const curDateStr = record.feTkDate?.substr(0, 10);
    const diffHours = moment(curDateStr).diff(moment(compareDate), "hours");
    console.log("diffHours: ", diffHours);
    data.forEach((r: any) => {
      r.takeoff = moment(r.day).format("yyyy-MM-DD");
    });
    console.timeEnd();
    const rst = transData(data, view);
    dataSource = rst;
    if (compareDate) {
      console.log("price second step:", rst);
      // 第三步, 转换对比的值
      console.time();
      const rst3 = rowToColumn(
        rst,
        ["d", "diffTime"],
        "takeoff",
        undefined,
        (rt: string, col: string) => {
          if (rt === curDateStr) {
            return col;
          } else {
            return col + "Compare";
          }
        }
      );
      console.timeEnd();
      console.log("third step:", rst3);
      // 第四步, 对于一部分只有对比日期没有当前日期的数据, 需要补充当前日期, 否则对比查看时会很麻烦
      rst3.forEach((r: any) => {
        // if (!r.day && r.dayCompare) {
        //   const curDay = moment(r.dayCompare).add(diffHours, "hours").valueOf();
        //   r.day = `/Date(${curDay})/`;
        // }
        if (!r.collectDay && r.collectDayCompare) {
          const curCollectDay = moment(r.collectDayCompare)
            .add(diffHours, "hours")
            .valueOf();
          r.collectDay = `/Date(${curCollectDay})/`;
        }
      });
      console.log("forth step:", rst3);
      dataSource = rst3;
    }
    setDataMerged(dataSource);
    console.log("suggest merged");
  }, [compareDate, data, isLoading, record.feTkDate, setXStart, setXEnd, view]);

  // 计算最大最小值
  useEffect(() => {
    let max: number | undefined;
    let min: number | undefined;
    const setStartEnd = (item: IHistoryPrice) => {
      const dMoment = moment(item.d);
      if (!dMoment.isValid()) {
        return;
      }
      if (max === undefined || moment(max).isAfter(dMoment)) {
        max = moment(item.d).valueOf();
      }
      if (min === undefined || moment(min).isBefore(dMoment)) {
        min = moment(item.d).valueOf();
      }
    };
    dataMerged.forEach(setStartEnd);
    // if (compareDate) {
    //   dataCom.forEach(setStartEnd);
    // }
    if (max !== undefined && min !== undefined) {
      setXStart(max);
      setXEnd(min);
    }
    console.log("suggest set max min end", max, min);
  }, [dataMerged, setXStart, setXEnd]);

  // 补齐数据
  useBatchEffect(() => {
    // 补齐坐标轴
    const dataMergedCopy = cloneDeep(dataMerged);
    if (xStart !== undefined && xEnd !== undefined) {
      console.log("price: fill data");
      console.time("price: fill");
      const rst = fillData(dataMergedCopy, xStart, xEnd, view);
      rst.sort((r1, r2) => (r1.d < r2.d ? -1 : 1));
      console.timeEnd("price: fill");
      setDataFilled(rst || EMPTY_ARRAY);
    }
  }, [dataMerged, xStart, xEnd]);

  // #endregion
  const xAxis = useRef({});
  /**
   * 修改xAxis的类型
   */
  useEffect(() => {
    xAxis.current = {
      type: "category",
      name: getSharkText("config_page_days_from_takeoff"),
      nameLocation: "start",
      nameTextStyle: {
        padding: [25, 35, 0, 0],
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        overflow: "truncate",
        interval:
          view === 0
            ? (index: number, value: string) => {
                const hours = moment(value).hour();
                // 仅显示整天的数据
                const tmp = hours % 24;
                if (tmp !== 0) {
                  return false;
                }
                return true;
              }
            : undefined,
        formatter: (val: number) => {
          let rst: string | number = val;
          if (view === 0) {
            const diff = moment(takeoffDate).diff(moment(val), "seconds");
            // const hours = round(val);
            rst = durationFormatter(diff, "h");
          } else {
            rst = moment(val).format(DATE_FORMAT);
          }
          return rst;
        },
      },
    } as any;
  }, [takeoffDate, view]);

  const types = useMemo(
    () => [
      `${record.flightNo}${getSharkText("config_page_price")}`,
      `${compareFlightNo}${getSharkText("config_page_price")}`,
      getSharkText("config_page_deal_price"),
      getSharkText("config_page_nearby_flight_average_price"),
    ],
    [compareFlightNo, record.flightNo]
  );

  const tooltip = useRef({});
  /**
   * 修改浮窗的值
   */
  useEffect(() => {
    tooltip.current = (params: any) => {
      if (!params.length) {
        return null;
      }
      if (view === 0) {
        const dayIndex = params[0].dimensionNames.indexOf("d");
        const currentDayMoment = moment(params[0].data[dayIndex]);
        if (!currentDayMoment.isValid()) {
          return "No Data";
        }
        const currentDay = currentDayMoment.format(DATE_MINUTE_FORMAT);
        const compareDay = compareDiffDays
          ? currentDayMoment
              .add(compareDiffDays, "days")
              .format(DATE_MINUTE_FORMAT)
          : null;
        // 150时当前起飞航班文字最长宽度, 11px是线条颜色点的宽度
        const day = `<span style="width: 200px; margin-left:11px; display:inline-block">${currentDay}</span>
        ${compareDay ? `<span>${compareDay}</span>` : ""}`;
        let dom = "";
        const suggest: IHistorySuggest | undefined = testSuggest.find(
          (d: IHistorySuggest) =>
            moment(d.day).format(DATE_MINUTE_FORMAT) === day
        );
        if (suggest) {
          const diff = suggest.suggestPrice - suggest.rawPrice;
          const color = diff > 0 ? "#D03D33" : "green";
          const dot = `<span class='dot' style='background:${color}'></span>`;
          dom =
            dom +
            "<br />" +
            dot +
            `${getSharkText("menu_dynamic_price_airlines")}: ${
              suggest.suggestPrice
            }(${diff > 0 ? "+" : ""}${diff}) ${
              statusNameMap[suggest.feedback].label
            }`;
        }
        types.forEach((type: string | undefined, tyIdx: number) => {
          if (!type) {
            return;
          }
          const baseSeriesName = `${type}`;
          const compareBaseSeriesName =
            getSharkText("key.compare.name") + `${baseSeriesName}`;
          const series = params.filter(
            (item: any) =>
              item.seriesName === baseSeriesName ||
              item.seriesName ===
                getSharkText("key.compare.name") + `${baseSeriesName}`
          );
          if (
            series.length > 0 &&
            series.some((s: any) => s.value[s.encode.y[0]] !== null)
          ) {
            const dot = `<span class='dot' style='background:${series[0].color}'></span>`;
            const currentSeries = series.find(
              (item: any) => item.seriesName === baseSeriesName
            );
            const currentVal =
              currentSeries && currentSeries.value[currentSeries.encode.y[0]];
            // 起飞时间
            const tkTimeBase = tyIdx === 0 ? "day" : "dayCom";
            const currentTk = currentSeries
              ? moment(
                  currentSeries.value[
                    currentSeries.dimensionNames.indexOf(tkTimeBase)
                  ]
                )
              : null;
            const currentTkMinute =
              currentTk && currentTk.isValid()
                ? currentTk.format("HH:mm")
                : "--";
            const currentTkStr = [0, 1].includes(tyIdx)
              ? ` | ${currentTkMinute}`
              : "";
            const currentDom = `${baseSeriesName} | ${
              isNumber(currentVal) ? showNum(currentVal, "num") : "--"
            }${currentTkStr}`;
            let compareDom;
            if (compareDate) {
              const compareSeries = series.find(
                (item: any) => item.seriesName === compareBaseSeriesName
              );
              const compareVal =
                compareSeries && compareSeries.value[compareSeries.encode.y[0]];
              const compareTkTime = compareSeries
                ? moment(
                    compareSeries.value[
                      compareSeries.dimensionNames.indexOf(
                        `${tkTimeBase}Compare`
                      )
                    ]
                  )
                : null;
              const compareTKMinute =
                compareTkTime && compareTkTime.isValid()
                  ? compareTkTime.format("HH:mm")
                  : "--";
              const compareTkStr = [0, 1].includes(tyIdx)
                ? ` | ${compareTKMinute}`
                : "";
              compareDom = `${compareBaseSeriesName} | ${
                isNumber(compareVal) ? showNum(compareVal, "num") : "--"
              }${compareTkStr}`;
            }
            const val = `<span style="width: 200px; display:inline-block">${currentDom}</span>${
              compareDom ? `<span>${compareDom}</span>` : ""
            }`;
            dom = dom + "<br />" + dot + val;
          }
        });
        if (isEmpty(dom)) {
          return null;
        }

        // params.forEach((item: any) => {
        //   let dot = `<span class='dot' style='background:${item.color}'></span>`
        //   let val = ''
        //   let value = item.value[item.encode.y[0]]
        //   if (value !== null) {
        //     val = `${item.seriesName}: ${!isZero(value) ? showNum(value) : '-'}`
        //     dom = dom + '<br />' + dot + val
        //   }
        // })
        dom = day + dom;
        return dom;
      } else if (view === 1) {
        const day = moment(params[0].data[params[0].encode.x[0]]).format(
          DATE_FORMAT
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const valIdx = item.encode.y[0];
          const itemValue = item.value[valIdx];
          const value = showNum(itemValue);
          const val = `${item.seriesName}: ${value}`;
          dom = dom + "<br />" + dot + val;
        });
        dom = day + dom;
        return dom;
      }
    };
  }, [compareDate, compareFlightNo, testSuggest, types, view]);

  /**
   * 应用图形参数
   */
  useBatchEffect(() => {
    if (!dataFilled || dataFilled.length === 0) {
      setOption({
        graphic: {
          id: "noData",
          type: "text",
          $action: "merge",
          z: 100,
          left: "center",
          top: "center",
          style: {
            fill: "#666",
            text: [
              getSharkText("config_page_data_empty"),
              getSharkText("config_page_please_reselect_filter_conditions"),
            ].join("\n"),
            font: "26px Microsoft YaHei",
          },
        },
      });
      return;
    }
    if (!dataFilled.length) {
      return;
    }
    const dataSource = listMap2dataSet(dataFilled);
    const dimensions = dataSource[0] || [];
    let sliderStart = 0;
    if (view === 0 && xStart !== undefined && xEnd !== undefined) {
      let days15 = xStart + 15 * 24 * 3600 * 1000;
      if (days15 > xEnd) {
        days15 = xEnd;
      }
      // sliderStart =
      //   100 - ((defaultSliderStart - xEnd) / (xStart - xEnd)) * 100;
      sliderStart = 100 - ((xEnd - days15) / (xEnd - xStart)) * 100;
    }
    const defaultShowLegend = [
      "price",
      "priceCom",
      "priceCompare",
      "priceComCompare",
    ];

    const legendSelected = compareDate
      ? genSeriesByDimensions(dimensions, defaultSeries).reduce(
          (total: any, item: ISeries) => {
            total[item.name] = defaultShowLegend.includes(item.encode.y);
            return total;
          },
          {}
        )
      : undefined;

    const tmpOption = {
      // color: ["#01C5DB", "#FC9B4F", "#177DFE", "#E96B5B", "#6950a1", "#769149"],
      title: {
        text: getSharkText("config_page_price"),
        // textVerticalAlign: 'middle'
        top: "center",
        left: "2%",
      },
      dataset: {
        dimensions,
        source: dataSource,
      },
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
        selected: legendSelected,
        tooltip: {
          show: true,
          formatter: (params: any) => {
            console.log(params);
            if (legendHelp[params.name]) {
              return ReactDOMServer.renderToString(
                <span>{legendHelp[params.name]}</span>
              );
            } else {
              return "";
            }
          },
        },
      },
      tooltip: {
        trigger: "axis",
        formatter: tooltip.current,
      },
      xAxis: xAxis.current,
      dataZoom: [
        {
          type: "inside",
          start: sliderStart,
          filterMode: "weakFilter",
        },
        {
          type: "slider",
          bottom: 0,
          start: sliderStart,
          filterMode: "weakFilter",
          labelFormatter: (val: string, valStr: string) => {
            if (view === 0) {
              const seconds = moment(takeoffDate).diff(
                moment(valStr),
                "seconds"
              );
              return durationFormatter(seconds, "d");
            } else {
              return moment(valStr).format(DATE_FORMAT);
            }
          },
        },
      ],
      yAxis: {
        type: "value",
        min: (v: any) => Math.floor(v.min * 0.9),
        splitNumber: 4,
      },
      series: genSeriesByDimensions(dimensions, defaultSeries),
    };
    if (compareDate) {
      tmpOption.series.push();
    }

    if (!isSame(tmpOption, option)) {
      setOption(tmpOption);
      console.log("suggest updated option", option);
    }
  }, [dataFilled, defaultSeries, record.flightNo, testSuggest]);

  return <EChartsBase ref={ref}></EChartsBase>;
};
export default SuggestTrend;
