import React, { useEffect, useMemo, useState } from "react";
import { IFlightRecord } from "../../FlightManageInterface";
import useGlobalState from "Store";
import { getServer } from "Service/server";
import { SystemType } from "Interface";
import { useFetch } from "Utils";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../../fetchCode";
import useRefFunc from "Utils/useRefFunc";
import { EMPTY_ARRAY } from "Constants";
import moment from "moment";

export interface useRealtimeProps {
  records: IFlightRecord[];
  adviseId: string | null;
}

/**
 * 航班管理-实时数据
 */
const useRealtime = (
  props: useRealtimeProps
): {
  realLoadingIds: string[];
  realLoadedData: IFlightRecord[];
  realLoadedIds: string[];
} => {
  const { records, adviseId } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition, moduleConfig } = globalState;
  const { airlines } = airlinesQueryCondition;
  const [realLoadingIds, setRealLoadingIds] = React.useState<string[]>([]);
  // 已经加载的实时数据的id;
  const [loadedRealtimeData, setLoadedRealtimeData] = useState<IFlightRecord[]>(
    []
  );
  const [loadedIds, setLoadedIds] = useState<string[]>([]);
  const [selfFlights, setSelfFlights] = useState<IFlightRecord[]>([]);

  useEffect(() => {
    // init
    setRealLoadingIds(EMPTY_ARRAY);
    setLoadedRealtimeData(EMPTY_ARRAY);
    setLoadedIds(EMPTY_ARRAY);
    const filter = records.filter(
      (r) =>
        r.flightNo &&
        r.flightNo.substring(0, 2) === airlines &&
        moment().isBefore(moment(`${r.takeoffdate} ${r.takeofftime}`))
    );
    setSelfFlights(filter);
  }, [airlines, records]);

  useEffect(() => {
    // 取出下一批要请求的
    if (selfFlights.length) {
      const nextBatchIds = genNextRealBatchIds(selfFlights);
      setRealLoadingIds(nextBatchIds);
    }
  }, [selfFlights]);

  useEffect(() => {
    // 请求
    if (realLoadingIds.length) {
      reqNextRealBatch(realLoadingIds);
    }
  }, [realLoadingIds]);

  useEffect(() => {
    // 过滤掉还剩的
    if (loadedIds.length) {
      setSelfFlights((old) => old.filter((f) => !loadedIds.includes(f.id)));
    }
  }, [loadedIds]);

  /**
   * 仅当有实时数据时传入第三个参数realtimeData, 将realTimeData合并到filterData
   */
  const genNextRealBatchIds = useRefFunc((needLoadFlights: IFlightRecord[]) => {
    if (needLoadFlights.length) {
      // 如果启用批量查询则每次查一批航班
      if (moduleConfig["allow.flights.batchPost"] === "true") {
        const batchFlightNo = needLoadFlights.filter(
          (n) => n.flightNo === needLoadFlights[0].flightNo
        );
        // 启用批量查询后, 每次查询数量依然有限制
        const limitCnt = Number(moduleConfig["allow.flights.batchLimit"]);
        if (limitCnt) {
          return batchFlightNo.slice(0, limitCnt).map((n) => n.id);
        } else {
          return batchFlightNo.map((n) => n.id);
        }
      }
      // 否则每次查一个航班
      return [needLoadFlights[0].id];
    }
    return EMPTY_ARRAY;
  });

  const reqNextRealBatch = useRefFunc((ids: string[]) => {
    const realTimeExt = {
      ids,
      adviseId: adviseId || null,
    };
    doFetchReal({ query: airlinesQueryCondition, ext: realTimeExt });
  });

  const [, doFetchReal] = useFetch({
    server: getServer(SystemType.airlines),
    url: QUERY_URL.realTime,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE.table,
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
    onSuccess: (res: any, bodyObj: any) => {
      // 每次请求完成后, 将ID加入已请求的列表, 并且生成下一批请求的ID
      if (bodyObj.ids) {
        const resData = res.data as IFlightRecord[];
        setLoadedRealtimeData((old) => [...old, ...resData]);
        setLoadedIds((old) => [...old, ...bodyObj.ids]);
        setRealLoadingIds(() => EMPTY_ARRAY);
      }
    },
  });

  return useMemo(() => {
    const tmp = {
      realLoadingIds,
      realLoadedData: loadedRealtimeData,
      realLoadedIds: loadedIds,
    };
    return tmp;
  }, [realLoadingIds.length, loadedRealtimeData.length, loadedIds.length]);
};
export default useRealtime;
