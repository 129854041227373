import React, { ReactElement } from "react";
import { useLocation } from "react-router";
import { Article } from "@ctrip/flt-bi-flightai-base";
import { Empty } from "antd";

/**
 * 研究报告内容页
 */
const Report = (): ReactElement => {
  const location = useLocation();
  console.log("location: ", location);
  const art: Article = location.state as Article;

  if (!art || !art.fileUrl) {
    return <Empty />;
  }

  return (
    <div>
      <object
        data="http://africau.edu/images/default/sample.pdf"
        type="application/pdf"
        width="100%"
        height="800"
      >
        <p>
          Alternative text - include a link{" "}
          <a href="http://africau.edu/images/default/sample.pdf">to the PDF!</a>
        </p>
      </object>
    </div>
  );
};
export default Report;
