export const PAGE_ID: Record<string, string> = {
  /** 注册 */
  "/login": "10650040938",
  /** 核心概览 */
  "/core_overview": "10650039103",
  /** 航线分析 */
  "/airline_analysis": "10650039105",
  /** 搜索指数 */
  "/search_index": "10650039107",
  /** 航班计划 */
  "/flight_plan": "10650041042",
  /** flightai指数 */
  "/dashboard": "10650042705",
  /** 客源预测 */
  "/tourist_source": "10650045142",
  /** 价格监控 */
  "/price_monitor": "10650047072",
  /** 机场对标 */
  "/airport_compare": "10650044809",
  /** 用户画像 */
  "/user_profile": "10650048734",
  /** 客源分析 */
  "/passenger_analysis": "10650045051",
  /** 中转分析 */
  "/transfer_analysis": "10650048732",
  /** 中转政策 */
  "/transfer_policy": "10650104028",
  /** 动态调价 */
  "/dynamic_price": "10650050014",
  /** 资料下载 */
  "/file_sharing": "10650051527",
  /** 行业趋势 */
  "/industry_trend": "10650057976",
  /** 节假日分析 */
  "/holiday_analysis": "10650057982",
  /** 预售监控 */
  "/presale_monitor": "10650058954",
  /** Travix Overview */
  "/travix_overview": "10650061126",
  /** Travix Sales Data */
  "/travix_salesdata": "10650061128",
  /** 航班管理 */
  "/flight_manage": "10650061602",
  /** 销售分析 */
  "/sales_analysis": "10650063454",
  /** 监控预警 */
  "/monitoring_alerting": "10650064838",
  /** 模拟器 */
  "/simulator": "10650103884",
  /** 市场趋势 */
  "/market_index": "10650104030",

  /** 官网-关于我们 */
  "/AboutUs": "10650048566",
  /** 官网-收益管理 */
  "/RevenueManagement": "10650044813",
  /** 官网-行业咨询 */
  "/Consulting": "10650048564",
  /** 官网-首页 */
  "/": "10650048562",
  /** 官网-市场洞察 */
  "/MarketInsight": "10650044811",
};
