import React, { ReactElement } from "react";
import copy from "copy-to-clipboard";
import { CopyTwoTone } from "@ant-design/icons";
import { message } from "antd";
import { ubt } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { TRACE_ID } from "Page/AI/FlightManage/FlightManageCommon";

export interface ICMDCopyProps {
  cmd: string;
}

/**
 * 复制调仓指令
 */
const CMDCopy = (props: ICMDCopyProps): ReactElement => {
  const { cmd } = props;
  const copyCmd = () => {
    ubt(TRACE_ID.getName("layoutCopy"), cmd);
    try {
      copy(cmd);
      message.success(getSharkText("config_page_copy_success"));
    } catch (e) {
      message.error(getSharkText("config_page_copy_fail"));
    }
  };
  return (
    <div>
      {cmd}
      <CopyTwoTone onClick={copyCmd} />
    </div>
  );
};
export default CMDCopy;
