import {
  SwapLeftOutlined,
  SwapOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import CascaderArea from "Components/CascaderArea";
import { DEFAULT_FLIGHT_AREA } from "Constants";
import { Area, Direction, FlightArea, FlightClass } from "Interface";
import React, { ReactElement, useMemo } from "react";
import { useLocation } from "react-router-dom";
import useGlobalState from "Store";
import { getIsDemo } from "Utils";
import useMergedState from "Utils/useMergedState";
import useRefFunc from "Utils/useRefFunc";

export interface ISelectRTProps {
  value?: FlightArea;
  /**
   * type city | airport
   */
  type?: "city" | "airport";
  /**
   * 航程类型, 去程, 返程 往返
   */
  directionType?: Direction;
  /**
   * 国内是否启用区域选择
   */
  useDomesticArea?: boolean;
  /**
   * container的 style
   */
  style?: React.CSSProperties;
  onChange?: (v: FlightArea) => void;
  /**
   * 是否启用出发到达对调功能, 默认不启用, 如果启用, 则directionType设置不生效
   */
  useExchange?: boolean;
  /**
   * 国际国内
   */
  flightClass?: FlightClass;
}

/**
 * 往返选择组件
 */
const SelectRT = (props: ISelectRTProps): ReactElement => {
  const {
    value,
    type = "airport",
    directionType = "T",
    useDomesticArea = false,
    onChange,
    flightClass: propFlightClass,
    style,
    useExchange = false,
  } = props;
  const location = useLocation();
  const [innerValue, setInnerValue] = useMergedState<FlightArea>(
    DEFAULT_FLIGHT_AREA,
    { value }
  );
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const { flightClass: globalFlightClass } = airlinesQueryCondition;
  const flightClass = propFlightClass ?? globalFlightClass;
  const isDemo = getIsDemo(location.pathname, globalState);

  const handleExchange = useRefFunc(() => {
    const nextValue = {
      departArea: innerValue.arriveArea,
      arriveArea: innerValue.departArea,
    };
    setInnerValue(nextValue);
    if (onChange) {
      onChange(nextValue);
    }
  });

  const direction = useMemo(() => {
    if (useExchange) {
      return (
        <SwapRightOutlined
          style={{ color: "#1890ff" }}
          onClick={handleExchange}
        ></SwapRightOutlined>
      );
    }
    switch (directionType) {
      case "R":
        return <SwapLeftOutlined />;
      case "T":
        return <SwapRightOutlined />;
      case "RT":
        return <SwapOutlined />;
    }
  }, [directionType, handleExchange, useExchange]);

  const handleChange = useRefFunc((t: "depart" | "arrive", v: Area) => {
    const key = t === "depart" ? "departArea" : "arriveArea";
    const newValue = {
      ...innerValue,
      [key]: v,
    };
    setInnerValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  });

  return (
    <Row id="selectedAirlines" align="middle" gutter={16} style={style}>
      <Col id="selectedAirlinesDepart">
        <CascaderArea
          value={innerValue.departArea}
          setSelectedArea={(v) => handleChange("depart", v)}
          flightClass={flightClass === 1 ? 1 : 0}
          type={type}
          isDemo={isDemo}
          useDomesticArea={useDomesticArea}
        />
      </Col>
      <Col style={{ padding: "0 5px" }}>{direction}</Col>
      <Col id="selectedAirlinesArrive">
        <CascaderArea
          value={innerValue.arriveArea}
          setSelectedArea={(v) => handleChange("arrive", v)}
          flightClass={flightClass === 1 ? 1 : 0}
          type={type}
          isDemo={isDemo}
          useDomesticArea={useDomesticArea}
        />
      </Col>
    </Row>
  );
};
export default SelectRT;
