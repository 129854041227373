// 只有机场版的页面
export const ONLY_AIRPORT_PAGE = [
  "/airport_compare",
  "/passenger_analysis",
  "/cancellation_prediction",
];
// 只有航司版的页面
export const ONLY_AIRLINE_PAGE = [
  "/price_monitor",
  "/dynamic_price",
  "/presale_monitor",
  "/flight_manage",
  "/monitoring_alerting",
  "/sales_analysis",
  "/flight_review",
  "/flight_segment",
  "/simulator",
  "/monitoring_suggestion",
  "/free_dashboard",
  "/profit_monitor",
  "/exception_monitoring",
  "/transit_suggestion",
  /** 规则调舱 */
  "/adjustment_by_rules",
  /** 市场趋势 */
  "/market_index",
  /** 销售预案 */
  "/sales_plan",
  /** 切位运营 */
  "/allocation_operation",
  /** 批量调舱 */
  "/im_batch",
  /** OD分析 */
  "/od_analysis",
];
