import { Affix, Alert } from "antd";
import HeaderAlert from "Components/HeaderAlert";
import Page from "Layout/Page";
import NotificationCenter from "Page/AI/NotificationCenter";
import React, { ReactElement, useState } from "react";
import useGlobalState from "Store";
import { getModule, getModuleNameFromPath } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import SearchArea from "./Components/SearchArea";
import styles from "./index.module.scss";
import { IFlightReviewFilter } from "./ReviewCommon";
import Summary from "./Components/Summary";
import Middle from "./Components/Middle";
import UpdateTime from "Components/UpdateTime";
import { CHART_TABLE_CODE, MODULE_CODE } from "./fetchCode";
import Footer from "./Components/Footer";
import { useLocation } from "react-router-dom";
import FlightManageDrawer from "../FlightManage/FlightManageDrawer";

/**
 * 航班复盘页面
 */
const FlightReview = (): ReactElement => {
  const location = useLocation();
  const [queryExt, setQueryExt] = useState<IFlightReviewFilter | undefined>(
    undefined
  );
  const [globalState] = useGlobalState();
  const { systemType, userInfo } = globalState;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const isDemo = (module && module.moduleStatus === 0) || false;
  const [isSummary, setIsSummary] = useState<boolean>(true);

  return (
    <>
      <Page defaultDateMode="none" needToolBar={false}>
        {isDemo ? <HeaderAlert /> : undefined}
        <div
          className={`content-white ${styles.flightManage}`}
          style={{ marginTop: 0, paddingTop: 0 }}
        >
          <Alert
            message={
              <UpdateTime
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].map}
                text={getSharkText(
                  "config_page_data_are_estimated_by_ctrip_big_data"
                )}
              />
            }
            description=""
            banner
            type="info"
            showIcon
            style={{ margin: "0px -15px 10px" }}
          />
          <Affix offsetTop={10}>
            <div style={{ background: "#fff" }}>
              <SearchArea setQueryExt={setQueryExt} />
            </div>
          </Affix>
          {queryExt && (
            <Summary
              queryExt={queryExt}
              setQueryExt={setQueryExt}
              setIsSummary={setIsSummary}
            />
          )}
        </div>
        {!isSummary && queryExt ? (
          <>
            <div className="content-white">
              <Middle queryExt={queryExt} />
            </div>
            {queryExt.filter.flightNO.substr(0, 2).toLocaleLowerCase() ===
              "ho" && (
              <div className="content-white">
                <Footer queryExt={queryExt} />
              </div>
            )}
          </>
        ) : undefined}
      </Page>
      <NotificationCenter />
      <FlightManageDrawer />
    </>
  );
};
export default FlightReview;
