import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Col, Radio, Row } from "antd";
import {
  Area,
  dateRanges,
  MarketTrendRequestType,
  QueryAirlines,
  RequestHead,
} from "@ctrip/flt-bi-flightai-airlines";
import { AggCode, FlightArea, RangeCompare } from "Interface";
import FlightClassCom from "Components/Toolbar/FlightClassCom";
import SelectRT from "Components/SelectRT/SelectRT";
import { cloneDeep } from "lodash";

import { AggCodeName, DATE_FORMAT, DEFAULT_FLIGHT_AREA } from "Constants";
import Dates from "Components/Dates";
import useGlobalState from "Store";
import moment from "moment";

export interface ISearchAreaProps {
  onChange: (v: MarketTrendRequestType) => void;
}
export const MonitoringAgg = [AggCode.hour, AggCode.day] as const;

export const MonitoringAggOptions = Object.values(MonitoringAgg).map((item) => {
  const key = item as AggCode;
  return {
    value: key,
    label: AggCodeName[key],
    disabled: false,
  };
});

const MarketTrendsAirline = (props: ISearchAreaProps): ReactElement => {
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [flightArea, setFlightArea] = useState<FlightArea>(DEFAULT_FLIGHT_AREA);
  const [granularity, setGranularity] = useState<AggCode>(AggCode.day);
  const [aggOption, setAggOption] = useState(MonitoringAggOptions);

  const { onChange } = props;
  const [rangePorps, setRange] = useState<RangeCompare>();

  const handleSearch = useCallback(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const formattedDate = tomorrow.toISOString().split("T")[0];
    let dateRangesValue: dateRanges = {
      current: [],
      compare: [],
    };
    if (rangePorps?.current?.[0]) {
      dateRangesValue.current?.push(rangePorps.current[0].format(DATE_FORMAT));
    } else {
      dateRangesValue.current?.push(formattedDate);
    }
    if (rangePorps?.current?.[1]) {
      dateRangesValue.current?.push(rangePorps.current[1].format(DATE_FORMAT));
    } else {
      dateRangesValue.current?.push(formattedDate);
    }
    if (rangePorps?.compare?.[0]) {
      dateRangesValue.compare?.push(rangePorps.compare[0].format(DATE_FORMAT));
    }
    if (rangePorps?.compare?.[1]) {
      dateRangesValue.compare?.push(rangePorps.compare[1].format(DATE_FORMAT));
    }

    const filter: MarketTrendRequestType = {
      head: {
        moduleCode: "market_trend_airlines",
      } as RequestHead,
      query: airlinesQueryCondition as QueryAirlines,
      aggCode: granularity as number,
      departArea: flightArea.departArea as Area,
      takeoffDateRanges: dateRangesValue as dateRanges,
      arriveArea: flightArea.arriveArea as Area,
    };
    onChange(filter);
  }, [
    airlinesQueryCondition,
    flightArea.arriveArea,
    flightArea.departArea,
    granularity,
    onChange,
    rangePorps,
  ]);

  useEffect(() => {
    if (
      (rangePorps &&
        rangePorps.compare &&
        rangePorps.compare[0] &&
        rangePorps.compare[1] &&
        rangePorps.compare[1].diff(rangePorps.compare[0], "d") > 1) ||
      (rangePorps &&
        rangePorps.current &&
        rangePorps.current[0] &&
        rangePorps.current[1] &&
        rangePorps.current[1].diff(rangePorps.current[0], "d") > 1)
    ) {
      const op = cloneDeep(aggOption);
      op.forEach((o) => {
        if (o.value !== AggCode.day) {
          o.disabled = true;
        }
      });
      setAggOption(op);
      setGranularity(AggCode.day);
    } else {
      setAggOption(MonitoringAggOptions);
    }
  }, [rangePorps]);

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  const now = moment();

  const defaultStartDate = now.clone().add(1, "day").startOf("day");

  const defaultValue: RangeCompare = {
    current: [defaultStartDate, defaultStartDate],
    compare: null,
  };
  return (
    <Row
      align="middle"
      gutter={[10, 20]}
      style={{ marginTop: 0, padding: "15px 0px" }}
    >
      <Col>
        <FlightClassCom />
      </Col>
      <Col>
        <Dates
          onChange={setRange}
          defaultValue={defaultValue}
          defaultDateMode="none"
          useCompare
        />
      </Col>
      <Col>
        <SelectRT
          value={flightArea}
          onChange={setFlightArea}
          type="city"
          useExchange
          useDomesticArea
        />
      </Col>
      <Col>
        <Radio.Group
          options={aggOption}
          optionType="button"
          value={granularity}
          onChange={(e) => setGranularity(e.target.value)}
        />
      </Col>
    </Row>
  );
};

export default MarketTrendsAirline;
