import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import {
  toThousands,
  getDateFromAggCode,
  getTooltipDateFromAggCode,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode } from "Interface";
import _ from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface Data {
  day: string;
  value: number;
  compareValue: number;
  otherValue: number;
}

interface ChartsProps {
  aggCode: AggCode;
  isPercentVal: boolean;
  loading: boolean;
  data: Data[];
  startDate: string;
  endDate: string;
  height: number;
  airport: string;
}

const AreaCharts: React.FC<ChartsProps> = (props: ChartsProps) => {
  const {
    aggCode,
    isPercentVal,
    loading,
    data,
    startDate,
    endDate,
    height,
    airport,
  } = props;

  const [areaData, setAreaData] = useState<Data[]>(data);

  useEffect(() => {
    if (_.isEmpty(data)) {
      setAreaData([]);
    } else {
      const transData = data.map((item) => {
        const sum =
          (item.value || 0) + (item.otherValue || 0) + (item.compareValue || 0);
        const tmp: Data = {
          day: item.day,
          value: _.isFinite(item.value / sum) ? item.value / sum : 0,
          compareValue: _.isFinite(item.compareValue / sum)
            ? item.compareValue / sum
            : 0,
          otherValue: _.isFinite(item.otherValue / sum)
            ? item.otherValue / sum
            : 0,
        };
        return tmp;
      });
      setAreaData(transData);
    }
  }, [data]);

  const isZero = (val: number) => val === 0;
  const option = {
    dataset: {
      source: areaData,
    },
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          false
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const dim = item.dimensionNames[item.encode.y[0]];
          const val = `${item.seriesName}: ${
            !isZero(item.data[dim])
              ? isPercentVal
                ? (item.data[dim] * 100).toFixed(2) + "%"
                : toThousands(item.data[dim])
              : "-"
          }`;
          dom = dom + "<br />" + dot + val;
        });
        dom = getSharkText("charts.date_of_departure") + day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    yAxis: {
      position: "left",
      type: "value",
      splitLine: false,
      splitNumber: 5,
      axisLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: number) =>
          `${isPercentVal ? (value * 100).toFixed(2) + "%" : value}`,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: "line",
        encode: { x: "day", y: "value" },
        name: `${airport}${getSharkText("transfer.transfer.name")}`,
        symbol: "none",
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          opacity: "0.5",
        },
        // 堆叠在一个分组里
        stack: "stack",
      },
      {
        type: "line",
        encode: { x: "day", y: "compareValue" },
        name: getSharkText("tranfer.direct.checkbox"),
        symbol: "none",
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          opacity: "0.5",
        },
        stack: "stack",
      },
      {
        type: "line",
        encode: { x: "day", y: "otherValue" },
        name: getSharkText("config_page_PaAiTrCoTrAr_other_transfer"),
        symbol: "none",
        lineStyle: {
          width: 0,
        },
        areaStyle: {
          opacity: "0.5",
        },
        stack: "stack",
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default AreaCharts;
