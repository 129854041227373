import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Button, Col, Modal, Row, Select } from "antd";
import useGlobal from "Store";
import { IDateMode, IFlightManageQueryExt, RangeObject, Role } from "Interface";
import { DATE_FORMAT, DEFAULT_AIRLINE, DEFAULT_AIRLINE_NAME } from "Constants";
import {
  getDateRange,
  getModuleNameFromPath,
  getModuleStatus,
  getUserAirlines,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { SelectMultFlightProps } from "./SelectMultFlight";
import moment from "moment";
import "./index.css";
import Airlines from "Components/Toolbar/Airlines";
import { SelectMultiRouteProps } from "./SelectMultRoute";
import usePageContext from "Page/AI/FlightManage/FlightManageContext";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { useLocation } from "react-router-dom";
import useRefFunc from "Utils/useRefFunc";
import { useQueryString } from "../../../../../Utils/query/useQueryString";
import { cloneDeep } from "lodash";
import TrafficCalendar from "../TrafficCalendar";
import {
  CalendarOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import SelectRouteAndFlight from "./SelectRouteAndFlight";

import { getLimitDays } from "../../FlightManageCommon";
const { Option } = Select;

// const defaultDateRange: IDateMode = "nextFourteenDays";
const defaultDateRange: IDateMode = "nextWeek";

interface SearchAreaProps {
  setQueryExt: (ext: IFlightManageQueryExt) => void;
  setAdjustFlag: (v: boolean) => void;
  defaultAirline?: string;
  defaultFlightNo?: string;
  defaultRoute?: string;
  defaultTakeoffDate?: string;
}

const SearchArea = (props: SearchAreaProps): ReactElement => {
  const {
    setQueryExt,
    setAdjustFlag,
    defaultAirline,
    defaultFlightNo,
    defaultRoute,
    defaultTakeoffDate,
  } = props;
  const location = useLocation();
  const [qs] = useQueryString("startDate", "");
  const [qe] = useQueryString("endDate", "");
  const [qf] = useQueryString("flightNo", "");
  const [qa] = useQueryString("airline", "");
  const [qr] = useQueryString("routes", "");
  const [routes, setRoutes] = useState(
    defaultRoute ? [defaultRoute] : qr ? [qr] : []
  );
  const [flightNO, setFlightNO] = useState(
    defaultFlightNo ? [defaultFlightNo] : qf ? [qf] : []
  );
  const defaultRange = () => {
    if (qs && qe) {
      return [moment(qs), moment(qe)] as RangeObject;
    } else if (defaultTakeoffDate) {
      return [
        moment(defaultTakeoffDate),
        moment(defaultTakeoffDate),
      ] as RangeObject;
    } else {
      return null;
    }
  };
  const [range, setRange] = useState<RangeObject>(
    cloneDeep(defaultRange()) || getDateRange(defaultDateRange)
  );
  const [departTime, setDepartTime] = useState<number>(0);
  const [, setLoading] = useState(false);
  const [globalState, actions] = useGlobal();
  const { userInfo, airlinesQueryCondition, systemType, moduleConfig } =
    globalState;
  const { setAirlinesQueryConditionAttr } = actions;
  const { user, roleList } = userInfo;
  const isCtrip = user.userType === 1;
  const { flightClass } = airlinesQueryCondition;
  const { pathname } = location;
  const userAirlines = useMemo(() => getUserAirlines(roleList), [roleList]);
  const [pageState] = usePageContext();
  const { routeList } = pageState;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;

  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const [initialed, setInitialed] = useState<boolean>(false);

  const limitDays = useMemo(
    () => getLimitDays(moduleConfig, routes[0]),
    [moduleConfig, routes]
  );

  useEffect(() => {
    if (routes && routeList.length) {
      const currentRoute = routeList.find((r) => r.line === routes.join());
      if (currentRoute) {
        setAdjustFlag(currentRoute.adjustFlag === 1);
      }
    } else {
      setAdjustFlag(false);
    }
  }, [routeList, routes, setAdjustFlag]);

  const handleTimeChange = (v: number) => {
    setDepartTime(v);
  };

  const handleSearch = useRefFunc((newRange?: RangeObject) => {
    setQueryExt({
      filter: {
        time: departTime,
        competitive: 0,
        route: routes.join(),
        flightNo: flightNO.filter((f) => !!f),
      },
    });
    const r = newRange || range;
    setAirlinesQueryConditionAttr({
      startDate: (r && r[0]?.format(DATE_FORMAT)) || "",
      endDate: (r && r[1]?.format(DATE_FORMAT)) || "",
    });
  });

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false));
  }, [flightClass]);

  const handleNextClick = () => {
    if (!range) return;

    const rangeCP = cloneDeep(range);
    const newStartDate = rangeCP[1]?.clone().add(1, "days");
    const newEndDate = newStartDate
      ?.clone()
      .add(rangeCP[1]?.diff(rangeCP[0]), "milliseconds");
    const newRange: RangeObject = [newStartDate || null, newEndDate || null];
    setRange(newRange);
    handleSearch(newRange);
  };

  const handlePreviousClick = () => {
    if (!range) return;

    const rangeCP = cloneDeep(range);
    const newEndDate = rangeCP[0]?.clone().add(-1, "days");
    const newStartDate = newEndDate
      ?.clone()
      .add(rangeCP[0]?.diff(rangeCP[1]), "milliseconds");
    const newRange: RangeObject = [newStartDate || null, newEndDate || null];
    setRange(newRange);
    handleSearch(newRange);
  };

  /** 首次查询 */
  useEffect(() => {
    if (
      !initialed &&
      range?.length &&
      routes.length &&
      airlinesQueryCondition.startDate &&
      airlinesQueryCondition.endDate
    ) {
      handleSearch();
      setInitialed(true);
    }
  }, [
    airlinesQueryCondition.endDate,
    airlinesQueryCondition.startDate,
    handleSearch,
    initialed,
    range,
    routes.length,
  ]);
  const defaultSelectedAirline = useMemo(() => {
    if (defaultAirline) {
      return defaultAirline;
    }
    if (qa) {
      return qa;
    }
    if (airlinesRole && airlinesRole.permissionCode !== "*") {
      return airlinesRole.permissionCode?.split(",")[0];
    } else {
      return DEFAULT_AIRLINE;
      // return "DR";
    }
  }, []);
  const defaultSelectedAirlineName = useMemo(() => {
    if (defaultAirline) {
      return "";
    }
    if (qa) {
      return "";
    }
    if (airlinesRole && airlinesRole.permissionCode !== "*") {
      return airlinesRole.permissionName?.split(",")[0];
    } else {
      return DEFAULT_AIRLINE_NAME;
    }
  }, []);
  useEffect(() => {
    if (!airlinesQueryCondition.airlines) {
      setAirlinesQueryConditionAttr({
        airlines: defaultSelectedAirline,
        airlinesName: defaultSelectedAirlineName,
      });
    }
    if (!airlinesQueryCondition.startDate) {
      setAirlinesQueryConditionAttr({
        startDate: (range && range[0]?.format(DATE_FORMAT)) || "",
        endDate: (range && range[1]?.format(DATE_FORMAT)) || "",
      });
    }
  }, []);

  const handleRoutesChange = useRefFunc((list: string[]) => {
    setRoutes(list);
  });

  const handleFlightNoChange = useRefFunc((list: string[]) => {
    setFlightNO(list);
  });

  const useAirlineChange = isCtrip || userAirlines.length > 1;

  const [trafficVisible, setTrafficVisible] = useState<boolean>(false);

  const ext = useMemo(() => {
    return { all: 1 };
  }, []);

  const routeProps: SelectMultiRouteProps = useMemo(() => {
    return {
      routes,
      setRoutes: handleRoutesChange,
      isDemo,
      // mode: undefined,
      width: 150,
      flights: flightNO.join(","),
      autoSelectFirst: !defaultRoute,
      useExchange: true,
      debugId: "manage_route",
      permissionType: "user",
      useGroup: true,
      ext,
    };
  }, [defaultRoute, ext, flightNO, handleRoutesChange, isDemo, routes]);
  const flightProps = useMemo(() => {
    const tmpProps: SelectMultFlightProps = {
      queryUrl: QUERY_URL.routeFlight,
      moduleCode: MODULE_CODE,
      flightNO,
      setFlightNO: handleFlightNoChange,
      routes: routes.join(","),
      isDemo,
      width: 120,
      mode: undefined,
      allowClear: true,
      placeholder: getSharkText("config_page_select_flight"),
      debugId: "manage_flight",
    };
    return tmpProps;
  }, [flightNO, handleFlightNoChange, isDemo, routes]);

  const [routeComponent, flightComponent] = SelectRouteAndFlight({
    routeProps,
    flightProps,
    changeRouteByFlight: true,
    clearFlightByRoute: true,
  });

  return (
    <Row
      align="middle"
      gutter={[10, 10]}
      style={{ marginTop: 0 }}
      justify="space-between"
    >
      {useAirlineChange && (
        <Col>
          <Airlines airlines={userAirlines} mode={undefined} />
        </Col>
      )}
      <Col>
        <Row gutter={[10, 10]}>
          <Col>{routeComponent}</Col>
          <Col>{flightComponent}</Col>
          <Col style={{ display: "flex", alignItems: "center" }}>
            {/* <PriceDateRangePicker defaultDateMode= setRange={setRange} /> */}
            <Button
              size="small"
              color="#F2F3F5"
              style={{
                fontSize: "10px",
                color: "#B5B6B7",
                marginRight: -2,
                border: "none",
                height: "100%",
                display: "flex",
                backgroundColor: "#F2F3F5",
                alignItems: "center",
              }}
              onClick={handlePreviousClick}
            >
              <LeftOutlined />
            </Button>
            <DateRangePickerV2
              onChange={setRange}
              defaultDateMode={cloneDeep(defaultRange()) || "nextWeek"}
              value={range}
              otherProps={{
                disabledDate: (c) => c.isAfter(moment().add(limitDays, "d")),
              }}
            />
            <Button
              size="small"
              color="#F2F3F5"
              style={{
                fontSize: "10px",
                color: "#B5B6B7",
                marginLeft: -2,
                border: "none",
                height: "100%",
                display: "flex",
                backgroundColor: "#F2F3F5",
                alignItems: "center",
              }}
              onClick={handleNextClick}
            >
              <RightOutlined />
            </Button>
          </Col>
          <Col>
            <Select
              value={departTime}
              onChange={handleTimeChange}
              style={{ width: 120 }}
            >
              <Option value={0}>{getSharkText("config_page_all")}</Option>
              <Option value={1}>0:00 - 6:00</Option>
              <Option value={2}>6:00 - 12:00</Option>
              <Option value={3}>12:00 - 18:00</Option>
              <Option value={4}>18:00 - 24:00</Option>
            </Select>
          </Col>
          <Col>
            <Button
              id="areaSearchBtn"
              type="primary"
              onClick={() => handleSearch()}
            >
              {getSharkText("key.query.button")}
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => setTrafficVisible(true)}
              style={{ color: "var(--main)" }}
            >
              <span style={{ marginRight: 5 }}>
                <CalendarOutlined />
              </span>
              <span>{getSharkText("config_page_traffic_calendar")}</span>
            </Button>
            <Modal
              open={trafficVisible}
              onCancel={() => setTrafficVisible(false)}
              width={1024}
              destroyOnClose
              footer={null}
              style={{ top: 20 }}
            >
              <TrafficCalendar
                isDemo={isDemo}
                flightNO={flightNO}
                route={routes[0]}
              />
            </Modal>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SearchArea;
