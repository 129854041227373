import { Empty, Spin, Tabs } from "antd";
import { Tab } from "rc-tabs/lib/interface";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useServices } from "../FreeDashboard/useServices";
import { CUSTOM_COLS, DATASET_ID_NEWEST } from "./Components/DatasetCols";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import useGlobalState from "Store";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import OverviewIndex from "./Components/Overview/OverviewIndex";
import DetailIndex from "./Components/Detail/DetailIndex";
import { getUserAirlines } from "Utils";
import { AirportOrAirline } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

export const getTrendColor = (v: number | null): string => {
  if (v == null) {
    return "";
  }
  if (v > 0) {
    return "red";
  }
  if (v < 0) {
    return "green";
  }
  return "";
};

/** 收益监控 */
const ProfitMonitor = (): ReactElement => {
  const [activeKey, setActiveKey] = useState<string>("overview");
  const [remoteCols, setRemoteCols] = useState<FDDatasetCol[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [globalState, actions] = useGlobalState();
  const airlines = useRef<AirportOrAirline[]>(
    getUserAirlines(globalState.userInfo.roleList)
  ).current;
  const services = useServices();
  useEffect(() => {
    setIsLoading(true);
    services
      .getDatasetColsOnQuery(DATASET_ID_NEWEST)
      .then(
        (r) => {
          console.log("r: ", r);
          // @ts-ignore
          if (r?.ResponseStatus?.Ack === "Success") {
            setRemoteCols(r.data ?? []);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e))
      .finally(() => {
        setIsLoading(false);
      });
    // 补充全局时间, 避免查询航线航班失败
    if (
      !globalState.airlinesQueryCondition.startDate ||
      !globalState.airlinesQueryCondition.endDate
    ) {
      actions.setAirlinesQueryConditionAttr({
        startDate: moment().add(-30).format(DATE_FORMAT),
        endDate: moment().add(-1).format(DATE_FORMAT),
      });
    }
    if (!globalState.airlinesQueryCondition.airlines) {
      actions.setAirlinesQueryConditionAttr({
        airlines: airlines.map((a) => a.code).join(","),
        airlinesName: airlines.map((a) => a.name).join(","),
      });
    }
  }, []);

  const columns = useMemo(() => {
    return remoteCols.concat(CUSTOM_COLS);
  }, [remoteCols]);

  const tabs: Tab[] = useMemo(
    () => [
      {
        key: "overview",
        label: getSharkText("key.ttl"),
      },
      {
        key: "flightNo",
        label: getSharkText("config_page_flightno_detail"),
      },
    ],
    []
  );

  if (isLoading) {
    return <Spin />;
  }
  if (!globalState.airlinesQueryCondition.airlines) {
    return <Empty description="无权限"></Empty>;
  }
  // if (!remoteCols.length) {
  //   return <Empty description="获取数据集信息失败" />;
  // }

  return (
    <div>
      <div
        style={{ margin: "0 20px", padding: "0 20px", backgroundColor: "#fff" }}
      >
        <Tabs items={tabs} activeKey={activeKey} onChange={setActiveKey} />
      </div>
      <div style={{ margin: "0 20px" }}>
        {activeKey === "overview" ? (
          <OverviewIndex columns={columns} />
        ) : (
          <DetailIndex columns={columns} />
        )}
      </div>
    </div>
  );
};
export default ProfitMonitor;
