import React, { useEffect, useState } from "react";
import { Col, message, Modal, Row } from "antd";
import { CompareAirport, UnitList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import AirportsList from "./AirportsList";

interface CompareAirportListEditProps {
  curUnit: UnitList;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  refetch: () => void;
  compareAirports: CompareAirport[];
  permissionCode: string;
}

const CompareAirportListEdit: React.FC<CompareAirportListEditProps> = (
  props: CompareAirportListEditProps
) => {
  const {
    visible,
    setVisible,
    curUnit,
    refetch,
    compareAirports,
    permissionCode,
  } = props;

  const [airportList, setAirportList] = useState<CompareAirport[]>([]);

  const [{ isLoading }, doFetch] = useFetch({
    url: "setCompareAirport",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      groupId: curUnit.unit.id,
      airportList,
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_modify_success"));
      setVisible(false);
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_fail"));
    },
  });

  useEffect(() => {
    setAirportList(compareAirports);
  }, [curUnit, compareAirports]);

  const handleOk = () => {
    doFetch({
      ext: {
        groupId: curUnit.unit.id,
        airportList,
      },
    });
  };

  return (
    <Modal
      title={getSharkText("config_page_benchmark_airport")}
      open={visible}
      onOk={handleOk}
      onCancel={() => setVisible(false)}
      okText={getSharkText("config_page_confirm")}
      cancelText={getSharkText("key.cancel.button")}
      confirmLoading={isLoading}
    >
      <Row justify="start" gutter={16} align="middle">
        <Col>{getSharkText("config_page_benchmark_airport")}</Col>
        <Col>
          <AirportsList
            setAirportList={setAirportList}
            airportList={airportList}
            permissionCode={permissionCode}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CompareAirportListEdit;
