import React, {
  ReactElement,
  Ref,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Col, Row, Spin } from "antd";
import "./CustomTable.scss";
import ProTable, { ColumnsState, ProTableProps } from "@ant-design/pro-table";
import { IFlightRecord } from "Page/AI/FlightManage/FlightManageInterface";
import { AVDataEntity, FLPDataEntity } from "./GetAVData";
import { isNumeric, orderMerge } from "Utils";
import { ListToolBarTabs } from "@ant-design/pro-table/es/components/ListToolBar";
import { VList, scrollTo } from "virtuallist-antd";

const localStorageKey = "flight_manage_columns_state";

const getDefaultColumnsState = (): Record<string, ColumnsState> => {
  const s = localStorage.getItem(localStorageKey);
  if (s) {
    return JSON.parse(s);
  } else {
    return { autoAdjust: { show: false }, groupDetail: { show: false } };
  }
};

const renderAvFlp = (
  avLoading: boolean,
  avData: AVDataEntity | undefined,
  flpData: FLPDataEntity | undefined
): ReactElement | null => {
  if (avLoading) {
    return <Spin />;
  }
  if (!avData && !flpData) {
    return null;
  }
  const avCabins = avData?.cabinStatuses?.map((av) => av.cls) || [];
  const flpCabins = flpData?.cabinBkdInfos?.map((flp) => flp.cls) || [];
  const allCabins = orderMerge(avCabins, flpCabins);
  return (
    <div className="flightManage-avFlpData">
      {allCabins.map((c) => {
        const av = avData?.cabinStatuses.find((a) => a.cls === c);
        const flp = flpData?.cabinBkdInfos.find((f) => f.cls === c);
        let greenCabinClassName = "";
        if (av && (av.status === "A" || isNumeric(av.status))) {
          greenCabinClassName = "green";
        }
        return (
          <div key={c} className={`cabin ${greenCabinClassName}`}>
            <div className="first">{`${av?.cls || "-"}${
              av?.status || "-"
            }`}</div>
            <div className="second">{`${flp?.bkd || "-"}`}</div>
          </div>
        );
      })}
    </div>
  );
};
interface CustomTableProps {
  columns: any;
  showAvFlp: boolean;
  dataSource: IFlightRecord[];
  handleDownload?: () => void;
  onRecordSelectedChange?: (record: any) => void;
  curRecord: IFlightRecord | undefined;
  setCurRecord: (v: IFlightRecord | undefined) => void;
  otherProps: ProTableProps<any, any>;
  toolbarTabsConfig: ListToolBarTabs;
  onReload: () => void;
  roOpen: boolean;
  isFastRender: boolean;
  setHoveredRowIndex: any;
}
export interface CustomTableHandler {
  scrollToDate: (date: string) => void;
}

const CustomTable = forwardRef(
  (props: CustomTableProps, ref: Ref<CustomTableHandler>) => {
    const {
      columns,
      showAvFlp,
      dataSource,
      curRecord,
      setCurRecord,
      onRecordSelectedChange,
      otherProps,
      toolbarTabsConfig,
      onReload,
      roOpen,
      isFastRender,
      // handleDownload
      setHoveredRowIndex,
    } = props;
    const [columnsState, setColumnsState] = useState<
      Record<string, ColumnsState>
    >(getDefaultColumnsState());

    const onRow = (record: IFlightRecord, Rowindex?: number) => ({
      onClick: () => {
        // dataSource.forEach((rd) => (rd.checked = false));
        // const r = dataSource.find((rd) => rd.id === record.id);
        if (record?.flightType !== 0) {
          return;
        }
        if (curRecord?.id === record.id && !roOpen) {
          setCurRecord(undefined);
        } else {
          setCurRecord(record);
        }
      },
      onDoubleClick: () => {
        // 非自营不展示趋势图
        if (record?.flightType !== 0) {
          return;
        }
        setCurRecord(record);
        // setCurrentId(record.id);
        if (onRecordSelectedChange) {
          onRecordSelectedChange(record);
        }
      },
      onMouseEnter: () => setHoveredRowIndex(Rowindex),
      onMouseLeave: () => setHoveredRowIndex(null),
    });

    const onColumnsStateChange = (v: Record<string, ColumnsState>) => {
      localStorage.setItem(localStorageKey, JSON.stringify(v));
      setColumnsState(v);
    };

    const expandedKeys = useMemo(() => {
      return dataSource.map((d) => d.id);
    }, [dataSource]);

    const VComponents = useMemo(() => {
      return VList({
        height: 1000,
        resetTopWhenDataChange: false,
      });
    }, []);

    useImperativeHandle(ref, () => ({
      scrollToDate: (date: string) => {
        const idx = dataSource.findIndex((d) => d.takeoffdate === date);
        scrollTo({ row: idx + 1 });
      },
    }));

    return (
      <>
        <Row style={{ marginTop: 0, textAlign: "left" }}>
          <Col>
            <ProTable<any>
              className="flight-manage micro-table"
              search={false}
              showSorterTooltip={false}
              tableLayout="fixed"
              dataSource={dataSource}
              bordered
              columns={columns}
              scroll={{ x: "max-content" }}
              style={{ color: "#000000d9" }}
              onRow={onRow}
              rowKey="id"
              columnsState={{
                value: columnsState,
                onChange: onColumnsStateChange,
              }}
              options={{
                reload: onReload,
                density: false,
              }}
              toolbar={{
                multipleLine: true,
                tabs: toolbarTabsConfig,
              }}
              expandable={
                showAvFlp
                  ? {
                      expandedRowRender: (record) =>
                        renderAvFlp(
                          record.avLoading,
                          record.avData,
                          record.flpData
                        ),
                      rowExpandable: (record) =>
                        record.avLoading || record.avData || record.flpData,
                      expandedRowKeys: expandedKeys,
                      showExpandColumn: false,
                    }
                  : undefined
              }
              sticky
              {...otherProps}
              components={isFastRender ? VComponents : undefined}
            />
          </Col>
        </Row>
      </>
    );
  }
);
CustomTable.displayName = "CustomTable";
export default CustomTable;
