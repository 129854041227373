import React, { ReactElement, useMemo, useState } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, Input, Popconfirm, Popover, Select } from "antd";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { InfoCircleFilled } from "@ant-design/icons";
import { StatisticalList } from "../common";
import { getSharkText } from "Utils/i18nGlobal";

const AggEnum = StatisticalList.reduce((acc, cur) => {
  const lower = cur.toLowerCase();
  acc[lower] = { text: lower };
  return acc;
}, {} as Record<string, { text: string }>);

const AggOptions = StatisticalList.map((c) => ({
  label: c.toLowerCase(),
  value: c.toLowerCase(),
}));

/**
 * 使用行编辑后, 部分数值字段会变成字符串, 用这个函数转换一下
 * @param v
 */
const formatRow = (v: any): FDDatasetCol => {
  const rst = {
    ...v,
    kind: Number(v.kind),
    extra: JSON.stringify({ isDate: v.isDate }),
    aggs: Array.isArray(v.aggArr) ? v.aggArr.join(",") : v.aggs,
  };
  delete rst.isDate;
  delete rst.aggArr;
  return rst;
};
export interface Props {
  editKeys?: string[];
  value: FDDatasetCol[];
  onRowSave?: (v: FDDatasetCol) => void;
  onRowChange?: (v: FDDatasetCol) => void;
  onRowDelete: (v: FDDatasetCol) => void;
}

const DataColumns = ({
  value,
  onRowSave,
  onRowChange,
  editKeys,
  onRowDelete,
}: Props): ReactElement => {
  const extraSource = useMemo(() => {
    return value.map((v) => ({
      ...v,
      isDate: v.extra ? JSON.parse(v.extra).isDate : false,
      aggArr: v.aggs ? v.aggs.split(",") : [],
    }));
  }, [value]);
  // 定义表格的列
  const columns: Array<ProColumns<FDDatasetCol>> = [
    {
      title: "ID",
      dataIndex: "id",
      readonly: true,
    },
    {
      title: "Dataset ID",
      dataIndex: "datasetId",
      readonly: true,
    },
    {
      title: "Display name",
      dataIndex: "displayName",
      valueType: "text",
    },
    {
      title: (
        <>
          Operate Name
          <Popover
            content={getSharkText("config_page_not_modify_unless_necessary")}
          >
            <InfoCircleFilled style={{ marginLeft: 5, color: "#faad14" }} />
          </Popover>
        </>
      ),
      dataIndex: "name",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          {
            required: true,
            message: getSharkText("config_page_this_item_is_required"),
          },
        ],
      }),
    },
    {
      title: "Kind",
      dataIndex: "kind",
      valueType: "select",
      valueEnum: {
        0: { text: getSharkText("config_page_indexes") },
        1: { text: getSharkText("config_page_dimension") },
        2: { text: "Expression" },
        3: { text: "UDF" },
      },
      renderFormItem: () => (
        <Select>
          <Select.Option value={0}>
            {getSharkText("config_page_indexes")}
          </Select.Option>
          <Select.Option value={1}>
            {getSharkText("config_page_dimension")}
          </Select.Option>
          <Select.Option value={2}>Expression</Select.Option>
          <Select.Option value={3}>UDF</Select.Option>
        </Select>
      ),
    },
    {
      title: "DB Column",
      dataIndex: "dbcolumn",
      readonly: true,
    },
    {
      title: "JDBC Type",
      dataIndex: "jdbctype",
      valueType: "text",
      valueEnum: {
        VARCHAR: { text: "VARCHAR" },
        BIGINT: { text: "BIGINT" },
        INTEGER: { text: "INTEGER" },
        DOUBLE: { text: "DOUBLE" },
        TIMESTAMP: { text: "TIMESTAMP" },
      },
      renderFormItem: () => (
        <Select size="small">
          <Select.Option value="VARCHAR">VARCHAR</Select.Option>
          <Select.Option value="BIGINT">BIGINT</Select.Option>
          <Select.Option value="INTEGER">INTEGER</Select.Option>
          <Select.Option value="DOUBLE">DOUBLE</Select.Option>
          <Select.Option value="TIMESTAMP">TIMESTAMP</Select.Option>
        </Select>
      ),
    },
    {
      title: "Aggs",
      dataIndex: "aggArr",
      valueType: "text",
      valueEnum: AggEnum,
      renderFormItem: () => (
        <Select
          options={AggOptions}
          mode="multiple"
          allowClear
          style={{ minWidth: 60 }}
          size="small"
        ></Select>
      ),
    },
    {
      title: "Sort",
      dataIndex: "sort",
      valueType: "text",
      renderFormItem: () => (
        <Select allowClear style={{ minWidth: 60 }} size="small">
          <Select.Option value="LONG">LONG</Select.Option>
          <Select.Option value="INT">INT</Select.Option>
          <Select.Option value="DOUBLE">DOUBLE</Select.Option>
          <Select.Option value="FLOAT">FLOAT</Select.Option>
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      valueType: "text",
      renderFormItem: () => <Input.TextArea />,
    },
    {
      title: "Is Date",
      dataIndex: "isDate",
      valueType: "select",
      valueEnum: {
        false: { text: getSharkText("config_page_no") },
        true: { text: getSharkText("config_page_yes") },
      },
      renderFormItem: () => (
        <Select>
          <Select.Option value={false}>
            {getSharkText("config_page_no")}
          </Select.Option>
          <Select.Option value={true}>
            {getSharkText("config_page_yes")}
          </Select.Option>
        </Select>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      valueType: "option",
      render: (_: any, record: any) => (
        <>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              // 进入编辑模式
              setEditableRowKeys((s) => {
                s.push(record.dbcolumn);
                return [...s];
              });
            }}
          >
            {getSharkText("config_page_edit")}
          </Button>
          <Popconfirm
            title={getSharkText("config_page_are_you_sure_to_delete")}
            onConfirm={() => {
              // 删除当前行
              onRowDelete(record);
            }}
          >
            <Button
              type="primary"
              danger
              style={{ marginLeft: 10 }}
              size="small"
            >
              {getSharkText("config_page_delete")}
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const [editableRowKeys, setEditableRowKeys] = useState<React.Key[]>(
    editKeys || []
  );

  const upsertDatasetColumn = (id: React.Key[] | React.Key, record: any) => {
    // 在这里处理更新或插入数据集列的逻辑
    console.log("Upsert dataset column:", id, record);
    setEditableRowKeys((s) => s.filter((s1) => s1 !== id));
    if (onRowSave) {
      onRowSave(formatRow(record));
    }
    // onChange([]);
    return record;
  };

  return (
    <ProTable<FDDatasetCol>
      columns={columns}
      dataSource={extraSource}
      rowKey="dbcolumn"
      tableLayout="auto"
      size="small"
      search={false}
      options={false}
      editable={{
        type: "single",
        editableKeys: editableRowKeys,
        onChange: setEditableRowKeys,
        onSave: upsertDatasetColumn,
        onValuesChange: (record) => {
          if (onRowChange) {
            onRowChange(formatRow(record));
          }
        },
      }}
    />
  );
};

export default DataColumns;
