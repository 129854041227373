import { Checkbox, Col, DatePicker, message, Radio, Row } from "antd";
import { DATE_FORMAT } from "Constants";
import { AggCode, IDownloadHeader, ISeries } from "Interface";
import moment, { Moment } from "moment";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../TravixFetchCode";
import {
  ETravixCompareType,
  ETravixDimensionType,
  ETravixValueType,
} from "../TravixCommon";
import SalesTable from "./SalesTable";
import { enumKeys } from "Utils/global";
import BarLineCharts from "./BarLineCharts";
import { cloneDeep } from "lodash";
import {
  getComparePercentageVal,
  getDateFromAggCodeEN,
  showNum,
  showRawNum,
  tableNumSorter,
} from "Utils";
import { SortOrder } from "antd/lib/table/interface";
import useI18Next from "Utils/useI18Next";

const { RangePicker } = DatePicker;
/**
 * Component description
 *
 */

/**
 * 截止日期取最近的周日, 如果当天是周日, 则取上一个周日
 * @returns
 */
const defaultEndDate = (): Moment => {
  const today = moment();
  if (today.day() === 0) {
    return today.add(-1, "week");
  } else {
    return today.set("day", 0);
  }
};

interface IProps {
  marketType?: number;
  country?: string;
  panelType: "overview" | "salesData";
}

const Sales = (props: IProps): ReactElement => {
  const { marketType = 0, panelType, country } = props;

  const defaultRange: [Moment, Moment] = useMemo(
    () => [moment("2021-01-04"), defaultEndDate()],
    []
  );

  const [queryExt, setQueryExt] = useState<Record<string, unknown>>();
  const [range, setRange] = useState<[Moment, Moment]>(defaultRange);
  const [compareType, setCompareType] = useState<number>(2);
  const [valueType, setValueType] = useState<number[]>([1, 2]);
  const [dimensionType, setDimensionType] = useState<number[]>([1, 2, 3]);
  const [totalSeries, setTotalSeries] = useState<ISeries[]>([]);
  const [aggCode, setAggCode] = useState<AggCode>(1);
  const [columns, setColumns] = useState<IDownloadHeader[]>([]);
  const t = useI18Next();

  const baseColumns: IDownloadHeader[] = useMemo(
    () => [
      {
        title: "date",
        dataIndex: "day",
        width: 120,
        sorter: (a: any, b: any) => a.day.localeCompare(b.day),
        render: (val: any) => getDateFromAggCodeEN(aggCode, val),
      },
      {
        title: panelType === "overview" ? "Departure Country" : "Market",
        dataIndex: panelType === "overview" ? "departureCountry" : "market",
        sorter: (a: any, b: any) =>
          a.departureCountry.localeCompare(b.departureCountry),
        width: 60,
      },
    ],
    [aggCode, panelType]
  );

  /**
   * Travix年粒度时, 选择跨年无法计算年同比, 因此需要限制年粒度不能跨年
   * @param agg 粒度
   * @param ranges 时间区间
   * @returns 如果粒度为年. 时间区间不跨年, 返回true, 跨年返回false
   */
  const dateRangeAndAggCheck = useCallback(
    (agg: AggCode, ranges: [Moment, Moment]) => {
      if (agg === AggCode.year && ranges[0].year() !== ranges[1].year()) {
        message.error(t("travix.yearDateError"), 8);
        return false;
      }
      return true;
    },
    [t]
  );

  useEffect(() => {
    if (!dateRangeAndAggCheck(aggCode, range)) {
      setRange(defaultRange);
    }
  }, [aggCode, dateRangeAndAggCheck, defaultRange, range]);

  useEffect(() => {
    const tmpSeries: ISeries[] = [];
    enumKeys(ETravixDimensionType).forEach(
      (dim: keyof typeof ETravixDimensionType) => {
        if (!dimensionType.includes(ETravixDimensionType[dim])) {
          return;
        }
        enumKeys(ETravixValueType).forEach(
          (value: keyof typeof ETravixValueType) => {
            if (!valueType.includes(ETravixValueType[value])) {
              return;
            }
            tmpSeries.push(
              {
                name: `${dim}${value}`,
                type: "line",
                encode: {
                  x: "day",
                  y: `${dim.toLocaleLowerCase()}${value}`,
                },
              },
              {
                name: `${dim}${value} ${ETravixCompareType[compareType]}`,
                type: "bar",
                yAxisIndex: 1,
                isPercentVal: true,
                encode: {
                  x: "day",
                  y: `${dim.toLocaleLowerCase()}${value}ComparePer`,
                },
              }
            );
          }
        );
      }
    );
    setTotalSeries(tmpSeries);

    const tmpColumns: IDownloadHeader[] = cloneDeep(baseColumns);
    enumKeys(ETravixDimensionType).forEach(
      (dim: keyof typeof ETravixDimensionType) => {
        if (!dimensionType.includes(ETravixDimensionType[dim])) {
          return;
        }
        enumKeys(ETravixValueType).forEach(
          (value: keyof typeof ETravixValueType) => {
            // Pax share数据没有年同比
            if (value !== "PaxShare") {
              tmpColumns.push({
                title: `${dim} ${value}`,
                dataIndex: `${dim.toLowerCase()}${value}`,
                sorter: (a: any, b: any) =>
                  a[`${dim.toLowerCase()}${value}`] -
                  b[`${dim.toLowerCase()}${value}`],
                render: (val: number) => showNum(val, "num"),
              });
              tmpColumns.push({
                title: `${ETravixCompareType[compareType]} +/- ${dim} ${value} %`,
                dataIndex: `${dim.toLowerCase()}${value}Compare`,
                sorter: (a: any, b: any, sorter?: SortOrder) => {
                  const perA = getComparePercentageVal(
                    a[`${dim.toLowerCase()}${value}`],
                    a[`${dim.toLowerCase()}${value}Compare`]
                  );
                  const perB = getComparePercentageVal(
                    b[`${dim.toLowerCase()}${value}`],
                    b[`${dim.toLowerCase()}${value}Compare`]
                  );
                  return tableNumSorter(perA, perB, sorter);
                },
                render: (val: number, r: any) => {
                  const per = getComparePercentageVal(
                    r[`${dim.toLowerCase()}${value}`],
                    r[`${dim.toLowerCase()}${value}Compare`]
                  );
                  return showNum(per, "percentage");
                },
                downloadFormatter: (val: number, r: any) => {
                  const per = getComparePercentageVal(
                    r[`${dim.toLowerCase()}${value}`],
                    r[`${dim.toLowerCase()}${value}Compare`]
                  );
                  return showRawNum(per, "percentage");
                },
              });
            }
          }
        );
      }
    );
    if ([2, 3].includes(aggCode)) {
      tmpColumns.push({
        title: "Travix Pax share %",
        dataIndex: "",
        render: (val: any) => showNum(val, "percentage"),
      });
    }
    setColumns(tmpColumns);
  }, [aggCode, baseColumns, compareType, dimensionType, valueType]);

  useEffect(() => {
    setQueryExt({
      query: {
        startDate: range[0] ? range[0].format(DATE_FORMAT) : "",
        endDate: range[1] ? range[1].format(DATE_FORMAT) : "",
        compareType,
        partner: "travix",
        marketType,
      },
      aggCode,
      country,
    });
  }, [
    range,
    compareType,
    valueType,
    dimensionType,
    marketType,
    aggCode,
    country,
  ]);

  const changeDateRange = (
    rangeDate: [Moment | null, Moment | null] | null
  ) => {
    if (!rangeDate || !rangeDate[0] || !rangeDate[1]) {
      return;
    }
    const ranges = rangeDate as [Moment, Moment];
    // 年粒度不允许时间范围跨年
    if (!dateRangeAndAggCheck(aggCode, ranges)) {
      return;
    }
    setRange(ranges);
  };

  return (
    <div>
      <Row>
        <Col>
          <h2 style={{ color: "#1890FF" }}>Sales data - EU summary</h2>
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{ marginTop: 10 }}>
        <Col>
          <RangePicker
            value={range}
            defaultValue={defaultRange}
            onChange={changeDateRange}
          />
        </Col>
        <Col>
          <Radio.Group
            value={compareType}
            onChange={(e) => setCompareType(e.target.value)}
          >
            {enumKeys(ETravixCompareType).map(
              (key: keyof typeof ETravixCompareType) => {
                return (
                  <Radio.Button value={ETravixCompareType[key]} key={key}>
                    {key}
                  </Radio.Button>
                );
              }
            )}
          </Radio.Group>
        </Col>
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={(e) => setAggCode(e.target.value)}
          >
            <Radio.Button value={0}>Day</Radio.Button>
            <Radio.Button value={1}>Week</Radio.Button>
            <Radio.Button value={2}>Month</Radio.Button>
            <Radio.Button value={3}>Year</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Checkbox.Group
            value={valueType}
            onChange={(e) => setValueType(e as number[])}
          >
            <Checkbox value={1}>Seg</Checkbox>
            <Checkbox value={2}>GMV</Checkbox>
          </Checkbox.Group>
        </Col>
        <Col>
          <Checkbox.Group
            value={dimensionType}
            onChange={(e) => setDimensionType(e as number[])}
          >
            <Checkbox value={1}>Travix</Checkbox>
            <Checkbox value={2}>Trip</Checkbox>
            <Checkbox value={3}>Total</Checkbox>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col span={24}>
          <BarLineCharts
            server={getServer(4)}
            queryUrl={QUERY_URL.barline}
            chartTableCode={CHART_TABLE_CODE[panelType].Sales}
            moduleCode={MODULE_CODE}
            defaultSeries={totalSeries}
            ext={queryExt}
            startDate={range && range[0] ? range[0].format(DATE_FORMAT) : ""}
            endDate={range && range[1] ? range[1].format(DATE_FORMAT) : ""}
            compareType={compareType}
            aggCode={aggCode}
            useGranularity={false}
          />
        </Col>
        <Col span={24}>
          <SalesTable panelType={panelType} ext={queryExt} columns={columns} />
        </Col>
      </Row>
    </div>
  );
};
export default Sales;
