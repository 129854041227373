import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { Button, Col, Input, Radio, Row, Select, message } from "antd";
import React, {
  ReactElement,
  Ref,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { SortType } from "../../interface";
import useRefFunc from "Utils/useRefFunc";
import { EMPTY_ARRAY } from "Constants";
import { IGetValueHandler } from "../Measure/FormatterEditor";
import { getSharkText } from "Utils/i18nGlobal";

interface StatisticsConfig {
  /** 字段类型允许的聚合操作 */
  allows: SortType[];
  /** 生成计算表达式 */
  genColExpression: (col: FDDatasetCol, method: string) => string;
}

const DEFAULT_GEN_COL_EXPRESSION = (col: FDDatasetCol, method: string) => {
  const prefix = col.kind === 0 ? "measure" : "dimension";
  return `${method.toLocaleLowerCase()}(${prefix}.${col.name})`;
};

const statisticsMap: Record<string, StatisticsConfig> = {
  SUM: {
    allows: ["LONG", "INT", "DOUBLE", "FLOAT"],
    genColExpression: DEFAULT_GEN_COL_EXPRESSION,
  },
  MAX: {
    allows: ["LONG", "INT", "DOUBLE", "FLOAT"],
    genColExpression: DEFAULT_GEN_COL_EXPRESSION,
  },
  MIN: {
    allows: ["LONG", "INT", "DOUBLE", "FLOAT"],
    genColExpression: DEFAULT_GEN_COL_EXPRESSION,
  },
  AVG: {
    allows: ["LONG", "INT", "DOUBLE", "FLOAT"],
    genColExpression: DEFAULT_GEN_COL_EXPRESSION,
  },
  COUNT: {
    allows: ["LONG", "INT", "DOUBLE", "FLOAT", null],
    genColExpression: DEFAULT_GEN_COL_EXPRESSION,
  },
  "COUNT DISTINCT": {
    allows: [null],
    genColExpression: (col) => {
      const prefix = col.kind === 0 ? "measure" : "dimension";
      return `countdistinct(${prefix}.${col.name})`;
    },
  },
};

export interface ExpEditorProps {
  datasetId: number;
  /** 原子指标 */
  columns: FDDatasetCol[];
  defaultValue?: string;
}

/** 表达式编辑器 */
const ExpEditor = forwardRef(
  (props: ExpEditorProps, ref: Ref<IGetValueHandler>): ReactElement => {
    const { columns, defaultValue } = props;
    const [displayName, setDisplayName] = useState<string>("");
    const [expression, setExpression] = useState<string>(defaultValue || "");
    const [selectedColId, setSelectedColId] = useState<number>();
    /** 默认为m_exp, 如果值不存在, 则视为m_exp */
    const [computeType, setComputeType] = useState<"m_exp" | "p_exp">("m_exp");
    const selectedCol = useMemo(() => {
      return columns.find((c) => c.id === selectedColId);
    }, [columns, selectedColId]);

    useImperativeHandle(ref, () => ({
      getValue: () => {
        if (displayName && expression) {
          const extraObj = { isExp: true, computeType };
          const newCol: Partial<FDDatasetCol> = {
            dbcolumn: expression,
            displayName,
            kind:
              computeType === "m_exp" ? 2 : computeType === "p_exp" ? 3 : -1,
            extra: JSON.stringify(extraObj),
          };
          return newCol;
        } else {
          message.error("名称和表达式不能为空");
          return null;
        }
      },
    }));

    const allowedStatistics = useMemo(() => {
      if (!selectedCol) {
        return EMPTY_ARRAY;
      }
      return Object.keys(statisticsMap).filter((method) => {
        return statisticsMap[method].allows.includes(
          selectedCol.sort as SortType
        );
      });
    }, [selectedCol]);

    const appendExpression = useRefFunc((key) => {
      if (!selectedCol) {
        message.error("column error, id is :", selectedColId);
        return;
      }
      const config = statisticsMap[key];
      const colExpression = config.genColExpression(selectedCol, key);
      setExpression((s) => s + colExpression);
      setSelectedColId(undefined);
    });

    const appendLogic = useRefFunc((logic: string) => {
      setExpression((s) => s + logic);
    });

    return (
      <div>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>{getSharkText("config_page_display_name")}</Col>
          <Col>
            <Input
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>{getSharkText("key.type")}</Col>
          <Col>
            <Radio.Group
              value={computeType}
              onChange={(e) => setComputeType(e.target.value)}
              options={[
                {
                  label: "Expression",
                  value: "m_exp",
                },
                {
                  label: "UDF",
                  value: "p_exp",
                },
              ]}
            ></Radio.Group>
          </Col>
        </Row>
        {computeType === "m_exp" ? (
          <>
            <Row style={{ marginBottom: 10 }}>
              <Col span={4}>{getSharkText("config_page_select_field")}</Col>
              <Col>
                <Select
                  value={selectedColId}
                  onChange={setSelectedColId}
                  options={columns}
                  fieldNames={{ label: "name", value: "id" }}
                  style={{ minWidth: 120 }}
                ></Select>
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col span={4}>
                {getSharkText("config_page_calculation_method")}
              </Col>
              <Col>
                {allowedStatistics.map((key) => (
                  <Button
                    size="small"
                    type="primary"
                    key={key}
                    onClick={() => appendExpression(key)}
                    style={{ marginRight: 5 }}
                  >
                    {key}
                  </Button>
                ))}
              </Col>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <Col span={4}>
                {getSharkText("config_page_logic_relationship")}
              </Col>
              <Col>
                {["+", "-", "*", "/", "%"].map((item) => (
                  <Button
                    key={item}
                    size="small"
                    type="primary"
                    style={{ marginRight: 5 }}
                    onClick={() => appendLogic(item)}
                  >
                    {item}
                  </Button>
                ))}
              </Col>
            </Row>
          </>
        ) : undefined}
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>{getSharkText("key.expression")}</Col>
          <Col>
            <Input.TextArea
              rows={3}
              value={expression}
              onChange={(e) => setExpression(e.target.value)}
            ></Input.TextArea>
          </Col>
        </Row>
      </div>
    );
  }
);
export default ExpEditor;
