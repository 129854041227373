import React from "react";
import { IDownloadHeader } from "Interface";
import { PollData } from "./IPoll";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

export const getColumns = (
  edit: (row: PollData) => void,
  del: (row: PollData) => void
): Array<IDownloadHeader<PollData>> => [
  {
    title: getSharkText("config_page_serial_number"),
    dataIndex: "feSortId",
    width: 120,
    fixed: true,
  },
  {
    title: "PollId",
    dataIndex: "pollId",
    width: 120,
    fixed: true,
  },
  {
    title: getSharkText("config_page_name"),
    dataIndex: "questionnaireName",
  },
  {
    title: getSharkText("config_page_display_start"),
    dataIndex: "startTime",
    width: 120,
    render: (v: string) => moment(v).format(DATE_FORMAT),
  },
  {
    title: getSharkText("config_page_display_end"),
    dataIndex: "endTime",
    width: 120,
    render: (v: string) => moment(v).format(DATE_FORMAT),
  },
  {
    title: getSharkText("config_page_group_id"),
    dataIndex: "",
    render: (v, r: PollData) =>
      r.feUnit ? r.feUnit.map((u) => u.id).join() : "",
  },
  {
    title: getSharkText("config_page_group_code"),
    dataIndex: "",
    render: (v, r: PollData) =>
      r.feUnit ? r.feUnit.map((u) => u.unitCode).join() : "",
  },
  {
    title: getSharkText("config_page_group_name"),
    dataIndex: "",
    ellipsis: true,
    render: (v, r: PollData) =>
      r.feUnit ? r.feUnit.map((u) => u.unitName).join() : "",
  },
  {
    title: getSharkText("config_page_user_name"),
    dataIndex: "userIdList",
    width: 135,
    ellipsis: true,
    render: (v, r: PollData) =>
      r.feUsers ? r.feUsers.map((u) => u.name).join() : "",
  },
  // {
  //   title: "问卷链接",
  //   dataIndex: "url",
  //   width: 80,
  // },
  {
    title: getSharkText("config_page_operation"),
    dataIndex: "actions",
    width: 150,
    fixed: "right",
    render: (v: any, record: PollData) => {
      return (
        <>
          <a
            key="edit"
            onClick={() => {
              edit(record);
            }}
          >
            {getSharkText("config_page_edit")}
          </a>
          <a
            style={{ marginLeft: 5 }}
            key="delete"
            onClick={() => {
              del(record);
            }}
          >
            {getSharkText("config_page_delete")}
          </a>
        </>
      );
    },
  },
];
