import { CMDPrice } from "@ctrip/flt-bi-flightai-airlines";
import React, { ReactElement, useEffect, useState } from "react";
import { ubt, useFetch } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import { MODULE_CODE, QUERY_URL } from "../../fetchCode";
import usePageContext from "../../FlightManageContext";
import { IFlightRecord } from "../../FlightManageInterface";
import { getServer } from "Service/server";
import { SystemType } from "Interface";

// 最多请求次数
const maxCount = 10;
// 每次间隔时间(秒)
const interval = 2 * 1000;

export interface IRefreshCMDPriceProps {
  cacheKey: string;
  row: IFlightRecord;
  updateRow: (newRow: IFlightRecord) => void;
}

/**
 * 执行指令后轮询查询价格变更
 */
const RefreshCMDPrice = (props: IRefreshCMDPriceProps): ReactElement => {
  const { cacheKey, row, updateRow } = props;
  const [, actions] = usePageContext();
  const { removeFetchCMDPrice } = actions;
  const [count, setCount] = useState(0);

  const handleStop = useRefFunc(() => {
    removeFetchCMDPrice(cacheKey);
  });

  const [, doFetch] = useFetch<CMDPrice>({
    server: getServer(SystemType.airlines),
    url: QUERY_URL.CMDPrice,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    ext: { cacheKey },
    lazey: true,
    onSuccess: (res) => {
      const data = res.data;
      ubt("cmdPriceResponse", data);
      if (data.isChanged || data.isTimeout) {
        ubt("cmdPriceStop", data);
        setCount(maxCount);
        handleStop();
      }
      if (
        data.changedPrice &&
        (data.isChanged ||
          data.isTimeout ||
          data.changedPrice !== row.suggestPrice)
      ) {
        ubt("cmdPriceUpdate", data);
        updateRow({ ...row, suggestPrice: data.changedPrice });
      } else {
        console.log("price not changed, data is : ", data);
      }
    },
  });
  useEffect(() => {
    if (count < maxCount) {
      const id = setTimeout(() => {
        setCount((c) => ++c);
        doFetch({});
      }, interval) as any;
      return () => {
        clearTimeout(id);
      };
    } else {
      ubt("cmdPriceStop", { count });
      handleStop();
    }
  }, [doFetch, count, handleStop]);

  return <></>;
};
export default RefreshCMDPrice;
