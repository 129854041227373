import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  PropsWithChildren,
  useRef,
} from "react";
import {
  QueryCondition,
  Login,
  UserInfo,
  VerifyInfo,
  LoginPageState,
  SystemType,
  AirlinesQueryCondition,
  IModuleConfig,
  IDateRangeGroup,
  EDateRangeGroupsNames,
  Theme,
} from "Interface";
import {
  DEFAULT_FLIGHT_CLASS,
  DATE_FORMAT,
  DEFAULT_AREA,
  DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
  DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
  THEMES,
} from "Constants";
import { Moment } from "moment";
import { getDateRangeGroupName } from "Utils/global";
import { Area } from "@ctrip/flt-bi-flightai-airlines";
import { PartnerCondition } from "@ctrip/flt-bi-flightai-partners";
import useRefFunc from "Utils/useRefFunc";
import { getCookieToken, getCookieUID } from "Utils";
import { isEmpty } from "lodash";

const SET_AIRLINES_QUERY_CONDITION = "SET_AIRLINES_QUERY_CONDITION";
const SET_QUERY_CONDITION = "SET_QUERY_CONDITION";
// 两个set_query_condition事件如果触发间隔过短,值会覆盖, 因此airport和date分开设置
const SET_QUERYCONDITION_ATTR = "SET_QUERYCONDITION_ATTR";
const SET_QUERY_CONDITION_AIRPORT = "SET_QUERY_CONDITION_AIRPORT";
const SET_AIRLINES_QUERY_CONDITION_ATTR = "SET_AIRLINES_QUERY_CONDITION_ATTR";
const SET_AIRLINES_QUERY_CONDITION_AIRLINE =
  "SET_AIRLINES_QUERY_CONDITION_AIRLINE";

const SET_PARTNER_CONDITION = "SET_PARTNER_CONDITION";
const SET_PARTNER_CONDITION_ATTR = "SET_PARTNER_CONDITION_ATTR";

const SET_LOGIN = "SET_LOGIN";
const SET_USERINFO = "SET_USERINFO";
const SET_HELPVISIBILITY = "SET_HELPVISIBILITY";
const SET_VERIFYINFO = "SET_VERIFYINFO";
const SET_LOGINPAGESTATE = "SET_LOGINPAGESTATE";
const SET_REFRESHCODESTATE = "SET_REFRESHCODESTATE";
const SET_SYSTEMTYPE = "SET_SYSTEMTYPE";
const SET_MODULECONFIG = "SET_MODULECONFIG";
const SET_DEMO_MODAL_SHOWED = "SET_DEMO_MODAL_SHOWED";
const SET_DATE_RANGE_IN_GROUP = "SET_DATE_RANGE_IN_GROUP";
const SET_THEME = "SET_THEME";

export interface IGlobalState {
  airlinesQueryCondition: AirlinesQueryCondition;
  queryCondition: QueryCondition;
  partnerCondition: PartnerCondition;
  helpVisibility: boolean;
  login: Login;
  userInfo: UserInfo;
  verifyInfo: VerifyInfo;
  loginPageState: LoginPageState;
  refreshCode: boolean;
  /** 1=airport,2=airline */
  systemType: SystemType;
  moduleConfig: IModuleConfig;
  // 控制demo modal 只显示一次
  demoModalShowed: boolean;
  dateRangeInGroup: IDateRangeGroup;
  theme: Theme;
}

const getLocalStorageTheme = () => {
  const savedTheme = localStorage.getItem("theme");
  return savedTheme && THEMES.includes(savedTheme as any)
    ? (savedTheme as any)
    : "light";
};

export interface IStateAction {
  setAirlinesQueryCondition: (v: AirlinesQueryCondition) => void;
  setAirlinesQueryConditionAttr: (v: Partial<AirlinesQueryCondition>) => void;
  /**
   * 当参数中的值在当前全局变量中不存在时设置
   */
  setAirlinesAttrIfNull: (v: Partial<AirlinesQueryCondition>) => void;
  setAirlinesQueryConditionAirline: (v: {
    airlines: string;
    airlinesName: string;
  }) => void;
  setQueryCondition: (v: QueryCondition) => void;
  setQueryConditionAttr: (v: Partial<QueryCondition>) => void;
  setAirportAttrIfNull: (v: Partial<QueryCondition>) => void;
  setQueryConditionAirport: (v: {
    airport: string;
    airportName: string;
    area: Area;
    originalAirport: Area;
  }) => void;
  setPartnerCondition: (v: PartnerCondition) => void;
  setPartnerConditionAttr: (v: Partial<PartnerCondition>) => void;
  setLogin: (v: Login) => void;
  setSystemType: (v: SystemType) => void;
  setUserInfo: (v: UserInfo) => void;
  setHelpVisibility: (v: boolean) => void;
  setVerifyInfo: (v: boolean) => void;
  setLoginPageState: (v: LoginPageState) => void;
  setRefreshCodeState: (v: boolean) => void;
  setModuleConfig: (v: Partial<IModuleConfig>) => void;
  setDemoModalShowed: (v: boolean) => void;
  setDateRangeInGroup: (v: Moment[], url: string) => void;
  /** 兼容用户不允许使用cookie的情况 */
  getLogin: () => Login;
  setTheme: (v: Theme) => void;
}

const initialState: IGlobalState = {
  login: {
    uid: getCookieUID(),
    token: getCookieToken(),
    name: "",
  },
  systemType: 1,
  userInfo: {
    user: {
      id: 0,
      uid: "",
      name: "",
      mobilePrefix: "",
      mobile: "",
      wechat: "",
      mail: "",
      groupId: 0,
      mainUnitCode: "",
      mainUnitName: "",
      memberType: 0,
      userStatus: 0,
      userType: 0,
      sortId: 0,
      registerDate: "",
      lastLoginDate: "",
      demoType: 0,
    },
    moduleList: [],
    roleList: undefined,
    unitList: undefined,
  },
  queryCondition: {
    flightClass: DEFAULT_FLIGHT_CLASS,
    airport: "",
    airportName: "",
    departure: 1,
    arrive: 1,
    startDate: "",
    endDate: "",
    useCompare: 1,
    compareType: DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
    area: DEFAULT_AREA,
  },
  airlinesQueryCondition: {
    flightClass: DEFAULT_FLIGHT_CLASS,
    airlines: "",
    airlinesName: "",
    startDate: "",
    endDate: "",
    takeoffTime: "",
    compareType: DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
    weekAlign: 1,
    fsa: 1,
    lcc: 1,
    ext: null,
  },
  partnerCondition: {
    partner: "",
    partnerName: "",
    startDate: "",
    endDate: "",
    compareType: 0,
    marketType: 0,
  },
  helpVisibility: false,
  verifyInfo: {
    token: "",
    version: "",
    requestId: "",
  },
  loginPageState: {
    mode: "login",
  },
  refreshCode: false,
  moduleConfig: {
    loading: false,
    updateTime: "",
    allowAccessAllAirports: false,
  },
  dateRangeInGroup: {
    businessAnalysis: [],
  },
  demoModalShowed: false,
  theme: getLocalStorageTheme(),
};

const GlobalStateContext = createContext<any>(null);

const globalStateReducer = (state: IGlobalState, action: any): IGlobalState => {
  switch (action.type) {
    case SET_AIRLINES_QUERY_CONDITION:
      return {
        ...state,
        airlinesQueryCondition: { ...action.payload },
      };
    case SET_AIRLINES_QUERY_CONDITION_ATTR:
      return {
        ...state,
        airlinesQueryCondition: {
          ...state.airlinesQueryCondition,
          ...action.payload,
        },
      };
    case SET_AIRLINES_QUERY_CONDITION_AIRLINE:
      return {
        ...state,
        airlinesQueryCondition: {
          ...state.airlinesQueryCondition,
          ...action.payload,
        },
      };
    case SET_QUERY_CONDITION:
      return {
        ...state,
        queryCondition: { ...action.payload },
      };
    case SET_QUERYCONDITION_ATTR:
      return {
        ...state,
        queryCondition: {
          ...state.queryCondition,
          ...action.payload,
        },
      };
    case SET_QUERY_CONDITION_AIRPORT:
      return {
        ...state,
        queryCondition: {
          ...state.queryCondition,
          ...action.payload,
        },
      };
    case SET_PARTNER_CONDITION:
      return {
        ...state,
        partnerCondition: { ...action.payload },
      };
    case SET_PARTNER_CONDITION_ATTR:
      return {
        ...state,
        partnerCondition: {
          ...state.partnerCondition,
          ...action.payload,
        },
      };
    case SET_LOGIN:
      return {
        ...state,
        login: { ...action.payload },
      };
    case SET_SYSTEMTYPE:
      return {
        ...state,
        systemType: action.payload,
      };
    case SET_USERINFO:
      return {
        ...state,
        userInfo: { ...action.payload },
      };
    case SET_HELPVISIBILITY:
      return {
        ...state,
        helpVisibility: action.payload,
      };
    case SET_VERIFYINFO:
      return {
        ...state,
        verifyInfo: { ...action.payload },
      };
    case SET_LOGINPAGESTATE:
      return {
        ...state,
        loginPageState: { ...action.payload },
      };
    case SET_REFRESHCODESTATE:
      return {
        ...state,
        refreshCode: { ...action.payload },
      };
    case SET_MODULECONFIG:
      return {
        ...state,
        moduleConfig: { ...action.payload },
      };
    case SET_DEMO_MODAL_SHOWED:
      return {
        ...state,
        demoModalShowed: action.payload,
      };
    case SET_DATE_RANGE_IN_GROUP:
      return {
        ...state,
        dateRangeInGroup: {
          ...state.dateRangeInGroup,
          ...action.payload,
        },
      };
    case SET_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    default:
      console.warn("not has this actionType: ", action.type);
      return state;
  }
};

const useGlobalState = (): [IGlobalState, IStateAction] => {
  const [state, dispatch] = useContext(GlobalStateContext);

  const setAirlinesQueryCondition = useRefFunc(
    (airlinesQueryCondition: AirlinesQueryCondition) => {
      dispatch({
        type: SET_AIRLINES_QUERY_CONDITION,
        payload: airlinesQueryCondition,
      });
    }
  );

  const setAirlinesQueryConditionAttr = useRefFunc(
    (airlinesQueryCondition: Partial<AirlinesQueryCondition>) => {
      dispatch({
        type: SET_AIRLINES_QUERY_CONDITION_ATTR,
        payload: airlinesQueryCondition,
      });
    }
  );

  const setAirlinesAttrIfNull = useRefFunc(
    (airlinesQueryCondition: Partial<AirlinesQueryCondition>) => {
      const newAttr: Partial<AirlinesQueryCondition> = {};
      Object.keys(airlinesQueryCondition).forEach((key) => {
        if (!state.airlinesQueryCondition[key]) {
          // @ts-ignore
          newAttr[key] = airlinesQueryCondition[key];
        }
      });
      if (!isEmpty(newAttr)) {
        dispatch({
          type: SET_AIRLINES_QUERY_CONDITION_ATTR,
          payload: newAttr,
        });
      }
    }
  );

  const setAirlinesQueryConditionAirline = useRefFunc(
    (v: { airlines: string; airlinesName: string }) => {
      dispatch({
        type: SET_AIRLINES_QUERY_CONDITION_AIRLINE,
        payload: v,
      });
    }
  );

  const setQueryCondition = useRefFunc((queryCondition: QueryCondition) => {
    dispatch({
      type: SET_QUERY_CONDITION,
      payload: queryCondition,
    });
  });

  const setQueryConditionAttr = useRefFunc(
    (queryCondition: Partial<QueryCondition>) => {
      dispatch({
        type: SET_QUERYCONDITION_ATTR,
        payload: queryCondition,
      });
    }
  );

  const setAirportAttrIfNull = useRefFunc(
    (queryCondition: Partial<QueryCondition>) => {
      const newAttr: Partial<QueryCondition> = {};
      Object.keys(queryCondition).forEach((key) => {
        if (!state.queryCondition[key]) {
          // @ts-ignore
          newAttr[key] = queryCondition[key];
        }
      });
      if (!isEmpty(newAttr)) {
        dispatch({
          type: SET_QUERYCONDITION_ATTR,
          payload: newAttr,
        });
      }
    }
  );

  const setQueryConditionAirport = useRefFunc(
    (v: {
      airport: string;
      airportName: string;
      originalAirport: Area;
      area: Area;
    }) => {
      dispatch({
        type: SET_QUERY_CONDITION_AIRPORT,
        payload: v,
      });
    }
  );

  const setPartnerCondition = useRefFunc(
    (partnerCondition: PartnerCondition) => {
      dispatch({
        type: SET_PARTNER_CONDITION,
        payload: partnerCondition,
      });
    }
  );

  const setPartnerConditionAttr = useRefFunc(
    (partnerCondition: Partial<PartnerCondition>) => {
      dispatch({
        type: SET_PARTNER_CONDITION,
        payload: partnerCondition,
      });
    }
  );

  const setLogin = useRefFunc((login: Login) => {
    dispatch({
      type: SET_LOGIN,
      payload: login,
    });
  });

  const setSystemType = useRefFunc((systemType: SystemType) => {
    dispatch({
      type: SET_SYSTEMTYPE,
      payload: systemType,
    });
  });

  const setUserInfo = useRefFunc((userInfo: UserInfo) => {
    dispatch({
      type: SET_USERINFO,
      payload: userInfo,
    });
  });

  const setHelpVisibility = useRefFunc((visibility: boolean) => {
    dispatch({
      type: SET_HELPVISIBILITY,
      payload: visibility,
    });
  });

  const setVerifyInfo = useRefFunc((verifyInfo: boolean) => {
    dispatch({
      type: SET_VERIFYINFO,
      payload: verifyInfo,
    });
  });

  const setLoginPageState = useRefFunc((loginPageState: LoginPageState) => {
    dispatch({
      type: SET_LOGINPAGESTATE,
      payload: loginPageState,
    });
  });

  const setRefreshCodeState = useRefFunc((refreshCode: boolean) => {
    dispatch({
      type: SET_REFRESHCODESTATE,
      payload: refreshCode,
    });
  });

  const setModuleConfig = useRefFunc((moduleConfig: Partial<IModuleConfig>) => {
    dispatch({
      type: SET_MODULECONFIG,
      payload: moduleConfig,
    });
  });

  const setDemoModalShowed = useRefFunc((demoModalShowed: boolean) => {
    dispatch({
      type: SET_DEMO_MODAL_SHOWED,
      payload: demoModalShowed,
    });
  });

  const setDateRangeInGroup = useRefFunc((v: Moment[], url: string) => {
    const name: keyof typeof EDateRangeGroupsNames | undefined =
      getDateRangeGroupName(url);
    if (name) {
      const tmp = { [name]: v.map((m: Moment) => m.format(DATE_FORMAT)) };
      dispatch({
        type: SET_DATE_RANGE_IN_GROUP,
        payload: tmp,
      });
    }
  });

  const getLoginRef = useRef(() => {
    const cookieUid = getCookieUID();
    const cookieToken = getCookieToken();
    const login = state.login as Login;
    return {
      name: login.name,
      uid: cookieUid || login.uid,
      token: cookieToken || login.token,
    };
  });

  const setTheme = useRefFunc((v: Theme) => {
    dispatch({
      type: SET_THEME,
      payload: v,
    });
  });

  const setState: IStateAction = useMemo(
    () => ({
      setAirlinesQueryCondition,
      setAirlinesQueryConditionAttr,
      setAirlinesAttrIfNull,
      setAirlinesQueryConditionAirline,
      setQueryCondition,
      setQueryConditionAttr,
      setAirportAttrIfNull,
      setQueryConditionAirport,
      setPartnerCondition,
      setPartnerConditionAttr,
      setLogin,
      setSystemType,
      setUserInfo,
      setHelpVisibility,
      setVerifyInfo,
      setLoginPageState,
      setRefreshCodeState,
      setModuleConfig,
      setDemoModalShowed,
      setDateRangeInGroup,
      getLogin: getLoginRef.current,
      setTheme,
    }),
    [
      setAirlinesQueryCondition,
      setAirlinesQueryConditionAttr,
      setAirlinesAttrIfNull,
      setAirlinesQueryConditionAirline,
      setQueryCondition,
      setQueryConditionAttr,
      setAirportAttrIfNull,
      setQueryConditionAirport,
      setPartnerCondition,
      setPartnerConditionAttr,
      setLogin,
      setSystemType,
      setUserInfo,
      setHelpVisibility,
      setVerifyInfo,
      setLoginPageState,
      setRefreshCodeState,
      setModuleConfig,
      setDemoModalShowed,
      setDateRangeInGroup,
      setTheme,
    ]
  );

  return [state, setState];
};

export default useGlobalState;

interface Props {
  initialValue?: IGlobalState;
}
export const GlobalStateProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  initialValue,
}) => {
  const initial = initialValue || initialState;
  const [state, dispatch] = useReducer(globalStateReducer, initial);

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};
