// Created by xh_zhu on 2021-03-11

import { Card, Col, Row } from "antd";
import Ranking from "./AirportRanking/index";
import { AirlineItem, FlightArea, Area, ISeries } from "Interface";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import useGlobal from "Store";
import AirportSearch from "./AirportSearch";
import PlanTable from "./PlanTable";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { DATE_FORMAT, DEFAULT_AREA } from "Constants";
import CurrentAirportCarryOutRate from "./CurrentAirportCarryOutRate";
import Top10CarryOutRate from "./Top10CarryOutRate";
import TotalCarryOutRate from "./TotalCarryOutRate";
import AirportTable from "./PlanTable/AirportTable";
import { top10Columns } from "./PlanTable/columns";
import "./AirportIndexPanel.css";
import BarLineChartsV2 from "Components/BarLineChartsV2";
import { merge } from "lodash";
import useRefFunc from "Utils/useRefFunc";
import moment from "moment";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 * 机场版航班计划分为上下两块, 内容基本共用, 唯独表格和执行率特别
 */
const defaultAirlinesQueryExt = {
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
};
const panelTypeName = [
  getSharkText("key.pastweek_change.name"),
  getSharkText("key.comparison_caac_forsale.name"),
];
interface IAirportIndexPanelProps {
  panelType: 0 | 1;
  alert?: ReactNode;
  example?: string;
  intl?: boolean;
}

const AirportIndexPanel = (props: IAirportIndexPanelProps): ReactElement => {
  const { panelType, alert, intl } = props;
  const [globalState] = useGlobal();
  const { systemType } = globalState;
  const [airlinesOnSaleAirlineExt] = useState<FlightArea>(
    defaultAirlinesQueryExt
  );
  const [area, setArea] = useState<Area>({
    areaType: 0,
    areaCode: "",
    areaName: "",
  });
  const [airline, setAirline] = useState<AirlineItem[]>([]);
  const [ext, setExt] = useState<any>();
  const [airportExt, setAirportExt] = useState<any>();
  const [totalExt, setTotalExt] = useState<any>();
  useEffect(() => {
    const tmpExt = {
      airline,
      area,
      source: panelType,
    };
    setExt(tmpExt);
    setAirportExt({ ...tmpExt, type: 0 });
    setTotalExt({ ...tmpExt, type: 1 });
  }, [airline, area, panelType]);

  const queryExt: any = useMemo(() => {
    return merge({}, area, ext);
  }, [area, ext]);

  const prefix =
    panelType === 0 ? getSharkText("key.past_seven_days.name") : "";

  const defaultSeries: ISeries[] = useMemo(
    () => [
      {
        type: "line",
        encode: { x: "day", y: "planValue" },
        name: getSharkText("key.for_sale.name"),
        showSymbol: false,
        symbol: "circle",
      },
      {
        type: "line",
        encode: { x: "day", y: "cancelValue" },
        name: `${prefix}${getSharkText("key.cancelled_smallcapital")}`,
        showSymbol: false,
        symbol: "circle",
      },
      {
        type: "line",
        encode: { x: "day", y: "newValue" },
        name: `${prefix}${getSharkText("key.added_smallcapital")}`,
        showSymbol: false,
        symbol: "circle",
      },
      ...(panelType === 0
        ? [
            {
              type: "line",
              encode: { x: "day", y: "executeValue" },
              name: `${getSharkText("key.execution_rate.flightplan")}`,
              showSymbol: false,
              symbol: "circle",
              isPercentVal: true,
              yAxisIndex: 1,
            },
          ]
        : []),
    ],
    [panelType, prefix]
  );

  const rankTab = useMemo(
    () => [
      {
        chartTableCode: "top10_city_cancel_new",
        title: getSharkText("key.temp_cancelled.name"),
        type: 0,
      },
      {
        chartTableCode: "top10_city_cancel_new",
        title: getSharkText("key.temp_added.name"),
        type: 1,
      },
      ...(panelType === 0
        ? [
            {
              chartTableCode: "top10_city_execute_ratio",
              title: getSharkText("key.execution_rate.flightplan"),
              type: 2,
            },
          ]
        : []),
    ],
    [panelType]
  );

  const dataTrans = useRefFunc((d) => {
    return d.map((d1: any) => {
      const sum = d1.planValue + d1.cancelValue + d1.newValue;
      return {
        ...d1,
        day: moment(d1.day).format(DATE_FORMAT),
        executeValue: sum ? ((d1.planValue + d1.newValue) / sum) * 100 : null,
      };
    });
  });

  return (
    <div className="content-white">
      <h1 style={{ color: "#1890FF" }}>{panelTypeName[panelType]}</h1>
      {alert}
      <AirportSearch setArea={setArea} setAirlines={setAirline} />
      <Row gutter={[{ xs: 8, sm: 16, md: 16 }, 20]} style={{ marginTop: 10 }}>
        <Col xl={intl ? 24 : 16} md={24} xs={24}>
          <BarLineChartsV2
            queryUrl={QUERY_URL[systemType].lineCharts}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
            ext={queryExt}
            defaultSeries={defaultSeries}
            dataTrans={dataTrans}
          />
        </Col>
        <Col xl={intl ? 24 : 8} md={24} xs={24}>
          <Ranking
            title={getSharkText("key.routes_performance")}
            queryUrl={QUERY_URL[systemType].top10}
            moduleCode={MODULE_CODE[systemType]}
            tab={rankTab}
            area={area}
            ext={ext}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Card>
          <PlanTable
            queryUrl={QUERY_URL[systemType].table}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].table}
            ext={ext}
            panelType={panelType}
          />
        </Card>
      </Row>
      {panelType !== 1 ? undefined : (
        <>
          <Row
            style={{ marginTop: "16px" }}
            gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
          >
            <Col lg={12} md={24}>
              <Card>
                <CurrentAirportCarryOutRate
                  queryUrl={QUERY_URL[systemType].lineCharts}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
                  area={area}
                  airlinesOnsaleAirlineExt={airlinesOnSaleAirlineExt}
                  ext={airportExt}
                  style={{ height: "432px" }}
                />
              </Card>
            </Col>
            <Col lg={12} md={24}>
              <Card className="total-carry-out">
                <TotalCarryOutRate
                  queryUrl={QUERY_URL[systemType].lineCharts}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
                  area={area}
                  airlinesOnsaleAirlineExt={airlinesOnSaleAirlineExt}
                  ext={totalExt}
                  style={{ height: "432px" }}
                />
              </Card>
            </Col>
          </Row>
          <Row style={{ marginTop: "16px", flexFlow: "column" }}>
            <Col>
              <Card>
                <Top10CarryOutRate
                  queryUrl={QUERY_URL[systemType].lineCharts}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
                  area={area}
                  airlinesOnsaleAirlineExt={airlinesOnSaleAirlineExt}
                  ext={ext}
                  style={{ height: "432px" }}
                />
              </Card>
            </Col>
          </Row>
          <Row
            style={{ marginTop: "16px" }}
            gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
          >
            <Col>
              <Card>
                <AirportTable
                  queryUrl={QUERY_URL[systemType].top10Table}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode={CHART_TABLE_CODE[systemType].top10Table}
                  panelType={panelType}
                  columns={top10Columns}
                  // area={area}
                  // airlinesOnsaleAirlineExt={airlinesOnsaleAirlineExt}
                  ext={ext}
                />
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};
export default AirportIndexPanel;
