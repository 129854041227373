import React, { useEffect, useState } from "react";
import { Col, Input, Popover, Radio, Row, Table } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import {
  getFilterData,
  openDownloadDialog,
  sheet2blob,
  showRawNum,
  useFetch,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import { AirportCompareQueryExt } from "Interface";
import Refetch from "Components/Refetch";
import ValueNum from "Components/ValueNum";
import DownloadBtn from "Components/DownloadBtn";
import _ from "lodash";
import { getServer } from "Service/server";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  COMPARE_SOLO_DESC,
  SOLO_DESC,
  TOGETHER_DESC,
} from "Page/AI/AirportCompare/AirportCompareCommon";

interface CompareTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  airportCompareQueryExt: AirportCompareQueryExt;
}

const CompareTable: React.FC<CompareTableProps> = (
  props: CompareTableProps
) => {
  const { queryUrl, moduleCode, chartTableCode, airportCompareQueryExt } =
    props;
  const [type, setType] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>("");

  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryCondition,
    ext: {
      ...airportCompareQueryExt,
      type,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query: queryCondition,
      ext: { ...airportCompareQueryExt, type },
    });
  };

  useEffect(() => {
    refetch();
  }, [queryCondition, airportCompareQueryExt, type]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const hanndleChange = (e: RadioChangeEvent) => {
    setType(e.target.value);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const tableData = _.isEmpty(data)
    ? []
    : data.map((item: any, idx: number) => {
        item.key = idx;
        return item;
      });
  const filterData = getFilterData(
    ["dportName", "aportName", "compareDportName", "compareAportName"],
    searchText,
    tableData
  );

  const columnsAirport = {
    title: queryCondition.airportName,
    children: [
      {
        title: getSharkText("key.departure.name"),
        dataIndex: "dportName",
        key: "dportName",
      },
      {
        title: getSharkText("key.arrival.name"),
        dataIndex: "aportName",
        key: "aportName",
      },
      {
        title: getSharkText("key.passenger_traffic"),
        dataIndex: "passengerTraffic",
        key: "passengerTraffic",
        sorter: (a: any, b: any) =>
          (a.passengerTraffic ? a.passengerTraffic : -1) -
          (b.passengerTraffic ? b.passengerTraffic : -1),
        defaultSortOrder: "descend",
        render: (val: number) => <ValueNum type="num" value={val} />,
      },
      {
        title: getSharkText("key.available_seats"),
        dataIndex: "transportCapacity",
        key: "transportCapacity",
        sorter: (a: any, b: any) =>
          (a.transportCapacity ? a.transportCapacity : -1) -
          (b.transportCapacity ? b.transportCapacity : -1),
        render: (val: number) => <ValueNum type="num" value={val} />,
      },
      {
        title: getSharkText("key.loadfactor"),
        dataIndex: "lf",
        key: "lf",
        sorter: (a: any, b: any) => (a.lf ? a.lf : -1) - (b.lf ? b.lf : -1),
        render: (val: number) => <ValueNum value={val} type="percentage" />,
      },
      {
        title: getSharkText("key.number_of_flights"),
        dataIndex: "flightSorties",
        key: "flightSorties",
        sorter: (a: any, b: any) => a.flightSorties - b.flightSorties,
      },
      {
        title: getSharkText("key.oneway_average_price"),
        dataIndex: "price",
        key: "price",
        sorter: (a: any, b: any) =>
          (a.price ? a.price : -1) - (b.price ? b.price : -1),
        render: (val: number) => <ValueNum value={val} type="price" />,
      },
      {
        title: getSharkText("key.search_index.name"),
        dataIndex: "searchIndex",
        key: "searchIndex",
        sorter: (a: any, b: any) =>
          (a.searchIndex ? a.searchIndex : -1) -
          (b.searchIndex ? b.searchIndex : -1),
        render: (val: number) => <ValueNum value={val} type="num" />,
      },
    ],
  };

  const columnsCompareAirport = {
    title: airportCompareQueryExt.compareAirport.id
      ? airportCompareQueryExt.compareAirport.maskName
      : airportCompareQueryExt.compareAirport.airportName,
    children: [
      {
        title: getSharkText("key.departure.name"),
        dataIndex: "compareDportName",
        key: "compareDportName",
      },
      {
        title: getSharkText("key.arrival.name"),
        dataIndex: "compareAportName",
        key: "compareAportName",
      },
      {
        title: getSharkText("key.passenger_traffic"),
        dataIndex: "comparePassengerTraffic",
        key: "comparePassengerTraffic",
        sorter: (a: any, b: any) =>
          (a.comparePassengerTraffic ? a.comparePassengerTraffic : -1) -
          (b.comparePassengerTraffic ? b.comparePassengerTraffic : -1),
        render: (val: number) => <ValueNum type="num" value={val} />,
      },
      {
        title: getSharkText("key.available_seats"),
        dataIndex: "compareTransportCapacity",
        key: "compareTransportCapacity",
        sorter: (a: any, b: any) =>
          (a.compareTransportCapacity ? a.compareTransportCapacity : -1) -
          (b.compareTransportCapacity ? b.compareTransportCapacity : -1),
        render: (val: number) => <ValueNum type="num" value={val} />,
      },
      {
        title: getSharkText("key.loadfactor"),
        dataIndex: "compareLf",
        key: "compareLf",
        sorter: (a: any, b: any) =>
          (a.compareLf ? a.compareLf : -1) - (b.compareLf ? b.compareLf : -1),
        render: (val: number) => <ValueNum value={val} type="percentage" />,
      },
      {
        title: getSharkText("key.number_of_flights"),
        dataIndex: "compareFlightSorties",
        key: "compareFlightSorties",
        sorter: (a: any, b: any) =>
          a.compareFlightSorties - b.compareFlightSorties,
      },
      {
        title: getSharkText("key.oneway_average_price"),
        dataIndex: "comparePrice",
        key: "comparePrice",
        sorter: (a: any, b: any) =>
          (a.comparePrice ? a.comparePrice : -1) -
          (b.comparePrice ? b.comparePrice : -1),
        render: (val: number) => <ValueNum value={val} type="price" />,
      },
      {
        title: getSharkText("key.search_index.name"),
        dataIndex: "compareSearchIndex",
        key: "compareSearchIndex",
        sorter: (a: any, b: any) =>
          (a.compareSearchIndex ? a.compareSearchIndex : -1) -
          (b.compareSearchIndex ? b.compareSearchIndex : -1),
        render: (val: number) => <ValueNum value={val} type="num" />,
      },
    ],
  };

  const getColumnsFromType = (num: number) => {
    switch (num) {
      case 1:
        return [columnsAirport];
      case 2:
        return [columnsCompareAirport];
      default:
        return [columnsAirport, columnsCompareAirport];
    }
  };

  const handleDownload = () => {
    let sheet;
    const compareAirportName = airportCompareQueryExt.compareAirport.airportName
      ? airportCompareQueryExt.compareAirport.airportName
      : airportCompareQueryExt.compareAirport.maskName;
    if (type === 0) {
      const sheetData = filterData.reduce((arr: any[], current: any) => {
        arr.push([
          current.dportName,
          current.aportName,
          current.passengerTraffic ? showRawNum(current.passengerTraffic) : "",
          current.transportCapacity
            ? showRawNum(current.transportCapacity)
            : "",
          current.lf ? showRawNum(current.lf, "percentage") : "",
          current.flightSorties ? showRawNum(current.flightSorties, "num") : "",
          current.price ? showRawNum(current.price) : "",
          current.searchIndex ? showRawNum(current.searchIndex) : "",
          current.compareDportName,
          current.compareAportName,
          current.comparePassengerTraffic
            ? showRawNum(current.comparePassengerTraffic)
            : "",
          current.compareTransportCapacity
            ? showRawNum(current.compareTransportCapacity)
            : "",
          current.compareLf ? showRawNum(current.compareLf, "percentage") : "",
          current.compareFlightSorties
            ? showRawNum(current.compareFlightSorties, "num")
            : "",
          current.comparePrice ? showRawNum(current.comparePrice) : "",
          current.compareSearchIndex
            ? showRawNum(current.compareSearchIndex)
            : "",
        ]);
        return arr;
      }, []);
      const subHeaders = [
        getSharkText("key.departure.name"),
        getSharkText("key.arrival.name"),
        getSharkText("key.passenger_traffic"),
        getSharkText("key.available_seats"),
        getSharkText("key.loadfactor"),
        getSharkText("key.number_of_flights"),
        getSharkText("key.oneway_average_price"),
        getSharkText("key.search_index.name"),
        getSharkText("key.departure.name"),
        getSharkText("key.arrival.name"),
        getSharkText("key.passenger_traffic"),
        getSharkText("key.available_seats"),
        getSharkText("key.loadfactor"),
        getSharkText("key.number_of_flights"),
        getSharkText("key.oneway_average_price"),
        getSharkText("key.search_index.name"),
      ];
      const headers = [
        queryCondition.airportName,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        compareAirportName,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ];
      sheetData.unshift(headers, subHeaders);
      sheet = XLSX.utils.aoa_to_sheet(sheetData);
      sheet["!merges"] = [
        // s表示开始，e表示结束，r表示行，c表示列；设置单元格合并
        { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        { s: { r: 0, c: 8 }, e: { r: 0, c: 15 } },
      ];
    } else if (type === 1) {
      const sheetData = filterData.reduce((arr: any[], current: any) => {
        arr.push([
          current.dportName,
          current.aportName,
          current.passengerTraffic ? showRawNum(current.passengerTraffic) : "",
          current.transportCapacity
            ? showRawNum(current.transportCapacity)
            : "",
          current.lf ? showRawNum(current.lf, "percentage") : "",
          current.flightSorties ? showRawNum(current.flightSorties, "num") : "",
          current.price ? showRawNum(current.price) : "",
          current.searchIndex ? showRawNum(current.searchIndex) : "",
        ]);
        return arr;
      }, []);
      const subHeaders = [
        getSharkText("key.departure.name"),
        getSharkText("key.arrival.name"),
        getSharkText("key.passenger_traffic"),
        getSharkText("key.available_seats"),
        getSharkText("key.loadfactor"),
        getSharkText("key.number_of_flights"),
        getSharkText("key.oneway_average_price"),
        getSharkText("key.search_index.name"),
      ];
      const headers = [
        queryCondition.airportName,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ];
      sheetData.unshift(headers, subHeaders);
      sheet = XLSX.utils.aoa_to_sheet(sheetData);
      sheet["!merges"] = [
        // s表示开始，e表示结束，r表示行，c表示列；设置单元格合并
        { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
      ];
    } else {
      const sheetData = filterData.reduce((arr: any[], current: any) => {
        arr.push([
          current.compareDportName,
          current.compareAportName,
          current.comparePassengerTraffic
            ? showRawNum(current.comparePassengerTraffic)
            : "",
          current.compareTransportCapacity
            ? showRawNum(current.compareTransportCapacity)
            : "",
          current.compareLf ? showRawNum(current.compareLf, "percentage") : "",
          current.compareFlightSorties
            ? showRawNum(current.compareFlightSorties, "num")
            : "",
          current.comparePrice ? showRawNum(current.comparePrice) : "",
          current.compareSearchIndex
            ? showRawNum(current.compareSearchIndex)
            : "",
        ]);
        return arr;
      }, []);
      const subHeaders = [
        getSharkText("key.departure.name"),
        getSharkText("key.arrival.name"),
        getSharkText("key.passenger_traffic"),
        getSharkText("key.available_seats"),
        getSharkText("key.loadfactor"),
        getSharkText("key.number_of_flights"),
        getSharkText("key.oneway_average_price"),
        getSharkText("key.search_index.name"),
      ];
      const headers = [compareAirportName, null, null, null, null, null, null];
      sheetData.unshift(headers, subHeaders);
      sheet = XLSX.utils.aoa_to_sheet(sheetData);
      sheet["!merges"] = [
        // s表示开始，e表示结束，r表示行，c表示列；设置单元格合并
        { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
      ];
    }
    const blob = sheet2blob(sheet);
    openDownloadDialog(blob, "机场对标.xlsx");
  };

  return (
    <>
      <Row align="middle" gutter={32}>
        <Col id="CompareAiprotTableType">
          <Radio.Group value={type} onChange={hanndleChange}>
            <Radio.Button value={0}>
              {getSharkText("config_page_together")}
              <Popover content={TOGETHER_DESC}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Popover>
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.solo_routes.name")}
              <Popover content={SOLO_DESC}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Popover>
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("config_page_compare_solo_flight")}
              <Popover content={COMPARE_SOLO_DESC}>
                <InfoCircleOutlined style={{ marginLeft: 5 }} />
              </Popover>
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col style={{ width: 350 }}>
          <Input.Search
            value={searchText}
            placeholder={getSharkText(
              "config_page_input_search_departure_arrival_airport"
            )}
            onChange={handleSearch}
          />
        </Col>
        <Col style={{ flex: 1, textAlign: "right" }}>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }}>
        <Table
          showSorterTooltip={false}
          tableLayout="fixed"
          dataSource={filterData}
          // @ts-ignore
          columns={getColumnsFromType(type)}
          bordered
          loading={isLoading}
        />
      </Row>
    </>
  );
};

export default CompareTable;
