import { useState, useCallback, Dispatch, SetStateAction } from "react";
import { getQueryStringValue, setQueryStringValue } from "./queryString";

export const useQueryString = (
  key: string,
  initialValue: string
): [string, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState(
    (getQueryStringValue(key) as string) || initialValue
  );
  const onSetValue = useCallback(
    (newValue: any) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
};

export const useQueryStringArr = (
  key: string,
  initialValue: string[]
): [string[], Dispatch<SetStateAction<string[]>>] => {
  const str = getQueryStringValue(key);
  const rst: string[] = str
    ? Array.isArray(str)
      ? (str.filter((s) => s !== null) as string[])
      : [str]
    : [];
  const [value, setValue] = useState<string[]>(
    rst.filter((r) => !!r) || initialValue
  );
  const onSetValue = useCallback(
    (newValue: any) => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
};
