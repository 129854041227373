import { DATE_FORMAT } from "Constants";
import SelectMultiRoute from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import useGlobalState from "Store";
import useRefFunc from "Utils/useRefFunc";
import { Col, Radio, Row, Select } from "antd";
import moment from "moment";
import React, { ReactElement, useMemo, useState } from "react";

const options = [
  {
    label: "默认",
    value: "*",
  },
  {
    label: "禁用",
    value: "-",
  },
  {
    label: "自定义",
    value: "custom",
  },
];

const getAirlineRouteType = (routes: string[]): string => {
  if (!routes?.length) {
    return "*";
  }
  if (routes.length === 1) {
    if (routes[0] === "*" || routes[0] === "") {
      return "*";
    } else if (routes[0] === "-") {
      return "-";
    }
  }
  return "custom";
};

export interface RoutesSelectorProps {
  airline: string;
  routes: string[];
  routeOptions?: string[];
  setRoutes: (routes: string[]) => void;
}

/** 航线选择 */
const RoutesSelector = (props: RoutesSelectorProps): ReactElement => {
  const { airline, routes, setRoutes, routeOptions } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [type, setType] = useState<string>(() => getAirlineRouteType(routes));
  const changeType = useRefFunc((v: string) => {
    setType(v);
    if (v === "*") {
      setRoutes([]);
    } else if (v === "-") {
      setRoutes(["-"]);
    }
  });
  const customQuery = useMemo(() => {
    return {
      ...airlinesQueryCondition,
      airlines: airline,
      startDate: moment().add(-1, "M").format(DATE_FORMAT),
      endDate: moment().add(1, "d").format(DATE_FORMAT),
    };
  }, [airline, airlinesQueryCondition]);
  const selector = useMemo(() => {
    if (routeOptions) {
      const os = routeOptions.map((o) => ({ label: o, value: o }));
      return (
        <Select
          options={os}
          value={routes}
          onChange={setRoutes}
          mode="multiple"
          style={{ minWidth: 150 }}
        ></Select>
      );
    } else {
      return (
        <SelectMultiRoute
          title=""
          flights=""
          routes={routes}
          mode="multiple"
          customQuery={customQuery}
          setRoutes={setRoutes}
          isDemo={false}
          style={{ minWidth: 150 }}
        ></SelectMultiRoute>
      );
    }
  }, [customQuery, routeOptions, routes, setRoutes]);
  return (
    <Row justify="space-between" style={{ marginBottom: 10 }}>
      <Col span={2}>{airline}</Col>
      <Col span={8}>
        <Radio.Group
          options={options}
          optionType="button"
          value={type}
          onChange={(e) => changeType(e.target.value)}
        ></Radio.Group>
      </Col>
      <Col span={14}>{type === "custom" ? selector : undefined}</Col>
    </Row>
  );
};
export default RoutesSelector;
