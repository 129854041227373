import React from "react";
import { Switch, message } from "antd";
import { User } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface IProps {
  user: User;
  refetchUser: () => void;
  unitId: number;
}

const RoleTypeChange: React.FC<IProps> = (props: IProps) => {
  const { user, refetchUser, unitId } = props;
  const [{ isLoading }, doFetch] = useFetch({
    url: "setUnitAdmin",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      userId: user.id,
      groupId: unitId,
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_modify_success"));
      refetchUser();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_fail"));
    },
  });

  const handleChange = (isChecked: boolean) => {
    doFetch({
      ext: {
        userId: user.id,
        groupId: unitId,
        status: isChecked ? 1 : 0,
      },
    });
  };

  return (
    <Switch
      checked={!!user.memberType}
      onChange={handleChange}
      disabled={isLoading}
    />
  );
};

export default RoleTypeChange;
