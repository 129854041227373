import React, { useEffect } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Table, Popover, Typography } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { FlightArea, AirlinesQueryCondition, QueryCondition } from "Interface";
import useGlobal from "Store";
import Refetch from "Components/Refetch";
import ValueNum from "Components/ValueNum";
import { getServer } from "Service/server";

interface TableProps {
  airlinesFlightArea: FlightArea;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  query: QueryCondition | AirlinesQueryCondition;
}

const { Text } = Typography;

const AirlinesFlightNOTable: React.FC<TableProps> = (props: TableProps) => {
  const { airlinesFlightArea, queryUrl, moduleCode, chartTableCode, query } =
    props;
  const [globalState] = useGlobal();
  const { systemType } = globalState;
  const ext = {
    filter: {
      filterType: 1,
      ...airlinesFlightArea,
    },
    type: 1,
  };
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    ext,
    query,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: { ...ext },
    });
  };

  useEffect(() => {
    if (airlinesFlightArea.arriveArea.areaType === 5) {
      refetch();
    }
  }, [airlinesFlightArea, query]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const tableData = data.map((item: any, idx: number) => {
    item.key = idx;
    return item;
  });

  const columns = [
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dcityName",
      key: "dcityName",
      fixed: "left",
      width: 100,
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "acityName",
      key: "acityName",
      fixed: "left",
      width: 100,
    },
    {
      title: getSharkText("key.flightno.name"),
      fixed: "left",
      dataIndex: "flightNO",
      key: "flightNO",
      width: 100,
    },
    {
      title: () => (
        <>
          <span>{getSharkText("key.number_of_flights")}</span>&nbsp;
          <Popover content={getSharkText("key.flightcarried_statistic")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "flightSorties",
      key: "flightSorties	",
      sorter: (a: any, b: any) => a.flightSorties - b.flightSorties,
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: () => (
        <>
          <span>{getSharkText("card.passenger_traffic.name")}</span>
          &nbsp;
          <Popover
            content={getSharkText("key.passengers_inoutbound_estimated.hint")}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "passengerTraffic",
      key: "passengerTraffic",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.passengerTraffic - b.passengerTraffic,
      render: (val: number) => (
        <ValueNum roundNum={-1} type="num" value={val} />
      ),
    },
    {
      title: () => (
        <>
          <span>{getSharkText("key.available_seats_estimated")}</span>
          &nbsp;
          <Popover content={getSharkText("key.estimated_based_on_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "transportCapacity",
      key: "transportCapacity",
      sorter: (a: any, b: any) => a.transportCapacity - b.transportCapacity,
      render: (val: number) => (
        <ValueNum roundNum={-1} type="num" value={val} />
      ),
    },
    {
      title: () => (
        <>
          <span>{getSharkText("key.loadfactor")}</span>&nbsp;
          <Popover content={getSharkText("key.estimated_lf_by_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "lf",
      key: "lf",
      sorter: (a: any, b: any) => a.lf - b.lf,
      render: (val: number) => <ValueNum type="percentage" value={val} />,
    },
    {
      title: getSharkText("key.flight_duration.hint"),
      dataIndex: "flightTime",
      key: "flightTime",
      sorter: (a: any, b: any) => a.flightTime - b.flightTime,
      render: (val: number) => (
        <ValueNum value={val} type="num" isShowZero={false} />
      ),
    },
    {
      title: () => (
        <>
          <span>{getSharkText("key.oneway_average_price")}</span>&nbsp;
          <Popover content={getSharkText("key.ow_avg_price_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: () => (
        <>
          <span>{getSharkText("key.rt_avg_price.name")}</span>&nbsp;
          <Popover content={getSharkText("key.rt_avg_price_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "rtprice",
      key: "rtprice",
      sorter: (a: any, b: any) => a.rtprice - b.rtprice,
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: () => (
        <>
          <span>{getSharkText("key.passenger_traffic_per_flight")}</span>
          &nbsp;
          <Popover content={getSharkText("key.actual_traffic_divide_flights")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "passengers",
      key: "passengers",
      sorter: (a: any, b: any) => a.passengers - b.passengers,
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: getSharkText("key.proportion_wide_body"),
      dataIndex: "wideBody",
      key: "wideBody",
      sorter: (a: any, b: any) => a.wideBody - b.wideBody,
      render: (val: number) => <ValueNum type="percentage" value={val} />,
    },
  ];

  return (
    <Table
      showSorterTooltip={false}
      tableLayout="fixed"
      dataSource={tableData}
      loading={isLoading}
      // @ts-ignore
      columns={columns}
      scroll={{ x: 1700 }}
    />
  );
};

export default AirlinesFlightNOTable;
