import React, {
  forwardRef,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Area, FlightArea, IDownloadHeader } from "Interface";
import {
  getComparePercentageVal,
  isAirportMode,
  isSame,
  openDownloadDialog,
  sheet2blob,
  showNum,
  showRawNum,
  tableNumSorter,
  useFetch,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Col,
  Empty,
  Input,
  Popover,
  Radio,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import DownloadBtn from "Components/DownloadBtn";
import _ from "lodash";
import { getServer } from "Service/server";
import { COMPARE_TYPE_VALUE_NAME } from "Constants";
import { SortOrder } from "antd/lib/table/interface";
import {
  getDownloadColumnHeader,
  getDownloadColumnValue,
} from "Utils/downloadXLSX";

interface SearchTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  area: Area;
  airlinesSearchTrendsExt: FlightArea;
  ext?: any;
}

export interface TableHandler {
  query: () => void;
}

const { Text } = Typography;

const SearchTable = forwardRef(
  (props: SearchTableProps, ref: Ref<TableHandler>) => {
    const {
      queryUrl,
      moduleCode,
      chartTableCode,
      area,
      airlinesSearchTrendsExt,
      ext: otherExt,
    } = props;
    const [searchText, setSearchText] = useState<string>("");
    const [globalState] = useGlobal();
    const { queryCondition, systemType, airlinesQueryCondition } = globalState;
    const isAirport = isAirportMode(systemType);
    const [tableType, setTableType] = useState<number>(0);
    const query = isAirport ? queryCondition : airlinesQueryCondition;
    const ext = useMemo(
      () => (isAirport ? { area } : { ...airlinesSearchTrendsExt }),
      [airlinesSearchTrendsExt, area, isAirport]
    );
    const lastQueryParam = useRef<any>(null);
    const [{ data, isLoading, error }, doFetch] = useFetch({
      server: getServer(systemType),
      url: queryUrl,
      defaultValue: [],
      head: {
        moduleCode,
        chartTableCode,
      },
      query,
      ext,
      lazey: true,
    });

    const refetch = useCallback(() => {
      const queryParam = {
        query,
        ext: { ...ext, ...otherExt },
      };
      if (!data || !isSame(queryParam, lastQueryParam.current)) {
        lastQueryParam.current = queryParam;
        doFetch(queryParam);
      }
    }, [data, doFetch, ext, otherExt, query]);

    useImperativeHandle(ref, () => ({
      query: refetch,
    }));

    useEffect(() => {
      // refetch();
      // clearData();
    }, [ext, otherExt, query]);

    if (isLoading) {
      return <Spin />;
    }
    if (error) {
      return <Refetch refetch={refetch} />;
    }
    if (_.isEmpty(data)) {
      return <Empty />;
    }

    const getFilterData = (searchkeys: string[], dataSource: any[]) => {
      const cloneData = _.cloneDeep(dataSource);
      const filter = cloneData.filter((item: any) => {
        let isPass = false;
        for (const key of searchkeys) {
          if (tableType != null && item.type !== tableType) {
            return false;
          }
          if (item[key] && item[key].includes(searchText.toLocaleUpperCase())) {
            isPass = true;
            break;
          }
        }
        return isPass;
      });
      return filter;
    };

    // const groupData = groupCompareData(data, "searchIndex");
    const filterData = getFilterData(
      ["dcityName", "acityName", "dcityCode", "acityCode"],
      data
    );

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    };

    const columns: IDownloadHeader[] = [
      {
        title: getSharkText("key.departure.name"),
        dataIndex: "dcityName",
        key: "dcityName",
        sorter: (a: any, b: any) => a.dcityCode.localeCompare(b.dcityCode),
        render: (value: string, record: any) =>
          `${record.dcityName}(${record.dcityCode})`,
      },
      {
        title: getSharkText("key.arrival.name"),
        dataIndex: "acityName",
        key: "acityName",
        sorter: (a: any, b: any) => a.acityCode.localeCompare(b.acityCode),
        render: (value: string, record: any) =>
          `${record.acityName}(${record.acityCode})`,
      },
      {
        title: (
          <>
            <span>{getSharkText("key.search_index.name")}</span>&nbsp;
            <Popover content={getSharkText("key.searchindex_explain.hint")}>
              <Text type="secondary">
                <InfoCircleOutlined />
              </Text>
            </Popover>
          </>
        ),
        downloadTitle: getSharkText("key.search_index.name"),
        dataIndex: "searchIndex",
        key: "searchIndex",
        sorter: (a: any, b: any) => a.searchIndex - b.searchIndex,
        render: (val: number) => <ValueNum type="num" value={val} />,
        downloadFormatter: (val: number) => showRawNum(val, "num"),
      },
      {
        title: COMPARE_TYPE_VALUE_NAME[query.compareType],
        key: "compareSearchIndex",
        dataIndex: "compareSearchIndex",
        sorter: (a: any, b: any, c?: SortOrder) =>
          tableNumSorter(a.compareSearchIndex, b.compareSearchIndex, c),
        render: (val: number) => <ValueNum type="num" value={val} />,
        downloadFormatter: (val: number) => showRawNum(val, "num"),
      },
      {
        title: getSharkText("key.yoy_increase.name"),
        key: "result",
        dataIndex: "result",
        sorter: (a: any, b: any, c?: SortOrder) =>
          tableNumSorter(
            getComparePercentageVal(a.searchIndex, a.compareSearchIndex),
            getComparePercentageVal(b.searchIndex, b.compareSearchIndex),
            c
          ),
        render: (val: number, r: any) =>
          showNum(
            getComparePercentageVal(r.searchIndex, r.compareSearchIndex),
            "percentage"
          ),
        downloadFormatter: (val: number, r) =>
          showRawNum(
            getComparePercentageVal(r.searchIndex, r.compareSearchIndex),
            "percentage"
          ),
      },
    ];

    const handleDownload = () => {
      const sheetData: any = [];
      filterData.forEach((item: any) => {
        const obj: any = {};
        columns.forEach((c, i) => {
          const title = getDownloadColumnHeader(c);
          obj[title] = getDownloadColumnValue(c, item, i);
        });
        sheetData.push(obj);
      });
      const sheet = XLSX.utils.json_to_sheet(sheetData);
      const blob = sheet2blob(sheet);
      openDownloadDialog(blob, `${getSharkText("key.search_index.name")}.xlsx`);
    };

    const handleTableTypeChange = (e: any) => {
      setTableType(e.target.value);
    };

    return (
      <Row style={{ minHeight: 700 }}>
        <Col span={24}>
          <Row justify="space-between">
            <Col style={{ width: 300 }}>
              <Input.Search
                value={searchText}
                placeholder={getSharkText("key.input_to_search_dep_arr")}
                onChange={handleSearch}
              />
            </Col>
            {isAirport ? undefined : (
              <Col>
                <Radio.Group value={tableType} onChange={handleTableTypeChange}>
                  <Radio.Button value={null}>
                    {getSharkText("config_page_all")}
                  </Radio.Button>
                  <Radio.Button value={0}>
                    {getSharkText("key.airline_operates.name")}
                  </Radio.Button>
                  <Radio.Button value={1}>
                    {getSharkText("key.airline_not_operates.name")}
                  </Radio.Button>
                  <Radio.Button value={2}>
                    {getSharkText("key.market_not_navigable.name")}
                  </Radio.Button>
                </Radio.Group>
              </Col>
            )}
            <Col style={{ textAlign: "right" }}>
              <DownloadBtn
                handleDownload={handleDownload}
                moduleCode={moduleCode}
                chartTableCode={chartTableCode}
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: 16 }}>
          <Table
            showSorterTooltip={false}
            tableLayout="fixed"
            dataSource={filterData}
            columns={columns}
          />
        </Col>
      </Row>
    );
  }
);

export default SearchTable;
