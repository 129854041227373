import React from "react";
import { Spin } from "antd";
import {
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  toThousands,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode } from "Interface";
import EchartsReactBase from "Components/EchartsReactBase";

interface Data {
  day: string;
  value: number;
  compareValue: number;
}

interface ChartsProps {
  aggCode: AggCode;
  isPercentVal: boolean;
  loading: boolean;
  data: Data[];
  startDate: string;
  endDate: string;
  height: number;
  airport: string;
  compareAirport: string | undefined;
}

const Charts: React.FC<ChartsProps> = (props: ChartsProps) => {
  const {
    aggCode,
    isPercentVal,
    loading,
    data,
    startDate,
    endDate,
    height,
    airport,
    compareAirport,
  } = props;

  const isZero = (val: number) => val === 0;
  const option = {
    dataset: {
      source: data,
    },
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          false
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const val =
            item.seriesName === airport
              ? `${item.seriesName}: ${
                  !isZero(item.data.value)
                    ? isPercentVal
                      ? item.data.value + "%"
                      : toThousands(item.data.value)
                    : "-"
                }`
              : `${item.seriesName}: ${
                  !isZero(item.data.compareValue)
                    ? isPercentVal
                      ? item.data.compareValue + "%"
                      : toThousands(item.data.compareValue)
                    : "-"
                }`;

          dom = dom + "<br />" + dot + val;
        });
        dom = getSharkText("key.takeoffdate_colon.name") + day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    yAxis: {
      position: "left",
      type: "value",
      splitLine: false,
      splitNumber: 5,
      axisLine: {
        show: false,
      },
      axisLabel: {
        formatter: (value: number) => `${isPercentVal ? value + "%" : value}`,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: "bar",
        encode: { x: "day", y: "value" },
        barMaxWidth: 20,
        barGap: 0,
        name: airport,
      },
      {
        type: "bar",
        encode: { x: "day", y: "compareValue" },
        barMaxWidth: 20,
        barGap: 0,
        name: compareAirport,
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default Charts;
