import { message } from "antd";
import { SystemType } from "Interface";
import React, { ReactElement, useCallback, useState } from "react";
import useGlobalState from "Store";
import { openDownloadDialog, workbook2blob, XLSX } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { IQuery } from "../../common";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../../fetchCode";
import { downloadFetch } from "./DownloadFetch";
import { getConfig } from "./ExcelConfig";
import DownloadBtn from "../../../../../Components/DownloadBtn";

export interface IDownloadProps {
  filter: IQuery;
}

/**
 * 用户画像下载
 */
const Download = (props: IDownloadProps): ReactElement => {
  const { filter } = props;
  const [globalState, actions] = useGlobalState();
  const { airlinesQueryCondition, queryCondition, systemType } = globalState;
  const { uid, token } = actions.getLogin();
  const [downloading, setDownloading] = useState<boolean>(false);
  const companyName =
    systemType === SystemType.airport
      ? queryCondition.airportName
      : airlinesQueryCondition.airlinesName;

  const handleClick = useCallback(async () => {
    if (!filter.current.query) return;
    const excelConfig = getConfig(companyName, systemType);
    const fetchSheets = excelConfig.map((c) => {
      const queryBody = {
        head: {
          uid,
          token,
          requestTime: new Date(),
          appId: "",
          clientType: "2",
          clientVersion: "",
          chartTableCode: CHART_TABLE_CODE[systemType].download,
          moduleCode: MODULE_CODE[systemType],
        },
        query: { ...filter.current.query, arrive: 1, departure: 1 },
        filter: {},
        tag: [],
        tagCode: c.tagCode,
      };
      return () =>
        downloadFetch(QUERY_URL[systemType].download, queryBody, systemType, c);
    });

    message.loading({
      key: "downloading",
      content: `正在获取下载数据...`,
      duration: 0,
    });
    setDownloading(true);
    const rst: any[] = [];
    const getRst = (
      req: () => Promise<any>,
      idx: number,
      list: Array<() => Promise<any>>
    ) => {
      message.loading({
        key: "downloading",
        content: `正在获取第${idx + 1}批下载数据, 共${fetchSheets.length}批...`,
        duration: 0,
      });
      req()
        .then((resolve) => {
          rst.push(resolve);
        })
        .finally(() => {
          if (idx !== list.length - 1) {
            const nextIdx = idx + 1;
            getRst(list[nextIdx], nextIdx, list);
          } else {
            console.log("finish :", rst);
            const wb = XLSX.utils.book_new();
            rst.forEach((r) => {
              XLSX.utils.book_append_sheet(wb, r.sheet, r.config.tagName);
            });
            const workbookBlob = workbook2blob(wb);
            openDownloadDialog(
              workbookBlob,
              getSharkText("menu_user_profile_airlines") + ".xlsx"
            );
            message.destroy("downloading");
            setDownloading(false);
          }
        });
    };
    getRst(fetchSheets[0], 0, fetchSheets);
  }, [companyName, filter, systemType, token, uid]);

  return (
    <DownloadBtn
      handleDownload={handleClick}
      showIcon={false}
      loading={downloading}
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].download}
    />
  );
};
export default Download;
