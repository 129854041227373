import React from "react";
import { ReactComponent as LargeNumber1 } from "assets/svg/1.svg";
import { ReactComponent as LargeNumber2 } from "assets/svg/2.svg";
import { ReactComponent as Number1 } from "assets/svg/01.svg";
import { ReactComponent as Number2 } from "assets/svg/02.svg";
import { ReactComponent as Number3 } from "assets/svg/03.svg";
import { ReactComponent as Priceing } from "assets/svg/pricing.svg";
import Side from "Components/Official/Side";
import "./index.scss";

const Income: React.FC = () => {
  return (
    <>
      <div className="top-banner income-banner">
        <div className="base-con">
          <div className="banner-con">
            <h1>收益管理</h1>
            <h4>利用算法模型，更科学、更高效的实现航班收益最大化。</h4>
          </div>
        </div>
      </div>
      <div className="base-mode income-mod">
        <div className="base-con">
          <h2 className="base-title">什么是收益管理？</h2>
          <h5 className="base-title-hint">
            当前国内航线的价格调整和收益管理重度依赖人工，我们结合对业务的深刻理解，
            <br />
            基于大量实时数据，以模型判断代替人工监测，根据航班售卖情况和市场整体情况，自动生成策略建议。
          </h5>
        </div>
        <div className="income-list income-odd-list">
          <div className="base-con">
            <LargeNumber1 className="list-large-number" />
            <div className="income-tilte">智能化航班监控</div>
            <h5>
              将人工调价经验模型化，更轻松、更高效。
              <br />
              基于大量数据生成决策，更智能，更准确。
            </h5>
          </div>
        </div>
        <div className="income-list income-even-list">
          <div className="base-con">
            <LargeNumber2 className="list-large-number" />
            <div className="income-tilte">精细化收益管理</div>
            <h5>
              时刻监控航班售卖、实时做出策略调整。
              <br />
              更精细、更及时的策略建议可以帮助提高收益
            </h5>
          </div>
        </div>
      </div>
      <div className="base-mode income-mod">
        <div className="base-con">
          <h2 className="base-title">为什么选择我们？</h2>
          <h5 className="base-title-hint">
            具备丰富的数据资产、强大的技术支撑、深刻的业务理解，将业务场景抽象为数据模型
          </h5>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info income-list-bg1">
              <Number1 className="list-number" />
              <div className="list-title">基于海量数据</div>
              <p>
                具备成熟的实时数据处理技术，实时为航空公司给出价格建议、获得用户反馈，面对瞬息万变的市场，确保策略的及时性
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-even-list">
          <div className="base-con">
            <div className="list-info income-list-bg2">
              <Number2 className="list-number" />
              <div className="list-title">实时策略输出</div>
              <p>
                拥有全面的市场数据及OTA独家数据，深入挖掘提炼用户画像、航线特征，并且具备多年的数据积累沉淀
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info income-list-bg3">
              <Number3 className="list-number" />
              <div className="list-title">贴合中国市场</div>
              <p>
                相比国外的收益管理工具，更了解国内市场，策略建议更结合国内市场的网络规划、竞争情况，更符合国内旅客的购票习惯
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="base-mode income-mod">
        <div className="base-con">
          <h2 className="base-title">动态定价模型</h2>
          <h5 className="base-title-hint">
            基于先进的算法模型，生成可靠的策略建议
          </h5>
          <div className="income-pricing">
            <Priceing />
          </div>
        </div>
      </div>
      <div className="how-to-cooperation">
        <div className="base-con">
          <h2>我们将如何开展合作？</h2>
          <ul>
            <li>
              <i className="coop-num1" />
              <h4>沟通咨询</h4>
              <p>确认具体需求、合作方向及合作方式</p>
            </li>
            <li>
              <i className="coop-num2" />
              <h4>前期分析</h4>
              <p>分析整体航线网络，挖掘重点潜力航线</p>
            </li>
            <li>
              <i className="coop-num3" />
              <h4>航线试点</h4>
              <p>试点部分航线，建立专属模型提供策略建议</p>
            </li>
            <li>
              <i className="coop-num4" />
              <h4>反馈优化</h4>
              <p>实时监控评价指标，优化预测模型</p>
            </li>
            <li>
              <i className="coop-num5" />
              <h4>全面推广</h4>
              <p>拓展航线范围，全面推广提升收益</p>
            </li>
          </ul>
        </div>
      </div>
      <Side />
    </>
  );
};

export default Income;
