import React, { useEffect, useState } from "react";
import useGlobal from "Store";
import { Area } from "Interface";
import _ from "lodash";
import { useFetch } from "Utils/useFetch";

const City = () => {
  const [globalState] = useGlobal();
  const { queryCondition } = globalState;
  const { airport } = queryCondition;
  const [city, setCity] = useState("");
  const language = sessionStorage.getItem("lang") || "zh-CN";
  const [{ data, isLoading, error }] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
  });

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const getAirportCity = (area: Area[], code: string): string => {
        let cityName = "";
        let cityNameEn = "";
        for (const element of area) {
          if (element.areaType === 4 && element.children) {
            if (
              element.children.findIndex((item) => item.areaCode === code) > -1
            ) {
              cityName = element.areaName;
              cityNameEn = element.areaNameEn ?? "";
              break;
            }
          } else if (element.children) {
            const value = getAirportCity(element.children, code);
            if (value) {
              return value;
            }
          }
        }
        return language === "en-US" ? cityNameEn : cityName;
      };
      setCity(getAirportCity(data, airport));
    }
  }, [data, airport]);

  if (isLoading) {
    return null;
  }
  if (error) {
    return null;
  }
  if (!data) {
    return null;
  }

  return <span>{city}</span>;
};

export default City;
