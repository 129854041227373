import React, { ReactElement, useEffect, useState } from "react";
import { Input, message, Select, SelectProps } from "antd";
import useGlobal from "Store";
import { AirlineItem } from "Interface";
import {
  getAirlinesRole,
  getModuleNameFromPath,
  getModuleStatus,
  getUserAirlines,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useFetch } from "Utils/useFetch";
import { getDefaultAirline } from "Utils/global";

interface AirlinesRoleArr {
  code: string;
  name: string;
}

interface IAirlinesGlobalProps extends SelectProps<any> {
  /**
   * 一般不需传此参数, 对于非航司用户, 可以用此参数指定可选航司列表. 如果不传此参数, 则根据用户权限限定可选航司列表
   */
  userAirlines?: AirlinesRoleArr[];
}

const { Option } = Select;

/**
 * 原airlines选择器与global捆绑, 但捆绑的不彻底, 导致每个使用airlines组件的模块, 需要自己再额外引入一次global,
 * V2版组件, 将使用global的逻辑集中在airlinesV2中处理, 外部组件使用时, 不需要自己再引入一次global
 */
const AirlinesGlobal = (props: IAirlinesGlobalProps): ReactElement => {
  const { userAirlines, ...otherProps } = props;
  const location = useLocation();
  const [allAirlines, setAirlines] = useState<AirlineItem[]>([]);
  const [globalState, actions] = useGlobal();
  const { airlinesQueryCondition, userInfo, systemType } = globalState;
  const { user } = userInfo;
  const { setAirlinesQueryCondition, setAirlinesQueryConditionAttr } = actions;
  const { airlines } = airlinesQueryCondition;
  const isCtrip = user.userType === 1;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const userInfoAirlines = getUserAirlines(userInfo.roleList);
  const sourceAirline = userAirlines || userInfoAirlines;

  const [{ data }, doFetch] = useFetch({
    url: "findAirlines",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
    lazey: true,
  });

  useEffect(() => {
    if (isCtrip) {
      doFetch({
        ext: {
          type: 1,
        },
      });
    }
    if (!airlines) {
      const airlinesRole = userInfo.roleList
        ? getAirlinesRole(userInfo.roleList)
        : undefined;
      const tmp = getDefaultAirline(airlinesRole);
      setAirlinesQueryConditionAttr(tmp);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setAirlines(data);
    }
  }, [data]);

  const handleSelectAirlines = (values: string[], option: any) => {
    if (_.isEmpty(values)) {
      message.error(getSharkText("key.choose_an_airline.hint"));
    } else {
      const codeArr: string[] = [];
      const nameArr: string[] = [];
      if (Array.isArray(option)) {
        option.forEach((item: any) => {
          codeArr.push(item.props.value);
          nameArr.push(item.props.label);
        });
        setAirlinesQueryCondition({
          ...airlinesQueryCondition,
          airlines: codeArr.join(","),
          airlinesName: nameArr.join(","),
        });
      } else {
        setAirlinesQueryCondition({
          ...airlinesQueryCondition,
          airlines: option.props.value,
          airlinesName: option.props.label,
        });
      }
    }
  };

  const filterOption = (input: string, option: any) =>
    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.label.includes(input);

  const selectValues = airlinesQueryCondition.airlines
    ? airlinesQueryCondition.airlines.split(",")
    : [];
  return (
    <>
      {isDemo ? (
        <Input style={{ minWidth: 200 }} disabled value="Demo" />
      ) : !isCtrip ? (
        <Select
          id="toolbarAirlinesSelect"
          showSearch
          style={{ minWidth: 180 }}
          value={selectValues}
          onChange={handleSelectAirlines}
          filterOption={filterOption}
          mode="multiple"
          data-ut-id="toolbarAirlinesSelect"
          data-ut-class="airlineSelector"
          {...otherProps}
        >
          {sourceAirline.map((item) => (
            <Option key={item.code} value={item.code} label={item.name}>
              {item.name}|{item.code}
            </Option>
          ))}
        </Select>
      ) : (
        <Select
          showSearch
          style={{ minWidth: 180 }}
          value={selectValues}
          onChange={handleSelectAirlines}
          filterOption={filterOption}
          mode="multiple"
          data-ut-id="ctripAirlinesSelect"
          data-ut-class="airlineSelector"
          {...otherProps}
        >
          {allAirlines.map((item) => (
            <Option
              className="airlines-select-item"
              key={item.airlinesCode}
              value={item.airlinesCode}
              label={item.airlinesName}
            >
              {item.airlinesName}|{item.airlinesCode}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};
export default AirlinesGlobal;
