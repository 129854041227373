import BarLineChartsV2 from "Components/BarLineChartsV2";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import SearchTrend from "./SearchTrend";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import useGlobalState from "Store";
import { IDownloadHeader, ISeries } from "Interface";
import { DATE_FORMAT, EMPTY_ARRAY } from "Constants";
import { Empty } from "antd";
import { IDynamicPriceHistoryPrice } from "../SalesAnalysisInterface";
import { round } from "lodash";
import moment from "moment";
import { isNumeric, rowToColumn, showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { downloadExcel } from "Utils/downloadXLSX";

interface ITrendBlockProps {
  onTrendClick: (date: string, route: string, flightNO: string) => void;
}
/**
 * 价格趋势
 */
const TrendBlock = (props: ITrendBlockProps): ReactElement => {
  const { onTrendClick } = props;
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  const [queryExtTrend, setQueryExtTrend] = useState<any>({});
  const [totalSeries, setTotalSeries] = useState<ISeries[]>(EMPTY_ARRAY);
  const [transData, setTransData] = useState<any[]>([]);

  const dataTrans = useCallback((data: IDynamicPriceHistoryPrice[]) => {
    const rst = data.reduce((total: any[], cur: IDynamicPriceHistoryPrice) => {
      let item: ISeries = total.find((t: any) => cur.name === t.name);
      if (!item) {
        item = {
          type: "bar",
          name: cur.name,
          stack: cur.name,
          encode: {
            x: "day",
            y: cur.name + "price",
          },
        };
        const itemBG = {
          type: "bar",
          name: cur.name + "全价",
          stack: cur.name,
          encode: {
            x: "day",
            y: cur.name + "feDiscountPrice",
          },
          itemStyle: {
            color: "#ccc",
          },
        };
        total.push(item);
        total.push(itemBG);
      }
      return total;
    }, []);
    // 计算价格与全价的差价
    data.forEach((d) => {
      d.feDiscountPrice = d.fullPrice === 0 ? 0 : d.fullPrice - d.price;
      // d.feDiscountPrice = d.fullPrice - d.price;
      d.day = moment(d.day).format(DATE_FORMAT);
    });
    const tran = rowToColumn(data, "day", "name", [
      "price",
      "feDiscountPrice",
      "fullPrice",
    ]);
    setTotalSeries(rst);
    setTransData(tran);
    return tran;
  }, []);

  const chartConfig = useMemo(() => {
    return {
      color: [
        "#01C5DB",
        "#FC9B4F",
        "#177DFE",
        "#FF008E",
        "#D22779",
        "#612897",
        "#0C1E7F",
        "#EDD2F3",
        "#FFFCDC",
        "#FFCC1D",
      ],
      title: {
        text: getSharkText("config_page_price"),
        top: "center",
        left: "2%",
      },
      dataZoom: null,
      xAxis: {
        type: "category",
        name: "",
        nameLocation: "start",
        nameTextStyle: {
          padding: [50, 25, 0, 0],
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          overflow: "truncate",
        },
      },
      yAxis: [
        {
          type: "value",
          splitNumber: 4,
          min: (v: any) => v.min * 0.9,
          axisLabel: {
            formatter: (value: number) => round(value, 2),
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const day = params[0].data.day
            ? moment(params[0].data.day).format(DATE_FORMAT)
            : "";
          let dom = "";
          params.forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const dim = item.dimensionNames[item.encode.y[0]];
            let itemValue = item.value[dim];
            if (
              isNumeric(itemValue) &&
              dim &&
              dim.indexOf("feDiscountPrice") >= 0
            ) {
              itemValue =
                item.value[dim.replace("feDiscountPrice", "fullPrice")];
              if (itemValue === 0) {
                itemValue = "--";
              }
            }
            const value =
              itemValue === -1
                ? getSharkText("key.cancel.button")
                : isNumeric(itemValue)
                ? showNum(itemValue)
                : itemValue;
            const val = `${item.seriesName}: ${value}`;
            dom = dom + "<br />" + dot + val;
          });
          dom = day + dom;
          return dom;
        },
      },
    };
  }, []);

  const onDownload = useCallback(() => {
    const dimColumns: IDownloadHeader[] = [
      {
        title: getSharkText("key.date.flightplan"),
        dataIndex: "day",
        downloadFormatter: (v) => moment(v).format(DATE_FORMAT),
      },
    ];
    const valColumns: IDownloadHeader[] = totalSeries.map((s) => ({
      title: s.name,
      dataIndex: s.encode.y,
      downloadFormatter: (v) => showRawNum(v),
    }));
    const columns = dimColumns.concat(valColumns);
    downloadExcel(columns, transData, getSharkText("config_page_price_trend"));
  }, [totalSeries, transData]);

  const chartEvents = useMemo(() => {
    return {
      click: (params: any) => {
        const date = moment(params.name).format(DATE_FORMAT);
        const name = params.seriesName as string;
        const nameArr = name.split(" ");
        if (nameArr.length < 2) {
          console.log(getSharkText("config_page_flight_or_route_not_complete"));
          return;
        }
        const route = nameArr[1];
        const flightNO = nameArr[0];
        onTrendClick(date, route, flightNO);
      },
    };
  }, [onTrendClick]);

  return (
    <>
      <div style={{ marginTop: 20 }}>
        <SearchTrend setQueryExt={setQueryExtTrend} isDemo={false} />
      </div>
      <div style={{ marginTop: 0 }}>
        {queryExtTrend.filter?.length > 0 ? (
          <BarLineChartsV2
            queryUrl={QUERY_URL[systemType].trend}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].trend}
            dataTrans={dataTrans}
            defaultSeries={totalSeries}
            ext={queryExtTrend}
            useGranularity={false}
            useDownload={true}
            onDownload={onDownload}
            chartConfig={chartConfig}
            chartEvents={chartEvents}
          />
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
};
export default TrendBlock;
