import { SwapRightOutlined } from "@ant-design/icons";
import CascaderArea from "Components/CascaderArea";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { Area, FlightClass, RangeObject } from "Interface";
import { defaultArea } from "Page/AI/UserProfile/common";
import useRefFunc from "Utils/useRefFunc";
import { Button, Col, Row, message } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { BeyondParam } from "./interface";
import Airlines from "Components/Toolbar/Airlines";
import useGlobalState from "Store";
import { getDateRange } from "Utils";

export interface SearchAreaProps {
  onQuery: (v: BeyondParam) => void;
}

/** 客源流向-搜索区域 */
const SearchArea = (props: SearchAreaProps): ReactElement => {
  const { onQuery } = props;
  const [globalState] = useGlobalState();
  const { airlines } = globalState.airlinesQueryCondition;
  const [departArea, setDepartArea] = useState<Area>(defaultArea);
  const [arriveArea, setArriveArea] = useState<Area>(defaultArea);
  const [destinationArea, setDestinationArea] = useState<Area>(defaultArea);
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange("lastThreeMonth")
  );
  const [initialed, setInitialed] = useState(false);

  const handleSearch = useRefFunc(() => {
    if (
      departArea.areaType === 0 &&
      arriveArea.areaType === 0 &&
      destinationArea.areaType === 0
    ) {
      message.error("Please select at least one area");
      return;
    }
    onQuery({
      airline: airlines,
      dateRange,
      departArea,
      arriveArea,
      destinationArea,
    });
  });

  // useEffect(() => {
  //   if (!initialed && airlines) {
  //     handleSearch();
  //     setInitialed(true);
  //   }
  // }, [airlines, handleSearch, initialed]);

  return (
    <Row gutter={20}>
      <Col>
        <Airlines />
      </Col>
      <Col>
        <DateRangePickerV2
          value={dateRange}
          onChange={setDateRange}
          otherProps={{ picker: "month", format: "YYYY-MM" }}
        />
      </Col>
      <Col>
        <CascaderArea
          flightClass={FlightClass.All}
          setSelectedArea={setDepartArea}
          changeOnSelect={true}
          types={[2, 3, 4, 5]}
          placeholder="Country/Province/City/Airport"
          lang="en-US"
        />
      </Col>
      <Col>
        <SwapRightOutlined />
      </Col>
      <Col>
        <CascaderArea
          flightClass={FlightClass.All}
          setSelectedArea={setArriveArea}
          changeOnSelect={true}
          types={[2, 3, 4, 5]}
          placeholder="Country/Province/City/Airport"
          lang="en-US"
        />
      </Col>
      <Col>...</Col>
      <Col>
        <CascaderArea
          flightClass={FlightClass.All}
          setSelectedArea={setDestinationArea}
          changeOnSelect={true}
          types={[2, 3, 4, 5]}
          placeholder="Country/Province/City/Airport"
          lang="en-US"
        />
      </Col>
      <Col>
        <Button type="primary" onClick={handleSearch}>
          Query
        </Button>
      </Col>
    </Row>
  );
};
export default SearchArea;
