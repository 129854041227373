import React, { useEffect } from "react";
import { Radio, Tooltip } from "antd";
import { FLIGHT_CLASS } from "Constants";
import useGlobal from "Store";
import { RadioChangeEvent } from "antd/lib/radio";
import { getSharkText } from "Utils/i18nGlobal";

const AirlineFlightClass: React.FC = () => {
  const [globalState, actions] = useGlobal();
  const { airlinesQueryCondition, helpVisibility } = globalState;
  const { setAirlinesQueryConditionAttr } = actions;
  const { flightClass } = airlinesQueryCondition;

  useEffect(() => {
    if (flightClass !== 1) {
      setAirlinesQueryConditionAttr({
        flightClass: 1,
      });
    }
  }, [flightClass, setAirlinesQueryConditionAttr]);

  const handleChangeFlightClass = (e: RadioChangeEvent) => {
    setAirlinesQueryConditionAttr({
      flightClass: e.target.value,
    });
  };

  return (
    <Tooltip
      placement="bottom"
      visible={helpVisibility}
      title={getSharkText("key.change_data_range")}
      getPopupContainer={() => document.getElementById("main") || document.body}
    >
      <Radio.Group
        id="toolbarFlightClass"
        value={airlinesQueryCondition.flightClass}
        onChange={handleChangeFlightClass}
      >
        <Radio.Button value={1}>{FLIGHT_CLASS[1]}</Radio.Button>
      </Radio.Group>
    </Tooltip>
  );
};

export default AirlineFlightClass;
