import { FlightReviewFlightFilter } from "@ctrip/flt-bi-flightai-airlines";
import React, { ReactElement, useState } from "react";
import TablePortal from "./TablePortal";
import SearchArea from "./SearchArea";

/**
 * 航班数据tab页
 */
const FlightPanel = (): ReactElement => {
  const [filter, setFilter] = useState<FlightReviewFlightFilter>(
    {} as FlightReviewFlightFilter
  );
  return (
    <div>
      <SearchArea onChange={setFilter} />
      <TablePortal filter={filter} />
    </div>
  );
};
export default FlightPanel;
