import BarLineChartsV2 from "Components/BarLineChartsV2";
import Page from "Layout/Page";
import React, { ReactElement, useCallback, useState } from "react";
import SearchArea from "./SearchArea";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { IDownloadHeader, ISeries } from "Interface";
import { getServer } from "Service/server";
import { durationFormatter, showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { downloadExcel } from "Utils/downloadXLSX";
import moment from "moment";
import { DATE_FORMAT, DATE_HOUR_FORMAT, DATE_MINUTE_FORMAT } from "Constants";
import { round } from "lodash";
import { Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";

export interface ILoadFactorProps {
  example?: string;
}

export interface IData {
  /**
   * 日期
   */
  day: string;
  /**
   * 采集日期
   */
  collectDay: string;
  /**
   * 航线
   */
  route: string;
  /**
   * 航线名称
   */
  routeName: string;
  /**
   * 航班
   */
  flightNO: string;
  /**
   * 客座率
   */
  lf: number;
  /**
   * 运力
   */
  cap: number;
  /**
   * 已售
   */
  sale: number;
}

const tableColumns: Array<IDownloadHeader<IData>> = [
  {
    title: "出发",
    dataIndex: "route",
    sorter: (a: IData, b: IData) =>
      a.route.substr(0, 3).localeCompare(b.route.substr(0, 3)),
    render: (val: string) => val.substr(0, 3),
  },
  {
    title: getSharkText("key.arrival.name"),
    dataIndex: "route",
    sorter: (a: IData, b: IData) =>
      a.route.substr(3, 3).localeCompare(b.route.substr(3, 3)),
    render: (val: string) => val.substr(3, 3),
  },
  {
    title: getSharkText("config_page_flight"),
    dataIndex: "flightNO",
    sorter: (a: IData, b: IData) => a.flightNO.localeCompare(b.flightNO),
  },
  {
    title: getSharkText("charts.date_of_departure"),
    dataIndex: "day",
    sorter: (a: IData, b: IData) => a.day.localeCompare(b.day),
    render: (val: string) => moment(val).format(DATE_FORMAT),
  },
  {
    title: getSharkText("config_page_takeoff_time"),
    dataIndex: "day",
    sorter: (a: IData, b: IData) => a.day.localeCompare(b.day),
    render: (val: string) => moment(val).format("hh:MM"),
  },
  {
    title: getSharkText("config_page_collection_time"),
    dataIndex: "collectDay",
    sorter: (a: IData, b: IData) => a.collectDay.localeCompare(b.collectDay),
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("key.available_seats"),
    dataIndex: "cap",
    sorter: (a: IData, b: IData) => a.cap - b.cap,
  },
  {
    title: getSharkText("key.loadfactor"),
    dataIndex: "lf",
    sorter: (a: IData, b: IData) => a.lf - b.lf,
    render: (val: number) => showNum(val * 100, "percentage"),
  },
];

const downloadColumns: Array<IDownloadHeader<IData>> = [
  {
    title: getSharkText("config_page_time"),
    dataIndex: "collectDay",
    downloadFormatter: (val: any) => moment(val).format(DATE_HOUR_FORMAT),
  },
  {
    title: getSharkText("config_page_flight"),
    dataIndex: "flightNO",
  },
  {
    title: getSharkText("key.loadfactor"),
    dataIndex: "lf",
    render: (val: number) => showNum(val, "percentage"),
    downloadFormatter: (val: number) => showRawNum(val * 100, "percentage"),
  },
];

const chartConfig = {
  title: {
    text: getSharkText("key.loadfactor"),
    top: "center",
    left: "2%",
  },
  dataZoom: null,
  xAxis: {
    type: "category",
    name: getSharkText("config_page_days_from_takeoff"),
    nameLocation: "start",
    nameTextStyle: {
      padding: [50, 25, 0, 0],
    },
    axisTick: {
      show: false,
    },
    axisLabel: {
      overflow: "truncate",
      // interval: (index: number, value: string) => {
      //   const hours = round(parseFloat(value) / 3600);
      //   // 仅显示整天的数据
      //   const tmp = hours % 24;
      //   if (tmp !== 0) {
      //     // return false;
      //   }
      //   return true;
      // },
      formatter: (val: number) => {
        // const hours = moment(record.takeofftime).diff(moment(val), 'hours');
        const hours = round(val);
        return durationFormatter(hours, "h");
      },
    },
  },
  yAxis: [
    {
      type: "value",
      splitNumber: 4,
      min: (v: any) => v.min * 0.9,
      axisLabel: {
        formatter: (value: number) => `${round(value * 100, 2)} %`,
      },
    },
  ],
  tooltip: {
    trigger: "axis",
    formatter: (params: any) => {
      const day = durationFormatter(params[0].data[0], "h");
      let dom = "";
      params.forEach((item: any) => {
        const dot = `<span class='dot' style='background:${item.color}'></span>`;
        const itemValue = item.data[1];
        const value = showNum(itemValue * 100, "percentage");
        const val = `${item.seriesName}: ${value}`;
        dom = dom + "<br />" + dot + val;
      });
      dom = day + dom;
      return dom;
    },
  },
};

/**
 * 益谷数据上客进度
 */
const LoadFactor = (): ReactElement => {
  const [totalSeries, setTotalSeries] = useState<ISeries[]>([]);
  const [ext, setExt] = useState<any>();
  const [initFilter, setInitFilter] = useState<boolean>(false);
  const [oriData, setOriData] = useState<Array<IData & { key: string }>>([]);

  const dataTrans = useCallback((data: IData[]) => {
    if (data.length) {
      const tmp: Array<IData & { key: string }> = data.map((d, i) => {
        return { ...d, key: `id${i}` };
      });
      setOriData(tmp);
    }
    const rst = data.reduce((total: any[], cur: IData) => {
      let item: ISeries = total.find((t: any) => cur.flightNO === t.name);
      // 计算提前起飞小时数, 按照起飞日的24点为基准计算
      const takeoffDate24 = moment(cur.day)
        .set("hour", 24)
        .set("minute", 0)
        .set("second", 0);
      const beforeSeconds = takeoffDate24.diff(moment(cur.collectDay)) / 1000;
      if (!item) {
        item = {
          type: "line",
          name: cur.flightNO,
          isPercentVal: true,
          encode: {
            x: "collectDay",
            y: "lf",
          },
          data: [],
        };
        total.push(item);
      }
      item.data.push([beforeSeconds, cur.lf]);
      return total;
    }, []);
    setTotalSeries(rst);
    return rst;
  }, []);

  const onDownload = useCallback((data: any[]) => {
    downloadExcel(downloadColumns, data, getSharkText("menu_load_factor"));
  }, []);

  const loadedFilter = useCallback((v: boolean) => {
    setTimeout(() => {
      setInitFilter(v);
    }, 300);
  }, []);

  return (
    <Page defaultDateMode="fourteenDays" needToolBar={false}>
      <div className="content-white" style={{ marginTop: 0 }}>
        <SearchArea setExt={setExt} setInitFilter={loadedFilter} />
      </div>
      <div className="content-white">
        <Spin spinning={!initFilter}>
          <BarLineChartsV2
            server={getServer(4)}
            queryUrl={QUERY_URL.chart}
            moduleCode={MODULE_CODE}
            chartTableCode={CHART_TABLE_CODE.chart}
            dataTrans={dataTrans}
            defaultSeries={totalSeries}
            useDataset={false}
            ext={ext}
            useGranularity={false}
            useDownload={true}
            onDownload={onDownload}
            chartConfig={chartConfig}
          />
          <div style={{ marginTop: 20 }}>
            <CustomTableV2
              dataSource={oriData}
              columns={tableColumns}
              downloadName={getSharkText("menu_load_factor")}
              moduleCode={MODULE_CODE}
              chartTableCode={CHART_TABLE_CODE.chart}
            />
          </div>
        </Spin>
      </div>
    </Page>
  );
};
export default LoadFactor;
