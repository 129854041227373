import { RedoOutlined } from "@ant-design/icons";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import CompareCom from "Components/Dates/CompareCom";
import Airlines from "Components/Toolbar/Airlines";
import useRefFunc from "Utils/useRefFunc";
import { Button, Col, Row } from "antd";
import React, { ReactElement } from "react";
import { TopSearchParam } from "../interface";
import { Moment } from "@react-awesome-query-builder/core";
import useGlobalState from "Store";
import moment from "moment";

export interface SearchAreaProps {
  query: () => void;
  value: TopSearchParam;
  onChange: (v: TopSearchParam) => void;
}

/** 汇总过滤 */
const SearchArea = (props: SearchAreaProps): ReactElement => {
  const { query, value, onChange } = props;
  const [, actions] = useGlobalState();

  const changeAirline = useRefFunc((v) => {
    actions.setAirlinesQueryConditionAirline({
      airlines: v.code,
      airlinesName: v.name,
    });
    onChange({ ...value, airline: v.code });
  });

  const handleRefresh = useRefFunc(() => {
    query();
  });
  return (
    <div>
      <Row gutter={[20, 20]}>
        <Col>
          <Airlines
            mode={undefined}
            value={value.airline}
            onChange={(v) => changeAirline(v)}
          />
        </Col>
        <Col>
          <DateRangePickerV2
            defaultDateMode="lastMonth"
            otherProps={{
              disabledDate: (cur: Moment) => {
                return (
                  cur.isBefore(moment().startOf("day")) ||
                  cur.isAfter(moment().add(60, "d"))
                );
              },
            }}
            value={value.tkDate}
            onChange={(v) =>
              onChange({ ...value, tkDate: v as [Moment, Moment] })
            }
          />
        </Col>
        <Col>
          <CompareCom
            wowType={value.wowType}
            yoyType={value.yoyType}
            yoyYear={value.yoyYear}
            handleChangeWowType={(v) => onChange({ ...value, wowType: v })}
            handleChangeYoyType={(v) => onChange({ ...value, yoyType: v })}
            handleChangeYoyYear={(v) => onChange({ ...value, yoyYear: v })}
          />
        </Col>
        <Col>
          <Button onClick={handleRefresh} type="primary">
            刷新
            <RedoOutlined />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default SearchArea;
