export const MODULE_CODE: any = {
  1: "tourist_source_airport",
  2: "tourist_source_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    lineCharts: "AP0501",
    table: "AP0502",
  },
  2: {
    lineCharts: "AL0501",
    table: "AL0502",
  },
};

export const QUERY_URL: any = {
  1: {
    lineCharts: "queryAirportTouristSourceBarLine",
    table: "queryAirportTouristSourceTable",
  },
  2: {
    lineCharts: "queryAirlinesTouristSourceBarLine",
    table: "queryAirlinesTouristSourceTable",
  },
};
