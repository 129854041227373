import React, { ReactElement } from "react";
import { LockOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message, notification } from "antd";
import hmacMD5 from "crypto-js/hmac-md5";
import { SERVER, TOKEN, UID } from "Constants";
import useGlobal from "Store";
import { getCookie } from "Utils";
import "./index.css";
import { redirectLogin } from "Utils/global";
import { useForm } from "antd/es/form/Form";
import useI18Next from "Utils/useI18Next";

const User = (): ReactElement => {
  const [globalState, actions] = useGlobal();
  const { setRefreshCodeState } = actions;
  const { refreshCode } = globalState;
  const { id, mobile } = globalState.userInfo.user;
  const t = useI18Next();
  const [form] = useForm();

  const { uid, token, name } = actions.getLogin();

  const abortCtrl = new AbortController();
  const signal = abortCtrl.signal;

  const logout = async () => {
    try {
      const result: any = await fetch(`${SERVER}logout`, {
        signal,
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: getCookie(UID),
          token: getCookie(TOKEN),
        }),
      });
      const res = await result.json();
      if (res.flag) {
        message.destroy();
        logout();
        redirectLogin();
      } else {
        message.destroy();
        message.error("server error");
      }
    } catch (error) {
      message.destroy();
      message.error("server error");
    }
  };

  const savePassword = async (params: any) => {
    const result: any = await fetch(`${SERVER}saveMyInfo`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        head: {
          uid,
          token,
          requestTime: new Date(),
          appId: "",
          clientType: "2",
          clientVersion: "",
        },
        user: {
          id,
          passwd: uid
            ? hmacMD5(params.passwordDoublecheck, uid).toString()
            : "error",
        },
      }),
    });
    const res = await result.json();
    if (res.flag) {
      notification.success({
        message: t("common.submitSuccess"),
        description: t("user.changePwdMsg"),
      });
      setTimeout(() => {
        logout();
        redirectLogin();
      }, 2000);
    } else {
      try {
        const code = res.ResponseStatus.Errors[0].ErrorCode;
        message.error(t(`error.${code}`));
        setRefreshCodeState(!refreshCode);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const handleResetSubmit = async () => {
    // e.preventDefault();
    const rst = await form.validateFields();
    if (rst) {
      savePassword(form.getFieldsValue());
    }
  };

  return (
    <div className="change-password">
      <Card className="login-card">
        <div className="login-card-title-wrap">
          <div className="login-card-title">{t("user.changePwd.title")}</div>
          <div className="login-card-subtitle">
            {t("user.changePwd.account")}: {mobile}
          </div>
        </div>
        <Form onFinish={handleResetSubmit} form={form}>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("user.changePwd.currentPwd"),
              },
              // {
              //   validator: (rule, val, callback) => {
              //     if (!val) {
              //       callback();
              //     }
              //     const validateResult = !uid
              //       ? false
              //       : hmacMD5(val, uid).toString().toUpperCase() ===
              //         // 自定义规则
              //         passwd.toUpperCase();
              //     if (!validateResult) {
              //       callback(t("user.changePwd.currentPwdIncorrect"));
              //     }
              //     callback();
              //   },
              // },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={t("user.changePwd.currentPwd")}
              className="login-card-input"
              type="password"
            />
          </Form.Item>
          <Form.Item
            name="passwordNew"
            rules={[
              {
                min: 8,
                message: t("user.changePwd.minLenNotice"),
              },
              {
                pattern: /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}/,
                message: t("user.changePwd.pwdRule"),
              },
              {
                validator: (rule, val, warn) => {
                  const cfmPwd = form.getFieldValue("passwordDoublecheck");
                  if (val === undefined || val === "") {
                    warn(t("user.changePwd.newPwdNotice"));
                  } else if (cfmPwd && cfmPwd !== val) {
                    warn(t("user.changePwd.newPwdDiffNotice"));
                  } else if (val === name) {
                    warn(t("user.changePwd.newPwdSameAccountNotice"));
                  } else {
                    warn();
                  }
                },
              },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={t("user.changePwd.newPwdNotice")}
              className="login-card-input"
              type="password"
            />
          </Form.Item>
          <Form.Item
            name="passwordDoublecheck"
            rules={[
              {
                min: 8,
                message: t("user.changePwd.minLenNotice"),
              },
              {
                pattern: /(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}/,
                message: t("user.changePwd.pwdRule"),
              },
              {
                validator: (rule, val, callback) => {
                  const Pwd = form.getFieldValue("passwordNew");
                  if (val === undefined || val === "") {
                    callback(t("user.changePwd.newPwdAgain"));
                  } else if (Pwd && Pwd !== val) {
                    callback(t("user.changePwd.newPwdDiffNotice"));
                  } else if (val === name) {
                    callback(t("user.changePwd.newPwdSameAccountNotice"));
                  } else {
                    callback();
                  }
                },
              },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder={t("user.changePwd.newPwdNotice")}
              className="login-card-input"
              type="password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="login-form-button"
            >
              {t("common.save")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default User;
