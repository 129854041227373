import { BarLineData } from "@ctrip/flt-bi-flightai-airlines";

/**
 * 航班复盘查询参数
 */
export interface IFlightReviewFilter extends Record<string, unknown> {
  filter: {
    flightNO: string;
    route: string;
  };
}

export const defaultQuery: IFlightReviewFilter = {
  filter: {
    flightNO: "",
    route: "",
  },
};
/**
 * 航班复盘图表查询参数
 */
export interface IFlightReviewLineExt extends IFlightReviewFilter {
  cardCode: string;
}
export const defaultLineExt: IFlightReviewLineExt = {
  ...defaultQuery,
  cardCode: "",
};

export interface competeItem {
  flightNO: string;
  route?: string;
}
export interface IReviewCompeteExt extends IFlightReviewFilter {
  competeFlights?: competeItem[];
}

export interface ISearchData {
  /**
   * 	起飞日期
   */
  day: string;
  /**
   * 	离起飞日x天 D1
   */
  diffDay: number;
  /**
   * 	航线搜索指数
   */
  searchIndex: number;
  /**
   * 	订座数增量
   */
  seatInc: number;
}

export interface IPriceData {
  /**
   * 起飞日期
   */
  day: string;
  /**
   * 采集时间
   */
  gatherDay: string;
  /**
   * 离起飞日x天 D1
   */
  diffDay: number;
  /**
   * 航班号/班期 当前航班为''
   */
  name: string;
  /**
   * 航班价格
   */
  price: number;
  /**
   * 航线成交均价
   */
  avgPrice: number;
  /**
   * 动态调价建议
   */
  feedback: number;
  /**
   * 原始价
   */
  rawPrice: number;
  /**
   * 建议价
   */
  suggestPrice: number;
  /**
   * 价差
   */
  diffPrice: number;
  /**
   * 原始舱
   */
  rawCabin: string;
  /**
   * 建议舱
   */
  suggestCabin: string;
}

export interface ILoadData {
  /**
   * 起飞日期
   */
  day: string | null;
  /**
   * 采集时间
   */
  gatherDay: string | null;
  /**
   * 离起飞日x天 D1
   */
  diffDay: number | null;
  /**
   * 航班号/班期 当前航班为''
   */
  name: string | null;
  /**
   * 客座率
   */
  lf: number | null;
  /**
   * 航线客座率
   */
  lflines: number | null;
  /**
   * 前四周平均客座率
   */
  last4weeklf: number | null;
}

/**
 * 0=总览, 1=对比竞飞, 2=对比前四周
 */
export type TCompareType = 0 | 1 | 2;

export interface IFootChartData {
  /**
   * 起飞日期
   */
  day: string | null;
  /**
   * 建设使用率
   */
  useFeedbackPercent: number | null;
  /**
   * 建议同意率
   */
  acceptFeedbackPercent: number | null;
  /**
   * 航班座公里收入
   */
  raskFlight: number | null;
  /**
   * 与航线座收差值
   */
  raskLineGap: number | null;
}

export interface IFootTableData {
  /**
   * 月份
   */
  month: number | null;
  /**
   * 座收提升
   */
  raskLift: number | null;
  /**
   * 平均座收
   */
  avgRask: number | null;
  /**
   * 座收提升百分比
   */
  raskLiftPercent: number | null;
  /**
   * 客座率提升
   */
  lfLift: number | null;
  /**
   * 平均客座率
   */
  avgLf: number | null;
  /**
   * 客座率提升百分比
   */
  lfLiftPercent: number | null;
  /**
   * 价格提升
   */
  priceLift: number | null;
  /**
   * 平均价格
   */
  avgPrice: number | null;
  /**
   * 价格提升百分比
   */
  priceLiftPercent: number | null;
}

export interface BarLineDataEx extends BarLineData {
  diff: number;
}
