import {
  Affix,
  Col,
  Empty,
  Radio,
  RadioChangeEvent,
  Row,
  Spin,
  Tabs,
} from "antd";
import {
  DATE_FORMAT,
  LIMIT_END_DATE,
  LIMIT_START_DATE,
  WEEK_EN,
} from "Constants";
import moment, { Moment } from "moment";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  defaultLineExt,
  IFlightReviewFilter,
  IFlightReviewLineExt,
} from "../ReviewCommon";
import Cards from "./Card/Cards";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import {
  MODULE_CODE as D_MODULE_CODE,
  QUERY_URL as D_QUERY_URL,
} from "Page/AI/DynamicPrice/fetchCode";
import useGlobalState from "Store";
import BarLineChartsV2 from "Components/BarLineChartsV2";
import { ISeries, RangeObject } from "Interface";
import PlanAnalysis from "./PlanAnalysis";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getServer } from "Service/server";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { cloneDeep } from "lodash";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";

const { TabPane } = Tabs;

export interface ISummaryProps {
  queryExt: IFlightReviewFilter;
  setQueryExt: (v: IFlightReviewFilter) => void;
  setIsSummary: (v: boolean) => void;
}

const defaultPlanAnalysisSeries: ISeries[] = [
  {
    name: "前四周航班",
    type: "bar",
    itemStyle: {
      color: "#01C5DB",
    },
    encode: {
      x: "day",
      y: "last4weekFlight",
    },
  },
  {
    name: "前四周航线",
    type: "bar",
    itemStyle: {
      color: "#FC9B4F",
    },
    encode: {
      x: "day",
      y: "last4weekRoute",
    },
  },
  {
    name: "本期",
    type: "bar",
    itemStyle: {
      color: "#177DFE",
    },
    encode: {
      x: "day",
      y: "value",
    },
  },
];

/**
 * 航班复盘第一部分, 卡片, 趋势图, 班期分析
 */
const Summary = (props: ISummaryProps): ReactElement => {
  const { queryExt, setQueryExt, setIsSummary } = props;
  const [lineChartExt, setLineChartExt] =
    useState<IFlightReviewLineExt>(defaultLineExt);
  const [planExt, setPlanExt] = useState<IFlightReviewLineExt>();
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const { startDate } = airlinesQueryCondition;
  const [title, setTitle] = useState<string>("");
  const [tabs, setTabs] = useState<string[]>(["总览"]);
  const [activeKey, setActiveKey] = useState<string>(tabs[0]);
  const [dateRange, setDateRange] = useState<RangeObject>([
    moment(startDate).add(-60, "days"),
    moment(startDate),
  ]);
  const [otherProps, setOtherProps] = useState<RangePickerProps<Moment>>();
  const [aggCode, setAggCode] = useState<number>(0);
  const [curCard, setCurCard] = useState({
    cardCode: "",
    cardName: "",
    cardHint: "",
    cardValue: 0,
    avgValue: 0,
    compareValue: 0,
  });
  const [planSeries, setPlanSeries] = useState<ISeries[]>(
    defaultPlanAnalysisSeries
  );

  /**
   * 获取tabs数据
   */
  const [{ data }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].routeFlight,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: null,
    },
    query: airlinesQueryCondition,
    ext: {
      filter: {
        flightNO: queryExt.filter.flightNO,
      },
      type: 1,
    },
    lazey: true,
  });

  /**
   * 获取航程给标题用
   */
  const [{ data: wholeRoute }, wholeRouteFetch] = useFetch({
    server: getServer(systemType),
    url: D_QUERY_URL[systemType].routeFlight,
    head: {
      moduleCode: D_MODULE_CODE[systemType],
      chartTableCode: null,
    },
    query: airlinesQueryCondition,
    ext: {
      code: [queryExt.filter.flightNO],
      type: 5,
    },
    lazey: true,
  });

  /**
   * 获取全部航程
   */
  useEffect(() => {
    if (queryExt.filter.flightNO) {
      wholeRouteFetch({
        query: airlinesQueryCondition,
        ext: { code: [queryExt.filter.flightNO], type: 5 },
      });
    }
  }, [airlinesQueryCondition, queryExt.filter.flightNO, wholeRouteFetch]);

  /**
   * 切换卡片, 设置班期参数
   */
  useEffect(() => {
    setPlanExt({
      ...queryExt,
      cardCode: curCard.cardCode,
    });
  }, [curCard.cardCode, queryExt]);

  /**
   * 切换卡片时调整图表的查询参数
   */
  useEffect(() => {
    setLineChartExt({
      ...queryExt,
      cardCode: curCard.cardCode,
      query: {
        ...airlinesQueryCondition,
        startDate:
          (dateRange && dateRange[0]?.format(DATE_FORMAT)) || LIMIT_START_DATE,
        endDate:
          (dateRange && dateRange[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
        compareType: 1,
      },
    });
  }, [curCard, queryExt, dateRange, airlinesQueryCondition]);

  /**
   * 根据航班号和airlineQueryCondition请求航段数据
   */
  useEffect(() => {
    if (queryExt.filter.flightNO) {
      doFetch({
        query: airlinesQueryCondition,
        ext: {
          filter: {
            flightNO: queryExt.filter.flightNO,
          },
          type: 1,
        },
      });
    }
  }, [airlinesQueryCondition, queryExt.filter.flightNO, doFetch]);

  /**
   * 根据请求数据刷新tabs, 如果切换后不存在已选中的tab, 则切回总览tab
   */
  useEffect(() => {
    if (data && data.length) {
      const rst = ["总览"].concat(data.map((d: any) => d.line));
      setTabs(rst);
      if (rst.indexOf(activeKey) < 0) {
        setActiveKey("总览");
      }
    } else {
      setTabs(["总览"]);
      setActiveKey("总览");
    }
  }, [data, activeKey]);
  useEffect(() => {
    setIsSummary(activeKey === "总览");
  }, [activeKey, setIsSummary]);

  /**
   * 切换起飞日期时, 重置趋势图的默认日期和限定日期
   */
  useEffect(() => {
    if (!startDate) {
      return;
    }
    const end = moment(startDate);
    const start = cloneDeep(end).add(-60, "days");
    setDateRange([start, end]);
    const disabledDateFn = (current: Moment) => current && current.isAfter(end);
    setOtherProps({ disabledDate: disabledDateFn });
  }, [startDate]);

  useEffect(() => {
    const weekDay = WEEK_EN[(moment(startDate).day() + 6) % 7];
    const tmp = `${startDate}(${weekDay}) 
    ${queryExt.filter.flightNO || ""} ${
      wholeRoute && wholeRoute.length ? wholeRoute[0].line : ""
    }`;
    setTitle(tmp);
  }, [queryExt, startDate, wholeRoute]);

  useEffect(() => {
    if (
      (activeKey === getSharkText("config_page_overview") &&
        queryExt.filter.route !== "") ||
      (activeKey !== getSharkText("config_page_overview") &&
        queryExt.filter.route !== activeKey)
    ) {
      setQueryExt({
        filter: {
          ...queryExt.filter,
          route:
            activeKey === getSharkText("config_page_overview") ? "" : activeKey,
        },
      });
    }
  }, [activeKey, queryExt, setQueryExt]);

  /**
   * 总览模式, 不展示前四周航线柱状图数据
   */
  useEffect(() => {
    let tmp = cloneDeep(defaultPlanAnalysisSeries);
    if (activeKey === "总览") {
      tmp = tmp.filter((s) => s.name !== "前四周航线");
    }
    if (curCard.cardCode === "final_lf") {
      tmp = tmp.map((s) => {
        s.isPercentVal = true;
        return s;
      });
    }
    setPlanSeries(tmp);
  }, [activeKey, curCard.cardCode]);

  const defaultSeries: ISeries[] = useMemo(
    () => [
      {
        name: "本期",
        type: "line",
        isPercentVal: curCard.cardCode === "final_lf",
        yAxisIndex: curCard.cardCode === "final_lf" ? 1 : 0,
        encode: {
          x: "day",
          y: "value",
        },
      },
      {
        name: getSharkText("config_page_last_period"),
        type: "line",
        isPercentVal: curCard.cardCode === "final_lf",
        yAxisIndex: curCard.cardCode === "final_lf" ? 1 : 0,
        encode: {
          x: "day",
          y: "compareValue",
        },
      },
      {
        name: getSharkText("config_page_this_period"),
        type: "bar",
        yAxisIndex: 1,
        isPercentVal: true,
        encode: {
          x: "day",
          y: "comparePer",
        },
      },
    ],
    [curCard]
  );

  const handleChange = useCallback((e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  }, []);
  if (!queryExt) {
    return <Empty />;
  }

  if (!queryExt) {
    return <Spin />;
  }

  return (
    <div>
      <Affix offsetTop={42}>
        <h1 style={{ background: "#fff" }}>{title}</h1>
      </Affix>
      {queryExt.filter.flightNO ? (
        <Tabs activeKey={activeKey} onChange={setActiveKey}>
          {tabs.map((t) => (
            <TabPane key={t} tab={t} />
          ))}
        </Tabs>
      ) : (
        <Empty />
      )}
      <Cards
        queryUrl={QUERY_URL[systemType].card}
        moduleCode={MODULE_CODE[systemType]}
        chartTableCode={CHART_TABLE_CODE[systemType].card}
        setCurCard={setCurCard}
        curCardCode={curCard.cardCode}
        area={{} as any}
        queryExt={queryExt}
      />
      <Row style={{ marginTop: 10 }}>
        <Col span={14}>
          <Row justify="space-around" style={{ marginBottom: 10 }}>
            <Col>
              <Radio.Group
                value={aggCode}
                onChange={handleChange}
                id="chartsAggCode"
              >
                <Radio.Button value={0}>
                  {getSharkText("key.day.button")}
                </Radio.Button>
                <Radio.Button value={1}>
                  {getSharkText("key.week.button")}
                </Radio.Button>
                <Radio.Button value={2}>
                  {getSharkText("key.month.button")}
                </Radio.Button>
              </Radio.Group>
            </Col>
            <Col>
              <DateRangePickerV2
                defaultDateMode="fourteenDays"
                value={dateRange}
                onChange={setDateRange}
                otherProps={otherProps}
              />
            </Col>
          </Row>
          {lineChartExt.cardCode && lineChartExt.filter.flightNO ? (
            <BarLineChartsV2
              queryUrl={QUERY_URL[systemType].barline}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].barline}
              defaultSeries={defaultSeries}
              ext={lineChartExt}
              need100Times
              aggCode={aggCode}
              startDate={
                dateRange?.length
                  ? dateRange[0]?.format(DATE_FORMAT)
                  : undefined
              }
              endDate={
                dateRange?.length === 2
                  ? dateRange[1]?.format(DATE_FORMAT)
                  : undefined
              }
              precision={
                curCard.cardCode === "kilo_income"
                  ? 3
                  : curCard.cardCode === "final_lf"
                  ? 1
                  : 0
              }
            />
          ) : (
            <Empty />
          )}
        </Col>
        <Col span={10}>
          {planExt && planExt.filter.flightNO ? (
            <PlanAnalysis
              ext={planExt}
              defaultSeries={planSeries}
              precision={
                curCard.cardCode === "kilo_income"
                  ? 3
                  : curCard.cardCode === "final_lf"
                  ? 1
                  : 0
              }
            />
          ) : (
            <Empty />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default Summary;
