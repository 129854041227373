import { Input, message, Modal, Spin } from "antd";
import { User } from "Interface";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

export interface IUserEtermEditor {
  user: User;
  unitId: number;
  refetchUser: () => void;
  /**
   * 用户编辑航线和eterm权限 0 = eterm, 1 = 航线
   */
  type: 0 | 1;
}

/**
 *  Component description
 */
const UserEtermEditor = (props: IUserEtermEditor): ReactElement => {
  const { user, refetchUser, unitId, type } = props;
  const [visible, setVisible] = useState<boolean>(false);
  /**
   * 如果value是空, 保存时会清空已经绑定的eterm账号
   */
  const [value, setValue] = useState<string>("");
  const [{ data, isLoading }, doFetch] = useFetch({
    url: "queryEtermOrRouteConfig",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      userId: user.id,
      groupId: unitId,
      type,
    },
    lazey: true,
  });
  const [{ isLoading: upLoading }, doSubmit] = useFetch({
    url: "setEtermOrRouteConfig",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    lazey: true,
    onSuccess: useCallback(() => {
      message.success(getSharkText("config_page_bind_success"));
      setVisible(false);
      refetchUser();
    }, [refetchUser]),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_bind_fail"));
    }, []),
  });

  const showModal = useCallback(() => {
    doFetch({
      ext: {
        userId: user.id,
        groupId: unitId,
        type,
      },
    });
    setVisible(true);
  }, [doFetch, type, unitId, user.id]);

  useEffect(() => {
    if (data && data.etermAccount) {
      setValue(data.etermAccount);
    }
  }, [data]);

  const handleClick = useCallback(() => {
    doSubmit({
      ext: {
        config: {
          ...data,
          userId: user.id,
          etermAccount: value,
        },
        type,
      },
    });
  }, [data, doSubmit, type, user.id, value]);

  return (
    <Spin spinning={isLoading}>
      <a onClick={showModal}>{getSharkText("config_page_bind_eterm")}</a>
      <Modal
        open={visible}
        title={getSharkText("config_page_bind_eterm_account")}
        onCancel={() => setVisible(false)}
        onOk={handleClick}
      >
        <Spin spinning={upLoading}>
          <span>{getSharkText("config_page_eterm_account")}：</span>
          <Input
            style={{ width: 200 }}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          ></Input>
        </Spin>
      </Modal>
    </Spin>
  );
};
export default UserEtermEditor;
