import React, { useState } from "react";
import { Row, Col } from "antd";
import useGlobal from "Store";
import Page from "Layout/Page";
import FileTabList from "./Components/FileTabList";
import Toolbar from "./Components/Toolbar";
import UploadModal from "./Components/UploadModal";
import {
  MODULE_CODE,
  CHART_TABLE_CODE,
  QUERY_URL,
  FileType,
  DEFAULT_FILE_TYPE,
} from "./fetchCode";

const FileSharing: React.FC = () => {
  const [keyword, setKeyword] = useState("");
  const [fileType, setFileType] = useState<FileType>(DEFAULT_FILE_TYPE);
  const [uploadModalOpened, setUploadModalOpened] = useState<boolean>(false);
  const [refreshTable, setRefreshTable] = useState<boolean>(false);
  const [globalState] = useGlobal();
  const { systemType } = globalState;

  const onUploadSuccess = () => {
    setRefreshTable(true);
  };

  return (
    <Page defaultDateMode="none" needToolBar={false}>
      <div className="content-white">
        <Row gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}>
          <Col lg={24} md={24} xs={24}>
            <Toolbar
              setKeyword={setKeyword}
              showUploadModal={setUploadModalOpened}
            />
          </Col>
        </Row>
      </div>
      <div className="content-white">
        <Row style={{ marginTop: "16px" }}>
          <Col lg={24} md={24} xs={24}>
            <FileTabList
              queryUrl={QUERY_URL[systemType].listFileType}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].table}
              keyWord={keyword}
              setFileType={setFileType}
              refreshTable={refreshTable}
              setRefreshTable={setRefreshTable}
            />
            <UploadModal
              queryUrl={QUERY_URL[systemType].uploadFile}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].table}
              fileType={fileType}
              visible={uploadModalOpened}
              setVisible={setUploadModalOpened}
              onUploadSuccess={onUploadSuccess}
            />
          </Col>
        </Row>
      </div>
    </Page>
  );
};

export default FileSharing;
