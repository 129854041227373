import React, { ReactElement, useState } from "react";
import { Card, Col, Row } from "antd";
import useGlobal from "Store";
import Cards from "Components/Card/Cards";
import PieCharts from "Components/PieCharts";
import CoreOverviewBarLineCharts from "./Components/CoreOverviewBarLineCharts";
import Ranking from "Components/Ranking";
import AirlinesRanking from "Components/AirlinesRanking";
import Page from "Layout/Page";
import UpdateTime from "Components/UpdateTime";
import { getModule, getModuleNameFromPath, isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { MARKET_COMPARISO_CARDS } from "Constants";
import "./index.css";
import { ECompareTypeV2 } from "Interface";
import { useLocation } from "react-router-dom";
import { CardsConfig } from "./Components/CoreOverviewBarLineCharts/CardConfig";

const CoreOverview = (): ReactElement => {
  const [globalState] = useGlobal();
  const { queryCondition, userInfo, systemType, airlinesQueryCondition } =
    globalState;
  const [hasCardData, setHasCardData] = useState(false);
  const [curCard, setCurCard] = useState({
    cardCode: "",
    cardName: "",
    cardHint: "",
    cardValue: 0,
    avgValue: 0,
    compareValue: 0,
  });
  const isAirport = isAirportMode(systemType);
  const location = useLocation();
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const IsDemo = module && module.moduleStatus === 0;
  const flightClass = isAirport
    ? queryCondition.flightClass
    : airlinesQueryCondition.flightClass;
  const isAirlinesMarketComparison =
    systemType === 2 && airlinesQueryCondition.compareType === 1;

  const title = (
    <div className="mode-card-title">
      {!IsDemo ? (
        <div>
          {isAirport ? (
            queryCondition.airportName
          ) : (
            <>
              <span>{airlinesQueryCondition.airlinesName}</span>|
              <span>{airlinesQueryCondition.airlines}</span>
            </>
          )}
          &nbsp;&nbsp;
        </div>
      ) : undefined}
      <UpdateTime
        isAirport={isAirport}
        moduleCode={MODULE_CODE[systemType]}
        chartTableCode={CHART_TABLE_CODE[systemType].cards}
        text={getSharkText("core_overview.ranking_title.alert")}
      />
    </div>
  );

  const rankTabAirport = curCard.cardCode
    ? [
        {
          chartTableCode: "card_top10_city",
          title: getSharkText(`card.${curCard.cardCode}.name`),
        },
        {
          chartTableCode: "card_top10_inc",
          title: getSharkText("key.yoy_increase.name"),
          compare: true,
        },
      ]
    : [];

  const rankeTabAirlines = curCard.cardCode
    ? [
        {
          title: getSharkText(`card.${curCard.cardCode}.name`),
          compare: false,
        },
        {
          title: isAirlinesMarketComparison
            ? MARKET_COMPARISO_CARDS.includes(curCard.cardCode)
              ? getSharkText("key.market_share.name")
              : getSharkText("key.compare_to_market")
            : getSharkText("key.yoy_increase.name"),
          compare: true,
        },
      ]
    : [];

  return (
    <Page
      defaultDateMode="lastMonth"
      airlinesCompareType={ECompareTypeV2.MARKET_COMPARE}
    >
      <div className="content">
        <Row style={{ marginBottom: 16 }}>
          <Col xs={24}>
            <Card bordered={false} title={title} className="ai_cards">
              <Cards
                queryUrl={QUERY_URL[systemType].cards}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].cards}
                curCardCode={curCard.cardCode}
                setCurCard={setCurCard}
                setHasCardData={setHasCardData}
                cardGroup={[
                  ["passenger_traffic", "kilo_passenger_traffic"],
                  ["transport_capacity", "kilo_transport_capacity"],
                  ["capacity_production_cost", "income_production_cost"],
                ]}
                config={CardsConfig}
              />
            </Card>
          </Col>
        </Row>
        {hasCardData ? (
          <Row gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}>
            <Col lg={17} md={12} xs={24}>
              <CoreOverviewBarLineCharts
                queryUrl={QUERY_URL[systemType].barLineCharts}
                queryDownloadUrl={
                  systemType === 1 ? "" : QUERY_URL[systemType].barLineDownload
                }
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].barLineCharts}
                cardCode={curCard.cardCode}
              />
            </Col>
            <Col lg={7} md={12} xs={24}>
              {flightClass === 0 ? (
                <PieCharts
                  queryUrl={QUERY_URL[systemType].pieCharts}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode={CHART_TABLE_CODE[systemType].pieCharts}
                  cardCode={curCard.cardCode}
                  height={484}
                />
              ) : isAirport ? (
                <Ranking
                  title={getSharkText("core_overview.ranking_title.airport")}
                  queryUrl={QUERY_URL[systemType].top10City}
                  moduleCode={MODULE_CODE[systemType]}
                  tab={rankTabAirport}
                  sortable
                  cardCode={curCard.cardCode}
                />
              ) : (
                <AirlinesRanking
                  title={getSharkText("core_overview.ranking_title.airline")}
                  queryUrl={QUERY_URL[systemType].top10City}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode="AL0104"
                  tab={rankeTabAirlines}
                  sortable
                  cardCode={curCard.cardCode}
                />
              )}
            </Col>
          </Row>
        ) : undefined}
      </div>
    </Page>
  );
};

export default CoreOverview;
