import React from "react";
import { IDownloadHeaderPro } from "Interface";
import { Input, Popover, Select } from "antd";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import { FlightInfoDetailWithKey } from "../../interface";
import {
  AHEAD_DAY,
  ARR_AIRPORTNAME,
  CANCEL_RATE,
  CQ_CROWD_DEGREE_FINAL_PRED_50,
  D,
  DEP_AIRPORTNAME,
  FLIGHTNO,
  IS_SINGLEFLIGHT,
  PRICE_DIFF,
  PROB,
  SEATS,
  SUBSIDY_AMOUNT_FIXED,
  SUBSIDY_NUM_FIXED,
  SUBSIDY_PRICE,
  TAKEOFFDATE,
  TAKEOFFTIME,
  UDF_CONFIG,
  UDF_PRICE,
  UDF_SUBSIDY_LOCAL,
  UDF_SUBSIDY_NON_LOCAL,
} from "./cols";
import { isNumber, round } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";

export const getSubSidyNum = (v: number): number => {
  if (v < 1) {
    return Math.ceil(v);
  } else {
    return round(v);
  }
};

const options = [
  {
    label: "补贴中转",
    value: "补贴中转",
  },
  {
    label: "补贴航司",
    value: "补贴航司",
  },
  {
    label: "其他",
    value: "其他",
  },
  {
    label: "不干预",
    value: "不干预",
  },
];

export const getColumns = (
  changeConfig: (v: string, r: FlightInfoDetailWithKey) => void,
  changeAirportSubsidyNonLocal: (v: string, r: FlightInfoDetailWithKey) => void,
  changeAirportSubsidyLocal: (v: string, r: FlightInfoDetailWithKey) => void
): Array<IDownloadHeaderPro<FlightInfoDetailWithKey>> => {
  const rst: Array<IDownloadHeaderPro<FlightInfoDetailWithKey>> = [
    {
      title: "预测日期",
      dataIndex: D,
      render: (v: any) => {
        return moment(v).add(1, "d").format(DATE_FORMAT);
      },
    },
    {
      title: getSharkText("charts.date_of_departure"),
      dataIndex: TAKEOFFDATE,
      width: 185,
      sorter: (a, b) => {
        return a[TAKEOFFDATE].localeCompare(b[TAKEOFFDATE]);
      },
    },
    {
      title: "航班",
      dataIndex: FLIGHTNO,
      width: 95,
    },
    {
      title: "出发机场",
      dataIndex: DEP_AIRPORTNAME,
      width: 150,
      render: (v, r: FlightInfoDetailWithKey) => {
        return `${r.dcityname}/${v}(${r.dport})`;
      },
    },
    {
      title: "到达机场",
      dataIndex: ARR_AIRPORTNAME,
      width: 150,
      render: (v, r: FlightInfoDetailWithKey) => {
        return `${r.acityname}/${v}(${r.aport})`;
      },
    },
    {
      title: "提前天数",
      dataIndex: AHEAD_DAY,
      width: 50,
    },
    {
      title: "计划起飞时刻",
      dataIndex: TAKEOFFTIME,
      width: 100,
      render: (v: any) => {
        return v.substring(11, 16);
      },
    },
    {
      title: "是否独飞",
      dataIndex: IS_SINGLEFLIGHT,
      width: 50,
      render: (v: any) => {
        return v === "0" ? "否" : v === "1" ? "是" : "-";
      },
    },
    {
      title: "供需",
      children: [
        {
          title: "座位数",
          dataIndex: `AVG_${SEATS}`,
        },
        {
          title: "预测客座率",
          dataIndex: `AVG_${CQ_CROWD_DEGREE_FINAL_PRED_50}`,
          render: (v: any) => {
            return showNum(v * 100, "percentage");
          },
        },
      ],
    },
    {
      title: "取消关联指标",
      children: [
        {
          title: (
            <span>
              历史取消率
              <Popover content="过去30天该航班的实际取消次数/过去30天该航班的计划起飞次数。">
                <InfoCircleOutlined style={{ marginLeft: 3 }} />
              </Popover>
            </span>
          ),
          dataIndex: `AVG_${CANCEL_RATE}`,
          width: 110,
          render: (v: any) => {
            return showNum(v * 100, "percentage");
          },
        },
        {
          title: "预测取消概率",
          dataIndex: `AVG_${PROB}`,
          width: 110,
          render: (v: any) => {
            return showNum(v * 100, "percentage");
          },
        },
      ],
    },
    {
      title: "价格",
      children: [
        {
          title: "航班展示价",
          dataIndex: `p_exp_${UDF_PRICE}`,
          width: 120,
        },
        {
          title: (
            <span>
              与竞对价差
              <Popover content="选取同一时间片下（即竞对航司航班起飞时间距离预测取消航班起飞时间差最短的航班），1个竞对航班价格，若满足条件的有多个航班则选择价格最低的竞对航班。">
                <InfoCircleOutlined style={{ marginLeft: 3 }} />
              </Popover>
            </span>
          ),
          dataIndex: `AVG_${PRICE_DIFF}`,
          width: 120,
          render: (v, r: FlightInfoDetailWithKey) => {
            if (!isNumber(r.p_exp_udf_compete_price)) {
              return "-";
            }
            return r.p_exp_udf_compete_price || 0;
          },
        },
      ],
    },
    {
      title: "补贴潜力",
      children: [
        {
          title: "补贴均价",
          width: 80,
          dataIndex: `AVG_${SUBSIDY_PRICE}`,
          render: (val: any) => showNum(val, "num", 1),
        },
        {
          title: "补贴人数",
          width: 80,
          dataIndex: `AVG_${SUBSIDY_NUM_FIXED}`,
          render: (v) => Math.ceil(v as number),
        },
        {
          title: "补贴金额",
          width: 80,
          dataIndex: `AVG_${SUBSIDY_AMOUNT_FIXED}`,
          render: (val: any) => showNum(val, "num", 1),
        },
      ],
    },
    {
      title: "计划措施",
      dataIndex: `p_exp_${UDF_CONFIG}`,
      width: 80,
      render: (val: any, r: FlightInfoDetailWithKey) => {
        return (
          <Select
            options={options}
            defaultValue={val}
            onChange={(v) => changeConfig(v, r)}
            size="small"
            style={{ minWidth: 80 }}
            // disabled={r.d !== moment().add(-1, "d").format(DATE_FORMAT)}
          ></Select>
        );
      },
    },
    {
      title: "非本机场补贴信息",
      dataIndex: `p_exp_${UDF_SUBSIDY_NON_LOCAL}`,
      width: 100,
      render: (val: any, r: FlightInfoDetailWithKey) => {
        return (
          <Input
            defaultValue={val}
            onChange={(e) => changeAirportSubsidyNonLocal(e.target.value, r)}
            size="small"
            style={{ minWidth: 80 }}
          ></Input>
        );
      },
    },
    {
      title: "本机场补贴信息",
      dataIndex: `p_exp_${UDF_SUBSIDY_LOCAL}`,
      width: 100,
      render: (val: any, r: FlightInfoDetailWithKey) => {
        return (
          <Input
            defaultValue={val}
            onChange={(e) => changeAirportSubsidyLocal(e.target.value, r)}
            size="small"
            style={{ minWidth: 80 }}
          ></Input>
        );
      },
    },
  ];
  rst.forEach((r) => {
    r.width = undefined;
    if (r.children) {
      r.children.forEach((c) => {
        c.width = undefined;
      });
    }
  });
  return rst;
};
