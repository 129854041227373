import React, { ReactElement, useState } from "react";
import SearchArea from "./SearchArea";
import BehindChart from "./BehindChart";
import BehindTable from "./BehindTable";
import { BehindParam } from "./interface";
import { useDataset } from "Page/AI/FreeDashboard/useDataset";
import { DATASET_ID } from "./behindCols";

export interface BehindProps {
  example?: string;
}

/**  Component description */
const Behind = (props: BehindProps): ReactElement => {
  const { example } = props;
  const [param, setParam] = useState<BehindParam>();

  const { isLoading, datasetCols } = useDataset(DATASET_ID);
  return (
    <div>
      <SearchArea onQuery={setParam} />
      {param ? (
        <>
          <div>
            <h3 style={{ marginTop: 10 }}>TOP 10 Origin</h3>
            <div style={{ border: "1px solid var(--filter-bg-border)" }}>
              <BehindChart param={param} cols={datasetCols} />
            </div>
            <div style={{ marginTop: 25 }}>
              <BehindTable param={param} cols={datasetCols} />
            </div>
          </div>
        </>
      ) : undefined}
    </div>
  );
};
export default Behind;
