import { DelayLink } from "Components/Nav/DelayLink";
import { Module } from "Interface";
import useGlobalState from "Store";
import { genMenuTreeByGlobal, getModuleName } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { Breadcrumb, Tag } from "antd";
import React, { ReactElement, ReactNode } from "react";
import { useLocation } from "react-router";

const findPaths = (
  tree: Module[],
  url: string,
  path = [] as Module[]
): Module[] => {
  // 遍历树的每个节点
  for (const node of tree) {
    // 如果当前节点的id匹配指定id，则将当前节点的name添加到路径中
    if (node.menuUrl === url) {
      path.push(node);
      return path;
    }
    // 如果当前节点有子节点，则递归查找子节点
    if (node.children && node.children.length > 0) {
      const subPath: Module[] = findPaths(node.children, url, [...path, node]);
      if (subPath.length > 0) {
        return subPath;
      }
    }
  }
  return [];
};

/** 面包屑导航 */
const BreadCom = (): ReactElement => {
  const [globalState] = useGlobalState();
  const menuTree = genMenuTreeByGlobal(globalState);
  const location = useLocation();
  const paths = findPaths(menuTree, location.pathname);

  const renderLinkItem = useRefFunc((item: Module) => {
    return (
      <DelayLink to={item.menuUrl} key={item.menuUrl}>
        <span className="nav-page-name">{getModuleName(item)}</span>
        {item.newModule === 1 ? (
          <Tag color="#f5222d" style={{ marginLeft: 15, lineHeight: "19px" }}>
            new
          </Tag>
        ) : undefined}
      </DelayLink>
    );
  });

  const moduleToMenuItem = useRefFunc((modules: Module[]) => {
    return modules.map((module) => {
      const newItem: { key: string; label: ReactNode; [key: string]: any } = {
        ...module,
        key: module.moduleCode,
        label: renderLinkItem(module),
        children: undefined,
        popupClassName: "nav-menu-popup",
        // label: module.moduleName,
      };
      if (module.children.length) {
        newItem.children = moduleToMenuItem(module.children);
      }
      return newItem;
    });
  });
  return paths.length ? (
    <Breadcrumb>
      {paths.map((p) => (
        <Breadcrumb.Item
          key={p.menuUrl}
          {...(p.children.length
            ? {
                menu: {
                  items: p.children.length
                    ? moduleToMenuItem(p.children)
                    : undefined,
                },
              }
            : undefined)}
        >
          {getModuleName(p)}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  ) : (
    <></>
  );
};
export default BreadCom;
