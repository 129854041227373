import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Col, Empty, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import BarCharts from "Components/BarLineCharts/charts";
import { AggCode, AirlinesQueryExt, Area, ECompareTypeV2 } from "Interface";
import useGlobal from "Store";
import {
  calculateCardsContrastVal,
  fillCharts,
  getBetweenDateArr,
  getModule,
  getModuleNameFromPath,
  isAirportMode,
  openDownloadDialog,
  showRawNum,
  useFetch,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import moment from "moment";
import {
  COMPARE_TYPE_PER_NAME,
  COMPARE_TYPE_VALUE_NAME,
  PERCENT_VALUES,
} from "Constants";
import Refetch from "Components/Refetch";
import "Components/BarLineCharts/index.css";
import { getServer } from "Service/server";
import DownloadBtn from "Components/DownloadBtn";
import { useLocation } from "react-router-dom";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  queryDownloadUrl: string;
  cardCode?: string;
  area?: Area;
  departArea?: Area;
  arriveArea?: Area;
  airlinesQueryExt?: AirlinesQueryExt;
  type?: number;
  height?: number;
  style?: object;
  handleDownload?: () => void;
}

const CoreOverviewBarLineCharts: React.FC<BarLineChartsProps> = (
  props: BarLineChartsProps
) => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    queryDownloadUrl,
    cardCode,
    area,
    height = 452,
    style,
    type,
    departArea,
    arriveArea,
  } = props;

  const location = useLocation();

  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [barData, setBarData] = useState<any[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition, userInfo } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const startDate = isAirport
    ? queryCondition.startDate
    : airlinesQueryCondition.startDate;
  const endDate = isAirport
    ? queryCondition.endDate
    : airlinesQueryCondition.endDate;
  const compareType = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const getExt = useCallback(
    (code: string) => {
      switch (code) {
        case "search_index_airport":
          return { aggCode, departArea, arriveArea, type };
        case "search_index_airlines":
          return { aggCode, departArea, arriveArea, type };
        default:
          return { aggCode, cardCode };
      }
    },
    [aggCode, departArea, arriveArea, type, cardCode]
  );
  const ext = useMemo(() => getExt(moduleCode), [moduleCode, getExt]);
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const IsDemo = !!(module && module.moduleStatus === 0);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const [{ data: dataAll }, doFetch1] = useFetch({
    server: getServer(systemType),
    url: queryDownloadUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;
  const precision =
    cardCode &&
    ["capacity_production_cost", "income_production_cost"].includes(cardCode)
      ? 3
      : 0;
  const refetch = useCallback(() => {
    doFetch({
      query,
      ext: { ...ext },
    });
  }, [doFetch, ext, query]);
  const refetch1 = useCallback(() => {
    if (queryDownloadUrl) {
      doFetch1({
        query,
        ext: { ...ext },
      });
    }
  }, [doFetch1, ext, query, queryDownloadUrl]);
  // 计算同比/市占
  const calculateCompare = (value1: number, value2: number) => {
    if (query.compareType === 1) {
      return showRawNum((value1 / value2) * 100, "percentage");
    } else {
      return showRawNum((value1 / value2 - 1) * 100, "percentage");
    }
  };

  // 转换一条记录
  const convertRecord = (
    obj: any,
    record: any,
    title1: any,
    titlePrefixCompare: any,
    titleSuffixCompare: any,
    isSum: boolean
  ) => {
    obj[title1] = !isSum
      ? moment(record.day).format("YYYY-MM-DD")
      : getSharkText("config_page_total");
    obj[getSharkText("config_page_passenger_traffic_estimate")] = showRawNum(
      record.passengerTraffic
    );
    obj[
      titlePrefixCompare +
        getSharkText("config_page_PaAiCoCoCoIn_passenger_traffic_estimate")
    ] = showRawNum(record.comparePassengerTraffic);
    obj["客运量" + titleSuffixCompare] = calculateCompare(
      record.passengerTraffic,
      record.comparePassengerTraffic
    );
    obj[getSharkText("key.available_seats_estimated")] = showRawNum(
      record.transportCapacity
    );
    obj[titlePrefixCompare + "座位数"] = showRawNum(
      record.compareTransportCapacity
    );
    obj["座位数" + titleSuffixCompare] = calculateCompare(
      record.transportCapacity,
      record.compareTransportCapacity
    );
    obj.航班架次 = showRawNum(record.flightSorties);
    obj[titlePrefixCompare + getSharkText("key.number_of_flights")] =
      showRawNum(record.compareFlightSorties);
    obj[getSharkText("key.number_of_flights") + titleSuffixCompare] =
      calculateCompare(record.flightSorties, record.compareFlightSorties);
    obj.客座率 = showRawNum(
      (record.passengerTraffic / record.transportCapacity) * 100,
      "percentage"
    );
    obj[titlePrefixCompare + getSharkText("key.loadfactor")] = showRawNum(
      (record.comparePassengerTraffic / record.compareTransportCapacity) * 100,
      "percentage"
    );
    obj.单机载客数 = showRawNum(record.passengerTraffic / record.flightSorties);
    obj[titlePrefixCompare + getSharkText("key.passenger_traffic_per_flight")] =
      showRawNum(record.comparePassengerTraffic / record.compareFlightSorties);
    obj[getSharkText("key.proportion_wide_body")] = showRawNum(
      (record.wideNum / record.flightSorties) * 100,
      "percentage"
    );
    obj[titlePrefixCompare + getSharkText("key.proportion_wide_body")] =
      showRawNum(
        (record.compareWideNum / record.compareFlightSorties) * 100,
        "percentage"
      );
    obj[getSharkText("key.capacity.roi")] = record.capacityProductionCost;
    obj[titlePrefixCompare + getSharkText("key.capacity.roi")] =
      record.compareCapacityProductionCost;
    obj[getSharkText("key.revenue.roi")] = record.incomeProductionCost;
    obj[titlePrefixCompare + getSharkText("key.revenue.roi")] =
      record.compareIncomeProductionCost;
  };

  const handleDownload = () => {
    // 列名
    let title1 = "";
    switch (aggCode) {
      case 0:
        title1 = getSharkText("config_page_takeoff_date_day");
        break;
      case 1:
        title1 = getSharkText("config_page_takeoff_date_week");
        break;
      case 2:
        title1 = getSharkText("config_page_takeoff_date_month");
        break;
    }
    let titlePrefixCompare = "";
    let titleSuffixCompare = "";
    switch (query.compareType) {
      case ECompareTypeV2.MARKET_COMPARE:
        titlePrefixCompare = getSharkText("charts.market.seriesvaluename");
        titleSuffixCompare = getSharkText("key.market_share.name");
        break;
      default:
        titlePrefixCompare = COMPARE_TYPE_VALUE_NAME[query.compareType];
        titleSuffixCompare = COMPARE_TYPE_PER_NAME[query.compareType];
        break;
    }
    const sumRecord = {
      passengerTraffic: 0,
      comparePassengerTraffic: 0,
      transportCapacity: 0,
      compareTransportCapacity: 0,
      flightSorties: 0,
      compareFlightSorties: 0,
      wideNum: 0,
      compareWideNum: 0,
    };
    // 排序逐行处理
    const sheetData: any = [];
    const sortDataAll = dataAll.sort((a: any, b: any) =>
      moment(a.day).isBefore(moment(b.day)) ? -1 : 1
    );
    sortDataAll.forEach((record: any) => {
      const obj: any = {};
      convertRecord(
        obj,
        record,
        title1,
        titlePrefixCompare,
        titleSuffixCompare,
        false
      );
      sheetData.push(obj);
      sumRecord.passengerTraffic += record.passengerTraffic;
      sumRecord.comparePassengerTraffic += record.comparePassengerTraffic;
      sumRecord.transportCapacity += record.transportCapacity;
      sumRecord.compareTransportCapacity += record.compareTransportCapacity;
      sumRecord.flightSorties += record.flightSorties;
      sumRecord.compareFlightSorties += record.compareFlightSorties;
      sumRecord.wideNum += record.wideNum;
      sumRecord.compareWideNum += record.compareWideNum;
    });
    // 插入总计
    const sumObj: any = {};
    convertRecord(
      sumObj,
      sumRecord,
      title1,
      titlePrefixCompare,
      titleSuffixCompare,
      true
    );
    sheetData.push(sumObj);
    // excel
    const sheet1 = XLSX.utils.json_to_sheet(sheetData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      sheet1,
      getSharkText("menu_core_overview_airlines")
    );
    const workbookBlob = workbook2blob(wb);
    openDownloadDialog(
      workbookBlob,
      getSharkText("config_page_core_overview_trend_chart") + ".xlsx"
    );
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    refetch1();
  }, [refetch1]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const sortDate = data.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value ? _.round(item.value, precision) : 0;
        item.compareValue = item.compareValue
          ? _.round(item.compareValue, precision)
          : 0;
        const resultValue = _.round(
          calculateCardsContrastVal(
            item.value,
            item.compareValue,
            compareType,
            systemType,
            cardCode
          ),
          precision || 1
        );
        item.resultValue = _.isFinite(resultValue) ? resultValue : 0;
        return item;
      });
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(startDate), moment(endDate), aggCode)
      );
      setBarData(IsDemo ? compareData : fillData);
    } else {
      setBarData([]);
    }
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  return (
    <Card style={style} className="barline-charts">
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={handleChange}
            id="chartsAggCode"
          >
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        {isAirport ? (
          <Col />
        ) : (
          <Col>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        )}
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <BarCharts
          aggCode={aggCode}
          isPercentVal={isPercentVal}
          loading={isLoading}
          systemType={systemType}
          compareType={compareType}
          data={barData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          airlines={airlinesQueryCondition.airlines}
          cardCode={cardCode}
          isDemo={IsDemo}
        />
      )}
    </Card>
  );
};

export default CoreOverviewBarLineCharts;
