import { getSharkText } from "Utils/i18nGlobal";

export const BAR_CODE = ["transit_minutes", "transit_price"];
export const NAME_MAP_TIME = {
  class1_cnt: "0h~2h",
  class2_cnt: "2h~4h",
  class3_cnt: "4h~6h",
  class4_cnt: "6h~8h",
  class5_cnt: "8h~12h",
  class6_cnt: "12h~16h",
  class7_cnt: "16h~24h",
};
export const NAME_MAP_PRICE = {
  class1_cnt: "0~400",
  class2_cnt: "400~800",
  class3_cnt: "800~1200",
  class4_cnt: "1200~1600",
  class5_cnt: "1600~1800",
  class6_cnt: "1800~2000",
  class7_cnt: getSharkText("config_page_2000_and_above"),
};
