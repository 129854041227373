import { useEffect, useReducer, useCallback, useRef } from "react";
import { SERVER } from "Constants";
import {
  AirlinesQueryCondition,
  Login,
  QueryCondition,
  ResponseStatusType,
} from "Interface";
import { IFetchOut } from "./useFetchInterface";
import useRefFunc from "./useRefFunc";
import { PartnerCondition } from "@ctrip/flt-bi-flightai-partners";
import fetchBase, { Params } from "./fetchBase";
import { getCookieToken, getCookieUID } from "./cookie";
import useGlobalState from "Store";
import ubtUtils from "./ubtUtils";

export interface IResponseCommon<T> {
  ResponseStatus: ResponseStatusType;
  data: T;
  [key: string]: any;
}

export interface FetchProps<T = any> {
  server?: string;
  url: string;
  head: Record<string, unknown>;
  ext?: Record<string, unknown>;
  defaultValue?: any;
  query?: QueryCondition | AirlinesQueryCondition | PartnerCondition | null;
  filter?: Record<string, unknown> | null;
  lazey?: boolean;
  onSuccess?: (res: IResponseCommon<T>, bodyObj?: any) => void;
  onError?: (err?: any, bodyObj?: any) => void;
  onFinish?: () => void;
  /**
   * 调试ID, 因为useEffect会导致代码上下文无法关联, 使用这个ID在调试阶段将逻辑串联起来
   */
  debugId?: string;
  /**
   * 请求新数据时是否清空上一次的数据, 如果为是, 则清空为defaultValue
   */
  clearLastData?: boolean;
  /**
   * 是否启用缓存, 查询条件完全相同时, 使用缓存结果.
   */
  useCache?: boolean;
  /**
   * 生成cacheKey的方法
   */
  generateCacheKey?: (url: string, bodyObj: any) => string;
  /** 是否单例, 单例模式下, hook每次查询都会取消上一次该hook的查询, 默认为true */
  isSingleInstance?: boolean;
}

export interface Parameter {
  server?: string;
  url?: string;
  head?: Record<string, unknown>;
  ext?: Record<string, unknown>;
  query?: QueryCondition | AirlinesQueryCondition | PartnerCondition | null;
  /** 是否单例, 单例模式下, hook每次查询都会取消上一次该hook的查询, 默认为true */
  isSingleInstance?: boolean;
  /** 是否使用缓存, 默认使用hook初始化的缓存设置 */
  useCache?: boolean;
  debugId?: string;
}
// 熔断机制, 防止某些bug无限循环刷新将页面卡死
// 秒
const time = 10;
// 超过300次;
const maxCount = 300;
let count = 0;

setInterval(() => {
  count = 0;
}, time * 1000);
/**
 * 因为原useFetch和useFetchV2有一个问题: 内部封装函数调用, 会有两个缺陷
 * 1. 无法同步调用, 一定需要等到useEffect触发才去查询
 * 2. 调用链断裂, 发起查询的点为useEffect中的函数, 导致业务逻辑和请求无法上下文关联, 调试困难
 * 因为就的useFetch被大量模块引用, 需要逐步修改, 因为新模块使用useFetchV2, 旧模块在更新时逐个替换
 * @param props 查询参数
 * @returns 查询结果和查询handle
 */
export const useFetch = <T = any>(props: FetchProps<T>): IFetchOut<T> => {
  const {
    server = SERVER,
    url,
    head,
    ext,
    defaultValue = null,
    query = null,
    lazey = false,
    onSuccess,
    onError,
    onFinish,
    debugId,
    clearLastData = true,
    useCache = false,
    generateCacheKey,
    isSingleInstance = true,
  } = props;
  const abortCtrlRef = useRef<any>();
  const lazeyRef = useRef<boolean>(lazey);
  let uid = getCookieUID();
  let token = getCookieToken();
  let login: Login | null = null;
  try {
    const [, actions] = useGlobalState();
    login = actions.getLogin();
  } catch (e) {
    console.log("get global state error");
  }
  if (!uid || !token) {
    uid = login?.uid || null;
    token = login?.token || null;
    if (!uid || !token) {
      console.error("get token error");
      window.location.href = "/login";
    }
  }

  const dataFetchReducer = useCallback(
    (v: any, action: any) => {
      switch (action.type) {
        case "FETCH_INIT":
          return {
            ...v,
            isLoading: true,
            error: null,
            ...(clearLastData ? { data: defaultValue, res: null } : undefined),
          };
        case "FETCH_SUCCESS":
          return {
            ...v,
            isLoading: false,
            error: null,
            data: action.payload,
            res: action.res,
          };
        case "FETCH_FAILURE":
          return {
            ...v,
            isLoading: false,
            error: action.payload,
            res: action.res,
          };
        case "FETCH_ABORT":
          return {
            ...v,
            isLoading: false,
            error: null,
          };
        default:
          throw new Error();
      }
    },
    [clearLastData, defaultValue]
  );

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    error: null,
    data: defaultValue,
    res: null,
  });

  const clearFetch = useRefFunc((initValue?: T) => {
    dispatch({
      type: "FETCH_SUCCESS",
      payload: initValue ?? defaultValue,
      res: { data: initValue ?? defaultValue },
    });
    if (abortCtrlRef.current && isSingleInstance) {
      abortCtrlRef.current.abort();
      abortCtrlRef.current = null;
    }
  });

  const onSuccessFn = useRef(
    (r: any, bodyObj: any) => onSuccess && onSuccess(r, bodyObj)
  );
  const onErrorFn = useRef(
    (r: any, bodyObj: any) => onError && onError(r, bodyObj)
  );
  const onFinishFn = useRefFunc(() => onFinish && onFinish());

  /**
   * 更新onSuccessFn和onErrorFn, 不会触发re-render
   */
  useEffect(() => {
    onSuccessFn.current = (r: any, bodyObj) =>
      onSuccess && onSuccess(r, bodyObj);
  }, [onSuccess]);
  useEffect(() => {
    onErrorFn.current = (r: any, bodyObj) => onError && onError(r, bodyObj);
  }, [onError]);

  useEffect(() => {
    const abortInstance = abortCtrlRef.current;
    return () => {
      if (abortInstance && isSingleInstance) {
        abortInstance.abort();
        abortCtrlRef.current = null;
      }
    };
  }, [isSingleInstance]);

  const doFetch = useRefFunc(
    async (parameter: Parameter): Promise<IResponseCommon<T> | undefined> => {
      // 熔断
      count++;
      if (count >= maxCount) {
        console.warn("request break down !!!!!");
        throw new Error("break down");
      }
      if (
        abortCtrlRef.current &&
        (isSingleInstance || parameter.isSingleInstance)
      ) {
        // 需要注意abort是异步的, 必须等abort完成再调用, 否则会开启新的请求后, 在abort旧的请求, 导致loading状态异常
        dispatch({ type: "FETCH_ABORT" });
        const ctrl = abortCtrlRef.current;
        abortCtrlRef.current = null;
        await ctrl.abort();
      }

      const fetchParam: Params = {
        uid,
        token,
        abortCtrlRef,
        head: parameter.head || head,
        query: parameter.query || query,
        ext: parameter.ext || ext,
        url: parameter.url || url,
        server: server || SERVER,
        debugId: parameter.debugId || debugId,
        useCache: parameter.useCache ?? useCache,
        generateCacheKey,
        onSuccess: (r: any, bodyObj) => {
          dispatch({
            type: "FETCH_SUCCESS",
            payload: r.data,
            res: r,
          });
          onSuccessFn.current(r, bodyObj);
          let recordCnt = 0;
          Object.keys(r).forEach((key) => {
            if (Array.isArray(r[key])) {
              recordCnt += r[key].length;
            }
          });
          if (url === "mytrixQuery") {
            const res = JSON.parse(r.data);
            if (res.rows) {
              recordCnt = res.rows.length;
            }
          }
          if (recordCnt) {
            ubtUtils.info("responseRecordCnt", {
              url: parameter.url || url,
              requestBody: bodyObj,
              recordCnt,
            });
          }
        },
        onError: (err, bodyObj, res) => {
          dispatch({
            type: "FETCH_FAILURE",
            payload: err,
            res,
          });
          onErrorFn.current(err, bodyObj);
        },
        onAbort: () => {
          // console.log("doFetch abort");
        },
        onFinish: () => {
          onFinishFn();
        },
      };
      dispatch({ type: "FETCH_INIT" });
      try {
        return fetchBase(fetchParam);
      } catch (error: any) {
        throw new Error(error.message);
      }
    }
  );
  useEffect(() => {
    if (!lazeyRef.current) {
      lazeyRef.current = true;
      setTimeout(() => {
        doFetch({});
      }, 0);
    }
  });

  return [state, doFetch, clearFetch];
};
