import { Tooltip } from "antd";
import React, { ReactElement, useState } from "react";
import "./index.scss";
import { getSharkText } from "Utils/i18nGlobal";

export interface IDrawerButtonProps {
  open: boolean;
  setOpen: (v: boolean) => void;
}

/**
 *  Component description
 */
const DrawerButton = (props: IDrawerButtonProps): ReactElement => {
  const { open, setOpen } = props;
  const [text, setText] = useState<string | null>();
  return open ? (
    <div
      onClick={() => {
        setOpen(false);
      }}
    >
      <Tooltip title="收起" placement="right">
        <div
          className="drawer-button"
          style={{
            left: 0,
            right: "auto",
            position: "absolute",
            borderRadius: "0 10px 10px 0",
          }}
        >
          {">>"}
        </div>
      </Tooltip>
    </div>
  ) : (
    <div
      onClick={() => {
        setText(null);
        setOpen(true);
      }}
      onMouseEnter={() => setText(getSharkText("menu_flight_manage_airlines"))}
      onMouseLeave={() => setText(null)}
    >
      <Tooltip title={getSharkText("config_page_expand")} placement="left">
        <div className="drawer-button">
          {"<<"}
          {text}
        </div>
      </Tooltip>
    </div>
  );
};
export default DrawerButton;
