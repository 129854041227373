import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SuggestTrend from "./SuggestTrend";
import LoadTrend from "./LoadTrend";
import { cloneDeep, max, min } from "lodash";
import { IFlightRecord, TView } from "../../FlightManageInterface";
import { getTS, rowToColumn, useFetch } from "Utils";
import * as echarts from "echarts/lib/echarts";
import {
  DynamicPriceHistoryLoad,
  DynamicPriceHistoryPrice,
  DynamicPricePopupFilter,
} from "@ctrip/flt-bi-flightai-airlines";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import {
  calcD,
  fillData,
  IHistoryLoad,
  IHistoryPrice,
  lfTypes,
} from "Page/AI/DynamicPrice/Components/TrendCommon";
import { QUERY_URL } from "Page/AI/DynamicPrice/fetchCode";
import moment from "moment";
import { DATE_FORMAT, EMPTY_ARRAY } from "Constants";

const transData = (source: any[], viewType: TView) => {
  if (viewType === 0) {
    return rowToColumn(
      source,
      ["d", "takeoff"],
      "type",
      undefined,
      (rt: any, col: string) => {
        if (col === "lf") {
          return lfTypes[Number(rt) - 1];
        }
        if (rt === 1) {
          return col;
        }
        if (rt === 2) {
          switch (col) {
            case "price":
              return "priceCom";
            case "day":
              return "dayCom";
          }
        }
        if (rt === 3 && col === "price") {
          return "priceDeal";
        }
        return null;
      }
    );
  } else {
    return rowToColumn(
      source,
      ["d"],
      "type",
      undefined,
      (rt: any, col: string) => {
        if (rt === 1) {
          return col;
        }
        if (rt === 2 && col === "price") {
          return "priceCom";
        }
        if (rt === 2 && col === "lf") {
          return "comLf";
        }
        return null;
      }
    );
  }
};

export interface TrendContentProps {
  record: IFlightRecord;
  compareDate?: string;
  compareFlightNo?: string;
  view: 0 | 1;
}

/** 趋势图 */
const TrendContent = (props: TrendContentProps): ReactElement => {
  const { record, compareDate, compareFlightNo, view } = props;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const [historyChartInstance, setHistoryChartInstance] =
    useState<any>(undefined);
  const [loadChartInstance, setLoadChartInstance] = useState<any>(undefined);
  const takeoffDate = useMemo(() => {
    return moment(record.takeoffdate).format(DATE_FORMAT);
  }, [record.takeoffdate]);

  const filterList: DynamicPricePopupFilter[] = useMemo(() => {
    const base: DynamicPricePopupFilter = {
      flightNo: record.flightNo,
      takeoffDate,
      route: record.route,
      type: 1,
    };
    const rst: DynamicPricePopupFilter[] = [base];
    if (compareDate) {
      const tmp = cloneDeep(base);
      tmp.takeoffDate = compareDate;
      rst.push(tmp);
    }
    if (compareFlightNo) {
      const tmp = cloneDeep(base);
      tmp.flightNo = compareFlightNo;
      tmp.type = 2;
      rst.push(tmp);
    }
    if (compareDate && compareFlightNo) {
      const tmp = cloneDeep(base);
      tmp.takeoffDate = compareDate;
      tmp.flightNo = compareFlightNo;
      tmp.type = 2;
      rst.push(tmp);
    }
    return rst;
  }, [
    record.flightNo,
    record.route,
    takeoffDate,
    compareDate,
    compareFlightNo,
  ]);
  // #region price data

  const [{ data: dataPrice, isLoading: loadingPrice }, doFetchPrice] = useFetch<
    DynamicPriceHistoryPrice[]
  >({
    server: getServer(systemType),
    url: QUERY_URL.popupPrice,
    defaultValue: [],
    head: {
      moduleCode: "dynamic_price_airlines",
      chartTableCode: "AL0801",
    },
    query: airlinesQueryCondition,
    ext: {
      filterList,
      view,
    },
  });

  const refetchPrice = useCallback(() => {
    doFetchPrice({
      query: airlinesQueryCondition,
      ext: {
        filterList,
        view,
      },
    });
  }, [airlinesQueryCondition, doFetchPrice, filterList, view]);

  useEffect(() => {
    refetchPrice();
  }, [refetchPrice]);
  // #endregion

  // #region lf data
  const [{ data: dataLf, isLoading: loadingLf }, doFetch] = useFetch<
    DynamicPriceHistoryLoad[]
  >({
    server: getServer(systemType),
    url: QUERY_URL.popupLf,
    defaultValue: [],
    head: {
      moduleCode: "dynamic_price_airlines",
      chartTableCode: "AL0801",
    },
    query: airlinesQueryCondition,
    ext: {
      filterList,
      view,
    },
  });

  const refetchLf = useCallback(() => {
    if (filterList.length) {
      doFetch({
        query: airlinesQueryCondition,
        ext: { filterList, view },
      });
    }
  }, [airlinesQueryCondition, doFetch, filterList, view]);

  useEffect(() => {
    refetchLf();
  }, [refetchLf]);
  // #endregion

  useEffect(() => {
    if (historyChartInstance && loadChartInstance) {
      echarts.connect([historyChartInstance, loadChartInstance]);
    }
  }, [historyChartInstance, loadChartInstance]);

  // #region Price 数据格式转换
  const priceMerged = useMemo(() => {
    if (loadingPrice) {
      return EMPTY_ARRAY;
    }
    if (!dataPrice || !dataPrice.length) {
      return EMPTY_ARRAY;
    }
    // 第一步, 转换对比数据
    dataPrice.forEach((d: any) => {
      d.d = calcD(d.diffTime, view === 0 ? takeoffDate : d.day, view);
    });
    let dataSource: any[] = [];
    // 第二步, 计算起飞时间
    console.time();
    const curDateStr = takeoffDate.substring(0, 10);
    const diffHours = moment(curDateStr).diff(moment(compareDate), "hours");
    console.log("diffHours: ", diffHours);
    dataPrice.forEach((r: any) => {
      r.takeoff = moment(r.day).format("yyyy-MM-DD");
    });
    console.timeEnd();
    const rst = transData(dataPrice, view);
    dataSource = rst;
    if (compareDate) {
      console.log("price second step:", rst);
      // 第三步, 转换对比的值
      console.time();
      const rst3 = rowToColumn(
        rst,
        ["d", "diffTime"],
        "takeoff",
        undefined,
        (rt: string, col: string) => {
          if (rt === curDateStr) {
            return col;
          } else {
            return col + "Compare";
          }
        }
      );
      console.timeEnd();
      console.log("third step:", rst3);
      // 第四步, 对于一部分只有对比日期没有当前日期的数据, 需要补充当前日期, 否则对比查看时会很麻烦
      rst3.forEach((r: any) => {
        // if (!r.day && r.dayCompare) {
        //   const curDay = moment(r.dayCompare).add(diffHours, "hours").valueOf();
        //   r.day = `/Date(${curDay})/`;
        // }
        if (!r.collectDay && r.collectDayCompare) {
          const curCollectDay = moment(r.collectDayCompare)
            .add(diffHours, "hours")
            .valueOf();
          r.collectDay = `/Date(${curCollectDay})/`;
        }
      });
      console.log("forth step:", rst3);
      dataSource = rst3;
    }
    return dataSource;
  }, [compareDate, dataPrice, loadingPrice, takeoffDate, view]);

  const [priceXStart, priceXEnd] = useMemo(() => {
    let xMax: number | undefined;
    let xMin: number | undefined;
    const setStartEnd = (item: IHistoryPrice) => {
      const dMoment = moment(item.d);
      if (!dMoment.isValid()) {
        return;
      }
      if (xMax === undefined || moment(xMax).isAfter(dMoment)) {
        xMax = moment(item.d).valueOf();
      }
      if (xMin === undefined || moment(xMin).isBefore(dMoment)) {
        xMin = moment(item.d).valueOf();
      }
    };
    priceMerged.forEach(setStartEnd);
    // if (compareDate) {
    //   dataCom.forEach(setStartEnd);
    // }
    return [xMax, xMin];
  }, [priceMerged]);
  // #endregion

  // #region Lf 数据格式转换
  const lfMerged = useMemo(() => {
    console.log("load data merging");
    if (loadingLf) {
      return EMPTY_ARRAY;
    }
    let dataSource: any = EMPTY_ARRAY;
    if (dataLf && dataLf.length) {
      dataLf.forEach(
        (d: any) =>
          (d.d = calcD(d.diffTime, view === 0 ? takeoffDate : d.day, view))
      );

      // 第一步, 生成takeoff字段. 同时将精确到分钟的收集时间转换为小时
      dataLf.forEach((r: any) => {
        r.takeoff = moment(r.day).format("yyyy-MM-DD");
        // 根据takeoff的值重算采集时间, 去除分钟的影响
        const collectMinutes = moment(r.collectDay);
        if (collectMinutes.minute() > 30) {
          collectMinutes.add(1, "hours");
        }
        collectMinutes.set("minute", 0);
        r.collectDay = getTS(collectMinutes.valueOf());
        // if (r.takeoff !== curDateStr) {
        //   r.diffTime += diffHours;
        // }
      });
      // 第二步, 过滤掉竞飞航线目标客座和竞飞航班目标客座
      const noCompare34 = dataLf.filter((d) => {
        if (d.type === 3) {
          const nameArr = d.name?.split(" ") || [];
          const flightNo = nameArr.length >= 2 ? nameArr[1] : null;
          if (flightNo !== record.flightNo) {
            return false;
          }
        }
        if (d.type === 4) {
          const nameArr = d.name?.split(" ") || [];
          const flightNo = nameArr.length >= 2 ? nameArr[1] : null;
          if (flightNo !== record.flightNo) {
            return false;
          }
        }
        return true;
      });
      console.time();
      // 第三步, 依照type行转列, 转换后每个采集时间节点, 每个起飞日只有一行
      const rst = transData(noCompare34, view);
      dataSource = rst;
      console.timeEnd();
      console.log("first step:", rst);
      // 第四步 将对比起飞日行转列
      if (compareDate) {
        console.time();
        const curDateStr = takeoffDate.substring(0, 10);
        const diffHours = moment(curDateStr).diff(moment(compareDate), "hours");
        console.log("diffHours: ", diffHours);
        console.timeEnd();
        console.log("load second step:", rst);
        // 第三步, 转换对比的值
        console.time();
        const rst3 = rowToColumn(
          rst,
          ["d", "diffTime"],
          "takeoff",
          undefined,
          (rt: string, col: string) => {
            if (rt === curDateStr) {
              return col;
            } else {
              return col + "Compare";
            }
          }
        );
        console.timeEnd();
        console.log("third step:", rst3);
        dataSource = rst3;
      }
    }
    return dataSource;
  }, [compareDate, dataLf, loadingLf, record.flightNo, takeoffDate, view]);

  const [lfXStart, lfXEnd] = useMemo(() => {
    let xMax: number | undefined;
    let xMin: number | undefined;
    const setStartEnd = (item: IHistoryLoad) => {
      const dMoment = moment(item.d);
      if (!dMoment.isValid()) {
        return;
      }
      if (xMax === undefined || moment(xMax).isAfter(dMoment)) {
        xMax = moment(item.d).valueOf();
      }
      if (xMin === undefined || moment(xMin).isBefore(dMoment)) {
        xMin = moment(item.d).valueOf();
      }
    };
    lfMerged.forEach(setStartEnd);
    console.log("load max min end", xMax, xMin);
    return [xMax, xMin];
  }, [lfMerged]);
  // #endregion

  const [xStart, xEnd] = useMemo(() => {
    const tmpStart = min([priceXStart, lfXStart]);
    const tmpEnd = max([priceXEnd, lfXEnd]);
    return [tmpStart, tmpEnd];
  }, [lfXEnd, lfXStart, priceXEnd, priceXStart]);

  // #region 补齐Price数据
  const priceFilled = useMemo(() => {
    const dataMergedCopy = cloneDeep(priceMerged);
    if (xStart !== undefined && xEnd !== undefined) {
      console.log("price: fill data");
      console.time("price: fill");
      const rst = fillData(dataMergedCopy, xStart, xEnd, view);
      rst.sort((r1, r2) => (r1.d < r2.d ? -1 : 1));
      console.timeEnd("price: fill");
      return rst || EMPTY_ARRAY;
    }
    return EMPTY_ARRAY;
  }, [priceMerged, view, xEnd, xStart]);
  // #endregion

  // #region 补齐Lf数据
  const lfFilled = useMemo(() => {
    const dataMergedCopy = cloneDeep(lfMerged);
    if (xStart !== undefined && xEnd !== undefined && dataMergedCopy.length) {
      console.log("load: fill data");
      console.time("load: fill");
      const rst = fillData(dataMergedCopy, xStart, xEnd, view);
      rst.sort((r1, r2) => (r1.d < r2.d ? -1 : 1));
      console.timeEnd("load: fill");
      return rst || EMPTY_ARRAY;
    }
    return EMPTY_ARRAY;
  }, [lfMerged, view, xEnd, xStart]);
  // #endregion

  return (
    <>
      <div style={{ height: 300 }}>
        <SuggestTrend
          record={record}
          compareDate={compareDate}
          compareFlightNo={compareFlightNo}
          filledData={priceFilled}
          isLoading={loadingPrice || loadingLf}
          onChartInstanceChange={setHistoryChartInstance}
          view={view}
        ></SuggestTrend>
      </div>
      <div style={{ height: 300 }}>
        <LoadTrend
          record={record}
          compareDate={compareDate}
          compareFlightNo={compareFlightNo}
          filledData={lfFilled}
          isLoading={loadingPrice || loadingLf}
          onChartInstanceChange={setLoadChartInstance}
          view={view}
        ></LoadTrend>
      </div>
    </>
  );
};
export default TrendContent;
