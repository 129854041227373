import React, { ReactElement } from "react";
import { Card } from "antd";
import ApproveTable from "./Components/ApproveTable";
import { getSharkText } from "Utils/i18nGlobal";

const ApprovelUser = (): ReactElement => {
  const title = (
    <div className="mode-card-title">{getSharkText("menu_approve_user")}</div>
  );

  return (
    <div className="content">
      <Card title={title}>
        <ApproveTable
          queryUrl="findUserApproveList"
          moduleCode="approve_user"
        />
      </Card>
    </div>
  );
};

export default ApprovelUser;
