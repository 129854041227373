import { EMPTY_ARRAY } from "Constants";
import {
  AirlinesQueryCondition,
  Area,
  FlightArea,
  ISeries,
  QueryCondition,
} from "Interface";
import {
  UserProfileChart,
  UserProfileTag,
} from "@ctrip/flt-bi-flightai-airlines";
import { cloneDeep } from "lodash";
import { getSharkText } from "Utils/i18nGlobal";

export const defaultArea = {
  areaType: 0,
  areaCode: "",
  areaName: "",
};
export const defaultAirlinesQueryExt = {
  departArea: defaultArea,
  arriveArea: defaultArea,
};

export interface IQuery {
  isCompare: boolean;
  loaded: boolean;
  current: {
    tag: UserProfileTag[];
    area?: Area;
    filter: FlightArea;
    query?: AirlinesQueryCondition | QueryCondition;
  };
  compare?: {
    tag: UserProfileTag[];
    area?: Area;
    filter: FlightArea;
    query?: AirlinesQueryCondition | QueryCondition;
  };
}

export const defaultQuery: IQuery = {
  isCompare: false,
  loaded: false,
  current: {
    tag: EMPTY_ARRAY,
    area: defaultArea,
    filter: defaultAirlinesQueryExt,
  },
};

export const getTagName = (tagName: string): string => {
  const tmp =
    !tagName || tagName === "unkown"
      ? getSharkText("key.unknown.name")
      : tagName;
  return tmp;
};

export const getTotalVal = (data: UserProfileChart[]): number => {
  let totalValue = 0;
  data.forEach((d) => {
    totalValue += d.value === null ? 0 : d.value;
  });
  return totalValue;
};

export interface ICompareChartConfig {
  series: Array<Partial<ISeries>>;
  yAxisData: string[];
  compareAxisData: string[];
}

export const formatName = (data: UserProfileChart[]): UserProfileChart[] => {
  const tmp = cloneDeep(data);
  tmp.forEach((d) => {
    if (d.tagName === "unkown") {
      d.tagName = getSharkText("key.unknown.name");
    }
  });
  return tmp;
};

export interface ILevel {
  code: string;
  level: number;
  name: string;
  coords: number[][][];
  children?: ILevel[];
  oriCoords?: any;
}
