import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { IS_PRO } from "Constants";

export const DATASET_ID = IS_PRO ? 316 : 78;
export const TAKEOFFDATE = "takeoffdate";
export const FLIGHTNO = "flightno";
export const DPORT = "dport";
export const APORT = "aport";
export const STOPS = "stops";
export const DCITY = "dcity";
export const DCITYID = "dcityid";
export const DCITYNAME = "dcityname";
export const DPROVINCEID = "dprovinceid";
export const DPROVINCENAME = "dprovincename";
export const DEP_REGIONNAME = "dep_regionname";
export const DEP_COUNTRYCODE = "dep_countrycode";
export const DEP_COUNTRYID = "dep_countryid";
export const DEP_COUNTRYNAME = "dep_countryname";
export const DEP_CONTINENTID = "dep_continentid";
export const DEP_CONTINENTNAME = "dep_continentname";
export const ACITY = "acity";
export const ACITYID = "acityid";
export const ACITYNAME = "acityname";
export const APROVINCEID = "aprovinceid";
export const APROVINCENAME = "aprovincename";
export const ARR_REGIONNAME = "arr_regionname";
export const ARR_COUNTRYCODE = "arr_countrycode";
export const ARR_COUNTRYID = "arr_countryid";
export const ARR_COUNTRYNAME = "arr_countryname";
export const ARR_CONTINENTID = "arr_continentid";
export const ARR_CONTINENTNAME = "arr_continentname";
export const CAP = "cap";
export const TKT_CNT = "tkt_cnt";
export const CUM_REVENUE_TAX_EXCL = "cum_revenue_tax_excl";
export const CUM_REVENUE_TAX_INCL = "cum_revenue_tax_incl";
export const TAG = "tag";
export const TKT_STATUS_CHANGEDATE = "tkt_status_changedate";
export const BKD_TIME = "bkd_time";
export const TPM = "tpm";
export const ASK = "ask";
export const RPK = "rpk";
export const D = "d";
export const RASK_TAX_EXCL = "rask_tax_excl";
export const YIELD_TAX_EXCL = "yield_tax_excl";
export const AVG_PRICE_TAX_EXCL = "avg_price_tax_excl";
export const RASK_TAX_INCL = "rask_tax_incl";
export const YIELD_TAX_INCL = "yield_tax_incl";
export const AVG_PRICE_TAX_INCL = "avg_price_tax_incl";
export const LF = "lf";
export const ROUTE = "route";
export const FESTIVAL_INFO = "festival_info";
export const UDF = "udf";
export const BKD_DETAIL = "bkd_detail";
export const customCols: FDDatasetCol[] = [
  {
    id: 0.11,
    displayName: UDF,
    name: UDF,
    dbcolumn: `salePlanUDPF(1)`,
    jdbctype: "DOUBLE",
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    datasetId: DATASET_ID,
    description: "",
    extra: '{"isExp":true, "computeType": "p_exp"}',
  },
];
