import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Alert, Card, Col, Row } from "antd";
import SearchTable, { TableHandler } from "./Components/SearchTable";
import { AirlinesQueryCondition, Area, FlightArea, Tab } from "Interface";
import SearchTrendBarLineCharts from "./Components/SearchTrendsBarLineCharts";
import UpdateTime from "Components/UpdateTime";
import Page from "Layout/Page";
import useGlobal from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { getDateRange, isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import AirlinesRanking from "Components/AirlinesRanking";
import { DATE_FORMAT, DEFAULT_AREA } from "Constants";
import { AxisType } from "./Common";
import moment from "moment";
import { useBatchEffect } from "Utils/useBatchEffect";
import { QueryExt } from "./SearchInterface";
import AirlineSearch from "./Components/AirlineSearch";
import useRefFunc from "Utils/useRefFunc";

const defaultAirlinesQueryExt: QueryExt = {
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
  range: {
    searchAlign: 1,
    axisReverse: 1,
    searchDateStart: null,
    searchDateEnd: moment().add(-1, "d").format(DATE_FORMAT),
    beforeStart: null,
    beforeEnd: null,
    queryType: 1,
  },
  searchType: 0,
  market: ["CN"],
};

const rankTabAirlines: Tab[] = [
  {
    title: getSharkText("config_page_all"),
    chartTableCode: "AL0301",
    type: 3,
  },
  {
    title: getSharkText("key.airline_operates.name"),
    chartTableCode: "AL0301",
    titleTips: getSharkText("key.airline_operates.hint"),
    type: 0,
  },
  {
    title: getSharkText("key.airline_not_operates.name"),
    chartTableCode: "AL0301",
    titleTips: getSharkText("key.airline_not_operates.hint"),
    type: 1,
  },
  {
    title: getSharkText("key.market_not_navigable.name"),
    chartTableCode: "AL0301",
    titleTips: getSharkText("key.market_not_navigable.hint"),
    type: 2,
  },
];

interface SearchTrendsAirlineProps {
  intl?: boolean;
}

const SearchTrendsAirline = (
  props: SearchTrendsAirlineProps
): ReactElement<SearchTrendsAirlineProps> => {
  const { intl } = props;
  const [globalState, actions] = useGlobal();
  const { setAirlinesQueryConditionAttr } = actions;
  const { systemType, airlinesQueryCondition } = globalState;
  const [loading, setLoading] = useState(false);
  // 机场版选中城市
  const [area, setArea] = useState<Area>(DEFAULT_AREA);
  /// 航司版选中城市
  const [airlinesSearchTrendsExt, setAirlinesSearchTrendsExt] =
    useState<QueryExt>(defaultAirlinesQueryExt);
  // 选中排行版城市
  const [selectCity, setSelectCity] = useState<FlightArea | null>(null);
  const [hasCityData, setHasCityData] = useState(false);
  const [chartsType, setChartsType] = useState(0);
  const [axis, setAxis] = useState<AxisType>(AxisType.takeoff);
  const isAirport = isAirportMode(systemType);
  const departArea = selectCity
    ? selectCity.departArea
    : airlinesSearchTrendsExt.departArea;
  const arriveArea = selectCity
    ? selectCity.arriveArea
    : airlinesSearchTrendsExt.arriveArea;

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setArea(DEFAULT_AREA);
    // setAirlinesSearchTrendsExt(defaultAirlinesQueryExt);
    setSelectCity(null);
    setTimeout(() => setLoading(false));
  }, [airlinesQueryCondition.flightClass]);

  useEffect(() => {
    setSelectCity(null);
  }, [area]);

  const alertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].top10}
      text={
        isAirport
          ? getSharkText("key.search_demonstration_airport.hint")
          : getSharkText("key.search_demonstration_airline.hint")
      }
    />
  );

  const defaultTkDateRange = getDateRange("ninetyDays");

  // #region 设置搜索日期起飞日期默认值等
  useBatchEffect(() => {
    console.log("set date");
    const tmpDate = {
      startDate: defaultTkDateRange
        ? defaultTkDateRange[0].format(DATE_FORMAT)
        : "",
      endDate: defaultTkDateRange
        ? defaultTkDateRange[1].format(DATE_FORMAT)
        : "",
    };
    setAirlinesQueryConditionAttr(tmpDate);
  }, []);
  // #endregion

  const queryExt = airlinesSearchTrendsExt;

  const handleSearchChange = useRefFunc(
    (q: AirlinesQueryCondition, v: QueryExt) => {
      setHasCityData(false);
      setAirlinesQueryConditionAttr(q);
      setAirlinesSearchTrendsExt(v);
    }
  );

  const tableRef = useRef<TableHandler>(null);

  const handleQueryTable = useRefFunc(() => {
    if (tableRef.current) {
      tableRef.current.query();
    }
  });

  return (
    <Page
      defaultDateMode="none"
      needToolBar={false}
      needGlobalConditionCheck={false}
    >
      <div className="content-white">
        <AirlineSearch axis={axis} onChange={handleSearchChange} intl={intl} />
      </div>
      {loading ? undefined : (
        <>
          <div className="content-white">
            <Alert message={alertMessage} banner type="info" />
            <Row
              gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
              style={{ marginTop: 10 }}
            >
              <Col xxl={intl ? 24 : 16} xl={intl ? 24 : 16} md={24} xs={24}>
                <SearchTrendBarLineCharts
                  queryUrl={QUERY_URL[systemType].barLineCharts}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode={CHART_TABLE_CODE[systemType].barLineCharts}
                  departArea={departArea}
                  arriveArea={arriveArea}
                  type={chartsType}
                  axis={axis}
                  onAxisChange={setAxis}
                  ext={queryExt}
                  onSuccess={handleQueryTable}
                />
              </Col>
              <Col xxl={intl ? 24 : 8} xl={intl ? 24 : 8} md={24} xs={24}>
                <AirlinesRanking
                  title={getSharkText("key.top_10_routes.name")}
                  queryUrl={QUERY_URL[systemType].top10}
                  moduleCode={MODULE_CODE[systemType]}
                  chartTableCode="AL0301"
                  tab={rankTabAirlines}
                  sortable
                  airlinesSearchTrendsExt={airlinesSearchTrendsExt}
                  selectItem={setSelectCity}
                  setChartsType={setChartsType}
                  ext={queryExt}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <Col>
                <Card>
                  <SearchTable
                    ref={tableRef}
                    queryUrl={QUERY_URL[systemType].table}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].table}
                    area={area}
                    airlinesSearchTrendsExt={airlinesSearchTrendsExt}
                    ext={queryExt}
                  />
                </Card>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Page>
  );
};

export default SearchTrendsAirline;
