import { Spin, Tooltip } from "antd";
import { ISeries } from "Interface";
import merge from "lodash/merge";
import {
  CHART_TABLE_CODE,
  MODULE_CODE,
  QUERY_URL,
} from "Page/AI/FlightReview/fetchCode";
import React, { ReactElement, useEffect, useState } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { genSeriesByDimensions, rowToColumn, showNum, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { DATE_FORMAT, EMPTY_ARRAY, WEEK } from "Constants";
import moment from "moment";
import EchartsReactBase from "Components/EchartsReactBase";

export interface IProcessChartsProps {
  defaultSeries: ISeries[];
  ext?: Record<string, unknown>;
  chartConfig?: Record<string, unknown>;
  /**
   * 0 堆积图, 1 面积图
   */
  type: 0 | 1;
  /**
   * 聚合粒度 0 = 天, 1= 周
   */
  aggCode: 0 | 1;
}

/**
 * 航班复盘-销售过程-订座数-图表
 */
const ProcessArea = (props: IProcessChartsProps): ReactElement => {
  const { ext, defaultSeries, chartConfig, type, aggCode } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [transData, setTransData] = useState<any[]>(EMPTY_ARRAY);

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(2),
    url: QUERY_URL[2].sales,
    defaultValue: null,
    head: {
      moduleCode: MODULE_CODE[2],
      chartTableCode: CHART_TABLE_CODE[2].sales,
    },
    query: airlinesQueryCondition,
    ext: {
      ...ext,
      type,
      aggCode,
    },
    lazey: true,
  });

  useEffect(() => {
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        ...ext,
        type,
        aggCode,
      },
    });
  }, [ext, doFetch, type, aggCode, airlinesQueryCondition]);

  /**
   * 需要对数据进行行列转换
   */
  useEffect(() => {
    if (data && data.length) {
      // 排序
      // data.sort((a: any, b: any) => b.diffDay - a.diffDay);
      const rst = rowToColumn(data, "day", "diffDay");
      setTransData(rst);
    } else {
      setTransData(EMPTY_ARRAY);
    }
  }, [data]);

  const [option, setOption] = useState<any>();

  useEffect(() => {
    const dimensions =
      transData && transData.length ? Object.keys(transData[0]) : [];
    const tmp: Record<string, any> = {
      dataset: {
        source: transData,
      },
      color: ["#01C5DB", "#FC9B4F", "#177DFE"],
      // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any[]) => {
          const dayMoment = moment(params[0].data.day);
          const day = `${dayMoment.format(DATE_FORMAT)} ${
            WEEK[(dayMoment.day() + 6) % 7]
          }`;
          let dom = "";
          params.reverse().forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const seriesConfig = defaultSeries.find(
              (d) => d.name === item.seriesName
            );
            const isPercentVal = seriesConfig
              ? seriesConfig.isPercentVal
              : false;
            const itemDim = item.dimensionNames[item.encode.y[0]];
            const itemCompare = itemDim.replace("value", "compareValue");
            const itemValue = item.value[itemDim];
            const itemTotalVal = item.value[itemCompare];
            const value = isPercentVal
              ? showNum(itemValue, "percentage")
              : showNum(itemValue, "num");
            const val = `${item.seriesName}: ${itemTotalVal} (${value})`;
            dom = dom + "<br />" + dot + val;
          });
          return day + dom;
        },
      },
      xAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
        axisLabel: {
          padding: [8, 0, 0, 0],
          formatter: (value: number) => WEEK[value - 1],
        },
      },
      grid: {
        top: "100",
        right: "100",
      },
      dataZoom: null,
      yAxis: [
        {
          position: "right",
          type: "value",
          splitNumber: 3,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#ddd",
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series: genSeriesByDimensions(dimensions, defaultSeries),
    };
    const opt = merge(tmp, chartConfig);
    if (typeof opt.xAxis.dataFn === "function") {
      opt.xAxis.data = opt.xAxis.dataFn(transData, defaultSeries);
    }
    setOption(opt);
  }, [chartConfig, transData, defaultSeries]);

  return (
    <Spin spinning={isLoading}>
      <Tooltip
        title={getSharkText(
          "config_page_PaAiFlCoMiPr_flight_daily_sales_stack"
        )}
        placement="left"
      >
        <div style={{ padding: 24 }}>
          {option ? (
            <EchartsReactBase
              className="bar-line-charts"
              option={option}
              theme="default"
              style={{ height: 484 }}
              notMerge
            />
          ) : undefined}
        </div>
      </Tooltip>
    </Spin>
  );
};
export default ProcessArea;
