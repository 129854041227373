// Created by xh_zhu on 2021-03-10

import { Input, Select } from "antd";
import { AirlineItem, AirlineItemPartial } from "Interface";
import React, { useEffect, useState } from "react";
import useGlobal from "Store";
import { getModuleNameFromPath, getModuleStatus } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import * as _ from "lodash";
import { SERVER } from "Constants";
import { useLocation } from "react-router-dom";
import { useFetch } from "Utils/useFetch";

const { Option } = Select;

interface AirlinesRoleArr {
  code: string;
  name: string;
}
interface ISelectMultiAirlinesProps {
  airlines: AirlinesRoleArr[];
  moduleCode?: string;
  queryUrl?: string;
  server?: string;
  query?: any;
  onChange?: (v: AirlineItem[]) => void;
  ext?: any;
}

const SelectMultiAirlines = (props: ISelectMultiAirlinesProps) => {
  const { airlines, onChange, queryUrl, server, query, ext, moduleCode } =
    props;
  const location = useLocation();
  const [allAirlines, setAirlines] = useState<AirlineItem[]>([]);
  const [selectedValues, setSelectValues] = useState<string[]>([]);
  const [globalState] = useGlobal();
  const { userInfo, systemType } = globalState;
  const { user } = userInfo;
  const isCtrip = user.userType === 1;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const [{ data }, doFetch] = useFetch({
    server: server || SERVER,
    url: queryUrl || "findAirlines",
    defaultValue: [],
    head: {
      moduleCode: moduleCode || "common",
      chartTableCode: null,
    },
    query,
    ext: {
      type: 1,
      ...ext,
    },
    lazey: true,
  });

  useEffect(() => {
    if (isCtrip) {
      doFetch({
        query,
        ext: {
          type: 1,
          ...ext,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setAirlines(data);
    }
  }, [data]);

  const handleSelectAirlines = (values: string[], option: any) => {
    const newSelectedOption = option.map((item: any) => {
      const selectedOption: AirlineItemPartial = {
        airlinesCode: item.props.value,
        airlinesName: item.props.label,
      };
      return selectedOption;
    });
    const newSelectedCodes = newSelectedOption.map(
      (item: AirlineItem) => item.airlinesCode
    );
    setSelectValues(newSelectedCodes);
    if (onChange) {
      onChange(newSelectedOption);
    }
  };

  const filterOption = (input: string, option: any) =>
    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.label.includes(input);

  const placeHolder = getSharkText("key.get_iata_airline_code.text");

  return (
    <>
      {isDemo ? (
        <Input style={{ minWidth: 200 }} disabled value="Demo" />
      ) : !isCtrip ? (
        <Select
          id="toolbarAirlinesSelect"
          showSearch
          style={{ minWidth: 250 }}
          value={selectedValues}
          onChange={handleSelectAirlines}
          filterOption={filterOption}
          mode="multiple"
          placeholder={placeHolder}
        >
          {airlines.map((item) => (
            <Option key={item.code} value={item.code} label={item.name}>
              {item.name}|{item.code}
            </Option>
          ))}
        </Select>
      ) : (
        <Select
          showSearch
          style={{ minWidth: 250 }}
          value={selectedValues}
          onChange={handleSelectAirlines}
          filterOption={filterOption}
          mode="multiple"
          placeholder={placeHolder}
        >
          {allAirlines.map((item) => (
            <Option
              className="airlines-select-item"
              key={item.airlinesCode}
              value={item.airlinesCode}
              label={item.airlinesName}
            >
              {item.airlinesName}|{item.airlinesCode}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};
export default SelectMultiAirlines;
