import React from "react";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode } from "Interface";
import { round } from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  day: string;
  value: number;
  compareValue: number;
  marketValue: number;
  marketCompareValue: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  aggCode: AggCode;
  startDate: string;
  endDate: string;
  seriesName: string;
  cardName: string;
  isPercentVal: boolean;
  compareType: number;
}

const BarCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const { data, height = 300, cardName, isPercentVal } = props;
  const isZero = (val: number) => val === 0;

  const option = {
    color: ["#01C5DB", "#177DFE", "#FC9B4F", "#82b1ff"],
    dataset: {
      source: data,
    },
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const dimIndex = item.encode && item.encode.y ? item.encode.y[0] : 1;
          const dimName = item.dimensionNames[dimIndex];
          const val = `${item.seriesName}: ${
            !isZero(item.data[dimName])
              ? dimName.toLowerCase().indexOf("yoy") >= 0
                ? isPercentVal
                  ? showNum(item.data[dimName], "percentage")
                  : showNum(item.data[dimName] * 100, "percentage")
                : isPercentVal
                ? `${round(item.data[dimName], 1)}%`
                : round(item.data[dimName])
              : "-"
          }`;
          dom = dom + "<br />" + dot + val;
        });
        return (
          params[0].data.airportName + getSharkText("area.airport.title") + dom
        );
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        interval: 0,
        width: 100,
        overflow: "truncate",
        formatter: (value: string, a: number) => {
          return `${value}\n\n${a + 1}`;
        },
      },
    },
    yAxis: [
      {
        position: "left",
        type: "value",
        splitLine: false,
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) =>
            `${isPercentVal ? value + "%" : value} `,
        },
        axisTick: {
          show: false,
        },
      },
      {
        position: "right",
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: isPercentVal
            ? (value: number) => `${value.toFixed(1)}%`
            : (value: number) => `${(value * 100).toFixed(2)}%`,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: "bar",
        encode: { x: "airportName", y: "value" },
        name: `${cardName}`,
        showSymbol: false,
        symbol: "circle",
        yAxisIndex: 0,
        lineStyle: {
          width: 3,
        },
      },
      {
        type: "line",
        encode: { x: "airportName", y: "yoy" },
        name: getSharkText("key.yoy.button"),
        showSymbol: false,
        symbol: "circle",
        connectNulls: false,
        yAxisIndex: 1,
        barMaxWidth: 20,
      },
    ],
  };

  return (
    <EchartsReactBase
      notMerge={true}
      option={option}
      theme="default"
      style={{ height }}
    />
  );
};

export default BarCharts;
