import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { QueryParam } from "./interface";
import SearchArea from "./SearchArea";
import BlackScreen from "../FlightManage/Components/BlackScreen";
import "./index.scss";
import useGlobalState from "Store";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
export interface IIMBatchProps {
  example?: string;
}

/**
 * 批量调仓页面
 */
const IMBatch = (props: IIMBatchProps): ReactElement => {
  const { example } = props;
  const [param, setParam] = useState<QueryParam>();
  const [, actions] = useGlobalState();
  useEffect(() => {
    actions.setAirlinesAttrIfNull({
      startDate: moment().add(-31, "day").format(DATE_FORMAT),
      endDate: moment().add(-1, "day").format(DATE_FORMAT),
    });
  }, []);
  const queryParam = useMemo(() => {
    if (!param) {
      return null;
    }
  }, []);
  return (
    <div className="content-white">
      <div>
        <SearchArea onChange={setParam} />
      </div>
      <div className="im-batch" data-test-id="im-batch">
        <div className="cabin">
          {param ? (
            <BlackScreen closeRoPanel={() => 0} param={param} />
          ) : undefined}
        </div>
      </div>
    </div>
  );
};
export default IMBatch;
