import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { SystemType } from "Interface";
import React, { ReactNode } from "react";
import { getSharkText } from "Utils/i18nGlobal";

export enum ERouteType {
  /**
   * 优势
   */
  Advantage = 1,
  /**
   * 劣势
   */
  Disadvantage,
  /**
   * 潜力
   */
  Potential,
}

export interface RouteObj {
  name: string;
  node: ReactNode;
}

const airlinesDisadvantageTip = (
  <ul>
    <li>{getSharkText("tranfer.alertnote1.name")}</li>
    <li>{getSharkText("tranfer.alertnote2.name")}</li>
    <li>{getSharkText("tranfer.alertnote3.name")}</li>
    <li>{getSharkText("tranfer.alertnote4.name")}</li>
  </ul>
);

const airportDisadvantageTip = (
  <ul>
    <li>{getSharkText("tranfer.alertnote1.name")}</li>
    <li>
      {getSharkText("config_page_only_transfer_duration_disadvantage_detail")}
    </li>
    <li>
      {getSharkText(
        "config_page_price_and_transfer_duration_both_disadvantages"
      )}
    </li>
    <li>
      {getSharkText("config_page_price_and_transfer_duration_both_advantages")}
    </li>
  </ul>
);

export const RouteName: Record<SystemType, Record<ERouteType, RouteObj>> = {
  [SystemType.airlines]: {
    [ERouteType.Advantage]: {
      name: getSharkText("transfer.advantage_trans_routes"),
      node: (
        <Popover content={getSharkText("tranfer.note4.name")}>
          {getSharkText("transfer.advantage_trans_routes")}
          <InfoCircleOutlined
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        </Popover>
      ),
    },
    [ERouteType.Disadvantage]: {
      name: getSharkText("transfer.disadvantage_trans_routes"),
      node: (
        <Popover content={airlinesDisadvantageTip}>
          {getSharkText("transfer.disadvantage_trans_routes")}
          <InfoCircleOutlined
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        </Popover>
      ),
    },
    [ERouteType.Potential]: {
      name: getSharkText("transfer.potential_transfer_routes"),
      node: (
        <Popover content={getSharkText("tranfer.alertnote5.name")}>
          {getSharkText("transfer.potential_transfer_routes")}
          <InfoCircleOutlined
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        </Popover>
      ),
    },
  },
  [SystemType.airport]: {
    [ERouteType.Advantage]: {
      name: getSharkText("transfer.advantage_trans_routes"),
      node: (
        <Popover content={getSharkText("config_page_popover_content")}>
          {getSharkText("transfer.advantage_trans_routes")}
          <InfoCircleOutlined
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        </Popover>
      ),
    },
    [ERouteType.Disadvantage]: {
      name: getSharkText("transfer.disadvantage_trans_routes"),
      node: (
        <Popover content={airportDisadvantageTip}>
          {getSharkText("transfer.disadvantage_trans_routes")}
          <InfoCircleOutlined
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        </Popover>
      ),
    },
    [ERouteType.Potential]: {
      name: getSharkText("transfer.potential_transfer_routes"),
      node: (
        <Popover
          content={getSharkText(
            "config_page_PaAiTrCo_airport_capacity_splice_and_transfer_splice_time_limit_90_1440_minutes"
          )}
        >
          {getSharkText("transfer.potential_transfer_routes")}
          <InfoCircleOutlined
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        </Popover>
      ),
    },
  },
};

export enum EAlertType {
  /**
   * 中转航线
   */
  TransferRoute = 1,
  /**
   * 中转机场
   */
  TransferAirport = 2,
}

export const AlertName: Record<EAlertType, string> = {
  [EAlertType.TransferRoute]: getSharkText("tranfer.t_routes.name"),
  [EAlertType.TransferAirport]: getSharkText("transfer.t_airport.name"),
};
