import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Typography } from "antd";
import ValueNum from "Components/ValueNum";
import { IDownloadHeader } from "Interface";
import { flightPlanTypes } from "Page/AI/FlightPlan/FlightPlanAirlineCommon";
import React from "react";
import { showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

const { Text } = Typography;

export const weekColumns = (cancelNewPlan: 0 | 1 | 2) => {
  return [
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dcityName",
      key: "dcityName",
      sorter: (a: any, b: any) => a.dcityCode.localeCompare(b.dcityCode),
      render: (val: string, record: any) =>
        `${record.dcityName}(${record.dcityCode})`,
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "acityName",
      key: "acityName",
      sorter: (a: any, b: any) => a.acityCode.localeCompare(b.acityCode),
      render: (val: string, record: any) =>
        `${record.acityName}(${record.acityCode})`,
    },
    {
      title: getSharkText("charts.date_of_departure"),
      dataIndex: "takeoffdate",
      key: "takeoffdate",
      sorter: (a: any, b: any) =>
        new Date(a.takeoffdate).getTime() - new Date(b.takeoffdate).getTime(),
    },
    {
      title: getSharkText("key.flights_carried.name"),
      dataIndex: "flightNO",
      key: "flightNO",
      sorter: (a: any, b: any) => a.flightNO.localeCompare(b.flightNO),
    },
    {
      title: () => (
        <>
          <span>{getSharkText("key.available_seats_estimated")}</span>
          &nbsp;
          <Popover content={getSharkText("key.estimated_based_on_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      downloadTitle: getSharkText("config_page_seat_count_estimate"),
      dataIndex: "transportCapacity",
      key: "transportCapacity",
      sorter: (a: any, b: any) => a.transportCapacity - b.transportCapacity,
      render: (val: number) => (
        <ValueNum roundNum={-1} type="num" value={val} />
      ),
      downloadFormatter: (val: number) => showRawNum(val),
    },
    {
      title: `${flightPlanTypes[cancelNewPlan]}${getSharkText(
        "key.date.flightplan"
      )}`,
      dataIndex: "effectdate",
      key: "effectdate",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.effectdate).getTime() - new Date(b.effectdate).getTime(),
    },
  ];
};

// 民航局明细数据
export const CAACDetailColumns = (cancelNewPlan: 0 | 1 | 2) => {
  return [
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dcityName",
      key: "dcityName",
      sorter: (a: any, b: any) => a.dcityCode.localeCompare(b.dcityCode),
      render: (val: string, record: any) =>
        `${record.dcityName}(${record.dcityCode})`,
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "acityName",
      key: "acityName",
      sorter: (a: any, b: any) => a.acityCode.localeCompare(b.acityCode),
      render: (val: string, record: any) =>
        `${record.acityName}(${record.acityCode})`,
    },
    {
      title: getSharkText("charts.date_of_departure"),
      dataIndex: "takeoffdate",
      key: "takeoffdate",
      sorter: (a: any, b: any) =>
        new Date(a.takeoffdate).getTime() - new Date(b.takeoffdate).getTime(),
    },
    {
      title: getSharkText("key.flights_carried.name"),
      dataIndex: "flightNO",
      key: "flightNO",
      sorter: (a: any, b: any) => a.flightNO.localeCompare(b.flightNO),
    },
    {
      title: `${flightPlanTypes[cancelNewPlan]}${getSharkText(
        "key.date.flightplan"
      )}      `,
      dataIndex: "effectdate",
      key: "effectdate",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.effectdate).getTime() - new Date(b.effectdate).getTime(),
    },
  ];
};

// 民航局非明细数据
export const CAACSummaryColumns = (cancelNewPlan: 0 | 1 | 2) => {
  return [
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dcityName",
      key: "dcityName",
      sorter: (a: any, b: any) => a.dcityCode.localeCompare(b.dcityCode),
      render: (val: string, record: any) =>
        `${record.dcityName}(${record.dcityCode})`,
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "acityName",
      key: "acityName",
      sorter: (a: any, b: any) => a.acityCode.localeCompare(b.acityCode),
      render: (val: string, record: any) =>
        `${record.acityName}(${record.acityCode})`,
    },
    {
      title: getSharkText("key.flights_carried.name"),
      dataIndex: "flightNO",
      key: "flightNO",
      sorter: (a: any, b: any) => a.flightNO.localeCompare(b.flightNO),
    },
    {
      title: getSharkText("key.CAAC_schedule_flight_cnt.name"),
      dataIndex: "plan",
      key: "plan",
      sorter: (a: any, b: any) => a.plan - b.plan,
      downLoadFormatter: (val: number) => showRawNum(val),
    },
    {
      title: getSharkText("key.flights_for_sale.name"),
      dataIndex: "predict",
      key: "predict",
      sorter: (a: any, b: any) => a.predict - b.predict,
      downLoadFormatter: (val: number) => showRawNum(val),
    },
    {
      title: `${flightPlanTypes[cancelNewPlan]}${getSharkText(
        "key.date.flightplan"
      )}`,
      dataIndex: "effectdate",
      key: "effectdate",
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        new Date(a.effectdate).getTime() - new Date(b.effectdate).getTime(),
    },
  ];
};

// 各机场预计航班执行率
export const top10Columns: IDownloadHeader[] = [
  {
    title: getSharkText("key.rank.name.flightplan"),
    dataIndex: "fe_rank",
    key: "fe_rank",
    sorter: (a: any, b: any) => a.fe_rank - b.fe_rank,
  },
  {
    title: getSharkText("key.airport_name.flightplan"),
    dataIndex: "airportName",
    key: "airportName",
    sorter: (a: any, b: any) => a.airportName.localeCompare(b.airportName),
    render: (val: number, record: any) =>
      `${record.airportName}(${record.airport})`,
  },
  {
    title: getSharkText("key.CAAC_schedule_flight_cnt.name"),
    dataIndex: "plan",
    key: "plan",
    sorter: (a: any, b: any) => a.plan - b.plan,
    downloadFormatter: (val: number) => showRawNum(val),
  },
  {
    title: getSharkText("key.flights_for_sale.name"),
    dataIndex: "onsale",
    key: "onsale",
    sorter: (a: any, b: any) => a.onsale - b.onsale,
    downloadFormatter: (val: number) => showRawNum(val),
  },
  {
    title: getSharkText("key.execution_rate.flightplan"),
    dataIndex: "value",
    key: "value",
    sorter: (a: any, b: any) => a.value - b.value,
    render: (val: number) => showNum(val, "percentage"),
    downloadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
];
