import {
  CompareContent,
  DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
  DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
  EAirlineCompareType,
  EYearCompareType,
} from "Constants";
import { ECompareTypeV2 } from "Interface";
import React, { ReactElement } from "react";
import useGlobalState from "Store";
import { isAirportMode } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import CompareTypeCore from "./CompareTypeCore";

export interface ICompareTypeGlobalProps {
  title?: string;
  compareContent?: Partial<Record<ECompareTypeV2, CompareContent>>;
  AirlineCompareOptions?: Partial<Record<ECompareTypeV2, ECompareTypeV2>>;
  AirportCompareOptions?: Partial<Record<ECompareTypeV2, ECompareTypeV2>>;
  AirlineDefaultCompare?: ECompareTypeV2;
  AirportDefaultCompare?: ECompareTypeV2;
}

/**
 * 使用全局query的CompareType
 */
const CompareTypeGlobal = (props: ICompareTypeGlobalProps): ReactElement => {
  const {
    title,
    AirlineCompareOptions = EAirlineCompareType,
    AirportCompareOptions = EYearCompareType,
    AirlineDefaultCompare = DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
    AirportDefaultCompare = DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
    compareContent,
  } = props;
  const [globalState, actions] = useGlobalState();
  const { queryCondition, airlinesQueryCondition, systemType } = globalState;
  const { setAirlinesQueryConditionAttr, setQueryConditionAttr } = actions;
  const isAirport = isAirportMode(systemType);
  const value = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  const defaultValue = isAirport
    ? AirportDefaultCompare
    : AirlineDefaultCompare;

  const handleChange = useRefFunc((v: ECompareTypeV2) => {
    if (isAirport) {
      setQueryConditionAttr({ compareType: v });
    } else {
      setAirlinesQueryConditionAttr({ compareType: v });
    }
  });

  return (
    <CompareTypeCore
      title={title}
      compareContent={compareContent}
      value={value}
      onChange={handleChange}
      defaultValue={defaultValue}
      source={isAirport ? AirportCompareOptions : AirlineCompareOptions}
    />
  );
};
export default CompareTypeGlobal;
