import React from "react";
import { Spin } from "antd";
import { toThousands } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  name: string;
  value: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  loading: boolean;
  seriesMap: object;
  chartConfig: object;
}

const genChartData = (data: BarData[], seriesMap: any) => {
  // 获取总量
  const totalObj = data.find((d) => /all$/.test(d.name));
  const totalValue = totalObj ? totalObj.value : 0;
  // 返回除了总量的每个值的占比
  const rst = data.reduce((total: BarData[], d: BarData) => {
    // 排除总量数据
    if (/all$/.test(d.name)) {
      return total;
    }
    if (!seriesMap) {
      total.push(d);
      return total;
    }
    if (seriesMap[d.name]) {
      total.push({
        name: seriesMap[d.name],
        value: parseFloat(((d.value / totalValue) * 100).toFixed(2)),
      });
    }
    return total;
  }, []);
  return rst;
};

const SimplePieCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const { data, height = 300, loading, seriesMap, chartConfig } = props;
  const chartData = genChartData(data, seriesMap);
  // const seriesValueName = systemType === 2 && compareType === 1 ? airlines : '当期'
  // const seriesResultValueName = getCardTrendText(cardCode, systemType, compareType)
  // const seriesCompareValueName = systemType === 2 && compareType === 1 ? '市场' : COMPARE_TABLE_TYPE[compareType]
  const isZero = (val: number) => val === 0;
  const option = {
    dataset: {
      source: chartData,
    },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    grid: {
      show: true,
      top: 300,
      bottom: 100,
      left: 100,
      right: 100,
      backgroundColor: "#fff",
      borderWidth: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (item: any) => {
        // const day = getTooltipDateFromAggCode(aggCode, params, data.length, startDate, endDate, isDemo)
        const totalObj = data.find((d) => /all$/.test(d.name));
        const totalValue = totalObj ? totalObj.value : 0;
        let dom = "";
        const dot = `<span class='dot' style='background:${item.color};'></span>`;
        const per = isZero(totalValue) ? "-" : item.data.value;
        const perDom = `${getSharkText("key.proportion_souce.text")}: ${
          per !== "-" ? per : "-"
        }%`;
        // dom = dot + val + '<br />' + perDom;
        dom = dot + perDom;
        // dom = '起飞日期：' + day + dom
        return dom;
      },
    },
    xAxis: {
      type: "value",
      show: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      splitNumber: 3,
      inverse: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: "bar",
        encode: { x: "value", y: "name" },
        lineStyle: {
          width: 3,
        },
      },
    ],
  };

  const mergedOption = chartConfig ? _.merge({}, option, chartConfig) : option;
  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={mergedOption}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default SimplePieCharts;
