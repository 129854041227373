import React, { ReactNode, useEffect } from "react";
import { Typography } from "antd";
import _ from "lodash";
import "./index.css";
import { useFetch } from "Utils/useFetch";
import { getSharkText } from "Utils/i18nGlobal";

const { Text } = Typography;

interface UpdateTimeProps {
  moduleCode: string;
  chartTableCode: string;
  text?: string | ReactNode;
  isAirport?: boolean;
}

const UpdateTime: React.FC<UpdateTimeProps> = (props: UpdateTimeProps) => {
  const { moduleCode, chartTableCode, text, isAirport = true } = props;
  const [{ res }, doFetch] = useFetch({
    url: "getModuleConfig",
    head: {
      moduleCode,
      chartTableCode,
    },
    lazey: true,
  });

  useEffect(() => {
    if (!moduleCode) {
      return;
    }
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
    });
  }, [isAirport]);

  return (
    <Text className="update-time">
      {!_.isEmpty(res) && res.moduleConfig && res.moduleConfig.updateTime
        ? `${getSharkText("key.data_statistics_by.text")}${
            res.moduleConfig.updateTime
          }，`
        : ""}
      {text}
    </Text>
  );
};

export default UpdateTime;
