import { Row } from "antd";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import SelectMultiRoute from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import { IFlight } from "../SalesAnalysisInterface";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { SystemType } from "Interface";
import useGlobalState from "Store";
import { getSharkText } from "Utils/i18nGlobal";

export interface ICompareFlightProps {
  f: IFlight;
  i: number;
  systemType: SystemType;
  handlePlus: () => void;
  handleMinus: () => void;
  isDemo: boolean;
  isLast: boolean;
}

/**
 * 价格趋势对比航线
 */
const CompareFlight = (props: ICompareFlightProps): ReactElement => {
  const { f, i, handlePlus, handleMinus, systemType, isDemo, isLast } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [, setCnt] = useState<number>();

  const queryExt = useMemo(() => {
    return i === 0
      ? undefined
      : {
          query: {
            ...airlinesQueryCondition,
            // airlines: "ALL",
            // airlinesName: "不限航司",
          },
        };
  }, [airlinesQueryCondition, i]);

  const setRoute = useCallback(
    (v: string[]) => {
      f.route = v[0] || "";
      setCnt(Math.random());
    },
    [f]
  );

  const setFlightNO = useCallback(
    (v: string[]) => {
      f.flightNO = v[0] || "";
      setCnt(Math.random());
    },
    [f]
  );
  return (
    <Row key={i} style={{ marginBottom: 5 }}>
      <SelectMultiRoute
        title={
          i !== 0
            ? getSharkText("config_page_compare_route")
            : getSharkText("key.route.name") + "："
        }
        queryUrl={QUERY_URL.routeFlight}
        moduleCode={MODULE_CODE}
        routes={f.route}
        setRoutes={setRoute}
        isDemo={isDemo}
        width={200}
        ext={queryExt}
        flights=""
        // ext={flightQueryExt}
        limitSelected={4}
        placeholder={getSharkText("config_page_please_select_route_required")}
        debugId={`price_analysis_trend_route_index_${i}`}
        mode={undefined}
      />
      <SelectMultiFlight
        title={i !== 0 ? "对比航班：" : "航班："}
        queryUrl={QUERY_URL.routeFlight}
        moduleCode={MODULE_CODE}
        flightNO={f.flightNO}
        setFlightNO={setFlightNO}
        routes={f.route}
        isDemo={isDemo}
        ext={queryExt}
        width={200}
        type={i === 0 ? undefined : 5}
        placeholder={getSharkText(
          "config_page_PaAiSaPrCo_please_select_route_required"
        )}
        debugId={`price_analysis_trend_flight_index_${i}`}
        mode={undefined}
      />
      {i !== 0 && (
        <MinusOutlined
          twoToneColor="#1890ff"
          style={{ fontSize: "200%", color: "#1890FF" }}
          onClick={handleMinus}
        />
      )}
      {isLast && (
        <PlusOutlined
          twoToneColor="#1890ff"
          style={{ fontSize: "200%", color: "#1890FF" }}
          onClick={handlePlus}
        />
      )}
    </Row>
  );
};
export default CompareFlight;
