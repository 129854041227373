import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useGlobal from "Store";
import Page from "Layout/Page";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { IFlightManageQueryExt, SystemType } from "Interface";
import SearchArea from "./Components/SearchArea";
import PriceCalendar, { PCalendarHandler } from "./Components/PriceCalendar";
import "./index.scss";
import { getModule, getModuleNameFromPath, ubt, useFetch } from "Utils";
import HeaderAlert from "Components/HeaderAlert";
import { Alert, Col, Result, Row, Spin, Switch } from "antd";
import { getServer } from "Service/server";
import { IFlightRecord } from "./FlightManageInterface";
import { FlightManageProvider } from "./FlightManageContext";
import NotificationCenter from "Page/AI/NotificationCenter";
import "Components/ResizeLayout/styles.scss";
import { TRACE_ID } from "./FlightManageCommon";
import { useLocation } from "react-router-dom";
import DetailTable from "./Components/DetailTable";
import useRefFunc from "Utils/useRefFunc";

import { AdjustCabinRecord } from "./Components/BlackScreen/interface";
import ROContainer from "./Components/BlackScreen/ROContainer";
import ubtUtils from "Utils/ubtUtils";
let timeOut: any = -1;
interface Props {
  airline?: string;
  flightNo?: string;
  route?: string;
  takeoffDate?: string;
  isDrawer?: boolean;
}
const FlightManage = (props: Props): ReactElement => {
  const { airline, flightNo, route, takeoffDate, isDrawer } = props;
  const location = useLocation();
  const [queryExt, setQueryExt] = useState<IFlightManageQueryExt | null>(null);
  const [curRecord, setCurRecord] = useState<IFlightRecord>();
  const [globalState] = useGlobal();
  const { systemType, airlinesQueryCondition, userInfo } = globalState;
  const { startDate, endDate } = airlinesQueryCondition;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const isDemo = (module && module.moduleStatus === 0) || false;
  const ref = useRef<PCalendarHandler>(null);
  const [adjustFlag, setAdjustFlag] = useState<boolean>(false);
  const [roOpen, setRoOpen] = useState<boolean>(false);
  const [useFastRender, setUseFastRender] = useState<boolean>(false);
  const [userFastRenderSelect, setUserFastRenderSelected] = useState<boolean>();
  const [disableFastRenderChange, setDisableFastRenderChange] =
    useState<boolean>(false);

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL.config,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    query: airlinesQueryCondition,
    ext: {
      // code: flights ? flights.split(",") : null,
      // 不用航班控制航线, 单选模式下会出现选择一次后无法切换的问题
      code: null,
      type: 1,
    },
    lazey: true,
  });

  useEffect(() => {
    if (systemType === SystemType.airlines) {
      doFetch({
        query: airlinesQueryCondition,
        ext: {},
      });
    }
  }, [airlinesQueryCondition, doFetch, systemType]);

  const changeQueryExt = useRefFunc((param: IFlightManageQueryExt) => {
    setQueryExt(param);
  });

  const setUbt = useCallback(() => {
    console.log(123);
    console.timeStamp();
    ubt(TRACE_ID.getName("query"), {
      ...queryExt,
      startDate,
      endDate,
    });
  }, [queryExt, startDate, endDate]);

  /**
   * 查询面板埋点
   */
  useEffect(() => {
    if (queryExt && startDate && endDate) {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        setUbt();
      }, 5000);
      // ubtThrottle();
    }
  }, [queryExt, startDate, endDate]);

  const changeCurRecord = useRefFunc((r: IFlightRecord | undefined) => {
    setCurRecord(r);
    // if (r) {
    //   // if (!roOpen) {
    //   //   setRoOpen(true);
    //   // }
    // } else {
    //   if (roOpen) {
    //     setRoOpen(false);
    //   }
    // }
  });

  const closeRoPanel = useRefFunc(() => {
    setCurRecord(undefined);
    setRoOpen(false);
  });

  const airlineMode = data ? data.mode : 0;

  const fastRenderProps = useMemo(() => {
    return {
      useFastRender,
      setUseFastRender,
      userFastRenderSelect,
      setUserFastRenderSelected,
      setDisableFastRenderChange,
    };
  }, [useFastRender, userFastRenderSelect]);

  const setRecord = useRefFunc((record: IFlightRecord) => {
    if (ref && ref.current?.setRecord) {
      ref.current.setRecord(record);
    } else {
      ubtUtils.error("setRecord is not a function");
    }
  });

  const tableContent = useMemo(
    () => (
      <PriceCalendar
        ref={ref}
        queryUrl={QUERY_URL.table}
        moduleCode={MODULE_CODE}
        chartTableCode={CHART_TABLE_CODE.table}
        queryExt={queryExt}
        isDemo={isDemo}
        curRecord={curRecord}
        setCurRecord={changeCurRecord}
        airlineMode={airlineMode}
        roOpen={roOpen}
        setRoOpen={setRoOpen}
        fastRenderProps={fastRenderProps}
      />
    ),
    [
      queryExt,
      isDemo,
      curRecord,
      changeCurRecord,
      airlineMode,
      roOpen,
      fastRenderProps,
    ]
  );

  const eterm = useMemo(() => {
    if (curRecord && airlineMode === 1) {
      const param: AdjustCabinRecord = {
        flightNo: curRecord.flightNo,
        takeoffdate: curRecord.takeoffdate,
        route: curRecord.route,
        // suggestPrice: curRecord.suggestPrice,
        // rawPrice: curRecord.rawPrice,
        // id: curRecord.id,
      };
      return (
        <ROContainer
          param={param}
          row={curRecord}
          setRecord={setRecord}
          closeRoPanel={closeRoPanel}
        />
      );
    } else {
      return undefined;
    }
  }, [curRecord, airlineMode, setRecord, closeRoPanel]);

  const changeFastRender = useRefFunc((flag: boolean) => {
    setUseFastRender(flag);
    setUserFastRenderSelected(flag);
  });

  return systemType === SystemType.airlines ? (
    <FlightManageProvider>
      <Page defaultDateMode="none" needToolBar={false}>
        {isDemo ? <HeaderAlert /> : undefined}
        <div>
          <Alert
            message={
              <span>
                <Switch
                  size="small"
                  checkedChildren="极速"
                  unCheckedChildren="普通"
                  checked={useFastRender}
                  onChange={changeFastRender}
                  disabled={disableFastRenderChange}
                ></Switch>
                <span style={{ marginLeft: 15 }}>
                  本模块查询数据量大，若遇到内存不足等卡顿或浏览器崩溃情况，可选择切换成极速样式节约内存消耗
                </span>
              </span>
            }
            banner
            showIcon={false}
            type="info"
          ></Alert>
        </div>
        <div
          className="content-white"
          style={{ borderBottom: "1px solid #0000001a" }}
        >
          <SearchArea
            setQueryExt={changeQueryExt}
            setAdjustFlag={setAdjustFlag}
            defaultAirline={airline}
            defaultFlightNo={flightNo}
            defaultRoute={route}
            defaultTakeoffDate={takeoffDate}
          />
        </div>
        <div
          id="flightManage"
          className="flightManage"
          style={{ display: "flex", margin: "0 20px" }}
        >
          <div
            style={{
              marginTop: 0,
              width: "100%",
              position: "relative",
              overflow: "hidden",
              background: "#fff",
              marginRight: 10,
            }}
          >
            {/* <Alert
              message={getSharkText(
                "config_page_takeoff_date_not_supported"
              )}
              type="info"
              banner
            />
            {!adjustFlag ? (
              <Alert
                message={getSharkText(
                  "config_page_flight_not_supported"
                )}
                type="warning"
                banner
              />
            ) : undefined} */}
            {queryExt?.filter?.route ? (
              airlineMode !== 0 ? (
                <>
                  <div style={{ position: "absolute", left: 200 }}>
                    {isLoading ? <Spin spinning={isLoading} /> : undefined}
                  </div>
                  <div>{tableContent}</div>
                  {/* <div className="flight-manage-cards">card1, card2, card3</div> */}
                  <div
                    className="flight-manage-difftime-table"
                    style={{ margin: "0 0px" }}
                  >
                    {curRecord ? (
                      <DetailTable
                        record={curRecord}
                        moduleCode={MODULE_CODE}
                        chartTableCode={CHART_TABLE_CODE.table}
                      />
                    ) : undefined}
                  </div>
                </>
              ) : (
                <Row gutter={[20, 20]}>
                  <Col span={24}>{tableContent}</Col>
                </Row>
              )
            ) : (
              <Result title="请选择航线" />
            )}
          </div>
          <div
            style={{
              width: 700,
              display: roOpen ? "block" : "none",
              flexShrink: 0,
            }}
          >
            {eterm}
          </div>
        </div>
      </Page>
      {!isDrawer && <NotificationCenter />}
    </FlightManageProvider>
  ) : (
    <></>
  );
};

export default FlightManage;
