import { InputNumber, Tooltip } from "antd";
import React, { ReactElement, useMemo } from "react";

export interface IInputItemProps {
  editable: boolean;
  value: number | null | undefined;
  originalValue: number | null | undefined;
  onChange: (v: number | null) => void;
}

/**
 * 编辑框
 */
const InputItem = (props: IInputItemProps): ReactElement => {
  const { editable, value, originalValue, onChange } = props;
  const input = useMemo(() => {
    const hasDiff = (value || originalValue) && value !== originalValue;
    return (
      <InputNumber
        size="small"
        value={value || null}
        onChange={onChange}
        disabled={!editable}
        placeholder="--"
        style={{ background: hasDiff ? "yellow" : undefined }}
      />
    );
  }, [editable, onChange, originalValue, value]);
  if (!editable) {
    return (
      <Tooltip title="请先选择航班航线并设置保存竞飞航班，独飞航线竞飞置空保存。">
        <span>{input}</span>
      </Tooltip>
    );
  }
  return input;
};
export default InputItem;
