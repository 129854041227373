import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Checkbox, Col, Popover, Row, Select } from "antd";
import useGlobal from "Store";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import {
  COMPARE_TYPE_PLC_NAME,
  DATE_FORMAT,
  DEFAULT_AIRLINE_COMPARE_TYPE_VALUE,
  DEFAULT_AIRPORT_COMPARE_TYPE_NAME,
} from "Constants";
import DateRangePicker from "../DateRangePicker";
import "./index.css";
import PreLastChain from "../PreLastChain";
import {
  AirlinesCardList,
  AirportCardList,
  ICard,
  IQueryExt,
} from "../../Interface";
import { Moment } from "moment";

import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { findKeyByValue } from "Utils/global";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

interface ISearchProps {
  onChangeVal: (v: IQueryExt) => void;
  onChangeCard: (v: ICard) => void;
  moduleCode?: string;
  chartTableCode?: string;
  title?: string;
  panelType: 0 | 1;
}

const defaultValControl = [DEFAULT_AIRPORT_COMPARE_TYPE_NAME];

const Search = (props: ISearchProps): ReactElement => {
  const { onChangeVal, panelType, onChangeCard } = props;
  const [range, setRange] = useState<Moment[]>([]);
  const [valControl, setValControl] = useState<string[]>(defaultValControl);
  const pickerRef = useRef<any>();

  const [globalState, actions] = useGlobal();
  const { queryCondition, airlinesQueryCondition, systemType } = globalState;
  const isAirport = isAirportMode(systemType);
  const [together, setTogether] = useState<number>(3);
  const cardList = isAirport ? AirportCardList : AirlinesCardList;
  const [cardCode, setCardCode] = useState<string>(
    cardList.filter((item: any) => item.type === panelType && item.checked)[0]
      .cardCode
  );
  const dateMode = panelType === 0 ? "lastMonth" : "nextThirtyDays";

  useEffect(() => {
    const dateStrArr = range.map((m: Moment) => m.format(DATE_FORMAT));
    if (!!onChangeVal && dateStrArr[0] !== undefined && cardCode !== "") {
      const type =
        findKeyByValue(COMPARE_TYPE_PLC_NAME, valControl[0], Number) ??
        DEFAULT_AIRLINE_COMPARE_TYPE_VALUE;
      const tmpQuery = isAirport ? queryCondition : airlinesQueryCondition;
      const query = {
        ...tmpQuery,
        startDate: dateStrArr[0],
        endDate: dateStrArr[1],
        useCompare: 1,
        compareType: type,
      };
      const option = cardList.filter((item) => item.cardCode === cardCode);
      if (option && option.length === 1) {
        onChangeCard(option[0]);
      }
      const val: IQueryExt = {
        query,
        ext: {
          cardCode,
          together,
        },
      };
      onChangeVal(val);
    }
  }, [
    range,
    valControl,
    onChangeVal,
    together,
    cardCode,
    airlinesQueryCondition,
    queryCondition,
  ]);

  const rangePickerChange = (val: Moment[]) => {
    setRange(val);
  };
  const onCardChange = (val: CheckboxValueType[]) => {
    const checked = val as string[];
    if (checked && checked.length > 1) {
      setCardCode(checked.filter((item) => item !== cardCode)[0]);
    }
  };

  const sharedHelp = useMemo(() => {
    return (
      <div>
        <div>{getSharkText("key.competitive_market_solo_excluded.hint")}</div>
        <div>{getSharkText("key.competitive_market_solo_included.hint")}</div>
        <div>{getSharkText("key.whole_market.hint")}</div>
      </div>
    );
  }, []);

  const extendTools = useMemo(() => {
    return (
      <>
        <Select defaultValue={3} onChange={setTogether} style={{ width: 170 }}>
          <Option value={1}>
            {getSharkText("config_page_shared_market_no_solo")}
          </Option>
          <Option value={2}>
            {getSharkText("config_page_shared_market_with_solo")}
          </Option>
          <Option value={3}>{getSharkText("key.whole_market.name")}</Option>
        </Select>
        <Popover content={sharedHelp}>
          <InfoCircleOutlined style={{ marginLeft: 3 }} />
        </Popover>
      </>
    );
  }, [sharedHelp]);

  return (
    <>
      <Row
        gutter={[{ xs: 8, sm: 16, md: 24 }, 20]}
        align="middle"
        className="search-row"
      >
        <Col>
          <DateRangePicker
            ref={pickerRef}
            defaultDateMode={dateMode}
            onChange={rangePickerChange}
            noHistoryToFuture
          ></DateRangePicker>
        </Col>
        <Col>
          <Checkbox.Group
            options={cardList
              .filter((item) => item.type === panelType)
              .map((item) => ({ label: item.cardLabel, value: item.cardCode }))}
            value={[cardCode]}
            onChange={onCardChange}
          />
        </Col>
        <Col>
          <PreLastChain
            defaultValue={defaultValControl}
            onChange={setValControl}
          ></PreLastChain>
        </Col>
        {!isAirport ? extendTools : undefined}
      </Row>
    </>
  );
};

export default Search;
