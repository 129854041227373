import React, {
  Key,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Col, Input, Row } from "antd";
import _ from "lodash";
import { IDownloadHeader } from "Interface";
import { TableRowSelection } from "antd/lib/table/interface";
import { downloadExcel } from "Utils/downloadXLSX";
import "./CustomTableV2.scss";
import { showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { EMPTY_ARRAY } from "Constants";
import useRefFunc from "Utils/useRefFunc";
import ProTable from "@ant-design/pro-table";
import DownloadBtn from "../DownloadBtn";

interface IProps<RecordType> {
  tableClass?: string;
  /**
   * 列配置
   */
  columns: IDownloadHeader[];
  /**
   * 数据源
   */
  dataSource: RecordType[];
  /**
   * 搜索字段名, 如果不配置则不会显示搜索框, 如果搜索列名配置错误会导致所有的数据都无法显示
   */
  searchKeys?: string[];
  /**
   * 搜索框占位文本
   */
  searchPlaceholder?: string;
  /**
   * 下载文件的名称, 如果配置为false则不显示下载按钮
   */
  downloadName?: string | false;
  /**
   * 自定义下载方法, 如果有值, 则不使用默认的下载方法
   */
  onDownload?: () => any;
  /**
   * 列勾选配置, 配置方式同antd配置, 如果不配置则不出现勾选列
   */
  rowSelection?: TableRowSelection<RecordType>;
  /**
   * 批量提交函数, 提交内容为勾选的列
   */
  batchSubmit?: (v: RecordType[]) => void;
  /**
   * 子标题, 显示在搜索框之后
   */
  subTitle?: string;
  /**
   * 表头附加内容, 比如新增, 过滤等
   */
  headExtends?: ReactNode;
  /**
   * 单击某一行时触发, 如果数据为空, 并且设置了默认焦点, 则传递null值
   */
  onRowClick?: (record: RecordType | null) => void;
  /**
   * 双击某一行时触发
   */
  onRowDblClick?: (record: RecordType) => void;
  /** 行高亮, 比如点选数据时行的焦点 */
  rowHighlight?: {
    /** 行id */
    value?: Key | null;
    /** 返回行或者null */
    onChange?: (row: RecordType) => void;
    /** 行id */
    defaultValue?: Key;
  };
  /**
   * tableProps
   */
  tableProps?: any;
  /**
   * 限制最多展示的条目数量
   */
  showLimit?: 1000;
  moduleCode: string;
  chartTableCode: string;
}

export interface ITableRef {
  download: () => void;
}

/**
 * 通用表格组件, 支持下载(自定义下载格式列需配置downloadFormatter), 过滤, 行点击双击, 批量操作等
 * 接收到数据后, 第一步先判断数据有没有ID, 如果没有ID, 则对所有数据赋值ID, 放入dataSourceWithId,
 * 第二步为所有数据赋值checked: false放入filterData
 */
export const CustomTableV2 = <
  T extends { [key: string]: any } & { key: string | number | bigint }
>(
  props: IProps<T>
  // ref: ForwardedRef<ITableRef>
): ReactElement => {
  const {
    tableClass,
    columns,
    dataSource,
    searchKeys,
    searchPlaceholder,
    downloadName = false,
    onDownload,
    rowSelection,
    batchSubmit,
    subTitle,
    headExtends,
    onRowClick,
    onRowDblClick,
    rowHighlight,
    tableProps,
    showLimit,
    moduleCode,
    chartTableCode,
  } = props;
  // id是antd table用来更新数据的依赖, 如果缺失很多数据更新操作会产生异常
  // feFocus标识点击, 每行数据被点击时, feFocus置位true
  type TWithId = T & { id: string };
  const [searchText, setSearchText] = useState<string>("");
  const [filterData, setFilterData] = useState<TWithId[]>([]);
  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  // #region 行高亮状态
  /** 高亮行ID */
  const [highlightRowKey, setHighlightRowKey] = useState<
    Key | null | undefined
  >(rowHighlight?.defaultValue);
  useEffect(() => {
    if (rowHighlight?.value !== undefined) {
      setHighlightRowKey(rowHighlight?.value);
    }
  }, [rowHighlight?.value]);
  // #endregion

  // #region 对一些快捷配置进行转换, 比如说百分比和小数位数
  const transColumns: IDownloadHeader[] = useMemo((): Array<
    IDownloadHeader<T>
  > => {
    const tmpColumns = columns.map((c) => {
      // 这里不能使用lodash的cloneDeep, 如果列中包含了react节点, 例如说明, 会导致cloneDeep需要漫长的时间来copy, 卡死页面
      const tmp = { ...c } as IDownloadHeader;
      if (tmp.isPercentVal) {
        if (!tmp.render) {
          tmp.render = (val: number) =>
            showNum(val * 100, "percentage", tmp.precision);
        }
        if (!tmp.downloadFormatter) {
          tmp.downloadFormatter = (val: number) =>
            showRawNum(val * 100, "percentage");
        }
      }
      if (tmp.precision !== undefined) {
        if (!tmp.render) {
          tmp.render = (val: number) => showNum(val, "num", tmp.precision);
        }
      }
      return tmp;
    });
    return tmpColumns;
  }, [columns]);
  // #endregion

  const rowSelectionConfig = useMemo(() => {
    const defaultRowSelection: TableRowSelection<T> = {
      // selectedRowKeys,
      onChange: (keys, rows) => {
        console.log("selected :", keys, rows);
        setSelectedRows(rows);
      },
    };
    if (rowSelection) {
      return { ...defaultRowSelection, ...rowSelection };
    } else {
      return undefined;
    }
  }, [rowSelection]);

  const dataSourceWithId: TWithId[] = useMemo(() => {
    if (dataSource && dataSource.length) {
      // 为数据设置id
      let dataWithId = [];
      if (dataSource[0].id) {
        dataWithId = dataSource;
      } else {
        dataWithId = dataSource.map((d, i) => {
          return {
            ...d,
            id: `${i}`,
          };
        });
      }
      return dataWithId;
    } else {
      if (onRowClick) {
        onRowClick(null);
      }
      return EMPTY_ARRAY;
    }
  }, [dataSource, onRowClick]);

  useEffect(() => {
    if (searchKeys) {
      const cloneData = _.cloneDeep(dataSourceWithId);
      const searchTextLower = searchText.toLocaleLowerCase();
      const filter = cloneData.filter((data: T) => {
        let isPass = false;
        for (const key of searchKeys) {
          const lowerValue = data[key]?.toLocaleLowerCase();
          if (lowerValue.includes(searchTextLower)) {
            isPass = true;
            break;
          }
        }
        return isPass;
      });
      setFilterData(filter);
    } else {
      setFilterData(dataSourceWithId);
    }
  }, [dataSourceWithId, searchKeys, searchText]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDownload = useCallback(() => {
    if (downloadName) {
      if (onDownload) {
        onDownload();
      } else {
        downloadExcel(transColumns, dataSource, downloadName);
      }
    }
  }, [dataSource, downloadName, onDownload, transColumns]);

  const setHighlightRowClass = useRefFunc((record: TWithId) => {
    if (highlightRowKey && highlightRowKey === record.id) {
      return ".row-checked";
    }
    return "";
  });

  const onRow = (record: TWithId) => ({
    onClick: () => {
      if (rowHighlight) {
        if (rowHighlight.onChange) {
          rowHighlight.onChange(record);
        } else {
          setHighlightRowKey(record.id);
        }
      }
      if (onRowClick) {
        onRowClick(record);
      }
    },
    onDoubleClick: () => {
      if (onRowDblClick) {
        onRowDblClick(record);
      }
    },
  });

  const showData = useMemo(() => {
    return showLimit ? filterData.slice(0, 1000) : filterData;
  }, [filterData, showLimit]);

  return (
    <>
      <Row align="middle" gutter={32}>
        {batchSubmit && rowSelection ? (
          <Col>
            <Button type="primary" onClick={() => batchSubmit(selectedRows)}>
              {getSharkText("key.batchsubmit.button")}
            </Button>
            {subTitle && (
              <span
                style={{
                  marginLeft: 20,
                  color: "#ff25b2",
                  fontWeight: "bold",
                  fontSize: "120%",
                }}
              >
                {subTitle}
              </span>
            )}
          </Col>
        ) : undefined}
        {searchKeys ? (
          <Col>
            <Input.Search
              value={searchText}
              placeholder={searchPlaceholder}
              onChange={handleSearch}
              size={tableProps?.size}
            />
          </Col>
        ) : undefined}
        {headExtends ? <Col>{headExtends}</Col> : undefined}
        {downloadName !== false ? (
          <Col style={{ flex: 1, textAlign: "right" }}>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        ) : undefined}
      </Row>
      <Row style={{ marginTop: 16 }} className="custom-table-v2">
        <Col span={24}>
          <ProTable
            className={tableClass}
            defaultExpandAllRows={true}
            showSorterTooltip={false}
            expandIconColumnIndex={-1}
            tableLayout="fixed"
            dataSource={showData}
            columns={transColumns}
            scroll={{ y: 550 }}
            rowSelection={rowSelectionConfig}
            rowClassName={setHighlightRowClass}
            onRow={onRow}
            search={false}
            options={false}
            pagination={{ defaultPageSize: 10 }}
            {...tableProps}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomTableV2;
export type ICustomTableV2Props<T> = IProps<T>;
