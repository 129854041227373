import {
  GetDynamicFarePriceRequestType,
  IMBatchCmdGenerateRequestType,
  IMBatchRequestType,
  IMCmdGenerateRequestType,
  IMRequestType,
  SetDynamicFarePriceRequestType,
} from "Interface";
import { useFetch } from "Utils";
import { useRef } from "react";
import { MODULE_CODE } from "../../fetchCode";
import useRefFunc from "Utils/useRefFunc";

/** 调舱服务 */
const useServices = () => {
  // #region IMGenerate
  const [, doIMGenerate] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    debugId: "IMGenerate",
    ext: {},
    lazey: true,
  });

  const IMCmdGenerate = useRefFunc(
    async (requestContent: IMCmdGenerateRequestType) => {
      return doIMGenerate({
        ext: {
          serviceCode: "22299",
          operation: "IMCmdGenerate",
          requestBody: JSON.stringify(requestContent),
        },
      });
    }
  );
  // #endregion

  // #region IMBatchGenerate
  const [, doIMBatchGenerate] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    debugId: "IMBatchGenerate",
    ext: {},
    lazey: true,
  });

  const IMBatchCmdGenerate = useRefFunc(
    async (requestContent: IMBatchCmdGenerateRequestType) => {
      return doIMBatchGenerate({
        ext: {
          serviceCode: "22299",
          operation: "IMBatchCmdGenerate",
          requestBody: JSON.stringify(requestContent),
        },
      });
    }
  );
  // #endregion

  // #region IM
  const [, doIM] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    debugId: "IM",
    ext: {},
    lazey: true,
  });
  const IM = useRefFunc(async (requestContent: IMRequestType) => {
    return doIM({
      ext: {
        serviceCode: "22299",
        operation: "IM",
        requestBody: JSON.stringify(requestContent),
      },
    });
  });
  // #endregion

  // #region IMBatch
  const [, doIMBatch] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    debugId: "IMBatch",
    ext: {},
    lazey: true,
  });
  const IMBatch = useRefFunc(async (requestContent: IMBatchRequestType) => {
    return doIMBatch({
      ext: {
        serviceCode: "22299",
        operation: "IMBatch",
        requestBody: JSON.stringify(requestContent),
      },
    });
  });
  // #endregion

  // #region GetDynamicFarePrice
  const [, doGetDynamicFarePrice] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    debugId: "GetDynamicFarePrice",
    ext: {},
    lazey: true,
  });
  const getDynamicFarePrice = useRefFunc(
    async (requestContent: GetDynamicFarePriceRequestType) => {
      return doGetDynamicFarePrice({
        ext: {
          serviceCode: "22299",
          operation: "GetDynamicFarePrice",
          requestBody: JSON.stringify(requestContent),
        },
      });
    }
  );
  // #endregion

  // #region SetDynamicFarePrice
  const [, doSetDynamicFarePrice] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: null,
    },
    debugId: "SetDynamicFarePrice",
    ext: {},
    lazey: true,
  });
  const setDynamicFarePrice = useRefFunc(
    async (requestContent: SetDynamicFarePriceRequestType) => {
      return doSetDynamicFarePrice({
        ext: {
          serviceCode: "22299",
          operation: "SetDynamicFarePrice",
          requestBody: JSON.stringify(requestContent),
        },
      });
    }
  );
  // #endregion

  const services = useRef({
    IMCmdGenerate,
    IMBatchCmdGenerate,
    IM,
    IMBatch,
    getDynamicFarePrice,
    setDynamicFarePrice,
  }).current;

  return services;
};
export default useServices;
