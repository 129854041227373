import { Card, Col, Popover, Radio, Row, Spin } from "antd";
import Holiday from "Components/Holiday";
import { ECompareTypeV2, IHoliday, ISeries, SystemType, Tab } from "Interface";
import Page from "Layout/Page";
import { ICard } from "Page/AI/IndustryTrend/Interface";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import useGlobalState from "Store";
import Cards from "./Card/Cards";
import AirlinesRanking from "./Components/AirlinesRanking";
import HolidayTable from "./Components/HolidayTable";
import LinesCharts from "./Components/LinesCharts";
import MapChart from "./Components/MapChart";
import { QUERY_URL, MODULE_CODE, CHART_TABLE_CODE } from "./fetchCode";
import { IHolidayQueryExt } from "./HolidayAnalysisCommon";
import Profile from "./Components/Profile";
import { cloneDeep } from "lodash";
import {
  CompareTypes,
  COMPARE_TYPE_PER_NAME,
  COMPARE_TYPE_VALUE_NAME,
  EYearCompareType,
} from "Constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { genSeries } from "./chartSeries";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 *
 */

const rankTabAirport: Tab[] = [
  {
    title: getSharkText("key.search_index.name"),
    chartTableCode: "test",
    type: 0,
  },
  {
    title: getSharkText("key.yoy_increment.name"),
    chartTableCode: "test",
    type: 1,
  },
];
const rankTabAirlines = rankTabAirport;

const defaultTransCard: ICard = {
  cardCode: "",
  cardLabel: "",
  isPercentVal: false,
  type: 0,
  checked: true,
};

const directionTitleHelp = (
  <div style={{ width: 320, color: "#eee" }}>
    <p>
      {getSharkText("config_page_passenger_flow_direction_index")}=
      {getSharkText("config_page_inbound_passengers")}/
      {getSharkText("config_page_outbound_passengers")}
      *100%，将进出港旅客动向分成三类。
    </p>
    <p>
      <span style={{ color: "#e84c3d", fontWeight: "bold" }}>
        {getSharkText("config_page_red_color")}
      </span>
      代表旅客流向指数高于105%，以旅客流入为主；
    </p>
    <p>
      <span style={{ color: "#26af5f", fontWeight: "bold" }}>
        {getSharkText("config_page_green")}
      </span>
      {getSharkText("config_page_passenger_flow_index_below_95")}
    </p>
    <p>
      <span style={{ color: "#ff0", fontWeight: "bold" }}>
        {getSharkText("config_page_yellow")}
      </span>
      {getSharkText("config_page_inbound_and_outbound_passengers_are_balanced")}
    </p>
    <p>
      {getSharkText(
        "config_page_understand_population_mobility_and_market_travel_dynamics"
      )}
    </p>
  </div>
);

const holidayCompareTypes = (() => {
  const holidayCompareContent = cloneDeep(CompareTypes);
  holidayCompareContent[ECompareTypeV2.YEAR].introduce = getSharkText(
    "config_page_holiday_same_period_last_year"
  );
  holidayCompareContent[ECompareTypeV2.YEAR_BEFORE_LAST].introduce =
    getSharkText("config_page_holiday_same_period_year_last_last");
  holidayCompareContent[ECompareTypeV2.YEAR_LAST_LAST].introduce = getSharkText(
    "config_page_holiday_same_period_year_last_last"
  );
  holidayCompareContent[ECompareTypeV2.YEAR_4_BEFORE].introduce = getSharkText(
    "config_page_holiday_same_period_four_years_ago"
  );
  holidayCompareContent[ECompareTypeV2.YEAR_5_BEFORE].introduce = getSharkText(
    "config_page_holiday_same_period_five_years_ago"
  );
  return holidayCompareContent;
})();

const HolidayAnalysis = (): ReactElement => {
  const [globalState] = useGlobalState();
  const { systemType, queryCondition, airlinesQueryCondition } = globalState;
  const isAirport = systemType === SystemType.airport;
  const compareType = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  const [hasCardData, setHasCardData] = useState(false);
  const [holiday, setHoliday] = useState<IHoliday>();
  const [queryExt, setQueryExt] = useState<IHolidayQueryExt>();
  const [curQueryExt, setCurQueryExt] = useState<any>();
  const [lyQueryExt, setLyQueryExt] = useState<any>();
  const [duration, setDuration] = useState<number>(1);
  const [tabs, setTabs] = useState<Tab[]>(rankTabAirlines);
  const [curCard, setCurCard] = useState({
    cardCode: "",
    cardName: "",
    cardHint: "",
    cardValue: 0,
    avgValue: 0,
    compareValue: 0,
  });
  const [transCard, setTransCard] = useState<ICard>(defaultTransCard);

  const prefix = COMPARE_TYPE_PER_NAME[compareType];
  const [totalSeries, setTotalSeries] = useState<ISeries[]>(
    genSeries(transCard, prefix, isAirport)
  );

  useEffect(() => {
    setTotalSeries(genSeries(transCard, prefix, isAirport));
  }, [transCard, prefix, isAirport]);

  useEffect(() => {
    if (curCard.cardCode) {
      setTransCard({
        cardCode: curCard.cardCode,
        cardLabel: curCard.cardName,
        isPercentVal: true,
        type: 0,
        checked: true,
      });
      const tmp = cloneDeep(rankTabAirlines);
      tmp[0].title = curCard.cardName;
      if (curCard.cardCode === "order_index") {
        tmp[0].isPercentVal = true;
        setTabs(tmp.slice(0, 1));
      } else {
        setTabs(tmp);
      }
    } else {
      setTransCard(defaultTransCard);
    }
  }, [curCard]);

  useEffect(() => {
    if (holiday) {
      setCurQueryExt({
        holiday: { ...holiday, holidayType: duration },
        type: 0,
      });
      setLyQueryExt({
        holiday: { ...holiday, holidayType: duration },
        type: 1,
      });
    }
  }, [holiday, duration]);

  useEffect(() => {
    if (holiday) {
      setQueryExt({ holiday });
    }
  }, [holiday]);

  const extendTools = useMemo(
    () => (
      <Col>
        <Holiday onChange={setHoliday} />
      </Col>
    ),
    []
  );

  const changeDuration = (e: any) => {
    setDuration(e.target.value);
  };

  const directionTitle = (
    <>
      <span style={{ marginRight: 20 }}>
        {getSharkText("config_page_PaAiHoIn_passenger_flow_direction")}
        <Popover
          content={directionTitleHelp}
          overlayClassName="dashboard-val-control-popover"
        >
          <InfoCircleOutlined style={{ marginLeft: 10 }} />
        </Popover>
      </span>
      <Radio.Group value={duration} onChange={changeDuration}>
        <Radio value={1}>
          <span>{getSharkText("config_page_before_festival")} </span>
          <Popover
            content={getSharkText("config_page_two_days_before_to_middle_day")}
          >
            <InfoCircleOutlined />
          </Popover>
        </Radio>
        <Radio value={2}>
          <span>{getSharkText("config_page_after_festival")} </span>
          <Popover
            content={getSharkText("config_page_middle_day_to_two_days_after")}
          >
            <InfoCircleOutlined />
          </Popover>
        </Radio>
      </Radio.Group>
    </>
  );

  return (
    <Page
      defaultDateMode="none"
      extendTools={extendTools}
      airlinesCompareTypes={EYearCompareType}
      compareContent={holidayCompareTypes}
    >
      <div className="content">
        <Row style={{ marginBottom: 16 }}>
          <Col xs={24}>
            <Card bordered={false} title="趋势概览">
              <Cards
                queryUrl={QUERY_URL[systemType].cards}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].cards}
                curCardCode={curCard.cardCode}
                setCurCard={setCurCard}
                setHasCardData={setHasCardData}
                queryExt={queryExt}
              />
              <Spin spinning={!hasCardData}>
                <Row
                  gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
                  style={{ marginTop: 16 }}
                >
                  <Col xxl={14} xl={24}>
                    <LinesCharts
                      queryUrl={QUERY_URL[systemType].barLineCharts}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={
                        CHART_TABLE_CODE[systemType].barLineCharts
                      }
                      card={transCard}
                      ext={queryExt}
                      totalSeries={totalSeries}
                    />
                  </Col>
                  <Col xxl={10} xl={24}>
                    <AirlinesRanking
                      title={getSharkText("key.top_10_routes.name")}
                      queryUrl={QUERY_URL[systemType].topCity}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={CHART_TABLE_CODE[systemType].topCity}
                      tab={tabs}
                      sortable
                      cardCode={curCard.cardCode}
                      ext={queryExt}
                    />
                    {/* )} */}
                  </Col>
                  <Col span={24}>
                    <HolidayTable ext={queryExt} card={transCard} />
                  </Col>
                </Row>
              </Spin>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <Col xs={24}>
            <Card bordered={false} title={directionTitle}>
              <Row>
                <Col span={24} xxl={12} style={{ padding: 10 }}>
                  <div style={{ marginTop: -20, fontSize: "110%" }}>
                    {holiday?.year}
                    {holiday?.holidayName}
                  </div>
                  <MapChart
                    queryUrl={QUERY_URL[systemType].map}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].map}
                    ext={curQueryExt}
                  />
                </Col>
                <Col span={24} xxl={12} style={{ padding: 10 }}>
                  <div style={{ marginTop: -20, fontSize: "110%" }}>
                    {COMPARE_TYPE_VALUE_NAME[compareType]}
                    {holiday?.holidayName}
                  </div>
                  <MapChart
                    queryUrl={QUERY_URL[systemType].map}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].map}
                    ext={lyQueryExt}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginBottom: 0 }}>
          <Col xs={24}>
            <Card
              bordered={false}
              title={getSharkText("config_page_passenger_portrait")}
            >
              <Profile ext={queryExt} />
            </Card>
          </Col>
        </Row>
      </div>
    </Page>
  );
};
export default HolidayAnalysis;
