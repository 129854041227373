import { FlightsSeasonPlanFilter } from "@ctrip/flt-bi-flightai-airlines";
import { Button, Checkbox, Col, Row, Select } from "antd";
import CheckButtons from "Components/CheckButtons/CheckButtons";
import { Area as AreaService } from "@ctrip/flt-bi-flightai-airports";
import AirportSingleSelector from "Components/AirportGroup/AirportSingleSelector";
import Airlines from "Components/Toolbar/Airlines";
import { DATE_FORMAT, DEFAULT_AREA } from "Constants";
import { AirlinesQueryCondition, Area, IDateMode } from "Interface";
import { cloneDeep } from "lodash";
import { Moment } from "moment";
import React, { Key, ReactElement, useEffect, useMemo, useState } from "react";
import useGlobalState from "Store";
import { getDateRange, useFetch, getUserAirlines } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { G } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { getDefaultAirport } from "Utils/user";

const { Option } = Select;

export interface ISearchAreaProps {
  onChange: (v: FlightsSeasonPlanFilter) => void;
  onChangeQuery: (v: Partial<AirlinesQueryCondition>) => void;
}

const scheduleOptions = G.formatKeyOptions(
  Array.from({ length: 7 }, (v, i) => (i + 1).toString())
);

const defaultDateMode: IDateMode = "lastWeek";

const GetFlightSeasons = () => {
  const flightSeason: string[] = [
    "W2023",
    "S2023",
    "W2022",
    "S2022",
    "W2021",
    "S2021",
    "W2020",
  ];
  return flightSeason;
};
/**
 *  航班时刻站点资源-筛选器
 */
const SearchArea = (props: ISearchAreaProps): ReactElement => {
  const [globalState, actions] = useGlobalState();
  const { userInfo } = globalState;
  const userDefaultAirport = useMemo(
    () => getDefaultAirport(userInfo),
    [userInfo]
  );
  const { setQueryConditionAirport } = actions;
  const [selected, setSelected] = useState<string[]>(
    userDefaultAirport ? [userDefaultAirport.code] : ["SHA"]
  );
  const [isAirlinesFilter, setIsAirlinesFilter] = useState<boolean>(true);

  const [selectedGroup, setSelectedGroup] = useState<Area[]>([]);
  const defaultSingle: Area = useMemo(() => {
    return userDefaultAirport
      ? {
          areaCode: userDefaultAirport.code,
          areaName: userDefaultAirport.name,
          areaType: 5,
        }
      : {
          areaCode: "SHA",
          areaName: getSharkText("config_page_shanghai_hongqiao"),
          areaType: 5,
        };
  }, []);
  const [selectedSingle, setSelectedSingle] = useState<Area[]>([defaultSingle]);

  const { onChange, onChangeQuery } = props;
  const [dateRange] = useState<Moment[]>(getDateRange(defaultDateMode) || []);
  const [schedule, setSchedule] = useState<Key[]>([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
  ]);
  const [filterAirlines, setFilterAirlines] = useState<string>(() => {
    const userAirlines = getUserAirlines(userInfo.roleList);
    return userAirlines.map((u) => u.code).join(",");
  });

  const handleChangeDates = useRefFunc((v: Moment[]) => {
    if (v.length !== 2) return;
    const startDate = v[0].format(DATE_FORMAT);
    const endDate = v[1].format(DATE_FORMAT);
    onChangeQuery({
      startDate,
      endDate,
    });
  });

  const handleSearch = useRefFunc(() => {
    const singleAirports: string[] = selectedSingle.map(
      (a: Area) => a.areaCode
    );
    const groupAirports: string[] = [];

    handleChangeDates(dateRange);
    const filter: FlightsSeasonPlanFilter = {
      schedule: schedule as string[],
      filterAirlines: filterAirlines as string,
      arrivalHour: checkedBoxList.includes("arrivalHour") ? 1 : 0,
      takeoffHour: checkedBoxList.includes("takeoffHour") ? 1 : 0,
      site: singleAirports.concat(groupAirports),
      season: selectFS as string,
    };
    onChange(filter);
  });

  useEffect(() => {
    handleSearch();
  }, []);

  const handleChange = useRefFunc(
    (selectedSingle: Area[], selectedGroup: Area[]) => {
      setSelected(
        selectedSingle
          .map((s: Area) => s.areaCode)
          .concat(selectedGroup.map((g: Area) => g.areaName))
      );
      // 用于查询数据的机场
      const singleAirports: string[] = selectedSingle.map(
        (a: Area) => a.areaCode
      );
      const singleAirportsName: string[] = selectedSingle.map(
        (a: Area) => a.areaName
      );
      const groupAirports: string[] = [];
      const groupAirportsName: string[] = [];
      selectedGroup.forEach((g: Area) => {
        g.children?.forEach((c: Area) => {
          groupAirports.push(c.areaCode);
          groupAirportsName.push(c.areaName);
        });
      });

      const total = selectedGroup.concat(selectedSingle);
      const area =
        total && total.length
          ? {
              areaType: total[0].areaType,
              areaCode: total.map((m) => m.areaCode).join(","),
              areaName: total.map((m) => m.areaName).join(","),
              areaNameEn: total.map((m) => m.areaNameEn).join(","),
              pinyin: null,
              children: null,
            }
          : (DEFAULT_AREA as AreaService);

      setQueryConditionAirport({
        airport: singleAirports.concat(groupAirports).join(","),
        airportName: singleAirportsName.concat(groupAirportsName).join(","),
        originalAirport: area,
        area,
      });
    }
  );
  const onSingleChange = useRefFunc((v: Area) => {
    const tmpArr = cloneDeep(selectedSingle);
    if (selected.includes(v.areaCode)) {
      return;
    }
    tmpArr.push(v);
    setSelectedGroup([]);
    setSelectedSingle(tmpArr);
    handleChange(tmpArr, []);
  });
  const onDeselect = (v: string) => {
    if (selectedSingle.find((s: Area) => s.areaCode === v)) {
      const tmp = cloneDeep(selectedSingle);
      const rst = tmp.filter((g: Area) => g.areaCode !== v);
      setSelectedSingle(rst);
      handleChange(rst, []);
    } else {
      const tmp = cloneDeep(selectedGroup);
      const rst = tmp.filter((t: Area) => t.areaCode !== v);
      setSelectedGroup(rst);
      handleChange([], rst);
    }
  };

  const optionsBox = [
    {
      label: getSharkText("config_page_takeoff_time"),
      value: "takeoffHour",
    },
    {
      label: getSharkText("config_page_landing_time"),
      value: "arrivalHour",
    },
  ];

  const [checkedBoxList, setCheckedBoxList] = useState(["takeoffHour"]);

  const onChangeBox = (list: any) => {
    if (list.length === 0) {
      setCheckedBoxList(["takeoffHour"]);
    } else {
      setCheckedBoxList(list);
    }
  };
  const [{ data }] = useFetch({
    url: "findAirlines",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
  });
  const airlines = useMemo(() => {
    return data.map((d: any) => ({
      code: d.airlinesCode,
      name: d.airlinesName,
    }));
  }, [data]);

  const [selectFS, setSelectedValue] = useState(GetFlightSeasons()[0]);

  const handleSelectChange = (value: string) => {
    setSelectedValue(value);
  };

  const flightSeason = useMemo(() => {
    return GetFlightSeasons();
  }, []);

  return (
    <Row
      align="middle"
      gutter={[10, 20]}
      style={{ marginTop: 0, padding: "15px 0px" }}
    >
      <Col>
        <span>{getSharkText("config_page_flight_season")}：</span>
        <Select
          showSearch
          value={selectFS}
          style={{ width: 100 }}
          defaultValue={selectFS}
          onChange={handleSelectChange}
          optionFilterProp="children"
        >
          {flightSeason.map((item) => (
            <Option key={item} value={item} label={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Col>
      <Col>
        <Checkbox
          checked={isAirlinesFilter}
          onChange={(e) => setIsAirlinesFilter(e.target.checked)}
          style={{ marginRight: 5 }}
        />
        <span>{getSharkText("config_page_airline_filter")}：</span>
        <Airlines
          value={filterAirlines}
          airlines={airlines}
          disabled={!isAirlinesFilter}
          onChange={(v) => setFilterAirlines(v.code)}
          allowEmpty
        />
      </Col>
      <Col>
        <span>{getSharkText("config_page_site")}：</span>
        <Select
          mode="tags"
          style={{ minWidth: 200 }}
          dropdownMatchSelectWidth={false}
          value={selected}
          onDeselect={onDeselect}
          dropdownRender={() => (
            <Row style={{ width: 400 }}>
              <Col span={20}>
                <div style={{ height: 300, overflow: "scroll" }}>
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    {
                      <AirportSingleSelector
                        airports={
                          userDefaultAirport
                            ? [
                                {
                                  areaCode: userDefaultAirport.code,
                                  areaName: userDefaultAirport.name,
                                  areaType: 5,
                                },
                              ]
                            : []
                        }
                        onChange={onSingleChange}
                      />
                    }
                  </div>
                </div>
              </Col>
            </Row>
          )}
        ></Select>
      </Col>
      <Col>
        <Checkbox.Group
          options={optionsBox}
          value={checkedBoxList}
          defaultValue={checkedBoxList}
          onChange={onChangeBox}
        />
      </Col>
      <Col>
        <CheckButtons
          options={scheduleOptions}
          value={schedule}
          onChange={setSchedule}
        />
      </Col>
      <Col>
        <Button onClick={handleSearch} type="primary">
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};
export default SearchArea;
