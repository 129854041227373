import React, { useMemo } from "react";
import { Spin } from "antd";
import _, { round, uniqBy } from "lodash";
import { ISeries } from "Interface";
import { UserProfileChart } from "@ctrip/flt-bi-flightai-airlines";
import { rowToColumn } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarChartsProps {
  data: UserProfileChart[];
  height?: number;
  loading: boolean;
  chartConfig: Record<string, unknown>;
}

const SimpleLineCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const { data, height = 300, loading, chartConfig } = props;
  const chartData = useMemo(() => {
    const tmp = rowToColumn(data, ["day"], "tagCode", [
      "value",
      "compareValue",
    ]);
    // 将所有数据转为百分比
    tmp.forEach((t: any) => {
      let totalValue = 0;
      let totalCompare = 0;
      Object.keys(t).forEach((key) => {
        if (key.indexOf("value") >= 0) {
          totalValue += t[key] || 0;
        }
        if (key.indexOf("compareValue") >= 0) {
          totalCompare += t[key] || 0;
        }
      });
      Object.keys(t).forEach((key) => {
        if (key.indexOf("value") >= 0 && totalValue) {
          t[key] = t[key] / totalValue;
        }
        if (key.indexOf("compareValue") >= 0 && totalCompare) {
          t[key] = t[key] / totalCompare;
        }
      });
    });
    tmp.sort((a: any, b: any) => {
      return moment(a.day).valueOf() - moment(b.day).valueOf();
    });
    return tmp;
  }, [data]);

  const series: ISeries[] = useMemo(() => {
    const tmpSeries = uniqBy(data, (d) => d.tagCode);
    tmpSeries.sort((a, b) => {
      if (a.tagCode && b.tagCode) {
        return b.tagCode.localeCompare(a.tagCode);
      }
      return 0;
    });
    return tmpSeries.map((t) => ({
      type: "line",
      name:
        t.tagName === "unkown" || !t.tagName
          ? getSharkText("key.unknown.name")
          : t.tagName,
      encode: { x: "day", y: t.tagCode + "value" || "value" },
      lineStyle: {
        width: 3,
      },
    }));
  }, [data]);

  const option = {
    dataset: {
      source: chartData,
    },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    grid: {
      show: true,
      top: 50,
      bottom: 50,
      left: 100,
      right: 100,
      backgroundColor: "#fff",
      borderWidth: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = moment(params[0].data.day).format(DATE_FORMAT);
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const name = item.seriesName;
          const value = item.data[item.dimensionNames[item.encode.y[0]]];
          const valueStr = `${round(value * 100, 2)}%`;
          const val = `${name}: ${valueStr}`;
          dom = dom + "<br />" + dot + val;
        });
        dom = day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisLabel: {
        formatter: (v: string) => {
          return moment(v).format(DATE_FORMAT);
        },
      },
      // show: false,
      // axisLine: {
      //   show: false,
      // },
      // axisTick: {
      //   show: false,
      // },
    },
    yAxis: {
      type: "value",
      splitNumber: 3,
      max: 1,
      axisLabel: {
        formatter: (v: number) => round(v * 100) + "%",
      },
    },
    series,
  };

  const mergedOption = chartConfig ? _.merge({}, option, chartConfig) : option;
  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={mergedOption}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default SimpleLineCharts;
