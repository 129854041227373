import HeaderAlert from "Components/HeaderAlert";
import { WingonFilter } from "@ctrip/flt-bi-flightai-partners";
import Page from "Layout/Page";
import React, { ReactElement, useState } from "react";
import { useLocation } from "react-router-dom";
import useGlobalState from "Store";
import { getModule, getModuleNameFromPath } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import TopFilter from "./Components/TopFilter";
import WingonPanel from "./Components/WingonPanel";
import { EPanelType } from "./WingonCommon";

/**
 * 永安直通车
 */
const WingonOverview = (): ReactElement => {
  const location = useLocation();
  const [globalState] = useGlobalState();
  const { systemType, userInfo } = globalState;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const isDemo = (module && module.moduleStatus === 0) || false;
  const [queryExt, setQueryExt] = useState<WingonFilter>();

  return (
    <Page needToolBar={false} defaultDateMode="nextThirtyDays">
      <div className="content-white">
        {isDemo ? <HeaderAlert /> : undefined}
        <TopFilter isDemo={isDemo} onChange={setQueryExt} />
      </div>
      {queryExt ? (
        <>
          <WingonPanel
            panelType={EPanelType.Search}
            title={getSharkText("config_page_PaAiWiIn_query_analysis")}
            queryExt={queryExt}
          />
          <WingonPanel
            panelType={EPanelType.Order}
            title={getSharkText("config_page_PaAiWiIn_order_analysis")}
            queryExt={queryExt}
          />
        </>
      ) : undefined}
    </Page>
  );
};
export default WingonOverview;
