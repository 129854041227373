import React, { useState, useEffect, useMemo } from "react";
import { Modal, message, Row, Col, Checkbox, Popconfirm, Select } from "antd";
import { UnitList, Role, RoleItem, RoleType } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import SelectAirports from "../SelectAirports";
import SelectAirlines from "../SelectAirlines";
import useRefFunc from "Utils/useRefFunc";

const { Option } = Select;

interface RoleListEditProps {
  curUnit: UnitList;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  refetch: () => void;
}

const RoleListEdit: React.FC<RoleListEditProps> = (
  props: RoleListEditProps
) => {
  const { visible, setVisible, curUnit, refetch } = props;
  const [airportRole, setAirportRole] = useState<RoleItem>({
    id: null,
    roleType: 1,
    permissionCode: "",
    permissionName: "",
    groupId: curUnit.unit.id,
  });
  const [airlineRole, setAirlineRole] = useState<RoleItem>({
    id: null,
    roleType: 2,
    permissionCode: "",
    permissionName: "",
    permissionRoute: "",
    groupId: curUnit.unit.id,
  });
  const [partnerRole, setPartnerRole] = useState<RoleItem>({
    id: null,
    roleType: 3,
    permissionCode: "",
    permissionName: "",
    groupId: curUnit.unit.id,
  });
  const [isCheckedAirport, setIsCheckedAirport] = useState(false);
  const [isCheckedAirAline, setIsCheckedAirline] = useState(false);
  const [isCheckedPartner, setIsCheckedPartner] = useState(false);
  const [isCheckedAll, setIsCheckedAll] = useState(false);

  const changeAirportAll = useRefFunc((v: boolean) => {
    setIsCheckedAll(v);
    if (v) {
      setAirportRole({
        ...airportRole,
        permissionCode: "*",
        permissionName: "*",
      });
    } else {
      setAirportRole({
        ...airportRole,
        permissionCode: "",
        permissionName: "",
      });
    }
  });

  const [{ isLoading }, doFetch] = useFetch({
    url: "bindingUnitRole",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      roleList: [],
      type: 1,
    },
    lazey: true,
    onSuccess: () => {
      message.success("修改成功");
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_modify_fail"));
    },
  });

  useEffect(() => {
    if (visible) {
      const defaultAirportRole = {
        id: null,
        roleType: 1,
        permissionCode: "",
        permissionName: "",
        groupId: curUnit.unit.id,
      };

      const defaultAirlineRole = {
        id: null,
        roleType: 2,
        permissionCode: "",
        permissionName: "",
        groupId: curUnit.unit.id,
      };

      const defaultPartnerRole = {
        id: null,
        roleType: 3,
        permissionCode: "",
        permissionName: "",
        groupId: curUnit.unit.id,
      };

      if (!_.isEmpty(curUnit.roleList)) {
        const airportUnitRole = curUnit.roleList.find(
          (role) => role.roleType === 1
        );
        const airAlineUnitRole = curUnit.roleList.find(
          (role) => role.roleType === 2
        );
        const partnerUnitRole = curUnit.roleList.find(
          (role) => role.roleType === 3
        );
        if (airportUnitRole) {
          if (airportUnitRole.permissionCode === "*") {
            setIsCheckedAll(true);
          } else {
            setIsCheckedAll(false);
          }
          setAirportRole({
            ...airportUnitRole,
            groupId: curUnit.unit.id,
          });
          setIsCheckedAirport(true);
        } else {
          setAirportRole(defaultAirportRole);
          setIsCheckedAirline(false);
        }

        if (airAlineUnitRole) {
          setAirlineRole({
            ...airAlineUnitRole,
            groupId: curUnit.unit.id,
          });
          setIsCheckedAirline(true);
        } else {
          setAirlineRole(defaultAirlineRole);
          setIsCheckedAirline(false);
        }

        if (partnerUnitRole) {
          setPartnerRole({
            ...partnerUnitRole,
            groupId: curUnit.unit.id,
          });
          setIsCheckedPartner(true);
        } else {
          setPartnerRole(defaultPartnerRole);
          setIsCheckedPartner(false);
        }
      } else {
        setAirportRole(defaultAirportRole);
        setAirlineRole(defaultAirlineRole);
        setPartnerRole(defaultPartnerRole);
        setIsCheckedAirport(false);
        setIsCheckedAirline(false);
        setIsCheckedPartner(false);
      }
    }
  }, [curUnit, visible]);

  const unbindRole = useRefFunc((roleItem: Role) => {
    doFetch({
      ext: {
        roleList: [roleItem],
        type: 2,
      },
    });
  });

  const bindRole = () => {
    const roleList = [];
    if (airportRole.permissionCode) {
      roleList.push(airportRole);
    }
    if (airlineRole.permissionCode) {
      roleList.push(airlineRole);
    }
    if (partnerRole.permissionCode) {
      roleList.push(partnerRole);
    }

    if (isCheckedAirport && !airportRole.permissionCode) {
      message.error(
        getSharkText("config_page_airport_permission_cannot_be_empty")
      );
    } else if (isCheckedAirAline && !airlineRole.permissionCode) {
      message.error(
        getSharkText("config_page_airline_permission_cannot_be_empty")
      );
    } else if (isCheckedPartner && !partnerRole.permissionCode) {
      message.error(
        getSharkText("config_page_partner_permission_cannot_be_empty")
      );
    } else if (!isCheckedAirport && !isCheckedAirAline && !isCheckedPartner) {
      setVisible(false);
    } else {
      doFetch({
        ext: {
          roleList,
          type: 1,
        },
      });
      setVisible(false);
    }
  };

  const handleChangeAirlineRole = useRefFunc((v: RoleItem) => {
    /** 排除已经无效的航司航线 */
    const routes = v.permissionRoute;
    let newRole = { ...v };
    if (routes) {
      const airlineRoutesMap = JSON.parse(routes);
      const newMap: Record<string, string> = {};
      const airlines = v.permissionCode.split(",");
      airlines.forEach((airline) => {
        if (airlineRoutesMap[airline] && airlineRoutesMap[airline] !== "*") {
          newMap[airline] = airlineRoutesMap[airline];
        }
      });
      const newRoutes = JSON.stringify(newMap);
      newRole = {
        ...v,
        permissionRoute: newRoutes,
      };
      setAirlineRole(newRole);
    } else {
      setAirlineRole(v);
    }
  });

  const airportRoleEditor = useMemo(
    () => (
      <Row justify="start" gutter={16} align="middle">
        <Col>
          {isCheckedAirport && airportRole.id ? (
            <Popconfirm
              title={getSharkText("config_page_confirm_cancel_airport_role")}
              onConfirm={() => unbindRole(airportRole)}
            >
              <Checkbox checked={isCheckedAirport}>
                {getSharkText("config_page_airport_role")}
              </Checkbox>
            </Popconfirm>
          ) : (
            <Checkbox
              checked={isCheckedAirport}
              onChange={() => setIsCheckedAirport(!isCheckedAirport)}
            >
              {getSharkText("config_page_airport_role")}
            </Checkbox>
          )}
        </Col>
        <Col>
          {isCheckedAirport ? (
            <SelectAirports
              // airportRole={airportRole}
              onChange={(v) =>
                setAirportRole({
                  ...airportRole,
                  permissionName: v.map((v1) => v1.label).join(","),
                  permissionCode: v.map((v1) => v1.value).join(","),
                })
              }
              disabled={isCheckedAll}
              style={{ maxWidth: 260, minWidth: 150 }}
              value={
                airportRole.permissionCode
                  ? airportRole.permissionCode.split(",")
                  : []
              }
            />
          ) : undefined}
        </Col>
        <Col>
          <Checkbox
            checked={isCheckedAll}
            onChange={(e) => changeAirportAll(e.target.checked)}
          >
            不限
          </Checkbox>
        </Col>
      </Row>
    ),
    [airportRole, changeAirportAll, isCheckedAirport, isCheckedAll, unbindRole]
  );
  const airlineRoleEditor = useMemo(
    () => (
      <Row justify="start" gutter={16} style={{ marginTop: 16 }}>
        <Col>
          {isCheckedAirAline && airlineRole.id ? (
            <Popconfirm
              title={getSharkText("config_page_confirm_cancel_airport_role")}
              onConfirm={() => unbindRole(airlineRole)}
            >
              <Checkbox checked={isCheckedAirAline}>
                {getSharkText("config_page_airline_role")}
              </Checkbox>
            </Popconfirm>
          ) : (
            <Checkbox
              checked={isCheckedAirAline}
              onChange={() => setIsCheckedAirline(!isCheckedAirAline)}
            >
              {getSharkText("config_page_airline_role")}
            </Checkbox>
          )}
        </Col>
        <Col>
          {isCheckedAirAline ? (
            <SelectAirlines
              airlineRole={airlineRole}
              setAirlineRole={handleChangeAirlineRole}
            />
          ) : undefined}
        </Col>
      </Row>
    ),
    [airlineRole, handleChangeAirlineRole, isCheckedAirAline, unbindRole]
  );
  const partnerRoleEditor = useMemo(
    () => (
      <Row justify="start" gutter={16} style={{ marginTop: 16 }}>
        <Col>
          {isCheckedPartner && partnerRole.id ? (
            <Popconfirm
              title={getSharkText(
                "config_page_PaAiRiCoRoIn_confirm_cancel_supplier_role"
              )}
              onConfirm={() => unbindRole(partnerRole)}
            >
              <Checkbox checked={isCheckedPartner}>
                {getSharkText("config_page_PaAiRiCoRoIn_supplier_role")}
              </Checkbox>
            </Popconfirm>
          ) : (
            <Checkbox
              checked={isCheckedPartner}
              onChange={() => setIsCheckedPartner(!isCheckedPartner)}
            >
              {getSharkText("config_page_partner_role")}
            </Checkbox>
          )}
        </Col>
        <Col>
          {isCheckedPartner ? (
            <Select
              style={{ width: 150 }}
              value={partnerRole.permissionCode}
              onChange={(e) =>
                setPartnerRole({
                  ...partnerRole,
                  permissionCode: e,
                  permissionName: e,
                })
              }
            >
              <Option value="*">{getSharkText("config_page_all")}</Option>
              <Option value="TRAVIX">Travix</Option>
              <Option value="WINGON">
                {getSharkText("menu_wingon_overview")}
              </Option>
            </Select>
          ) : undefined}
        </Col>
      </Row>
    ),
    [isCheckedPartner, partnerRole, unbindRole]
  );

  const allowRoles = useRefFunc((r: RoleType) => {
    if (!window.TARGET_PLATFORM_CONFIG?.usedRoles) {
      return true;
    }
    return window.TARGET_PLATFORM_CONFIG.usedRoles.includes(r);
  });

  return (
    <Modal
      title={getSharkText("config_page_data_permission")}
      open={visible}
      onOk={bindRole}
      onCancel={() => setVisible(false)}
      okText={getSharkText("config_page_confirm")}
      cancelText={getSharkText("key.cancel.button")}
      confirmLoading={isLoading}
    >
      {allowRoles(RoleType.airport) ? airportRoleEditor : undefined}
      {allowRoles(RoleType.airline) ? airlineRoleEditor : undefined}
      {allowRoles(RoleType.other) ? partnerRoleEditor : undefined}
    </Modal>
  );
};

export default RoleListEdit;
