import {
  MarketTrendData,
  MarketTrendRequestType,
} from "@ctrip/flt-bi-flightai-airlines";

import CustomTableV2 from "Components/CustomTable/CustomTableV2";

import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useMemo,
} from "react";
import { ChartType, x15d } from "../common";

import { ChartDataFormatter, ISeries, SystemType } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { getColumns, getColumnsCompare } from "./tableColumns";
import EchartsReactBase from "Components/EchartsReactBase";

export interface ITablePortalProps {
  filter: MarketTrendRequestType;
  chartType?: ChartType;
}
export interface ChartHandle {
  download: (name: string) => void;
}

const moduleCode = "market_trend_airlines";
const AirlineCharts = forwardRef(
  (props: ITablePortalProps, ref: Ref<ChartHandle>): ReactElement => {
    const { filter, chartType } = props;

    const [globalState] = useGlobalState();
    const { airlinesQueryCondition } = globalState;

    airlinesQueryCondition.startDate = "2023-09-09";
    airlinesQueryCondition.endDate = "2023-09-09";
    const [{ data, isLoading }, doFetch] = useFetch({
      url: "queryMarketTrend",
      defaultValue: [],
      server: getServer(SystemType.airlines),
      head: {
        moduleCode: moduleCode,
      },
      query: airlinesQueryCondition,
      ext: {},
      lazey: true,
    });

    useEffect(() => {
      if (airlinesQueryCondition.compareType != null) {
        doFetch({
          query: {
            ...airlinesQueryCondition,
          },
          ext: {
            aggCode: filter.aggCode,
            arriveArea: filter.arriveArea,
            departArea: filter.departArea,
            takeoffDateRanges: filter.takeoffDateRanges,
          },
        });
      }
    }, [
      airlinesQueryCondition,
      doFetch,
      filter.aggCode,
      filter.arriveArea,
      filter.departArea,
      filter.takeoffDateRanges,
    ]);
    let dataxAxis = [] as any[];
    const series = useMemo(() => {
      const groupdate = (data as MarketTrendData[]) ?? [];
      const tmp = [] as ISeries[];
      if (groupdate.length) {
        if (filter.aggCode == 0) {
          groupdate.map((b) => {
            if (b.diffTime && b.diffTime > 24) {
              b.diffTime = b.diffTime / 24;
            }
          });
        }
        Object.keys(groupdate[0]).forEach((key) => {
          if (key == "searchIndex") {
            tmp.push({
              type: "line",
              name: getSharkText("key.search_index.name"),
              data: groupdate.map((item) => {
                if (item.searchIndex) {
                  return item.searchIndex.toFixed(1);
                } else return 0;
              }),
              yAxisIndex: 0,
              formatterType: ChartDataFormatter.Num,
              encode: {
                x: "xAxis",
                y: "searchIndex",
              },
            });
          }
          if (key == "diffTime") {
            dataxAxis.push({
              data: groupdate.map((item) => {
                if (item.diffTime) {
                  if (item.diffTime && item.diffTime % 24 == 0)
                    return item.diffTime / 24 + "d";
                  else
                    return (
                      Math.floor(item.diffTime / 24) +
                      "d" +
                      (item.diffTime % 24) +
                      "h"
                    );
                } else return "0d";
              }),
            });
          }
          if (key == "flightCount") {
            tmp.push({
              type: "line",
              name: getSharkText("key.numbers_of_flights_carried"),
              data: groupdate.map((item) => {
                if (item.flightCount) {
                  return item.flightCount.toFixed(1);
                } else return 0;
              }),
              formatterType: ChartDataFormatter.Num,
              yAxisIndex: 0,
              encode: {
                x: "xAxis",
                y: "flightCount",
              },
            });
          }
          if (key == "avgPrice") {
            tmp.push({
              type: "bar",
              name: getSharkText("config_page_average_deal_price"),
              data: groupdate.map((item) => {
                if (item.avgPrice) {
                  return Math.floor(item.avgPrice);
                } else return 0;
              }),
              formatterType: ChartDataFormatter.Num,
              yAxisIndex: 1,
              encode: {
                x: "xAxis",
                y: "avgPrice",
              },
            });
          }
          if (
            filter.takeoffDateRanges &&
            filter.takeoffDateRanges.compare &&
            filter.takeoffDateRanges.compare.length > 0
          ) {
            if (key == "compareSearchIndex") {
              tmp.push({
                type: "line",
                name:
                  getSharkText("key.compare.name") +
                  " " +
                  getSharkText("key.search_index.name"),
                data: groupdate.map((item) => {
                  if (item.compareSearchIndex) {
                    return item.compareSearchIndex.toFixed(1);
                  } else return 0;
                }),
                formatterType: ChartDataFormatter.Num,
                yAxisIndex: 0,
                encode: {
                  x: "xAxis",
                  y: "compareSearchIndex",
                },
              });
            }
            if (key == "compareFlightCount") {
              tmp.push({
                type: "line",
                name:
                  getSharkText("key.compare.name") +
                  " " +
                  getSharkText("key.numbers_of_flights_carried"),
                data: groupdate.map((item) => {
                  if (item.compareFlightCount) {
                    return item.compareFlightCount.toFixed(1);
                  } else return 0;
                }),
                formatterType: ChartDataFormatter.Num,
                yAxisIndex: 0,
                encode: {
                  x: "xAxis",
                  y: "compareFlightCount",
                },
              });
            }
            if (key == "compareAvgPrice") {
              tmp.push({
                type: "bar",
                name:
                  getSharkText("key.compare.name") +
                  " " +
                  getSharkText("config_page_average_deal_price"),
                data: groupdate.map((item) => {
                  if (item.compareAvgPrice) {
                    return Math.floor(item.compareAvgPrice);
                  } else return 0;
                }),
                formatterType: ChartDataFormatter.Num,
                yAxisIndex: 1,
                encode: {
                  x: "xAxis",
                  y: "compareAvgPrice",
                },
              });
            }
          }
        });
        return tmp;
      }

      return data;
    }, [data, isLoading]);

    const option = useMemo(() => {
      return {
        dataset: {
          source: data,
        },
        legend: {},
        grid: {
          left: "10%",
          right: "10%",
          bottom: "10%",
          top: "10%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          enterable: true,
          appendToBody: true,
          formatter: (items: any[]) => {
            if (!items.length) {
              return null;
            }
            let x = "";
            const rows = items.map((item) => {
              const dot = `<span class='dot' style='background:${item.color}'></span>`;
              const name = item.seriesName;
              const yVal = item.value;
              let yShowVal = yVal;
              const xVal: string = item.name;
              if (!x) {
                let days = xVal.includes("d")
                  ? parseInt(xVal.slice(0, xVal.indexOf("d")), 10)
                  : 0;
                let datet = new Date();
                if (
                  filter.takeoffDateRanges &&
                  filter.takeoffDateRanges.current
                ) {
                  datet = new Date(filter.takeoffDateRanges.current[0]);
                  datet.setDate(datet.getDate() - days - 1);

                  x =
                    getSharkText("key.date.flightplan") +
                    " : " +
                    datet.toISOString().slice(0, 10) +
                    " " +
                    (countHours(0, xVal) == -1 ? "" : countHours(0, xVal));
                } else {
                  x = xVal;
                }

                if (
                  filter.takeoffDateRanges &&
                  filter.takeoffDateRanges.compare &&
                  filter.takeoffDateRanges.compare.length > 0
                ) {
                  datet = new Date(filter.takeoffDateRanges.compare[0]);
                  datet.setDate(datet.getDate() - days);

                  x +=
                    "<br />" +
                    getSharkText("config_page_CoDaIn_compare_date") +
                    ": " +
                    datet.toISOString().slice(0, 10) +
                    " " +
                    (countHours(0, xVal) == -1 ? "" : countHours(0, xVal));
                }
              }

              return `${dot} ${name}: ${yShowVal}`;
            });
            return `${x}<br />` + rows.join("<br />");
          },
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: true,
            data: filter.aggCode == 0 ? x15d : dataxAxis[0]?.data,
            axisLabel: {
              interval: filter.aggCode == 0 ? 0 : 23,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name:
              getSharkText("key.numbers_of_flights_carried") +
              "/" +
              getSharkText("key.search_index.name"),
            position: "left",
          },
          {
            type: "value",
            name: getSharkText("config_page_average_deal_price"),
            position: "right",
          },
        ],
        dataZoom: [
          {
            type: "inside",
          },
          {
            type: "slider",
            bottom: 0,
          },
        ],
        series,
      };
    }, [data, series, filter, filter.aggCode, isLoading]);

    function countHours(hours: number, xVal: string): number {
      if (!xVal.includes("h")) {
        return -1;
      }
      hours =
        24 - parseInt(xVal.slice(xVal.indexOf("d") + 1, xVal.length - 1), 10);
      while (hours > 24) {
        hours -= 24;
      }
      return hours;
    }
    let col = getColumns();
    if (
      filter.takeoffDateRanges &&
      filter.takeoffDateRanges.compare &&
      filter.takeoffDateRanges.compare.length > 0
    ) {
      col = getColumnsCompare();
    }

    const tableData = useMemo(() => {
      const groupdate = (data as MarketTrendDataDiffTime[]) ?? [];
      groupdate.map((item) => {
        if (item.diffTime) {
          if (item.diffTime > 24) {
            item.earlyTime =
              Math.floor(item.diffTime / 24) + "d" + (item.diffTime % 24) + "h";
          } else if (item.diffTime == 24 && filter.aggCode == 1) {
            item.earlyTime = "1d0h";
          } else if (item.diffTime == 24 && filter.aggCode == 0) {
            item.earlyTime = "1d";
          } else if (filter.aggCode == 0) {
            item.earlyTime = item.diffTime + "d";
          } else {
            item.earlyTime = item.diffTime + "h";
          }
        } else {
          item.earlyTime = filter.aggCode == 0 ? "0d" : "0h";
        }
      });
      return groupdate as any;
    }, [filter, data]);

    return (
      <div style={{ overflowX: "auto", overflowY: "hidden" }}>
        {chartType === ChartType.Line ? (
          <EchartsReactBase
            option={option}
            notMerge
            style={{ width: "100%" }}
          />
        ) : (
          <CustomTableV2
            columns={col}
            dataSource={tableData}
            moduleCode={moduleCode}
            chartTableCode={""}
          />
        )}
      </div>
    );
  }
);
export default AirlineCharts;

export interface MarketTrendDataDiffTime {
  /**
   * 对比平均价格
   */
  compareAvgPrice: number | null;
  /**
   * 差异天
   */
  diffTime: number | null;
  /**
   * 对比搜索指数
   */
  compareSearchIndex: number | null;
  /**
   * 平均价格
   */
  avgPrice: number | null;
  /**
   * 搜索指数
   */
  searchIndex: number | null;
  /**
   * 航班数量
   */
  flightCount: number | null;
  /**
   * 对比航班数量
   */
  compareFlightCount: number | null;
  // 为了表格展示
  earlyTime: String | null;
}
