import React, { CSSProperties } from "react";
import { IDownloadHeader } from "Interface";
import { Col, Popover, Row } from "antd";
import {
  EPanelType,
  EStatus,
  ILoadDataType,
  ISpeedDataType,
} from "../MonitoringAlertingCommon";
import { genLoadFloat, showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import { DATE_MINUTE_FORMAT } from "Constants";
import Trend from "Components/Trend";
import { InfoCircleOutlined } from "@ant-design/icons";

const statusRender = (v: number) => {
  switch (v) {
    case 0:
      return (
        <span style={{ color: "red" }}>
          {getSharkText("config_page_untreated")}
        </span>
      );
    case 1:
      return (
        <span style={{ color: "green" }}>
          {getSharkText("config_page_processed")}
        </span>
      );
    case 2:
      return (
        <span style={{ color: "#aaa" }}>
          {getSharkText("config_page_expired")}
        </span>
      );
  }
};
const reasonRender = (val: string) => {
  return (
    <span>
      {val.split(";").map((s, i) =>
        s ? (
          <p style={{ margin: 0 }} key={i}>
            {s}
          </p>
        ) : undefined
      )}
    </span>
  );
};

const seatsColumns: IDownloadHeader[] = [
  {
    title: getSharkText("config_page_warning_time"),
    dataIndex: "changedate",
    sorter: (a: any, b: any) => a.changedate.localeCompare(b.changedate),
  },
  {
    title: getSharkText("config_page_takeoff_time"),
    dataIndex: "takeofftime",
    sorter: (a: any, b: any) => a.takeofftime.localeCompare(b.takeofftime),
  },
  {
    title: getSharkText("config_page_changed_flight_number"),
    dataIndex: "flightno",
    sorter: (a: any, b: any) => a.flightno.localeCompare(b.flightno),
  },
  {
    title: getSharkText("config_page_origin_city"),
    dataIndex: "dcity",
    sorter: (a: any, b: any) => a.dcity.localeCompare(b.dcity),
  },
  {
    title: getSharkText("config_page_arrival_city"),
    dataIndex: "acity",
    sorter: (a: any, b: any) => a.acity.localeCompare(b.acity),
  },
  {
    title: getSharkText("config_page_capacity_change_type"),
    dataIndex: "changetype",
    sorter: (a: any, b: any) => a.changetype.localeCompare(b.changetype),
  },
  {
    title: getSharkText("config_page_capacity_change"),
    dataIndex: "changecnt",
    sorter: (a: any, b: any) => a.changecnt - b.changecnt,
    render: (v: number) => {
      return <span style={{ color: v > 0 ? "red" : "green" }}>{v}</span>;
    },
  },
  {
    title: getSharkText("config_page_warning_reason"),
    dataIndex: "reason",
    sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
    render: reasonRender,
  },
  {
    title: getSharkText("config_page_status"),
    dataIndex: "status",
    sorter: (a: any, b: any) => a.status - b.status,
    render: statusRender,
    downloadFormatter: (v: number) => {
      return EStatus[v];
    },
  },
];

const searchColumns: IDownloadHeader[] = [
  {
    title: getSharkText("config_page_warning_time"),
    dataIndex: "alertTime",
    sorter: (a: any, b: any) => a.alertTime.localeCompare(b.alertTime),
  },
  {
    title: getSharkText("charts.date_of_departure"),
    dataIndex: "takeoffdate",
    sorter: (a: any, b: any) => a.takeoffdate.localeCompare(b.takeoffdate),
  },
  {
    title: getSharkText("config_page_advance_days"),
    dataIndex: "searchbefore",
    sorter: (a: any, b: any) => a.searchbefore - b.searchbefore,
  },
  {
    title: getSharkText("config_page_origin_city"),
    dataIndex: "dcity",
    sorter: (a: any, b: any) => a.dcity.localeCompare(b.dcity),
  },
  {
    title: getSharkText("config_page_arrival_city"),
    dataIndex: "acity",
    sorter: (a: any, b: any) => a.acity.localeCompare(b.acity),
  },
  {
    title: getSharkText("config_page_cumulative_search_index"),
    dataIndex: "searchIndex",
    sorter: (a: any, b: any) => a.searchIndex - b.searchIndex,
  },
  {
    title: getSharkText("config_page_search_index_forecast_lower_limit"),
    dataIndex: "predLower",
    sorter: (a: any, b: any) => a.predLower - b.takeofftime,
  },
  {
    title: getSharkText("config_page_search_index_forecast_upper_limit"),
    dataIndex: "predUpper",
    sorter: (a: any, b: any) => a.predUpper - b.predUpper,
  },
  {
    title: getSharkText("key.yoy_increase.name"),
    dataIndex: "yoy",
    sorter: (a: any, b: any) => a.yoy - b.yoy,
    render: (v: number) => {
      return (
        <span style={{ color: v > 0 ? "red" : "green" }}>
          {showNum(v, "percentage")}
        </span>
      );
    },
    downloadFormatter: (v: number) => {
      return showRawNum(v, "percentage");
    },
  },
  {
    title: getSharkText("config_page_warning_reason"),
    dataIndex: "reason",
    sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
  },
  {
    title: getSharkText("config_page_status"),
    dataIndex: "status",
    sorter: (a: any, b: any) => a.status - b.status,
    render: statusRender,
    downloadFormatter: (v: number) => {
      return EStatus[v];
    },
  },
];

const changeColumns: IDownloadHeader[] = [
  {
    title: getSharkText("config_page_warning_time"),
    dataIndex: "changedate",
    sorter: (a: any, b: any) => a.changedate.localeCompare(b.changedate),
  },
  {
    title: getSharkText("config_page_takeoff_time"),
    dataIndex: "takeofftime",
    sorter: (a: any, b: any) => a.takeofftime.localeCompare(b.takeofftime),
  },
  {
    title: getSharkText("config_page_flight"),
    dataIndex: "flightno",
    sorter: (a: any, b: any) => a.flightno.localeCompare(b.flightno),
  },
  {
    title: getSharkText("config_page_departure_airport"),
    dataIndex: "dport",
    sorter: (a: any, b: any) => a.dport.localeCompare(b.dport),
  },
  {
    title: getSharkText("config_page_arrival_airport"),
    dataIndex: "aport",
    sorter: (a: any, b: any) => a.aport.localeCompare(b.aport),
  },
  {
    title: getSharkText("config_page_change_type"),
    dataIndex: "changetype",
    sorter: (a: any, b: any) => a.changetype.localeCompare(b.changetype),
    render: (v: string) =>
      v === "1"
        ? getSharkText("key.cancel.button")
        : v === "5"
        ? getSharkText("config_page_cancel_and_resume")
        : getSharkText("system_type_Others"),
  },
  {
    title: getSharkText("config_page_warning_reason"),
    dataIndex: "reason",
    sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
    render: reasonRender,
  },
  {
    title: getSharkText("config_page_status"),
    dataIndex: "status",
    sorter: (a: any, b: any) => a.status - b.status,
    render: statusRender,
    downloadFormatter: (v: number) => {
      return EStatus[v];
    },
  },
];

const loadReasonHelp = (
  <div style={{ width: 320 }}>
    <div>{getSharkText("config_page_example_description")}：</div>
    <div>
      <Row>
        <Col span={8}>
          <Trend
            style={{
              display: "inline-block",
              marginRight: 10,
              borderRight: "1px solid #888",
              paddingRight: 10,
            }}
            flag="up"
          >
            12%
          </Trend>
          <Trend style={{ display: "inline-block" }} flag="down">
            -34
          </Trend>
        </Col>
        <Col>
          {getSharkText("config_page_difference_percentage_absolute_value")}
        </Col>
      </Row>
      <div>
        <Row>
          <Col span={8}>
            <Trend style={{ textAlign: "center" }} flag="up">
              56%
            </Trend>
          </Col>
          <Col>
            {getSharkText("config_page_compare_with_previous_four_weeks")}
          </Col>
        </Row>
      </div>
    </div>
  </div>
);

const loadColumns: IDownloadHeader[] = [
  {
    title: getSharkText("config_page_warning_time"),
    dataIndex: "changedate",
    width: 150,
    sorter: (a: any, b: any) => a.changedate.localeCompare(b.changedate),
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_takeoff_time"),
    dataIndex: "takeofftime",
    width: 150,
    sorter: (a: any, b: any) => a.takeofftime.localeCompare(b.takeofftime),
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_flight"),
    dataIndex: "flightno",
    sorter: (a: any, b: any) => a.flightno.localeCompare(b.flightno),
    render: (val: any, r: any) => {
      if (r && r.flightno) {
        const url = `/flight_manage?startDate=${r.takeofftime.slice(
          0,
          10
        )}&endDate=${r.takeofftime.slice(0, 10)}&airline=${r.flightno.slice(
          0,
          2
        )}&routes=${r.dport}${r.aport}&flightNo=${r.flightno}`;
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {val}
          </a>
        );
      }
    },
  },
  {
    title: getSharkText("config_page_departure_airport"),
    dataIndex: "dport",
    sorter: (a: any, b: any) => a.dport.localeCompare(b.dport),
  },
  {
    title: getSharkText("config_page_arrival_airport"),
    dataIndex: "aport",
    sorter: (a: any, b: any) => a.aport.localeCompare(b.aport),
  },
  {
    title: getSharkText("config_page_flight_progress"),
    dataIndex: "lf",
    sorter: (a: any, b: any) => a.lf - b.lf,
    render: (val: number, r: ILoadDataType) => {
      const cnt = r.cnt ? `| ${r.cnt}` : "";
      return `${showNum(val, "percentage")} ${cnt}`;
    },
    downloadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText("config_page_last_four_weeks_progress"),
    dataIndex: "last4weekLf",
    sorter: (a: any, b: any) => a.last4weekLf - b.last4weekLf,
    render: (val: number) => showNum(val, "percentage"),
    downloadFormatter: (val: number) => showRawNum(val, "percentage"),
  },

  {
    title: getSharkText("config_page_compete_flight"),
    dataIndex: "compareFlightno",
    sorter: (a: any, b: any) =>
      a.compareFlightno.localeCompare(b.compareFlightno),
  },
  {
    title: getSharkText(
      "config_page_competing_flight_passenger_progress_estimated"
    ),
    dataIndex: "compareLf",
    sorter: (a: any, b: any) => a.compareLf - b.compareLf,
    width: 130,
    render: (val: number, r: ILoadDataType) => {
      const cntStr = r.compareCnt ? (
        <>
          <br />
          <span>{genLoadFloat(r.compareCnt)}</span>
        </>
      ) : undefined;
      return (
        <div>
          <span>{genLoadFloat(val, "percentage", 1)}</span>
          {cntStr}
        </div>
      );
    },
    downloadFormatter: (val: number) => genLoadFloat(val, "percentage", 1),
  },
  {
    title: (
      <span>
        {getSharkText("config_page_warning_progress_difference")}
        <Popover content={loadReasonHelp}>
          <InfoCircleOutlined style={{ marginLeft: 5 }} />
        </Popover>
      </span>
    ),
    dataIndex: "diffLf",
    width: 180,
    sorter: (a: any, b: any) => a.diffLf - b.diffLf,
    render: (val: number, r: ILoadDataType) => {
      const statusPer = val > 0 ? "up" : "down";
      const statusCnt = r.diffCnt > 0 ? "up" : "down";
      const statusLast = r.lf - r.last4weekLf > 0 ? "up" : "down";
      const diffCnt = r.diffCnt ? `${r.diffCnt}` : "";
      const style: CSSProperties = {};
      if (r.diffCnt) {
        style.marginRight = 10;
        style.borderRight = "1px solid #888";
        style.paddingRight = 10;
      }
      return (
        <div style={{ textAlign: "center" }}>
          <div>
            <Trend
              style={{ display: "inline-block", ...style }}
              flag={statusPer}
            >
              {showNum(val, "percentage")}
            </Trend>
            {r.diffCnt ? (
              <Trend style={{ display: "inline-block" }} flag={statusCnt}>
                {diffCnt}
              </Trend>
            ) : undefined}
          </div>
          <div>
            <Trend style={{ textAlign: "center" }} flag={statusLast}>
              {showNum(r.lf - r.last4weekLf, "percentage")}
            </Trend>
          </div>
        </div>
      );
    },
    downloadFormatter: (val: number, r: ILoadDataType) =>
      `${showNum(val, "percentage")} ${r.diffCnt}`,
  },
  {
    title: getSharkText("config_page_warning_reason"),
    dataIndex: "reason",
    width: 180,
    sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
    render: reasonRender,
  },
  {
    title: getSharkText("config_page_status"),
    dataIndex: "status",
    sorter: (a: any, b: any) => a.status - b.status,
    render: statusRender,
    downloadFormatter: (v: number) => {
      return EStatus[v];
    },
  },
];

const speedHelp = (
  <div style={{ marginLeft: 10 }}>
    <p>{getSharkText("config_page_long_term_d14_d11")}</p>
    <p>{getSharkText("config_page_mid_term_d10_d4")}</p>
    <p>{getSharkText("config_page_short_term_d3_d0")}</p>
  </div>
);

const speedColumns: Array<IDownloadHeader<ISpeedDataType>> = [
  {
    title: getSharkText("config_page_warning_time"),
    dataIndex: "changedate",
    width: 150,
    sorter: (a: any, b: any) => a.changedate.localeCompare(b.changedate),
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_takeoff_time"),
    dataIndex: "takeofftime",
    width: 150,
    sorter: (a: any, b: any) => a.takeofftime.localeCompare(b.takeofftime),
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_flight"),
    dataIndex: "flightno",
    sorter: (a: any, b: any) => a.flightno.localeCompare(b.flightno),
    render: (val: any, r: any) => {
      if (r && r.flightno) {
        const url = `/flight_manage?startDate=${r.takeofftime.slice(
          0,
          10
        )}&endDate=${r.takeofftime.slice(0, 10)}&airline=${r.flightno.slice(
          0,
          2
        )}&routes=${r.dport}${r.aport}&flightNo=${r.flightno}`;
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {val}
          </a>
        );
      }
    },
  },
  {
    title: getSharkText("config_page_departure_airport"),
    dataIndex: "dport",
    sorter: (a: any, b: any) => a.dport.localeCompare(b.dport),
  },
  {
    title: getSharkText("config_page_arrival_airport"),
    dataIndex: "aport",
    sorter: (a: any, b: any) => a.aport.localeCompare(b.aport),
  },
  {
    title: (
      <span>
        {getSharkText("config_page_speed")}
        <Popover content={speedHelp}>
          <InfoCircleOutlined />
        </Popover>
      </span>
    ),
    dataIndex: "saledseat",
    sorter: (a: ISpeedDataType, b: ISpeedDataType) => a.saledseat - b.saledseat,
    render: (val: number, r) => {
      return `${showNum(val)}`;
    },
    downloadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText("config_page_flight_price"),
    dataIndex: "price",
  },
  {
    title: getSharkText("config_page_compete_flight"),
    dataIndex: "compareFlightno",
    sorter: (a: any, b: any) => a.compareFlightno - b.compareFlightno,
  },
  {
    title: getSharkText("config_page_competing_price_price_difference"),
    dataIndex: "diffPrice",
    sorter: (a: any, b: any) => a.diffPrice - b.diffPrice,
    render: (val: number, r: any) => {
      const status = val > 0 || r.diffPrice > 0 ? "up" : "down";
      const diffCnt = r.diffPrice ? `| ${r.diffPrice}` : "";
      const text = `${showNum(r.comparePrice)} ${diffCnt}`;
      return <Trend flag={status}>{text}</Trend>;
    },
    downloadFormatter: (val: number, r: any) =>
      `${showNum(r.comparePrice)} ${r.diffPrice}`,
  },
  {
    title: getSharkText("config_page_warning_reason"),
    dataIndex: "reason",
    width: 180,
    sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
    render: reasonRender,
  },
  {
    title: getSharkText("config_page_status"),
    dataIndex: "status",
    sorter: (a: any, b: any) => a.status - b.status,
    render: statusRender,
    downloadFormatter: (v: number) => {
      return EStatus[v];
    },
  },
];

const priceColumns: IDownloadHeader[] = [
  {
    title: getSharkText("config_page_warning_time"),
    dataIndex: "changedate",
    width: 150,
    sorter: (a: any, b: any) => a.changedate.localeCompare(b.changedate),
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_takeoff_time"),
    dataIndex: "takeofftime",
    width: 150,
    sorter: (a: any, b: any) => a.takeofftime.localeCompare(b.takeofftime),
    render: (val: string) => moment(val).format(DATE_MINUTE_FORMAT),
  },
  {
    title: getSharkText("config_page_flight"),
    dataIndex: "flightno",
    sorter: (a: any, b: any) => a.flightno.localeCompare(b.flightno),
    render: (val: any, r: any) => {
      if (r && r.flightno) {
        const url = `/flight_manage?startDate=${r.takeofftime.slice(
          0,
          10
        )}&endDate=${r.takeofftime.slice(0, 10)}&airline=${r.flightno.slice(
          0,
          2
        )}&routes=${r.dport}${r.aport}&flightNo=${r.flightno}`;
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {val}
          </a>
        );
      }
    },
  },
  {
    title: getSharkText("config_page_departure_airport"),
    dataIndex: "dport",
    sorter: (a: any, b: any) => a.dport.localeCompare(b.dport),
  },
  {
    title: getSharkText("config_page_arrival_airport"),
    dataIndex: "aport",
    sorter: (a: any, b: any) => a.aport.localeCompare(b.aport),
  },
  {
    title: getSharkText("config_page_flight_price"),
    dataIndex: "price",
    sorter: (a: any, b: any) => a.price - b.price,
  },

  {
    title: getSharkText("config_page_compete_flight"),
    dataIndex: "compareFlightno",
    sorter: (a: any, b: any) =>
      a.compareFlightno.localeCompare(b.compareFlightno),
  },
  {
    title: getSharkText("config_page_competitor_price"),
    dataIndex: "comparePrice",
    sorter: (a: any, b: any) => a.comparePrice - b.comparePrice,
  },
  {
    title: getSharkText("config_page_price_difference"),
    dataIndex: "diffPrice",
    sorter: (a: any, b: any) => a.diffPrice - b.diffPrice,
    // render: (val: number) => {
    //   const color = val > 0 ? "red" : "green";
    //   const s = <span style={{ color }}>{val}</span>;
    //   console.log(s);
    //   return s;
    // },
    render: (val: number) => {
      const status = val > 0 ? "up" : "down";
      return <Trend flag={status}>{val}</Trend>;
    },
  },
  {
    title: getSharkText("config_page_warning_reason"),
    dataIndex: "reason",
    sorter: (a: any, b: any) => a.reason.localeCompare(b.reason),
    render: reasonRender,
  },
  {
    title: getSharkText("config_page_status"),
    dataIndex: "status",
    sorter: (a: any, b: any) => a.status - b.status,
    render: statusRender,
    downloadFormatter: (v: number) => {
      return EStatus[v];
    },
  },
];

export const getColumns = (panelType: EPanelType): IDownloadHeader[] => {
  switch (panelType) {
    case EPanelType.Capacity:
      return seatsColumns;
    case EPanelType.Search:
      return searchColumns;
    case EPanelType.Change:
      return changeColumns;
    case EPanelType.Price:
      return priceColumns;
    case EPanelType.Load:
      return loadColumns;
    case EPanelType.Speed:
      return speedColumns;
  }
  throw new Error("can't get columns defs");
};

// 不同的表格有不同的字段可供搜索
export const genSearchKeys = (panelType: EPanelType): string[] | undefined => {
  const config: Partial<Record<EPanelType, string[]>> = {
    0: ["flightno", "dcity", "acity"],
    1: ["dcity", "acity"],
    3: ["flightno", "dport", "aport"],
  };
  return config[panelType];
};
