import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ODBarChartData, ODParam } from "./interface";
import { getFilters } from ".";
import { useFetch } from "Utils";
import { Radio, message } from "antd";
import { StandardResponse } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import {
  DATASET_ID,
  DESTINATION_AIRPORT,
  ORIGIN_AIRPORT,
  PAX_MASKING,
  PRICE_PERSONS_SUM,
  TRANSIT_AIRPORT1,
  TRANSIT_AIRPORT2,
} from "./ODCols";
import EChartsReact from "echarts-for-react";
import FullSpin from "Components/FullSpin";
import { ECHARTS_NODATA_OPTION } from "Constants";

const TABS = ["OD", "OTD", "ORIGIN", "DESTINATION"] as const;
const TAB_OPTIONS = TABS.map((t) => ({ label: t, value: t }));
type TAB = (typeof TABS)[number];

export interface ODTrendProps {
  param: ODParam;
  cols: FDDatasetCol[];
}

/**  Component description */
const ODBar = (props: ODTrendProps): ReactElement => {
  const { param, cols } = props;
  const [tab, setTab] = useState<TAB>("OD");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [resData, setResData] = useState<ODBarChartData[]>([]);

  // #region 表格查询
  const [{ error, isLoading }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "ODBar",
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(res.rows, res.headers);
      console.log("map: ", map);
      const tmp: ODBarChartData[] = map.map((m) => ({
        x: m[`SUM_${PAX_MASKING}`] as number,
        y: [
          m[ORIGIN_AIRPORT],
          m[TRANSIT_AIRPORT1],
          m[TRANSIT_AIRPORT2],
          m[DESTINATION_AIRPORT],
        ]
          .filter((f) => !!f)
          .join("-"),
      }));
      setResData(tmp);
    },
    onFinish: () => {
      setIsLoadingData(false);
    },
  });

  const oriFilters = useMemo(() => {
    return getFilters(param);
  }, [param]);

  const dimensions = useMemo(() => {
    switch (tab) {
      case "OD":
        return [ORIGIN_AIRPORT, DESTINATION_AIRPORT];
      case "OTD":
        return [
          ORIGIN_AIRPORT,
          TRANSIT_AIRPORT1,
          TRANSIT_AIRPORT2,
          DESTINATION_AIRPORT,
        ];
      case "ORIGIN":
        return [ORIGIN_AIRPORT];
      case "DESTINATION":
        return [DESTINATION_AIRPORT];
    }
  }, [tab]);

  const builder = useMemo(() => {
    if (!cols.length || !oriFilters.length) {
      return null;
    }
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns: cols,
      dimensions,
      measures: [PAX_MASKING],
      chartFilters: [],
      containerFilters: [],
      oriFilters,
      limit: 10,
      sorters: [PAX_MASKING],
    });
  }, [cols, dimensions, oriFilters]);

  const { requestBody, encrypted } = useMemo(() => {
    return builder
      ? builder.getRequestBody()
      : { requestBody: null, encrypted: "" };
  }, [builder]);

  const lastParam = useRef<string>();

  const refetch = useCallback(() => {
    setIsLoadingData(true);
    setResData([]);
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetch, encrypted]);

  useEffect(() => {
    if (encrypted && encrypted !== lastParam.current) {
      refetch();
      lastParam.current = encrypted;
    }
  }, [encrypted, refetch]);

  const option = useMemo(() => {
    // if (isLoading) {
    //   return {};
    // }
    if (!resData.length) {
      return ECHARTS_NODATA_OPTION;
    }
    return {
      title: {
        text: null,
      },
      color: ["#165DFF"],
      dataset: {
        dimension: Object.keys(resData[0]),
        source: resData,
      },
      tooltip: {
        trigger: "axis",
      },
      grid: { top: 10, bottom: 40, left: 150, right: 40 },
      xAxis: {
        type: "value",
      },
      yAxis: [
        {
          type: "category",
          inverse: true,
          axisLabel: {
            align: "left",
            margin: 125,
            fontFamily: "TripGeomTF-Medium",
          },
          data: resData.map((m) => m.y),
        },
      ],
      series: [
        {
          name: `Market Passenger volume`,
          id: `id1`,
          type: "bar",
          encode: {
            x: "x",
            y: "y",
          },
        },
      ],
    };
  }, [resData]);

  if (isLoading) {
    return <FullSpin minHeight={300} />;
  }

  return (
    <div>
      <Radio.Group
        options={TAB_OPTIONS}
        optionType="button"
        buttonStyle="solid"
        value={tab}
        onChange={(e) => setTab(e.target.value)}
      ></Radio.Group>
      <div
        style={{ marginTop: 10, border: "1px solid var(--filter-bg-border)" }}
      >
        <h3 style={{ margin: "10px 0 0 10px" }}>TOP 10 Passenger volume</h3>
        <EChartsReact option={option} notMerge style={{ height: 431 }} />
      </div>
    </div>
  );
};
export default ODBar;
