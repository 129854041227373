// Created by xh_zhu on 2021-04-14

import { Affix, Button, Col, message, Radio, Row } from "antd";
import { IDateMode, RangeObject, Role } from "Interface";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import DateRangePicker from "Components/Toolbar/DateRangePicker";
import useGlobalState from "Store";
import { getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import AirlineFlightClass from "../AirlineComponents/AirlineFlightClass";
import { DATE_FORMAT } from "Constants";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import moment, { Moment } from "moment";
import Search from "./Search";
import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";
import useRefFunc from "Utils/useRefFunc";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import Airport from "Components/Toolbar/Airport";

/**
 * Component description
 * 中转分析-航司版顶部筛选条
 */

interface IProps {
  isDemo: boolean;
  onChange: (v: Partial<TransferPolicyBarlineRequestType>) => void;
}

const TopFilter = (props: IProps): ReactElement => {
  const { onChange, isDemo } = props;
  const defaultDateMode: IDateMode = "nextThirtyDays";
  const defaultSearchRange: IDateMode = "lastMonth";
  const [queryExt, setQueryExt] =
    useState<Partial<TransferPolicyBarlineRequestType>>();
  const [panelFilterValue, setPanelFilterValue] = useState<any>();
  const [transferType, setTransferType] = useState<number[]>([0]);
  const [searchRange, setSearchRange] = useState<RangeObject>(
    getDateRange(defaultSearchRange)
  );
  const [axisType, setAxisType] = useState<0 | 1>(0);
  const [globalState, action] = useGlobalState();
  const { userInfo, queryCondition } = globalState;
  const { airport, flightClass, startDate, endDate, originalAirport } =
    queryCondition;
  const { setQueryConditionAttr } = action;
  const { roleList } = userInfo;
  const airportsRole = roleList
    ? roleList.find((item: Role) => item.roleType === 1)
    : undefined;
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);

  useEffect(() => {
    const tmpExt = {
      filter: {
        ...panelFilterValue,
        ...{
          transferArea: originalAirport,
        },
        ...(searchRange
          ? {
              searchStartDate:
                (searchRange && searchRange[0]?.format(DATE_FORMAT)) || "",
              searchEndDate:
                (searchRange && searchRange[1]?.format(DATE_FORMAT)) || "",
            }
          : null),
        sameAirline: transferType.includes(0) ? 1 : 0,
        airline1: transferType.includes(1) ? 1 : 0,
        airline2: transferType.includes(2) ? 1 : 0,
      },
      axisType,
    };
    setQueryExt(tmpExt);
  }, [panelFilterValue, searchRange, axisType, transferType, originalAirport]);

  useEffect(() => {
    const range = getDateRange(defaultDateMode);
    setQueryConditionAttr({
      startDate: range ? range[0].format(DATE_FORMAT) : "",
      endDate: range ? range[1].format(DATE_FORMAT) : "",
    });
  }, [airport, airportsRole, setQueryConditionAttr]);

  const submit = useRefFunc(() => {
    if (!queryExt) {
      return;
    }
    if (!startDate || !endDate) {
      message.error(getSharkText("config_page_select_departure_time"));
      return;
    }
    if (
      !queryExt.filter ||
      !queryExt.filter.searchStartDate ||
      !queryExt.filter.searchEndDate
    ) {
      message.error(getSharkText("config_page_select_search_time"));
      return;
    }
    onChange(queryExt);
  });

  // #region 变更机场时触发查询
  useEffect(() => {
    // TODO 当前机场组件不能脱离全局参数单独使用导致的问题, 这里正确的逻辑是选择机场后, 点击查询才生效,
    // 但是目前选择机场会修改全局参数触发查询, 导致无法实现正确逻辑, 只能延时再查一次
    setTimeout(submit, 300);
  }, [airport, submit]);
  // #endregion

  /**
   * 过滤器加载完成后查询一次数据
   */
  useEffect(() => {
    if (!firstLoaded && queryExt && queryExt.filter) {
      submit();
      setFirstLoaded(true);
    }
  }, [submit, queryExt, firstLoaded]);
  const searchProps: RangePickerProps<Moment> = useMemo(() => {
    return {
      allowClear: true,
      ...(startDate && endDate
        ? {
            disabledDate: (cur: Moment) => {
              return cur.isBefore(moment(startDate).add(-31, "days"));
            },
          }
        : null),
    };
  }, [endDate, startDate]);

  const takeoffProps: RangePickerProps<Moment> = useMemo(() => {
    return {
      ...(queryExt?.filter?.searchStartDate && queryExt.filter.searchEndDate
        ? {
            disabledDate: (cur: Moment) => {
              return cur.isAfter(
                moment(queryExt?.filter?.searchEndDate).add(31, "days")
              );
            },
          }
        : null),
    };
  }, [queryExt?.filter?.searchEndDate, queryExt?.filter?.searchStartDate]);

  return (
    <Affix
      offsetTop={0}
      target={() => document.getElementById("main")}
      className="toolbar-wrap"
    >
      <div>
        <div className="toolbar">
          <Row gutter={[{ md: 24 }, 20]} align="middle">
            <Col>
              <AirlineFlightClass />
            </Col>
            <Col>
              <Airport />
            </Col>
            <Col>
              <span style={{ marginRight: 10 }}>
                {getSharkText("charts.date_of_search")}
              </span>
              {/* <Radio.Group value={searchType} onChange={handleChangeSearchType}>
                <Radio.Button value={0}>昨日</Radio.Button>
                <Radio.Button value={1}>近七日</Radio.Button>
              </Radio.Group> */}
              <DateRangePickerV2
                defaultDateMode="lastMonth"
                onChange={setSearchRange}
                otherProps={searchProps}
              />
            </Col>
            <Col>
              <span style={{ marginRight: 10 }}>
                {getSharkText("charts.date_of_departure")}
              </span>
              <DateRangePicker
                defaultDateMode={defaultDateMode}
                shouldDisableDate={false}
                otherProps={takeoffProps}
              />
            </Col>
            <Col>
              <Search
                onChangeVal={setPanelFilterValue}
                isDemo={isDemo}
                flightClass={flightClass}
              />
            </Col>
            <Col>
              <span style={{ marginRight: 10 }}>
                {getSharkText("key.axis_display.name")}
              </span>
              <Radio.Group
                value={axisType}
                onChange={(e) => setAxisType(e.target.value)}
              >
                <Radio value={0}>{getSharkText("charts.date_of_search")}</Radio>
                <Radio value={1}>
                  {getSharkText("charts.date_of_departure")}
                </Radio>
              </Radio.Group>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={submit}
                value={getSharkText("key.query.button")}
              >
                {getSharkText("key.query.button")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Affix>
  );
};
export default TopFilter;
