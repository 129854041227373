import { AirlinesSearchIndexTag } from "@ctrip/flt-bi-flightai-airlines";
import { getSharkText } from "Utils/i18nGlobal";
import HTMLReactParser from "html-react-parser";
import React from "react";

export enum ESeason {
  /**
   * 本航季
   */
  Current = 1,
  /**
   * 上航季
   */
  Last,
  /**
   * 去年同航季
   */
  LY,
}

export const Seasons: Record<ESeason, string> = {
  [ESeason.Current]: getSharkText("key.current_season.name"),
  [ESeason.Last]: getSharkText("key.last_season.name"),
  [ESeason.LY]: getSharkText("key.same_season_lastyear.name"),
};

export enum ERoute {
  /**
   * 执飞航段
   */
  Performed = 1,
  /**
   * 未执飞航段，两城市均为通航点
   */
  NoProduct,
  /**
   * 未执飞航段，仅一城市为通航点
   */
  OneComing,
  /**
   * 未执飞航段，两城市均未通航
   */
  NotComing,
}

export const Routes: Record<ERoute, string> = {
  [ERoute.Performed]: getSharkText("key.op_seg.checkbox"),
  [ERoute.NoProduct]: getSharkText("key.no_op_seg_two_navi.checkbox"),
  [ERoute.OneComing]: getSharkText("key.no_op_seg_one_navi.checkbox"),
  [ERoute.NotComing]: getSharkText("key.no_op_seg_zero_navi.checkbox"),
};

export const defaultTag: AirlinesSearchIndexTag = {
  airline: [1],
  season: [1],
  route: [1, 2, 3, 4],
};

const helpText = getSharkText("key.search_aheaddays_demonstration.text");
const text = HTMLReactParser(helpText);
export const searchAlignHelp = <div>{text}</div>;

export enum AxisType {
  takeoff = 0,
  search = 1,
}

export const QueryType = {
  "1": getSharkText("key.search_dep_date.mode"),
  "2": getSharkText("key.dep_aheadday.mode"),
  "3": getSharkText("key.search_aheadday.mode"),
};

export const QueryTypeOptions = Object.keys(QueryType).map((k) => {
  const key = k as keyof typeof QueryType;
  return {
    label: QueryType[key],
    value: key,
  };
});

export const searchTrendsAirlinesFlightClass = [
  getSharkText("flightClass.all.name"),
  getSharkText("flightClass.domestic.name"),
  getSharkText("flightClass.foreign.name"),
  getSharkText("flightClass.overseas.name"),
];
