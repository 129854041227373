import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import { showNum, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { EMPTY_ARRAY } from "Constants";
import { getServer } from "Service/server";
import {
  CHART_TABLE_CODE,
  MODULE_CODE,
  QUERY_URL,
} from "Page/AI/FlightReview/fetchCode";
import round from "lodash/round";
import merge from "lodash/merge";
import { Spin } from "antd";
import { ISeries } from "Interface";
import { cloneDeep } from "lodash";
import { IFlightReviewFilter } from "Page/AI/FlightReview/ReviewCommon";
import EchartsReactBase from "Components/EchartsReactBase";

export interface IOffProps {
  queryExt?: IFlightReviewFilter;
  compareFlightNO?: string[];
  compareDate?: string;
}

const defaultSeries: ISeries[] = [
  {
    name: getSharkText("config_page_discount_less_than_or_equal_to_0_3"),
    type: "bar",
    stack: "total",
    encode: {
      x: "day",
      y: "<=0.3",
    },
  },
  {
    name: getSharkText(
      "config_page_0_7_greater_than_or_equal_to_discount_greater_than_0_3"
    ),
    type: "bar",
    stack: "total",
    encode: {
      x: "day",
      y: "(0.3,0.7]",
    },
  },
  {
    name: getSharkText(
      "config_page_1_greater_than_or_equal_to_discount_greater_than_0_7"
    ),
    type: "bar",
    stack: "total",
    encode: {
      x: "day",
      y: "(0.7,1]",
    },
  },
  {
    name: getSharkText("config_page_discount_greater_than_1"),
    type: "bar",
    stack: "total",
    encode: {
      x: "day",
      y: ">1",
    },
  },
];

const chartConfig = {};

/**
 * 航班复盘/折扣分布
 */
const OFFContent = (props: IOffProps): ReactElement => {
  const { queryExt, compareFlightNO, compareDate } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [series, setSeries] = useState<ISeries[]>(EMPTY_ARRAY);

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(2),
    url: QUERY_URL[2].discount,
    defaultValue: null,
    head: {
      moduleCode: MODULE_CODE[2],
      chartTableCode: CHART_TABLE_CODE[2].discount,
    },
    query: airlinesQueryCondition,
    ext: {
      ...queryExt,
    },
    lazey: true,
  });

  const [{ data: dataCom, isLoading: comLoading }, doFetchCom] = useFetch({
    server: getServer(2),
    url: QUERY_URL[2].discount,
    defaultValue: null,
    head: {
      moduleCode: MODULE_CODE[2],
      chartTableCode: CHART_TABLE_CODE[2].discount,
    },
    query: airlinesQueryCondition,
    ext: {
      ...queryExt,
      ...{
        filter: {
          flightNO: compareFlightNO?.join(",") || queryExt?.filter.flightNO,
          route: queryExt?.filter.route,
        },
      },
      startDate: compareDate,
      endDate: compareDate,
    },
    lazey: true,
  });

  /**
   * 获取当前航班数据
   */
  useEffect(() => {
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        ...queryExt,
      },
    });
  }, [doFetch, queryExt, airlinesQueryCondition]);

  useEffect(() => {
    doFetchCom({
      query: airlinesQueryCondition,
      ext: {
        ...queryExt,
        ...{
          filter: {
            flightNO: compareFlightNO?.join(",") || queryExt?.filter.flightNO,
            route: queryExt?.filter.route,
          },
          query: {
            ...airlinesQueryCondition,
            startDate: compareDate || airlinesQueryCondition.startDate,
            endDate: compareDate || airlinesQueryCondition.endDate,
          },
        },
      },
    });
  }, [
    airlinesQueryCondition,
    compareDate,
    compareFlightNO,
    doFetchCom,
    queryExt,
  ]);

  /**
   * 生成series
   */
  useEffect(() => {
    let ds: ISeries[] = [];
    if (data && data.length) {
      ds = cloneDeep(defaultSeries).map((d) => {
        const f = data.find((t: any) => t.name === d.encode.y);
        const val = f ? f.value / f.totalValue : 0;
        const day = f?.day || "";
        d.data = [[day, val, queryExt?.filter.flightNO]];
        d.stack = "cur";
        d.xAxisIndex = 0;
        d.yAxisIndex = 0;
        return d;
      });
    }
    let cs: ISeries[] = [];
    if (dataCom && dataCom.length) {
      cs = cloneDeep(defaultSeries).map((d) => {
        const f = dataCom.find((t: any) => t.name === d.encode.y);
        const val = f ? f.value / f.totalValue : 0;
        const day = f?.day || "";
        const flightNo =
          compareFlightNO && compareFlightNO.length
            ? compareFlightNO[0]
            : queryExt?.filter.flightNO || "unknown";
        d.data = [[day, val, flightNo]];
        d.stack = "compare";
        d.xAxisIndex = 1;
        d.yAxisIndex = 1;
        return d;
      });
    }
    setSeries(ds.concat(cs));
  }, [compareFlightNO, data, dataCom, queryExt?.filter.flightNO]);

  const [option, setOption] = useState<any>();

  useEffect(() => {
    const tmp: Record<string, any> = {
      color: ["#01C5DB", "#FC9B4F", "#177DFE"],
      // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
      },
      grid: [
        { x: "8%", y: "20%", width: "38%", height: "76%" },
        { x2: "8%", y: "20%", width: "38%", height: "76%" },
      ],
      tooltip: {
        trigger: "axis",
        formatter: (params: any[]) => {
          const dim = params[0].data[2];
          let dom = "";
          params.reverse().forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const isPercentVal = true;
            const itemValue = item.value[item.encode.y[0]];
            const value = isPercentVal
              ? showNum(itemValue * 100, "percentage")
              : showNum(itemValue, "num");
            const val = `${item.seriesName}: ${value}`;
            dom = dom + dot + val + "<br />";
          });
          return dim + "<br />" + dom;
        },
      },

      xAxis: [
        { gridIndex: 0, type: "category" },
        {
          gridIndex: 1,
          type: "category",
        },
      ],
      yAxis: [
        {
          gridIndex: 0,
          type: "value",
          axisLabel: {
            formatter: (v: any) => `${round(v * 100, 2)}%`,
          },
        },
        {
          gridIndex: 1,
          type: "value",
          axisLabel: {
            formatter: (v: any) => `${round(v * 100, 2)}%`,
          },
        },
      ],
      dataZoom: null,
      series,
    };
    const opt = merge(tmp, chartConfig);
    setOption(opt);
  }, [series]);

  return (
    <Spin spinning={isLoading || comLoading}>
      <div style={{ padding: 24 }}>
        {option ? (
          <EchartsReactBase
            className="bar-line-charts"
            option={option}
            theme="default"
            style={{ height: 484 }}
            notMerge
          />
        ) : undefined}
      </div>
    </Spin>
  );
};
export default OFFContent;
