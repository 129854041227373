import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SearchAreaOrAirport from "Components/SearchAreaOrAirport";
// import SelectedAirlines from "./Search/SelectedAirlines";
import GroupSelectorEntry from "./GroupSelectorEntry";
import {
  Area,
  FlightArea,
  IDateMode,
  RangeObject,
  SystemType,
} from "Interface";
import { UserProfileTag } from "@ctrip/flt-bi-flightai-airlines";
import { DATE_FORMAT, DEFAULT_AREA, EMPTY_ARRAY } from "Constants";
import { Button, Row } from "antd";
import { ReactComponent as VS } from "assets/svg/vs.svg";
import { defaultAirlinesQueryExt, IQuery } from "../common";
import useGlobalState from "Store";
import usePageContext from "../UserProfileContext";
import { cloneDeep } from "lodash";
import { deepFind, getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import Download from "./Download/Download";
import SelectedAirlines from "Components/SelectedAirlines";
import useRefFunc from "Utils/useRefFunc";

const defaultDateMode: IDateMode = "lastThreeMonth";
export interface ISearchAreaProps {
  isAirport: boolean;
  onChange: (v: IQuery) => void;
}

/**
 * 过滤器的部分
 */
const SearchArea = (props: ISearchAreaProps): ReactElement => {
  const { isAirport, onChange } = props;
  const [tags, setTags] = useState<UserProfileTag[]>(EMPTY_ARRAY);
  const [dateRange, setDateRange] = useState<RangeObject>(
    getDateRange(defaultDateMode)
  );
  const [filter, setFilter] = useState<FlightArea>(defaultAirlinesQueryExt);

  const [isCompare, setIsCompare] = useState<boolean>(false);
  const [compareTags, setCompareTags] = useState<UserProfileTag[]>(EMPTY_ARRAY);
  const [compareDateRange, setCompareDateRange] = useState<RangeObject>(
    getDateRange(defaultDateMode)
  );
  const [compareFilter, setCompareFilter] = useState<FlightArea>(
    defaultAirlinesQueryExt
  );
  const [globalState] = useGlobalState();
  const { queryCondition, airlinesQueryCondition, systemType } = globalState;
  const [context] = usePageContext();
  const { areaSource } = context;
  // 触发首次查询
  const [mounted, setMounted] = useState<boolean>(false);
  const [tagsLoaded, setTagsLoaded] = useState<boolean>(false);
  const query =
    systemType === SystemType.airlines
      ? airlinesQueryCondition
      : queryCondition;
  const selectingFilter = useMemo(() => {
    const filterWithDep = cloneDeep(filter);
    if (systemType === SystemType.airport) {
      const tmpArea =
        deepFind(
          areaSource,
          (a: Area) => a.areaCode === queryCondition.airport && a.areaType === 5
        ) || DEFAULT_AREA;
      filterWithDep.departArea = tmpArea;
    }
    const tmp: IQuery = {
      isCompare,
      loaded: false,
      current: {
        query: {
          ...query,
          startDate: (dateRange && dateRange[0]?.format(DATE_FORMAT)) || "",
          endDate: (dateRange && dateRange[1]?.format(DATE_FORMAT)) || "",
        },
        tag: tags,
        filter: filterWithDep,
      },
    };
    if (isCompare) {
      tmp.compare = {
        query: {
          ...query,
          startDate:
            (compareDateRange && compareDateRange[0]?.format(DATE_FORMAT)) ||
            "",
          endDate:
            (compareDateRange && compareDateRange[1]?.format(DATE_FORMAT)) ||
            "",
        },
        tag: compareTags,
        filter: compareFilter,
      };
    }
    return tmp;
  }, [
    areaSource,
    compareDateRange,
    compareFilter,
    compareTags,
    dateRange,
    filter,
    isCompare,
    query,
    queryCondition.airport,
    systemType,
    tags,
  ]);
  const submitHandle = useRefFunc(() => {
    const tmp = {
      ...selectingFilter,
      loaded: true,
    };
    onChange(tmp);
  });

  const setFilterArrive = useCallback(
    (arrive: Area) => {
      if (arrive.areaCode !== filter.arriveArea.areaCode) {
        setFilter({ ...filter, arriveArea: arrive });
      }
    },
    [filter]
  );

  // #region 触发首次查询
  useEffect(() => {
    if (
      !mounted &&
      tagsLoaded &&
      areaSource.length &&
      (queryCondition.originalAirport || airlinesQueryCondition.airlines)
    ) {
      submitHandle();
      setMounted(true);
    }
  }, [
    airlinesQueryCondition.airlines,
    areaSource.length,
    mounted,
    queryCondition.originalAirport,
    submitHandle,
    tagsLoaded,
  ]);
  // #endregion

  const handleTagsLoaded = useRefFunc(() => {
    setTagsLoaded(true);
  });

  return (
    <div
      style={{ display: "flex", flexDirection: isCompare ? "column" : "row" }}
    >
      <div style={{ flexGrow: 1, display: "flex" }}>
        <div
          style={{
            width: "45%",
            flexGrow: 1,
          }}
        >
          {isAirport ? (
            <SearchAreaOrAirport
              setArea={setFilterArrive}
              submitControl={false}
              style={{ marginRight: 10 }}
            />
          ) : (
            <SelectedAirlines
              setAirlinesQueryExt={setFilter}
              submitControl={false}
              useExchange
            />
          )}
          <div style={{ margin: "5px 0" }}>
            <DateRangePickerV2
              defaultDateMode={defaultDateMode}
              onChange={setDateRange}
            />
          </div>
          <GroupSelectorEntry onChange={setTags} onLoaded={handleTagsLoaded} />
        </div>
        {isCompare ? (
          <>
            <div
              style={{ width: "10%", textAlign: "center", alignSelf: "center" }}
            >
              <VS style={{ height: 100, width: 100, alignSelf: "center" }} />
            </div>
            <div
              style={{
                width: "45%",
                flexGrow: 1,
              }}
            >
              <SelectedAirlines
                changeTypeEnable={!isAirport}
                setAirlinesQueryExt={setCompareFilter}
                submitControl={false}
                useExchange
              />
              <div style={{ margin: "5px 0" }}>
                <DateRangePickerV2
                  defaultDateMode={defaultDateMode}
                  onChange={setCompareDateRange}
                />
              </div>
              <GroupSelectorEntry onChange={setCompareTags} />
            </div>
          </>
        ) : undefined}
      </div>
      <div style={{ alignSelf: "flex-end" }}>
        <Row style={{ alignSelf: "flex-end" }}>
          <Button
            type="primary"
            onClick={() => setIsCompare((c) => !c)}
            style={{ marginRight: 20 }}
          >
            {!isCompare
              ? getSharkText("key.add.button")
              : getSharkText("key.cancel.button")}
            {getSharkText("key.compare.name")}
          </Button>
          <Button type="primary" onClick={submitHandle}>
            {getSharkText("key.query.button")}
          </Button>
          {!isCompare ? (
            <span style={{ marginLeft: 20 }}>
              <Download filter={selectingFilter}></Download>
            </span>
          ) : undefined}
        </Row>
      </div>
    </div>
  );
};
export default SearchArea;
