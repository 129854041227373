import { Drawer } from "antd";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useImperativeHandle,
  useState,
} from "react";
import FlightManage from ".";
import DrawerButton from "./DrawerButton";
import { SelectedFlight } from "./FlightManageInterface";

export interface IFlightManageDrawerProps {
  selectedFlight?: SelectedFlight | null;
}

export interface FlightManageDrawerHandler {
  open: () => void;
}

/**
 * 抽屉式展开航班管理页面
 */
const FlightManageDrawer = forwardRef(
  (
    props: IFlightManageDrawerProps,
    ref: Ref<FlightManageDrawerHandler>
  ): ReactElement => {
    const { selectedFlight } = props;
    const [open, setOpen] = useState<boolean>(false);
    // 抽屉的宽度为窗口宽度减去导航栏宽度50;
    const width = window.innerWidth - 50;
    useImperativeHandle(ref, () => ({
      open: () => setOpen(true),
    }));

    return (
      <>
        <Drawer
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          width={width}
        >
          {open && (
            <div style={{ margin: -24 }}>
              <FlightManage
                airline={selectedFlight?.airline}
                flightNo={selectedFlight?.flightNo}
                route={selectedFlight?.route}
                takeoffDate={selectedFlight?.takeoffDate}
                isDrawer={true}
              />
              <DrawerButton open={open} setOpen={setOpen} />
            </div>
          )}
        </Drawer>
        {!open && <DrawerButton open={open} setOpen={setOpen} />}
      </>
    );
  }
);

FlightManageDrawer.displayName = "FlightManageDrawer";
export default FlightManageDrawer;
