import { Popover } from "antd";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useGlobal from "Store";
import { getSharkText } from "Utils/i18nGlobal";
import ubtUtils from "Utils/ubtUtils";
import useRefFunc from "Utils/useRefFunc";

interface Props {
  /**
   * 同个页面内显示多个验证码, ID必须不同
   */
  id?: string;
}
const VerifyCode = (props: Props): ReactElement => {
  const { id = "verification-code" } = props;
  const [globalState, actions] = useGlobal();
  const { refreshCode } = globalState;
  const { setVerifyInfo } = actions;
  const [slideObj, setSlideObj] = useState<any>();
  const [needTip, setNeedTip] = useState<boolean>(false);
  const { i18n } = useTranslation(["ns1"]);
  const loadedRef = useRef(false);
  const lang = i18n.language;
  const init = () => {
    if (slideObj) {
      // 如果涉及在同一个div多次渲染验证码，可以加个判断来清除
      slideObj.clear();
    }
    // @ts-ignore
    const newSlide = new slidingVerification({
      // 验证码DIV的id
      id,
      // appId
      appId: "100026391",
      // businessSite
      businessSite: "flightai_login_online",
      // 可选填像素字符串或百分数字符串，如'280px'或'80%'，默认值'280px'
      width: "100%",
      // 滑块长度，可选，填像素字符串，如'40px'，默认值'40px'
      height: "40px",
      // 语种，默认‘zh-CN’（简体中文）
      language: lang || "zh-CN",
      // language: "en-US",
      // select_language: "en-US",
      // 选字语种，默认‘zh-CN’（简体中文）
      select_language: lang || "zh-CN",
      chooseOpt: {
        // 选字验证码定位，可选，默认absolute（绝对定位），可选fixed（相对于浏览器定位） ~2.5.0中新增
        position: "fixed",
        // 选字图片宽度，可选，选字验证码 大图的宽度
        width: "280px",
        // 选字图片高度，可选，选字验证码 大图的高度 如果选字width，height不填，生成的图片将根据后端默认配置进行屏幕大小自适应；如果选字width，height填写了，需要先找 ldq刘丹青 进行配置响应大小的图片
        height: "200px",
        // "over"： 浮窗(默认)；"pop"：弹出
        type: "pop",
      },
      // 状态监听函数
      stateChange: (state: number) => {
        if (state == 0) {
          if (typeof console !== "undefined") {
            console.log("滑块显示 state:", state);
          }
        } else if (state == 1) {
          if (typeof console !== "undefined") {
            console.log("选字验证码弹框出现 state:", state);
          }
        } else if (state == 2) {
          if (typeof console !== "undefined") {
            console.log("选字验证码弹框消失 state:", state);
          }
        }
      },
      resultHandler: (e: any) => {
        if (e.checkState === "error") {
          ubtUtils.warn("verifyError", JSON.parse(JSON.stringify(e)));
          setNeedTip(true);
        }
        // @ts-ignore
        setVerifyInfo({
          token: e.token,
          version: e.version,
          requestId: e.rid,
        });
      },
    });
    setSlideObj(newSlide);
  };

  const refresh = useRefFunc(() => {
    if (slideObj) {
      //   @ts-ignore
      slideObj.refresh();
    }
  });

  useEffect(() => {
    if (loadedRef.current) return;
    init();
    loadedRef.current = true;
  }, []);

  useEffect(() => {
    refresh();
  }, [refreshCode]);

  useEffect(() => {
    // 信安验证码有效期为5分钟. 每3分钟刷新一次
    const refreshTimer = setInterval(refresh, 3 * 60 * 1000);
    return () => {
      clearInterval(refreshTimer);
    };
  }, []);

  if (window.TARGET_PLATFORM_CONFIG) {
    return <div></div>;
  }

  return (
    <Popover
      content={
        needTip ? (
          <div style={{ width: 240 }}>
            {getSharkText(
              "config_page_if_continue_to_encounter_network_abnormal_errors"
            )}
          </div>
        ) : undefined
      }
    >
      <div className="form-group has-feedback validcode-div">
        <div id={id}></div>
      </div>
    </Popover>
  );
};

export default VerifyCode;
