import React, { useEffect, useState } from "react";
import {
  Col,
  Empty,
  Input,
  Radio,
  Result,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { openDownloadDialog, sheet2blob, useFetch, XLSX } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import moment from "moment";
import { ApproveUserData } from "Interface";
import ApproveModal from "Page/AI/ApproveUser/Components/ApproveModal";
import { FileInfo } from "@ctrip/flt-bi-flightai-base";
import ImageViewer from "Components/ImageViewer";
import DownloadBtn from "../../../../../Components/DownloadBtn";

interface ApproveTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode?: string;
}

const { Search } = Input;

const ApproveTable: React.FC<ApproveTableProps> = (
  props: ApproveTableProps
) => {
  const { queryUrl, moduleCode, chartTableCode = "" } = props;

  const [searchText, setSearchText] = useState("");
  const [searchApproveStatus, setSearchApproveStatus] = useState(3);
  const [approveList, setApproveList] = useState<ApproveUserData[]>([]);
  const [searchList, setSearchList] = useState<ApproveUserData[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [curItem, setCurItem] = useState<ApproveUserData | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [{ data, isLoading, error }, doFetch] = useFetch({
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    ext: {
      approveStatus: null,
    },
  });

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setApproveList(data);
    }
  }, [data]);

  useEffect(() => {
    const text = searchText.toLowerCase();
    const filterApproveStatus = (status: number, item: ApproveUserData) =>
      status === 3 ? true : item.approveStatus === status;
    const list = approveList.filter(
      (item) =>
        (item.mainUnitName.includes(text) ||
          item.mobile.includes(text) ||
          item.name.toLowerCase().includes(text) ||
          item.mainUnitCode.toLowerCase().includes(text)) &&
        filterApproveStatus(searchApproveStatus, item)
    );
    setSearchList(list);
  }, [approveList, searchText, searchApproveStatus]);

  if (error) {
    return <Result status="error" title="Error" subTitle={"sever error"} />;
  }
  if (_.isEmpty(data)) {
    return <Empty />;
  }

  const getUserRoleTypeText = (userData: ApproveUserData) => {
    if (userData.groupId === 0 || userData.roleList.length === 0) {
      return userData.demoType === 2
        ? getSharkText("system_type_Airline")
        : getSharkText("area.airport.title");
    } else if (userData.roleList.length > 1) {
      return getSharkText("config_page_airline_airport");
    } else {
      return userData.roleList[0].roleType === 2
        ? getSharkText("system_type_Airline")
        : getSharkText("area.airport.title");
    }
  };

  const handleClick = (curData: ApproveUserData) => {
    setIsShowModal(true);
    setCurItem(curData);
  };

  const refetchList = () => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      ext: {
        approveStatus: null,
      },
    });
  };

  const getApproveStatus = (val: number) => {
    switch (val) {
      case 1:
        return (
          <span style={{ color: "green" }}>
            {getSharkText("config_page_opened")}
          </span>
        );
      case 2:
        return (
          <span style={{ color: "grey" }}>
            {getSharkText("config_page_refused")}
          </span>
        );
      default:
        return (
          <span style={{ color: "red" }}>
            {getSharkText("config_page_untreated")}
          </span>
        );
    }
  };

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize);
    }
  };

  const columns = [
    {
      title: getSharkText("config_page_apply_time"),
      dataIndex: "applyTime",
      key: "applyTime",
      render: (value: string) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
      defaultSortOrder: "descend",
      sortDirections: ["ascend", "descend"],
      sorter: (a: any, b: any) =>
        moment(a.applyTime).isBefore(moment(b.applyTime)) ? -1 : 1,
      fixed: "left",
      width: 130,
    },
    {
      title: getSharkText("config_page_applicant_name"),
      dataIndex: "name",
      key: "name",
      fixed: "left",
      width: 130,
    },
    {
      title: getSharkText("config_page_role"),
      key: "demoType",
      fixed: "left",
      width: 100,
      render: (value: string, record: ApproveUserData) =>
        getUserRoleTypeText(record),
    },
    {
      title: getSharkText("config_page_applicant_mobile"),
      dataIndex: "mobile",
      key: "mobile",
      width: 160,
    },
    {
      title: getSharkText("config_page_work_unit"),
      dataIndex: "mainUnitName",
      key: "mainUnitName",
      render: (value: string, record: ApproveUserData) =>
        `${record.mainUnitName}${
          record.mainUnitCode ? `(${record.mainUnitCode})` : ""
        }`,
      width: 180,
    },
    {
      title: getSharkText("config_page_id_photo"),
      dataIndex: "photos",
      key: "photos",
      render: (value: FileInfo[]) => {
        return value
          ? value.map((v, i) => (
              <ImageViewer
                key={v.id}
                file={v}
                nameSuffix={(i + 1).toString()}
              />
            ))
          : "--";
      },
      width: 200,
    },
    {
      title: getSharkText("config_page_department"),
      dataIndex: "depart",
      key: "depart",
    },
    {
      title: getSharkText("config_page_job"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: getSharkText("config_page_budget"),
      dataIndex: "budget",
      key: "budget",
    },
    {
      title: getSharkText("config_page_persons"),
      dataIndex: "persons",
      key: "persons",
    },
    {
      title: getSharkText("config_page_status"),
      dataIndex: "approveStatus",
      key: "approveStatus",
      render: (value: number) => getApproveStatus(value),
    },
    {
      title: getSharkText("config_page_data_cooperation_content"),
      dataIndex: "remark",
      key: "remark",
      width: 200,
    },
    {
      title: getSharkText("config_page_remark"),
      dataIndex: "approveRemark",
      key: "approveRemark",
      width: 200,
    },
    {
      title: getSharkText("config_page_update_time"),
      dataIndex: "updateTime",
      key: "updateTime",
      width: 130,
      render: (value: string) => moment(value).format("YYYY-MM-DD"),
      sorter: (a: ApproveUserData, b: ApproveUserData) =>
        moment(a.updateTime).isBefore(moment(b.updateTime)) ? -1 : 1,
    },
    {
      title: getSharkText("config_page_operation"),
      key: "action",
      render: (value: string, record: ApproveUserData) => (
        <a onClick={() => handleClick(record)}>
          {getSharkText("config_page_PaAiApCoApIn_view_details")}
        </a>
      ),
      fixed: "right",
    },
  ];

  const handleDownload = () => {
    const sheetData: any = [];
    searchList.forEach((item: any) => {
      const obj: any = {
        申请时间: moment(item.applyTime).format("YYYY-MM-DD HH:mm:ss"),
        申请人姓名: item.name,
        角色: getUserRoleTypeText(item),
        申请人手机: item.mobile,
        uid: item.uid,
        mail: item.mail,
        工作单位: item.mainUnitName,
        数据权限: item.mainUnitCode,
        权限组:
          item.groupId === 0
            ? getSharkText("config_page_no_permission_group")
            : item.mainUnitName,
        部门: item.depart,
        人数: item.persons,
        预算: item.budget,
        状态:
          item.approveStatus === 0
            ? getSharkText("config_page_untreated")
            : item.approveStatus === 1
            ? getSharkText("config_page_opened")
            : getSharkText("config_page_refused"),
        数据合作内容: item.remark,
        备注: item.approveRemark,
      };
      sheetData.push(obj);
    });
    const sheet = XLSX.utils.json_to_sheet(sheetData);
    const blob = sheet2blob(sheet);
    openDownloadDialog(blob, getSharkText("menu_approve_user") + ".xlsx");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleChangeSearchApproveStatus = (e: RadioChangeEvent) => {
    setSearchApproveStatus(e.target.value);
  };

  return (
    <>
      <Row gutter={16}>
        <Col>
          <Search
            placeholder={getSharkText("config_page_phone_name_work_unit")}
            onChange={handleSearch}
            style={{ width: 300 }}
          />
        </Col>
        <Col>
          <Radio.Group
            value={searchApproveStatus}
            onChange={handleChangeSearchApproveStatus}
          >
            <Radio.Button value={3}>
              {getSharkText("config_page_all_status")}
            </Radio.Button>
            <Radio.Button value={0}>
              {getSharkText("config_page_untreated")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("config_page_opened")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("config_page_refused")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col style={{ flex: 1, textAlign: "right" }}>
          <DownloadBtn
            handleDownload={handleDownload}
            showIcon={false}
            style={{ marginBottom: 16 }}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      <Table
        showSorterTooltip={false}
        loading={isLoading}
        tableLayout="fixed"
        dataSource={searchList}
        scroll={{ x: 1800 }}
        rowKey="id"
        // @ts-ignore
        columns={columns}
        onChange={handleChangePagination}
        pagination={{
          pageSize,
          current: currentPage,
          showTotal: (total) => (
            <>
              {getSharkText("config_page_total")}&nbsp;{total}
            </>
          ),
        }}
      />
      {curItem ? (
        <ApproveModal
          visible={isShowModal}
          setVisible={setIsShowModal}
          data={curItem}
          refetch={refetchList}
        />
      ) : undefined}
    </>
  );
};

export default ApproveTable;
