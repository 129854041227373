import { Alert, Checkbox, Col, Row } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import UpdateTime from "Components/UpdateTime";
import React, { ReactElement, useState } from "react";
import { enumKeys } from "Utils/global";
import { MODULE_CODE } from "../fetchCode";
import {
  EPanelDays,
  EPanelName,
  EPanelType,
  EStatus,
  HelpInfo,
} from "../MonitoringAlertingCommon";
import SearchArea from "./SearchArea";
import TablePortal from "./TablePortal";

/**
 * Component description
 * 预警页面
 */

interface IProps {
  example?: string;
  panelType: EPanelType;
}

const PanelContent = (props: IProps): ReactElement => {
  const { panelType } = props;
  const [queryExt, setQueryExt] = useState<Record<string, any>>();
  const statusKeys = enumKeys(EStatus);
  const [selectedStatus, setSelectedStatus] = useState<number[]>([0]);

  const onStatusChange = (v: CheckboxValueType[]) => {
    setSelectedStatus(v as number[]);
  };
  return (
    <div className="content-white">
      <Alert
        message={HelpInfo[enumKeys(EPanelType)[panelType]]}
        banner
        type="info"
      />
      <SearchArea panelType={panelType} onChange={setQueryExt} />
      <Row
        style={{ margin: "0 0 10px 0", alignItems: "center" }}
        gutter={[20, 20]}
      >
        <Col>
          <UpdateTime
            moduleCode={MODULE_CODE[2]}
            chartTableCode="none"
            text={
              <Alert
                banner
                message={`提前${
                  EPanelDays[enumKeys(EPanelType)[panelType]] || 45
                }天监控航线${EPanelName[panelType]}指数，及时掌握市场变化。`}
                type="info"
              />
            }
          />
        </Col>
        <Col>
          <Checkbox.Group value={selectedStatus} onChange={onStatusChange}>
            {statusKeys.map((key) => (
              <Checkbox key={key} value={EStatus[key]}>
                {key}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Col>
      </Row>
      <TablePortal
        panelType={panelType}
        queryExt={queryExt}
        selectedStatus={selectedStatus}
      ></TablePortal>
    </div>
  );
};
export default PanelContent;
