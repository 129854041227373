import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import useRefFunc from "Utils/useRefFunc";
import { Radio, Spin } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import SelectMultiFlight from "../SearchArea/SelectMultFlight";
import "./index.scss";
import columns from "./columns";
import { orderMerge, U, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getEnv } from "Utils/global";
import {
  Dimension,
  Filter,
  Measure,
  Sorter,
} from "Page/AI/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import Refetch from "Components/Refetch";
import { IFlightRecord } from "../../FlightManageInterface";
import { cloneDeep } from "lodash";
import { EMPTY_ARRAY } from "Constants";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";

// #region 文本
const CURRENT_FLIGHT = getSharkText("config_page_current_flight");
const COMPETE_FLIGHT = getSharkText("config_page_compete_flight");
// #endregion

/** 提前售卖明细表的数据集的ID */
const isPro = getEnv() === "pro";
const DATASET_ID = isPro ? 23 : 22;
const TAKEOFF_ID = isPro ? 332 : 595;
const TAKEOFF_NAME = "takeoffdate";
const AHEADDAY_ID = isPro ? 333 : 596;
const AHEADDAY_NAME = "aheadday";
const FLIGHTNO_ID = isPro ? 336 : 599;
const FLIGHTNO_NAME = "flightno";
const ROUTE_ID = isPro ? 465 : 612;
const ROUTE_NAME = "route";
const CAP_ID = isPro ? 339 : 602;
const CAP_NAME = "cap";
const SALED_BEFORE_ID = isPro ? 342 : 605;
const SALED_BEFORE_NAME = "saledseat_before";
const LF_BEFORE_ID = isPro ? 343 : 606;
const LF_BEFORE_NAME = "lf_before";
const SUBCABINBKD_DETAIL_ID = isPro ? 344 : 607;
const SUBCABINBKD_DETAIL_NAME = "subcabinbkddetail";
const COLLECTAIRLINE = "collectairline";
const SHOW_HOUR_ID = isPro ? 347 : 610;
const SHOW_HOUR_NAME = "show_hour";

/** 本航班和竞飞航班切换 */
const FLIGHT_IS_SELF_OPTIONS = [
  // 本航班
  {
    label: CURRENT_FLIGHT,
    value: 1,
  },
  {
    label: COMPETE_FLIGHT,
    value: 0,
  },
];

/** 计算舱位变化明细 */
const computeChangeDetail = (
  currentDetail: string,
  previewDetail: string
): string => {
  const currentCabinSoldList = currentDetail.split(",");
  const currentCabins = currentCabinSoldList.map((c) => c.substring(0, 1));
  const previewCabinSoldList = previewDetail.split(",");
  const previewCabins = previewCabinSoldList.map((c) => c.substring(0, 1));
  const allCabins = orderMerge(currentCabins, previewCabins);
  const result: string[] = [];
  allCabins.forEach((cabin) => {
    const current = currentCabinSoldList.find(
      (c) => c.substring(0, 1) === cabin
    );
    const preview = previewCabinSoldList.find(
      (c) => c.substring(0, 1) === cabin
    );
    if (!preview) {
      if (!current) {
        return;
      }
      result.push(current);
      return;
    }
    const previewSold = U.getStringFirstNumber(preview);
    if (previewSold == null) {
      result.push(cabin + "-");
      return;
    }
    if (!current) {
      result.push(cabin + -1 * previewSold);
      return;
    }
    const currentSold = U.getStringFirstNumber(current);
    if (currentSold == null) {
      result.push(preview);
      return;
    }
    const diff = currentSold - previewSold;
    if (diff !== 0) {
      result.push(cabin + diff);
    }
  });
  return result.join(",");
};

const genShowHours = (duration = 2): number[] => {
  const all = Array.from({ length: 24 }, (v, k) => k);
  return all.filter((a) => a % duration === 0);
};

// @ts-ignore
window.hour = genShowHours;

interface ResData {
  aheadday: string;
  subcabinbkddetail: string;
  SUM_saledseat_before: string;
  SUM_lf: number;
  SUM_cap: string;
}

export interface DetailTableProps {
  record: IFlightRecord;
  compareFlightNo?: string;
  moduleCode: string;
  chartTableCode: string;
}

/** 航班管理航班提前售卖明细表格 */
const DetailTable = (props: DetailTableProps): ReactElement => {
  const {
    record,
    compareFlightNo: propCompareFlightNo,
    moduleCode,
    chartTableCode,
  } = props;
  /** 本航班还是竞飞航班 */
  const [isSelf, setIsSelf] = useState<0 | 1>(1);
  const [agg, setAgg] = useState<string>("1d");
  const [compareFlightNo, setCompareFlightNo] = useState<string[]>(
    propCompareFlightNo ? [propCompareFlightNo] : EMPTY_ARRAY
  );
  useEffect(() => {
    setCompareFlightNo(
      propCompareFlightNo ? [propCompareFlightNo] : EMPTY_ARRAY
    );
  }, [propCompareFlightNo]);
  const [resData, setResData] = useState<ResData[]>([]);
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const services = useServices();
  const aggOptions = useMemo(() => {
    return [
      {
        label: "2h",
        value: "2h",
      },
      {
        label: "1d",
        value: "1d",
      },
    ];
  }, []);
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_ID)
      .then(
        (r) => {
          // @ts-ignore
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{ isLoading, error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const rows = res.rows;
      const headers = res.headers;
      const source = DataRow2ListMap(rows, headers);
      setResData(source as unknown as ResData[]);
    },
  });

  const refetch = useCallback(() => {
    const flightNo = isSelf ? record.flightNo : compareFlightNo.join(",");
    if (!flightNo || !record || !datasetCols.length) {
      return;
    }
    const showHours =
      agg === "1d" ? [0] : genShowHours(U.getStringFirstNumber(agg) || 0);
    const dimensions: Dimension[] = [
      {
        columnId: AHEADDAY_ID,
        columnName: AHEADDAY_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnId: SUBCABINBKD_DETAIL_ID,
        columnName: SUBCABINBKD_DETAIL_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnId: SHOW_HOUR_ID,
        columnName: SHOW_HOUR_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ];
    const measures: Measure[] = [
      {
        id: "cap",
        columnId: CAP_ID,
        columnName: CAP_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "saleseat",
        columnId: SALED_BEFORE_ID,
        columnName: SALED_BEFORE_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "SUM",
          },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: "lf",
        columnId: LF_BEFORE_ID,
        columnName: LF_BEFORE_NAME,
        measureConfig: {
          statisticalConfig: {
            method: "SUM",
          },
          formatConfig: null,
          comparison: null,
        },
      },
    ];
    const filters: Filter[] = [
      {
        columnId: TAKEOFF_ID,
        columnName: TAKEOFF_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [record.takeoffdate || ""],
          },
        ],
      },
      {
        columnId: FLIGHTNO_ID,
        columnName: FLIGHTNO_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [flightNo],
          },
        ],
      },
      {
        columnId: ROUTE_ID,
        columnName: ROUTE_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [record.route || ""],
          },
        ],
      },
      {
        columnId: SHOW_HOUR_ID,
        columnName: SHOW_HOUR_NAME,
        filterConfig: [
          {
            calculate: "include",
            argsType: "number",
            numberArgs: showHours,
          },
        ],
      },
      {
        columnName: COLLECTAIRLINE,
        filterConfig: [
          {
            calculate: "include",
            argsType: "string",
            stringArgs: [record.flightNo.substring(0, 2) || ""],
          },
        ],
      },
    ];
    const sorters: Sorter[] = [
      {
        chartUsedColId: AHEADDAY_NAME,
        columnId: AHEADDAY_ID,
        columnName: AHEADDAY_NAME,
        sorter: "ASC",
        statistical: "COUNT",
      },
      {
        chartUsedColId: SHOW_HOUR_NAME,
        columnId: SHOW_HOUR_ID,
        columnName: SHOW_HOUR_NAME,
        sorter: "DESC",
        statistical: "COUNT",
      },
    ];
    const requestBuild = new RequestBuilder({
      datasetId: DATASET_ID,
      columns: datasetCols,
      dimensions,
      measures,
      chartFilters: filters,
      sorters,
      containerFilters: [],
    });
    const { encrypted } = requestBuild.getRequestBody();
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [agg, compareFlightNo, datasetCols, doFetch, isSelf, record]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  /** 计算变化明细 */
  const dataSource = useMemo(() => {
    const tmpData = cloneDeep(resData);
    const rst: Array<
      ResData & { changeDetail: string; daySold: number; key: number }
    > = [];
    for (let i = 0; i < tmpData.length; i++) {
      let changeDetail;
      let daySold;
      const currentNode = tmpData[i];
      if (i === tmpData.length - 1) {
        changeDetail = currentNode.subcabinbkddetail;
        daySold = Number(currentNode.SUM_saledseat_before);
      } else {
        const previewNode = tmpData[i + 1];
        changeDetail = computeChangeDetail(
          currentNode.subcabinbkddetail || "",
          previewNode.subcabinbkddetail || ""
        );
        daySold =
          Number(currentNode.SUM_saledseat_before) -
          Number(previewNode.SUM_saledseat_before);
      }
      rst.push({
        ...tmpData[i],
        changeDetail,
        daySold,
        key: i,
      });
    }
    return rst;
  }, [resData]);

  return (
    <div className="flightManage-detailTable">
      <div className="searchArea">
        <div className="flight">
          <div>
            <Radio.Group
              options={FLIGHT_IS_SELF_OPTIONS}
              value={isSelf}
              onChange={(e) => setIsSelf(e.target.value)}
            ></Radio.Group>
          </div>
          {propCompareFlightNo === undefined ? (
            <div>
              <SelectMultiFlight
                title=""
                flightNO={compareFlightNo}
                setFlightNO={setCompareFlightNo}
                routes={record.route}
                isDemo={false}
                type={3}
                width={100}
                size="small"
                disabled={!!isSelf}
                mode={undefined}
              />
            </div>
          ) : undefined}
        </div>
        <div className="agg">
          <Radio.Group
            options={aggOptions}
            optionType="button"
            buttonStyle="solid"
            size="small"
            value={agg}
            onChange={(e) => setAgg(e.target.value)}
          ></Radio.Group>
        </div>
      </div>
      {error ? (
        <Refetch refetch={refetch}></Refetch>
      ) : (
        <Spin spinning={isLoading}>
          <CustomTableV2
            tableClass="micro-table"
            columns={columns}
            dataSource={dataSource}
            tableProps={{
              scroll: { x: "100%" },
              tableLayout: "auto",
              size: "small",
              bordered: true,
            }}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Spin>
      )}
    </div>
  );
};
export default DetailTable;
