import { showNum } from "Utils";
import { Col, Row } from "antd";
import React, { ReactElement } from "react";
import "./OverviewCardItem.scss";
import { ChartContainer } from "@ctrip/flt-bidw-mytrix-ui";
import {
  StandardMatrixRequest,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { MetricSchema } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/schema";
import {
  ChartRenderConfig,
  DimensionSchemaEx,
  Measure,
  MeasureSchemaEx,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { valueFormatter } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/Components/Views";
import { getTrendColor } from "Page/AI/ProfitMonitor";

export interface OverviewCardItemProps {
  title?: string;
  measure: Measure;
  line: {
    request: StandardMatrixRequest;
    response: StandardResponse;
    schema: MetricSchema<DimensionSchemaEx, MeasureSchemaEx>;
    renderConfig: ChartRenderConfig;
  } | null;
  data: {
    value: number | null;
    yoy: number | null;
    wow: number | null;
    dayValue?: number | null;
  };
}

/** 汇总卡片 */
const OverviewCardItem = (props: OverviewCardItemProps): ReactElement => {
  const { title, data, line, measure } = props;
  return (
    <div className="profit-monitor_overview_card" style={{ padding: 10 }}>
      <div className="card-header">{title}</div>
      <Row style={{}}>
        <Col span={12}>
          <div style={{ fontSize: "130%", fontWeight: "bold" }}>
            {valueFormatter(data.value, measure.measureConfig.formatConfig)}
          </div>
          <div>
            {valueFormatter(data.dayValue, measure.measureConfig.formatConfig)}
          </div>
        </Col>
        <Col span={12}>
          {line && (
            <div style={{ height: 60 }}>
              <ChartContainer
                request={line.request}
                response={line.response}
                schema={line.schema}
                renderConfig={line.renderConfig}
              />
            </div>
          )}
        </Col>
      </Row>
      <Row
        justify="space-between"
        style={{ borderTop: "1px solid #ccc", marginTop: 5, paddingTop: 5 }}
      >
        <Col span={12}>
          环比:{" "}
          <span
            style={{
              color: getTrendColor(data.wow),
            }}
          >
            {data.wow == null ? "-" : showNum(data.wow * 100, "percentage")}
          </span>
        </Col>
        <Col span={12}>
          同比:{" "}
          <span
            style={{
              color: getTrendColor(data.yoy),
            }}
          >
            {data.yoy == null ? "-" : showNum(data.yoy * 100, "percentage")}
          </span>
        </Col>
      </Row>
    </div>
  );
};
export default OverviewCardItem;
