import { getSharkText } from "Utils/i18nGlobal";

export const getAreaTypeName = (type: number, code: string) => {
  switch (type) {
    case 1:
      return getSharkText("area.continent.title");
    case 2:
      if (code === fixHMTCode(code)) {
        return getSharkText("area.country.title");
      } else {
        return getSharkText("area.region.title");
      }
    case 3:
      return getSharkText("area.province.title");
    case 4:
      return getSharkText("area.city.title");
    case 5:
      return getSharkText("area.airport.title");
    default:
      return "";
  }
};

export const fixHMTCode = (code: string) => {
  if (code === "HK" || code === "MO" || code === "TW") {
    return "CN";
  }
  return code;
};
