import React, { useEffect, useMemo, useState } from "react";
import { Input, message, Select, SelectProps } from "antd";
import useGlobal from "Store";
import { AirportOrAirline, Role } from "Interface";
import {
  getAirportsOrAirlinesArr,
  getModuleNameFromPath,
  getModuleStatus,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useFetch } from "Utils/useFetch";

interface AirportProps extends SelectProps<any> {
  queryUrl?: string;
  moduleCode?: string;
  server?: string;
  /**
   * 非携程员工访问时使用的航司数据集
   */
  airlines?: AirportOrAirline[];
  /**
   * 携程员工访问时使用的航司数据集, 不指定时使用findAirlines获取的航司全集
   */
  ctripAirlines?: AirportOrAirline[];
  /**
   * 如果启用了onChange, 那么Airline变更时, 将不会写入全局的airlinesQueryCondition
   */
  onChange?: (v: AirportOrAirline) => void;
  /**
   * 仅在启用onChange时有用, 可以通过外部传入参数的方式管理选中值
   */
  value?: string;
  /**
   * 是否允许不选择航司, 默认不允许
   */
  allowEmpty?: boolean;
}

const { Option } = Select;

const Airlines: React.FC<AirportProps> = (props: AirportProps) => {
  const {
    queryUrl = "findAirlines",
    moduleCode = "common",
    server,
    airlines: propsAirlines,
    ctripAirlines,
    onChange,
    value: propsValue,
    allowEmpty = false,
    ...otherProps
  } = props;
  const location = useLocation();
  const [allAirlines, setAllAirlines] = useState<AirportOrAirline[]>([]);
  const [globalState, actions] = useGlobal();
  const { airlinesQueryCondition, userInfo, systemType } = globalState;
  const { user } = userInfo;
  const { setAirlinesQueryConditionAirline } = actions;
  const isCtrip = user.userType === 1;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;

  const airlines = useMemo(() => {
    if (propsAirlines) {
      return propsAirlines;
    } else {
      const { roleList } = userInfo;
      const airlinesRole = roleList
        ? roleList.find((item: Role) => item.roleType === 2)
        : undefined;
      return getAirportsOrAirlinesArr(airlinesRole, false);
    }
  }, [propsAirlines, userInfo]);
  const [{ data }, doFetch] = useFetch({
    url: queryUrl,
    server,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
    lazey: true,
  });

  useEffect(() => {
    // 不传入airlines的情况下, 设置默认值
    if (!propsAirlines && !airlinesQueryCondition.airlines) {
      setAirlinesQueryConditionAirline({
        airlines: airlines[0].code.split(",")[0],
        airlinesName: airlines[0].name.split(",")[0],
      });
    }
    if (isCtrip) {
      doFetch({ ext: { type: 1 } });
    }
  }, []);

  useEffect(() => {
    if (ctripAirlines) {
      setAllAirlines(ctripAirlines);
    } else if (!_.isEmpty(data)) {
      setAllAirlines(
        data.map((d: any) => ({ code: d.airlinesCode, name: d.airlinesName }))
      );
    }
  }, [ctripAirlines, data]);

  const handleSelectAirlines = (values: string[], option: any) => {
    if (!allowEmpty && _.isEmpty(values)) {
      message.error(getSharkText("key.choose_an_airline.hint"));
    } else {
      const codeArr: string[] = [];
      const nameArr: string[] = [];
      let code = "";
      let name = "";
      if (Array.isArray(option)) {
        option.forEach((item: any) => {
          codeArr.push(item.props.value);
          nameArr.push(item.props.label);
        });
        code = codeArr.join(",");
        name = nameArr.join(",");
      } else {
        code = option.props.value;
        name = option.props.label;
      }
      if (onChange) {
        onChange({ code, name });
      } else {
        setAirlinesQueryConditionAirline({
          airlines: code,
          airlinesName: name,
        });
      }
    }
  };

  const filterOption = (input: string, option: any) =>
    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.label.includes(input);

  // 启用onChange时, 不使用全局航司, 并且使用外部propValue
  const outerValue = useMemo(() => {
    return propsValue ? propsValue.split(",").filter((f) => !!f) : undefined;
  }, [propsValue]);
  const selectValues = onChange
    ? outerValue
    : airlinesQueryCondition.airlines
    ? airlinesQueryCondition.airlines.split(",")
    : [];

  const showAirlines = useMemo(() => {
    return isCtrip ? allAirlines : airlines;
  }, [airlines, allAirlines, isCtrip]);

  return (
    <>
      {isDemo ? (
        <Input style={{ minWidth: 200 }} disabled value="Demo" />
      ) : (
        <Select
          id="toolbarAirlinesSelect"
          showSearch
          style={{ minWidth: 150 }}
          value={selectValues}
          onChange={handleSelectAirlines}
          filterOption={filterOption}
          mode="multiple"
          data-ut-id="toolbarAirlinesSelect"
          data-ut-class="airlineSelector"
          {...otherProps}
        >
          {showAirlines.map((item) => (
            <Option key={item.code} value={item.code} label={item.name}>
              {item.name ? item.name + "|" + item.code : item.code}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};

export default Airlines;
