import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Switch,
  Tabs,
} from "antd";
import { cloneDeep } from "lodash";
import SelectMultiFlight, {
  SelectMultFlightProps,
} from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import SelectMultiRoute, {
  SelectMultiRouteProps,
} from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import React, {
  ReactElement,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import {
  MODULE_CODE as FlightModule,
  QUERY_URL as FlightQuery,
} from "Page/AI/FlightManage/fetchCode";
import { IScribe, ISubScribe, ScribeName } from "../NotificationCenterCommon";

import styles from "./Settings.module.scss";
import CompareChange from "./SettingTabs/CompareChange";
import CompareLoad from "./SettingTabs/CompareLoad";
import ComparePrice from "./SettingTabs/ComparePrice";
import Last4Week from "./SettingTabs/Last4Week";
import LoadAbnormal from "./SettingTabs/LoadAbnormal";
import {
  defaultCompareChangeConfig,
  defaultCompareLoadConfig,
  defaultLast4WeekConfig,
  defaultLoadAbnormalConfig,
  defaultPriceConfig,
  defaultRuleList,
} from "./SettingCommon";
import { MonitoringAlertingConfig } from "@ctrip/flt-bi-flightai-airlines";
import useRefFunc from "Utils/useRefFunc";
import { SystemType } from "Interface";
import SelectRouteAndFlight from "Page/AI/FlightManage/Components/SearchArea/SelectRouteAndFlight";

const { TabPane } = Tabs;

export interface ISettingsProps {
  setUserConfig: (v: any) => void;
  res: any;
  refetchRule: () => void;
}

const findRule = (
  list: MonitoringAlertingConfig[],
  configType: number,
  flightNo: string,
  route: string
) => {
  const rst = list.find(
    (l) =>
      l.configType === configType &&
      l.flightno === flightNo &&
      l.route === route
  );
  return rst;
};

/**
 * 设置面板
 */
const SS = (props: ISettingsProps, ref: any): ReactElement => {
  const { setUserConfig, res, refetchRule } = props;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition, userInfo } = globalState;
  const [routes, setRoutes] = useState<string[]>([]);
  const [remoteSubScribe, setRemoteSub] = useState<ISubScribe>();
  const [last4Week, setLast4Week] = useState<MonitoringAlertingConfig>(
    defaultLast4WeekConfig
  );
  const [compareLoad, setCompareLoad] = useState<MonitoringAlertingConfig>(
    defaultCompareLoadConfig
  );
  const [comparePrice, setComparePrice] =
    useState<MonitoringAlertingConfig>(defaultPriceConfig);
  const [compareChange, setCompareChange] = useState<MonitoringAlertingConfig>(
    defaultCompareChangeConfig
  );
  const [loadAbnormal, setLoadAbnormal] = useState<MonitoringAlertingConfig>(
    defaultLoadAbnormalConfig
  );
  const [ruleFlightNo, setRuleFlightNo] = useState<string[]>([]);
  const [ruleRoutes, setRuleRoutes] = useState<string[]>([]);
  const [comRuleFlightNo, setComRuleFlightNo] = useState<string[]>([]);
  const [activeTab, setActiveTab] = useState<string>("1");
  const [wechatAccount, setWechatAccount] = useState<string>("");
  const [scribe, setScribe] = useState<IScribe>({
    price: 0,
    dynamicPrice: 0,
    capacity: 0,
    search: 0,
    lf: 0,
    priceMonitor: 0,
    manualReview: 0,
  });
  const [mail, setMail] = useState<boolean>(false);
  const [wechat, setWechat] = useState<boolean>(false);

  const [{ isLoading: isSaving }, doSubmit] = useFetch<any[]>({
    server: getServer(systemType),
    url: QUERY_URL.setRule,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_save_success"));
      refetchRule();
    },
    onError: () => {
      message.error(getSharkText("config_page_save_failed"));
    },
  });

  const [, doDelete] = useFetch({
    server: getServer(SystemType.airlines),
    url: QUERY_URL.delRule,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_delete_success"));
      // refetchRule();
      const newRules: MonitoringAlertingConfig[] = [];
      defaultRuleList.forEach((r) => {
        const newRule: MonitoringAlertingConfig = {
          ...r,
          flightno: ruleFlightNo.join(),
          route: ruleRoutes.join(),
          compareFlightno: comRuleFlightNo.join(),
        };
        newRules.push(newRule);
      });
      const subScribe: ISubScribe = {
        ...scribe,
        userId: userInfo.user.id,
        route: routes.join(),
        routecity: routes.join(),
        mail: mail ? 1 : 0,
        wechat: wechat ? 1 : 0,
        wechatAccount,
      };
      doSubmit({
        ext: {
          subscribe: res.subscribe || subScribe,
          configList: newRules,
        },
      });
    },
    onError: () => {
      message.error(getSharkText("config_page_operation_exception"));
    },
  });

  const [{ res: QRRes }] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL.getQR,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
  });

  // #region 是否可以编辑非竞飞规则
  const editable = useMemo(() => {
    if (!res || !res.configList || !ruleFlightNo.length || !ruleRoutes.length) {
      return false;
    }
    const existsList = res.configList.filter(
      (r: MonitoringAlertingConfig) =>
        r.flightno === ruleFlightNo.join() &&
        r.route === ruleRoutes.join() &&
        ((!comRuleFlightNo.length && !r.compareFlightno) ||
          comRuleFlightNo[0] === r.compareFlightno)
    );
    return existsList.length > 0;
  }, [comRuleFlightNo, res, ruleFlightNo, ruleRoutes]);
  // #endregion

  // #region 是否可以编辑竞飞相关的规则
  const editableCompare = useMemo(() => {
    if (
      !res ||
      !res.configList ||
      !ruleFlightNo.length ||
      !ruleRoutes.length ||
      !comRuleFlightNo.length
    ) {
      return false;
    }
    const existsList = res.configList.filter(
      (r: MonitoringAlertingConfig) =>
        r.flightno === ruleFlightNo.join() &&
        r.route === ruleRoutes.join() &&
        (!comRuleFlightNo.length || comRuleFlightNo[0] === r.compareFlightno)
    );
    return existsList.length > 0;
  }, [comRuleFlightNo, res, ruleFlightNo, ruleRoutes]);
  // #endregion

  // #region 是否可以保存竞飞, 航班航线不完整, 或者已经存在相同的竞飞关系, 则不能保存竞飞
  const canSaveCompare = useMemo(() => {
    if (!ruleFlightNo.length || !ruleRoutes.length) {
      return false;
    }
    const existsList = res?.configList.filter(
      (r: MonitoringAlertingConfig) =>
        r.flightno === ruleFlightNo.join() &&
        r.route === ruleRoutes.join() &&
        ((!comRuleFlightNo.length && !r.compareFlightno) ||
          comRuleFlightNo[0] === r.compareFlightno)
    );
    return existsList?.length === 0;
  }, [comRuleFlightNo, res?.configList, ruleFlightNo, ruleRoutes]);
  // #endregion

  // #region 根据航班和航线/竞飞航班的切换, 寻找是否已有相同的竞飞配置, 如果存在则加载, 否则使用default配置
  useEffect(() => {
    if (res?.subscribe) {
      setRemoteSub(res.subscribe);
    }
    let list: MonitoringAlertingConfig[] = [];
    if (
      res?.configList &&
      ruleFlightNo &&
      ruleRoutes &&
      res.configList.find(
        (r: MonitoringAlertingConfig) =>
          r.flightno === ruleFlightNo.join() &&
          r.route === ruleRoutes.join() &&
          ((!r.compareFlightno && !comRuleFlightNo.length) ||
            r.compareFlightno === comRuleFlightNo[0])
      )
    ) {
      message.success("加载了已存在的航线/航班/竞飞配置相同的规则");
      list = cloneDeep(res.configList) || [];
    } else {
      list = cloneDeep(defaultRuleList);
    }
    const flightNo = ruleFlightNo.join();
    const route = ruleRoutes.join();
    setLast4Week(
      findRule(list, 1, flightNo, route) || cloneDeep(defaultLast4WeekConfig)
    );
    setCompareLoad(
      findRule(list, 2, flightNo, route) || cloneDeep(defaultCompareLoadConfig)
    );
    setComparePrice(
      findRule(list, 3, flightNo, route) || cloneDeep(defaultPriceConfig)
    );
    setCompareChange(
      findRule(list, 4, flightNo, route) ||
        cloneDeep(defaultCompareChangeConfig)
    );
    setLoadAbnormal(
      findRule(list, 5, flightNo, route) || cloneDeep(defaultLoadAbnormalConfig)
    );
    setUserConfig(res);
  }, [res, setUserConfig, ruleFlightNo, ruleRoutes, comRuleFlightNo]);
  // #endregion

  // #region 加载指定的航班航线竞飞
  // 设置当前需要编辑的规则, 设置航班航线后, 会根据航班航线读取对应的配置
  const handleSetEditingRUles = useRefFunc((v: MonitoringAlertingConfig[]) => {
    const config = v[0];
    setRuleFlightNo(config?.flightno?.split(",") || []);
    setRuleRoutes(config?.route?.split(",") || []);
    setComRuleFlightNo(config?.compareFlightno?.split(",") || []);
  });
  // #endregion

  // #region 加载用户订阅内容
  useEffect(() => {
    if (remoteSubScribe) {
      setScribe(remoteSubScribe);
      setMail(remoteSubScribe.mail === 1);
      setWechat(remoteSubScribe.wechat === 1);
      setRoutes(remoteSubScribe.route.split(","));
      setWechatAccount(remoteSubScribe.wechatAccount);
    }
  }, [remoteSubScribe]);
  // #endregion

  /**
   * 当微信号设置不为空时, 视为选中微信推送
   */
  useEffect(() => {
    if (wechatAccount) {
      setWechat(true);
    }
  }, [wechatAccount]);

  const submit = useRefFunc((config?: MonitoringAlertingConfig) => {
    const subScribe: ISubScribe = {
      ...scribe,
      userId: userInfo.user.id,
      route: routes.join(),
      routecity: routes.join(),
      mail: mail ? 1 : 0,
      wechat: wechat ? 1 : 0,
      wechatAccount,
    };
    console.log("subScribe: ", subScribe);
    let saveList: MonitoringAlertingConfig[] = [];
    if (config) {
      const extConfig: MonitoringAlertingConfig = {
        ...config,
        flightno: ruleFlightNo.join(),
        compareFlightno: comRuleFlightNo.join(),
        route: ruleRoutes.join(),
      };
      saveList = [extConfig];
    } else {
      saveList = [
        last4Week,
        compareLoad,
        compareChange,
        comparePrice,
        loadAbnormal,
      ];
    }
    doSubmit({
      query: airlinesQueryCondition,
      ext: {
        subscribe: subScribe,
        configList: saveList,
      },
    });
  });

  // #region 登记航线预警范围
  const submitMonitoringRoutes = useRefFunc(() => {
    const subScribe: ISubScribe = {
      ...scribe,
      userId: userInfo.user.id,
      route: routes.join(),
      routecity: routes.join(),
      mail: mail ? 1 : 0,
      wechat: wechat ? 1 : 0,
      wechatAccount,
    };
    doSubmit({
      query: airlinesQueryCondition,
      ext: {
        subscribe: subScribe,
        configList: [],
      },
    });
  });
  // #endregion

  /**
   * 保存竞飞航班关系, 如果已经存在的config列表中, 有相同的航班+航线组合, 则将竞飞关系替换, 如果没有, 则创建一组新的监控列表
   */
  const submitCompare = useRefFunc(() => {
    // 是否存在竞飞不同的配置
    const existsDiffList = res.configList.filter(
      (r: MonitoringAlertingConfig) =>
        r.flightno === ruleFlightNo.join() &&
        r.route === ruleRoutes.join() &&
        (!!r.compareFlightno !== !!comRuleFlightNo.length ||
          r.compareFlightno !== comRuleFlightNo[0])
    );
    if (existsDiffList.length) {
      Modal.confirm({
        content: `${getSharkText(
          "config_page_exists_other_competing_flight_config"
        )} ${existsDiffList[0].compareFlightno}`,
        onOk: () => {
          const list = res.configList.filter(
            (f: MonitoringAlertingConfig) =>
              f.flightno === existsDiffList[0].flightno &&
              f.route === existsDiffList[0].route &&
              f.compareFlightno === existsDiffList[0].compareFlightno
          );
          doDelete({
            ext: {
              configIds: list.map((l: MonitoringAlertingConfig) => l.configId),
            },
          });
        },
      });
    } else {
      const newRules: MonitoringAlertingConfig[] = [];
      defaultRuleList.forEach((r) => {
        const newRule: MonitoringAlertingConfig = {
          ...r,
          flightno: ruleFlightNo.join(),
          route: ruleRoutes.join(),
          compareFlightno: comRuleFlightNo.join(),
        };
        newRules.push(newRule);
      });
      const subScribe: ISubScribe = {
        ...scribe,
        userId: userInfo.user.id,
        route: routes.join(),
        routecity: routes.join(),
        mail: mail ? 1 : 0,
        wechat: wechat ? 1 : 0,
        wechatAccount,
      };
      doSubmit({
        ext: {
          subscribe: res.subscribe || subScribe,
          configList: newRules,
        },
      });
    }
  });

  useImperativeHandle(ref, () => ({
    setEditingConfig: handleSetEditingRUles,
  }));

  const changeScribe = (v: boolean, key: keyof IScribe) => {
    setScribe({
      ...scribe,
      [key]: v ? 1 : 0,
    });
  };

  // #region 保存消息推送
  const submitSubScribe = useRefFunc(() => {
    const subScribe: ISubScribe = {
      ...scribe,
      userId: userInfo.user.id,
      route: routes.join(),
      routecity: routes.join(),
      mail: mail ? 1 : 0,
      wechat: wechat ? 1 : 0,
      wechatAccount,
    };
    doSubmit({
      query: airlinesQueryCondition,
      ext: {
        subscribe: subScribe,
        configList: [],
      },
    });
  });
  // #endregion

  const flightProps = useMemo(() => {
    const tmp: SelectMultFlightProps = {
      queryUrl: FlightQuery.routeFlight,
      moduleCode: FlightModule,
      flightNO: ruleFlightNo,
      setFlightNO: setRuleFlightNo,
      isDemo: false,
      routes: "",
      width: "auto",
      placeholder: getSharkText("key.mutiple_flightno.query"),
      debugId: "notification_settings_ori_flight",
    };
    return tmp;
  }, [ruleFlightNo]);

  const routeProps = useMemo(() => {
    const tmp: SelectMultiRouteProps = {
      routes: ruleRoutes,
      setRoutes: setRuleRoutes,
      isDemo: false,
      flights: ruleFlightNo.join(","),
      width: "auto",
      debugId: "notification_settings_ori_route",
      autoSelectFirst: true,
    };
    return tmp;
  }, [ruleFlightNo, ruleRoutes]);

  const [routeComponent, flightComponent] = SelectRouteAndFlight({
    flightProps,
    routeProps,
    changeRouteByFlight: true,
  });

  return (
    <div>
      <Spin spinning={isSaving}>
        <h2>{getSharkText("config_page_monitoring_warning_rules")}</h2>
        <div className={styles.body}>
          <h3 className={styles.subHeader}>
            {getSharkText("config_page_register_warning_route_range")}
          </h3>
          <Row gutter={[10, 10]}>
            <Col>
              <SelectMultiRoute
                mode="multiple"
                routes={routes}
                setRoutes={setRoutes}
                isDemo={false}
                flights=""
                useContext={false}
                useGroup
              />
            </Col>
            <Col>
              <Button type="primary" onClick={() => submitMonitoringRoutes()}>
                {getSharkText("config_page_save")}
              </Button>
            </Col>
            <Col>
              <Button type="default" onClick={() => setRoutes([])}>
                {getSharkText("config_page_clear")}
              </Button>
            </Col>
          </Row>
          <h3 className={styles.subHeader}>
            {getSharkText("config_page_competing_flight_settings")}
          </h3>
          <Row gutter={[10, 10]}>
            <Col>{flightComponent}</Col>
            <Col>{routeComponent}</Col>
            <Col>
              <SelectMultiFlight
                title="竞飞航班："
                queryUrl={FlightQuery.routeFlight}
                moduleCode={FlightModule}
                flightNO={comRuleFlightNo}
                setFlightNO={setComRuleFlightNo}
                isDemo={false}
                routes={ruleRoutes.join()}
                width="auto"
                mode={undefined}
                type={3}
                allowClear
                placeholder={getSharkText(
                  "config_page_select_competing_flight"
                )}
                debugId="notification_settings_compare_flight"
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => submitCompare()}
                disabled={!canSaveCompare}
              >
                {getSharkText("config_page_save_competition")}
              </Button>
            </Col>
          </Row>
          <div>
            <Tabs
              activeKey={activeTab}
              onChange={setActiveTab}
              items={[
                {
                  label: getSharkText("config_page_last_4_weeks_progress"),
                  key: "1",
                  children: (
                    <Last4Week
                      value={last4Week}
                      submitRule={submit}
                      editable={editable}
                    />
                  ),
                },
                {
                  label: getSharkText(
                    "config_page_compare_competition_flight_progress"
                  ),
                  key: "2",
                  children: (
                    <CompareLoad
                      value={compareLoad}
                      submitRule={submit}
                      editable={editableCompare}
                    />
                  ),
                },
                {
                  label: getSharkText("config_page_compare_price_difference"),
                  key: "3",
                  children: (
                    <ComparePrice
                      value={comparePrice}
                      submitRule={submit}
                      editable={editableCompare}
                    />
                  ),
                },
                {
                  label: getSharkText("config_page_price_change_ex"),
                  key: "4",
                  children: (
                    <CompareChange
                      value={compareChange}
                      submitRule={submit}
                      editable={editableCompare}
                    />
                  ),
                },
                {
                  label: getSharkText("config_page_load_speed_abnormal"),
                  key: "5",
                  children: (
                    <LoadAbnormal
                      value={loadAbnormal}
                      submitRule={submit}
                      editable={editable}
                    />
                  ),
                },
              ]}
            ></Tabs>
          </div>
        </div>

        <h2 style={{ marginTop: 10 }}>消息推送设置</h2>
        <div className={styles.body}>
          <Row>
            <Col span={4}>
              {getSharkText("config_page_PaAiNoCoSe_push_module")}
            </Col>
            <Col span={20}>
              <Row gutter={[5, 5]}>
                {Object.keys(ScribeName).map((key) => {
                  const k = key as keyof IScribe;
                  if (k === "manualReview" && userInfo.user.userType !== 1) {
                    return null;
                  }
                  return (
                    <Col span={8} key={key}>
                      <span style={{ marginRight: 5 }}>{ScribeName[k]}</span>
                      <Switch
                        checked={scribe[k] === 1}
                        onChange={(v) => changeScribe(v.valueOf(), k)}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: 5,
              borderTop: "1px solid #ddd",
              marginTop: 10,
            }}
          >
            <Col span={4}>{getSharkText("config_page_push_channel")}</Col>
            <Col span={6}>
              <span style={{ marginRight: 5 }}>
                {getSharkText("config_page_email_subscription")}
              </span>
              <Switch checked={mail} onChange={() => setMail(!mail)} />
            </Col>
            <Col span={10}>
              <Row>
                <Col span={5}>
                  {getSharkText("config_page_wechat_subscription")}：
                </Col>
                <Col span={19}>
                  <Row>
                    <Col>
                      {getSharkText(
                        "config_page_bind_corporate_wechat_account"
                      )}
                      ：
                    </Col>
                    <Col>
                      <Input
                        value={wechatAccount}
                        onChange={(e) => setWechatAccount(e.target.value)}
                        size="small"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={16}>
                      {getSharkText(
                        "config_page_scan_qr_code_and_join_corporate_wechat"
                      )}
                    </Col>
                    <Col span={8}>
                      <img
                        src={QRRes?.url || ""}
                        width="120"
                        height="120"
                        alt={getSharkText(
                          "config_page_PaAiNoCoSe_wechat_qr_code"
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify="space-around">
            <Col>
              <Button type="primary" onClick={() => submitSubScribe()}>
                {getSharkText("config_page_PaAiNoCoSe_save_message_push")}
              </Button>
            </Col>
          </Row>
        </div>
      </Spin>
    </div>
  );
};

const Settings = React.forwardRef(SS);
export default Settings;
