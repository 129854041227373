// #region imports
import React, {
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { IDownloadHeaderPro } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _, { cloneDeep, isNumber, merge } from "lodash";

import { Empty, message, Spin, TablePaginationConfig } from "antd";
import { DATE_FORMAT } from "Constants";
import Refetch from "Components/Refetch";
import CustomTable from "./CustomTable";
import moment from "moment";

import "./index.scss";
import useRefFunc from "Utils/useRefFunc";
import { getColumns } from "./columns";
import { ListToolBarTabs } from "@ant-design/pro-table/es/components/ListToolBar";
import SvgIcon from "Components/SvgIcon";
import {
  FlightInfoDetail,
  FlightInfoDetailWithKey,
  GetFlightInfoRequest,
  UpdateFlightStatusRequest,
} from "../../interface";
import { MODULE_CODE } from "../..";
import { useDebounce } from "Utils/useDebounce";
import { isCtrip } from "Utils/global";

interface ITabItem {
  name: string;
  label: ReactNode;
  key: string;
  disabled?: boolean;
  value: string | "all";
}

const defaultTabs = [
  {
    name: getSharkText("config_page_all"),
    // label: getSharkText("config_page_all"),
    label: (
      <span style={{ paddingRight: 10, borderRight: "1px solid #eee" }}>
        <SvgIcon name="zhiding" style={{ position: "relative", top: 4 }} />
      </span>
    ),
    key: "all",
    value: "all",
  },
];

interface AirlineTableProps {
  queryExt: GetFlightInfoRequest | null;
  isDemo: boolean;
  curRecord: FlightInfoDetailWithKey | undefined;
  setCurRecord: (record: FlightInfoDetailWithKey | undefined) => void;
  roOpen: boolean;
  setRoOpen: (v: boolean) => void;
}
export interface PCalendarHandler {
  refresh: () => void;
}

const PCalendar = (props: AirlineTableProps, ref: Ref<PCalendarHandler>) => {
  const { queryExt, curRecord, setCurRecord, roOpen, setRoOpen } = props;
  const [globalState] = useGlobal();

  const { systemType, airlinesQueryCondition } = globalState;
  const isCtriper = isCtrip(globalState.userInfo);

  /** 因为ProTable的headerTitle中直接使用tabs会有闪跳问题, 为了解决此问题, 需要计算table的width来固定tabs的大小, 防止闪跳 */
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [dataWithKey, setDataWithKey] = useState<FlightInfoDetailWithKey[]>([]);
  const [searchRange, setSearchRange] = useState<
    [number | null | undefined, number | null | undefined]
  >([null, null]);
  const [totalCnt, setTotalCnt] = useState<number>(0);
  const [pageParam, setPageParam] = useState<{ size: number; pageNum: number }>(
    { size: 20, pageNum: 1 }
  );

  const [{ isLoading, error, res }, doFetch] = useFetch<FlightInfoDetail[]>({
    url: "soaProxy",
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "none",
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
    onSuccess: (res) => {
      if (res?.responseBody) {
        try {
          const response = JSON.parse(res.responseBody);
          if (response.ResponseStatus.Ack === "Success") {
            const tmp = response.flightInfoDetailList as FlightInfoDetail[];
            const tmpWithKey = tmp.map((d) => {
              const item: FlightInfoDetailWithKey = {
                ...d,
                key: `${d.takeoffdate}-${d.flightno}-${d.dport}-${d.aport}-${d.inventoryId}`,
                feRemainInventory:
                  isNumber(d.totalInventory) && isNumber(d.soldInventory)
                    ? d.totalInventory - d.soldInventory
                    : null,
              };
              return item;
            });
            setDataWithKey(tmpWithKey);
            setTotalCnt(response.count || 0);
            // setCurRecord(undefined);
            // setRoOpen(false);
          } else {
            message.error(response.ResponseStatus?.Errors[0].Message);
          }
          return;
        } catch (e) {
          console.log("error");
          console.error(e);
        }
      }
      setDataWithKey([]);
    },
  });

  const refetch = useCallback(() => {
    if (
      !airlinesQueryCondition.startDate ||
      !airlinesQueryCondition.endDate ||
      !queryExt
    ) {
      return;
    }
    const queryParam = cloneDeep(queryExt);
    // queryParam.size = pageParam.size;
    queryParam.size = 1000;
    queryParam.pageNum = 1;
    // queryParam.pageNum = pageParam.pageNum;
    console.log("request param: ", queryParam);
    const ext = {
      serviceCode: "30554",
      operation: "getFlightInfoDetailList",
      requestBody: JSON.stringify(queryParam),
    };
    setSearchRange([null, null]);
    doFetch({
      query: null,
      ext,
    });
  }, [
    airlinesQueryCondition.endDate,
    airlinesQueryCondition.startDate,
    doFetch,
    queryExt,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useImperativeHandle(ref, () => ({
    refresh: refetch,
  }));

  const chooseFirst = useRefFunc(() => {
    setCurRecord(undefined);
    setCurRecord(dataWithKey[0]);
  });

  // #region 生成日期Tabs
  /** 日期tabs */
  const tabs = useMemo(() => {
    const sortedSource = _.sortBy(dataWithKey, (d) => new Date(d.takeoffdate));
    const takeoffTimes = _.sortedUniqBy(sortedSource, (d) =>
      new Date(d.takeoffdate.substr(0, 10)).getTime()
    );
    const takeoffDays = takeoffTimes.map((d: any) =>
      moment(d.takeoffdate).format(DATE_FORMAT)
    );
    const today = moment().format(DATE_FORMAT);
    const rst: ITabItem[] = _.cloneDeep(defaultTabs);
    takeoffDays.forEach((v) => {
      const vd = moment(v).format(DATE_FORMAT);
      rst.push({
        name: today === vd ? getSharkText("config_page_today") : `${vd}`,
        label: today === vd ? getSharkText("config_page_today") : `${vd}`,
        key: vd,
        value: vd,
      });
    });
    return rst;
  }, [dataWithKey]);
  // #endregion

  //
  /**
   * 2. 数据筛选展示逻辑, 首先为数据添加状态, 自营,  从头开始找第一条需要实时查询的数据, 找到后获取同日竞飞航班的最后一条的索引,
   * 如果索引大于20, 则停止查找并记录showIndex, 展示已经找到的数据
   * 如果索引小于20, 则继续查找直到索引大于20位置, 假如查找了三条自营数据都没有大于20, 则返回
   */

  useEffect(() => {
    // if (!dataWithKey.length) {
    //   return;
    // }
    // setCurRecord(undefined);
    // setRoOpen(false);
    // chooseFirst(data);
  }, [setCurRecord, chooseFirst, setRoOpen, dataWithKey.length]);

  const changeRoOpen = useRefFunc(
    (open: boolean, r: FlightInfoDetailWithKey) => {
      setRoOpen(open);
      if (open && r.key !== curRecord?.key) {
        setCurRecord(r);
      }
    }
  );

  const [{}, changeAutoReq] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "none",
    },
    lazey: true,
    onSuccess: (res) => {
      if (res?.responseBody) {
        try {
          const response = JSON.parse(res.responseBody);
          if (response.ResponseStatus.Ack === "Success") {
            refetch();
            message.success("操作成功");
          } else {
            message.error("操作失败");
          }
        } catch (e) {
          message.error("操作失败");
        }
      }
    },
    onError: () => {
      message.error("操作失败");
    },
  });

  const changeAuto = useRefFunc(
    (r: FlightInfoDetailWithKey, isAuto: boolean) => {
      console.log("changeAuto", r, isAuto);
      const req: UpdateFlightStatusRequest = {
        user: globalState.userInfo.user.uid,
        groupId: globalState.userInfo.unitList
          ? globalState.userInfo.unitList[0].id.toString()
          : "",
        groupName: globalState.userInfo.unitList
          ? globalState.userInfo.unitList[0].unitName
          : "",
        flightStatusInfoList: [
          {
            flightNo: r.flightno,
            takeoffdate: r.takeoffdate,
            dport: r.dport,
            aport: r.aport,
            automated: isAuto,
          },
        ],
      };
      console.log("change auto req: ", req);
      changeAutoReq({
        ext: {
          serviceCode: "30554",
          operation: "updateFlightStatus",
          requestBody: JSON.stringify(req),
        },
      });
    }
  );

  // #region 列定义
  const columns: IDownloadHeaderPro[] = useMemo(
    () =>
      getColumns({
        key: curRecord?.key,
        changeAuto,
        roOpen,
        changeRoOpen,
        searchRange,
        setSearchRange: (v) => setSearchRange((s) => merge([], s, v)),
        isCtriper,
        agency: queryExt?.supplierId || 0,
      }),
    [
      curRecord?.key,
      changeAuto,
      roOpen,
      changeRoOpen,
      searchRange,
      isCtriper,
      queryExt?.supplierId,
    ]
  );
  // #endregion

  const handleRefresh = () => {
    refetch();
  };

  const setOldRowClass = useRefFunc((record: FlightInfoDetailWithKey) => {
    let boldBorder = "";
    // if (index !== 0 && (record.feRowSpan || record.isTakeoffFirstRow)) {
    //   boldBorder = "boldBorder";
    // }
    const c = record.key === curRecord?.key ? "row-checked" : "row-compare";
    const rowIndex = `record-${record.key}`;
    return `${boldBorder} ${c} ${rowIndex}`;
  });

  const scrollToDateFirstRow = useRefFunc((date?: string) => {
    let targetRowClass = `.record-${dataWithKey[0].key}`;
    if (date) {
      const firstDateRow = dataWithKey.find(
        (r) => moment(r.takeoffdate).format(DATE_FORMAT) === date
      );
      if (firstDateRow) {
        targetRowClass = `.record-${firstDateRow.key}`;
      }
    }
    const element: HTMLTableRowElement | null =
      document.querySelector(targetRowClass);
    const container = document.querySelector(
      "#flight-manage-main-table .ant-table-body"
    );
    if (element !== null && container) {
      container.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  });

  const [activeKey, setActiveKey] = useState<string>("unknown");

  const onChangeTab = useRefFunc((key: string) => {
    scrollToDateFirstRow(key === "all" ? undefined : key);
    setActiveKey(key === "all" ? "unknown" : key);
  });

  const [filterData, setFilterData] = useState<FlightInfoDetailWithKey[]>([]);

  const filterDataByRange = useDebounce(() => {
    const start = searchRange[0];
    const end = searchRange[1];
    if (!start && !end) {
      setFilterData(dataWithKey);
    } else {
      const tmp = dataWithKey.filter((d) => {
        return (
          isNumber(d.feRemainInventory) &&
          (!isNumber(start) || d.feRemainInventory >= start) &&
          (!isNumber(end) || d.feRemainInventory <= end)
        );
      });
      setFilterData(tmp);
    }
  }, 500);
  useEffect(() => {
    filterDataByRange();
  }, [dataWithKey, filterDataByRange, searchRange]);

  const toolbarTabsConfig = useMemo(() => {
    const config: ListToolBarTabs = {
      activeKey,
      onChange: onChangeTab,
      // items: Array.from({ length: 20 }, (v, i) => ({
      //   key: `tab${i}`,
      //   tab: `第${i}个标签页的内容`,
      // })),
      items: tabs,
    };
    return config;
  }, [activeKey, onChangeTab, tabs]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }
  if (!dataWithKey.length) {
    return <Empty description="请重新查询" />;
  }
  if (!isLoading && _.isEmpty(dataWithKey)) {
    return <Empty />;
  }

  return (
    <div id="analysisTable">
      <Spin spinning={isLoading}>
        <div ref={tableContainerRef}>
          <CustomTable
            curRecord={curRecord}
            setCurRecord={setCurRecord}
            dataSource={filterData}
            columns={columns}
            otherProps={{
              id: "flight-manage-main-table",
              pagination: {
                pageSize: 20,
                showSizeChanger: false,
                // total: totalCnt,
              },
              rowClassName: setOldRowClass,
              rowKey: "key",
              onChange: (v: TablePaginationConfig) => {
                setPageParam((s) => ({ ...s, pageNum: v.current || 1 }));
              },
              // bordered,
            }}
            // toolbarTabsConfig={toolbarTabsConfig}
            onReload={handleRefresh}
            roOpen={roOpen}
          />
        </div>
      </Spin>
    </div>
  );
};

const PriceCalendar = React.forwardRef(PCalendar);
export default PriceCalendar;
