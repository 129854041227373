import { FetchProps, IResponseCommon } from "./useFetch";
import _, { cloneDeep } from "lodash";
import { requestDebug } from "@ctrip/flt-bidw-common/debugMode";
import { arrayUpsert, logout } from "Utils";
import { getEnv, redirectLogin } from "./global";
import ubtUtils from "./ubtUtils";
import AesUtil, { AESPWD_PREFIX, CRYPTOGRAPHY_VERSION } from "./AESUtil";
import moment from "moment/moment";
import { DATE_FORMAT } from "../Constants";

export const DEFAULT_SUB_FAT = "FAT3";
window.subFat = getEnv() !== "pro" ? DEFAULT_SUB_FAT : "";

const cache: Record<string, Promise<any> | undefined> = {};
const cacheJson: Record<string, any> = {};
const isPro = process.env.NODE_ENV !== "development";

const generateCommonCacheKey = (url: string, body: any): string => {
  const bodyCP = cloneDeep(body);
  // 因为每次请求时间都会重新生成, 导致无法缓存, 所以删除时间字段
  delete bodyCP.head.requestTime;
  return JSON.stringify({ url, body: bodyCP });
};

export interface Params extends FetchProps {
  uid: string | null;
  token: string | null;
  /** 需要请求可abort时, 传递此ref, 外部调用abortCtrlRef.current.abort(), 取消请求 */
  abortCtrlRef?: { current: { abort: () => void } | undefined | null };
  onAbort?: () => void;
  onError?: (err?: any, bodyObj?: any, res?: any) => void;
}

// #region 性能埋点
interface QueryMetric {
  id: string;
  url?: string;
  start?: string;
  end?: string;
  duration?: number;
  loading: boolean;
  bodyObj?: any;
  // 由于UBT最大只能发送50KB, 所以不发送res, 只发送responseStatus
  // res?: any;
  responseStatus?: string;
  error?: any;
}
interface FetchMetrics {
  start: string;
  end: string;
  duration: number;
  queryList: QueryMetric[];
  location: string;
}
const defaultFetchMetrics = (): FetchMetrics => ({
  start: "",
  end: "",
  location: window.location.href,
  duration: 0,
  queryList: [],
});
let fetchMetrics = defaultFetchMetrics();
const setMetrics = (queryMetric: QueryMetric) => {
  if (fetchMetrics.start === "") {
    fetchMetrics.start = queryMetric.start || "";
  }
  const exists = fetchMetrics.queryList.find((v) => v.id === queryMetric.id);
  const tmpItem = { ...exists, ...queryMetric };
  if (tmpItem.start && tmpItem.end) {
    tmpItem.duration =
      new Date(tmpItem.end).getTime() - new Date(tmpItem.start).getTime();
  }
  const list = arrayUpsert(
    fetchMetrics.queryList,
    tmpItem,
    (v) => v.id === tmpItem.id
  );
  fetchMetrics.queryList = list;
};
const setMetricsEnd = () => {
  fetchMetrics.end = new Date().toISOString();
  fetchMetrics.duration =
    new Date().getTime() - new Date(fetchMetrics.start).getTime();
};
const sendUbt = _.throttle(
  () => {
    // 发送前检查是否已经完成所有请求
    const fetchingCnt = fetchMetrics.queryList.filter(
      (item) => item.loading
    ).length;
    if (fetchingCnt === 0) {
      ubtUtils.info(
        "fetchMetrics",
        fetchMetrics,
        "o_flt_flightai_query_performance"
      );
      fetchMetrics = defaultFetchMetrics();
    } else {
      console.log("still fetching, wait for next sendUbt: ", fetchingCnt);
    }
  },
  1000,
  { leading: false }
);
// #endregion

const fetchBase = async <T = IResponseCommon<any>>({
  uid,
  token,
  abortCtrlRef,
  head,
  query,
  ext,
  url,
  server,
  debugId,
  generateCacheKey,
  useCache = false,
  onSuccess,
  onError,
  onFinish,
  onAbort,
}: Params): Promise<T | undefined> => {
  const newAbortCtrl = new AbortController();
  const signal = newAbortCtrl.signal;

  const getCacheKey = generateCacheKey || generateCommonCacheKey;
  const bodyObj = {
    head: {
      uid,
      token,
      requestTime: new Date(),
      appId: "",
      clientType: "1",
      clientVersion: "",
      locale: sessionStorage.getItem("lang") || "zh-CN",
      ...head,
    },
    query,
    ...ext,
  };
  const aes = new AesUtil();
  const pwd = AESPWD_PREFIX + moment().format(DATE_FORMAT);
  const encrypted = isPro
    ? aes.encrypt(JSON.stringify(bodyObj), pwd)
    : JSON.stringify(bodyObj);
  const headers = {
    "content-type": "application/json",
    "X-Encrypt-Version": isPro ? CRYPTOGRAPHY_VERSION : "",
  };
  const config: any = {
    signal,
    method: "POST",
    headers,
    mode: "cors",
    body: encrypted,
  };
  const targetUrl = url;
  const targetServer = server;
  const apiUrl = window.location.protocol + "//" + targetServer + targetUrl;
  const urlObj = new URL(apiUrl);
  if (debugId) {
    urlObj.searchParams.append("debugId", debugId);
  }
  if (window.subFat) {
    urlObj.searchParams.append("subEnv", window.subFat);
  }
  const queryUrl = urlObj.toString();
  const id = Math.random().toString();
  const cacheKey = getCacheKey(queryUrl, bodyObj);
  try {
    if (abortCtrlRef) {
      abortCtrlRef.current = newAbortCtrl;
    }
    if (useCache && cache[cacheKey]) {
      const cacheReq = cache[cacheKey];
      // console.warn("waiting cache request: ", queryUrl, bodyObj, id);
      const cacheRes = await cacheReq;
      const cacheResJson = cacheJson[cacheKey];
      if (cacheResJson) {
        if (onSuccess) {
          onSuccess(cacheResJson, bodyObj);
        }
        return cacheResJson;
      }
      // console.log("cache response: ", id, cacheRes);
    }
    setMetrics({
      id,
      url: urlObj.pathname + urlObj.search,
      start: new Date().toISOString(),
      end: "",
      duration: 0,
      loading: true,
      bodyObj,
    });
    const req = fetch(queryUrl, config);
    // console.log("no cache request: ", id);
    const result = await req;
    if (useCache && result.ok) {
      cache[cacheKey] = req;
    }
    const resText = await result.text();
    const res = isPro
      ? JSON.parse(aes.decrypt(resText, pwd))
      : JSON.parse(resText);
    if (process.env.NODE_ENV === "development") {
      console.log("fetchBase: ", queryUrl, bodyObj, res);
    }
    if (useCache) {
      cacheJson[cacheKey] = res;
    }
    setMetrics({
      id,
      end: new Date().toISOString(),
      loading: false,
      responseStatus: res?.ResponseStatus,
    });
    if (useCache && (!res || res?.data?.length === 0)) {
      delete cache[cacheKey];
      delete cacheJson[cacheKey];
    }
    requestDebug(queryUrl, config, result);
    const { ResponseStatus } = res;
    const { Ack } = ResponseStatus;
    if (Ack === "Success") {
      if (onSuccess) {
        onSuccess(res, bodyObj);
      }
    } else {
      if (useCache) {
        delete cache[cacheKey];
        delete cacheJson[cacheKey];
      }
      if (ResponseStatus.Errors[0].ErrorCode === "2001") {
        logout();
        redirectLogin();
      } else {
        if (onError) {
          onError(ResponseStatus.Errors[0], bodyObj, res);
        }
      }
    }
    return res;
  } catch (error: any) {
    // console.log("error: ", id);
    if (useCache) {
      delete cache[cacheKey];
      delete cacheJson[cacheKey];
    }
    if (error.name === "AbortError" && onAbort) {
      // console.log("abort: ", id);
      onAbort();
      return;
    }
    if (onError) {
      onError(error, {}, null);
    }
    setMetrics({
      id,
      end: new Date().toISOString(),
      loading: false,
      error,
    });
    return error;
  } finally {
    if (onFinish) {
      onFinish();
    }
    setMetrics({
      id,
      end: new Date().toISOString(),
      loading: false,
    });

    const fetchingCnt = fetchMetrics.queryList.filter((item) => {
      const rst = item.loading;
      return rst;
    }).length;
    if (fetchingCnt === 0) {
      setMetricsEnd();
      sendUbt();
    }
  }
};

export default fetchBase;
