import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { getEnv } from "Utils/global";

const isPro = getEnv() === "pro";
export const DATASET_ID = isPro ? 141 : 25;
export const DATASET_DA_ID = isPro ? 148 : 26;
export const DPORT_NAME = "dport";
export const DPORTNAME_NAME = "dportname";
export const DNAME_NAME = "dname";
export const ANAME_NAME = "aname";
export const DCITYCODE_NAME = "dcitycode";
export const DEP_CITYNAME_NAME = "dep_cityname";
export const DEP_PROVINCENAME_NAME = "dep_provincename";
export const DEP_REGIONNAME_NAME = "dep_regionname";
export const DEP_COUNTRYNAME_NAME = "dep_countryname";
export const DEP_CONTINENTNAME_NAME = "dep_continentname";
export const DEP_COUNTRYCLASS_NAME = "dep_countryclass";
export const APORT_NAME = "aport";
export const APORTNAME_NAME = "aportname";
export const ACITYCODE_NAME = "acitycode";
export const ARR_CITYNAME_NAME = "arr_cityname";
export const ARR_PROVINCENAME_NAME = "arr_provincename";
export const ARR_REGIONNAME_NAME = "arr_regionname";
export const ARR_COUNTRYNAME_NAME = "arr_countryname";
export const ARR_CONTINENTNAME_NAME = "arr_continentname";
export const ARR_COUNTRYCLASS_NAME = "arr_countryclass";
export const FLIGHTNO_NAME = "flightno";
export const AIRLINE_NAME = "airline";
export const ROUTE_AIRPORT_NAME = "route_airport";
export const ALL_SEATS_NAME = "all_seats";
export const FLIGHTSTATE_NAME = "flightstate";
export const CHANGE_DATE_NAME = "change_date";
export const HAS_ORDER_NAME = "has_order";
export const MAX_SEARCH_DATE_NAME = "max_search_date";
export const DBRIDGE_NAME = "dbridge";
export const ABRIDGE_NAME = "abridge";
export const REALTAKEOFFTIME_DIFF_NAME = "realtakeofftime_diff";
export const REALARRIVETIME_DIFF_NAME = "realarrivetime_diff";
export const TAKEOFFDATE_NAME = "takeoffdate";
/** 包含时区的起飞日期 */
export const TKZONE = "tkzone";
export const IS_TAKEOFF_DELAY_NAME = "is_takeoff_delay";
export const IS_ARRIVE_DELAY_NAME = "is_arrive_delay";
export const TAKEOFF_DELAY_NOT_NULL_NAME = "takeoff_delay_not_null";
export const ARRIVE_DELAY_NOT_NULL_NAME = "arrive_delay_not_null";
export const DBRIDGE_CNT_NAME = "dbridge_cnt";
export const ABRIDGE_CNT_NAME = "abridge_cnt";
export const DBIRDGE_NOT_NULL_NAME = "dbirdge_not_null";
export const ABRIDGE_NOT_NULL_NAME = "abridge_not_null";
export const EXECUTE_NAME = "execute";
export const DA_TYPE_NAME = "daType";
/** 执行率 */
export const EXECUTE_RATIO_NAME = "execute_rate";
export const CNT_NAME = "cnt";
/** 正点率 */
export const ONTIME_RATIO_NAME = "ontime_rate";
export const BRIDGE_RATIO_NAME = "bridge_rate";

export const customCols: FDDatasetCol[] = [
  {
    id: 0.1,
    datasetId: DATASET_ID,
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    name: EXECUTE_RATIO_NAME,
    displayName: "座公里收益",
    dbcolumn: `sum(measure.${EXECUTE_NAME})/count(1)`,
    jdbctype: "DOUBLE",
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.2,
    datasetId: DATASET_ID,
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    name: CNT_NAME,
    displayName: "计数",
    dbcolumn: `count(1)`,
    jdbctype: "DOUBLE",
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.3,
    datasetId: DATASET_ID,
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    name: ONTIME_RATIO_NAME,
    displayName: "ONTIME_RATIO_NAME",
    dbcolumn: `1 - sum(measure.${IS_TAKEOFF_DELAY_NAME})/sum(measure.${TAKEOFF_DELAY_NOT_NULL_NAME})`,
    jdbctype: "DOUBLE",
    description: "",
    extra: '{"isExp":true}',
  },
  {
    id: 0.4,
    datasetId: DATASET_ID,
    kind: 0,
    sort: "DOUBLE",
    aggs: "",
    name: BRIDGE_RATIO_NAME,
    displayName: BRIDGE_RATIO_NAME,
    dbcolumn: `sum(measure.${DBRIDGE_CNT_NAME})/sum(measure.${DBIRDGE_NOT_NULL_NAME})`,
    jdbctype: "DOUBLE",
    description: "",
    extra: '{"isExp":true}',
  },
];
