import React, { CSSProperties } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import useGlobal from "Store";
import { getModuleNameFromPath, getModuleStatus, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { useLocation } from "react-router-dom";
import ubtUtils from "../../Utils/ubtUtils";
import type { SizeType } from "antd/lib/config-provider/SizeContext";

interface BtnProps {
  handleDownload: () => void;
  moduleCode: string;
  chartTableCode: string;
  en?: boolean;
  showIcon?: boolean;
  loading?: boolean;
  size?: SizeType;
  style?: CSSProperties;
  disabled?: boolean;
}

const DownloadBtn: React.FC<BtnProps> = (props: BtnProps) => {
  const {
    handleDownload,
    moduleCode,
    chartTableCode,
    en = false,
    showIcon = true,
    loading = false,
    style = {},
    size = "middle",
    disabled = false,
  } = props;
  const location = useLocation();
  const [globalState] = useGlobal();
  const { userInfo, systemType } = globalState;
  const moduleStatus = getModuleStatus(
    getModuleNameFromPath(location.pathname, systemType),
    userInfo.moduleList
  );
  const isShowDownloadBtn = moduleStatus === 1 || moduleStatus === 2;

  const doHandleDownload = () => {
    ubtUtils.info("download");
    doFetch({}).then((r) => {
      if (r?.flag) {
        handleDownload();
      } else {
        console.error(r);
        message.error("下载超出限制");
      }
    });
  };

  const [{ data, isLoading }, doFetch] = useFetch({
    url: "downloadPermission",
    head: {
      moduleCode,
      chartTableCode,
    },
    lazey: true,
  });

  return (
    <>
      {isShowDownloadBtn ? (
        <Button
          type="primary"
          key="download"
          size={size}
          onClick={doHandleDownload}
          loading={isLoading || loading}
          style={style}
          disabled={disabled}
        >
          {showIcon ? <DownloadOutlined /> : null}
          {en ? "Download" : getSharkText("key.download.button")}
        </Button>
      ) : undefined}
    </>
  );
};

export default DownloadBtn;
