import { isNumber } from "lodash";
import { FESTIVAL_INFO } from "./Components/cols";
import { RecordTypeWithCompare } from "./interface";

export const arrayDataToRecord = (
  arr: any[]
): Partial<RecordTypeWithCompare> => {
  const getNumberCell = (val: any): number | null => {
    if (val == null) {
      return null;
    }
    if (isNumber(val)) {
      return Number(val) ?? null;
    }
    throw new Error(val);
  };
  const getStringCell = (val: any): string | null => {
    if (val == null) {
      return null;
    }
    return val.toString();
  };
  const tmp: Partial<RecordTypeWithCompare> = {
    [FESTIVAL_INFO]: arr[0],
    takeoffdate: arr[1],
    takeoffdate_compare: arr[3],
    flightno: arr[5],
    route: arr[6],
    SUM_cap: getNumberCell(arr[7]),
    SUM_tpm: getNumberCell(arr[8]),
    flightno_compare: arr[9],
    route_compare: arr[10],
    SUM_cum_revenue_tax_excl_compare: getNumberCell(arr[11]),
    SUM_cap_compare: getNumberCell(arr[12]),
    SUM_tkt_cnt_compare: getNumberCell(arr[13]),
    SUM_lf_compare: getNumberCell(arr[14]),
    AVG_avg_price_tax_excl_compare: getNumberCell(arr[15]),
    SUM_rask_tax_excl_compare: getNumberCell(arr[16]),
    SUM_yield_tax_excl_compare: getNumberCell(arr[17]),
    bkd_detail_compare: getStringCell(arr[18]),
    targetRevenue: getNumberCell(arr[19]),
    targetPersons: getNumberCell(arr[20]),
    targetLf: getNumberCell(arr[21]),
    targetPrice: getNumberCell(arr[22]),
    targetRevenueSeat: getNumberCell(arr[23]),
    targetRevenuePerson: getNumberCell(arr[24]),
    passengerFlow: getStringCell(arr[25]),
    cap: getNumberCell(arr[26]),
    tpm: getNumberCell(arr[27]),
    subcabinBkdDetail: getStringCell(arr[28]),
    firstOpenSubcabinBkdDetail: getStringCell(arr[29]),
    openCap: getNumberCell(arr[30]),
    specialCabinCnt: getStringCell(arr[31]),
    specialCabinPrice: getNumberCell(arr[32]),
    groupCnt: getStringCell(arr[33]),
    groupPrice: getNumberCell(arr[34]),
    remark: getStringCell(arr[35]),
  };
  return tmp;
};
