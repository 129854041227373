export const MODULE_CODE: any = {
  1: "",
  2: "sales_analysis_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {},
  2: {
    lineCharts: "non",
    price: "non",
    table: "non",
    trend: "non",
  },
};

export const QUERY_URL: any = {
  1: {},
  2: {
    lineCharts: "querySalesAnalysisLoadFactor",
    price: "querySalesAnalysisPriceChart",
    table: "querySalesAnalysisPriceTable",
    trend: "querySalesAnalysisPriceTrend",
  },
};
