import React, {
  CSSProperties,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { Cascader, Col, message, Row, Space, Spin, Tag } from "antd";
import { Area, FlightClass, SystemType } from "Interface";
import { getAirportPath, stringContains, U } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _ from "lodash";
import { EMPTY_ARRAY, SERVER } from "Constants";
import { DefaultOptionType, SingleValueType } from "rc-cascader/lib/Cascader";
import { useFetch } from "Utils/useFetch";
import { getServer } from "Service/server";
import useRefFunc from "Utils/useRefFunc";
import { fixHMTCode, getAreaTypeName } from "../../Utils/AreaUtil";
import CheckableTag from "antd/es/tag/CheckableTag";

const DEFAULT_ALL_OPTION = {
  areaCode: "*",
  areaName: "不限",
  areaType: 0,
};

export interface CascaderAreaProps {
  server?: string;
  url?: string;
  moduleCode?: string;
  type?: "city" | "airport";
  value?: Area;
  setSelectedArea: (area: Area) => void;
  /** 允许选择的区域级别, 对应areaType
   * url为searchArea时:
   * 境内: 2=country, 3=province, 4=city, 5=airport
   * 境外: 1=continent, 2=country, 3=province, 4=city, 5=airport
   * */
  allowSelectType?: number[];
  flightClass: FlightClass;
  isDemo?: boolean;
  changeOnSelect?: boolean;
  placeholder?: string;
  defaultCode?: string;
  style?: CSSProperties;
  dataSource?: Area[];
  /** 是否启用国内区域, 仅当server/url都未设置时生效 */
  useDomesticArea?: boolean;
  /** 使用areaType指定需要使用的列, 比如只能出现省份/城市等, 可选值1,2,3,4,5 */
  types?: number[];
  // TODO 支持多选, 支持显示父层级, 但是只能选中子层级,
  // 比如说只支持选择城市级别地区. 但是如果只显示城市级别, 会由于数据量过大导致卡顿
  /** 是否支持多选, 默认不支持, 多选模式下changeOnSelect会强制设置为false */
  multiple?: boolean;
  disabled?: boolean;
  lang?: "zh-CN" | "en-US";
  dropdownOpen?: boolean;
  useAllOption?: boolean | Area;
}

export interface CascaderAreaRef {
  setValue: (e: Area) => void;
}

const CascaderArea = React.forwardRef(
  (props: CascaderAreaProps, ref: React.Ref<CascaderAreaRef>) => {
    const {
      server: propServer,
      url: propUrl,
      moduleCode: propModuleCode,
      type = "airport",
      value,
      setSelectedArea,
      allowSelectType,
      flightClass,
      isDemo = false,
      changeOnSelect = true,
      placeholder,
      defaultCode,
      style,
      dataSource,
      useDomesticArea = false,
      disabled,
      types,
      lang,
      dropdownOpen,
      useAllOption,
    } = props;
    const [areaData, setAreaData] = useState<Area[]>([]);
    const [areaCityData, setAreaCityData] = useState<Area[]>();
    const language = lang || sessionStorage.getItem("lang") || "zh-CN";
    const labelName = language === "en-US" ? "areaNameEn" : "areaName";
    const [open, setOpen] = useState(dropdownOpen ?? false);
    useEffect(() => {
      setOpen((state) => dropdownOpen ?? state);
    }, [dropdownOpen]);
    const [v, setV] = useState<string[]>(EMPTY_ARRAY);
    const cannotCloseRef = useRef<boolean>(false);
    const server = useMemo(() => {
      if (propServer) return propServer;
      if (flightClass === FlightClass.Domestic && useDomesticArea) {
        return getServer(SystemType.airport);
      }
      return SERVER;
    }, [flightClass, propServer, useDomesticArea]);

    const url = useMemo(() => {
      if (propUrl) return propUrl;
      if (flightClass === FlightClass.Domestic && useDomesticArea) {
        return "searchAirportArea";
      }
      return "searchArea";
    }, [flightClass, propUrl, useDomesticArea]);
    const moduleCode = useMemo(() => {
      if (propModuleCode) return propModuleCode;
      if (flightClass === FlightClass.Domestic && useDomesticArea) {
        return "dashboard_airport";
      }
      return "common";
    }, [flightClass, propModuleCode, useDomesticArea]);

    const queryExtType = useMemo(() => {
      if (flightClass === FlightClass.Domestic && useDomesticArea) {
        return 2;
      }
      return flightClass;
    }, [flightClass, useDomesticArea]);

    const [{ data, isLoading, error }, doFetch] = useFetch({
      server,
      url,
      defaultValue: dataSource || [],
      head: {
        moduleCode,
        chartTableCode: null,
      },
      ext: {
        type: flightClass,
      },
      lazey: true,
    });

    useEffect(() => {
      doFetch({
        server,
        url,
        ext: {
          type: queryExtType,
        },
      });
    }, [doFetch, queryExtType, server, url]);

    useImperativeHandle(ref, () => ({
      setValue: (area: Area) => {
        const item = U.deepFindPath(
          areaData,
          (v1: Area) => v1.areaCode === area.areaCode
        );
        const fn = (a: Area, rst: string[]) => {
          rst.push(a.areaCode);
          if (a.children) {
            fn(a.children[0], rst);
          }
        };
        const tmpValue: string[] = [];
        fn(item, tmpValue);
        setV(tmpValue.slice(1));
      },
    }));

    useEffect(() => {
      if (!_.isEmpty(data)) {
        const completionData = (arr: any[]) => {
          for (const item of arr) {
            if (!item.areaName) {
              item.areaName = "";
            }
            if (!item.areaCode) {
              item.areaCode = "";
            }
            if (item.children) {
              completionData(item.children);
            }
          }
          return arr;
        };
        const cloneData = completionData(_.cloneDeep(data));
        setAreaData(cloneData);
        if (type === "city") {
          const areaRemoveAirport = (area: Area[]) => {
            if (area.length) {
              for (const i of area) {
                if (i.children && i.areaType !== 4) {
                  areaRemoveAirport(i.children);
                } else {
                  i.children = undefined;
                }
              }
            }
            return area;
          };
          setAreaCityData(
            flightClass === 1
              ? useDomesticArea
                ? areaRemoveAirport(cloneData)
                : areaRemoveAirport(cloneData)[0].children
              : areaRemoveAirport(cloneData)
          );
        }
      }
    }, [data]);

    const sourceData = useMemo(() => {
      let rst: Area[] = [];
      if (!data?.length) {
        return;
      }
      if (!types?.length) {
        rst =
          type === "airport"
            ? flightClass === 1
              ? useDomesticArea
                ? areaData
                : areaData[0] && areaData[0].children
                ? areaData[0].children
                : []
              : areaData
            : areaCityData || [];
      } else {
        const usedArea: Area[] = [];
        const addParent = (v1: Area) => {
          if (v1.areaType === types[0]) {
            usedArea.push(v1);
          } else {
            v1.children?.forEach((vc) => addParent(vc));
          }
        };
        data.forEach(addParent);
        const removeChild = (v1: Area) => {
          if (types.includes(v1.areaType)) {
            v1.children = v1.children?.filter((vc) => removeChild(vc));
            return v1;
          } else {
            return false;
          }
        };
        if (usedArea.length) {
          usedArea.forEach(removeChild);
        }
        rst = usedArea;
      }
      let allOption: Area | null = null;
      if (useAllOption) {
        if (typeof useAllOption === "boolean") {
          allOption = DEFAULT_ALL_OPTION;
        } else {
          allOption = useAllOption;
        }
      }
      return allOption ? [allOption, ...rst] : rst;
    }, [
      areaCityData,
      areaData,
      data,
      flightClass,
      type,
      types,
      useAllOption,
      useDomesticArea,
    ]);

    useEffect(() => {
      setV((state) => {
        if (value && value.areaCode) {
          const rst = U.getPathFromTreeNode(
            sourceData || [],
            (item) =>
              item.areaCode === value.areaCode &&
              item.areaType === value.areaType
          );
          if (rst) {
            return rst.map((t) => t.areaCode);
          }
        }
        return state;
      });
    }, [value, sourceData]);

    const handleVisibleChange = useRefFunc((visible: boolean) => {
      if (dropdownOpen) {
        return;
      }
      if (cannotCloseRef.current && !visible) {
        cannotCloseRef.current = false;
        return;
      }
      setOpen(visible);
    });

    if (error) {
      return null;
    }

    const showSearch = {
      filter: (queryText: string, path: any[]) =>
        path.some(
          (item) =>
            // item.areaName.includes(value) ||
            item.areaCode.includes(queryText.toLocaleUpperCase()) ||
            stringContains(item[labelName], queryText)
        ),
      render: (arg1: string, path: any[]) => {
        const subArea = path[path.length - 1];
        return (
          <Row justify="space-between">
            <Col>
              <CheckableTag checked={false}>
                {fixHMTCode(
                  path.filter((p) => p.areaType === 2)[0]?.areaCode
                ) || ""}
              </CheckableTag>
            </Col>
            <Col>
              <span>{subArea[labelName]}</span>
            </Col>
            <Col flex="1" style={{ textAlign: "right" }}>
              <Tag color={"blue"}>
                {getAreaTypeName(subArea.areaType, subArea.areaCode)}
              </Tag>
            </Col>
          </Row>
        );
      },
    };

    const onCascaderChange = (
      area: SingleValueType,
      selectedOptions?: DefaultOptionType[]
    ) => {
      if (selectedOptions && selectedOptions.length > 0) {
        const { areaType, areaCode, areaName, areaNameEn } =
          selectedOptions[selectedOptions.length - 1];
        const tmp = { areaType, areaCode, areaName, areaNameEn };
        if (!allowSelectType || allowSelectType.includes(areaType)) {
          setSelectedArea(tmp);
          setV(area as string[]);
          cannotCloseRef.current = false;
        } else {
          message.error("不允许选择这个层级, 选择无效");
          cannotCloseRef.current = true;
        }
      } else {
        const tmp = {
          areaType: 0,
          areaCode: "",
          areaName: "",
          areaNameEn: "",
        };
        cannotCloseRef.current = false;
        setSelectedArea(tmp);
        setV(EMPTY_ARRAY);
      }
    };

    const displayRender = (labels: string[]) => (
      <span>{labels[labels.length - 1]}</span>
    );

    const defaultValue =
      !_.isEmpty(areaData) && defaultCode
        ? getAirportPath(defaultCode, areaData, [])
        : undefined;

    return (
      <Spin spinning={isLoading}>
        {_.isEmpty(areaData) ? undefined : (
          <Cascader
            open={open}
            onDropdownVisibleChange={handleVisibleChange}
            style={style}
            disabled={disabled ?? isDemo}
            options={sourceData}
            placeholder={
              placeholder ||
              `${getSharkText("cascader.continent_level.hint")}${
                type === "airport"
                  ? getSharkText("cascader.airport_level.hint")
                  : ""
              }`
            }
            expandTrigger="hover"
            fieldNames={{
              label: labelName,
              value: "areaCode",
              children: "children",
            }}
            value={v}
            changeOnSelect={changeOnSelect}
            onChange={onCascaderChange}
            suffixIcon={<span></span>}
            showSearch={showSearch}
            displayRender={displayRender}
            notFoundContent="No Data"
            defaultValue={defaultValue}
          />
        )}
      </Spin>
    );
  }
);
CascaderArea.displayName = "CascaderArea";
export default CascaderArea;
