// Created by xh_zhu on 2021-04-02

import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Row } from "antd";
import { Area } from "Interface";
import React, { ReactElement } from "react";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import {
  LOSS_AIRPORT_SELECTOR_HELP,
  LOSS_CITY_SELECTOR_HELP,
} from "../PassengerAnalysisCommon";
import NearAirportSelector from "./NearAirportSelector";
import NearCitySelector from "./NearCitySelector";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 *
 */

interface ILossFilterProps {
  setNearCity: (v: Area[]) => void;
  setNearAirport: (v: Area[]) => void;
}

const LossFilter = (props: ILossFilterProps): ReactElement => {
  const { setNearCity, setNearAirport } = props;
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  return (
    <Row>
      <Col span={12}>
        <span style={{ marginRight: 5 }}>
          {getSharkText("key.source_of_psgr.text")}
        </span>
        <NearCitySelector
          queryUrl={QUERY_URL[systemType].nearCityList}
          chartTableCode={CHART_TABLE_CODE[systemType].nearCityList}
          moduleCode={MODULE_CODE[systemType]}
          type={1}
          setNearCity={setNearCity}
        ></NearCitySelector>
        <Popover content={LOSS_CITY_SELECTOR_HELP}>
          <InfoCircleOutlined style={{ marginLeft: 5 }} />
        </Popover>
      </Col>
      <Col span={12}>
        <span style={{ marginRight: 5 }}>
          {getSharkText("key.surrounding_airports.text")}
        </span>
        <NearAirportSelector
          queryUrl={QUERY_URL[systemType].nearAirportList}
          chartTableCode={CHART_TABLE_CODE[systemType].nearCityList}
          moduleCode={MODULE_CODE[systemType]}
          type={2}
          setNearAirport={setNearAirport}
        />
        <Popover content={LOSS_AIRPORT_SELECTOR_HELP}>
          <InfoCircleOutlined style={{ marginLeft: 5 }} />
        </Popover>
      </Col>
    </Row>
  );
};
export default LossFilter;
