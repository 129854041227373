import { DEFAULT_AIRLINE, DEFAULT_AIRLINE_NAME } from "Constants";
import { Role } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";
interface Airports {
  code: string;
  name: string;
}

export const getAirportsOrAlinesArr = (
  role: Role | undefined,
  airportMode: boolean
): Airports[] => {
  const ctripArr = airportMode
    ? [
        {
          name: getSharkText("config_page_shanghai_hongqiao"),
          code: "SHA",
        },
      ]
    : [
        {
          name: DEFAULT_AIRLINE_NAME,
          code: DEFAULT_AIRLINE,
        },
      ];
  if (role) {
    const isCtrip = role.permissionCode === "*";
    const codeArr = role.permissionCode.split(",");
    const nameArr = role.permissionName.split(",");
    const arr: Airports[] = [];
    codeArr.forEach((item, idx) => {
      arr.push({
        code: item,
        name: nameArr[idx],
      });
    });
    return isCtrip ? ctripArr : arr;
  } else {
    return ctripArr;
  }
};
