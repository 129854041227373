import React, { useState, useEffect, ReactElement, useCallback } from "react";
import { Card, Row, Col, Tabs, Result, Spin, Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import useGlobal from "Store";
import { UnitList } from "Interface";
import ModuleManage from "./Components/ModuleManage";
import UnitUser from "./Components/UnitUser";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import UnitSide from "./Components/UnitSide";
import SearchUser from "./Components/SearchUser";

const { TabPane } = Tabs;

const RightManage = (): ReactElement => {
  const [globalState] = useGlobal();
  const { userInfo } = globalState;
  const { user } = userInfo;

  const [curUnit, setCurUnit] = useState<UnitList | null>(null);
  const [unitList, setUnitList] = useState<UnitList[]>([]);
  const [unitType, setUnitType] = useState<0 | 1 | 2>(0);
  const [addUnitName, setAddUnitName] = useState("");
  const [{ isLoading, error, res }, doFetch] = useFetch({
    url: "findUnitList",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      unitName: "",
    },
    lazey: true,
  });

  const isSuperAdmin = user.userType === 1 && user.memberType === 1;

  const refetchUnitList = useCallback(() => {
    doFetch({
      ext: {
        unitName: "",
      },
    });
  }, [doFetch]);

  useEffect(() => {
    if (isSuperAdmin) {
      refetchUnitList();
    }
  }, [isSuperAdmin, refetchUnitList]);

  // 选中新增unit
  useEffect(() => {
    if (addUnitName) {
      const unit = unitList.find((item) => item.unit.unitName === addUnitName);
      if (unit) {
        setUnitType(0);
        setCurUnit(unit);
        setAddUnitName("");
      }
    }
  }, [unitList, addUnitName]);

  useEffect(() => {
    if (res && res.unitList) {
      const noPermissionUnit: UnitList = {
        unit: {
          id: 0,
          unitCode: "",
          unitName: getSharkText("config_page_no_permission_group"),
          managerUnit: false,
          remark: "",
          sortId: 0,
          autoRenew: null,
          renewDay: null,
        },
        roleList: [],
      };
      const allUnitList = [...res.unitList, noPermissionUnit];
      setUnitList(allUnitList);
    }
  }, [res]);

  if (error) {
    return <Result title="Error" status="error" />;
  }

  const handleChangeUnitType = (e: RadioChangeEvent) => {
    setUnitType(e.target.value);
  };

  const title = (
    <Row gutter={30}>
      <Col>
        <div className="mode-card-title">
          {isSuperAdmin
            ? getSharkText("key.access_mgt")
            : getSharkText("config_page_member_management")}
        </div>
      </Col>
      {isSuperAdmin && !window.TARGET_PLATFORM_CONFIG ? (
        <>
          <Col>
            <Radio.Group
              value={unitType}
              buttonStyle="solid"
              onChange={handleChangeUnitType}
            >
              <Radio.Button value={0}>
                {getSharkText("config_page_all")}
              </Radio.Button>
              <Radio.Button value={2}>
                {getSharkText("system_type_Airline")}
              </Radio.Button>
              <Radio.Button value={1}>
                {getSharkText("area.airport.title")}
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col>
            <SearchUser
              unitList={unitList}
              restUnitType={() => setUnitType(0)}
              setCurUnit={setCurUnit}
            />
          </Col>
        </>
      ) : undefined}
    </Row>
  );

  return (
    <div className="content">
      <Card title={title}>
        <Row gutter={[32, 32]}>
          {isSuperAdmin ? (
            <Col style={{ width: 320 }}>
              <UnitSide
                isLoading={isLoading}
                refetch={refetchUnitList}
                curUnit={curUnit}
                setCurUnit={setCurUnit}
                unitList={unitList}
                setAddUnitName={setAddUnitName}
                unitType={unitType}
              />
            </Col>
          ) : undefined}
          <Col style={{ flex: 1 }}>
            <Spin spinning={isLoading}>
              {curUnit ? (
                <Tabs type="card">
                  <TabPane
                    tab={`${curUnit.unit.unitName}${getSharkText(
                      "key.access_mgt"
                    )}`}
                    key="1"
                  >
                    <ModuleManage
                      refetch={refetchUnitList}
                      curUnit={curUnit}
                      unitList={unitList}
                    />
                  </TabPane>
                  <TabPane
                    tab={`${curUnit.unit.unitName}${getSharkText(
                      "menu_user_manage"
                    )}`}
                    key="2"
                  >
                    <UnitUser unitId={curUnit.unit.id} unit={curUnit} />
                  </TabPane>
                </Tabs>
              ) : (
                <UnitUser unitId={user.groupId} />
              )}
            </Spin>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default RightManage;
