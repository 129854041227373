import { Modal, Tag } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PlusOutlined } from "@ant-design/icons";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import { FlightClass, SystemType } from "Interface";
import GroupSelector from "./GroupSelector";
import { UserProfileTag } from "@ctrip/flt-bi-flightai-airlines";
import { EMPTY_ARRAY, SERVER } from "Constants";
import usePageContext from "../UserProfileContext";
import { cloneDeep, isEmpty } from "lodash";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";

export interface IGroupSelectorEntryProps {
  onChange: (v: UserProfileTag[]) => void;
  onLoaded?: () => void;
}

const defaultAirlinesSelected: Record<string, string[]> = {
  airline: ["1"],
};

/**
 * 在筛选框展示的群体圈选器
 */
const GroupSelectorEntry = (props: IGroupSelectorEntryProps): ReactElement => {
  const { onChange, onLoaded } = props;
  const [selectedTags, setSelectedTags] =
    useState<UserProfileTag[]>(EMPTY_ARRAY);
  const [visible, setVisible] = useState<boolean>(false);
  const [globalState] = useGlobalState();
  const { systemType, queryCondition, airlinesQueryCondition } = globalState;
  const { flightClass } = queryCondition;
  const query = useMemo(() => {
    return systemType === SystemType.airport
      ? queryCondition
      : airlinesQueryCondition;
  }, [airlinesQueryCondition, queryCondition, systemType]);
  const formRef = useRef<any>();
  const [, actions] = usePageContext();
  const { setTagList, setAreaSource } = actions;
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleTagsChange = useRefFunc((v: UserProfileTag[]) => {
    setSelectedTags(v);
    onChange(v);
  });
  const onFetchSuccess = useRefFunc((resTags: UserProfileTag[]) => {
    if (resTags) {
      setTagList(resTags);
      if (isEmpty(selectedTags) && systemType === SystemType.airlines) {
        const keys = Object.keys(defaultAirlinesSelected);
        const tags = cloneDeep(resTags).filter(
          (d) => d.tagCode && keys.includes(d.tagCode)
        );
        tags.forEach((t) => {
          if (t.tagCode && t.children) {
            t.children = t.children?.filter(
              (c) =>
                t.tagCode &&
                c.tagCode &&
                defaultAirlinesSelected[t.tagCode].includes(c.tagCode)
            );
          }
        });
        const rst = tags.filter((t) => !!t.children);
        handleTagsChange(rst);
      }
    }
  });

  const [{ data }] = useFetch<UserProfileTag[]>({
    server: getServer(systemType),
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: null,
    },
    url: QUERY_URL[systemType].tagList,
    query,
    onSuccess: (res) => {
      onFetchSuccess(res.data);
      if (!loaded) {
        setLoaded(true);
        if (onLoaded) {
          onLoaded();
        }
      }
    },
  });

  // #region tagList写入context, 默认选中本航司
  useEffect(() => {
    if (data) {
      setTagList(data);
      if (isEmpty(selectedTags) && systemType === SystemType.airlines) {
        const keys = Object.keys(defaultAirlinesSelected);
        const tags = cloneDeep(data).filter(
          (d) => d.tagCode && keys.includes(d.tagCode)
        );
        tags.forEach((t) => {
          if (t.tagCode && t.children) {
            t.children = t.children?.filter(
              (c) =>
                t.tagCode &&
                c.tagCode &&
                defaultAirlinesSelected[t.tagCode].includes(c.tagCode)
            );
          }
        });
        const rst = tags.filter((t) => !!t.children);
        handleTagsChange(rst);
      }
    }
  }, [data, handleTagsChange, selectedTags, setTagList, systemType]);
  // #endregion

  // #region  region source
  //  effect description
  const [{ data: areaSource }, doFetchArea] = useFetch({
    server: SERVER,
    url: "searchArea",
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: flightClass,
    },
  });
  const [{ data: areaSourceDomestic }] = useFetch({
    server: SERVER,
    url: "searchArea",
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: FlightClass.Domestic,
    },
  });
  useEffect(() => {
    doFetchArea({ ext: { type: flightClass } });
  }, [doFetchArea, flightClass]);
  // areaSource适用广泛, 请求到数据后放入sessionStorage公用
  useEffect(() => {
    setAreaSource(areaSourceDomestic);
  }, [areaSourceDomestic, setAreaSource]);
  // #endregion

  const onClose = useRefFunc((idx: string | null) => {
    if (idx) {
      const tmp = selectedTags.filter((s) => s.tagCode !== idx);
      handleTagsChange(tmp);
    }
  });

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const submit = useCallback(() => {
    if (formRef && formRef.current) {
      formRef.current.submit();
    }
    setVisible(false);
  }, []);

  const tagRender = (t: UserProfileTag) => {
    if (t.tagType === 2) {
      return (
        <Tag key={t.tagCode} closable onClose={() => onClose(t.tagCode)}>
          {t.tagName}：{t.areaList?.map((c) => c.areaName).join(",")}
        </Tag>
      );
    } else {
      return (
        <Tag key={t.tagCode} closable onClose={() => onClose(t.tagCode)}>
          {t.tagName}：{t.children?.map((c) => c.tagName).join(",")}
        </Tag>
      );
    }
  };

  return (
    <span>
      <span style={{ marginRight: 5 }}>
        {getSharkText("custom_profile.group_selector_title")} :
      </span>
      <span>
        {selectedTags.map(tagRender)}
        <Tag className="site-tag-plus" onClick={showModal}>
          <PlusOutlined /> {getSharkText("key.add.button")}
        </Tag>
      </span>
      <Modal
        title={getSharkText("custom_profile.group_selector_title")}
        open={visible}
        onCancel={() => setVisible(false)}
        width={1000}
        onOk={submit}
        destroyOnClose
      >
        <GroupSelector
          data={data}
          ref={formRef}
          value={selectedTags}
          onChangeValue={handleTagsChange}
          areaSource={areaSource}
        />
      </Modal>
    </span>
  );
};
export default GroupSelectorEntry;
