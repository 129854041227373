import React, { useState, useEffect, useRef, useMemo } from "react";
import { Alert, Tooltip, Row, Col, Card, Select, Popover } from "antd";
import Cards from "Components/Card/Cards";
import AirlinesRanking from "Components/AirlinesRanking";
import AlineCharts from "Page/AI/AirlineAnalysis/Components/AlineCharts";
import SearchAreaOrAirport from "Components/SearchAreaOrAirport";
import Count from "Components/Count";
import AirlineAnalysisBarLineCharts from "./Components/AirlineAnalysisBarLineCharts";
import UpdateTime from "Components/UpdateTime";
import {
  Area,
  AirlinesQueryExt,
  Tab,
  ECompareTypeV2,
  SystemType,
} from "Interface";
import useGlobal from "Store";
import AirlineTable from "./Components/AirlineTable";
import Page from "Layout/Page";
import { getModuleNameFromPath, getModuleStatus, isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { MODULE_CODE, CHART_TABLE_CODE, QUERY_URL } from "./fetchCode";
import { MARKET_COMPARISO_CARDS, DEFAULT_AREA, CompareTypes } from "Constants";
import "./index.css";
import AirportRanking from "./Components/AirportRanking";
import { useLocation } from "react-router-dom";
import { AirlinesCardsConfig } from "./Components/CardsConfig";
import useRefFunc from "Utils/useRefFunc";
import { InfoCircleOutlined } from "@ant-design/icons";
import SelectedAirlines from "Components/SelectedAirlines";
import { cloneDeep } from "lodash";
const { Option } = Select;

const defaultAirlinesAreaQueryExt: AirlinesQueryExt = {
  filterType: 0,
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
  flightNO: [],
};

const AirlineAnalysis = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [hasCardData, setHasCardData] = useState(false);
  const [shared, setShared] = useState<number>(3);
  const [curCard, setCurCard] = useState({
    cardCode: "",
    cardName: "",
    cardHint: "",
    cardValue: 0,
    avgValue: 0,
    compareValue: 0,
  });
  // 机场版搜索area
  const [area, setArea] = useState<Area>(DEFAULT_AREA);
  const [airlinesAreaQueryExt, setAirlinesAreaQueryExt] =
    useState<AirlinesQueryExt>(defaultAirlinesAreaQueryExt);
  // 航司版query参数
  const airlinesQueryExt = useMemo(() => {
    return {
      ...airlinesAreaQueryExt,
      together: shared,
    };
  }, [airlinesAreaQueryExt, shared]);
  const [globalState] = useGlobal();
  const {
    queryCondition,
    helpVisibility,
    userInfo,
    systemType,
    airlinesQueryCondition,
  } = globalState;
  const { departure, arrive } = queryCondition;
  const isAirport = isAirportMode(systemType);
  const isAirlinesMarketComparison =
    systemType === 2 && airlinesQueryCondition.compareType === 1;
  const isDemo =
    getModuleStatus(
      getModuleNameFromPath(location.pathname, systemType),
      userInfo.moduleList
    ) === 0;
  const cascaderRef = useRef<any>();

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setArea(DEFAULT_AREA);
    setAirlinesAreaQueryExt(defaultAirlinesAreaQueryExt);
    setTimeout(() => setLoading(false));
  }, [queryCondition.flightClass, airlinesQueryCondition.flightClass]);

  useEffect(() => {
    if (!departure && !arrive) {
      setArea(DEFAULT_AREA);
    }
  }, [departure, arrive]);

  const alertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].map}
      text={`${getSharkText("key.stopover_codeshare_excluded.hint")} ${
        isAirport
          ? getSharkText("key.target_airport_radiated.hint")
          : getSharkText("key.observe_actual_flight_routes.hint")
      }`}
    />
  );

  const rankTab: Tab[] = curCard.cardCode
    ? [
        {
          chartTableCode: "target_top10_city",
          title: getSharkText(`card.${curCard.cardCode}.name`),
          compare: 0,
        },
        {
          chartTableCode: "target_top10_compare",
          title: getSharkText("key.yoy_increase.name"),
          compare: 1,
        },
        {
          chartTableCode: "target_top10_compare",
          title: getSharkText("key.yoy_increment.name"),
          compare: 2,
        },
      ]
    : [];

  const rankTabAirlines = curCard.cardCode
    ? [
        {
          title: getSharkText(`card.${curCard.cardCode}.name`),
          compare: false,
        },
        {
          title: isAirlinesMarketComparison
            ? MARKET_COMPARISO_CARDS.includes(curCard.cardCode)
              ? getSharkText("key.market_share.name")
              : getSharkText("key.compare_to_market")
            : getSharkText("key.yoy_increase.name"),
          compare: true,
        },
      ]
    : [];

  const handleMapAreaChange = useRefFunc((v: Area) => {
    console.log("set cascader value: ", v);
    setArea(v);
    if (cascaderRef && cascaderRef.current) {
      cascaderRef.current.setValue(v);
    }
  });

  const queryExt = useMemo(() => {
    return isAirport ? { area } : { filter: airlinesQueryExt };
  }, [airlinesQueryExt, area, isAirport]);

  const handleSharedChange = useRefFunc((val: number) => {
    setShared(val);
  });

  const sharedHelp = useMemo(() => {
    return (
      <div>
        <div>{getSharkText("key.competitive_market_solo_excluded.hint")}</div>
        <div>{getSharkText("key.competitive_market_solo_included.hint")}</div>
        <div>{getSharkText("key.whole_market.hint")}</div>
      </div>
    );
  }, []);

  const extendTools = useMemo(() => {
    return !isAirport ? (
      <>
        <Select
          value={shared}
          onChange={handleSharedChange}
          style={{ width: 170 }}
        >
          <Option value={1}>
            {getSharkText("key.competitive_market_solo_excluded.name")}
          </Option>
          <Option value={2}>
            {getSharkText("key.competitive_market_solo_included.name")}
          </Option>
          <Option value={3}>{getSharkText("key.whole_market.name")}</Option>
          <Option value={4}>{getSharkText("key.solo_routes.name")}</Option>
        </Select>
        <Popover content={sharedHelp}>
          <InfoCircleOutlined style={{ marginLeft: 3 }} />
        </Popover>
      </>
    ) : undefined;
  }, [handleSharedChange, isAirport, shared, sharedHelp]);

  const AirlineCompareTypes = useMemo(() => {
    if (systemType === SystemType.airport) {
      return CompareTypes;
    }
    const types = cloneDeep(CompareTypes);
    types[ECompareTypeV2.MARKET_COMPARE].introduce = getSharkText(
      "key.compare_market_share.hint"
    );
    return types;
  }, [systemType]);

  return (
    <Page
      defaultDateMode="lastMonth"
      airlinesCompareType={ECompareTypeV2.MARKET_COMPARE}
      useAirportGroup={true}
      airportGroupMultiple={true}
      extendTools={extendTools}
      compareContent={AirlineCompareTypes}
    >
      {loading ? undefined : (
        <>
          <div className="content-white">
            <Alert message={alertMessage} banner type="info" />
            {isAirport ? (
              <SearchAreaOrAirport
                value={area}
                setArea={setArea}
                ref={cascaderRef}
                useDomesticArea
              />
            ) : (
              // <AirlineAnalysisSelectAirlines
              //   ref={cascaderRef}
              //   airlinesQueryExt={airlinesAreaQueryExt}
              //   setAirlinesQueryExt={setAirlinesAreaQueryExt}
              // />
              <SelectedAirlines
                airlinesQueryExt={airlinesAreaQueryExt}
                setAirlinesQueryExt={setAirlinesAreaQueryExt}
                useExchange
              />
            )}
            {!isDemo ? (
              <Row
                style={{
                  marginTop: 16,
                  position: "relative",
                  flexFlow: "column",
                }}
              >
                <Col>
                  <Tooltip
                    getPopupContainer={() =>
                      document.getElementById("main") || document.body
                    }
                    title={getSharkText("key.draw_lines_color_reflect.hint")}
                    placement="top"
                    visible={helpVisibility}
                  >
                    <AlineCharts
                      queryUrl={QUERY_URL[systemType].map}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={CHART_TABLE_CODE[systemType].map}
                      area={area}
                      airlinesQueryExt={airlinesQueryExt}
                      setArea={handleMapAreaChange}
                      setAirlinesQueryExt={setAirlinesAreaQueryExt}
                    />
                  </Tooltip>
                  <Card className="count-card" bordered={false}>
                    <Count
                      queryUrl={QUERY_URL[systemType].count}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={CHART_TABLE_CODE[systemType].count}
                      area={area}
                      airlinesQueryExt={airlinesQueryExt}
                    />
                  </Card>
                </Col>
              </Row>
            ) : undefined}
            <Row style={{ marginTop: 16, flexFlow: "column" }}>
              <Col>
                <Card className="ai_cards">
                  <Cards
                    queryUrl={QUERY_URL[systemType].card}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].card}
                    setCurCard={setCurCard}
                    curCardCode={curCard.cardCode}
                    setHasCardData={setHasCardData}
                    ext={queryExt}
                    cardGroup={[
                      ["passenger_traffic", "kilo_passenger_traffic"],
                      ["transport_capacity", "kilo_transport_capacity"],
                      ["ow_price", "price_sum_ow"],
                      ["capacity_production_cost", "income_production_cost"],
                    ]}
                    config={AirlinesCardsConfig}
                  />
                </Card>
              </Col>
            </Row>
            {hasCardData ? (
              <Row gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}>
                <Col lg={15} md={12} xs={24}>
                  <AirlineAnalysisBarLineCharts
                    queryUrl={QUERY_URL[systemType].barLineCharts}
                    queryDownloadUrl={
                      systemType === 1
                        ? ""
                        : QUERY_URL[systemType].barLineDownload
                    }
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].barLineCharts}
                    ext={queryExt}
                    cardCode={curCard.cardCode}
                    style={{ marginTop: 16 }}
                    height={452}
                  />
                </Col>
                <Col lg={9} md={12} xs={24}>
                  {isAirport ? (
                    <AirportRanking
                      title={getSharkText("key.top_10_routes.name")}
                      queryUrl={QUERY_URL[systemType].top10}
                      moduleCode={MODULE_CODE[systemType]}
                      tab={rankTab}
                      style={{ marginTop: 16 }}
                      sortable
                      cardCode={curCard.cardCode}
                      area={area}
                    />
                  ) : (
                    <AirlinesRanking
                      title={getSharkText("key.top_10_routes.name")}
                      queryUrl={QUERY_URL[systemType].top10}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode="AL0205"
                      tab={rankTabAirlines}
                      style={{ marginTop: 16 }}
                      sortable
                      cardCode={curCard.cardCode}
                      airlinesQueryExt={airlinesQueryExt}
                    />
                  )}
                </Col>
              </Row>
            ) : undefined}
          </div>

          <div className="content-white">
            <AirlineTable
              queryUrl={QUERY_URL[systemType].table}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={
                isAirport
                  ? CHART_TABLE_CODE[systemType].table
                  : isAirlinesMarketComparison
                  ? CHART_TABLE_CODE[systemType].tableMarket
                  : CHART_TABLE_CODE[systemType].tableLastYear
              }
              area={area}
              airlinesQueryExt={airlinesQueryExt}
            />
          </div>
        </>
      )}
    </Page>
  );
};

export default AirlineAnalysis;
