import { Avatar, Dropdown } from "antd";
import React, { ReactElement, useMemo } from "react";
import i18next from "i18next";
import { resources } from "i18n/config";
import { enumKeys } from "Utils/global";
import SvgIcon from "Components/SvgIcon";

/**
 * Component description
 * 多语言切换组件
 */
const Trans = (): ReactElement => {
  const changeLanguage = (v: string) => {
    i18next.changeLanguage(v);
    sessionStorage.setItem("lang", v);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("lang")) {
      urlParams.set("lang", v);
    } else {
      urlParams.append("lang", v);
    }
    // 重定向到新的URL
    window.location.search = urlParams.toString();
    // window.location.href = window.location.href.replace
    // window.location.reload();
  };
  const keys = enumKeys(resources);

  const items = useMemo(() => {
    return keys.map((lang: keyof typeof resources) => {
      const langObj = resources[lang];
      return {
        label: langObj.name,
        key: lang,
        onClick: () => changeLanguage(lang),
      };
    });
  }, [keys]);

  return (
    <Dropdown menu={{ items }}>
      <Avatar
        icon={
          <span className="anticon">
            <SvgIcon name="zhongyingqiehuan1" />
          </span>
        }
      />
    </Dropdown>
  );
};
export default Trans;
