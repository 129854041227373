import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ITransferAnalysisExt } from "Page/AI/TransferPolicy/TransferPolicyInterface";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { getServer } from "Service/server";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AlertName, EAlertType } from "../Common";
import { InputNumber, Popover, Spin, Tabs } from "antd";
import { G } from "Utils/global";
import Refetch from "Components/Refetch";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { getColumns } from "./Columns";
import { RouteColumns } from "../AdvantageComponents/Columns";
import { InfoCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

export interface ITransferAlertProps {
  transferAnalysisExt: ITransferAnalysisExt;
}

/**
 * 中转分析优劣势涨跌预警
 */
const TransferAlert = (props: ITransferAlertProps): ReactElement => {
  const { transferAnalysisExt } = props;
  const { filter, query } = transferAnalysisExt;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition, queryCondition } = globalState;
  const [activeKey, setActiveKey] = useState<string>(
    EAlertType.TransferRoute.toString()
  );
  const [rate, setRate] = useState<number>(30);
  const isAirport = isAirportMode(systemType);
  const queryWithExt = useMemo(() => {
    if (isAirport) {
      return {
        ...queryCondition,
        ...query,
      };
    }
    return airlinesQueryCondition;
  }, [airlinesQueryCondition, isAirport, query, queryCondition]);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].alert,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].alert,
    },
    query: queryWithExt,
    ext: {
      filter: {},
      type: 1,
      rate: 0.3,
    },
    lazey: true,
  });
  const refetch = useCallback(() => {
    doFetch({
      query: queryWithExt,
      ext: {
        filter,
        type: parseInt(activeKey, 10),
        rate: rate / 100,
      },
    });
  }, [activeKey, doFetch, filter, queryWithExt, rate]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getPaneContent = useCallback(
    (columns: RouteColumns, name: string) => {
      return (
        <CustomTableV2
          headExtends={
            <div>
              <span
                style={{
                  fontSize: "120%",
                  fontWeight: "bold",
                  margin: "0 5px 0 30px",
                }}
              >
                {getSharkText("tranfer.note3.name")}: ±
              </span>
              <InputNumber
                value={rate}
                onChange={(e) => setRate(e || 0)}
                step={5}
              />
              <span style={{ marginLeft: 5, fontWeight: "bold" }}>%</span>
            </div>
          }
          dataSource={data}
          columns={columns}
          downloadName={name}
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={CHART_TABLE_CODE[systemType].alert}
        />
      );
    },
    [data, rate]
  );

  const getPane = useCallback(
    (t: EAlertType) => {
      const columns = getColumns(systemType, t);
      if (!columns) {
        return "Columns not defined";
      }
      const name = AlertName[t];
      return (
        <TabPane key={t.toString()} tab={name}>
          {getPaneContent(columns, name)}
        </TabPane>
      );
    },
    [getPaneContent, systemType]
  );

  const airportPane = useMemo(() => {
    const columns = getColumns(systemType, EAlertType.TransferRoute);
    if (columns) {
      return getPaneContent(columns, getSharkText("transfer.t_airport.name"));
    }
  }, [getPaneContent, systemType]);

  const titleTip = useMemo(() => {
    return isAirport
      ? getSharkText("tranfer.note1.name")
      : getSharkText("tranfer.note2.name");
  }, [isAirport]);
  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <Spin spinning={isLoading}>
      <Popover content={titleTip}>
        <span
          style={{
            display: "inline-block",
            marginRight: 20,
            fontSize: "120%",
            fontWeight: "bold",
          }}
        >
          {getSharkText("transfer.pre_warning.name")}
          <InfoCircleOutlined
            style={{ display: "inline-block", marginLeft: 5 }}
          />
        </span>
      </Popover>
      {isAirport ? (
        airportPane
      ) : (
        <Tabs
          defaultActiveKey={activeKey.toString()}
          type="card"
          onChange={(e) => setActiveKey(e)}
        >
          {G.enumValues(EAlertType).map((t) => getPane(t))}
        </Tabs>
      )}
    </Spin>
  );
};
export default TransferAlert;
