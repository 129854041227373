import { AirlinesSearchIndexTag } from "@ctrip/flt-bi-flightai-airlines";
import { Checkbox } from "antd";
import { EMPTY_ARRAY } from "Constants";
import React, { ReactElement } from "react";
import { G } from "Utils/global";
import { ERoute, ESeason, Routes, Seasons } from "../Common";
import { getSharkText } from "Utils/i18nGlobal";

export interface IGroupSelectorProps {
  value: AirlinesSearchIndexTag;
  onChange: (v: Partial<AirlinesSearchIndexTag>) => void;
}

/**
 *  Component description
 */
const GroupSelector = (props: IGroupSelectorProps): ReactElement => {
  const { value, onChange } = props;
  return (
    <div>
      <div>
        <span>{getSharkText("key.airline_colon.name")}</span>
        <Checkbox.Group
          value={value.airline || EMPTY_ARRAY}
          onChange={(v) => onChange({ airline: v as number[] })}
          disabled
        >
          <Checkbox value={1}>
            {getSharkText("key.user_airline.checkbox")}
          </Checkbox>
        </Checkbox.Group>
      </div>
      <div style={{ marginTop: 10 }}>
        <span>{getSharkText("key.seanson_colon.name")}</span>
        <Checkbox.Group
          value={value.season || EMPTY_ARRAY}
          onChange={(v) => onChange({ season: v as number[] })}
        >
          {G.enumValues(ESeason).map((t) => (
            <Checkbox value={t} key={t}>
              {Seasons[t as ESeason]}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </div>
      <div style={{ marginTop: 10 }}>
        <span>{getSharkText("key.navigable_situation.name")}</span>
        <Checkbox.Group
          value={value.route || EMPTY_ARRAY}
          onChange={(v) => onChange({ route: v as number[] })}
        >
          {G.enumValues(ERoute).map((t) => (
            <Checkbox value={t} key={t}>
              {Routes[t as ERoute]}
            </Checkbox>
          ))}
        </Checkbox.Group>
      </div>
    </div>
  );
};
export default GroupSelector;
