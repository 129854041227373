// Created by xh_zhu on 2020-12-15

import { Col, Row } from "antd";
import { Area, FlightClass as FlightClassEnum } from "Interface";
import React, { useEffect, useState } from "react";

import FlightClass from "./FlightClass";
import useGlobal from "Store";
import CascaderArea from "Components/CascaderArea";
import { MODULE_CODE } from "../fetchCode";
import { getServer } from "Service/server";
import InOut from "Components/Toolbar/inOut";
import { DEFAULT_FLIGHT_CLASS } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

interface ISearchAreaProps {
  onChangeArea: (val: Area) => void;
  defaultAirportCode?: string;
  isDemo: boolean;
}

const SearchArea: React.FC<ISearchAreaProps> = (props: ISearchAreaProps) => {
  const { isDemo, onChangeArea, defaultAirportCode } = props;
  const [fc, setFc] = useState<FlightClassEnum>(DEFAULT_FLIGHT_CLASS);
  const [area, setArea] = useState<Area>();

  const [globalState, actions] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const { setQueryCondition } = actions;

  useEffect(() => {
    setQueryCondition({
      ...queryCondition,
      flightClass: fc,
    });
  }, [fc]);

  useEffect(() => {
    if (area) {
      onChangeArea(area);
    }
  }, [area]);

  return (
    <Row
      align="middle"
      gutter={[10, 20]}
      style={{ marginTop: 0, padding: "15px 0px" }}
    >
      <Col>
        <FlightClass setFlightClass={setFc}></FlightClass>
      </Col>
      <Col>
        <CascaderArea
          server={getServer(systemType)}
          url="searchAirportArea"
          moduleCode={MODULE_CODE[systemType]}
          type="airport"
          setSelectedArea={setArea}
          // 如果这里设置为flightClass的值, 则每次刷新时, 选择器的值会被刷掉
          flightClass={0}
          isDemo={isDemo}
          placeholder={getSharkText("config_page_area_province_airport")}
          defaultCode={defaultAirportCode}
        />
      </Col>
      <Col>
        <InOut />
      </Col>
    </Row>
  );
};
export default SearchArea;
