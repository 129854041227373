// Created by xh_zhu on 2020-12-14

import { InfoCircleOutlined } from "@ant-design/icons";

import { Alert, Card, Col, Empty, Popover, Row } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import RadarCharts from "./Components/RadarCharts";
import TrendsPie from "./Components/TrendsPie";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import useGlobal from "Store";
import SearchArea from "./Components/SearchArea";
import { IAirportQueryExt2, IQueryExt } from "./Interface";
import BoardSearch from "./Components/BoardSearch";
import Cards from "./Components/Cards";
import DashTable from "./Components/DashTable";
import UpdateTime from "Components/UpdateTime";
import "./AirportIndex.css";
import { Area, ECompareTypeV2 } from "Interface";
import Page from "Layout/Page";
import { getServer } from "Service/server";
import HeaderAlert from "Components/HeaderAlert";
import { isSame } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { COMPARE_TYPE_VALUE_NAME } from "Constants";
import { getDefaultAirport } from "Utils/user";

interface IAirportIndexProps {
  isDemo: boolean;
}

const defaultArea: Area = {
  areaType: 0,
  areaCode: "",
  areaName: "",
};

const AirportIndex: React.FC<IAirportIndexProps> = (
  props: IAirportIndexProps
) => {
  const { isDemo } = props;
  const [queryExt1, setQueryExt1] = useState<Area>();
  const [queryExt2, setQueryExt2] = useState<IAirportQueryExt2>();
  const [queryExt, setQueryExt] = useState<IQueryExt>();
  const [globalState] = useGlobal();
  const tableRef = useRef<any>();
  const { systemType, userInfo, queryCondition } = globalState;
  const valControl =
    queryExt2 && queryExt2.valControl ? queryExt2.valControl : [];
  const server = getServer(systemType);
  const userFirstAirport = getDefaultAirport(userInfo);
  const missRequiredParameter =
    !queryCondition.departure && !queryCondition.arrive;
  // 前年时显示前年恢复指数, 否则显示去年恢复指数
  const lastPeriodName = useMemo(() => {
    const compareType = (
      queryExt2?.yoy
        ? queryExt2.yoy === 1
          ? 0
          : queryExt2.yoy
        : queryCondition.compareType
    ) as ECompareTypeV2;
    return COMPARE_TYPE_VALUE_NAME[compareType];
  }, [queryCondition.compareType, queryExt2?.yoy]);

  useEffect(() => {
    if (!queryExt2) {
      return;
    }
    const tmpExt: IQueryExt = {
      yoy: queryExt2?.yoy || 0,
      wow: queryExt2?.wow || 0,
      area: queryExt1 || defaultArea,
      // area: queryExt1 || userDefaultAirport || defaultArea
    };
    setQueryExt((s) => {
      if (
        !isSame(tmpExt, s) &&
        queryCondition.startDate &&
        queryCondition.endDate
      ) {
        return tmpExt;
      } else {
        return s;
      }
    });
  }, [queryCondition.endDate, queryCondition.startDate, queryExt1, queryExt2]);

  const downLoadTable = () => {
    if (tableRef?.current?.download) {
      tableRef.current.download();
    }
  };

  const text = (
    <UpdateTime
      isAirport={true}
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].cards}
      text={getSharkText("dashboard.alert")}
    />
  );
  const description = "";

  return (
    <Page
      needToolBar={false}
      defaultDateMode="none"
      needGlobalConditionCheck={false}
    >
      {/* {isDemo ? <HeaderAlert /> : undefined} */}
      <div
        className="content-white"
        style={{ backgroundColor: "var(--filter_area_bg)", paddingBottom: 0 }}
      >
        <div style={{ borderBottom: "1px solid var(--filter_bg_color_inner)" }}>
          <HeaderAlert />
        </div>
        <Alert
          message={text}
          description={description}
          banner
          type="info"
          showIcon
        />
        <SearchArea isDemo={isDemo} onChangeArea={setQueryExt1} />
      </div>
      <div
        className="content-white"
        style={{
          marginTop: 0,
          paddingTop: 0,
          paddingBottom: 0,
        }}
      ></div>
      <div
        className="content-white"
        style={{ borderTop: "1px solid var(--filter_bg_color_inner)" }}
      >
        {missRequiredParameter ? (
          <Empty>
            {getSharkText("config_page_entry_exit_must_choose_one")}
          </Empty>
        ) : (
          <Row
            gutter={[{ xs: 8, sm: 16, md: 24 }, 20]}
            style={{ alignItems: "stretch" }}
          >
            <Col span={18}>
              <div
                style={{
                  height: "100%",
                }}
              >
                <div className="search-area">
                  <BoardSearch
                    downLoadHandler={downLoadTable}
                    onChangeVal={setQueryExt2}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].table}
                  />
                </div>
                <div className="card-list">
                  <Row style={{ marginBottom: 16 }}>
                    <Col xs={24}>
                      <Card bordered={false} title="" className="dashboard">
                        <Cards
                          server={server}
                          queryUrl={QUERY_URL[systemType].cards}
                          moduleCode={MODULE_CODE[systemType]}
                          chartTableCode={CHART_TABLE_CODE[systemType].cards}
                          queryExt={queryExt}
                        />
                      </Card>
                    </Col>
                  </Row>
                </div>
                <div className="table">
                  <DashTable
                    ref={tableRef}
                    server={server}
                    queryUrl={QUERY_URL[systemType].table}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].table}
                    valControl={valControl}
                    queryExt={queryExt}
                    defaultAirport={userFirstAirport?.code}
                  />
                </div>
              </div>
            </Col>
            <Col
              span={6}
              style={{
                flex: 1,
                borderLeft: "1px solid var(--filter_bg_color_inner)",
              }}
            >
              <div
                style={{
                  height: "100%",
                }}
              >
                <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 0]}>
                  <Col
                    span={24}
                    id="travelTrends"
                    style={{
                      borderBottom: "1px solid var(--filter_bg_color_inner)",
                    }}
                  >
                    <TrendsPie
                      server={server}
                      queryUrl={QUERY_URL[systemType].trendsPie}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={CHART_TABLE_CODE[systemType].travel}
                      queryExt={queryExt}
                      color="#01C5DB"
                      link="/core_overview"
                      helpString={
                        <>
                          <span style={{ display: "block" }}>
                            {getSharkText("dashboard.trend_pie.travel.title")}：
                            {getSharkText("date.last30")}
                            {getSharkText("dashboard.trend_pie.travel.content")}
                            /{lastPeriodName}
                            {getSharkText("dashboard.trend_pie.travel.content")}
                          </span>
                          <span>
                            {getSharkText("key.compare_to_market")}：
                            {getSharkText(
                              "dashboard.trend_pie.travel.compare.content"
                            )}
                          </span>
                        </>
                      }
                      type={1}
                    />
                  </Col>
                  <Col
                    span={24}
                    id="confidentTrends"
                    style={{
                      borderBottom: "1px solid var(--filter_bg_color_inner)",
                    }}
                  >
                    <TrendsPie
                      server={server}
                      queryUrl={QUERY_URL[systemType].trendsPie}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={CHART_TABLE_CODE[systemType].confident}
                      queryExt={queryExt}
                      color="#FC9B4F"
                      link="/search_index"
                      helpString={
                        <>
                          <span style={{ display: "block" }}>
                            {getSharkText(
                              "dashboard.trend_pie.Confidence.title"
                            )}
                            ：{getSharkText("date.next30")}
                            {getSharkText("card.search_index.name")}/
                            {lastPeriodName}
                            {getSharkText("card.search_index.name")}
                          </span>
                          <span>
                            {getSharkText("key.compare_to_market")}：
                            {getSharkText(
                              "dashboard.trend_pie.confidence.compare.content"
                            )}
                          </span>
                        </>
                      }
                      type={2}
                    />
                  </Col>
                  {!queryExt1 ||
                  !queryExt1.areaType ||
                  queryExt1.areaType === 0 ? undefined : (
                    <Col
                      span={24}
                      style={{
                        marginTop: 10,
                        minHeight: 295,
                        borderLeft: "1px solid var(--filter_bg_color_inner)",
                      }}
                    >
                      <div className="trends-mod">
                        <div className="trends-header">
                          <span>
                            {getSharkText("config_page_competitiveness_score")}
                          </span>
                          <Popover
                            content={getSharkText(
                              "config_page_competitiveness_score_description"
                            )}
                            overlayClassName="dashboard-val-control-popover"
                          >
                            <InfoCircleOutlined />
                          </Popover>
                        </div>
                        <RadarCharts
                          server={server}
                          queryUrl={QUERY_URL[systemType].radarCharts}
                          moduleCode={MODULE_CODE[systemType]}
                          chartTableCode={CHART_TABLE_CODE[systemType].score}
                          queryExt={queryExt}
                          link="/airline_analysis"
                          type={1}
                          height={240}
                        />
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Page>
  );
};
export default AirportIndex;
