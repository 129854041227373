import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Card, Col, Empty, Radio, Row } from "antd";
import { useLocation } from "react-router-dom";
import { RadioChangeEvent } from "antd/lib/radio";
import BarCharts from "./charts";
import { AggCode, ISeries } from "Interface";
import useGlobal from "Store";
import {
  fillCharts,
  getBetweenDateArr,
  getModule,
  getModuleNameFromPath,
  getProportionPercentageVal,
  isAirportMode,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";
import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: React.CSSProperties;
  ext?: Partial<TransferPolicyBarlineRequestType>;
  totalSeries?: ISeries[];
  legendHelp?: Record<string, string | ReactNode>;
}

const BarLineCharts = (props: BarLineChartsProps): ReactElement => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    style,
    ext: otherExt,
    totalSeries,
    legendHelp,
  } = props;

  const location = useLocation();
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [barData, setBarData] = useState<any[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition, userInfo } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const startDate = isAirport
    ? queryCondition.startDate
    : airlinesQueryCondition.startDate;
  const endDate = isAirport
    ? queryCondition.endDate
    : airlinesQueryCondition.endDate;
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const IsDemo = !!(module && module.moduleStatus === 0);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      aggCode,
      ...otherExt,
    },
    lazey: true,
  });
  const refetch = useCallback(() => {
    if (otherExt?.filter?.searchStartDate && otherExt?.filter?.searchEndDate) {
      doFetch({
        query,
        ext: {
          aggCode,
          ...otherExt,
        },
      });
    }
  }, [aggCode, doFetch, otherExt, query]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const dataCP = cloneDeep(data);
      const sortDate = dataCP.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value ? _.round(item.value, 0) : 0;
        item.compareValue = item.compareValue
          ? _.round(item.compareValue, 0)
          : 0;
        const resultValue = _.round(
          getProportionPercentageVal(item.compareValue, item.value),
          2
        );
        item.resultValue = _.isFinite(resultValue) ? resultValue : 0;
        return item;
      });
      const sd =
        otherExt?.axisType === 0
          ? otherExt?.filter?.searchStartDate || startDate
          : startDate;
      const ed =
        otherExt?.axisType === 0
          ? otherExt?.filter?.searchEndDate || endDate
          : endDate;
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(sd), moment(ed), aggCode)
      );
      setBarData(IsDemo ? compareData : fillData);
    } else {
      setBarData([]);
    }
  }, [
    IsDemo,
    aggCode,
    data,
    endDate,
    otherExt?.axisType,
    otherExt?.filter?.searchEndDate,
    otherExt?.filter?.searchStartDate,
    startDate,
  ]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  return (
    <Card style={style} className="barline-charts">
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={handleChange}
            id="chartsAggCode"
          >
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <BarCharts
          aggCode={aggCode}
          loading={isLoading}
          data={barData}
          startDate={startDate}
          endDate={endDate}
          height={height}
          isDemo={IsDemo}
          totalSeries={totalSeries}
          legendHelp={legendHelp}
          ext={otherExt}
        />
      )}
    </Card>
  );
};

export default BarLineCharts;
