import { ITrace } from "Interface";
import { Moment } from "moment";
import { EPanelType } from "Page/AI/MonitoringAlerting/MonitoringAlertingCommon";
import { getSharkText } from "Utils/i18nGlobal";

export interface INotificationDataType {
  id: string;
  /**
   * 类型（1=运力预警、2=搜索预警、3=动态调价、4=价格监控、5=客座率预警 6=价格预警）
   */
  type: number;
  //  告警时间
  changedate: string;
  //  起飞日期
  takeofftime: string;
  //  航班
  flightno: string;
  // dport: string; //  出发城市
  // aport: string; //  到达城市
  /**
   * 航线 城市 机场
   */
  route: string;
  //  上客进度
  price: number;
  //  竞飞航班
  compareFlightno: string;
  //  竞飞航班上客进度
  comparePrice: number;
  //  差异类型 0=竞飞对比差异 1=竞飞变价
  compareType: number;
  //  价格差异
  diffPrice: number;
  //  原因
  reason: string;
  /**
   * 状态 0= 未读 1=已读 2=作废
   */
  status: number;
  //	预警内容
  item: string;
  //	对比项(竞飞/其他)
  compareItem: string;
  //	指标
  value: number;
  //	对比项
  compareValue: number;
  //	差异
  diffValue: number;
  //	模板
  template: string;
}

export const NOTIFY_TYPE_TO_ALERT_ENUM: Record<number, EPanelType> = {
  1: EPanelType.Capacity,
  2: EPanelType.Search,
  5: EPanelType.Load,
  6: EPanelType.Price,
};

export interface IScribe {
  /**
   * 价格监控
   */
  price: number;
  /**
   * 动态调价
   */
  dynamicPrice: number;
  /**
   * 运力预警
   */
  capacity: number;
  /**
   * 搜索预警
   */
  search: number;
  /**
   * 客座率预警
   */
  lf: number;
  /**
   * 价格预警
   */
  priceMonitor: number;
  /**
   * 人工审核
   */
  manualReview: number;
}

export const ScribeName: Record<keyof IScribe, string> = {
  price: getSharkText("menu_price_monitor_airlines"),
  dynamicPrice: getSharkText("config_page_dynamic_price"),
  capacity: getSharkText("config_page_capacity"),
  search: getSharkText("config_page_search_warning"),
  lf: getSharkText("config_page_load_warning"),
  priceMonitor: getSharkText("config_page_price_warning"),
  manualReview: getSharkText("config_page_manual_review"),
};

export interface ISubScribe extends IScribe {
  /**
   * 	id 更新需要传
   */
  subscribeId?: number;
  /**
   * 	用户id 插入更新无需传
   */
  userId: number;
  /**
   * 	航线列表,多个逗号分隔
   */
  route: string;
  /**
   * 	城市对
   */
  routecity?: string;
  /**
   * 邮件订阅
   */
  mail: number;
  /**
   * 微信订阅
   */
  wechat: number;
  /**
   * 绑定的企业微信号
   */
  wechatAccount: string;
}

export const ALERT_NAMES = [
  "",
  getSharkText("config_page_capacity"),
  getSharkText("config_page_search_warning"),
  getSharkText("menu_dynamic_price_airlines"),
  getSharkText("menu_price_monitor_airlines"),
  getSharkText("config_page_load_warning"),
  getSharkText("config_page_price_warning"),
  // 上客速度暂时归类到了客座率预警, 客座率预警
  getSharkText("config_page_guest_speed"),
  getSharkText("config_page_price_adjustment_review"),
];
/**
 * 分类, 1=监控预警[价格预警/客座率/价格监控], 2=信息建议[调价建议/运力预警/搜索预警]
 */
export const ALERT_TYPE: Record<number, number[]> = {
  1: [6, 5, 4],
  2: [3, 1, 2],
};

/**
 * 不能链接航班管理的通知类型
 */
export const DISABLE_MANAGE = [1, 2];

export const TRACE_ID: ITrace = {
  category: "notification",
  getName: (key: keyof typeof TRACE_ID) => {
    if (typeof TRACE_ID[key] === "string") {
      return TRACE_ID.category + "_" + TRACE_ID[key];
    } else {
      throw new Error("没有这个埋点key");
    }
  },
  main: "alert",
  info: "info",
  warn: "warn",
  settings: "settings",
};

export interface IFilter {
  dateRange?: Moment[] | null;
  filterType?: number[];
  flightNO?: string[];
  route?: string[];
  isFilterMode: boolean;
}

export const DEFAULT_FILTER: IFilter = {
  isFilterMode: false,
};
