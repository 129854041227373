export const MODULE_CODE: any = {
  1: "holiday_analysis_airport",
  2: "holiday_analysis_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    cards: "AP0101",
    barLineCharts: "AP0102",
    topCity: "AP0104",
    pieCharts: "AP0103",
    profile: "AP1107",
  },
  2: {
    cards: "AL0101",
    barLineCharts: "AL0102",
    topCity: "AL0104",
    pieCharts: "AL0103",
    profile: "AL1107",
  },
};

export const QUERY_URL: any = {
  1: {
    cards: "queryHolidayAnalysisCard",
    barLineCharts: "queryHolidayAnalysisBarLine",
    topCity: "queryHolidayAnalysisTopCity",
    table: "queryHolidayAnalysisTable",
    map: "queryHolidayAnalysisMap",
    profile: "queryHolidayAnalysisUserProfile",
  },
  2: {
    cards: "queryHolidayAnalysisCard",
    barLineCharts: "queryHolidayAnalysisBarLine",
    topCity: "queryHolidayAnalysisTopCity",
    table: "queryHolidayAnalysisTable",
    map: "queryHolidayAnalysisMap",
    profile: "queryHolidayAnalysisUserProfile",
  },
};
