import { Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { IDownloadHeader } from "Interface";
import React, { ReactElement, useEffect, useMemo } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import { IFlightReviewFilter, IFootTableData } from "../ReviewCommon";

export interface IFooterTablePortalProps {
  queryExt: IFlightReviewFilter;
}

/**
 * 航班复盘动态调价使用率表格
 */
const FooterTablePortal = (props: IFooterTablePortalProps): ReactElement => {
  const { queryExt } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition, systemType } = globalState;
  const columns = useMemo((): Array<IDownloadHeader<IFootTableData>> => {
    return [
      {
        title: getSharkText("config_page_seat_revenue_increase_yuan"),
        dataIndex: "raskLift",
        precision: 3,
      },
      {
        title: getSharkText("config_page_average_seat_revenue_yuan"),
        dataIndex: "avgRask",
        precision: 3,
      },
      {
        title: getSharkText("config_page_seat_revenue_increase_percentage"),
        dataIndex: "raskLiftPercent",
        isPercentVal: true,
      },
      {
        title: getSharkText("config_page_passenger_load_factor_increase"),
        dataIndex: "lfLift",
        isPercentVal: true,
      },
      {
        title: getSharkText("config_page_average_passenger_seat"),
        dataIndex: "avgLf",
        isPercentVal: true,
      },
      {
        title: getSharkText(
          "config_page_passenger_seat_rate_increase_percentage"
        ),
        dataIndex: "lfLiftPercent",
        isPercentVal: true,
      },
      {
        title: getSharkText("config_page_price_increase_yuan"),
        dataIndex: "priceLift",
        precision: 0,
      },
      {
        title: getSharkText("config_page_average_price_yuan"),
        dataIndex: "avgPrice",
        precision: 0,
      },
      {
        title: getSharkText("config_page_price_lift_percentage"),
        dataIndex: "priceLiftPercent",
        isPercentVal: true,
      },
    ];
  }, []);
  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].footTable,
    defaultValue: null,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {
      ...queryExt,
    },
    lazey: true,
  });

  useEffect(() => {
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        ...queryExt,
      },
    });
  }, [airlinesQueryCondition, doFetch, queryExt]);
  return (
    <Spin spinning={isLoading}>
      <CustomTableV2
        columns={columns}
        dataSource={data}
        moduleCode={MODULE_CODE[systemType]}
        chartTableCode={"non"}
      />
    </Spin>
  );
};
export default FooterTablePortal;
