import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import {
  Modal,
  Anchor,
  Row,
  Col,
  Select,
  InputNumber,
  Button,
  message,
} from "antd";
import React, {
  ReactElement,
  Ref,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  CHART_TABLE_CODE,
  MODULE_CODE,
  QUERY_URL,
} from "Page/AI/DynamicPrice/fetchCode";
import SearchArea from "./SearchArea";
import { RORecord, LFRecord } from "../interface";
import useRefFunc from "Utils/useRefFunc";
import { getServer } from "Service/server";
import moment from "moment";
import { isNil, round } from "lodash";
import { SystemType } from "Interface";
import ParaBatchGenerate, {
  ParaBatchGenerateRef,
  BatchConfig,
} from "./ParaBatchGenerate";
import { UserReason } from "Page/AI/DynamicPrice/DynamicPriceInterface";
import "./style.scss";
import { getDowOfDateRange } from "Utils/global";
const reasons = Object.keys(UserReason).map((key) => {
  const k = key as keyof typeof UserReason;
  return { label: UserReason[k], value: k };
});

export interface DynamicFarePriceProps {
  takeoffdate: string;
  flightNo: string;
  route: string;
  doFetch: (v: any) => void;
  dataSource: LFRecord | undefined;
  setRefresh: (v: boolean) => void;
  setQueryStatus: (v: boolean) => void;
  example?: any;
}

export interface CabinAdjustmentParameterRef {
  open: (v: boolean) => void;
}

interface FormState {
  value: number | null;
  isValueInvalid: boolean;
  teamMargin: number | null;
  isTeamMarginInvalid: boolean;
  adjustPriceBase: number | null;
  isPriceBaseInvalid: boolean;
  adjustPriceGap: number | null;
  isPriceGapInvalid: boolean;
}

// 初始状态
const initialState: FormState = {
  value: null,
  isValueInvalid: false,
  teamMargin: null,
  isTeamMarginInvalid: false,
  adjustPriceBase: null,
  isPriceBaseInvalid: false,
  adjustPriceGap: null,
  isPriceGapInvalid: false,
};

/**
 * 调舱参数组件
 */
const CabinAdjustmentParameter = forwardRef(
  (
    props: DynamicFarePriceProps,
    ref: Ref<CabinAdjustmentParameterRef>
  ): ReactElement => {
    const {
      takeoffdate,
      flightNo,
      route,
      doFetch,
      dataSource,
      setRefresh,
      setQueryStatus,
    } = props;
    const [open, setOpen] = useState<boolean>(false);
    // const [param, setParam] = useState<RORecord>();
    const [takeofftime, setTakeofftime] = useState<string>(takeoffdate);
    const [reason, setReason] = useState<keyof typeof UserReason | null>(null);
    const [formState, setFormState] = useState<FormState>(initialState);
    const [batchConfig, setBatchConfig] = useState<BatchConfig>({
      dateRange: [takeofftime, takeofftime],
      schedule: getDowOfDateRange([moment(takeofftime), moment(takeofftime)])
        .map((a) => Number(a) + 1)
        .join(""),
    });
    const [routes, setRoutes] = useState<string[]>([route]);
    const [flightNos, setFlightNO] = useState<string[]>([flightNo]);
    const paraBatchGenerateRef = useRef<ParaBatchGenerateRef>(null);
    // const batchConfig = paraBatchGenerateRef.current?.getBatchConfig().config;

    // 创建一个重置函数
    const resetForm = () => {
      setFormState(initialState);
    };

    useEffect(() => {
      setTakeofftime(takeoffdate);
      setRoutes([route ?? ""]);
      setFlightNO([flightNo ?? ""]);
    }, [takeoffdate]);

    useEffect(() => {
      setBatchConfig({
        dateRange: [takeofftime, takeofftime],
        schedule: getDowOfDateRange([moment(takeofftime), moment(takeofftime)])
          .map((a) => Number(a) + 1)
          .join(""),
      });
    }, [takeofftime]);

    // 通用状态更新函数
    const updateFormState = (
      key: string,
      value: number | null,
      isInValid: string,
      defaultValue: number | null
    ) => {
      if (value == null) {
        setFormState((prevState) => ({
          ...prevState,
          [key]: null,
          [isInValid]: false,
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          [key]: value,
          [isInValid]: value !== defaultValue,
        }));
      }
    };

    // 使用通用处理函数生成具体的处理函数
    const handleValueChange = (v: number | null) =>
      updateFormState(
        "value",
        v,
        "isValueInvalid",
        dataSource?.defaultTargetLFFinal ?? null
      );
    const handleTeamMarginChange = (v: number | null) =>
      updateFormState(
        "teamMargin",
        v,
        "isTeamMarginInvalid",
        dataSource?.defaultTeamMargin ?? null
      );
    const handleAdjustPriceBaseChange = (v: number | null) =>
      updateFormState(
        "adjustPriceBase",
        v,
        "isPriceBaseInvalid",
        dataSource?.defaultAdjustPriceBase ?? null
      );
    const handleAdjustPriceGapChange = (v: number | null) =>
      updateFormState(
        "adjustPriceGap",
        v,
        "isPriceGapInvalid",
        dataSource?.defaultAdjustPriceGap ?? null
      );

    useImperativeHandle(ref, () => ({
      open: (v: boolean) => {
        setOpen(v);
      },
    }));

    const [, save] = useFetch({
      server: getServer(SystemType.airlines),
      url: QUERY_URL.save,
      defaultValue: [],
      head: {
        moduleCode: MODULE_CODE[SystemType.airlines],
        chartTableCode: CHART_TABLE_CODE[SystemType.airlines].table,
      },
      query: null,
      lazey: true,
      onSuccess: (res) => {
        if (res.flag) {
          message.success(getSharkText("config_page_save_success"));
        } else {
          message.error(getSharkText("config_page_save_failed"));
        }
      },
      onError: () => {
        message.error(getSharkText("config_page_save_failed"));
      },
    });

    const maxDate = useMemo(() => {
      return moment().add(60, "day");
    }, []);

    const { Link } = Anchor;
    // eslint-disable-next-line max-len
    const resultTargetLFFinal =
      dataSource?.userTargetLFFinal ?? dataSource?.defaultTargetLFFinal
        ? (dataSource.userTargetLFFinal ?? dataSource.defaultTargetLFFinal) *
          100
        : "--";
    const resultTeamMargin =
      dataSource?.userTeamMargin ?? dataSource?.defaultTeamMargin
        ? (dataSource.userTeamMargin ?? dataSource.defaultTeamMargin) * 100
        : "--";

    const resultPriceBase =
      dataSource?.userAdjustPriceBase ||
      dataSource?.defaultAdjustPriceBase ||
      "--";

    const resultPriceGap =
      dataSource?.userAdjustPriceGap ||
      dataSource?.defaultAdjustPriceGap ||
      "--";

    const handleSave = useRefFunc(() => {
      console.log("save value: ", formState.value);
      if (!isNil(formState.value) && isNil(reason)) {
        message.error(getSharkText("config_page_reason_cannot_be_empty"));
        return;
      }
      if (isNil(formState.value) && !isNil(reason)) {
        message.error(getSharkText("最终目标客座率的修改值不能为空"));
        return;
      }
      if (JSON.stringify(formState) === JSON.stringify(initialState)) {
        message.error("无修改");
        return { config: null };
      }
      console.log("batchConfig", batchConfig);
      const newData = {
        takeofftime,
        flightNo: flightNos[0],
        route: routes[0] || "",
        defaultTargetLF: dataSource?.defaultTargetLF,
        defaultTargetLFFinal: dataSource?.defaultTargetLFFinal,
        userTargetLFFinal: isNil(formState.value)
          ? null
          : round(formState.value / 100, 5),
        userReason: formState.value ? Number(reason) : dataSource?.userReason,
        defaultTeamMargin: dataSource?.defaultTeamMargin,
        userTeamMargin: isNil(formState.teamMargin)
          ? null
          : round(formState.teamMargin / 100, 5),
        defaultAdjustPriceBase: dataSource?.defaultAdjustPriceBase,
        userAdjustPriceBase: isNil(formState.adjustPriceBase)
          ? null
          : formState.adjustPriceBase,
        defaultAdjustPriceGap: dataSource?.defaultAdjustPriceGap,
        userAdjustPriceGap: isNil(formState.adjustPriceGap)
          ? null
          : formState.adjustPriceGap,
      };
      save({
        ext: {
          data: newData,
          range: {
            dow: batchConfig?.schedule,
            startDate: batchConfig?.dateRange[0],
            endDate: batchConfig?.dateRange[1],
          },
        },
      }).then(() => {
        doFetch({
          ext: {
            data: {
              takeofftime,
              flightNo: flightNos[0],
              route: routes[0] || "",
            },
          },
        });
      });
      setOpen(true);
      resetForm();
      setReason(null);
      setRefresh(true);
    });

    const onSearch = useRefFunc((v: RORecord) => {
      if (v) {
        console.log("onSearch: ", v);
        setQueryStatus(true);
        setTimeout(() => {
          doFetch({ ext: { data: { ...v } } });
        }, 500);
      }
      resetForm();
      setReason(null);
    });

    const handleCancel = useRefFunc(() => {
      setOpen(false);
      resetForm();
      setReason(null);
      setTakeofftime(takeoffdate);
      setRoutes([route ?? ""]);
      setFlightNO([flightNo ?? ""]);
    });

    return (
      <Modal
        open={open}
        onCancel={handleCancel}
        width={1024}
        style={{ top: 30 }}
        footer={[
          <Row justify="end" style={{ marginTop: 10 }} key="footer">
            <Col>
              <Button size="small" onClick={handleCancel}>
                {getSharkText("key.cancel.button")}
              </Button>
              <Button
                size="small"
                style={{ marginLeft: 10 }}
                type="primary"
                onClick={handleSave}
              >
                {getSharkText("config_page_apply")}
              </Button>
            </Col>
          </Row>,
        ]}
        destroyOnClose
      >
        <div id="cabin-adjustment-parameter_container">
          <div>
            <h2
              style={{
                color: "var(--main)",
                fontWeight: "bold",
                marginLeft: "60px",
              }}
            >
              调舱参数设置
            </h2>
            <SearchArea
              takeoffdate={takeoffdate}
              onChange={onSearch}
              setAreaTakeoffDate={setTakeofftime}
              routes={routes}
              setRoutes={setRoutes}
              flightNO={flightNos}
              setFlightNO={setFlightNO}
              maxDate={maxDate}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              zIndex: 1000,
              overflow: "hidden",
            }}
          >
            <div>
              <Anchor
                style={{
                  marginLeft: "75px",
                  marginTop: 40,
                }}
                getContainer={() =>
                  document.getElementById(
                    "cabin-adjustment-parameter_content"
                  ) as HTMLElement
                }
                targetOffset={60}
                className="CabinAdjustmentParameter-anchorModule"
              >
                <Link href="#final-seat-occupancy" title="最终目标客座率" />
                <Link href="#team-water" title="团队水分" />
                <Link href="#Price-floor" title="调价价格底限" />
                <Link href="#price-change" title="单次调价幅度" />
                <Link href="#set-range" title="设置应用范围" />
              </Anchor>
            </div>
            <div
              id="cabin-adjustment-parameter_content"
              style={{
                marginLeft: "45px",
                marginTop: "0px",
                overflow: "scroll",
                flex: "1 1 auto",
                paddingRight: 30,
              }}
            >
              <div id="final-seat-occupancy">
                <h3 className="character-style">最终目标客座率</h3>
                <div className="shadow-border">
                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">
                      当前值：
                      {resultTargetLFFinal !== "--"
                        ? round(resultTargetLFFinal, 1)
                        : resultTargetLFFinal}
                    </span>
                    <span>{resultTargetLFFinal !== "--" ? "%" : ""}</span>
                  </Row>

                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">修改值：</span>
                    <Col>
                      <InputNumber
                        value={formState.value}
                        size="small"
                        min={0}
                        max={100}
                        step={1}
                        style={{
                          borderColor: formState.isValueInvalid
                            ? "red"
                            : "#d9d9d9",
                        }}
                        onChange={handleValueChange}
                      />
                    </Col>
                    <span>%</span>
                  </Row>

                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">
                      {getSharkText("config_page_modify_reason")}：
                    </span>
                    <Col span={16}>
                      <Select
                        style={{ width: 200 }}
                        size="small"
                        options={reasons}
                        value={reason}
                        allowClear
                        onChange={setReason}
                      ></Select>
                    </Col>
                  </Row>
                </div>
              </div>
              <div id="team-water">
                <h3 className="character-style">团队水分</h3>
                <div className="shadow-border">
                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">
                      当前值：
                      {resultTeamMargin !== "--"
                        ? round(resultTeamMargin, 1)
                        : resultTeamMargin}
                    </span>
                    <span>{resultTeamMargin !== "--" ? "%" : ""}</span>
                  </Row>
                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">修改值：</span>
                    <Col>
                      <InputNumber
                        value={formState.teamMargin}
                        size="small"
                        min={0}
                        max={100}
                        step={1}
                        onChange={handleTeamMarginChange}
                        style={{
                          borderColor: formState.isTeamMarginInvalid
                            ? "red"
                            : "#d9d9d9",
                        }}
                      />
                    </Col>
                    <span>%</span>
                  </Row>
                </div>
              </div>
              <div id="Price-floor">
                <h3 className="character-style">调价价格底限</h3>
                <div className="shadow-border">
                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">
                      当前值：
                      {resultPriceBase}
                    </span>
                  </Row>
                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">修改值：</span>
                    <Col>
                      <InputNumber
                        value={formState.adjustPriceBase}
                        size="small"
                        onChange={handleAdjustPriceBaseChange}
                        style={{
                          borderColor: formState.isPriceBaseInvalid
                            ? "red"
                            : "#d9d9d9",
                        }}
                      />
                    </Col>
                    <span>元</span>
                  </Row>
                </div>
              </div>
              <div id="price-change">
                <h3 className="character-style">单次调价幅度</h3>
                <div className="shadow-border">
                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">
                      当前值：
                      {resultPriceGap}
                    </span>
                  </Row>
                  <Row gutter={[1, 20]} className="row-style">
                    <span className="margin-left-span">修改值：</span>
                    <Col>
                      <InputNumber
                        value={formState.adjustPriceGap}
                        size="small"
                        onChange={handleAdjustPriceGapChange}
                        style={{
                          borderColor: formState.isPriceGapInvalid
                            ? "red"
                            : "#d9d9d9",
                        }}
                      />
                    </Col>
                    <span>元</span>
                  </Row>
                </div>
              </div>
              <div id="set-range">
                <h3 className="character-style">设置应用范围</h3>
                <div className="shadow-border">
                  <Row gutter={[1, 20]} className="div-style">
                    <span className="margin-left-span">起飞日期：</span>
                    <Col>
                      <ParaBatchGenerate
                        ref={paraBatchGenerateRef}
                        takeoffdate={takeofftime}
                        maxDate={maxDate}
                        config={batchConfig}
                        setConfig={setBatchConfig}
                      />
                    </Col>
                  </Row>
                  <Row gutter={[1, 20]} className="div-style">
                    <span className="margin-left-span">航班号：</span>
                    <Col>
                      <input
                        className="input-style-one"
                        disabled={true}
                        placeholder={flightNos[0]}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[1, 20]} className="div-style">
                    <span className="margin-left-span">航段：</span>
                    <Col>
                      <input
                        className="input-style-two"
                        disabled={true}
                        placeholder={routes[0]}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);
export default CabinAdjustmentParameter;
