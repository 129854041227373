import React, { ReactNode, useMemo, useState } from "react";
import { InfoCircleOutlined, SwapOutlined } from "@ant-design/icons";
import { Card, Empty, Popover } from "antd";
import { CardItemData } from "Interface";
import useRefFunc from "Utils/useRefFunc";
import { getCardGroupKey } from "Components/Card/Cards";
import CardItemValue from "./CardItemValue";
import { getSharkText } from "Utils/i18nGlobal";

export interface CardItemDataEx extends CardItemData {
  valueType: "num" | "percentage";
  precision: number;
  collectTime: string;
}

export interface ICardItemProps {
  title?: ReactNode;
  data: CardItemDataEx[];
  comparePrefix: string;
}

const CardItem: React.FC<ICardItemProps> = (props: ICardItemProps) => {
  const { title = "unknown", data, comparePrefix } = props;
  const [currentCardIdx, setCurrentCardIdx] = useState<number>(() => {
    if (Array.isArray(data) && data.length > 1) {
      const key = getCardGroupKey(data);
      const selected = localStorage.getItem(key);
      if (selected !== null) {
        return Number(selected);
      }
    }
    return 0;
  });
  const item: CardItemDataEx | undefined = useMemo(() => {
    return Array.isArray(data) ? data[currentCardIdx] : data;
  }, [currentCardIdx, data]);
  const { cardCode, cardHint } = item || {};

  const switchInfo = useMemo(() => {
    if (Array.isArray(data) && data.length) {
      return (
        <div>
          <span style={{ fontSize: "90%", color: "#aaa" }}>
            {getSharkText("card.switch_card.button")}
          </span>
          {data.map((d) =>
            d.cardCode === cardCode ? (
              <div key={d.cardCode}>
                <strong>{d.cardName}</strong>
              </div>
            ) : (
              <div style={{ color: "#aaa" }} key={d.cardCode}>
                {d.cardName}
              </div>
            )
          )}
        </div>
      );
    }
  }, [cardCode, data]);

  const switchCard = useRefFunc((e: any) => {
    if (Array.isArray(data)) {
      const nextIdx = (currentCardIdx + 1) % data.length;
      const key = getCardGroupKey(data);
      localStorage.setItem(key, nextIdx.toString());
      setCurrentCardIdx(nextIdx);
      // 需要阻止事件冒泡, 防止卡片点击事件被触发
      e.stopPropagation();
    }
  });

  return (
    <Card
      id={cardCode}
      size="small"
      bordered={true}
      className={`card cur`}
      hoverable={true}
      style={{ height: 165 }}
    >
      <div className="card-title">
        <div className="title">{title}</div>
        <div>
          {Array.isArray(data) && data.length > 1 ? (
            <Popover content={switchInfo}>
              <SwapOutlined onClick={switchCard} style={{ marginRight: 10 }} />
            </Popover>
          ) : undefined}
          <Popover content={<span>{cardHint}</span>}>
            {cardHint && <InfoCircleOutlined />}
          </Popover>
        </div>
      </div>
      <div style={{ color: "#999" }}>更新时间: {data[0]?.collectTime}</div>
      {!data.length ? (
        <Empty />
      ) : (
        <CardItemValue
          currentCardIdx={currentCardIdx}
          data={data}
          comparePrefix={comparePrefix}
        />
      )}
    </Card>
  );
};

export default CardItem;
