import { ICardConfig, ICardConfigList } from "Components/Card/CardsInterface";
import { CardItemData, ECompareTypeV2, INumberLabelOption } from "Interface";
import { getComparedPercentage, getCompareVal, showNum, U } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

const COMPARE_TO_MARKET_TEXT = getSharkText("key.compare_to_market");
const MARKET_SHARE_TEXT = getSharkText("key.market_share.name");

const percentageFormatter = (card: CardItemData): INumberLabelOption => {
  return {
    value: card.cardValue,
    label: showNum(card.cardValue, "percentage"),
  };
};

const bigNumberFormatter = (value: number) => {
  let label = showNum(value);
  if (Math.abs(value) > 10 * 1000 * 1000) {
    label = U.abbreviateNumber(value) || "--";
  }
  return { value, label };
};

export const precisionConfig: ICardConfig = {
  main: {
    value: {
      formatter: (v) => ({ value: v, label: showNum(v, "num", 3) }),
    },
  },
  avg: {
    value: {
      formatter: (card) => ({
        value: card.avgValue,
        label: showNum(card.avgValue, "num", 3),
      }),
    },
  },
};

export const productionRateConfig: ICardConfig = {
  ...precisionConfig,
  trend: {
    name: {
      formatter: (v, card, compareType) => {
        if (compareType === ECompareTypeV2.MARKET_COMPARE) {
          return COMPARE_TO_MARKET_TEXT;
        }
        return v;
      },
    },
    value: {
      formatter: (v, card) => {
        const { cardValue, compareValue } = card;
        if (!cardValue || !compareValue) return { value: 0, label: "-" };
        const value = getComparedPercentage(cardValue, compareValue) / 100;
        return {
          value,
          label: showNum(value * 100, "percentage"),
        };
      },
    },
  },
};

export const CardsConfig: ICardConfigList = {
  wide_body: {
    main: {
      value: {
        formatter: (v, c) => percentageFormatter(c),
      },
    },
    trend: {
      name: {
        formatter: (v, card, compareType) => {
          if (compareType === ECompareTypeV2.MARKET_COMPARE) {
            return COMPARE_TO_MARKET_TEXT;
          }
          return v;
        },
      },
      value: {
        formatter: (v, card) => {
          const { cardValue, compareValue } = card;
          if (!cardValue || !compareValue) return { value: 0, label: "-" };
          const value = getComparedPercentage(cardValue, compareValue) / 100;
          return {
            value,
            label: showNum(value * 100, "percentage"),
          };
        },
      },
    },
    avg: {
      value: { formatter: percentageFormatter },
    },
  },
  passengers: {
    trend: {
      name: {
        formatter: (v, card, compareType) => {
          if (compareType === ECompareTypeV2.MARKET_COMPARE) {
            return COMPARE_TO_MARKET_TEXT;
          }
          return v;
        },
      },
      value: {
        formatter: (v, card, compareType) => {
          let value = v;
          if (compareType === ECompareTypeV2.MARKET_COMPARE) {
            const { cardValue, compareValue } = card;
            value = getCompareVal(cardValue, compareValue);
          }
          return {
            value,
            label: showNum(value * 100, "percentage"),
          };
        },
      },
    },
  },
  kilo_passenger_traffic: {
    main: {
      value: {
        formatter: (v, card) => bigNumberFormatter(card.cardValue),
      },
    },
    trend: {
      name: {
        formatter: (v, card, compareType) => {
          return compareType === ECompareTypeV2.MARKET_COMPARE
            ? MARKET_SHARE_TEXT
            : v;
        },
      },
      value: {
        formatter: (v, card, compareType) => {
          let value = v;
          const { cardValue, compareValue } = card;
          if (compareType === ECompareTypeV2.MARKET_COMPARE) {
            value = cardValue / compareValue;
          }
          return { value, label: showNum(value * 100, "percentage") };
        },
      },
    },
    avg: {
      value: { formatter: (card) => bigNumberFormatter(card.avgValue) },
    },
  },
  kilo_transport_capacity: {
    main: {
      value: { formatter: (v, card) => bigNumberFormatter(card.cardValue) },
    },
    trend: {
      name: {
        formatter: (v, card, compareType) => {
          return compareType === ECompareTypeV2.MARKET_COMPARE
            ? MARKET_SHARE_TEXT
            : v;
        },
      },
      value: {
        formatter: (v, card, compareType) => {
          let value = v;
          const { cardValue, compareValue } = card;
          if (compareType === ECompareTypeV2.MARKET_COMPARE) {
            value = cardValue / compareValue;
          }
          return { value, label: showNum(value * 100, "percentage") };
        },
      },
    },
    avg: {
      value: { formatter: (card) => bigNumberFormatter(card.avgValue) },
    },
  },
  capacity_production_cost: productionRateConfig,
  income_production_cost: productionRateConfig,
};
