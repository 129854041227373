import { DynamicPriceTable } from "@ctrip/flt-bi-flightai-airlines";
import { getSharkText } from "Utils/i18nGlobal";

export interface IRecord extends DynamicPriceTable {
  /**
   * 	唯一id
   */
  id: string;
  /**
   * 	发送时间
   */
  sendTime: string;
  /**
   * 	航线
   */
  route: string;
  /**
   * 	航班号
   */
  flightNo: string;
  /**
   * 	起飞时间
   */
  takeofftime: string;
  /**
   * 	建议价格
   */
  suggestPrice: number;
  /**
   * 	原始价格
   */
  rawPrice: number;
  /**
   * 	临近共飞价格
   */
  nearPrice: number;
  /**
   * 	航线最低共飞
   */
  routeMinPrice: number;
  /**
   * 	城市对最低竞飞
   */
  cityMinPrice: number;
  /**
   * 	建议仓位
   */
  suggestCabin: string;
  /**
   * 	原始仓位
   */
  rawCabin: string;
  /**
   * 	购买概率
   */
  buyProbability: number;
  /**
   * 	原始概率
   */
  oldProbability: number;
  /**
   * 	航班实际上座率进度
   */
  lf: number;
  /**
   * 	航线起飞客座率预测
   */
  lflines: number;
  /**
   * 	多个参考价格 例：[1.0,2.0,3.0]
   */
  referPrices: string;
  /**
   * 	类型COMPETE=共飞 MONOPOLY=独飞
   */
  flightType: string;
  /**
   * 	临近航线均价
   */
  racePriceAvgSame: string;
  /**
   * 	批次=1为第一批
   */
  rnk: number;
  /**
   * 	反馈 0=未选择 1=接受 2=拒绝
   */
  feedback: number;
  /**
   * 	拒绝原因
   */
  cause: string;
  /**
   * 数据的状态 获取数据后由前端计算
   */
  feStatus?: IFeedbackStatusCode;
  /**
   * 表格行是否被选中, 前端用
   */
  checked?: boolean;
  /**
   * 前端表格使用, 由前端生成
   */
  key?: number;
  // takeoffdate: string;
}

export interface ICabinPrice {
  aport: string;
  d: string;
  dport: string;
  price: number;
  subclass: string;
  takeoffdate: string;
}

export interface IOnNextReturnMsg {
  0: string;
  1: string;
  2: string;
  3: string;
}

export const onNextReturnMsg: IOnNextReturnMsg = {
  //  正常返回
  0: "",
  1: getSharkText("config_page_current_record_abnormal"),
  2: getSharkText("config_page_already_last_one"),
  3: getSharkText("config_page_already_first_one"),
};

export type IOnNextReturnCode = keyof IOnNextReturnMsg;

export interface IFeedbackStatus {
  label: string;
  color: string;
}

export const statusNameMap: IStatusNameMap = {
  0: {
    label: getSharkText("config_page_price_change"),
    color: "#d85311",
  },
  1: { label: getSharkText("config_page_processed"), color: "#0769c3" },
  2: { label: getSharkText("config_page_expired"), color: "#666666" },
  3: {
    label: getSharkText("config_page_original_price"),
    color: "#666666",
  },
};

export interface IStatusNameMap {
  0: IFeedbackStatus;
  1: IFeedbackStatus;
  2: IFeedbackStatus;
  3: IFeedbackStatus;
}

export type IFeedbackStatusCode = keyof IStatusNameMap;

export interface IStatus {
  label: string;
  value: IFeedbackStatusCode;
  cssClass?: string;
  count?: number | "--";
}

export const defaultStatusList: IStatus[] = [
  {
    label: getSharkText("price_adjustment_suggestion"),
    value: 0,
    cssClass: "new-checkbox",
  },
  {
    label: getSharkText("config_page_original_price_suggestion"),
    value: 3,
    cssClass: "expired-checkbox",
  },
  {
    label: getSharkText("config_page_processed_suggestion"),
    value: 1,
  },
  {
    label: getSharkText("config_page_expired_suggestion"),
    value: 2,
    cssClass: "expired-checkbox",
  },
];

export enum EFlightType {
  /**
   * 竞飞
   */
  COMPETE = "COMPETE",
  /**
   * 独飞
   */
  MONOPOLY = "MONOPOLY",
}

/**
 * 视角,不传默认为0 0=当前起飞日 销售日期从远道近 1=所有起飞日
 */
export type TView = 0 | 1;

export interface IRecordTrend {
  id: string;
  suggestPrice: number;
  rawPrice: number;
  feedback: number;
  cause: string;
  route: string;
  flightNo: string;
  takeoffdate: string | null;
  takeofftime: string;
  feTkDate?: string;
}

export const UserReason = {
  "1": getSharkText("config_page_group_guests_protect_flight_seat_growth"),
  "2": getSharkText(
    "config_page_special_activities_holidays_expected_increase"
  ),
  "3": getSharkText("config_page_exist_refund_lower_expectation"),
  "4": getSharkText("config_page_epidemic_adverse_factors_market_downturn"),
};
