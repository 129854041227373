import { Moment } from "@react-awesome-query-builder/core";
import CheckButtonsWithAll from "Components/CheckButtons/CheckButtonsWithAll";
import { DATE_FORMAT, WEEK_OPTIONS } from "Constants";
import {
  DynamicFarePriceOptMode,
  FlightDynamicFarePriceType,
  FlightFarePriceSetType,
  IValueLabelOption,
} from "Interface";
import { getDowOfDateRange } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import {
  Col,
  Input,
  Radio,
  Row,
  Select,
  DatePicker,
  Button,
  message,
} from "antd";
import { cloneDeep, isNumber } from "lodash";
import moment from "moment";
import React, { Key, ReactElement, useMemo, useState } from "react";
import { submitValidate } from "./common";
import ubtUtils from "Utils/ubtUtils";

const { RangePicker } = DatePicker;

const OPTIONS = [
  {
    label: "运价发布",
    value: DynamicFarePriceOptMode.PUBLISH,
  },
  {
    label: "运价关闭",
    value: DynamicFarePriceOptMode.DRAWBACK,
  },
];

export interface PriceSetProps {
  onSubmit: (v: FlightFarePriceSetType) => void;
  data: FlightDynamicFarePriceType;
}

/**
 * 动态运价-设置组件
 */
const PriceSet = (props: PriceSetProps): ReactElement => {
  const { data, onSubmit } = props;
  const [optMode, setOptMode] = useState<DynamicFarePriceOptMode>(
    DynamicFarePriceOptMode.PUBLISH
  );
  const cabinList = useMemo(() => {
    return (data.priceList || [])
      .filter((f) => !!f.cabinType)
      .map((item) => item.cabinType) as string[];
  }, [data.priceList]);
  const [cabinType, setCabinType] = useState<string>(cabinList[0] || "");
  const [price, setPrice] = useState<string>("");
  const [dateRange, setDateRange] = useState<[Moment, Moment]>([
    moment(data.takeoffdate),
    moment(data.takeoffdate),
  ]);
  const [dow, setDow] = useState<Key[]>(() => {
    return [moment(data.takeoffdate).weekday()];
  });

  const changeDow = useRefFunc((v: Key[]) => {
    const newDow = cloneDeep(v);
    newDow.sort();
    setDow(newDow);
  });

  const cabinOptions = useMemo((): IValueLabelOption[] => {
    const cabins = cabinList.map((item) => {
      return { value: item, label: item };
    });
    if (optMode === DynamicFarePriceOptMode.DRAWBACK) {
      cabins.unshift({ value: "", label: "全部" });
    }
    return cabins;
  }, [cabinList, optMode]);

  const allowDow = useMemo(() => {
    if (!dateRange || dateRange.filter((f) => !!f).length !== 2) {
      return undefined;
    }
    const days = moment(dateRange[1]).diff(dateRange[0], "days");
    if (days > 7) {
      return undefined;
    }
    const tmpDow = getDowOfDateRange(dateRange as [Moment, Moment]);
    return tmpDow;
  }, [dateRange]);

  const handleRangeChange = useRefFunc((v: [Moment, Moment]) => {
    setDateRange(v);
    if (v?.filter((f) => !!f).length === 2) {
      const newDow = getDowOfDateRange(v as [Moment, Moment]);
      if (newDow.length > 0) {
        changeDow(newDow);
      }
    }
  });

  const handleSubmit = useRefFunc(() => {
    const { errorMsg, param } = submitValidate({
      data,
      cabinType,
      price,
      optMode,
      dow,
      dateRange,
    });
    if (errorMsg) {
      message.error(errorMsg);
    } else if (param) {
      onSubmit(param);
    } else {
      message.error("未知错误");
      ubtUtils.error("submitDynamicFarePriceFailed", undefined, undefined, 2);
    }
  });

  return (
    <div style={{ padding: "15px 0" }}>
      <div style={{ marginBottom: 15 }}>
        <Radio.Group
          options={OPTIONS}
          optionType="button"
          buttonStyle="solid"
          value={optMode}
          onChange={(e) => setOptMode(e.target.value)}
          size="small"
        ></Radio.Group>
      </div>
      <div>
        <Row gutter={[20, 20]}>
          <Col>
            <Select
              options={cabinOptions}
              value={cabinType}
              onChange={setCabinType}
              size="small"
              style={{ width: 80 }}
            ></Select>
          </Col>
          <Col>
            <Input
              type="number"
              size="small"
              value={price}
              style={{ width: 100 }}
              allowClear
              placeholder="运价"
              onChange={(e) => setPrice(e.target.value)}
            ></Input>
          </Col>
          <Col>
            <RangePicker
              value={dateRange}
              onChange={(v) => handleRangeChange(v as [Moment, Moment])}
              size="small"
            ></RangePicker>
          </Col>
          <Col>
            <CheckButtonsWithAll
              allowValues={allowDow}
              options={WEEK_OPTIONS}
              value={dow}
              onChange={changeDow}
              allStyle={{
                width: 18,
                height: 26,
                fontSize: "10px",
                lineHeight: "13px",
              }}
            ></CheckButtonsWithAll>
          </Col>
        </Row>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={handleSubmit} type="primary" size="small">
          提交
        </Button>
      </div>
    </div>
  );
};
PriceSet.displayName = "PriceSet";
export default PriceSet;
