import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Popover, Statistic, Typography, Divider } from "antd";
import Trend from "./Trend";
import { CardItemData, SystemType, ECompareTypeV2 } from "Interface";
import _ from "lodash";
import {
  isHideDigits,
  calculateCardsContrastVal,
  getCardTrendText,
  showNum,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";

interface CardItemProps {
  data: CardItemData;
  selectedCode: string;
  setCurCard: (card: CardItemData) => void;
  compareType?: ECompareTypeV2;
  systemType?: SystemType;
}

const { Text } = Typography;

const CardItem: React.FC<CardItemProps> = (props: CardItemProps) => {
  const { data, setCurCard, selectedCode, systemType, compareType } = props;
  const { cardCode, cardName, cardValue, cardHint, avgValue, compareValue } =
    data;

  const isPercentVal = cardCode === "order_index";
  const isAirlinesMarketComparison = systemType === 2 && compareType === 1;
  const calculateVal = calculateCardsContrastVal(
    cardValue,
    compareValue,
    compareType,
    systemType,
    cardCode
  );
  const isMarketComparison =
    cardCode === "passenger_traffic" ||
    cardCode === "transport_capacity" ||
    cardCode === "flight_sorties";
  const isShowEmpty = cardValue === 0;
  const isShowCardFlag = (): boolean => {
    if (!_.isFinite(calculateVal) || calculateVal === 0 || isShowEmpty) {
      return false;
    } else {
      return isAirlinesMarketComparison ? !isMarketComparison : true;
    }
  };

  const handleSelectCard = () => {
    setCurCard(data);
  };

  return (
    <Card
      id={cardCode}
      size="small"
      bordered={false}
      className={`card ${selectedCode === cardCode ? "cur" : ""}`}
      hoverable={true}
      onClick={handleSelectCard}
    >
      <div className="card-title">
        <div className="title">{cardName}</div>
        <Popover content={<span>{cardHint}</span>}>
          {cardHint && <InfoCircleOutlined />}
        </Popover>
      </div>
      {isShowEmpty ? (
        <div className="ant-statistic-content">
          <span className="ant-statistic-content-value">
            <span className="ant-statistic-content-value-int">-</span>
          </span>
        </div>
      ) : (
        <Statistic
          groupSeparator=","
          precision={isPercentVal ? 1 : 0}
          value={showNum(cardValue, isPercentVal ? "percentage" : "num")}
        />
      )}
      {cardCode !== "order_index" ? (
        <>
          <Trend
            flag={compareValue < cardValue ? "up" : "down"}
            showFlag={isShowCardFlag()}
          >
            <Text className="card-text">
              {getCardTrendText(cardCode, systemType, compareType)}&nbsp;
              {isShowEmpty ? (
                "-"
              ) : (
                <ValueNum
                  isShowZero={false}
                  type="percentage"
                  value={calculateVal}
                />
              )}
            </Text>
          </Trend>
          <Divider style={{ margin: "6px 0" }} />
          <Text className="card-text">
            {getSharkText("card.daily_average")}&nbsp;
            <ValueNum
              isShowZero={false}
              roundNum={isHideDigits(cardCode) ? -1 : 0}
              type={isPercentVal ? "percentage" : "num"}
              value={avgValue}
            />
          </Text>
        </>
      ) : undefined}
    </Card>
  );
};

export default CardItem;
