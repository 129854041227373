import { useTranslation } from "react-i18next";
import useRefFunc from "./useRefFunc";

/**
 *  Component description
 */
const useI18Next = (): ((key: string) => string) => {
  const { t } = useTranslation(["ns1"]);
  const translate = useRefFunc((key: string) => {
    return t<string>(key);
  });
  return translate;
};
export default useI18Next;
