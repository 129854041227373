import React, { useEffect } from "react";
import { Radio, Tooltip } from "antd";
import { FLIGHT_CLASS } from "Constants";
import useGlobal from "Store";
import { RadioChangeEvent } from "antd/lib/radio";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface Props {
  flightClassName?: string[];
  intl?: boolean;
}
/**
 * @returns 境内外选择器
 */
const FlightClassCom: React.FC<Props> = ({
  flightClassName = FLIGHT_CLASS,
  intl = false,
}) => {
  const [globalState, actions] = useGlobal();
  const { queryCondition, airlinesQueryCondition, helpVisibility, systemType } =
    globalState;
  const { setQueryCondition, setAirlinesQueryCondition } = actions;
  const isAirport = isAirportMode(systemType);

  useEffect(() => {
    if (intl) {
      if (isAirport) {
        setQueryCondition({
          ...queryCondition,
          flightClass: 2,
        });
      } else {
        setAirlinesQueryCondition({
          ...airlinesQueryCondition,
          flightClass: 2,
        });
      }
    }
  }, [intl]);

  const handleChangeFlightClass = (e: RadioChangeEvent) => {
    if (isAirport) {
      setQueryCondition({
        ...queryCondition,
        flightClass: e.target.value,
      });
    } else {
      setAirlinesQueryCondition({
        ...airlinesQueryCondition,
        flightClass: e.target.value,
      });
    }
  };

  return intl ? null : (
    <Tooltip
      placement="bottom"
      open={helpVisibility}
      title={getSharkText("key.change_data_range")}
      getPopupContainer={() => document.getElementById("main") || document.body}
    >
      <Radio.Group
        id="toolbarFlightClass"
        optionType="button"
        buttonStyle="solid"
        value={
          isAirport
            ? queryCondition.flightClass
            : airlinesQueryCondition.flightClass
        }
        onChange={handleChangeFlightClass}
      >
        {flightClassName.map((item, idx) => (
          <Radio.Button key={idx} value={idx}>
            {item}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Tooltip>
  );
};

export default FlightClassCom;
