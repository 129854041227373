import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useFetch, useSessionStorage } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AREA_DOMESTIC } from "Constants";
import { Area, CompareAirport } from "Interface";
import _ from "lodash";

interface IProps {
  setAirportList: (airportList: CompareAirport[]) => void;
  airportList: CompareAirport[];
  permissionCode: string;
}

interface Airports {
  areaType: number;
  areaCode: string;
  areaName: string;
}

const { Option } = Select;

const SelectAirports: React.FC<IProps> = (props: IProps) => {
  const { setAirportList, airportList, permissionCode } = props;

  const [areaData, setAreaData] = useSessionStorage(AREA_DOMESTIC, []);
  const [airports, setAirports] = useState<Airports[]>([]);
  const [{ data }, doFetch] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "",
      chartTableCode: "",
    },
    ext: {
      type: 1,
    },
    lazey: true,
  });

  useEffect(() => {
    if (_.isEmpty(areaData)) {
      doFetch({
        ext: {
          type: 1,
        },
      });
    } else {
      const airportArr: Airports[] = [];
      const findAllairports = (areaArr: Area[]) => {
        for (const area of areaArr) {
          if (
            area.areaType === 5 &&
            !permissionCode.split(",").includes(area.areaCode)
          ) {
            airportArr.push(area);
          } else if (area.children) {
            findAllairports(area.children);
          }
        }
      };
      findAllairports(areaData);
      setAirports(airportArr);
    }
  }, [areaData]);

  useEffect(() => {
    if (data.length > 0) {
      setAreaData(data);
    }
  }, [data]);

  const handleChange = (arr: string[], option: any) => {
    const list = option.reduce(
      (accumulator: CompareAirport[], current: any, idx: number) => {
        accumulator.push({
          airport: current.props.value,
          airportName: current.props.title,
          maskName: "对标机场" + (idx + 1),
        });
        return accumulator;
      },
      []
    );
    setAirportList(list);
  };

  const filterOption = (input: string, option: any) =>
    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.title.includes(input);

  const airportListCode = airportList.reduce(
    (arr: any[], current: CompareAirport) => {
      arr.push(current.airport);
      return arr;
    },
    []
  );

  return (
    <Select
      showSearch
      style={{ width: 330 }}
      placeholder={getSharkText("config_page_select_airport_code")}
      filterOption={filterOption}
      onChange={handleChange}
      value={airportListCode}
      mode="multiple"
    >
      {airports.map((airport) => (
        <Option
          key={airport.areaCode}
          title={airport.areaName}
          value={airport.areaCode}
        >
          {airport.areaName}
          {airport.areaCode}
        </Option>
      ))}
    </Select>
  );
};

export default SelectAirports;
