import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import {
  Dimension,
  DimensionSchemaEx,
  Filter,
  Measure,
  MeasureSchemaEx,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AIRLINE_NAME,
  APORT_NAME,
  ARR_CITYNAME_NAME,
  ARR_CONTINENTNAME_NAME,
  ARR_COUNTRYNAME_NAME,
  ARR_PROVINCENAME_NAME,
  ARR_REGIONNAME_NAME,
  BRIDGE_RATIO_NAME,
  customCols,
  DATASET_DA_ID,
  DATASET_ID,
  DEP_CITYNAME_NAME,
  DEP_CONTINENTNAME_NAME,
  DEP_COUNTRYCLASS_NAME,
  DEP_COUNTRYNAME_NAME,
  DEP_PROVINCENAME_NAME,
  DEP_REGIONNAME_NAME,
  DPORT_NAME,
  FLIGHTSTATE_NAME,
  ONTIME_RATIO_NAME,
  TKZONE,
} from "../AirportRanking/DataSetColumns";
import { Card, Col, Empty, Radio, Row, Spin } from "antd";
import { AggCode, Area, FlightClass, ISeries } from "Interface";
import useGlobalState from "Store";
import { MetricSchema } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/schema";
import {
  FDDatasetCol2DimensionSchemaEx,
  FDDatasetCol2MeasureSchemaEx,
} from "Page/AI/FreeDashboard/common";
import BarCharts from "Components/BarLineChartsV2/charts";
import { groupBy } from "lodash";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";

const titleText = getSharkText("key.routes_performance");

interface ResData {
  [TKZONE]: string;
  day: string;
  m_exp_ontime_ratio: number | null;
  m_exp_bridge_ratio: number | null;
}

const tabValues = ["cancel", "new", "execute"] as const;
type TabType = (typeof tabValues)[number];

const tabs: Array<{ label: string; value: TabType }> = [
  {
    label: getSharkText("key.temp_cancelled.name"),
    value: "cancel",
  },
  {
    label: getSharkText("key.temp_added.name"),
    value: "new",
  },
  {
    label: getSharkText("key.execution_rate.flightplan"),
    value: "execute",
  },
];

const internal: Record<number, { dep: string; arr: string }> = {
  1: { dep: DEP_CONTINENTNAME_NAME, arr: ARR_CONTINENTNAME_NAME },
  2: { dep: DEP_COUNTRYCLASS_NAME, arr: ARR_COUNTRYNAME_NAME },
  3: { dep: DEP_PROVINCENAME_NAME, arr: ARR_PROVINCENAME_NAME },
  4: { dep: DEP_CITYNAME_NAME, arr: ARR_CITYNAME_NAME },
  5: { dep: DPORT_NAME, arr: APORT_NAME },
};

const inland: Record<number, { dep: string; arr: string }> = {
  1: { dep: DEP_CONTINENTNAME_NAME, arr: ARR_CONTINENTNAME_NAME },
  2: { dep: DEP_REGIONNAME_NAME, arr: ARR_REGIONNAME_NAME },
  3: { dep: DEP_PROVINCENAME_NAME, arr: ARR_PROVINCENAME_NAME },
  4: { dep: DEP_CITYNAME_NAME, arr: ARR_CITYNAME_NAME },
  5: { dep: DPORT_NAME, arr: APORT_NAME },
};

const AreaColumnMap: Record<
  FlightClass,
  Record<number, { dep: string; arr: string }>
> = {
  [FlightClass.All]: internal,
  [FlightClass.Domestic]: inland,
  [FlightClass.Foreign]: internal,
  [FlightClass.Overseas]: internal,
};

interface FilterGenerator {
  depart: 0 | 1;
  arrive: 0 | 1;
  flightClass: FlightClass;
  startDate: string;
  endDate: string;
  /** 页面顶部的全局机场 */
  port: Area;
  /** 选中的机场 */
  area?: Area;
  airline: string;
}

export const getFilters = (
  param: FilterGenerator
): [Filter[], StandardFilter[]] => {
  const {
    depart,
    arrive,
    flightClass,
    startDate,
    endDate,
    port,
    area,
    airline,
  } = param;
  const filters: Filter[] = [];
  let oriFilters: StandardFilter[] = [];
  if (airline) {
    filters.push({
      columnName: AIRLINE_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: airline.split(","),
        },
      ],
    });
  }
  if (flightClass === FlightClass.Domestic) {
    filters.push({
      columnName: DEP_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
    filters.push({
      columnName: ARR_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
  } else if (flightClass === FlightClass.Foreign) {
    oriFilters.push({
      or: {
        filters: [
          {
            and: {
              filters: [
                {
                  in: {
                    field: `dimension.${DEP_COUNTRYNAME_NAME}`,
                    values: ["中国"],
                  },
                },
                {
                  not: {
                    filter: {
                      in: {
                        field: `dimension.${ARR_COUNTRYNAME_NAME}`,
                        values: ["中国"],
                      },
                    },
                  },
                },
              ],
            },
          },
          {
            and: {
              filters: [
                {
                  in: {
                    field: `dimension.${ARR_COUNTRYNAME_NAME}`,
                    values: ["中国"],
                  },
                },
                {
                  not: {
                    filter: {
                      in: {
                        field: `dimension.${DEP_COUNTRYNAME_NAME}`,
                        values: ["中国"],
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    });
    filters.push({
      columnName: DEP_COUNTRYCLASS_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
    filters.push({
      columnName: ARR_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
  }
  const daArr: StandardFilter[] = [
    {
      in: {
        field: `dimension.${AreaColumnMap[flightClass][port.areaType].dep}`,
        values: [port.areaType === 5 ? port.areaCode : port.areaName],
      },
    },
    ...(area?.areaCode
      ? [
          {
            in: {
              field: `dimension.${
                AreaColumnMap[flightClass][area.areaType].arr
              }`,
              values: [area.areaType === 5 ? area.areaCode : area.areaName],
            },
          },
        ]
      : []),
  ];
  const adArr: StandardFilter[] = [
    ...(area?.areaCode
      ? [
          {
            in: {
              field: `dimension.${
                AreaColumnMap[flightClass][area.areaType].dep
              }`,
              values: [area.areaType === 5 ? area.areaCode : area.areaName],
            },
          },
        ]
      : []),
    {
      in: {
        field: `dimension.${AreaColumnMap[flightClass][port.areaType].arr}`,
        values: [port.areaType === 5 ? port.areaCode : port.areaName],
      },
    },
  ];
  if (depart && arrive) {
    oriFilters.push({
      or: {
        filters: [
          {
            and: { filters: daArr },
          },
          {
            and: { filters: adArr },
          },
        ],
      },
    });
  } else if (depart) {
    oriFilters = oriFilters.concat(daArr);
  } else if (arrive) {
    oriFilters = oriFilters.concat(adArr);
  }
  if (startDate && endDate) {
    oriFilters.push({
      range: {
        field: `dimension.${TKZONE}`,
        strRange: { lower: startDate, upper: endDate },
      },
    });
  }
  // if (depart && !arrive) {
  //   filters.push({
  //     columnName: DA_TYPE_NAME,
  //     filterConfig: [
  //       {
  //         calculate: "include",
  //         argsType: "string",
  //         stringArgs: ["da"],
  //       },
  //     ],
  //   });
  // } else if (!depart && arrive) {
  //   filters.push({
  //     columnName: DA_TYPE_NAME,
  //     filterConfig: [
  //       {
  //         calculate: "include",
  //         argsType: "string",
  //         stringArgs: ["ad"],
  //       },
  //     ],
  //   });
  // }
  oriFilters.push({
    not: {
      filter: {
        in: {
          field: `dimension.${FLIGHTSTATE_NAME}`,
          values: ["取消"],
        },
      },
    },
  });
  return [filters, oriFilters];
};

export interface AirportChartProps {
  area?: Area;
  airline: string;
}

/** 航班计划-正点率图表 */
const AirportChart = (props: AirportChartProps): ReactElement => {
  const { area, airline } = props;
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const {
    departure,
    arrive,
    flightClass,
    startDate,
    endDate,
    originalAirport,
  } = queryCondition;
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const [resData, setResData] = useState<ResData[]>([]);
  const [aggCode, setAggCode] = useState<AggCode>(AggCode.day);
  const services = useServices();
  const datasetId = departure && arrive ? DATASET_DA_ID : DATASET_ID;
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_DA_ID)
      .then(
        (r) => {
          // @ts-ignore
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{ isLoading, error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "airportChartD",
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const rows = res.rows;
      const headers = res.headers;
      const source = DataRow2ListMap(rows, headers);
      setResData(source as unknown as ResData[]);
    },
  });

  const [{ isLoading: loadingDa, error: errorDa }, doFetchDA] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    debugId: "airportChartA",
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const rows = res.rows;
      const headers = res.headers;
      const source = DataRow2ListMap(rows, headers);
      setResData(source as unknown as ResData[]);
    },
  });

  const columns = useMemo(() => {
    return datasetCols.concat(customCols);
  }, [datasetCols]);

  const dimensions = useMemo(() => {
    const tmp: Dimension[] = [
      {
        columnName: TKZONE,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ];
    return tmp;
  }, []);
  const measures: Measure[] = useMemo(
    () => [
      {
        id: ONTIME_RATIO_NAME,
        columnName: ONTIME_RATIO_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: BRIDGE_RATIO_NAME,
        columnName: BRIDGE_RATIO_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
    ],
    []
  );
  const sorters: Sorter[] = useMemo(() => {
    const tmp: Sorter[] = [
      {
        chartUsedColId: TKZONE,
        columnName: TKZONE,
        sorter: "ASC",
        statistical: "SUM",
      },
    ];
    return tmp;
  }, []);

  const refetch = useCallback(() => {
    if (!datasetCols.length || !originalAirport) {
      return;
    }
    const [dFilters, dOriFilters] = getFilters({
      depart: departure,
      arrive: 0,
      flightClass,
      startDate,
      endDate,
      area,
      airline,
      port: originalAirport,
    });
    const [aFilters, aOriFilters] = getFilters({
      depart: 0,
      arrive: 1,
      flightClass,
      startDate,
      endDate,
      area,
      airline,
      port: originalAirport,
    });
    const reqList = [];
    if (departure && arrive) {
      const [filters, oriFilters] = getFilters({
        depart: departure,
        arrive,
        flightClass,
        startDate,
        endDate,
        area,
        airline,
        port: originalAirport,
      });
      const requestBuild = new RequestBuilder({
        datasetId,
        columns,
        dimensions,
        measures,
        chartFilters: filters,
        sorters,
        containerFilters: [],
        oriFilters,
        limit: 10,
        granularity:
          aggCode === AggCode.day
            ? "1d"
            : aggCode === AggCode.week
            ? "1W"
            : "1M",
      });
      const { encrypted } = requestBuild.getRequestBody();
      reqList.push(
        doFetch({
          debugId: "airportChartDA",
          ext: {
            datasetId,
            colIds: [],
            req: encrypted,
          },
        })
      );
    } else if (departure) {
      const requestBuild = new RequestBuilder({
        datasetId,
        columns,
        dimensions,
        measures,
        chartFilters: dFilters,
        sorters,
        containerFilters: [],
        oriFilters: dOriFilters,
        limit: 10,
        granularity:
          aggCode === AggCode.day
            ? "1d"
            : aggCode === AggCode.week
            ? "1W"
            : "1M",
      });
      const { encrypted } = requestBuild.getRequestBody();
      reqList.push(
        doFetch({
          ext: {
            datasetId,
            colIds: [],
            req: encrypted,
          },
        })
      );
    } else if (arrive) {
      const requestBuild = new RequestBuilder({
        datasetId,
        columns,
        dimensions,
        measures,
        chartFilters: aFilters,
        sorters,
        containerFilters: [],
        oriFilters: aOriFilters,
        limit: 10,
        granularity:
          aggCode === AggCode.day
            ? "1d"
            : aggCode === AggCode.week
            ? "1W"
            : "1M",
      });
      const { encrypted } = requestBuild.getRequestBody();
      reqList.push(
        doFetchDA({
          ext: {
            datasetId,
            colIds: [],
            req: encrypted,
          },
        })
      );
    }
    Promise.all(reqList).then((values) => {
      let allRows: any[] = [];
      let headers: any = [];
      values.map((r: any) => {
        if (r && r.data) {
          const res = JSON.parse(r.data);
          if (res.status === 40000) {
            throw new Error("40000");
          }
          const rows = res.rows;
          allRows = allRows.concat(rows);
          headers = res.headers;
        }
      });
      const source = DataRow2ListMap(allRows, headers);
      // console.log("source:L ", source);
      const grouped = groupBy(source, TKZONE);
      const tmpRes: ResData[] = [];
      Object.values(grouped).forEach((vs) => {
        const takeoffdate = vs[0][TKZONE] as string;
        tmpRes.push({
          [TKZONE]: takeoffdate,
          day: moment(takeoffdate).format(DATE_FORMAT),
          // m_exp_ontime_ratio:
          //   sumT.sumADelayNotNull + sumT.sumTDelayNotNull
          //     ? 1 -
          //       (sumT.sumADelay + sumT.sumTDelay) /
          //         (sumT.sumADelayNotNull + sumT.sumTDelayNotNull)
          //     : null,
          // m_exp_bridge_ratio:
          //   sumT.sumANotNull + sumT.sumDNotNull
          //     ? (sumT.sumACnt + sumT.sumDCnt)
          //       (sumT.sumANotNull + sumT.sumDNotNull)
          //     : null,
          m_exp_ontime_ratio: vs[0].m_exp_ontime_rate as number,
          m_exp_bridge_ratio: vs[0].m_exp_bridge_rate as number,
        });
      });
      setResData(tmpRes);
    });
  }, [
    aggCode,
    airline,
    area,
    arrive,
    columns,
    datasetCols.length,
    datasetId,
    departure,
    dimensions,
    doFetch,
    doFetchDA,
    endDate,
    flightClass,
    measures,
    originalAirport,
    sorters,
    startDate,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const dimCols = useMemo(() => {
    return columns.filter((c) => c.kind === 1);
  }, [columns]);

  const meaCols = useMemo(() => {
    return columns.filter((c) => c.kind === 0);
  }, [columns]);

  const schema = useMemo(() => {
    const tmp: MetricSchema<DimensionSchemaEx, MeasureSchemaEx> = {
      name: datasetId.toString(),
      version: "0.0.0",
      dimensions: dimCols.map((d) => FDDatasetCol2DimensionSchemaEx(d)),
      measures: meaCols.map((m) => FDDatasetCol2MeasureSchemaEx(m)),
    };
    return tmp;
  }, [datasetId, dimCols, meaCols]);

  const height = 452;

  const showMeasure = useMemo(() => {
    const tmp: Measure[] = [
      {
        id: ONTIME_RATIO_NAME,
        columnName: ONTIME_RATIO_NAME,
        measureConfig: {
          name: "正点率",
          statisticalConfig: { method: "SUM" },
          formatConfig: {
            type: "percent",
            precision: 2,
          },
          comparison: null,
        },
      },
      {
        id: BRIDGE_RATIO_NAME,
        columnName: BRIDGE_RATIO_NAME,
        measureConfig: {
          name: "靠桥率",
          statisticalConfig: { method: "SUM" },
          formatConfig: {
            type: "percent",
            precision: 2,
          },
          comparison: null,
        },
      },
    ];
    return tmp;
  }, []);

  const series = useMemo(() => {
    const s: ISeries[] = [
      {
        name: getSharkText("config_page_ontime_rate"),
        type: "line",
        encode: {
          x: "day",
          y: "m_exp_ontime_ratio",
        },
        symbol: null,
        isPercentVal: true,
      },
      {
        name: getSharkText("config_page_bridge_rate"),
        type: "line",
        encode: {
          x: "day",
          y: "m_exp_bridge_ratio",
        },
        symbol: null,
        isPercentVal: true,
      },
    ];
    return s;
  }, []);

  if (isLoading) {
    return <Spin />;
  }
  if (!datasetCols.length) {
    return <Empty />;
  }
  return (
    // <ChartView
    //   chartId="on-time-chart"
    //   chartType="line"
    //   dataSource={resData}
    //   dimensions={dimensions}
    //   measures={showMeasure}
    //   scheme={schema}
    //   isEdit={false}
    // />
    <Card className="barline-charts">
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={(e) => setAggCode(e.target.value)}
            id="chartsAggCode"
          >
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
      <BarCharts
        height={452}
        data={resData}
        defaultSeries={series}
        loading={false}
        aggCode={AggCode.day}
        startDate={startDate}
        endDate={endDate}
        isDemo={false}
        useDataset={true}
        need100Times
        precision={2}
      />
    </Card>
  );
};
export default AirportChart;
