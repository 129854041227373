import { IDownloadHeader, IValueLabelOption, SystemType } from "Interface";
import { showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * 根据每个标签的枚举名, 生成表头数据
 * @param tagNames 标签的枚举名, 比如男,女
 * @param companyName 公司名, 比如上海/虹桥, 东方航空等
 * @returns [["一行一列","一行二列"], ["二行一列","二行二列"]]
 */
const generateHeaderData = (
  tagNames: IValueLabelOption[],
  companyName: string,
  systemType: SystemType
): string[][] => {
  const headerAirlines = Array.from(
    { length: 4 * tagNames.length + 1 },
    (v, idx: number) => {
      if (idx === 0) {
        return "航线/整体";
      }
      if (idx <= tagNames.length) {
        return companyName;
      }
      if (idx <= tagNames.length * 2) {
        return `${companyName} - ${getSharkText("config_page_whole_industry")}`;
      }
      if (idx <= tagNames.length * 3) {
        return `${companyName}：${getSharkText("config_page_sales_price")}`;
      }
      if (idx <= tagNames.length * 4) {
        return `${getSharkText(
          "config_page_sales_price_difference"
        )}(${companyName} - ${getSharkText("config_page_whole_industry")})`;
      }
      console.warn(getSharkText("config_page_abnormal_column_generation"));
      return "--";
    }
  );
  const headerAirport = Array.from(
    { length: 2 * tagNames.length + 1 },
    (v, idx: number) => {
      if (idx === 0) {
        return "航线/整体";
      }
      if (idx <= tagNames.length) {
        return companyName;
      }
      if (idx <= tagNames.length * 2) {
        return `${companyName}：${getSharkText("config_page_sales_price")}`;
      }
      return "--";
    }
  );
  const groupCnt = systemType === SystemType.airport ? 2 : 4;
  const header2 = Array.from(
    { length: tagNames.length * groupCnt + 1 },
    (v, idx: number) => {
      if (idx === 0) {
        return getSharkText("config_page_route_whole_industry");
      }
      return tagNames[(idx - 1) % tagNames.length].label;
    }
  );
  return [
    systemType === SystemType.airport ? headerAirport : headerAirlines,
    header2,
  ];
};
/**
 * 根据枚举数量, 计算第一行表头需要进行的单元格合并配置
 * @param nameLength 枚举数量
 */
const computeMergeCells = (nameLength: number, systemType: SystemType) => {
  return Array.from(
    { length: systemType === SystemType.airport ? 3 : 5 },
    (v, idx: number) => {
      if (idx === 0) {
        // 合并左上角航线/整体
        return { s: { r: 0, c: 0 }, e: { r: 1, c: 0 } };
      } else {
        // 合并第一行的机场名/行业总体等
        return {
          s: { r: 0, c: (idx - 1) * nameLength + 1 },
          e: { r: 0, c: idx * nameLength },
        };
      }
    }
  );
};
/**
 * 生成列配置
 * @param tagNames 标签value和label
 */
const generateColumns = (
  tagNames: IValueLabelOption[],
  systemType: SystemType
) => {
  const dimColumns: IDownloadHeader[] = [
    {
      title: getSharkText("flightClass.all.name"),
      dataIndex: "name",
      render: (v: string) => (!v ? getSharkText("flightClass.all.name") : v),
    },
  ];
  const valueColumns: IDownloadHeader[] = tagNames.map((t) => {
    return {
      title: t.label,
      dataIndex: `${t.value}valuePer`,
      downloadFormatter: (v: number) => showRawNum(v * 100, "percentage"),
    };
  });
  const valueDiffColumns: IDownloadHeader[] =
    systemType === SystemType.airport
      ? []
      : tagNames.map((t) => {
          return {
            title: `${t.label}MarketValue`,
            dataIndex: `${t.value}marketValuePer`,
            downloadFormatter: (v: number, record: any) =>
              showRawNum(
                (record[`${t.value}valuePer`] - v) * 100,
                "percentage"
              ),
          };
        });
  const priceColumns: IDownloadHeader[] = tagNames.map((t) => {
    return {
      title: `${t.label}price`,
      dataIndex: `${t.value}price`,
      downloadFormatter: (v: number) => showRawNum(v, "num"),
    };
  });
  const priceDiffColumns: IDownloadHeader[] =
    systemType === SystemType.airport
      ? []
      : tagNames.map((t) => {
          return {
            title: `${t.label}MarketPrice`,
            dataIndex: `${t.value}marketPrice`,
            downloadFormatter: (v: number, record: any) =>
              showRawNum(record[`${t.value}price`] - v, "num"),
          };
        });
  const columns = dimColumns
    .concat(valueColumns)
    .concat(valueDiffColumns)
    .concat(priceColumns)
    .concat(priceDiffColumns);
  return columns;
};

/**
 * 获取sheets的配置
 * @param companyName 公司名,机场/航司
 * @returns
 */
export const getConfig = (companyName: string, systemType: SystemType) => {
  return [
    {
      tagCode: "gender",
      tagName: getSharkText("custom_profile.page.gender"),
      config: (() => {
        const tagNames = [
          {
            label: getSharkText("custom_profile.page.gender.male"),
            value: "1",
          },
          {
            label: getSharkText("custom_profile.page.gender.female"),
            value: "2",
          },
        ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
    {
      tagCode: "age",
      tagName: getSharkText("custom_profile.page.age"),
      config: (() => {
        const tagNames = [
          { label: "0-2", value: "1" },
          { label: "3-12", value: "2" },
          { label: "13-17", value: "3" },
          { label: "18-24", value: "4" },
          { label: "25-34", value: "5" },
          { label: "35-44", value: "6" },
          { label: "45-54", value: "7" },
          { label: "55-79", value: "8" },
          { label: "80+", value: "9" },
        ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
    {
      tagCode: "distribution_class",
      tagName: getSharkText("custom_profile.page.passenger_distribution"),
      config: (() => {
        const tagNames = [
          {
            label: getSharkText("custom_profile.page.solo"),
            value: "1",
          },
          {
            label: getSharkText("custom_profile.page.with_children"),
            value: "2",
          },
          {
            label: getSharkText("custom_profile.page.with_elderly"),
            value: "3",
          },
          {
            label: getSharkText("custom_profile.page.with_children_elderly"),
            value: "4",
          },
          {
            label: getSharkText("custom_profile.page.other_people"),
            value: "5",
          },
        ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
    // {
    //   tagCode: "frequency_class",
    //   tagName: getSharkText("custom_profile.page.travel_frequency"),
    //   config: (() => {
    //     const tagNames = [
    //       { label: "0-1", value: "1" },
    //       { label: "2-3", value: "2" },
    //       { label: "4-7", value: "3" },
    //       { label: "8-11", value: "4" },
    //       { label: "12+", value: "5" },
    //     ];
    //     return {
    //       data: generateHeaderData(tagNames, companyName),
    //       mergeCells: computeMergeCells(tagNames.length),
    //       columns: generateColumns(tagNames),
    //       tagNames,
    //     };
    //   })(),
    // },
    {
      tagCode: "dayofweek",

      tagName: getSharkText("custom_profile.page.travel_time"),
      config: (() => {
        const tagNames = [
          { label: getSharkText("date_mon"), value: "1" },
          { label: getSharkText("date_tue"), value: "2" },
          { label: getSharkText("date_wed"), value: "3" },
          { label: getSharkText("date_thu"), value: "4" },
          { label: getSharkText("date_fri"), value: "5" },
          { label: getSharkText("date_sat"), value: "6" },
          { label: getSharkText("date_sun"), value: "7" },
        ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
    {
      tagCode: "order_aheadday",
      tagName: getSharkText("custom_profile.page.orderday_before"),
      config: (() => {
        const tagNames = [
          { label: getSharkText("config_page_0_3_days"), value: "1" },
          { label: getSharkText("config_page_4_7_days"), value: "2" },
          { label: getSharkText("config_page_8_14_days"), value: "3" },
          { label: getSharkText("config_page_15_30_days"), value: "4" },
          { label: getSharkText("config_page_31_60_days"), value: "5" },
          {
            label: getSharkText("custom_profile.page.orderday_before.60days"),
            value: "6",
          },
        ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
    {
      tagCode: "uid_price_sensitive",
      tagName: getSharkText("custom_profile.page.price_sensitivity"),
      config: (() => {
        const tagNames = [
          {
            label: getSharkText("custom_profile.page.price_sensitivity.high"),
            value: "1",
          },
          {
            label: getSharkText("custom_profile.page.price_sensitivity.medium"),
            value: "2",
          },
          {
            label: getSharkText("custom_profile.page.price_sensitivity.low"),
            value: "3",
          },
        ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
    {
      tagCode: "purpose",
      tagName: getSharkText("custom_profile.page.travel_purpose"),
      config: (() => {
        const tagNames = [
          {
            label: getSharkText("custom_profile.page.Leisure"),
            value: "1",
          },
          {
            label: getSharkText("custom_profile.page.Business"),
            value: "2",
          },
          {
            label: getSharkText("custom_profile.page.Return_to_Hometown"),
            value: "3",
          },
          { label: getSharkText("system_type_Others"), value: "4" },
        ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
    {
      tagCode: "airline",
      tagName: getSharkText("custom_profile.page.airline_preference"),
      config: (() => {
        const tagNames =
          systemType === SystemType.airport
            ? [
                {
                  label: getSharkText("custom_profile.page.airline.three_big"),
                  value: "1",
                },
                {
                  label: getSharkText("custom_profile.page.airline.category1"),
                  value: "2",
                },
                {
                  label: getSharkText("custom_profile.page.airline.category2"),
                  value: "3",
                },
                {
                  label: getSharkText("system_type_Others"),
                  value: "4",
                },
              ]
            : [
                {
                  label: getSharkText("key.user_airline.checkbox"),
                  value: "1",
                },
                {
                  label: getSharkText("custom_profile.page.airline.three_big"),
                  value: "2",
                },
                {
                  label: getSharkText("custom_profile.page.airline.category1"),
                  value: "3",
                },
                {
                  label: getSharkText("custom_profile.page.airline.category2"),
                  value: "4",
                },
                {
                  label: getSharkText("system_type_Others"),
                  value: "5",
                },
              ];
        return {
          data: generateHeaderData(tagNames, companyName, systemType),
          mergeCells: computeMergeCells(tagNames.length, systemType),
          columns: generateColumns(tagNames, systemType),
          tagNames,
        };
      })(),
    },
  ];
};
