import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Col, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { AirlinesQueryExt, Area, Direction, FlightArea } from "Interface";
import SelectMultiFlight from "Components/SelectMultiFlight";
import { getModuleNameFromPath, getModuleStatus } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _ from "lodash";
import { DEFAULT_AREA, EMPTY_ARRAY } from "Constants";
import { useLocation } from "react-router-dom";
import SelectRT from "Components/SelectRT/SelectRT";
import useRefFunc from "Utils/useRefFunc";

interface SelectedProps {
  airlinesQueryExt?: AirlinesQueryExt;
  setAirlinesQueryExt: (ext: AirlinesQueryExt) => void;
  /**
   * 是否支持航段/航班切换, 默认支持
   */
  changeTypeEnable?: boolean;
  /**
   * 航段选择的范围是到机场还是城市, 默认为机场
   */
  type?: "airport" | "city";
  /**
   * 是否启用查询按钮, 如果启用, 则值变化仅在点击查询按钮时生效, 不启用则每次值变化立即生效. 默认启用
   */
  submitControl?: boolean;
  /**
   * 是否启用航段方向分隔符控制, 如果不启用, 则航段标识左边出发右边到达. 如果启用, 则根据全局queryCondition中的depart arrive决定分隔符的方向
   */
  useDAFlag?: boolean;
  style?: CSSProperties;
  /**
   * 是否启用国内区域, 仅当server/url都未设置时生效
   */
  useDomesticArea?: boolean;
  /**
   * 是否启用出发到达对调功能, 默认不启用
   */
  useExchange?: boolean;
}

const SelectedAirlines: React.FC<SelectedProps> = (props: SelectedProps) => {
  const {
    airlinesQueryExt,
    setAirlinesQueryExt,
    changeTypeEnable = true,
    type = "airport",
    submitControl = true,
    style,
    useDomesticArea,
    useExchange = false,
  } = props;
  const location = useLocation();
  // 航司版切换搜索航线0或航班1查询
  const [filterType, setFilterType] = useState<1 | 2>(1);
  // 航司版出发城市
  const [departArea, setDepartArea] = useState<Area>(DEFAULT_AREA);
  // 航司版到达城市
  const [arriveArea, setArriveArea] = useState<Area>(DEFAULT_AREA);
  // 航司版航班号
  const [flightNO, setFlightNO] = useState<string[]>([]);
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, userInfo, systemType, queryCondition } =
    globalState;
  const { departure, arrive } = queryCondition;
  const { pathname } = location;
  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const isAirlinesFilterType = filterType === 1;
  const isEmptyFilter = isAirlinesFilterType
    ? departArea.areaType === 0 && arriveArea.areaType === 0
    : _.isEmpty(flightNO);

  useEffect(() => {
    if (!airlinesQueryExt) {
      return;
    }
    setDepartArea(airlinesQueryExt.departArea);
    setArriveArea(airlinesQueryExt.arriveArea);
    if (airlinesQueryExt.filterType) {
      setFilterType(airlinesQueryExt.filterType !== 2 ? 1 : 2);
    }
    if (airlinesQueryExt.flightNO) {
      setFlightNO(airlinesQueryExt.flightNO);
    }
  }, [airlinesQueryExt]);

  const setValue = useCallback(() => {
    if (changeTypeEnable) {
      setAirlinesQueryExt({
        departArea: isAirlinesFilterType ? departArea : DEFAULT_AREA,
        arriveArea: isAirlinesFilterType ? arriveArea : DEFAULT_AREA,
        filterType: isEmptyFilter ? 0 : filterType,
        flightNO: isAirlinesFilterType ? EMPTY_ARRAY : flightNO,
      });
    } else {
      setAirlinesQueryExt({
        departArea,
        arriveArea,
      });
    }
  }, [
    arriveArea,
    changeTypeEnable,
    departArea,
    filterType,
    flightNO,
    isAirlinesFilterType,
    isEmptyFilter,
    setAirlinesQueryExt,
  ]);

  // #region 应用选中值
  // 如果submitControl启用, 则仅在点击查询时, 应用值, 否则, 每次值改变时都应用值
  useEffect(() => {
    if (submitControl) {
      return;
    }
    setValue();
  }, [setValue, submitControl]);
  // #endregion

  const handleChangeMode = (e: RadioChangeEvent) => {
    setFilterType(e.target.value);
  };

  const handleClick = () => {
    setValue();
  };

  const routeDirection: Direction = useMemo(() => {
    const tStr = departure ? "T" : "";
    const rStr = arrive ? "R" : "";
    return (rStr + tStr || "T") as Direction;
  }, [departure, arrive]);

  const handleRouteChange = useRefFunc((v: FlightArea) => {
    setDepartArea(v.departArea);
    setArriveArea(v.arriveArea);
  });

  const innerValue = useMemo(() => {
    return {
      departArea,
      arriveArea,
    } as FlightArea;
  }, [arriveArea, departArea]);

  return (
    <Row
      id="selectedAirlines"
      align="middle"
      gutter={16}
      style={{ marginTop: 16, ...style }}
    >
      {changeTypeEnable ? (
        <Col id="selectedAirlinesTab">
          <Radio.Group
            onChange={handleChangeMode}
            value={filterType !== 2 ? 1 : 2}
            buttonStyle="solid"
          >
            <Radio.Button value={1}>
              {getSharkText("key.route_query")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.flight_query")}
            </Radio.Button>
          </Radio.Group>
        </Col>
      ) : undefined}
      {!isAirlinesFilterType ? (
        <Col id="selectedAirlinesFlightNo">
          <SelectMultiFlight
            airlinesCode={airlinesQueryCondition.airlines}
            value={flightNO}
            onChange={setFlightNO}
            isDemo={isDemo}
          />
        </Col>
      ) : (
        <>
          <Col>
            <SelectRT
              value={innerValue}
              onChange={handleRouteChange}
              useDomesticArea={useDomesticArea}
              type={type}
              directionType={routeDirection}
              useExchange={useExchange}
            />
            {/* <Row>
              <Col id="selectedAirlinesDepart">
                <CascaderArea
                  setSelectedArea={setDepartArea}
                  flightClass={flightClass === 1 ? 1 : 0}
                  type={type}
                  isDemo={isDemo}
                  useDomesticArea={useDomesticArea}
                />
              </Col>
              <Col style={{ padding: "0 5px" }}>
                {useDAFlag ? (
                  <>
                    {departure && arrive ? (
                      <SwapOutlined />
                    ) : departure ? (
                      <SwapRightOutlined />
                    ) : (
                      <SwapLeftOutlined />
                    )}
                  </>
                ) : (
                  <SwapRightOutlined />
                )}
              </Col>
              <Col id="selectedAirlinesArrive">
                <CascaderArea
                  setSelectedArea={setArriveArea}
                  flightClass={flightClass === 1 ? 1 : 0}
                  type={type}
                  isDemo={isDemo}
                  useDomesticArea={useDomesticArea}
                />
              </Col>
            </Row> */}
          </Col>
        </>
      )}
      {submitControl ? (
        <Col>
          <Button
            id="selectedAirlinesBtn"
            disabled={!!isDemo}
            type="primary"
            onClick={handleClick}
          >
            {getSharkText("key.query.button")}
          </Button>
        </Col>
      ) : undefined}
    </Row>
  );
};

export default SelectedAirlines;
