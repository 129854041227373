import CheckButtons from "Components/CheckButtons/CheckButtons";
import CheckButtonsWithAll from "Components/CheckButtons/CheckButtonsWithAll";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { DATE_FORMAT, WEEK, WEEK_OPTIONS } from "Constants";
import { IKeyLabelOption, IValueLabelOption, RangeObject } from "Interface";
import { getDowOfDateRange } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { Col, Row, Switch, message } from "antd";
import { cloneDeep } from "lodash";
import moment, { Moment } from "moment";
import React, {
  Key,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

export interface BatchConfig {
  dateRange: [string, string];
  schedule: string;
}

export interface BatchGenerateProps {
  takeoffdate: string;
  maxDate: Moment;
  isBatchPage: boolean;
}
export interface BatchGenerateRef {
  getBatchConfig: () => { isBatch: boolean; config: BatchConfig | null };
}

/**
 * 批量调舱组件
 */
const BatchGenerate = forwardRef(
  (props: BatchGenerateProps, ref: Ref<BatchGenerateRef>): ReactElement => {
    const { takeoffdate, isBatchPage, maxDate } = props;
    const [schedule, setSchedule] = useState<Key[]>(
      // WEEK_OPTIONS.map((a) => a.value)
      getDowOfDateRange([moment(takeoffdate), moment(takeoffdate)])
    );
    const [isBatch, setIsBatch] = useState(false);
    const [dateRange, setDateRange] = useState<RangeObject>([
      // moment().add(0, "day"),
      moment(takeoffdate),
      moment(takeoffdate).add(0, "day"),
    ]);

    useEffect(() => {
      setDateRange([moment(takeoffdate), moment(takeoffdate).add(0, "day")]);
      setSchedule(
        getDowOfDateRange([moment(takeoffdate), moment(takeoffdate)])
      );
    }, [takeoffdate]);

    useImperativeHandle(ref, () => ({
      getBatchConfig: () => {
        let config: BatchConfig | null = null;
        if (!isBatch) {
          return { isBatch: false, config: null };
        }
        if (!dateRange || dateRange.filter((f) => !!f).length !== 2) {
          message.error("请选择日期范围");
          return { isBatch: true, config: null };
        }
        const [start, end] = dateRange as [Moment, Moment];
        if (
          start.startOf("day").isAfter(moment(takeoffdate)) ||
          end.startOf("day").isBefore(moment(takeoffdate))
        ) {
          message.error("日期范围必须包含起飞日期");
          return { isBatch: true, config: null };
        }
        if (schedule.length === 0) {
          message.error("请选择星期");
          return { isBatch: true, config: null };
        }
        return {
          isBatch: true,
          config: {
            dateRange: [start.format(DATE_FORMAT), end.format(DATE_FORMAT)],
            schedule: schedule.map((a) => Number(a) + 1).join(""),
          },
        };
      },
    }));

    const allowDow = useMemo(() => {
      if (!dateRange || dateRange.filter((f) => !!f).length !== 2) {
        return undefined;
      }
      const days = moment(dateRange[1]).diff(dateRange[0], "days");
      if (days > 7) {
        return undefined;
      }
      const dow = getDowOfDateRange(dateRange as [Moment, Moment]);
      return dow;
    }, [dateRange]);

    const handleDateRangeChange = useRefFunc((v: RangeObject) => {
      setDateRange(v);
      if (v?.filter((f) => !!f).length === 2) {
        const dow = getDowOfDateRange(v as [Moment, Moment]);
        if (dow.length > 0) {
          setSchedule(dow);
        }
      }
    });

    return (
      <Row gutter={[20, 20]} style={{ lineHeight: "30px", height: 34 }}>
        <Col>
          <Switch
            checked={isBatch}
            checkedChildren="批量"
            unCheckedChildren="批量"
            onChange={setIsBatch}
            style={{ width: 70 }}
          ></Switch>
        </Col>
        {isBatch ? (
          <>
            <Col>
              <DateRangePickerV2
                value={dateRange}
                onChange={handleDateRangeChange}
                otherProps={{
                  size: "small",
                  disabledDate: (date) => {
                    return date.isAfter(maxDate);
                  },
                }}
              />
            </Col>
            <Col>
              <CheckButtonsWithAll
                allowValues={allowDow}
                options={WEEK_OPTIONS}
                value={schedule}
                onChange={setSchedule}
              ></CheckButtonsWithAll>
            </Col>
          </>
        ) : undefined}
      </Row>
    );
  }
);
BatchGenerate.displayName = "BatchGenerate";
export default BatchGenerate;
