import React, { useCallback, useEffect, useMemo, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Empty,
  message,
  Popover,
  Radio,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import useGlobal from "Store";
import { FlightArea, IDownloadHeader, Tab } from "Interface";
import _, { cloneDeep, merge, round, uniqBy } from "lodash";
import {
  getComparePercentageVal,
  getCompareVal,
  getProportionPercentageVal,
  showNum,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import Refetch from "Components/Refetch";
import "./LossRanking.css";
import { getServer } from "Service/server";
import { ILossRankingData } from "../PassengerAnalysisCommon";
import { COMPARE_TYPE_PER_NAME, EMPTY_ARRAY } from "Constants";
import useRefFunc from "Utils/useRefFunc";
import {
  data2SheetData,
  downloadSheetData,
  getDownloadColumnHeader,
} from "Utils/downloadXLSX";
import DownloadBtn from "../../../../Components/DownloadBtn";

const { Option } = Select;

interface IProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  selectItem: (item: FlightArea | null) => void;
  ext?: any;
}
/** 统计类型. type: 0=值统计, 1=总计百分比, 2=行百分比,3=列百分比 */
const statisticsTabs: Tab[] = [
  {
    title: getSharkText("key.est_num_of_ppl.text"),
    chartTableCode: "AP0801",
    type: 0,
  },
  {
    title: getSharkText("key.proportion_of_ttl"),
    chartTableCode: "AP0801",
    type: 1,
  },
  {
    title: getSharkText("key.proportion_of_row_ttl"),
    chartTableCode: "AP0801",
    type: 2,
  },
  {
    title: getSharkText("key.proportion_of_column_ttl"),
    chartTableCode: "AP0801",
    type: 3,
  },
];

const tableHeaderDimWidth = 100;
const valueColumnWidth = 80;

const defaultColumns: IDownloadHeader[] = [
  {
    title: getSharkText("key.source_of_psgr.text"),
    dataIndex: "cityName",
    width: tableHeaderDimWidth,
    fixed: true,
  },
];

const defaultTabs: Tab[] = [
  {
    title: getSharkText("key.value.text"),
    chartTableCode: "AP0801",
    type: 1,
  },
];

const defaultTotal = {};

const LossRanking: React.FC<IProps> = (props: IProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    selectItem,
    ext: otherExt,
  } = props;
  const [statisticsTab, setStatisticsTab] = useState<Tab>();
  const [currentPer, setCurrentPer] = useState<Tab | null>(null);
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const { compareType, airport, airportName } = queryCondition;
  const [currentCell, setCurrentCell] = useState<any>(null);
  const [allTotal, setAllTotal] = useState<number>(0);
  const [rowTotal, setRowTotal] = useState<any>(defaultTotal);
  const [columnTotal, setColumnTotal] = useState<any>(defaultTotal);
  const [compareAllTotal, setCompareAllTotal] = useState<number>(0);
  const [compareRowTotal, setCompareRowTotal] = useState<any>(defaultTotal);
  const [compareColumnTotal, setCompareColumnTotal] =
    useState<any>(defaultTotal);

  const isPercentVal = currentPer?.isPercentVal || statisticsTab?.type !== 0;

  /** 值视图切换1=数值,2=同/环比 */
  const valueTabs = useMemo(() => {
    return defaultTabs.concat({
      title: COMPARE_TYPE_PER_NAME[compareType],
      chartTableCode: "AP0801",
      type: 2,
      isPercentVal: true,
    });
  }, [compareType]);

  // #region 请求数据
  const queryParam = useMemo(
    () => ({
      server: getServer(systemType),
      url: queryUrl,
      head: {
        moduleCode,
        chartTableCode: statisticsTabs[0].chartTableCode,
      },
      query: queryCondition,
      ext: {
        type: 0,
        orderSort: 0,
        ...otherExt,
      },
      lazey: true,
    }),
    [moduleCode, otherExt, queryCondition, queryUrl, systemType]
  );

  const [{ data, isLoading, error }, doFetch] = useFetch(queryParam);

  const queryParamCur = useMemo(() => {
    return merge({}, queryParam, { ext: { type: 1 } });
  }, [queryParam]);

  const [{ data: dataCurPort, isLoading: isLoadingCur }, curDoFetch] =
    useFetch(queryParamCur);

  const refetch = useCallback(() => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode: statisticsTabs[0].chartTableCode,
      },
      query: queryCondition,
      ext: {
        type: 0,
        compare: statisticsTabs[0].compare,
        ...otherExt,
      },
    });
  }, [doFetch, moduleCode, otherExt, queryCondition]);

  const refetchCur = useCallback(() => {
    curDoFetch({
      query: queryCondition,
      ext: {
        type: 1,
        compare: statisticsTabs[0].compare,
        ...otherExt,
      },
    });
  }, [curDoFetch, otherExt, queryCondition]);

  useEffect(() => {
    setStatisticsTab(statisticsTabs[0]);
    setCurrentPer(valueTabs[0]);
  }, [valueTabs]);

  useEffect(() => {
    if (selectItem) {
      selectItem(null);
    }
    refetch();
    refetchCur();
  }, [refetch, refetchCur, selectItem]);
  // #endregion

  // #region 转换数据
  const dataMixed: ILossRankingData[] = useMemo(() => {
    if (isLoading || isLoadingCur || !data) {
      return undefined;
    }
    return data.concat(dataCurPort);
  }, [data, dataCurPort, isLoading, isLoadingCur]);

  const airports: any[] = useMemo(() => {
    if (!data || !data.length) {
      return EMPTY_ARRAY;
    }
    const tmp = [
      { airport, airportName: airportName.split("\\").pop() },
    ].concat(uniqBy(data, (d: any) => d.airport));
    return tmp;
  }, [airport, airportName, data]);

  // 数据需要转换格式后才能被表格显示
  const transData = useMemo(() => {
    const rst: any[] = [];
    if (!statisticsTab || !dataMixed || !airports) {
      return rst;
    }
    const dataCP = cloneDeep(dataMixed);
    const cities = uniqBy(dataMixed, (d: any) => d.cityId);
    let tmpAllTotal = 0;
    const tmpRowTotal: any = {};
    const tmpColumnTotal: any = {};
    let tmpCompareAllTotal = 0;
    const tmpCompareRowTotal: any = {};
    const tmpCompareColumnTotal: any = {};
    cities.forEach((cityObj: any, index: number) => {
      const cityId = cityObj.cityId;
      const row: any = { key: index };
      airports.forEach((airportObj: any) => {
        const port: string = airportObj.airport;
        const cellIdx: number = dataCP.findIndex(
          (d: any) => d.cityId === cityId && d.airport === port
        );
        if (cellIdx >= 0) {
          const cell = dataCP.splice(cellIdx, 1)[0];
          if (!row.cityName) {
            row.cityName = cell.cityName;
            row.cityId = cell.cityId;
          }
          row[port] = round(cell.value);
          row[`${port}_compare`] = round(cell.compareValue);
          // 计算总和
          tmpAllTotal += round(cell.value);
          tmpCompareAllTotal += round(cell.compareValue);

          if (tmpRowTotal[cityId]) {
            tmpRowTotal[cityId] += round(cell.value);
          } else {
            tmpRowTotal[cityId] = round(cell.value);
          }
          if (tmpCompareRowTotal[cityId]) {
            tmpCompareRowTotal[cityId] += round(cell.compareValue);
          } else {
            tmpCompareRowTotal[cityId] = round(cell.compareValue);
          }
          if (tmpColumnTotal[port]) {
            tmpColumnTotal[port] += round(cell.value);
          } else {
            tmpColumnTotal[port] = round(cell.value);
          }
          if (tmpCompareColumnTotal[port]) {
            tmpCompareColumnTotal[port] += round(cell.compareValue);
          } else {
            tmpCompareColumnTotal[port] = round(cell.compareValue);
          }
          setAllTotal(tmpAllTotal);
          setRowTotal(tmpRowTotal);
          setColumnTotal(tmpColumnTotal);
          setCompareAllTotal(tmpCompareAllTotal);
          setCompareRowTotal(tmpCompareRowTotal);
          setCompareColumnTotal(tmpCompareColumnTotal);
        } else {
          row[port] = null;
        }
      });
      rst.push(row);
    });
    return rst;
  }, [airports, statisticsTab, dataMixed]);

  const getSummaryValue = useCallback(
    (cityId: number) => {
      let value;
      if (currentPer?.type === 1) {
        // 数值
        if (statisticsTab?.type === 0) {
          // 人次
          value = rowTotal[cityId];
        } else {
          // 各种比例
          value = allTotal
            ? getProportionPercentageVal(rowTotal[cityId], allTotal)
            : 0;
          value = showNum(value, "percentage");
        }
      } else {
        // 比例
        if (statisticsTab?.type === 0) {
          // 人次
          value = compareRowTotal[cityId]
            ? getComparePercentageVal(rowTotal[cityId], compareRowTotal[cityId])
            : 0;
          value = showNum(value, "percentage");
        } else {
          const curValue = allTotal
            ? getComparePercentageVal(rowTotal[cityId], allTotal)
            : 0;
          const compareV = compareAllTotal
            ? getComparePercentageVal(compareRowTotal[cityId], compareAllTotal)
            : 0;
          value = curValue - compareV;
          value = showNum(value, "percentage");
        }
      }
      return value;
    },
    [
      allTotal,
      compareAllTotal,
      compareRowTotal,
      currentPer?.type,
      statisticsTab?.type,
      rowTotal,
    ]
  );

  // #endregion

  const cellClick = useRefFunc((port: any, record: any) => {
    if (airport === port.airport) {
      message.warn(
        getSharkText("config_page_selected_airport_same_as_target_airport")
      );
      return;
    }
    const item: any = {
      airport: port.airport,
      airportName: port.airportName,
      cityId: record.cityId,
      cityName: record.cityName,
    };
    setCurrentCell(item);
  });

  // #region 列配置及表格属性
  /** 机场列 */
  const getAirportCellValue = useCallback(
    (val: any, record: any, port: any) => {
      let value = val;
      const compareValue = record[`${port.airport}_compare`];
      if (statisticsTab && statisticsTab.type !== 0) {
        // 行/列/总百分比
        let tmpTotal = 0;
        let tmpCompareTotal = 0;
        switch (statisticsTab.type) {
          case 1:
            tmpTotal = allTotal;
            tmpCompareTotal = compareAllTotal;
            break;
          case 2:
            tmpTotal = rowTotal[record.cityId];
            tmpCompareTotal = compareRowTotal[record.cityId];
            break;
          case 3:
            tmpTotal = columnTotal[port.airport];
            tmpCompareTotal = compareColumnTotal[port.airport];
            break;
        }
        value = tmpTotal ? val / tmpTotal : 0;
        if (currentPer?.type !== 1) {
          const comVal = tmpCompareTotal ? compareValue / tmpCompareTotal : 0;
          value = value - comVal;
        }
      } else if (
        statisticsTab &&
        statisticsTab.type === 0 &&
        currentPer &&
        currentPer.type === 2
      ) {
        value = compareValue ? getCompareVal(val, compareValue) : 0;
      }
      return value;
    },
    [
      allTotal,
      columnTotal,
      compareAllTotal,
      compareColumnTotal,
      compareRowTotal,
      currentPer,
      rowTotal,
      statisticsTab,
    ]
  );

  const renderCell = useCallback(
    (val: any, record: any, port: any) => {
      const value = getAirportCellValue(val, record, port);
      const cell = (
        <span
          className={
            currentCell &&
            record.cityId === currentCell.cityId &&
            port.airport === currentCell.airport
              ? "cur-cell"
              : ""
          }
          onClick={(e) => {
            cellClick(port, record);
          }}
        >
          {showNum(
            isPercentVal ? value * 100 : value,
            isPercentVal ? "percentage" : "num"
          )}
        </span>
      );
      return cell;
    },
    [getAirportCellValue, currentCell, isPercentVal, cellClick]
  );

  const airportsColumns: IDownloadHeader[] = useMemo(() => {
    return airports.map((port: any) => {
      return {
        title: port.airportName,
        dataIndex: port.airport,
        width: valueColumnWidth,
        render: (val: any, record: any) => {
          return renderCell(val, record, port);
        },
        downloadFormatter: (val: any, record: any) => {
          return getAirportCellValue(val, record, port);
        },
      };
    });
  }, [airports, getAirportCellValue, renderCell]);
  /** 统计列 */
  const summaryColumn: IDownloadHeader = useMemo(
    () => ({
      title: getSharkText("key.total"),
      dataIndex: "",
      width: valueColumnWidth,
      className: "passenger-column-summary",
      fixed: "right",
      render: (val: number, record: any) => getSummaryValue(record.cityId),
    }),
    [getSummaryValue]
  );
  const columns = useMemo(() => {
    return defaultColumns.concat(airportsColumns).concat(summaryColumn);
  }, [airportsColumns, summaryColumn]);
  const tableX =
    airportsColumns.length * valueColumnWidth + tableHeaderDimWidth;
  // #endregion

  useEffect(() => {
    if (!transData.length || airports.length < 2) {
      setCurrentCell(null);
    } else {
      setCurrentCell({
        cityId: transData[0].cityId,
        cityName: transData[0].cityName,
        airport: airports[1].airport,
        airportName: airports[1].airportName,
      });
    }
  }, [airports, transData]);

  useEffect(() => {
    selectItem(currentCell);
  }, [currentCell, selectItem]);

  const getSummaryRowCellValue = useRefFunc((col: IDownloadHeader) => {
    let value: string | number = getSharkText("key.total");
    if (col.dataIndex === "cityName") {
      value = getSharkText("key.total");
    } else if (col.dataIndex === "") {
      // 右下角总计
      if (currentPer?.type === 1) {
        // 数值
        if (statisticsTab?.type === 0) {
          // 估算人次
          value = allTotal;
        } else {
          value = "100%";
        }
      } else {
        // 同比
        if (statisticsTab?.type === 0) {
          value = compareAllTotal
            ? showNum(
                getComparePercentageVal(allTotal, compareAllTotal),
                "percentage"
              )
            : "--";
        } else {
          value = "0%";
        }
      }
    } else {
      if (currentPer?.type === 1) {
        // 数值
        if (statisticsTab?.type === 0) {
          // 人次
          value = columnTotal[col.dataIndex];
        } else {
          // 各种比例
          value = allTotal
            ? getProportionPercentageVal(columnTotal[col.dataIndex], allTotal)
            : 0;
          value = showNum(value, "percentage");
        }
      } else {
        // 同比
        if (statisticsTab?.type === 0) {
          // 人次
          value = compareColumnTotal[col.dataIndex]
            ? getComparePercentageVal(
                columnTotal[col.dataIndex],
                compareColumnTotal[col.dataIndex]
              )
            : 0;
          value = showNum(value, "percentage");
        } else {
          const curValue = allTotal
            ? getComparePercentageVal(columnTotal[col.dataIndex], allTotal)
            : 0;
          const compareV = compareAllTotal
            ? getComparePercentageVal(
                compareColumnTotal[col.dataIndex],
                compareAllTotal
              )
            : 0;
          value = curValue - compareV;
          value = showNum(value, "percentage");
        }
      }
    }
    return value;
  });

  const handleDownload = useRefFunc(() => {
    const sheet = data2SheetData(columns, transData);
    const summaryRow: any = {};
    columns.forEach((col) => {
      const header = getDownloadColumnHeader(col);
      summaryRow[header] = getSummaryRowCellValue(col);
    });
    sheet.push(summaryRow);
    downloadSheetData([{ name: "test123", data: sheet }], "test234");
  });

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleModeChange = (e: number) => {
    const selectedTab = statisticsTabs[e];
    if (!selectedTab) {
      return;
    }
    setStatisticsTab(selectedTab);
    if (selectItem) {
      selectItem(null);
    }
  };

  const handlePerChange = (e: any) => {
    setCurrentPer(e.target.value);
  };

  const genHeaderItem = (item: Tab, index: number) => {
    const tip = item.titleTips ? (
      <Popover content={item.titleTips}>
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
      </Popover>
    ) : undefined;
    return (
      <Option value={index} key={index}>
        {item.title}
        {tip}
      </Option>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="passenger-ranking-card">
        <Row justify="space-between">
          <Col>
            <Select
              style={{ width: 240 }}
              onChange={handleModeChange}
              defaultValue={0}
              id="rankTab"
            >
              {statisticsTabs.map((item: Tab, index: number) =>
                genHeaderItem(item, index)
              )}
            </Select>
          </Col>
          <Col>
            <Radio.Group
              onChange={handlePerChange}
              value={currentPer}
              id="rankPer"
              buttonStyle="outline"
            >
              {valueTabs.map((item: Tab, index: number) => (
                <Radio value={item} key={index}>
                  {item.title}
                </Radio>
              ))}
            </Radio.Group>
            <DownloadBtn
              handleDownload={handleDownload}
              size={"small"}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        </Row>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <Table
            showSorterTooltip={false}
            className="passenger-loss-ranking"
            columns={columns}
            dataSource={transData}
            size="small"
            scroll={{ x: tableX }}
            summary={() => {
              return (
                <>
                  <Table.Summary.Row>
                    {columns.map((col: IDownloadHeader, index: number) => {
                      return (
                        <Table.Summary.Cell index={index} key={index}>
                          {getSummaryRowCellValue(col)}
                        </Table.Summary.Cell>
                      );
                    })}
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        )}
      </Card>
    </Spin>
  );
};

export default LossRanking;
