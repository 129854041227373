import { Col, Row } from "antd";
import { COMPARE_TYPE_VALUE_NAME, pathSymbols } from "Constants";
import { QUERY_URL, MODULE_CODE } from "Page/AI/HolidayAnalysis/fetchCode";
import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import UserProfileBlock from "./UserProfileBlock";

/**
 * Component description
 *
 */

const pieChartsConfig = {
  legend: { bottom: 10, top: "auto" },
  tooltip: {
    trigger: "item",
  },
  series: [
    {
      type: "pie",
      encode: { itemName: "name", value: "value" },
      radius: ["45%", "60%"],
      center: ["50%", "40%"],
      label: {
        show: true,
        formatter: (d: any) =>
          d.data.name + ":" + Math.round(d.data.value) + "%",
      },
    },
  ],
};

const airlineConfig: any = (compareTypeName: string) => [
  {
    title: getSharkText("custom_profile.page.gender"),
    subtitle: "",
    chartTableCode: {
      1: "AP1107",
      2: "AL1107",
    },
    chartType: "pictorialBar",
    // 数据端返回的男女数据,顺序是反的,需要调整
    sort: (a: any, b: any) => b.name.localeCompare(a.name),
    seriesMap: {
      gender2: {
        name: getSharkText("custom_profile.page.gender.male"),
        symbol: pathSymbols.man,
        itemStyle: {
          color: "#2f89fd",
        },
      },
      gender1: {
        name: getSharkText("custom_profile.page.gender.female"),
        symbol: pathSymbols.woman,
        itemStyle: {
          color: "#f90",
        },
      },
    },
  },
  {
    title: `${compareTypeName}${getSharkText("custom_profile.page.gender")}`,
    subtitle: "",
    chartTableCode: {
      1: "AP1107",
      2: "AL1107",
    },
    chartType: "pictorialBar",
    // 数据端返回的男女数据,顺序是反的,需要调整
    sort: (a: any, b: any) => b.name.localeCompare(a.name),
    seriesMap: {
      gender2: {
        name: getSharkText("custom_profile.page.gender.male"),
        symbol: pathSymbols.man,
        itemStyle: {
          color: "#2f89fd",
        },
      },
      gender1: {
        name: getSharkText("custom_profile.page.gender.female"),
        symbol: pathSymbols.woman,
        itemStyle: {
          color: "#f90",
        },
      },
    },
  },
  {
    title: getSharkText("custom_profile.page.age"),
    subtitle: "",
    chartTableCode: {
      1: "AP1108",
      2: "AL1108",
    },
    chartType: "bar",
    seriesMap: {
      age1: "0-2",
      age2: "3-12",
      age3: "13-17",
      age4: "18-24",
      age5: "25-34",
      age6: "35-44",
      age7: "45-54",
      age8: "55-79",
      age9: "80+",
    },
    chartConfig: {
      color: ["#2f89fd"],
      series: [
        {
          label: {
            show: true,
            position: "right",
            formatter: (d: any) => Math.round(d.data.value) + "%",
          },
        },
      ],
    },
  },
  {
    title: `${compareTypeName}${getSharkText("custom_profile.page.age")}`,
    subtitle: "",
    chartTableCode: {
      1: "AP1108",
      2: "AL1108",
    },
    chartType: "bar",
    seriesMap: {
      age1: "0-2",
      age2: "3-12",
      age3: "13-17",
      age4: "18-24",
      age5: "25-34",
      age6: "35-44",
      age7: "45-54",
      age8: "55-79",
      age9: "80+",
    },
    chartConfig: {
      color: ["#2f89fd"],
      series: [
        {
          label: {
            show: true,
            position: "right",
            formatter: (d: any) => Math.round(d.data.value) + "%",
          },
        },
      ],
    },
  },
  {
    title: getSharkText("config_page_traveler_type"),
    subtitle: "",
    chartTableCode: {
      1: "AP1109",
      2: "AL1109",
    },
    chartType: "pie",
    seriesMap: {
      people1: getSharkText("config_page_parent_child_trip"),
      people2: getSharkText("config_page_silver_hair_group"),
      people3: getSharkText("config_page_college_student"),
      people4: getSharkText("config_page_minor_student"),
      people5: getSharkText("system_type_Others"),
    },
    chartConfig: pieChartsConfig,
  },
  {
    title: `${compareTypeName}${getSharkText("config_page_traveler_type")}`,
    subtitle: "",
    chartTableCode: {
      1: "AP1109",
      2: "AL1109",
    },
    chartType: "pie",
    seriesMap: {
      people1: getSharkText("config_page_parent_child_trip"),
      people2: getSharkText("config_page_silver_hair_group"),
      people3: getSharkText("config_page_college_student"),
      people4: getSharkText("config_page_minor_student"),
      people5: getSharkText("system_type_Others"),
    },
    chartConfig: pieChartsConfig,
  },
  {
    title: getSharkText("config_page_travel_type"),
    chartTableCode: {
      1: "AP1110",
      2: "AL1110",
    },
    chartType: "pie",
    seriesMap: {
      traveltype1: getSharkText("config_page_island_tour"),
      traveltype2: getSharkText("config_page_plateau_tour"),
      traveltype3: getSharkText("config_page_grassland_tour"),
      traveltype4: getSharkText("config_page_ancient_city_tour"),
      traveltype5: getSharkText("config_page_northwest_tour"),
      traveltype6: getSharkText("config_page_ski_tour"),

      traveltype7: getSharkText("config_page_food_tour"),
      traveltype8: getSharkText("config_page_mountain_tour"),
      traveltype9: getSharkText("config_page_city_tour"),
      traveltype10: getSharkText("system_type_Others"),
    },
    chartConfig: pieChartsConfig,
  },
  {
    title: `${compareTypeName}出游类型`,
    chartTableCode: {
      1: "AP1110",
      2: "AL1110",
    },
    chartType: "pie",
    seriesMap: {
      traveltype1: getSharkText("config_page_island_tour"),
      traveltype2: getSharkText("config_page_plateau_tour"),
      traveltype3: getSharkText("config_page_grassland_tour"),
      traveltype4: getSharkText("config_page_ancient_city_tour"),
      traveltype5: getSharkText("config_page_northwest_tour"),

      traveltype6: getSharkText("config_page_ski_tour"),
      traveltype7: getSharkText("config_page_food_tour"),
      traveltype8: getSharkText("config_page_mountain_tour"),
      traveltype9: getSharkText("config_page_city_tour"),
      traveltype10: getSharkText("system_type_Others"),
    },
    chartConfig: pieChartsConfig,
  },
];

interface IProps {
  ext?: any;
}

const Profile = (props: IProps): ReactElement => {
  const { ext } = props;
  const [globalState] = useGlobalState();
  const { systemType, queryCondition, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const compareType = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  const [configs, setConfigs] = useState<any>(
    airlineConfig(COMPARE_TYPE_VALUE_NAME[compareType])
  );
  useEffect(() => {
    setConfigs(airlineConfig(COMPARE_TYPE_VALUE_NAME[compareType]));
  }, [compareType]);
  return (
    <Row gutter={[20, 20]}>
      {configs.map((config: any, idx: number) => (
        <Col lg={12} md={12} xs={24} key={idx}>
          <UserProfileBlock
            queryUrl={QUERY_URL[systemType].profile}
            moduleCode={MODULE_CODE[systemType]}
            title={config.title}
            subtitle={config.subtitle}
            chartTableCode={config.chartTableCode[systemType]}
            chartType={config.chartType}
            seriesMap={config.seriesMap}
            chartConfig={config.chartConfig}
            dataSort={config.sort}
            ext={ext}
            type={idx % 2}
          />
        </Col>
      ))}
    </Row>
  );
};
export default Profile;
