import React from "react";
import { Spin } from "antd";
import { toThousands } from "Utils";
import { round, sortBy } from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  dcityCode: string;
  dcityName: string;
  acityCode: string;
  acityName: string;
  value: number;
  compareValue: number;
  yoy: number;
}

interface BarChartsProps {
  data: BarData[];
  loading: boolean;
  height?: number;
  fieldNames: string[];
  isPercentVal: boolean;
}

const genChartData = (data: BarData[], isPercentVal: boolean) => {
  // 获取总量

  const rst = data.reduce((total: any, d) => {
    total.push({
      name: d.dcityName + "-" + d.acityName,
      value: d.value,
      ydata: isPercentVal
        ? toThousands(round(d.value)) + " " + round(d.yoy)
        : toThousands(round(d.value)) + " " + round(d.yoy * 100),
    });

    return total;
  }, []);
  return rst;
};

const BarCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const { data, loading, height = 450, fieldNames, isPercentVal } = props;
  const chartData = genChartData(data, isPercentVal);
  const chartData2 = sortBy(
    genChartData(data, isPercentVal),
    (item) => item.value
  );
  const isZero = (val: number) => val === 0;
  const option = {
    dataset: {
      source: chartData,
    },
    color: ["#177DFE", "#01C5DB", "#FC9B4F"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    grid: {
      show: true,
      top: 10,
      bottom: 50,
      left: 125,
      right: 150,
      backgroundColor: "#fff",
      borderWidth: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        // const day = getTooltipDateFromAggCode(aggCode, params, data.length, startDate, endDate, isDemo)

        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const val = `${item.data.name}: ${
            !isZero(item.data.value) ? toThousands(item.data.value) : "-"
          }`;
          // const per = isZero(totalValue) ? '-' : item.data.value / totalValue;
          // const perDom = `占比: ${per !== '-' ? ((per * 100).toFixed(2)) : '-'}%`;
          dom = dot + val + "<br />";
        });
        return dom;
      },
    },
    xAxis: [
      {
        type: "value",
        show: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: true,
        },
        boundaryGap: [0, 0.01],
      },
    ],
    yAxis: [
      {
        type: "category",
        splitNumber: 10,
        inverse: true,
        axisLine: {
          show: true,
        },
        axisTick: {
          show: false,
        },
      },
      {
        type: "category",
        splitNumber: 10,
        data: chartData2.map((item: any) => item.ydata),
        axisLabel: {
          formatter: (value: any) => {
            let v = value.split(" ")[1];
            if (v === 0 || isNaN(v)) {
              v = "-";
              return "{value|" + value.split(" ")[0] + " }{yoy|" + v + "}";
            } else if (v > 0) {
              return "{value|" + value.split(" ")[0] + " }{yoyRed|" + v + "%}";
            } else {
              return (
                "{value|" + value.split(" ")[0] + " }{yoyGreen|" + v + "%}"
              );
            }
          },
          rich: {
            value: {
              lineHeight: 30,
              width: 70,
              align: "center",
            },
            yoy: {
              lineHeight: 30,
              width: 70,
              align: "center",
            },
            yoyRed: {
              lineHeight: 30,
              width: 70,
              align: "center",
              color: "red",
            },
            yoyGreen: {
              lineHeight: 30,
              width: 70,
              align: "center",
              color: "green",
            },
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: "bar",
        encode: { x: "value", y: "name" },
        lineStyle: {
          width: 3,
        },
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <div style={{ width: "100%", textAlign: "right" }}>
        {fieldNames[0]}&nbsp;&nbsp;&nbsp;{fieldNames[1]}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default BarCharts;
