export const MODULE_CODE: any = {
  1: "industry_trend_airport",
  2: "industry_trend_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    lineCharts: "AP0901",
    top: "AP0902",
    refund: "AP0903",
  },
  2: {
    lineCharts: "AL0901",
    top10: "AL0902",
    sale: "AL0903",
  },
};

export const QUERY_URL: any = {
  1: {
    lineCharts: "queryIndustryTrendBarLine",
    top: "queryIndustryTrendTop",
    refund: "queryIndustryTrendRefund",
  },
  2: {
    lineCharts: "queryIndustryTrendBarLine",
    top10: "queryIndustryTrendTop",
    sale: "queryIndustryTrendSale",
  },
};
