import CheckButtonsWithAll from "Components/CheckButtons/CheckButtonsWithAll";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { DATE_FORMAT, WEEK_OPTIONS } from "Constants";
import { RangeObject } from "Interface";
import { getDowOfDateRange } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { Col, Row } from "antd";
import moment, { Moment } from "moment";
import React, {
  Key,
  ReactElement,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from "react";

export interface BatchConfig {
  dateRange: [string, string];
  schedule: string;
}

export interface ParaBatchGenerateProps {
  takeoffdate: string;
  maxDate: Moment;
  config: BatchConfig;
  setConfig: (v: BatchConfig) => void;
}
export interface ParaBatchGenerateRef {
  getBatchConfig: () => { config: BatchConfig | null };
}

/**
 * 批量调舱组件
 */
const ParaBatchGenerate = forwardRef(
  (props: ParaBatchGenerateProps): ReactElement => {
    const { takeoffdate, maxDate, config, setConfig } = props;

    const [schedule, setSchedule] = useState<Key[]>(
      // WEEK_OPTIONS.map((a) => a.value)
      getDowOfDateRange([moment(takeoffdate), moment(takeoffdate)])
    );
    const [dateRange, setDateRange] = useState<RangeObject>([
      // moment().add(0, "day"),
      moment(takeoffdate),
      moment(takeoffdate).add(0, "day"),
    ]);

    useEffect(() => {
      setDateRange([moment(takeoffdate), moment(takeoffdate).add(0, "day")]);
      setSchedule(
        getDowOfDateRange([moment(takeoffdate), moment(takeoffdate)])
      );
    }, [takeoffdate]);

    const allowDow = useMemo(() => {
      if (!dateRange || dateRange.filter((f) => !!f).length !== 2) {
        return undefined;
      }
      const days = moment(dateRange[1]).diff(dateRange[0], "days");
      if (days > 7) {
        return undefined;
      }
      const dow = getDowOfDateRange(dateRange as [Moment, Moment]);
      return dow;
    }, [dateRange]);

    const handleDateRangeChange = useRefFunc((v: RangeObject) => {
      setDateRange(v);
      console.log("dateRange-v is what", v?.[0]?.format(DATE_FORMAT));
      console.log("dateRange-v is what", v?.[1]?.format(DATE_FORMAT));
      const dow = getDowOfDateRange(v as [Moment, Moment]);
      if (dateRange?.filter((f) => !!f).length === 2) {
        if (dow.length > 0) {
          setSchedule(dow);
        }
      }
      const newConfig: BatchConfig = {
        ...config,
        dateRange: [
          v?.[0]?.format(DATE_FORMAT) ?? "",
          v?.[1]?.format(DATE_FORMAT) ?? "",
        ],
        schedule: dow.map((a) => Number(a) + 1).join(""),
      };
      setConfig(newConfig);
    });

    const handleCheckButtonsChange = useRefFunc((v: Key[]) => {
      console.log("v is what", v);
      setSchedule(v);

      const newConfigSchedule: BatchConfig = {
        ...config,
        schedule: v.map((a) => Number(a) + 1).join(""),
      };
      setConfig(newConfigSchedule);
    });

    return (
      <Row gutter={[20, 20]} style={{ lineHeight: "30px", height: 34 }}>
        <>
          <Col>
            <DateRangePickerV2
              value={dateRange}
              onChange={handleDateRangeChange}
              otherProps={{
                size: "small",
                disabledDate: (date) => {
                  return (
                    date.isAfter(maxDate) ||
                    date.isBefore(moment().add(-1, "day"))
                  );
                },
              }}
            />
          </Col>
          <Col>
            <CheckButtonsWithAll
              allowValues={allowDow}
              options={WEEK_OPTIONS}
              value={schedule}
              onChange={handleCheckButtonsChange}
            ></CheckButtonsWithAll>
          </Col>
        </>
      </Row>
    );
  }
);
ParaBatchGenerate.displayName = "ParaBatchGenerate";
export default ParaBatchGenerate;
