import React, { useState, useEffect } from "react";
import {
  InfoCircleOutlined,
  SwapLeftOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import {
  Typography,
  Card,
  Radio,
  List,
  Button,
  Row,
  Spin,
  Empty,
  Popover,
} from "antd";
import useGlobal from "Store";
import { AirlinesQueryExt, FlightArea } from "Interface";
import _ from "lodash";
import { getComparedPercentage, isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import { getServer } from "Service/server";
import "./index.css";
import RankSortBtn from "../../../../../Components/RankSortBtn";

const { Title, Text } = Typography;

const routeTabs = [
  {
    label: getSharkText("key.departure.name"),
    value: 0,
  },
  {
    label: getSharkText("key.arrival.name"),
    value: 1,
  },
  {
    label: getSharkText("key.route.name"),
    value: 2,
  },
];

interface IProps {
  title: string;
  tab: any;
  style?: object;
  queryUrl: string;
  moduleCode: string;
  chartTableCode?: string;
  sortable?: boolean;
  cardCode?: string;
  setHasCityData?: (has: boolean) => void;
  airlinesQueryExt?: AirlinesQueryExt;
  selectItem?: (item: FlightArea | null) => void;
  airlinesSearchTrendsExt?: FlightArea;
  airlinesOnsaleAirlineExt?: FlightArea;
  setChartsType?: (v: number) => void;
  ext: any;
}

interface Tab {
  title: string;
  titleTip?: string;
  type: 0 | 1;
  compare: boolean;
  isPercentVal: boolean;
}

const AirlinesRanking: React.FC<IProps> = (props: IProps) => {
  const {
    title,
    tab,
    style,
    queryUrl,
    moduleCode,
    sortable,
    cardCode,
    setHasCityData,
    chartTableCode,
    airlinesQueryExt,
    airlinesSearchTrendsExt,
    airlinesOnsaleAirlineExt,
    selectItem,
    setChartsType,
    ext: otherExt,
  } = props;
  const [currentTab, setCurrentTab] = useState<Tab | null>(null);
  const [sort, setSort] = useState(1);
  const [routeType, setRouteType] = useState<number>(2);
  const [curCityIndex, setCurCityIndex] = useState<number | null>(null);
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, systemType, queryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const getExt = () => {
    let obj;
    if (airlinesQueryExt) {
      // 航线分析
      obj = {
        cardCode,
        orderSort: sort,
        compare: currentTab ? currentTab.compare : false,
        filter: airlinesQueryExt,
      };
    } else if (airlinesSearchTrendsExt) {
      // 搜索指数
      obj = {
        ...airlinesSearchTrendsExt,
        type: currentTab ? currentTab.type || 0 : 0,
        orderSort: sort,
      };
    } else if (airlinesOnsaleAirlineExt) {
      // 航班计划
      obj = {
        ...airlinesOnsaleAirlineExt,
        type: currentTab ? currentTab.type : 0,
        orderSort: sort,
      };
    } else {
      // 核心概览
      obj = {
        cardCode,
        orderSort: sort,
        compare: currentTab ? currentTab.compare : false,
      };
    }
    return obj;
  };
  const ext = getExt();
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: isAirport ? queryCondition : airlinesQueryCondition,
    ext: {
      ...ext,
      ...otherExt,
      type: routeType,
      cardCode,
      orderByType: (currentTab && currentTab.type) || 0,
    },
    lazey: true,
  });

  const isPercentVal = (currentTab && currentTab.isPercentVal) ?? false;

  const refetch = () => {
    if (currentTab) {
      doFetch({
        query: isAirport ? queryCondition : airlinesQueryCondition,
        ext: {
          ...ext,
          ...otherExt,
          type: routeType,
          cardCode,
          orderByType: currentTab.type,
        },
      });
    }
  };

  useEffect(() => {
    setCurrentTab(tab[0]);
    if (setChartsType) {
      setChartsType(tab[0].type);
    }
  }, [tab]);

  useEffect(() => {
    setCurCityIndex(null);
    if (selectItem) {
      selectItem(null);
    }
    if (setHasCityData) {
      setHasCityData(false);
    }
    if (cardCode) {
      refetch();
    }
  }, [
    sort,
    currentTab,
    airlinesQueryCondition,
    airlinesQueryExt,
    airlinesSearchTrendsExt,
    airlinesOnsaleAirlineExt,
    otherExt,
    routeType,
    cardCode,
    queryCondition,
  ]);

  useEffect(() => {
    if (data && setHasCityData) {
      setHasCityData(true);
    }
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleSort = () => {
    setSort(sort === 0 ? 1 : 0);
  };

  const handleModeChange = (e: any) => {
    setCurrentTab(e.target.value);
    if (setChartsType) {
      setChartsType(e.target.value.type);
    }
  };

  const changeRouteType = (e: any) => {
    setRouteType(e.target.value);
  };

  const handleChooseItem = (value: any, idx?: number) => {
    if (selectItem) {
      selectItem({
        departArea: {
          areaType: 4,
          areaCode: value.dcityCode,
          areaName: value.dcityName,
        },
        arriveArea: {
          areaType: 4,
          areaCode: value.acityCode,
          areaName: value.acityName,
        },
      });
      if (idx || idx === 0) {
        setCurCityIndex(idx);
      }
    }
  };

  const searchItem = (item: any, index: any) => {
    const computeValue = _.round(item.value);

    return (
      <List.Item
        className={`ranking-item optional ${
          index === curCityIndex ? "cur" : ""
        }`}
        onClick={() => handleChooseItem(item, index)}
      >
        <div className="ranking-badge">{index + 1}</div>
        <div className="ranking-item-content">
          <Text>
            {item.dcityName} {item.dcityName && item.acityName ? "-" : ""}{" "}
            {item.acityName}
          </Text>
        </div>
        <div className="ranking-item-value">
          <Text>
            <ValueNum
              type={isPercentVal ? "percentage" : "num"}
              value={
                currentTab?.type === 0
                  ? computeValue
                  : getComparedPercentage(item.value, item.compareValue)
              }
            />
          </Text>
        </div>
      </List.Item>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="ranking-card" style={style}>
        {title && <span className="module-title">{title}</span>}
        <Row justify="space-between" data-ut-id="ranking-tab">
          <Radio.Group
            onChange={handleModeChange}
            value={currentTab}
            id="rankTab"
          >
            {tab.map((item: any, index: any) => (
              <Radio.Button value={item} key={index}>
                {item.title}
                {item.titleTips ? (
                  <Popover content={item.titleTips}>
                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                  </Popover>
                ) : undefined}
              </Radio.Button>
            ))}
            <Radio.Group
              value={routeType}
              onChange={changeRouteType}
              style={{ marginLeft: 10 }}
            >
              {routeTabs.map((item: any, index: number) => (
                <Radio value={item.value} key={index}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Radio.Group>
          {sortable ? (
            <RankSortBtn sort={Boolean(sort)} handleSortChange={handleSort} />
          ) : undefined}
        </Row>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={data}
            style={{ margin: "0px 0 -10px" }}
            renderItem={(item: any, index) => searchItem(item, index)}
          />
        )}
      </Card>
    </Spin>
  );
};

export default AirlinesRanking;
