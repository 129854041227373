import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import useRefFunc from "Utils/useRefFunc";
import { IFetchCMDPrice, IRouteItem } from "./FlightManageInterface";

const SET_ROUTE_LIST = "SET_ROUTE_LIST";
const SET_FETCH_CMD_PRICE_LIST = "SET_FETCH_CMD_PRICE_LIST";
const ADD_FETCH_CMD_PRICE_LIST = "ADD_FETCH_CMD_PRICE_LIST";
const REMOVE_FETCH_CMD_PRICE = "REMOVE_FETCH_CMD_PRICE";

export interface IFlightManageState {
  routeList: IRouteItem[];
  fetchCMDPriceList: IFetchCMDPrice[];
}

export interface IStateAction {
  setRouteList: (v: IRouteItem[]) => void;
  setFetchCMDPriceList: (v: IFetchCMDPrice[]) => void;
  addFetchCMDPriceList: (v: IFetchCMDPrice) => void;
  removeFetchCMDPrice: (cacheKey: string) => void;
}

const initialState: IFlightManageState = {
  routeList: [],
  fetchCMDPriceList: [],
};

const FlightManageContext = createContext<any>(null);

const globalStateReducer = (
  state: IFlightManageState,
  action: any
): IFlightManageState => {
  switch (action.type) {
    case SET_ROUTE_LIST:
      return {
        ...state,
        routeList: action.payload,
      };
    case SET_FETCH_CMD_PRICE_LIST:
      return {
        ...state,
        fetchCMDPriceList: action.payload,
      };
    case ADD_FETCH_CMD_PRICE_LIST:
      return {
        ...state,
        fetchCMDPriceList: [...state.fetchCMDPriceList, action.payload],
      };
    case REMOVE_FETCH_CMD_PRICE:
      return {
        ...state,
        fetchCMDPriceList: state.fetchCMDPriceList.filter(
          (f) => f.cacheKey !== action.payload
        ),
      };
    default:
      return state;
  }
};

const usePageContext = (): [IFlightManageState, IStateAction] => {
  const [state, dispatch] = useContext(FlightManageContext);

  const setRouteList = useCallback(
    (v: IRouteItem[]) => {
      dispatch({
        type: SET_ROUTE_LIST,
        payload: v,
      });
    },
    [dispatch]
  );

  const setFetchCMDPriceList = useRefFunc((v: IFetchCMDPrice[]) => {
    dispatch({
      type: SET_FETCH_CMD_PRICE_LIST,
      payload: v,
    });
  });

  const addFetchCMDPriceList = useRefFunc((v: IFetchCMDPrice) => {
    if (
      !state.fetchCMDPriceList.find(
        (f: IFetchCMDPrice) => f.cacheKey === v.cacheKey
      )
    ) {
      dispatch({
        type: ADD_FETCH_CMD_PRICE_LIST,
        payload: v,
      });
    }
  });

  const removeFetchCMDPrice = useRefFunc((cacheKey: string) => {
    dispatch({
      type: REMOVE_FETCH_CMD_PRICE,
      payload: cacheKey,
    });
  });

  const setState: IStateAction = useMemo(
    () => ({
      setRouteList,
      setFetchCMDPriceList,
      addFetchCMDPriceList,
      removeFetchCMDPrice,
    }),
    [
      setFetchCMDPriceList,
      setRouteList,
      addFetchCMDPriceList,
      removeFetchCMDPrice,
    ]
  );

  return [state, setState];
};

export default usePageContext;

export const FlightManageProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  return (
    <FlightManageContext.Provider value={[state, dispatch]}>
      {children}
    </FlightManageContext.Provider>
  );
};
