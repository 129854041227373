import { Questionnaire } from "@ctrip/flt-bi-flightai-base";
import { Button, Col, Input, message, Row, Tree } from "antd";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { DATE_FORMAT, LIMIT_END_DATE, LIMIT_START_DATE } from "Constants";
import { RangeObject } from "Interface";
import { uniq } from "lodash";
import moment, { Moment } from "moment";
import React, {
  Key,
  ReactElement,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { getDateRange, isNumeric } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import { PollData, UnitUserTree } from "./IPoll";

const defaultDateMode = "nextThirtyDays";

export interface IEditorProps {
  value: PollData;
  unitUserTree: UnitUserTree[];
}

/**
 * 问卷调查编辑页面
 */
const Editor = React.forwardRef(
  (props: IEditorProps, ref: any): ReactElement => {
    const { value, unitUserTree } = props;
    const [pollId, setPollId] = useState<string>(
      value.pollId !== null ? `${value.pollId}` : ""
    );
    const [questionnaireName, setQuestionnaireName] = useState<string>(
      value.questionnaireName || ""
    );
    const [dateRange, setDateRange] = useState<RangeObject>(() => {
      if (value.startTime && value.endTime) {
        return [moment(value.startTime), moment(value.endTime)];
      }
      return getDateRange(defaultDateMode);
    });
    const [inputGroup, setInputGroup] = useState<string>("");
    const [selectedKeys, setSelectedKeys] = useState<Key[]>(() => {
      const rst: string[] = [];
      if (value.userIdList?.length) {
        unitUserTree.forEach((u) => {
          u.children.forEach((c) => {
            if (value.userIdList?.includes(c.id)) {
              rst.push(c.key);
            }
          });
        });
      }
      return rst;
    });
    const selectedGroup = useMemo(() => {
      return unitUserTree
        .reduce((total, current) => {
          const hasThisGroup = current.children.some((c) =>
            selectedKeys.includes(c.key)
          );
          if (hasThisGroup) {
            total.push(current.id);
          }
          return total;
        }, [] as number[])
        .join();
    }, [selectedKeys, unitUserTree]);
    const [url, setUrl] = useState<string>(value.url || "");
    useImperativeHandle(ref, () => ({
      save: (): Questionnaire | null => {
        if (!pollId) {
          message.error("pollId 不能为空");
          return null;
        }
        if (!questionnaireName) {
          message.error("questionnaireName 不能为空");
          return null;
        }
        if (!dateRange || dateRange.filter((d) => !!d).length !== 2) {
          message.error(getSharkText("config_page_time_selection_error"));
          return null;
        }
        if (!url) {
          message.error(getSharkText("config_page_url_cannot_be_empty"));
          return null;
        }
        // #region 获取选中的用户
        const selectedUserIdList = unitUserTree.reduce((total, current) => {
          const selectedUsers = current.children.filter((user) =>
            selectedKeys.includes(user.key)
          );
          total = total.concat(selectedUsers.map((s) => s.id));
          return total;
        }, [] as number[]);
        // #endregion
        if (!selectedUserIdList.length) {
          message.error(
            getSharkText("config_page_need_to_select_at_least_one_user")
          );
          return null;
        }
        return {
          questionnaireId: value.questionnaireId,
          pollId: isNumeric(pollId) ? Number(pollId) : null,
          questionnaireName,
          startTime:
            (dateRange && dateRange[0]?.format(DATE_FORMAT)) ||
            LIMIT_START_DATE,
          endTime:
            (dateRange && dateRange[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
          url,
          userIdList: selectedUserIdList,
        };
      },
    }));

    const checkUser = useRefFunc(
      (
        keys:
          | Key[]
          | {
              checked: Key[];
              halfChecked: Key[];
            }
      ) => {
        if (Array.isArray(keys)) {
          setSelectedKeys(keys);
        } else {
          setSelectedKeys(keys.checked);
        }
      }
    );

    // #region 将指定组中的所有用户添加到选中列表, 同时清空用户输入
    const handleInputGroupAdd = useRefFunc(() => {
      const inputArr = inputGroup.split(",").map((i) => Number(i));
      const ipt = unitUserTree.filter((f) => inputArr.includes(f.id));
      const iptUsersKeys = ipt.reduce((total, current) => {
        const userKeys = current.children.map((c) => c.key);
        total = total.concat(userKeys);
        return total;
      }, [] as string[]);
      const nextSelectedKeys = uniq(selectedKeys.concat(iptUsersKeys));
      setSelectedKeys(nextSelectedKeys);
      setInputGroup("");
    });
    // #endregion

    // #region 将输入框中的组从选中组中删除
    const handleInputGroupRemove = useRefFunc(() => {
      console.log("remove");
      const inputArr = inputGroup.split(",").map((i) => Number(i));
      const inputGroups = unitUserTree.filter((f) => inputArr.includes(f.id));
      const inputUserKeys = inputGroups.reduce((total, current) => {
        const groupUserKeys = current.children.map((user) => user.key);
        total = total.concat(groupUserKeys);
        return total;
      }, [] as string[]);
      const nextSelectedKeys = selectedKeys.filter(
        (key) => !inputUserKeys.includes(key as string)
      );
      setSelectedKeys(nextSelectedKeys);
      setInputGroup("");
    });
    // #endregion

    // #region 选中所有组所有用户
    const handleSelectAllGroup = useRefFunc(() => {
      console.log("handle select all");
      const nextSelectedKeys = unitUserTree.reduce((total, group) => {
        const userKeys = group.children.map((user) => user.key);
        return total.concat(userKeys);
      }, [] as string[]);
      setSelectedKeys(nextSelectedKeys);
    });
    // #endregion

    return (
      <div>
        <div style={{ marginBottom: 5 }}>
          <Row>
            <Col span={3}>
              <span>PollId</span>
            </Col>
            <Col>
              <Input
                value={pollId}
                onChange={(e) => setPollId(e.target.value)}
              ></Input>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: 5 }}>
          <Row>
            <Col span={3}>
              <span>{getSharkText("config_page_questionnaire_name")}</span>
            </Col>
            <Col>
              <Input
                value={questionnaireName}
                onChange={(e) => setQuestionnaireName(e.target.value)}
              ></Input>
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: 5 }}>
          <Row>
            <Col span={3}>
              <span>{getSharkText("config_page_display_date_range")}</span>
            </Col>
            <Col>
              <DateRangePickerV2
                defaultDateMode={defaultDateMode}
                value={dateRange}
                onChange={setDateRange}
              />
            </Col>
          </Row>
        </div>
        <div style={{ marginBottom: 5 }}>
          <span>{getSharkText("config_page_target_user_selection")}</span>
          <div style={{ display: "flex", border: "1px solid #eee" }}>
            <div style={{ width: "50%" }}>
              <div style={{ height: 140, padding: 5, marginBottom: 10 }}>
                <div>
                  {getSharkText("config_page_enter_user_group_id_quickly")}
                </div>
                <Input.TextArea
                  rows={3}
                  value={inputGroup}
                  onChange={(e) => setInputGroup(e.target.value)}
                />
                <Button
                  style={{ marginTop: 3 }}
                  type="primary"
                  size="small"
                  onClick={handleInputGroupAdd}
                >
                  {getSharkText("key.add.button")}
                </Button>
                <Button
                  style={{ marginTop: 3, marginLeft: 5 }}
                  type="primary"
                  danger
                  size="small"
                  onClick={handleInputGroupRemove}
                >
                  {getSharkText("config_page_delete")}
                </Button>
                <Button
                  style={{ marginTop: 3, marginLeft: 5 }}
                  type="primary"
                  size="small"
                  onClick={handleSelectAllGroup}
                >
                  {getSharkText("config_page_select_all")}
                </Button>
              </div>
              <div style={{ height: 140, padding: 5 }}>
                <div>{getSharkText("config_page_selected_user_group")}</div>
                <Input.TextArea
                  rows={4}
                  readOnly
                  value={selectedGroup}
                  style={{ background: "#eee" }}
                />
              </div>
            </div>
            <div style={{ width: "50%" }}>
              <div
                style={{
                  maxHeight: 300,
                  overflow: "scroll",
                  padding: 5,
                }}
              >
                <Tree
                  treeData={unitUserTree}
                  checkable
                  checkedKeys={selectedKeys}
                  onCheck={checkUser}
                  titleRender={(nodeData: UnitUserTree) =>
                    `(${nodeData.id})${nodeData.title}`
                  }
                ></Tree>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Row>
            <Col span={3}>
              <span>{getSharkText("config_page_questionnaire_link")}</span>
            </Col>
            <Col>
              <Input.TextArea
                rows={4}
                style={{ width: 600 }}
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              ></Input.TextArea>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
);
export default Editor;
