import React, { useEffect } from "react";
import { Select } from "antd";
import { useFetch, useSessionStorage } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { ALINES_LIST } from "Constants";
import { AirlineItem, RoleItem } from "Interface";
import _ from "lodash";

interface IProps {
  airlineRole: RoleItem;
  setAirlineRole: (role: RoleItem) => void;
}

const { Option } = Select;

const SelectAirports: React.FC<IProps> = (props: IProps) => {
  const { airlineRole, setAirlineRole } = props;

  const [airlines, setAirlines] = useSessionStorage(ALINES_LIST, []);
  const [{ data }, doFetch] = useFetch({
    url: "findAirlines",
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      code: "",
      type: 1,
    },
    lazey: true,
  });

  useEffect(() => {
    if (_.isEmpty(airlines)) {
      doFetch({
        ext: {
          type: 1,
        },
      });
    }
  }, [airlines]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setAirlines(data);
    }
  }, [data]);

  const handleChange = (arr: string[], option: any) => {
    const permissionNameArr: string[] = [];
    if (option.length) {
      option.map((item: any) => {
        permissionNameArr.push(item.props.title);
      });
    }
    const permissionName = permissionNameArr.join(",");
    const permissionCode = arr.join(",");
    setAirlineRole({
      ...airlineRole,
      permissionName,
      permissionCode,
    });
  };

  const filterOption = (input: string, option: any) =>
    option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
    option.props.title.includes(input);

  if (_.isEmpty(airlines)) {
    return null;
  }

  return (
    <Select
      showSearch
      style={{ width: 330 }}
      placeholder={getSharkText(
        "config_page_PaAiRiCoSeIn_select_airline_two_letter_code"
      )}
      filterOption={filterOption}
      onChange={handleChange}
      value={
        airlineRole.permissionCode ? airlineRole.permissionCode.split(",") : []
      }
      mode="multiple"
    >
      {airlines.map((item: AirlineItem) => (
        <Option
          key={item.airlinesCode}
          title={item.airlinesName}
          value={item.airlinesCode}
        >
          {item.airlinesName}
          {item.airlinesCode}
        </Option>
      ))}
    </Select>
  );
};

export default SelectAirports;
