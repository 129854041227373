import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  ReactElement,
} from "react";
import { Row, Col, Result, Affix } from "antd";
import useGlobal from "Store";
import Page from "Layout/Page";
import { MODULE_CODE, CHART_TABLE_CODE, QUERY_URL } from "./fetchCode";
import { FlightArea, Area, CardItemData, ECompareTypeV2 } from "Interface";
import { COMPARE_TYPE_PER_NAME, DEFAULT_AREA } from "Constants";
import TransferBarLineCharts from "./Components/TransferBarLineCharts";
import TransferTable from "./Components/TransferTable";
import SearchArea from "./Components/SearchArea";
import TransferCard from "./Components/TransferCard";
import TransferRanking from "./Components/TransferRanking";
import UpdateTime from "Components/UpdateTime";
import TopSearchArea from "./Components/TopSearchArea";
import { ITransferAnalysisExt } from "../TransferPolicy/TransferPolicyInterface";
import { getServer } from "Service/server";
import HeaderAlert from "Components/HeaderAlert";
import TransferAdvantage from "./Components/TransferAdvantage";
import TransferAlert from "./AlertComponents/TransferAlert";
import { getSharkText } from "Utils/i18nGlobal";

const defaultFlightAreaQueryExt = {
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
};
const defaultCard: CardItemData = {
  cardCode: "transit_persons",
  cardName: "中转人数",
  cardHint: "",
  cardValue: 0,
  compareValue: 0,
  avgValue: 0,
  yoyValue: 0,
};

interface IAirportIndexProps {
  isDemo: boolean;
}

const AirportIndex = (props: IAirportIndexProps): ReactElement => {
  const { isDemo } = props;
  const [flightAreaQueryExt, setFlightAreaQueryExt] = useState<
    FlightArea | undefined
  >();
  const [transferAnalysisExt, setTransferAnalysisExt] =
    useState<ITransferAnalysisExt | null>(null);
  const [riseControl, setRiseControl] = useState<string[]>(["年同比"]);
  const [transAirport, setTransAirport] = useState<Area>();
  const [alertMessage, setAlertMessage] = useState<ReactNode | string>("");
  const [curCard, setCurCard] = useState<CardItemData>(defaultCard);
  const curCardCode = curCard.cardCode;
  const [chartType, setChartType] = useState<string>("barline");
  const [loading, setLoading] = useState(false);
  const [globalState] = useGlobal();
  const containerRef = useRef<HTMLDivElement>(null);
  const { systemType, queryCondition } = globalState;
  const { departure, arrive, flightClass } = queryCondition;
  const server = getServer(systemType);

  useEffect(() => {
    if (!departure && !arrive) {
      setFlightAreaQueryExt(undefined);
    }
  }, [departure, arrive]);

  useEffect(() => {
    const obj: ITransferAnalysisExt = {
      query: {
        yoy: riseControl.includes(COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR])
          ? 1
          : riseControl.includes(
              COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_BEFORE_LAST]
            )
          ? 3
          : riseControl.includes(
              COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_LAST_LAST]
            )
          ? 5
          : riseControl.includes(
              COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_4_BEFORE]
            )
          ? 6
          : riseControl.includes(
              COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_5_BEFORE]
            )
          ? 7
          : 0,
        wow: riseControl.includes(COMPARE_TYPE_PER_NAME[ECompareTypeV2.MONTH])
          ? 2
          : riseControl.includes(COMPARE_TYPE_PER_NAME[ECompareTypeV2.WEEK])
          ? 1
          : 0,
        area: transAirport || DEFAULT_AREA,
      },
      filter: flightAreaQueryExt && flightAreaQueryExt,
      riseControl,
    };
    setTransferAnalysisExt(obj);
  }, [flightAreaQueryExt, riseControl, transAirport]);

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setFlightAreaQueryExt(defaultFlightAreaQueryExt);
    setTimeout(() => setLoading(false));
  }, [flightClass]);

  useEffect(
    () =>
      setAlertMessage(
        <UpdateTime
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={CHART_TABLE_CODE[systemType].barline}
          text={getSharkText("config_page_analysis_target_airport")}
        />
      ),
    [systemType]
  );

  const onCardChange = (data: CardItemData) => {
    setCurCard(data);
  };

  // 排行榜第三项为环比, 不选中任意环比的情况下, 不显示环比项
  const getRankTab = () => {
    const rst = [
      {
        title: getSharkText(`card.${curCardCode}.name`),
        compare: 0,
        // TODO
      },
    ];
    if (transferAnalysisExt === null) {
      return rst;
    }
    const { query } = transferAnalysisExt;
    const { wow, yoy } = query;
    if (yoy !== 0) {
      rst.push({
        title: getSharkText("key.yoy_increment.name"),
        compare: 1,
      });
    }
    if (wow !== 0) {
      rst.push({
        title: getSharkText("config_page_PaAiTrAi_link_relative_increment"),
        compare: 2,
      });
    }
    return rst;
  };

  return (
    <Page defaultDateMode="lastMonth" needToolBar={false}>
      <div>
        {isDemo ? <HeaderAlert /> : undefined}
        <TopSearchArea
          alertMessage={alertMessage}
          onRiseControlChange={setRiseControl}
          onAirportChange={setTransAirport}
        />
      </div>
      <Affix offsetTop={67}>
        <div className="content-white">
          <SearchArea
            queryUrl={QUERY_URL[systemType].compareList}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].compareList}
            setFlightAreaQueryExt={setFlightAreaQueryExt}
          />
        </div>
      </Affix>
      <div
        id="transfer-container"
        ref={containerRef}
        style={{ overflow: "scroll" }}
      >
        {loading ? undefined : transferAnalysisExt && flightAreaQueryExt ? (
          <>
            <div id="transfer-trend" className="content-white">
              <TransferCard
                queryUrl={QUERY_URL[systemType].cards}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].cards}
                setCurCard={onCardChange}
                selectedCode={curCardCode}
                transferAnalysisExt={transferAnalysisExt}
              />
              <Row
                gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
                style={{ marginTop: 0 }}
              >
                <Col xxl={14} lg={24}>
                  <TransferBarLineCharts
                    queryUrl={QUERY_URL[systemType][chartType]}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType][chartType]}
                    cardCode={curCardCode}
                    chartType={chartType}
                    setChartType={setChartType}
                    transferAnalysisExt={transferAnalysisExt}
                  />
                </Col>
                <Col xxl={10} lg={24}>
                  <TransferRanking
                    title={getSharkText("key.top_10_routes.name")}
                    server={server}
                    queryUrl={QUERY_URL[systemType].top}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].top}
                    tab={getRankTab()}
                    style={{ marginTop: 0 }}
                    sortable
                    cardCode={curCardCode}
                    area={transferAnalysisExt}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <TransferAdvantage
                    transferAnalysisExt={transferAnalysisExt}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <TransferAlert transferAnalysisExt={transferAnalysisExt} />
                </Col>
              </Row>
            </div>
            <div
              id="transfer-table"
              className="content-white"
              style={{ margin: "0 12px 0 20px", height: "980px" }}
            >
              <TransferTable
                queryUrl={QUERY_URL[systemType].table}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].table}
                transferAnalysisExt={transferAnalysisExt}
              />
            </div>
          </>
        ) : (
          <Result
            title={getSharkText("config_page_please_select_departure_arrival")}
          />
        )}
      </div>
    </Page>
  );
};

export default AirportIndex;
