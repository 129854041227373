import { message, Select } from "antd";
import { Area, IValueLabelOption } from "Interface";
import { cloneDeep, isEqual } from "lodash";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import CascaderArea, { CascaderAreaProps } from "./CascaderArea";
import useRefFunc from "Utils/useRefFunc";
import { getSharkText } from "Utils/i18nGlobal";

type CodeOption = { code: string } & IValueLabelOption;
type KeyArea = { key: string } & Area;

export interface ISelectMultiAirportsProps {
  /**
   * 可选数量上限
   */
  limitedCnt?: number;
  onChange?: (v: Area[]) => void;
  cascaderAreaProps?: Partial<CascaderAreaProps>;
  allowDuplicate?: boolean;
  placeholder?: string;
}

interface IState {
  selectedOptions: CodeOption[];
  selectedArea: KeyArea[];
}

/**
 * 机场多选组件
 */
const SelectMultiAirports = (
  props: ISelectMultiAirportsProps
): ReactElement => {
  const {
    limitedCnt,
    onChange,
    cascaderAreaProps,
    allowDuplicate = false,
    placeholder,
  } = props;
  const [open, setOpen] = useState(false);
  const idxRef = useRef(0);

  const [selected, setSelected] = useReducer(
    (state: IState, v: KeyArea[]) => {
      if (isEqual(state.selectedArea.sort(), v.sort())) {
        return state;
      }
      if (onChange) {
        onChange(v);
      }
      return {
        selectedArea: v,
        selectedOptions: v.map((e) => ({
          label: e.areaCode,
          value: e.key,
          code: e.areaCode,
        })),
      };
    },
    {
      selectedArea: [],
      selectedOptions: [],
    }
  );

  const handleChange = useRefFunc((airport: Area) => {
    if (
      !allowDuplicate &&
      selected.selectedArea.find((e) => e.areaCode === airport.areaCode)
    ) {
      return;
    }
    if (
      limitedCnt !== undefined &&
      selected.selectedArea.length >= limitedCnt
    ) {
      message.error(
        `${getSharkText(
          "key.maximum_select_number.text"
        )}${limitedCnt}${getSharkText("key.options.text")}`
      );
      return;
    }
    const newArr = cloneDeep(selected.selectedArea);
    newArr.push({
      ...airport,
      key: idxRef.current.toString(),
    });
    idxRef.current += 1;
    setSelected(newArr);
  });

  const onDropdownVisibleChange = useRefFunc((v: boolean) => {
    console.log("onDropdownVisibleChange", v);
    if (v) {
      setOpen(v);
    }
  });

  const onDeselect = useCallback(
    (v: string) => {
      console.log("onDeselect", v);
      const tmp = cloneDeep(selected.selectedArea);
      setSelected(tmp.filter((g) => g.key !== v));
    },
    [selected]
  );

  useEffect(() => {
    const handle = () => {
      setOpen(false);
    };
    document.addEventListener("click", handle);
    return () => {
      document.removeEventListener("click", handle);
    };
  }, []);

  return (
    <>
      <Select
        mode="multiple"
        open={open}
        style={{ minWidth: 200 }}
        dropdownMatchSelectWidth={false}
        value={selected.selectedOptions}
        onDeselect={(e) => onDeselect(e.value ?? e)}
        onDropdownVisibleChange={onDropdownVisibleChange}
        placeholder={placeholder}
        searchValue=""
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        dropdownRender={() => (
          <div
            style={{ paddingLeft: 5 }}
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
          >
            <CascaderArea
              setSelectedArea={handleChange}
              flightClass={1}
              isDemo={false}
              changeOnSelect={false}
              dropdownOpen={open}
              {...cascaderAreaProps}
            />
          </div>
        )}
      ></Select>
    </>
  );
};
export default SelectMultiAirports;
