import React, { useEffect, useState } from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Input, Popover, Row, Spin, Table, Typography } from "antd";
import useGlobal from "Store";
import { openDownloadDialog, sheet2blob, useFetch, XLSX } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import Refetch from "Components/Refetch";
import ValueNum from "Components/ValueNum";
import MonitorDetail from "../MonitorDetail";
import moment from "moment";
import DownloadBtn from "Components/DownloadBtn";
import _ from "lodash";
import { getServer } from "Service/server";

const { Text } = Typography;

interface MonitorTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  refetchCount: number;
}

const MonitorTable: React.FC<MonitorTableProps> = (
  props: MonitorTableProps
) => {
  const { queryUrl, moduleCode, chartTableCode, refetchCount } = props;
  const [searchText, setSearchText] = useState<string>("");
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, systemType } = globalState;
  const query = airlinesQueryCondition;
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      query,
    });
  };

  useEffect(() => {
    refetch();
  }, [query, refetchCount]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const columns = [
    {
      title: getSharkText("config_page_push_time"),
      dataIndex: "sendTime",
      key: "sendTime",
      width: 120,
      fixed: "left",
      sorter: (a: any, b: any) =>
        new Date(a.sendTime).getTime() - new Date(b.sendTime).getTime(),
      render: (val: string) =>
        val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : "",
    },
    {
      title: getSharkText("config_page_takeoff_time"),
      dataIndex: "takeoffdate",
      key: "takeoffdate",
      width: 120,
      fixed: "left",
      sorter: (a: any, b: any) =>
        new Date(a.takeoffdate.split(",")[0]).getTime() -
        new Date(b.takeoffdate.split(",")[0]).getTime(),
      render: (val: string) => val,
    },
    {
      title: getSharkText("config_page_return_time"),
      dataIndex: "arrivedate",
      key: "arrivedate",
      width: 120,
      fixed: "left",
      sorter: (a: any, b: any) =>
        new Date(a.arrivedate.split(",")[0]).getTime() -
        new Date(b.arrivedate.split(",")[0]).getTime(),
      render: (val: string) => val,
    },
    {
      title: getSharkText("config_page_origin_city"),
      dataIndex: "dcity",
      key: "dcity",
      sorter: (a: any, b: any) => a.dcity.localeCompare(b.dcity),
    },
    {
      title: getSharkText("config_page_arrival_city"),
      dataIndex: "acity",
      key: "acity",
      sorter: (a: any, b: any) => a.acity.localeCompare(b.acity),
    },
    {
      title: getSharkText("config_page_trip_type"),
      dataIndex: "tripType",
      key: "tripType",
      render: (val: string) =>
        val === "RT"
          ? getSharkText("config_page_round_trip")
          : getSharkText("config_page_one_way"),
    },
    {
      title: getSharkText("key.flightno.name"),
      dataIndex: "flightNo",
      key: "flightNo",
      render: (val: string) => val.replace(/\|$/, ""),
    },
    {
      title: getSharkText("config_page_ticket_airline"),
      dataIndex: "airlines",
      key: "airlines",
      sorter: (a: any, b: any) => a.airlines.localeCompare(b.airlines),
    },
    {
      title: getSharkText("config_page_cabin_class"),
      dataIndex: "flightClass",
      key: "flightClass",
      render: (val: string) => val.replace(/\|$/, ""),
    },
    {
      title: getSharkText("config_page_current_price"),
      dataIndex: "price",
      key: "price",
      sorter: (a: any, b: any) => a.price - b.price,
    },
    {
      title: () => (
        <Popover
          content={getSharkText(
            "config_page_based_on_30_days_lowest_price_reference"
          )}
        >
          <span>
            {getSharkText("config_page_reference_lowest_price")}&nbsp;
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </span>
        </Popover>
      ),
      width: 130,
      dataIndex: "last30price",
      key: "last30price",
      render: (val: string) => _.min(val.split(",")),
    },
    {
      title: () => (
        <Popover
          content={getSharkText(
            "config_page_accumulated_orders_within_24_hours"
          )}
        >
          <span>
            {getSharkText("config_page_order_count")}&nbsp;
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </span>
        </Popover>
      ),
      dataIndex: "orderCount",
      key: "orderCount",
      sorter: (a: any, b: any) => a.orderCount - b.orderCount,
    },
    {
      title: getSharkText("config_page_suggested_follow_index"),
      width: 130,
      dataIndex: "followIndex",
      key: "followIndex",
      render: (val: number) => <ValueNum type="percentage" value={val} />,
      sorter: (a: any, b: any) => a.followIndex - b.followIndex,
    },
    {
      title: getSharkText("config_page_details"),
      dataIndex: "",
      key: "detail",
      width: 80,
      fixed: "right",
      render: (val: any, record: any) =>
        record.id ? <MonitorDetail id={record.id} /> : "",
    },
  ];

  const tableData = data.map((item: any, idx: number) => {
    item.key = idx;
    return item;
  });

  const getFilterData = () => {
    const cloneData = _.cloneDeep(tableData);
    const filter = cloneData.filter((item: any) => {
      let isPass = false;
      for (const key of [
        "dcity",
        "acity",
        "flightNo",
        "airlines",
        "flightClass",
      ]) {
        if (item[key] && item[key].includes(searchText.toUpperCase())) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = getFilterData();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDownload = () => {
    const sheetData: any = [];
    filterData.forEach((item: any) => {
      const obj: any = {
        [getSharkText("config_page_push_time")]: item.sendTime,
        [getSharkText("config_page_takeoff_time")]: item.takeoffdate,
        [getSharkText("config_page_return_time")]: item.arrivedate,
        [getSharkText("config_page_origin_city")]: item.dcity,
        [getSharkText("config_page_arrival_city")]: item.acity,
        [getSharkText("config_page_trip_type")]: item.tripType,
        [getSharkText("key.flightno.name")]: item.flightNo.replace(/\|$/, ""),
        [getSharkText("config_page_ticket_airline")]: item.airlines,
        [getSharkText("config_page_cabin_class")]: item.flightClass.replace(
          /\|$/,
          ""
        ),
        [getSharkText("config_page_current_price")]: item.price,
        [getSharkText("config_page_reference_lowest_price")]: _.min(
          item.last30price.split(",")
        ),
        [getSharkText("config_page_order_count")]: item.orderCount,
        [getSharkText("config_page_suggested_follow_index")]: item.followIndex,
      };
      sheetData.push(obj);
    });
    const sheet = XLSX.utils.json_to_sheet(sheetData);
    const blob = sheet2blob(sheet);
    openDownloadDialog(
      blob,
      getSharkText("menu_price_monitor_airlines") + ".xlsx"
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Row>
        <Col>
          <Row>
            <Col style={{ width: 400 }}>
              <Input.Search
                value={searchText}
                placeholder={getSharkText(
                  "config_page_input_search_departure_arrival_flight_number_ticketing_airline_cabin"
                )}
                onChange={handleSearch}
              />
            </Col>
            <Col style={{ flex: 1, textAlign: "right" }}>
              <DownloadBtn
                handleDownload={handleDownload}
                moduleCode={moduleCode}
                chartTableCode={chartTableCode}
              />
            </Col>
          </Row>
        </Col>
        <Col style={{ marginTop: 16 }}>
          <Table
            showSorterTooltip={false}
            tableLayout="fixed"
            dataSource={filterData}
            scroll={{ x: 1450 }}
            // @ts-ignore
            columns={columns}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default MonitorTable;
