import React from "react";
import { Spin } from "antd";
import _, { round } from "lodash";
import { ISeries } from "Interface";
import { UserProfileChart } from "@ctrip/flt-bi-flightai-airlines";
import EchartsReactBase from "Components/EchartsReactBase";
import { getSharkText } from "Utils/i18nGlobal";

interface BarChartsProps {
  data: UserProfileChart[];
  height?: number;
  loading: boolean;
  seriesConfig: ISeries[];
  chartConfig: Record<string, unknown>;
}

const genChartData = (data: any[], seriesConfig: ISeries[]): any[] => {
  // 获取总量
  let totalValue = 0;
  data.forEach((d: UserProfileChart) => {
    totalValue += d.value || 0;
  });
  // 返回除了总量的每个值的占比
  const rst = data.reduce((total: any[], d: any) => {
    const v = d.value || 0;
    total.push({
      name:
        d.tagName === "unkown" ? getSharkText("key.unknown.name") : d.tagName,
      value: v / totalValue,
      cnt: parseFloat(d.value),
      ...seriesConfig.find((s) => s.tagCode === d.tagCode),
    });
    return total;
  }, []);
  return rst;
};

const SimpleBarCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const { data, height = 300, loading, seriesConfig, chartConfig } = props;
  const chartData = genChartData(data, seriesConfig);

  const option = {
    dataset: {
      source: chartData,
    },
    color: ["#177DFE", "#FC9B4F", "#01C5DB"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    grid: {
      show: true,
      top: 50,
      bottom: 50,
      left: 100,
      right: 100,
      backgroundColor: "#fff",
      borderWidth: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let dom = "";
        params.forEach((item: any) => {
          const name = item.data.name;
          const value = item.data.value;
          const valueStr = value === null ? "--" : `${round(value * 100, 2)}%`;
          const val = `${name}: ${valueStr}`;
          dom = val;
        });
        return dom;
      },
    },
    xAxis: {
      type: "value",
      show: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      splitNumber: 3,
      inverse: true,
      axisLabel: {
        overflow: "truncate",
        width: 80,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    series: [
      {
        type: "bar",
        encode: { x: "value", y: "name" },
        lineStyle: {
          width: 3,
        },
        label: {
          show: true,
          align: "left",
          // position: [20, 0],
          formatter: (v: any) => {
            const valueStr =
              v.data.value === null ? "--" : `${round(v.data.value * 100, 2)}%`;
            return `${valueStr}`;
          },
        },
      },
    ],
  };

  const mergedOption = chartConfig ? _.merge({}, option, chartConfig) : option;
  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={mergedOption}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default SimpleBarCharts;
