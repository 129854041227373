import React, { useCallback, useEffect, useState } from "react";
import {
  Col,
  DatePicker,
  Input,
  message,
  Radio,
  Row,
  Spin,
  Switch,
  Table,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { Module, ModuleStatus, UnitList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import { MODULE } from "Constants";
import ModuleHistory from "../ModuleHistory";
import ModuleEditButton from "../ModuleEditButton";
import _ from "lodash";
import { Unit } from "@ctrip/flt-bi-flightai-base";

interface IProps {
  curUnit: UnitList;
  mode: Array<"airport" | "airline" | "partner">;
  refetch: () => void;
}

const { RangePicker } = DatePicker;

const ModuleAuthorityList: React.FC<IProps> = (props: IProps) => {
  const { curUnit, mode, refetch } = props;
  const unit: Unit = curUnit.unit as Unit;
  const [changeList, setChangeList] = useState<Module[]>([]);
  const [isShow, setIsShow] = useState(false);
  const [curModuleId, setCurModuleId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);
  const [autoRenew, setAutoRenew] = useState<boolean>(unit.autoRenew === 1);
  const [renewDays, setRenewDays] = useState<number>(unit.renewDay || 90);

  // #region 切换用户组时更新自动续期和天数
  useEffect(() => {
    setAutoRenew(unit.autoRenew === 1);
    setRenewDays(unit.renewDay || 90);
  }, [unit.autoRenew, unit.renewDay]);
  // #endregion

  const [{ isLoading, res }, doFetch] = useFetch({
    url: "findModuleAuthorityList",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      groupId: curUnit.unit.id,
    },
    lazey: true,
  });
  const [{ isLoading: loadingAuto }, setAuto] = useFetch({
    url: "saveUnit",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {},
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_set_auto_renew_success"));
      refetch();
    },
    onError: () => {
      message.error(getSharkText("config_page_set_auto_renew_fail"));
    },
  });

  const saveAutoRenew = useCallback(() => {
    setAuto({
      ext: {
        unit: {
          ...unit,
          autoRenew: autoRenew ? 1 : 0,
          renewDay: renewDays,
        } as Unit,
      },
    });
  }, [setAuto, unit, autoRenew, renewDays]);

  const filterList = () => {
    let rst: Module[] = [];
    mode.forEach((m) => {
      switch (m) {
        case "airport":
          rst = rst.concat(
            changeList.filter(
              (item) => item.moduleType === 1 || !item.moduleType
            )
          );
          break;
        case "airline":
          rst = rst.concat(changeList.filter((item) => item.moduleType === 3));
          break;
        case "partner":
          rst = rst.concat(changeList.filter((item) => item.moduleType === 4));
          break;
      }
    });
    return rst;
  };

  const refetchModule = () => {
    doFetch({
      ext: {
        groupId: curUnit.unit.id,
      },
    });
  };

  useEffect(() => {
    setIsEdit(false);
    refetchModule();
  }, [curUnit]);

  useEffect(() => {
    if (res && res.moduleList) {
      setChangeList(_.cloneDeep(res.moduleList));
    }
  }, [res]);

  const isShowTime = (status: number) => status === 1 || status === 2;

  const handleShowHistory = (module: Module) => {
    if (module.id) {
      setCurModuleId(module.id);
      setIsShow(true);
    }
  };

  const resetModule = () => {
    setChangeList(_.cloneDeep(res.moduleList));
    setIsEdit(false);
  };

  const getModuleStatusTime = (status: ModuleStatus) => {
    switch (status) {
      case ModuleStatus.formal:
        return [moment(new Date()), moment(new Date()).add(365, "days")];
      case ModuleStatus.trial:
        return [moment(new Date()), moment(new Date()).add(14, "days")];
      default:
        return null;
    }
  };

  const handelChangeAllModuleStatus = (e: RadioChangeEvent) => {
    const list = [...changeList];
    list.map((item) => {
      item.moduleStatus = e.target.value;
      const time = getModuleStatusTime(e.target.value);
      if (time) {
        item.beginTime = time[0].format();
        item.endTime = time[1].format();
      }
      return item;
    });
    setChangeList(list);
  };

  const handleSelectAllRange = (dates: any) => {
    const list = [...changeList];
    list.forEach((item) => {
      item.beginTime = dates[0].format();
      item.endTime = dates[1].format();
    });
    setChangeList(list);
  };

  const handelChangeModuleStatus = (e: RadioChangeEvent, module: Module) => {
    const list = [...changeList];
    const idx = list.findIndex((item) => item.moduleCode === module.moduleCode);
    if (idx > -1) {
      list[idx].moduleStatus = e.target.value;
      const time = getModuleStatusTime(e.target.value);
      if (time) {
        list[idx].beginTime = time[0].format();
        list[idx].endTime = time[1].format();
      }
      setChangeList(list);
    }
  };

  const handleSelectRange = (
    dates: any,
    dateStrings: string[],
    module: Module
  ) => {
    const list = [...changeList];
    const idx = list.findIndex((item) => item.moduleCode === module.moduleCode);
    if (idx > -1) {
      list[idx].beginTime = dates[0].format();
      list[idx].endTime = dates[1].format();
      setChangeList(list);
    }
  };

  const disabledDate = (current: any) =>
    current < moment(new Date()).add(-1, "day");

  const columns = [
    {
      title: getSharkText("config_page_module_name"),
      dataIndex: "moduleName",
      key: "moduleName",
    },
    {
      title: getSharkText("config_page_permission_status"),
      dataIndex: "moduleStatus",
      width: 300,
      key: "moduleStatus",
      render: (value: ModuleStatus, record: Module) =>
        isEdit ? (
          <Radio.Group
            size="small"
            onChange={(e) => handelChangeModuleStatus(e, record)}
            value={value}
          >
            <Radio value={ModuleStatus.null}>
              {getSharkText("module.none.name")}
            </Radio>
            <Radio value={ModuleStatus.demo}>
              {getSharkText("config_page_demo")}
            </Radio>
            <Radio value={ModuleStatus.formal}>
              {getSharkText("module.official.name")}
            </Radio>
            <Radio value={ModuleStatus.trial}>
              {getSharkText("module.trial.name")}
            </Radio>
          </Radio.Group>
        ) : (
          MODULE[value]
        ),
    },
    {
      title: getSharkText("config_page_deadline"),
      key: "time",
      width: 250,
      render: (value: any, record: Module) =>
        isEdit ? (
          <>
            {isShowTime(record.moduleStatus) ? (
              <RangePicker
                disabledDate={disabledDate}
                size="small"
                format="YYYY-MM-DD"
                onChange={(dates, dateStrings) =>
                  handleSelectRange(dates, dateStrings, record)
                }
                value={[moment(record.beginTime), moment(record.endTime)]}
                allowClear={false}
              />
            ) : (
              "--"
            )}
          </>
        ) : (
          `${
            isShowTime(record.moduleStatus) &&
            record.beginTime &&
            record.endTime
              ? `${moment(record.beginTime).format("YYYY-MM-DD")} ~ ${moment(
                  record.endTime
                ).format("YYYY-MM-DD")}`
              : "--"
          }`
        ),
    },
    {
      title: getSharkText("config_page_remark"),
      dataIndex: "remark",
      key: "remark",
    },
    {
      title: getSharkText("config_page_history"),
      key: "history",
      render: (value: any, record: Module) =>
        record.id ? (
          <a onClick={() => handleShowHistory(record)}>查看</a>
        ) : undefined,
    },
  ];

  return (
    <>
      <Row align="middle">
        <Col>
          {!curUnit.unit.managerUnit && curUnit.unit.id ? (
            <ModuleEditButton
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              resetModule={resetModule}
              // changeList={changeList}
              changeList={filterList()}
              unitId={curUnit.unit.id}
              refetch={refetchModule}
              saveAutoRenew={saveAutoRenew}
            />
          ) : undefined}
        </Col>
        {isEdit ? (
          <>
            <Col>
              <Radio.Group
                size="small"
                onChange={handelChangeAllModuleStatus}
                style={{ marginLeft: 30 }}
              >
                <Radio value={3}>{getSharkText("module.none.name")}</Radio>
                <Radio value={0}>{getSharkText("config_page_demo")}</Radio>
                <Radio value={1}>{getSharkText("module.official.name")}</Radio>
                <Radio value={2}>{getSharkText("module.trial.name")}</Radio>
              </Radio.Group>
              <RangePicker
                style={{ marginLeft: 16 }}
                disabledDate={disabledDate}
                size="small"
                format="YYYY-MM-DD"
                allowClear={false}
                onChange={(dates) => handleSelectAllRange(dates)}
              />
            </Col>
            <Spin spinning={loadingAuto}>
              <Col>
                <Switch
                  checked={autoRenew}
                  checkedChildren={getSharkText("config_page_enable")}
                  unCheckedChildren={getSharkText("config_page_disable")}
                  onChange={setAutoRenew}
                />
                <span>
                  {getSharkText("config_page_auto_renewal_renewal_cycle")}
                </span>
                <Input
                  type="number"
                  bordered={false}
                  value={renewDays}
                  style={{ borderBottom: "1px solid #888", width: 60 }}
                  onChange={(e) => setRenewDays(parseInt(e.target.value, 10))}
                ></Input>
                <span>{getSharkText("key.day.button")}</span>
              </Col>
            </Spin>
          </>
        ) : undefined}
      </Row>
      <Table
        showSorterTooltip={false}
        loading={isLoading}
        tableLayout="fixed"
        columns={columns}
        dataSource={filterList()}
        size="middle"
        rowKey="moduleCode"
        pagination={false}
      />
      <ModuleHistory
        unitId={curUnit.unit.id}
        moduleId={curModuleId}
        visible={isShow}
        setVisible={setIsShow}
      />
    </>
  );
};

export default ModuleAuthorityList;
