import { IS_PRO } from "Constants";

export const DATASET_ID = IS_PRO ? 407 : 162;
export const ORIGIN_AIRPORT = "origin_airport";
export const TRANSIT_AIRPORT1 = "transit_airport1";
export const TRANSIT_AIRPORT2 = "transit_airport2";
export const DESTINATION_AIRPORT = "destination_airport";
export const TRANSIT_CLASS = "transit_class";
export const TAKEOFF_MONTH = "takeoffmonth";
export const AIRLINE_MARK = "airline_mark";
export const PAX = "pax";
export const PAX_MASKING = "pax_masking";
export const PRICE_TAX_SUM = "price_tax_sum";
export const PRICE_PERSONS_SUM = "price_persons_sum";
export const FLIGHT_MINUTES_SUM = "flight_minutes_sum";
export const FLIGHT_DURATION = "9ac50";
export const AVG_PRICE = "a1f1e";
export const ORI_CITYCODE = "ori_citycode";
export const ORI_PROVINCEID = "ori_provinceid";
export const ORI_COUNTRYCODE = "ori_countrycode";
export const TRANSIT_1_CITYCODE = "transit_1_citycode";
export const TRANSIT_1_PROVINCEID = "transit_1_provinceid";
export const TRANSIT_1_COUNTRYCODE = "transit_1_countrycode";
export const TRANSIT_2_CITYCODE = "transit_2_citycode";
export const TRANSIT_2_PROVINCEID = "transit_2_provinceid";
export const TRANSIT_2_COUNTRYCODE = "transit_2_countrycode";
export const DEST_CITYCODE = "dest_citycode";
export const DEST_PROVINCEID = "dest_provinceid";
export const DEST_COUNTRYCODE = "dest_countrycode";
