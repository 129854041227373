import React, { useEffect, useState } from "react";
import { SwapRightOutlined } from "@ant-design/icons";
import { Button, Col, message, Row } from "antd";
import useGlobal from "Store";
import CascaderArea from "Components/CascaderArea";
import SelectCompareAirport from "../SelectCompareAirport";
import { AirportCompareQueryExt, Area, CompareAirport } from "Interface";
import { DEFAULT_AREA } from "Constants";
import { getModuleNameFromPath, getModuleStatus } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { useLocation } from "react-router-dom";

interface SearchAirportProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  setAirportCompareQueryExt: (ext: AirportCompareQueryExt) => void;
}

const SearchAirport: React.FC<SearchAirportProps> = (
  props: SearchAirportProps
) => {
  const { setAirportCompareQueryExt, queryUrl, moduleCode, chartTableCode } =
    props;
  const location = useLocation();
  const [compareAirport, setCompareAirport] = useState<CompareAirport | null>(
    null
  );
  const [arriveArea, setArriveArea] = useState<Area>(DEFAULT_AREA);
  const [loading, setLoading] = useState(false);
  const [globalState] = useGlobal();
  const { userInfo, queryCondition, systemType } = globalState;
  const { airportName, flightClass } = queryCondition;
  const { pathname } = location;
  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false));
  }, [flightClass]);

  const handleSearch = () => {
    if (compareAirport) {
      setAirportCompareQueryExt({
        compareAirport,
        area: arriveArea,
      });
    } else {
      message.warning(getSharkText("key.choose_airport_compare"));
    }
  };

  return (
    <Row align="middle" gutter={10} style={{ marginTop: 16 }}>
      <Col>{airportName}</Col>
      <Col>
        <strong style={{ margin: "0 10px" }}>PK</strong>
      </Col>
      <Col>
        <SelectCompareAirport
          isDemo={isDemo}
          // 因为修改为对所有人开放所有机场, 这里将携程用户设置为true
          isCtriper={true}
          queryUrl={queryUrl}
          moduleCode={moduleCode}
          chartTableCode={chartTableCode}
          setCompareAirport={setCompareAirport}
        />
      </Col>
      <Col>
        <SwapRightOutlined />
      </Col>
      <Col>
        {loading ? undefined : (
          <CascaderArea
            type="airport"
            setSelectedArea={setArriveArea}
            flightClass={flightClass}
            isDemo={isDemo}
          />
        )}
      </Col>
      <Col>
        <Button id="areaSearchBtn" type="primary" onClick={handleSearch}>
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};

export default SearchAirport;
