export const MODULE_CODE: any = {
  1: "transfer_analysis_airport",
  2: "transfer_analysis_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    map: "AP0901",
    cards: "AP0902",
    barline: "AP0903",
    top: "AP0904",
    table: "AP0905",
    advantage: "AP0906",
    alert: "AP0907",
  },
  2: {
    barline: "AL0901",
    bar: "AL0902",
    card: "AL0903",
    top: "AL0904",
    table: "AL0905",
    advantage: "AL0906",
    alert: "AL0907",
  },
};

export const QUERY_URL: any = {
  1: {
    map: "queryTransferAnalysisMap",
    cards: "queryTransferAnalysisCard",
    barline: "queryTransferAnalysisBarline",
    top: "queryTransferAnalysisTop",
    table: "queryTransferAnalysisTable",
    advantage: "queryTransferAnalysisRoute",
    alert: "queryTransferAnalysisAlert",
  },
  2: {
    barline: "queryTransferAnalysisBarlineNew",
    bar: "queryTransferAnalysisBarNew",
    card: "queryTransferAnalysisCardNew",
    top: "queryTransferAnalysisTopNew",
    table: "queryTransferAnalysisTableNew",
    advantage: "queryTransferAnalysisRoute",
    alert: "queryTransferAnalysisAlert",
  },
};
