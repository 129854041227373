import { IS_PRO } from "Constants";

export const DATASET_ID = IS_PRO ? 337 : 99;

export const D = "d";
export const PRI_FLIGHTNO = "pri_flightno";
export const FLIGHTNO1 = "flightno1";
export const FLIGHTNO2 = "flightno2";
export const PRI_TAKEOFFDATE = "pri_takeoffdate";
export const TAKEOFFDATE = "takeoffdate";
export const DCITYNAME = "dcityname";
export const ACITYNAME = "acityname";
export const DCITYCODE = "dcitycode";
export const ACITYCODE = "acitycode";
export const DPORT = "dport";
export const TPORT = "tport";
export const APORT = "aport";
export const FLIGHTNO_BENCHMARK = "flightno_benchmark";
export const RAOHANG = "raohang";
export const DPORT_BG = "dport_bg";
export const TPORT_BG = "tport_bg";
export const APORT_BG = "aport_bg";
export const TRANSIT_PERSONS = "transit_persons";
export const TRANSIT_PERSONS_FIX = "transit_persons_fix";
export const PRICE1 = "price1";
export const PRICE2 = "price2";
export const PRICE_DIFF = "price_diff";
export const PRICE_DIFF_FIX = "price_diff_fix";
export const TRANSIT_MINUTES_DIFF = "transit_minutes_diff";
export const POTENTIAL = "potential";
export const RK = "rk";
export const PRI_DPORT = "pri_dport";
export const PRI_APORT = "pri_aport";
export const FLIGHTNO_GROUP = "flightno_group";
export const PORT_GROUP = "port_group";
export const PORT_BG_GROUP = "port_bg_group";
