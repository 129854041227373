import { Col, Radio, Row } from "antd";
import { ISeries } from "Interface";
import { IFlightReviewFilter } from "Page/AI/FlightReview/ReviewCommon";
import React, { ReactElement, useState } from "react";
import ProcessArea from "./ProcessArea";
import ProcessStack from "./ProcessStack";
import { getSharkText } from "Utils/i18nGlobal";

export interface IProcessProps {
  queryExt?: IFlightReviewFilter;
}

const stackSeries: ISeries[] = [
  {
    id: 3,
    name: "D7-D14",
    type: "bar",
    stack: "stack",
    encode: {
      x: "day",
      y: "2value",
    },
  },
  {
    id: 2,
    name: "D3-D6",
    type: "bar",
    stack: "stack",
    encode: {
      x: "day",
      y: "1value",
    },
  },
  {
    id: 1,
    name: "D0-D2",
    type: "bar",
    stack: "stack",
    encode: {
      x: "day",
      y: "0value",
    },
  },
];

const areaSeries: ISeries[] = Array.from({ length: 15 }, (v, i) => {
  return {
    name: `D${14 - i}`,
    type: "line",
    stack: "total",
    // 这是一个flightAi自定义的echarts配置, 在lib/dataStackReplace.js文件中实现此功能, 设置为true时将正负数堆叠在Y轴同一侧, 不设置为echarts默认操作
    ___stackInOne: true,
    areaStyle: {},
    encode: {
      x: "day",
      y: `${14 - i}value`,
    },
  };
});

/**
 * 航班复盘-销售进度-订座数
 */
const Process = (props: IProcessProps): ReactElement => {
  const { queryExt } = props;
  const [aggCode, setAggCode] = useState<0 | 1>(1);
  return (
    <div>
      <span style={{ fontSize: "160%" }}>
        {getSharkText("config_page_sales_process_seat_reservation")}
      </span>
      <div>
        <Radio.Group
          value={aggCode}
          onChange={(e) => setAggCode(e.target.value)}
        >
          <Radio.Button value={1}>
            {getSharkText("config_page_compare_previous_four_weeks")}
          </Radio.Button>
          <Radio.Button value={0}>
            {getSharkText("config_page_compare_previous_seven_days")}
          </Radio.Button>
        </Radio.Group>
      </div>
      <Row>
        <Col span={12}>
          <ProcessStack
            ext={queryExt}
            type={0}
            aggCode={aggCode}
            defaultSeries={stackSeries}
          />
        </Col>
        <Col span={12}>
          <ProcessArea
            ext={queryExt}
            type={1}
            aggCode={aggCode}
            defaultSeries={areaSeries}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Process;
