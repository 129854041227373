import React, { ReactElement, useMemo } from "react";
import { LEVEL } from "./interface";
import { Area, FlightClass } from "Interface";
import MytrixCitySelectorCore from "./MytrixCitySelectorCore";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { AREAS_CONFIG } from "./cols";

export interface IMytrixCitySelectorProps {
  levels?: LEVEL[];
  flightClass?: FlightClass;
  onChange?: (item: Area | null) => void;
}

/**
 * 基于Mytrix查询的城市选择器
 */
const MytrixCitySelector = (props: IMytrixCitySelectorProps): ReactElement => {
  const {
    levels = ["region", "province", "city"],
    flightClass,
    onChange,
  } = props;

  const filters = useMemo(() => {
    if (flightClass === FlightClass.Domestic) {
      const tmpFilters: StandardFilter[] = [
        {
          in: {
            field: `dimension.${AREAS_CONFIG.country.code}`,
            values: ["CN"],
          },
        },
      ];
      return tmpFilters;
    }
    return undefined;
  }, [flightClass]);

  return (
    <MytrixCitySelectorCore
      filters={filters}
      levels={levels}
      onChange={onChange}
    />
  );
};
export default MytrixCitySelector;
