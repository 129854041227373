// Created by xh_zhu on 2021-03-24

import { DATE_FORMAT } from "Constants";
import { FlightArea, Area, IDownloadHeader } from "Interface";
import { isEmpty, round } from "lodash";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import { showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import CarryOutRateCharts from "./CarryOutRateCharts";

/**
 * Component description
 * 整体市场预计航班执行率
 */

interface ITotalCarryOutRateProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  area: Area;
  height?: number;
  style?: object;
  airlinesOnsaleAirlineExt: FlightArea;
  ext: any;
}

const TotalCarryOutRate = (props: ITotalCarryOutRateProps): ReactElement => {
  const [compareType, setCompareType] = useState<number>(-1);
  // const { } = props;
  const totalSeries = [
    {
      type: "line",
      name: getSharkText("key.estimated_flight_execution_rate"),
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "day",
        y: "value",
      },
    },
    {
      type: "line",
      name: getSharkText("key.estimated_compared_flight_ex_rate"),
      showSymbol: false,
      symbol: "circle",
      encode: {
        x: "day",
        y: "compareValue",
      },
    },
  ];

  const downloadColumns: IDownloadHeader[] = [
    {
      title: getSharkText("config_page_time"),
      dataIndex: "day",
      downloadFormatter: (val: any) => moment(val).format(DATE_FORMAT),
    },
    {
      title: getSharkText("key.estimated_flight_execution_rate"),
      dataIndex: "value",
      downloadFormatter: (val: number) => showRawNum(val, "percentage"),
    },
  ];

  if (compareType !== -1) {
    downloadColumns.push({
      title: getSharkText("key.estimated_compared_flight_ex_rate"),
      dataIndex: "compareValue",
      downloadFormatter: (val: number) => showRawNum(val, "percentage"),
    });
  }

  const chartsOption = {
    yAxis: [
      {
        type: "value",
        min: (value: any) => {
          return round(value.min * 0.95);
        },
        axisLabel: {
          formatter: "{value}%",
        },
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const dayIndex = params[0].dimensionNames.indexOf("day");
        const day = moment(params[0].data[dayIndex]).format(DATE_FORMAT);
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const value = item.value[item.encode.y];
          if (value !== null) {
            const val = `${item.seriesName}: ${showNum(value, "percentage")}`;
            dom = dom + "<br />" + dot + val;
          }
        });
        if (isEmpty(dom)) {
          return null;
        }
        dom = day + dom;
        return dom;
      },
    },
  };

  return (
    <CarryOutRateCharts
      title={getSharkText("key.domestic_est_ex_rate")}
      {...props}
      queryUrl="queryOnsaleFlightsAirportExecution"
      totalSeries={totalSeries}
      downloadColumns={downloadColumns}
      chartsOption={chartsOption}
      onCompareChange={setCompareType}
    />
  );
};
export default TotalCarryOutRate;
