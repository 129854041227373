import React, { useMemo } from "react";
import { Radio, Select } from "antd";
import { SystemType } from "Interface";
import { SYSTEM_TYPES } from "Constants";
import { ONLY_AIRLINE_PAGE, ONLY_AIRPORT_PAGE } from "Config/PageConfig";
import { useLocation } from "react-router-dom";
import { getSharkText } from "Utils/i18nGlobal";

interface TypeProps {
  systemType: SystemType;
  changeSystemType: (type: SystemType) => void;
}

const { Option } = Select;

const SelectSystemType: React.FC<TypeProps> = (props: TypeProps) => {
  const { systemType, changeSystemType } = props;
  const location = useLocation();

  const { pathname } = location;
  const isOnlyAirport = ONLY_AIRPORT_PAGE.includes(pathname);
  const isOnlyAirline = ONLY_AIRLINE_PAGE.includes(pathname);

  if (isOnlyAirline && systemType === SystemType.airport) {
    changeSystemType(SystemType.airlines);
  }
  if (isOnlyAirport && systemType === SystemType.airlines) {
    changeSystemType(SystemType.airport);
  }

  const handleChange = (value: number) => {
    changeSystemType(value);
  };

  const options = useMemo(() => {
    return SYSTEM_TYPES.map((item) =>
      // <Option className={item.className} key={item.value} value={item.value}>
      //   {item.name}
      //   {getSharkText("key.version.text")}
      // </Option>
      ({
        label: item.name + getSharkText("key.version.text"),
        value: item.value,
      })
    );
  }, []);

  return (
    <Radio.Group
      disabled={isOnlyAirline || isOnlyAirport}
      // dropdownClassName="system-type"
      options={options}
      optionType="button"
      buttonStyle="solid"
      value={systemType}
      style={{ minWidth: 90, marginRight: 16 }}
      onChange={(e) => handleChange(e.target.value)}
    >
      {/* {SYSTEM_TYPES.map((item) => (
        <Option className={item.className} key={item.value} value={item.value}>
          {item.name}
          {getSharkText("key.version.text")}
        </Option>
      ))} */}
    </Radio.Group>
  );
};

export default SelectSystemType;
