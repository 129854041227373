// Created by xh_zhu on 2021-01-29

import { Modal } from "antd";
import { IFlightRecord } from "Page/AI/FlightManage/FlightManageInterface";
import React, { useEffect, useImperativeHandle, useState } from "react";
import "./PriceTrend.scss";
import PriceTrendContent from "./PriceTrendContent";
import useRefFunc from "Utils/useRefFunc";

interface IPriceTrendProps {
  record: IFlightRecord | undefined;
  /** 所有可切换航班 */
  allRecords: IFlightRecord[];
  setRecord: (r: IFlightRecord) => void;
  /** 设置选中航班 */
  setSelectedRecord: (r: IFlightRecord) => void;
  isDemo: boolean;
}

const PTrend = (props: IPriceTrendProps, ref: any) => {
  const { record, allRecords, setRecord, setSelectedRecord, isDemo } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<{
    idx: number;
    r: IFlightRecord | undefined;
  }>(() => {
    const idx = allRecords.findIndex((a) => a.id === record?.id);
    return { idx, r: record };
  });
  useEffect(() => {
    const idx = allRecords.findIndex((a) => a.id === record?.id);
    setSelected({
      idx,
      r: record,
    });
  }, [allRecords, record]);

  useImperativeHandle(ref, () => ({
    show: () => {
      setModalVisible(true);
    },
  }));

  const onNext = useRefFunc(() => {
    if (selected.idx < allRecords.length - 1) {
      setSelected({
        idx: selected.idx + 1,
        r: allRecords[selected.idx + 1],
      });
    }
  });

  const onPrev = useRefFunc(() => {
    if (selected.idx > 0) {
      const prevIdx = selected.idx - 1;
      setSelected({
        idx: prevIdx,
        r: allRecords[prevIdx],
      });
    }
  });

  const closeDetailModal = () => {
    if (selected.r && selected.r.id !== record?.id) {
      setSelectedRecord(selected.r);
    }
    setModalVisible(false);
  };

  return selected.r ? (
    <Modal
      className="flightmanage-price-trend-modal"
      open={modalVisible}
      onCancel={closeDetailModal}
      footer={null}
      // 因为modal内全屏也是esc退出, 并且比fullScreen先触发. 无法截断
      keyboard={false}
      destroyOnClose
      style={{ minWidth: 1280, height: "80%", minHeight: 700 }}
    >
      <PriceTrendContent
        record={selected.r}
        setModalVisible={setModalVisible}
        setRecord={setRecord}
        onNext={onNext}
        onPrev={onPrev}
        isDemo={isDemo}
        currentIdx={selected.idx}
        totalSize={allRecords.length}
      />
    </Modal>
  ) : (
    <></>
  );
};
const PriceTrend = React.forwardRef(PTrend);
export default PriceTrend;
