import { Area, ECompareTypeV2, FlightArea } from "Interface";

export interface ITopSearchAreaVal {
  riseControl: string[];
}

export interface ITransferAnalysisQueryExt {
  yoy:
    | ECompareTypeV2.YEAR
    | ECompareTypeV2.WEEK
    | ECompareTypeV2.YEAR_BEFORE_LAST
    | ECompareTypeV2.YEAR_LAST_LAST
    | ECompareTypeV2.YEAR_4_BEFORE
    | ECompareTypeV2.YEAR_5_BEFORE;
  wow: 0 | 1 | 2;
  area: Area;
}

export interface ITransferAnalysisExt {
  query: ITransferAnalysisQueryExt;
  filter?: FlightArea;
  riseControl: string[];
}

export const PERCENT_VALUES = [
  "vs_same_airline",
  "vs_all_transit",
  "vs_all_direct",
  "vs_od_transit",
];
export const DURATION_VALUES = ["transit_minutes"];
