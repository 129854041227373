import { Alert, Tabs } from "antd";
import Page from "Layout/Page";
import React, { ReactElement, useMemo } from "react";
import FlightManageDrawer from "../FlightManage/FlightManageDrawer";
import FlightPanel from "./Components/FlightPanel";
import SegmentPanel from "./Components/SegmentPanel";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * 航班管理-航班复盘-航段数据
 */
const FlightSegment = (): ReactElement => {
  const panels = useMemo(
    () => [
      {
        label: getSharkText("config_page_segment_data"),
        key: "segment",
        children: <SegmentPanel />,
      },
      {
        label: getSharkText("config_page_flight_data"),
        key: "flight",
        children: <FlightPanel />,
      },
    ],
    []
  );

  return (
    <Page defaultDateMode="none" needToolBar={false}>
      <div
        className="content-white"
        style={{ marginTop: 0, paddingTop: 0, paddingBottom: 0 }}
      >
        <Alert
          message={getSharkText("config_page_data_range_only_china_airports")}
          banner
          type="info"
          showIcon
          style={{ margin: "0px -15px" }}
        />
        <Tabs size="large" items={panels} />
      </div>
      <FlightManageDrawer />
    </Page>
  );
};
export default FlightSegment;
