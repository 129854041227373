import { InputNumber, Table } from "antd";
import { IDownloadHeader } from "Interface";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import "./Adjust.css";
import { arrayUpsert } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import { cloneDeep, round } from "lodash";
import {
  FlightInfoDetailWithKey,
  IntervalEditing,
  IntervalInventoryInfo,
  IntervalTableRow,
  IntervalWithEdited,
} from "../../interface";

interface IProps {
  record: FlightInfoDetailWithKey;
  intervals: IntervalInventoryInfo[];
}

export interface CabinAdjustHandle {
  getAdjusting: () => IntervalEditing[];
  reset: () => void;
}

/** 调舱工具 */
const AdjustCabin = forwardRef(
  (props: IProps, ref: Ref<CabinAdjustHandle>): ReactElement => {
    const { record, intervals } = props;
    // 当前所有的改动
    const [adjusting, setAdjusting] = useState<IntervalEditing[]>([]);

    useEffect(() => {
      setAdjusting([]);
    }, [record, intervals]);

    useImperativeHandle(ref, () => ({
      getAdjusting: () => adjusting,
      reset: () => {
        setAdjusting([]);
      },
    }));

    const [displayInterval, setDisplayInterval] =
      useState<IntervalWithEdited[]>();

    useEffect(() => {
      const tmpDisplayInterval: IntervalTableRow[] = intervals.map((ori) => {
        const adjustingItem = adjusting.find(
          (adjust) => adjust.code === ori.code
        );
        const item: IntervalTableRow = {
          ...ori,
          newTotalInventory: adjustingItem?.newTotalInventory,
        };
        return item;
      });
      // 计算累计可售
      let oriSum = 0;
      let newSum = 0;
      const cumulative = tmpDisplayInterval.reverse().map((r) => {
        oriSum += r.remainingInventory || 0;
        newSum +=
          (r.newTotalInventory ?? r.totalInventory ?? 0) -
          (r.soldInventory ?? 0);
        const item: IntervalWithEdited = {
          ...r,
          oriCumulative: oriSum,
          newCumulative: newSum,
        };
        return item;
      });
      setDisplayInterval(cumulative.reverse());
    }, [adjusting, intervals]);

    const handleNumChange = useRefFunc((val: number, r: IntervalTableRow) => {
      let adjustingItem = adjusting.find((a) => a.code === r.code);
      if (!adjustingItem) {
        adjustingItem = { code: r.code, newTotalInventory: val };
      } else {
        adjustingItem = cloneDeep(adjustingItem);
        adjustingItem.newTotalInventory = val;
      }
      setAdjusting(
        arrayUpsert(adjusting, adjustingItem, (a) => a.code === r.code)
      );
    });

    const checkIsLowestPriceRow = useRefFunc((r: IntervalTableRow): boolean => {
      const start = (r.intervalStart ?? 0) * (record.fullPrice ?? 0);
      const end = (r.intervalEnd ?? 0) * (record.fullPrice ?? 0);
      const isLowestPriceRow = record.minDisplayPrice
        ? start <= record.minDisplayPrice && end >= record.minDisplayPrice
        : false;
      return isLowestPriceRow;
    });

    const columns: Array<IDownloadHeader<IntervalWithEdited>> = useMemo(
      () => [
        {
          title: "编号",
          // dataIndex: "cabin",
          dataIndex: "code",
          width: 60,
          render: (v, r: IntervalTableRow) => {
            return checkIsLowestPriceRow(r) ? (
              <span style={{ fontWeight: "bolder" }}>{v}</span>
            ) : (
              v
            );
          },
        },
        {
          title: "折扣区间",
          dataIndex: "intervalStart",
          key: "discountInterval",
          render: (val, r) => {
            const v = `${r.intervalStart} ~ ${r.intervalEnd}`;
            return checkIsLowestPriceRow(r) ? (
              <span style={{ fontWeight: "bolder" }}>{v}</span>
            ) : (
              v
            );
          },
        },
        {
          title: "价格",
          key: "price",
          dataIndex: "feNone",
          width: 160,
          render: (val, r) => {
            const fullPrice = record.fullPrice ?? 0;
            const v = `${round((r.intervalStart ?? 0) * fullPrice)} ~ ${round(
              (r.intervalEnd ?? 0) * fullPrice
            )}`;
            return checkIsLowestPriceRow(r) ? (
              <span style={{ fontWeight: "bolder" }}>{v}</span>
            ) : (
              v
            );
          },
        },
        {
          title: "已售",
          dataIndex: "soldInventory",
        },
        {
          title: "库存",
          dataIndex: "totalInventory",
          width: 100,
          render: (value, r: IntervalTableRow) => {
            const oriVal = r.totalInventory;
            const newVal = r.newTotalInventory;
            const val = newVal ?? oriVal;
            const color = newVal === oriVal ? "" : "red";
            return (
              <InputNumber
                className="adjust-cabin-number"
                size="small"
                style={{
                  width: 60,
                  color,
                  transition: "all 0.0s linear",
                }}
                min={`${0}`}
                value={val}
                onChange={(v) => handleNumChange(Number(v), r)}
                formatter={() => {
                  if (val !== oriVal) {
                    return `${val}(${oriVal})`;
                  }
                  return `${val}`;
                }}
                parser={(v?: string) => {
                  if (v) {
                    try {
                      return parseInt(v, 10);
                    } catch (e) {
                      return v;
                    }
                  }
                  return "0";
                }}
              />
            );
          },
        },
        {
          title: "可售",
          // dataIndex: "suggestSold",
          dataIndex: "actions",
          render: (value, r: IntervalWithEdited) => {
            if (r.newCumulative !== r.oriCumulative) {
              return (
                <span style={{ color: "red" }}>
                  {r.newCumulative}({r.oriCumulative})
                </span>
              );
            }
            return r.oriCumulative;
          },
        },
      ],
      [checkIsLowestPriceRow, handleNumChange, record.fullPrice]
    );

    return (
      <div
        style={{
          background: "#fff",
        }}
      >
        <Table
          columns={columns}
          dataSource={displayInterval}
          pagination={false}
          size="small"
          sticky
        ></Table>
      </div>
    );
  }
);
AdjustCabin.displayName = "AdjustCabin";
export default AdjustCabin;
