import React, { ReactElement, useEffect, useMemo, useState } from "react";
import Page from "Layout/Page";
import useGlobal from "Store";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import Panel from "./Components/Panel";
import AirportPanel from "./Components/AirportPanel";
import RefundPanel from "./Components/RefundPanel";
import { AirlinesQueryCondition, Area, SystemType } from "Interface";
import SelectMultiAirports from "Components/SelectMultiAirports";
import { EMPTY_ARRAY, EMPTY_OBJECT } from "Constants";
import { Col, Radio, Tooltip } from "antd";
import { CascaderAreaProps } from "Components/CascaderArea";

const IndustryTrend = (): ReactElement => {
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const isAirport = isAirportMode(systemType);
  const [, setLoading] = useState(false);
  const [selectedAirports, setSelectedAirports] = useState<Area[]>(EMPTY_ARRAY);
  const [inOutDisabled, setInOutDisabled] = useState<boolean>(true);
  const [inOut, setInOut] = useState<"dep" | "arr">("dep");
  const [airportsExt, setQueryExt] = useState<Partial<AirlinesQueryCondition>>(
    {}
  );

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setTimeout(() => setLoading(false));
  }, [queryCondition.flightClass, airlinesQueryCondition.flightClass]);

  // #region 不选中机场时, 不能选进出港
  useEffect(() => {
    setInOutDisabled(!selectedAirports.length);
  }, [selectedAirports.length]);
  // #endregion

  // #region 生成机场和进出港扩展查询参数
  useEffect(() => {
    if (selectedAirports.length === 0) {
      setQueryExt(EMPTY_OBJECT);
    } else {
      setQueryExt({
        areaList: selectedAirports as any[],
        departure: inOut === "dep" ? 1 : 0,
        arrive: inOut === "arr" ? 1 : 0,
      });
    }
  }, [inOut, selectedAirports]);
  // #endregion

  const cascaderAreaProps: Partial<CascaderAreaProps> = useMemo(
    () => ({
      type: "city",
      changeOnSelect: true,
      placeholder: "请选择城市",
      useDomesticArea: true,
    }),
    []
  );

  const extendTools = useMemo(() => {
    return systemType === SystemType.airlines ? (
      <Col>
        <Tooltip
          placement="leftTop"
          title={getSharkText("config_page_industry_tooltip_title")}
        >
          <SelectMultiAirports
            limitedCnt={3}
            onChange={setSelectedAirports}
            cascaderAreaProps={cascaderAreaProps}
          />
          <Radio.Group
            value={inOut}
            onChange={(e) => setInOut(e.target.value)}
            disabled={inOutDisabled}
            style={{ marginLeft: 10 }}
          >
            <Radio value="dep">{getSharkText("key.departure.name")}</Radio>
            <Radio value="arr">{getSharkText("key.arrival.name")}</Radio>
          </Radio.Group>
        </Tooltip>
      </Col>
    ) : undefined;
  }, [inOut, inOutDisabled, systemType]);

  return (
    <Page
      defaultDateMode="none"
      airlinesCompareType={1}
      isShowCompareType={false}
      isShowInOut={isAirport}
      useAirportGroup={true}
      extendTools={extendTools}
    >
      {isAirport ? (
        <>
          <AirportPanel panelType={0} />
          <RefundPanel />
          <AirportPanel panelType={1} />
        </>
      ) : (
        <>
          <Panel panelType={0} airportsExt={airportsExt} />
          <Panel panelType={1} airportsExt={airportsExt} />
        </>
      )}
    </Page>
  );
};

export default IndustryTrend;
