import CheckButtons from "Components/CheckButtons/CheckButtons";
import { WEEK_OPTIONS } from "Constants";
import { RangeObject } from "Interface";
import { Col, DatePicker, message, Row, Select } from "antd";
import moment from "moment";
import React, {
  forwardRef,
  Key,
  ReactElement,
  Ref,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";

export interface CopyUserInputHandler {
  getValue: () => {
    startDate: string;
    endDate: string;
    routes: string[];
    flights: string[];
    schedule: Key[];
  } | null;
}

export interface CopyUserInputProps {
  startDate: string;
  endDate: string;
  allFlights: string[];
  allRoutes: string[];
}

/**  Component description */
const CopyUserInput = forwardRef(
  (props: CopyUserInputProps, ref: Ref<CopyUserInputHandler>): ReactElement => {
    const { startDate, endDate, allFlights, allRoutes } = props;
    const [range, setRange] = useState<RangeObject>([
      moment(startDate),
      moment(endDate),
    ]);
    const [routes, setRoutes] = useState<string[]>(allRoutes);
    const [flights, setFlights] = useState<string[]>(allFlights);
    const [schedule, setSchedule] = useState<Key[]>(
      WEEK_OPTIONS.map((r) => r.value)
    );
    useImperativeHandle(ref, () => ({
      getValue: () => {
        if (!routes.length) {
          message.error("请选择航段");
        }
        if (!flights.length) {
          message.error("请选择航班");
        }
        if (!schedule.length) {
          message.error("请选择班期");
        }
        if (range && range.length === 2) {
          return {
            startDate: range[0]?.format("YYYY-MM-DD") || "",
            endDate: range[1]?.format("YYYY-MM-DD") || "",
            routes,
            flights,
            schedule,
          };
        }
        return null;
      },
    }));
    const routeOptions = useMemo(() => {
      return allRoutes.map((r) => ({ label: r, value: r }));
    }, [allRoutes]);
    const flightOptions = useMemo(() => {
      return allFlights.map((r) => ({ label: r, value: r }));
    }, [allFlights]);
    return (
      <div>
        <Row gutter={[10, 20]}>
          <Col span={6}>时间段</Col>
          <Col span={18}>
            <DatePicker.RangePicker
              value={range}
              onChange={setRange}
              disabledDate={(c) =>
                c.isBefore(moment(startDate)) || c.isAfter(moment(endDate))
              }
            />
          </Col>
          <Col span={6}>班期</Col>
          <Col span={18}>
            <CheckButtons
              options={WEEK_OPTIONS}
              value={schedule}
              onChange={setSchedule}
            />
          </Col>
          <Col span={6}>航段</Col>
          <Col span={18}>
            <Select
              options={routeOptions}
              value={routes}
              onChange={setRoutes}
              size="small"
              mode="multiple"
            ></Select>
          </Col>
          <Col span={6}>航班</Col>
          <Col span={18}>
            <Select
              options={flightOptions}
              value={flights}
              size="small"
              onChange={setFlights}
              mode="multiple"
            ></Select>
          </Col>
        </Row>
      </div>
    );
  }
);
export default CopyUserInput;
