import React from "react";
import Form from "Components/Official/Form";
import { ReactComponent as IconWechat } from "assets/svg/icon-wechat.svg";
import { ReactComponent as IconMail } from "assets/svg/icon-mail.svg";
import webchat from "assets/images/wechat.png";
import "./index.scss";

const ContactUs: React.FC = () => {
  return (
    <div id="contactUs" className="base-mode contact-mode">
      <div className="base-con">
        <h2 className="contact-mode-title">联系我们</h2>
        <h5 className="contact-mode-hint">
          如果您有任何问题或建议，请填写右侧信息，我们将在第一时间与您取得联系。
        </h5>
        <div className="contact-form">
          <Form verifyId="verify-code-contact-us" />
          <div className="contact-other">
            <h6>
              <span>其他联系方式</span>
            </h6>
            <p>
              <IconMail className="other-icon" />
              flightai@trip.com
            </p>
            <p>
              <IconWechat className="other-icon" />
              FlightAI航空智能大数据
            </p>
            <img src={webchat} alt="微信公众号" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
