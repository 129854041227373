import { List, Pagination, Typography } from "antd";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { showNum } from "Utils";
import "./RankingV2.scss";
import { IProps, Item } from "./RankingV2Interface";
import { min } from "lodash";
import useMergedState from "Utils/useMergedState";

const { Text } = Typography;

export interface IRankingV2ViewProps extends IProps {
  /**
   * 数据列表
   */
  data: Item[];
}

/**
 * Ranking的视图组件
 */
const RankingV2View = (props: IRankingV2ViewProps): ReactElement => {
  const {
    data,
    formatter = "int",
    value,
    onClick,
    useSelected = false,
    usePagination = false,
  } = props;
  const [innerValue, setInnerValue] = useMergedState<Item | undefined>(
    undefined,
    { value }
  );
  const [current, setCurrent] = useState<number>(1);
  const showingData = useMemo(() => {
    if (usePagination) {
      const start = (current - 1) * 10;
      const end = min([current * 10, data.length]);
      if (start > data.length) {
        setCurrent(0);
      }
      return data.slice(start, end);
    } else {
      return data;
    }
  }, [current, data, usePagination]);
  /**
   * 检测data类型, 如果缺失字段则报警
   */
  useEffect(() => {
    if (data && data.length) {
      const item = data[0];
      if (item.name === undefined || item.id === undefined) {
        console.warn("ranking组件的接受的数据格式不正确!");
      }
    }
  }, [data]);
  /**
   * 启用选中模式. 数据更新时检查当前选中项是否则新的数据列表中, 如果不在则移除当前选中项
   */
  useEffect(() => {
    if (useSelected && !data.find((d) => d.id === innerValue?.id)) {
      setInnerValue(undefined);
    }
  }, [data, innerValue?.id, setInnerValue, useSelected]);

  const handleClick = useCallback(
    (item: Item, idx: number) => {
      if (onClick) {
        onClick(item, idx);
      }
      if (useSelected) {
        setInnerValue(item);
      }
    },
    [onClick, setInnerValue, useSelected]
  );

  const valueFormatter = useCallback(
    (item: Item) => {
      const val = item.value;
      switch (formatter) {
        case "int":
          return val === null ? "--" : showNum(val, "num");
        case "float":
          return val === null ? "--" : showNum(val, "num", 3);
        case "percent":
          return val === null ? "--" : showNum(val * 100, "percentage", 2);
      }
      if (typeof formatter === "function") {
        return formatter(val, item);
      }
    },
    [formatter]
  );
  const renderItem = useCallback(
    (item: Item, idx: number) => {
      const realIdx = usePagination ? (current - 1) * 10 + idx : idx;
      const showIdx = realIdx + 1;
      return (
        <List.Item
          className={`ranking-v2-item optional ${
            item.id === innerValue?.id ? "cur" : ""
          }`}
          onClick={() => handleClick(item, idx)}
          data-rnk={showIdx}
        >
          <div className="ranking-badge">{showIdx}</div>
          <div className="ranking-item-content">
            <Text>{item.name || "--"}</Text>
          </div>
          <div className="ranking-item-value">
            <Text>{valueFormatter(item)}</Text>
          </div>
        </List.Item>
      );
    },
    [usePagination, current, innerValue?.id, valueFormatter, handleClick]
  );
  return (
    <div>
      <List
        itemLayout="horizontal"
        dataSource={showingData}
        style={{ margin: "5px 0 -10px" }}
        className="ranking-v2"
        renderItem={renderItem}
      />
      {usePagination ? (
        <div>
          <Pagination
            size="small"
            current={current}
            onChange={setCurrent}
            showSizeChanger={false}
            total={data.length}
          ></Pagination>
        </div>
      ) : undefined}
    </div>
  );
};
export default RankingV2View;
