import React, { useEffect, useState } from "react";
import CascaderArea from "Components/CascaderArea";
import SelectAirport from "./SelectAirport";
import { Area, CompareAirport } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

interface SelectIProps {
  isDemo: boolean;
  isCtriper: boolean;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  setCompareAirport: (compareAirport: CompareAirport | null) => void;
}

const SelectCompareAirport: React.FC<SelectIProps> = (props: SelectIProps) => {
  const {
    isDemo,
    isCtriper,
    queryUrl,
    moduleCode,
    chartTableCode,
    setCompareAirport,
  } = props;
  const [cascaderArea, setCascaderArea] = useState<Area | null>(null);

  useEffect(() => {
    if (isCtriper && cascaderArea) {
      setCompareAirport(
        cascaderArea.areaCode
          ? {
              id: "",
              airport: cascaderArea.areaCode,
              airportName: cascaderArea.areaName,
              maskName: "",
            }
          : null
      );
    }
  }, [cascaderArea]);

  return (
    <>
      {isCtriper ? (
        <CascaderArea
          type="airport"
          setSelectedArea={setCascaderArea}
          flightClass={1}
          isDemo={isDemo}
          changeOnSelect={false}
          placeholder={getSharkText("key.choose_airport_compare")}
        />
      ) : (
        <SelectAirport
          queryUrl={queryUrl}
          moduleCode={moduleCode}
          chartTableCode={chartTableCode}
          setCompareAirport={setCompareAirport}
        />
      )}
    </>
  );
};

export default SelectCompareAirport;
