import React, { ReactElement, useMemo } from "react";
import SelectMultiRoute from "./SelectMultRoute";
import SelectMultiFlight from "./SelectMultFlight";
import { IFlightItem, IRouteItem } from "../../FlightManageInterface";
import { EMPTY_ARRAY } from "Constants";
import { SelectMultFlightProps } from "./SelectMultFlight/index";
import { SelectMultiRouteProps } from "./SelectMultRoute/index";
import useRefFunc from "Utils/useRefFunc";
export interface IRouteAndFlightSelectorProps {
  flightProps: SelectMultFlightProps;
  routeProps: SelectMultiRouteProps;
  /** 是否在选择航线时关联选中航班 */
  changeFlightByRoute?: boolean;
  /** 是否在选择航班时关联选中航线 */
  changeRouteByFlight?: boolean;
  /** 是否在选择航线后清除已选航班, 配置为true时, changeFlightByRoute不生效 */
  clearFlightByRoute?: boolean;
  /** 是否在选择航班后清除已选航线, 配置为true时, changeRouteByFlight不生效 */
  clearRouteByFlight?: boolean;
}

/**
 *  Component description
 */
const SelectRouteAndFlight = (
  props: IRouteAndFlightSelectorProps
): [ReactElement, ReactElement] => {
  const {
    routeProps,
    flightProps,
    changeFlightByRoute,
    changeRouteByFlight,
    clearFlightByRoute,
    clearRouteByFlight,
  } = props;
  const { setRoutes, routes, ...otherRouteProps } = routeProps;
  const { setFlightNO, flightNO, ...otherFlightProps } = flightProps;
  const [flightList, setFlightList] =
    React.useState<IFlightItem[]>(EMPTY_ARRAY);
  const [routeList, setRouteList] = React.useState<IRouteItem[]>(EMPTY_ARRAY);

  const handleChangeRoute = useRefFunc((v: string[]) => {
    setRoutes(v);
    if (clearFlightByRoute) {
      setFlightNO(EMPTY_ARRAY);
    } else if (changeFlightByRoute && v.length) {
      const willSelect = flightList.filter((item) =>
        v.some((v1) => item.line.includes(v1))
      );
      if (willSelect.length === 0) {
        setFlightNO(EMPTY_ARRAY);
      } else {
        if (flightProps.mode === "multiple") {
          setFlightNO(willSelect.map((item) => item.flightNo));
          // flightRef.current?.setValue([willSelect[0].flightNo]);
        } else {
          // flightRef.current?.setValue(willSelect.map((item) => item.flightNo));
          setFlightNO([willSelect[0].flightNo]);
        }
      }
    }
  });

  const handleChangeFlight = useRefFunc((v: string[]) => {
    setFlightNO(v);
    if (clearRouteByFlight) {
      setRoutes(EMPTY_ARRAY);
    } else if (changeRouteByFlight && v.length) {
      const willSelect = routeList.filter((item) =>
        v.some((v1) => item.flightNo.includes(v1))
      );
      if (willSelect.length === 0) {
        setRoutes(EMPTY_ARRAY);
      } else {
        if (routeProps.mode === "multiple") {
          setRoutes(willSelect.map((item) => item.line));
          // flightRef.current?.setValue([willSelect[0].flightNo]);
        } else {
          // flightRef.current?.setValue(willSelect.map((item) => item.flightNo));
          const selectedRoute = Array.isArray(routes) ? routes[0] : routes;
          const lines = willSelect.map((item) => item.line);
          if (!(selectedRoute && lines.includes(selectedRoute))) {
            setRoutes([willSelect[0].line]);
          }
        }
      }
    }
  });

  const routeComponent = useMemo(
    () => (
      <SelectMultiRoute
        setRoutes={handleChangeRoute}
        routes={routes}
        onDataSourceChange={setRouteList}
        {...otherRouteProps}
      />
    ),
    [handleChangeRoute, otherRouteProps, routes]
  );
  const flightComponent = useMemo(
    () => (
      <SelectMultiFlight
        setFlightNO={handleChangeFlight}
        flightNO={flightNO}
        onDataSourceChange={setFlightList}
        {...otherFlightProps}
      />
    ),
    [flightNO, handleChangeFlight, otherFlightProps]
  );

  return [routeComponent, flightComponent];
};
export default SelectRouteAndFlight;
