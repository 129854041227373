import { Tabs } from "antd";
import HeaderAlert from "Components/HeaderAlert";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import useGlobalState from "Store";
import { getIsDemo } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import NotificationCenter from "Page/AI/NotificationCenter";
import PriceAnalysis from "./PriceAnalysis";
import { useLocation } from "react-router-dom";
import FlightManageDrawer from "../FlightManage/FlightManageDrawer";

/**
 * Component description
 *
 */

const SalesAnalysis = (): ReactElement => {
  const location = useLocation();
  const { pathname } = location;
  const [globalState] = useGlobalState();
  const isDemo = getIsDemo(pathname, globalState);

  return (
    <Page defaultDateMode="none" needToolBar={false}>
      {isDemo ? <HeaderAlert /> : undefined}
      <div style={{ margin: "0 20px" }}>
        <Tabs
          tabBarStyle={{
            backgroundColor: "#fff",
            marginBottom: 0,
            paddingLeft: 20,
          }}
        >
          {/* 部分航司仅展示价格分析不展示销售分析
          {NO_SALE_AIRLINES.includes(
            airlines.toLocaleUpperCase()
          ) ? undefined : (
          <Tabs.TabPane tab={getSharkText("menu_sales_analysis_airlines")} key={0}>
              <div
                className="content-white"
                style={{ margin: 0, padding: "10px 0 0" }}
              >
                <Alert
                  message={
                    <UpdateTime
                      moduleCode=""
                      chartTableCode=""
      text={getSharkText("config_page_only_support_domestic_routes")}
                    />
                  }
                  type="info"
                  banner
                />
                <div style={{ marginTop: 0 }}>
                  <SearchArea setQueryExt={setQueryExt} isDemo={isDemo} />
                </div>
                <div style={{ marginTop: 0, height: 500 }}>
                  <LoadTrend
                    queryExt={queryExt as any}
                    compareDate={queryExt?.compareTakeoffDate}
                  />
                </div>
              </div>
            </Tabs.TabPane>
          )} */}
          <Tabs.TabPane
            tab={getSharkText("config_page_price_analysis")}
            key={1}
          >
            <PriceAnalysis />
          </Tabs.TabPane>
        </Tabs>
      </div>
      <NotificationCenter />
      <FlightManageDrawer />
    </Page>
  );
};
export default SalesAnalysis;
