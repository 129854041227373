import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { IDownloadHeaderPro, IDynamicPriceFilter } from "Interface";
import { showNum, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _, { cloneDeep, isEmpty, uniqBy } from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Checkbox,
  Col,
  Empty,
  Popover,
  Row,
  Spin,
  TablePaginationConfig,
  Tabs,
  Typography,
} from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import ValueNum from "Components/ValueNum";
import { DATE_FORMAT } from "Constants";
import Refetch from "Components/Refetch";
import CustomTable from "./CustomTable";
import moment from "moment";
import Feedback from "./Feedback";

import "./index.css";
import { getServer } from "Service/server";
import {
  defaultStatusList,
  EFlightType,
  ICabinPrice,
  IFeedbackStatusCode,
  IOnNextReturnCode,
  IRecord,
  IStatus,
  statusNameMap,
} from "Page/AI/DynamicPrice/DynamicPriceInterface";
import PriceTrend from "../PriceTrend";
import RadarCharts from "./RadarCharts";
import { QUERY_URL } from "../../fetchCode";
import { SelectedFlight } from "Page/AI/FlightManage/FlightManageInterface";
import {
  DynamicPriceCount,
  DynamicPriceFilter,
  Page,
} from "@ctrip/flt-bi-flightai-airlines";

const { TabPane } = Tabs;
interface AirlineTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  queryExt: IDynamicPriceFilter | null;
  isDemo: boolean;
  onFlightClick: (selectedFlight: SelectedFlight) => void;
}

interface ITabItem {
  name: string;
  key: string;
  disabled?: boolean;
  value: string | "all";
}

const { Text } = Typography;

const defaultTabs = [
  {
    name: getSharkText("config_page_all"),
    key: "all",
    value: "all",
  },
];

const statusHelpInfo = (
  <>
    <p>
      {getSharkText("price_adjustment_suggestion")}:
      最新推送时间批次调价建议，且未经处理
    </p>
    <p>
      {getSharkText("config_page_original_price_suggestion")}:
      最新推送时间批次原价建议，且未经处理
    </p>
    <p>
      {getSharkText("config_page_processed")}:
      最新推送时间批次调价建议，并接受或拒绝
    </p>
    <p>
      {getSharkText("config_page_invalid_suggestion")}:
      最新推送时间批次之前的调价建议被认为失效
    </p>
  </>
);

const PriceCalendar: React.FC<AirlineTableProps> = (
  props: AirlineTableProps
) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    queryExt,
    isDemo,
    onFlightClick,
  } = props;
  const [tabs, setTabs] = useState<ITabItem[]>(defaultTabs);
  const [activeTabItem, setActiveTabItem] = useState<ITabItem>(defaultTabs[0]);
  const [filterData, setFilterData] = useState<IRecord[]>([]);
  const [hasStatusData, setHasStatusData] = useState<IRecord[]>([]);
  const [upFlag, setUpFlag] = useState<any>({ count: 1 });
  const [selectedStatus, setSelectedStatus] = useState<number[]>([0, 1]);
  const [statusList, setStatusList] = useState<IStatus[]>(defaultStatusList);
  const [globalState] = useGlobal();
  const { systemType, airlinesQueryCondition } = globalState;
  const [currentRecord, setCurrentRecord] = useState<IRecord | undefined>();
  const [selectedRecord, setSelectedRecord] = useState<IRecord | undefined>();
  const [paginationExt, setPaginationExt] = useState<Page>({
    page: 1,
    pageSize: 10,
    total: null,
    orderSort: 0,
    orderByField: "",
  });
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  });
  const priceTrendModalRef = useRef<any>();

  // 计算每个状态包含多少条数据
  const calcStatusCount = useCallback((count: DynamicPriceCount) => {
    const rst = _.cloneDeep(defaultStatusList);
    rst.forEach((s) => {
      let tmpCnt = 0;
      switch (s.value) {
        case 0:
          tmpCnt = count.adjustCount || 0;
          break;
        case 1:
          tmpCnt = count.processCount || 0;
          break;
        case 2:
          tmpCnt = count.invalidCount || 0;
          break;
        case 3:
          tmpCnt = count.rawCount || 0;
          break;
      }
      s.count = tmpCnt;
    });
    setStatusList(rst);
  }, []);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query: airlinesQueryCondition,
    lazey: true,
    onSuccess: (res) => {
      if (res.page) {
        const page = res.page as Page;
        setPagination({
          current: page.page as number,
          pageSize: 10,
          total: page.total as number,
          showSizeChanger: false,
        });
      }
      if (res.count) {
        calcStatusCount(res.count as DynamicPriceCount);
      }
    },
  });

  // 生成tab
  const generateTabs = (source: string[]) => {
    const takeoffDays = cloneDeep(source);
    takeoffDays.sort();
    const today = moment().format(DATE_FORMAT);
    const rst: ITabItem[] = _.cloneDeep(defaultTabs);
    takeoffDays.forEach((v, i) => {
      rst.push({
        name: today === v ? getSharkText("config_page_today") : v,
        key: `${i}`,
        value: v,
      });
    });
    return rst;
  };

  const [, doFetchCount] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].tableCount,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query: airlinesQueryCondition,
    lazey: true,
    onSuccess: (res) => {
      if (res.count) {
        const count = res.count as DynamicPriceCount;
        if (count.takeoffdate) {
          const days: string[] = JSON.parse(count.takeoffdate);
          const dayTabs = generateTabs(days);
          setTabs(dayTabs);
        }
      }
      if (res.count) {
        calcStatusCount(res.count as DynamicPriceCount);
      }
    },
  });

  const refetch = useCallback(() => {
    if (queryExt && queryExt.startDate !== "") {
      const status: Partial<DynamicPriceFilter> = {
        raw: selectedStatus.includes(3) ? 1 : 0,
        adjust: selectedStatus.includes(0) ? 1 : 0,
        process: selectedStatus.includes(1) ? 1 : 0,
        invalid: selectedStatus.includes(2) ? 1 : 0,
      };
      const dateExt: Partial<DynamicPriceFilter> | undefined =
        activeTabItem.value !== "all"
          ? {
              startDate: activeTabItem.value,
              endDate: activeTabItem.value,
            }
          : undefined;
      doFetch({
        query: airlinesQueryCondition,
        ext: {
          filter: { ...queryExt, ...dateExt, ...status },
          page: paginationExt,
        },
      });
    }
  }, [
    activeTabItem.value,
    airlinesQueryCondition,
    doFetch,
    paginationExt,
    queryExt,
    selectedStatus,
  ]);

  // #region 重新获取日期列表
  useEffect(() => {
    if (queryExt && queryExt.startDate !== "") {
      const status: Partial<DynamicPriceFilter> = {
        raw: selectedStatus.includes(3) ? 1 : 0,
        adjust: selectedStatus.includes(0) ? 1 : 0,
        process: selectedStatus.includes(1) ? 1 : 0,
        invalid: selectedStatus.includes(2) ? 1 : 0,
      };
      doFetchCount({
        ext: {
          query: airlinesQueryCondition,
          filter: {
            ...queryExt,
            ...status,
          },
        },
      });
    }
  }, [airlinesQueryCondition, doFetchCount, queryExt, selectedStatus]);
  // #endregion

  /**
   * 计算数据的状态, 先判断是变价还是原价, 如果是变价, 再判断数据是否失效, 如果未失效, 再判断数据已处理/未处理
   *
   * @param d 数据项
   */
  const genDataStatus = (source: IRecord) => {
    const d = cloneDeep(source);
    d.feStatus =
      d.suggestPrice === d.rawPrice
        ? 3
        : d.rnk !== 1
        ? 2
        : d.feedback === 0
        ? 0
        : 1;
    return d;
  };

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    const statusData = data?.length
      ? data.map((d: IRecord) => genDataStatus(d))
      : [];
    setHasStatusData(statusData);
    // setTabs(generateTabs(data));
  }, [data]);

  useEffect(() => {
    const filterRst =
      hasStatusData.filter(
        (d: any) =>
          (activeTabItem.value === "all" ||
            moment(d.takeofftime).format(DATE_FORMAT) ===
              activeTabItem.value) &&
          selectedStatus.includes(d.feStatus)
      ) || [];
    setFilterData(filterRst);
    if (filterRst.length) {
      filterRst[0].checked = true;
      setSelectedRecord(filterRst[0]);
    } else {
      setSelectedRecord(undefined);
    }
  }, [hasStatusData, activeTabItem, upFlag, selectedStatus]);

  const onTableChange = useCallback(
    (
      pages: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IRecord> | Array<SorterResult<IRecord>>
    ) => {
      console.log("onTableChange: ", pages, filters, sorter);
      const tmpSorter: SorterResult<IRecord> = Array.isArray(sorter)
        ? sorter[0]
        : sorter;
      setPaginationExt({
        page: pages.current || 1,
        pageSize: 10,
        total: null,
        orderSort: tmpSorter.order === "ascend" ? 0 : 1,
        orderByField: (tmpSorter.field as string) || "id",
      });
    },
    []
  );

  const generateCabinList = (list: string): ReactNode => {
    try {
      const cabins: ICabinPrice[] = JSON.parse(list);
      if (!cabins.length) {
        return null;
      }
      const date = cabins[0].d;
      const ordered = cabins.sort((c1, c2) => (c1.price > c2.price ? -1 : 1));
      return (
        <div style={{ width: 100 }}>
          <div style={{ textAlign: "center" }}>
            {getSharkText("config_page_query_date")}：
          </div>
          <div style={{ marginBottom: 15, textAlign: "center" }}>{date}</div>
          {ordered.map((c) => {
            return (
              <div key={c.subclass}>
                <span
                  style={{
                    display: "inline-block",
                    width: 30,
                    textAlign: "right",
                    marginRight: 10,
                  }}
                >
                  {c.subclass}
                </span>
                | <span>{c.price}</span>
              </div>
            );
          })}
        </div>
      );
    } catch (e) {
      return null;
    }
  };

  const columns: Array<IDownloadHeaderPro<IRecord>> = [
    {
      title: getSharkText("config_page_send_time"),
      dataIndex: "sendTime",
      key: "sendTime",
      sorter: true,
      render: (val: ReactNode) => (val as string).substring(0, 16),
      width: 130,
    },
    {
      title: getSharkText("key.route.name"),
      dataIndex: "route",
      key: "route",
      sorter: true,
      width: 80,
    },
    {
      title: getSharkText("key.flightno.name"),
      dataIndex: "flightNo",
      key: "flightNo",
      sorter: true,
      width: 70,
      // render: (val: number, r: any) => {
      //   if (r && r.flightNo) {
      //     const url = `/flight_manage?startDate=${r.takeofftime.slice(
      //       0,
      //       10
      //     )}&endDate=${r.takeofftime.slice(0, 10)}&airline=${r.flightNo.slice(
      //       0,
      //       2
      //     )}&routes=${r.route}&flightNo=${r.flightNo}`;
      //     return (
      //       <a href={url} target="_blank" rel="noreferrer">
      //         {val}
      //       </a>
      //     );
      //   }
      // },
      render: (val, r: any) => {
        const flight: SelectedFlight = {
          airline: r.flightNo.slice(0, 2),
          flightNo: r.flightNo,
          route: r.route,
          takeoffDate: r.takeofftime.slice(0, 10),
        };
        return (
          <a href="#" onClick={() => onFlightClick(flight)}>
            {val}
          </a>
        );
      },
    },
    {
      title: getSharkText("config_page_scheduled_takeoff_time"),
      dataIndex: "takeofftime",
      key: "takeofftime",
      sorter: true,
      render: (val) => (val as string).substring(0, 16),
      width: 130,
    },
    {
      title: getSharkText("config_page_solo_flight"),
      dataIndex: "flightType",
      sorter: true,
      width: 60,
      render: (val) =>
        EFlightType.COMPETE === val
          ? getSharkText("config_page_no")
          : EFlightType.MONOPOLY === val
          ? getSharkText("config_page_yes")
          : getSharkText("key.unknown.name"),
    },
    {
      title: getSharkText("config_page_status"),
      dataIndex: "feStatus",
      key: "feStatus",
      sorter: true,
      render: (value) => {
        const val = value as IFeedbackStatusCode;
        const statusMapObj = statusNameMap[val];
        return (
          <div style={{ color: statusMapObj.color }}>{statusMapObj.label}</div>
        );
      },
      downloadFormatter: (v: number) => {
        const key = v as IFeedbackStatusCode;
        return statusNameMap[key].label;
      },
      width: 70,
    },
    {
      title: getSharkText("config_page_suggested_price"),
      dataIndex: "suggestPrice",
      key: "suggestPrice",
      width: 120,
      sorter: true,
      render: (value, record: any) => {
        const val = value as number;
        const diff = val - record.rawPrice;
        const color = diff > 0 ? "#D03D33" : "green";
        return (
          <span>
            <span style={{ color, marginRight: 5 }}>{val}</span>
          </span>
        );
      },
    },
    {
      title: getSharkText("config_page_original_price"),
      dataIndex: "rawPrice",
      key: "rawPrice",
      sorter: true,
      width: 60,
      render: (val) => <ValueNum roundNum={-1} type="num" value={val} />,
    },
    {
      title: getSharkText(
        "config_page_PaAiDyCoPrIn_reference_competitive_price"
      ),
      dataIndex: "referPrices",
      key: "referPrices",
      sorter: true,
      width: 110,
      render: (val: any) => {
        try {
          const js = JSON.parse(val);
          if (js.length) {
            if (typeof js[0] === "number") {
              return js.join(",");
            } else {
              // 对竞飞价格去重
              const uniqJS = uniqBy(js, (a) => JSON.stringify(a));
              const str = uniqJS.map((j: any) => j.price).join(",");
              const desc = uniqJS.map((j: any) => (
                <div key={j.flightno}>
                  <span>{j.flightno} | </span>
                  <span>{showNum(j.price)} | </span>
                  <span>{j.takeofftime && j.takeofftime.substr(0, 5)}</span>
                </div>
              ));
              return (
                <Popover destroyTooltipOnHide={true} content={desc}>
                  {str}
                </Popover>
              );
            }
          }
        } catch (e) {
          return val;
        }
      },
    },
    {
      title: getSharkText("config_page_minimum_co_flight"),
      dataIndex: "routeMinPrice",
      key: "routeMinPrice",
      width: 60,
      sorter: true,
      render: (val) => <ValueNum type="num" value={val} />,
    },
    {
      title: getSharkText("config_page_minimum_competitive_flight"),
      dataIndex: "cityMinPrice",
      key: "cityMinPrice",
      width: 70,
      sorter: true,
      render: (val) => <ValueNum type="num" value={val} />,
    },
    {
      /** 今日目标客座率 */
      title: getSharkText("config_page_today_target_load_factor"),
      dataIndex: "factTargetLF",
      width: 80,
      render: (value, r: IRecord) => {
        const v = value as number;
        const changedLf =
          r.userTargetLF !== r.defaultTargetLF &&
          r.userTargetLF !== -1 &&
          r.userTargetLF != null;
        const textColor: string =
          changedLf && r.factTargetLF === r.userTargetLF ? "#1890ff" : "#000";
        const val = v !== -1 && v !== null ? v : r.defaultTargetLF;
        return (
          <span style={{ color: textColor }}>
            {val === null ? "-" : showNum(val * 100, "percentage")}
          </span>
        );
      },
      downloadFormatter: (v, r) => {
        const value = v !== -1 && v !== null ? v : r.defaultTargetLF;
        return showNum(value * 100, "percentage");
      },
    },
    {
      title: getSharkText("config_page_nearby_flight_average_price"),
      dataIndex: "racePriceAvgSame",
      width: 110,
      sorter: true,
      render: (val: any) => {
        try {
          const js = JSON.parse(val);
          if (js.length) {
            if (typeof js[0] === "number") {
              return js.join(",");
            } else {
              // 对竞飞价格去重
              const uniqJS = uniqBy(js, (a) => JSON.stringify(a));
              const str = uniqJS.map((j: any) => showNum(j.price)).join(",");
              const desc = uniqJS.map((j: any) => (
                <div key={j.lines}>
                  <span>{j.lines} | </span>
                  <span>{showNum(j.price)}</span>
                </div>
              ));
              return (
                <Popover destroyTooltipOnHide={true} content={desc}>
                  {str}
                </Popover>
              );
            }
          }
        } catch (e) {
          return val;
        }
      },
    },
    {
      title: getSharkText("config_page_suggested_cabin"),
      dataIndex: "suggestCabin",
      key: "suggestCabin",
      width: 60,
      sorter: true,
      render: (v, r: IRecord) => {
        if (r.pricePolicy) {
          const list = generateCabinList(r.pricePolicy);
          if (list) {
            return (
              <Popover content={list} placement="right">
                <div style={{ padding: "0px 10px" }}>{v}</div>
              </Popover>
            );
          }
        }
        return v;
      },
    },
    {
      title: "原始舱位",
      dataIndex: "rawCabin",
      key: "rawCabin",
      sorter: true,
      width: 60,
      render: (v, r: IRecord) => {
        if (r.pricePolicy) {
          const list = generateCabinList(r.pricePolicy);
          if (list) {
            return (
              <Popover content={list} placement="right">
                <div style={{ padding: "0px 10px" }}>{v}</div>
              </Popover>
            );
          }
        }
        return v;
      },
    },
    {
      title: getSharkText("config_page_purchase_probability"),
      dataIndex: "buyProbability",
      key: "buyProbability",
      width: 60,
      sorter: true,
      render: (val) => showNum((val as number) * 100, "percentage"),
    },
    {
      title: getSharkText("config_page_original_purchase_probability"),
      dataIndex: "oldProbability",
      key: "oldProbability",
      sorter: true,
      render: (val) => showNum((val as number) * 100, "percentage"),
    },
    // {
    //     title: '实际上座进度',
    //     dataIndex: 'lf',
    //     key: 'lf',
    //     sorter: (a: any, b: any) => a.lf - b.lf,
    //     render: (val: number) => <ValueNum type='percentage' value={val * 100} />
    // },
    // {
    //     title: '起飞上座预测',
    //     dataIndex: 'lflines',
    //     key: 'lflines',
    //     sorter: (a: any, b: any) => a.lflines - b.lflines,
    //     render: (val: number) => <ValueNum type='percentage' value={val * 100} />
    // },
    {
      title: getSharkText("config_page_accept"),
      dataIndex: "feedback",
      fixed: "right",
      key: "feedback",
      width: 270,
      render: (val, record: any) => (
        <Feedback<IRecord>
          feedback={val as number}
          cause={record.cause}
          setRecord={setRecord}
          record={record}
          isDemo={isDemo}
        ></Feedback>
      ),
      downloadFormatter: (v, r: any) => {
        if (v === 2) {
          return getSharkText("config_page_no") + ": " + r.cause;
        } else if (v === 1) {
          return getSharkText("config_page_yes");
        } else {
          return "-";
        }
      },
    },
  ];

  const setRecord = (record: IRecord) => {
    const oldRecord = data.find((r: IRecord) => r.id === record.id);
    if (!oldRecord) {
      return;
    }
    oldRecord.feedback = record.feedback;
    oldRecord.cause = record.cause;
    const newRecord = genDataStatus(oldRecord);
    const index = hasStatusData.findIndex(
      (d: IRecord) => d.id === newRecord.id
    );
    if (index > -1) {
      const newHasStatusData = cloneDeep(hasStatusData);
      newHasStatusData[index] = newRecord;
      setHasStatusData(newHasStatusData);
    }
    setUpFlag({ count: upFlag.count++ });
    setCurrentRecord(record);
  };

  const changeTab = (key: string) => {
    setActiveTabItem(tabs.find((t) => t.key === key) || defaultTabs[0]);
  };

  const changeSelectedStatus = (val: any[]) => {
    setSelectedStatus(val);
  };

  const openDetailModal = (record: IRecord) => {
    setCurrentRecord(record);
    if (priceTrendModalRef && priceTrendModalRef.current) {
      priceTrendModalRef.current.show();
    }
  };

  const showRadar = (record: IRecord) => {
    console.log("show Radar");
    setSelectedRecord(record);
  };

  /**
   * 下一条按钮点击时的事件
   *
   * @param record
   */
  const selectRecord = (
    record: IRecord,
    direct: "next" | "prev"
  ): IOnNextReturnCode => {
    const idx = filterData.findIndex((r: IRecord) => r.id === record.id);
    if (idx === -1) {
      return 1;
    }
    if (direct === "next" && idx === filterData.length - 1) {
      return 2;
    }
    if (direct === "prev" && idx === 0) {
      return 3;
    }
    const nextIdx = direct === "next" ? idx + 1 : idx - 1;
    setCurrentRecord(filterData[nextIdx]);
    return 0;
  };

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <div id="analysisTable">
      <div style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <Row
            align="middle"
            gutter={[10, 20]}
            style={{ marginTop: 0, marginBottom: 10 }}
          >
            <Col lg={8} md={8} xs={24}>
              <Alert
                message={getSharkText(
                  "config_page_PaAiDyCoPrIn_only_show_today_adjustment_suggestion"
                )}
                banner
                type="info"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 0 }}>
            <Col lg={16} md={16} xs={24}>
              <Checkbox.Group
                value={selectedStatus}
                style={{ width: "100%" }}
                onChange={changeSelectedStatus}
              >
                {statusList.map((statusObj: IStatus) => (
                  <Checkbox
                    key={statusObj.value}
                    value={statusObj.value}
                    className={statusObj.cssClass}
                    style={{ fontWeight: 600 }}
                  >
                    {`${statusObj.label}(${
                      statusObj.count === undefined
                        ? "--"
                        : `${statusObj.count}`
                    }条)`}
                  </Checkbox>
                ))}
                <Popover content={statusHelpInfo}>
                  <Text type="secondary">
                    <InfoCircleOutlined />
                  </Text>
                </Popover>
              </Checkbox.Group>
            </Col>
          </Row>
        </div>
        <div
          style={{
            width: 250,
            maxWidth: 250,
            position: "absolute",
            right: 40,
            zIndex: 2,
          }}
        >
          {selectedRecord && selectedRecord.ruleCause ? (
            <RadarCharts data={JSON.parse(selectedRecord.ruleCause)} />
          ) : undefined}
        </div>
      </div>

      <div style={{ position: "relative", zIndex: 1 }}>
        <div
          className={`dynamic-price-tabs-all ${
            activeTabItem.key === "all" ? "active" : ""
          }`}
          onClick={() => changeTab("all")}
        >
          {getSharkText("config_page_all")}
        </div>
        <Spin spinning={isLoading}>
          <Tabs
            activeKey={activeTabItem.key}
            tabPosition="top"
            style={{ minHeight: 220 }}
            tabBarStyle={{ marginLeft: 40 }}
            onChange={changeTab}
            className="dynamic-price-tabs"
          >
            {tabs.map((tab) => (
              <TabPane tab={tab.name} key={tab.key} disabled={tab.disabled}>
                {isEmpty(data) ? (
                  <Empty />
                ) : (
                  <CustomTable
                    // handleDownload={handleDownload}
                    dataSource={filterData}
                    columns={columns}
                    searchkeys={["sendTime"]}
                    searchPlaceholder="输入搜索始发或到达"
                    onRecordSelectedChange={openDetailModal}
                    onRecordClick={showRadar}
                    onTableChange={onTableChange}
                    pagination={pagination}
                    moduleCode={moduleCode}
                    chartTableCode={chartTableCode}
                  />
                )}
              </TabPane>
            ))}
          </Tabs>
        </Spin>
      </div>
      <PriceTrend
        ref={priceTrendModalRef}
        record={currentRecord}
        setRecord={setRecord}
        onNext={(r: IRecord) => selectRecord(r, "next")}
        onPrev={(r: IRecord) => selectRecord(r, "prev")}
        currentIdx={
          currentRecord
            ? filterData.findIndex((r: IRecord) => r.id === currentRecord.id)
            : -1
        }
        maxIdx={filterData.length}
        isDemo={isDemo}
      ></PriceTrend>
    </div>
  );
};

export default PriceCalendar;
