import { Button, Col, message, Modal, Row, Select } from "antd";
import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import DataColumns from "./DataColumns";
import DatasetEditor, { DatasetEditorHandler } from "./DatasetEditor";
import useRefFunc from "Utils/useRefFunc";
import { FDDataset, FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { useServices } from "../useServices";
import Page from "Layout/Page";
import useGlobalState from "Store";
import { EMPTY_ARRAY } from "Constants";
import { arrayUpsert, getUnDuplicateUUID } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { isSuperAdmin } from "Utils/global";
import ubtUtils from "Utils/ubtUtils";
import ExpEditor from "../ChartEditor/Datasource/ExpEditor";
import { IGetValueHandler } from "../ChartEditor/Measure/FormatterEditor";

export interface IindexProps {
  example?: string;
}

/**
 * 自由报表数据列管理页面
 */
const Manage = (props: IindexProps): ReactElement => {
  const { example } = props;
  const datasetEditorRef = useRef<DatasetEditorHandler>(null);
  const [globalState] = useGlobalState();
  const { userInfo } = globalState;
  const { user } = userInfo;
  const [datasets, setDatasets] = useState<FDDataset[]>([]);
  const [curDataset, setCurDataset] = useState<FDDataset | null>(null);
  const [curCols, setCurCols] = useState<FDDatasetCol[]>([]);
  const services = useServices();

  const datasetOptions = useMemo(() => {
    return datasets.map((d) => ({
      label: d.name,
      value: d.id,
    }));
  }, [datasets]);

  const canDelete = useMemo(() => {
    if (!curDataset) {
      return false;
    }
    if (curDataset.userCreate === user.id || isSuperAdmin(user)) {
      return true;
    }
    return false;
  }, [curDataset, user]);

  const refreshDatasets = useRefFunc(() => {
    services
      .getDataset()
      .then(
        (res: any) => {
          if (res.data?.length) {
            setDatasets(res.data);
            setCurDataset(null);
          } else {
            message.error(getSharkText("config_page_get_dataset_failed"));
          }
        },
        () => {
          message.error(getSharkText("config_page_get_dataset_failed"));
        }
      )
      .catch((e) => {
        ubtUtils.error(e);
      });
  });

  useEffect(() => {
    refreshDatasets();
  }, [refreshDatasets]);

  const openDatasetEditor = useRefFunc(() => {
    if (datasetEditorRef.current) {
      datasetEditorRef.current.open();
    }
  });
  const handleChangeDataset = useRefFunc((v: number) => {
    const t = datasets.find((d) => d.id === v);
    if (t && t.id != null) {
      setCurDataset(t);
      setCurCols(EMPTY_ARRAY);
      services.getDatasetColsOnEdit(t.id).then(
        (res: any) => {
          if (res.data) {
            setCurCols(res.data);
          } else {
            setCurCols(EMPTY_ARRAY);
          }
        },
        () => {
          setCurCols(EMPTY_ARRAY);
        }
      );
    }
  });

  const onRowSave = useRefFunc((v: FDDatasetCol) => {
    services.upsertDatasetCols([v]).then(
      (res: any) => {
        if (res.data.length) {
          setCurCols(
            arrayUpsert(curCols, v, (item) => item.dbcolumn === v.dbcolumn)
          );
          message.success(getSharkText("config_page_save_success"));
        } else {
          message.success(getSharkText("config_page_save_failed"));
        }
      },
      () => {
        message.error(getSharkText("config_page_save_failed"));
      }
    );
  });

  const onRowDelete = useRefFunc((v: FDDatasetCol) => {
    if (v.id == null) {
      return;
    }
    services.deleteDatasetCol(v.id).then(
      (res: any) => {
        if (res.count) {
          message.success(getSharkText("config_page_delete_success"));
          refreshDatasets();
        } else {
          message.error(getSharkText("config_page_delete_fail"));
        }
      },
      () => {
        message.error(getSharkText("config_page_delete_fail"));
      }
    );
  });

  const handleDeleteSet = useRefFunc(() => {
    Modal.confirm({
      content: "Will delete!!",
      onOk: () => {
        if (!curDataset || curDataset.id == null) {
          message.error("Current dataset id is null");
          return;
        }
        services.deleteDataset(curDataset.id).then(
          (res: any) => {
            if (res.count) {
              message.success(getSharkText("config_page_delete_success"));
              refreshDatasets();
            } else {
              message.error(getSharkText("config_page_delete_fail"));
            }
          },
          () => message.error(getSharkText("config_page_delete_fail"))
        );
      },
    });
  });

  const handleEditSet = useRefFunc(() => {
    if (!curDataset) {
      message.error("must select a dataset");
      return;
    }
    if (curDataset.id == null || !curCols.length) {
      message.warn("dataset is empty");
      // return;
    }
    if (datasetEditorRef.current) {
      datasetEditorRef.current.open(curDataset, curCols);
    }
  });

  const expEditorRef = useRef<IGetValueHandler<Partial<FDDatasetCol>>>(null);

  const handleAddCol = useRefFunc(() => {
    if (!curDataset) {
      message.error("must select a dataset");
      return;
    }
    if (curDataset.id == null || !curCols.length) {
      message.warn("dataset is empty");
      return;
    }
    Modal.confirm({
      icon: null,
      onOk: () => {
        if (expEditorRef.current) {
          const value = expEditorRef.current.getValue();
          if (value && value.dbcolumn && value.kind) {
            const newCol: FDDatasetCol = {
              id: null,
              datasetId: curDataset.id,
              name: getUnDuplicateUUID(
                5,
                curCols.map((c) => c.name || "")
              ),
              displayName: value.displayName || null,
              kind: value.kind,
              dbcolumn: value.dbcolumn,
              jdbctype: "NUMERIC",
              sort: "DOUBLE",
              aggs: "sum,avg,max,min,count",
              extra: value.extra || null,
              description: value.description || null,
            };
            onRowSave(newCol);
          }
        }
      },
      content: (
        <ExpEditor
          columns={curCols}
          datasetId={curDataset.id}
          ref={expEditorRef}
        />
      ),
    });
  });

  return (
    <Page defaultDateMode="none" needToolBar={false}>
      <div className="content-white">
        <Row gutter={[20, 20]}>
          <Col>
            <Button type="primary" onClick={openDatasetEditor}>
              {getSharkText("config_page_new_dataset")}
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              danger
              onClick={handleDeleteSet}
              disabled={!canDelete}
            >
              {getSharkText("config_page_delete_dataset")}
            </Button>
          </Col>
          <Col>
            <Select
              options={datasetOptions}
              value={curDataset?.id}
              onChange={handleChangeDataset}
              placeholder={getSharkText("config_page_select_dataset")}
              style={{ minWidth: 200 }}
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleEditSet}
              disabled={!curDataset}
            >
              Edit dataset
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              onClick={handleAddCol}
              disabled={!curDataset}
            >
              新增复合指标
            </Button>
          </Col>
        </Row>
        <div style={{ marginTop: 10 }} key={curDataset?.id}>
          <DataColumns
            value={curCols}
            onRowSave={onRowSave}
            onRowDelete={onRowDelete}
          />
        </div>
        <DatasetEditor
          ref={datasetEditorRef}
          refreshDatasets={refreshDatasets}
        />
      </div>
    </Page>
  );
};
export default Manage;
