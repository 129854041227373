import React, { useEffect, useState } from "react";
import { Affix, Card, Col, message, Popover, Row } from "antd";
import useGlobal from "Store";
import Page from "Layout/Page";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { Area, PassengerAnalysisQueryExt, Tab } from "Interface";
import { DEFAULT_AREA, EYmwCompareType } from "Constants";
import RouteAndScroll from "./Components/RouteAndScroll";
import "./index.css";
import DistributionChart from "./Components/DistributionChart";
import LossFilter from "./Components/LossFilter";
import LossRanking from "./Components/LossRanking";
import LossCharts from "./Components/LossCharts";
import { DISTRIBUTION_HELP, LOSS_HELP } from "./PassengerAnalysisCommon";
import DistributionRanking from "./Components/DistributionRanking";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getSharkText } from "Utils/i18nGlobal";

const defaultPassengerAnalysisQueryExt: PassengerAnalysisQueryExt = {
  local: 1,
  surrounding: 1,
  cityList: [],
  byProvince: 1,
  byDistance: 1,
  kilometers: 100,
};

const distributionTab: Tab[] = [
  {
    title: "绝对值",
    chartTableCode: "AP0801",
    type: 1,
  },
];

const PassengerAnalysis: React.FC = () => {
  const [passengerAnalysisQueryExt, setPassengerAnalysisQueryExt] =
    useState<PassengerAnalysisQueryExt>(defaultPassengerAnalysisQueryExt);
  const [loading, setLoading] = useState(false);
  const [area, setArea] = useState<Area | null>();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [globalState] = useGlobal();
  const { systemType, queryCondition } = globalState;
  const { flightClass, compareType } = queryCondition;
  const [distributionRankingExt, setDistributionRankingExt] = useState<any>();
  const [ext, setExt] = useState<any>();
  const [lossRankExt, setLossRankExt] = useState<any>();
  const [lossChartExt, setLossChartExt] = useState<any>();
  const [nearCity, setNearCity] = useState<Area[]>([]);
  const [nearAirport, setNearAirport] = useState<Area[]>([]);
  const [lossRow, setLossRow] = useState<any>();
  const [distributionRankingTab, setDistributionRankingTab] =
    useState<Tab[]>(distributionTab);
  const [isNewInPassenger, setIsNewInPassenger] = useState<boolean>(true);

  useEffect(() => {
    if ([1, 2].includes(compareType)) {
      setDistributionRankingTab(
        distributionTab.concat({
          title: "周/月环比",
          chartTableCode: "AP0801",
          type: 3,
          isPercentVal: true,
        })
      );
    } else {
      setDistributionRankingTab(
        distributionTab.concat({
          title: "同比",
          chartTableCode: "AP0801",
          type: 2,
          isPercentVal: true,
        })
      );
    }
  }, [compareType]);

  useEffect(() => {
    // 修改flightClass清空page页面参数
    if (passengerAnalysisQueryExt) {
      setLoading(true);
      setPassengerAnalysisQueryExt(defaultPassengerAnalysisQueryExt);
      setTimeout(() => setLoading(false));
    }
  }, [flightClass]);

  useEffect(() => {
    const dRankingExt = { area: area || DEFAULT_AREA };
    setDistributionRankingExt(dRankingExt);
  }, [area]);

  useEffect(() => {
    const tmp = { area: area || DEFAULT_AREA, city: selectedItem || null };
    setExt(tmp);
  }, [area, selectedItem]);

  useEffect(() => {
    const tmp = {
      area: area || DEFAULT_AREA,
      filter: { cityList: nearCity, airportList: nearAirport },
    };
    setLossRankExt(tmp);
  }, [area, nearCity, nearAirport]);

  useEffect(() => {
    const tmp = {
      area: area || DEFAULT_AREA,
      filter: { cityList: nearCity, airportList: nearAirport },
      row: lossRow,
    };
    setLossChartExt(tmp);
  }, [area, nearCity, nearAirport, lossRow]);

  const passengerTip = () => {
    if (isNewInPassenger) {
      message.warn({
        content:
          "由于客源分布热力图数据量较大，页面加载及操作可能产生卡顿，请耐心等待。",
        duration: 15,
        style: { marginTop: -50 },
      });
      setIsNewInPassenger(false);
    }
  };

  return (
    <Page
      defaultDateMode="lastMonth"
      isShowCompareType={true}
      // extendTools={extendTopTool}
      airportCompareTypes={EYmwCompareType}
    >
      <>
        {passengerTip()}
        <Affix offsetTop={63}>
          <div
            className="content-white"
            style={{ margin: "0 20px", padding: "0 20px" }}
          >
            {loading ? undefined : (
              <RouteAndScroll setArea={setArea}></RouteAndScroll>
            )}
          </div>
        </Affix>
        <div
          id="passenger-analysis-container"
          className="content-white"
          style={{ overflow: "scroll", margin: "0 20px", padding: "0 20px" }}
        >
          <Row
            id="passenger-analysis-distribution"
            gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
            style={{ paddingTop: 15 }}
          >
            <Col span={24}>
              <div className="panel-header">
                <hr />
                <span className="panel-title">
                  {getSharkText("key.distribution_psgr.name")}
                  <Popover content={DISTRIBUTION_HELP}>
                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                  </Popover>
                </span>
              </div>
            </Col>
            <Col xl={12} lg={24}>
              <DistributionRanking
                queryUrl={QUERY_URL[systemType].distributionTop}
                moduleCode={MODULE_CODE[systemType]}
                tab={distributionRankingTab}
                ext={distributionRankingExt}
                theme="radio"
                selectItem={setSelectedItem}
              ></DistributionRanking>
            </Col>
            <Col xl={12} lg={24}>
              <Card>
                <DistributionChart ext={ext} />
              </Card>
            </Col>
          </Row>
          <Row
            id="passenger-analysis-loss"
            gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
            style={{ paddingTop: 15 }}
          >
            <Col span={24}>
              <div className="panel-header">
                <hr />
                <span className="panel-title">
                  {getSharkText("key.psgr_loss.name")}
                  <Popover content={LOSS_HELP}>
                    <InfoCircleOutlined style={{ marginLeft: 5 }} />
                  </Popover>
                </span>
              </div>
            </Col>
            <Col span={24}>
              <LossFilter
                setNearCity={setNearCity}
                setNearAirport={setNearAirport}
              />
            </Col>
            <Col xl={12} lg={24}>
              <LossRanking
                // tab={distributionTab}
                queryUrl={QUERY_URL[systemType].lossRanking}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].table}
                selectItem={setLossRow}
                ext={lossRankExt}
              />
            </Col>
            <Col xl={12} lg={24}>
              <Card>
                <LossCharts ext={lossChartExt} />
              </Card>
            </Col>
          </Row>

          {/* <Row gutter={[{ xs: 8, sm: 16, md: 16 }, 20]} style={{ marginTop: 10 }}>
                <Col lg={14} md={12} xs={24}>
                  <TopChartMap
                    queryUrl={QUERY_URL[systemType].distribution}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].distribution}
                    passengerAnalysisQueryExt={passengerAnalysisQueryExt} />
                </Col>
                <Col lg={10} md={12} xs={24}>
                  <TopCityBarCharts
                    queryUrl={QUERY_URL[systemType].top10City}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].top10City}
                    passengerAnalysisQueryExt={passengerAnalysisQueryExt} />
                </Col>
              </Row>
              <Row style={{ marginTop: '16px' }}>
                <Card>
                  {
                    <CompareTable
                      queryUrl={QUERY_URL[systemType].table}
                      moduleCode={MODULE_CODE[systemType]}
                      chartTableCode={CHART_TABLE_CODE[systemType].table}
                      passengerAnalysisQueryExt={passengerAnalysisQueryExt} />
                  }
                </Card>
              </Row> */}
        </div>
      </>
    </Page>
  );
};

export default PassengerAnalysis;
