import { Modal, Spin, Table, message } from "antd";
import React, {
  ReactElement,
  Ref,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import SearchArea from "./SearchArea";
import { RORecord } from "../interface";
import useRefFunc from "Utils/useRefFunc";
import {
  CabinDynamicFarePriceType,
  FlightDynamicFarePriceType,
  FlightFarePriceSetType,
  GetDynamicFarePriceRequestType,
  IDownloadHeader,
} from "Interface";
import useServices from "../useServices";
import {
  CLIENT_APP_ID,
  CLIENT_IP,
} from "Page/AI/FlightManage/FlightManageCommon";
import PriceSet from "./PriceSet";
import useGlobalState from "Store";
import ubtUtils from "Utils/ubtUtils";
import { EMPTY_ARRAY } from "Constants";
import { STATUS_MAP } from "./common";

export interface DynamicFarePriceProps {
  takeoffdate: string;
  flightNo: string;
  route: string;
}

export interface DynamicFarePriceRef {
  open: (v: boolean) => void;
}

/**
 * 动态运价组件
 */
const DynamicFarePrice = forwardRef(
  (
    props: DynamicFarePriceProps,
    ref: Ref<DynamicFarePriceRef>
  ): ReactElement => {
    const { takeoffdate, flightNo, route } = props;
    const [open, setOpen] = useState<boolean>(false);
    const [param, setParam] = useState<RORecord>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<FlightDynamicFarePriceType[]>([]);
    const [globalState] = useGlobalState();
    const userInfo = globalState.userInfo;
    const services = useServices();

    useImperativeHandle(ref, () => ({
      open: (v: boolean) => {
        setOpen(v);
      },
    }));

    const onSearch = useRefFunc((v?: RORecord) => {
      const queryParam = v || param;
      if (queryParam) {
        setParam(queryParam);
        const request: GetDynamicFarePriceRequestType = {
          Head: { ClientAppID: CLIENT_APP_ID, ClientIP: CLIENT_IP },
          flights: [
            {
              flightno: queryParam.flightNo,
              takeoffdate: queryParam.takeoffdate,
              seg: queryParam.route,
            },
          ],
          userGroup: userInfo.unitList
            ? userInfo.unitList[0].id.toString()
            : "-1",
          airline: queryParam.flightNo.substring(0, 2),
          // airline: "",
        };
        setIsLoading(true);
        setData(EMPTY_ARRAY);
        services
          .getDynamicFarePrice(request)
          .then((res) => {
            if (res?.ResponseStatus.Ack === "Success") {
              const response = JSON.parse(res.responseBody);
              setData(response.flights);
            } else {
              message.error("查询失败");
              ubtUtils.error(
                "getDynamicFarePriceFailed",
                undefined,
                undefined,
                2
              );
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });

    const infoColumns = useMemo(() => {
      const tmp: Array<IDownloadHeader<FlightDynamicFarePriceType>> = [
        {
          title: "航班号",
          dataIndex: "flightno",
        },
        {
          title: "起飞日期",
          dataIndex: "takeoffdate",
        },
        {
          title: "距起飞天数",
          dataIndex: "aheaddays",
        },
        {
          title: "航段",
          dataIndex: "line",
        },
      ];
      return tmp;
    }, []);

    const priceColumns = useMemo(() => {
      const tmp: Array<IDownloadHeader<CabinDynamicFarePriceType>> = [
        {
          title: "舱位",
          dataIndex: "cabinType",
        },
        {
          title: "标准运价",
          dataIndex: "standardPrice",
        },
        {
          title: "生效动态运价",
          dataIndex: "effectivePrice",
        },
        {
          title: "生效时间",
          dataIndex: "effectTime",
        },
        {
          title: "等待生效运价",
          dataIndex: "awaitPrice",
          render: (val) => <span style={{ color: "#F59A23" }}>{val}</span>,
        },
        {
          title: "状态",
          dataIndex: "status",
          render: (val) => (
            <span style={{ color: "#F59A23" }}>{STATUS_MAP[val] || val}</span>
          ),
        },
        {
          title: "状态更新时间",
          dataIndex: "statusUpdateTime",
        },
      ];
      return tmp;
    }, []);

    const priceData = useMemo(() => {
      if (data.length) {
        return data[0].priceList;
      }
      return [];
    }, [data]);

    const submit = useRefFunc((v: FlightFarePriceSetType) => {
      console.log("submit", v);
      setIsLoading(true);
      services
        .setDynamicFarePrice({
          Head: {
            ClientIP: CLIENT_IP,
            ClientAppID: CLIENT_APP_ID,
          },
          flights: [v],
          airline: v.flightno?.substring(0, 2) || "",
          websiteUserID: userInfo.user.id.toString(),
          websiteUserName: userInfo.user.name,
          userGroup: userInfo.unitList
            ? userInfo.unitList[0].id.toString()
            : "-1",
        })
        .then((res) => {
          if (res?.ResponseStatus.Ack === "Success") {
            const response = JSON.parse(res.responseBody);
            if (response?.ResponseStatus.Ack === "Success") {
              const flights: FlightDynamicFarePriceType[] = response.flights;
              if (flights[0] && flights[0].optStatus === "SUCCESS") {
                message.success("设置成功");
                onSearch();
                return;
              }
            }
          }
          message.error("设置失败");
          ubtUtils.error("setDynamicFarePrice failed", undefined, undefined, 2);
        })
        .catch((error) => {
          message.error("设置失败");
          ubtUtils.error(error.message, undefined, undefined, 2);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });

    return (
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        width={1024}
        destroyOnClose
        footer={null}
      >
        <Spin spinning={isLoading}>
          动态运价管理
          <SearchArea
            onChange={onSearch}
            defaultDate={takeoffdate}
            defaultFlightNo={flightNo}
            defaultRoute={route}
          />
          <div>
            <h3>航班信息</h3>
            <Table
              columns={infoColumns}
              dataSource={data}
              size="small"
              pagination={false}
            ></Table>
          </div>
          <div>
            <h3>运价情况</h3>
            <Table
              columns={priceColumns}
              dataSource={priceData}
              size="small"
              pagination={false}
              className="micro-table"
            />
          </div>
          <div>
            {data[0] ? (
              <PriceSet
                data={data[0]}
                onSubmit={submit}
                key={`${data[0].flightno}${data[0].takeoffdate}${data[0].line}`}
              />
            ) : undefined}
          </div>
        </Spin>
      </Modal>
    );
  }
);
export default DynamicFarePrice;
