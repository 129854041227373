import React from "react";
import { Spin } from "antd";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  name: string;
  value: number;
}

interface SimplePictorialBarChartsProps {
  data: BarData[];
  loading: boolean;
  seriesMap: Record<string, any>;
}

const genChartData = (data: BarData[], seriesMap: any) => {
  // 获取总量
  const totalObj = data.find((d) => /all$/.test(d.name));
  const totalValue = totalObj ? totalObj.value : 0;
  const rst = data.reduce((total: any, d) => {
    if (seriesMap[d.name]) {
      total.push({
        value: parseFloat(((d.value / totalValue) * 100).toFixed(2)),
        ...seriesMap[d.name],
      });
    }
    return total;
  }, []);
  return rst;
};

const SimplePictorialBarCharts: React.FC<SimplePictorialBarChartsProps> = (
  props: SimplePictorialBarChartsProps
) => {
  const { data, loading, seriesMap } = props;
  const chartData = genChartData(data, seriesMap);
  const isZero = (val: number) => val === 0;
  const option = {
    // dataset: {
    //     source: data
    // },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const totalObj = data.find((d) => /all$/.test(d.name));
        const totalValue = totalObj ? totalObj.value : 0;
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const per = isZero(totalValue) ? "-" : item.data.value;
          const perDom = `占比: ${per !== "-" ? per : "-"}%`;
          dom = dot + perDom;
        });
        return dom;
      },
    },
    xAxis: {
      splitLine: { show: false },
      axisLabel: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    yAxis: {
      data: chartData.map((d: any) => d.name),
      inverse: true,
      splitLine: { show: false },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    series: [
      {
        type: "pictorialBar",
        label: {
          normal: {
            show: true,
            position: "right",
            offset: [10, 0],
            formatter: (d: any) => Math.round(d.data.value) + "%",
          },
        },
        symbolRepeat: true,
        symbolClip: true,
        symbolSize: ["50%", "90%"],
        barCategoryGap: "40%",
        data: chartData,
      },
    ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height: "300px" }}
      />
    </Spin>
  );
};

export default SimplePictorialBarCharts;
