import { PieData } from "@ctrip/flt-bi-flightai-airlines";
import { Item } from "Components/RankingV2/RankingV2Interface";
import { U } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

export const flightPlanTypes = [
  getSharkText("key.cancelled.name"),
  getSharkText("key.added.name"),
  getSharkText("key.scheduled.name"),
];
export const flightPlanFlightClass = [
  getSharkText("flightClass.all.name"),
  getSharkText("flightClass.domestic.name"),
  getSharkText("key.crossborder_overseas.button"),
];

export const executeTransFn = (source?: PieData[]): Item[] => {
  return (source || []).map((s) => {
    return {
      id: s.name || U.getUUID(),
      ...s,
    };
  });
};
