export const MODULE_CODE = "travix_overview";

export const CHART_TABLE_CODE = {
  overview: {
    Sales: "TX0101",
    Table: "TX0102",
    Market: "TX0103",
    Channel: "TX0104",
  },
  salesData: {
    Sales: "TX0201",
    Table: "TX0202",
    Market: "",
    Channel: "TX0203",
  },
};

export const QUERY_URL = {
  barline: "querySalesData",
  pie: "queryChannelType",
};
