// Created by xh_zhu on 2021-04-07

import { Select } from "antd";
import { EMPTY_ARRAY } from "Constants";
import { Area } from "Interface";
import React, { ReactElement, useEffect, useMemo } from "react";
import useGlobalState from "Store";
import { stringContains, useFetch } from "Utils";

/**
 * Component description
 * 周边机场选项
 */

interface INearAirportSelectorProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  type: 1 | 2;
  setNearAirport: (v: Area[]) => void;
}

const NearAirportSelector = (
  props: INearAirportSelectorProps
): ReactElement => {
  const { queryUrl, moduleCode, chartTableCode, type, setNearAirport } = props;
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const { airport } = queryCondition;
  const ext = {
    airportCode: airport,
    type,
    byProvince: 1,
    byDistance: 1,
    kilometers: 500,
  };
  const [{ data, isLoading }, doFetch] = useFetch({
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    ext,
  });

  const refetch = () => {
    doFetch({
      ext,
    });
  };

  const options = useMemo(() => {
    if (!Array.isArray(data)) {
      return EMPTY_ARRAY;
    }
    return data.map((d: Area) => ({ label: d.areaName, value: d.areaCode }));
  }, [data]);

  useEffect(() => {
    refetch();
  }, [airport, type]);

  const handleChange = (value: string[]) => {
    const rst: Area[] = [];
    data.forEach((d: Area) => value.includes(d.areaCode) && rst.push(d));
    setNearAirport(rst);
  };
  return (
    <>
      {isLoading ? undefined : (
        <Select
          mode="multiple"
          style={{ minWidth: 200, maxWidth: "80%" }}
          onChange={handleChange}
          options={options}
          filterOption={(input, option) =>
            stringContains(option?.label, input) ||
            stringContains(option?.value, input)
          }
        ></Select>
      )}
    </>
  );
};
export default NearAirportSelector;
