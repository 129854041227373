import React, { useEffect } from "react";
import { Card, Empty } from "antd";
import useGlobal from "Store";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import Refetch from "Components/Refetch";
import { getServer } from "Service/server";
import { useFetch } from "Utils/useFetch";
import EchartsReactBase from "Components/EchartsReactBase";

interface PieChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  cardCode: string;
  height?: number;
  style?: object;
}

const PieCharts: React.FC<PieChartsProps> = (props: PieChartsProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    cardCode,
    height = 300,
    style,
  } = props;

  const [globalState, actions] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const { setQueryCondition, setAirlinesQueryCondition } = actions;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      cardCode,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        cardCode,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [cardCode, query]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }
  if (!data) {
    return <Empty />;
  }

  const option = {
    title: {
      text: getSharkText("key.domestic_vs_crossborder.text"),
      textStyle: {
        fontWeight: "normal",
        fontSize: 14,
        color: "#333",
      },
      left: "center",
    },
    tooltip: {
      trigger: "item",
      formatter: "{b}: {d}%",
    },
    legend: {
      bottom: 0,
      icon: "circle",
      itemGap: 20,
      itemWidth: 8,
      itemHeight: 8,
      textStyle: {
        color: "#666",
      },
    },
    dataset: {
      source: data,
    },
    series: [
      {
        type: "pie",
        radius: ["60%", "70%"],
        label: false,
        selectedMode: true,
      },
    ],
  };

  const onChartClick = (params: any) => {
    if (isAirport) {
      setQueryCondition({
        ...queryCondition,
        flightClass:
          params.value.name === getSharkText("key.domestic.text") ? 1 : 2,
      });
    } else {
      setAirlinesQueryCondition({
        ...airlinesQueryCondition,
        flightClass:
          params.value.name === getSharkText("key.domestic.text") ? 1 : 2,
      });
    }
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <Card style={style}>
      <EchartsReactBase
        className="pie-charts"
        option={option}
        style={{ height }}
        onEvents={onEvents}
        theme="default"
        showLoading={isLoading}
        loadingOption={{
          textColor: "#999",
          color: "#1890ff",
        }}
      />
    </Card>
  );
};

export default PieCharts;
