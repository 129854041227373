import React, { useEffect, useRef, useState } from "react";
import useGlobal from "Store";
import Page from "Layout/Page";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { IDynamicPriceFilter } from "Interface";
import SearchArea from "./Components/SearchArea";
import PriceCalendar from "./Components/PriceCalendar";
import "./index.css";
import { getModule, getModuleNameFromPath } from "Utils";
import HeaderAlert from "Components/HeaderAlert";
import NotificationCenter from "Page/AI/NotificationCenter";
import { useLocation } from "react-router-dom";
import FlightManageDrawer from "../FlightManage/FlightManageDrawer";
import useRefFunc from "Utils/useRefFunc";
import { SelectedFlight } from "../FlightManage/FlightManageInterface";

const DynamicPrice = () => {
  const location = useLocation();
  const [queryExt, setQueryExt] = useState<IDynamicPriceFilter | null>(null);
  const [globalState] = useGlobal();
  const { systemType, queryCondition, userInfo } = globalState;
  const { departure, arrive } = queryCondition;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const isDemo = (module && module.moduleStatus === 0) || false;
  const drawerRef = useRef<any>(null);
  const [selectedFlight, setSelectedFlight] = useState<SelectedFlight | null>(
    null
  );

  useEffect(() => {
    if (!departure && !arrive) {
      setQueryExt(null);
    }
  }, [departure, arrive]);

  const handleOpenDrawer = useRefFunc((flight: SelectedFlight) => {
    setSelectedFlight(flight);
    if (drawerRef?.current?.open) {
      drawerRef.current.open();
    }
  });

  return (
    <Page defaultDateMode="none" needToolBar={false}>
      {isDemo ? <HeaderAlert /> : undefined}
      <div className="content-white dynamic-price" style={{ marginTop: 0 }}>
        <SearchArea
          queryUrl={QUERY_URL[systemType].routeFlight}
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={CHART_TABLE_CODE[systemType].routeFlight}
          setQueryExt={setQueryExt}
        />
      </div>
      <div className="content-white dynamic-price">
        <PriceCalendar
          queryUrl={QUERY_URL[systemType].table}
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={CHART_TABLE_CODE[systemType].table}
          queryExt={queryExt}
          isDemo={isDemo}
          onFlightClick={handleOpenDrawer}
        />
      </div>
      <NotificationCenter />
      <FlightManageDrawer ref={drawerRef} selectedFlight={selectedFlight} />
    </Page>
  );
};

export default DynamicPrice;
