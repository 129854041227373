import { getEnv } from "Utils/global";

const isPro = getEnv() === "pro";
export const DATASET_ID = isPro ? 281 : 57;

export const DPORT_NAME = "dport";
export const APORT_NAME = "aport";
export const FLIGHTNO_NAME = "flightno";
export const TAKEOFFDATE_NAME = "takeoffdate";
export const CLASS_NAME = "class";
export const PERSONS_NAME = "persons";
export const D_NAME = "d";
