// Created by xh_zhu on 2021-04-19

import { SwapRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import CascaderArea from "Components/CascaderArea";
import { Area, FlightClass } from "Interface";
import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";

/**
 * Component description
 * 航司版中转分析查询区域
 */

interface ISearchProps {
  onChangeVal: (v: any) => void;
  flightClass: FlightClass;
  isDemo: boolean;
}

const Search = (props: ISearchProps): ReactElement => {
  const { onChangeVal, flightClass, isDemo } = props;
  const [departArea, setDepart] = useState<Area | null>(null);
  const [arriveArea, setArrive] = useState<Area | null>(null);
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const { airportName } = queryCondition;

  useEffect(() => {
    onChangeVal({
      departArea,
      arriveArea,
    });
  }, [departArea, arriveArea, onChangeVal]);

  return (
    <>
      <Row style={{ alignItems: "center" }}>
        <Col>
          <CascaderArea
            type="city"
            setSelectedArea={setDepart}
            flightClass={flightClass}
            isDemo={isDemo}
          />
        </Col>
        <Col>
          <SwapRightOutlined style={{ margin: "0 5px" }} />
        </Col>
        <Col>{airportName}</Col>
        <Col>
          <SwapRightOutlined style={{ margin: "0 5px" }} />
        </Col>
        <Col>
          <CascaderArea
            type="city"
            setSelectedArea={setArrive}
            flightClass={flightClass}
            isDemo={isDemo}
          />
        </Col>
      </Row>
    </>
  );
};
export default Search;
