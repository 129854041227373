import React, { useEffect, useState } from "react";
import { IDownloadHeader } from "Interface";
import { openDownloadDialog, sheet2blob, useFetch, XLSX } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _, { merge } from "lodash";
import { Col, Input, Radio, Row, Switch, Table } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import Refetch from "Components/Refetch";
import DownloadBtn from "Components/DownloadBtn";
import { getServer } from "Service/server";
import { flightPlanTypes } from "Page/AI/FlightPlan/FlightPlanAirlineCommon";
import { CAACDetailColumns, CAACSummaryColumns, weekColumns } from "./columns";
import { getDownloadColumnHeader } from "Utils/downloadXLSX";

interface AirlineTableProps {
  moduleCode: string;
  chartTableCode: string;
  ext: any;
  queryUrl: string;
  panelType: 0 | 1;
}

const PlanTable: React.FC<AirlineTableProps> = (props: AirlineTableProps) => {
  const {
    moduleCode,
    chartTableCode,
    ext: otherExt,
    queryUrl,
    panelType,
  } = props;
  const [type, setType] = useState<0 | 1 | 2>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const query = queryCondition;
  const detail = panelType === 1 ? { detail: showDetail ? 1 : 0 } : null;
  const ext = merge({}, { type }, otherExt, detail);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        ...ext,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [queryCondition, otherExt, type, showDetail]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  // @ts-ignore
  const columns: IDownloadHeader[] =
    panelType === 0
      ? weekColumns(type)
      : showDetail
      ? CAACDetailColumns(type)
      : CAACSummaryColumns(type);

  const handleChange = (e: RadioChangeEvent) => {
    const val = e.target.value;
    setType(val);
  };

  const tableData = data.map((item: any, idx: number) => {
    item.key = idx;
    return item;
  });

  const getFilterData = () => {
    const cloneData = _.cloneDeep(tableData);
    const filter = cloneData.filter((item: any) => {
      let isPass = false;
      for (const key of ["dcityName", "acityName", "takeoffdate", "flightNO"]) {
        if (item[key] && item[key].includes(searchText.toUpperCase())) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = getFilterData();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDownload = () => {
    const sheetData: any = [];
    filterData.forEach((item: any, idx: number) => {
      const obj: any = {};
      columns.forEach((col: IDownloadHeader) => {
        const val = item[col.dataIndex];
        const title = getDownloadColumnHeader(col);
        // 计算渲染的结果, 如果渲染的结果是string或者number, 就直接拿来用
        const renderValTmp = col.render ? col.render(val, item, idx) : null;
        const renderVal =
          typeof renderValTmp === "string" || typeof renderValTmp === "number"
            ? renderValTmp
            : val;
        obj[title] = col.downloadFormatter
          ? col.downloadFormatter(val, item, idx)
          : renderVal;
      });
      // let obj: any = {
      //     '始发': `${item.dcityName}(${item.dcityCode})`,
      //     '到达': `${item.acityName}(${item.acityCode})`,
      //     '起飞日期': item.takeoffdate,
      //     '承运航班': item.flightNO,
      //     '座位数(估算)': _.round(item.transportCapacity),
      // } typeName = `${flightPlanTypes[type]}日期`;
      // obj[typeName] = item.effectdate;
      sheetData.push(obj);
    });
    const sheet = XLSX.utils.json_to_sheet(sheetData);
    const blob = sheet2blob(sheet);
    openDownloadDialog(
      blob,
      getSharkText("menu_flight_plan_airlines") + ".xlsx"
    );
  };

  return (
    <>
      <Row align="middle" gutter={32} className="filght_plan_filter">
        <Col id="flightPlanTableType">
          <Radio.Group value={type} onChange={handleChange}>
            {flightPlanTypes.map((item, idx) => (
              <Radio.Button value={idx} key={idx}>
                {item}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Col>
        <Col style={{ width: 350 }}>
          <Input.Search
            value={searchText}
            placeholder={getSharkText("key.input_to_search_relevant")}
            onChange={handleSearch}
          />
        </Col>
        {panelType === 1 ? (
          <Col style={{ width: 350 }}>
            <span style={{ display: "inline-block", marginRight: 5 }}>
              {getSharkText("key.details.flightplan")}:
            </span>
            <Switch checked={showDetail} onChange={setShowDetail} />
          </Col>
        ) : undefined}
        <Col style={{ flex: 1, textAlign: "right" }}>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Table
          showSorterTooltip={false}
          loading={isLoading}
          tableLayout="fixed"
          dataSource={filterData}
          // @ts-ignore
          columns={columns}
          className="filght_plan_table"
        />
      </Row>
    </>
  );
};

export default PlanTable;
