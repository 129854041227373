import { AirlinesQueryCondition } from "Interface";
import { cloneDeep, unionBy } from "lodash";
import moment from "moment";
import { getServer } from "Service/server";
import { FetchProps } from "Utils";
import { TView } from "../DynamicPriceInterface";

export const lfTypes: string[] = ["lf", "comLf", "predictLf", "lineLf"];

export const queryLoadParam = (
  type: number,
  systemType: 1 | 2,
  query: AirlinesQueryCondition,
  ext: Record<string, unknown>
): FetchProps => ({
  server: getServer(systemType),
  url: "queryDynamicPriceHistoryLoad",
  defaultValue: [],
  head: {
    moduleCode: "dynamic_price_airlines",
    chartTableCode: "AL0801",
  },
  query,
  ext: {
    ...ext,
    type,
  },
});

export const refetchLoadParam = (
  type: number,
  query: AirlinesQueryCondition,
  ext: any
): Partial<FetchProps> => ({
  head: {
    moduleCode: "dynamic_price_airlines",
    chartTableCode: "AL0801",
  },
  query,
  ext: {
    ...ext,
    type,
  },
});

export interface IDataBase {
  // 后端给的时间戳string类型: /Date(1612368000000)/, 前端计算的时间戳数值类型: 1612368000000
  day: string | number;
  diffTime: number;
}

// 这个接口允许lf为空, 给数据合并的中间计算用的
export interface IHistoryLoadTmp extends IDataBase {
  lf?: number;
  ctripLf?: number;
  predictLf?: number;
  dealLf?: number;
  compareDay?: string | number;
  compareLf?: number;
  ctripComLf?: number;
  predictComLf?: number;
  dealComLf?: number;
}
// lf不能为空, 给图形展示用
export interface IHistoryLoad extends IHistoryLoadTmp {
  lf: number;
  d: string;
  dTs?: string;
}

export interface IHistorySuggest extends IDataBase {
  suggestPrice: number;
  rawPrice: number;
  feedback: 0 | 1 | 2;
}

export interface IHistoryPrice extends IDataBase {
  price?: number;
  nearPrice?: number;
  minPrice?: number;
  racePriceAvgSame?: number;
  compareDay?: string | number;
  comparePrice?: number;
  compareMinPrice?: number;
  compareNearPrice?: number;
  compareRacePriceAvgSame?: number;
  d: string;
}

export interface IHistoryPriceMerged extends IDataBase {
  dealLf?: number;
  dealComLf?: number;
}

/**
 * 航班上座数据为实时数据, 距起飞实时数据是准确的。 携程数据和预测数据为T+1数据, 距起飞小时数为按日计算，不准确，无法与实时数据匹配。需要获取数据后再次计算
 * 计算逻辑, 先根据任意实时数据为标准计算出航班的起飞时间, 再用携程或者预测的时间戳计算距起飞小时数
 *
 * @param standardData
 * @param data
 */
export const calculateDiffTime = <T extends IDataBase>(
  standardData: IDataBase,
  data: T[]
): T[] => {
  if (!standardData) {
    return data;
  }
  const takeoffTimeMoment = moment(standardData.day).add(
    standardData.diffTime,
    "hours"
  );
  const dataCP = cloneDeep(data);
  dataCP.forEach((d: T) => {
    const diffSeconds = moment(takeoffTimeMoment).diff(d.day, "second");
    const diffHours = diffSeconds / 3600;
    d.diffTime = diffHours;
  });
  return dataCP;
};

/**
 * 计算坐标轴的时间, 因为采集时间有分有秒, 计算时存在大量四舍五入比对比较繁琐,
 * 因此, 预售模式使用起飞日期, 销售过程模式使用diffTime的提前期计算小时时间
 */
export const calcD = (
  diffHours: number,
  tkDate: string,
  view: TView
): string => {
  if (view === 0) {
    const n = moment(tkDate)
      .set({ hour: 24, minute: 0, second: 0, millisecond: 0 })
      .add(-1 * diffHours, "hours")
      .valueOf();
    return n ? `/Date(${n})/` : "";
  } else if (view === 1) {
    const n = moment(tkDate)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .valueOf();
    return n ? `/Date(${n})/` : "";
  }
  return "";
};

/**
 * 调价建议弹窗向数组中填充数据
 * @param data 待填充数据
 * @param start 开始点
 * @param end 结束点
 */
export const fillData = (
  data: any[],
  start: number,
  end: number,
  viewType: TView
): any[] => {
  const hour = 1000 * 3600;
  const step = viewType === 0 ? hour : hour * 24;
  // 先生成一个example数组
  const exampleLen = Math.floor((end - start) / step);
  const exampleArr: IHistoryLoad[] = Array.from(
    { length: exampleLen },
    (v, i) => {
      const tmpD = `/Date(${start + i * step})/`;
      return {
        diffTime: -1,
        d: tmpD,
        dTs: moment(tmpD).toISOString(),
      } as IHistoryLoad;
    }
  );
  return unionBy(data, exampleArr, "d");
};
