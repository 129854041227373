import React, { ReactElement, useEffect, useState } from "react";
import SearchArea from "./Components/SearchArea";
import useRefFunc from "Utils/useRefFunc";
import useGlobalState from "Store";
import {
  AirlinesQueryCondition,
  IDateMode,
  RangeCompare,
  RangeObject,
} from "Interface";
import { DATE_FORMAT, DEFAULT_AIRLINE } from "Constants";
import { getDateRange, isSame } from "Utils";
import FullSpin from "Components/FullSpin";
import PlanTable from "./Components/PlanTable";
import { WorkBook } from "xlsx";

export const DEFAULT_DATE: IDateMode = "lastWeek";

export interface SalesPlanProps {
  example?: string;
}

export interface QueryParam {
  rangeCompare: RangeCompare;
  routes: string[];
  flights: string[];
}

/** 销售预案 */
const SalesPlan = (): ReactElement => {
  const [range, setRange] = useState<RangeObject>(getDateRange(DEFAULT_DATE));
  const [globalState, actions] = useGlobalState();
  const [param, setParam] = useState<QueryParam | null>(null);
  const [uploadData, setUploadData] = useState<WorkBook | null>(null);
  const { airlinesQueryCondition } = globalState;
  useEffect(() => {
    const initParam: Partial<AirlinesQueryCondition> = {};
    if (!airlinesQueryCondition.airlines) {
      initParam.airlines = DEFAULT_AIRLINE;
    }
    if (!airlinesQueryCondition.startDate) {
      initParam.startDate = (range && range[0]?.format(DATE_FORMAT)) ?? "";
    }
    if (!airlinesQueryCondition.endDate) {
      initParam.endDate = (range && range[1]?.format(DATE_FORMAT)) ?? "";
    }
    if (Object.keys(initParam).length) {
      actions.setAirlinesQueryConditionAttr(initParam);
    }
  }, []);
  const onSearch = useRefFunc((v: QueryParam | null) => {
    if (v == null) {
      setParam(null);
    }
    if (!isSame(v, param)) {
      setParam(v);
    }
  });

  if (!airlinesQueryCondition.airlines || !airlinesQueryCondition.startDate) {
    return <FullSpin />;
  }
  return (
    <div className="sales-plan">
      <div className="content-white" style={{ display: "flex" }}>
        <SearchArea onChange={onSearch} onChangeUpload={setUploadData} />
        <div></div>
      </div>
      <div className="content-white">
        {param && (
          <PlanTable
            rangeCompare={param.rangeCompare}
            flights={param.flights}
            routes={param.routes}
            uploadData={uploadData}
            clearUpload={() => setUploadData(null)}
          />
        )}
      </div>
    </div>
  );
};
export default SalesPlan;
