import ProTable, { ColumnsState } from "@ant-design/pro-table";
import {
  FlightReviewFlightFilter,
  FlightSegmentFilter,
} from "@ctrip/flt-bi-flightai-airlines";
import { Radio, Select, Spin } from "antd";
import Refetch from "Components/Refetch";
import { AggCode } from "Interface";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { downloadExcel } from "Utils/downloadXLSX";
import useRefFunc from "Utils/useRefFunc";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../../fetchCode";
import { FlightReviewFlightTable } from "../../interface";
import { getColumns } from "./tableColumns";
import DownloadBtn from "../../../../../Components/DownloadBtn";

const localStorageKey = "flight_segment_flight_columns_state";

const OPTIONS = [
  { label: "无分摊", value: "default" },
  { label: "投影分摊", value: "apportion" },
];

const getDefaultColumnsState = (): Record<string, ColumnsState> => {
  const s = localStorage.getItem(localStorageKey);
  if (s) {
    return JSON.parse(s);
  } else {
    return {};
  }
};

export interface ITablePortalProps {
  filter: FlightSegmentFilter | FlightReviewFlightFilter;
}

const allowAgg = [
  { label: getSharkText("config_page_all"), value: 4 },
  { label: getSharkText("key.day.button"), value: 0 },
  { label: getSharkText("key.week.button"), value: 1 },
  { label: getSharkText("key.month.button"), value: 2 },
  { label: getSharkText("key.year.button"), value: 3 },
];

/**
 * 航段数据-表格
 */
const TablePortal = (props: ITablePortalProps): ReactElement => {
  const { filter } = props;
  const [aggCode, setAggCode] = useState<AggCode>(AggCode.total);
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const { startDate, endDate } = airlinesQueryCondition;
  const [groups, setGroups] = useState<string[]>(["default"]);
  const [columnsState, setColumnsState] = useState<
    Record<string, ColumnsState>
  >(getDefaultColumnsState());
  const [{ data, isLoading, error }, doFetch] = useFetch<
    FlightReviewFlightTable[]
  >({
    url: QUERY_URL.flight,
    server: getServer(systemType),
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE.table,
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (
      airlinesQueryCondition.startDate &&
      filter.departArea &&
      filter.arriveArea
    ) {
      doFetch({
        query: {
          ...airlinesQueryCondition,
          // airlines: "ALL",
          // airlinesName: "所有航司",
        },
        ext: {
          filter,
          aggCode,
        },
      });
    }
  }, [aggCode, airlinesQueryCondition, doFetch, filter]);

  useEffect(() => refetch(), [refetch]);

  const agg = useMemo(() => {
    return (
      <div>
        <Radio.Group
          options={allowAgg}
          optionType="button"
          buttonStyle="solid"
          value={aggCode}
          onChange={(e) => setAggCode(e.target.value as AggCode)}
        ></Radio.Group>
        <Select
          mode="multiple"
          style={{ minWidth: 120, marginLeft: 10 }}
          placeholder="请选择分组"
          options={OPTIONS}
          value={groups}
          onChange={(v: any) => setGroups(v)}
        />
      </div>
    );
  }, [aggCode, groups]);

  const columns: any = useMemo(() => {
    return getColumns(aggCode, false, startDate, endDate, groups);
  }, [aggCode, endDate, groups, startDate]);

  const onColumnsStateChange = (v: Record<string, ColumnsState>) => {
    localStorage.setItem(localStorageKey, JSON.stringify(v));
    setColumnsState(v);
  };

  const handleDownload = useRefFunc(() => {
    const names = OPTIONS.filter((o) => groups.includes(o.value)).map(
      (o) => o.label
    );
    downloadExcel(columns, data, "航班数据-" + names.join(","));
  });

  const downloadBtn = useMemo(() => {
    return (
      <DownloadBtn
        handleDownload={handleDownload}
        size={"small"}
        moduleCode={MODULE_CODE}
        chartTableCode={CHART_TABLE_CODE.table}
      />
    );
  }, [handleDownload]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  if (isLoading) {
    return <Spin />;
  }

  return (
    <ProTable
      columns={columns}
      dataSource={data}
      headerTitle={agg}
      tableLayout="auto"
      size="small"
      toolBarRender={() => [downloadBtn]}
      showSorterTooltip={false}
      search={false}
      options={{ density: false, reload: false }}
      columnsStateMap={columnsState}
      onColumnsStateChange={onColumnsStateChange}
      // downloadName="航段数据"
      // showLimit={1000}
    />
  );
};
export default TablePortal;
