import { QuestionCircleOutlined } from "@ant-design/icons";
import { BarLineData } from "@ctrip/flt-bi-flightai-airlines";
import { Badge, Button, Calendar, Col, Row, Select, Spin, Tooltip } from "antd";
import { HeaderRender } from "antd/lib/calendar/generateCalendar";
import { DATE_FORMAT, DATE_FORMAT_MONTH } from "Constants";
import moment, { Moment } from "moment";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { getCalendarRange, showLunarDay, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import SelectMultiRoute from "./SearchArea/SelectMultRoute";
import "./TrafficCalendar.css";

export interface ITrafficCalendarProps {
  isDemo: boolean;
  flightNO?: string[];
  route?: string;
}

const types = [
  {
    title: getSharkText("config_page_city_pair_traffic"),
    field: "compareValue",
    color: "#F7D04C",
  },
  {
    title: getSharkText("config_page_segment_traffic"),
    field: "value",
    color: "#70B603",
  },
];

/**
 * 流量日历
 */
const TrafficCalendar = (props: ITrafficCalendarProps): ReactElement => {
  const { isDemo, flightNO, route } = props;
  const [routes, setRoutes] = useState<string>(route || "");
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const [dates, setDates] = useReducer(
    (state: any, v: Moment) => {
      let rangeChange = null;
      if (
        v.format(DATE_FORMAT_MONTH) !== state.value.format(DATE_FORMAT_MONTH)
      ) {
        rangeChange = {
          ranges: getCalendarRange(v),
        };
      }
      return {
        ...state,
        value: v,
        ...rangeChange,
      };
    },
    {
      value: moment(airlinesQueryCondition.startDate),
      ranges: getCalendarRange(moment(airlinesQueryCondition.startDate)),
    }
  );
  const [showTypes, setShowTypes] = useState<string[]>(
    types.map((t) => t.field)
  );

  const [{ data, isLoading }, doFetch] = useFetch<BarLineData[]>({
    server: getServer(systemType),
    url: QUERY_URL.calendar,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
  });
  useEffect(() => {
    doFetch({
      query: {
        ...airlinesQueryCondition,
        startDate: dates.ranges[0].format(DATE_FORMAT),
        endDate: dates.ranges[1].format(DATE_FORMAT),
      },
      ext: { line: routes },
    });
  }, [airlinesQueryCondition, dates.ranges, doFetch, routes]);

  const dateFullCellRender = useCallback(
    (date: Moment) => {
      const curDayStr = date.format(DATE_FORMAT);
      const item: any = data.find(
        (d) => moment(d.day).format(DATE_FORMAT) === curDayStr
      );
      const isToday = date.format(DATE_FORMAT) === moment().format(DATE_FORMAT);
      const lunar = showLunarDay(date.toDate());
      return (
        <div
          className={`ant-picker-cell-inner ant-picker-calendar-date ${
            isToday ? "ant-picker-calendar-date-today" : ""
          }`}
        >
          <div className="ant-picker-calendar-date-value">
            <span
              style={{
                marginRight: 20,
                display: "inline-block",
                fontSize: "120%",
                fontWeight: 600,
              }}
            >
              {date.date()}
            </span>
            <span style={{ fontSize: "90%" }}>{lunar}</span>
          </div>
          <div className="ant-picker-calendar-date-content">
            {item ? (
              <ul>
                {types.map((t) => {
                  const visible = showTypes.includes(t.field)
                    ? "visible"
                    : "hidden";
                  return (
                    <li
                      style={{ listStyle: "none", visibility: visible }}
                      key={t.field}
                    >
                      <Badge color={t.color} text={item[t.field]} />
                    </li>
                  );
                })}
              </ul>
            ) : undefined}
          </div>
        </div>
      );
    },
    [data, showTypes]
  );

  const onPanelChange = (date: Moment, mode: string) => {
    console.log("on panel change :", date, mode);
  };

  const onDateChange = useCallback((date: Moment) => {
    setDates(date);
  }, []);

  const onTypeClick = useCallback(
    (t: { title: string; field: string; color: string }) => {
      setShowTypes((st) => {
        if (st.includes(t.field)) {
          return st.filter((s) => s !== t.field);
        } else {
          return [...st, t.field];
        }
      });
    },
    []
  );

  // #region 日历头部, 控制可选年份, 增加航段筛选, 返回今日, 城市航段数据控制
  const headerRender: HeaderRender<Moment> = useCallback(
    ({ value, onChange }) => {
      const start = 0;
      const end = 12;
      const monthOptions = [];

      const current = value.clone();
      const localeData = value.localeData();
      const months = [];
      for (let i = 0; i < 12; i++) {
        current.month(i);
        months.push(localeData.monthsShort(current));
      }

      for (let index = start; index < end; index++) {
        monthOptions.push(
          <Select.Option
            className="month-item"
            key={`${index}`}
            value={months[index]}
          >
            {months[index]}
          </Select.Option>
        );
      }
      const month = value.month();

      const year = value.year();
      const todayYear = moment().year();
      const endYear = todayYear + 1;
      const yearOptions = [];
      for (let i = 2019; i <= endYear; i += 1) {
        yearOptions.push(
          <Select.Option key={i} value={i} className="year-item">
            {i}
          </Select.Option>
        );
      }
      return (
        <div style={{ padding: 8 }}>
          <Row gutter={8}>
            <Col>
              <Select
                size="small"
                dropdownMatchSelectWidth={false}
                className="my-year-select"
                onChange={(newYear) => {
                  const now = value.clone().year(parseInt(newYear, 10));
                  onChange(now);
                }}
                value={String(year)}
              >
                {yearOptions}
              </Select>
            </Col>
            <Col>
              <Select
                size="small"
                dropdownMatchSelectWidth={false}
                value={months[month]}
                onChange={(selectedMonth) => {
                  const newValue = value.clone();
                  newValue.month(parseInt(selectedMonth, 10) - 1);
                  onChange(newValue);
                }}
              >
                {monthOptions}
              </Select>
            </Col>
            <Col>
              <SelectMultiRoute
                routes={routes}
                setRoutes={(e) => setRoutes(e[0])}
                isDemo={isDemo}
                mode={undefined}
                width="auto"
                flights={flightNO ? flightNO.join(",") : ""}
                autoSelectFirst={!route}
                debugId="manage_traffic_calendar_route"
                size="small"
                permissionType="user"
              />
            </Col>
            <Col style={{ display: "flex" }}>
              <ul style={{ marginTop: -10, marginRight: 10, float: "left" }}>
                {types.map((t) => {
                  const color = showTypes.includes(t.field)
                    ? t.color
                    : "#AAAAAA";
                  return (
                    <li
                      key={t.field}
                      style={{ listStyle: "none", cursor: "pointer" }}
                      onClick={() => onTypeClick(t)}
                    >
                      <Badge
                        color={color}
                        text={<span style={{ color }}>{t.title}</span>}
                      />
                    </li>
                  );
                })}
              </ul>
              <Tooltip
                title={getSharkText("config_page_city_flow_flight_flow")}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={() => setDates(moment())}
                size="small"
              >
                {getSharkText("config_page_return_today")}
              </Button>
            </Col>
          </Row>
        </div>
      );
    },
    [flightNO, isDemo, onTypeClick, route, routes, showTypes]
  );
  // #endregion

  return (
    <Spin spinning={isLoading}>
      <Calendar
        className="traffic-calendar"
        dateFullCellRender={dateFullCellRender}
        headerRender={headerRender}
        onPanelChange={onPanelChange}
        onChange={onDateChange}
        value={dates.value}
      />
    </Spin>
  );
};
export default TrafficCalendar;
