export const MODULE_CODE: any = {
  1: "search_index_airport",
  2: "search_index_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    barLineCharts: "capacity_compare",
    table: "target_table",
  },
  2: {
    barLineCharts: "search_order_table",
    table: "AL0303",
  },
};

export const QUERY_URL: any = {
  1: {
    barLineCharts: "querySearchIndexBarLine",
    top10: "querySearchIndexTop10City",
    table: "querySearchIndexTable",
  },
  2: {
    barLineCharts: "queryAirlinesSearchIndexBarLine",
    top10: "queryAirlinesSearchIndexTop10City",
    table: "queryAirlinesSearchIndexTable",
  },
};
