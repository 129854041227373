import { Col, Empty, Row, Spin } from "antd";
import React, {
  forwardRef,
  ReactElement,
  Ref,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import OverviewCardItem from "./OverviewCardItem";
import { OVERVIEW_CARDS_CONFIG } from "./OverviewCardsConfig";
import { useFetch } from "Utils";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import {
  ACC_REVENUE_NAME,
  AHEADHOUR_CAL_NAME,
  ASK_NAME,
  ASK_REVENUE_NAME,
  AVG_PRICE_NAME,
  CAP_NAME,
  DATACHANGE_LASTTIME_NAME,
  DATASET_ID,
  DATASET_ID_NEWEST,
  FLYING_HOURS_NAME,
  GROUP_BKD_NAME,
  GROUP_PCT_NAME,
  HOURS_REVENUE_NAME,
  LOAD_FACTOR_NAME,
  PLAN_MINUTES_NAME,
  RPK_NAME,
  RPK_REVENUE_NAME,
  SALEDSEAT_NAME,
  SCHEDULE_CNT_NAME,
  TAKEOFF_CNT_NAME,
  TAKEOFFDATE_NAME,
  TPM_NAME,
} from "../../DatasetCols";
import {
  ChartRenderConfig,
  Dimension,
  DimensionSchemaEx,
  FilterValue,
  Measure,
  MeasureSchemaEx,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { getRenderMeasureColumnName } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/common";
import {
  FDDatasetCol2DimensionSchemaEx,
  FDDatasetCol2MeasureSchemaEx,
} from "Page/AI/FreeDashboard/common";
import {
  CompareMean,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { MetricSchema } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/schema";
import useRefFunc from "Utils/useRefFunc";
import { QueryHandler, QueryParam } from "Page/AI/ProfitMonitor/interface";
import { DATE_FORMAT } from "Constants";
import moment from "moment";
import { calculate } from "./calculate";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import { ComparatorArg } from "Interface";

interface ResData extends Record<string, any> {
  SUM_acc_revenue: number;
  SUM_saledseat: number;
  SUM_all_seats: number;
  m_exp_schedule_cnt: number;
  m_exp_flying_hours: number;
  m_exp_hours_revenue: number;
  m_exp_ask_revenue: number;
  m_exp_load_factor: number;
  m_exp_avg_price: number;
  m_exp_group_pct: number;
  m_exp_takeoff_cnt: number;
}

export interface OverviewCardsProps {
  columns: FDDatasetCol[];
  params: QueryParam;
  commonFilters: FilterValue[];
}

/**  Component description */
const OverviewCards = forwardRef(
  (props: OverviewCardsProps, ref: Ref<QueryHandler>): ReactElement => {
    const { columns, params, commonFilters } = props;

    const [summaryData, setSummaryData] = useState<ResData>();
    const [lineResponse, setLineResponse] = useState<StandardResponse | null>();

    const summaryDimensions = useMemo(() => {
      const tmp: Dimension[] = [
        {
          columnName: TAKEOFFDATE_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
        {
          columnName: AHEADHOUR_CAL_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
      ];
      return tmp;
    }, []);

    const lineDimensions: Dimension[] = useMemo(() => {
      return [
        {
          columnName: DATACHANGE_LASTTIME_NAME,
          dimensionConfig: {
            type: "row",
            calculateConfig: null,
          },
        },
      ];
    }, []);

    const lineMeasures = useMemo(() => {
      const tmp: Measure[] = [
        {
          id: ACC_REVENUE_NAME,
          columnName: ACC_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: SALEDSEAT_NAME,
          columnName: SALEDSEAT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: CAP_NAME,
          columnName: CAP_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: SCHEDULE_CNT_NAME,
          columnName: SCHEDULE_CNT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: FLYING_HOURS_NAME,
          columnName: FLYING_HOURS_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: HOURS_REVENUE_NAME,
          columnName: HOURS_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: ASK_REVENUE_NAME,
          columnName: ASK_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              precision: 6,
            },
          },
        },
        {
          id: LOAD_FACTOR_NAME,
          columnName: LOAD_FACTOR_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "percent",
              precision: 2,
            },
          },
        },
        {
          id: AVG_PRICE_NAME,
          columnName: AVG_PRICE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "decimal",
              precision: 2,
            },
          },
        },
        {
          id: RPK_REVENUE_NAME,
          columnName: RPK_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              precision: 6,
            },
          },
        },
        {
          id: GROUP_PCT_NAME,
          columnName: GROUP_PCT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "percent",
              precision: 2,
            },
          },
        },
        {
          id: RPK_REVENUE_NAME,
          columnName: RPK_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "decimal",
              precision: 6,
            },
          },
        },
        {
          id: GROUP_BKD_NAME,
          columnName: GROUP_BKD_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "percent",
              precision: 2,
            },
          },
        },
        {
          id: TAKEOFF_CNT_NAME,
          columnName: TAKEOFF_CNT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
      ];
      return tmp;
    }, []);

    /** 可累加的总计值指标, 用以后置计算 */
    const tmpMeasures = useMemo(() => {
      const tmp: Measure[] = [
        {
          id: ACC_REVENUE_NAME,
          columnName: ACC_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: SALEDSEAT_NAME,
          columnName: SALEDSEAT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: CAP_NAME,
          columnName: CAP_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: SCHEDULE_CNT_NAME,
          columnName: SCHEDULE_CNT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: PLAN_MINUTES_NAME,
          columnName: PLAN_MINUTES_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: TPM_NAME,
          columnName: TPM_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
        {
          id: HOURS_REVENUE_NAME,
          columnName: HOURS_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
              precision: 0,
            },
          },
        },
        {
          id: ASK_NAME,
          columnName: ASK_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "decimal",
              precision: 6,
            },
          },
        },
        {
          id: RPK_NAME,
          columnName: RPK_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "decimal",
              precision: 6,
            },
          },
        },
        {
          id: LOAD_FACTOR_NAME,
          columnName: LOAD_FACTOR_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "percent",
              precision: 2,
            },
          },
        },
        {
          id: AVG_PRICE_NAME,
          columnName: AVG_PRICE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
              precision: 0,
            },
          },
        },
        {
          id: RPK_REVENUE_NAME,
          columnName: RPK_REVENUE_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "decimal",
              precision: 6,
            },
          },
        },
        {
          id: GROUP_BKD_NAME,
          columnName: GROUP_BKD_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              type: "percent",
              precision: 2,
            },
          },
        },
        {
          id: TAKEOFF_CNT_NAME,
          columnName: TAKEOFF_CNT_NAME,
          measureConfig: {
            statisticalConfig: {
              method: "SUM",
            },
            formatConfig: {
              thousandths: 3,
            },
          },
        },
      ];
      return tmp;
    }, []);

    const summaryMeasures = useMemo(() => {
      return tmpMeasures;
    }, [tmpMeasures]);

    const summaryFilters = useMemo(() => {
      return commonFilters;
    }, [commonFilters]);

    const summaryBuilder = useMemo(() => {
      const comparatorWowFilter: ComparatorArg = {
        key: "takeoffdate",
        op: "REPLACE",
        filter: {
          range: {
            field: `dimension.takeoffdate`,
            strRange: {
              lower: params.wowStart,
              upper: params.wowEnd,
            },
          },
        },
      };
      const comparatorYoyFilter: ComparatorArg = {
        key: "takeoffdate",
        op: "REPLACE",
        filter: {
          range: {
            field: `dimension.takeoffdate`,
            strRange: {
              lower: params.yoyStart,
              upper: params.yoyEnd,
            },
          },
        },
      };
      return new RequestBuilder({
        datasetId: DATASET_ID_NEWEST,
        columns,
        dimensions: summaryDimensions,
        measures: summaryMeasures,
        chartFilters: [],
        containerFilters: summaryFilters,
        sorters: [],
        granularity: "1d",
        limit: 1000,
        comparators: [
          {
            name: "filterKey",
            comparisonName: "wow",
            means: [CompareMean.abs],
            args: ["", JSON.stringify(comparatorWowFilter)],
          },
          {
            name: "filterKey",
            comparisonName: "yoy",
            means: [CompareMean.abs],
            args: ["", JSON.stringify(comparatorYoyFilter)],
          },
        ],
      });
    }, [
      columns,
      summaryFilters,
      params.wowEnd,
      params.wowStart,
      params.yoyEnd,
      params.yoyStart,
      summaryDimensions,
      summaryMeasures,
    ]);

    const { requestBody, encrypted } = useMemo(() => {
      return summaryBuilder.getRequestBody();
    }, [summaryBuilder]);

    const lineFilters = useMemo(() => {
      return commonFilters.concat([
        {
          filterId: DATACHANGE_LASTTIME_NAME,
          filterType: "date",
          argOfReports: [
            {
              panelId: "1",
              columnName: DATACHANGE_LASTTIME_NAME,
              values: {
                current: [
                  moment().add(-0, "d").format(DATE_FORMAT),
                  moment().add(-0, "d").endOf("day").toISOString(),
                ],
              },
            },
          ],
        },
      ]);
    }, [commonFilters]);

    const lineBuilder = useMemo(() => {
      return new RequestBuilder({
        datasetId: DATASET_ID,
        columns,
        dimensions: lineDimensions,
        measures: lineMeasures,
        chartFilters: [],
        containerFilters: lineFilters,
        sorters: [
          {
            chartUsedColId: DATACHANGE_LASTTIME_NAME,
            columnName: DATACHANGE_LASTTIME_NAME,
            sorter: "ASC",
            statistical: "SUM",
          },
        ],
        granularity: "1h",
      });
    }, [columns, lineFilters, lineDimensions, lineMeasures]);

    const { requestBody: lineRequestBody, encrypted: lineEncrypted } =
      useMemo(() => {
        return lineBuilder.getRequestBody();
      }, [lineBuilder]);

    const [{ isLoading }, doFetchSummary] = useFetch({
      url: "mytrixQuery",
      head: {},
      lazey: true,
      debugId: "overview-summary",
      onSuccess: (r) => {
        const res = JSON.parse(r.data);
        if (res.status === 40000) {
          throw new Error("40000");
        }
        const rows = res.rows;
        const headers = res.headers;
        const source = DataRow2ListMap(rows, headers) as unknown as ResData[];
        if (!requestBody) {
          return;
        }
        const rst = calculate(requestBody, source, lineMeasures, columns, [
          TAKEOFFDATE_NAME,
          AHEADHOUR_CAL_NAME,
        ]);
        setSummaryData(rst as any);
      },
    });

    const [{ isLoading: isLoadingLine }, doFetchLine] = useFetch({
      url: "mytrixQuery",
      head: {},
      lazey: true,
      debugId: "overview-line",
      onSuccess: (r) => {
        const res = JSON.parse(r.data);
        if (res.status === 40000) {
          throw new Error("40000");
        }
        setLineResponse(res);
      },
    });

    const reqSummary = useRefFunc(() => {
      return doFetchSummary({
        ext: {
          datasetId: DATASET_ID_NEWEST,
          colIds: [],
          req: encrypted,
        },
      });
    });

    useEffect(() => {
      setSummaryData(undefined);
      reqSummary();
    }, [encrypted, reqSummary]);

    const reqLine = useRefFunc(() => {
      return doFetchLine({
        ext: {
          datasetId: DATASET_ID,
          colIds: [],
          req: lineEncrypted,
        },
      });
    });

    useEffect(() => {
      if (lineEncrypted) {
        setLineResponse(null);
        reqLine();
      }
    }, [reqLine, lineEncrypted]);

    useImperativeHandle(ref, () => ({
      query: () => {
        reqSummary();
        reqLine();
      },
    }));

    const allDataKeys = useMemo(() => {
      return Object.keys(summaryData || {});
    }, [summaryData]);

    const schema = useMemo(() => {
      const rst: MetricSchema<DimensionSchemaEx, MeasureSchemaEx> = {
        name: "test",
        version: "1",
        dimensions: columns
          .filter((c) => c.kind === 1)
          .map((c) => FDDatasetCol2DimensionSchemaEx(c)),
        measures: columns
          .filter((c) => c.kind === 0)
          .map((c) => FDDatasetCol2MeasureSchemaEx(c)),
      };
      return rst;
    }, [columns]);

    if (isLoading || isLoadingLine) {
      return <Spin></Spin>;
    }

    return (
      <div>
        <Row gutter={[10, 10]}>
          {OVERVIEW_CARDS_CONFIG.map((item) => {
            const column = columns.find((c) => c.name === item.measureName);
            const measure = lineMeasures.find(
              (m) => m.columnName === item.measureName
            );
            let data = {
              value: null,
              yoy: null,
              wow: null,
              dayValue: null,
            };
            const summaryMeasure = lineMeasures.find(
              (m) => m.columnName === item.measureName
            );
            if (column && summaryMeasure && summaryData) {
              const measureSchema = FDDatasetCol2MeasureSchemaEx(column);
              const alias = getRenderMeasureColumnName(
                measureSchema,
                summaryMeasure.measureConfig.statisticalConfig.method,
                allDataKeys
              );
              data = {
                value: summaryData[alias],
                yoy: summaryData[`yoy_grate_${alias}`],
                wow: summaryData[`wow_grate_${alias}`],
                dayValue: null,
              };
            }

            let line = null;
            if (column && measure && lineResponse && lineRequestBody) {
              const renderConfig: ChartRenderConfig = {
                id: "123",
                name: "123",
                chartType: "line",
                dimensions: lineDimensions,
                measures: [measure],
                viewConfig: {
                  line: {
                    echartsOption: {
                      legend: { show: false },
                      xAxis: { show: false },
                      yAxis: { show: false },
                      grid: { top: 5, left: 0, right: 0, bottom: 0 },
                    },
                  },
                },
              };
              line = {
                request: lineRequestBody,
                response: lineResponse,
                schema,
                renderConfig,
              };
            }

            if (!column || !summaryMeasure) {
              return <Empty key={item.measureName} />;
            }

            return (
              <Col xxl={6} xl={6} key={item.measureName}>
                <OverviewCardItem
                  measure={summaryMeasure}
                  title={item.title}
                  data={data}
                  line={line}
                />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
);
OverviewCards.displayName = "OverviewCards";
export default OverviewCards;
