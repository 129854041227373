import React, { ReactElement, useState } from "react";
import SearchArea from "./SearchArea";
import { DATASET_ID } from "./beyondCols";
import { useDataset } from "Page/AI/FreeDashboard/useDataset";
import BeyondChart from "./BeyondChart";
import BeyondTable from "./BeyondTable";
import { BeyondParam } from "./interface";

export interface BeyondProps {
  example?: string;
}

/**  Component description */
const Beyond = (props: BeyondProps): ReactElement => {
  const { example } = props;
  const [param, setParam] = useState<BeyondParam>();

  const { isLoading, datasetCols } = useDataset(DATASET_ID);

  return (
    <div>
      <SearchArea onQuery={setParam} />
      {param ? (
        <>
          <div>
            <h3 style={{ marginTop: 10 }}>TOP 10 Destination</h3>
            <div style={{ border: "1px solid var(--filter-bg-border)" }}>
              <BeyondChart param={param} cols={datasetCols} />
            </div>
          </div>
          <div style={{ marginTop: 25 }}>
            <BeyondTable param={param} cols={datasetCols} />
          </div>
        </>
      ) : undefined}
    </div>
  );
};
export default Beyond;
