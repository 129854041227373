import {
  CloseOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { getSharkText } from "Utils/i18nGlobal";
import { Input, InputRef, message, Modal, Tag, Tooltip } from "antd";
import React, { ReactElement, useEffect, useRef, useState } from "react";

export interface ITagsInputProps {
  tags: string[];
  /** 新增或修改 */
  onUpsert: (newTag: string, oldTag?: string) => void;
  onRemove: (name: string) => void;
  placeholder?: string;
}

/**
 * 标签显示及增删
 */
const TagsInput = (props: ITagsInputProps): ReactElement => {
  const { tags, onUpsert, onRemove, placeholder = "New tag" } = props;
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  const handleClose = (removedTag: string) => {
    Modal.confirm({
      title: getSharkText("config_page_confirm_delete_category"),
      icon: <ExclamationCircleOutlined />,
      content: getSharkText("config_page_all_reports_unfiltered"),
      onOk: () => {
        onRemove(removedTag);
      },
      onCancel: () => {
        console.log(getSharkText("key.cancel.button"));
      },
    });
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (!inputValue) {
      message.error("tag error");
    } else if (tags.indexOf(inputValue) !== -1) {
      message.error("Duplicate tags");
    } else {
      onUpsert(inputValue);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    const oldTag = newTags[editInputIndex];
    onUpsert(editInputValue, oldTag);
    setEditInputIndex(-1);
    setInputValue("");
  };

  return (
    <>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={editInputRef}
              key={tag}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag className="edit-tag" key={tag}>
            <span
              onDoubleClick={(e) => {
                setEditInputIndex(index);
                setEditInputValue(tag);
                e.preventDefault();
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
            <CloseOutlined onClick={() => handleClose(tag)} />
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          ref={inputRef}
          type="text"
          size="small"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <PlusOutlined style={{ marginRight: 5 }} />
          {placeholder}
        </Tag>
      )}
    </>
  );
};
export default TagsInput;
