import React, { ReactElement, useMemo } from "react";
import { CompareType, DetailItemValue } from "./DetailCardItem";
import { Col, Row } from "antd";
import { showNum } from "Utils";
import { FormatConfig } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import { valueFormatter } from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/Components/Views";
import { getTrendColor } from "Page/AI/ProfitMonitor";
import EchartsReactBase from "Components/EchartsReactBase";

export interface DetailItemIndexProps {
  title: string;
  data: DetailItemValue;
  formatConfig: FormatConfig;
  compareType: CompareType;
}

/** 指标展示 */
const DetailItemIndex = (props: DetailItemIndexProps): ReactElement => {
  const { title, data, compareType, formatConfig } = props;
  const compareValue = compareType === "yoy" ? data.yoy : data.wow;

  const option = useMemo(() => {
    return {
      dataset: {
        source: data.trend,
      },
      xAxis: {
        type: "category",
        show: false,
      },
      yAxis: {
        type: "value",
        show: false,
      },
      tooltip: {
        trigger: "axis",
        formatter: (v: any) => {
          if (v && v[0]?.data) {
            const date = v[0].data.datachange_lasttime;
            const value = v[0].data.value;
            return `${date}<br />${valueFormatter(value, formatConfig)}`;
          }
        },
      },
      grid: { left: 0, right: 0, top: 0, bottom: 0 },
      series: [
        {
          type: "line",
          symbol: null,
          encode: {
            x: "datachange_lasttime",
            y: "value",
          },
        },
      ],
    };
  }, [data.trend, formatConfig]);

  return (
    <Row gutter={[5, 5]} justify="space-between">
      <Col span={4} style={{ fontWeight: "bold" }}>
        {title}
      </Col>
      <Col span={4} style={{ fontWeight: "bold" }}>
        {valueFormatter(data.value, formatConfig)}
      </Col>
      <Col span={4} style={{ color: getTrendColor(compareValue) }}>
        {compareValue === null
          ? "-"
          : showNum(compareValue * 100, "percentage", 1)}
      </Col>
      <Col span={12}>
        <div style={{ height: 50 }}>
          <EchartsReactBase style={{ height: 50 }} option={option} notMerge />
        </div>
      </Col>
    </Row>
  );
};
export default DetailItemIndex;
