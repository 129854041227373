import { Button, Col, message, Radio, Row, Spin, Typography } from "antd";
import { CheckboxOptionType } from "antd/es/checkbox";
import React, { ReactElement, useMemo, useState } from "react";
import "./RouteFlightArea.scss";
import { DoubleRightOutlined } from "@ant-design/icons";
import NotifyTag from "Components/NotifyTag/NotifyTag";
import { RouteFlightSearchParam } from "../interface";
import SelectMultRoute from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import useRefFunc from "Utils/useRefFunc";
import { uniq } from "lodash";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { getServer } from "Service/server";
import { SystemType } from "Interface";
import useGlobalState from "Store";
import { QUERY_URL } from "Page/AI/FlightManage/fetchCode";

const SearchTypes = ["route", "flight"] as const;
type SearchType = (typeof SearchTypes)[number];

const SEARCH_TYPE: CheckboxOptionType[] = [
  {
    label: getSharkText("config_page_search_type_route"),
    value: "route",
  },
  {
    label: getSharkText("config_page_search_type_flightno"),
    value: "flight",
  },
];

export interface RouteFlightAreaProps {
  value: RouteFlightSearchParam;
  onChange: (v: RouteFlightSearchParam) => void;
  hasAll?: boolean;
}

/** 航段/航班号查询组件 */
const RouteFlightArea = (props: RouteFlightAreaProps): ReactElement => {
  const { value, onChange, hasAll = true } = props;
  const [globalState] = useGlobalState();
  const [searchType, setSearchType] = useState<SearchType>("route");
  const [tmpRoutes, setTmpRoutes] = useState<string[]>([]);
  const [routes, setRoutes] = useState<string[]>(value.routes);
  const [selectedRoutes, setSelectedRoutes] = useState<string[]>(["all"]);
  const [tmpFlights, setTmpFlights] = useState<string[]>([]);
  const [flights, setFlights] = useState<string[]>(value.flights);
  const [selectedFlights, setSelectedFlights] = useState<string[]>(["all"]);

  const [{ isLoading }, fetchRight] = useFetch({
    server: getServer(SystemType.airlines),
    // url: "queryFlightManageRouteOrFlight",
    url: QUERY_URL.routeFlight,
    head: {
      moduleCode: "flight_manage_airlines",
      chartTableCode: null,
    },
    query: globalState.airlinesQueryCondition,
    lazey: true,
    useCache: true,
    onSuccess: (res) => {
      if (!res.data) {
        message.warn("查询结果为空");
      }
      if (searchType === "route") {
        setFlights(res.data.map((d: any) => d.flightNo));
      } else {
        setRoutes(res.data.map((d: any) => d.line));
      }
    },
  });

  const doFetchRight = useRefFunc((left: string[], viewType: SearchType) => {
    if (!left.length) {
      return;
    }
    fetchRight({
      ext: {
        permissionType: "group",
        type: viewType === "route" ? 2 : 1,
        code: left,
      },
    });
  });

  const changeRoutesOrFlights = useRefFunc(() => {
    if (searchType === "route") {
      const tmp = uniq(routes.concat(tmpRoutes));
      setRoutes(tmp);
      setTmpRoutes([]);
    } else if (searchType === "flight") {
      const tmp = uniq(flights.concat(tmpFlights));
      setFlights(tmp);
      setTmpFlights([]);
    }
  });

  const clickAll = useRefFunc(() => {
    if (searchType === "route") {
      setSelectedRoutes(["all"]);
      setFlights([]);
      setSelectedFlights([]);
      onChange({ ...value, routes: [] });
    } else if (searchType === "flight") {
      setSelectedFlights(["all"]);
      setRoutes([]);
      setSelectedRoutes([]);
      onChange({ ...value, flights: [] });
    }
  });

  const tagClick = useRefFunc((tagKey: string, type: SearchType) => {
    if (type === "route") {
      let tr = selectedRoutes.filter((r) => r !== "all");
      if (tr.includes(tagKey)) {
        tr = tr.filter((r) => r !== tagKey);
        if (tr.length) {
          onChange({ ...value, routes: tr });
          if (type === searchType) {
            doFetchRight(tr, searchType);
          }
        } else {
          if (type === searchType) {
            clickAll();
          } else {
            setSelectedRoutes([]);
            onChange({ ...value, routes: [] });
          }
        }
      } else {
        tr.push(tagKey);
        onChange({ ...value, routes: tr });
        if (type === searchType) {
          doFetchRight(tr, searchType);
        }
      }
      setSelectedRoutes(tr);
    } else if (type === "flight") {
      let tr = selectedFlights.filter((r) => r !== "all");
      if (tr.includes(tagKey)) {
        tr = tr.filter((r) => r !== tagKey);
        if (tr.length) {
          onChange({ ...value, flights: tr });
          if (type === searchType) {
            doFetchRight(tr, searchType);
          }
        } else {
          if (type === searchType) {
            clickAll();
          } else {
            setSelectedFlights([]);
            onChange({ ...value, flights: [] });
          }
        }
      } else {
        tr.push(tagKey);
        onChange({ ...value, flights: tr });
        if (type === searchType) {
          doFetchRight(tr, searchType);
        }
      }
      setSelectedFlights(tr);
    }
  });

  const onTagClose = useRefFunc((r: string, type: SearchType) => {
    const isSelected = (
      type === "route" ? selectedRoutes : selectedFlights
    ).includes(r);
    if (isSelected) {
      tagClick(r, type);
    }
  });

  const routesTags = useMemo(() => {
    return routes.map((r) => (
      <NotifyTag
        key={r}
        closable={searchType === "route"}
        onClose={() => onTagClose(r, "route")}
        notifyCount={null}
        onClick={() => tagClick(r, "route")}
        color={selectedRoutes.includes(r) ? "#1890ff" : undefined}
      >
        {r}
      </NotifyTag>
    ));
  }, [onTagClose, routes, searchType, selectedRoutes, tagClick]);

  const flightsTags = useMemo(() => {
    return flights.map((r) => (
      <NotifyTag
        key={r}
        closable={searchType === "flight"}
        onClose={() => onTagClose(r, "flight")}
        notifyCount={null}
        onClick={() => tagClick(r, "flight")}
        color={selectedFlights.includes(r) ? "#1890ff" : undefined}
      >
        {r}
      </NotifyTag>
    ));
  }, [flights, onTagClose, searchType, selectedFlights, tagClick]);

  const changeSearchType = useRefFunc((v: SearchType) => {
    setSearchType(v);
    if (v === "route") {
      setSelectedRoutes(["all"]);
      setSelectedFlights([]);
    } else if (v === "flight") {
      setSelectedFlights(["all"]);
      setSelectedRoutes([]);
    }
    onChange({ routes: [], flights: [] });
  });

  return (
    <Spin spinning={isLoading}>
      <div className="profit-monitor_route-flight-area">
        <Radio.Group
          options={SEARCH_TYPE}
          value={searchType}
          onChange={(e) => changeSearchType(e.target.value)}
        />
        <div style={{ display: "flex", border: "1px solid #ccc", margin: 5 }}>
          <div className="select-area left-select-area">
            <div className="area-header">
              <Row gutter={[20, 20]}>
                <Col>
                  <Typography.Title level={5}>
                    {searchType === "route" ? "航段" : "航班号"}
                  </Typography.Title>
                </Col>
                <Col>
                  {searchType === "route" ? (
                    <SelectMultRoute
                      routes={tmpRoutes}
                      setRoutes={setTmpRoutes}
                      isDemo={false}
                      flights=""
                      size="small"
                      mode="multiple"
                    />
                  ) : (
                    <SelectMultiFlight
                      flightNO={tmpFlights}
                      setFlightNO={setTmpFlights}
                      routes=""
                      size="small"
                      isDemo={false}
                      mode="multiple"
                    />
                  )}
                </Col>
                <Col>
                  <Button size="small" onClick={changeRoutesOrFlights}>
                    +添加
                  </Button>
                </Col>
              </Row>
            </div>
            <div style={{ minHeight: 100 }}>
              {hasAll ? (
                <NotifyTag
                  key="all"
                  notifyCount={null}
                  onClick={clickAll}
                  color={
                    (searchType === "route"
                      ? selectedRoutes
                      : selectedFlights
                    ).includes("all")
                      ? "#1890ff"
                      : undefined
                  }
                >
                  整体
                </NotifyTag>
              ) : undefined}
              {searchType === "route" ? routesTags : flightsTags}
            </div>
          </div>
          <div className="sep" style={{ position: "relative" }}>
            <div style={{ border: "1px solid #eee", height: "100%" }}></div>
            <div
              style={{
                position: "absolute",
                top: "50%",
              }}
            >
              <span
                style={{
                  marginLeft: "-50%",
                  marginTop: "-50%",
                  display: "inherit",
                }}
              >
                <DoubleRightOutlined
                  style={{ fontSize: "200%", color: "#888" }}
                />
              </span>
            </div>
          </div>
          <div className="select-area right-select-area">
            <div className="area-header">
              <Typography.Title level={5}>
                {searchType === "route" ? "航班号" : "航段"}
              </Typography.Title>
            </div>
            <div style={{ minHeight: 100 }}>
              {searchType === "route" ? flightsTags : routesTags}
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
};
export default RouteFlightArea;
