import { MarketTrendRequestType } from "@ctrip/flt-bi-flightai-airlines";
import React, { ReactElement, useState } from "react";
import MarketTrendsAirline from "./Components/AirlineIndex";
import TablePortal from "./TablePortal";
import useGlobal from "Store";
import { Card } from "antd";
import { SystemType } from "Interface";

const MarketTrends = (): ReactElement => {
  const [filter, setFilter] = useState<MarketTrendRequestType>(
    {} as MarketTrendRequestType
  );
  const [globalState] = useGlobal();
  const { systemType } = globalState;

  return systemType == SystemType.airlines ? (
    <div
      className={`content-white  { readonly [key: string]: string }`}
      style={{ marginTop: 0 }}
    >
      <Card>
        <MarketTrendsAirline onChange={setFilter} />
      </Card>
      <Card>
        <TablePortal filter={filter} />
      </Card>
    </div>
  ) : (
    <div></div>
  );
};

export default MarketTrends;
