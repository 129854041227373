import { Alert, Empty, Tooltip } from "antd";
import UpdateTime from "Components/UpdateTime";
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import SearchArea, { SearchAreaHandle } from "./PriceComponents/SearchArea";
import SearchAreaTable from "./PriceComponents/SearchAreaTable";
import BarLineChartsV2 from "Components/BarLineChartsV2";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { IDownloadHeader, ISeries } from "Interface";
import { DATE_HOUR_FORMAT, EMPTY_ARRAY, EMPTY_OBJECT } from "Constants";
import moment from "moment";
import { downloadExcel } from "Utils/downloadXLSX";
import { IDynamicPriceHistoryPrice } from "./SalesAnalysisInterface";
import { durationFormatter, showNum, showRawNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { maxBy, round } from "lodash";
import TablePortal from "./PriceComponents/TablePortal";
import { InfoCircleOutlined } from "@ant-design/icons";
import TrendBlock from "./PriceComponents/TrendBlock";

const downloadColumns: Array<IDownloadHeader<IDynamicPriceHistoryPrice>> = [
  {
    title: "起飞时间",
    dataIndex: "day",
    downloadFormatter: (val: any) => moment(val).format(DATE_HOUR_FORMAT),
  },
  {
    title: "采集时间",
    dataIndex: "collectDay",
    downloadFormatter: (val: any) => moment(val).format(DATE_HOUR_FORMAT),
  },
  {
    title: "提前时间",
    dataIndex: "diffTime",
    downloadFormatter: (val: any) => durationFormatter(val * 3600, "d"),
  },
  {
    title: getSharkText("config_page_flight"),
    dataIndex: "name",
  },
  {
    title: getSharkText("config_page_price"),
    dataIndex: "price",
    render: (val: number) => showNum(val),
    downloadFormatter: (val: number) => showRawNum(val, "num"),
  },
];

export const defaultQuery = EMPTY_OBJECT;

/**
 * 价格分析模块
 */
const PriceAnalysis = (): ReactElement => {
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  const [queryExtChart, setQueryExtChart] = useState<any>(defaultQuery);
  const chartSearchRef = useRef<SearchAreaHandle>(null);
  const [queryExtTable, setQueryExtTable] = useState<any>(defaultQuery);
  const [totalSeries, setTotalSeries] = useState<ISeries[]>(EMPTY_ARRAY);
  const [oriData, setOriData] = useState<
    Array<IDynamicPriceHistoryPrice & { key: string }>
  >([]);

  const dataTrans = useCallback((data: IDynamicPriceHistoryPrice[]) => {
    if (data.length) {
      const tmp: Array<IDynamicPriceHistoryPrice & { key: string }> = data.map(
        (d, i) => {
          return { ...d, key: `id${i}` };
        }
      );
      setOriData(tmp);
    }
    const sortedData = data.sort((a, b) => (a.diffTime < b.diffTime ? 1 : -1));
    const rst = sortedData.reduce(
      (total: any[], cur: IDynamicPriceHistoryPrice) => {
        let item: ISeries = total.find((t: any) => cur.name === t.name);
        // 计算提前起飞小时数, 按照起飞日的24点为基准计算
        const beforeHours = round(cur.diffTime);
        if (!item) {
          item = {
            type: "line",
            name: cur.name,
            isPercentVal: true,
            encode: {
              x: "day",
              y: "price",
            },
            data: [],
          };
          total.push(item);
        }
        item.data.push([`${beforeHours}`, cur.price, cur.collectDay]);
        return total;
      },
      []
    );
    setTotalSeries(rst);
    return rst;
  }, []);

  const chartConfig = useMemo(() => {
    const max =
      maxBy(oriData, (item: IDynamicPriceHistoryPrice) => item.diffTime)
        ?.diffTime || 0;
    const xAxisData = Array.from({ length: max + 1 }, (v, i) => max - i);
    return {
      color: [
        "#01C5DB",
        "#FC9B4F",
        "#177DFE",
        "#FF008E",
        "#D22779",
        "#612897",
        "#0C1E7F",
        "#EDD2F3",
        "#FFFCDC",
        "#FFCC1D",
      ],
      title: {
        text: getSharkText("config_page_price"),
        top: "center",
        left: "2%",
      },
      dataZoom: null,
      xAxis: {
        type: "category",
        data: xAxisData,
        name: getSharkText("config_page_days_from_takeoff"),
        nameLocation: "start",
        min: 0,
        nameTextStyle: {
          padding: [50, 25, 0, 0],
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          overflow: "truncate",
          interval: (index: number, value: string) => {
            const hours = round(parseFloat(value));
            // 仅显示整天的数据
            const tmp = hours % 24;
            if (tmp !== 0) {
              return false;
            }
            return true;
          },
          formatter: (val: number) => {
            // const hours = moment(record.takeofftime).diff(moment(val), 'hours');
            const hours = round(val);
            return durationFormatter(hours * 3600, "d");
          },
        },
      },
      yAxis: [
        {
          type: "value",
          splitNumber: 4,
          min: (v: any) => v.min * 0.9,
          axisLabel: {
            formatter: (value: number) => round(value, 2),
          },
        },
      ],
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const day = params[0].data[2]
            ? moment(params[0].data[2]).format(DATE_HOUR_FORMAT)
            : "";
          let dom = "";
          params.forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const itemValue = item.data[1];
            const value = showNum(itemValue);
            const val = `${item.seriesName}: ${value}`;
            dom = dom + "<br />" + dot + val;
          });
          dom = day + dom;
          return dom;
        },
      },
    };
  }, [oriData]);

  const onDownload = useCallback((data: any[]) => {
    downloadExcel(
      downloadColumns,
      data,
      getSharkText("config_page_price_analysis")
    );
  }, []);

  /**
   * 在价格趋势中点击某个航班时, 跳转到价格过程并查询该航班航线起飞日期
   */
  const onTrendClick = useCallback(
    (date: string, route: string, flightNO: string) => {
      chartSearchRef.current?.setFilters(date, route, flightNO);
    },
    []
  );

  return (
    <>
      <Alert
        message={
          <UpdateTime
            moduleCode=""
            chartTableCode=""
            text={getSharkText("config_page_only_support_domestic_routes")}
          />
        }
        type="info"
        banner
      />
      <div className="content-white" style={{ margin: 0 }} id="price_process">
        <div className="section-title">
          <Tooltip
            title={getSharkText(
              "config_page_visual_display_query_flight_external_price_adjustment_process"
            )}
          >
            <span className="text" style={{ marginRight: 10 }}>
              {getSharkText("config_page_price_process")}
            </span>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div style={{ margin: "10px 0" }}>
          <SearchArea
            onChange={setQueryExtChart}
            isDemo={false}
            ref={chartSearchRef}
          />
        </div>
        <div style={{ marginTop: 0 }}>
          {queryExtChart.filter?.routeList?.length > 0 ? (
            <BarLineChartsV2
              queryUrl={QUERY_URL[systemType].price}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].price}
              dataTrans={dataTrans}
              defaultSeries={totalSeries}
              useDataset={false}
              ext={queryExtChart}
              useGranularity={false}
              useDownload={true}
              onDownload={onDownload}
              chartConfig={chartConfig}
            />
          ) : (
            <Empty description={getSharkText("config_page_select_route")} />
          )}
        </div>
      </div>
      <div className="content-white" style={{ margin: "10px 0 0 " }}>
        <div className="section-title">
          <Tooltip title="对外放价格进行详细查询">
            <span className="text" style={{ marginRight: 10 }}>
              {getSharkText("config_page_price_query")}
            </span>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div style={{ marginTop: 20 }}>
          <SearchAreaTable setQueryExt={setQueryExtTable} isDemo={false} />
        </div>
        <div style={{ marginTop: 0 }}>
          <TablePortal
            queryUrl={QUERY_URL[systemType].table}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].table}
            ext={queryExtTable}
          />
        </div>
      </div>
      <div className="content-white" style={{ margin: "10px 0 0 " }}>
        <div className="section-title">
          <Tooltip
            title={
              getSharkText("config_page_PaAiSaPr_view_flight_price_trend") +
              "，" +
              getSharkText("config_page_PaAiSaPr_compare_other_flight_routes")
            }
          >
            <span className="text" style={{ marginRight: 10 }}>
              {getSharkText("config_page_price_trend")}
            </span>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <TrendBlock onTrendClick={onTrendClick} />
      </div>
    </>
  );
};
export default PriceAnalysis;
