// Created by xh_zhu on 2021-03-11

import { Col, Row } from "antd";
import LinesCharts from "./LinesCharts";
import React, { ReactElement, ReactNode, useState } from "react";
import useGlobal from "Store";
import Search from "./Search";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { ICard, IQueryExt } from "../Interface";
import Top20Charts from "./Top20Chart";
import { trendColumns } from "./LinesCharts/downloadColumns";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 * 市场趋势分为上下两块, 内容基本共用, 唯独销量特别
 */
const panelTypeName = [
  getSharkText("menu_industry_trend_airlines"),
  getSharkText("config_page_ctrip_perspective"),
];
interface IAirportIndexPanelProps {
  panelType: 0 | 1;
  alert?: ReactNode;
}

const AirportPanel = (props: IAirportIndexPanelProps): ReactElement => {
  const { panelType } = props;
  const [globalState] = useGlobal();
  const { systemType } = globalState;
  const [queryExt, setQueryExt] = useState<IQueryExt>();
  const [card, setCard] = useState<ICard>();

  return (
    <div className="content-white">
      <h1 style={{ color: "#1890FF" }} className={"row-title"}>
        {queryExt?.ext.together === 1
          ? getSharkText("config_page_together_flight_route")
          : ""}
        {panelTypeName[panelType]}
      </h1>

      <Search
        onChangeVal={setQueryExt}
        panelType={panelType}
        onChangeCard={setCard}
      />

      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          {!queryExt || !queryExt.query || !card ? undefined : (
            <LinesCharts
              queryUrl={QUERY_URL[systemType].lineCharts}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
              ext={queryExt.ext}
              query={queryExt.query}
              card={card}
              style={{ height: "532px" }}
              compareType={queryExt.query.compareType}
              panelType={panelType}
              downloadColumns={trendColumns}
            />
          )}
        </Col>
      </Row>
      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          {!queryExt || !queryExt.query || !card ? undefined : (
            <Top20Charts
              queryUrl={QUERY_URL[systemType].top}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].top}
              ext={queryExt.ext}
              query={queryExt.query}
              card={card}
              style={{ height: "532px" }}
              compareType={queryExt.query.compareType}
              panelType={panelType}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default AirportPanel;
