// Created by xh_zhu on 2021-04-14

import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";
import HeaderAlert from "Components/HeaderAlert";
import Page from "Layout/Page";
import React, { ReactElement, useState } from "react";
import useGlobalState from "Store";
import AirlinePanel from "./AirlineComponents/AirlinePanel";
import TopFilter from "./AirportComponents/TopFilter";

/**
 * Component description
 * 中转分析-航司版
 */

interface IAirlineIndexProps {
  isDemo: boolean;
}

const AirportIndex = (props: IAirlineIndexProps): ReactElement => {
  const { isDemo } = props;
  const [queryExt, setQueryExt] =
    useState<Partial<TransferPolicyBarlineRequestType>>();
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const { airportName } = queryCondition;

  return (
    <Page needToolBar={false} defaultDateMode="nextThirtyDays">
      <div className="content-white">
        {isDemo ? <HeaderAlert /> : undefined}
        <TopFilter isDemo={isDemo} onChange={setQueryExt} />
      </div>
      {queryExt ? (
        <>
          <AirlinePanel
            panelType={0}
            title={`整体航线${airportName}中转展示率`}
            queryExt={queryExt}
          />
          <AirlinePanel
            panelType={1}
            title={`中转航线${airportName}政策展示率`}
            queryExt={queryExt}
          />
        </>
      ) : undefined}
    </Page>
  );
};
export default AirportIndex;
