import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Popover, Statistic, Typography, Row } from "antd";
import { CardItemData, ECompareTypeV2 } from "Interface";
import _, { round } from "lodash";
import {
  isHideDigits,
  getComparePercentageVal,
  durationFormatter,
  getComparedPercentage,
  showNum,
  isNumeric,
  getProportionPercentageVal,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";
import "./index.css";
import Trend from "Components/Trend";
import {
  ITransferAnalysisQueryExt,
  PERCENT_VALUES,
} from "Page/AI/TransferPolicy/TransferPolicyInterface";
import { COMPARE_TYPE_PER_NAME } from "Constants";
import useGlobalState from "Store";

interface CardItemProps {
  onCardChange: (card: CardItemData) => void;
  selectedCode: string;
  data: CardItemData;
  queryExt: ITransferAnalysisQueryExt;
}

const { Text } = Typography;

const CardItem: React.FC<CardItemProps> = (props: CardItemProps) => {
  const { data, onCardChange, selectedCode, queryExt } = props;
  const { cardCode, cardName, cardValue, cardHint, compareValue } = data;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const { compareType } = airlinesQueryCondition;
  const isPercentVal = PERCENT_VALUES.includes(cardCode);
  const isTransitMinutes = cardCode === "transit_minutes";
  const isShowEmpty = cardValue === 0;
  const yoyPer =
    cardCode === "transit_persons" &&
    compareType === ECompareTypeV2.MARKET_COMPARE
      ? getProportionPercentageVal(cardValue, compareValue)
      : isPercentVal
      ? getComparedPercentage(cardValue, compareValue)
      : getComparePercentageVal(cardValue, compareValue);

  const handleSelectCard = () => {
    onCardChange(data);
  };

  const getCardValue = (val: any) => {
    if (!isNumeric(val)) {
      return "-";
    }
    if (isPercentVal) {
      return round(val, 2) + "%";
    } else if (isTransitMinutes) {
      return durationFormatter(val * 60);
    } else if (isHideDigits(cardCode)) {
      return _.round(val);
    } else {
      return val;
    }
  };

  return (
    <Card
      id={cardCode}
      size="small"
      bordered={true}
      className={`card ${selectedCode === cardCode ? "cur" : ""}`}
      hoverable={true}
      onClick={handleSelectCard}
    >
      <div className="card-title">
        <div className="title">{getSharkText(`card.${cardCode}.name`)}</div>
        {isTransitMinutes ? undefined : (
          <Popover
            overlayClassName="dashboard-val-control-popover"
            content={<span>{getSharkText(`card.${cardCode}.hint`)}</span>}
          >
            {getSharkText(`card.${cardCode}.hint`) && <InfoCircleOutlined />}
          </Popover>
        )}
      </div>
      {
        // isShowEmpty ?
        //     <div className='ant-statistic-content'>
        //         <span className='ant-statistic-content-value'>
        //             <span className='ant-statistic-content-value-int'>-</span>
        //         </span>
        //     </div> :
        <Statistic
          groupSeparator=","
          precision={isPercentVal ? 1 : 0}
          value={getCardValue(cardValue)}
          // suffix={cardCode === 'transit_minutes' ? 'min' : ''}
        />
      }
      <Row>
        {!queryExt || queryExt.yoy === 0 ? undefined : cardCode ===
            "transit_persons" &&
          compareType === ECompareTypeV2.MARKET_COMPARE ? (
          `${getSharkText("compare_market_share_name")} ${showNum(
            yoyPer,
            "percentage"
          )}`
        ) : (
          <Trend
            flag={compareValue < cardValue ? "up" : "down"}
            showFlag={cardValue !== 0 && compareValue !== cardValue}
          >
            <Text className="card-text">
              {COMPARE_TYPE_PER_NAME[compareType]}&nbsp;
              {isShowEmpty ? (
                "-"
              ) : (
                <ValueNum isShowZero={false} type="percentage" value={yoyPer} />
              )}
            </Text>
          </Trend>
        )}
      </Row>
    </Card>
  );
};

export default CardItem;
