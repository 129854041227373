import moment, { Moment } from "moment";
import { AggCode, ISeries } from "Interface";
import { WEEK, WEEK_EN } from "Constants";
import _ from "lodash";
import { getSharkText } from "Utils/i18nGlobal";

export const getDateWeek = (day: string, en = false): string => {
  try {
    const idx = parseInt(moment(day).format("E"), 10) - 1;
    return en ? WEEK_EN[idx] : WEEK[idx];
  } catch (e) {
    console.log("get Date Week Error", e);
  }
  return en ? "unknown" : getSharkText("key.unknown.name");
};

export const getDateFromAggCode = (
  aggCode: AggCode,
  date: string
): string | undefined => {
  switch (aggCode) {
    case AggCode.day:
      return moment(date).format("YYYY-MM-DD");
    case AggCode.week:
      return moment(date).format(
        `YYYY${decodeURIComponent("%E5%B9%B4")} ${decodeURIComponent(
          "%E7%AC%AC"
        )}WW${decodeURIComponent("%E5%91%A8")}`
      );
    case AggCode.month:
      return moment(date).format(
        `YYYY${decodeURIComponent("%E5%B9%B4")}MM${decodeURIComponent(
          "%E6%9C%88"
        )}`
      );
    case AggCode.year:
      return moment(date).format("YYYY年");
    case AggCode.total:
      return getSharkText("config_page_total");
  }
};

export const getDateFromAggCodeEN = (
  aggCode: AggCode,
  date: string
): string | undefined => {
  switch (aggCode) {
    case 0:
      return moment(date).format("YYYY-MM-DD");
    case 1:
      return `${moment(date).week()} week ${moment(date).year()}`;
    case 2:
      return moment(date).format(`YYYY-MM`);
    case 3:
      return moment(date).format("YYYY");
  }
};

export const getTooltipDateFromAggCode = (
  aggCode: AggCode,
  params: any[],
  dataLength: number,
  startDate: string,
  endDate: string,
  isDemo = false,
  en = false
): string | undefined => {
  const day = params[0].data.day;
  const index = params[0].dataIndex;
  const dataIndex = dataLength - 1;
  const sep = en ? "to" : getSharkText("config_page_to");
  if (isDemo) {
    return moment(day).format("YYYY-MM-DD");
  } else {
    switch (aggCode) {
      case AggCode.day:
        return `${day} ${getDateWeek(day, en)}`;
      case AggCode.week:
        if (dataIndex === 0) {
          return `${startDate} ${sep} ${endDate}`;
        } else {
          const weekOfday: any = moment(day).format("E");
          return `${index === 0 ? startDate : day} ${sep} ${
            dataIndex === index
              ? endDate
              : moment(day)
                  .add(7 - weekOfday, "days")
                  .format("YYYY-MM-DD")
          }`;
        }
      case AggCode.month:
        if (dataIndex === 0) {
          return `${startDate} ${sep} ${endDate}`;
        } else {
          return `${index === 0 ? startDate : day} ${sep} ${
            dataIndex === index
              ? endDate
              : moment(day).endOf("month").format("YYYY-MM-DD")
          }`;
        }
      case AggCode.year:
        if (dataIndex === 0) {
          return `${startDate} ${sep} ${endDate}`;
        } else {
          return `${index === 0 ? startDate : day} ${sep} ${
            dataIndex === index
              ? endDate
              : moment(day).endOf("year").format("YYYY-MM-DD")
          }`;
        }
      case AggCode.total:
        return "总体";
    }
  }
};

export const getBetweenDateArr = (
  start: Moment,
  end: Moment,
  aggCode: AggCode
): string[] => {
  let startClone = start.clone();
  let endClone = end.clone();
  if (aggCode === 1) {
    startClone = startClone.startOf("week");
    endClone = endClone.startOf("week");
  } else if (aggCode === 2) {
    startClone = startClone.startOf("month");
    endClone = endClone.startOf("month");
  } else if (aggCode === 3) {
    startClone = startClone.startOf("year");
    endClone = endClone.startOf("year");
  }
  const dates = [];
  while (startClone.isSameOrBefore(endClone)) {
    dates.push(startClone.format("YYYY-MM-DD"));
    if (aggCode === 3) {
      startClone.add(1, "years");
    } else if (aggCode === 2) {
      startClone.add(1, "months");
    } else if (aggCode === 1) {
      startClone.add(1, "weeks");
    } else {
      startClone.add(1, "days");
    }
  }
  return dates;
};

export const fillCharts = (
  chartsData: any[],
  dateArr: string[],
  genEmpty?: (date: string) => Record<string, any>
): any[] => {
  const cloneData = _.cloneDeep(chartsData);
  dateArr.forEach((date, idx) => {
    if (!cloneData[idx] || cloneData[idx].day !== date) {
      let emptyData: Record<string, any> = {
        day: date,
        value: 0,
        compareValue: 0,
        resultValue: 0,
      };
      if (genEmpty) {
        emptyData = genEmpty(date);
      }
      cloneData.splice(idx, 0, emptyData);
    }
  });
  return cloneData;
};

/**
 * 数据转换, 将[{name: 'aaa'},{age: 23}] 转为 [['name', 'age'], ['aaa', null], [null, 23]]; 缺失的数据用null填充
 *
 * @param listMap
 */
export const listMap2dataSet = (listMap: any[]): any[][] => {
  const rst: any[][] = [];
  const keys: string[] = [];
  // 遍历所有数据获得去重KEY
  listMap.forEach((map: Record<string, unknown>) => {
    Object.keys(map).forEach((key: string) => {
      if (!keys.includes(key)) {
        keys.push(key);
      }
    });
  });
  // 生成结果集
  rst.push(keys);
  listMap.forEach((map: any) => {
    const row: any[] = [];
    keys.forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(map, key)) {
        row.push(map[key]);
      } else {
        row.push(null);
      }
    });
    rst.push(row);
  });
  return rst;
};

/**
 * 根据dimensions决定有哪些series需要展示
 *
 * @param dimensions
 * @param defaultSeriesConfig
 */
export const genSeriesByDimensions = (
  dimensions: string[],
  defaultSeriesConfig: ISeries[]
): ISeries[] => {
  const rst: ISeries[] = defaultSeriesConfig.filter((series: ISeries) => {
    if (!series.encode) {
      return false;
    }
    return (
      dimensions.includes(series.encode.x) &&
      dimensions.includes(series.encode.y)
    );
  });
  return rst;
};

export const genChartData = (
  data: any[],
  seriesMap: Record<string, unknown>
): any[] => {
  // 获取总量
  const totalObj = data.find((d) => /all$/.test(d.name));
  let valueSum = 0;
  data.forEach((e) => (valueSum += parseFloat(e.value)));
  const totalValue = totalObj ? totalObj.value : valueSum;
  // 返回除了总量的每个值的占比
  const rst = data.reduce((total: any[], d: any) => {
    if (/all$/.test(d.name)) {
      return total;
    }
    if (!seriesMap) {
      total.push(d);
      return total;
    }
    if (seriesMap[d.name]) {
      total.push({
        name: seriesMap[d.name],
        value: parseFloat(((d.value / totalValue) * 100).toFixed(2)),
        cnt: parseFloat(d.value),
      });
    }
    return total;
  }, []);
  return rst;
};

export interface IDimItem {
  dimensionNames: string[];
  data: any[];
}

/**
 * 将{
 * data: ['/Date(1638028800000)/', '/Date(1637913600000)/', 32, '', 358, 0, 2, 358, 408, 50, 'T', 'D'],
 * dimensionNames: ['day', 'gatherDay', 'diffDay', 'name', 'price', 'avgPrice', 'feedback', 'rawPrice', 'suggestPrice', 'diffPrice', 'rawCabin', 'suggestCabin']
 * } 转成对象
 *
 * @param item echarts tooltip中的一个item
 */
export const dimensionsAsObject = (item: IDimItem): any => {
  const rst: any = {};
  item.dimensionNames.forEach((s: string, i: number) => {
    rst[s] = item.data[i];
  });
  return rst;
};
