import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Table, Input, Button, Row, Col } from "antd";
import { UnitList } from "Interface";
import _ from "lodash";
import UnitDelete from "../UnitDelete";
import UnitEditModal from "../UnitEditModal";
import "./index.css";
import { getSharkText } from "Utils/i18nGlobal";

interface UnitSideProps {
  curUnit: UnitList | null;
  unitList: UnitList[];
  setCurUnit: (unitList: UnitList) => void;
  refetch: () => void;
  isLoading: boolean;
  setAddUnitName: (name: string) => void;
  unitType: 0 | 1 | 2;
}

const { Search } = Input;

const UnitSide: React.FC<UnitSideProps> = (props: UnitSideProps) => {
  const {
    curUnit,
    setCurUnit,
    unitList,
    refetch,
    isLoading,
    setAddUnitName,
    unitType,
  } = props;
  const [searchText, setSearchText] = useState("");
  const [searchUnit, setSearchUnit] = useState<UnitList[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(unitList)) {
      if (curUnit) {
        const unit = unitList.find((item) => item.unit.id === curUnit.unit.id);
        if (unit) {
          setCurUnit(unit);
        }
      } else {
        const ctripUnit = unitList.find(
          (unit: UnitList) => unit.unit.unitName === "携程"
        );
        if (ctripUnit) {
          setCurUnit(ctripUnit);
        }
      }
    }
  }, [unitList]);

  useEffect(() => {
    const result = unitList.filter(
      (item) =>
        item.unit.unitName.includes(searchText) &&
        (unitType === 0
          ? true
          : !!item.roleList.find((role) => role.roleType === unitType))
    );
    setSearchUnit(result || []);
  }, [unitList, searchText, unitType]);

  const handleClick = (item: UnitList) => {
    setCurUnit(item);
  };

  const setCtripUnit = () => {
    const ctripUnit = unitList.find((unit) => unit.unit.unitName === "携程");
    if (ctripUnit) {
      setCurUnit(ctripUnit);
    }
  };

  const handleAddUnit = () => {
    setIsShowModal(true);
  };

  const columns = [
    {
      title: () => (
        <>
          {getSharkText("config_page_permission_group_list")}
          <span className="unit-total">
            {getSharkText("config_page_total")}&nbsp;{searchUnit.length}
          </span>
        </>
      ),
      dataIndex: ["unit", "unitName"],
      key: "unitName",
      ellipsis: true,
      render: (value: string, record: any) => (
        <div
          className={
            curUnit && record.unit.id === curUnit.unit.id
              ? "unit-item cur"
              : "unit-item"
          }
        >
          <div
            style={{ margin: "-8px 0 -8px -8px", padding: 8 }}
            onClick={() => handleClick(record)}
          >
            {value}
          </div>
          <UnitDelete
            item={record}
            curUnit={curUnit}
            refetch={refetch}
            setCtripUnit={setCtripUnit}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={14}>
          <Search
            placeholder={getSharkText("config_page_search_permission_group")}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ display: "block" }}
          />
        </Col>
        <Col span={10}>
          <Button type="link" onClick={handleAddUnit} block>
            <PlusOutlined />
            {getSharkText("config_page_add_permission_group")}
          </Button>
        </Col>
      </Row>
      <Table
        showSorterTooltip={false}
        className="unit-table"
        size="small"
        loading={isLoading}
        dataSource={searchUnit}
        rowKey={(record) => record.unit.id.toString()}
        columns={columns}
        scroll={{ y: 500 }}
        pagination={false}
      />
      <UnitEditModal
        visible={isShowModal}
        setVisible={setIsShowModal}
        refetch={refetch}
        unitList={unitList}
        setAddUnitName={setAddUnitName}
      />
    </>
  );
};

export default UnitSide;
