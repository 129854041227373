import React, { useCallback, useEffect, useState } from "react";
import { Col, Row, TablePaginationConfig } from "antd";
import _ from "lodash";
import "./CustomTable.css";
import { IRecord } from "Page/AI/DynamicPrice/DynamicPriceInterface";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import ProTable, { ColumnsState } from "@ant-design/pro-table";
import useRefFunc from "Utils/useRefFunc";
import { downloadExcel } from "Utils/downloadXLSX";
import { IDownloadHeaderPro } from "Interface";
import DownloadBtn from "../../../../../Components/DownloadBtn";
import { getSharkText } from "Utils/i18nGlobal";

const localStorageKey = "dynamic_price_columns_state";

const getDefaultColumnsState = (): Record<string, ColumnsState> => {
  const s = localStorage.getItem(localStorageKey);
  if (s) {
    return JSON.parse(s);
  } else {
    return {};
  }
};

interface CustomTableProps {
  columns: Array<IDownloadHeaderPro<IRecord>>;
  dataSource: IRecord[];
  searchkeys: string[];
  searchPlaceholder: string;
  handleDownload?: () => void;
  onRecordSelectedChange?: (record: any) => void;
  onRecordClick: (record: IRecord) => void;
  onTableChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IRecord> | Array<SorterResult<IRecord>>
  ) => void;
  /**
   * 与onTableChange配合使用, 服务端控制分页和排序, 不使用onTableChange时, 设置此值无效
   */
  pagination?: TablePaginationConfig;
  moduleCode: string;
  chartTableCode: string;
}

const CustomTable: React.FC<CustomTableProps> = (props: CustomTableProps) => {
  const {
    columns,
    dataSource,
    searchkeys,
    onRecordSelectedChange,
    onRecordClick,
    onTableChange,
    pagination,
    // handleDownload,
    moduleCode,
    chartTableCode,
  } = props;
  const [searchText] = useState<string>("");
  // 就只是用来触发重绘表格
  const [tick, setTick] = useState({ count: 1 });
  const [, setCurrentId] = useState<string>("");
  const [columnsState, setColumnsState] = useState<
    Record<string, ColumnsState>
  >(getDefaultColumnsState());

  useEffect(() => {
    console.log(123);
  }, [tick]);

  const getFilterData = () => {
    const cloneData = _.cloneDeep(dataSource);
    const filter = cloneData.filter((data: any) => {
      let isPass = false;
      for (const key of searchkeys) {
        if (data[key] && data[key].includes(searchText)) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = _.isEmpty(dataSource) ? dataSource : getFilterData();

  const setOldRowClass = (record: any) => (record.checked ? "row-checked" : "");

  const onRow = (record: IRecord) => ({
    onClick: () => {
      dataSource.forEach((rd) => (rd.checked = false));
      const r = dataSource.find((rd) => rd.id === record.id);
      if (r) {
        r.checked = true;
        onRecordClick(r);
      }
      setTick({ count: tick.count++ });
    },
    onDoubleClick: () => {
      setCurrentId(record.id);
      onRecordClick(record);
      if (onRecordSelectedChange) {
        onRecordSelectedChange(record);
      }
    },
  });

  const getFilterDataKeys = (tmpFilterData: IRecord[]): number[] => {
    const keys = tmpFilterData.map((data: IRecord) => data.key);
    const rst = keys.filter((key) => !!key) as number[];
    return rst;
  };

  const handleTableChange = useCallback(
    (
      pages: TablePaginationConfig,
      filters: Record<string, FilterValue | null>,
      sorter: SorterResult<IRecord> | Array<SorterResult<IRecord>>
    ) => {
      if (onTableChange) {
        onTableChange(pages, filters, sorter);
      }
    },
    [onTableChange]
  );

  const onColumnsStateChange = (v: Record<string, ColumnsState>) => {
    localStorage.setItem(localStorageKey, JSON.stringify(v));
    setColumnsState(v);
  };

  const handleDownload = useRefFunc(() => {
    const NEED_DOWNLOAD_COLUMNS = [
      "sendTime",
      "route",
      "flightNo",
      "takeofftime",
      "flightType",
      "feStatus",
      "suggestPrice",
      "rawPrice",
    ];
    const needDownloadColumns = columns.filter(
      (c) => c.dataIndex && NEED_DOWNLOAD_COLUMNS.includes(c.dataIndex)
    );
    downloadExcel(
      needDownloadColumns,
      filterData,
      getSharkText("menu_dynamic_price_airlines")
    );
  });

  return (
    <>
      <Row style={{ marginTop: 0 }}>
        <Col>
          <ProTable
            showSorterTooltip={false}
            search={false}
            defaultExpandAllRows={true}
            expandIconColumnIndex={-1}
            tableLayout="fixed"
            dataSource={filterData}
            columns={columns}
            expandedRowKeys={getFilterDataKeys(filterData)}
            scroll={{ x: "max-content", y: 540 }}
            style={{ color: "#000000d9" }}
            rowClassName={setOldRowClass}
            onRow={onRow}
            defaultSize="small"
            pagination={onTableChange ? pagination : undefined}
            onChange={onTableChange ? handleTableChange : undefined}
            columnsStateMap={columnsState}
            onColumnsStateChange={onColumnsStateChange}
            options={{ density: false, reload: false }}
            toolBarRender={() => [
              <DownloadBtn
                handleDownload={handleDownload}
                showIcon={false}
                moduleCode={moduleCode}
                chartTableCode={chartTableCode}
              />,
            ]}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomTable;
