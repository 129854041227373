import { FlightsSeasonPlanTable } from "@ctrip/flt-bi-flightai-airlines";
import { IDownloadHeader } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

export let getColumns = (): Array<IDownloadHeader<FlightsSeasonPlanTable>> => {
  return [
    {
      title: getSharkText("config_page_time_period"),
      dataIndex: "timeHour",
      sorter: (r1: any, r2: any) =>
        r1.timeHour?.localeCompare(r2.timeHour) || 0,
    },
    {
      title: getSharkText("date_mon"),
      dataIndex: "monday",
    },
    {
      title: getSharkText("date_tue"),
      dataIndex: "tuesday",
    },
    {
      title: getSharkText("date_wed"),
      dataIndex: "wednesday",
    },
    {
      title: getSharkText("date_thu"),
      dataIndex: "thursday",
    },
    {
      title: getSharkText("date_fri"),
      dataIndex: "friday",
    },
    {
      title: getSharkText("date_sat"),
      dataIndex: "saturday",
    },
    {
      title: getSharkText("date_sun"),
      dataIndex: "sunday",
    },
    {
      title: getSharkText("config_page_row_total"),
      dataIndex: "sumV",
    },
  ];
};
