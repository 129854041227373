import React, { useCallback } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";
import { UnitList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

interface UnitDeleteProps {
  item: UnitList;
  curUnit: UnitList | null;
  refetch: () => void;
  setCtripUnit: () => void;
}

const UnitDelete: React.FC<UnitDeleteProps> = (props: UnitDeleteProps) => {
  const { item, curUnit, refetch, setCtripUnit } = props;

  const [, doFetch] = useFetch({
    url: "deleteUnitById",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      id: item.unit.id,
    },
    lazey: true,
    onSuccess: useCallback(() => {
      message.success(getSharkText("config_page_delete_success"));
      refetch();
      if (curUnit && item.unit.id === curUnit.unit.id) {
        setCtripUnit();
      }
    }, [curUnit, item.unit.id, refetch, setCtripUnit]),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_delete_fail"));
    }, []),
  });

  const confirm = () => {
    doFetch({
      head: {
        moduleCode: "right_manage",
        chartTableCode: null,
      },
      ext: {
        id: item.unit.id,
      },
    });
  };

  return (
    <>
      {!item.unit.managerUnit && item.unit.id !== 0 ? (
        <Popconfirm
          title={getSharkText("config_page_confirm_delete")}
          okText={getSharkText("config_page_confirm")}
          cancelText={getSharkText("key.cancel.button")}
          onConfirm={confirm}
        >
          <span className="delete-icon">
            <DeleteOutlined />
          </span>
        </Popconfirm>
      ) : (
        <span className="delete-icon" />
      )}
    </>
  );
};

export default UnitDelete;
