// Created by xh_zhu on 2021-02-08

import { InfoCircleOutlined } from "@ant-design/icons";

import { Checkbox, Popover, Select } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  COMPARE_TYPE_HELP,
  COMPARE_TYPE_PER_NAME,
  DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
  EYearCompareType,
} from "Constants";
import { T_YEAR_COMPARE_TYPE } from "Interface";
import { difference } from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { validateAllowValControl } from "./service";
import { getSharkText } from "Utils/i18nGlobal";

export interface IChainProps {
  defaultValue?: string[];
  onChange?: (val: string[]) => void;
  startDate?: string;
  endDate?: string;
  // 是否显示数值选项, 默认为true
  needValueOption?: boolean;
  mode?: "multiple" | "single";
}
const { Option } = Select;

export const PreLastChain = (props: IChainProps): ReactElement => {
  const {
    defaultValue = [],
    startDate,
    endDate,
    onChange,
    needValueOption = true,
  } = props;
  const [valControl, setValControl] = useState<string[]>(defaultValue);
  const [yoyIdx, setYoyIdx] = useState<T_YEAR_COMPARE_TYPE>(
    DEFAULT_AIRPORT_COMPARE_TYPE_VALUE
  );
  // 当设置起始和终止日期时, 周月环比会有区间限制
  const isLimitLyLwChain = startDate || endDate;

  useEffect(() => {
    if (onChange) {
      onChange(valControl);
    }
  }, [valControl, onChange]);

  // 非整周数据移除周环比, 非整月数据移除月环比
  useEffect(() => {
    const tmpVal = validateAllowValControl(valControl, startDate, endDate);
    if (tmpVal.length !== valControl.length) {
      setValControl(tmpVal);
      if (onChange) {
        onChange(tmpVal);
      }
    }
  }, [startDate, endDate, valControl, onChange]);

  const handleValControlChange = (val: CheckboxValueType[]) => {
    // 得到新增的项
    const checked = val as string[];
    // 先检测选中项是否符合要求
    const diff = difference(checked, valControl);
    const allowed = validateAllowValControl(diff, startDate, endDate);
    // 如果不符合要求, 则不继续操作
    if (allowed.length !== diff.length) {
      return;
    }
    let filtered = checked;
    if (diff.length && diff[0] === getSharkText("key.wow.indicator")) {
      filtered = checked.filter((c) => c !== getSharkText("key.mom.indicator"));
    }
    if (diff.length && diff[0] === getSharkText("key.mom.indicator")) {
      filtered = checked.filter((c) => c !== getSharkText("key.wow.indicator"));
    }
    if (filtered.length !== valControl.length) {
      setValControl(filtered);
    }
  };

  const changeYoy = (val: T_YEAR_COMPARE_TYPE) => {
    setYoyIdx(val);
    // 排除年同比的选项, 再选中年同比的选项
    const yearList = Object.values(EYearCompareType).map(
      (year) => COMPARE_TYPE_PER_NAME[year]
    );
    const tmpVal = valControl.filter(
      (item: string) => !yearList.includes(item)
    );
    tmpVal.push(COMPARE_TYPE_PER_NAME[val]);
    setValControl(tmpVal);
  };

  const selectClicked = (v: any) => {
    v.stopPropagation();
    v.preventDefault();
  };

  const yoyHelpNode = (
    <span style={{ display: "inline-block", width: 240 }}>
      {COMPARE_TYPE_HELP[yoyIdx]}
    </span>
  );

  return (
    <Checkbox.Group
      value={valControl}
      onChange={handleValControlChange}
      style={{ paddingTop: 4 }}
    >
      {needValueOption ? (
        <Checkbox key="0" value={getSharkText("key.value.text")}>
          {getSharkText("key.value.text")}
        </Checkbox>
      ) : undefined}
      <Checkbox key="1" value={COMPARE_TYPE_PER_NAME[yoyIdx]}>
        <span onClick={selectClicked}>
          <Select value={yoyIdx} onChange={changeYoy} size="small">
            {Object.values(EYearCompareType).map((item) => {
              return (
                <Option key={item} value={item}>
                  {COMPARE_TYPE_PER_NAME[item]}
                </Option>
              );
            })}
          </Select>
          <Popover
            content={yoyHelpNode}
            overlayClassName="dashboard-val-control-popover"
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      </Checkbox>
      <Checkbox key="2" value={getSharkText("key.wow.indicator")}>
        <span>{getSharkText("key.wow.indicator")}</span>
        {isLimitLyLwChain ? (
          <Popover
            content={getSharkText("key.daterange_wow.hint")}
            overlayClassName="dashboard-val-control-popover"
          >
            <InfoCircleOutlined />
          </Popover>
        ) : undefined}
      </Checkbox>
      <Checkbox key="3" value={getSharkText("key.mom.indicator")}>
        <span>{getSharkText("key.mom.indicator")}</span>
        {isLimitLyLwChain ? (
          <Popover
            content={getSharkText("key.daterange_mom.hint")}
            overlayClassName="dashboard-val-control-popover"
          >
            <InfoCircleOutlined />
          </Popover>
        ) : undefined}
      </Checkbox>
    </Checkbox.Group>
  );
};
export default PreLastChain;
