import React, { useState, useEffect } from "react";
import { SwapRightOutlined } from "@ant-design/icons";
import { Row, Col, Checkbox, message } from "antd";
import useGlobal from "Store";
import CascaderArea from "Components/CascaderArea";
import { Area, TransferFlightArea } from "Interface";
import { DEFAULT_AREA } from "Constants";
import { getModuleStatus, getModuleNameFromPath } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { useLocation } from "react-router-dom";
import useRefFunc from "Utils/useRefFunc";

interface SearchAreaProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  setFlightAreaQueryExt: (ext: TransferFlightArea) => void;
}

const SearchArea: React.FC<SearchAreaProps> = (props: SearchAreaProps) => {
  const { setFlightAreaQueryExt } = props;
  const [departArea, setDepartArea] = useState<Area>(DEFAULT_AREA);
  const [arriveArea, setArriveArea] = useState<Area>(DEFAULT_AREA);
  const [transferArea, setTransferArea] = useState<Area>(DEFAULT_AREA);
  const [transferType, setTransferType] = useState<number[]>([0]);
  const [loading, setLoading] = useState(false);
  const [globalState] = useGlobal();
  const { userInfo, airlinesQueryCondition, systemType } = globalState;
  const { airlines, flightClass } = airlinesQueryCondition;
  const location = useLocation();
  const { pathname } = location;
  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false));
  }, [flightClass]);

  useEffect(() => {
    setFlightAreaQueryExt({
      departArea,
      arriveArea,
      transferArea,
      sameAirline: transferType.includes(0) ? 1 : 0,
      airline1: transferType.includes(1) ? 1 : 0,
      airline2: transferType.includes(2) ? 1 : 0,
    });
  }, [
    departArea,
    arriveArea,
    transferArea,
    setFlightAreaQueryExt,
    transferType,
  ]);

  const handleTransferTypeChange = useRefFunc((e: number[]) => {
    if (e.length === 0) {
      message.error("至少需要选中一项");
    } else {
      setTransferType(e);
    }
  });

  return (
    <Row align="middle" gutter={10} style={{ marginTop: 0 }}>
      <Col>
        <CascaderArea
          type="airport"
          setSelectedArea={setDepartArea}
          flightClass={flightClass}
          isDemo={isDemo}
        />
      </Col>
      <Col>
        <SwapRightOutlined />
      </Col>
      <Col>
        <CascaderArea
          type="airport"
          setSelectedArea={setTransferArea}
          flightClass={flightClass}
          isDemo={isDemo}
        />
      </Col>
      <Col>
        <SwapRightOutlined />
      </Col>
      <Col>
        {loading ? undefined : (
          <CascaderArea
            type="airport"
            setSelectedArea={setArriveArea}
            flightClass={flightClass}
            isDemo={isDemo}
          />
        )}
      </Col>
      {/* <Col>
        <Anchor getContainer={getAnchorContainer} offsetTop={210} affix={false}>
          <Link href="#transfer-trend" title="航线图" />
          <Link href="#transfer-table" title="表格" />
        </Anchor>
      </Col> */}
      <Col>
        <Checkbox.Group
          value={transferType}
          onChange={(e) => handleTransferTypeChange(e as number[])}
        >
          <Checkbox value={0}>
            {getSharkText("transfer.same_airline.name")}
          </Checkbox>
          <Checkbox value={1}>
            {getSharkText("transfer.inter_airline.name")}(
            {getSharkText("transfer.first_seg.name")}
            {airlines})
          </Checkbox>
          <Checkbox value={2}>
            {getSharkText("transfer.inter_airline.name")}(
            {getSharkText("transfer.second_seg.name")}
            {airlines})
          </Checkbox>
        </Checkbox.Group>
      </Col>
    </Row>
  );
};

export default SearchArea;
