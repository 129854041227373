import React from "react";
import { IDownloadHeader } from "Interface";
import { Article, ArticleType } from "@ctrip/flt-bi-flightai-base";
import moment from "moment";
import { DATE_FORMAT } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

export interface IAction<T> {
  name: string;
  method: (v: T) => void;
}

export const genColumns = (
  actions: Array<IAction<Article>>,
  articleTypes: ArticleType[]
): Array<IDownloadHeader<Article>> => {
  return [
    {
      title: getSharkText("config_page_title"),
      dataIndex: "title",
      fixed: true,
    },
    {
      title: getSharkText("config_page_summary"),
      dataIndex: "summary",
      width: 400,
      render: (val: string) => (
        <div
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {val}
        </div>
      ),
    },
    {
      title: getSharkText("config_page_category"),
      dataIndex: "typeId",
      render: (val: number) => {
        const t = articleTypes?.find((ty) => ty.typeId === val);
        return t?.typeName || getSharkText("config_page_uncategorized");
      },
    },
    {
      title: getSharkText("config_page_publish_time"),
      dataIndex: "publishTime",
      render: (val: string) => moment(val).format(DATE_FORMAT),
    },
    {
      title: getSharkText("config_page_image_name"),
      dataIndex: "imageName",
    },
    {
      title: getSharkText("config_page_attachment_name"),
      dataIndex: "fileName",
    },
    {
      title: getSharkText("config_page_operation"),
      dataIndex: "actions",
      width: 150,
      fixed: "right",
      render: (text, record: Article) => {
        return (
          <>
            {actions.map((a) => (
              <a
                key={a.name}
                style={{ marginRight: 15 }}
                onClick={() => {
                  a.method(record);
                }}
              >
                {a.name}
              </a>
            ))}
          </>
        );
      },
    },
  ];
};
