import { ChartDataFormatter, ISeries } from "Interface";
import { LineChartOutlined, TableOutlined } from "@ant-design/icons";
import React, { ReactElement } from "react";

export enum ChartType {
  Line,
  Table,
}

export interface ChartTypeContent {
  name: string;
  component: ReactElement;
}

export const RevertChartTypeOptions: Record<ChartType, ChartTypeContent> = {
  [ChartType.Table]: {
    name: "趋势图",
    component: <LineChartOutlined />,
  },
  [ChartType.Line]: {
    name: "表格",
    component: <TableOutlined />,
  },
};
export const yoySeries: ISeries[] = [
  {
    name: "搜索指数（平均）",
    type: "line",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "searchIndex",
    },
    stack: "x",
  },
  {
    name: "航班数量（平均）",
    type: "line",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "flightCount",
    },
    stack: "x",
  },
  {
    name: "成交均价",
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "avgPrice",
    },
    stack: "x",
  },
  {
    name: "对比搜索指数（平均）",
    type: "line",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "compareSearchIndex",
    },
    stack: "x",
  },
  {
    name: "对比航班数量（平均）",
    type: "line",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "compareFlightCount",
    },
    stack: "x",
  },
  {
    name: "对比成交均价",
    type: "bar",
    formatterType: ChartDataFormatter.Num,
    encode: {
      x: "xAxis",
      y: "compareAvgPrice",
    },
    stack: "x",
  },
];

export const x15d: string[] = [
  "15d",
  "14d",
  "13d",
  "12d",
  "11d",
  "10d",
  "9d",
  "8d",
  "7d",
  "6d",
  "5d",
  "4d",
  "3d",
  "2d",
  "1d",
  "0d",
];
