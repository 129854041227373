import { Spin, Tabs } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { ITransferAnalysisExt } from "Page/AI/TransferPolicy/TransferPolicyInterface";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { isAirportMode, useFetch } from "Utils";
import { G } from "Utils/global";
import { columns } from "../AdvantageComponents/Columns";
import { ERouteType, RouteName } from "../Common";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import Refetch from "Components/Refetch";

const { TabPane } = Tabs;

export interface ITransferAdvantageProps {
  transferAnalysisExt: ITransferAnalysisExt;
}

/**
 * 价格优劣势表格
 */
const TransferAdvantage = (props: ITransferAdvantageProps): ReactElement => {
  const { transferAnalysisExt } = props;
  const { query, filter } = transferAnalysisExt;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition, queryCondition } = globalState;
  const [activeKey, setActiveKey] = useState<string>(
    ERouteType.Advantage.toString()
  );
  const isAirport = isAirportMode(systemType);
  const queryWithExt = useMemo(() => {
    if (isAirport) {
      return {
        ...queryCondition,
        ...query,
      };
    }
    return airlinesQueryCondition;
  }, [airlinesQueryCondition, isAirport, query, queryCondition]);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].advantage,
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].advantage,
    },
    query: queryWithExt,
    ext: {
      filter: {},
      type: 1,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    doFetch({
      query: queryWithExt,
      ext: {
        filter,
        type: parseInt(activeKey, 10),
      },
    });
  }, [activeKey, doFetch, filter, queryWithExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const getPane = useCallback(
    (t: ERouteType) => {
      const routeObj = RouteName[systemType][t];
      return (
        <TabPane key={t.toString()} tab={routeObj.node}>
          <CustomTableV2
            dataSource={data}
            columns={columns[systemType][t]}
            downloadName={routeObj.name}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].advantage}
          />
        </TabPane>
      );
    },
    [data, systemType]
  );
  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <Spin spinning={isLoading}>
      <Tabs
        defaultActiveKey={activeKey.toString()}
        type="card"
        onChange={(e) => setActiveKey(e)}
      >
        {G.enumValues(ERouteType).map((t) => getPane(t))}
      </Tabs>
    </Spin>
  );
};
export default TransferAdvantage;
