// Created by xh_zhu on 2020-12-21

import EchartsReactBase from "Components/EchartsReactBase";
import React, { ReactElement, useMemo } from "react";
import useGlobalState from "Store";

interface SizeConfig {
  /**
   * 图表高度
   */
  height: number;
  /**
   * 仪表盘圆环的宽度
   */
  lineWidth: number;
  /**
   * 刻度线的长度
   */
  splitLineLength: number;
  /**
   * 文字距离
   */
  labelDistance: number;
}
interface IDashGaugeProps {
  color?: string;
  max?: number;
  value: number;
  size?: "small" | "middle";
}

const sizeConfigMiddle: SizeConfig = {
  height: 300,
  lineWidth: 25,
  splitLineLength: 10,
  labelDistance: 30,
};

const sizeConfigSmall: SizeConfig = {
  height: 200,
  lineWidth: 15,
  splitLineLength: 10,
  labelDistance: 20,
};

const DashGauge = (props: IDashGaugeProps): ReactElement => {
  const { color = "#349bf8", max = 100, value, size = "middle" } = props;
  const [globalState] = useGlobalState();
  const theme = globalState.theme;
  const sizeConfig: SizeConfig = useMemo(() => {
    return size === "small" ? sizeConfigSmall : sizeConfigMiddle;
  }, [size]);
  let per = value === 0 ? 0.000001 : (max / 100) * value * 100;

  // 仪表盘组件有bug, 当值为0时, 仪表盘会打满
  if (value === 0) {
    per = 0.000001;
  }

  const option = useMemo(() => {
    return {
      series: [
        {
          type: "gauge",
          radius: "100%",
          color,
          progress: {
            show: true,
            width: sizeConfig.lineWidth,
          },
          axisLine: {
            lineStyle: {
              color: [[1, theme === "light" ? "#f0f0f0" : "#393939"]],
              width: sizeConfig.lineWidth,
            },
          },
          axisTick: {
            show: false,
          },
          max,
          splitNumber: 5,
          splitLine: {
            length: sizeConfig.splitLineLength,
            distance: 0,
            lineStyle: {
              width: 2,
              color: "#ccc",
            },
          },
          axisLabel: {
            distance: sizeConfig.labelDistance,
            color: theme === "light" ? "#333" : "#ddd",
            fontSize: 12,
            fontWeight: 200,
            formatter: (v: any) => `${Number(v).toFixed(0)}%`,
          },
          title: {
            show: false,
          },
          detail: {
            valueAnimation: true,
            fontSize: 20,
            color:
              theme === "dark"
                ? "#ccc"
                : theme === "light"
                ? "#464646"
                : undefined,
            fontWeight: 400,
            offsetCenter: [0, "65%"],
            formatter: (v: any) => `${Number(v).toFixed(0)}%`,
          },
          pointer: {
            icon: "rect",
            length: "40%",
            itemStyle: {
              color: theme === "light" ? "#ddd" : "#333",
            },
          },
          anchor: {
            show: true,
            showAbove: true,
            size: 20,
            itemStyle: {
              color: theme === "light" ? "#fff" : "#000",
              borderWidth: 5,
              borderColor: theme === "light" ? "#ddd" : "#333",
            },
          },
          data: [{ value: per }],
        },
      ],
    };
  }, [
    color,
    max,
    per,
    sizeConfig.labelDistance,
    sizeConfig.lineWidth,
    sizeConfig.splitLineLength,
    theme,
  ]);

  return (
    <div style={{ height: sizeConfig.height }}>
      <EchartsReactBase style={{ height: sizeConfig.height }} option={option} />
    </div>
  );
};
export default DashGauge;
