import {
  ACC_REVENUE_NAME,
  ASK_REVENUE_NAME,
  AVG_PRICE_NAME,
  CAP_NAME,
  FLYING_HOURS_NAME,
  GROUP_PCT_NAME,
  HOURS_REVENUE_NAME,
  LOAD_FACTOR_NAME,
  RPK_REVENUE_NAME,
  SALEDSEAT_NAME,
  SCHEDULE_CNT_NAME,
  TAKEOFF_CNT_NAME,
} from "../../DatasetCols";

interface Config {
  title: string;
  measureName: string;
}

export const OVERVIEW_CARDS_CONFIG: Config[] = [
  {
    title: "收入",
    measureName: ACC_REVENUE_NAME,
  },
  {
    title: "客运量",
    measureName: SALEDSEAT_NAME,
  },
  {
    title: "座位数",
    measureName: CAP_NAME,
  },
  {
    title: "班次量",
    measureName: SCHEDULE_CNT_NAME,
  },
  {
    title: "飞行小时",
    measureName: FLYING_HOURS_NAME,
  },
  {
    title: "小时收入",
    measureName: HOURS_REVENUE_NAME,
  },
  {
    title: "座公里收入",
    measureName: ASK_REVENUE_NAME,
  },
  {
    title: "客座率",
    measureName: LOAD_FACTOR_NAME,
  },
  {
    title: "平均票价",
    measureName: AVG_PRICE_NAME,
  },
  {
    title: "客公里收入",
    measureName: RPK_REVENUE_NAME,
  },
  {
    title: "团队旅客占比",
    measureName: GROUP_PCT_NAME,
  },
  {
    title: "起降架次",
    measureName: TAKEOFF_CNT_NAME,
  },
];
