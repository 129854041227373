import { getSharkText } from "Utils/i18nGlobal";

export enum Index {
  LF,
  Seat,
}

export const IndexList: Record<Index, string> = {
  [Index.Seat]: getSharkText("key.passenger_traffic"),
  [Index.LF]: getSharkText("key.loadfactor"),
};
