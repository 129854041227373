import { SERVER } from "Constants";

export const getServer = (type: number): string => {
  switch (type) {
    case 1:
      return SERVER.replace("19595", "21323");
    case 2:
      return SERVER.replace("19595", "21324");
    case 4:
      return SERVER.replace("19595", "22409");
  }
  return SERVER;
};
