import { SortOrder } from "antd/lib/table/interface";
import { IFlightRecord } from "./FlightManageInterface";
import { IModuleConfig, ITrace } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

export const COMPETITIVE_EMPHASIS_HELP = getSharkText(
  "config_page_competitive_emphasis_help"
);
export const COMPETITIVE_ALL_HELP = getSharkText(
  "config_page_competitive_all_help"
);

// 调舱中的#和0可视为相等
export const EQUAL_ITEM = ["#", "0"];

export const legendHelp: Record<string, string> = {
  航线成交均价: getSharkText("config_page_average_deal_price_tooltip"),
  临近起飞价格: getSharkText("config_page_near_departure_price"),
  最低起飞价格: getSharkText("config_page_PaAiFlFl_lowest_departure_price"),
};
/**
 * 航班管理表格排序, 先按日期排序, 后按字段排序
 * @param a 行1
 * @param b 行2
 * @param attr 排序字段
 * @param type 字段类型
 * @returns 小于0表示行1在前, 否则在后
 */
export const orderTable = (
  a: IFlightRecord,
  b: IFlightRecord,
  attr: keyof IFlightRecord,
  type: "string" | "number",
  s?: SortOrder
): number => {
  let rst = 0;
  // 无论何种排序, 日期始终从小至大
  if (a.takeoffdate !== b.takeoffdate) {
    return a.takeoffdate.localeCompare(b.takeoffdate);
  } else if (type === "string") {
    const av = a[attr] as string;
    const bv = b[attr] as string;
    if (av && bv) {
      rst = av.localeCompare(bv);
    } else {
      rst = 0;
    }
  } else if (type === "number") {
    const av = a[attr] as number;
    const bv = b[attr] as number;
    if (av && bv) {
      rst = av - bv;
    } else {
      rst = 0;
    }
  }
  if (s === "descend") {
    rst = rst * -1;
  }
  return rst;
};

// 埋点类型
export const TRACE_ID: ITrace = {
  category: "flight_manage",
  getName: (key: keyof typeof TRACE_ID) => {
    if (typeof TRACE_ID[key] === "string") {
      return TRACE_ID.category + "_" + TRACE_ID[key];
    } else {
      throw new Error("没有这个埋点key");
    }
  },
  // 查询参数
  query: "query",
  // 自营
  selfSupport: "selfSupport",
  // 行选中
  selectedRow: "selectedRow",
  // 预警查看
  alert: "alert",
  // 调舱还原
  layoutReset: "layoutReset",
  // 调舱生成
  layoutGenerate: "layoutGenerate",
  // 调舱航段切换
  layoutRoute: "layoutRoute",
  // 指令复制
  layoutCopy: "layoutCopy",
};

export enum EEtermCmdType {
  GENERATE = 0,
  SUBMIT,
}

/** 请求22299接口时使用 */
export const CLIENT_APP_ID = "100026391";
/** 请求22299接口时使用 */
export const CLIENT_IP = "10.22.117.126";

export const getLimitDays = (
  moduleConfig: IModuleConfig,
  route: string
): number => {
  const defaultDays =
    Number(moduleConfig["flightmanage.airline.timerange.default"]) || 30;
  const customAirlinesJson = moduleConfig["flightmanage.airline.timerange"];
  if (!customAirlinesJson) {
    return defaultDays;
  }
  const customAirlinesObj = JSON.parse(customAirlinesJson);
  const customAirlines = Object.keys(customAirlinesObj);
  if (!customAirlines.length) {
    return defaultDays;
  }
  const customDaysObj = customAirlinesObj[customAirlines[0]];
  const customRoutes = Object.keys(customDaysObj);
  if (route && customRoutes.includes(route)) {
    return customDaysObj[route];
  } else if (customRoutes.includes("*")) {
    return customDaysObj["*"];
  } else {
    return defaultDays;
  }
};
