/**
 * how to use
 * <SvgIcon name={svgFileName} className?={className}/>
 */
import cx from "classnames";
import * as React from "react";
// 最好的方式是自动将svg文件转成symbol，运用webpack的require特性，动态的引入svg
// 由于ice-script 1.9不支持相关的loader，所以只能这样搞
import "./icons/iconfont";
import "./index.css";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 * @return {null}
 */
interface IconProps {
  name: string;
  className?: string;
  style?: any;
  color?: string;
}

const SvgIcon: React.FC<IconProps> = (props: IconProps) => {
  const { className, name, style, color } = props;
  if (!name) {
    return null;
  }
  return (
    <svg
      className={cx("icon", `icon-${name}`, className)}
      style={{ ...style, ...{ fill: color } }}
    >
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
};

export default SvgIcon;
