import React, {
  useState,
  useEffect,
  CSSProperties,
  useCallback,
  useMemo,
} from "react";
import { Select, Spin } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobalState from "Store";
import { getServer } from "Service/server";

interface IProps {
  queryUrl: string;
  moduleCode: string;
  routes: string[];
  setRoutes: (list: string[]) => void;
  flights: string;
  isDemo: boolean;
  title?: string | false;
  style?: CSSProperties;
  autoSelectFirst?: boolean;
}

const { Option } = Select;
const transRoute = (item: string) =>
  `${item.substr(0, 3)}-${item.substr(3, 3)}`;
const queryExt = {
  partner: "travix",
  partnerName: "travix",
  compareType: 0,
  marketType: 0,
};

const SelectMultiRoutes: React.FC<IProps> = (props: IProps) => {
  const {
    routes,
    setRoutes,
    isDemo,
    title = getSharkText("key.route.name"),
    style,
    queryUrl,
    moduleCode,
    autoSelectFirst = true,
  } = props;
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const [flightList, setFlightList] = useState<string[]>([]);
  const [filterList, setFilterList] = useState<string[]>([]);
  const [displayRoutes, setDisplayRoutes] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const queryWithExt = useMemo(
    () => ({ ...queryCondition, ...queryExt }),
    [queryCondition]
  );
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(4),
    url: queryUrl || "queryDynamicPriceRouteOrFlight",
    head: {
      moduleCode: moduleCode || "dynamic_price_airlines",
      chartTableCode: null,
    },
    query: queryWithExt,
    ext: {
      type: 1,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (isDemo) {
      return;
    }
    doFetch({
      query: queryWithExt,
      ext: {
        type: 1,
      },
    });
  }, [isDemo, doFetch, queryWithExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    setDisplayRoutes(routes.map((item) => transRoute(item)));
  }, [routes]);

  useEffect(() => {
    if (data) {
      const rst = data.map((d: any) => d.route);
      const filterArr = rst.slice(0, 20);
      setFlightList(rst);
      setFilterList(filterArr);
      if (autoSelectFirst) {
        setRoutes([rst[0]]);
      }
    }
  }, [data, autoSelectFirst, setRoutes]);

  if (error) {
    return null;
  }

  const handleChange = (value: string[] | string) => {
    if (Array.isArray(value)) {
      setRoutes(value.map((item) => item.replace("-", "")));
    } else {
      setRoutes([value.replace("-", "")]);
    }
  };

  const handleSearch = (value: string) => {
    const filterArr = flightList.filter((item) =>
      item
        ?.replace("-", "")
        .includes(value.toLocaleUpperCase().replace("-", ""))
    );
    if (filterArr) {
      setFilterList(filterArr.slice(0, 20));
    }
  };

  const onVisibleChange = (v: boolean) => {
    setOpen(v);
  };

  const closeDropdown = () => {
    setOpen(false);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        {title ? (
          <span className="search-area-filter-title">{title}</span>
        ) : undefined}
        <Select
          open={open}
          className="search-airlines"
          disabled={isDemo}
          mode="multiple"
          notFoundContent="NO Data"
          placeholder={getSharkText(
            "config_page_please_select_routes_support_multiple"
          )}
          value={displayRoutes}
          onChange={handleChange}
          onSearch={handleSearch}
          onDropdownVisibleChange={onVisibleChange}
          style={{ width: 204, ...style }}
          dropdownRender={(menu) => <div>{menu}</div>}
        >
          {filterList.map((item: string, idx: number) => (
            <Option key={idx} value={item}>
              {item}
            </Option>
          ))}
        </Select>
      </Spin>
    </>
  );
};

export default SelectMultiRoutes;
