import React, { useEffect, useState } from "react";
import BackToTop from "./backToTop";
import Form from "Components/Official/Form";
import { ReactComponent as IconArrow } from "assets/svg/arrow-r.svg";
import "./index.scss";

const Side: React.FC = () => {
  const [isShowContact, setIsShowContact] = useState(false);

  useEffect(() => {
    const removePop = (e: any) => {
      const contactdom = document.getElementsByClassName("side-contact-mod")[0];
      if (contactdom && !contactdom.contains(e.target)) {
        setIsShowContact(false);
      }
    };
    document.addEventListener("click", removePop);
    return () => {
      document.removeEventListener("click", removePop);
    };
  }, []);

  const handleClick = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    setIsShowContact(!isShowContact);
  };

  const handleClose = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    setIsShowContact(false);
  };

  return (
    <div className="side-wrap">
      <div onClick={handleClick} className="side-btn side-contact-btn">
        <span>联系我们</span>
      </div>
      <div
        className="side-contact-mod"
        style={{ display: isShowContact ? "block" : "none" }}
      >
        <h5 className="side-contact-title">联系我们</h5>
        <IconArrow className="side-arrow" onClick={handleClose} />
        <Form verifyId="verify-code-side" />
      </div>
      <BackToTop />
    </div>
  );
};

export default Side;
