import React from "react";
import { Modal } from "antd";
import { cloneDeep } from "lodash";
import moment from "moment";
import { getSharkText } from "Utils/i18nGlobal";

export const openWarning = (): void => {
  Modal.warning({
    title: getSharkText("key.daterange_notsupported.hint"),
    okText: getSharkText("key.continue.text"),
    width: 600,
    mask: false,
    content: (
      <>
        <span style={{ display: "block" }}>
          <span>
            {getSharkText("key.wow.indicator")}:
            {getSharkText("key.daterange_wow.hint")}
          </span>
        </span>
        <span>
          {getSharkText("key.mom.indicator")}:
          {getSharkText("key.daterange_mom.hint")}
        </span>
      </>
    ),
  });
};

export const validateAllowValControl = (
  val: string[],
  startDate?: string,
  endDate?: string
): string[] => {
  // 如果日期为空则跳过
  if (!startDate || !endDate) {
    return val;
  }
  const start = moment(startDate);
  const end = moment(endDate);
  let tmpVal = cloneDeep(val);
  if (val.includes(getSharkText("key.wow.indicator"))) {
    // 必须为同一周的周一至周日
    if (
      !(start.week() === end.week() && start.day() === 1 && end.day() === 0)
    ) {
      tmpVal = tmpVal.filter((v) => v !== getSharkText("key.wow.indicator"));
      openWarning();
      // message.warn('仅时间为一整周时可以选择周环比')
    }
  }
  if (val.includes(getSharkText("key.mom.indicator"))) {
    // 必须为同一月的开始到结束
    if (
      !(
        start.month() === end.month() &&
        start.date() === 1 &&
        moment(endDate).add(1, "d").month() !== start.month()
      )
    ) {
      tmpVal = tmpVal.filter((v) => v !== getSharkText("key.mom.indicator"));
      openWarning();
      // message.warn('仅时间为一整月时可以选择月环比')
    }
  }
  return tmpVal;
};
