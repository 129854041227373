import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SearchArea from "./Components/SearchArea";
import "./index.scss";
import { Empty } from "antd";
import PriceCalendar, { PCalendarHandler } from "./Components/PriceCalendar";
import { FlightInfoDetailWithKey, GetFlightInfoRequest } from "./interface";
import DetailTable from "./Components/PriceCalendar/DetailTable";
import { AirlinesQueryCondition } from "Interface";
import useGlobalState from "Store";
import { DATE_FORMAT, EMPTY_ARRAY } from "Constants";
import { getDefaultAirline, isCtrip } from "Utils/global";
import { getDateRange } from "Utils";
import { useModuleConfig } from "Utils/useModuleConfig";
import BlackScreen from "./Components/BlackScreen";
import useRefFunc from "Utils/useRefFunc";

export const MODULE_CODE = "allocation_operation";
export const CHART_TABLE_CODE = "none";
export const DEFAULT_RANGE = getDateRange("nextFourteenDays");

/** 切位运营 */
const AllocationOperation = (): ReactElement => {
  const [globalState, actions] = useGlobalState();
  const [curRecord, setCurRecord] = useState<FlightInfoDetailWithKey>();
  const [requestParam, setRequestParam] = useState<GetFlightInfoRequest>();
  const [roOpen, setRoOpen] = useState<boolean>(false);
  const isCtriper = isCtrip(globalState.userInfo);
  useModuleConfig({
    moduleCode: MODULE_CODE,
    chartTableCode: CHART_TABLE_CODE,
  });

  useEffect(() => {
    const initParam: Partial<AirlinesQueryCondition> = {
      airlines: getDefaultAirline(globalState.userInfo.roleList).airlines,
      startDate: DEFAULT_RANGE ? DEFAULT_RANGE[0]?.format(DATE_FORMAT) : "",
      endDate: DEFAULT_RANGE ? DEFAULT_RANGE[1]?.format(DATE_FORMAT) : "",
    };
    actions.setAirlinesAttrIfNull(initParam);
  }, []);

  const tableRef = useRef<PCalendarHandler>(null);

  const refreshTable = useRefFunc(() => {
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  });

  const tableContent = useMemo(
    () =>
      requestParam ? (
        <PriceCalendar
          ref={tableRef}
          queryExt={requestParam}
          isDemo={false}
          curRecord={curRecord}
          setCurRecord={setCurRecord}
          roOpen={roOpen}
          setRoOpen={setRoOpen}
        />
      ) : undefined,
    [requestParam, curRecord, roOpen]
  );

  const eterm = useMemo(() => {
    return curRecord ? (
      <div>
        <BlackScreen
          record={curRecord}
          closeRoPanel={() => setRoOpen(false)}
          refresh={refreshTable}
        />
      </div>
    ) : undefined;
  }, [curRecord, refreshTable]);

  return (
    <div className="allocation-operation">
      <div className="search-area-container">
        <SearchArea onChange={setRequestParam} />
      </div>
      <div className="content-white">
        <div
          className="flightManage"
          style={{ display: "flex", margin: "0 20px" }}
        >
          <div
            style={{
              marginTop: 0,
              position: "relative",
              overflow: "hidden",
              background: "#fff",
              marginRight: 10,
            }}
          >
            {requestParam ? (
              <>
                <div>{tableContent}</div>
                {curRecord && isCtriper ? (
                  <div
                    className="flight-manage-difftime-table"
                    style={{ margin: "0 0px" }}
                  >
                    <DetailTable
                      sales={curRecord.saleUpdateRecordList || EMPTY_ARRAY}
                    />
                  </div>
                ) : undefined}
              </>
            ) : (
              <Empty description="请选择航线" />
            )}
          </div>
          <div
            style={{
              width: 700,
              display: roOpen ? "block" : "none",
              flexShrink: 0,
            }}
          >
            {eterm}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AllocationOperation;
