import React, { Key, ReactElement, useEffect, useState } from "react";
import CheckButtons, { ICheckButtonsProps } from "./CheckButtons";
import useRefFunc from "Utils/useRefFunc";
import { isSame } from "Utils";

export interface CheckButtonsWithAllProps extends ICheckButtonsProps {
  allowValues?: Key[];
  allStyle?: React.CSSProperties;
}

/**
 *  Component description
 */
const CheckButtonsWithAll = (props: CheckButtonsWithAllProps): ReactElement => {
  const { allowValues, allStyle, options, value, onChange, ...rest } = props;
  const [isAll, setIsAll] = useState(false);

  useEffect(() => {
    setIsAll(() => {
      if (allowValues) {
        return isSame(value?.sort(), allowValues.sort());
      } else if (value?.length === options.length) {
        return true;
      } else {
        return false;
      }
    });
  }, [allowValues, options.length, value]);

  const handleChange = useRefFunc((v: Key[]) => {
    if (allowValues && v.some((v1) => !allowValues.includes(v1))) {
      return;
    }
    if (onChange) {
      onChange(v);
    }
    // if (v.length === options.length && !isAll) {
    //   setIsAll(true);
    // } else if (v.length !== options.length && isAll) {
    //   setIsAll(false);
    // }
  });
  const handleClickAll = () => {
    // setIsAll(true);
    handleChange(allowValues || options.map((a) => a.value));
  };
  return (
    <span>
      <span
        className={`check-buttons-all ${isAll ? "checked" : ""}`}
        onClick={handleClickAll}
        style={{ ...allStyle }}
      >
        全部
      </span>
      <CheckButtons
        onChange={handleChange}
        options={options}
        value={value}
        {...rest}
      />
    </span>
  );
};
CheckButtonsWithAll.displayName = "CheckButtonsWithAll";
export default CheckButtonsWithAll;
