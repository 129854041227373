import Airport from "Components/Toolbar/Airport";
import InOut from "Components/Toolbar/inOut";
import { Col, Row } from "antd";
import React, { ReactElement } from "react";
import DateRangePicker from "Components/Toolbar/DateRangePicker";
import NumberRange from "./NumberRange";

export interface IProps {
  onChangeRange: (v: [number | null, number | null]) => void;
}

/**  Component description */
const SearchArea = (props: IProps): ReactElement => {
  const { onChangeRange } = props;
  return (
    <Row gutter={10} style={{ alignItems: "center" }}>
      <Col>
        <Airport />
      </Col>
      <Col>
        <InOut />
      </Col>
      <Col>
        <DateRangePicker
          defaultDateMode="nextThirtyDays"
          shouldDisableDate={false}
        />
      </Col>
      <Col>
        <span style={{ marginRight: 10, marginLeft: 20 }}>预测取消概率</span>
        <NumberRange onChange={onChangeRange} />
      </Col>
    </Row>
  );
};
export default SearchArea;
