import { LS } from "Constants/localStorage";
import useGlobalState from "Store";
import useRefFunc from "Utils/useRefFunc";
import { Button, Checkbox, Modal } from "antd";
import moment from "moment";
import React, { ReactElement, ReactNode, useEffect, useMemo } from "react";

export interface PaymentNoticeProps {
  onMount?: (entry: ReactNode) => void;
}

/**
 * 收费通知(弹窗)
 */
const PaymentNotice = (props: PaymentNoticeProps): ReactElement => {
  const { onMount } = props;
  const [globalState] = useGlobalState();
  const userInfo = globalState.userInfo;

  const allowNotice = useMemo(() => {
    // 仅机场用户弹窗
    const roleList = userInfo.roleList;
    if (roleList?.length !== 1 || roleList[0].roleType !== 1) {
      return false;
    }
    if (moment().isSameOrAfter(moment("2025-01-01"))) {
      return false;
    }
    return true;
  }, [userInfo.roleList]);

  const [open, setOpen] = React.useState(() => {
    if (!allowNotice) {
      return false;
    }
    const res = localStorage.getItem(LS.PaymentNoticeClose);
    return res === null;
  });
  const [notAgain, setNotAgain] = React.useState(false);

  const entry = useMemo(
    () => <span onClick={() => setOpen(true)}>FlightAI服务变更通知</span>,
    []
  );
  useEffect(() => {
    if (onMount && allowNotice) {
      onMount(entry);
    }
  }, [allowNotice, entry, onMount]);

  const close = useRefFunc(() => {
    if (notAgain) {
      localStorage.setItem(LS.PaymentNoticeClose, "true");
    }
    setOpen(false);
  });

  return (
    <Modal
      title="FlightAI服务变更通知"
      open={open}
      maskClosable={false}
      closeIcon={null}
      footer={[
        <Checkbox
          key={"0"}
          checked={notAgain}
          onChange={(e) => setNotAgain(e.target.checked)}
          style={{ fontSize: "90%", marginRight: 15 }}
        >
          不再提示
        </Checkbox>,
        <Button key={"1"} onClick={close} type="primary">
          知道了
        </Button>,
      ]}
    >
      <div style={{ lineHeight: 2 }}>
        <p style={{ textIndent: 30 }}>
          感谢您在公测期间对FlightAI市场洞察平台的支持。FlightAI即将开启商业化收费，以下是重要信息摘要：
        </p>
        <ul style={{ paddingInlineStart: 16 }}>
          <li>
            <span style={{ fontWeight: "bold" }}>服务调整日期：</span>自
            <span style={{ color: "red" }}>2024/9/16</span>
            起，我们将收回部分产品模块的使用权限。届时您可以保留免费模块的使用权限。
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>收费计划启动：</span>
            从2025/1/1开始，FlightAI将正式进入收费模式。
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>行动指南：</span>
            如需继续使用完整服务或了解更多详情，请与我们联系。
          </li>
        </ul>
        <div style={{ fontWeight: "bold" }}>联系方式</div>
        <ul style={{ paddingInlineStart: 16 }}>
          <li>俞女士 13701926332 / 吴先生 15618856169</li>
          <li>
            邮箱：<a href="mailto:flightai@trip.com">flightai@trip.com</a>
          </li>
        </ul>
        <div style={{ margin: "16px 0px" }}>
          感谢您的理解与支持，期待与您继续合作。
        </div>
        <div style={{ textAlign: "right" }}>
          <div>FlightAI团队</div>
          <div>2024年9月2日</div>
        </div>
      </div>
    </Modal>
  );
};
PaymentNotice.displayName = "PaymentNotice";
export default PaymentNotice;
