import React, { ReactElement, useEffect, useState } from "react";
import { Spin } from "antd";
import {
  genSeriesByDimensions,
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  showNum,
} from "Utils";
import { AggCode, ISeries } from "Interface";
import { merge, uniq } from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarChartsProps {
  /**
   * data set source 放入dataSet中, 列名需要与series匹配
   */
  data: any[];
  /**
   * 可选, 默认300
   */
  height?: number;
  /**
   * 是否处于加载状态
   */
  loading: boolean;
  /**
   * 粒度, 影响tooltip的内容提示
   */
  aggCode: AggCode;
  /**
   * 起始日期, 影响tooltip的日期显示计算
   */
  startDate: string;
  /**
   * 终止日期, 影响tooltip的日期显示计算
   */
  endDate: string;
  /**
   * 是否demo模式, 影响tooltip的日期显示计算
   */
  isDemo: boolean;
  /**
   * 全部series配置
   */
  defaultSeries: ISeries[];
  /**
   * 是否使用dataset数据集
   */
  useDataset: boolean;
  /**
   * 可选, ECharts配置项, 会透传给ECharts, 因为过于灵活可能导致难以维护, 非必要不建议使用
   */
  chartConfig?: Record<string, unknown>;
  /**
   * need100Times, 百分数是否需要乘以100, 由于旧版flightAi的问题, 百分数*100是由后端处理的, 但应该由前端在显示时处理才更加合理
   * 默认为false以兼容旧逻辑, 新模块设为true
   */
  need100Times: boolean;
  /**
   * 数据精度
   */
  precision: number;
  /**
   * 图表事件
   */
  chartEvents?: Record<string, (...args: any[]) => any>;
}
/**
 * 图表渲染组件
 * @param props
 * @returns
 */
export const BarCharts = (props: BarChartsProps): ReactElement => {
  const {
    data,
    height,
    loading,
    aggCode,
    startDate,
    endDate,
    isDemo,
    defaultSeries,
    useDataset = true,
    chartConfig,
    need100Times,
    precision,
    chartEvents,
  } = props;

  const [option, setOption] = useState<any>();

  useEffect(() => {
    const dimensions: string[] =
      data && data.length
        ? uniq(
            data.reduce((t, c) => {
              t = t.concat(Object.keys(c));
              return t;
            }, [])
          )
        : [];
    const series = useDataset
      ? genSeriesByDimensions(dimensions, defaultSeries)
      : defaultSeries;
    const yAxis0Type = series
      .filter((s) => s.yAxisIndex === undefined || s.yAxisIndex === 0)
      .every((s) => s.isPercentVal)
      ? "percentage"
      : "num";
    const tmp: Record<string, any> = {
      dataset: {
        dimensions,
        source: data,
      },
      color: ["#01C5DB", "#FC9B4F", "#177DFE"],
      // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          const day = getTooltipDateFromAggCode(
            aggCode,
            params,
            data.length,
            startDate,
            endDate,
            isDemo
          );
          let dom = "";
          params.forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const seriesConfig = defaultSeries.find(
              (d) => d.name === item.seriesName
            );
            const isPercentVal = seriesConfig
              ? seriesConfig.isPercentVal
              : false;
            const itemDim = item.dimensionNames[item.encode.y[0]];
            const itemValue = item.value[itemDim];
            const value = isPercentVal
              ? showNum(
                  need100Times ? itemValue * 100 : itemValue,
                  "percentage"
                )
              : showNum(itemValue, "num", precision);
            const val = `${item.seriesName}: ${value}`;
            dom = dom + "<br />" + dot + val;
          });
          dom = day + dom;
          return dom;
        },
      },
      xAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
        axisLabel: {
          padding: [8, 0, 0, 0],
          formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
        },
      },
      dataZoom: [
        {
          type: "inside",
        },
        {
          type: "slider",
          bottom: 0,
        },
      ],
      yAxis: [
        {
          position: "left",
          type: "value",
          splitNumber: 3,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "#ddd",
            },
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            formatter: (value: number) =>
              yAxis0Type === "percentage"
                ? showNum(value * 100, "percentage")
                : showNum(value, "num", precision),
          },
        },
        {
          position: "right",
          type: "value",
          splitLine: false,
          splitNumber: 3,
          axisLine: {
            show: false,
          },
          axisLabel: {
            formatter: (value: number) =>
              `${
                defaultSeries.filter((d) => d.isPercentVal).length > 0
                  ? (need100Times ? value * 100 : value) + "%"
                  : value
              }`,
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series,
      // ...chartConfig,
    };
    if (!useDataset) {
      tmp.dataset = null;
    }
    const opt = merge(tmp, chartConfig);
    if (typeof opt.xAxis.dataFn === "function") {
      opt.xAxis.data = opt.xAxis.dataFn(data, defaultSeries);
    }
    setOption(opt);
  }, [aggCode, chartConfig, data, defaultSeries, startDate, endDate, isDemo]);

  return (
    <Spin spinning={loading}>
      {option ? (
        <EchartsReactBase
          className="bar-line-charts"
          option={option}
          theme="default"
          style={{ height }}
          notMerge
          onEvents={chartEvents}
        />
      ) : undefined}
    </Spin>
  );
};

export default BarCharts;
