import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, Divider, Popover, Row, Statistic, Typography } from "antd";
import Trend from "./Trend";
import { CardItemData, ECompareTypeV2, SystemType } from "Interface";
import { PERCENT_VALUES } from "Constants";
import _ from "lodash";
import {
  getComparedPercentage,
  getComparePercentageVal,
  isHideDigits,
  showNum,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";
import { IQueryExt } from "../Interface";

interface CardItemProps {
  data: CardItemData;
  selectedCode?: string;
  setCurCard?: (card: CardItemData) => void;
  compareType?: ECompareTypeV2;
  systemType?: SystemType;
  queryExt?: IQueryExt;
}

const { Text } = Typography;

const CardItem: React.FC<CardItemProps> = (props: CardItemProps) => {
  const { data, selectedCode, queryExt } = props;
  const { cardCode, cardName, cardValue, cardHint, compareValue, yoyValue } =
    data;

  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;
  const yoyPer = isPercentVal
    ? getComparedPercentage(cardValue, yoyValue)
    : getComparePercentageVal(cardValue, yoyValue);
  const wowPer = isPercentVal
    ? getComparedPercentage(cardValue, compareValue)
    : getComparePercentageVal(cardValue, compareValue);
  const isShowEmpty = cardValue === 0;

  const getCardValue = (val: any) => {
    if (isPercentVal) {
      return showNum(val, "percentage");
    } else if (isHideDigits(cardCode)) {
      return _.round(val);
    } else {
      return val;
    }
  };

  return (
    <Card
      id={cardCode}
      size="small"
      bordered={false}
      className={`card ${selectedCode === cardCode ? "cur" : ""}`}
      hoverable={true}
    >
      <div className="card-title">
        <div className="title">{getSharkText(`card.${cardCode}.name`)}</div>
        <Popover
          content={<span>{getSharkText(`card.${cardCode}.hint`)}</span>}
          overlayClassName="dashboard-val-control-popover"
        >
          {getSharkText(`card.${cardCode}.hint`) && <InfoCircleOutlined />}
        </Popover>
      </div>
      {isShowEmpty ? (
        <div className="ant-statistic-content">
          <span className="ant-statistic-content-value">
            <span className="ant-statistic-content-value-int">-</span>
          </span>
        </div>
      ) : (
        <Statistic
          groupSeparator=","
          precision={isPercentVal ? 1 : 0}
          value={getCardValue(cardValue)}
        />
      )}
      <Divider style={{ margin: "6px 0" }} />
      <Row>
        {!queryExt || queryExt.yoy === 0 ? undefined : (
          <Trend
            flag={yoyValue < cardValue ? "up" : "down"}
            showFlag={cardValue !== 0 && yoyValue !== cardValue}
          >
            <Text className="card-text">
              {getSharkText("key.yoy.button")}&nbsp;
              {isShowEmpty ? (
                "-"
              ) : (
                <ValueNum isShowZero={false} type="percentage" value={yoyPer} />
              )}
            </Text>
          </Trend>
        )}
        {!queryExt || queryExt.wow === 0 ? undefined : (
          <Trend
            flag={compareValue < cardValue ? "up" : "down"}
            showFlag={cardValue !== 0 && compareValue !== cardValue}
          >
            <Text className="card-text">
              {getSharkText("key.mom.indicator")}&nbsp;
              {isShowEmpty ? (
                "-"
              ) : (
                <ValueNum isShowZero={false} type="percentage" value={wowPer} />
              )}
            </Text>
          </Trend>
        )}
      </Row>
    </Card>
  );
};

export default CardItem;
