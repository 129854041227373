export const MODULE_CODE = "flight_review_segment_airlines";

export const CHART_TABLE_CODE = {
  table: "non",
};

export const QUERY_URL = {
  segment: "queryFlightSegmentTable",
  flight: "queryFlightReviewFlightTable",
  airlines: "queryFlightReviewFlightAirlines",
  flightNO: "queryFlightReviewFlightFlightNO",
};
