import React, { useEffect, useMemo, useState } from "react";
import { Checkbox, Col, Input, Row, Table } from "antd";
import cloneDeep from "lodash/cloneDeep";
import round from "lodash/round";
import isEmpty from "lodash/isEmpty";
import DownloadBtn from "Components/DownloadBtn";
import {
  durationFormatter,
  groupSubData,
  openDownloadDialog,
  showNum,
  showRawNum,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";
import "./CustomTable.css";
import useGlobalState from "Store";
import { COMPARE_TYPE_MARKET_NAME, COMPARE_TYPE_PER_NAME } from "Constants";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
// #region columns define
const dimColumns: any[] = [
  {
    title: getSharkText("transfer.route_OD"),
    dataIndex: "dport",
    key: "dport",
    width: 280,
    fixed: "left",
    render: (value: string, record: any) =>
      record.isChild
        ? ""
        : `${record.dportName}(${record.dport})-${record.aportName}(${record.aport})`,
    subRender: () => "--",
    downLoadFormatter: (val: any, record: any) =>
      `${record.dportName}(${record.dport})-${record.aportName}(${record.aport})`,
  },
  {
    title: getSharkText("transfer.transfer_point.name"),
    dataIndex: "tportName",
    key: "tportName",
    width: 120,
    fixed: "left",
    render: (val: string, record: any) =>
      val === getSharkText("tranfer.direct.checkbox")
        ? val
        : `${record.tportName}(${record.tport})`,
    downLoadFormatter: (val: any, record: any) =>
      val === getSharkText("tranfer.direct.checkbox")
        ? val
        : `${record.tportName}(${record.tport})`,
  },
];

const valColumns: any[] = [
  {
    title: getSharkText("transfer.psgr_traffic.name"),
    dataIndex: "transitPersons",
    key: "transitPersons",
    sorter: (a: any, b: any) => a.transitPersons - b.transitPersons,
    render: (val: number) => showNum(val),
    downLoadFormatter: (val: number) => showRawNum(val),
  },
  {
    title: getSharkText("transfer.avg_transfer_duration.name"),
    dataIndex: "transferTime",
    key: "transferTime",
    sorter: (a: any, b: any) => a.transferTime - b.transferTime,
    render: (val: number) => `${durationFormatter(val * 60)}`,
    downLoadFormatter: (val: number) => showRawNum(val, "minute"),
  },
  {
    title: getSharkText("transfer.flight_duration.name"),
    dataIndex: "flightTime",
    key: "flightTime",
    sorter: (a: any, b: any) => a.flightTime - b.flightTime,
    render: (val: number) => `${durationFormatter(val * 60)}`,
    downLoadFormatter: (val: number) => showRawNum(val, "minute"),
  },
  {
    title: getSharkText("transfer.avg_transfer_price.name"),
    dataIndex: "avgPrice",
    key: "avgPrice",
    sorter: (a: any, b: any) => a.avgPrice - b.avgPrice,
    render: (val: number) => <ValueNum type="num" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val),
  },
  {
    title: getSharkText("transfer.proportion_same_airline.name"),
    dataIndex: "airlineRate",
    key: "airlineRate",
    sorter: (a: any, b: any) => a.airlineRate - b.airlineRate,
    render: (val: number) => <ValueNum type="percentage" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText("transfer.proportion_ttl_transfers.name"),
    dataIndex: "allTransferRate",
    key: "allTransferRate",
    sorter: (a: any, b: any) => a.allTransferRate - b.allTransferRate,
    render: (val: number) => <ValueNum type="percentage" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
  {
    title: getSharkText("transfer_proportion_ttl_od.name"),
    dataIndex: "allOdRate",
    key: "allOdRate",
    sorter: (a: any, b: any) => a.allOdRate - b.allOdRate,
    render: (val: number) => <ValueNum type="percentage" value={val} />,
    downLoadFormatter: (val: number) => showRawNum(val, "percentage"),
  },
];
// #endregion

// 动态计算表格的高度, 如果表格高度太高, 控制栏与表格底部数据就不能同时显示
const getBoxHeight = () => {
  const ele = document.getElementById("table-container");
  const height = ele?.clientHeight;
  console.log(height);
  // 100是分页器高度
  return height ? height - 100 : 400;
};

// #region control define
const ExValueList = ["-"] as const;
type ExType = (typeof ExValueList)[number];
const ExMap: Record<ExType, string> = {
  "-": getSharkText("tranfer.direct.checkbox"),
};
const ExOptions = Object.keys(ExMap).map((key) => ({
  value: key,
  label: ExMap[key as ExType],
}));
// #endregion

// 表格头两列的宽度
const dimWidth = 400;
const baseWidth = 800;
interface CustomTableProps {
  dataSource: any[];
  useCompare: number;
  searchkeys: string[];
  searchPlaceholder: string;
  size?: "large" | "middle" | "small";
  moduleCode: string;
  chartTableCode: string;
}

const CustomTable: React.FC<CustomTableProps> = (props: CustomTableProps) => {
  const {
    dataSource,
    useCompare,
    searchkeys,
    searchPlaceholder,
    size = "large",
    moduleCode,
    chartTableCode,
  } = props;
  const [width, setWidth] = useState<number>(baseWidth + dimWidth);
  const [searchText, setSearchText] = useState<string>("");
  const [chiControls, setChiControls] = useState<ExType[]>([]);
  const [isShowCompare, setIsShowCompare] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [tableHeight, setTableHeight] = useState<number>(400);
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const { compareType } = airlinesQueryCondition;
  const compareName = COMPARE_TYPE_MARKET_NAME[compareType];

  useEffect(() => {
    setTableHeight(getBoxHeight());
    const resizeListener = () => {
      // change width from the state object
      setTableHeight(getBoxHeight());
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  // 生成同比环比的columns
  useEffect(() => {
    if (!isShowCompare) {
      setColumns(dimColumns.concat(valColumns));
    }
    setWidth(baseWidth * (isShowCompare ? 2 : 1) + dimWidth);
    const tmpColumns: any[] = cloneDeep(valColumns);
    let count = 1;
    valColumns.forEach((c, i) => {
      if (isShowCompare) {
        const key = `${c.dataIndex}Compare`;
        const yoyItem = {
          title: `${c.title}${
            c.dataIndex === "transitPersons"
              ? compareName
              : COMPARE_TYPE_PER_NAME[compareType]
          }`,
          dataIndex: key,
          key,
          sorter: (a: any, b: any) => a[key] - b[key],
          render: (val: number) => <ValueNum type="percentage" value={val} />,
          downLoadFormatter: (val: any) => showRawNum(round(val), "percentage"),
        };
        tmpColumns.splice(i + count, 0, yoyItem);
        count++;
      }
    });
    const rstColumns = tmpColumns;
    setColumns(dimColumns.concat(rstColumns));
  }, [isShowCompare, compareName, compareType]);

  const groupData = useMemo(() => {
    const childrenKey = ExValueList;
    dataSource.forEach((item: any) => (item.key = item.id || item.key));
    // 排除不需要显示的子项
    const showData = dataSource.filter(
      (item: any) =>
        childrenKey.indexOf(item.tport) < 0 ||
        chiControls.indexOf(item.tport) >= 0
    );
    const groupedData = groupSubData({
      data: showData,
      groupByFn: (d) => (childrenKey.includes(d.tport) ? 1 : 0),
      dimColumns: ["aport", "dport"],
    });
    /** 为所有的列生成id, 因为同出发到达不同中转, 会重复使用相同的直飞数据, 需要保证每行都有不同的id */
    groupedData.forEach((item) => {
      item.id = `${item.dport}_${item.tport}_${item.aport}`;
    });
    return groupedData;
  }, [chiControls, dataSource]);

  const filterData = useMemo(() => {
    if (isEmpty(dataSource)) {
      return dataSource;
    } else {
      const cloneData = cloneDeep(groupData);
      const filter = cloneData.filter((data: any) => {
        // let isPass = false;
        const isPass = searchkeys.some(
          (key) =>
            data[key] &&
            data[key]
              .toLocaleLowerCase()
              .includes(searchText.toLocaleLowerCase())
        );
        return isPass;
      });
      return filter.slice(0, 100);
    }
  }, [dataSource, groupData, searchText, searchkeys]);

  const handleOnChange = (checkedValues: any[]) => {
    setChiControls(checkedValues);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const onChangeColControls = (e: CheckboxChangeEvent) => {
    setIsShowCompare(e.target.checked);
  };

  const handleDownload = () => {
    const sheetData1: any = [];
    const sheetData2: any = [];
    const sheetDataFn = (record: any, i: number) => {
      const obj1: any = {};
      columns.forEach((c: any) => {
        obj1[c.title] = c.downLoadFormatter
          ? c.downLoadFormatter(record[c.dataIndex], record, i)
          : record[c.dataIndex];
      });
      return obj1;
    };

    dataSource.forEach((record: any, i: number) => {
      switch (record.tport) {
        case "-":
          sheetData2.push(sheetDataFn(record, i));
          break;
        default:
          sheetData1.push(sheetDataFn(record, i));
          break;
      }
    });
    const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
    const sheet2 = XLSX.utils.json_to_sheet(sheetData2);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      wb,
      sheet1,
      getSharkText("transfer.transfer.name")
    );
    XLSX.utils.book_append_sheet(
      wb,
      sheet2,
      getSharkText("tranfer.direct.checkbox")
    );
    const workbookBlob = workbook2blob(wb);
    openDownloadDialog(
      workbookBlob,
      getSharkText("config_page_transfer_analysis_table") + ".xlsx"
    );
  };

  return (
    <>
      {useCompare === 1 ? (
        <Row align="middle" gutter={32}>
          <Col>
            <Input.Search
              value={searchText}
              placeholder={searchPlaceholder}
              onChange={handleSearch}
            />
          </Col>
          <Col>
            <Checkbox checked={isShowCompare} onChange={onChangeColControls}>
              {COMPARE_TYPE_PER_NAME[compareType]}
            </Checkbox>
          </Col>
          <Col>
            <Checkbox.Group
              value={chiControls}
              style={{ width: "100%" }}
              onChange={handleOnChange}
              options={ExOptions}
            ></Checkbox.Group>
          </Col>
          <Col style={{ flex: 1, textAlign: "right" }}>
            <DownloadBtn
              handleDownload={handleDownload}
              moduleCode={moduleCode}
              chartTableCode={chartTableCode}
            />
          </Col>
        </Row>
      ) : undefined}
      <Row
        id="table-container"
        style={{ marginTop: 16, flexGrow: 1, overflow: "hidden" }}
      >
        <Table
          showSorterTooltip={false}
          defaultExpandAllRows={true}
          rowClassName={(record: any) => (record.isChild ? "is-child-row" : "")}
          expandIconColumnIndex={-1}
          tableLayout="fixed"
          indentSize={0}
          dataSource={filterData}
          columns={columns}
          scroll={{ x: width, y: tableHeight }}
          pagination={{ defaultPageSize: 20 }}
          // defaultExpandedRowKeys={filterData.map((data: any) => data.key)}
          expandedRowKeys={filterData.map((data: any) => data.key)}
          size={size}
        />
      </Row>
    </>
  );
};

export default CustomTable;
