import React, { ReactElement, useEffect, useState } from "react";
import { Alert, Card, Col, Row } from "antd";
import { Area } from "Interface";
import useGlobal from "Store";
import Page from "Layout/Page";
import UpdateTime from "Components/UpdateTime";
import SearchAreaOrAirport from "Components/SearchAreaOrAirport";
import TouristTable from "./Components/TouristTable";
import TouristCharts from "./Components/TouristCharts";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { DEFAULT_AREA } from "Constants";
import { TouristQueryExt } from "./TouristInterface";
import { Index } from "./TouristConstant";
import AirlinesSelectArea from "./Components/AirlinesSelectArea";

const defaultAirlinesQueryExt: TouristQueryExt = {
  filterType: 0,
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
  flightNO: [],
  indexes: [Index.Seat],
};

const TouristSource = (): ReactElement => {
  // 机场版搜索area
  const [area, setArea] = useState<Area>(DEFAULT_AREA);
  // 航司版query参数
  const [airlinesQueryExt, setAirlinesQueryExt] = useState<TouristQueryExt>(
    defaultAirlinesQueryExt
  );
  const [globalState] = useGlobal();
  const { systemType, queryCondition, airlinesQueryCondition } = globalState;
  const { departure, arrive } = queryCondition;
  const isAirport = isAirportMode(systemType);
  useEffect(() => {
    // 修改flightClass清空page页面参数
    setArea(DEFAULT_AREA);
    setAirlinesQueryExt(defaultAirlinesQueryExt);
  }, [queryCondition.flightClass, airlinesQueryCondition.flightClass]);

  useEffect(() => {
    if (!departure && !arrive) {
      setArea(DEFAULT_AREA);
    }
  }, [departure, arrive]);

  const subText = (
    <>
      {getSharkText("config_page_not_consider_long_segments")}
      <br />
      {getSharkText("config_page_predict_passenger_load_factor")}
      <br />
      {getSharkText("config_page_predict_passenger_volume")}
    </>
  );

  const alertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
      text={subText}
    />
  );

  return (
    <Page defaultDateMode="fourteenDays" isShowCompareType={false}>
      <div className="content-white">
        <Alert message={alertMessage} banner type="info" />
        {isAirport ? (
          <SearchAreaOrAirport setArea={setArea} useDomesticArea />
        ) : (
          <AirlinesSelectArea
            value={airlinesQueryExt}
            onChange={setAirlinesQueryExt}
          />
        )}
        <Row style={{ marginTop: 16, flexFlow: "column" }}>
          <Col>
            <Card>
              <TouristCharts
                queryUrl={QUERY_URL[systemType].lineCharts}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].lineCharts}
                area={area}
                airlinesQueryExt={airlinesQueryExt}
              />
            </Card>
          </Col>
          <Col style={{ marginTop: 16 }}>
            <Card>
              <TouristTable
                queryUrl={QUERY_URL[systemType].table}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].table}
                area={area}
                airlinesQueryExt={airlinesQueryExt}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </Page>
  );
};

export default TouristSource;
