import React from "react";
import chunqiu from "assets/images/chunqiu.png";
import shenzhen from "assets/images/shenzhen.png";
import hk from "assets/images/hk.png";
import xinjiang from "assets/images/xinjiang.png";
import yunnan from "assets/images/yunnan.png";
import "./index.scss";

const Partner: React.FC = () => {
  return (
    <div className="partner">
      <h2 className="title">合作伙伴</h2>
      <div className="partner-list">
        <img src={chunqiu} alt="春秋航空" />
        &nbsp;
        <img src={shenzhen} alt="深圳航空" />
        &nbsp;
        <img src={hk} alt="香港国际航空" />
        &nbsp;
        <img src={xinjiang} alt="新疆机场集团公司" />
        &nbsp;
        <img src={yunnan} alt="云南机场集团公司" />
      </div>
    </div>
  );
};

export default Partner;
