import React, { ReactElement, useEffect, useState } from "react";
import SearchArea from "./Components/SearchArea";
import { Alert } from "antd";
import SearchAreaOrAirport from "Components/SearchAreaOrAirport";
import PriceCalendar from "./Components/PriceCalendar";
import { Area, FlightClass, QueryCondition, SystemType } from "Interface";
import { defaultArea } from "../UserProfile/common";
import useGlobalState from "Store";
import useRefFunc from "Utils/useRefFunc";
import { AirportRouteParam } from "./interface";
import { getServer } from "Service/server";

/**  Component description */
const CancellationPrediction = (): ReactElement => {
  const [globalState, actions] = useGlobalState();
  const { departure, arrive, originalAirport, airport, startDate } =
    globalState.queryCondition;
  const [queryExt, setQueryExt] = useState<AirportRouteParam | null>(null);
  const [query, setQuery] = useState<QueryCondition | null>(null);
  const [initialed, setInitialed] = useState(false);
  const changeArea = useRefFunc((area: Area) => {
    setQueryExt((s) => ({
      departArea: originalAirport,
      arriveArea: area,
      airport,
      cancelRateStart: s?.cancelRateStart ?? null,
      cancelRateEnd: s?.cancelRateEnd ?? null,
    }));
    setQuery(globalState.queryCondition);
  });

  const changeRange = useRefFunc((v: [number | null, number | null]) => {
    setQueryExt({
      departArea: originalAirport,
      arriveArea: defaultArea,
      airport,
      cancelRateStart: v[0],
      cancelRateEnd: v[1],
    });
    setQuery(globalState.queryCondition);
  });

  useEffect(() => {
    if (!initialed && airport && startDate) {
      setQueryExt((s) => ({
        departArea: originalAirport,
        arriveArea: defaultArea,
        airport,
        cancelRateStart: s?.cancelRateStart ?? null,
        cancelRateEnd: s?.cancelRateEnd ?? null,
      }));
      setQuery(globalState.queryCondition);
      setInitialed(true);
    }
  }, [
    airport,
    globalState.queryCondition,
    initialed,
    originalAirport,
    startDate,
  ]);

  return (
    <div>
      <Alert
        message="双击数据所在行查看潜力中转补贴航线明细"
        banner
        type="info"
      />
      <div
        className="content-white"
        style={{ borderBottom: "1px solid var(--filter-bg-border)" }}
      >
        <SearchArea onChangeRange={changeRange} />
      </div>
      <div className="content-white">
        <div className="od-search" style={{ display: "flex" }}>
          <SearchAreaOrAirport
            setArea={changeArea}
            submitControl={true}
            server={getServer(SystemType.airport)}
            url="searchAirportArea"
            moduleCode="dashboard_airport"
            flightClass={FlightClass.Foreign}
          />
        </div>
        {queryExt && query ? (
          <div className="table">
            <PriceCalendar queryExt={queryExt} query={query} isDemo={false} />
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
export default CancellationPrediction;
