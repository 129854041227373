import React, { useEffect, useState } from "react";

const BackToTop: React.FC = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (window.pageYOffset > 600) {
        setShowScroll(true);
      } else if (window.pageYOffset <= 600) {
        setShowScroll(false);
      }
    };
    window.addEventListener("scroll", checkScrollTop);
    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, []);

  const scrollTop = (e: React.MouseEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {showScroll ? (
        <div onClick={scrollTop} className="side-btn back-to-top">
          <span>返回顶部</span>
        </div>
      ) : undefined}
    </>
  );
};

export default BackToTop;
