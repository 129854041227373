import { Button, Cascader, Form, Input, Modal, Radio } from "antd";
import React, { ReactElement, useEffect, useState } from "react";
import { district } from "Page/Official/Salon/data";
import successEcho from "assets/images/salonSubmitSuccess.png";
import styles from "./index.module.scss";
import VerifyCode from "Components/Official/VerifyCode";
import { ERROR_CODE } from "Constants";
import moment from "moment";
import { showToast } from "Utils/Toast";

const HOST = window.location.host;

let SERVER = "";
switch (HOST) {
  // FAT
  case "flightai-site.fat4151.qa.nt.ctripcorp.com/":
    SERVER = "//gateway.m.fws.qa.nt.ctripcorp.com/restapi/soa2/22409/salonReg";
    break;
  // PRO
  case "www.flightai.aero":
    SERVER = "//m.ctrip.com/restapi/soa2/22409/salonReg";
    break;
  default:
    SERVER = "//gateway.m.fws.qa.nt.ctripcorp.com/restapi/soa2/22409/salonReg";
  // SERVER = '//10.5.13.202:8080/api/'
  // SERVER = '//m.ctrip.com/restapi/soa2/19595/';
}

const { TextArea } = Input;

const toast = (msg: string) => {
  if (msg) {
    showToast(msg, 3000);
  }
};

/**
 * 沙龙报名页面手机版
 */
const SalonH5 = (): ReactElement => {
  const [gender, setGender] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [, setSecond] = useState<number>(10);
  const [visible, setVisible] = useState<boolean>(false);
  const [verifyCode, setVerifyCode] = useState<any>({});

  useEffect(() => {
    let interval: any;
    if (submitted) {
      interval = setInterval(() => {
        setSecond((preSecond) => {
          if (preSecond <= 1) {
            setSubmitted(false);
            clearInterval(interval);
            // 重置秒数
            return 60;
          } else {
            return preSecond - 1;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [submitted]);

  const abortCtrl = new AbortController();
  const signal = abortCtrl.signal;
  const fetchData = async (values: any) => {
    try {
      setLoading(true);
      const result = await fetch(SERVER, {
        signal,
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          head: {
            requestTime: new Date(),
            moduleCode: "salon_manage",
            chartTableCode: "TX0301",
          },
          data: values,
          validCode: verifyCode,
        }),
      });
      const res = await result.json();
      if (res.flag) {
        toast("提交成功");
        setSubmitted(true);
        setVisible(true);
      } else {
        const code: keyof typeof ERROR_CODE =
          res?.ResponseStatus?.Errors?.length &&
          res.ResponseStatus.Errors[0].ErrorCode;
        if (code && ERROR_CODE[code]) {
          toast(ERROR_CODE[code]);
        } else {
          toast("提交失败");
        }
      }
    } catch (error: any) {
      if (error.name === "AbortError") {
        return;
      }
      toast("提交失败");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      // abortCtrl.abort();
    };
  }, []);

  const handleSubmit = (values: any) => {
    if (!loading && !submitted && values.cascadeCity.length > 0) {
      // 检测是否有验证码
      if (JSON.stringify(verifyCode) === "{}") {
        toast("请拖动滑块输入验证码");
        return;
      }
      values.province = values.cascadeCity[0];
      // 如果cascadeCity的长度只有一, 说明是北京上海这样的直辖市
      if (values.cascadeCity.length === 1) {
        values.city = values.cascadeCity[0];
      } else {
        values.city = values.cascadeCity[1];
      }
      delete values.cascadeCity;
      values.mobilePrefix = "+86";
      values.regYear = moment().year();
      values.content = values.content || "";
      console.log(values);
      fetchData(values);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    if (errorInfo?.errorFields?.length) {
      toast(errorInfo.errorFields[0].errors[0]);
    } else {
      toast("填写失败");
    }
  };

  return (
    <div className={styles.container}>
      <div className={`top-banner ${styles["salon-banner"]}`}>
        <div className={styles["base-con"]}>
          <div className={styles["banner-con"]}>
            <div className="logo logo-reverse"></div>
            <div className={styles.bannerDesc}>
              2021携程航空数据应用与研究论坛
            </div>
            <div className={styles.bannerTitle}>报名信息填写</div>
            <div className={styles.bannerHref}>
              某月某日 中国 上海
              {/* <a href="#contactUs"> */}
              {/* <Button fill>某月某日 中国 上海</Button> */}
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.applyContainer}>
        <div className={styles.formTitle}>
          <div className={styles.formTitleText}>报名信息填写</div>
        </div>
        <Form
          name="basic"
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 19 }}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="单位名称"
            name="unitName"
            rules={[{ required: true, message: "请输入您的单位/公司名称" }]}
          >
            <Input placeholder="请输入您的单位/公司名称" />
          </Form.Item>

          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: "请输入您的姓名" }]}
          >
            <Input placeholder="请输入您的姓名" />
          </Form.Item>

          <Form.Item
            label="职务"
            name="job"
            rules={[{ required: true, message: "请输入您的职务" }]}
          >
            <Input placeholder="请输入您的职务" />
          </Form.Item>

          <Form.Item label="性别" name="sex">
            <Radio.Group
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <Radio value={0}>男</Radio>
              <Radio value={1}>女</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="手机号"
            name="mobile"
            rules={[
              { required: true, message: "请输入您的手机号" },
              {
                pattern: new RegExp(/^1[0-9]{10}$/),
                message: "请输入正确的手机号码",
              },
            ]}
          >
            <Input placeholder="请输入您的手机号" />
          </Form.Item>

          <Form.Item
            label="邮箱"
            name="mail"
            rules={[
              { required: true, message: "请输入您的邮箱" },
              {
                pattern: new RegExp(
                  /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
                ),
                message: "请输入正确的邮箱地址",
              },
            ]}
          >
            <Input placeholder="请输入您的邮箱" />
          </Form.Item>

          <Form.Item
            label="现居城市"
            name="cascadeCity"
            rules={[{ required: true, message: "请选择省市" }]}
          >
            <Cascader
              options={district}
              placeholder="请选择省市"
              expandTrigger="hover"
            />
          </Form.Item>

          <Form.Item label="内容留言" name="content">
            <TextArea placeholder="您最想在本次论坛上听到什么主题的内容：" />
          </Form.Item>

          <Form.Item wrapperCol={{}}>
            <VerifyCode setVerifyInfo={setVerifyCode} />
          </Form.Item>

          <div className={styles.applyHelp}>
            <div className={styles.applyHelpTitle}>
              <div className={styles.applyHelpTitleHR}></div>
              <span className={styles.applyHelpTitleText}>注册须知</span>
            </div>
            <div className={styles.applySection}>
              <span className={styles.title}>一、注册流程：</span>
              <p>
                1. 请准确填写各项注册信息 (*内容必填)； 2.
                填写报名信息不代表报名成功，会务组会尽快向您的注册邮箱发送确认函，收到确认函代表报名成功；3.
                本次沙龙不收取任何费用，感谢您的关注与支持。
              </p>
            </div>
            <div className={styles.applySection}>
              <span className={styles.title}>二、提供服务：</span>
              <p>
                1. 参加沙龙； 2. 沙龙全部会议资料 (会议手册等)； 3.
                某月某日午餐和晚餐。
              </p>
            </div>
            <div className={styles.applySection}>
              <span className={styles.title}>三、报到须知：</span>
              <p>1. 报到时间：某月某日上午9时；2. 报道地点：xxx</p>
            </div>
            <div className={styles.applySection}>
              <span className={styles.title}>2021携程数据论坛联系人</span>
              <p>flightai@ctrip.com</p>
              <p>FlightAi航空智能大数据</p>
            </div>
          </div>

          <Form.Item
            style={{
              textAlign: "center",
              background: "#191D40",
              margin: "-20px -20px -10px",
              padding: 30,
            }}
            wrapperCol={{ span: 24 }}
          >
            <Button
              style={{ alignSelf: "center", width: "60%" }}
              type="primary"
              htmlType="submit"
            >
              {submitted ? "已报名" : "确认报名"}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        closable={false}
        style={{ textAlign: "center" }}
        bodyStyle={{ background: "transparent" }}
        className={styles.modal}
      >
        <img src={successEcho} alt="报名成功" />
      </Modal>
    </div>
  );
};
export default SalonH5;
