import { Spin } from "antd";
import NormalTable from "Components/Table/NormalTable";
import { IDownloadHeader } from "Interface";
import React, { ReactElement, useCallback, useEffect } from "react";
import { getServer } from "Service/server";
import { useFetch } from "Utils";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../TravixFetchCode";

/**
 * Component description
 *
 */

const tableProps = { scroll: { x: "max-content" } };

interface IProps {
  panelType: "overview" | "salesData";
  ext?: Record<string, unknown>;
  columns: IDownloadHeader[];
}

const SalesTable = (props: IProps): ReactElement => {
  const { panelType, ext, columns } = props;

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(4),
    url: QUERY_URL.barline,
    defaultValue: null,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: CHART_TABLE_CODE[panelType].Table,
    },
    query: null,
    ext,
    lazey: true,
  });
  const refetch = useCallback(() => {
    doFetch({
      ext: { ...ext },
    });
  }, [doFetch, ext]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <Spin spinning={isLoading}>
      <NormalTable
        columns={columns}
        data={data}
        searchKeys={["departureCountry"]}
        tableProps={tableProps}
        en
        moduleCode={MODULE_CODE}
        chartTableCode={CHART_TABLE_CODE[panelType].Table}
      />
    </Spin>
  );
};
export default SalesTable;
