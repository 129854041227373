import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import {
  SmallCabinInfo,
  SmallCabinOptType,
} from "@ctrip/flt-bi-flightai-airlines";
import { Button, Empty, message, Modal, Spin, Table } from "antd";
import Refetch from "Components/Refetch";
import { IDownloadHeader, SystemType } from "Interface";
import { cloneDeep, difference, max, uniq, uniqBy } from "lodash";
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import { arrayUpsert, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import { MODULE_CODE, QUERY_URL } from "../../fetchCode";
import { SmallCabinInfoEx } from "../../FlightManageInterface";
import "./SubCabin.scss";
import { SubCabinRecord } from "./interface";

const getOptList = (
  tree: SmallCabinInfoEx[],
  allSmallCabins: SmallCabinInfo[]
): SmallCabinOptType[] => {
  const changedList: SmallCabinInfoEx[] = [];
  tree.forEach((t) => {
    if (t.itemList) {
      t.itemList.forEach((i) => {
        if (i.feOpt) {
          changedList.push(i);
        }
      });
    }
  });
  // #region 获取所有同组的操作
  // 检查变化舱位中有没有冲突的, 比如说同一组内有一个舱位是开启的, 一个是关闭的, 则关闭的操作无效
  const conflictList = changedList.reduce((t: SmallCabinInfoEx[], c) => {
    if (t.find((t1) => t1.smallCabinType === c.smallCabinType)) {
      return t;
    } else {
      t.push(c);
      return t;
    }
  }, []);
  let rst: SmallCabinOptType[] = [];
  conflictList.forEach((c) => {
    const groups = allSmallCabins.filter(
      (a) =>
        a.smallCabinType === c.smallCabinType ||
        (a.groupNumber != null && a.groupNumber === c.groupNumber)
    );
    groups.forEach((g) => {
      if (g.isOpen === c.isOpen && c.feOpt) {
        rst = arrayUpsert(
          rst,
          {
            opt: c.feOpt,
            subCabinType: g.subCabinType,
            smallCabinType: g.smallCabinType,
            status: null,
          },
          (r) => r.smallCabinType === g.smallCabinType
        );
      }
    });
  });
  // #endregion
  return rst;
};

const SUB_CABIN_WIDTH = 50;

export interface ISubCabinProps {
  onCancel: () => void;
  row: SubCabinRecord;
  seg: string;
  roCacheKey: string | null;
  isShow: boolean;
  adviseId: string | null;
}

/**
 * 小舱调整面板
 */
const SubCabin = (props: ISubCabinProps): ReactElement => {
  const { onCancel, row, seg, roCacheKey, isShow, adviseId } = props;
  const sType = SystemType.airlines;
  const [tableData, setTableData] = useState<SmallCabinInfoEx[]>([]);
  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string[]>([]);
  const [{ data, isLoading, res, error }, doFetch] = useFetch<SmallCabinInfo[]>(
    {
      server: getServer(sType),
      url: QUERY_URL.querySubCabin,
      head: {
        moduleCode: MODULE_CODE,
        chartTableCode: "non",
      },
      query: null,
      ext: { row, seg, roCacheKey },
      lazey: true,
    }
  );

  const [{ isLoading: isSubmitLoading }, doSubmit] = useFetch({
    server: getServer(sType),
    url: QUERY_URL.setSubCabin,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    query: null,
    ext: {},
    lazey: true,
    onSuccess: (res) => {
      if (!res.optList) {
        Modal.error({
          title: getSharkText("config_page_get_operation_result_failed"),
        });
        return;
      }
      if (res.ResponseStatus.Errors?.length) {
        const errorObj = res.ResponseStatus.Errors[0];
        if (Number(errorObj.ErrorCode) !== 200) {
          Modal.error({
            title: `${getSharkText("config_page_PaAiFlCoBlSu_error_code")}:${
              errorObj.ErrorCode
            }, ${getSharkText("config_page_PaAiFlCoBlSu_error_message")}: ${
              errorObj.Message
            }`,
          });
          return;
        }
      }
      const rst = res.optList.map((opt: SmallCabinOptType) => {
        const icon =
          opt.status === "1" ? (
            <CheckCircleFilled style={{ color: "green" }} />
          ) : (
            <CloseCircleFilled style={{ color: "red" }} />
          );
        return (
          <div key={opt.smallCabinType}>
            {icon}
            {`${opt.smallCabinType}${getSharkText("config_page_operation")}${
              opt.status === "1"
                ? getSharkText("config_page_success")
                : getSharkText("config_page_fail")
            }`}
          </div>
        );
      });
      Modal.info({
        title: getSharkText("config_page_operation_result"),
        content: rst,
      });
      doFetch({
        ext: { row, seg, roCacheKey },
      });
    },
    onError: () => {
      Modal.error({
        title: getSharkText("config_page_operation_failed"),
      });
    },
  });

  useEffect(() => {
    if (!isShow) {
      return;
    }
    doFetch({
      ext: { row, seg, roCacheKey },
    });
  }, [roCacheKey, doFetch, isShow, row, seg]);

  // 将远程获取的数据打包, 并计算一些前端需要使用的数据
  useEffect(() => {
    if (!data || !res) {
      return;
    }
    // 1. 获取子舱列表
    const cabins = uniqBy(
      cloneDeep(data),
      (d) => d.subCabinType
    ) as SmallCabinInfoEx[];
    cabins.forEach((c) => {
      c.smallCabinType = null;
      c.farePrice = null;
    });
    // 2. 计算子舱下面的小舱列表, 同时生成selectedKeys, 根据建议生成feOpt
    data.forEach((d) => {
      if (!d.smallCabinType) {
        console.error("d.smallCabinType is null", d);
        return;
      }
      const cabin = cabins.find((c) => c.subCabinType === d.subCabinType);
      if (!cabin) {
        console.log("error");
        return;
      }
      if (!cabin.itemList) {
        cabin.itemList = [];
      }
      cabin.itemList.push({
        ...d,
        feOpt: (d.suggestOpt as "+" | "-") || undefined,
      });
      if (!cabin.originalSelectedKeys) {
        cabin.originalSelectedKeys = [];
      }
      if (d.isOpen) {
        cabin.originalSelectedKeys.push(d.smallCabinType);
      }
      if (!cabin.newSelectedKeys) {
        cabin.newSelectedKeys = [];
      }
      // 如果有建议操作, 则将建议操作置位默认修改项
      if ((d.isOpen && d.suggestOpt !== "-") || d.suggestOpt === "+") {
        cabin.newSelectedKeys.push(d.smallCabinType);
      }
    });
    // 3. 计算子舱的最后同步时间和最后修改时间, 生效小舱;
    cabins.forEach((c) => {
      if (c.itemList) {
        const lastModify = max(c.itemList.map((i) => i.lastModifyTime));
        if (lastModify) {
          c.lastModifyTime = lastModify;
        }
        const lastSync = max(c.itemList.map((i) => i.lastSyncTime));
        if (lastSync) {
          c.lastSyncTime = lastSync;
        }
        const effective = c.itemList.find((i) => i.isEffective);
        if (effective?.smallCabinType) {
          c.feEffective = effective.smallCabinType;
        }
      }
    });
    // 4. 计算默认展开列表, 有默认修改项或者有生效小舱的默认展开
    const tmpDefaultExpandedKeys: string[] = [];
    if (res.lowestEffiSubCabin) {
      const idx = cabins.findIndex(
        (c) => c.subCabinType === res.lowestEffiSubCabin
      );
      if (idx > -1) {
        const preCabin = idx > 0 ? cabins[idx - 1] : null;
        const curCabin = cabins[idx].subCabinType;
        const nextCabin = idx < cabins.length - 1 ? cabins[idx + 1] : null;
        if (preCabin?.subCabinType) {
          tmpDefaultExpandedKeys.push(preCabin.subCabinType);
        }
        if (curCabin) {
          tmpDefaultExpandedKeys.push(curCabin);
        }
        if (nextCabin?.subCabinType) {
          tmpDefaultExpandedKeys.push(nextCabin.subCabinType);
        }
      }
    }
    setDefaultExpandedKeys(tmpDefaultExpandedKeys);
    setTableData(cabins);
  }, [data, res]);
  const columns: Array<IDownloadHeader<SmallCabinInfoEx>> = useMemo(
    () => [
      {
        title: getSharkText("config_page_cabin"),
        dataIndex: "subCabinType",
        width: SUB_CABIN_WIDTH,
      },
      {
        title: getSharkText("key.fare_price"),
        dataIndex: "farePrice",
        width: 50,
      },
      {
        title: getSharkText("config_page_small_cabin"),
        dataIndex: "smallCabinType",
        width: 50,
      },
      {
        title: getSharkText("config_page_last_modify_time"),
        width: 130,
        dataIndex: "lastModifyTime",
      },
      {
        title: getSharkText("config_page_last_sync_time"),
        width: 130,
        dataIndex: "lastSyncTime",
      },
      {
        title: getSharkText("config_page_current_effective_small_cabin"),
        width: 120,
        dataIndex: "feEffective",
      },
      {
        title: getSharkText("config_page_is_modified"),
        dataIndex: "feOpt",
        render: (v: "+" | "-" | undefined) =>
          v ? getSharkText("config_page_not_saved_modification") : "",
      },
    ],
    []
  );

  const renderCheckbox = useCallback(
    (c: boolean, r: SmallCabinInfoEx, idx: number, o: ReactNode) => {
      // 需要显示特别的选中修改标识
      const className = c === r.isOpen ? "" : c ? "will-check" : "will-uncheck";
      return (
        <span
          className={className}
          style={{
            display: "inline-block",
            width: SUB_CABIN_WIDTH - 16,
            textAlign: "left",
          }}
        >
          {o}
        </span>
      );
    },
    []
  );

  const subRowRender = useCallback(
    (record: SmallCabinInfoEx): ReactNode => {
      const handleChange = (
        v: React.Key[],
        v1?: any,
        v2?: any,
        v3?: any,
        v4?: any
      ) => {
        console.log("keys: ", v, v1, v2, v3, v4);
        // #region 操作一组的所有小舱
        const add = difference(v, record.newSelectedKeys || []);
        const remove = difference(record.newSelectedKeys || [], v);
        const changedSmallCabin = add.length ? add[0] : remove[0];
        const isAdd =
          add.length > 0 ? true : remove.length > 0 ? false : undefined;
        const changedObj = data.find(
          (d) => d.smallCabinType === changedSmallCabin
        );
        if (changedSmallCabin && isAdd !== undefined && changedObj) {
          const groups = data.filter(
            (d) =>
              (d.groupNumber != null &&
                d.groupNumber === changedObj.groupNumber) ||
              d.smallCabinType === changedSmallCabin
          );
          tableData.forEach((t) => {
            const smallCabins = groups
              .filter((g) => g.subCabinType === t.subCabinType)
              .map((s) => s.smallCabinType) as string[];
            const keys = isAdd
              ? uniq((t.newSelectedKeys || []).concat(smallCabins))
              : difference(t.newSelectedKeys || [], smallCabins);
            t.newSelectedKeys = keys;
          });
        }
        // #endregion
        // record.newSelectedKeys = v as string[];
        tableData.forEach((r) => {
          (r.itemList || []).forEach((item) => {
            if (item.smallCabinType) {
              if (
                r.originalSelectedKeys?.includes(item.smallCabinType) &&
                !r.newSelectedKeys?.includes(item.smallCabinType)
              ) {
                item.feOpt = "-";
                return;
              }
              if (
                !r.originalSelectedKeys?.includes(item.smallCabinType) &&
                r.newSelectedKeys?.includes(item.smallCabinType)
              ) {
                item.feOpt = "+";
                return;
              }
              item.feOpt = undefined;
            }
          });
        });
        const newTableData = cloneDeep(tableData);
        // 需要触发组件更新
        setTableData(newTableData);
      };
      return (
        <div style={{ position: "relative", padding: "12px 0 5px" }}>
          <Table
            size="small"
            columns={columns.slice(1)}
            dataSource={record.itemList}
            showHeader={false}
            pagination={false}
            rowKey="smallCabinType"
            rowClassName={(r) => (r.feOpt ? "changed" : "")}
            rowSelection={{
              selectedRowKeys: record.newSelectedKeys,
              onChange: handleChange,
              renderCell: renderCheckbox,
            }}
          />
        </div>
      );
    },
    [columns, renderCheckbox, tableData]
  );
  const handleSave = useRefFunc(() => {
    console.log("save: ", tableData);
    const optList = getOptList(tableData, data);
    if (!optList.length) {
      message.warn("no small cabin changed");
      return;
    }
    const content = optList.map((opt) => {
      return (
        <div key={opt.smallCabinType}>
          <span>{opt.smallCabinType}：</span>
          <span style={{ marginLeft: 10 }}>
            {opt.opt === "+"
              ? getSharkText("config_page_open")
              : opt.opt === "-"
              ? getSharkText("key.close.button")
              : ""}
          </span>
        </div>
      );
    });
    Modal.confirm({
      title: getSharkText("config_page_upcoming_operation"),
      content,
      okText: getSharkText("config_page_save"),
      onOk: () => {
        doSubmit({
          ext: {
            row,
            seg,
            optList,
            cacheKey: res?.cacheKey || "",
            adviseId,
            originCmd: res?.suggestCMD,
          },
        });
      },
    });
  });

  if (isLoading) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  if (error) {
    <Refetch
      refetch={() =>
        doFetch({
          ext: { row, seg, roCacheKey },
        })
      }
    />;
  }

  if (!tableData.length) {
    return <Empty />;
  }

  return (
    <div>
      <Table
        size="small"
        columns={columns}
        dataSource={tableData}
        rowKey="subCabinType"
        expandable={{
          expandedRowRender: subRowRender,
          defaultExpandedRowKeys: defaultExpandedKeys,
        }}
        pagination={false}
      />
      <div
        style={{ textAlign: "right", padding: "5px 5px 5px 0", marginTop: 5 }}
      >
        <Spin spinning={isSubmitLoading}>
          <Button size="small" onClick={onCancel}>
            {getSharkText("key.cancel.button")}
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={handleSave}
            style={{ marginLeft: 5 }}
          >
            {getSharkText("config_page_preview")}
          </Button>
        </Spin>
      </div>
    </div>
  );
};
export default SubCabin;
