import React, {
  useState,
  useEffect,
  ReactNode,
  PropsWithChildren,
  ReactElement,
} from "react";
import { useLocation, Link } from "react-router-dom";
import Toolbar from "Components/Toolbar";
import useGlobal from "Store";
import { getModule, getModuleNameFromPath, isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { Result, Button, Empty } from "antd";
import { CompareContent, PAGE_ID } from "Constants";
import { ECompareTypeV2, IDateMode, IModuleConfig } from "Interface";
import { isEmpty } from "lodash";
import { useFetch } from "Utils/useFetch";
import useI18Next from "Utils/useI18Next";
import ErrorBoundary from "Components/ErrorBoundary";

interface PageProps {
  children?: ReactNode;
  defaultDateMode: IDateMode;
  shouldDisableDate?: boolean;
  useAirportGroup?: boolean;
  airportGroupMultiple?: boolean;
  airlinesCompareType?: ECompareTypeV2;
  isShowCompareType?: boolean;
  isShowInOut?: boolean;
  isShowAirlineAirport?: boolean;
  /**
   * 部分页面过滤框完全由自己构造, 不需要toolBar
   */
  needToolBar?: boolean;
  /**
   * 是否需要进出港选择判断等, 如果页面自己管理则设置为false, 默认为true
   */
  needGlobalConditionCheck?: boolean;
  topMessage?: ReactNode | string;
  extendTools?: ReactNode;
  airportCompareTypes?: Partial<Record<ECompareTypeV2, ECompareTypeV2>>;
  airlinesCompareTypes?: Partial<Record<ECompareTypeV2, ECompareTypeV2>>;
  compareContent?: Partial<Record<ECompareTypeV2, CompareContent>>;
  /**
   * 定制FlightClass的名称
   */
  flightClassName?: string[];
  /**
   * 国际版页面
   */
  intl?: boolean;
}

/**
 * 该页面绑定了埋点功能, 需要埋点的页面须引用这个组件
 */
const Page = ({
  children,
  defaultDateMode,
  shouldDisableDate,
  useAirportGroup,
  airportGroupMultiple = false,
  airlinesCompareType,
  isShowCompareType,
  isShowInOut,
  isShowAirlineAirport,
  needToolBar = true,
  topMessage,
  needGlobalConditionCheck = true,
  extendTools,
  airportCompareTypes,
  airlinesCompareTypes,
  compareContent,
  flightClassName,
  intl,
}: PropsWithChildren<PageProps>): ReactElement => {
  const location = useLocation();
  const [globalState, actions] = useGlobal();
  const { setModuleConfig } = actions;
  const { userInfo, systemType, queryCondition, airlinesQueryCondition } =
    globalState;
  const { pathname } = location;
  // 不需要toolbar的页面, loading由页面自行控制, toolbar不再控制
  const [loading, setLoading] = useState(!!needToolBar);
  const isAirport = isAirportMode(systemType);
  const moduleName = getModuleNameFromPath(pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const flightClass = isAirport
    ? queryCondition.flightClass
    : airlinesQueryCondition.flightClass;
  const isTouristSource = pathname === "/tourist_source";
  const isPriceMonitor = pathname === "/price_monitor";
  const isDomestic = flightClass === 1;
  const isIntegrated = flightClass === 0;
  const t = useI18Next();
  const pageId =
    PAGE_ID[
      location.pathname === "/"
        ? "dashboard"
        : location.pathname.replace("/", "")
    ];

  const [{ res }, doFetch] = useFetch({
    url: "getModuleConfig",
    head: {
      moduleCode: module ? module.moduleCode : "common",
      chartTableCode: null,
    },
    lazey: true,
  });

  useEffect(() => {
    setModuleConfig({ loading: true });
    doFetch({
      head: {
        moduleCode: module ? module.moduleCode : "common",
      },
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(res)) {
      const tmpModuleConfig: Partial<IModuleConfig> = { loading: false };
      // 开关配置项 允许查询所有机场
      if (res.moduleConfig) {
        const config = res.moduleConfig;
        setModuleConfig({
          ...config,
          ...tmpModuleConfig,
          allowAccessAllAirports: config["allow.access.allAirports"] === "true",
          updateTime: config.updateTime,
        });
      } else {
        setModuleConfig(tmpModuleConfig);
      }
    }
  }, [res]);

  if (!module || module.moduleStatus === 3) {
    return (
      <Result
        status="403"
        title="403"
        subTitle={t("common.noPermission")}
        extra={
          <Button type="primary">
            <Link to="/">{t("common.backToHome")}</Link>
          </Button>
        }
      />
    );
  }

  const missRequiredParameter = isAirport
    ? !queryCondition.departure && !queryCondition.arrive
    : false;

  const getDescriptionText = () => {
    if (missRequiredParameter) {
      return getSharkText("");
    } else if (isTouristSource && !isDomestic) {
      return getSharkText("key.crossboarder_not_supported.hint");
    } else if (isPriceMonitor && isIntegrated) {
      return getSharkText("key.switch_domestic_crossboarder.hint");
    }
  };

  return (
    <ErrorBoundary pageId={pageId}>
      {needToolBar ? (
        <Toolbar
          module={module}
          defaultDateMode={defaultDateMode}
          shouldDisableDate={shouldDisableDate}
          useAirportGroup={useAirportGroup}
          airportGroupMultiple={airportGroupMultiple}
          setLoading={setLoading}
          airlinesCompareType={airlinesCompareType}
          isShowCompareType={isShowCompareType}
          isShowInOut={isShowInOut}
          isShowAirlineAirport={isShowAirlineAirport}
          topMessage={topMessage}
          extendTools={extendTools}
          airportCompareTypes={airportCompareTypes}
          airlinesCompareTypes={airlinesCompareTypes}
          compareContent={compareContent}
          flightClassName={flightClassName}
          intl={intl}
        />
      ) : undefined}
      {loading ? undefined : needGlobalConditionCheck &&
        (missRequiredParameter ||
          (isTouristSource && !isDomestic) ||
          (isPriceMonitor && isIntegrated)) ? (
        <Empty
          style={{ marginTop: "50px" }}
          description={getDescriptionText()}
        />
      ) : (
        children
      )}
    </ErrorBoundary>
  );
};

export default Page;
