import React, { useState } from "react";
import { message, Modal, Button, Descriptions } from "antd";
import { UserList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import SelectUnauthorizedUser from "../SelectUnauthorizedUser";

interface IProps {
  unitId: number;
  visible: boolean;
  userList: UserList[];
  setVisible: (visible: boolean) => void;
  refetchUser: () => void;
}

interface CurUser {
  key: number;
  label: string;
}

const AddUserModal: React.FC<IProps> = (props: IProps) => {
  const { unitId, visible, setVisible, refetchUser, userList } = props;
  const [userIds, setUserIds] = useState<number[]>([]);
  const [curUsers, setCurUsers] = useState<CurUser[]>([]);
  const [{ isLoading }, doFetch] = useFetch({
    url: "addUserToUnit",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      userId: userIds,
      groupId: unitId,
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_PaAiRiCoAdIn_add_success"));
      refetchUser();
      setUserIds([]);
      setCurUsers([]);
    },
    onError: () => {
      message.error(getSharkText("config_page_add_failed"));
    },
  });

  const handleCancel = () => {
    setVisible(false);
  };

  const handleClick = () => {
    if (userIds) {
      doFetch({
        ext: {
          userId: userIds,
          groupId: unitId,
        },
      });
    } else {
      message.error(getSharkText("config_page_no_user_selected"));
    }
  };

  return (
    <Modal
      width={900}
      title={getSharkText("config_page_add_user_to_permission_group")}
      open={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          {getSharkText("key.close.button")}
        </Button>,
      ]}
    >
      <Descriptions bordered>
        <Descriptions.Item
          label={getSharkText("config_page_find_user")}
          span={3}
        >
          {visible ? (
            <SelectUnauthorizedUser
              curUsers={curUsers}
              setCurUsers={setCurUsers}
              setUserIds={setUserIds}
            />
          ) : undefined}
          <Button
            style={{ marginLeft: 16 }}
            type="primary"
            loading={isLoading}
            onClick={handleClick}
          >
            {getSharkText("key.add.button")}
          </Button>
        </Descriptions.Item>
        <Descriptions.Item
          label={getSharkText("config_page_added_users")}
          span={3}
        >
          {userList.map((item) => (
            <span key={item.user.id} style={{ marginRight: 10 }}>
              {item.user.mobile}
              {item.user.name}
            </span>
          ))}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default AddUserModal;
