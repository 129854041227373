import React, { useEffect, useReducer } from "react";
import { Spin } from "antd";
import { round, uniq } from "lodash";
import { UserProfileChart } from "@ctrip/flt-bi-flightai-airlines";
import { formatName, getTotalVal, ICompareChartConfig } from "../common";
import EchartsReactBase from "Components/EchartsReactBase";
import { getSharkText } from "Utils/i18nGlobal";

interface BarChartsProps {
  data: UserProfileChart[];
  compareData: UserProfileChart[];
  height?: number;
  loading: boolean;
}

const genDefaultVal = (name: string) => {
  return { name, value: 0, originVal: 0, originCnt: 0 };
};

const SimpleCompareBarCharts: React.FC<BarChartsProps> = (
  props: BarChartsProps
) => {
  const { data, compareData, height = 300, loading } = props;
  const [config, setConfig] = useReducer(
    (state: any, v: ICompareChartConfig) => {
      return v;
    },
    { series: [], yAxisData: [], compareAxisData: [] }
  );

  // #region 转换数据
  useEffect(() => {
    const tmp = [];
    const curDataCp = formatName(data);
    const comDataCp = formatName(compareData);
    const names = curDataCp.map((t) =>
      t.tagName === "unkown" || !t.tagName
        ? getSharkText("key.unknown.name")
        : t.tagName
    );
    const compareNames = comDataCp.map((t) =>
      t.tagName === "unkown" || !t.tagName
        ? getSharkText("key.unknown.name")
        : t.tagName
    );
    const tmpAxisData = uniq(names.concat(compareNames));

    const curTotalVal = getTotalVal(curDataCp);
    const comTotalVal = getTotalVal(comDataCp);
    const curSeriesData = tmpAxisData.map((a) => {
      const t = curDataCp.find((d) => d.tagName === a);
      if (!t) {
        return genDefaultVal(a);
      }
      const per = t.value === null ? null : t.value / curTotalVal;
      return {
        name:
          t.tagName === "unkown" || !t.tagName
            ? getSharkText("key.unknown.name")
            : t.tagName,
        value: per === null ? null : per * -1,
        originVal: per,
        originCnt: t.value,
      };
    });
    const compareSeriesData = tmpAxisData.map((a) => {
      const t = comDataCp.find((d) => d.tagName === a);
      if (!t) {
        return genDefaultVal(a);
      }
      const per = t.value === null ? null : t.value / comTotalVal;
      return {
        name:
          t.tagName === "unkown" || !t.tagName
            ? getSharkText("key.unknown.name")
            : t.tagName,
        value: per,
        originVal: per,
        originCnt: t.value,
      };
    });
    tmp.push({
      name: getSharkText("custom_profile.page_left.title"),
      stack: "all",
      type: "bar",
      data: curSeriesData,
      label: {
        show: true,
        align: "right",
        formatter: (d: any) => round(d.data.originVal * 100, 2) + "%",
      },
    });
    tmp.push({
      name: "右边",
      stack: "all",
      type: "bar",
      data: compareSeriesData,
      label: {
        show: true,
        align: "left",
        formatter: (d: any) => round(d.data.originVal * 100, 2) + "%",
      },
    });
    const comData = tmpAxisData.map((t) => {
      const cur = curSeriesData.find((s) => s.name === t);
      const com = compareSeriesData.find((s) => s.name === t);
      if (!cur?.originVal && !com?.originVal) {
        return "--";
      } else {
        const curV = cur?.originVal || 0;
        const comV = com?.originVal || 0;
        return `${t}_${curV - comV}`;
      }
    });
    setConfig({
      series: tmp,
      yAxisData: tmpAxisData,
      compareAxisData: comData,
    });
  }, [compareData, data]);
  // #endregion

  const option = {
    color: ["#177DFE", "#FC9B4F", "#01C5DB"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    grid: {
      show: true,
      top: 30,
      bottom: 20,
      left: 100,
      right: 150,
      backgroundColor: "#fff",
      borderWidth: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let dom = "";
        params.forEach((item: any, i: number) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const name = item.seriesName;
          const value = item.data.originVal;
          const valueStr =
            value === null
              ? "--"
              : `<span style="width: 50px;display:inline-block">${round(
                  value * 100,
                  2
                )}%</span>`;
          const val = `<span>${name}: ${valueStr}</span>`;
          if (i !== 0) {
            dom += "<br />";
          }
          dom += dot + val;
        });
        return dom;
      },
    },
    xAxis: {
      type: "value",
      show: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      max: (value: any) => {
        const { max, min } = value;
        const absMax = Math.max(Math.abs(max), Math.abs(min));
        return absMax * 1.2;
      },
      min: (value: any) => {
        const { max, min } = value;
        const absMax = Math.max(Math.abs(max), Math.abs(min));
        return min < 0 ? -absMax * 1.2 : min;
      },
    },
    yAxis: [
      {
        type: "category",
        data: config.yAxisData,
        splitNumber: 3,
        inverse: true,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      {
        name: "左边-右边",
        nameLocation: "start",
        type: "category",
        data: config.compareAxisData,
        splitNumber: 3,
        margin: -10,
        inverse: true,
        nameTextStyle: {
          align: "left",
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: (v: string) => {
            if (v.indexOf("_") > 0) {
              const arr = v.split("_");
              const name = arr[0];
              const val = arr.pop();
              const value =
                val !== undefined
                  ? `${round(parseFloat(val) * 100, 2)}%`
                  : "--";
              return `${name}: ${value}`;
            }
            return v;
          },
        },
      },
    ],
    series: config.series,
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default SimpleCompareBarCharts;
