import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { Cascader, Input, Select, Spin } from "antd";
import useGlobal from "Store";
import { Area, RoleType } from "Interface";
import { getModuleNameFromPath, getModuleStatus } from "Utils";
import _ from "lodash";
import { DefaultOptionType, SingleValueType } from "rc-cascader/lib/Cascader";
import { useLocation } from "react-router-dom";
import { useFetch } from "Utils/useFetch";
import { canSelectAirports } from "Utils/global";
import Refetch from "Components/Refetch";
import { getDefaultAirport, getPermissionItems } from "Utils/user";

const { Option } = Select;

/**
 * 不依赖任何外部传入, 可全局便利使用的机场组件, 默认会设置全局机场
 */
const Airport = (): ReactElement => {
  const location = useLocation();
  const [area, setArea] = useState<Area[]>([]);
  const [globalState, actions] = useGlobal();
  const { queryCondition, userInfo, systemType, moduleConfig } = globalState;
  const { airportName } = queryCondition;
  const { setQueryCondition, setQueryConditionAirport } = actions;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const language = sessionStorage.getItem("lang") || "zh-CN";
  const labelName = language === "en-US" ? "areaNameEn" : "areaName";
  const airportRole = userInfo.roleList?.find(
    (f) => f.roleType === RoleType.airport
  );
  const userAirports = useMemo(
    () => (airportRole ? getPermissionItems(airportRole) : []),
    [airportRole]
  );
  const userDefaultAirport = useMemo(() => {
    return getDefaultAirport(userInfo);
  }, [userInfo]);
  const [{ data, isLoading, error }, doFetch] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 1,
    },
    lazey: true,
  });

  // 设置默认值
  useEffect(() => {
    if (!userDefaultAirport) return;
    setQueryConditionAirport({
      airport: userDefaultAirport.code,
      airportName: userDefaultAirport.name,
      area: {
        areaCode: userDefaultAirport.code,
        areaName: userDefaultAirport.name,
        areaNameEn: null,
        areaType: 5,
        pinyin: "",
        children: [],
      },
      originalAirport: {
        areaCode: userDefaultAirport.code,
        areaName: userDefaultAirport.name,
        areaNameEn: null,
        areaType: 5,
        pinyin: "",
        children: [],
      },
    });
  }, [setQueryConditionAirport, userDefaultAirport]);

  const isCtripOrCanSelectAirports = canSelectAirports(userInfo, moduleConfig);

  useEffect(() => {
    if (isCtripOrCanSelectAirports) {
      doFetch({
        ext: {
          type: 1,
        },
      });
    }
  }, [doFetch, isCtripOrCanSelectAirports]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      setArea(data);
    }
  }, [data]);

  const handleSelectAirport = (value: string, option: any) => {
    setQueryCondition({
      ...queryCondition,
      airport: option.props.value,
      airportName: option.props.label,
    });
  };

  const onCascaderChange = (
    value: SingleValueType,
    selectedOptions?: DefaultOptionType[]
  ) => {
    if (selectedOptions && selectedOptions.length) {
      const { areaCode, areaName, areaType } =
        selectedOptions[selectedOptions.length - 1];
      setQueryCondition({
        ...queryCondition,
        airportName: areaName,
        airport: areaCode,
        originalAirport: { areaCode, areaType, areaName },
        area: { areaCode, areaType, areaName },
      });
    }
  };

  const showSearch = {
    filter: (value: string, path: any[]) =>
      path.some(
        (item) =>
          item.areaType === 5 &&
          (item.areaCode.includes(value.toUpperCase()) ||
            item.areaName.includes(value))
      ),
    limit: 10,
    render: (value: string, path: any[]) => (
      <span>
        {path[path.length - 1].areaName}({path[path.length - 1].areaCode})
      </span>
    ),
  };

  const displayRender = (labels: string[]) => (
    <span>{labels[labels.length - 1]}</span>
  );

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return (
      <Refetch size="small" refetch={() => doFetch({ ext: { type: 1 } })} />
    );
  }

  return (
    <>
      {isDemo ? (
        <Input style={{ minWidth: 200 }} disabled value="Demo" />
      ) : !isCtripOrCanSelectAirports && userAirports !== "*" ? (
        <Select
          id="toolbarAirportSelect"
          showSearch
          style={{ minWidth: 200 }}
          value={airportName}
          onChange={handleSelectAirport}
        >
          {userAirports.map((item) => (
            <Option key={item.code} value={item.code} label={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Cascader
          className="toolbar-cascader"
          options={area.length > 0 && area[0].children ? area[0].children : []}
          placeholder={airportName}
          expandTrigger="hover"
          fieldNames={{
            label: labelName,
            value: "areaCode",
            children: "children",
          }}
          onChange={onCascaderChange}
          showSearch={showSearch}
          displayRender={displayRender}
          notFoundContent="No Data"
          allowClear={false}
        />
      )}
    </>
  );
};

export default Airport;
