export const showToast = (msg: string, duration: number): void => {
  duration = isNaN(duration) ? 3000 : duration;
  const m = document.createElement("div");
  m.innerHTML = msg;
  m.style.cssText = `width:60%; min-width:80px; background:#000; opacity:0.6; height:auto;min-height: 30px; color:#fff; 
    line-height:30px; text-align:center; border-radius:4px; position:fixed; top:60%; left:20%; z-index:999999;`;
  document.body.appendChild(m);
  setTimeout(() => {
    const d = 0.5;
    m.style.webkitTransition =
      "-webkit-transform " + d + "s ease-in, opacity " + d + "s ease-in";
    m.style.opacity = "0";
    setTimeout(() => {
      document.body.removeChild(m);
    }, d * 1000);
  }, duration);
};
