import React, { useCallback, useEffect, useMemo } from "react";
import { Col, Empty, Row, Spin, Tooltip } from "antd";
import { isAirportMode, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import CardItem from "./CardItem";
import { Area, CardItemData } from "Interface";
import Refetch from "Components/Refetch";
import _ from "lodash";
import { IQueryExt } from "../Interface";

interface CardsProps {
  server: string;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  curCardCode?: string;
  setCurCard?: (card: CardItemData) => void;
  area?: Area;
  setHasCardData?: (has: boolean) => void;
  queryExt?: IQueryExt;
}

const Cards: React.FC<CardsProps> = (props: CardsProps) => {
  const { server, queryUrl, moduleCode, chartTableCode, queryExt } = props;
  const [globalState] = useGlobal();
  const { queryCondition, helpVisibility, airlinesQueryCondition, systemType } =
    globalState;
  const isAirport = isAirportMode(systemType);
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const queryWithExt = useMemo(
    () => ({ ...query, ...queryExt }),
    [query, queryExt]
  );
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryWithExt,
    lazey: true,
  });

  const refetch = useCallback(() => {
    const queryKeys = Object.keys(queryWithExt);
    if (
      queryKeys.includes("yoy") &&
      queryKeys.includes("wow") &&
      queryWithExt.startDate &&
      queryWithExt.endDate
    ) {
      doFetch({ query: queryWithExt, ext: Math.random() as any });
    }
  }, [doFetch, queryWithExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <Tooltip
      title={getSharkText("card.switch_card.explaination")}
      getPopupContainer={() => document.getElementById("main") || document.body}
      visible={helpVisibility}
      placement="bottomLeft"
    >
      <Spin spinning={isLoading}>
        <div className="cards-carousel">
          <div className="cards-carousel-content">
            {!_.isEmpty(data) ? (
              <Row
                gutter={[{ xs: 6, sm: 12, md: 18 }, 20]}
                className="cards-row"
              >
                {data.map((item: any, index: number) => (
                  <Col
                    xxl={4}
                    xl={6}
                    lg={8}
                    md={12}
                    sm={12}
                    xs={24}
                    key={index}
                    className="cards-column"
                  >
                    <CardItem data={item} queryExt={queryExt} />
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty />
            )}
          </div>
        </div>
      </Spin>
    </Tooltip>
  );
};

export default Cards;
