// Created by xh_zhu on 2021-01-29

import { Modal } from "antd";
import React, { useImperativeHandle, useState } from "react";
import { IOnNextReturnCode, IRecord } from "../DynamicPriceInterface";
import "./PriceTrend.css";
import PriceTrendContent from "./PriceTrendContent";

interface IPriceTrendProps {
  record: IRecord | undefined;
  setRecord: (r: IRecord) => void;
  onNext: (r: IRecord) => IOnNextReturnCode;
  onPrev: (r: IRecord) => IOnNextReturnCode;
  currentIdx: number;
  maxIdx: number;
  isDemo: boolean;
  pageType?: number;
}

const PTrend = (props: IPriceTrendProps, ref: any) => {
  const {
    record,
    setRecord,
    onNext,
    onPrev,
    currentIdx,
    maxIdx,
    isDemo,
    pageType = 0,
  } = props;
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    show: () => {
      setModalVisible(true);
    },
  }));

  const closeDetailModal = () => {
    setModalVisible(false);
  };

  return (
    <Modal
      className="price-trend-modal"
      open={modalVisible}
      onCancel={closeDetailModal}
      footer={null}
      // 因为modal内全屏也是esc退出, 并且比fullScreen先触发. 无法截断
      keyboard={false}
      style={{ minWidth: 1280, height: "80%", minHeight: 700 }}
    >
      {record ? (
        <PriceTrendContent<IRecord>
          record={record}
          setModalVisible={setModalVisible}
          onNext={onNext}
          onPrev={onPrev}
          setRecord={setRecord}
          currentIdx={currentIdx}
          maxIdx={maxIdx}
          isDemo={isDemo}
          pageType={0}
        />
      ) : undefined}
    </Modal>
  );
};
const PriceTrend = React.forwardRef(PTrend);
export default PriceTrend;
