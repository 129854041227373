import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import {
  Dimension,
  Filter,
  Measure,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  AIRLINE_NAME,
  APORT_NAME,
  APORTNAME_NAME,
  ARR_CITYNAME_NAME,
  ARR_CONTINENTNAME_NAME,
  ARR_COUNTRYNAME_NAME,
  ARR_PROVINCENAME_NAME,
  ARR_REGIONNAME_NAME,
  customCols,
  DATASET_ID,
  DEP_CITYNAME_NAME,
  DEP_CONTINENTNAME_NAME,
  DEP_COUNTRYCLASS_NAME,
  DEP_COUNTRYNAME_NAME,
  DEP_PROVINCENAME_NAME,
  DEP_REGIONNAME_NAME,
  DPORT_NAME,
  DPORTNAME_NAME,
  EXECUTE_RATIO_NAME,
  TKZONE,
} from "./DataSetColumns";
import RankingV2View from "Components/RankingV2/RankingV2View";
import { Item } from "Components/RankingV2/RankingV2Interface";
import { Spin } from "antd";
import { Area, FlightClass } from "Interface";
import useGlobalState from "Store";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";

const titleText = getSharkText("key.routes_performance");

interface ResData {
  dportname: string;
  aportname: string;
  m_exp_execute_rate: number;
  SUM_lf: number;
  SUM_cap: string;
}

const tabValues = ["cancel", "new", "execute"] as const;
type TabType = (typeof tabValues)[number];

const tabs: Array<{ label: string; value: TabType }> = [
  {
    label: getSharkText("key.temp_cancelled.name"),
    value: "cancel",
  },
  {
    label: getSharkText("key.temp_added.name"),
    value: "new",
  },
  {
    label: getSharkText("key.execution_rate.flightplan"),
    value: "execute",
  },
];

const internal: Record<number, { dep: string; arr: string }> = {
  1: { dep: DEP_CONTINENTNAME_NAME, arr: ARR_CONTINENTNAME_NAME },
  2: { dep: DEP_COUNTRYCLASS_NAME, arr: ARR_COUNTRYNAME_NAME },
  3: { dep: DEP_PROVINCENAME_NAME, arr: ARR_PROVINCENAME_NAME },
  4: { dep: DEP_CITYNAME_NAME, arr: ARR_CITYNAME_NAME },
  5: { dep: DPORT_NAME, arr: APORT_NAME },
};

const inland: Record<number, { dep: string; arr: string }> = {
  1: { dep: DEP_CONTINENTNAME_NAME, arr: ARR_CONTINENTNAME_NAME },
  2: { dep: DEP_REGIONNAME_NAME, arr: ARR_REGIONNAME_NAME },
  3: { dep: DEP_PROVINCENAME_NAME, arr: ARR_PROVINCENAME_NAME },
  4: { dep: DEP_CITYNAME_NAME, arr: ARR_CITYNAME_NAME },
  5: { dep: DPORT_NAME, arr: APORT_NAME },
};

const AreaColumnMap: Record<
  FlightClass,
  Record<number, { dep: string; arr: string }>
> = {
  [FlightClass.All]: internal,
  [FlightClass.Domestic]: inland,
  [FlightClass.Foreign]: internal,
  [FlightClass.Overseas]: internal,
};

interface FilterGenerator {
  depart: 0 | 1;
  arrive: 0 | 1;
  flightClass: FlightClass;
  startDate: string;
  endDate: string;
  /** 页面顶部的全局机场 */
  port: Area;
  /** 选中的机场 */
  area?: Area;
  airline: string;
}

const getFilters = (param: FilterGenerator): [Filter[], StandardFilter[]] => {
  const {
    depart,
    arrive,
    flightClass,
    startDate,
    endDate,
    port,
    area,
    airline,
  } = param;
  const filters: Filter[] = [];
  let oriFilters: StandardFilter[] = [];
  if (airline) {
    filters.push({
      columnName: AIRLINE_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: airline.split(","),
        },
      ],
    });
  }
  if (flightClass === FlightClass.Domestic) {
    filters.push({
      columnName: DEP_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
    filters.push({
      columnName: ARR_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
  } else if (flightClass === FlightClass.Foreign) {
    oriFilters.push({
      or: {
        filters: [
          {
            and: {
              filters: [
                {
                  in: {
                    field: `dimension.${DEP_COUNTRYNAME_NAME}`,
                    values: ["中国"],
                  },
                },
                {
                  not: {
                    filter: {
                      in: {
                        field: `dimension.${ARR_COUNTRYNAME_NAME}`,
                        values: ["中国"],
                      },
                    },
                  },
                },
              ],
            },
          },
          {
            and: {
              filters: [
                {
                  in: {
                    field: `dimension.${ARR_COUNTRYNAME_NAME}`,
                    values: ["中国"],
                  },
                },
                {
                  not: {
                    filter: {
                      in: {
                        field: `dimension.${DEP_COUNTRYNAME_NAME}`,
                        values: ["中国"],
                      },
                    },
                  },
                },
              ],
            },
          },
        ],
      },
    });
    filters.push({
      columnName: DEP_COUNTRYCLASS_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
    filters.push({
      columnName: ARR_COUNTRYNAME_NAME,
      filterConfig: [
        {
          calculate: "include",
          argsType: "string",
          stringArgs: ["中国"],
        },
      ],
    });
  }
  const daArr: StandardFilter[] = [
    {
      in: {
        field: `dimension.${AreaColumnMap[flightClass][port.areaType].dep}`,
        values: [port.areaType === 5 ? port.areaCode : port.areaName],
      },
    },
    ...(area?.areaCode
      ? [
          {
            in: {
              field: `dimension.${
                AreaColumnMap[flightClass][area.areaType].arr
              }`,
              values: [area.areaType === 5 ? area.areaCode : area.areaName],
            },
          },
        ]
      : []),
  ];
  const adArr: StandardFilter[] = [
    ...(area?.areaCode
      ? [
          {
            in: {
              field: `dimension.${
                AreaColumnMap[flightClass][area.areaType].dep
              }`,
              values: [area.areaType === 5 ? area.areaCode : area.areaName],
            },
          },
        ]
      : []),
    {
      in: {
        field: `dimension.${AreaColumnMap[flightClass][port.areaType].arr}`,
        values: [port.areaType === 5 ? port.areaCode : port.areaName],
      },
    },
  ];
  if (depart && arrive) {
    oriFilters.push({
      or: {
        filters: [
          {
            and: { filters: daArr },
          },
          {
            and: { filters: adArr },
          },
        ],
      },
    });
  } else if (depart) {
    oriFilters = oriFilters.concat(daArr);
  } else if (arrive) {
    oriFilters = oriFilters.concat(adArr);
  }
  if (startDate && endDate) {
    oriFilters.push({
      range: {
        field: `dimension.${TKZONE}`,
        strRange: { lower: startDate, upper: endDate },
      },
    });
  }
  return [filters, oriFilters];
};

export interface AirportRankingProps {
  area?: Area;
  airline: string;
}

/** 机场排行榜组件 */
const AirportRanking = (props: AirportRankingProps): ReactElement => {
  const { area, airline } = props;
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const {
    departure,
    arrive,
    flightClass,
    startDate,
    endDate,
    originalAirport,
  } = queryCondition;
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const [resData, setResData] = useState<ResData[]>([]);
  const services = useServices();
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_ID)
      .then(
        (r) => {
          // @ts-ignore
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{ isLoading, error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    onSuccess: (r) => {
      const res = JSON.parse(r.data);
      if (res.status === 40000) {
        throw new Error("40000");
      }
      const rows = res.rows;
      const headers = res.headers;
      const source = DataRow2ListMap(rows, headers);
      setResData(source as unknown as ResData[]);
    },
  });

  const columns = useMemo(() => {
    return datasetCols.concat(customCols);
  }, [datasetCols]);

  const refetch = useCallback(() => {
    if (!datasetCols.length || !originalAirport) {
      return;
    }
    const dimensions: Dimension[] = [
      {
        columnName: DPORTNAME_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: APORTNAME_NAME,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ];
    const measures: Measure[] = [
      {
        id: EXECUTE_RATIO_NAME,
        columnName: EXECUTE_RATIO_NAME,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
    ];
    const [filters, oriFilters] = getFilters({
      depart: departure,
      arrive,
      flightClass,
      startDate,
      endDate,
      area,
      airline,
      port: originalAirport,
    });
    const sorters: Sorter[] = [
      {
        chartUsedColId: EXECUTE_RATIO_NAME,
        // column: "m_exp_ontime_ratio",
        columnName: EXECUTE_RATIO_NAME,
        sorter: "DESC",
        statistical: "COUNT",
      },
    ];

    const requestBuild = new RequestBuilder({
      datasetId: DATASET_ID,
      columns,
      dimensions,
      measures,
      chartFilters: filters,
      sorters,
      containerFilters: [],
      oriFilters,
      limit: 10,
    });
    const { encrypted } = requestBuild.getRequestBody();
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [
    airline,
    area,
    arrive,
    columns,
    datasetCols.length,
    departure,
    doFetch,
    endDate,
    flightClass,
    originalAirport,
    startDate,
  ]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const data = useMemo(() => {
    return resData.map((r, i) => {
      const name = r.dportname + " - " + r.aportname;
      const tmp: Item = {
        id: name,
        name,
        value: r.m_exp_execute_rate,
        rnk: i,
      };
      return tmp;
    });
  }, [resData]);

  if (isLoading) {
    return <Spin />;
  }

  return <RankingV2View data={data} formatter="percent" />;
};
export default AirportRanking;
