// Created by xh_zhu on 2021-04-14

import { Affix, Button, Checkbox, Col, message, Radio, Row } from "antd";
import Airlines from "Components/Toolbar/Airlines";
import { IDateMode, RangeObject, Role } from "Interface";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import DateRangePicker from "Components/Toolbar/DateRangePicker";
import useGlobalState from "Store";
import { getDateRange, getUserAirlines } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import AirlineFlightClass from "./AirlineFlightClass";
import { DATE_FORMAT } from "Constants";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import moment, { Moment } from "moment";
import Search from "./Search";
import useRefFunc from "Utils/useRefFunc";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";
import { getDefaultAirline } from "Utils/global";

/**
 * Component description
 * 中转分析-航司版顶部筛选条
 */

interface IProps {
  isDemo: boolean;
  onChange: (v: Partial<TransferPolicyBarlineRequestType>) => void;
}

const TopFilter = (props: IProps): ReactElement => {
  const { onChange, isDemo } = props;
  const defaultDateMode: IDateMode = "nextThirtyDays";
  const defaultSearchRange: IDateMode = "lastMonth";
  const [queryExt, setQueryExt] =
    useState<Partial<TransferPolicyBarlineRequestType>>();
  const [panelFilterValue, setPanelFilterValue] = useState<any>();
  const [transferType, setTransferType] = useState<number[]>([0]);
  const [searchRange, setSearchRange] = useState<RangeObject>(
    getDateRange(defaultSearchRange)
  );
  const [axisType, setAxisType] = useState<0 | 1>(0);
  const [globalState, action] = useGlobalState();
  const { userInfo, airlinesQueryCondition } = globalState;
  const { airlines, flightClass, startDate, endDate } = airlinesQueryCondition;
  const { setAirlinesQueryConditionAttr } = action;
  const { roleList } = userInfo;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;
  const userAirlines = useMemo(
    () => getUserAirlines(userInfo.roleList),
    [userInfo.roleList]
  );
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);

  useEffect(() => {
    const tmpExt = {
      filter: {
        ...panelFilterValue,
        ...(searchRange
          ? {
              searchStartDate:
                (searchRange && searchRange[0]?.format(DATE_FORMAT)) || "",
              searchEndDate:
                (searchRange && searchRange[1]?.format(DATE_FORMAT)) || "",
            }
          : null),
        sameAirline: transferType.includes(0) ? 1 : 0,
        airline1: transferType.includes(1) ? 1 : 0,
        airline2: transferType.includes(2) ? 1 : 0,
      },
      axisType,
    };
    setQueryExt(tmpExt);
  }, [panelFilterValue, searchRange, axisType, transferType]);

  useEffect(() => {
    if (!airlines) {
      const tmp = getDefaultAirline(airlinesRole);
      setAirlinesQueryConditionAttr(tmp);
    }
    const range = getDateRange(defaultDateMode);
    setAirlinesQueryConditionAttr({
      startDate: range ? range[0].format(DATE_FORMAT) : "",
      endDate: range ? range[1].format(DATE_FORMAT) : "",
    });
  }, [airlines, airlinesRole, setAirlinesQueryConditionAttr]);

  const submit = useRefFunc(() => {
    if (!queryExt) {
      return;
    }
    if (!startDate || !endDate) {
      message.error(getSharkText("config_page_select_departure_time"));
      return;
    }
    if (
      !queryExt.filter ||
      !queryExt.filter.searchStartDate ||
      !queryExt.filter.searchEndDate
    ) {
      message.error(getSharkText("config_page_select_search_time"));
      return;
    }
    onChange(queryExt);
  });

  /**
   * 过滤器加载完成后查询一次数据
   */
  useEffect(() => {
    if (!firstLoaded && queryExt && queryExt.filter) {
      submit();
      setFirstLoaded(true);
    }
  }, [submit, queryExt, firstLoaded]);

  const searchProps: RangePickerProps<Moment> = useMemo(() => {
    return {
      allowClear: true,
      ...(startDate && endDate
        ? {
            disabledDate: (cur: Moment) => {
              return cur.isBefore(moment(startDate).add(-31, "days"));
            },
          }
        : null),
    };
  }, [endDate, startDate]);

  const takeoffProps: RangePickerProps<Moment> = useMemo(() => {
    return {
      ...(queryExt?.filter?.searchStartDate && queryExt.filter.searchEndDate
        ? {
            disabledDate: (cur: Moment) => {
              return cur.isAfter(
                moment(queryExt?.filter?.searchEndDate).add(31, "days")
              );
            },
          }
        : null),
    };
  }, [queryExt?.filter?.searchEndDate, queryExt?.filter?.searchStartDate]);

  return (
    <Affix
      offsetTop={0}
      target={() => document.getElementById("main")}
      className="toolbar-wrap"
    >
      <div>
        <div className="toolbar">
          <Row gutter={[{ md: 24 }, 20]} align="middle">
            <Col>
              <AirlineFlightClass />
            </Col>
            <Col>
              <Airlines airlines={userAirlines} />
            </Col>
            <Col>
              <span style={{ marginRight: 10 }}>搜索日期</span>
              <DateRangePickerV2
                defaultDateMode="lastMonth"
                onChange={setSearchRange}
                otherProps={searchProps}
              />
            </Col>
            <Col>
              <span style={{ marginRight: 10 }}>
                {getSharkText("charts.date_of_departure")}
              </span>
              <DateRangePicker
                defaultDateMode={defaultDateMode}
                shouldDisableDate={false}
                otherProps={takeoffProps}
              />
            </Col>
            <Col>
              <span style={{ marginRight: 10 }}>
                {getSharkText("key.axis_display.name")}
              </span>
              <Radio.Group
                value={axisType}
                onChange={(e) => setAxisType(e.target.value)}
              >
                <Radio value={0}>{getSharkText("charts.date_of_search")}</Radio>
                <Radio value={1}>
                  {getSharkText("charts.date_of_departure")}
                </Radio>
              </Radio.Group>
            </Col>

            <Search
              onChangeVal={setPanelFilterValue}
              isDemo={isDemo}
              flightClass={flightClass}
            />
            <Checkbox.Group
              value={transferType}
              onChange={(e) => setTransferType(e as number[])}
            >
              <Checkbox value={0}>
                {getSharkText("transfer.same_airline.name")}
              </Checkbox>
              <Checkbox value={1}>
                {getSharkText(
                  "config_page_cross_airline_transfer_first_leg"
                ).replace("{airlines}", airlines)}
              </Checkbox>
              <Checkbox value={2}>
                {getSharkText(
                  "config_page_cross_airline_transfer_second_leg"
                ).replace("{airlines}", airlines)}
              </Checkbox>
            </Checkbox.Group>
            <Col>
              <Button type="primary" onClick={submit} value="查询">
                {getSharkText("key.query.button")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Affix>
  );
};
export default TopFilter;
