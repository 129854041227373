import {
  BorderOutlined,
  CheckSquareFilled,
  EditOutlined,
  InfoCircleFilled,
  SearchOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { Col, Popover, Row } from "antd";
import React, { ReactElement } from "react";

import {
  ALERT_NAMES,
  DISABLE_MANAGE,
  INotificationDataType,
  NOTIFY_TYPE_TO_ALERT_ENUM,
} from "../NotificationCenterCommon";
import HtmlParse from "html-react-parser";
import styles from "./Card.module.scss";
import moment from "moment";
import { DATE_FORMAT, DATE_MINUTE_FORMAT } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

export interface IBaseCardProps {
  data: INotificationDataType;
  cardType: number;
  setPopup: () => void;
}
export interface IBaseCardState {
  loading: boolean;
}

/**
 * 通知中心卡片基础
 */
const BaseCard = (props: IBaseCardProps): ReactElement => {
  const { data, cardType, setPopup } = props;

  const genStatus = () => {
    const baseUrl =
      data.type === 3 || data.type === 8
        ? "/dynamic_price"
        : "/monitoring_alerting";
    const genUrl = (href: string) => {
      let tmpUrl = `${href}?startDate=${moment(data.takeofftime).format(
        DATE_FORMAT
      )}&endDate=${moment(data.takeofftime).format(DATE_FORMAT)}&routes=${
        data.route
      }&tab=${NOTIFY_TYPE_TO_ALERT_ENUM[data.type]}`;
      // 如果是非运力和搜索, 需要切换航司
      if ([3, 4, 5, 6, 8].includes(data.type)) {
        tmpUrl += `&airline=${data.flightno.slice(0, 2)}`;
      }
      if ([8].includes(data.type)) {
        tmpUrl += `&adviseId=${data.id}`;
      }
      return tmpUrl;
    };
    const searchUrl = genUrl(baseUrl);
    const manageUrl = genUrl("/flight_manage");
    return data.status === 2 ? (
      getSharkText("config_page_expired")
    ) : (
      <span style={{ fontSize: "120%" }}>
        {data.status === 1 ? (
          <Popover
            placement="top"
            content={getSharkText("config_page_processed")}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentElement as HTMLElement
            }
          >
            <CheckSquareFilled style={{ color: "#aaa", marginRight: 5 }} />
          </Popover>
        ) : (
          <Popover
            placement="top"
            content={getSharkText("config_page_click_to_process_message")}
            getPopupContainer={(triggerNode) =>
              triggerNode.parentElement as HTMLElement
            }
          >
            <BorderOutlined
              style={{ color: "#1890ff", marginRight: 5 }}
              onClick={setPopup}
            />
          </Popover>
        )}
        <Popover
          placement="topLeft"
          content={
            <span style={{ display: "inline-block", width: 90 }}>
              {getSharkText("config_page_view_message_details")}
            </span>
          }
          getPopupContainer={(triggerNode) =>
            triggerNode.parentElement as HTMLElement
          }
        >
          <a
            href={searchUrl}
            target="_blank"
            rel="noreferrer"
            // onClick={setPopup}
          >
            <SearchOutlined style={{ color: "#1890ff", marginRight: 5 }} />
          </a>
        </Popover>
        {!DISABLE_MANAGE.includes(data.type) ? (
          <Popover
            placement="topLeft"
            content={
              <span style={{ display: "inline-block", width: 90 }}>
                {getSharkText("config_page_view_flight_status")}
              </span>
            }
            getPopupContainer={(triggerNode) =>
              triggerNode.parentElement as HTMLElement
            }
          >
            <a
              href={manageUrl}
              target="_blank"
              rel="noreferrer"
              // onClick={setPopup}
            >
              <EditOutlined style={{ color: "#1890ff", marginRight: 5 }} />
            </a>
          </Popover>
        ) : undefined}
      </span>
    );
  };
  return (
    <div className={styles.cardContainer}>
      <Row justify="space-between">
        <Col>
          {cardType === 1 ? (
            <WarningFilled
              style={{ color: "red", marginRight: 5, fontSize: "110%" }}
            />
          ) : (
            <InfoCircleFilled
              style={{ color: "#f3c325", marginRight: 5, fontSize: "110%" }}
            />
          )}
          {ALERT_NAMES[data.type] || getSharkText("key.unknown.name")}
        </Col>
        <Col>{moment(data.changedate).format(DATE_MINUTE_FORMAT)}</Col>
      </Row>
      <Row justify="space-between" style={{ alignItems: "center" }}>
        <Col span={18}>
          <Row>
            {/* <Col span={24}>{genHeader()}</Col>
              <Col>{genBody()}</Col> */}
            {HtmlParse(data.template)}
          </Row>
        </Col>
        <Col span={6}>{genStatus()}</Col>
      </Row>
    </div>
  );
};
export default BaseCard;
