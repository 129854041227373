import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import {
  comparatorsToComparisons,
  getRenderMeasureColumnName,
  isExpCol,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/common";
import {
  CompareMean,
  StandardComparison,
  StandardMatrixRequest,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { getResponseListMapKey } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils/index";
import { FDDatasetCol2MeasureSchemaEx } from "Page/AI/FreeDashboard/common";
import { Measure, StatisticalType } from "Page/AI/FreeDashboard/interface";
import { cloneDeep } from "lodash";

export interface FECalculateAttr {
  /** 参数字段 */
  _argColumnNames?: string[];
  /** 计算方法 */
  _calculate?: (args: number[]) => number;
}

const getResponseKey = (
  columns: FDDatasetCol[],
  columnName: string,
  method?: StatisticalType,
  comparison?: StandardComparison
): string | null => {
  const column = columns.find((c) => c.name === columnName);
  if (!column) {
    return null;
  }
  const schema = FDDatasetCol2MeasureSchemaEx(column);
  const alias = getRenderMeasureColumnName(schema, method);
  const key = getResponseListMapKey(alias, comparison);
  return key;
};

const calculateValue = (
  map: Record<string, any>,
  columns: FDDatasetCol[],
  column: FDDatasetCol & FECalculateAttr,
  comparison?: StandardComparison
): void => {
  const key = getResponseKey(columns, column.name || "", "SUM", comparison);
  const argKeys = column._argColumnNames || [];
  const args = argKeys.reduce((t, c) => {
    const argKey = getResponseKey(columns, c, "SUM", comparison);
    if (argKey && map[argKey] !== undefined) {
      t.push(map[argKey]);
    }
    return t;
  }, [] as number[]);
  if (
    key &&
    argKeys.length &&
    argKeys.length === args.length &&
    column._calculate
  ) {
    map[key] = column._calculate(args);
  } else if (
    argKeys.length &&
    (argKeys.length !== args.length || !column._calculate)
  ) {
    console.error(
      "calculateValue error: argKeys:",
      argKeys,
      map,
      columns,
      column,
      comparison
    );
  }
};

export const calculate = (
  request: StandardMatrixRequest,
  listMap: Array<Record<string, any>>,
  measures: Measure[],
  columns: Array<FDDatasetCol & FECalculateAttr>,
  ignoreCols: string[]
): Record<string, any> => {
  const comparisons = comparatorsToComparisons(request.comparators || []);
  const tmp = listMap.reduce((t, c, idx) => {
    Object.keys(c).forEach((key) => {
      if (idx === 0 && ignoreCols.includes(key)) {
        t[key] = c[key];
      } else {
        const v = parseFloat(c[key]) || 0;
        t[key] = (t[key] || 0) + v;
      }
    });
    return t;
  }, {} as Record<string, any>);
  measures.forEach((mea) => {
    const column = columns.find((c) => c.name === mea.columnName);
    if (!column) {
      return;
    }
    // 求复合指标的累加值计算值
    if (isExpCol(column)) {
      calculateValue(tmp, columns, column);
      comparisons.forEach((com) => {
        calculateValue(tmp, columns, column, com);
      });
    }
    // 求同环比
    comparisons.forEach((com) => {
      const grate = cloneDeep(com);
      grate.mean = CompareMean.grate;
      const currentAbsKey = getResponseKey(columns, mea.columnName, "SUM");
      const compareAbsKey = getResponseKey(columns, mea.columnName, "SUM", com);
      const compareGrateKey = getResponseKey(
        columns,
        mea.columnName,
        "SUM",
        grate
      );
      if (currentAbsKey && compareAbsKey && compareGrateKey) {
        tmp[compareGrateKey] = tmp[currentAbsKey] / tmp[compareAbsKey] - 1;
      }
    });
  });
  return tmp;
};
