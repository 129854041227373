import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { SwapLeftOutlined, SwapRightOutlined } from "@ant-design/icons";

export interface ISortOrderProps {
  value?: "asc" | "desc" | "default";
  onChange?: (v: "asc" | "desc") => void;
}

/**
 * 排序图标
 */
const SortOrder = (props: ISortOrderProps): ReactElement => {
  const { value = "default", onChange } = props;
  const [v, setV] = useState<"asc" | "desc" | "default">(value);

  useEffect(() => {
    setV(value);
  }, [value]);

  const handleChange = useCallback(() => {
    const val = v === "asc" ? "desc" : "asc";
    setV(val);
    if (onChange) {
      onChange(val);
    }
  }, [onChange, v]);

  return (
    <span onClick={handleChange} style={{ cursor: "pointer" }}>
      <SwapRightOutlined
        style={{
          transform: "rotate(90deg) rotateX(180deg)",
          marginRight: -5,
          color: v === "desc" ? "#1890ff" : undefined,
        }}
      />
      <SwapLeftOutlined
        style={{
          transform: "rotate(90deg)",
          color: v === "asc" ? "#1890ff" : undefined,
        }}
      />
    </span>
  );
};
export default SortOrder;
