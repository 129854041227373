import React, { ReactNode, useMemo, useState } from "react";
import { DatePicker, message } from "antd";
import moment, { Moment } from "moment";
import { getDateRange, isHistoryToFuture } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { DATE_MODES } from "Constants";
import { IDateMode, RangeObject } from "Interface";
import { GetGroupDateRangeValue } from "Utils/global";
import { RangePickerProps } from "antd/lib/date-picker/generatePicker";
import useMergedState from "Utils/useMergedState";

export interface DateRangePickerProps {
  /**
   * 默认日期, 字符串模式可选:
  | "none"
  | "currentMonth"
  | "lastMonth"
  | "lastThreeMonth"
  | "lastYear"
  | "currentDay"
  | "nextWeek"
  | "nextThirtyDays"
  | "nextFourteenDays"
  | "fourteenDays"
  | "ninetyDays"
  | "custom";
  也可以使用Moment[]传入初始值
   */
  defaultDateMode?: IDateMode | RangeObject;
  /**
   * 如果父节点控制了值, 那么使用父节点的值, 本地的值只跟随父节点的值改变
   */
  value?: RangeObject;
  /**
   * 设置值的函数
   */
  onChange?: (v: RangeObject) => void;
  /**
   * 是否允许选择历史到未来, 默认允许
   */
  allowHistoryToFuture?: boolean;
  /**
   * 禁用未来选项的快捷配置, 如果与otherProps里的disabledDate冲突, 以otherProps为主
   */
  disableFuture?: boolean;
  /**
   * antd时间选择器支持的其它属性
   */
  otherProps?: RangePickerProps<Moment>;
  /**
   * 允许选择的时间跨度
   */
  limitRange?: number;
  /**
   * 如果设置了pathname, 则会从GetGroupDateRangeValue方法中获取默认的日期,
   * 此功能用于跨页面保持时间选择统一, 比如经营分析模块部分页面需要跨页面保持时间选项
   */
  pathname?: string;
  /**
   * 标题
   */
  title?: string | ReactNode;
  /**
   * 底部附加内容, 比如快捷选项或者对比日期
   */
  footer?: () => ReactNode;
}

const { RangePicker } = DatePicker;
/**
 * 因为原DateRangePicker捆绑了部分页面逻辑, 并且强制使用全局queryCondition. 所以重写了DateRangePicker, 不捆绑业务逻辑, 不使用全局queryCondition
 * 1. 提供禁止跨越历史未来的快捷选项
 * @param props
 * @returns
 */
const DateRangePickerV2: React.FC<DateRangePickerProps> = (
  props: DateRangePickerProps
) => {
  const {
    defaultDateMode,
    value,
    onChange,
    allowHistoryToFuture = true,
    limitRange,
    otherProps,
    pathname,
    disableFuture,
    title,
    footer,
  } = props;
  const [, setRangeMode] = useState(defaultDateMode);
  const defaultRange = Array.isArray(defaultDateMode)
    ? (defaultDateMode as [Moment, Moment])
    : defaultDateMode != null
    ? getDateRange(defaultDateMode)
    : null;
  // 部分页面需要跨页面保持日期统一, 比如经营分析的各个页面, 此时使用pathname来获取统一的日期
  const [innerRange, setInnerRange] = useMergedState<RangeObject>(
    (pathname && GetGroupDateRangeValue(pathname)) || defaultRange,
    { value }
  );
  const [isShowPicker, setIsShowPicker] = useState(false);
  const tableProps = useMemo(() => {
    const disableFutureObj = disableFuture
      ? {
          disabledDate: (current: Moment) => !current.isBefore(moment(), "day"),
        }
      : null;
    return {
      ...disableFutureObj,
      ...otherProps,
    };
  }, [disableFuture, otherProps]);

  const handleSelectRange = (dates: RangeObject) => {
    if (dates) {
      const tmpDates: Moment[] = dates.filter((d) => d != null) as Moment[];
      if (tmpDates.length === 2) {
        if (!allowHistoryToFuture && isHistoryToFuture(tmpDates)) {
          message.error(getSharkText("key.choose_past_or_future_time.hint"));
          return;
        }
        if (
          limitRange &&
          Math.abs(tmpDates[0].diff(tmpDates[1], "days")) > limitRange
        ) {
          message.error(
            `${getSharkText(
              "key.maximum_select_time_range"
            )} ${limitRange}${getSharkText("key.days.text")}`
          );
          return;
        }
      }
    }
    if (onChange) {
      onChange(dates);
    }
    if (value === undefined) {
      setInnerRange(dates);
    }
  };

  const handleOpenRange = (open: boolean) => {
    if (open) {
      setRangeMode(DATE_MODES[2].value);
    }
    setIsShowPicker(open);
  };

  return (
    <>
      {title ? <span>{title}</span> : undefined}
      <RangePicker
        style={{ width: "260px" }}
        data-ut-id="dateRangePickerV2"
        allowClear={false}
        value={value || innerRange}
        format="YYYY-MM-DD"
        onChange={handleSelectRange}
        open={isShowPicker}
        onOpenChange={handleOpenRange}
        renderExtraFooter={footer}
        placement={"topLeft"}
        {...tableProps}
      />
    </>
  );
};

export default DateRangePickerV2;
