import { Button, Col, Row } from "antd";
import { DATE_FORMAT } from "Constants";
import { Article, ArticleType } from "@ctrip/flt-bi-flightai-base";
import moment from "moment";
import React, { ReactElement, useCallback } from "react";
import styles from "./reportItem.module.scss";

export interface IReportItemProps {
  article: Article;
  types: ArticleType[];
  index: number;
}

/**
 * 列表页文章项
 */
const ReportItem = (props: IReportItemProps): ReactElement => {
  const { article, types } = props;

  const getPublishTime = useCallback((s: string | null) => {
    try {
      return moment(s).format(DATE_FORMAT);
    } catch (e) {
      console.error(e);
      return "";
    }
  }, []);

  return (
    <Row className={styles.reportItem}>
      <Col span={6}>
        {article.imageUrl && (
          <img className={styles.img} src={article.imageUrl} />
        )}
      </Col>
      <Col span={18}>
        <div className={styles.content}>
          <div className={styles.header}>{article.title}</div>
          <div className={styles.summary}>{article.summary}</div>
          <div className={styles.info}>
            <span>
              {
                types?.find((t) => t.typeId === (article.typeId || -1))
                  ?.typeName
              }{" "}
              | {getPublishTime(article.publishTime)}
            </span>
            <span>
              {article.fileUrl && (
                <a href={article.fileUrl}>
                  <Button className={styles.button}>报告下载</Button>
                </a>
              )}
            </span>
          </div>
          {/* <div className={styles.actions}>
            <Link to={{ pathname: "/Report", state: article }}>
              <Button className={styles.button} style={{ marginRight: 30 }}>
                立即查看
              </Button>
            </Link>
          </div> */}
        </div>
      </Col>
    </Row>
  );
};
export default ReportItem;
