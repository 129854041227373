import { IDownloadHeader } from "Interface";
import { DetailResData } from "../../interface";
import { FLIGHTNO_GROUP } from "./cols";
import { showNum } from "Utils";

export const columns: Array<IDownloadHeader<DetailResData>> = [
  {
    title: "补贴航班出发",
    dataIndex: "pri_dport",
  },
  {
    title: "补贴航班到达",
    dataIndex: "pri_aport",
  },
  {
    title: "补贴航组",
    dataIndex: FLIGHTNO_GROUP,
  },
  {
    title: "补贴航组全程机场",
    dataIndex: "port_group",
  },
  {
    title: "标杆航组全程机场",
    dataIndex: "port_bg_group",
  },
  {
    title: "绕航率",
    dataIndex: "AVG_raohang",
    render: (val: number) => showNum(val, "num", 2),
  },
  {
    title: "补贴人数",
    dataIndex: "SUM_transit_persons_fix",
    sortOrder: "descend",
    render: (val: number) => Math.ceil(val),
  },
  {
    title: "补贴单价",
    dataIndex: "AVG_price_diff_fix",
    render: (val: number) => showNum(val),
  },
];
