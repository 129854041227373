import {
  StandardFilter,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { useEffect, useMemo, useRef, useState } from "react";
import { useServices } from "./useServices";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { Dimension, Measure, Sorter } from "./interface";
import RequestBuilder from "./Components/RequestBuilder";
import { useFetch } from "Utils";
import useRefFunc from "Utils/useRefFunc";
import { Obj } from "Interface";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import ubtUtils from "Utils/ubtUtils";

const CNT = "cnt";

interface Services {
  getDimensionEnumValues: (
    dimColumnName: string[] | string,
    filters?: StandardFilter[],
    limit?: number
  ) => Promise<Obj[] | undefined>;
  getCols: () => FDDatasetCol[];
}

export interface useDimensionEnumValuesProps {
  datasetId: number;
  sorters?: Sorter[];
}

/**  Component description */
const useDimensionEnumValues = (
  props: useDimensionEnumValuesProps
): Services => {
  const { datasetId, sorters } = props;
  const services = useServices();
  const [cols, setCols] = useState<FDDatasetCol[]>([]);
  const initialReq = useRef<Promise<any>>();

  const getCols = useRefFunc(() => {
    initialReq.current = services.getDatasetColsOnQuery(datasetId);
    initialReq.current.then((res) => {
      if (res?.ResponseStatus?.Ack === "Success") {
        setCols(res.data || []);
      }
    });
  });

  useEffect(() => {
    getCols();
  }, []);

  const [{}, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    isSingleInstance: false,
    useCache: true,
  });

  const columns = useMemo(() => {
    return cols.concat({
      id: 0.1,
      datasetId,
      kind: 0,
      sort: "DOUBLE",
      aggs: "",
      name: CNT,
      displayName: "数值",
      dbcolumn: `count(1)`,
      jdbctype: "DOUBLE",
      description: "",
      extra: '{"isExp":true}',
    });
  }, [cols, datasetId]);

  const reqEnumValues = useRefFunc(
    async (
      dimensionColumnName: string | string[],
      filters: StandardFilter[] = [],
      limit = 20000
    ): Promise<Obj[] | undefined> => {
      const dims = Array.isArray(dimensionColumnName)
        ? dimensionColumnName
        : [dimensionColumnName];
      const dimensions: Dimension[] = dims.map((d) => ({
        columnName: d,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      }));
      if (dimensions.length === 0) {
        ubtUtils.error(
          "useDimensionEnumValues Error",
          JSON.stringify({
            msg: "dimensionColumnName is empty",
            datasetId: datasetId.toString(),
            dimensionColumnName,
            filters,
          })
        );
        return Promise.resolve(undefined);
      }

      const measures: Measure[] = [
        {
          id: CNT,
          columnName: CNT,
          measureConfig: {
            statisticalConfig: { method: "AVG" },
            formatConfig: { type: "decimal", precision: 2 },
            comparison: null,
          },
        },
      ];
      const defaultSorters: Sorter[] = [
        {
          chartUsedColId: CNT,
          // column: "m_exp_ontime_ratio",
          columnName: CNT,
          sorter: "DESC",
          statistical: "COUNT",
        },
      ];
      const requestBuild = new RequestBuilder({
        datasetId,
        columns,
        dimensions,
        measures,
        chartFilters: [],
        sorters: sorters || defaultSorters,
        containerFilters: [],
        oriFilters: filters,
        limit,
      });
      const { encrypted, requestBody } = requestBuild.getRequestBody();

      return new Promise((resolve, reject) => {
        doFetch({
          ext: {
            datasetId,
            colIds: [],
            req: encrypted,
          },
        }).then((res) => {
          if (res?.ResponseStatus.Ack === "Success") {
            const obj: StandardResponse = JSON.parse(res.data);
            console.log(
              `getDimensionEnumValues ${dimensionColumnName} : `,
              obj
            );
            if (obj.status === 0) {
              const list = DataRow2ListMap(obj.rows, obj.headers);
              resolve(list);
              return;
            }
          }
          reject(new Promise(() => "error"));
        });
      });
    }
  );

  const getDimensionEnumValues = useRefFunc(
    async (
      dimensionColumnName: string | string[],
      filters = [],
      limit = 20000,
      times = 5
    ): Promise<Obj[] | undefined> => {
      await initialReq.current;
      if (cols.length) {
        return reqEnumValues(dimensionColumnName, filters, limit);
      } else {
        if (times < 0) {
          return Promise.resolve(undefined);
        }
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(null);
          }, 200);
        });
        return getDimensionEnumValues(
          dimensionColumnName,
          filters,
          limit,
          times--
        );
      }
    }
  );

  const rCols = useRefFunc(() => cols);

  const dimensionService = useRef({
    getDimensionEnumValues,
    getCols: rCols,
  }).current;

  return dimensionService;
};
export default useDimensionEnumValues;
