import { SHOW_LOAD } from "Constants";
import { AirlinesQueryCondition, QueryCondition } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";

//  首页-机场版页面主体模块的过滤器, 时间, 上周/本周/本月/今年
export interface IAirportQueryExt2 {
  yoy?: number;
  wow?: number;
  valControl?: string[];
}

export interface IQueryExt {
  query: AirlinesQueryCondition | QueryCondition;
  ext: {
    cardCode: string;
    together: number;
  };
}

export interface IRefundExt {
  query: QueryCondition;
  ext: {
    type: number;
  };
}
export interface ICard {
  cardCode: string;
  cardLabel: string;
  type: number;
  checked: boolean;
  isPercentVal: boolean;
}

export const AirportCardList = SHOW_LOAD
  ? [
      {
        cardCode: "passenger_traffic",
        cardLabel: getSharkText("key.passenger_traffic"),
        type: 0,
        checked: true,
        isPercentVal: false,
      },
      {
        cardCode: "transport_capacity",
        cardLabel: getSharkText("key.available_seats"),
        type: 0,
        checked: false,
        isPercentVal: false,
      },
      {
        cardCode: "flight_sorties",
        cardLabel: getSharkText("config_page_market_flight_sorties"),
        type: 0,
        checked: false,
        isPercentVal: false,
      },
      {
        cardCode: "load_factor",
        cardLabel: getSharkText("key.loadfactor"),
        type: 0,
        checked: false,
        isPercentVal: true,
      },
      {
        cardCode: "search_index",
        cardLabel: getSharkText("key.search_index.name"),
        type: 1,
        checked: true,
        isPercentVal: false,
      },
      {
        cardCode: "ow_price",
        cardLabel: getSharkText("config_page_average_ticket_price"),
        type: 1,
        checked: false,
        isPercentVal: false,
      },
      {
        cardCode: "price_sum_ow",
        cardLabel: getSharkText(
          "config_page_average_ticket_price_excluding_tax"
        ),
        type: 1,
        checked: false,
        isPercentVal: false,
      },
    ]
  : [
      {
        cardCode: "passenger_traffic",
        cardLabel: getSharkText("key.passenger_traffic"),
        type: 0,
        checked: true,
        isPercentVal: false,
      },
      {
        cardCode: "transport_capacity",
        cardLabel: getSharkText("key.available_seats"),
        type: 0,
        checked: true,
        isPercentVal: false,
      },
      {
        cardCode: "flight_sorties",
        cardLabel: getSharkText("config_page_market_flight_sorties"),
        type: 0,
        checked: false,
        isPercentVal: false,
      },
      {
        cardCode: "search_index",
        cardLabel: getSharkText("key.search_index.name"),
        type: 1,
        checked: true,
        isPercentVal: false,
      },
      {
        cardCode: "ow_price",
        cardLabel: getSharkText("config_page_average_ticket_price"),
        type: 1,
        checked: false,
        isPercentVal: false,
      },
      {
        cardCode: "price_sum_ow",
        cardLabel: getSharkText(
          "config_page_average_ticket_price_excluding_tax"
        ),
        type: 1,
        checked: false,
        isPercentVal: false,
      },
    ];

export const AirlinesCardList = [
  ...AirportCardList,
  {
    cardCode: "ticket",
    cardLabel: getSharkText("config_page_ticket_volume"),
    type: 1,
    checked: true,
    isPercentVal: false,
  },
  {
    cardCode: "kilo_passenger_traffic",
    cardLabel: getSharkText("card.rpk_estimated.name"),
    type: 0,
    checked: false,
    isPercentVal: false,
  },
  {
    cardCode: "kilo_transport_capacity",
    cardLabel: getSharkText("config_page_seat_kilometer_estimate"),
    type: 0,
    checked: false,
    isPercentVal: false,
  },
];
