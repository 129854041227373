import React, { ReactElement } from "react";
import { IFlightRecord } from "../../FlightManageInterface";
import { AdjustCabinRecord } from "./interface";
import BlackScreen from ".";
import usePageContext from "../../FlightManageContext";

export interface ROContainerProps {
  row?: IFlightRecord;
  param: AdjustCabinRecord;
  setRecord?: (record: IFlightRecord) => void;
  closeRoPanel: () => void;
}

/**
 * 调用Context透传至调仓面板
 */
const ROContainer = (props: ROContainerProps): ReactElement => {
  const { row, setRecord, param, closeRoPanel } = props;
  const [, actions] = usePageContext();
  const { addFetchCMDPriceList } = actions;

  return (
    <BlackScreen
      row={row}
      param={param}
      setRecord={setRecord}
      closeRoPanel={closeRoPanel}
      addFetchCMDPriceList={addFetchCMDPriceList}
    />
  );
};
export default ROContainer;
