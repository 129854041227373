import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import FileTable from "Page/AI/FileSharing/Components/FileTable";
import {
  QUERY_URL,
  FileType,
  DEFAULT_FILE_TYPE,
} from "Page/AI/FileSharing/fetchCode";
import { useFetch } from "Utils";

interface FileTabProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  keyWord: string;
  setFileType: (fileType: FileType) => void;
  refreshTable: boolean;
  setRefreshTable: (refreshTable: boolean) => void;
}

const { TabPane } = Tabs;

const FileTabList: React.FC<FileTabProps> = (props: FileTabProps) => {
  const {
    setFileType,
    keyWord,
    queryUrl,
    moduleCode,
    chartTableCode,
    refreshTable,
    setRefreshTable,
  } = props;
  const [loading, setLoading] = useState(false);
  const [typeId, setTypeId] = useState(0);
  const [fileTypeList, setFileTypeList] = useState<FileType[]>([]);

  const [{ res }, doFetch] = useFetch({
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    ext: {
      typeId,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({ ext: { typeId } });
  };

  useEffect(() => {
    setLoading(true);
    refetch();
    setTimeout(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (res && res.typeList) {
      const list = [];
      list.push(DEFAULT_FILE_TYPE);
      setFileTypeList(list.concat(res.typeList));
    }
  }, [res]);

  const tabChange = (key: string) => {
    const selectId = Number(key);
    setTypeId(selectId);
    const fileTypeResult = fileTypeList.find(
      (item) => item.typeId === selectId
    );
    if (fileTypeResult) {
      setFileType(fileTypeResult);
    }
  };

  return (
    <>
      {loading ? undefined : (
        <Tabs defaultActiveKey="0" onChange={tabChange}>
          {fileTypeList.map((item) => (
            <TabPane tab={item.typeName} key={item.typeId + ""}>
              <FileTable
                queryUrl={QUERY_URL[1].listFile}
                moduleCode={moduleCode}
                chartTableCode={chartTableCode}
                keyword={keyWord}
                typeId={item.typeId}
                refreshTable={refreshTable}
                setRefreshTable={setRefreshTable}
              />
            </TabPane>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default FileTabList;
