import React, { ReactElement } from "react";
import useGlobal from "Store";
import { isAirportMode } from "Utils";
import SearchTrendsAirport from "./AirportIndex";
import SearchTrendsAirline from "./AirlineIndex";

interface SearchTrendsProps {
  intl?: boolean;
}

const SearchTrends = (
  props: SearchTrendsProps
): ReactElement<SearchTrendsProps> => {
  const { intl } = props;
  const [globalState] = useGlobal();
  const { systemType } = globalState;
  const isAirport = isAirportMode(systemType);

  return isAirport ? (
    <SearchTrendsAirport intl={intl} />
  ) : (
    <SearchTrendsAirline intl={intl} />
  );
};

export default SearchTrends;
