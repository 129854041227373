import { Col, Row } from "antd";
import React, { ReactElement, useState } from "react";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { ICard, IRefundExt } from "../Interface";
import LinesCharts from "./LinesCharts";
import { refundColumns } from "./LinesCharts/downloadColumns";
import RefundSearch from "./RefundSearch";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 *
 */

const RefundPanel = (): ReactElement => {
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  const [queryExt, setQueryExt] = useState<IRefundExt>();
  const [card] = useState<ICard>({
    cardLabel: "退票率",
    isPercentVal: true,
  } as ICard);
  return (
    <div className="content-white">
      <h1 style={{ color: "#1890FF" }} className={"row-title"}>
        {getSharkText("config_page_refund_analysis")}
      </h1>

      <RefundSearch onChangeVal={setQueryExt} />

      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          {!queryExt || !queryExt.query || !card ? undefined : (
            <LinesCharts
              queryUrl={QUERY_URL[systemType].refund}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].refund}
              ext={queryExt.ext}
              query={queryExt.query}
              card={card}
              style={{ height: "532px" }}
              compareType={queryExt.query.compareType}
              dateType={
                queryExt.ext.type === 0
                  ? getSharkText("config_page_takeoff")
                  : getSharkText("config_page_refund_ticket")
              }
              downloadColumns={refundColumns}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default RefundPanel;
