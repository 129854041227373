import React, { useState, useEffect, useCallback } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Table, Button, Result, Input, Row, Col } from "antd";
import { UserList, User, UnitList } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import RemoveUser from "./RemoveUser";
import RoleTypeChange from "./RoleTypeChange";
import useGlobal from "Store";
import AddUserModal from "./AddUserModal";
import _ from "lodash";
import UserEtermEditor from "./UserEtermEditor";
import UserRoutesEditor from "./UserRoutesEditor";

interface Iprops {
  unit?: UnitList;
  unitId: number;
}

const UnitUser: React.FC<Iprops> = (props) => {
  const { unitId, unit } = props;
  const [userList, setUserList] = useState<UserList[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [globalState] = useGlobal();
  const { userInfo } = globalState;
  const [{ isLoading, error, res }, doFetch] = useFetch({
    url: "findUserList",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      filter: {
        groupId: unitId,
      },
    },
    lazey: true,
  });

  const isVisitor = (user: User) => userInfo.user.id === user.id;

  const refetchUser = useCallback(() => {
    doFetch({
      ext: {
        filter: {
          groupId: unitId,
        },
      },
    });
  }, [doFetch, unitId]);

  useEffect(() => {
    refetchUser();
  }, [refetchUser]);

  useEffect(() => {
    if (res && res.userList) {
      setUserList(res.userList);
    }
  }, [res]);

  if (error) {
    return <Result status="error" title="Error" />;
  }

  const handleShowModal = () => {
    setIsShowModal(true);
  };

  const columns = [
    {
      title: getSharkText("config_page_mobile"),
      dataIndex: ["user", "mobile"],
      key: "mobile",
    },
    {
      title: getSharkText("config_page_name"),
      dataIndex: ["user", "name"],
      key: "name",
    },
    {
      title: getSharkText("config_page_work_unit"),
      dataIndex: ["user", "mainUnitName"],
      key: "mainUnitName",
    },
    {
      title: getSharkText("config_page_registration_time"),
      dataIndex: ["user", "registerDate"],
      key: "registerDate",
      render: (value: string) => moment(value).format("YYYY-MM-DD"),
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) =>
        moment(a.registerDate).isBefore(moment(b.registerDate)) ? -1 : 1,
    },
    {
      title: getSharkText("config_page_last_login_time"),
      dataIndex: ["user", "lastLoginDate"],
      key: "lastLoginDate",
      render: (value: string) => moment(value).format("YYYY-MM-DD"),
      sorter: (a: any, b: any) =>
        moment(a.lastLoginDate).isBefore(moment(b.lastLoginDate)) ? -1 : 1,
    },
    {
      title: getSharkText("config_page_is_admin"),
      dataIndex: ["user", "memberType"],
      key: "memberType",
      render: (value: number, record: UserList) => (
        <>
          {!isVisitor(record.user) && unitId !== 0 ? (
            <RoleTypeChange
              user={record.user}
              unitId={unitId}
              refetchUser={refetchUser}
            />
          ) : undefined}
        </>
      ),
    },
    {
      title: getSharkText("config_page_operation"),
      key: "action",
      width: 300,
      render: (value: any, record: UserList) => (
        <Row gutter={[10, 10]}>
          {(unit && unit.roleList.find((r) => r.roleType === 2)) ||
          (userInfo &&
            userInfo.user.memberType === 1 &&
            userInfo.roleList?.find((r) => r.roleType === 2)) ? (
            <>
              <Col>
                <UserEtermEditor
                  user={record.user}
                  unitId={unitId}
                  refetchUser={refetchUser}
                  type={0}
                />
              </Col>
              {/* 不能编辑自己的航线 */}
              {/*{userInfo.user.id !== record.user.id && (*/}
              <Col>
                <UserRoutesEditor
                  user={record.user}
                  unitId={unitId}
                  roleList={unit?.roleList || userInfo?.roleList || []}
                  refetchUser={refetchUser}
                  type={1}
                />
              </Col>
              {/*)}*/}
            </>
          ) : undefined}
          {!isVisitor(record.user) && unitId !== 0 ? (
            <Col>
              <RemoveUser
                user={record.user}
                unitId={unitId}
                refetchUser={refetchUser}
              />
            </Col>
          ) : undefined}
        </Row>
      ),
    },
  ];

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const filterList = _.cloneDeep(userList).filter(
    (item) =>
      item.user.mobile.includes(searchText) ||
      item.user.name.includes(searchText)
  );

  return (
    <>
      <Row gutter={16}>
        <Col style={{ width: 250 }}>
          <Input.Search
            value={searchText}
            onChange={handleSearch}
            placeholder={getSharkText(
              "config_page_enter_search_username_or_phone"
            )}
          />
        </Col>
        {unitId !== 0 ? (
          <Col>
            <Button type="primary" onClick={handleShowModal}>
              <UserOutlined />
              {getSharkText("config_page_add_user_to_permission_group")}
            </Button>
          </Col>
        ) : undefined}
      </Row>
      <Table
        showSorterTooltip={false}
        size="middle"
        style={{ marginTop: 16 }}
        loading={isLoading}
        dataSource={filterList}
        // @ts-ignore
        columns={columns}
        rowKey={(record) => record.user.id.toString()}
        tableLayout="fixed"
      />
      <AddUserModal
        visible={isShowModal}
        setVisible={setIsShowModal}
        unitId={unitId}
        refetchUser={refetchUser}
        userList={userList}
      />
    </>
  );
};

export default UnitUser;
