import { Tag, TagProps } from "antd";
import React, { ReactElement, useMemo } from "react";
import "./NotifyTag.scss";

export interface NotifyTagProps extends TagProps {
  notifyCount: number | null;
  showNull?: boolean;
}

/**  Component description */
const NotifyTag = (props: NotifyTagProps): ReactElement => {
  const { notifyCount, showNull = false, children } = props;
  const extend = useMemo(() => {
    return notifyCount == null && !showNull ? undefined : (
      <span className="tag-count">{notifyCount}</span>
    );
  }, [notifyCount, showNull]);
  return (
    <span className="notify-tag">
      <Tag {...props}>{children}</Tag>
      {extend}
    </span>
  );
};
export default NotifyTag;
