import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Result,
  Row,
} from "antd";
import { SERVER } from "Constants";
import useGlobal from "Store";
import VerifyCode from "Page/AI/Login/verifyCode";
import useI18Next from "Utils/useI18Next";

const { TextArea } = Input;

interface IFormComponentProps {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

const RightModal: React.FC<IFormComponentProps> = (
  props: IFormComponentProps
) => {
  const { visible, setVisible } = props;
  // const { getFieldDecorator } = Form;
  const [form] = Form.useForm();

  const [modalStatus, setModalStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [globalState, actions] = useGlobal();
  const { verifyInfo, userInfo } = globalState;
  const { uid, token } = actions.getLogin();
  const { user } = userInfo;
  const {
    name,
    mobilePrefix,
    mobile,
    mail,
    mainUnitName,
    mainUnitCode,
    depart,
  } = user;
  const t = useI18Next();

  const handleCancel = () => {
    if (loading) {
      setLoading(false);
    }
    setVisible(false);
    setTimeout(() => {
      setModalStatus(false);
    }, 500);
  };

  const handleRegisterSubmit = async () => {
    // e.preventDefault();
    const rst = await form.validateFields();
    // form.validateFields(async (err: any, values: any) => {
    if (rst) {
      setLoading(true);
      const result: any = await fetch(`${SERVER}trial`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          head: {
            uid,
            token,
            requestTime: new Date(),
            appId: "",
            clientType: "2",
            clientVersion: "",
          },
          user: {
            name,
            mobilePrefix,
            mobile,
            mail,
            mainUnitCode,
            mainUnitName,
            remark: form.getFieldValue("remark"),
            depart: form.getFieldValue("depart"),
            title: form.getFieldValue("title"),
            budget: form.getFieldValue("budget"),
            persons: form.getFieldValue("persons"),
          },
          validCode: {
            token: verifyInfo.token,
            version: verifyInfo.version,
            requestId: verifyInfo.requestId,
          },
        }),
      });
      const res = await result.json();
      if (res.flag) {
        setLoading(false);
        setModalStatus(true);
      } else {
        setLoading(false);
        try {
          const code = res.ResponseStatus.Errors[0].ErrorCode;
          message.error(t(`error.${code}`));
        } catch (e1) {
          console.log(e1);
        }
      }
    }
  };

  const subTitle = <span>{t("user.businessCooperation.submitted")}</span>;

  const leftFormLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  const rightFormLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      title={t("user.businessCooperation.title")}
      open={visible}
      footer={null}
      onCancel={handleCancel}
      className="modal-apply"
    >
      {!modalStatus ? (
        <Form onFinish={handleRegisterSubmit} hideRequiredMark form={form}>
          <Row gutter={16}>
            <Col style={{ flex: 1 }}>
              <Form.Item
                label={t("common.user")}
                {...leftFormLayout}
                labelAlign="left"
              >
                <div className="ellipsis">{name}</div>
              </Form.Item>
            </Col>
            <Col style={{ flex: 1 }}>
              <Form.Item
                label={t("common.company")}
                {...rightFormLayout}
                labelAlign="left"
              >
                <div className="ellipsis">{mainUnitName}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{ flex: 1 }}>
              <Form.Item
                label={t("common.mobile")}
                {...leftFormLayout}
                labelAlign="left"
              >
                {mobile}
              </Form.Item>
            </Col>
            <Col style={{ flex: 1 }}>
              <Form.Item
                label={t("common.email")}
                {...rightFormLayout}
                labelAlign="left"
                colon={true}
              >
                <div className="ellipsis">{mail}</div>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col style={{ flex: 1 }}>
              <Form.Item
                name="depart"
                label={t("user.businessCooperation.department.shortTitle")}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValue={depart}
                rules={[
                  {
                    required: true,
                    message: t("user.businessCooperation.department.msg"),
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder={t(
                    "user.businessCooperation.department.placeholder"
                  )}
                />
              </Form.Item>
            </Col>
            <Col style={{ flex: 1 }}>
              <Form.Item
                name="title"
                label={t("user.businessCooperation.job.title")}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  {
                    required: true,
                    message: t("user.businessCooperation.job.msg"),
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  placeholder={t("user.businessCooperation.job.placeholder")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{ display: "none" }}>
            <Col style={{ flex: 1 }}>
              <Form.Item
                name="budget"
                label={t("user.businessCooperation.budget.title")}
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  {
                    type: "number",
                    message: t("user.businessCooperation.budget.msg"),
                  },
                ]}
              >
                <>
                  <InputNumber
                    min={0}
                    placeholder={t("user.businessCooperation.budget.title")}
                    style={{ width: 120 }}
                  />
                  &nbsp;&nbsp;{t("user.businessCooperation.budget.unit")}
                </>
              </Form.Item>
            </Col>
            <Col style={{ flex: 1 }}>
              <Form.Item
                name="persons"
                label={t("user.businessCooperation.persons.title")}
                labelAlign="left"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={[
                  {
                    type: "integer",
                    message: t("user.businessCooperation.persons.msg"),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  placeholder={t(
                    "user.businessCooperation.persons.placeholder"
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            name="remark"
            rules={[
              {
                required: true,
                message: t("user.businessCooperation.content.msg"),
              },
            ]}
          >
            <TextArea
              // value={value}
              placeholder={t("user.businessCooperation.content.placeholder")}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>
          <Form.Item>
            <VerifyCode />
          </Form.Item>
          <Form.Item>
            <Button
              loading={loading}
              type="primary"
              htmlType="submit"
              size="large"
              className="login-form-button"
            >
              {t("common.submit")}
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Result
          status="success"
          title={t("common.submitSuccess")}
          subTitle={subTitle}
          extra={[
            <Button type="primary" key="console" onClick={handleCancel}>
              {t("common.ok")}
            </Button>,
          ]}
        />
      )}
    </Modal>
  );
};

// export default Form.create<IFormComponentProps>()(RightModal);
export default RightModal;
