// Created by xh_zhu on 2021-04-02

import { getSharkText } from "Utils/i18nGlobal";
import React from "react";

/**
 * Component description
 * 客源分析专用类型
 */

export const DISTRIBUTION_HELP = (
  <>
    <div>{getSharkText("key.note2.psgrsource")}</div>
    <div>{getSharkText("key.note3.psgrsource")}</div>
  </>
);

export const DISTRIBUTION_HOT_HELP = getSharkText("key.note4.psgrsource");

export const DISTRIBUTION_TREND_HELP = getSharkText("key.note5.psgrsource");

export const DISTRIBUTION_TREND_PROPORTION_HELP = getSharkText(
  "key.note6.psgrsource"
);

export const LOSS_HELP = (
  <>
    <div>{getSharkText("key.note7.psgrsource")}</div>
    <div>{getSharkText("key.note8.psgrsource")}</div>
  </>
);

export const LOSS_CITY_SELECTOR_HELP = getSharkText("key.note9.psgrsource");

export const LOSS_AIRPORT_SELECTOR_HELP = getSharkText("key.note10.psgrsource");

export const LOSS_HOT_HELP = getSharkText("key.note11.psgrsource");

export const LOSS_TREND_HELP = getSharkText("key.note12.psgrsource");

export const LOSS_ROUTE_HELP = getSharkText("key.note13.psgrsource");

export interface ILossRankingData {
  airport: string;
  airportName: string;
  cityId: number;
  cityName: string;
  compareValue: number;
  value: number;
}
