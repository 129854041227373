import React, { useEffect, useState } from "react";
import {
  AirlinesQueryExt,
  Area,
  ECompareTypeV2,
  FlightArea,
  IDownloadHeader,
} from "Interface";
import {
  groupCompareData,
  groupMarketComparisonData,
  isAirportMode,
  openDownloadDialog,
  showNum,
  showRawNum,
  U,
  useFetch,
  workbook2blob,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _ from "lodash";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Empty, Modal, Popover, Spin, Typography } from "antd";
import CustomTable from "Components/CustomTable";
import ValueNum from "Components/ValueNum";
import { COMPARE_TYPE_PER_NAME, COMPARE_TYPE_VALUE_NAME } from "Constants";
import Refetch from "Components/Refetch";
import AirlinesFlightNOTable from "../AirlinesFlightNOTable";
import { getServer } from "Service/server";

interface AirlineTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  area: Area;
  airlinesQueryExt: AirlinesQueryExt;
}

const { Text } = Typography;

const defaultFlightArea = {
  departArea: {
    areaType: 0,
    areaCode: "",
    areaName: "",
  },
  arriveArea: {
    areaType: 0,
    areaCode: "",
    areaName: "",
  },
};

const AirlineTable: React.FC<AirlineTableProps> = (
  props: AirlineTableProps
) => {
  const { queryUrl, moduleCode, chartTableCode, area, airlinesQueryExt } =
    props;
  const [tableMode, setTableMode] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const [airlinesFlightArea, setAirlinesFlightArea] =
    useState<FlightArea>(defaultFlightArea);
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const { useCompare } = queryCondition;
  const isAirport = isAirportMode(systemType);
  const compareType = isAirport
    ? queryCondition.compareType
    : airlinesQueryCondition.compareType;
  // 是否是航司的市场对标
  const isAirlinesMarketComparison =
    !isAirport &&
    airlinesQueryCondition.compareType === ECompareTypeV2.MARKET_COMPARE;
  const query = isAirport ? queryCondition : airlinesQueryCondition;
  const ext = isAirport
    ? { area }
    : { filter: { ...airlinesQueryExt }, type: 0 };

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      query,
      ext: { ...ext },
    });
  };

  useEffect(() => {
    refetch();
    setAirlinesFlightArea(defaultFlightArea);
  }, [query, area, airlinesQueryExt]);

  if (isLoading) {
    return <Spin />;
  }
  if (error) {
    return <Refetch refetch={refetch} />;
  }
  if (_.isEmpty(data)) {
    return <Empty />;
  }

  // 处理分组compare
  const groupData = !isAirlinesMarketComparison
    ? groupCompareData(
        data,
        ["lf", "wideBody"],
        ["dcityCode", "dcityName", "acityCode", "acityName", "type"]
      )
    : groupMarketComparisonData(data);
  // 处理格式
  const subComRender = (
    val: number,
    index: number,
    type: "num" | "percentage" | "price" = "num",
    roundNum = 0,
    isShowZero = true
  ) => {
    return !isAirlinesMarketComparison ? (
      tableMode !== 2 ? (
        !index ? (
          <ValueNum
            isShowZero={isShowZero}
            roundNum={roundNum}
            type={type}
            value={val}
          />
        ) : (
          <ValueNum color type="percentage" value={val} />
        )
      ) : (
        <ValueNum color type="percentage" value={val} />
      )
    ) : (
      <ValueNum
        isShowZero={isShowZero}
        roundNum={roundNum}
        type={type}
        value={val}
      />
    );
  };

  const handleShowAirlinesDetail = (item: any) => {
    if (!isAirport) {
      setAirlinesFlightArea({
        departArea: {
          areaType: 5,
          areaCode: item.dcityCode,
          areaName: item.dcityName,
        },
        arriveArea: {
          areaType: 5,
          areaCode: item.acityCode,
          areaName: item.acityName,
        },
      });
      setIsShowModal(true);
    }
  };

  const columns: IDownloadHeader[] = [
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dcityName",
      key: "dcityName",
      width: 220,
      sorter: (a: any, b: any) => a.dcityCode.localeCompare(b.dcityCode),
      render: (value: string, record: any) =>
        `${record.dcityName}(${record.dcityCode})`,
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "acityName",
      key: "acityName",
      width: 220,
      sorter: (a: any, b: any) => a.acityCode.localeCompare(b.acityCode),
      render: (value: string, record: any) =>
        `${record.acityName}(${record.acityCode})`,
    },
    {
      title: (
        <>
          <span>
            {isAirport
              ? getSharkText("key.numbers_of_airlines")
              : getSharkText("key.numbers_of_flights_carried")}
          </span>
          &nbsp;
          <Popover content={getSharkText("key.statistics_based_on_oc")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: isAirport ? "airline" : "flightCount",
      key: isAirport ? "airline" : "flightCount",
      width: 55,
      sorter: (a: any, b: any) =>
        isAirport ? a.airline - b.airline : a.flightCount - b.flightCount,
      render: (val: number, record: any) =>
        isAirport || airlinesQueryExt.filterType === 2 ? (
          <ValueNum type="num" value={val} />
        ) : (
          <a
            className="airlines-num"
            onClick={() => handleShowAirlinesDetail(record)}
          >
            <ValueNum type="num" value={val} />
          </a>
        ),
    },
    {
      title: (
        <>
          <span>{getSharkText("key.number_of_flights")}</span>&nbsp;
          <Popover content={getSharkText("key.flightcarried_statistic")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "flightSorties",
      key: "flightSorties	",
      width: 66,
      sorter: (a: any, b: any) => a.flightSorties - b.flightSorties,
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: (
        <>
          <span>{getSharkText("card.passenger_traffic.name")}</span>
          &nbsp;
          <Popover content={getSharkText("key.passenger_traffic.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "passengerTraffic",
      key: "passengerTraffic",
      width: 90,
      defaultSortOrder: "descend",
      sorter: (a: any, b: any) => a.passengerTraffic - b.passengerTraffic,
      render: (val: number) => (
        <ValueNum roundNum={-1} type="num" value={val} />
      ),
    },
    ...(!isAirport
      ? [
          {
            title: (
              <>
                <span>{getSharkText("card.rpk_estimated.name")}</span>
                &nbsp;
                <Popover content={getSharkText("card.rpk_tips.hint")}>
                  <Text type="secondary">
                    <InfoCircleOutlined />
                  </Text>
                </Popover>
              </>
            ),
            dataIndex: "kiloPassengerTraffic",
            key: "kiloPassengerTraffic",
            width: 100,
            render: (val: number) =>
              Math.abs(val) > 10 * 1000 * 100
                ? U.abbreviateNumber(val)
                : showNum(val),
          },
        ]
      : []),
    {
      title: (
        <>
          <span>{getSharkText("key.available_seats_estimated")}</span>
          &nbsp;
          <Popover content={getSharkText("key.estimated_based_on_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "transportCapacity",
      key: "transportCapacity",
      width: 100,
      sorter: (a: any, b: any) => a.transportCapacity - b.transportCapacity,
      render: (val: number) => (
        <ValueNum roundNum={-1} type="num" value={val} />
      ),
    },
    ...(!isAirport
      ? [
          {
            title: (
              <>
                <span>{getSharkText("card.ask_estimated.name")}</span>
                &nbsp;
                <Popover
                  content={getSharkText("key.estimated_based_on_ctrip.hint")}
                >
                  <Text type="secondary">
                    <InfoCircleOutlined />
                  </Text>
                </Popover>
              </>
            ),
            dataIndex: "kiloTransportCapacity",
            key: "kiloTransportCapacity",
            width: 100,
            render: (val: number) =>
              Math.abs(val) > 10 * 1000 * 100
                ? U.abbreviateNumber(val)
                : showNum(val),
          },
        ]
      : []),
    {
      title: (
        <>
          <span>{getSharkText("key.loadfactor")}</span>&nbsp;
          <Popover content={getSharkText("key.estimated_lf_by_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "lf",
      key: "lf",
      width: 80,
      sorter: (a: any, b: any) => a.lf - b.lf,
      render: (val: number) => <ValueNum type="percentage" value={val} />,
    },
    {
      title: getSharkText("key.flight_duration.hint"),
      dataIndex: "flightTime",
      key: "flightTime",
      width: 100,
      sorter: (a: any, b: any) =>
        (a.flightTime ? a.flightTime : -1) - (b.flightTime ? b.flightTime : -1),
      render: (val: number) => (
        <ValueNum value={val} type="num" isShowZero={false} />
      ),
    },
    {
      title: (
        <>
          <span>{getSharkText("key.oneway_average_price")}</span>&nbsp;
          <Popover content={getSharkText("key.ow_avg_price_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "price",
      key: "price",
      width: 80,
      sorter: (a: any, b: any) =>
        (a.price ? a.price : -1) - (b.price ? b.price : -1),
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: (
        <>
          <span>{getSharkText("key.oneway_average_price_tax_excluded")}</span>
          &nbsp;
          <Popover
            content={getSharkText("key.ow_avg_price_tax_excluded_ctrip.hint")}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "priceSumOw",
      key: "priceSumOw",
      width: 100,
      sorter: (a: any, b: any) =>
        (a.priceSumOw ? a.priceSumOw : -1) - (b.priceSumOw ? b.priceSumOw : -1),
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: (
        <>
          <span>{getSharkText("key.rt_avg_price.name")}</span>&nbsp;
          <Popover content={getSharkText("key.rt_avg_price_ctrip.hint")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "rtprice",
      key: "rtprice",
      width: 80,
      sorter: (a: any, b: any) =>
        (a.rtprice ? a.rtprice : -1) - (b.rtprice ? b.rtprice : -1),
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: (
        <>
          <span>{getSharkText("key.rt_avg_price_tax_excluded.name")}</span>
          &nbsp;
          <Popover
            content={getSharkText("key.rt_avg_price_tax_excluded_ctrip.hint")}
          >
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "priceSumRt",
      key: "priceSumRt",
      width: 100,
      sorter: (a: any, b: any) =>
        (a.priceSumRt ? a.priceSumRt : -1) - (b.priceSumRt ? b.priceSumRt : -1),
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: (
        <>
          <span>{getSharkText("key.passenger_traffic_per_flight")}</span>
          &nbsp;
          <Popover content={getSharkText("key.actual_traffic_divide_flights")}>
            <Text type="secondary">
              <InfoCircleOutlined />
            </Text>
          </Popover>
        </>
      ),
      dataIndex: "passengers",
      key: "passengers",
      width: 80,
      sorter: (a: any, b: any) => a.passengers - b.passengers,
      render: (val: number) => <ValueNum type="num" value={val} />,
    },
    {
      title: getSharkText("key.proportion_wide_body"),
      dataIndex: "wideBody",
      key: "wideBody",
      width: 80,
      sorter: (a: any, b: any) => a.wideBody - b.wideBody,
      render: (val: number) => <ValueNum type="percentage" value={val} />,
    },
    ...(isAirport
      ? [
          {
            title: getSharkText("key.revenue_per_psgr_km.name"),
            dataIndex: "passengerKilorm",
            key: "passengerKilorm",
            width: 80,
            sorter: (a: any, b: any) => a.passengerKilorm - b.passengerKilorm,
            render: (val: number) => showNum(val, "num", 4),
          },
        ]
      : []),
    ...(!isAirport
      ? [
          {
            title: (
              <>
                <span>{getSharkText("key.capacity.roi")}</span>&nbsp;
                <Popover content={getSharkText("key.capacity_roi_calculation")}>
                  <Text type="secondary">
                    <InfoCircleOutlined />
                  </Text>
                </Popover>
              </>
            ),
            width: 80,
            dataIndex: "capacityProductionCost",
            key: "capacityProductionCost",
            render: (val: number) => showNum(val, "num", 3),
          },
          {
            title: (
              <>
                <span>{getSharkText("key.revenue.roi")}</span>&nbsp;
                <Popover content={getSharkText("key.revenue_roi_calculation")}>
                  <Text type="secondary">
                    <InfoCircleOutlined />
                  </Text>
                </Popover>
              </>
            ),
            dataIndex: "incomeProductionCost",
            key: "incomeProductionCost",
            width: 80,
            render: (val: number) => showNum(val, "num", 3),
          },
        ]
      : []),
  ];

  const subColumns = [
    {
      title: "",
      dataIndex: "dcityName",
      key: "dcityName",
      width: 220,
      render: () => <span />,
    },
    {
      title: getSharkText("key.compare.name"),
      dataIndex: "acityName",
      key: "acityName",
      width: 220,
      render: (val: string, record: any, index: number) => (
        <div>
          {tableMode !== 2 ? (
            <Text type="secondary" className="expend-title">
              {isAirlinesMarketComparison
                ? !index
                  ? getSharkText("key.route_airport.name")
                  : getSharkText("key.route_city.name")
                : !index
                ? COMPARE_TYPE_VALUE_NAME[compareType]
                : getSharkText("key.yoy_increase.name")}
            </Text>
          ) : (
            <Text type="secondary">
              {isAirlinesMarketComparison
                ? getSharkText("key.route_city.name")
                : getSharkText("key.yoy_increase.name")}
            </Text>
          )}
        </div>
      ),
    },
    {
      title: isAirport
        ? getSharkText("key.numbers_of_airlines")
        : getSharkText("key.numbers_of_flights_carried"),
      dataIndex: isAirport ? "airline" : "flightCount",
      key: isAirport ? "airline" : "flightCount",
      width: 55,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index),
    },
    {
      title: getSharkText("key.number_of_flights"),
      dataIndex: "flightSorties",
      key: "flightSorties	",
      width: 66,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index),
    },
    {
      title: getSharkText("key.passenger_traffic"),
      dataIndex: "passengerTraffic",
      key: "passengerTraffic",
      width: 90,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index, "num", -1),
    },
    ...(!isAirport
      ? [
          {
            title: getSharkText("card.rpk_estimated.name"),
            dataIndex: "kiloPassengerTraffic",
            key: "kiloPassengerTraffic",
            width: 100,
            render: (val: number) =>
              Math.abs(val) > 10 * 1000 * 100
                ? U.abbreviateNumber(val)
                : showNum(val),
          },
        ]
      : []),
    {
      title: getSharkText("key.available_seats"),
      dataIndex: "transportCapacity",
      key: "transportCapacity",
      width: 100,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index, "num", -1),
    },
    ...(!isAirport
      ? [
          {
            title: getSharkText("card.ask_estimated.name"),
            dataIndex: "kiloTransportCapacity",
            key: "kiloTransportCapacity",
            width: 100,
            render: (val: number) =>
              Math.abs(val) > 10 * 1000 * 100
                ? U.abbreviateNumber(val)
                : showNum(val),
          },
        ]
      : []),
    {
      title: getSharkText("key.loadfactor"),
      dataIndex: "lf",
      key: "lf",
      width: 80,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index, "percentage"),
    },
    {
      title: getSharkText("key.flight_duration.hint"),
      dataIndex: "flightTime",
      key: "flightTime",
      width: 100,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index, "num", 0, false),
    },
    {
      title: getSharkText("key.oneway_average_price"),
      dataIndex: "price",
      key: "price",
      width: 80,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index, "price", 0, false),
    },
    {
      title: getSharkText("key.oneway_average_price_tax_excluded"),
      dataIndex: "priceSumOw",
      key: "priceSumOw",
      width: 100,
      sorter: (a: any, b: any) =>
        (a.priceSumOw ? a.priceSumOw : -1) - (b.priceSumOw ? b.priceSumOw : -1),
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: getSharkText("key.rt_avg_price.name"),
      dataIndex: "rtprice",
      key: "rtprice",
      width: 80,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index, "price", 0, false),
    },
    {
      title: getSharkText("key.rt_avg_price_tax_excluded.name"),
      dataIndex: "priceSumRt",
      key: "priceSumRt",
      width: 100,
      sorter: (a: any, b: any) =>
        (a.priceSumRt ? a.priceSumRt : -1) - (b.priceSumRt ? b.priceSumRt : -1),
      render: (val: number) => (
        <ValueNum type="price" value={val} isShowZero={false} />
      ),
    },
    {
      title: getSharkText("key.passenger_traffic_per_flight"),
      dataIndex: "passengers",
      key: "passengers",
      width: 80,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index),
    },
    {
      title: getSharkText("key.proportion_wide_body"),
      dataIndex: "wideBody",
      key: "wideBody",
      width: 80,
      render: (val: number, record: any, index: number) =>
        subComRender(val, index, "percentage"),
    },
    ...(isAirport
      ? [
          {
            title: getSharkText("key.revenue_per_psgr_km.name"),
            dataIndex: "passengerKilorm",
            key: "passengerKilorm",
            width: 80,
            sorter: (a: any, b: any) => a.passengerKilorm - b.passengerKilorm,
            render: (val: number) => showNum(val, "num", 4),
          },
        ]
      : []),
    ...(!isAirport
      ? [
          {
            title: (
              <>
                <span>{getSharkText("key.capacity.roi")}</span>&nbsp;
                <Popover content={getSharkText("key.capacity_roi_calculation")}>
                  <Text type="secondary">
                    <InfoCircleOutlined />
                  </Text>
                </Popover>
              </>
            ),
            dataIndex: "capacityProductionCost",
            key: "capacityProductionCost",
            width: 80,
            render: (val: number) => showNum(val, "num", 3),
          },
          {
            title: (
              <>
                <span>{getSharkText("key.revenue.roi")}</span>&nbsp;
                <Popover content={getSharkText("key.revenue_roi_calculation")}>
                  <Text type="secondary">
                    <InfoCircleOutlined />
                  </Text>
                </Popover>
              </>
            ),
            dataIndex: "incomeProductionCost",
            key: "incomeProductionCost",
            width: 80,
            render: (val: number) => showNum(val, "num", 3),
          },
        ]
      : []),
  ];

  const handleDownload = () => {
    const sheetData1: any = [];
    const sheetData2: any = [];
    const sheetData3: any = [];
    const sortedData = groupData.sort((a, b) =>
      a.passengerTraffic > b.passengerTraffic ? -1 : 1
    );
    sortedData.forEach((item: any) => {
      const obj1: any = {
        [getSharkText(
          "key.departure.name"
        )]: `${item.dcityName}(${item.dcityCode})`,
        [getSharkText(
          "key.arrival.name"
        )]: `${item.acityName}(${item.acityCode})`,
        [getSharkText("key.number_of_flights")]: showRawNum(item.flightSorties),
        [getSharkText("config_page_passenger_traffic_estimate")]: showRawNum(
          item.passengerTraffic
        ),
        [getSharkText("config_page_kilo_passenger_traffic_estimate")]:
          showRawNum(item.kiloPassengerTraffic),
        [getSharkText("config_page_seat_count_estimate")]: showRawNum(
          item.transportCapacity
        ),
        "座公里(估算)": showRawNum(item.kiloTransportCapacity),
        客座率: showRawNum(item.lf, "percentage"),
        "飞行时长(min)": showRawNum(item.flightTime),
        单程均价: showRawNum(item.price, "price"),
        "单程均价（不含税）": showRawNum(item.priceSumOw, "price"),
        往返均价: showRawNum(item.rtprice, "price"),
        "往返均价（不含税）": showRawNum(item.priceSumRt, "price"),
        单机载客数: showRawNum(item.passengers),
        宽体机占比: showRawNum(item.wideBody, "percentage"),
      };
      const obj2: any = {
        始发: `${item.dcityName}(${item.dcityCode})`,
        到达: `${item.acityName}(${item.acityCode})`,
        航班架次: showRawNum(item.compare.flightSorties),
        "客运量(估算)": showRawNum(item.compare.passengerTraffic),
        "客公里(估算)": showRawNum(item.compare.kiloPassengerTraffic),
        "座位数(估算)": showRawNum(item.compare.transportCapacity),
        "座公里(估算)": showRawNum(item.compare.kiloTransportCapacity),
        客座率: showRawNum(item.compare.lf, "percentage"),
        "飞行时长(min)": showRawNum(item.compare.flightTime),
        单程均价: showRawNum(item.compare.price, "price"),
        "单程均价（不含税）": showRawNum(item.compare.priceSumOw, "price"),
        往返均价: showRawNum(item.compare.rtprice, "price"),
        "往返均价（不含税）": showRawNum(item.compare.priceSumRt, "price"),
        单机载客数: showRawNum(item.compare.passengers),
        宽体机占比: showRawNum(item.compare.wideBody, "percentage"),
      };
      if (isAirport) {
        obj1.航司数量 = showRawNum(item.airline);
        obj2.航司数量 = showRawNum(item.compare.airline);
      } else {
        obj1.航班数量 = showRawNum(item.flightCount);
        obj2.航班数量 = showRawNum(item.compare.flightCount);
        obj1.运力产投比 = item.capacityProductionCost;
        obj1.收入产投比 = item.incomeProductionCost;
        obj2.运力产投比 = item.compare.capacityProductionCost;
        obj2.收入产投比 = item.compare.incomeProductionCost;
      }
      sheetData1.push(obj1);
      sheetData2.push(obj2);
      if (!isAirlinesMarketComparison) {
        const obj3: any = {
          [getSharkText(
            "key.departure.name"
          )]: `${item.dcityName}(${item.dcityCode})`,
          [getSharkText(
            "key.arrival.name"
          )]: `${item.acityName}(${item.acityCode})`,
          [getSharkText("key.number_of_flights")]: showRawNum(
            item.result.flightSorties,
            "percentage"
          ),
          [getSharkText("config_page_passenger_traffic_estimate")]: showRawNum(
            item.result.passengerTraffic,
            "percentage"
          ),
          [getSharkText("config_page_seat_count_estimate")]: showRawNum(
            item.result.transportCapacity,
            "percentage"
          ),
          [getSharkText("key.loadfactor")]: showRawNum(
            item.result.lf,
            "percentage"
          ),
          [getSharkText("key.flight_duration.hint")]: showRawNum(
            item.result.flightTime,
            "percentage"
          ),
          [getSharkText("key.oneway_average_price")]: showRawNum(
            item.result.price,
            "percentage"
          ),
          [getSharkText("key.oneway_average_price_tax_excluded")]: showRawNum(
            item.result.priceSumOw,
            "price"
          ),
          [getSharkText("key.rt_avg_price.name")]: showRawNum(
            item.result.rtprice,
            "percentage"
          ),
          [getSharkText("key.rt_avg_price_tax_excluded.name")]: showRawNum(
            item.result.priceSumRt,
            "price"
          ),
          [getSharkText("key.passenger_traffic_per_flight")]: showRawNum(
            item.result.passengers,
            "percentage"
          ),
          [getSharkText("key.proportion_wide_body")]: showRawNum(
            item.result.wideBody,
            "percentage"
          ),
        };
        if (isAirport) {
          obj3[getSharkText("key.numbers_of_airlines")] = showRawNum(
            item.result.airline,
            "percentage"
          );
        } else {
          obj3[getSharkText("key.numbers_of_flights_carried")] = showRawNum(
            item.result.flightCount,
            "percentage"
          );
        }
        obj3[getSharkText("key.capacity.roi")] =
          item.result.capacityProductionCost;
        obj3[getSharkText("key.revenue.roi")] =
          item.result.incomeProductionCost;
        sheetData3.push(obj3);
      } else {
        const obj3: any = {
          [getSharkText(
            "key.departure.name"
          )]: `${item.compare2.dcityName}(${item.compare2.dcityCode})`,
          [getSharkText(
            "key.arrival.name"
          )]: `${item.compare2.acityName}(${item.compare2.acityCode})`,
          [getSharkText("key.number_of_flights")]: showRawNum(
            item.compare2.flightSorties
          ),
          [getSharkText("config_page_passenger_traffic_estimate")]: showRawNum(
            item.compare2.passengerTraffic
          ),
          [getSharkText("config_page_seat_count_estimate")]: showRawNum(
            item.compare2.transportCapacity
          ),
          [getSharkText("key.loadfactor")]: showRawNum(
            item.compare2.lf,
            "percentage"
          ),
          [getSharkText("key.flight_duration.hint")]: showRawNum(
            item.compare2.flightTime
          ),
          [getSharkText("key.oneway_average_price")]: showRawNum(
            item.compare2.price,
            "price"
          ),
          "单程均价（不含税）": showRawNum(item.compare2.priceSumOw, "price"),
          往返均价: showRawNum(item.compare2.rtprice, "price"),
          "往返均价（不含税）": showRawNum(item.compare2.priceSumRt, "price"),
          单机载客数: showRawNum(item.compare2.passengers),
          宽体机占比: showRawNum(item.compare2.wideBody, "percentage"),
          航班数量: showRawNum(item.compare2.flightCount),
          运力产投比: item.compare2.capacityProductionCost,
          收入产投比: item.compare2.incomeProductionCost,
        };
        sheetData3.push(obj3);
      }
    });
    const sheet1 = XLSX.utils.json_to_sheet(sheetData1);
    const sheet2 = XLSX.utils.json_to_sheet(sheetData2);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, sheet1, "航线分析");
    const secondSheetName = isAirlinesMarketComparison
      ? getSharkText("key.air_route_airport.checkbox")
      : isAirport
      ? COMPARE_TYPE_PER_NAME[queryCondition.compareType]
      : COMPARE_TYPE_PER_NAME[airlinesQueryCondition.compareType];
    XLSX.utils.book_append_sheet(wb, sheet2, secondSheetName);
    if (!isAirlinesMarketComparison) {
      const sheet3 = XLSX.utils.json_to_sheet(sheetData3);
      XLSX.utils.book_append_sheet(
        wb,
        sheet3,
        getSharkText("key.yoy_increase.name")
      );
    } else {
      const sheet3 = XLSX.utils.json_to_sheet(sheetData3);
      XLSX.utils.book_append_sheet(
        wb,
        sheet3,
        getSharkText("key.air_route_city.checkbox")
      );
    }
    const workbookBlob = workbook2blob(wb);
    openDownloadDialog(
      workbookBlob,
      getSharkText("key.routes_analysis") + ".xlsx"
    );
  };

  return (
    <div id="analysisTable">
      <CustomTable
        handleDownload={handleDownload}
        useCompare={useCompare}
        dataSource={groupData}
        columns={columns}
        subColumns={subColumns}
        mode={tableMode}
        setMode={setTableMode}
        isAirlinesMarketComparison={isAirlinesMarketComparison}
        searchkeys={["dcityName", "acityName"]}
        searchPlaceholder={getSharkText("key.input_to_search_dep_arr")}
        moduleCode={moduleCode}
        chartTableCode={chartTableCode}
        disableDownload={
          isAirport && queryCondition.area.areaCode.split(",").length > 1
        }
      />
      <Modal
        className="airlines-detail-flightNO"
        title={getSharkText("key.self_operated_flights")}
        open={isShowModal}
        onCancel={() => setIsShowModal(false)}
        width={1000}
        footer={[
          <Button key="back" onClick={() => setIsShowModal(false)}>
            {getSharkText("key.close.button")}
          </Button>,
        ]}
      >
        <AirlinesFlightNOTable
          queryUrl={queryUrl}
          moduleCode={moduleCode}
          chartTableCode={chartTableCode}
          query={query}
          airlinesFlightArea={airlinesFlightArea}
        />
      </Modal>
    </div>
  );
};

export default AirlineTable;
