import React, { ReactElement, useState } from "react";
import { useFetch } from "Utils";
import { CLIENT_APP_ID, CLIENT_IP } from "../../FlightManageCommon";
import { message, Select, Spin } from "antd";
import useRefFunc from "Utils/useRefFunc";
import {
  AutoAdjustFlightStateType,
  SetAutoAdjustStatesRequestType,
} from "./GetAutoAdjustStates";
import useGlobalState from "Store";
import ubtUtils from "Utils/ubtUtils";

const AutoAdjustState = [
  "ALL",
  // "PRICE_CHANGE",
  // "PRICE_UNCHANGE",
  "NONE",
] as const;
type AutoAdjustType = (typeof AutoAdjustState)[number];
const AutoAdjustMap: Record<AutoAdjustType, string> = {
  ALL: "打开",
  // PRICE_CHANGE: "仅变价调舱 ON",
  // PRICE_UNCHANGE: "仅原价补舱 ON",
  NONE: "关闭",
};
const options = AutoAdjustState.map((a) => ({
  value: a,
  label: AutoAdjustMap[a],
}));

export interface AutoAdjustProps {
  autoAdjust: AutoAdjustFlightStateType;
}

/** 自动调舱模式设置组件 */
const AutoAdjust = (props: AutoAdjustProps): ReactElement => {
  const { autoAdjust } = props;
  const [globalState] = useGlobalState();
  const unitList = globalState.userInfo.unitList;
  const [selected, setSelected] = useState<AutoAdjustType | undefined>(
    autoAdjust.state || undefined
  );

  const [{ isLoading: isSaving }, saveFetch] = useFetch({
    url: "soaProxy",
    head: {
      moduleCode: "flight_manage_airlines",
      chartTableCode: "none",
    },
    ext: {},
    lazey: true,
    debugId: "SetAutoAdjustStates",
    onSuccess: (res) => {
      // @ts-ignore
      if (res.ResponseStatus.Ack === "Success") {
        const soaResponse = JSON.parse(res.responseBody);
        if (soaResponse.ResponseStatus.Ack === "Success") {
          const flight = soaResponse.flights[0];
          if (flight.isSetOk) {
            setSelected(soaResponse.flights[0].state);
          } else {
            message.error("set failed");
            ubtUtils.error("set failed");
          }
        } else {
          message.error("access SetAutoAdjustStates error");
          ubtUtils.error("access SetAutoAdjustStates error");
        }
      } else {
        message.error("soa proxy error");
        ubtUtils.error("soa proxy error");
      }
    },
  });
  const onChange = useRefFunc((v: string) => {
    const req: SetAutoAdjustStatesRequestType = {
      head: {
        clientAppID: CLIENT_APP_ID,
        clientIP: CLIENT_IP,
      },
      flights: [
        {
          flightno: autoAdjust.flightno,
          takeoffdate: autoAdjust.takeoffdate,
          seg: autoAdjust.seg,
          currentState: selected,
          targetState: v,
        },
      ],
      websiteUserID: globalState.userInfo.user.id.toString(),
      websiteUserName: globalState.userInfo.user.name,
      userGroup: unitList ? unitList[0].id.toString() : "-1",
    };
    saveFetch({
      ext: {
        serviceCode: "22299",
        operation: "SetAutoAdjustStates",
        requestBody: JSON.stringify(req),
      },
    });
  });
  return (
    <Spin spinning={isSaving}>
      <Select
        size="small"
        value={selected}
        onChange={onChange}
        options={options}
        disabled={!autoAdjust.isWithinRange}
        style={{ minWidth: 150 }}
      ></Select>
    </Spin>
  );
};
export default AutoAdjust;
