import { useRef } from "react";
import debounce from "lodash/debounce";

/**
 * 函数节流, 等待时间为毫秒
 * @param fn 延期执行的函数
 * @param waitMs 等待时间, 毫秒
 * @returns 可执行函数
 */
export const useDebounce = <T extends (...args: any) => void>(
  fn: T,
  waitMs = 1000
): T => {
  const func = useRef(fn);
  func.current = fn;
  const debounceWrapper = useRef(
    debounce((...args: any) => func.current?.(...args), waitMs)
  );
  return debounceWrapper.current as unknown as T;
};
