import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Card, Radio, Row, Spin, Empty, Table } from "antd";
import useGlobal from "Store";
import {
  FlightArea,
  CardItemData,
  ECompareTypeV2,
  IDownloadHeader,
} from "Interface";
import _ from "lodash";
import {
  durationFormatter,
  getComparePercentageVal,
  getProportionPercentageVal,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import {
  COMPARE_TYPE_MARKET_NAME,
  COMPARE_TYPE_PER_NAME,
  SERVER,
} from "Constants";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import "./index.css";
import {
  ITransferAnalysisExt,
  PERCENT_VALUES,
} from "Page/AI/TransferPolicy/TransferPolicyInterface";
import { SorterResult } from "antd/lib/table/interface";
interface IRouteTab {
  label: string;
  value: number;
  getRouteName: (item: any) => string;
}

const routeTabs: IRouteTab[] = [
  {
    label: getSharkText("key.route.name"),
    value: 0,
    getRouteName: (item: any) => `${item.dportName} - ${item.aportName}`,
  },
  {
    label: getSharkText("key.departure.name"),
    value: 1,
    getRouteName: (item: any) => item.dportName,
  },
  {
    label: getSharkText("transfer.transfer.name"),
    value: 3,
    getRouteName: (item: any) => item.tportName,
  },
  {
    label: getSharkText("key.arrival.name"),
    value: 2,
    getRouteName: (item: any) => item.aportName,
  },
];

interface IProps {
  title?: string;
  style?: React.CSSProperties;
  queryUrl: string;
  moduleCode: string;
  area: ITransferAnalysisExt;
  sortable?: boolean;
  selectItem?: (item: FlightArea | null) => void;
  setChartsType?: (type: number) => void;
  curCard?: CardItemData;
  setHasCityData?: (has: boolean) => void;
  server?: string;
  chartTableCode: string;
}

const TransferRanking: React.FC<IProps> = (props: IProps) => {
  const {
    server = SERVER,
    title = "",
    style,
    queryUrl,
    moduleCode,
    area,
    sortable = false,
    selectItem,
    curCard,
    setHasCityData,
    chartTableCode,
  } = props;
  const { query, filter } = area;
  const [routeType, setRouteType] = useState<IRouteTab>(routeTabs[0]);
  const [globalState] = useGlobal();
  const { airlinesQueryCondition } = globalState;
  const { compareType } = airlinesQueryCondition;
  const cardCode = curCard?.cardCode;
  const cardName = curCard?.cardName;
  const isDurationNum = cardCode === "transit_minutes";
  const isPercentVal = _.indexOf(PERCENT_VALUES, cardCode) > -1;
  // 排序类型 0= asc 1= desc
  const [sort, setSort] = useState(1);
  // 按什么排序 0=按总量 1=同比增长 2=环比增长
  const [orderByType, setOrderByType] = useState<number>(0);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server,
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: airlinesQueryCondition,
    ext: {
      filter: { ...filter },
      orderSort: 0,
      cardCode,
      orderByType,
    },
    lazey: true,
  });

  const queryWithExt = useMemo(
    () => ({ ...airlinesQueryCondition, area: query.area }),
    [airlinesQueryCondition, query.area]
  );

  const refetch = useCallback(() => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      query: queryWithExt,
      ext: {
        filter: { ...filter },
        orderSort: sort,
        cardCode,
        orderByType,
        type: routeType.value,
      },
    });
  }, [
    cardCode,
    chartTableCode,
    doFetch,
    filter,
    moduleCode,
    orderByType,
    queryWithExt,
    routeType.value,
    sort,
  ]);

  useEffect(() => {
    if (selectItem) {
      selectItem(null);
    }
    if (setHasCityData) {
      setHasCityData(false);
    }
    refetch();
  }, [refetch, selectItem, setHasCityData]);

  useEffect(() => {
    if (data && setHasCityData) {
      setHasCityData(true);
    }
  }, [data, setHasCityData]);

  const onTableChange = useCallback(
    (
      pagination: unknown,
      filters: unknown,
      sorter: SorterResult<any> | Array<SorterResult<any>>
    ) => {
      if (Array.isArray(sorter)) {
        return;
      }
      setSort(sorter.order === "ascend" ? 0 : 1);
      setOrderByType(sorter.field === "value" ? 0 : 1);
    },
    []
  );

  const columns: IDownloadHeader[] = useMemo(() => {
    return [
      {
        title: getSharkText("transfer.ranking.name"),
        dataIndex: "",
        render: (val: number, r: any, i: number) => i + 1,
      },
      {
        title: routeType.label,
        dataIndex: "route",
        render: (val: number, r: any) => routeType.getRouteName(r),
      },
      {
        title: getSharkText(`card.${cardCode}.name`),
        dataIndex: "value",
        sorter: sortable ? true : undefined,
        render: (val: number) => {
          const computeValue = _.round(val);
          if (isDurationNum) {
            return durationFormatter(computeValue * 60);
          } else if (isPercentVal) {
            return <ValueNum type="percentage" value={computeValue} />;
          } else {
            return <ValueNum type="num" value={computeValue} />;
          }
        },
      },
      {
        title:
          cardCode === "transit_persons"
            ? COMPARE_TYPE_MARKET_NAME[compareType]
            : COMPARE_TYPE_PER_NAME[compareType],
        dataIndex: "valueCompare",
        sorter: sortable ? true : undefined,
        render: (val: number, item: any) => {
          let rst = 0;
          if (compareType === ECompareTypeV2.MARKET_COMPARE) {
            rst = getProportionPercentageVal(item.value, item.valueCompare);
          } else {
            rst = getComparePercentageVal(item.value, item.valueCompare);
          }
          return <ValueNum type="percentage" value={rst} />;
        },
      },
    ];
  }, [
    routeType,
    cardName,
    sortable,
    cardCode,
    compareType,
    isDurationNum,
    isPercentVal,
  ]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const changeRouteType = (e: any) => {
    setRouteType(e.target.value);
  };
  return (
    <Spin spinning={isLoading}>
      <Card className="ranking-card" style={style}>
        {title && <span className="module-title">{title}</span>}
        <Row justify="space-between">
          <Radio.Group
            value={routeType}
            onChange={changeRouteType}
            style={{ marginLeft: 10 }}
          >
            {routeTabs.map((item: any, index: number) => (
              <Radio value={item} key={index}>
                {item.label}
              </Radio>
            ))}
          </Radio.Group>
        </Row>
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <Table
            columns={columns}
            dataSource={data}
            size="small"
            pagination={false}
            onChange={onTableChange}
          ></Table>
        )}
      </Card>
    </Spin>
  );
};

export default TransferRanking;
