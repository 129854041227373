import React from "react";
import Button from "Components/Official/Button";
import { ReactComponent as Number1 } from "assets/svg/01.svg";
import { ReactComponent as Number2 } from "assets/svg/02.svg";
import { ReactComponent as Number3 } from "assets/svg/03.svg";
import { ReactComponent as Number4 } from "assets/svg/04.svg";
import { ReactComponent as Number5 } from "assets/svg/05.svg";
import { ReactComponent as TeamLogo1 } from "assets/svg/team1.svg";
import { ReactComponent as TeamLogo2 } from "assets/svg/team2.svg";
import { ReactComponent as TeamLogo3 } from "assets/svg/team3.svg";
import Partner from "Components/Official/Partner";
import ContactUs from "Components/Official/ContactUs";
import Side from "Components/Official/Side";
import "./index.scss";

const About: React.FC = () => {
  return (
    <>
      <div className="top-banner about-banner">
        <div className="base-con">
          <div className="banner-con">
            <h1>关于我们</h1>
            <h4>FlightAI——数据分析、市场洞察与智能解决方案提供者</h4>
            <a href="#contactUs">
              <Button fill>联系我们</Button>
            </a>
          </div>
        </div>
      </div>
      <div className="base-mode who-mode">
        <div className="base-con">
          <h2>我们是谁</h2>
          <h5 className="hint">
            FlightAI是携程旗下品牌，专注于航空业数据解决方案。
            <br />
            我们与航司、机场等行业伙伴深度合作，致力于通过大数据，解决行业实际问题，赋能业务增长。经过多年的沉淀，我们积累了丰富的数据资产和雄厚的技术实力，在航空领域大数据应用功能方面拥有多项专利发明
          </h5>
          <div className="who-banner" />
        </div>
      </div>

      <div className="base-mode why-mod">
        <div className="why-title">
          <div className="base-con">
            <h2 className="base-title">为什么选择我们</h2>
            <h5 className="base-title-hint">
              以月报、专题咨询分析等形式，提供专业数据咨询服务
              <br />
              为日常数据监测及解读、航线规划、市场营销、客源引流等决策工作提供数据支持
            </h5>
          </div>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info why-list-bg1">
              <Number1 className="list-number" />
              <div className="list-title">独家OTA数据</div>
              <p>
                基于OTA独家数据，采集用户行为数据，反映最真实的市场需求情况。
                <br />
                例如搜索数据，可以提前预判市场需求，发掘潜在市场机会，帮助做出策略调整。
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-even-list">
          <div className="base-con">
            <div className="list-info why-list-bg2">
              <Number2 className="list-number" />
              <div className="list-title">全面的市场数据</div>
              <p>
                拥有丰富全面的民航数据，帮助进行宏观趋势分析，行业情况解读。
                <br />
                全方位了解运力供给、时刻安排，进行更透彻的数据分析。
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info why-list-bg3">
              <Number3 className="list-number" />
              <div className="list-title">丰富的用户画像数据</div>
              <p>
                基于用户真实行为，结合实际应用场景，建立用户画像标签体系。
                <br />
                通过判断用户常驻地、出行目的、用户偏好等特征，深度挖掘用户需求，发掘市场潜在机会。
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-even-list">
          <div className="base-con">
            <div className="list-info why-list-bg4">
              <Number4 className="list-number" />
              <div className="list-title">先进的算法模型</div>
              <p>
                通过先进的算法模型，能够准确的从海量数据中挖掘特征，
                <br />
                进行未来趋势预测，监测异常数据，提供参考建议，赋能业务场景。
              </p>
            </div>
          </div>
        </div>
        <div className="base-list base-odd-list">
          <div className="base-con">
            <div className="list-info why-list-bg5">
              <Number5 className="list-number" />
              <div className="list-title">强大的技术支撑</div>
              <p>
                拥有专业的技术团队，高效开发、敏捷迭代，确保项目落地的质量和效率；
                <br />
                通过强大的技术能力，实时处理海量数据，及时提供策略调整建议和异常数据告警
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="base-mode team-mode">
        <div className="base-con">
          <h2 className="base-title">我们的团队</h2>
          <h5 className="base-title-hint">
            团队构成多元化，具备专业的技术能力、敏锐的数据洞察力；拥有丰富的项目经验、深刻的行业理解
          </h5>
          <ul className="team-list">
            <li>
              <i className="team-logo">
                <TeamLogo1 />
              </i>
              <h4>技术研发团队</h4>
              <p>
                专业高效的技术团队，保障数据质量可靠，系统运行稳定，项目落地及时
              </p>
              <ol>
                <li>数据仓库工程师</li>
                <li>软件开发工程师</li>
              </ol>
            </li>
            <li>
              <i className="team-logo">
                <TeamLogo2 />
              </i>
              <h4>业务分析团队</h4>
              <p>
                丰富的行业背景、项目经验，善于通过数据发掘业务增长点，提出切实可行的解决方案
              </p>
              <ol>
                <li>航空业专家</li>
                <li>数据分析师</li>
              </ol>
            </li>
            <li>
              <i className="team-logo">
                <TeamLogo3 />
              </i>
              <h4>算法模型团队</h4>
              <p>
                深入理解业务，善于将业务问题抽象为数据模型运用先进的算法解决实际问题
              </p>
              <ol>
                <li>数据挖掘工程师</li>
                <li>算法工程师</li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
      <ContactUs />
      <Partner />
      <Side />
    </>
  );
};

export default About;
