import { AirlineItem, Area } from "Interface";
import { Col, Popover, Row } from "antd";
import React, { ReactElement, useState } from "react";
import AirportSearch from "../AirportSearch";
import AirportRanking from "./AirportRanking";
import AirportChart from "./AirportChart";
import AirportTable from "./AirportTable";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { getSharkText } from "Utils/i18nGlobal";

/** 航班计划-正点率 */
const AirportOnTime = (): ReactElement => {
  const [area, setArea] = useState<Area>({
    areaType: 0,
    areaCode: "",
    areaName: "",
  });
  const [airline, setAirline] = useState<AirlineItem[]>([]);
  return (
    <div className="content-white">
      <h1 style={{ color: "#1890FF" }}>
        {getSharkText("config_page_ontime_title")}
        <Popover
          content={
            <div style={{ width: 400 }}>
              <p>
                1.
                航班正点率为（在该机场正点起飞或正点降落的航班数量）/实际执行数量，若航班在该机场（实际起飞时间-计划起飞时间）大于30min或在该机场（实际到达时间-计划到达时间）大于30min，则判断为延误，
                航班正点率=1-航班延误率
              </p>
              <p>
                2.
                航班靠桥率为（在该机场登机或下机靠桥的航班数量）/实际执行数量，若勾选进港或出港，则只对对应类型的航线进行统计
              </p>
              <p>
                3.
                因未来航班尚未实际执行，本模块仅统计历史起飞日期的实际执行航班，对于缺失数据，将在分子分母中同时剔除。
              </p>
            </div>
          }
        >
          <QuestionCircleOutlined style={{ marginLeft: 5 }} />
        </Popover>
      </h1>
      <AirportSearch setArea={setArea} setAirlines={setAirline} />
      <Row
        gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
        style={{ marginTop: 10, minHeight: 300 }}
      >
        <Col lg={17} md={12} xs={24}>
          <AirportChart
            area={area}
            airline={airline.map((a) => a.airlinesCode).join(",")}
          />
        </Col>
        <Col lg={7} md={12} xs={24}>
          <AirportRanking
            area={area}
            airline={airline.map((a) => a.airlinesCode).join(",")}
          />
        </Col>
      </Row>
      <div>
        <AirportTable
          area={area}
          airline={airline.map((a) => a.airlinesCode).join(",")}
        />
      </div>
    </div>
  );
};
export default AirportOnTime;
