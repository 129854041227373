import React from "react";
import {
  genSeriesByDimensions,
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  showNum,
} from "Utils";
import { AggCode, ISeries } from "Interface";
import { round } from "lodash";
import useGlobalState from "Store";
import { ICard } from "Page/AI/IndustryTrend/Interface";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  day: string;
  value: number;
  compareValue: number;
  marketValue: number;
  marketCompareValue: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  aggCode: AggCode;
  seriesName: string;
  cardName: string;
  isPercentVal: boolean;
  card: ICard;
  totalSeries: ISeries[];
}

const BarCharts: React.FC<BarChartsProps> = (props: BarChartsProps) => {
  const { data, height = 300, aggCode, isPercentVal, totalSeries } = props;
  const [globalState] = useGlobalState();
  const { queryCondition } = globalState;
  const { startDate, endDate } = queryCondition;
  const isZero = (val: number) => val === 0;

  const series =
    data && data.length
      ? genSeriesByDimensions(Object.keys(data[0]), totalSeries)
      : totalSeries;

  const option = {
    color: ["#01C5DB", "#177DFE", "#FC9B4F", "#82b1ff"],
    dataset: {
      source: data,
    },
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const dayVal = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          false
        );
        const day = aggCode === AggCode.total ? dayVal : "起飞日期：" + dayVal;
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const dimIndex = item.encode && item.encode.y ? item.encode.y[0] : 1;
          const dimName = item.dimensionNames[dimIndex];
          const val = `${item.seriesName}: ${
            !isZero(item.data[dimName])
              ? dimName.toLowerCase().indexOf("yoy") >= 0
                ? isPercentVal
                  ? showNum(item.data[dimName], "percentage")
                  : showNum(item.data[dimName] * 100, "percentage")
                : isPercentVal
                ? showNum(round(item.data[dimName], 1), "percentage")
                : round(item.data[dimName])
              : "-"
          }`;

          dom = dom + "<br />" + dot + val;
        });
        dom = day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    yAxis: [
      {
        position: "left",
        type: "value",
        splitLine: false,
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) =>
            `${isPercentVal ? value + "%" : value} `,
        },
        axisTick: {
          show: false,
        },
      },
      {
        position: "right",
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: isPercentVal
            ? (value: number) => `${value.toFixed(1)}%`
            : (value: number) => `${(value * 100).toFixed(2)}%`,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series,
  };

  return (
    <EchartsReactBase
      notMerge={true}
      option={option}
      theme="default"
      style={{ height }}
    />
  );
};

export default BarCharts;
