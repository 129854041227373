import React, {
  useState,
  useEffect,
  useRef,
  ReactNode,
  ReactElement,
} from "react";
import { Row, Col, Result, Affix } from "antd";
import useGlobal from "Store";
import Page from "Layout/Page";
import { MODULE_CODE, CHART_TABLE_CODE, QUERY_URL } from "./fetchCode";
import { Area, CardItemData, TransferFlightArea } from "Interface";
import { DEFAULT_AREA } from "Constants";
import TransferBarLineCharts from "./AirlineComponents/TransferBarLineCharts";
import TransferTable from "./AirlineComponents/TransferTable";
import SearchArea from "./AirlineComponents/SearchArea";
import TransferCard from "./AirlineComponents/TransferCard";
import TransferRanking from "./AirlineComponents/TransferRanking";
import UpdateTime from "Components/UpdateTime";
import { ITransferAnalysisExt } from "../TransferPolicy/TransferPolicyInterface";
import { getServer } from "Service/server";
import HeaderAlert from "Components/HeaderAlert";
import TopSearchArea from "./AirlineComponents/TopSearchArea";
import { BAR_CODE } from "./AirlineCommon";
import TransferAdvantage from "./Components/TransferAdvantage";
import TransferAlert from "./AlertComponents/TransferAlert";
import { getSharkText } from "Utils/i18nGlobal";

const defaultFlightAreaQueryExt: TransferFlightArea = {
  departArea: DEFAULT_AREA,
  arriveArea: DEFAULT_AREA,
  sameAirline: 1,
  transferArea: DEFAULT_AREA,
  airline1: 0,
  airline2: 0,
};
const defaultCard: CardItemData = {
  cardCode: "transit_persons",
  cardName: getSharkText("config_page_transfer_persons"),
  cardHint: "",
  cardValue: 0,
  compareValue: 0,
  avgValue: 0,
  yoyValue: 0,
};

interface IAirportIndexProps {
  isDemo: boolean;
}

const AirlineIndex = (props: IAirportIndexProps): ReactElement => {
  const { isDemo } = props;
  const [flightAreaQueryExt, setFlightAreaQueryExt] = useState<
    TransferFlightArea | undefined
  >();
  const [transferAnalysisExt, setTransferAnalysisExt] =
    useState<ITransferAnalysisExt | null>(null);
  const [transAirport] = useState<Area>();
  const [alertMessage, setAlertMessage] = useState<ReactNode | string>("");
  const [curCard, setCurCard] = useState<CardItemData>(defaultCard);
  const curCardCode = curCard.cardCode;
  const [chartType, setChartType] = useState<string>("barline");
  const [loading, setLoading] = useState(false);
  const [globalState] = useGlobal();
  const containerRef = useRef<HTMLDivElement>(null);
  const { systemType, airlinesQueryCondition } = globalState;
  const { flightClass } = airlinesQueryCondition;
  const server = getServer(systemType);

  useEffect(() => {
    if (!BAR_CODE.includes(curCard.cardCode) && chartType !== "barline") {
      setChartType("barline");
    }
  }, [curCard, chartType]);

  useEffect(() => {
    const obj: any = {
      query: {
        area: transAirport || DEFAULT_AREA,
      },
      filter: flightAreaQueryExt && flightAreaQueryExt,
    };
    setTransferAnalysisExt(obj);
  }, [flightAreaQueryExt, transAirport]);

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setFlightAreaQueryExt(defaultFlightAreaQueryExt);
    setTimeout(() => setLoading(false));
  }, [flightClass]);

  const onCardChange = (data: CardItemData) => {
    setCurCard(data);
  };

  return (
    <Page defaultDateMode="lastMonth" needToolBar={false}>
      <div className="content-white">
        {isDemo ? <HeaderAlert /> : undefined}
        <TopSearchArea alertMessage={alertMessage} />
      </div>
      <Affix offsetTop={67}>
        <div className="content-white">
          <SearchArea
            queryUrl={QUERY_URL[systemType].compareList}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].compareList}
            setFlightAreaQueryExt={setFlightAreaQueryExt}
          />
        </div>
      </Affix>
      <div
        id="transfer-container"
        ref={containerRef}
        style={{ overflow: "scroll" }}
      >
        {loading ? undefined : transferAnalysisExt && flightAreaQueryExt ? (
          <>
            <div id="transfer-trend" className="content-white">
              <TransferCard
                queryUrl={QUERY_URL[systemType].card}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].card}
                setCurCard={onCardChange}
                selectedCode={curCardCode}
                transferAnalysisExt={transferAnalysisExt}
              />
              <Row
                gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
                style={{ marginTop: 0 }}
              >
                <Col xxl={14} lg={24}>
                  <TransferBarLineCharts
                    queryUrl={QUERY_URL[systemType][chartType]}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType][chartType]}
                    cardCode={curCardCode}
                    chartType={chartType}
                    setChartType={setChartType}
                    transferAnalysisExt={transferAnalysisExt}
                  />
                </Col>
                <Col xxl={10} lg={24}>
                  <TransferRanking
                    title={getSharkText("core_overview.ranking_title.airline")}
                    server={server}
                    queryUrl={QUERY_URL[systemType].top}
                    moduleCode={MODULE_CODE[systemType]}
                    chartTableCode={CHART_TABLE_CODE[systemType].top}
                    style={{ marginTop: 0 }}
                    sortable
                    curCard={curCard}
                    area={transferAnalysisExt}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <TransferAdvantage
                    transferAnalysisExt={transferAnalysisExt}
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col>
                  <TransferAlert transferAnalysisExt={transferAnalysisExt} />
                </Col>
              </Row>
            </div>
            <div
              id="transfer-table"
              className="content-white"
              style={{ height: "980px" }}
            >
              <TransferTable
                queryUrl={QUERY_URL[systemType].table}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].table}
                transferAnalysisExt={transferAnalysisExt}
              />
            </div>
          </>
        ) : (
          <Result
            title={getSharkText("config_page_please_select_departure_arrival")}
          />
        )}
      </div>
    </Page>
  );
};

export default AirlineIndex;
