import { Alert, Col, Radio, RadioChangeEvent, Row, Select } from "antd";
import { TRAVIX_COUNTRY } from "Constants";
import Page from "Layout/Page";
import React, { ReactElement, useCallback, useState } from "react";
import ChannelType from "../Components/ChannelType";
import Market from "../Components/Market";
import Sales from "../Components/Sales";

const { Option } = Select;

/**
 * Component description
 *
 */

const msg =
  "For alignment with IATA DDS data upon market share calculation, “Pax” numbers in this dashboard are doubled for round-trip tickets.";

const TravixOverview = (): ReactElement => {
  const [marketType, setMarketType] = useState<number>(1);
  const [country, setCountry] = useState<string>();
  const onCountryChange = useCallback((v: string) => {
    setMarketType(-1);
    setCountry(v);
  }, []);
  const onMarketTypeChange = useCallback((v: RadioChangeEvent) => {
    setCountry(undefined);
    setMarketType(v.target.value);
  }, []);
  return (
    <Page defaultDateMode="none" needToolBar={false}>
      <div className="content-white">
        <Alert
          banner
          showIcon
          type="warning"
          message="All dates/month/year are based on departure dates."
        ></Alert>
        <Alert banner showIcon type="info" message={msg}></Alert>
        <Row style={{ padding: 20 }} gutter={[20, 20]}>
          <Col>POC</Col>
          <Col>
            <Radio.Group value={marketType} onChange={onMarketTypeChange}>
              <Radio value={1}>14 POC</Radio>
              <Radio value={2}>5 POC</Radio>
            </Radio.Group>
          </Col>
          <Col>Country</Col>
          <Col>
            <Select
              value={country}
              showSearch
              onChange={onCountryChange}
              size="small"
              style={{ width: 100 }}
              allowClear
            >
              {TRAVIX_COUNTRY.map((c) => (
                <Option value={c} key={c}>
                  {c}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
      <div className="content-white" style={{ marginTop: 0, marginBottom: 0 }}>
        <Sales panelType="overview" country={country} marketType={marketType} />
      </div>
      <div className="content-white">
        <Market
          panelType="overview"
          country={country}
          marketType={marketType}
        />
      </div>
      <div className="content-white">
        <ChannelType
          panelType="overview"
          country={country}
          marketType={marketType}
        />
      </div>
    </Page>
  );
};
export default TravixOverview;
