import { DATE_FORMAT } from "Constants";
import { IDownloadHeader } from "Interface";
import moment from "moment";
import { showNum } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { EPanelType } from "../WingonCommon";
import { WingonFilter, WingonTable } from "@ctrip/flt-bi-flightai-partners";

export const getColumns = (
  panelType: EPanelType,
  ext: WingonFilter
): Array<IDownloadHeader<WingonTable>> => {
  const base: Array<IDownloadHeader<WingonTable>> = [
    {
      title:
        ext?.axisType === 0
          ? getSharkText("config_page_query_booking_date")
          : getSharkText("config_page_departure_date"),
      dataIndex: "day",
      sorter: (a: WingonTable, b: WingonTable) =>
        a.day?.localeCompare(b.day ?? "") ?? 0,
      render: (val: string) => moment(val).format(DATE_FORMAT),
    },
    {
      title: getSharkText("key.departure.name"),
      dataIndex: "dcityName",
    },
    {
      title: getSharkText("key.arrival.name"),
      dataIndex: "acityName",
    },
    {
      title: getSharkText("config_page_engine"),
      dataIndex: "bookingChannel",
    },
    {
      title: getSharkText("subchannel"),
      dataIndex: "channel",
    },
  ];
  if (panelType === 0) {
    return [
      ...base,
      {
        title: getSharkText("config_page_interface"),
        dataIndex: "interfaceName",
      },
      {
        title: getSharkText("config_page_query_total"),
        dataIndex: "searchCount",
        sorter: (a: WingonTable, b: WingonTable) =>
          (a.searchCount || 0) - (b.searchCount || 0),
      },
      {
        title: getSharkText("config_page_success_count"),
        dataIndex: "successCount",
        sorter: (a: WingonTable, b: WingonTable) =>
          (a.successCount || 0) - (b.successCount || 0),
      },
      {
        title: getSharkText("config_page_success_rate"),
        dataIndex: "ratio",
        sorter: (a: WingonTable, b: WingonTable) =>
          (a.ratio || 0) - (b.ratio || 0),
        render: (v: number) => showNum(v * 100, "percentage"),
      },
    ];
  } else {
    return [
      ...base,
      {
        title: getSharkText("config_page_booking_amount"),
        dataIndex: "orderCount",
        sorter: (a: WingonTable, b: WingonTable) =>
          (a.orderCount || 0) - (b.orderCount || 0),
      },
      {
        title: getSharkText("config_page_booking_ratio"),
        dataIndex: "ratio",
        render: (v: number) => showNum(v, "num", 2),
        sorter: (a: WingonTable, b: WingonTable) =>
          (a.ratio || 0) - (b.ratio || 0),
      },
    ];
  }
};

export const getDownloadColumns = (
  panelType: EPanelType
): IDownloadHeader[] => {
  const base: IDownloadHeader[] = [
    {
      title: getSharkText("key.date.flightplan"),
      dataIndex: "day",
      render: (v: string) => moment(v).format(DATE_FORMAT),
    },
  ];

  const columns: Record<EPanelType, IDownloadHeader[]> = {
    0: [
      ...base,
      {
        title: getSharkText("config_page_query_total"),
        dataIndex: "value",
      },
      {
        title: getSharkText("config_page_success_count"),
        dataIndex: "compareValue",
      },
      {
        title: getSharkText("config_page_success_rate"),
        dataIndex: "totalValue",
        render: (v: number) => showNum(v * 100, "percentage"),
      },
    ],
    1: [
      ...base,
      {
        title: getSharkText("config_page_booking_amount"),
        dataIndex: "value",
      },
      {
        title: getSharkText("config_page_booking_ratio"),
        dataIndex: "compareValue",
      },
    ],
  };
  return columns[panelType];
};
