import React, { useMemo, useState } from "react";
import { InfoCircleOutlined, SwapOutlined } from "@ant-design/icons";
import { Card, Divider, Popover, Typography } from "antd";
import Trend from "../Trend";
import { CardItemData, ECompareTypeV2, SystemType } from "Interface";
import _ from "lodash";
import {
  calculateCardsContrastVal,
  getCardTrendText,
  isNumeric,
  showNum,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import { getCardGroupKey } from "./Cards";

export interface ICardItemProps {
  data: CardItemData | CardItemData[];
  selectedCode: string;
  setCurCard: (card: CardItemData) => void;
  compareType?: ECompareTypeV2;
  systemType?: SystemType;
}

const { Text } = Typography;

const CardItem: React.FC<ICardItemProps> = (props: ICardItemProps) => {
  const { data, setCurCard, selectedCode, systemType, compareType } = props;
  const [currentCardIdx, setCurrentCardIdx] = useState<number>(() => {
    if (Array.isArray(data) && data.length > 1) {
      const key = getCardGroupKey(data);
      const selected = localStorage.getItem(key);
      if (selected !== null) {
        return Number(selected);
      }
    }
    return 0;
  });
  const item = useMemo(() => {
    return Array.isArray(data) ? data[currentCardIdx] : data;
  }, [currentCardIdx, data]);
  const config = item.feCardConfig;
  const { cardCode, cardName, cardValue, cardHint, compareValue } = item;
  const isAirlinesMarketComparison =
    systemType === 2 && compareType === ECompareTypeV2.MARKET_COMPARE;

  /**
   * 卡片中央大数字
   */
  const mainValue = useMemo(() => {
    const value = item.cardValue;
    if (config?.main?.value?.formatter) {
      return config.main.value.formatter(value, item);
    }
    return { value, label: showNum(value) };
  }, [config?.main?.value, item]);

  const trendName = useMemo(() => {
    const originalName = getCardTrendText(cardCode, systemType, compareType);
    if (config?.trend?.name?.formatter) {
      return config.trend.name.formatter(originalName, item, compareType);
    }
    return originalName;
  }, [cardCode, compareType, config?.trend?.name, item, systemType]);
  const trendValue = useMemo(() => {
    if (!isNumeric(cardValue) || !isNumeric(compareValue)) {
      return undefined;
    }
    const originalValue =
      calculateCardsContrastVal(
        cardValue,
        compareValue,
        compareType,
        systemType,
        cardCode
      ) / 100;
    if (config?.trend?.value?.formatter) {
      return config.trend.value.formatter(originalValue, item, compareType);
    }
    return {
      value: originalValue,
      label: showNum(originalValue * 100, "percentage"),
    };
  }, [
    cardCode,
    cardValue,
    compareType,
    compareValue,
    config?.trend?.value,
    item,
    systemType,
  ]);

  const avg = useMemo(() => {
    if (config?.avg?.value?.formatter) {
      return config.avg.value.formatter(item);
    }
    return { value: item.avgValue, label: showNum(item.avgValue) };
  }, [config?.avg?.value, item]);

  const isMarketComparison = [
    "passenger_traffic",
    "transport_capacity",
    "flight_sorties",
    "kilo_passenger_traffic",
    "kilo_transport_capacity",
  ].includes(cardCode);
  const isShowEmpty = cardValue === 0;
  const isShowCardFlag = (): boolean => {
    if (
      !trendValue ||
      !_.isFinite(trendValue.value) ||
      trendValue.value === 0 ||
      isShowEmpty
    ) {
      return false;
    } else {
      return isAirlinesMarketComparison ? !isMarketComparison : true;
    }
  };

  const switchCard = useRefFunc((e: any) => {
    if (Array.isArray(data)) {
      const nextIdx = (currentCardIdx + 1) % data.length;
      const key = getCardGroupKey(data);
      localStorage.setItem(key, nextIdx.toString());
      setCurrentCardIdx(nextIdx);
      if (selectedCode === item.cardCode) {
        setCurCard(data[nextIdx]);
      }
      // 需要阻止事件冒泡, 防止卡片点击事件被触发
      e.stopPropagation();
    }
  });

  const handleSelectCard = () => {
    setCurCard(item);
  };

  const switchInfo = useMemo(() => {
    if (Array.isArray(data) && data.length) {
      return (
        <div>
          <span style={{ fontSize: "90%", color: "#aaa" }}>
            {getSharkText("card.switch_card.button")}
          </span>
          {data.map((d) =>
            d.cardCode === cardCode ? (
              <div key={d.cardCode}>
                <strong>{d.cardName}</strong>
              </div>
            ) : (
              <div style={{ color: "#aaa" }} key={d.cardCode}>
                {d.cardName}
              </div>
            )
          )}
        </div>
      );
    }
  }, [cardCode, data]);

  return (
    <Card
      id={cardCode}
      size="small"
      bordered={false}
      className={`card ${selectedCode === cardCode ? "cur" : ""}`}
      hoverable={true}
      onClick={handleSelectCard}
    >
      <div className="card-title">
        <div className="title">{getSharkText(`card.${cardCode}.name`)}</div>
        <div>
          {Array.isArray(data) && data.length > 1 ? (
            <Popover content={switchInfo}>
              <SwapOutlined onClick={switchCard} style={{ marginRight: 10 }} />
            </Popover>
          ) : undefined}
          <Popover
            content={<span>{getSharkText(`card.${cardCode}.hint`)}</span>}
          >
            {cardHint && <InfoCircleOutlined />}
          </Popover>
        </div>
      </div>
      {isShowEmpty ? (
        <div className="ant-statistic-content">
          <span className="ant-statistic-content-value">
            <span className="ant-statistic-content-value-int">-</span>
          </span>
        </div>
      ) : (
        <div style={{ fontSize: 24 }}>{mainValue.label}</div>
      )}
      <Trend
        flag={compareValue < cardValue ? "up" : "down"}
        showFlag={isShowCardFlag()}
      >
        <Text className="card-text">
          {trendName}&nbsp;
          {isShowEmpty ? "-" : trendValue?.label || ""}
        </Text>
      </Trend>
      <Divider style={{ margin: "6px 0" }} />
      <Text className="card-text">
        {getSharkText("card.daily_average")}&nbsp; {avg.label}
      </Text>
    </Card>
  );
};

export default CardItem;
