import React, {
  createRef,
  MutableRefObject,
  ReactElement,
  useMemo,
  useRef,
  useState,
} from "react";
import { Col, Row, Space, Typography } from "antd";
import { ChartType, RevertChartTypeOptions } from "../common";
import useRefFunc from "Utils/useRefFunc";
import { ArrowDownOutlined } from "@ant-design/icons";
import { IDownloadHeader, ISeries } from "Interface";
import { DPMonitorFilter } from "@ctrip/flt-bi-flightai-airlines";
import Chart, { ChartHandle } from "./Chart";
import { assign, cloneDeep } from "lodash";
import { getSharkText } from "Utils/i18nGlobal";

export interface IPanelProps {
  title?: string;
  filter: DPMonitorFilter;
  type: string;
  baseSeries: ISeries[];
  extraSeries?: any;
  tableColumns: IDownloadHeader[];
  panelFilterComponent?: Array<
    (ref: MutableRefObject<any>, onChange: (v: any) => void) => ReactElement
  >;
  moduleCode: string;
  chartTableCode: string;
}

/**
 * 同环比缺失率/建议比例/小舱设置监控
 */
const Panel = (props: IPanelProps): ReactElement => {
  const {
    title,
    filter,
    type,
    baseSeries,
    tableColumns,
    extraSeries,
    panelFilterComponent = [],
    moduleCode,
    chartTableCode,
  } = props;
  const [chartType, setChartType] = useState<ChartType>(ChartType.Line);
  const chartRef = useRef<ChartHandle>(null);
  const filterRefs = useMemo(
    () => panelFilterComponent.map(() => createRef()),
    [panelFilterComponent]
  );
  const [flag, setFlag] = useState<number>(1);

  const handleChangeChartType = useRefFunc(() => {
    if (chartType === ChartType.Line) {
      setChartType(ChartType.Table);
    } else {
      setChartType(ChartType.Line);
    }
  });
  const chartTypeContent = RevertChartTypeOptions[chartType];
  const handleDownload = useRefFunc(() => {
    if (chartRef && chartRef.current) {
      chartRef.current.download(
        title ||
          getSharkText(
            "config_page_PaAiMoCoPa_price_adjustment_advice_monitoring_panel"
          )
      );
    }
  });

  const warpFilter = useMemo(() => {
    const warpNew = cloneDeep(filter);
    filterRefs.forEach((r) => {
      assign(warpNew, r.current);
    });
    return warpNew;
    // magic flag
  }, [filter, filterRefs, flag]);

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className="price-alert-toolbar">
        <Row justify="space-between" style={{ margin: 3 }}>
          <Col>
            <Space>
              <Typography>{title}</Typography>
              {panelFilterComponent.map((c, i) => {
                return c(filterRefs[i], () => setFlag(Math.random()));
              })}
            </Space>
          </Col>
          <Col>
            <Space>
              <span onClick={handleChangeChartType}>
                {chartTypeContent.component}
              </span>
              <span>
                <ArrowDownOutlined onClick={handleDownload} />
              </span>
            </Space>
          </Col>
        </Row>
        <div>
          <Chart
            filter={warpFilter}
            ref={chartRef}
            type={type}
            baseSeries={baseSeries}
            extraSeries={extraSeries}
            chartType={chartType}
            tableColumns={tableColumns}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </div>
      </div>
    </div>
  );
};
export default Panel;
