import { Spin } from "antd";
import NormalTable from "Components/Table/NormalTable";
import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";
import React, { ReactElement, useCallback, useEffect } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { columns } from "../AirlineComponents/tableColumns";
import { SystemType } from "Interface";

/**
 * Component description
 *
 */

interface IProps {
  ext: Partial<TransferPolicyBarlineRequestType>;
  panelType: 0 | 1;
}

const AirlineTable = (props: IProps): ReactElement => {
  const { ext, panelType } = props;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition, queryCondition } = globalState;
  const isAirport = systemType === SystemType.airport;
  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].table,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: CHART_TABLE_CODE[systemType].table,
    },
    query: isAirport ? queryCondition : airlinesQueryCondition,
    ext,
    // lazey: true,
  });

  const refetch = useCallback(() => {
    if (ext?.filter?.searchStartDate && ext?.filter?.searchEndDate) {
      doFetch({
        ext: { ...ext },
        query: isAirport ? queryCondition : airlinesQueryCondition,
      });
    }
  }, [ext, doFetch, isAirport, queryCondition, airlinesQueryCondition]);

  useEffect(() => {
    refetch();
  }, [refetch]);
  return (
    <Spin spinning={isLoading}>
      <NormalTable
        downloadFileName={getSharkText("menu_transfer_policy_airlines")}
        columns={columns(panelType, systemType, ext)}
        data={data}
        searchKeys={["dport", "dportName", "aport", "aportName"]}
        moduleCode={MODULE_CODE[systemType]}
        chartTableCode={CHART_TABLE_CODE[systemType].table}
      />
    </Spin>
  );
};
export default AirlineTable;
