import { pathSymbols } from "Constants";
import { IQuery } from "./common";
import { getSharkText } from "Utils/i18nGlobal";

export interface Config {
  title: string;
  subtitle?: string;
  tagInfo?: string;
  tagCode: string;
  allowTypes?: string[];
  chartTableCode: Record<number, string>;
  chartType?: string[];
  sort?: (a: any, b: any) => number;
  seriesConfig?: any[];
  seriesMap?: Record<string, string>;
  isMap?: boolean;
  reconstructQuery?: (v: IQuery) => IQuery;
  chartConfig?: any;
}

export const airlineConfig = (
  isAirport: boolean,
  airlinesFlightClass: number,
  airportFlightClass: number
): Config[] => [
  {
    title: getSharkText("custom_profile.page.gender"),
    subtitle: "",
    tagCode: "gender",
    chartTableCode: {
      1: "AP0701",
      2: "AL0701",
    },
    chartType: ["pictorialBar"],
    // 数据端返回的男女数据,顺序是反的,需要调整
    sort: (a: any, b: any) => b.tagCode.localeCompare(a.tagCode),
    seriesConfig: [
      {
        tagCode: "1",
        name: getSharkText("custom_profile.page.gender.male"),
        symbol: pathSymbols.man,
        itemStyle: {
          color: "#177DFE",
        },
      },
      {
        tagCode: "2",
        name: getSharkText("custom_profile.page.gender.female"),
        symbol: pathSymbols.woman,
        itemStyle: {
          color: "#f90",
        },
      },
    ],
  },
  {
    title: getSharkText("custom_profile.page.age"),
    tagCode: "age",
    chartTableCode: {
      1: "AP0702",
      2: "AL0702",
    },
  },
  {
    title: getSharkText("custom_profile.page.passenger_distribution"),
    tagCode: "distribution_class",
    chartTableCode: {
      1: "AP0701",
      2: "AL0701",
    },
    seriesConfig: [
      {
        tagCode: "1",
        name: getSharkText("custom_profile.page.solo"),
      },
      {
        tagCode: "2",
        name: getSharkText("custom_profile.page.with_children"),
      },
      {
        tagCode: "3",
        name: getSharkText("custom_profile.page.with_elderly"),
      },
      {
        tagCode: "4",
        name: getSharkText("custom_profile.page.with_children_elderly"),
      },
      {
        tagCode: "5",
        name: getSharkText("custom_profile.page.other_people"),
      },
    ],
  },
  {
    // 年均出行频次
    title: getSharkText("custom_profile.page.travel_frequency"),
    tagCode: "frequency_class",
    allowTypes: ["bar", "pie", "download"],
    tagInfo: getSharkText("custom_profile.page.travel_frequency_info"),
    chartTableCode: {
      1: "AP0701",
      2: "AL0701",
    },
  },
  {
    title: getSharkText("custom_profile.page.travel_time"),
    subtitle: "",
    tagCode: "dayofweek",
    chartTableCode: {
      1: "AP0703",
      2: "AL0703",
    },
    seriesConfig: [
      {
        tagCode: "1",
        name: getSharkText("date_mon"),
      },
      {
        tagCode: "2",
        name: getSharkText("date_tue"),
      },
      {
        tagCode: "3",
        name: getSharkText("date_wed"),
      },
      {
        tagCode: "4",
        name: getSharkText("date_thu"),
      },
      {
        tagCode: "5",
        name: getSharkText("date_fri"),
      },
      {
        tagCode: "6",
        name: getSharkText("date_sat"),
      },
      {
        tagCode: "7",
        name: getSharkText("date_sun"),
      },
    ],
    seriesMap: {
      "1": getSharkText("date_mon"),
      // day1: getSharkText("date_mon"),
      day2: getSharkText("date_tue"),
      day3: getSharkText("date_wed"),
      day4: getSharkText("date_thu"),
      day5: getSharkText("date_fri"),
      day6: getSharkText("date_sat"),
      day7: getSharkText("date_sun"),
    },
  },
  {
    title: getSharkText("custom_profile.page.orderday_before"),
    subtitle: getSharkText("custom_profile.page.orderday_before.subtitle"),
    tagCode: "order_aheadday",
    chartTableCode: {
      1: "AP0705",
      2: "AL0705",
    },
    seriesConfig: [
      {
        tagCode: "1",
        name: `0-3${getSharkText("key.day.button")}`,
      },
      {
        tagCode: "2",
        name: `4-7${getSharkText("key.day.button")}`,
      },
      {
        tagCode: "3",
        name: `8-14${getSharkText("key.day.button")}`,
      },
      {
        tagCode: "4",
        name: `15-30${getSharkText("key.day.button")}`,
      },
      {
        tagCode: "5",
        name: `31-60${getSharkText("key.day.button")}`,
      },
      {
        tagCode: "6",
        name: getSharkText("custom_profile.page.orderday_before.60days"),
      },
    ],
    // chartType: "custom",
    seriesMap: {
      orderday_before1: `0~3${getSharkText("key.day.button")}`,
      orderday_before2: `4~7${getSharkText("key.day.button")}`,
      orderday_before3: `8~14${getSharkText("key.day.button")}`,
      orderday_before4: `15~30${getSharkText("key.day.button")}`,
      orderday_before5: `31~60${getSharkText("key.day.button")}`,
      orderday_before6: getSharkText(
        "custom_profile.page.orderday_before.60days"
      ),
    },
  },
  {
    title: getSharkText("custom_profile.page.price_sensitivity"),
    subtitle: "",
    tagCode: "uid_price_sensitive",
    chartTableCode: {
      1: "AP0708",
      2: "AL0708",
    },
    seriesConfig: [
      {
        tagCode: "1",
        name: getSharkText("custom_profile.page.price_sensitivity.high"),
      },
      {
        tagCode: "2",
        name: getSharkText("custom_profile.page.price_sensitivity.medium"),
      },
      {
        tagCode: "3",
        name: getSharkText("custom_profile.page.price_sensitivity.low"),
      },
    ],
    seriesMap: {
      price_sensitivity1: getSharkText(
        "custom_profile.page.price_sensitivity.high"
      ),
      price_sensitivity2: getSharkText(
        "custom_profile.page.price_sensitivity.medium"
      ),
      price_sensitivity3: getSharkText(
        "custom_profile.page.price_sensitivity.low"
      ),
    },
  },
  {
    title: getSharkText("custom_profile.page.travel_purpose"),
    tagCode: "purpose",
    chartTableCode: {
      1: "AP0708",
      2: "AL0708",
    },
    seriesConfig: [
      {
        tagCode: "2",
        name: getSharkText("custom_profile.page.Business"),
      },
      {
        tagCode: "4",
        name: getSharkText("custom_profile.page.Other"),
      },
      {
        tagCode: "6",
        name: getSharkText("custom_profile.page.Return_to_China"),
      },
      {
        tagCode: "1",
        name: getSharkText("custom_profile.page.Leisure"),
      },
      {
        tagCode: "3",
        name: getSharkText("custom_profile.page.Return_to_Hometown"),
      },
    ],
  },
  {
    title: getSharkText("custom_profile.page.source_distribution"),
    tagCode: "uid_base",
    allowTypes: ["map"],
    isMap: true,
    chartTableCode: {
      1: "AP0708",
      2: "AL0708",
    },
  },
  {
    title: getSharkText("custom_profile.page.airline_preference"),
    tagCode: "airline",
    chartTableCode: {
      1: "AP0708",
      2: "AL0708",
    },
  },

  ...((isAirport && airportFlightClass === 1) ||
  (!isAirport && airlinesFlightClass === 1)
    ? [
        {
          title: getSharkText("custom_profile.page.travel_mode"),
          tagCode: "is_transfer",
          tagInfo: getSharkText("custom_profile.page.travel_mode_info"),
          chartTableCode: {
            1: "AP0705",
            2: "AL0705",
          },
        },
        {
          title: getSharkText("custom_profile.page.itinerary_type"),
          tagCode: "is_direct_rt",
          tagInfo: getSharkText("custom_profile.page.itinerary_type_info"),
          chartTableCode: {
            1: "AP0705",
            2: "AL0705",
          },
        },
      ]
    : []),
];
