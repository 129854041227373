// Created by xh_zhu on 2021-04-06

import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { ReactElement, ReactNode, useState } from "react";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import {
  LOSS_HOT_HELP,
  LOSS_ROUTE_HELP,
  LOSS_TREND_HELP,
} from "../PassengerAnalysisCommon";
import BarLineCharts from "./BarLineCharts";
import LossChartRanking from "./LossChartRanking";
import TopChartMap from "./TopChartMap";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 * 客源流失
 */

interface ILossChartsProps {
  ext: any;
}

const lossChartTypes = [
  <span key={0}>
    <span>{getSharkText("key.heatmap.psgrsource")}</span>
    <Popover content={LOSS_HOT_HELP}>
      <InfoCircleOutlined style={{ marginLeft: 5 }} />
    </Popover>
  </span>,
  <span key={1}>
    <span>{getSharkText("key.trendchart.psgrsource")}</span>
    <Popover content={LOSS_TREND_HELP}>
      <InfoCircleOutlined style={{ marginLeft: 5 }} />
    </Popover>
  </span>,
  <span key={2}>
    <span>{getSharkText("key.routes_distribution")}</span>
    <Popover content={LOSS_ROUTE_HELP}>
      <InfoCircleOutlined style={{ marginLeft: 5 }} />
    </Popover>
  </span>,
];

const LossCharts = (props: ILossChartsProps): ReactElement => {
  const { ext } = props;
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  const [lossChartType, setLossChartType] = useState<number>(0);
  const changeType = (v: RadioChangeEvent) => {
    setLossChartType(v.target.value);
  };

  const getLossChart = (): ReactNode => {
    switch (lossChartType) {
      case 0:
        return (
          <TopChartMap
            queryUrl={QUERY_URL[systemType].lossHeatMap}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].distribution}
            ext={ext}
          />
        );
      case 1:
        return (
          <BarLineCharts
            queryUrl={QUERY_URL[systemType].lossBarline}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].distribution}
            hasPer={false}
            ext={ext}
          />
        );
      case 2:
        return (
          <LossChartRanking
            queryUrl={QUERY_URL[systemType].lossTopAirline}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].table}
            ext={ext}
          />
        );
    }
  };
  return (
    <div>
      <Row style={{ justifyContent: "center" }}>
        <Radio.Group
          optionType="button"
          buttonStyle="outline"
          value={lossChartType}
          onChange={changeType}
        >
          {lossChartTypes.map((item: ReactElement, i: number) => (
            <Radio.Button value={i} key={i}>
              {item}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Row>
      {lossChartType === 0 ? (
        <Popover
          placement="topRight"
          content={<span>{getSharkText("key.full_screen.psgrsource")}</span>}
        >
          <Row style={{ flexFlow: "column" }}>{getLossChart()}</Row>
        </Popover>
      ) : (
        <Row style={{ flexFlow: "column" }}>{getLossChart()}</Row>
      )}
    </div>
  );
};
export default LossCharts;
