import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Col, Radio, Row } from "antd";
import Search from "./Search";
import Price from "./Price";
import Load from "./Load";
import * as echarts from "echarts/lib/echarts";
import {
  IFlightReviewFilter,
  IReviewCompeteExt,
  TCompareType,
} from "Page/AI/FlightReview/ReviewCommon";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import { MODULE_CODE, QUERY_URL } from "Page/AI/FlightManage/fetchCode";
import { EMPTY_ARRAY } from "Constants";
import { getSharkText } from "Utils/i18nGlobal";

export interface ICompareProps {
  queryExt: IFlightReviewFilter;
}

/**
 * 销售过程搜索/价格/客座率
 */
const Compare = (props: ICompareProps): ReactElement => {
  const { queryExt } = props;
  // 1=总览, 2=对比竞飞, 3=环比四周
  const [dataType, setDataType] = useState<TCompareType>(0);
  const [xMax, setXMax] = useState<number | undefined>(undefined);
  const [xMin, setXMin] = useState<number | undefined>(undefined);
  const [searchInstance, setSearchInstance] = useState<any>();
  const [priceInstance, setPriceInstance] = useState<any>();
  const [comFlightNO, setComFlightNO] = useState<string[]>(EMPTY_ARRAY);
  const [competeExt, setCompeteExt] = useState<IReviewCompeteExt>(queryExt);
  const [loadInstance, setLoadInstance] = useState<any>();
  const setXMaxVal = useCallback(
    (val: number | undefined) => {
      if (xMax === undefined || (val !== undefined && val > xMax)) {
        setXMax(val);
      }
    },
    [xMax]
  );
  const setXMinVal = useCallback(
    (val: number | undefined) => {
      if (xMin === undefined || (val !== undefined && val < xMin)) {
        setXMin(val);
      }
    },
    [xMin]
  );

  useEffect(() => {
    const instances: any[] = [];
    [searchInstance, priceInstance, loadInstance].forEach(
      (e) => e && instances.push(e)
    );
    if (instances.length > 1) {
      echarts.connect(instances);
    }
  }, [searchInstance, priceInstance, loadInstance]);

  /**
   * 设置竞飞航班
   */
  useEffect(() => {
    if (comFlightNO.length) {
      setCompeteExt({
        ...queryExt,
        competeFlights: comFlightNO.map((c) => ({
          flightNO: c,
          route: queryExt.filter.route,
        })),
      });
    } else {
      setCompeteExt(queryExt);
    }
  }, [comFlightNO, queryExt]);

  return (
    <div>
      <span style={{ fontSize: "160%" }}>
        {getSharkText("config_page_sales_process_search_price_and_load_factor")}
      </span>
      <Row>
        <Col>
          <Radio.Group
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
          >
            <Radio.Button value={0}>
              {getSharkText("config_page_overview")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("config_page_compare_flight")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("config_page_ring_ratio_four_weeks")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        {dataType === 1 && (
          <Col>
            <SelectMultiFlight
              title={getSharkText("config_page_compete_flight") + "："}
              queryUrl={QUERY_URL.routeFlight}
              moduleCode={MODULE_CODE}
              flightNO={comFlightNO}
              setFlightNO={setComFlightNO}
              routes={queryExt?.filter.route || ""}
              isDemo={false}
              type={3}
              mode={undefined}
              width="auto"
              debugId="fupan_three_charts_compare_flight"
            />
          </Col>
        )}
      </Row>
      <div style={{ height: 352 }}>
        <Search
          queryExt={queryExt}
          type={dataType}
          xMin={xMin}
          xMax={xMax}
          setXMax={setXMaxVal}
          setXMin={setXMinVal}
          onChartInstanceChange={setSearchInstance}
        />
      </div>
      <div style={{ height: 352 }}>
        <Price
          queryExt={competeExt}
          type={dataType}
          xMin={xMin}
          xMax={xMax}
          setXMax={setXMaxVal}
          setXMin={setXMinVal}
          onChartInstanceChange={setPriceInstance}
        />
      </div>
      <div style={{ height: 352 }}>
        <Load
          queryExt={competeExt}
          type={dataType}
          xMin={xMin}
          xMax={xMax}
          setXMax={setXMaxVal}
          setXMin={setXMinVal}
          onChartInstanceChange={setLoadInstance}
        />
      </div>
    </div>
  );
};
export default Compare;
