import React from "react";
import { useLocation } from "react-router-dom";
import useGlobal from "Store";
import { getModule, getModuleNameFromPath } from "Utils";
import AirlineIndex from "./AirlineIndex";
import AirportIndex from "./AirportIndex";

const TransferPolicy = () => {
  const location = useLocation();
  const [globalState] = useGlobal();
  const { systemType, userInfo } = globalState;
  const isAirport = systemType === 1;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const isDemo = (module && module.moduleStatus === 0) || false;

  return (
    <>
      {isAirport ? (
        <AirportIndex isDemo={isDemo} />
      ) : (
        <AirlineIndex isDemo={isDemo} />
      )}
    </>
  );
};

export default TransferPolicy;
