import { Spin, Tooltip } from "antd";
import { ISeries } from "Interface";
import merge from "lodash/merge";
import {
  CHART_TABLE_CODE,
  MODULE_CODE,
  QUERY_URL,
} from "Page/AI/FlightReview/fetchCode";
import React, { ReactElement, useEffect, useState } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { isNumeric, rowToColumn, showNum, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { DATE_FORMAT, EMPTY_ARRAY, WEEK } from "Constants";
import moment from "moment";
import { round } from "lodash";
import EchartsReactBase from "Components/EchartsReactBase";

export interface IProcessChartsProps {
  defaultSeries: ISeries[];
  ext?: Record<string, unknown>;
  chartConfig?: Record<string, unknown>;
  /**
   * 0 堆积图, 1 面积图
   */
  type: 0 | 1;
  /**
   * 聚合粒度 0 = 天, 1= 周
   */
  aggCode: 0 | 1;
}

/**
 * 航班复盘-销售过程-订座数-图表
 */
const ProcessStack = (props: IProcessChartsProps): ReactElement => {
  const { ext, defaultSeries, chartConfig, type, aggCode } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [transData, setTransData] = useState<any[]>(EMPTY_ARRAY);

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(2),
    url: QUERY_URL[2].sales,
    defaultValue: null,
    head: {
      moduleCode: MODULE_CODE[2],
      chartTableCode: CHART_TABLE_CODE[2].sales,
    },
    query: airlinesQueryCondition,
    ext: {
      ...ext,
      type,
      aggCode,
    },
    lazey: true,
  });

  useEffect(() => {
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        ...ext,
        type,
        aggCode,
      },
    });
  }, [ext, doFetch, type, aggCode, airlinesQueryCondition]);

  /**
   * 堆叠图的数据需要做行列转换
   */
  useEffect(() => {
    if (data && data.length) {
      const rst: any[] = rowToColumn(
        data,
        "day",
        "type",
        "value",
        (rt, c) => rt + c
      );
      // 补空数据
      rst.forEach((r: any) => {
        defaultSeries.forEach((s) => {
          if (r[s.encode.y] === undefined) {
            r[s.encode.y] = 0;
          }
        });
      });
      // 计算数据的占比
      rst.forEach((r: any) => {
        let sum = 0;
        for (const n in r) {
          if (isNumeric(r[n])) {
            sum += parseFloat(r[n]);
          }
        }
        for (const n in r) {
          if (isNumeric(r[n])) {
            r[n] = round(r[n] / sum, 4);
          }
        }
      });
      setTransData(rst);
    } else {
      setTransData(EMPTY_ARRAY);
    }
  }, [data]);

  const [option, setOption] = useState<any>();

  useEffect(() => {
    const dimensions =
      transData && transData.length ? Object.keys(transData[0]) : [];
    const tmp: Record<string, any> = {
      dataset: {
        source: transData,
      },
      color: ["#177DFE", "#FC9B4F", "#01C5DB"],
      // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
      legend: {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
        data: ["D0-D2", "D3-D6", "D7-D14"],
      },
      grid: {
        right: 50,
      },
      tooltip: {
        trigger: "axis",
        formatter: (params: any[]) => {
          const dayMoment = moment(params[0].data.day);
          const day = `${dayMoment.format(DATE_FORMAT)} ${
            WEEK[(dayMoment.day() + 6) % 7]
          }`;
          let dom = "";
          params.reverse().forEach((item: any) => {
            const dot = `<span class='dot' style='background:${item.color}'></span>`;
            const isPercentVal = true;
            const itemDim = item.dimensionNames[item.encode.y[0]];
            const itemValue = item.value[itemDim];
            const value = isPercentVal
              ? showNum(itemValue * 100, "percentage")
              : showNum(itemValue, "num");
            const val = `${item.seriesName}: ${value}`;
            dom = dom + dot + val + "<br />";
          });
          return day + "<br />" + dom;
        },
      },
      xAxis: {
        type: "category",
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: (value: string) => moment(value).format(DATE_FORMAT),
        },
      },
      dataZoom: null,
      yAxis: [
        {
          position: "right",
          type: "value",
          axisLabel: {
            formatter: (value: number) => showNum(value * 100, "percentage"),
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series: defaultSeries,
    };
    const opt = merge(tmp, chartConfig);
    if (typeof opt.xAxis.dataFn === "function") {
      opt.xAxis.data = opt.xAxis.dataFn(transData, defaultSeries);
    }
    setOption(opt);
  }, [chartConfig, transData, defaultSeries]);

  return (
    <Spin spinning={isLoading}>
      <Tooltip
        title={getSharkText("config_page_tooltip_title")}
        placement="right"
      >
        <div style={{ padding: 24 }}>
          {option ? (
            <EchartsReactBase
              className="bar-line-charts"
              option={option}
              theme="default"
              style={{ height: 484 }}
              notMerge
            />
          ) : undefined}
        </div>
      </Tooltip>
    </Spin>
  );
};
export default ProcessStack;
