import { Empty, message, Spin } from "antd";
import CustomTableV2, {
  ICustomTableV2Props,
} from "Components/CustomTable/CustomTableV2";
import { cloneDeep } from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { enumKeys } from "Utils/global";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import { EPanelType, EStatus } from "../MonitoringAlertingCommon";
import { genSearchKeys, getColumns } from "./TableColumns";

/**
 * Component description
 * 表格入口
 */

const defaultRowSelection = {};

interface IProps {
  example?: string;
  queryExt?: Record<string, unknown>;
  panelType: EPanelType;
  selectedStatus: number[];
  setTabCount?: (tab: EPanelType, count: number) => void;
  tableProps?: Partial<ICustomTableV2Props<any>>;
}

const TablePortal = <T extends { [key: string]: any }>(
  props: IProps
): ReactElement => {
  type TKey = T & { key: string };
  const { queryExt, panelType, selectedStatus, tableProps, setTabCount } =
    props;
  const [globalState] = useGlobalState();
  const { systemType, airlinesQueryCondition } = globalState;
  const [keyData, setKeyData] = useState<TKey[]>([]);
  const [filterData, setFilterData] = useState<TKey[]>([]);

  const urlName = EPanelType[panelType].toString().toLocaleLowerCase();
  console.log("name: ", urlName);

  const [{ data, isLoading, error }, doFetch] = useFetch<TKey[]>({
    server: getServer(systemType),
    url: QUERY_URL[systemType][urlName],
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {
      ...queryExt,
    },
    lazey: true,
  });

  const [{ isLoading: submitLoading, res }, doFetchSubmit] = useFetch({
    server: getServer(systemType),
    url: QUERY_URL[systemType].set,
    defaultValue: [],
    head: {
      moduleCode: MODULE_CODE[systemType],
      chartTableCode: "non",
    },
    query: airlinesQueryCondition,
    ext: {
      ...queryExt,
    },
    lazey: true,
  });

  useEffect(() => {
    const rstData = data.map((d, i) => {
      const tmpD: TKey = {
        ...cloneDeep(d),
        key: d.id ? `${d.id}` : `${i}`,
      };
      return tmpD;
    });
    setKeyData(rstData);
    if (setTabCount) {
      setTabCount(panelType, data.length);
    }
  }, [data]);

  useEffect(() => {
    if (
      selectedStatus.length === 0 ||
      selectedStatus.length === enumKeys(EStatus).length
    ) {
      setFilterData(keyData);
    } else {
      const tmp = keyData.filter((k) =>
        selectedStatus.includes(k.status as number)
      );
      setFilterData(tmp);
    }
  }, [keyData, selectedStatus]);

  useEffect(() => {
    doFetch({
      query: airlinesQueryCondition,
      ext: {
        ...queryExt,
      },
    });
  }, [airlinesQueryCondition, queryExt]);

  const batchSubmit = (v: any[]) => {
    if (v.length) {
      doFetchSubmit({
        query: airlinesQueryCondition,
        ext: {
          type: parseInt(panelType.toString(), 10) + 1,
          idList: v.map((m) => m.id),
        },
      });
    } else {
      message.error(getSharkText("config_page_please_select_item"));
    }
  };

  useEffect(() => {
    if (!submitLoading) {
      doFetch({
        query: airlinesQueryCondition,
        ext: {
          ...queryExt,
        },
      });
    }
  }, [submitLoading]);

  return (
    <Spin spinning={isLoading || submitLoading}>
      {data && data.length ? (
        <CustomTableV2<T & { key: string }>
          columns={getColumns(panelType)}
          dataSource={filterData}
          downloadName={getSharkText("menu_monitoring_alerting_airlines")}
          rowSelection={defaultRowSelection}
          batchSubmit={batchSubmit}
          searchKeys={genSearchKeys(panelType)}
          {...tableProps}
          moduleCode={MODULE_CODE[systemType]}
          chartTableCode={"non"}
        ></CustomTableV2>
      ) : (
        <Empty />
      )}
    </Spin>
  );
};
export default TablePortal;
