import React from "react";
import { Spin } from "antd";
import {
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  durationFormatter,
  genSeriesByDimensions,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode } from "Interface";
import merge from "lodash/merge";
import round from "lodash/round";
import { ITransferBarLineChartsChartConfig } from "./common";
import EchartsReactBase from "Components/EchartsReactBase";

interface Data {
  day: string;
  value: number;
  compareValue: number;
}

interface ChartsProps {
  aggCode: AggCode;
  isPercentVal: boolean;
  isDurationVal: boolean;
  loading: boolean;
  data: Data[];
  startDate: string;
  endDate: string;
  height: number;
  airport: string;
  chartOptions?: ITransferBarLineChartsChartConfig;
}

const BarLineCharts: React.FC<ChartsProps> = (props: ChartsProps) => {
  const {
    aggCode,
    isPercentVal,
    isDurationVal,
    loading,
    data,
    startDate,
    endDate,
    height,
    airport,
    chartOptions = {},
  } = props;
  const { series, legend, yAxis } = chartOptions;

  const isZero = (val: number) => val === 0;
  const option = {
    color: ["#01C5DB", "#FC9B4F", "#177DFE", "#ffb61e", "#97dDFE"],
    dataset: {
      source: data,
    },
    legend: merge(
      {},
      {
        itemGap: 20,
        itemWidth: 16,
        itemHeight: 8,
        top: 0,
      },
      legend
    ),
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          false
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const dimIndex = item.encode && item.encode.y ? item.encode.y[0] : 1;
          const dimName = item.dimensionNames[dimIndex];
          const val = `${item.seriesName}: ${
            !isZero(item.data[dimName])
              ? dimName.indexOf("Per") >= 0
                ? isPercentVal
                  ? item.data[dimName] + "%"
                  : (item.data[dimName] * 100).toFixed(0) + "%"
                : isPercentVal
                ? `${round(item.data[dimName], 1)}%`
                : isDurationVal
                ? durationFormatter(item.data[dimName] * 60)
                : round(item.data[dimName])
              : "-"
          }`;

          dom = dom + "<br />" + dot + val;
        });
        dom = getSharkText("charts.date_of_departure") + ": " + day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    yAxis: yAxis || [
      {
        position: "left",
        type: "value",
        splitLine: false,
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) =>
            `${
              isPercentVal
                ? value + "%"
                : isDurationVal
                ? durationFormatter(value * 60)
                : value
            } `,
        },
        axisTick: {
          show: false,
        },
      },
    ],
    series:
      series && data.length > 0
        ? genSeriesByDimensions(Object.keys(data[0]), series)
        : [
            {
              type: "bar",
              encode: { x: "day", y: "value" },
              barMaxWidth: 20,
              barGap: 0,
              name: airport,
            },
          ],
  };

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        notMerge={true}
        theme="default"
        style={{ height }}
      />
    </Spin>
  );
};

export default BarLineCharts;
