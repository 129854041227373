import React, { useImperativeHandle } from "react";
import {
  getDateFromAggCode,
  getTooltipDateFromAggCode,
  isAirportMode,
  showNum,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AggCode, ECompareTypeV2 } from "Interface";
import { round } from "lodash";
import useGlobalState from "Store";
import { COMPARE_TYPE_PLC_NAME } from "Constants";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  day: string;
  value: number;
  compareValue: number;
  marketValue: number;
  marketCompareValue: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  aggCode: AggCode;
  startDate: string;
  endDate: string;
  seriesName: string;
  cardName: string;
  isPercentVal: boolean;
  compareType: ECompareTypeV2;
  dateType?: string;
}

const BarCharts = React.forwardRef((props: BarChartsProps, ref: any) => {
  const {
    data,
    height = 300,
    aggCode,
    startDate,
    endDate,
    seriesName,
    cardName,
    isPercentVal,
    compareType,
    dateType = "起飞",
  } = props;
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  const isAirport = isAirportMode(systemType);
  const isZero = (val: number) => val === 0;

  const prefix = COMPARE_TYPE_PLC_NAME[compareType];

  const option = {
    color: ["#01C5DB", "#177DFE", "#FC9B4F", "#82b1ff"],
    dataset: {
      source: data,
    },
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    dataZoom: [
      {
        type: "inside",
      },
      {
        type: "slider",
        bottom: 0,
      },
    ],
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          false
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const dimIndex = item.encode && item.encode.y ? item.encode.y[0] : 1;
          const dimName = item.dimensionNames[dimIndex];
          const val = `${item.seriesName}: ${
            !isZero(item.data[dimName])
              ? dimName.toLowerCase().indexOf("yoy") >= 0
                ? isPercentVal
                  ? showNum(item.data[dimName], "percentage")
                  : showNum(item.data[dimName] * 100, "percentage")
                : isPercentVal
                ? showNum(round(item.data[dimName], 1), "percentage")
                : round(item.data[dimName])
              : "-"
          }`;

          dom = dom + "<br />" + dot + val;
        });
        dom = `${dateType}日期：` + day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCode(aggCode, value)}`,
      },
    },
    yAxis: [
      {
        position: "left",
        type: "value",
        splitLine: false,
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) =>
            `${isPercentVal ? value + "%" : value} `,
        },
        axisTick: {
          show: false,
        },
        max: (value: any) => {
          const { max, min } = value;
          const absMax = Math.max(Math.abs(max), Math.abs(min));
          return Math.floor(absMax * 1.2);
        },
        min: (value: any) => {
          const { max, min } = value;
          const absMax = Math.max(Math.abs(max), Math.abs(min));
          return min < 0 ? Math.floor(-absMax * 1.2) : Math.floor(min);
        },
      },
      {
        position: "right",
        type: "value",
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        splitNumber: 5,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: isPercentVal
            ? (value: number) => `${value.toFixed(1)}%`
            : (value: number) => `${(value * 100).toFixed(2)}%`,
        },
        axisTick: {
          show: false,
        },
        max: (value: any) => {
          const { max, min } = value;
          const absMax = Math.max(Math.abs(max), Math.abs(min));
          return absMax * 1.2;
        },
        min: (value: any) => {
          const { max, min } = value;
          const absMax = Math.max(Math.abs(max), Math.abs(min));
          return min < 0 ? -absMax * 1.2 : min;
        },
      },
    ],
    series: [
      {
        type: "line",
        encode: { x: "day", y: "marketValue" },
        name: `${getSharkText("charts.market.seriesvaluename")}${cardName}`,
        showSymbol: false,
        symbol: "circle",
        yAxisIndex: 0,
        lineStyle: {
          width: 3,
        },
      },
      {
        type: "bar",
        encode: { x: "day", y: "marketYoy" },
        name: `${getSharkText(
          "charts.market.seriesvaluename"
        )}${cardName}${prefix}`,
        showSymbol: false,
        symbol: "circle",
        yAxisIndex: 1,
        barMaxWidth: 20,
      },
    ],
  };
  // 航司版不显示航司的搜索指数
  if (!(!isAirport && cardName === "搜索指数")) {
    option.series.push({
      type: "line",
      encode: { x: "day", y: "value" },
      name: `${seriesName}${cardName}`,
      showSymbol: false,
      symbol: "circle",
      yAxisIndex: 0,
      lineStyle: {
        width: 3,
      },
    });
    option.series.push({
      type: "bar",
      encode: { x: "day", y: "yoy" },
      name: `${seriesName}${cardName}${prefix}`,
      showSymbol: false,
      symbol: "circle",
      yAxisIndex: 1,
      barMaxWidth: 20,
    });
  }

  useImperativeHandle(ref, () => ({
    getSeries: () => {
      return option.series;
    },
  }));

  return (
    <EchartsReactBase
      notMerge={true}
      option={option}
      theme="default"
      style={{ height }}
    />
  );
});

export default BarCharts;
