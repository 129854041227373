import React from "react";
import { Input, message } from "antd";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { UnitList } from "Interface";

interface SearchUserProps {
  unitList: UnitList[];
  setCurUnit: (unit: UnitList | null) => void;
  restUnitType: () => void;
}

const { Search } = Input;

const SearchUser: React.FC<SearchUserProps> = (props: SearchUserProps) => {
  const { unitList, setCurUnit, restUnitType } = props;
  const [{ isLoading }, doFetch] = useFetch({
    url: "findUserList",
    head: {
      moduleCode: "right_manage",
      chartTableCode: null,
    },
    ext: {
      filter: {
        mobile: "",
      },
    },
    lazey: true,
    onSuccess: (response: any) => {
      const { userList } = response;
      if (userList && userList.length > 0) {
        const { user } = userList[0];
        restUnitType();
        const curUnit = unitList.find((item) => item.unit.id === user.groupId);
        setCurUnit(curUnit || null);
      } else {
        message.error(getSharkText("config_page_user_not_exist"));
      }
    },
    onError: () => {
      message.error(getSharkText("config_page_server_error"));
    },
  });

  const handleSearch = (value: string) => {
    if (value) {
      doFetch({
        ext: {
          filter: { mobile: value },
        },
      });
    } else {
      message.error(getSharkText("config_page_mobile_number_cannot_be_empty"));
    }
  };

  return (
    <Search
      placeholder={getSharkText("config_page_mobile")}
      enterButton={getSharkText("key.search.button")}
      onSearch={handleSearch}
      loading={isLoading}
    />
  );
};

export default SearchUser;
