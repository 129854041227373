import { RangePickerProps } from "antd/es/date-picker/generatePicker";
import BarLineChartsV2 from "Components/BarLineChartsV2";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { DATE_FORMAT, LIMIT_END_DATE, LIMIT_START_DATE } from "Constants";
import { AirlinesQueryCondition, ISeries, RangeObject } from "Interface";
import moment, { Moment } from "moment";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useGlobalState from "Store";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import { IFlightReviewFilter } from "../ReviewCommon";
import FooterTablePortal from "./FooterTablePortal";
import { getSharkText } from "Utils/i18nGlobal";

interface IFooterProps {
  queryExt: IFlightReviewFilter;
}

/**
 * 航班复盘, 底部的调仓使用率
 */
const Footer = (props: IFooterProps): ReactElement => {
  const { queryExt } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition, systemType } = globalState;
  const { startDate } = airlinesQueryCondition;
  const [dateRange, setDateRange] = useState<RangeObject>([
    moment(startDate).add(-14, "days"),
    moment(startDate),
  ]);

  useEffect(() => {
    setDateRange([moment(startDate).add(-14, "days"), moment(startDate)]);
  }, [startDate]);

  const footChartExt: IFlightReviewFilter & {
    query: Partial<AirlinesQueryCondition>;
  } = useMemo(() => {
    return {
      ...queryExt,
      query: {
        ...airlinesQueryCondition,
        startDate:
          (dateRange && dateRange[0]?.format(DATE_FORMAT)) || LIMIT_START_DATE,
        endDate:
          (dateRange && dateRange[1]?.format(DATE_FORMAT)) || LIMIT_END_DATE,
      },
    };
  }, [airlinesQueryCondition, dateRange, queryExt]);

  const defaultSeries = useMemo(
    (): ISeries[] => [
      {
        type: "line",
        name: "建议使用率",
        isPercentVal: true,
        connectNulls: true,
        yAxisIndex: 1,
        encode: {
          x: "day",
          y: "useFeedbackPercent",
        },
      },
      {
        type: "line",
        name: getSharkText("config_page_suggestion_agree_rate"),
        isPercentVal: true,
        connectNulls: true,
        yAxisIndex: 1,
        encode: {
          x: "day",
          y: "acceptFeedbackPercent",
        },
      },
      {
        type: "line",
        name: getSharkText("config_page_flight_seat_revenue"),
        connectNulls: true,
        yAxisIndex: 0,
        encode: {
          x: "day",
          y: "raskFlight",
        },
      },
      {
        type: "line",
        name: getSharkText("config_page_revenue_difference"),
        connectNulls: true,
        yAxisIndex: 0,
        encode: {
          x: "day",
          y: "raskLineGap",
        },
      },
    ],
    []
  );
  const otherProps = useMemo((): RangePickerProps<moment.Moment> => {
    return {
      disabledDate: (current: Moment) => {
        return current.isAfter(moment().add(-1, "days"));
      },
    };
  }, []);

  const dataTrans = useCallback((data: any[]) => {
    data.forEach((d: any) => {
      d.day = moment(d.day).format(DATE_FORMAT);
    });
    return data;
  }, []);
  const tmpDateRange = useMemo(() => {
    return dateRange
      ? (dateRange.filter((d) => d != null) as Moment[])
      : undefined;
  }, [dateRange]);
  return (
    <div>
      <span style={{ fontSize: "160%" }}>
        {getSharkText(
          "config_page_dynamic_price_adjustment_suggestion_statistics"
        )}
      </span>
      <div style={{ padding: "10px" }}>
        <span>{getSharkText("config_page_departure_date_range")}：</span>
        <DateRangePickerV2
          defaultDateMode="none"
          onChange={setDateRange}
          value={dateRange}
          limitRange={30}
          otherProps={otherProps}
        />
      </div>
      <div>
        {tmpDateRange?.length === 2 && (
          <BarLineChartsV2
            queryUrl={QUERY_URL[systemType].footChart}
            moduleCode={MODULE_CODE[systemType]}
            ext={footChartExt}
            chartTableCode="non"
            defaultSeries={defaultSeries}
            useGranularity={false}
            need100Times
            precision={3}
            startDate={tmpDateRange[0].format(DATE_FORMAT)}
            endDate={tmpDateRange[1].format(DATE_FORMAT)}
            dataTrans={dataTrans}
          />
        )}
      </div>
      <div style={{ marginTop: 30, marginBottom: -10 }}>
        {moment(startDate).month() + 1}
        {getSharkText("config_page_dynamic_adjustment_advice_effect")}（
        {getSharkText("config_page_airline_overall")}）
      </div>
      <div>
        <FooterTablePortal queryExt={queryExt} />
      </div>
    </div>
  );
};
export default Footer;
