import { SubCabinEntityExtend } from "Interface";
import { getSharkText } from "Utils/i18nGlobal";
import React, { ReactNode } from "react";

const SMALL_CABIN_TITLE = getSharkText(
  "config_page_current_effective_small_cabin"
);
const FARE_PRICE_TITLE = getSharkText("effective_fare_price");

export const getCabinTips = (
  subCabin: SubCabinEntityExtend
): { color: string; popupContent: ReactNode[] } | null => {
  let color = "";
  if (subCabin.effectiveSmallCabin && subCabin.dynamicFarePrice) {
    color = "orange";
  } else if (subCabin.dynamicFarePrice) {
    color = "var(--main)";
  } else if (subCabin.effectiveSmallCabin) {
    color = "green";
  } else {
    return null;
  }
  const popupContent = [];
  if (subCabin.effectiveSmallCabin) {
    const effPrice = subCabin.effectiveFarePrice
      ? `, ${subCabin.effectiveFarePrice}`
      : "";
    popupContent.push(
      <div key="smallCabin" style={{ color: "green" }}>
        <span style={{ marginRight: 5 }}>{SMALL_CABIN_TITLE}:</span>
        {subCabin.effectiveSmallCabin}
        {effPrice}
      </div>
    );
  }
  if (subCabin.dynamicFarePrice) {
    popupContent.push(
      <div key="farePrice" style={{ color: "var(--main)" }}>
        <span style={{ marginRight: 5 }}>{FARE_PRICE_TITLE}:</span>
        {subCabin.dynamicFarePrice}
      </div>
    );
  }
  return { color, popupContent };
};
