import { SwapRightOutlined } from "@ant-design/icons";
import { Affix, Row, Col, Radio, Button, message, Select } from "antd";
import CityAirportSelectorPortal from "Components/CityAirportSelector/CityAirportSelectorPortal";
import DateRangePickerV2 from "Components/DateRangePickerV2";
import { DATE_FORMAT, DEFAULT_AREA, EMPTY_ARRAY } from "Constants";
import { Area, IDateMode, IValueLabelOption, RangeObject } from "Interface";
import { Area as AreaInterface } from "@ctrip/flt-bi-flightai-base";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import useGlobalState from "Store";
import { getDateRange } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { EAxisType } from "../WingonCommon";
import Search from "./Search";
import { WingonFilter } from "@ctrip/flt-bi-flightai-partners";
import useRefFunc from "Utils/useRefFunc";

export interface ITopFilterProps {
  isDemo: boolean;
  onChange: (v: WingonFilter) => void;
}

const defaultSearchRange: IDateMode = "lastMonth";
const defaultTkRange: IDateMode = "nextThirtyDays";

/**
 * 永安直通车过滤器
 */
const TopFilter = (props: ITopFilterProps): ReactElement => {
  const { onChange } = props;
  const [globalState, actions] = useGlobalState();
  const { partnerCondition } = globalState;
  const { setPartnerCondition } = actions;
  const [searchRange, setSearchRange] = useState<RangeObject>(
    getDateRange(defaultSearchRange)
  );
  const [tkRange, setTkRange] = useState<RangeObject>(
    getDateRange(defaultTkRange)
  );
  const [axisType, setAxisType] = useState<EAxisType>(0);
  const [departArea, setDepartArea] = useState<Area>();
  const [arriveArea, setArriveArea] = useState<Area>();
  const [queryExt, setQueryExt] = useState<WingonFilter>(
    {} as unknown as WingonFilter
  );
  const [bookingChannel, setBookingChannel] = useState<string[]>(EMPTY_ARRAY);
  const [channel, setChannel] = useState<string[]>(EMPTY_ARRAY);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setPartnerCondition({
      ...partnerCondition,
      partner: "wingon",
      partnerName: getSharkText("menu_wingon_overview"),
      startDate: (tkRange && tkRange[0]?.format(DATE_FORMAT)) || "",
      endDate: (tkRange && tkRange[1]?.format(DATE_FORMAT)) || "",
    });
  }, []);

  const submit = useRefFunc(() => {
    console.log("submit");
    if (searchRange === null && tkRange === null) {
      message.error(
        getSharkText("config_page_date_cannot_be_empty_at_the_same_time")
      );
      return;
    }
    if (
      (axisType === 0 && searchRange === null) ||
      (axisType === 1 && tkRange === null)
    ) {
      message.error(
        getSharkText("config_page_coordinate_axis_date_cannot_be_empty")
      );
      return;
    }
    const tmp: WingonFilter = {
      ...(searchRange && {
        searchDateStart:
          (searchRange && searchRange[0]?.format(DATE_FORMAT)) || "",
        searchDateEnd:
          (searchRange && searchRange[1]?.format(DATE_FORMAT)) || "",
      }),
      axisType,
      // 以后需要改造本地Area
      departArea: (departArea || DEFAULT_AREA) as AreaInterface,
      arriveArea: (arriveArea || DEFAULT_AREA) as AreaInterface,
      bookingChannel,
      channel,
      // 以后需要改造本地WingonFilter
    } as unknown as WingonFilter;
    onChange(tmp);
    setQueryExt(tmp);

    if (tkRange) {
      const globalStart = (tkRange && tkRange[0]?.format(DATE_FORMAT)) || "";
      const globalEnd = (tkRange && tkRange[1]?.format(DATE_FORMAT)) || "";
      if (
        globalStart !== partnerCondition.startDate ||
        globalEnd !== partnerCondition.endDate
      ) {
        setPartnerCondition({
          ...partnerCondition,
          startDate: globalStart,
          endDate: globalEnd,
        });
      }
    }
  });

  /**
   * 设置condition后首次load
   */
  useEffect(() => {
    if (!loaded && partnerCondition.partner) {
      submit();
      setLoaded(true);
    }
  }, [loaded, partnerCondition.partner, submit]);

  const channelOptions = useRef<IValueLabelOption[]>([
    {
      value: "Wingon_22394258",
      label: "Wingon_22394258",
    },
    {
      value: "Wingon_23434100",
      label: "Wingon_23434100",
    },
    {
      value: "Wingon_14298803",
      label: "Wingon_14298803",
    },
  ]).current;

  return (
    <Affix
      offsetTop={0}
      target={() => document.getElementById("main")}
      className="toolbar-wrap"
    >
      <div>
        <div className="toolbar">
          <Row gutter={[{ md: 24 }, 20]} align="middle">
            <Col xxl={8} xl={12}>
              <span style={{ marginRight: 10 }}>
                {getSharkText("config_page_query_booking_date")}
              </span>
              <DateRangePickerV2
                defaultDateMode={defaultSearchRange}
                onChange={setSearchRange}
                otherProps={{ allowClear: true }}
              />
            </Col>
            <Col xxl={8} xl={12}>
              <span style={{ marginRight: 10 }}>
                {getSharkText("charts.date_of_departure")}
              </span>
              <DateRangePickerV2
                defaultDateMode={defaultTkRange}
                onChange={setTkRange}
                otherProps={{ allowClear: true }}
              />
            </Col>
            <Col xxl={8}>
              <span style={{ marginRight: 10 }}>
                {getSharkText("key.axis_display.name")}
              </span>
              <Radio.Group
                value={axisType}
                onChange={(e) => setAxisType(e.target.value)}
              >
                <Radio value={0}>
                  {getSharkText("config_page_PaAiWiCoTo_query_booking_date")}
                </Radio>
                <Radio value={1}>
                  {getSharkText("charts.date_of_departure")}
                </Radio>
              </Radio.Group>
            </Col>
            <Col>
              <Row gutter={[10, 10]}>
                <Col>
                  <CityAirportSelectorPortal
                    onChange={setDepartArea}
                    type="city"
                  />
                </Col>
                <Col>
                  <SwapRightOutlined />
                </Col>
                <Col>
                  <CityAirportSelectorPortal
                    onChange={setArriveArea}
                    type="city"
                  />
                </Col>
              </Row>
            </Col>
            <Col>
              <Search
                title={getSharkText("config_page_engine")}
                filter={queryExt}
                onChange={setBookingChannel}
                type={0}
              />
            </Col>
            <Col>
              <span style={{ marginRight: 10 }}>
                {getSharkText("subchannel")}
              </span>
              <Select
                mode="multiple"
                options={channelOptions}
                onChange={setChannel}
                style={{ minWidth: 150 }}
              ></Select>
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={submit}
                value={getSharkText("key.query.button")}
              >
                {getSharkText("key.query.button")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    </Affix>
  );
};
export default TopFilter;
