import React, { useEffect, useReducer, useState } from "react";
import { Button, Col, Radio, Row, Select, Spin, Tooltip } from "antd";
import { AirportCompareQueryExt, ISeries } from "Interface";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import Refetch from "Components/Refetch";
import { getServer } from "Service/server";
import { round } from "lodash";
import { AirportAirlinesShareData } from "@ctrip/flt-bi-flightai-airports";
import { SwapLeftOutlined, SwapRightOutlined } from "@ant-design/icons";
import EchartsReactBase from "../../../../../Components/EchartsReactBase";
import RankSortBtn from "../../../../../Components/RankSortBtn";

interface ICompareChartConfig {
  series: Array<Partial<ISeries>>;
  yAxisData: string[];
  compareAxisData: string[];
}
interface CompareCardProps {
  queryUrl: string;
  moduleCode: string;
  airportCompareQueryExt: AirportCompareQueryExt;
}

const CompareShare: React.FC<CompareCardProps> = (props: CompareCardProps) => {
  const { queryUrl, moduleCode, airportCompareQueryExt } = props;

  const [globalState] = useGlobal();
  const { queryCondition, systemType, moduleConfig } = globalState;
  const { Option } = Select;
  const compareAirport = airportCompareQueryExt.compareAirport;
  const options = [
    {
      label: getSharkText("airport_compare.airline_market_share.seats_share"),
      value: "transport_capacity",
    },
    {
      label: getSharkText(
        "airport_compare.airline_market_share.number_of_flts_share"
      ),
      value: "flight_sorties",
    },
    {
      label: getSharkText(
        "airport_compare.airline_market_share.psgr_traffic_share"
      ),
      value: "passenger_traffic",
      disabled: moduleConfig["allow.airlines.passenger.traffic"] != "true",
    },
  ];
  const [cardValue, setcardValue] = useState("transport_capacity");
  const [topShow, setTopShow] = useState(10);
  const [sort, setSort] = useState(1);

  const handleSort = () => {
    setSort(sort === 0 ? 1 : 0);
  };
  const renderRadio = (option: any) => {
    if (option.disabled) {
      return (
        <Tooltip title={getSharkText("flightai.data_purchase.hint")}>
          <Radio disabled>{option.label}</Radio>
        </Tooltip>
      );
    }
    return <Radio value={option.value}>{option.label}</Radio>;
  };

  const handleChange = (e: any) => {
    setcardValue(e.target.value);
  };

  const handleChangeTop = (value: number) => {
    setTopShow(value);
  };

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
    },
    query: queryCondition,
    ext: {
      compareAirport,
      cardCode: cardValue,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query: queryCondition,
      ext: { compareAirport, cardCode: cardValue },
    });
  };

  useEffect(() => {
    refetch();
  }, [
    queryCondition,
    airportCompareQueryExt,
    compareAirport,
    cardValue,
    topShow,
    sort,
  ]);

  const [config, setConfig] = useReducer(
    (state: any, v: ICompareChartConfig) => {
      return v;
    },
    { series: [], yAxisData: [], compareAxisData: [] }
  );

  useEffect(() => {
    const groupdate = (data as AirportAirlinesShareData[]) ?? [];
    const tmp = [] as any[];

    if (groupdate.length) {
      Object.keys(groupdate[0]).forEach((key) => {
        if (key == "compareProportion") {
          tmp.push({
            type: "bar",
            stack: "all",
            name: compareAirport.airport,
            data: groupdate
              .map((item) => {
                if (item.compareProportion != null) {
                  return {
                    name: compareAirport.airport,
                    value: (item.compareProportion * 100).toFixed(1),
                    originVal: (item.compareProportion * 100).toFixed(1),
                    airline: item.airline,
                  };
                } else {
                  return {
                    name: compareAirport.airport,
                    value: 0,
                    originVal: "",
                    airline: item.airline,
                  };
                }
              })
              .sort((a, b) => {
                if (sort > 0) {
                  return parseFloat(b.originVal) - parseFloat(a.originVal);
                } else {
                  return parseFloat(a.originVal) - parseFloat(b.originVal);
                }
              })
              .slice(0, topShow),
            label: {
              show: true,
              position: "right",
              formatter: (d: any) => round(d.data.originVal, 2) + "%",
            },
          });
        } else if (key == "proportion") {
          tmp.push({
            type: "bar",
            stack: "all",
            name: queryCondition.airport,
            data: groupdate
              .map((item) => {
                if (item.proportion != null) {
                  return {
                    name: queryCondition.airport,
                    value: (item.proportion * -100).toFixed(1),
                    originVal: (item.proportion * 100).toFixed(1),
                    airline: item.airline,
                  };
                } else
                  return {
                    name: queryCondition.airport,
                    value: 0,
                    originVal: "",
                    airline: item.airline,
                  };
              })
              .sort((a, b) => {
                if (sort > 0) {
                  return parseFloat(b.originVal) - parseFloat(a.originVal);
                } else {
                  return parseFloat(a.originVal) - parseFloat(b.originVal);
                }
              })
              .slice(0, topShow),
            label: {
              show: true,
              position: "left",
              formatter: (d: any) => round(d.data.originVal, 2) + "%",
            },
          });
        }
      });

      setConfig({
        series: tmp,
        yAxisData: tmp[0].data.map((a: any) => {
          return a.airline;
        }),
        compareAxisData: tmp[1].data.map((a: any) => {
          return a.airline;
        }),
      });
    }
  }, [data, topShow, sort]);

  const option = {
    color: ["#177dfe", "#01c5db"],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    grid: {
      show: true,
      top: 30,
      bottom: 20,
      left: 10,
      right: 10,
      backgroundColor: "#fff",
      borderWidth: 0,
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let dom = "";
        params.forEach((item: any, i: number) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const name = item.seriesName;
          const value = item.data.originVal;
          const valueStr =
            value === null
              ? "--"
              : `<span style="width: 50px;display:inline-block">${round(
                  value,
                  2
                )}%</span>`;
          const val = `<span>${name}: ${valueStr}</span>`;
          if (i !== 0) {
            dom += "<br />";
          }
          dom += dot + val;
        });
        return dom;
      },
    },
    xAxis: {
      type: "value",
      show: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      max: (value: any) => {
        const { max, min } = value;
        const absMax = Math.max(Math.abs(max), Math.abs(min));
        return absMax * 1.1;
      },
      min: (value: any) => {
        const { max, min } = value;
        const absMax = Math.max(Math.abs(max), Math.abs(min));
        return min < 0 ? -absMax * 1.1 : min * 1.1;
      },
    },
    yAxis: [
      {
        type: "category",
        data: config.yAxisData,
        axisLabel: {
          interval: 0,
        },
        inverse: true,
        axisTick: {
          show: false,
        },
      },
      {
        type: "category",
        data: config.compareAxisData,
        axisLabel: {
          interval: 0,
        },
        inverse: true,
        axisTick: {
          show: false,
        },
      },
    ],
    series: config.series,
  };
  console.log(option);
  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <div>
      <Row>
        <Col>
          <Radio.Group
            onChange={handleChange}
            value={cardValue}
            optionType="button"
            buttonStyle="solid"
          >
            {options.map((option) => (
              <React.Fragment key={option.value}>
                {renderRadio(option)}
              </React.Fragment>
            ))}
          </Radio.Group>
        </Col>
        <Col offset={1}>
          <Select
            defaultValue={10}
            style={{ width: 100 }}
            onChange={handleChangeTop}
          >
            <Option value={10}>Top 10</Option>
            <Option value={20}>Top 20</Option>
            <Option value={30}>Top 30</Option>
            <Option value={50}>Top 50</Option>
          </Select>
        </Col>
        <Col>
          <RankSortBtn sort={Boolean(sort)} handleSortChange={handleSort} />
        </Col>
      </Row>
      <Spin spinning={isLoading}>
        <EchartsReactBase
          className="bar-line-charts"
          option={option}
          theme="default"
          style={{ height: 500 }}
        />
      </Spin>
    </div>
  );
};

export default CompareShare;
