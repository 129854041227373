import { IModuleConfig } from "Interface";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import useGlobalState from "Store";
import { useFetch } from "Utils";

interface UpdateTimeProps {
  moduleCode: string;
  chartTableCode: string;
}

export const useModuleConfig = (props: UpdateTimeProps): void => {
  const { moduleCode, chartTableCode } = props;
  const [, actions] = useGlobalState();
  const { setModuleConfig } = actions;
  const [, doFetch] = useFetch({
    url: "getModuleConfig",
    head: {
      moduleCode,
      chartTableCode,
    },
    lazey: true,
    onSuccess: (res) => {
      if (!isEmpty(res)) {
        const tmpModuleConfig: Partial<IModuleConfig> = { loading: false };
        // 开关配置项 允许查询所有机场
        if (res.moduleConfig) {
          const config = res.moduleConfig;
          setModuleConfig({
            ...config,
            ...tmpModuleConfig,
            allowAccessAllAirports:
              config["allow.access.allAirports"] === "true",
            updateTime: config.updateTime,
          });
        } else {
          setModuleConfig(tmpModuleConfig);
        }
      }
    },
  });

  useEffect(() => {
    if (!moduleCode) {
      return;
    }
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
    });
  }, [chartTableCode, doFetch, moduleCode]);
};
