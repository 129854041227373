import React, { useState } from "react";
import { SyncOutlined } from "@ant-design/icons";
import { Alert, Button, Col, Empty, Row } from "antd";
import useGlobal from "Store";
import Page from "Layout/Page";
import Follow from "./Components/Follow";
import MonitorTable from "./Components/MonitorTable";
import { getModule, getModuleNameFromPath, isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import NotificationCenter from "Page/AI/NotificationCenter";
import { ECompareTypeV2 } from "Interface";
import { useLocation } from "react-router-dom";

const PriceMonitor: React.FC = () => {
  const location = useLocation();
  const [refetchCount, setRefetchCount] = useState<number>(0);
  const [globalState] = useGlobal();
  const { systemType, userInfo } = globalState;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const isFormal = module ? module.moduleStatus === 1 : false;
  const isAirport = isAirportMode(systemType);

  const alertMessage = (
    <Row gutter={20}>
      <Col style={{ flex: 1 }}>
        {getSharkText("config_page_real_time_monitoring")}
      </Col>
      <Col>
        <Button
          type="primary"
          size="small"
          icon={<SyncOutlined />}
          onClick={() => setRefetchCount(refetchCount + 1)}
        >
          {getSharkText("key.refresh.button")}
        </Button>
      </Col>
    </Row>
  );

  return (
    <Page
      defaultDateMode="lastMonth"
      airlinesCompareType={ECompareTypeV2.MARKET_COMPARE}
    >
      {isAirport ? (
        <Empty
          style={{ marginTop: 50 }}
          description={getSharkText(
            "config_page_price_monitoring_only_supports_airline_edition"
          )}
        />
      ) : (
        <>
          <div className="content-white">
            <Alert message={alertMessage} banner type="info" />
            <div style={{ marginTop: 20 }}>
              <Follow
                queryUrl={QUERY_URL[systemType].follow}
                moduleCode={MODULE_CODE[systemType]}
                chartTableCode={CHART_TABLE_CODE[systemType].follow}
                refetchCount={refetchCount}
                isFormal={isFormal}
              />
            </div>
          </div>
          <div className="content-white">
            <MonitorTable
              queryUrl={QUERY_URL[systemType].table}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].table}
              refetchCount={refetchCount}
            />
          </div>
        </>
      )}
      <NotificationCenter />
    </Page>
  );
};

export default PriceMonitor;
