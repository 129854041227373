// Created by xh_zhu on 2021-02-08

import { InfoCircleOutlined } from "@ant-design/icons";

import { Checkbox, Popover, Select } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import {
  COMPARE_TYPE_HELP,
  COMPARE_TYPE_PER_NAME,
  COMPARE_TYPE_PLC_NAME,
  DEFAULT_AIRPORT_COMPARE_TYPE_VALUE,
  EYearCompareType,
} from "Constants";
import { ECompareTypeV2, T_YEAR_COMPARE_TYPE } from "Interface";
import { difference } from "lodash";
import React, { ReactElement, useEffect, useState } from "react";
import { validateAllowValControl } from "./service";

const { Option } = Select;

interface IPreLastChainProps {
  defaultValue?: string[];
  onChange?: (val: string[]) => void;
  startDate?: string;
  endDate?: string;
}

export const PreLastChain = (props: IPreLastChainProps): ReactElement => {
  const { defaultValue = [], startDate, endDate, onChange } = props;
  const [valControl, setValControl] = useState<string[]>(defaultValue);
  const [yoyIdx, setYoyIdx] = useState<T_YEAR_COMPARE_TYPE>(
    DEFAULT_AIRPORT_COMPARE_TYPE_VALUE
  );

  useEffect(() => {
    if (onChange) {
      onChange(valControl);
    }
  }, [valControl, onChange]);

  // 非整周数据移除周环比, 非整月数据移除月环比
  useEffect(() => {
    console.log("update val control");
    const tmpVal = validateAllowValControl(valControl, startDate, endDate);
    if (tmpVal.length !== valControl.length) {
      setValControl(tmpVal);
      if (onChange) {
        onChange(tmpVal);
      }
    }
  }, [startDate, endDate, valControl, onChange]);

  const handleValControlChange = (val: CheckboxValueType[]) => {
    // 得到新增的项
    const checked = val as string[];
    // 先检测选中项是否符合要求
    const diff = difference(checked, valControl);
    const allowed = validateAllowValControl(diff, startDate, endDate);
    // 如果不符合要求, 则不继续操作
    if (allowed.length !== diff.length) {
      return;
    }
    console.log(valControl);
    if (checked && checked.length > 1) {
      setValControl(checked.filter((item) => item !== valControl[0]));
    }
  };

  const changeYoy = (val: T_YEAR_COMPARE_TYPE) => {
    setYoyIdx(val);
    // 排除年同比的选项, 再选中年同比的选项
    const yearList = Object.values(EYearCompareType).map(
      (year) => COMPARE_TYPE_PER_NAME[year]
    );
    const tmpVal = valControl.filter(
      (item: string) => !yearList.includes(item)
    );
    tmpVal.push(COMPARE_TYPE_PLC_NAME[val]);
    setValControl(tmpVal);
  };

  const selectClicked = (v: any) => {
    v.stopPropagation();
    v.preventDefault();
  };

  const yoyHelpNode = (
    <span style={{ display: "inline-block", width: 240 }}>
      {COMPARE_TYPE_HELP[yoyIdx]}
    </span>
  );

  return (
    <Checkbox.Group
      value={valControl}
      onChange={handleValControlChange}
      style={{ paddingTop: 4 }}
    >
      <Checkbox key="1" value={COMPARE_TYPE_PLC_NAME[yoyIdx]}>
        <span onClick={selectClicked}>
          <Select value={yoyIdx} onChange={changeYoy} size="small">
            {EYearCompareType.map((item) => (
              <Option key={item} value={item}>
                {COMPARE_TYPE_PLC_NAME[item]}
              </Option>
            ))}
          </Select>
          <Popover
            content={yoyHelpNode}
            overlayClassName="dashboard-val-control-popover"
          >
            <InfoCircleOutlined />
          </Popover>
        </span>
      </Checkbox>
      <Checkbox key="2" value={COMPARE_TYPE_PLC_NAME[ECompareTypeV2.WEEK]}>
        <span>{COMPARE_TYPE_PLC_NAME[ECompareTypeV2.WEEK]}</span>
      </Checkbox>
      <Checkbox key="3" value={COMPARE_TYPE_PLC_NAME[ECompareTypeV2.MONTH]}>
        <span>{COMPARE_TYPE_PLC_NAME[ECompareTypeV2.MONTH]}</span>
      </Checkbox>
    </Checkbox.Group>
  );
};
export default PreLastChain;
