import React from "react";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message } from "antd";
import useGlobal from "Store";
import { SERVER } from "Constants";
import VerifyCode from "./verifyCode";
import { useForm } from "antd/es/form/Form";
import useI18Next from "Utils/useI18Next";

interface IFormComponentProps {
  mode: string;
}

const Forget = (props: IFormComponentProps) => {
  const [globalState, actions] = useGlobal();
  const { verifyInfo, refreshCode } = globalState;
  const { setLoginPageState, setRefreshCodeState } = actions;
  const t = useI18Next();
  const [form] = useForm();

  const forget = async (params: any) => {
    const result: any = await fetch(`${SERVER}forgotPassword`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        account: params.account,
        mail: params.mail,
        validCode: {
          token: verifyInfo.token,
          version: verifyInfo.version,
          requestId: verifyInfo.requestId,
        },
      }),
    });
    const res = await result.json();
    if (res.flag) {
      setLoginPageState({
        mode: "reset-submit-success",
      });
    } else {
      const code = res.ResponseStatus.Errors[0].ErrorCode;
      message.error(t(`error.${code}`));
      setRefreshCodeState(!refreshCode);
    }
  };

  const handleForgetSubmit = async (e: any) => {
    const rst = await form.validateFields();
    if (rst) {
      if (verifyInfo.token === "") {
        message.error(t("user.login.needSecurity"));
      } else {
        forget(form.getFieldsValue());
      }
    }
  };

  const handleToggleLogin = () => {
    setLoginPageState({
      mode: "login",
    });
  };

  return (
    <Card className="login-card">
      <div className="login-card-title-wrap">
        <div className="login-card-title">{t("user.resetPwd.title")}</div>
        <div className="login-card-subtitle">{t("user.resetPwd.resetMsg")}</div>
      </div>
      <Form onFinish={handleForgetSubmit} form={form}>
        <Form.Item
          name="account"
          rules={[{ required: true, message: t("user.resetPwd.enterAccount") }]}
        >
          <Input
            prefix={<UserOutlined className="login-input-icon" />}
            placeholder={t("user.resetPwd.enterAccount")}
            className="login-card-input"
          />
        </Form.Item>
        <Form.Item
          name="mail"
          rules={[
            { required: true, message: t("user.resetPwd.enterEmail") },
            {
              pattern:
                /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
              message: t("user.register.enterEmailWarn"),
            },
          ]}
        >
          <Input
            prefix={<MailOutlined className="login-input-icon" />}
            placeholder={t("user.resetPwd.enterEmail")}
            className="login-card-input"
          />
        </Form.Item>
        <Form.Item>
          <VerifyCode />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="login-form-button"
          >
            {t("common.submit")}
          </Button>
          <a href="#" onClick={handleToggleLogin}>
            {t("user.resetPwd.backToLogin")}
          </a>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Forget;
