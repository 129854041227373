import { Tabs, TabsProps } from "antd";
import React, { ReactElement } from "react";
import Behind from "./page/Behind";
import Beyond from "./page/Beyond";
import OD from "./page/OD";

const tabs: TabsProps["items"] = [
  {
    key: "beyond",
    label: "Beyond Traffic",
    children: <Beyond />,
  },
  {
    key: "behind",
    label: "Behind Traffic",
    children: <Behind />,
  },
  {
    key: "od",
    label: "OD Analysis",
    children: <OD />,
  },
];

export interface ODAnalysisProps {
  example?: string;
}

/** OD分析 */
const ODAnalysis = (props: ODAnalysisProps): ReactElement => {
  const { example } = props;
  return (
    <div className="od_analysis">
      <div className="content-white" style={{ margin: "0 200px" }}>
        <Tabs items={tabs} size="small" />
      </div>
    </div>
  );
};
export default ODAnalysis;
