import React, { useState, useEffect, useRef } from "react";
import { Empty, Spin } from "antd";
import useGlobal from "Store";
import _ from "lodash";
import { useFetch } from "Utils";
import Refetch from "Components/Refetch";
import BMap from "Components/BMap";
import { getServer } from "Service/server";
import "echarts/extension/bmap/bmap";
import EchartsReactBase from "Components/EchartsReactBase";

interface ChartMapProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
  ext?: any;
}
interface Data {
  airport: string;
  airportName: string;
  value: number;
  compareValue: number;
  lng: number;
  lat: number;
}

let mapDebug = {
  tooltip: null,
  series: null,
} as any;
// @ts-ignore
window.mapDebug = (v = true) => {
  if (v) {
    mapDebug = {
      tooltip: {
        formatter: (item: any) => {
          return item.value.join(" | ");
        },
      },
      series: {
        // series图表类型
        type: "scatter",
      },
    };
  } else {
    mapDebug = {
      tooltip: null,
      series: null,
    };
  }
};

const TopChartMap: React.FC<ChartMapProps> = (props: ChartMapProps) => {
  const { queryUrl, moduleCode, chartTableCode, ext: otherExt } = props;
  const [option, setOption] = useState<object>({});
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const { airport } = queryCondition;
  const ext = { ...otherExt };
  const [cityId, setCityId] = useState<number>(1);
  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryCondition,
    ext,
    lazey: true,
  });
  const chartsRef = useRef<any>(null);
  const refetch = () => {
    doFetch({ query: queryCondition, ext: { ...ext } });
  };
  useEffect(() => {
    // 必须执行这一步才能引入百度地图组件
    BMap.init();
  }, []);

  const [{ res }, doFetch1] = useFetch({
    url: "findCity",
    head: {
      moduleCode: "airline_analysis_airlines",
      chartTableCode: "AL0202",
    },
    lazey: true,
    ext: {
      cityId,
    },
  });

  useEffect(() => {
    if (otherExt.area && (otherExt.city || (otherExt.filter && otherExt.row))) {
      refetch();
    }
    if (otherExt.row) {
      setCityId(otherExt.row.cityId);
    } else if (otherExt.city) {
      setCityId(otherExt.city.cityId);
    }
  }, [queryCondition, otherExt]);

  const refetch1 = () => {
    doFetch1({
      ext: {
        cityId,
      },
    });
  };
  useEffect(() => {
    refetch1();
  }, [cityId]);

  // 机场变化的时候重置map
  useEffect(() => {
    setMapLoaded(false);
  }, [airport]);
  const convertData = (source: Data[]) => {
    let sum = 0;
    const rst: any[] = [];
    if (!source) {
      return rst;
    }
    source.forEach((d: any) => {
      const v = Math.round(d.value);
      sum += v;
      const geoCoord = [d.lng, d.lat, v];

      if (geoCoord) {
        rst.push(geoCoord);
      }
    });
    return [rst, sum];
  };
  const bigCities = [1, 2];
  // 地图在全屏和取消全屏时, 由于页面的滚动会导致中心点偏移, 需要设置一个变量在切换全屏时记忆滚动距离, 取消全屏时再设置回来
  let scrollTopInBMap = 0;
  const loadMapData = (source: Data[]) => {
    const res1 = res && res.data && res.data.length ? res.data[0] : null;
    const center =
      res1 && res1.lng && res1.lat
        ? [res1.lng, res1.lat]
        : [source[0].lng, source[0].lat];
    // const zoomLevel = bigAirports.includes(data[0].airport) ? 10 : 9;
    const zoomLevel = bigCities.includes(cityId) ? 11 : 10;
    const [d, sum] = convertData(source);
    const hotStandard = sum / 10000 > 20 ? sum / 7000 : 20;
    const visualMaxMin = sum
      ? {
          max: hotStandard,
        }
      : null;
    const optionTemp = {
      // backgroundColor: "#fff",
      // 加载 bmap 组件
      bmap: {
        // 百度地图中心经纬度
        // center: [121.33066322374, 31.204018034923],
        center,
        // 百度地图缩放
        zoom: zoomLevel,
        // 是否开启拖拽缩放，可以只设置 'scale' 或者 'move'
        roam: true,
        // // 百度地图的自定义样式，见 http://developer.baidu.com/map/jsdevelop-11.htm
        // mapStyle: {}
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: false },
          dataZoom: { show: false },
          restore: { show: false },
          saveAsImage: { show: false },
          myFull: {
            show: true,
            title: "Full Screen View",
            icon: `path://M64.46799 531.124935c34.111289 0 61.929376 25.535468 64.297327 58.814775l0.170664 4.586571-0.042666 217.062144L374.733526 
            573.428054c21.972876-22.399533 56.852149-25.023479 82.344952-8.255828l4.373242 3.157267 3.967917 3.370597c25.044812 23.03952 26.602112 
            61.225391 4.693236 87.251515l-4.159914 4.479907-241.850961 233.744464h218.171455c32.468657 0 59.284098 23.167517 63.785337 54.121539l0.51199 
            4.693235 0.14933 4.586571c0 33.940626-26.346118 60.969396-59.838753 63.252016l-4.607904 0.14933H64.46799c-34.132622 
            0-61.950709-25.535468-64.297327-58.814775L0 
            960.577321v-366.072373c0-35.476594 28.7994-63.380013 64.46799-63.380013zM959.510677 0c34.132622 0 61.950709 25.535468 64.297327 58.814775l0.170663 
            4.586571v366.072373c0 35.476594-28.7994 63.380013-64.46799 63.380013A63.913335 63.913335 0 0 1 895.981334 
            439.542843l-0.682653-4.7999-0.255994-5.290557V211.024937l-248.954814 241.274973c-23.03952 21.183559-58.025458 23.03952-82.259619 
            5.546552l-4.181247-3.263932-3.690589-3.498594a61.929376 61.929376 0 0 1-3.626592-85.395555l3.626592-3.775921L798.575363 126.760026l-216.870149 
            0.021333c-32.468657 0-59.284098-23.146184-63.785337-54.100207l-0.51199-4.693235-0.14933-4.586571c0-33.940626 26.346118-60.969396 
            59.838753-63.252016L581.705214         0h377.805463z`,
            iconStyle: {
              color: "blue",
              background: "#fff",
            },
            onclick: () => {
              const fullscreenElement =
                // 标准
                document.fullscreenElement ||
                // chrome内核
                document.webkitFullscreenElement ||
                // firefox
                document.mozFullScreenElement ||
                // IE;
                document.msFullscreenElement;
              // const element = document.getElementById('vehicleProvince');
              const element = chartsRef.current.ele;
              if (!fullscreenElement) {
                const body = document.querySelector("html");
                if (body) {
                  scrollTopInBMap = body.scrollTop;
                  body.scrollTop = 0;
                }
                // IE 10 and less ActiveXObject
                if (window.ActiveXObject) {
                  // @ts-ignore
                  const WsShell = new ActiveXObject("WScript.Shell");
                  WsShell.SendKeys("{F11}");
                } else if (element.requestFullScreen) {
                  // HTML W3C proposed
                  element.requestFullScreen();
                } else if (element.msRequestFullscreen) {
                  // IE11
                  element.msRequestFullScreen();
                } else if (element.webkitRequestFullScreen) {
                  // Webkit (works in Safari5.1 and Chrome 15)
                  element.webkitRequestFullScreen();
                } else if (element.mozRequestFullScreen) {
                  // Firefox (works in nightly)
                  element.mozRequestFullScreen();
                }
                // isFullScreen = true;
              } else {
                const body = document.querySelector("html");
                if (body) {
                  body.scrollTop = scrollTopInBMap;
                }
                if (document.exitFullscreen) {
                  document.exitFullscreen();
                } else if (document.mozCancelFullScreen) {
                  document.mozCancelFullScreen();
                } else if (document.msExitFullscreen) {
                  document.msExitFullscreen();
                } else if (document.webkitExitFullscreen) {
                  document.webkitExitFullscreen();
                }
                // isFullScreen = false
              }
            },
          },
        },
      },
      visualMap: {
        type: "continuous",
        show: true,
        top: 20,
        realtime: false,
        inRange: {
          color: ["blue", "yellow", "red"],
        },
        ...visualMaxMin,
      },
      title: {
        show: false,
        text: "",
        left: 0,
      },
      tooltip: {
        trigger: "item",
        ...mapDebug.tooltip,
      },
      series: [
        {
          id: "series1",
          name: "heatmap",
          type: "heatmap",
          coordinateSystem: "bmap",
          data: d,
          minOpacity: 0.05,
          pointSize: 3,
          blurSize: 3,
          ...mapDebug.series,
        },
      ],
      animation: false,
    };
    setOption(optionTemp);
  };

  // hook data
  useEffect(() => {
    if (data && data.length) {
      loadMapData(data);
      setMapLoaded(true);
    }
  }, [data, res]);

  useEffect(() => {
    const reRender = () => {
      if (chartsRef && chartsRef.current) {
        const optionTmp = chartsRef.current.getEchartsInstance().getOption();
        chartsRef.current.getEchartsInstance().clear();
        chartsRef.current.getEchartsInstance().setOption(optionTmp);
      }
    };
    const onResize = _.debounce(reRender, 500);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [chartsRef]);

  const chartReady = () => {
    console.log("ready.....");
    loadMapData(data);
  };

  if (error) {
    return <Refetch refetch={refetch} />;
  }
  return (
    <Spin spinning={isLoading}>
      {_.isEmpty(data) ? (
        <Empty style={{ marginTop: 32 }} />
      ) : mapLoaded ? (
        <EchartsReactBase
          className="airlines-map"
          ref={chartsRef}
          option={option}
          style={{ height: 537, width: "100%" }}
          onChartReady={chartReady}
        />
      ) : undefined}
    </Spin>
  );
};

export default TopChartMap;
