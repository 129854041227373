import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Card, Empty, Spin, Table, Typography } from "antd";
import useGlobal from "Store";
import { FlightArea, IDownloadHeader, Tab } from "Interface";
import _, { round } from "lodash";
import { getCompareVal, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import "./DistributionRanking.css";
import { getServer } from "Service/server";

const { Title } = Typography;

const columns: IDownloadHeader[] = [
  {
    title: getSharkText("key.source_of_psgr.text"),
    dataIndex: "cityName",
  },
  {
    title: getSharkText("key.est_num_of_ppl.text"),
    dataIndex: "value",
    render: (val: number) => round(val),
  },
  {
    title: getSharkText("key.proportion_souce.text"),
    dataIndex: "percentOfAll",
    render: (val: number) => <ValueNum type="percentage" value={val * 100} />,
  },
  {
    title: getSharkText("key.yoy_increase.name"),
    dataIndex: "yoyPer",
    render: (val: number) => <ValueNum type="percentage" value={val * 100} />,
  },
  {
    title: getSharkText("key.yoy_increment.name"),
    dataIndex: "yoyCnt",
    render: (val: number) => <ValueNum type="num" value={round(val)} />,
  },
];

interface IProps {
  title?: string;
  tab: Tab[];
  style?: Record<string, unknown>;
  queryUrl: string;
  moduleCode: string;
  area?: Record<string, unknown>;
  sortable?: boolean;
  // 向父组件传递选中项
  selectItem?: (item: FlightArea | null) => void;
  setChartsType?: (type: number) => void;
  cardCode?: string;
  setHasCityData?: (has: boolean) => void;
  ext?: any;
  theme?: "radio" | "button" | "solid";
  itemRender?: (
    item: any,
    index: number,
    currentTab: Tab | null,
    other?: any
  ) => ReactNode;
  onTabChange?: (tab: Tab) => void;
}

const DistributionRanking: React.FC<IProps> = (props: IProps) => {
  const {
    title = "",
    tab,
    style,
    queryUrl,
    moduleCode,
    area,
    selectItem,
    cardCode,
    setHasCityData,
    ext: otherExt,
  } = props;
  const [currentTab, setCurrentTab] = useState<Tab | null>(null);
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const [selectedItem, setSelectedItem] = useState<any>();
  const [transData, setTransData] = useState<any[]>([]);
  const [selectedRowKey, setSelectedRowKey] = useState<number>(0);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode: tab[0].chartTableCode,
    },
    query: queryCondition,
    ext: {
      area,
      // type: currentTab && typeof currentTab.type !== 'undefined' ? currentTab.type : 0,
      type: 1,
      orderSort: 0,
      cardCode,
      ...otherExt,
    },
    lazey: true,
  });

  const refetch = useCallback(() => {
    if (currentTab) {
      doFetch({
        head: {
          moduleCode,
          chartTableCode: currentTab.chartTableCode,
        },
        query: queryCondition,
        ext: {
          area,
          orderSort: 1,
          cardCode,
          // type: currentTab && typeof currentTab.type !== 'undefined' ? currentTab.type : 0,
          type: 1,
          compare: currentTab.compare,
          ...otherExt,
        },
      });
    }
  }, [
    area,
    cardCode,
    currentTab,
    doFetch,
    moduleCode,
    otherExt,
    queryCondition,
  ]);

  useEffect(() => {
    setCurrentTab(tab[0]);
  }, [tab]);

  useEffect(() => {
    if (setHasCityData) {
      setHasCityData(false);
    }
    refetch();
  }, [refetch, setHasCityData]);

  useEffect(() => {
    if (!selectItem) {
      return;
    }
    if (selectedItem) {
      selectItem(selectedItem);
    } else {
      selectItem(null);
    }
  }, [selectedItem, selectItem]);

  useEffect(() => {
    setTransData([]);
    if (data && data.length) {
      setSelectedItem(data[0]);
    }
    if (data && setHasCityData) {
      setHasCityData(true);
    }
    let sum = 0;
    if (data && data.length) {
      data.forEach((d: any) => {
        sum += d.value;
      });
      // 数据计算和转化
      const trans = data.map((d: any, index: number) => {
        const tmp = {
          ...d,
          key: index,
          percentOfAll: sum ? d.value / sum : 0,
          yoyCnt: d.value - d.compareValue,
          yoyPer: getCompareVal(d.value, d.compareValue),
        };
        return tmp;
      });
      setTransData(trans);
    }
    setSelectedRowKey(0);
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const onRow = (record: any) => {
    return {
      onClick: () => {
        setSelectedRowKey(record.key);
        setSelectedItem(record);
      },
    };
  };

  return (
    <Spin spinning={isLoading}>
      <Card className="passenger-ranking-card" style={style}>
        {title && <Title level={4}>{title}</Title>}
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <Table
            showSorterTooltip={false}
            columns={columns}
            dataSource={transData}
            onRow={onRow}
            size="small"
            rowSelection={{
              selectedRowKeys: [selectedRowKey],
              hideSelectAll: true,
              renderCell: () => null,
            }}
            pagination={false}
          />
        )}
      </Card>
    </Spin>
  );
};

export default DistributionRanking;
