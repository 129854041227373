import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Select } from "antd";
import { CompareContent, CompareTypes } from "Constants";
import { ECompareTypeV2 } from "Interface";
import React, { ReactElement, useMemo } from "react";
import { getSharkText } from "Utils/i18nGlobal";
import useMergedState from "Utils/useMergedState";
import useRefFunc from "Utils/useRefFunc";

export interface ICompareTypeCoreProps {
  source?: Partial<Record<ECompareTypeV2, ECompareTypeV2>>;
  /**
   * 对比配置, 名称, 说明文字等, 只需要配置与CompareContent不同的枚举项
   */
  compareContent?: Partial<Record<ECompareTypeV2, CompareContent>>;
  title?: string;
  value?: ECompareTypeV2;
  defaultValue: ECompareTypeV2;
  onChange?: (v: ECompareTypeV2) => void;
}

/**
 * Compare的核心功能, 不含业务逻辑
 */
const CompareTypeCore: React.FC<ICompareTypeCoreProps> = (
  props
): ReactElement => {
  const {
    source = ECompareTypeV2,
    compareContent,
    title = getSharkText("key.benchmarking"),
    value,
    defaultValue,
    onChange,
  } = props;
  const [innerValue, setInnerValue] = useMergedState<ECompareTypeV2>(
    defaultValue,
    { value }
  );

  const typeContents = useMemo(() => {
    return {
      ...CompareTypes,
      ...compareContent,
    };
  }, [compareContent]);

  const options = useMemo(() => {
    return Object.values(source).map((item) => {
      const key = item as ECompareTypeV2;
      return {
        label: typeContents[key].compareName,
        value: item,
      };
    });
  }, [source, typeContents]);
  const handleChange = useRefFunc((v: ECompareTypeV2) => {
    if (onChange) {
      onChange(v);
    } else {
      setInnerValue(v);
    }
  });
  return (
    <>
      {title ? <span>{title}：</span> : undefined}
      <Select value={innerValue} onChange={handleChange} options={options} />
      <Popover
        content={
          <span style={{ display: "inline-block", width: 240 }}>
            {typeContents[innerValue].introduce}
          </span>
        }
      >
        <InfoCircleOutlined style={{ marginLeft: 5 }} />
      </Popover>
    </>
  );
};
export default CompareTypeCore;
