import { Alert } from "antd";
import UpdateTime from "Components/UpdateTime";
import Page from "Layout/Page";
import React, { ReactElement } from "react";
import useGlobal from "Store";
import { CHART_TABLE_CODE, MODULE_CODE } from "./fetchCode";
import AirportIndexPanel from "./Components/AirportIndexPanel";
import AirportOnTime from "./Components/AirportOnTime";
import FlightWave from "./Components/FlightWave";
import { getSharkText } from "Utils/i18nGlobal";

interface AirportIndexProps {
  intl?: boolean;
}

const AirportIndex = (
  props: AirportIndexProps
): ReactElement<AirportIndexProps> => {
  const { intl } = props;
  const [globalState] = useGlobal();
  const { systemType } = globalState;
  const lastWeekAlertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].top10}
      text={
        <span>
          {getSharkText("key.stopover_codeshare_excluded.hint")}
          <span style={{ fontWeight: "bold" }}>
            {getSharkText("key.note1.flightplan")}
          </span>
          <br />
          {getSharkText("key.note2.flightplan")}
        </span>
      }
    />
  );
  const CAACAlertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].top10}
      text={
        <span>
          {getSharkText("key.stopover_codeshare_excluded.hint")}
          <span style={{ fontWeight: "bold" }}>
            {getSharkText("key.note3.flightplan")}
          </span>
          <br />
          {getSharkText("key.note2.flightplan")}
        </span>
      }
    />
  );
  return (
    <Page
      defaultDateMode="ninetyDays"
      airlinesCompareType={1}
      isShowCompareType={false}
      intl={intl}
    >
      <AirportIndexPanel
        panelType={0}
        alert={<Alert message={lastWeekAlertMessage} banner type="info" />}
        intl={intl}
      />
      {/* <FlightWave /> */}
      <AirportOnTime />
      <AirportIndexPanel
        panelType={1}
        alert={<Alert message={CAACAlertMessage} banner type="info" />}
      />
    </Page>
  );
};
export default AirportIndex;
