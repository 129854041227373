import React, { useEffect, useState } from "react";
import { Card, Empty, Spin, Table, Typography } from "antd";
import useGlobal from "Store";
import { IDownloadHeader } from "Interface";
import _, { round } from "lodash";
import { getCompareVal, getProportionPercentageVal, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import ValueNum from "Components/ValueNum";
import Refetch from "Components/Refetch";
import "./DistributionRanking.css";
import { getServer } from "Service/server";

const { Title } = Typography;

const columns: IDownloadHeader[] = [
  {
    title: "航线",
    dataIndex: "deportName",
    render: (val: any, record: any) =>
      `${record.dportName}-${record.aportName}`,
  },
  {
    title: getSharkText("key.est_num_of_ppl.text"),
    dataIndex: "value",
    render: (val: number) => round(val),
  },
  {
    title: getSharkText("key.proportion_souce.text"),
    dataIndex: "percentOfAll",
    render: (val: number, record: any) => (
      <ValueNum
        type="percentage"
        value={getProportionPercentageVal(record.value, record.totalValue)}
      />
    ),
  },
  {
    title: getSharkText("key.yoy_increase.name"),
    dataIndex: "yoyPer",
    render: (val: number) => <ValueNum type="percentage" value={val * 100} />,
  },
  {
    title: getSharkText("key.yoy_increment.name"),
    dataIndex: "yoyCnt",
    render: (val: number) => <ValueNum type="num" value={round(val)} />,
  },
];

interface IProps {
  title?: string;
  style?: Record<string, unknown>;
  chartTableCode: string;
  queryUrl: string;
  moduleCode: string;
  area?: Record<string, unknown>;
  ext?: any;
}

const LossChartRanking: React.FC<IProps> = (props: IProps) => {
  const {
    title = "",
    style,
    queryUrl,
    moduleCode,
    area,
    ext: otherExt,
    chartTableCode,
  } = props;
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const [transData, setTransData] = useState<any[]>([]);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: {
      ...queryCondition,
    },
    ext: {
      area,
      // type: currentTab && typeof currentTab.type !== 'undefined' ? currentTab.type : 0,
      type: 1,
      orderSort: 0,
      ...otherExt,
    },
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      head: {
        moduleCode,
        chartTableCode,
      },
      query: queryCondition,
      ext: {
        area,
        type: 1,
        ...otherExt,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [area, queryCondition, otherExt]);

  useEffect(() => {
    setTransData([]);
    let sum = 0;
    if (data && data.length) {
      data.forEach((d: any) => {
        sum += d.value;
      });
      // 数据计算和转化
      const trans = data.map((d: any, index: number) => {
        const tmp = {
          ...d,
          key: index,
          percentOfAll: sum ? d.value / sum : 0,
          yoyCnt: d.value - d.compareValue,
          yoyPer: getCompareVal(d.value, d.compareValue),
        };
        return tmp;
      });
      setTransData(trans);
    }
  }, [data]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  return (
    <Spin spinning={isLoading}>
      <Card className="passenger-ranking-card" style={style}>
        {title && <Title level={4}>{title}</Title>}
        {_.isEmpty(data) ? (
          <Empty style={{ marginTop: "32px" }} />
        ) : (
          <Table
            showSorterTooltip={false}
            columns={columns}
            dataSource={transData}
            size="small"
            pagination={false}
          />
        )}
      </Card>
    </Spin>
  );
};

export default LossChartRanking;
