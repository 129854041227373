import React, { useState, useEffect } from "react";
import { Button, Input, Modal, message, Descriptions, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { FileType } from "Page/AI/FileSharing/fetchCode";

interface ToolbarProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode?: string;
  visible: boolean;
  fileType: FileType;
  setVisible: (visible: boolean) => void;
  onUploadSuccess: () => void;
}

const { Dragger } = Upload;
const UploadModal: React.FC<ToolbarProps> = (props: ToolbarProps) => {
  const {
    queryUrl,
    moduleCode,
    chartTableCode,
    setVisible,
    onUploadSuccess,
    fileType,
    visible,
  } = props;
  const [loading, setLoading] = useState(false);
  const [source, setSource] = useState<string>("");
  const [fileSize] = useState<number>(0);
  const [fileName] = useState<string>("");
  const [base64] = useState<string>("");
  const [fileList, setFileList] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [successCount, setSuccessCount] = useState<number>(0);
  const [failCount, setFailCount] = useState<number>(0);
  const [cancelled, setCancelled] = useState<boolean>(false);

  const [, doFetch] = useFetch({
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    ext: {
      file: {
        fileName,
        source,
        fileSize,
        typeId: fileType.typeId,
        base64,
      },
    },
    lazey: true,
    onSuccess: () => {
      setSuccessCount(successCount + 1);
      linkInvoke();
    },
    onError: () => {
      // message.error('上传失败')
      setFailCount(failCount + 1);
      linkInvoke();
    },
  });

  useEffect(() => {
    if (visible) {
      setSource("");
      setCount(0);
      setSuccessCount(0);
      setFailCount(0);
      setLoading(false);
      setFileList([]);
      setCancelled(false);
    }
  }, [visible]);

  const linkInvoke = () => {
    const file = fileList.find((item) => item.status !== "done");
    if (!cancelled && file) {
      file.status = "done";
      setCount(count + 1);
      doFetch({
        ext: {
          file: {
            fileName: file.name,
            source,
            fileSize: file.size,
            typeId: fileType.typeId,
            base64: file.base64,
          },
        },
      });
    } else {
      message.success(
        `共${count}个文件，本次成功上传${
          successCount + 1
        }个，失败${failCount}个`
      );
      setLoading(false);
      setVisible(false);
      onUploadSuccess();
    }
  };
  const handleOk = () => {
    if (!source || !fileList) {
      message.error(getSharkText("config_page_file_or_source_cannot_be_empty"));
      return;
    }
    if (fileList.length > 5) {
      message.error(getSharkText("config_page_no_more_than_5_files_at_a_time"));
      return;
    }
    setLoading(true);
    linkInvoke();
  };

  const handleCancel = () => {
    setVisible(false);
    setCancelled(true);
  };
  const handleSource = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSource(e.target.value);
  };
  const uploadProp = {
    action: "test",
    beforeUpload: (file: any) => {
      const reader = new FileReader();

      if (file) {
        if (file.size > 1024 * 1024 * 5) {
          // 最大支持的文件大小，单位：字节
          message.destroy();
          message.error(getSharkText("config_page_file_too_large"));
          // this.props.onChange({target:{value:oldFileUrl}});
          return false;
        }
      }

      reader.onloadstart = () => {};

      reader.onload = () => {
        const result = reader.result + "";
        file.base64 = result.split(",")[1];
      };

      reader.onerror = () => {};

      reader.readAsDataURL(file);
      const list: any[] = [...fileList];
      if (list.indexOf(file) < 0) {
        list.push(file);
      }
      setFileList(list);
      return false;
    },
    onRemove: (file: any) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
  };
  return (
    <Modal
      title={getSharkText("config_page_upload_file")}
      /** onOk={handleOk}
          okText='确定'
          onCancel={handleCancel}
          confirmLoading={isLoading}      **/
      closable={false}
      open={visible}
      footer={[
        <span key={0} style={{ float: "left", color: "#1890ff" }}>
          {getSharkText("config_page_no_more_than_5_files_each_time")}
        </span>,
        <Button key="back" onClick={handleCancel}>
          {getSharkText("key.cancel.button")}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleOk}
          disabled={fileList.length === 0}
        >
          {getSharkText("config_page_confirm")}
        </Button>,
      ]}
    >
      <div>
        <Descriptions bordered className="modal-descriptions">
          <Descriptions.Item
            label={getSharkText("config_page_category")}
            span={3}
          >
            {fileType.typeName}
          </Descriptions.Item>
          <Descriptions.Item
            label={getSharkText("config_page_source")}
            span={3}
          >
            <Input
              value={source}
              placeholder={getSharkText("config_page_enter_file_source")}
              onChange={handleSource}
            />
          </Descriptions.Item>
        </Descriptions>
        <div>
          <Dragger {...uploadProp} listType={"text"} fileList={fileList}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              {getSharkText("config_page_click_or_drag_file_to_upload")}
            </p>
            <p className="ant-upload-hint">
              {getSharkText("config_page_supported_extensions")}
            </p>
          </Dragger>
        </div>
      </div>
    </Modal>
  );
};

export default UploadModal;
