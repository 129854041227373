import React from "react";
import { Button, message, Modal, Typography } from "antd";
import useGlobal from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../../fetchCode";
import _ from "lodash";
import { getServer } from "Service/server";

interface DetailProps {
  id: string;
}

interface Data {
  rule: string;
  index: number;
  compareIndex: number;
  hintCount: number;
}

const { Text } = Typography;

const MonitorDetail: React.FC<DetailProps> = (props: DetailProps) => {
  const { id } = props;
  const [globalState] = useGlobal();
  const { airlinesQueryCondition, systemType } = globalState;
  const query = airlinesQueryCondition;
  const url = QUERY_URL[2].detail;
  const moduleCode = MODULE_CODE[2];
  const chartTableCode = CHART_TABLE_CODE[2].detail;

  const getDetailContent = (item: Data, key: number) => {
    const { rule, index, compareIndex } = item;
    const value = _.round((1 - index / compareIndex) * 100, 1);
    switch (rule) {
      case "0":
        return (
          <p key={key}>
            {getSharkText("config_page_compared_to_the_same")}
            <Text type="danger">
              {getSharkText(
                "config_page_ticket_airlines_and_advance_booking_days"
              )}
            </Text>
            {getSharkText("config_page_range_of_reference_lowest_price")}
            {compareIndex}，{getSharkText("config_page_current_price_drop")}：
            <Text type="danger">{value}%</Text>
          </p>
        );
      case "1":
        return (
          <p key={key}>
            {getSharkText("config_page_compared_to_the_same")}
            <Text type="danger">
              {getSharkText("config_page_ticket_airline")}
            </Text>
            的参考最低价
            {compareIndex}，当前价格
            <Text type="danger">
              {getSharkText("config_page_drop_rate")}：{value}%
            </Text>
          </p>
        );
      case "2":
        return (
          <p key={key}>
            {getSharkText(
              "config_page_compared_to_the_lowest_reference_price_of_all_same_routes"
            )}
            <Text type="danger">
              {getSharkText(
                "config_page_non_low_cost_airlines_and_same_advance_booking_days"
              )}
            </Text>
            范围的参考最低价{compareIndex}，当前价格
            <Text type="danger">
              {getSharkText("config_page_drop_rate")}：{value}%
            </Text>
          </p>
        );
      case "3":
        return (
          <p key={key}>
            {getSharkText("config_page_compared_to_last_month")}
            <Text type="danger">{compareIndex}</Text>，
            {getSharkText("config_page_drop_rate")}
            <Text type="danger">：{value}%</Text>
          </p>
        );
      case "4":
        return (
          <p key={key}>
            {getSharkText("config_page_compared_to_last_month_non_low_cost")}
            <Text type="danger">{compareIndex}</Text>，
            {getSharkText("config_page_drop_rate")}
            <Text type="danger">：{value}%</Text>
          </p>
        );
    }
  };

  const modalInfo = (data: Data[]) => {
    const content = (
      <div>
        {data.map((item: Data, idx: number) =>
          item.hintCount ? getDetailContent(item, idx) : undefined
        )}
      </div>
    );

    Modal.info({
      title: getSharkText("config_page_price_monitoring_details"),
      content,
      okText: getSharkText("config_page_confirm"),
      width: 520,
    });
  };
  const [{ isLoading }, doFetch] = useFetch({
    server: getServer(systemType),
    url,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      id,
    },
    lazey: true,
    onError: () => {
      message.error(getSharkText("config_page_get_details_failed"));
    },
    onSuccess: (res) => {
      const { data } = res;
      modalInfo(data);
    },
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        id,
      },
    });
  };

  const handleClick = () => {
    refetch();
  };

  return (
    <Button
      style={{ padding: 0 }}
      loading={isLoading}
      type="link"
      onClick={handleClick}
    >
      {getSharkText("config_page_details")}
    </Button>
  );
};

export default MonitorDetail;
