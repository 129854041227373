import { Role } from "Interface";
import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useGlobalState from "Store";
import { getSharkText } from "Utils/i18nGlobal";

// Functional link component which delays page navigation
export const DelayLink = (props: any) => {
  const { delay = 100, onDelayStart, onDelayEnd, replace, to, ...rest } = props;
  const [globalState, actions] = useGlobalState();
  const { userInfo } = globalState;
  const { roleList } = userInfo;
  /**
   * 因为春节应急发布, 功能需求很急, 所以不得已在全局link中硬编码处理国航可以选中多个航司, 导致页面跳转时无权限的问题, 后续再想一个完整的方案
   */
  const { setAirlinesQueryConditionAttr } = actions;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;
  let timeout: any = null;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [timeout]);

  const handleClick = (e: any) => {
    // if trying to navigate to current page stop everything
    if (location?.pathname === to) return;

    if (onDelayStart) {
      onDelayStart(e, to);
    }
    if (e.defaultPrevented) {
      return;
    }

    e.preventDefault();

    if (
      location.pathname === "/airline_analysis" &&
      airlinesRole &&
      airlinesRole.permissionCode === "CA"
    ) {
      setAirlinesQueryConditionAttr({
        airlines: "CA",
        airlinesName: getSharkText("config_page_china_airlines"),
      });
    }

    timeout = setTimeout(() => {
      if (replace) {
        console.log("history.replace to :", to);
        navigate(to);
      } else {
        console.log("history.push to :", to);
        navigate(to);
      }
      if (onDelayEnd) {
        onDelayEnd(e, to);
      }
    }, delay);
  };

  return <Link {...rest} to={to} onClick={handleClick} />;
};
