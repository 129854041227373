import { ISeries } from "Interface";
import { ICard } from "Page/AI/IndustryTrend/Interface";

export const genSeries = (
  card: ICard,
  compareName: string,
  isAirport: boolean
): ISeries[] => {
  const order: ISeries[] = [
    {
      type: "line",
      encode: { x: "day", y: "value" },
      name: `${card.cardLabel}`,
      showSymbol: false,
      symbol: "circle",
      yAxisIndex: 0,
      lineStyle: {
        width: 3,
      },
    },
  ];
  const priceEx: ISeries[] = [
    {
      type: "bar",
      encode: { x: "day", y: "yoy" },
      name: `${card.cardLabel}${compareName}`,
      showSymbol: false,
      symbol: "circle",
      yAxisIndex: 1,
      barMaxWidth: 20,
    },
  ];
  const searchEx: ISeries[] = [
    {
      type: "line",
      encode: { x: "day", y: "marketValue" },
      name: `市场${card.cardLabel}`,
      showSymbol: false,
      symbol: "circle",
      yAxisIndex: 0,
      lineStyle: {
        width: 3,
      },
    },
  ];

  const searchMarketEx: ISeries[] = [
    {
      type: "bar",
      encode: { x: "day", y: "marketYoy" },
      name: `市场${card.cardLabel}${compareName}`,
      showSymbol: false,
      symbol: "circle",
      yAxisIndex: 1,
      barMaxWidth: 20,
    },
  ];
  let rst: ISeries[] = [];
  switch (card.cardCode) {
    case "search_index":
      if (isAirport) {
        rst = searchEx.concat(searchMarketEx).concat(order).concat(priceEx);
      } else {
        rst = order.concat(priceEx);
      }
      break;
    case "order_index":
      rst = order.concat(searchEx);
      break;
    case "ow_price":
      rst = searchEx.concat(searchMarketEx).concat(order).concat(priceEx);
      break;
  }
  return rst;
};
