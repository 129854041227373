import { Article, ArticleType } from "@ctrip/flt-bi-flightai-base";
import React, { ReactElement, useCallback, useMemo } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Spin,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import moment, { isMoment } from "moment";
import { DATE_FORMAT, SERVER } from "Constants";
import { QUERY_URL } from "./fetchCode";
import { cloneDeep } from "lodash";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";

const { Option } = Select;

export interface IReportEditorProps {
  article?: Article;
  articleTypes?: ArticleType[];
  refreshArticles?: () => void;
}

/**
 * 研报编辑器
 */
const ReportEditor = (props: IReportEditorProps): ReactElement => {
  const { article, articleTypes, refreshArticles } = props;
  const [globalState] = useGlobalState();
  const { userInfo } = globalState;
  const { user } = userInfo;
  const editorArt = useMemo(() => {
    return {
      ...article,
      publishTime: moment(article?.publishTime),
    };
  }, [article]);

  const [{ isLoading }, fetchPost] = useFetch({
    server: SERVER,
    url: QUERY_URL.postArticle,

    head: {
      moduleCode: "common",
      chartTableCode: "null",
    },
    ext: {},
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_save_success"));
      if (refreshArticles) {
        refreshArticles();
      }
    },
    onError: () => {
      message.error(getSharkText("config_page_save_failed"));
    },
  });

  const onFinish = (values: Partial<Article> & { img: any; pdf: any }) => {
    console.log("Received values of form: ", values);
    const tmp = cloneDeep(values);
    if (!tmp.img?.length && !article) {
      message.error(getSharkText("config_page_pdf_cannot_be_empty"));
      return;
    }
    if (!tmp.pdf?.length && !article) {
      message.error(getSharkText("config_page_image_cannot_be_empty"));
      return;
    }
    fetchPost({
      ext: {
        article: {
          ...article,
          ...tmp,
          publishTime: isMoment(tmp.publishTime)
            ? tmp.publishTime.format(DATE_FORMAT)
            : "",
          imageBase64: editorArt.imageBase64,
          imageName: editorArt.imageName,
          imageUrl: editorArt.imageUrl,
          fileBase64: editorArt.fileBase64,
          fileName: editorArt.fileName,
          fileUrl: editorArt.fileUrl,
          topic: "",
          userId: user.id,
          userName: user.name,
        } as Article,
        type: article ? 2 : 1,
      },
    });
  };
  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };

  const getBase64 = useCallback((img: File, callback: (s: any) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }, []);

  const getImageData = useCallback(
    (img: File) => {
      return new Promise((resolve: (s: string) => void, reject) => {
        getBase64(img, (src) => {
          const image = new Image();
          image.src = src;
          if (src) {
            resolve(src);
          } else {
            reject(new Error("upload failed"));
          }
        });
      });
    },
    [getBase64]
  );

  const handleUpload = useCallback(
    async (file: File, fileType: "pdf" | "img") => {
      const src: string = await getImageData(file);
      console.log("src: ", src);
      if (fileType === "pdf") {
        editorArt.fileBase64 = src.replace(/.*base64,/, "");
        editorArt.fileName = file.name;
        editorArt.fileUrl = null;
      } else if (fileType === "img") {
        editorArt.imageBase64 = src.replace(/.*base64,/, "");
        editorArt.imageName = file.name;
        editorArt.imageUrl = null;
      } else {
        console.error("file type unknown");
      }
    },
    [getImageData, editorArt]
  );

  return (
    <Spin spinning={isLoading}>
      <Form
        name="validate_other"
        {...formItemLayout}
        onFinish={onFinish}
        initialValues={editorArt}
      >
        <Form.Item
          label={getSharkText("config_page_title")}
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="summary"
          label={getSharkText("config_page_summary")}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input.TextArea autoSize={{ minRows: 6, maxRows: 15 }} />
        </Form.Item>
        {articleTypes ? (
          <Form.Item
            name="typeId"
            label={getSharkText("config_page_category")}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              placeholder={getSharkText("config_page_select_category")}
              style={{ width: 200 }}
            >
              {articleTypes.map((t) =>
                t.typeId ? (
                  <Option value={t.typeId}>{t.typeName}</Option>
                ) : undefined
              )}
            </Select>
          </Form.Item>
        ) : undefined}
        <Form.Item
          label={getSharkText("config_page_publish_time")}
          name="publishTime"
        >
          <DatePicker />
        </Form.Item>

        {/* <Form.Item name="image" label="摘要图片">
        <Upload name="image" listType="picture">
          <Button icon={<UploadOutlined />}>Click to upload</Button>
        </Upload>
      </Form.Item> */}

        <Form.Item
          name="pdf"
          label={getSharkText("config_page_pdf_document")}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          extra={getSharkText("config_page_pdf_size_suggestion")}
        >
          <Upload
            name="pdf"
            listType="picture"
            beforeUpload={(f) => handleUpload(f, "pdf")}
            accept=".pdf"
            maxCount={1}
          >
            <Button icon={<UploadOutlined />}>
              {getSharkText("config_page_click_upload_pdf")}
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item label={getSharkText("config_page_image")}>
          <Form.Item
            name="img"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            extra={getSharkText("config_page_image_size_suggestion")}
            noStyle
          >
            <Upload
              name="img"
              listType="picture"
              beforeUpload={(f) => handleUpload(f, "img")}
              accept=".jpg,.png,.gif"
              maxCount={1}
            >
              <Button icon={<UploadOutlined />}>
                {getSharkText("config_page_click_upload_image")}
              </Button>
            </Upload>
          </Form.Item>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
          <Button type="primary" htmlType="submit">
            {getSharkText("config_page_submit")}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  );
};
export default ReportEditor;
