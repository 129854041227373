// Created by xh_zhu on 2021-03-24

import React, { ReactElement, useEffect, useState } from "react";
import { IDownloadHeader } from "Interface";
import { openDownloadDialog, sheet2blob, useFetch, XLSX } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import useGlobal from "Store";
import _, { merge } from "lodash";
import { Col, Input, Row, Table } from "antd";
import Refetch from "Components/Refetch";
import DownloadBtn from "Components/DownloadBtn";
import { getServer } from "Service/server";
import { CAACDetailColumns, CAACSummaryColumns, weekColumns } from "./columns";
import { getDownloadColumnHeader } from "Utils/downloadXLSX";

/**
 * Component description
 * 各机场预计航班执行率
 */

interface IAirportTableProps {
  moduleCode: string;
  chartTableCode: string;
  ext: any;
  queryUrl: string;
  panelType: 0 | 1;
  columns?: IDownloadHeader[];
}

const AirportTable = (props: IAirportTableProps): ReactElement => {
  const {
    moduleCode,
    chartTableCode,
    ext: otherExt,
    queryUrl,
    panelType,
    columns: propsColumns,
  } = props;
  const [type] = useState<0 | 1 | 2>(0);
  const [searchText, setSearchText] = useState<string>("");
  const [showDetail] = useState<boolean>(false);
  const [globalState] = useGlobal();
  const { queryCondition, systemType } = globalState;
  const query = queryCondition;
  const detail = panelType === 1 ? { detail: showDetail ? 1 : 0 } : null;
  const ext = merge({}, { type }, otherExt, detail);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(systemType),
    url: queryUrl,
    defaultValue: [],
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext,
    lazey: true,
  });

  const refetch = () => {
    doFetch({
      query,
      ext: {
        ...ext,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [queryCondition, otherExt, type, showDetail]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  // 优先使用props传递过来的columns;
  // @ts-ignore
  const columns: IDownloadHeader[] =
    propsColumns ||
    (panelType === 0
      ? weekColumns(type)
      : showDetail
      ? CAACDetailColumns(type)
      : CAACSummaryColumns(type));

  const tableData = data.map((item: any, idx: number) => {
    item.key = idx + 1;
    item.fe_rank = idx + 1;
    return item;
  });

  const getFilterData = () => {
    const cloneData = _.cloneDeep(tableData);
    const filter = cloneData.filter((item: any) => {
      let isPass = false;
      for (const key of ["airport", "airportName"]) {
        if (item[key] && item[key].includes(searchText.toUpperCase())) {
          isPass = true;
          break;
        }
      }
      return isPass;
    });
    return filter;
  };

  const filterData = getFilterData();

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleDownload = () => {
    const sheetData: any = [];
    filterData.forEach((item: any, idx: number) => {
      const obj: any = {};
      columns.forEach((col: IDownloadHeader) => {
        const val = item[col.dataIndex];
        const title = getDownloadColumnHeader(col);
        obj[title] = col.downloadFormatter
          ? col.downloadFormatter(val, item, idx)
          : val;
      });
      sheetData.push(obj);
    });
    const sheet = XLSX.utils.json_to_sheet(sheetData);
    const blob = sheet2blob(sheet);
    openDownloadDialog(
      blob,
      getSharkText("menu_flight_plan_airlines") + ".xlsx"
    );
  };

  return (
    <>
      <Row align="middle" gutter={32} className="filght_plan_filter">
        <Col>
          <h3 style={{ color: "rgba(0, 0, 0, 0.85)", fontWeight: "bold" }}>
            {getSharkText("key.estimated_execution_rate_airport.name")}
          </h3>
        </Col>
        <Col style={{ width: 350 }}>
          <Input.Search
            value={searchText}
            placeholder={getSharkText("key.input_to_search_relevant")}
            onChange={handleSearch}
          />
        </Col>
        <Col style={{ flex: 1, textAlign: "right" }}>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Table
          showSorterTooltip={false}
          size="small"
          loading={isLoading}
          tableLayout="fixed"
          dataSource={filterData}
          // @ts-ignore
          columns={columns}
          className="filght_plan_table"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["10", "25", "50", "100"],
          }}
        />
      </Row>
    </>
  );
};
export default AirportTable;
