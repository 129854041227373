import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Card, Col, Empty, Radio, Row } from "antd";
import { useLocation } from "react-router-dom";
import { RadioChangeEvent } from "antd/lib/radio";
import BarCharts from "./charts";
import { AggCode, IDownloadHeader, ISeries } from "Interface";
import useGlobal from "Store";
import {
  fillCharts,
  getBetweenDateArr,
  getModule,
  getModuleNameFromPath,
  getProportionPercentageVal,
  useFetch,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import Refetch from "Components/Refetch";
import "./index.css";
import { getServer } from "Service/server";
import { EPanelType } from "../../WingonCommon";
import DownloadBtn from "Components/DownloadBtn";
import { downloadExcel } from "Utils/downloadXLSX";
import { WingonFilter } from "@ctrip/flt-bi-flightai-partners";

interface BarLineChartsProps {
  moduleCode: string;
  chartTableCode: string;
  queryUrl: string;
  height?: number;
  style?: React.CSSProperties;
  ext?: WingonFilter;
  totalSeries?: ISeries[];
  legendHelp?: Record<string, string | ReactNode>;
  panelType: EPanelType;
  downloadColumns?: IDownloadHeader[];
}

const BarLineCharts = (props: BarLineChartsProps): ReactElement => {
  const {
    moduleCode,
    chartTableCode,
    queryUrl,
    height = 452,
    style,
    ext: otherExt,
    totalSeries,
    legendHelp,
    panelType,
    downloadColumns,
  } = props;

  const location = useLocation();
  const [aggCode, setAggCode] = useState<AggCode>(0);
  const [barData, setBarData] = useState<any[]>([]);
  const [globalState] = useGlobal();
  const { systemType, userInfo, partnerCondition } = globalState;
  const startDate =
    otherExt?.axisType === 0
      ? otherExt.searchDateStart
      : partnerCondition.startDate;
  const endDate =
    otherExt?.axisType === 0
      ? otherExt.searchDateEnd
      : partnerCondition.endDate;
  const query = partnerCondition;
  const moduleName = getModuleNameFromPath(location.pathname, systemType);
  const module = getModule(moduleName, userInfo.moduleList);
  const IsDemo = !!(module && module.moduleStatus === 0);

  const [{ data, isLoading, error }, doFetch] = useFetch({
    server: getServer(4),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query,
    ext: {
      type: panelType,
      aggCode,
      ...otherExt,
    },
    lazey: true,
  });
  const refetch = useCallback(() => {
    doFetch({
      query,
      ext: {
        type: panelType,
        aggCode,
        filter: otherExt,
      },
    });
  }, [aggCode, doFetch, otherExt, panelType, query]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      const dataCP = cloneDeep(data);
      const sortDate = dataCP.sort((a: any, b: any) =>
        moment(a.day).isBefore(moment(b.day)) ? -1 : 1
      );
      const compareData = sortDate.map((item: any) => {
        item.day = moment(item.day).format("YYYY-MM-DD");
        item.value = item.value ? _.round(item.value, 0) : 0;
        item.compareValue = item.compareValue
          ? _.round(item.compareValue, 0)
          : 0;
        const resultValue = _.round(
          getProportionPercentageVal(item.compareValue, item.value),
          1
        );
        item.resultValue = _.isFinite(resultValue) ? resultValue : 0;
        return item;
      });
      const sd =
        otherExt?.axisType === 0
          ? otherExt?.searchDateStart || startDate
          : startDate;
      const ed =
        otherExt?.axisType === 0 ? otherExt?.searchDateEnd || endDate : endDate;
      const fillData = fillCharts(
        compareData,
        getBetweenDateArr(moment(sd), moment(ed), aggCode),
        (date: string) => {
          return {
            day: date,
            value: 0,
            totalValue: 0,
            compareValue: 0,
            resultValue: 0,
          };
        }
      );
      setBarData(IsDemo ? compareData : fillData);
    } else {
      setBarData([]);
    }
  }, [
    IsDemo,
    aggCode,
    data,
    endDate,
    otherExt?.axisType,
    otherExt?.searchDateEnd,
    otherExt?.searchDateStart,
    startDate,
  ]);

  const handleDownload = useCallback(() => {
    if (!downloadColumns) {
      console.error("download columns not defined!!!");
      return;
    }
    downloadExcel(downloadColumns, data, getSharkText("menu_wingon_overview"));
  }, [data, downloadColumns]);

  if (error) {
    return <Refetch refetch={refetch} />;
  }

  const handleChange = (e: RadioChangeEvent) => {
    setAggCode(e.target.value);
  };

  return (
    <Card style={style} className="barline-charts">
      <Row justify="space-between">
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={handleChange}
            id="chartsAggCode"
          >
            <Radio.Button value={0}>
              {getSharkText("key.day.button")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("key.week.button")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("key.month.button")}
            </Radio.Button>
            <Radio.Button value={3}>
              {getSharkText("key.year.button")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <DownloadBtn
            handleDownload={handleDownload}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      {data && data.length === 0 ? (
        <Empty style={{ marginTop: 40, height: height - 40 }} />
      ) : (
        <BarCharts
          aggCode={aggCode}
          loading={isLoading}
          data={barData}
          startDate={startDate as string}
          endDate={endDate as string}
          height={height}
          isDemo={IsDemo}
          totalSeries={totalSeries}
          legendHelp={legendHelp}
          ext={otherExt}
        />
      )}
    </Card>
  );
};

export default BarLineCharts;
