import { Col, DatePicker, Row, Spin } from "antd";
import AirlinesGlobal from "Components/AirlinesGlobal";
import { DATE_FORMAT, EMPTY_ARRAY } from "Constants";
import moment, { Moment } from "moment";
import {
  MODULE_CODE as ReviewModule,
  QUERY_URL as ReviewUrl,
} from "Page/AI/FlightReview/fetchCode";
import { SelectMultFlightProps } from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import { SelectMultiRouteProps } from "Page/AI/FlightManage/Components/SearchArea/SelectMultRoute";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { BarLineDataEx, IFlightReviewFilter } from "../ReviewCommon";
import { getServer } from "Service/server";
import { minBy } from "lodash";
import { BarLineData } from "@ctrip/flt-bi-flightai-airlines";
import { Role } from "Interface";
import { getDefaultAirline } from "Utils/global";
import SelectRouteAndFlight from "Page/AI/FlightManage/Components/SearchArea/SelectRouteAndFlight";

export interface ISearchAreaProps {
  setQueryExt: (v?: IFlightReviewFilter) => void;
  isDemo?: boolean;
}

/**
 *  Component description
 */
const SearchArea = (props: ISearchAreaProps): ReactElement => {
  const { setQueryExt, isDemo = false } = props;
  // const [qf] = useQueryStringArr("flightNo", []);
  const [routes, setRoutes] = useState<string[]>(EMPTY_ARRAY);
  const [flightNO, setFlightNO] = useState<string[]>(EMPTY_ARRAY);
  const [departDate, setDepartDate] = useState<Moment>();
  const [globalState, actions] = useGlobalState();
  const { systemType, userInfo, airlinesQueryCondition } = globalState;
  const { setAirlinesQueryConditionAttr } = actions;
  const roleList = userInfo.roleList;
  const airlinesRole = roleList
    ? roleList.find((item: Role) => item.roleType === 2)
    : undefined;
  const year = moment().year();
  const [pickerRange, setPickerRange] = useState<Moment[]>([
    moment(`${year}-01-01`),
    moment(`${year}-12-31`),
  ]);

  const [{ data, isLoading }, fetchDate] = useFetch<BarLineData[]>({
    server: getServer(systemType),
    url: ReviewUrl[systemType].checkDate,
    head: {
      moduleCode: ReviewModule[systemType],
      chartTableCode: null,
    },
    query: airlinesQueryCondition,
    ext: {},
    lazey: true,
    onSuccess: (res: any) => {
      if (res?.data?.length) {
        const dates: BarLineData[] = res.data;
        if (
          !departDate ||
          (departDate &&
            !dates.find(
              (d) =>
                moment(d.day).format(DATE_FORMAT) ===
                departDate.format(DATE_FORMAT)
            ))
        ) {
          const dEx: BarLineDataEx[] = dates.map((d) => {
            return {
              ...d,
              diff: Math.abs(moment(d.day).diff(moment(), "days")),
            };
          });
          const item = minBy(dEx, (t) => t.diff);
          setDepartDate(moment(item?.day));
        }
      } else {
        setDepartDate(undefined);
      }
    },
  });

  /**
   * 获取可用日期区间
   */
  useEffect(() => {
    fetchDate({
      query: {
        ...airlinesQueryCondition,
        startDate: pickerRange[0].format(DATE_FORMAT),
        endDate: pickerRange[1].format(DATE_FORMAT),
      },
      ext: {
        filter: {
          flightNO: flightNO.join(","),
          route: routes.join(","),
        },
      },
    });
  }, [fetchDate, flightNO, pickerRange, routes]);

  useEffect(() => {
    if (!airlinesQueryCondition.airlines) {
      const tmp = getDefaultAirline(airlinesRole);
      setAirlinesQueryConditionAttr(tmp);
    }
  }, []);

  /**
   * 监听参数变化并缓存参数
   */
  useEffect(() => {
    if (departDate && !isLoading) {
      setQueryExt({
        filter: {
          flightNO: flightNO.join(","),
          route: "",
        },
      });
    } else {
      setQueryExt(undefined);
    }
  }, [departDate, flightNO, isLoading, setQueryExt]);

  useEffect(() => {
    if (departDate) {
      setAirlinesQueryConditionAttr({
        startDate: departDate.format(DATE_FORMAT),
        endDate: departDate.format(DATE_FORMAT),
      });
    } else {
      setAirlinesQueryConditionAttr({
        startDate: "",
        endDate: "",
      });
    }
  }, [departDate, setAirlinesQueryConditionAttr]);

  const disableFn = useCallback(
    (cur: Moment) => {
      if (!data || !data.length) {
        return false;
      }
      const matched = data.find(
        (d) => moment(d.day).format(DATE_FORMAT) === cur.format(DATE_FORMAT)
      );
      return !matched;
    },
    [data]
  );

  const onPanelChange = useCallback(
    (value: Moment, mode: any) => {
      if (value.isAfter(pickerRange[0]) && value.isBefore(pickerRange[1])) {
        return;
      } else {
        const y = value.year();
        setPickerRange([moment(`${y}-01-01`), moment(`${y}-12-31`)]);
      }
      console.log("on panel change:", value, mode);
    },
    [pickerRange]
  );

  const dateExt = useMemo(() => {
    return {
      query: {
        ...airlinesQueryCondition,
        startDate: "2018-01-01",
        endDate: moment().add(-1, "d").format(DATE_FORMAT),
      },
    };
  }, [airlinesQueryCondition]);

  const flightProps = useMemo(() => {
    const tmp: SelectMultFlightProps = {
      queryUrl: ReviewUrl[systemType].routeFlight,
      moduleCode: ReviewModule[systemType],
      flightNO,
      setFlightNO,
      ext: dateExt,
      routes: routes.join(","),
      isDemo,
      width: 200,
      autoSelectFirst: true,
      debugId: "fupan_top_flight",
    };
    return tmp;
  }, [dateExt, flightNO, isDemo, routes, systemType]);

  const routeProps = useMemo(() => {
    const tmp: SelectMultiRouteProps = {
      queryUrl: ReviewUrl[systemType].routeFlight,
      moduleCode: ReviewModule[systemType],
      routes,
      setRoutes,
      isDemo,
      width: "auto",
      ext: dateExt,
      flights: flightNO.join(),
      useExchange: true,
      autoSelectFirst: true,
      debugId: "fupan_top_route",
    };
    return tmp;
  }, [dateExt, flightNO, isDemo, routes, systemType]);

  const [routeComponent, flightComponent] = SelectRouteAndFlight({
    routeProps,
    flightProps,
    changeRouteByFlight: true,
    changeFlightByRoute: true,
  });

  return (
    <Row align="middle" gutter={[10, 20]} style={{ marginTop: 0 }}>
      <Col>
        <span className="search-area-filter-title">航司：</span>
        {/* <Airlines airlines={userAirlines} mode={undefined} /> */}
        <AirlinesGlobal mode={undefined} />
      </Col>
      <Col>{flightComponent}</Col>
      <Col>{routeComponent}</Col>
      <Spin spinning={isLoading}>
        <Col>
          <span>{getSharkText("charts.date_of_departure")}：</span>
          <DatePicker
            value={departDate}
            onChange={(e) => e && setDepartDate(e)}
            disabledDate={disableFn}
            onPanelChange={onPanelChange}
          />
        </Col>
      </Spin>
    </Row>
  );
};
export default SearchArea;
