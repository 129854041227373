import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Select } from "antd";
import { BaseOptionType } from "rc-select/lib/Select";
import useRefFunc from "../../../../Utils/useRefFunc";

export interface IPanelFilterProps {
  onChange: (v: any) => void;
}

const options: BaseOptionType[] = [
  { value: "MONOPOLY" },
  { value: "realLF1" },
  { value: "realLF2" },
];

const PanelFilterCommon = forwardRef(
  (props: IPanelFilterProps, ref: ForwardedRef<any>): ReactElement => {
    const { onChange } = props;
    const [value, setValue] = useState([options[1].value, options[2].value]);
    const timeout = useRef(0);
    const handleParentChange = useRefFunc((v) => onChange(v));
    useEffect(() => {
      if (timeout.current !== 0) {
        clearTimeout(timeout.current);
      }
      // @ts-ignore
      timeout.current = setTimeout(() => {
        handleParentChange({ mode: value });
        timeout.current = 0;
      }, 500);
    }, [handleParentChange, value]);
    useImperativeHandle(ref, () => ({ mode: value }));
    return (
      <>
        <Select
          style={{ minWidth: 100 }}
          mode={"multiple"}
          size={"small"}
          onChange={setValue}
          options={options}
          value={value}
        />
      </>
    );
  }
);

export default PanelFilterCommon;
