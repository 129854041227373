import { MonitoringAlertingCombiRuleTagQuery } from "@ctrip/flt-bi-flightai-airlines";
import { getSharkText } from "Utils/i18nGlobal";
import { enumKeys } from "Utils/global";

export enum EPanelType {
  // 运力
  Capacity,
  // 搜索
  Search,
  // 客座率
  Load,
  // 航班取消
  Change,
  // 价格
  Price,
  // 上客速度
  Speed,
  // 自主组合
  Custom = 100,
}

export enum EPanelName {
  运力,
  搜索,
  客座率,
  航班取消,
  价格,
  上客速度,
}

/**
 * 提前天数
 */
export const EPanelDays: Record<keyof typeof EPanelType, number> = {
  // 运力
  Capacity: 45,
  // 搜索
  Search: 45,
  // 客座率
  Load: 15,
  // 航班取消
  Change: 45,
  // 价格
  Price: 15,
  Speed: 15,
  Custom: -1,
};

const genHelpInfoStr = (key: EPanelType): string => {
  return `目前${EPanelName[key]}预警只支持境内航线，数据范围已切换。`;
};

// 根据EPanelType和EPanelName生成
const genHelpInfo = (): Record<keyof typeof EPanelType, string> => {
  const rst: Partial<Record<keyof typeof EPanelType, string>> = {};
  enumKeys(EPanelType).forEach((key) => {
    rst[key] = genHelpInfoStr(EPanelType[key]);
  });
  return rst as Record<keyof typeof EPanelType, string>;
};

export const HelpInfo: Record<keyof typeof EPanelType, string> = genHelpInfo();
export enum EStatus {
  未处理,
  已处理,
  // 已失效,
}

export interface ILoadDataType {
  //	id
  id: string;
  //	告警时间
  changedate: string;
  //	起飞日期
  takeofftime: string;
  //	航班
  flightno: string;
  //	出发城市
  dport: string;
  //	到达城市
  aport: string;
  //	上客进度
  lf: number;
  //	上客人数
  cnt: number;
  //	前四周平均上客进度
  last4weekLf: number;
  //	竞飞航班
  compareFlightno: string;
  //	竞飞航班上客进度
  compareLf: number;
  //	竞飞航班上客人数
  compareCnt: number;
  //	差异类型 0=竞飞对比 1=与前四周平均对比
  compareType: number;
  //	客座率差异
  diffLf: number;
  //	人数差异
  diffCnt: number;
  //	原因
  reason: string;
  //	状态
  status: number;
}

export interface ISpeedDataType {
  /**
   * 	id
   */
  id: string;
  /**
   * 	告警时间
   */
  changedate: string;
  /**
   * 	起飞日期
   */
  takeofftime: string;
  /**
   * 	航班
   */
  flightno: string;
  /**
   * 	出发城市
   */
  dport: string;
  /**
   * 	到达城市
   */
  aport: string;
  /**
   * 	单位时间上客人数
   */
  saledseat: number;
  /**
   * 	价格
   */
  price: number;
  /**
   * 	竞飞航班
   */
  compareFlightno: string;
  /**
   * 	竞飞航班上客进度
   */
  comparePrice: number;
  /**
   * 	客座率差异
   */
  diffPrice: number;
  /**
   * 	原因
   */
  reason: string;
  /**
   * 	命中规则
   */
  hintRule: string;
  /**
   * 	状态
   */
  status: number;
}

export const TAG_NAME_MAP: any = {
  lf: getSharkText("key.loadfactor"),
  sellcount: getSharkText("key.available_seats"),
  discount: getSharkText("config_page_discount_rate"),
  price: getSharkText("config_page_price"),
  seed: getSharkText("config_page_guest_speed"),

  search_abnormal: getSharkText("config_page_search_abnormal"),
  competitor_lf: getSharkText("config_page_competitor_lf"),
  competitor_sellcount: getSharkText("config_page_competitor_sellcount"),
  competitor_discount: getSharkText("config_page_competitor_discount"),
  competitor_price: getSharkText("config_page_competitor_price"),
  competitor_seed: getSharkText("config_page_competitor_seed"),
  competitor_lf_diff: getSharkText("config_page_competitor_lf_diff"),
  competitor_sellcount_diff: getSharkText(
    "config_page_competitor_sellcount_diff"
  ),
  competitor_discount_diff: getSharkText(
    "config_page_competitor_discount_diff"
  ),
  competitor_price_diff: getSharkText("config_page_competitor_price_diff"),
  competitor_seed_diff: getSharkText("config_page_competitor_seed_diff"),
  wow_lf: getSharkText("config_page_wow_lf"),
  wow_sellcount: getSharkText("config_page_wow_sellcount"),
  wow_discount: getSharkText("config_page_wow_discount"),
  wow_price: getSharkText("config_page_wow_price"),
  wow_seed: getSharkText("config_page_wow_seed"),
  wow_lf_diff: getSharkText("config_page_wow_lf_diff"),
  wow_sellcount_diff: getSharkText("config_page_wow_sellcount_diff"),
  wow_discount_diff: getSharkText("config_page_wow_discount_diff"),
  wow_price_diff: getSharkText("config_page_wow_price_diff"),

  wow_seed_diff: getSharkText(
    "config_page_compare_previous_four_weeks_customer_speed_change_value"
  ),
};

export enum ELogic {
  and = 1,
  or,
}
export enum ETriggerRule {
  all = 0,
  yes,
  no,
}

export enum ECompareType {
  /**
   * 前四周, 环比四周
   */
  wow = 1,
}

export type Tag = MonitoringAlertingCombiRuleTagQuery & { isPercent: boolean };
export const defaultCurrent: Tag[] = [
  {
    useTag: 1,
    tagCode: "lf",
    opera: 4,
    value: 85,
    isPercent: true,
  },
  {
    useTag: 0,
    tagCode: "sellcount",
    opera: 5,
    value: 85,
    isPercent: false,
  },
  {
    useTag: 1,
    tagCode: "discount",
    opera: 5,
    value: 80,
    isPercent: true,
  },
  {
    useTag: 0,
    tagCode: "price",
    opera: 4,
    value: 85,
    isPercent: false,
  },
];

export const defaultCompare: Tag[] = [
  {
    useTag: 1,
    tagCode: "lf",
    opera: 4,
    value: 10,
    isPercent: true,
  },
  {
    useTag: 0,
    tagCode: "sellcount",
    opera: 5,
    value: 15,
    isPercent: false,
  },
  {
    useTag: 1,
    tagCode: "discount",
    opera: 5,
    value: 80,
    isPercent: true,
  },
  {
    useTag: 0,
    tagCode: "price",
    opera: 4,
    value: 85,
    isPercent: false,
  },
];

export enum EOwnerType {
  System = 1,
  User = 2,
}

export const OwnerType: Record<EOwnerType, string> = {
  [EOwnerType.System]: getSharkText("config_page_system_default_monitor"),
  [EOwnerType.User]: getSharkText("config_page_user_setting_monitor"),
};
