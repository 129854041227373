import { Area } from "Interface";
import { cloneDeep, isEmpty } from "lodash";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import useGlobalState from "Store";
import { useFetch } from "Utils";
import CitySelector from "./Selector";
import { canSelectAirports } from "Utils/global";

export interface ICitySelectorPortalProps {
  type?: "city" | "airport";
  onChange?: (v: Area) => void;
}

/**
 * 城市选择器与业务捆绑的部分
 */
const CityAirportSelectorPortal = (
  props: ICitySelectorPortalProps
): ReactElement => {
  const { onChange, type = "airport" } = props;
  const [area, setArea] = useState<Area[]>([]);
  const [globalState, actions] = useGlobalState();
  const { setQueryCondition } = actions;
  const { queryCondition, userInfo, moduleConfig } = globalState;
  const isCtripOrCanSelectAirports = canSelectAirports(userInfo, moduleConfig);

  const [{ data }, doFetch] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "common",
      chartTableCode: null,
    },
    ext: {
      type: 0,
    },
    lazey: true,
  });

  useEffect(() => {
    if (isCtripOrCanSelectAirports) {
      doFetch({
        ext: {
          type: 0,
        },
      });
    }
  }, [doFetch, isCtripOrCanSelectAirports]);

  useEffect(() => {
    if (!isEmpty(data)) {
      if (type === "airport") {
        setArea(data);
      } else if (type === "city") {
        const deleteAirports = (source: Area[]) => {
          source.forEach((l) => {
            if (l.areaType === 4 && l.children) {
              delete l.children;
            } else if (l.children) {
              deleteAirports(l.children);
            }
          });
        };
        const tmpArea = cloneDeep(data);
        deleteAirports(tmpArea);
        setArea(tmpArea);
      }
    }
  }, [data, type]);

  const handleChange = useCallback(
    (v: Area) => {
      if (onChange) {
        onChange(v);
        return;
      }
      if (v === null) {
        console.log("city selector null");
        return;
      }
      const { areaCode, areaName, areaType } = v;
      setQueryCondition({
        ...queryCondition,
        airportName: areaName,
        airport: areaCode,
        originalAirport: { areaCode, areaType, areaName },
        area: { areaCode, areaType, areaName },
      });
    },
    [onChange, queryCondition, setQueryCondition]
  );
  return <CitySelector area={area} onChange={handleChange} type={type} />;
};
export default CityAirportSelectorPortal;
