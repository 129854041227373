import useRefFunc from "Utils/useRefFunc";
import React, {
  Key,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AVG_PRICE_TAX_EXCL,
  BKD_DETAIL,
  CAP,
  CUM_REVENUE_TAX_EXCL,
  customCols,
  DATASET_ID,
  FESTIVAL_INFO,
  FLIGHTNO,
  LF,
  RASK_TAX_EXCL,
  ROUTE,
  TAKEOFFDATE,
  TKT_CNT,
  TPM,
  UDF,
  YIELD_TAX_EXCL,
} from "./cols";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import { StandardFilter } from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import FullSpin from "Components/FullSpin";
import {
  arrayUpsert,
  listMapGroup,
  openDownloadDialog,
  useFetch,
  workbook2blob,
  XLSX,
} from "Utils";
import {
  Dimension,
  Measure,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { RangeCompare, SystemType } from "Interface";
import { DATE_FORMAT } from "Constants";
import { getColumns } from "./columns";
import { EditableProTable } from "@ant-design/pro-table";
import { Button, message, Modal, Space, Spin } from "antd";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import moment from "moment";
import { diffDays } from "Components/Dates/CompareCom";
import { cloneDeep, isEmpty, max, min, set, uniq } from "lodash";
import { getServer } from "Service/server";

import "./PlanTable.scss";
import { useDebounce } from "Utils/useDebounce";
import CopyUserInput, { CopyUserInputHandler } from "./CopyUserInput";
import { downloadExcel } from "Utils/downloadXLSX";
import { RecordKeyType, RecordType, RecordTypeWithCompare } from "../interface";
import { arrayDataToRecord } from "../common";
import { WorkBook } from "xlsx";

const PAGE_SIZE = 15;

export const recordCanPreCompute = (record: RecordType): boolean => {
  return (
    record.cap !== record.SUM_cap ||
    record.tpm !== record.SUM_tpm ||
    !!record.openCap ||
    !!record.specialCabinPrice ||
    !!record.groupPrice ||
    !!record.passengerFlow ||
    !!record.subcabinBkdDetail ||
    !!record.firstOpenSubcabinBkdDetail ||
    !!record.specialCabinCnt ||
    !!record.groupCnt
  );
};

export const recordHasInputData = (record: InputData): boolean => {
  return (
    !!record.cap ||
    !!record.tpm ||
    !!record.openCap ||
    !!record.specialCabinPrice ||
    !!record.groupPrice ||
    !!record.passengerFlow ||
    !!record.subcabinBkdDetail ||
    !!record.firstOpenSubcabinBkdDetail ||
    !!record.specialCabinCnt ||
    !!record.groupCnt ||
    !!record.remark
  );
};

export interface RevenueMgrSalePlan {
  id: number | null;
  takeoffdate: string | null;
  flightno: string | null;
  dport: string | null;
  aport: string | null;
  version?: number | null;
  /**
   * 座位数
   */
  cap: number | null;
  /**
   * 航距
   */
  tpm: number | null;
  /**
   * 操作人
   */
  operator?: string | null;
  /**
   * extra config json
   */
  config?: string | null;
  /**
   * 客流性质
   */
  passengerFlow: string | null;
  /**
   * 目标舱位和数量
   */
  subcabinBkdDetail: string | null;
  /**
   * 初次开舱舱位和数量
   */
  firstOpenSubcabinBkdDetail: string | null;
  /**
   * 分配座位数
   */
  openCap: number | null;
  /**
   * 特殊舱数量
   */
  specialCabinCnt: string | null;
  /**
   * 特殊舱运价
   */
  specialCabinPrice: number | null;
  /**
   * 团队数量
   */
  groupCnt: string | null;
  /**
   * 团队价格
   */
  groupPrice: number | null;
  /**
   * 备注
   */
  remark: string | null;
  /**
   * 目标收入
   */
  targetRevenue?: number | null;
  /**
   * 目标人数
   */
  targetPersons?: number | null;
  /**
   * 目标客座率
   */
  targetLf?: number | null;
  /**
   * 目标票价
   */
  targetPrice?: number | null;
  /**
   * 目标座收
   */
  targetRevenueSeat?: number | null;
  /**
   * 目标客收
   */
  targetRevenuePerson?: number | null;
}

const mergeCurrentAndCompare = (
  curMap: Array<Record<string, any>>,
  comMap: Array<Record<string, any>>,
  diff: number
) => {
  const rst = cloneDeep(curMap);
  rst.forEach((cur) => {
    const compareDate = moment(cur.takeoffdate)
      .add(diff, "d")
      .format(DATE_FORMAT);
    const com = comMap.find(
      (c) => c[TAKEOFFDATE] === compareDate
      /** 因为需要和航班航线对比，所以要把下面两行注释掉 */
      // c[FLIGHTNO] === cur[FLIGHTNO] &&
      // c[ROUTE] === cur[ROUTE]
    );
    if (com) {
      Object.keys(com).forEach((k) => {
        cur[`${k}_compare`] = com[k];
      });
    }
  });
  return rst;
};

interface ResData {
  takeoffdate: string | null;
  flightno: string | null;
  route: string | null;
  festival_info: string | null;
  SUM_cap: number | null;
  SUM_tpm: number | null;
  SUM_cum_revenue_tax_excl: number | null;
  SUM_tkt_cnt: number | null;
  SUM_lf: number | null;
  AVG_avg_price_tax_excl: number | null;
  SUM_rask_tax_excl: number | null;
  SUM_yield_tax_excl: number | null;
  SUM_bkd_detail: number | null;
  p_exp_udf: string | null;
}

interface InputData {
  id: number | null;
  cap: number | null;
  tpm: number | null;
  operator?: string | null;
  config?: string | null;
  passengerFlow: string | null;
  subcabinBkdDetail: string | null;
  firstOpenSubcabinBkdDetail: string | null;
  openCap: number | null;
  specialCabinCnt: string | null;
  specialCabinPrice: number | null;
  groupCnt: string | null;
  groupPrice: number | null;
  remark: string | null;
  targetRevenue?: number | undefined | null;
  targetPersons?: number | undefined | null;
  targetLf?: number | undefined | null;
  targetPrice?: number | undefined | null;
  targetRevenueSeat?: number | undefined | null;
  targetRevenuePerson?: number | undefined | null;
}

const DEFAULT_INPUT: InputData = {
  id: null,
  cap: null,
  tpm: null,
  operator: null,
  config: null,
  passengerFlow: null,
  subcabinBkdDetail: null,
  firstOpenSubcabinBkdDetail: null,
  openCap: null,
  specialCabinCnt: null,
  specialCabinPrice: null,
  groupCnt: null,
  groupPrice: null,
  remark: null,
  targetRevenue: null,
  targetPersons: null,
  targetLf: null,
  targetPrice: null,
  targetRevenueSeat: null,
  targetRevenuePerson: null,
};

export interface PlanTableProps {
  rangeCompare: RangeCompare;
  flights: string[];
  routes: string[];
  uploadData: WorkBook | null;
  clearUpload: () => void;
}

/**  Component description */
const PlanTable = (props: PlanTableProps): ReactElement => {
  const { rangeCompare, flights, routes, uploadData, clearUpload } = props;
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const [resData, setResData] = useState<ResData[]>([]);
  const [dataWithUdfAndKey, setDataWithUdfKey] = useState<RecordKeyType[]>([]);
  const [editingData, setEditingData] = useState<any[]>([]);
  const [editableKeys, setEditableKeys] = useState<string[]>([]);
  const [selectedVersion, setSelectedVersion] = useState<
    Record<string, number>
  >({});
  const [currentPage, setCurrentPage] = useState(1);

  // #region 表格数据查询代码
  const services = useServices();
  const [initialized, setInitialized] = useState(false);

  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_ID)
      .then(
        (r) => {
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
            setInitialized(true);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  const [{}, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    isSingleInstance: false,
  });

  const columns = useMemo(() => {
    return datasetCols.concat(customCols);
  }, [datasetCols]);

  const dimensions: Dimension[] = useMemo(
    () => [
      {
        columnName: TAKEOFFDATE,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: FLIGHTNO,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: ROUTE,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: FESTIVAL_INFO,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: BKD_DETAIL,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ],
    []
  );

  const measures: Measure[] = useMemo(
    () => [
      {
        id: CAP,
        columnName: CAP,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: TPM,
        columnName: TPM,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: CUM_REVENUE_TAX_EXCL,
        columnName: CUM_REVENUE_TAX_EXCL,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: TKT_CNT,
        columnName: TKT_CNT,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: LF,
        columnName: LF,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: AVG_PRICE_TAX_EXCL,
        columnName: AVG_PRICE_TAX_EXCL,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: RASK_TAX_EXCL,
        columnName: RASK_TAX_EXCL,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: YIELD_TAX_EXCL,
        columnName: YIELD_TAX_EXCL,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
      {
        id: UDF,
        columnName: UDF,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: null,
          comparison: null,
        },
      },
    ],
    []
  );

  const baseFilter = useMemo(() => {
    if (!datasetCols.length) {
      return [];
    }
    const filters: StandardFilter[] = [];
    if (flights.length) {
      filters.push({
        in: {
          field: `dimension.${FLIGHTNO}`,
          values: flights,
        },
      });
    }
    if (routes.length) {
      filters.push({
        in: {
          field: `dimension.${ROUTE}`,
          values: routes,
        },
      });
    }
    return filters;
  }, [datasetCols.length, flights, routes]);

  const currentFilter = useMemo(() => {
    if (!baseFilter.length || !rangeCompare.current) {
      return [];
    }
    const filter: StandardFilter[] = [
      {
        range: {
          field: `dimension.${TAKEOFFDATE}`,
          strRange: {
            lower: rangeCompare.current[0]?.format(DATE_FORMAT) || "",
            upper: rangeCompare.current[1]?.format(DATE_FORMAT) || "",
          },
        },
      },
    ];
    if (rangeCompare.compare) {
      if (flights.length) {
        filter.push({
          in: {
            field: `dimension.${FLIGHTNO}`,
            values: flights[0] === "" ? [] : [flights[0]],
          },
        });
      }
      if (routes.length) {
        filter.push({
          in: {
            field: `dimension.${ROUTE}`,
            values: [routes[0]],
          },
        });
      }
    } else {
      filter.push(...baseFilter);
    }
    return filter;
  }, [baseFilter, rangeCompare, flights, routes]);
  const compareFilter = useMemo(() => {
    if (!baseFilter.length || !rangeCompare.compare) {
      return [];
    }
    const filter: StandardFilter[] = [
      {
        range: {
          field: `dimension.${TAKEOFFDATE}`,
          strRange: {
            lower: rangeCompare.compare[0]?.format(DATE_FORMAT) || "",
            upper: rangeCompare.compare[1]?.format(DATE_FORMAT) || "",
          },
        },
      },
      // ...baseFilter,
    ];
    if (flights.length) {
      filter.push({
        in: {
          field: `dimension.${FLIGHTNO}`,
          values: flights[1] === "" ? [] : [flights[1]],
        },
      });
    }
    if (routes.length) {
      filter.push({
        in: {
          field: `dimension.${ROUTE}`,
          values: [routes[1]],
        },
      });
    }
    return filter;
  }, [baseFilter, rangeCompare.compare, flights, routes]);
  const currentRequestBuild = useMemo(() => {
    if (!currentFilter.length) {
      return;
    }
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns,
      dimensions,
      measures,
      chartFilters: [],
      oriFilters: currentFilter,
      sorters: [TAKEOFFDATE, FLIGHTNO, ROUTE],
      containerFilters: [],
      limit: 5000,
    });
  }, [columns, currentFilter, dimensions, measures]);
  const { encrypted: currentEncrypted } = useMemo(() => {
    if (currentRequestBuild) {
      return currentRequestBuild.getRequestBody();
    } else {
      return { encrypted: null, requestBody: null };
    }
  }, [currentRequestBuild]);

  const compareRequestBuild = useMemo(() => {
    if (!compareFilter.length) {
      return;
    }
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns,
      dimensions,
      measures,
      chartFilters: [],
      oriFilters: compareFilter,
      sorters: [TAKEOFFDATE, FLIGHTNO, ROUTE],
      containerFilters: [],
      limit: 5000,
    });
  }, [columns, compareFilter, dimensions, measures]);

  const { encrypted: compareEncrypted } = useMemo(() => {
    if (compareRequestBuild) {
      return compareRequestBuild.getRequestBody();
    } else {
      return { encrypted: null, requestBody: null };
    }
  }, [compareRequestBuild]);

  const lastParam = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);

  const initPage = useRef(() => {
    setResData([]);
    setDataWithUdfKey([]);
    setEditingData([]);
    setEditableKeys([]);
    setSelectedVersion({});
    setCurrentPage(1);
  }).current;

  const refetch = useCallback(() => {
    const currentReq = currentEncrypted
      ? doFetch({
          ext: {
            datasetId: DATASET_ID,
            colIds: [],
            req: currentEncrypted,
          },
        })
      : null;
    const compareReq = compareEncrypted
      ? doFetch({
          ext: {
            datasetId: DATASET_ID,
            colIds: [],
            req: compareEncrypted,
          },
        })
      : null;
    const reqs = [currentReq, compareReq].filter((f) => !!f);
    if (!reqs.length) {
      return;
    }
    setIsLoading(true);
    initPage();
    Promise.all(reqs).then((values) => {
      try {
        const curRes = values[0];
        const comRes = values[1];
        if (!curRes) {
          // message.error("current response is empty");
          return;
        }
        const curData = JSON.parse(curRes.data);
        if (curData.status !== 0) {
          message.error(`current response status: ${curData.status}`);
          return;
        }
        console.log("curData: is ", curData);
        const curMap = DataRow2ListMap(curData.rows, curData.headers);
        let rst = curMap;
        let comMap: Array<Record<string, any>> = [];
        console.log("curMap: is ", curMap);
        console.log("comRes: is ", comRes);
        if (comRes) {
          const comData = JSON.parse(comRes?.data);
          console.log("comData: is ", comData);
          comMap = DataRow2ListMap(comData.rows, comData.headers);
          console.log("comMap: is ", comMap);
          const diff = diffDays(rangeCompare);
          console.log("diff: is ", diff);
          rst = mergeCurrentAndCompare(curMap, comMap, diff);
          console.log("rst: is ", rst);
        }
        // setIsMergeUpload(true);
        setResData(rst as unknown as ResData[]);
      } catch (e) {
        initPage();
      } finally {
        setIsLoading(false);
      }
    });
  }, [currentEncrypted, doFetch, compareEncrypted, initPage, rangeCompare]);

  useEffect(() => {
    const params = { cur: currentEncrypted, com: compareEncrypted };
    if (params && params !== lastParam.current) {
      refetch();
      lastParam.current = params;
    }
  }, [compareEncrypted, currentEncrypted, refetch]);
  // #endregion

  // 计算dataWithUdfAndKey, 计算editableKeys, 初始化editingData
  useEffect(() => {
    console.log("resData is data what: ", resData);
    const tmpWithUdfAndKey: RecordKeyType[] = resData.map((r) => {
      const key = `${r[TAKEOFFDATE]}_${r[FLIGHTNO]}_${r[ROUTE]}`;
      const udfData = r.p_exp_udf ? JSON.parse(r.p_exp_udf) : null;
      const ipt =
        selectedVersion[key] &&
        udfData.history.find((h: any) => h.version === selectedVersion[key])
          ? udfData.history.find((h: any) => h.version === selectedVersion[key])
          : udfData;
      if (ipt) {
        return {
          ...r,
          ...ipt,
          key: `${r[TAKEOFFDATE]}_${r[FLIGHTNO]}_${r[ROUTE]}`,
        };
      }
      return {
        ...r,
        ...DEFAULT_INPUT,
        key: `${r[TAKEOFFDATE]}_${r[FLIGHTNO]}_${r[ROUTE]}`,
      };
    });
    setDataWithUdfKey(tmpWithUdfAndKey);
    let editingList = tmpWithUdfAndKey.map((r) => {
      if (!r.cap) {
        r.cap = r.SUM_cap;
      }
      if (!r.tpm) {
        r.tpm = r.SUM_tpm;
      }
      return r;
    });
    console.log("editingList is data what: ", editingList);
    if (uploadData && editingList.length) {
      const parseFailedRows: number[] = [];
      const groupFailedRows: number[] = [];
      const json = XLSX.utils.sheet_to_json(
        uploadData.Sheets[uploadData.SheetNames[0]],
        {
          header: 1,
        }
      );
      const uploadRecords: Array<Partial<RecordTypeWithCompare> | null> = json
        .slice(1)
        .map((j: any[], idx: number) => {
          try {
            return arrayDataToRecord(j);
          } catch (e) {
            parseFailedRows.push(idx + 1);
            return null;
          }
        })
        .filter((f: any) => !!f);
      const groupedData = listMapGroup(editingList, uploadRecords, [
        TAKEOFFDATE,
        FLIGHTNO,
        ROUTE,
      ]);
      editingList = groupedData
        .map((g) => {
          if (!g.item2) {
            return g.item1;
          }
          if (!g.item1 && g.item2Idx != null) {
            groupFailedRows.push(g.item2Idx + 1);
            return null;
          }
          const inputData: InputData = {
            id: -1,
            cap: g.item2.cap,
            tpm: g.item2.tpm,
            passengerFlow: g.item2.passengerFlow,
            subcabinBkdDetail: g.item2.subcabinBkdDetail,
            firstOpenSubcabinBkdDetail: g.item2.firstOpenSubcabinBkdDetail,
            openCap: g.item2.openCap,
            specialCabinCnt: g.item2.specialCabinCnt,
            specialCabinPrice: g.item2.specialCabinPrice,
            groupCnt: g.item2.groupCnt,
            groupPrice: g.item2.groupPrice,
            remark: g.item2.remark,
          };
          const item: RecordKeyType = {
            ...cloneDeep(g.item1),
            ...inputData,
          };
          return {
            ...item,
          };
        })
        .filter((f) => !!f);

      console.log("uploadData: ", uploadData);
      console.log("parseFailedRows: ", parseFailedRows);
      console.log("groupFailedRows: ", groupFailedRows);
      if (parseFailedRows.length || groupFailedRows.length) {
        const header = json[0];
        Modal.error({
          title: "存在未能成功上传的行",
          content: (
            <Space direction="vertical">
              <div>共{uploadRecords.length + 1}行数据</div>
              <div>
                解析失败{parseFailedRows.length}行{" "}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    const data = json.filter((_: any, idx: number) =>
                      parseFailedRows.includes(idx)
                    );
                    const wb = XLSX.utils.book_new();
                    const sheet1 = XLSX.utils.aoa_to_sheet([header, ...data]);
                    XLSX.utils.book_append_sheet(wb, sheet1, "解析失败");
                    const workbookBlob = workbook2blob(wb);
                    openDownloadDialog(workbookBlob, `销售预案-上传失败.xlsx`);
                  }}
                >
                  下载
                </Button>
              </div>
              <div>
                匹配失败{groupFailedRows.length}行{" "}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    const data = json.filter((_: any, idx: number) =>
                      groupFailedRows.includes(idx)
                    );
                    const wb = XLSX.utils.book_new();
                    const sheet1 = XLSX.utils.aoa_to_sheet([header, ...data]);
                    XLSX.utils.book_append_sheet(wb, sheet1, "匹配失败");
                    const workbookBlob = workbook2blob(wb);
                    openDownloadDialog(workbookBlob, `销售预案-上传失败.xlsx`);
                  }}
                >
                  下载
                </Button>
              </div>
            </Space>
          ),
        });
      } else {
        message.success("上传成功");
      }
    }
    // .filter((r) => {
    //   return recordHasInputData(r);
    // });
    setEditingData(editingList);
  }, [resData, selectedVersion, uploadData]);

  // #region 复制行
  const startDate = useMemo(
    () => min(dataWithUdfAndKey.map((d) => d.takeoffdate)) as string,
    [dataWithUdfAndKey]
  );
  const endDate = useMemo(
    () => max(dataWithUdfAndKey.map((d) => d.takeoffdate)) as string,
    [dataWithUdfAndKey]
  );
  const allFlights = useMemo(
    () =>
      uniq(
        dataWithUdfAndKey.map((d) => d.flightno).filter((f) => !!f) as string[]
      ),
    [dataWithUdfAndKey]
  );
  const allRoutes = useMemo(
    () =>
      uniq(
        dataWithUdfAndKey.map((d) => d.route).filter((f) => !!f) as string[]
      ),
    [dataWithUdfAndKey]
  );

  const [copyEditorOpen, setCopyEditorOpen] = useState(false);
  const copyRef = useRef<CopyUserInputHandler>(null);
  const [copySource, setCopySource] = useState<any>(null);
  const onCopy = useRefFunc(() => {
    const value = copyRef.current?.getValue();
    if (value && copySource) {
      setCopyEditorOpen(false);
      const { startDate, endDate, routes, flights, schedule } = value;
      const needChangeRecords = dataWithUdfAndKey.filter((d) => {
        const weekDay = (moment(d[TAKEOFFDATE]).day() + 6) % 7;
        return (
          d.takeoffdate &&
          d.route &&
          d.flightno &&
          d.takeoffdate >= startDate &&
          d.takeoffdate <= endDate &&
          routes.includes(d.route) &&
          flights.includes(d.flightno) &&
          schedule.includes(weekDay)
        );
      });
      let rst = cloneDeep(editingData);
      needChangeRecords.forEach((r) => {
        const editingItem = cloneDeep(r);
        if (editingItem) {
          const {
            cap,
            tpm,
            config,
            passengerFlow,
            subcabinBkdDetail,
            firstOpenSubcabinBkdDetail,
            openCap,
            specialCabinCnt,
            specialCabinPrice,
            groupCnt,
            groupPrice,
            remark,
          } = copySource;
          set(editingItem, "cap", cap);
          set(editingItem, "tpm", tpm);
          set(editingItem, "config", config);
          set(editingItem, "passengerFlow", passengerFlow);
          set(editingItem, "subcabinBkdDetail", subcabinBkdDetail);
          set(
            editingItem,
            "firstOpenSubcabinBkdDetail",
            firstOpenSubcabinBkdDetail
          );
          set(editingItem, "openCap", openCap);
          set(editingItem, "specialCabinCnt", specialCabinCnt);
          set(editingItem, "specialCabinPrice", specialCabinPrice);
          set(editingItem, "groupCnt", groupCnt);
          set(editingItem, "groupPrice", groupPrice);
          set(editingItem, "remark", remark);
        }
        rst = arrayUpsert(rst, editingItem, (v) => v.key === r.key);
      });
      setEditingData(rst);
      console.log("value: ", value);
    }
  });

  const copyUserInput = useRefFunc((r) => {
    setCopyEditorOpen(true);
    setCopySource(r);
  });
  // #endregion

  const changeVersion = useRefFunc((r: RecordKeyType, v: number) => {
    setSelectedVersion({ ...selectedVersion, [r.key]: v });
  });

  const tableColumns = useMemo(() => {
    return getColumns(copyUserInput, changeVersion);
  }, [changeVersion, copyUserInput]);

  const setRowClass = useRefFunc((record: any) => {
    if (record[FESTIVAL_INFO]) {
      return "record-warn";
    }
    return "";
  });

  const [{}, doSave] = useFetch({
    server: getServer(SystemType.airlines),
    head: {},
    lazey: true,
    url: "saveRevenueMgrSalePlan",
    query: null,
    ext: {},
    onSuccess: (res, body) => {
      const error = res.errorMsg ? JSON.parse(res.errorMsg) : null;
      if (!isEmpty(error)) {
        // Modal.error({
        //   title: "包含错误数据, 已回填正确数据",
        //   width: 1000,
        //   content: Object.keys(error).map((k) => (
        //     <div key={k}>
        //       {k} : {error[k]}
        //     </div>
        //   )),
        // });
      } else {
        message.success("操作成功");
      }
      if (!body.save) {
        const withKey = res.data.map((d: any) => ({
          ...d,
          route: d.dport + d.aport,
          key: `${d.takeoffdate}_${d.flightno}_${d.dport}${d.aport}`,
        }));
        const newEditingData = editingData.map((r) => {
          const resRow = withKey.find((d: RecordKeyType) => d.key === r.key);
          return resRow ?? r;
        });
        setEditingData(newEditingData);
      } else {
        refetch();
        clearUpload();
      }
    },
    onError: () => {
      message.error("操作失败");
    },
    onFinish: () => {
      setIsLoading(false);
    },
  });

  const onSave = useRefFunc((isSave = true) => {
    console.time("save");
    const data = editingData.reduce((total, r: ResData & InputData) => {
      const rst: RevenueMgrSalePlan = {
        id: r.id || null,
        takeoffdate: r.takeoffdate,
        flightno: r.flightno,
        dport: r.route?.substring(0, 3) || null,
        aport: r.route?.substring(3, 6) || null,
        cap: Number(r.cap),
        tpm: Number(r.tpm),
        config:
          r.config && !/^[\\"null]+$/.test(r.config)
            ? JSON.stringify(r.config)
            : null,
        passengerFlow: r.passengerFlow ? r.passengerFlow : null,
        subcabinBkdDetail: r.subcabinBkdDetail,
        firstOpenSubcabinBkdDetail: r.firstOpenSubcabinBkdDetail,
        openCap: Number(r.openCap),
        specialCabinCnt: r.specialCabinCnt,
        specialCabinPrice: Number(r.specialCabinPrice),
        groupCnt: r.groupCnt,
        groupPrice: Number(r.groupPrice),
        remark: r.remark,
        targetLf: r.targetLf,
        targetPersons: r.targetPersons,
        targetPrice: r.targetPrice,
        targetRevenue: r.targetRevenue,
        targetRevenuePerson: r.targetRevenuePerson,
        targetRevenueSeat: r.targetRevenueSeat,
      };
      if (isSave && recordHasInputData(rst)) {
        total.push(rst);
      }
      if (!isSave && recordCanPreCompute({ ...r, ...rst })) {
        total.push(rst);
      }
      return total;
    }, [] as RevenueMgrSalePlan[]);
    if (!data.length) {
      message.error(
        "不能提交空数据, 请注意所有的输入框都需要填写, 没有内容填空"
      );
      return;
    }
    console.timeEnd("save");
    setIsLoading(true);
    doSave({
      ext: {
        data,
        save: !!isSave,
      },
    });
  });
  const onPreview = useRefFunc(() => {
    onSave(false);
  });

  const tableData = useMemo(() => {
    const currentPageData = editingData.slice(
      (currentPage - 1) * PAGE_SIZE,
      currentPage * PAGE_SIZE
    );
    const keys = currentPageData.map((r) => r.key);
    setEditableKeys(keys);
    return currentPageData;
  }, [currentPage, editingData]);

  const update = useDebounce((item) => {
    const idx = editingData.findIndex((e) => e.key === item.key);
    if (idx >= 0) {
      editingData.splice(idx, 1, item);
    }
    console.log("set editing data");
    setEditingData([...editingData]);
  }, 600);

  const onDownload = useRefFunc(() => {
    if (editingData.length) {
      downloadExcel(tableColumns, editingData, "销售预案");
    }
  });

  if (!initialized) {
    return <FullSpin />;
  }
  return (
    <Spin spinning={isLoading}>
      <div style={{ textAlign: "right" }}>
        <span>
          <Button size="small" type="primary" onClick={onPreview}>
            预计算
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={onSave}
            style={{ marginLeft: 10 }}
          >
            全部保存
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={onDownload}
            style={{ marginLeft: 10 }}
          >
            下载
          </Button>
        </span>
        {tableData.length ? (
          <EditableProTable
            className="sales-plan_table"
            rowKey="key"
            value={tableData}
            columns={tableColumns}
            bordered
            search={false}
            rowClassName={setRowClass}
            scroll={{ x: "max-content" }}
            recordCreatorProps={false}
            headerTitle
            controlled
            size="small"
            options={false}
            tableLayout="auto"
            pagination={{
              pageSize: PAGE_SIZE,
              total: editingData.length,
              current: currentPage,
              pageSizeOptions: ["15"],
              onChange: (page, pageSize) => {
                setCurrentPage(page);
              },
            }}
            editable={{
              type: "multiple",
              editableKeys,
              onSave: async (rowKey, data, row) => {},
              onChange: (keys: Key[], rows: any[]) => {
                console.log("keys: ", keys);
                console.log("rows: ", rows);
              },
              onValuesChange: (record, recordList) => {
                // changeRecord(record);
                update(record);
                console.log("record: ", record);
                console.log("recordList: ", recordList);
              },
            }}
          />
        ) : undefined}
        <Modal
          title="复制到其他航班"
          open={copyEditorOpen}
          onOk={onCopy}
          onCancel={() => setCopyEditorOpen(false)}
          destroyOnClose
        >
          <CopyUserInput
            ref={copyRef}
            startDate={startDate}
            endDate={endDate}
            allFlights={allFlights}
            allRoutes={allRoutes}
          />
        </Modal>
      </div>
    </Spin>
  );
};
export default PlanTable;
