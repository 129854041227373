import React, { useState } from "react";
import {
  SwapLeftOutlined,
  SwapOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { Button, Row, Col, Tooltip, Typography } from "antd";
import { AirlineItem, Area } from "Interface";
import useGlobal from "Store";
import { getModuleStatus, getModuleNameFromPath, getUserAirlines } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import CascaderArea from "Components/CascaderArea";
import City from "Components/SearchAreaOrAirport/city";
import SelectMultiAirlines from "Components/SelectMultiAirlines";
import { DEFAULT_AREA } from "Constants";
import { getServer } from "Service/server";
import { useLocation } from "react-router-dom";

const { Text } = Typography;

interface SearchAirlineProps {
  setArea: (area: Area) => void;
  setAirlines: (v: AirlineItem[]) => void;
  moduleCode?: string;
  chartTableCode?: string;
  type?: "city" | "airport";
}

const AirportSearch = (props: SearchAirlineProps) => {
  const { setArea, setAirlines, type = "airport" } = props;
  const location = useLocation();
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [selectedAirlines, setSelectedAirlines] = useState<AirlineItem[]>([]);
  const [globalState] = useGlobal();
  const { queryCondition, helpVisibility, userInfo, systemType } = globalState;
  const { departure, arrive, airportName, flightClass } = queryCondition;
  const { pathname } = location;
  const { roleList } = userInfo;
  const moduleName = getModuleNameFromPath(pathname, systemType);
  const isDemo = getModuleStatus(moduleName, userInfo.moduleList) === 0;
  const userAirlines = getUserAirlines(roleList);
  const ext = { selectedArea };

  const handleClick = () => {
    setArea(selectedArea || DEFAULT_AREA);
    setAirlines(selectedAirlines);
  };

  return (
    <Row
      className="search-area"
      align="middle"
      gutter={4}
      style={{ marginTop: 16 }}
    >
      {!isDemo ? (
        <>
          <Col className="area-departure" id="areaDeparture">
            <Tooltip
              getPopupContainer={() =>
                document.getElementById("main") || document.body
              }
              title={getSharkText("key.airport_search_note.hint")}
              placement="bottomLeft"
              visible={helpVisibility}
            >
              <Text>{type === "airport" ? airportName : <City />}</Text>
            </Tooltip>
          </Col>
          <Col style={{ padding: "0 5px" }}>
            {departure && arrive ? (
              <SwapOutlined />
            ) : departure ? (
              <SwapRightOutlined />
            ) : (
              <SwapLeftOutlined />
            )}
          </Col>
        </>
      ) : undefined}
      <Col className="area-arrive" id="areaArrive">
        <CascaderArea
          type={type}
          setSelectedArea={setSelectedArea}
          flightClass={flightClass}
          isDemo={isDemo}
          useDomesticArea
        />
      </Col>
      <Col style={{ marginLeft: 20 }}>
        <span style={{ display: "inline-block", marginRight: 10 }}>
          {getSharkText("key.airline.flightplan")}
        </span>
        {queryCondition.airport ? (
          <SelectMultiAirlines
            moduleCode="flight_plan_airport"
            queryUrl="queryOnsaleFlightsListAirline"
            airlines={userAirlines}
            onChange={setSelectedAirlines}
            server={getServer(systemType)}
            query={queryCondition}
            ext={ext}
          ></SelectMultiAirlines>
        ) : undefined}
      </Col>
      <Col>
        <Button
          id="areaSearchBtn"
          disabled={!!isDemo}
          type="primary"
          onClick={handleClick}
        >
          {getSharkText("key.query.button")}
        </Button>
      </Col>
    </Row>
  );
};

export default AirportSearch;
