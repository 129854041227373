import { Col, DatePicker, Row, Tooltip } from "antd";
import { DATE_FORMAT } from "Constants";
import moment, { Moment } from "moment";
import SelectMultiFlight from "Page/AI/FlightManage/Components/SearchArea/SelectMultFlight";
import { IFlightReviewFilter } from "Page/AI/FlightReview/ReviewCommon";
import React, { ReactElement, useEffect, useState } from "react";
import useGlobalState from "Store";
import OFFContent from "./OFFContent";
import { InfoCircleOutlined } from "@ant-design/icons";
import { getSharkText } from "Utils/i18nGlobal";

export interface IOffProps {
  queryExt?: IFlightReviewFilter;
}

/**
 * 订座折扣
 */
const OFF = (props: IOffProps): ReactElement => {
  const { queryExt } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const { startDate } = airlinesQueryCondition;
  const [flightNO, setFlightNO] = useState<string[]>([]);
  const [compareDate, setCompareDate] = useState<Moment>(
    moment(startDate).add(-7, "days")
  );

  useEffect(() => {
    setCompareDate(moment(startDate).add(-7, "days"));
  }, [startDate]);

  return (
    <div>
      <span style={{ fontSize: "160%", marginRight: 5 }}>
        {getSharkText("config_page_booking_discount_distribution")}
      </span>
      <Tooltip
        title={getSharkText("config_page_view_flight_sales_composition")}
      >
        <InfoCircleOutlined />
      </Tooltip>
      <Row gutter={[10, 10]} style={{ margin: "5px 0px" }}>
        <Col>{getSharkText("config_page_compare_flight_number")}：</Col>
        <Col>
          <SelectMultiFlight
            title=""
            flightNO={flightNO}
            setFlightNO={setFlightNO}
            routes={queryExt?.filter.route || ""}
            isDemo={false}
            placeholder={getSharkText(
              "config_page_PaAiFlCoMiOf_select_compare_flight"
            )}
            mode={undefined}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col>{getSharkText("config_page_compare_takeoff_date")}：</Col>
        <Col>
          <DatePicker
            value={compareDate}
            onChange={(v) => v && setCompareDate(v)}
            allowClear
            size="middle"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <OFFContent
            queryExt={queryExt}
            compareFlightNO={flightNO}
            compareDate={compareDate.format(DATE_FORMAT)}
          />
        </Col>
      </Row>
    </div>
  );
};
export default OFF;
