// Created by xh_zhu on 2021-04-19

import { Col, Row } from "antd";
import CascaderArea from "Components/CascaderArea";
import { Area, FlightClass } from "Interface";
import React, { ReactElement, useEffect, useState } from "react";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 * 航司版中转分析查询区域
 */

interface ISearchProps {
  onChangeVal: (v: any) => void;
  flightClass: FlightClass;
  isDemo: boolean;
}

const Search = (props: ISearchProps): ReactElement => {
  const { onChangeVal, flightClass, isDemo } = props;
  const [departArea, setDepart] = useState<Area | null>(null);
  const [arriveArea, setArrive] = useState<Area | null>(null);
  const [transferArea, setTrans] = useState<Area | null>(null);

  useEffect(() => {
    onChangeVal({
      departArea,
      arriveArea,
      transferArea,
    });
  }, [departArea, arriveArea, transferArea, onChangeVal]);

  return (
    <>
      <Col>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <span style={{ marginRight: 10 }}>
              {getSharkText("config_page_departure_place")}
            </span>
          </Col>
          <Col>
            <CascaderArea
              type="city"
              setSelectedArea={setDepart}
              flightClass={flightClass}
              isDemo={isDemo}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <span style={{ marginRight: 10 }}>
              {getSharkText("config_page_arrive_place")}
            </span>
          </Col>
          <Col>
            <CascaderArea
              type="city"
              setSelectedArea={setArrive}
              flightClass={flightClass}
              isDemo={isDemo}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <span style={{ marginRight: 10 }}>
              {getSharkText("config_page_transit_place")}
            </span>
          </Col>
          <Col>
            <CascaderArea
              type="city"
              setSelectedArea={setTrans}
              flightClass={flightClass}
              isDemo={isDemo}
            />
          </Col>
        </Row>
      </Col>
    </>
  );
};
export default Search;
