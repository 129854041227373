import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button, Input } from "antd";
import useGlobal from "Store";
import { getSharkText } from "Utils/i18nGlobal";

interface ToolbarProps {
  setKeyword: (keyword: string) => void;
  showUploadModal: (visable: boolean) => void;
}
const { Search } = Input;
const Toolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {
  const { setKeyword, showUploadModal } = props;
  const [globalState] = useGlobal();
  const { userInfo } = globalState;
  const { user } = userInfo;
  const isSuperAdmin = user.userType === 1 && user.memberType === 1;

  const handleUpload = () => {
    showUploadModal(true);
  };
  const handSearch = (value: string) => {
    setKeyword(value);
    console.log(value);
  };

  return (
    <Row align="middle" gutter={[10, 20]} style={{ marginTop: 0 }}>
      <Col lg={12} md={12} xs={24}>
        <Search
          placeholder={getSharkText(
            "config_page_PaAiFiCoToIn_enter_article_title"
          )}
          onSearch={handSearch}
          style={{ width: 200 }}
        />
      </Col>
      <Col lg={12} md={12} xs={24}>
        {isSuperAdmin ? (
          <Button
            id="areaSearchBtn"
            icon={<PlusOutlined />}
            type="primary"
            onClick={handleUpload}
            style={{ marginLeft: 351 }}
          >
            {getSharkText("config_page_PaAiFiCoToIn_upload")}
          </Button>
        ) : undefined}
      </Col>
    </Row>
  );
};

export default Toolbar;
