import { Checkbox, Col, DatePicker, message, Radio, Row } from "antd";
import { DATE_FORMAT } from "Constants";
import { AggCode, ISeries } from "Interface";
import moment, { Moment } from "moment";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import { enumKeys } from "Utils/global";
import useI18Next from "Utils/useI18Next";
import {
  ETravixCompareType,
  ETravixDimensionType,
  ETravixValueType,
} from "../TravixCommon";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../TravixFetchCode";
import BarLineCharts from "./BarLineCharts";

const { RangePicker } = DatePicker;
/**
 * Component description
 *
 */

interface IProps {
  marketType?: number;
  country?: string;
  panelType: "overview" | "salesData";
}

const Market = (props: IProps): ReactElement => {
  const { marketType = 0, panelType, country } = props;
  const defaultRange: [Moment, Moment] = useMemo(
    () => [moment("2021-01-01"), moment().add(-1, "month").endOf("month")],
    []
  );

  const [queryExt, setQueryExt] = useState<Record<string, unknown>>();
  const [range, setRange] = useState<[Moment, Moment]>(defaultRange);
  const [compareType, setCompareType] = useState<number>(2);
  const [aggCode, setAggCode] = useState<AggCode>(2);
  const [valueType, setValueType] = useState<number[]>([1, 2]);
  const [dimensionType, setDimensionType] = useState<number[]>([1, 2, 3]);
  const [totalSeries, setTotalSeries] = useState<ISeries[]>([]);
  const t = useI18Next();
  /**
   * Travix年粒度时, 选择跨年无法计算年同比, 因此需要限制年粒度不能跨年
   * @param agg 粒度
   * @param ranges 时间区间
   * @returns 如果粒度为年. 时间区间不跨年, 返回true, 跨年返回false
   */
  const dateRangeAndAggCheck = useCallback(
    (agg: AggCode, ranges: [Moment, Moment]) => {
      if (agg === AggCode.year && ranges[0].year() !== ranges[1].year()) {
        message.error(t("travix.yearDateError"), 8);
        return false;
      }
      return true;
    },
    [t]
  );

  useEffect(() => {
    if (!dateRangeAndAggCheck(aggCode, range)) {
      setRange(defaultRange);
    }
  }, [aggCode, dateRangeAndAggCheck, defaultRange, range]);

  useEffect(() => {
    const tmpSeries: ISeries[] = [];
    enumKeys(ETravixDimensionType).forEach(
      (dim: keyof typeof ETravixDimensionType) => {
        if (!dimensionType.includes(ETravixDimensionType[dim])) {
          return;
        }
        enumKeys(ETravixValueType).forEach(
          (value: keyof typeof ETravixValueType) => {
            if (!valueType.includes(ETravixValueType[value])) {
              return;
            }
            tmpSeries.push(
              {
                name: `${dim}${value}`,
                type: "line",
                yAxisIndex:
                  value === ETravixValueType[ETravixValueType.PaxShare] ? 2 : 0,
                isPercentVal:
                  value === ETravixValueType[ETravixValueType.PaxShare],
                encode: {
                  x: "day",
                  y: `${dim.toLocaleLowerCase()}${value}`,
                },
              },
              {
                name: `${dim}${value} ${ETravixCompareType[compareType]}`,
                type: "bar",
                yAxisIndex: 1,
                isPercentVal: true,
                encode: {
                  x: "day",
                  y: `${dim.toLocaleLowerCase()}${value}ComparePer`,
                },
              }
            );
          }
        );
      }
    );
    setTotalSeries(tmpSeries);
  }, [compareType, dimensionType, valueType]);

  useEffect(() => {
    setQueryExt({
      query: {
        startDate: range[0] ? range[0].format(DATE_FORMAT) : "",
        endDate: range[1] ? range[1].format(DATE_FORMAT) : "",
        compareType,
        partner: "travix",
        marketType,
      },
      aggCode,
      country,
      dimensionType: {
        trip: dimensionType.includes(2) ? 1 : 0,
        total: dimensionType.includes(3) ? 1 : 0,
      },
      valueType: {
        seg: valueType.includes(1) ? 1 : 0,
        gmv: valueType.includes(2) ? 1 : 0,
      },
    });
  }, [
    range,
    compareType,
    aggCode,
    valueType,
    dimensionType,
    marketType,
    country,
  ]);

  const changeDateRange = (
    rangeDate: [Moment | null, Moment | null] | null
  ) => {
    if (!rangeDate || !rangeDate[0] || !rangeDate[1]) {
      return;
    }
    const ranges = rangeDate as [Moment, Moment];
    if (!dateRangeAndAggCheck(aggCode, ranges)) {
      return;
    }
    setRange(ranges);
  };
  return (
    <div>
      <Row>
        <Col>
          <h2 style={{ color: "#1890FF" }}>Market share - EU summary</h2>
        </Col>
      </Row>
      <Row gutter={[20, 20]} style={{ marginTop: 10 }}>
        <Col>
          <RangePicker
            picker="month"
            defaultValue={defaultRange}
            onChange={changeDateRange}
          />
        </Col>
        <Col>
          <Radio.Group
            value={compareType}
            onChange={(e) => setCompareType(e.target.value)}
          >
            <Radio.Button value={2}>Yo19</Radio.Button>
            <Radio.Button value={3}>YoY</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Radio.Group
            value={aggCode}
            onChange={(e) => setAggCode(e.target.value)}
          >
            <Radio.Button value={2}>Month</Radio.Button>
            <Radio.Button value={3}>Year</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Checkbox.Group
            value={valueType}
            onChange={(e) => setValueType(e as number[])}
          >
            <Checkbox value={1}>Seg</Checkbox>
            <Checkbox value={3}>Pax Share</Checkbox>
          </Checkbox.Group>
        </Col>
        <Col>
          <Checkbox.Group
            value={dimensionType}
            onChange={(e) => setDimensionType(e as number[])}
          >
            <Checkbox value={1}>Travix</Checkbox>
            <Checkbox value={2}>Trip</Checkbox>
            <Checkbox value={3}>Total</Checkbox>
          </Checkbox.Group>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BarLineCharts
            server={getServer(4)}
            queryUrl={QUERY_URL.barline}
            chartTableCode={CHART_TABLE_CODE[panelType].Market}
            moduleCode={MODULE_CODE}
            defaultSeries={totalSeries}
            ext={queryExt}
            useGranularity={false}
            startDate={range && range[0] ? range[0].format(DATE_FORMAT) : ""}
            endDate={range && range[1] ? range[1].format(DATE_FORMAT) : ""}
            compareType={compareType}
            aggCode={aggCode}
            dec={2}
          />
        </Col>
      </Row>
    </div>
  );
};
export default Market;
