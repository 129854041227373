import { IDownloadHeader, IDownloadHeaderPro } from "Interface";
import { openDownloadDialog, workbook2blob, XLSX } from "./xlsx";
import ubtUtils from "./ubtUtils";

export const getDownloadColumnHeader = (
  col: IDownloadHeader | IDownloadHeaderPro
): string => {
  let title = "";
  if (col.downloadTitle) {
    title = col.downloadTitle;
  } else if (typeof col.title === "string") {
    title = col.title;
  } else {
    title = col.dataIndex || "";
  }
  return title;
};

export const getDownloadColumnValue = (
  col: IDownloadHeader | IDownloadHeaderPro,
  record: Record<string, any>,
  rowIndex: number
): any => {
  if (!col.dataIndex) {
    return null;
  }
  if (col.downloadFormatter) {
    return col.downloadFormatter(record[col.dataIndex], record, rowIndex);
  } else {
    if (col.render) {
      const renderRst = col.render(
        record[col.dataIndex],
        record,
        rowIndex,
        undefined,
        {} as any
      );
      if (typeof renderRst === "string" || typeof renderRst === "number") {
        return renderRst;
      }
    }
    return record[col.dataIndex];
  }
};

export const data2SheetData = (
  columns: IDownloadHeader[] | IDownloadHeaderPro[],
  data: any[]
): any[] => {
  const sheetData1: any = [];
  const sheetDataFn = (record: any, i: number) => {
    const obj1: any = {};
    columns.forEach((c: IDownloadHeader | IDownloadHeaderPro) => {
      const parentTitle = getDownloadColumnHeader(c);
      // @ts-ignore
      if (c.children) {
        //@ts-ignore
        c.children.forEach((child: IDownloadHeader | IDownloadHeaderPro) => {
          const title = `${getDownloadColumnHeader(child)}(${parentTitle})`;
          obj1[title] = getDownloadColumnValue(child, record, i);
        });
      } else {
        obj1[parentTitle] = getDownloadColumnValue(c, record, i);
      }
    });
    return obj1;
  };

  data.forEach((record: any, i: number) => {
    sheetData1.push(sheetDataFn(record, i));
  });
  return sheetData1;
};

export const downloadSheetData = (
  sheets: Array<{ name: string; data: any[] }>,
  fileName: string
): void => {
  const wb = XLSX.utils.book_new();
  sheets.forEach((sheet) => {
    const sheet1 = XLSX.utils.json_to_sheet(sheet.data);
    XLSX.utils.book_append_sheet(wb, sheet1, sheet.name);
  });
  const workbookBlob = workbook2blob(wb);
  openDownloadDialog(workbookBlob, `${fileName}.xlsx`);
};

export const downloadExcel = (
  columns: IDownloadHeader[] | IDownloadHeaderPro[],
  data: any[],
  downloadFileName?: string
): void => {
  ubtUtils.info("downloadExcel", {
    columns,
    records: data.length,
    downloadFileName,
  });
  const fileName = downloadFileName || "download";
  const sheetData1 = data2SheetData(columns, data);
  downloadSheetData([{ name: fileName, data: sheetData1 }], fileName);
};
