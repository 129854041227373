import {
  MonitoringAlertingConfig,
  MonitoringAlertingRule,
} from "@ctrip/flt-bi-flightai-airlines";
import { message, Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { IDownloadHeader, SystemType } from "Interface";
import { uniqBy } from "lodash";
import React, { ReactElement, useMemo } from "react";
import { getServer } from "Service/server";
import { U, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { transRoute } from "Utils/global";
import useRefFunc from "Utils/useRefFunc";
import { MODULE_CODE, QUERY_URL } from "../fetchCode";
import { MonitoringAlertingConfigEx } from "../NotificationCenter";

export interface ISettingListProps {
  /**
   * 用户所有的规则列表
   */
  configList: MonitoringAlertingConfig[];
  setCurrentConfig: (v: MonitoringAlertingConfigEx[]) => void;
  refetchRule: () => void;
}

/**
 * 配置列表
 */
const SettingList = (props: ISettingListProps): ReactElement => {
  const { configList, setCurrentConfig, refetchRule } = props;

  const [{ isLoading }, doDelete] = useFetch({
    server: getServer(SystemType.airlines),
    url: QUERY_URL.delRule,
    head: {
      moduleCode: MODULE_CODE,
      chartTableCode: "non",
    },
    lazey: true,
    onSuccess: () => {
      message.success(getSharkText("config_page_delete_success"));
      refetchRule();
    },
    onError: () => {
      message.error(getSharkText("config_page_operation_exception"));
    },
  });

  const handleDelete = useRefFunc((r: MonitoringAlertingConfig) => {
    const list = configList.filter(
      (f) =>
        f.flightno === r.flightno &&
        f.route === r.route &&
        f.compareFlightno === r.compareFlightno
    );
    if (list.length) {
      doDelete({
        ext: {
          configIds: list.map((l) => l.configId),
        },
      });
    }
  });
  const dataSource = useMemo(() => {
    // 根据航班航线竞飞去重configList
    const uniqList = uniqBy(
      configList,
      (config) => `${config.route}${config.flightno}${config.compareFlightno}`
    );
    const withKeyAndHasThreshold: MonitoringAlertingConfigEx[] = uniqList.map(
      (c) => {
        // 获取同航班航线竞飞的配置集合
        const sameList = configList.filter(
          (s) =>
            s.route === c.route &&
            s.flightno === c.flightno &&
            s.compareFlightno === c.compareFlightno
        );
        // 有任意一个Tab页的任意一个规则的任意一个阈值, 则判定为有阈值
        const hasThreshold = sameList.some((s) => {
          return s.ruleList?.some((r) => {
            return Object.keys(r).some((key) => {
              if (
                key.includes("threshold") &&
                r[key as keyof MonitoringAlertingRule]
              ) {
                return true;
              }
              return false;
            });
          });
        });

        return {
          ...c,
          feHasThreshold: hasThreshold,
          key: c.configId || U.getUUID(),
        } as MonitoringAlertingConfigEx;
      }
    );
    return withKeyAndHasThreshold;
  }, [configList]);

  const columns: Array<IDownloadHeader<MonitoringAlertingConfigEx>> = useMemo(
    () => [
      {
        title: getSharkText("key.route.name"),
        dataIndex: "route",
        width: 85,
        render: (v) => transRoute(v),
      },
      {
        title: getSharkText("key.self_operated_flights"),
        width: 75,
        dataIndex: "flightno",
      },
      {
        title: getSharkText("config_page_compete_flight"),
        width: 75,
        dataIndex: "compareFlightno",
      },
      {
        title: getSharkText("config_page_is_set_threshold"),
        width: 60,
        dataIndex: "feHasThreshold",
        render: (v) =>
          v ? getSharkText("config_page_yes") : getSharkText("config_page_no"),
      },
      {
        title: getSharkText("config_page_operation"),
        dataIndex: "actions",
        render: (v, r: MonitoringAlertingConfig) => {
          return (
            <>
              <span
                style={{ marginRight: 3, cursor: "pointer", color: "#1890FF" }}
                onClick={() => {
                  setCurrentConfig(
                    dataSource.filter(
                      (c) =>
                        c.flightno === r.flightno &&
                        c.route === r.route &&
                        c.compareFlightno === r.compareFlightno
                    )
                  );
                }}
              >
                {getSharkText("config_page_set")}
              </span>
              <span
                style={{ cursor: "pointer", color: "#1890FF" }}
                onClick={() => {
                  handleDelete(r);
                }}
              >
                {getSharkText("config_page_delete")}
              </span>
            </>
          );
        },
      },
    ],
    [dataSource, handleDelete, setCurrentConfig]
  );
  return (
    <div>
      <h2>{getSharkText("config_page_flight_competition_config_list")}</h2>
      <Spin spinning={isLoading}>
        <CustomTableV2
          tableProps={{ size: "small" }}
          searchKeys={["route", "flightno"]}
          columns={columns}
          dataSource={dataSource}
          searchPlaceholder={getSharkText("config_page_input_route_or_flight")}
          moduleCode={MODULE_CODE}
          chartTableCode={"non"}
        />
      </Spin>
    </div>
  );
};
export default SettingList;
