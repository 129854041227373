import React, { useImperativeHandle } from "react";
import { Spin } from "antd";
import {
  genSeriesByDimensions,
  getDateFromAggCodeEN,
  getTooltipDateFromAggCode,
  showNum,
  showRawNum,
} from "Utils";
import {
  AggCode,
  ECompareTypeV2,
  IDownloadHeader,
  ISeries,
  SystemType,
} from "Interface";
import { downloadExcel } from "Utils/downloadXLSX";
import EchartsReactBase from "Components/EchartsReactBase";

interface BarData {
  day: string;
  value: number;
  compareValue: number;
}

interface BarChartsProps {
  data: BarData[];
  height?: number;
  systemType: SystemType;
  compareType: ECompareTypeV2;
  loading: boolean;
  aggCode: AggCode;
  startDate: string;
  endDate: string;
  airlines: string;
  cardCode?: string;
  isDemo: boolean;
  defaultSeries: ISeries[];
  chartConfig?: Record<string, unknown>;
  dec?: number;
}

const BCharts = (props: BarChartsProps, ref: any) => {
  const {
    data,
    height = 300,
    loading,
    aggCode,
    startDate,
    endDate,
    isDemo,
    defaultSeries,
    chartConfig,
    dec = 1,
  } = props;

  const dimensions = data && data.length ? Object.keys(data[0]) : [];
  const option = {
    dataset: {
      source: data,
    },
    color: ["#01C5DB", "#FC9B4F", "#177DFE"],
    // color: ['#177DFE', '#01C5DB', '#FC9B4F'],
    legend: {
      itemGap: 20,
      itemWidth: 16,
      itemHeight: 8,
      top: 0,
    },
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        const day = getTooltipDateFromAggCode(
          aggCode,
          params,
          data.length,
          startDate,
          endDate,
          isDemo,
          true
        );
        let dom = "";
        params.forEach((item: any) => {
          const dot = `<span class='dot' style='background:${item.color}'></span>`;
          const seriesConfig = defaultSeries.find(
            (d) => d.name === item.seriesName
          );
          const isPercentVal = seriesConfig ? seriesConfig.isPercentVal : false;
          const itemDim = item.dimensionNames[item.encode.y[0]];
          const itemValue = item.value[itemDim];
          const value = isPercentVal
            ? showNum(itemValue, "percentage", dec)
            : showNum(itemValue, "num");
          const val = `${item.seriesName}: ${value}`;
          dom = dom + "<br />" + dot + val;
        });
        dom = day + dom;
        return dom;
      },
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      axisLabel: {
        padding: [8, 0, 0, 0],
        formatter: (value: string) => `${getDateFromAggCodeEN(aggCode, value)}`,
      },
    },
    // dataZoom: [
    //   {
    //     type: "inside",
    //   },
    //   {
    //     type: "slider",
    //     bottom: 0,
    //   },
    // ],
    yAxis: [
      {
        position: "left",
        type: "value",
        splitNumber: 3,
        splitLine: {
          lineStyle: {
            type: "dashed",
            color: "#ddd",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
      },
      {
        position: "right",
        type: "value",
        splitLine: false,
        splitNumber: 3,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: (value: number) =>
            `${
              defaultSeries.filter((d) => d.isPercentVal).length > 0
                ? value + "%"
                : value
            }`,
        },
        axisTick: {
          show: false,
        },
      },
      {
        show: false,
        position: "right",
        type: "value",
        axisLabel: {
          formatter: (value: number) =>
            `${
              defaultSeries.filter((d) => d.isPercentVal).length > 0
                ? value + "%"
                : value
            }`,
        },
      },
    ],
    series: genSeriesByDimensions(dimensions, defaultSeries),
    ...chartConfig,
  };

  useImperativeHandle(ref, () => ({
    download: () => {
      if (data) {
        const series = genSeriesByDimensions(dimensions, defaultSeries);
        const baseColumns: IDownloadHeader[] = [
          {
            title: "date",
            dataIndex: "day",
            downloadFormatter: (val: any) => getDateFromAggCodeEN(aggCode, val),
          },
        ];
        const downloadColumns: IDownloadHeader[] = baseColumns.concat(
          series.map((s) => {
            return {
              title: s.name,
              dataIndex: s.encode.y,
              downloadFormatter: (val: number) =>
                showRawNum(val, s.isPercentVal ? "percentage" : "num", dec),
            };
          })
        );
        downloadExcel(downloadColumns, data, "Travix");
      }
    },
  }));

  return (
    <Spin spinning={loading}>
      <EchartsReactBase
        className="bar-line-charts"
        option={option}
        theme="default"
        style={{ height }}
        notMerge
      />
    </Spin>
  );
};
const BarCharts = React.forwardRef(BCharts);
export default BarCharts;
