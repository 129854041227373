import React from "react";
import { getLunarDay } from "Components/Dates/CompareCom";
import { IDownloadHeaderPro } from "Interface";
import { formatLunarDate } from "Utils/lunar";
import moment from "moment";
import { showNum } from "Utils";
import { BKD_DETAIL, FESTIVAL_INFO } from "./cols";
import { Button, Select } from "antd";
import { RecordKeyType } from "../interface";
import { WEEK } from "Constants";
import { recordCanPreCompute } from "./PlanTable";

export const getColumns = (
  copyUserInput: (r: any) => void,
  changeVersion: (r: RecordKeyType, version: number) => void
): IDownloadHeaderPro[] => {
  const columns: IDownloadHeaderPro[] = [
    {
      title: "时间对照",
      children: [
        {
          title: "本期节假日",
          dataIndex: FESTIVAL_INFO,
          readonly: true,
          fixed: "left",
          width: 100,
          shouldCellUpdate: (record, prevRecord) => {
            return record[FESTIVAL_INFO] !== prevRecord[FESTIVAL_INFO];
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record[FESTIVAL_INFO] !== prevRecord[FESTIVAL_INFO],
          },
        },
        {
          title: "本期公历",
          dataIndex: "takeoffdate",
          fixed: "left",
          readonly: true,
          width: 120,
          renderFormItem: (schema, config) => {
            const record = config.record;
            const val = record.takeoffdate;
            const m = moment(val);
            const date = m.format("YYYY-MM-DD");
            const weekDay = WEEK[(m.day() + 6) % 7];
            return `${date} ${weekDay}`;
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.takeoffdate !== prevRecord.takeoffdate;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.takeoffdate !== prevRecord.takeoffdate,
          },
        },
        {
          title: "本期农历",
          key: "takeoffdate_lunar",
          dataIndex: "takeoffdate",
          fixed: "left",
          readonly: true,
          width: 90,
          renderFormItem: (schema, config) => {
            const record = config.record;
            const val = record.takeoffdate;
            const m = moment(val);
            if (!val || !m.isValid()) {
              return "-";
            }
            const lv = getLunarDay(val);
            return formatLunarDate(lv);
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.takeoffdate !== prevRecord.takeoffdate;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.takeoffdate !== prevRecord.takeoffdate,
          },
        },
        {
          title: "同期公历",
          key: "takeoffdate_compare",
          dataIndex: "takeoffdate_compare",
          readonly: true,
          fixed: "left",
          width: 120,
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.takeoffdate_compare !== prevRecord.takeoffdate_compare
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.takeoffdate_compare !== prevRecord.takeoffdate_compare,
          },
          renderFormItem: (schema, config) => {
            const record = config.record;
            const val = record.takeoffdate_compare;
            const m = moment(val);
            if (!val || !m.isValid()) {
              return "-";
            }
            const date = m.format("YYYY-MM-DD");
            const weekDay = WEEK[(m.day() + 6) % 7];
            return `${date} ${weekDay}`;
          },
        },
        {
          title: "同期农历",
          key: "takeoffdate_compare_lunar",
          dataIndex: "takeoffdate_compare",
          readonly: true,
          width: 90,
          fixed: "left",
          renderFormItem: (schema, config) => {
            const record = config.record;
            const val = record.takeoffdate_compare;
            if (val && moment(val).isValid()) {
              const lv = getLunarDay(val);
              return formatLunarDate(lv);
            }
            return "-";
          },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.takeoffdate_compare !== prevRecord.takeoffdate_compare
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.takeoffdate_compare !== prevRecord.takeoffdate_compare,
          },
        },
      ],
    },
    {
      title: "本期航班",
      children: [
        {
          title: "航班号",
          dataIndex: "flightno",
          readonly: true,
          shouldCellUpdate: (record, prevRecord) => {
            return record.flightno !== prevRecord.flightno;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.flightno !== prevRecord.flightno,
          },
        },
        {
          title: "航段",
          dataIndex: "route",
          readonly: true,
          shouldCellUpdate: (record, prevRecord) => {
            return record.route !== prevRecord.route;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.route !== prevRecord.route,
          },
        },
        {
          title: "座位数",
          dataIndex: "SUM_cap",
          readonly: true,
          valueType: "text",
          shouldCellUpdate: (record, prevRecord) => {
            return record.SUM_cap !== prevRecord.SUM_cap;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_cap !== prevRecord.SUM_cap,
          },
        },
        {
          title: "航距",
          dataIndex: "SUM_tpm",
          readonly: true,
          renderFormItem: (schema, config) => {
            const val = config.record.SUM_tpm;
            const valStr = showNum(val);
            return valStr;
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.SUM_tpm !== prevRecord.SUM_tpm;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_tpm !== prevRecord.SUM_tpm,
          },
        },
      ],
    },
    {
      title: "同期数据",
      children: [
        {
          title: "航班号",
          dataIndex: "flightno_compare",
          readonly: true,
          shouldCellUpdate: (record, prevRecord) => {
            return record.flightno_compare !== prevRecord.flightno_compare;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.flightno_compare !== prevRecord.flightno_compare,
          },
        },
        {
          title: "航段",
          dataIndex: "route_compare",
          readonly: true,
          shouldCellUpdate: (record, prevRecord) => {
            return record.route_compare !== prevRecord.route_compare;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.route_compare !== prevRecord.route_compare,
          },
        },
        {
          title: "不含税收入",
          dataIndex: "SUM_cum_revenue_tax_excl_compare",
          readonly: true,
          renderFormItem: (schema, config) => {
            return (
              <span>
                {showNum(config.record.SUM_cum_revenue_tax_excl_compare)}
              </span>
            );
          },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.SUM_cum_revenue_tax_excl_compare !==
              prevRecord.SUM_cum_revenue_tax_excl_compare
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_cum_revenue_tax_excl_compare !==
              prevRecord.SUM_cum_revenue_tax_excl_compare,
          },
        },
        {
          title: "座位数",
          dataIndex: "SUM_cap_compare",
          readonly: true,
          renderFormItem: (schema, config) => {
            return <span>{showNum(config.record.SUM_cap_compare)}</span>;
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.SUM_cap_compare !== prevRecord.SUM_cap_compare;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_cap_compare !== prevRecord.SUM_cap_compare,
          },
        },
        {
          title: "订座数",
          dataIndex: "SUM_tkt_cnt_compare",
          readonly: true,
          renderFormItem: (schema, config) => {
            return <span>{showNum(config.record.SUM_tkt_cnt_compare)}</span>;
          },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.SUM_tkt_cnt_compare !== prevRecord.SUM_tkt_cnt_compare
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_tkt_cnt_compare !== prevRecord.SUM_tkt_cnt_compare,
          },
        },
        {
          title: "客座率",
          dataIndex: "SUM_lf_compare",
          readonly: true,
          renderFormItem: (schema, config) => {
            return (
              <span>
                {showNum(config.record.SUM_lf_compare * 100, "percentage")}
              </span>
            );
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.SUM_lf_compare !== prevRecord.SUM_lf_compare;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_lf_compare !== prevRecord.SUM_lf_compare,
          },
        },
        {
          title: "均价",
          dataIndex: "AVG_avg_price_tax_excl_compare",
          readonly: true,
          renderFormItem: (schema, config) => {
            return (
              <span>
                {showNum(config.record.AVG_avg_price_tax_excl_compare)}
              </span>
            );
          },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.AVG_avg_price_tax_excl_compare !==
              prevRecord.AVG_avg_price_tax_excl_compare
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.AVG_avg_price_tax_excl_compare !==
              prevRecord.AVG_avg_price_tax_excl_compare,
          },
        },
        {
          title: "座公里收入",
          dataIndex: "SUM_rask_tax_excl_compare",
          readonly: true,
          renderFormItem: (schema, config) => {
            return (
              <span>
                {showNum(config.record.SUM_rask_tax_excl_compare, "num", 3)}
              </span>
            );
          },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.SUM_rask_tax_excl_compare !==
              prevRecord.SUM_rask_tax_excl_compare
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_rask_tax_excl_compare !==
              prevRecord.SUM_rask_tax_excl_compare,
          },
        },
        {
          title: "客公里收入",
          dataIndex: "SUM_yield_tax_excl_compare",
          readonly: true,
          renderFormItem: (schema, config) => {
            return (
              <span>
                {showNum(config.record.SUM_yield_tax_excl_compare, "num", 3)}
              </span>
            );
          },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.SUM_yield_tax_excl_compare !==
              prevRecord.SUM_yield_tax_excl_compare
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.SUM_yield_tax_excl_compare !==
              prevRecord.SUM_yield_tax_excl_compare,
          },
        },
        {
          title: "同期舱位",
          dataIndex: `${BKD_DETAIL}_compare`,
          readonly: true,
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record[`${BKD_DETAIL}_compare`] !==
              prevRecord[`${BKD_DETAIL}_compare`]
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record[`${BKD_DETAIL}_compare`] !==
              prevRecord[`${BKD_DETAIL}_compare`],
          },
        },
      ],
    },
    {
      title: "本期目标",
      children: [
        {
          title: "目标收入",
          dataIndex: "targetRevenue",
          readonly: true,
          shouldCellUpdate: (record, prevRecord) => {
            return record.targetRevenue !== prevRecord.targetRevenue;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.targetRevenue !== prevRecord.targetRevenue,
          },
        },
        {
          title: "目标人数",
          dataIndex: "targetPersons",
          valueType: "text",
          readonly: true,
          shouldCellUpdate: (record, prevRecord) => {
            return record.targetPersons !== prevRecord.targetPersons;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.targetPersons !== prevRecord.targetPersons,
          },
        },
        {
          title: "目标客座",
          dataIndex: "targetLf",
          readonly: true,
          valueType: "text",
          renderFormItem: (schema, config) => {
            return (
              <span>{showNum(config.record.targetLf * 100, "percentage")}</span>
            );
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.targetLf !== prevRecord.targetLf;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.targetLf !== prevRecord.targetLf,
          },
        },
        {
          title: "目标票价",
          dataIndex: "targetPrice",
          readonly: true,
          renderFormItem: (schema, config) => {
            return <span>{showNum(config.record.targetPrice)}</span>;
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.targetPrice !== prevRecord.targetPrice;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.targetPrice !== prevRecord.targetPrice,
          },
        },
        {
          title: "目标座收",
          dataIndex: "targetRevenueSeat",
          readonly: true,
          renderFormItem: (schema, config) => {
            const val = config.record.targetRevenueSeat;
            const valStr = showNum(val, "num", 3);
            return valStr;
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.targetRevenueSeat !== prevRecord.targetRevenueSeat;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.targetRevenueSeat !== prevRecord.targetRevenueSeat,
          },
        },
        {
          title: "目标客收",
          dataIndex: "targetRevenuePerson",
          readonly: true,
          renderFormItem: (schema, config) => {
            const val = config.record.targetRevenuePerson;
            const valStr = showNum(val, "num", 3);
            return valStr;
          },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.targetRevenuePerson !== prevRecord.targetRevenuePerson
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.targetRevenuePerson !== prevRecord.targetRevenuePerson,
          },
        },
      ],
    },
    {
      title: "本期预案",
      children: [
        {
          title: "客流性质",
          dataIndex: "passengerFlow",
          fieldProps: { style: { width: 75 }, size: "small" },
          valueEnum: {
            正向: {
              text: (
                <span>
                  <span
                    className="dot"
                    style={{ background: "#26af5f" }}
                  ></span>
                  正向
                </span>
              ),
              status: "正向",
            },
            偏正: {
              text: (
                <span>
                  <span
                    className="dot"
                    style={{ background: "#52c41a" }}
                  ></span>
                  偏正
                </span>
              ),
              status: "偏正",
            },
            平向: {
              text: (
                <span>
                  <span
                    className="dot"
                    style={{ background: "#faad14" }}
                  ></span>
                  平向
                </span>
              ),
              status: "平向",
            },
            偏单: {
              text: (
                <span>
                  <span
                    className="dot"
                    style={{ background: "#fa7615" }}
                  ></span>
                  偏单
                </span>
              ),
              status: "偏单",
            },
            单向: {
              text: (
                <span>
                  <span
                    className="dot"
                    style={{ background: "#e84c3d" }}
                  ></span>
                  单向
                </span>
              ),
              status: "单向",
            },
          },
          shouldCellUpdate: (record, prevRecord) => {
            return record.passengerFlow !== prevRecord.passengerFlow;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.passengerFlow !== prevRecord.passengerFlow,
          },
        },
        {
          title: "座位数",
          downloadTitle: "本期预案-座位数",
          dataIndex: "cap",
          fieldProps: { style: { width: 60 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.cap !== prevRecord.cap;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) => record.cap !== prevRecord.cap,
          },
        },
        {
          title: "航距",
          downloadTitle: "本期预案-航距",
          dataIndex: "tpm",
          fieldProps: { style: { width: 70 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.tpm !== prevRecord.tpm;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) => record.tpm !== prevRecord.tpm,
          },
        },
        {
          title: "目标舱位和数量",
          dataIndex: "subcabinBkdDetail",
          fieldProps: { style: { width: 150 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.subcabinBkdDetail !== prevRecord.subcabinBkdDetail;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.subcabinBkdDetail !== prevRecord.subcabinBkdDetail,
          },
        },
        {
          title: "初次开舱舱位和数量",
          dataIndex: "firstOpenSubcabinBkdDetail",
          fieldProps: { style: { width: 100 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return (
              record.firstOpenSubcabinBkdDetail !==
              prevRecord.firstOpenSubcabinBkdDetail
            );
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.firstOpenSubcabinBkdDetail !==
              prevRecord.firstOpenSubcabinBkdDetail,
          },
        },
        {
          title: "分配座位数",
          dataIndex: "openCap",
          fieldProps: { style: { width: 60 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.openCap !== prevRecord.openCap;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.openCap !== prevRecord.openCap,
          },
        },
        {
          title: "特殊舱数量",
          dataIndex: "specialCabinCnt",
          fieldProps: { style: { width: 60 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.specialCabinCnt !== prevRecord.specialCabinCnt;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.specialCabinCnt !== prevRecord.specialCabinCnt,
          },
        },
        {
          title: "特殊舱均价",
          dataIndex: "specialCabinPrice",
          fieldProps: { style: { width: 80 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.specialCabinPrice !== prevRecord.specialCabinPrice;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.specialCabinPrice !== prevRecord.specialCabinPrice,
          },
        },
        {
          title: "团队数量",
          dataIndex: "groupCnt",
          fieldProps: { style: { width: 60 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.groupCnt !== prevRecord.groupCnt;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.groupCnt !== prevRecord.groupCnt,
          },
        },
        {
          title: "团队均价",
          dataIndex: "groupPrice",
          fieldProps: { style: { width: 60 }, size: "small" },
          shouldCellUpdate: (record, prevRecord) => {
            return record.groupPrice !== prevRecord.groupPrice;
          },
          formItemProps: {
            shouldUpdate: (record, prevRecord) =>
              record.groupPrice !== prevRecord.groupPrice,
          },
        },
      ],
    },
    {
      title: "备注",
      dataIndex: "remark",
      fieldProps: { style: { width: 100 }, size: "small" },
      shouldCellUpdate: (record, prevRecord) => {
        return record.remark !== prevRecord.remark;
      },
      formItemProps: {
        shouldUpdate: (record, prevRecord) =>
          record.remark !== prevRecord.remark,
      },
    },
    {
      title: "历史版本",
      dataIndex: "versions",
      readonly: true,
      renderFormItem: (schema, config) => {
        const r = config.record;
        const ipt = r.p_exp_udf ? JSON.parse(r.p_exp_udf) : null;
        if (ipt?.history?.length) {
          return (
            <Select
              size="small"
              style={{ minWidth: 100 }}
              options={ipt.history.map((v: any) => ({
                label: `版本${v.version} ${v.operator}`,
                value: v.version,
              }))}
              onChange={(v) => changeVersion(r, v)}
            ></Select>
          );
        }
        return "-";
      },
      shouldCellUpdate: (record, prevRecord) => {
        return record.versions !== prevRecord.versions;
      },
      formItemProps: {
        shouldUpdate: (record, prevRecord) =>
          record.versions !== prevRecord.versions,
      },
    },
    {
      title: "操作",
      dataIndex: "actions",
      key: "option",
      readonly: true,
      fixed: "right",
      width: 60,
      renderFormItem: (schema, config, form, action) => {
        const record = config.record;
        const disabledCopy = !recordCanPreCompute(record);
        return [
          <Button
            key="copy"
            type="primary"
            size="small"
            disabled={disabledCopy}
            onClick={() => {
              copyUserInput(config.record);
            }}
          >
            复制
          </Button>,
        ];
      },
    },
  ];
  return columns;
};
