export const MODULE_CODE: any = {
  1: "",
  2: "price_monitor_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {},
  2: {
    subscribe: "AL0600",
    follow: "AL0601",
    table: "AL0602",
    detail: "AL0603",
  },
};

export const QUERY_URL: any = {
  1: {},
  2: {
    subscribe: "subscribeAirlinesPriceMonitor",
    follow: "queryAirlinesPriceMonitorFollow",
    table: "queryAirlinesPriceMonitorTable",
    detail: "queryAirlinesPriceMonitorDetail",
  },
};
