import React, { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import {
  Col,
  Input,
  Radio,
  Result,
  Row,
  Table,
  TablePaginationConfig,
} from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { IDownloadHeaderArrIdx, User, UserList } from "Interface";
import {
  getUserRoleTypeText,
  openDownloadDialog,
  sheet2blob,
  useFetch,
  XLSX,
} from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import moment from "moment";
import UserDelete from "../Delete";
import UserModal from "../UserModal";
import { FileInfo } from "@ctrip/flt-bi-flightai-base";
import ImageViewer from "Components/ImageViewer";
import DownloadBtn from "../../../../../Components/DownloadBtn";

interface UserTableProps {
  queryUrl: string;
  moduleCode: string;
  chartTableCode?: string;
}

const { Search } = Input;

const UserTable: React.FC<UserTableProps> = (props: UserTableProps) => {
  const { queryUrl, moduleCode, chartTableCode = "" } = props;

  const [searchText, setSearchText] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [curItem, setCurItem] = useState<UserList | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [userList, setUserList] = useState<UserList[]>([]);
  const [searchList, setSearchList] = useState<UserList[]>([]);
  const [searchGroupId, setSearchGroupId] = useState(2);
  const [searchUserType, setSearchUserType] = useState<number>(0);
  const [searchDemoType, setSearchDemoType] = useState<number>(0);
  const [{ isLoading, error, res }, doFetch] = useFetch({
    url: queryUrl,
    head: {
      moduleCode,
      chartTableCode,
    },
    ext: {
      filter: {
        startDate: null,
        endDate: null,
        name: null,
        mobile: null,
        mainUnitName: null,
        userStatus: null,
        groupId: null,
      },
    },
  });

  useEffect(() => {
    if (res && res.userList) {
      setUserList(res.userList);
    }
  }, [res]);

  useEffect(() => {
    const text = searchText.toLowerCase();
    const filterGroup = (id: number, item: User): boolean => {
      switch (id) {
        case 0:
          return item.groupId === 0;
        case 1:
          return item.groupId !== 1;
        default:
          return true;
      }
    };
    const filterUser = (type: number, item: User): boolean => {
      switch (type) {
        case 1:
          return item.memberType === 0;
        case 2:
          return item.memberType === 1;
        default:
          return true;
      }
    };

    const filterDemotype = (type: number, item: User): boolean => {
      switch (type) {
        case 1:
          return item.demoType !== 2;
        case 2:
          return item.demoType === 2;
        default:
          return true;
      }
    };

    const list = userList.filter(
      (item) =>
        (item.user.mail.toLowerCase().includes(text) ||
          item.user.mainUnitName.includes(text) ||
          item.user.mobile.includes(text) ||
          item.user.name.toLowerCase().includes(text) ||
          item.user.mainUnitCode.toLowerCase().includes(text)) &&
        filterGroup(searchGroupId, item.user) &&
        filterUser(searchUserType, item.user) &&
        filterDemotype(searchDemoType, item.user)
    );
    setSearchList(list);
  }, [userList, searchText, searchGroupId, searchUserType, searchDemoType]);

  if (error) {
    return <Result status="error" title="Error" subTitle={"sever error"} />;
  }

  const getMemberTypeText = (user: User) =>
    user.memberType
      ? user.userType === 1
        ? getSharkText("config_page_super_admin")
        : getSharkText("config_page_admin")
      : getSharkText("config_page_common_user");

  const handleEdit = (user: UserList) => {
    setIsShowModal(true);
    setCurItem(user);
  };

  const refetchList = () => {
    doFetch({
      ext: {
        filter: {
          startDate: null,
          endDate: null,
          name: null,
          mobile: null,
          mainUnitName: null,
          userStatus: null,
          groupId: null,
        },
      },
    });
  };

  const handleChangePagination = (pagination: TablePaginationConfig) => {
    if (pagination.current) {
      setCurrentPage(pagination.current);
    }
    if (pagination.pageSize) {
      setPageSize(pagination.pageSize);
    }
  };

  const columns: IDownloadHeaderArrIdx[] = [
    {
      title: getSharkText("config_page_applicant_mobile"),
      dataIndex: ["user", "mobile"],
      key: "mobile",
      width: 140,
      fixed: "left",
    },
    {
      title: getSharkText("config_page_applicant_name"),
      dataIndex: ["user", "name"],
      key: "name",
      width: 140,
      fixed: "left",
    },
    {
      title: getSharkText("config_page_role"),
      dataIndex: "demoType",
      key: "demoType",
      width: 100,
      fixed: "left",
      render: (value: string, record: UserList) => getUserRoleTypeText(record),
    },
    {
      title: getSharkText("config_page_email"),
      dataIndex: ["user", "mail"],
      key: "mail",
      width: 200,
    },
    {
      title: getSharkText("config_page_work_unit"),
      dataIndex: ["user", "mainUnitName"],
      key: "mainUnitName",
      width: 180,
      render: (value: string, record: UserList) =>
        `${record.user.mainUnitName}`,
    },
    {
      title: getSharkText("config_page_id_photo"),
      dataIndex: ["user", "photos"],
      key: "photos",
      render: (value: FileInfo[]) => {
        return value
          ? value.map((v, i) => (
              <ImageViewer
                key={v.id}
                file={v}
                nameSuffix={(i + 1).toString()}
              />
            ))
          : "--";
      },
      width: 200,
    },
    {
      title: getSharkText("config_page_permission_group"),
      dataIndex: ["user", "groupId"],
      key: "groupId",
      render: (value: number, record: UserList) => {
        if (value === 0) {
          return getSharkText("config_page_no_permission_group");
        } else {
          return record.user.mainUnitName;
        }
      },
    },
    {
      title: getSharkText("config_page_user_type"),
      dataIndex: ["user", "memberType"],
      key: "memberType",
      render: (value: number, record: UserList) =>
        getMemberTypeText(record.user),
    },
    {
      title: getSharkText("config_page_registration_time"),
      dataIndex: ["user", "registerDate"],
      key: "registerDate",
      render: (value: string) => moment(value).format("YYYY-MM-DD"),
      defaultSortOrder: "descend",
      sorter: (a: UserList, b: UserList) =>
        moment(a.user.registerDate).isBefore(moment(b.user.registerDate))
          ? -1
          : 1,
    },
    {
      title: getSharkText("config_page_login_time"),
      dataIndex: ["user", "lastLoginDate"],
      key: "lastLoginDate",
      render: (value: string, record: UserList) =>
        record.user.lastLoginDate === record.user.registerDate
          ? ""
          : moment(value).format("YYYY-MM-DD"),
      sorter: (a: UserList, b: UserList) =>
        moment(a.user.lastLoginDate).isBefore(moment(b.user.lastLoginDate))
          ? -1
          : 1,
    },
    {
      title: getSharkText("config_page_operation"),
      dataIndex: "action",
      key: "action",
      width: 160,
      fixed: "right",
      render: (value: string, record: UserList) => (
        <>
          <a style={{ marginRight: 20 }} onClick={() => handleEdit(record)}>
            <EditOutlined /> {getSharkText("config_page_edit")}
          </a>
          <UserDelete refetch={refetchList} userItem={record} />
        </>
      ),
    },
  ];

  const handleClick = () => {
    const sheetData: any = [];
    searchList.forEach((item) => {
      const obj: any = {
        name: item.user.name,
        uid: item.user.uid,
        [getSharkText("config_page_mobile")]: item.user.mobile,
        [getSharkText("config_page_role")]: getUserRoleTypeText(item),
        [getSharkText("config_page_wechat")]: item.user.wechat,
        mail: item.user.mail,
        [getSharkText("config_page_work_unit")]: item.user.mainUnitName,
        [getSharkText("config_page_permission_group")]:
          item.user.groupId === 0 ? "无权限组" : item.user.mainUnitName,
        [getSharkText("config_page_user_type")]: getMemberTypeText(item.user),
        [getSharkText("config_page_registration_time")]: moment(
          item.user.registerDate
        ).format("YYYY-MM-DD HH:mm:ss"),
        [getSharkText("config_page_last_login_time")]:
          item.user.lastLoginDate === item.user.registerDate
            ? "-"
            : moment(item.user.lastLoginDate).format("YYYY-MM-DD HH:mm:ss"),
      };
      sheetData.push(obj);
    });
    const sheet = XLSX.utils.json_to_sheet(sheetData);
    const blob = sheet2blob(sheet);
    openDownloadDialog(blob, "用户.xlsx");
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleChangeSearchGroup = (e: RadioChangeEvent) => {
    setSearchGroupId(e.target.value);
  };

  const handleChangeSearchUserType = (e: RadioChangeEvent) => {
    setSearchUserType(e.target.value);
  };

  const handleChangeSearchDemoType = (e: RadioChangeEvent) => {
    setSearchDemoType(e.target.value);
  };

  return (
    <>
      <Row gutter={16}>
        <Col>
          <Search
            placeholder={getSharkText("config_page_phone_name_work_unit")}
            onChange={handleSearch}
            style={{ width: 300 }}
          />
        </Col>
        <Col>
          <Radio.Group
            value={searchDemoType}
            onChange={handleChangeSearchDemoType}
          >
            <Radio.Button value={0}>
              {getSharkText("config_page_all_roles")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("area.airport.title")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("system_type_Airline")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Radio.Group value={searchGroupId} onChange={handleChangeSearchGroup}>
            <Radio.Button value={2}>
              {getSharkText("config_page_all_permission_groups")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("config_page_permission_groups")}
            </Radio.Button>
            <Radio.Button value={0}>
              {getSharkText("config_page_no_permission_group")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Radio.Group
            value={searchUserType}
            onChange={handleChangeSearchUserType}
          >
            <Radio.Button value={0}>
              {getSharkText("config_page_all_users")}
            </Radio.Button>
            <Radio.Button value={1}>
              {getSharkText("config_page_common_user")}
            </Radio.Button>
            <Radio.Button value={2}>
              {getSharkText("config_page_admin")}
            </Radio.Button>
          </Radio.Group>
        </Col>
        <Col style={{ flex: 1, textAlign: "right" }}>
          <DownloadBtn
            handleDownload={handleClick}
            showIcon={false}
            style={{ marginBottom: 16 }}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
      <Table
        showSorterTooltip={false}
        loading={isLoading}
        tableLayout="fixed"
        dataSource={searchList}
        rowKey={(record) => record.user.id.toString()}
        scroll={{ x: 1500 }}
        columns={columns}
        onChange={handleChangePagination}
        pagination={{
          pageSize,
          current: currentPage,
          showTotal: (total) => (
            <>
              {getSharkText("config_page_total")}&nbsp;{total}
            </>
          ),
        }}
      />
      {curItem ? (
        <UserModal
          visible={isShowModal}
          setVisible={setIsShowModal}
          data={curItem}
          refetch={refetchList}
        />
      ) : undefined}
    </>
  );
};

export default UserTable;
