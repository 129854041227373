import { IDownloadHeader } from "Interface";
import React, { ReactElement, useMemo } from "react";
import { SaleUpdateRecord } from "../../interface";
import { showNum } from "Utils";
import { Table } from "antd";
import { cloneDeep } from "lodash";

export interface DetailTableProps {
  sales: SaleUpdateRecord[];
}

/**  Component description */
const DetailTable = (props: DetailTableProps): ReactElement => {
  const { sales } = props;
  const showSales = useMemo(() => {
    const cp = cloneDeep(sales);
    cp.sort((a, b) => {
      return a.updateTime?.localeCompare(b.updateTime ?? "") ? -1 : 1 ?? 0;
    });
    return cp;
  }, [sales]);
  const columns = useMemo(() => {
    const tmp: Array<IDownloadHeader<SaleUpdateRecord>> = [
      {
        title: "变更时间",
        dataIndex: "updateTime",
      },
      {
        title: "总库存",
        dataIndex: "totalInventory",
      },
      {
        title: "销售变化",
        dataIndex: "inventoryNum",
      },
      {
        title: "变化明细",
        dataIndex: "changeDetail",
      },
      {
        title: "已售汇总",
        dataIndex: "summaryDetail",
        key: "percentage",
        render: (v, r: SaleUpdateRecord) => {
          const sold = r.soldInventory ?? 0;
          const per = r.totalInventory ? sold / r.totalInventory : 0;
          const perText = showNum(per * 100, "percentage");
          return `${sold} / ${perText}`;
        },
      },
      {
        title: "汇总明细",
        dataIndex: "summaryDetail",
      },
    ];
    return tmp;
  }, []);
  return (
    <div>
      <Table columns={columns} dataSource={showSales} size="small" />
    </div>
  );
};
export default DetailTable;
