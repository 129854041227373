import React, { ReactElement, useMemo, useState } from "react";
import { EDictType, EPanelType } from "../WingonCommon";
import AirlineTable from "../Components/AirlineTable";
import BarLineCharts from "./BarLineCharts";
import { Divider, Row, Col } from "antd";
import { QUERY_URL, MODULE_CODE, CHART_TABLE_CODE } from "../fetchCode";
import { genSeries } from "./BarLineCharts/series";
import Search from "./Search";
import { EMPTY_ARRAY } from "Constants";
import { getDownloadColumns } from "./columns";
import { WingonFilter } from "@ctrip/flt-bi-flightai-partners";
import { getSharkText } from "Utils/i18nGlobal";

export interface IWingonPanelProps {
  panelType: EPanelType;
  title: string;
  queryExt: WingonFilter;
}

/**
 * 永安直通车看板
 */
const WingonPanel = (props: IWingonPanelProps): ReactElement => {
  const { panelType, title, queryExt } = props;
  const [interfaceList, setInterface] = useState<string[]>(EMPTY_ARRAY);
  const ext: WingonFilter = useMemo(() => {
    return {
      ...queryExt,
      interfaceName: interfaceList,
    };
  }, [interfaceList, queryExt]);

  const totalSeries = useMemo(() => {
    return genSeries(panelType);
  }, [panelType]);

  const downloadColumns = useMemo(() => {
    return getDownloadColumns(panelType);
  }, [panelType]);

  return (
    <div
      className="content-white"
      style={{ margin: "0 20px 20px", padding: "20px 20px 5px" }}
    >
      <div style={{ color: "#1890ff", fontSize: "120%" }}>{title}</div>
      {panelType === EPanelType.Search ? (
        <>
          <Search
            title={getSharkText("config_page_interface")}
            filter={queryExt}
            onChange={setInterface}
            type={EDictType.Interface}
            width={200}
          />
        </>
      ) : undefined}
      <Divider style={{ margin: "15px 0" }} />
      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          <BarLineCharts
            queryUrl={QUERY_URL.barline}
            moduleCode={MODULE_CODE}
            chartTableCode={CHART_TABLE_CODE}
            ext={ext}
            totalSeries={totalSeries}
            panelType={panelType}
            downloadColumns={downloadColumns}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          <AirlineTable ext={ext} panelType={panelType} />
        </Col>
      </Row>
    </div>
  );
};
export default WingonPanel;
