export const MODULE_CODE: any = {
  1: "core_overview_airport",
  2: "core_overview_airlines",
};

export const CHART_TABLE_CODE: any = {
  1: {
    cards: "index_card",
    barLineCharts: "card_date_compare",
    pieCharts: "card_overview_compare",
  },
  2: {
    cards: "AL0101",
    barLineCharts: "AL0102",
    pieCharts: "AL0103",
  },
};

export const QUERY_URL: any = {
  1: {
    cards: "queryCoreOverviewCard",
    barLineCharts: "queryCoreOverviewBarLine",
    pieCharts: "queryCoreOverviewPie",
    top10City: "queryCoreOverviewTop10City",
  },
  2: {
    cards: "queryAirlinesCoreOverviewCard",
    barLineCharts: "queryAirlinesCoreOverviewBarLine",
    pieCharts: "queryAirlinesCoreOverviewPie",
    top10City: "queryAirlinesCoreOverviewTop10City",
    barLineDownload: "queryAirlinesCoreOverviewBarLineDownload",
  },
};
