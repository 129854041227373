import React, { useEffect, useMemo, useState } from "react";
import { Select, SelectProps } from "antd";
import { isSame, stringContains, useFetch, useSessionStorage } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { AREA_DOMESTIC } from "Constants";
import { Area, IValueLabelOption } from "Interface";
import _ from "lodash";
import { DefaultOptionType } from "antd/lib/select";

interface IProps extends SelectProps {
  value?: string[];
  onChange: (v: IValueLabelOption[]) => void;
}

const SelectAirports: React.FC<IProps> = (props: IProps) => {
  const { value, onChange, ...rest } = props;

  const [areaData, setAreaData] = useSessionStorage(AREA_DOMESTIC, []);
  const [innerValue, setInnerValue] = useState<string[]>(value || []);

  useEffect(() => {
    setInnerValue((state) => {
      if (isSame(value, state)) {
        return state;
      }
      return value || [];
    });
  }, [value]);

  const [{ data }, doFetch] = useFetch({
    url: "searchArea",
    defaultValue: [],
    head: {
      moduleCode: "",
      chartTableCode: "",
    },
    ext: {
      type: 1,
    },
    lazey: true,
  });

  const airports = useMemo(() => {
    const airportArr: DefaultOptionType[] = [];
    const findAllAirports = (areaArr: Area[]) => {
      for (const area of areaArr) {
        if (area.areaType === 5) {
          airportArr.push({ value: area.areaCode, label: area.areaName });
        } else if (area.children) {
          findAllAirports(area.children);
        }
      }
    };
    findAllAirports(areaData);
    return airportArr;
  }, [areaData]);

  useEffect(() => {
    if (_.isEmpty(areaData)) {
      doFetch({
        ext: {
          type: 1,
        },
      });
    }
  }, [areaData, doFetch]);

  useEffect(() => {
    if (data.length > 0) {
      setAreaData(data);
    }
  }, [data, setAreaData]);

  const handleChange = (arr: string[], option: any) => {
    onChange(option);
  };

  const filterOption = (input: string, option: any) => {
    return (
      stringContains(option.value, input) || stringContains(option.label, input)
    );
  };

  return (
    <Select
      showSearch
      style={{ width: 330 }}
      placeholder={getSharkText("config_page_select_airport_code")}
      filterOption={filterOption}
      onChange={handleChange}
      value={innerValue}
      mode="multiple"
      options={airports}
      {...rest}
    ></Select>
  );
};

export default SelectAirports;
