import React from "react";
import { Col, Row } from "antd";
import "./CustomTable.scss";
import ProTable, { ProTableProps } from "@ant-design/pro-table";
import { ListToolBarTabs } from "@ant-design/pro-table/es/components/ListToolBar";
import { FlightInfoDetailWithKey } from "../../interface";

interface CustomTableProps {
  columns: any;
  dataSource: FlightInfoDetailWithKey[];
  otherProps: ProTableProps<any, any>;
  toolbarTabsConfig?: ListToolBarTabs;
  onReload: () => void;
  onDoubleClick: (record: FlightInfoDetailWithKey) => void;
}

const CustomTable: React.FC<CustomTableProps> = (props: CustomTableProps) => {
  const {
    columns,
    dataSource,
    otherProps,
    toolbarTabsConfig,
    onReload,
    onDoubleClick,
  } = props;

  const onRow = (record: FlightInfoDetailWithKey) => ({
    onDoubleClick: () => {
      onDoubleClick(record);
    },
  });

  return (
    <>
      <Row style={{ marginTop: 0, textAlign: "left" }}>
        <Col>
          <ProTable<any>
            className="flight-manage"
            search={false}
            showSorterTooltip={false}
            tableLayout="auto"
            dataSource={dataSource}
            bordered
            columns={columns}
            scroll={{ x: "max-content" }}
            style={{ color: "#000000d9" }}
            onRow={onRow}
            rowKey="id"
            size="small"
            options={{
              reload: onReload,
              density: false,
            }}
            toolbar={{
              multipleLine: true,
              tabs: toolbarTabsConfig,
            }}
            sticky
            {...otherProps}
          />
        </Col>
      </Row>
    </>
  );
};

export default CustomTable;
