import { message, Spin } from "antd";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { EMPTY_ARRAY } from "Constants";
import { IDownloadHeader, SystemType } from "Interface";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getServer } from "Service/server";
import useGlobalState from "Store";
import { tableNumSorter, useFetch } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import { downloadExcel } from "Utils/downloadXLSX";
import { IHourData } from "../SalesAnalysisInterface";

export interface ITablePortalProps {
  ext?: any;
  queryUrl: string;
  moduleCode: string;
  chartTableCode: string;
}

/**
 * 价格分析表格
 */
const TablePortal = (props: ITablePortalProps): ReactElement => {
  const { ext, queryUrl, moduleCode, chartTableCode } = props;
  const [globalState] = useGlobalState();
  const { airlinesQueryCondition } = globalState;
  const [transData, setTransData] = useState<any[]>(EMPTY_ARRAY);
  const [downloading, setDownloading] = useState<boolean>(false);
  const queryExt = useMemo(() => {
    if (ext.startDate || ext.endDate) {
      return {
        ...airlinesQueryCondition,
        startDate: ext.startDate,
        endDate: ext.endDate,
      };
    } else {
      return airlinesQueryCondition;
    }
  }, [airlinesQueryCondition, ext.startDate, ext.endDate]);
  const columns: IDownloadHeader[] = useMemo(() => {
    const baseColumns: IDownloadHeader[] = [
      {
        title: getSharkText("charts.date_of_departure"),
        dataIndex: "takeoffdate",
        sorter: (a: any, b: any) => a.takeoffdate.localeCompare(b.takeoffdate),
        fixed: "left",
        width: 100,
      },
      {
        title: getSharkText("key.flightno.name"),
        dataIndex: "flightNo",
        sorter: (a: any, b: any) => a.flightNo.localeCompare(b.flightNo),
        fixed: "left",
      },
      {
        title: getSharkText("key.route.name"),
        dataIndex: "line",
        sorter: (a: any, b: any) => a.line.localeCompare(b.line),
        fixed: "left",
      },
    ];
    const d7d14: IDownloadHeader[] = Array.from({ length: 8 }, (v, i) => {
      const colId = `d${14 - i}`;
      return {
        title: `D${14 - i} 08`,
        dataIndex: colId,
        sorter: (a, b, s) => tableNumSorter(a[colId], b[colId], s),
      };
    });
    const d3d6: IDownloadHeader[] = [6, 5, 4, 3].reduce((total, cur) => {
      const curDayCols = Array.from({ length: 8 }, (v, i) => {
        const hour = 8 + i * 2;
        const colId = `d${cur}_${hour}`;
        return {
          title: `D${cur} ${hour}`,
          dataIndex: colId,
          sorter: (a, b, s) => tableNumSorter(a[colId], b[colId], s),
        } as IDownloadHeader;
      });
      return total.concat(curDayCols);
    }, [] as IDownloadHeader[]);
    const d0d2: IDownloadHeader[] = [2, 1, 0].reduce((total, cur) => {
      const curDayCols = Array.from({ length: 16 }, (v, i) => {
        const hour = 8 + i;
        const colId = `d${cur}_${hour}`;
        return {
          title: `D${cur} ${hour}`,
          dataIndex: colId,
          sorter: (a, b, s) => tableNumSorter(a[colId], b[colId], s),
        } as IDownloadHeader;
      });
      return total.concat(curDayCols);
    }, [] as IDownloadHeader[]);
    const tmp = baseColumns.concat(d7d14).concat(d3d6).concat(d0d2);
    console.log("columns: ", tmp);
    return tmp;
  }, []);

  const [{ data, isLoading }, doFetch] = useFetch({
    server: getServer(SystemType.airlines),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryExt,
    ext,
    lazey: true,
  });

  const transDataFn = useCallback((source: any[]) => {
    const rst: any[] = [];
    source.forEach((d: any) => {
      const tmp: any = {};
      Object.keys(d).forEach((key) => {
        if (/^d[0-9]{1,2}$/.test(key)) {
          const values: IHourData[] = JSON.parse(d[key]);
          if (parseInt(key.replace("d", ""), 10) >= 7) {
            tmp[key] = values.find((dv) => dv.h === "08")?.price;
          } else {
            values.forEach((v) => {
              tmp[`${key}_${parseInt(v.h, 10)}`] = v.price;
            });
          }
        } else {
          tmp[key] = d[key];
        }
      });
      rst.push(tmp);
    });
    return rst;
  }, []);

  const [, downloadFetch] = useFetch({
    server: getServer(SystemType.airlines),
    url: queryUrl,
    defaultValue: null,
    head: {
      moduleCode,
      chartTableCode,
    },
    query: queryExt,
    lazey: true,
    onSuccess: useCallback(
      (res: any) => {
        if (res.data && res.data.length) {
          const target = transDataFn(res.data);
          downloadExcel(
            columns,
            target,
            getSharkText("config_page_price_analysis")
          );
          setDownloading(false);
        } else {
          message.warn(
            getSharkText("config_page_data_is_empty_unable_to_download")
          );
          setDownloading(false);
        }
      },
      [columns, transDataFn]
    ),
    onError: useCallback(() => {
      message.error(getSharkText("config_page_abnormal_error"));
      setDownloading(false);
      console.log("error");
    }, []),
  });
  const refetch = useCallback(() => {
    doFetch({
      query: queryExt,
      ext,
    });
  }, [ext, doFetch, queryExt]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const tableProps = useMemo(() => {
    return {
      size: "small",
      scroll: { x: 10000 },
    };
  }, []);

  /**
   * 数据转化
   */
  useEffect(() => {
    if (data && data.length) {
      setTransData(transDataFn(data));
    } else {
      setTransData(EMPTY_ARRAY);
    }
  }, [data, transDataFn]);

  const onDownload = useCallback(() => {
    setDownloading(true);
    downloadFetch({
      query: queryExt,
      ext: {
        filter: {
          ...ext.filter,
          download: 1,
        },
      },
    });
  }, [queryExt, ext, downloadFetch]);

  const tipTypeName = downloading
    ? getSharkText("key.download.button")
    : isLoading
    ? getSharkText("key.loadfactor")
    : getSharkText("config_page_read");

  const tip = `${getSharkText(
    "config_page_data_large_slow"
  )}${tipTypeName}${getSharkText("config_page_please_wait")}. ${getSharkText(
    "config_page_load_failed"
  )}${tipTypeName}${getSharkText(
    "config_page_limit_time_range"
  )}. ${getSharkText(
    "config_page_continuous_fail"
  )}${tipTypeName}${getSharkText("config_page_contact_us")}.`;

  return (
    <Spin spinning={isLoading || downloading} tip={tip}>
      <CustomTableV2
        columns={columns}
        dataSource={transData}
        tableProps={tableProps}
        downloadName="custom"
        onDownload={onDownload}
        moduleCode={moduleCode}
        chartTableCode={chartTableCode}
      />
    </Spin>
  );
};
export default TablePortal;
