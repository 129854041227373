// Created by xh_zhu on 2021-03-31

import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Popover, Radio, RadioChangeEvent, Row } from "antd";
import React, { useState } from "react";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import {
  DISTRIBUTION_HOT_HELP,
  DISTRIBUTION_TREND_HELP,
} from "../PassengerAnalysisCommon";
import BarLineCharts from "./BarLineCharts";
import TopChartMap from "./TopChartMap";
import { getSharkText } from "Utils/i18nGlobal";

/**
 * Component description
 * 客源分布地图/趋势图
 */

interface IDistributionChartProps {
  ext?: any;
}

const options = [
  {
    label: (
      <span>
        {getSharkText("key.heatmap.psgrsource")}
        <Popover content={DISTRIBUTION_HOT_HELP}>
          <InfoCircleOutlined style={{ marginLeft: 5 }} />
        </Popover>
      </span>
    ),
    value: 0,
  },
  {
    label: (
      <span>
        {getSharkText("key.trendchart.psgrsource")}
        <Popover content={DISTRIBUTION_TREND_HELP}>
          <InfoCircleOutlined style={{ marginLeft: 5 }} />
        </Popover>
      </span>
    ),
    value: 1,
  },
];

const DistributionChart = (props: IDistributionChartProps) => {
  const { ext: otherExt } = props;
  const [distributionType, setDistributionType] = useState<0 | 1>(0);
  const [globalState] = useGlobalState();
  const { systemType } = globalState;

  const onTypeChange = (v: RadioChangeEvent) => {
    setDistributionType(v.target.value);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Row style={{ justifyContent: "center" }}>
        <Col>
          <Radio.Group
            value={distributionType}
            options={options}
            onChange={onTypeChange}
            optionType="button"
          />
        </Col>
      </Row>
      <Row style={{ flexDirection: "column", flexGrow: 1 }}>
        <Col style={{ flexGrow: 1 }}>
          {distributionType === 0 ? (
            <TopChartMap
              queryUrl={QUERY_URL[systemType].heatMap}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].distribution}
              ext={otherExt}
            />
          ) : (
            <BarLineCharts
              queryUrl={QUERY_URL[systemType].barline}
              moduleCode={MODULE_CODE[systemType]}
              chartTableCode={CHART_TABLE_CODE[systemType].distribution}
              ext={otherExt}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
export default DistributionChart;
