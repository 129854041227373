import { Checkbox, Empty, Form, Tooltip } from "antd";
import {
  Area as BaseArea,
  UserProfileTag,
} from "@ctrip/flt-bi-flightai-airlines";
import React, { forwardRef, ReactElement, useCallback, useMemo } from "react";
import { cloneDeep } from "lodash";
import AreaSelector from "./AreaSelector";
import { Area } from "Interface";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./GroupSelector.css";

export interface IGroupSelectorProps {
  data?: UserProfileTag[];
  value: UserProfileTag[];
  onChangeValue: (v: UserProfileTag[]) => void;
  areaSource: Area[];
}

/**
 * 群体圈选面板
 */
const GroupSelector = (props: IGroupSelectorProps, ref: any): ReactElement => {
  const { data, value, onChangeValue, areaSource } = props;

  const initialValues = useMemo(() => {
    const rst: Record<string, unknown[]> = {};
    value.forEach((v) => {
      if (v.tagCode) {
        if (v.tagType === 0) {
          const tmp: string[] = [];
          v.children?.forEach((c) => c.tagCode && tmp.push(c.tagCode));
          if (tmp.length) {
            rst[v.tagCode] = tmp;
          }
        } else if (v.tagType === 2) {
          rst[v.tagCode] = v.areaList as Area[];
        }
      }
    });
    return rst;
  }, [value]);

  const onFinish = useCallback(
    (v: Record<string, unknown[]>) => {
      const rst: UserProfileTag[] = [];
      // 常规字段
      data?.forEach((d) => {
        const code = d.tagCode;
        if (code && v[code] && d.children) {
          const tmp = cloneDeep(d);
          tmp.children = d.children.filter(
            (c) => c.tagCode && v[code].includes(c.tagCode)
          );
          rst.push(tmp);
        }
      });
      // 客源地字段
      if (v.uid_base) {
        const d = data?.find((t) => t.tagCode === "uid_base");
        if (d) {
          const tmp = cloneDeep(d);
          tmp.areaList = v.uid_base as BaseArea[];
          rst.push(tmp);
        }
      }
      onChangeValue(rst);
    },
    [data, onChangeValue]
  );

  // useImperativeHandle(ref, () => {
  //   submit: () => {
  //     form?.submit();
  //   }
  // })
  const getLabel = (v: UserProfileTag) => {
    if (!v.tagTip) {
      return v.tagName;
    }
    return (
      <>
        {v.tagName}
        <Tooltip title={v.tagTip}>
          <QuestionCircleOutlined style={{ marginLeft: 3 }} />
        </Tooltip>
      </>
    );
  };

  const genTag = useCallback(
    (v: UserProfileTag) => {
      if (v.tagType === 2) {
        return (
          <Form.Item
            name={v.tagCode || "unknown"}
            label={getLabel(v)}
            key={v.tagCode}
          >
            <AreaSelector areaSource={areaSource} />
          </Form.Item>
        );
      } else {
        return (
          <Form.Item
            name={v.tagCode || "unknown"}
            label={getLabel(v)}
            key={v.tagCode}
          >
            <Checkbox.Group>
              {v.children?.map((c) => (
                <Checkbox
                  key={c.tagCode}
                  value={c.tagCode}
                  style={{ lineHeight: "32px" }}
                >
                  {getLabel(c)}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      }
    },
    [areaSource]
  );

  if (!data) {
    return <Empty />;
  }
  return (
    <div className="group-selector">
      <Form
        ref={ref}
        name="validate_other"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        {data.map((d) => genTag(d))}
      </Form>
    </div>
  );
};
const GP = forwardRef(GroupSelector);
export default GP;
