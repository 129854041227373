import React, { useEffect, useState } from "react";
import { Alert, Button, Modal } from "antd";
import { Module } from "Interface";
import useGlobal from "Store";
import RightModal from "Components/RightModal";
import HTMLReactParser from "html-react-parser";
import "./index.css";
import { getSharkText } from "Utils/i18nGlobal";

interface IProps {
  module?: Module;
}

export const DemoModal: React.FC<IProps> = () => {
  const [, actions] = useGlobal();
  const { setDemoModalShowed } = actions;
  const [visible, setVisible] = useState<boolean>(true);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!visible && !modalVisible) {
      setDemoModalShowed(true);
    }
  }, [visible, modalVisible, setDemoModalShowed]);

  const handleClick = () => {
    setVisible(false);
    setModalVisible(true);
  };

  const description = (
    <span>
      {HTMLReactParser(getSharkText("headeralert.acquire_real_data.button"))}
      <Button style={{ marginLeft: 5 }} type="primary" onClick={handleClick}>
        {getSharkText("headeralert.apply.button")}
      </Button>
    </span>
  );

  return (
    <div style={{ margin: "0 20px" }}>
      <Modal
        className="demo-modal"
        open={visible}
        title={null}
        footer={null}
        onCancel={() => setVisible(false)}
        style={{ minWidth: 600 }}
      >
        <Alert
          message={getSharkText("headeralert.demo_data.message")}
          description={description}
          banner
          type="warning"
          showIcon
        />
      </Modal>
      <RightModal visible={modalVisible} setVisible={setModalVisible} />
    </div>
  );
};
