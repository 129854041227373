import AirportIndex from "./AirportIndex";
import AirlineIndex from "./AirlineIndex";
import React, { ReactElement } from "react";
import useGlobalState from "Store";
import { getIsDemo } from "Utils";
import { useLocation } from "react-router-dom";

/**
 * 中转分析组件
 */
const TransferAnalysis = (): ReactElement => {
  const location = useLocation();
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  const isAirport = systemType === 1;
  const isDemo = getIsDemo(location.pathname, globalState);
  return (
    <div>
      {isAirport ? (
        <AirportIndex isDemo={isDemo} />
      ) : (
        <AirlineIndex isDemo={isDemo} />
      )}
    </div>
  );
};
export default TransferAnalysis;
