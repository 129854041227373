// Created by xh_zhu on 2020-12-16
// 首页-机场版-大面板第一行的过滤器
import { Col, Row } from "antd";
import { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import { IAirportQueryExt2 } from "../Interface";
import DashboardDateRangePicker from "./DashboardDateRangePicker";
import RangePickerSwift from "./RangePickerSwift";
import useGlobal from "Store";
import {
  COMPARE_TYPE_PER_NAME,
  DATE_FORMAT,
  DEFAULT_AIRPORT_COMPARE_TYPE_NAME,
} from "Constants";

import "./BoardSearch.css";
import PreLastChain from "Components/PreLastChain";
import { ECompareTypeV2 } from "Interface";
import DownloadBtn from "../../../../Components/DownloadBtn";
import { getSharkText } from "Utils/i18nGlobal";

interface IBoardSearchProps {
  downLoadHandler: () => void;
  onChangeVal: (v: IAirportQueryExt2) => void;
  moduleCode: string;
  chartTableCode: string;
}

const defaultValControl = ["数值", DEFAULT_AIRPORT_COMPARE_TYPE_NAME];

const BoardSearch: React.FC<IBoardSearchProps> = (props: IBoardSearchProps) => {
  const { downLoadHandler, onChangeVal, moduleCode, chartTableCode } = props;
  const [range, setRange] = useState<Moment[]>([]);
  const [valControl, setValControl] = useState<string[]>(defaultValControl);
  const pickerRef = useRef<any>();
  const rangeSwiftRef = useRef<any>();

  const [globalState, actions] = useGlobal();
  const { queryCondition } = globalState;
  const [startDate, endDate] = range.length
    ? range.map((m: Moment) => m.format(DATE_FORMAT))
    : [];
  const { setQueryCondition } = actions;

  useEffect(() => {
    const dateStrArr = range.map((m: Moment) => m.format(DATE_FORMAT));
    setQueryCondition({
      ...queryCondition,
      startDate: dateStrArr[0],
      endDate: dateStrArr[1],
    });
  }, [range]);

  useEffect(() => {
    if (onChangeVal) {
      const yoy = valControl.includes(
        COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR]
      )
        ? 1
        : valControl.includes(
            COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_BEFORE_LAST]
          )
        ? 3
        : valControl.includes(
            COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_LAST_LAST]
          )
        ? 5
        : valControl.includes(
            COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_4_BEFORE]
          )
        ? 6
        : valControl.includes(
            COMPARE_TYPE_PER_NAME[ECompareTypeV2.YEAR_5_BEFORE]
          )
        ? 7
        : 0;
      const wow = valControl.includes(getSharkText("key.mom.indicator"))
        ? 2
        : valControl.includes(getSharkText("key.wow.indicator"))
        ? 1
        : 0;
      const val: IAirportQueryExt2 = {
        yoy,
        wow,
        valControl,
      };
      onChangeVal(val);
    }
  }, [startDate, endDate, valControl, onChangeVal]);

  const rangeSwiftChange = (r: Moment[]) => {
    if (r && r.length === 2) {
      if (pickerRef && pickerRef.current) {
        pickerRef.current.setValue(r);
      }
      setRange(r);
    }
  };

  const handleDownload = () => {
    downLoadHandler();
  };

  const rangePickerChange = (val: Moment[] | null) => {
    setRange(val || []);
    if (rangeSwiftRef && rangeSwiftRef.current) {
      rangeSwiftRef.current.setValue(null);
    }
  };

  return (
    <>
      <Row gutter={[{ xs: 8, sm: 16, md: 24 }, 20]}>
        <Col>
          <DashboardDateRangePicker
            ref={pickerRef}
            defaultDateMode="lastMonth"
            onChange={rangePickerChange}
            noHistoryToFuture
          ></DashboardDateRangePicker>
        </Col>
        <Col>
          <RangePickerSwift
            ref={rangeSwiftRef}
            onChange={rangeSwiftChange}
          ></RangePickerSwift>
        </Col>
        <Col>
          <PreLastChain
            defaultValue={defaultValControl}
            onChange={setValControl}
            startDate={startDate}
            endDate={endDate}
          ></PreLastChain>
        </Col>
        <Col>
          <DownloadBtn
            handleDownload={handleDownload}
            showIcon={false}
            moduleCode={moduleCode}
            chartTableCode={chartTableCode}
          />
        </Col>
      </Row>
    </>
  );
};
export default BoardSearch;
