import { Col, Divider, Row } from "antd";
import { ISeries } from "Interface";
import React, { ReactElement, useMemo, useState } from "react";
import useGlobalState from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "../fetchCode";
import { legendHelp } from "./AirlineCommon";
import AirlineTable from "../Components/AirlineTable";
import BarLineCharts from "./BarLineCharts";
import { genSeries } from "./chartSeries";
import { TransferPolicyBarlineRequestType } from "@ctrip/flt-bi-flightai-airlines";

/**
 * Component description
 * 中转分析航司版模块
 */

interface IProps {
  // 0 整体航线, 1 中转航线
  panelType: 0 | 1;
  title: string;
  queryExt: Partial<TransferPolicyBarlineRequestType>;
}

const AirlinePanel = (props: IProps): ReactElement => {
  const { panelType, title, queryExt } = props;
  const [globalState] = useGlobalState();
  const { systemType } = globalState;
  const [totalSeries] = useState<ISeries[]>(genSeries(panelType, systemType));
  const typeExt = useMemo(() => {
    return {
      ...queryExt,
      type: panelType,
    };
  }, [panelType, queryExt]);

  return (
    <div
      className="content-white"
      style={{ margin: "0 20px 20px", padding: "20px 20px 5px" }}
    >
      <div style={{ color: "#1890ff", fontSize: "120%" }}>{title}</div>
      <Divider style={{ margin: "15px 0" }} />
      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          <BarLineCharts
            queryUrl={QUERY_URL[systemType].barline}
            moduleCode={MODULE_CODE[systemType]}
            chartTableCode={CHART_TABLE_CODE[systemType].barline}
            ext={typeExt}
            totalSeries={totalSeries}
            legendHelp={legendHelp[systemType][panelType]}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px", flexFlow: "column" }}>
        <Col>
          <AirlineTable ext={typeExt} panelType={panelType} />
        </Col>
      </Row>
    </div>
  );
};
export default AirlinePanel;
