// Created by xh_zhu on 2020-12-16

import { getSharkText } from "Utils/i18nGlobal";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import moment, { Moment } from "moment";
import React, { useImperativeHandle, useState } from "react";

interface ISwiftConfig {
  label: string;
  value: () => Moment[];
}

const defaultSwift: ISwiftConfig[] = [
  {
    label: getSharkText("key.last_week.checkbox"),
    value: () => {
      const weekOfYear = moment().week();
      const base = () => moment().set("week", weekOfYear - 1);
      return [base().set("weekdays", 0), base().set("weekdays", 6)];
    },
  },
  {
    label: getSharkText("key.current_week.checkbox"),
    value: () =>
      // 假如当天是周一, 则日期为当天-当天, 假如当天不是周一, 则日期为本周一至前一天
      [
        moment().set("weekdays", 0),
        moment().day() === 1 ? moment() : moment().add(-1, "d"),
      ],
  },
  {
    label: getSharkText("key.current_month.checkbox"),
    value: () =>
      // 假如是当月1日,则结束取当日,否则取前一日
      [
        moment().set("date", 1),
        moment().date() === 1 ? moment() : moment().add(-1, "d"),
      ],
  },
  {
    label: getSharkText("key.current_year.checkbox"),
    value: () =>
      // 假如是1月1日,则结束日期取当日,否则取前一日
      [
        moment().set("dayOfYear", 1),
        moment().month() === 0 && moment().date() === 1
          ? moment()
          : moment().add(-1, "d"),
      ],
  },
];

interface IRangePickerSwiftProps {
  swiftConfig?: ISwiftConfig[];
  onChange?: (v: Moment[]) => void;
}

const Swift = (props: IRangePickerSwiftProps, ref: any) => {
  const { swiftConfig = defaultSwift, onChange } = props;
  const [idx, setIdx] = useState<number | null>(null);

  useImperativeHandle(ref, () => ({
    setValue(val: number | null) {
      setIdx(val);
    },
  }));

  const handleChangeSwift = (e: RadioChangeEvent) => {
    setIdx(e.target.value);
    if (onChange && swiftConfig.length > e.target.value) {
      onChange(swiftConfig[e.target.value].value());
    }
  };

  return (
    <Radio.Group id="rangePickerSwift" value={idx} onChange={handleChangeSwift}>
      {swiftConfig.map((item: ISwiftConfig, i) => (
        <Radio.Button key={i} value={i}>
          {item.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
const RangePickerSwift = React.forwardRef(Swift);
export default RangePickerSwift;
