import { Button, Col, Input, Row, Select } from "antd";
import { DEFAULT_AREA, EMPTY_ARRAY } from "Constants";
import { Area } from "Interface";
import { Area as AreaService } from "@ctrip/flt-bi-flightai-airports";
import { cloneDeep, isEqual } from "lodash";
import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import useGlobalState from "Store";
import { getSharkText } from "Utils/i18nGlobal";
import useRefFunc from "Utils/useRefFunc";
import AirportGroupSelector from "./AirportGroupSelector";
import AirportSingleSelector from "./AirportSingleSelector";
import { getDefaultAirport } from "Utils/user";

/**
 * Component description
 * 包含机场集团选择的机场选择器
 */

interface IProps {
  isDemo: boolean;
  multipleGroup?: boolean;
}

const Selectors = ["port", "group"] as const;

type SelectorType = (typeof Selectors)[number];

const NAME_MAP: Record<SelectorType, string> = {
  port: getSharkText("area.airport.title"),
  group: getSharkText("key.airport_group"),
};

const AirportSelectorWithGroup = (props: IProps): ReactElement => {
  const { isDemo, multipleGroup = false } = props;
  const [selectorType, setSelectorType] = useState<SelectorType>("port");
  const [globalState, actions] = useGlobalState();
  const { userInfo, queryCondition } = globalState;
  const { airport } = queryCondition;
  const userDefaultAirport = useMemo(
    () => getDefaultAirport(userInfo),
    [userInfo]
  );
  const { setQueryConditionAirport } = actions;
  const [selected, setSelected] = useState<string[]>(
    userDefaultAirport ? [userDefaultAirport.code] : ["SHA"]
  );
  const [selectedGroup, setSelectedGroup] = useState<Area[]>([]);
  const defaultSingle: Area = useMemo(() => {
    return userDefaultAirport
      ? {
          areaCode: userDefaultAirport.code,
          areaName: userDefaultAirport.name,
          areaType: 5,
        }
      : {
          areaCode: "SHA",
          areaName: getSharkText("config_page_shanghai_hongqiao"),
          areaType: 5,
        };
  }, []);
  const [selectedSingle, setSelectedSingle] = useState<Area[]>([defaultSingle]);

  const getAllSelectedAirports = useRefFunc(() => {
    const singleAirports: string[] = selectedSingle.map(
      (a: Area) => a.areaCode
    );
    const groupAirports: string[] = [];
    selectedGroup.forEach((g: Area) => {
      g.children?.forEach((c: Area) => {
        groupAirports.push(c.areaCode);
      });
    });
    return singleAirports.concat(groupAirports);
  });

  // 设置全局默认值
  useLayoutEffect(() => {
    if (!airport) {
      const tmpArea = {
        ...defaultSingle,
        pinyin: "",
        children: [],
        areaNameEn: defaultSingle.areaNameEn ?? null,
      };
      setQueryConditionAirport({
        airport: defaultSingle.areaCode,
        airportName: defaultSingle.areaName,
        area: tmpArea,
        originalAirport: tmpArea,
      });
    }
    return () => {
      const tmpArea = {
        ...defaultSingle,
        pinyin: "",
        children: [],
        areaNameEn: defaultSingle.areaNameEn ?? null,
      };
      console.log("set global airport to default", tmpArea, defaultSingle);
      setQueryConditionAirport({
        airport: defaultSingle.areaCode,
        airportName: defaultSingle.areaName,
        area: tmpArea,
        originalAirport: tmpArea,
      });
    };
  }, []);

  // #region queryCondition更新时更新组件的值
  useEffect(() => {
    const globalArr = airport.split(",");
    const selectedTotal = getAllSelectedAirports();
    const isSame = isEqual(globalArr.sort(), selectedTotal.sort());
    if (!isSame) {
      const globalName = queryCondition.airportName.split(",");
      const needSelected = globalArr.map((g, i) => {
        return {
          areaCode: g,
          areaName: globalName[i] || "",
          areaType: 5,
        } as Area;
      });
      setSelected(needSelected.map((g) => g.areaCode));
      setSelectedSingle(needSelected);
      setSelectedGroup(EMPTY_ARRAY);
    }
  }, [airport, queryCondition.airportName, getAllSelectedAirports]);
  // #endregion

  const handleChange = useRefFunc(
    (selectedSingle: Area[], selectedGroup: Area[]) => {
      setSelected(
        selectedSingle
          .map((s: Area) => s.areaCode)
          .concat(selectedGroup.map((g: Area) => g.areaName))
      );
      // 用于查询数据的机场
      const singleAirports: string[] = selectedSingle.map(
        (a: Area) => a.areaCode
      );
      const singleAirportsName: string[] = selectedSingle.map(
        (a: Area) => a.areaName
      );
      const groupAirports: string[] = [];
      const groupAirportsName: string[] = [];
      selectedGroup.forEach((g: Area) => {
        g.children?.forEach((c: Area) => {
          groupAirports.push(c.areaCode);
          groupAirportsName.push(c.areaName);
        });
      });

      const total = selectedGroup.concat(selectedSingle);
      const area =
        total && total.length
          ? {
              areaType: total[0].areaType,
              areaCode: total.map((m) => m.areaCode).join(","),
              areaName: total.map((m) => m.areaName).join(","),
              areaNameEn: null,
              pinyin: null,
              children: null,
            }
          : (DEFAULT_AREA as AreaService);

      setQueryConditionAirport({
        airport: singleAirports.concat(groupAirports).join(","),
        airportName: singleAirportsName.concat(groupAirportsName).join(","),
        originalAirport: area,
        area,
      });
    }
  );

  const onSingleChange = useRefFunc((v: Area) => {
    const tmpArr = cloneDeep(selectedSingle);
    if (selected.includes(v.areaCode)) {
      return;
    }
    tmpArr.push(v);
    setSelectedGroup([]);
    setSelectedSingle(tmpArr);
    handleChange(tmpArr, []);
  });

  const onDeselect = (v: string) => {
    if (selectedSingle.find((s: Area) => s.areaCode === v)) {
      const tmp = cloneDeep(selectedSingle);
      const rst = tmp.filter((g: Area) => g.areaCode !== v);
      setSelectedSingle(rst);
      handleChange(rst, []);
    } else {
      const tmp = cloneDeep(selectedGroup);
      const rst = tmp.filter((t: Area) => t.areaCode !== v);
      setSelectedGroup(rst);
      handleChange([], rst);
    }
  };

  const onGroupChange = useRefFunc((v: Area[]) => {
    setSelectedSingle([]);
    setSelectedGroup(v);
    handleChange([], v);
  });

  return (
    <>
      {isDemo ? (
        <Input style={{ minWidth: 200 }} disabled value="Demo" />
      ) : (
        <Select
          mode="tags"
          style={{ minWidth: 200 }}
          dropdownMatchSelectWidth={false}
          value={selected}
          onDeselect={onDeselect}
          dropdownRender={() => (
            <Row style={{ width: 800 }}>
              <Col span={4} style={{ borderRight: "1px solid #aaa" }}>
                {Selectors.map((e: SelectorType) => (
                  <div key={e} style={{ textAlign: "center", margin: "10px" }}>
                    <Button
                      type={selectorType === e ? "primary" : "text"}
                      value={e}
                      onClick={() => setSelectorType(e)}
                    >
                      {NAME_MAP[e]}
                    </Button>
                  </div>
                ))}
              </Col>
              <Col span={20}>
                <div style={{ height: 500, overflow: "scroll" }}>
                  <div
                    style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}
                  >
                    {selectorType === "port" ? (
                      <AirportSingleSelector
                        airports={
                          userDefaultAirport
                            ? [
                                {
                                  areaCode: userDefaultAirport.code,
                                  areaName: userDefaultAirport.name,
                                  areaType: 5,
                                },
                              ]
                            : []
                        }
                        onChange={onSingleChange}
                      />
                    ) : (
                      <AirportGroupSelector
                        selected={selectedGroup}
                        onChange={onGroupChange}
                        multiple={multipleGroup}
                      />
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        ></Select>
      )}
    </>
  );
};
export default AirportSelectorWithGroup;
