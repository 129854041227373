import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Alert, Col, Row } from "antd";
import UpdateTime from "Components/UpdateTime";
import Page from "Layout/Page";
import useGlobal from "Store";
import { CHART_TABLE_CODE, MODULE_CODE, QUERY_URL } from "./fetchCode";
import { isAirportMode } from "Utils";
import { getSharkText } from "Utils/i18nGlobal";
import UserProfileBlock from "./Components/UserProfileBlock";
import SearchArea from "./Components/SearchArea";
import { EMPTY_ARRAY } from "Constants";

import { airlineConfig, Config } from "./panelConfig";
import { defaultQuery, IQuery } from "./common";
import { cloneDeep } from "lodash";
import { UserProfileProvider } from "./UserProfileContext";

const defaultAllowTypes = ["bar", "pie", "line", "download"];

const UserProfile = (): ReactElement => {
  const [globalState] = useGlobal();
  const { queryCondition, systemType, airlinesQueryCondition } = globalState;
  const [loading, setLoading] = useState(false);
  /// 航司版选中城市
  const [queryExt, setQueryExt] = useState<IQuery>(defaultQuery);
  const isAirport = isAirportMode(systemType);

  useEffect(() => {
    // 修改flightClass清空page页面参数
    setLoading(true);
    setQueryExt(defaultQuery);
    setTimeout(() => setLoading(false));
  }, [queryCondition.flightClass, airlinesQueryCondition.flightClass]);

  const alertMessage = (
    <UpdateTime
      moduleCode={MODULE_CODE[systemType]}
      chartTableCode={CHART_TABLE_CODE[systemType].top10}
      text={getSharkText("custom_profile.alert")}
    />
  );

  const compareMap = useCallback(
    (config: Config) => {
      if (
        queryExt.isCompare &&
        queryExt.compare &&
        config.allowTypes?.length &&
        config.allowTypes[0] === "map"
      ) {
        const queryCp = cloneDeep(queryExt);
        queryCp.current = queryExt.compare;
        const newQueryExt = config.reconstructQuery
          ? config.reconstructQuery(queryCp)
          : queryCp;
        return (
          <Col lg={12} md={12} xs={24} key={`${config.title}Compare`}>
            <UserProfileBlock
              queryUrl={QUERY_URL[systemType].barLineCharts}
              moduleCode={MODULE_CODE[systemType]}
              title={
                config.title +
                `-${getSharkText("custom_profile.page_right.title")}`
              }
              subtitle={config.subtitle}
              chartTableCode={config.chartTableCode[systemType]}
              chartType={config.chartType || EMPTY_ARRAY}
              allowTypes={config.allowTypes || defaultAllowTypes}
              seriesConfig={config.seriesConfig || EMPTY_ARRAY}
              chartConfig={config.chartConfig}
              dataSort={config.sort}
              tagCode={config.tagCode}
              config={config}
              queryExt={newQueryExt}
            />
          </Col>
        );
      }
    },
    [queryExt, systemType]
  );

  return (
    <UserProfileProvider>
      <Page defaultDateMode="none" isShowCompareType={false}>
        {loading ? undefined : (
          <>
            <div className="content-white">
              <Alert message={alertMessage} banner type="info" />
              <SearchArea isAirport={isAirport} onChange={setQueryExt} />
              <Row
                gutter={[{ xs: 8, sm: 16, md: 16 }, 20]}
                style={{ marginTop: 10 }}
              >
                {airlineConfig(
                  isAirport,
                  airlinesQueryCondition.flightClass,
                  queryCondition.flightClass
                ).map((config: any, idx: number) => {
                  const newQueryExt = config.reconstructQuery
                    ? config.reconstructQuery(queryExt)
                    : queryExt;
                  return (
                    <>
                      <Col lg={12} md={12} xs={24} key={config.title || idx}>
                        <UserProfileBlock
                          queryUrl={QUERY_URL[systemType].barLineCharts}
                          moduleCode={MODULE_CODE[systemType]}
                          title={
                            config.title +
                            `${
                              newQueryExt.isCompare && config.isMap
                                ? `-${getSharkText(
                                    "custom_profile.page_left.title"
                                  )}`
                                : ""
                            }`
                          }
                          subtitle={config.subtitle}
                          chartTableCode={config.chartTableCode[systemType]}
                          chartType={config.chartType || EMPTY_ARRAY}
                          allowTypes={config.allowTypes || defaultAllowTypes}
                          seriesConfig={config.seriesConfig || EMPTY_ARRAY}
                          chartConfig={config.chartConfig}
                          dataSort={config.sort}
                          tagCode={config.tagCode}
                          config={config}
                          queryExt={newQueryExt}
                        />
                      </Col>
                      {compareMap(config)}
                    </>
                  );
                })}
              </Row>
            </div>
          </>
        )}
      </Page>
    </UserProfileProvider>
  );
};

export default UserProfile;
