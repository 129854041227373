import Modal from "antd/lib/modal/Modal";
import SvgIcon from "Components/SvgIcon";
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ubt } from "Utils";
import SettingContainer from "./Components/SettingContainer";
import "./index.scss";
import { TRACE_ID } from "./NotificationCenterCommon";
import Panel from "./Panel";
import { isNumber } from "lodash";

/**
 * 告警中心
 */
const NotificationCenter = (): ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
  const [warnCnt, setWarnCnt] = useState<number | "...">(0);
  const [infoCnt, setInfoCnt] = useState<number | "...">(0);
  const [userConfig, setUserConfig] = useState<any>({});
  const settingsRef = useRef<any>();

  const closeListener = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    window.addEventListener("click", closeListener);
    return () => {
      window.removeEventListener("click", closeListener);
    };
  }, [closeListener]);

  const openSettings = () => {
    setOpen(false);
    setSettingsVisible(true);
    ubt(TRACE_ID.getName("settings"));
  };

  const clickAlert = useCallback(() => {
    if (!open) {
      ubt(TRACE_ID.getName("main"));
    }
    setOpen(!open);
  }, [open]);

  return (
    <div
      className="notification-center"
      style={{ position: "relative", zIndex: 10000 }}
      onClick={(e) => {
        // 阻止触发全局的隐藏事件
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }}
    >
      <div className="panel" style={{ display: open ? "block" : "none" }}>
        <Panel
          openSettings={openSettings}
          setWarnCnt={setWarnCnt}
          setInfoCnt={setInfoCnt}
          userConfig={userConfig}
        />
      </div>
      <div onClick={clickAlert}>
        <div className="container">
          <div style={{ marginTop: 8 }}>
            <SvgIcon name="yujinghangban2" />
          </div>
          <div className="cntBase">
            <span style={{ borderBottom: "1px solid #eee", paddingBottom: 2 }}>
              {isNumber(warnCnt) && warnCnt > 999 ? "999+" : warnCnt}
            </span>
          </div>
          <div className="cntBase">
            <div>{isNumber(infoCnt) && infoCnt > 999 ? "999+" : infoCnt}</div>
          </div>
        </div>
      </div>
      <Modal
        title="设置"
        open={settingsVisible}
        onCancel={() => setSettingsVisible(false)}
        width={1280}
        // 需要在消息为空时判断是否因为用户没有设置导致消息为空. 所以即使不点击设置也需要加载用户的设置信息
        forceRender
        footer={null}
      >
        <SettingContainer
          setUserConfig={setUserConfig}
          settingsRef={settingsRef}
        />
      </Modal>
    </div>
  );
};
export default NotificationCenter;
