import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DetailResData, FlightInfoDetailWithKey } from "../../interface";
import {
  StandardFilter,
  StandardResponse,
} from "@ctrip/flt-bidw-mytrix-ui/dist/Interface/mytrix";
import { useServices } from "Page/AI/FreeDashboard/useServices";
import { useFetch } from "Utils";
import { message, Spin } from "antd";
import { DataRow2ListMap } from "@ctrip/flt-bidw-mytrix-ui/dist/Utils";
import useRefFunc from "Utils/useRefFunc";
import {
  D,
  DATASET_ID,
  DPORT,
  FLIGHTNO_GROUP,
  PORT_BG_GROUP,
  PORT_GROUP,
  POTENTIAL,
  PRI_APORT,
  PRI_DPORT,
  PRI_FLIGHTNO,
  PRI_TAKEOFFDATE,
  PRICE_DIFF_FIX,
  RAOHANG,
  TPORT,
  TRANSIT_PERSONS_FIX,
} from "./cols";
import {
  Dimension,
  Measure,
  Sorter,
} from "@ctrip/flt-bidw-mytrix-ui/dist/FreeDashboard/interface";
import RequestBuilder from "Page/AI/FreeDashboard/Components/RequestBuilder";
import { FDDatasetCol } from "@ctrip/flt-bi-flightai-base";
import CustomTableV2 from "Components/CustomTable/CustomTableV2";
import { columns } from "./columns";

export interface DetailTableProps {
  record: FlightInfoDetailWithKey;
  moduleCode: string;
  chartTableCode: string;
}

/** 弹窗表格 */
const DetailTable = (props: DetailTableProps): ReactElement => {
  const { record, moduleCode, chartTableCode } = props;
  const [resData, setResData] = useState<DetailResData[]>([]);
  const [datasetCols, setDatasetCols] = useState<FDDatasetCol[]>([]);
  const services = useServices();
  const [inited, setInited] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);

  const [{ isLoading, error }, doFetch] = useFetch({
    url: "mytrixQuery",
    head: {},
    ext: {},
    lazey: true,
    onSuccess: (r) => {
      if (r.ResponseStatus.Ack !== "Success") {
        message.error("请求失败!");
        return;
      }
      const res = JSON.parse(r.data) as StandardResponse;
      if (res.status !== 0) {
        message.error(`错误码: ${res.status}`);
        return;
      }
      const map = DataRow2ListMap(
        res.rows,
        res.headers
      ) as unknown as DetailResData[];
      const tmpWithKey = map.map((d) => {
        const item: DetailResData = {
          ...d,
          key: `${d.pri_dport}-${d.pri_aport}-${d.flightno_group}-${d.port_group}-${d.port_bg_group}`,
        } as DetailResData;
        return item;
      });
      console.log("tmpWithKey: ", tmpWithKey);
      tmpWithKey.sort((a, b) =>
        a.SUM_transit_persons_fix > b.SUM_transit_persons_fix ? -1 : 1
      );
      setResData(tmpWithKey);
    },
  });
  const init = useRefFunc(() => {
    services
      .getDatasetColsOnQuery(DATASET_ID)
      .then(
        (r) => {
          if (r?.ResponseStatus?.Ack === "Success") {
            setDatasetCols(r.data || []);
            setInited(true);
          }
        },
        (error) => {
          console.log("error: ", error);
        }
      )
      .catch((e) => console.log("eee: ", e));
  });
  useEffect(() => {
    init();
  }, [init]);

  // const columns = useMemo(() => {
  //   return datasetCols.concat(customCols);
  // }, [datasetCols]);

  const dimensions: Dimension[] = useMemo(
    () => [
      {
        columnName: PRI_DPORT,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: PRI_APORT,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: FLIGHTNO_GROUP,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: DPORT,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: TPORT,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: PORT_GROUP,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
      {
        columnName: PORT_BG_GROUP,
        dimensionConfig: {
          type: "row",
          calculateConfig: null,
        },
      },
    ],
    []
  );

  const measures: Measure[] = useMemo(
    () => [
      {
        id: RAOHANG,
        columnName: RAOHANG,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: TRANSIT_PERSONS_FIX,
        columnName: TRANSIT_PERSONS_FIX,
        measureConfig: {
          statisticalConfig: { method: "SUM" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: PRICE_DIFF_FIX,
        columnName: PRICE_DIFF_FIX,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
      {
        id: POTENTIAL,
        columnName: POTENTIAL,
        measureConfig: {
          statisticalConfig: { method: "AVG" },
          formatConfig: { type: "decimal", precision: 2 },
          comparison: null,
        },
      },
    ],
    []
  );
  const sorters: Sorter[] = useMemo(
    () => [
      // {
      //   // column: "m_exp_ontime_ratio",
      //   columnName: DPORT,
      //   sorter: "ASC",
      //   statistical: "COUNT",
      // },
      // {
      //   // column: "m_exp_ontime_ratio",
      //   columnName: TPORT,
      //   sorter: "ASC",
      //   statistical: "COUNT",
      // },
      // {
      //   // column: "m_exp_ontime_ratio",
      //   columnName: APORT,
      //   sorter: "ASC",
      //   statistical: "COUNT",
      // },
      // {
      //   columnName: TRANSIT_PERSONS_FIX,
      //   sorter: "DESC",
      //   statistical: "AVG",
      // },
    ],
    []
  );

  const oriFilters = useMemo(() => {
    const filters: StandardFilter[] = [
      {
        in: {
          field: `dimension.${D}`,
          values: record.d ? [record.d] : [],
        },
      },
      {
        in: {
          field: `dimension.${PRI_TAKEOFFDATE}`,
          values: record.takeoffdate ? [record.takeoffdate] : [],
        },
      },
      {
        in: {
          field: `dimension.${PRI_DPORT}`,
          values: record.dport ? [record.dport] : [],
        },
      },
      {
        in: {
          field: `dimension.${PRI_APORT}`,
          values: record.aport ? [record.aport] : [],
        },
      },
      {
        in: {
          field: `dimension.${PRI_FLIGHTNO}`,
          values: record.flightno ? [record.flightno] : [],
        },
      },
    ];
    return filters;
  }, [record]);
  const requestBuild = useMemo(() => {
    if (datasetCols.length === 0) return null;
    return new RequestBuilder({
      datasetId: DATASET_ID,
      columns: datasetCols,
      dimensions,
      measures,
      chartFilters: [],
      sorters,
      containerFilters: [],
      oriFilters,
      limit: 100,
    });
  }, [datasetCols, dimensions, measures, oriFilters, sorters]);
  const { encrypted, requestBody } = useMemo(() => {
    if (requestBuild) {
      return requestBuild.getRequestBody();
    } else {
      return { encrypted: null, requestBody: null };
    }
  }, [requestBuild]);

  const lastParam = useRef<string>();

  const refetch = useCallback(() => {
    doFetch({
      ext: {
        datasetId: DATASET_ID,
        colIds: [],
        req: encrypted,
      },
    });
  }, [doFetch, encrypted]);

  useEffect(() => {
    if (encrypted && encrypted !== lastParam.current) {
      refetch();
      lastParam.current = encrypted;
    }
  }, [encrypted, refetch]);
  return (
    <Spin spinning={isLoading}>
      <CustomTableV2
        columns={columns}
        dataSource={resData}
        moduleCode={moduleCode}
        chartTableCode={chartTableCode}
      ></CustomTableV2>
    </Spin>
  );
};
export default DetailTable;
