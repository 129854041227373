import { Alert, Col, Radio, RadioChangeEvent, Row, Select } from "antd";
import { TRAVIX_MARKET } from "Constants";
import Page from "Layout/Page";
import React, { ReactElement, useCallback, useState } from "react";
import ChannelType from "../Components/ChannelType";
import Sales from "../Components/Sales";

const { Option } = Select;

/**
 * Component description
 *
 */

const msg = (
  <div>
    <div>
      Travix and Trip sales data for the below 14 EU markets : AT, BE, CH, DE,
      DK, ES, FR, GB, GR, IE, IT, NL, PL, PT 5 core EU markets : DE, ES, FR, GB,
      IT
    </div>
    <div>
      {`Both Traxix and Trip are excluding cancelled orders (Travix: iscancelled = 'Y' and Trip: orderstatus = 'C');`}
    </div>
    <div>
      China domestic flights orders are excluded from Trip counts as well.
    </div>
    <div>{`For Channel Type, we have trip data correctly categorized, while for
    Travix data, currently only 'meta' share is accurate.`}</div>
    <div>Time Zone:UTC+1; Currency: EUR</div>
  </div>
);

const TravixSalesData = (): ReactElement => {
  const [marketType, setMarketType] = useState<number>(1);
  const [country, setCountry] = useState<string>();
  const onCountryChange = useCallback((v: string) => {
    setMarketType(-1);
    setCountry(v);
  }, []);
  const onMarketTypeChange = useCallback((v: RadioChangeEvent) => {
    setCountry(undefined);
    setMarketType(v.target.value);
  }, []);
  return (
    <Page defaultDateMode="none" needToolBar={false}>
      <div className="content-white">
        <Alert
          banner
          showIcon
          type="warning"
          message="All dates/month/year are based on sales dates."
        ></Alert>
        <Alert banner showIcon type="info" message={msg}></Alert>
        <Row style={{ padding: 20 }} gutter={[20, 20]}>
          <Col>Market</Col>
          <Col>
            <Radio.Group value={marketType} onChange={onMarketTypeChange}>
              <Radio value={1}>14 EU markets</Radio>
              <Radio value={2}>5 core EU markets</Radio>
            </Radio.Group>
          </Col>
          <Col>Market</Col>
          <Col>
            <Select
              value={country}
              showSearch
              onChange={onCountryChange}
              size="small"
              style={{ width: 100 }}
              allowClear
            >
              {TRAVIX_MARKET.map((c) => (
                <Option value={c} key={c}>
                  {c}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </div>
      <div className="content-white">
        <Sales
          panelType="salesData"
          marketType={marketType}
          country={country}
        />
      </div>
      <div className="content-white">
        <ChannelType
          panelType="salesData"
          marketType={marketType}
          country={country}
        />
      </div>
    </Page>
  );
};
export default TravixSalesData;
